import { groupBy, map, orderBy } from "lodash";
import { Interval } from "luxon";
import { transform } from "../../util/transform.util";
import { LinkTimeSlot } from "./types";

type LinkTimeSlotsByDate = {
  date: string;
  times: LinkTimeSlot[];
};

export function groupTimeSlotsByDate(timeSlots: LinkTimeSlot[]): LinkTimeSlotsByDate[] {
  return transform(
    timeSlots,
    (slots) => groupBy(slots, (slot) => Interval.fromISO(slot.timeSlot).start.toISODate()),
    (groupedSlots) => map(groupedSlots, (times, date) => ({ date, times })),
    (groupedList) => orderBy(groupedList, ["date", "asc"]),
  );
}

export const hasTag = (tag: "Best" | "Inconvenient") => (time: LinkTimeSlot) => time.tag === tag;

export const isBest = hasTag("Best");
export const isBad = hasTag("Inconvenient");
