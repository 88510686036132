export const getTitleFromPillMarkdown = (text: string) => {
  // Regular expression to match text between ":pill[" and "]"
  const regex = /:pill\[(.*?)\]/;

  // Extracting text using match function and accessing the first captured group
  var match = text.match(regex);

  if (match && match.length > 1) {
    return match[1]; // Return the content captured by the parentheses
  } else {
    return null;
  }
};
