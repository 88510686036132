import { Event, IntervalDefinition, RecurrenceRules } from "@clockwise/schema";
import { SetRequired } from "../types";
import { getAbsoluteDateTimeForEvent, getAbsoluteTimeForEvent } from "./format-time";
import { prettyList } from "./text";

export const DAY_STRINGS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

/////////////
// Helpers //
/////////////

function validateFragmentForString(servicesRules: RecurrenceRules) {
  const isValid =
    !!servicesRules &&
    !!servicesRules.interval &&
    !!servicesRules.interval.freqType &&
    typeof servicesRules.interval.byDay !== "undefined" &&
    typeof servicesRules.interval.interval === "number";

  return isValid;
}

////////////////////
// STRING CREATION
////////////////////

export function getEventTime(
  event: SetRequired<Partial<Event>, "recurrenceRules" | "isAllDay" | "startTime" | "endTime">,
  timezone: string,
) {
  const recurrence = event.recurrenceRules?.interval ? new Recurrence(event.recurrenceRules) : null;

  const absoluteEventTime = getAbsoluteTimeForEvent(event, timezone);
  const eventTime = !event.isAllDay && absoluteEventTime ? ` from ${absoluteEventTime}` : "";

  return recurrence
    ? `${recurrence.baseRecurrenceString}${eventTime}`
    : getAbsoluteDateTimeForEvent(event);
}

export type AutopilotTimeEvent = SetRequired<
  Partial<Event>,
  "recurrenceRules" | "startTime" | "endTime" | "isHold"
>;

export class Recurrence {
  private intervalDef: IntervalDefinition;

  constructor(rules: RecurrenceRules) {
    validateFragmentForString(rules);
    this.intervalDef = rules.interval as IntervalDefinition;
  }

  private byDayCount() {
    const { byDay } = this.intervalDef;
    if (!byDay) {
      return 0;
    }
    return byDay.split(",").length;
  }

  get freqType() {
    if (!this.intervalDef) {
      console.warn("intervalDef is null, falling back to weekly, errors likely");
      return "Weekly";
    }

    switch (this.intervalDef.freqType) {
      case "Daily":
        return "Daily";
      case "Weekly":
        return "Weekly";
      case "Monthly":
        return "Monthly";
      case "Yearly":
        return "Yearly";
      default:
        console.warn("passed unknown frequency type, falling back to weekly, errors likely");
        return "Weekly";
    }
  }

  get freqInterval() {
    if (!this.intervalDef) {
      console.warn("intervalDef is null, falling back interval of 1, errors likely");
      return 1;
    }
    return this.intervalDef.interval;
  }

  get days() {
    const { byDay } = this.intervalDef;
    if (!byDay) {
      return [];
    }
    return byDay.split(",").map((day: string) => {
      // TODO: this ignores byDay rules like '2FR'
      if (day.indexOf("SU") > -1) {
        return DAY_STRINGS[0];
      } else if (day.indexOf("MO") > -1) {
        return DAY_STRINGS[1];
      } else if (day.indexOf("TU") > -1) {
        return DAY_STRINGS[2];
      } else if (day.indexOf("WE") > -1) {
        return DAY_STRINGS[3];
      } else if (day.indexOf("TH") > -1) {
        return DAY_STRINGS[4];
      } else if (day.indexOf("FR") > -1) {
        return DAY_STRINGS[5];
      } else if (day.indexOf("SA") > -1) {
        return DAY_STRINGS[6];
      } else {
        console.warn("passed unknown byDay string; errors likely");
        return "";
      }
    });
  }

  get daysString() {
    const days = this.days;
    return days && days.length ? prettyList(days) : null;
  }

  get isOncePerDay() {
    return this.freqType === "Daily" || (this.freqType === "Weekly" && this.byDayCount() >= 5);
  }

  get isMultipleTimesPerWeek() {
    return this.freqType === "Daily" || (this.freqType === "Weekly" && this.byDayCount() > 1);
  }

  get isOncePerWeek() {
    return this.freqType === "Weekly" && this.byDayCount() === 1 && this.intervalDef.interval === 1;
  }

  get isBiWeekly() {
    return this.freqType === "Weekly" && this.intervalDef.interval === 2;
  }

  get isOncePerMonth() {
    return this.freqType === "Monthly";
  }

  get isBiMonthly() {
    return this.freqType === "Monthly" && this.intervalDef.interval === 2;
  }

  get isOncePerYear() {
    return this.freqType === "Yearly";
  }

  get isEveryDay() {
    return DAY_STRINGS.reduce((prev, curr) => prev && this.days.includes(curr), true);
  }

  get isEveryWeekDay() {
    const noSundaySaturday =
      !this.days.includes(DAY_STRINGS[0]) && !this.days.includes(DAY_STRINGS[6]);
    return (
      noSundaySaturday &&
      DAY_STRINGS.slice(1, 5).reduce((prev, curr) => prev && this.days.includes(curr), true)
    );
  }

  get freqString() {
    switch (this.freqType) {
      case "Daily":
        return "day";
      default:
      case "Weekly":
        return "week";
      case "Monthly":
        return "month";
      case "Yearly":
        return "year";
    }
  }

  get baseRecurrenceString() {
    // for every n weeks or days
    const intervalFrequency = this.intervalDef.interval > 1 ? ` ${this.intervalDef.interval}` : "";
    const plural = this.intervalDef.interval > 1 ? "s" : "";
    let maybeIncludeDay = "";

    // return a simpler string given some understanding of the event
    if (this.isEveryDay) {
      maybeIncludeDay = " on all days";
    } else if (this.isEveryWeekDay) {
      maybeIncludeDay = " on weekdays";
    } else if (this.daysString) {
      maybeIncludeDay = ` on ${this.daysString}`;
    }

    return `Repeats every${intervalFrequency} ${this.freqString}${plural}${maybeIncludeDay}`;
  }
}
