export enum OnboardingType {
  Default = "Default",
  Links = "Links",
  ManagerSegmentation = "ManagerSegmentation",
  M365 = "M365",
}

export enum OnboardingTypeSource {
  JtbdSurvey = "jtbd-survey",
  LinksLandingPage = "links-landing-page",
  WelcomeLoginPage = "welcome-login-page",
  Survey = "survey",
}

export interface OnboardingMeta {
  onboardingType: OnboardingType;
  onboardingTypeSource: OnboardingTypeSource | null; // the reason why the current onboarding type is the way it is
}
