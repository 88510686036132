export const webappPathRoot = "/app";
export const webappDefaultWindowName = "_clockwiseWeb";
export const helpDefaultWindowName = "_clockwiseHelp";
export const clockwiseSupportUrl = "https://support.getclockwise.com/";
export const scheduleWithCSPath = "/c/tatiana/cs-sync";

// TODO: @scott cleanup pageSlugs vs pagePaths after found migraiton
export const pageSlugs = {
  accountPreferences: "your-ideal-day",
  ai: "",
  analytics: "analytics",
  appSettings: "integrations",
  createOrJoinTeam: "create-or-join-team",
  createSchedulingLink: "create-scheduling-link",
  editSchedulingLink: "edit-scheduling-link/:linkName/:slug",
  checkout: "checkout",
  experiments: "experiments",
  flexibleHolds: "flexibility#holds",
  flexibleMeetings: "flexibility",
  graphiql: "graphiql",
  landing: "welcome",
  members: "users",
  onboarding: "onboarding",
  orgSettings: "organization-settings",
  plansAndBilling: "plans-and-billing",
  preferences: "preferences",
  pricing: "pricing",
  proposal: "proposal",
  schedulingLinks: "scheduling-links",
  sudo: "sudo",
  teamSettings: "team",
  teamsSettings: "team-settings",
  webSettings: "web-settings",
  provisioning: "sudo/provisioning",
} as const;

export const pagePaths = {
  accountPreferences: "/your-ideal-day",
  ai: "",
  analytics: "/analytics",
  appSettings: "/integrations",
  chatPlusCalendar: "/cerebro",
  createOrJoinTeam: "/create-or-join-team",
  experiments: "/experiments",
  flexibleHolds: "/flexibility#holds",
  flexibleMeetings: "/flexibility",
  graphiql: "/graphiql",
  landing: "/welcome",
  login: "/login",
  logout: "/logout",
  members: "/users",
  onboarding: "/onboarding",
  orgSettings: "/organization-settings",
  planner: "/planner",
  plansAndBilling: "/plans-and-billing",
  preferences: "/preferences",
  pricing: "/pricing",
  proposal: "/proposal",
  schedulingLinks: "/scheduling-links",
  sudo: "/sudo",
  provisioning: "/sudo/provisioning",
  teamSettings: "/team",
  teamsSettings: "/team-settings",
  testPage: "/gateway-test-page",
  webSettings: "/web-settings",
} as const;

export const appPaths = {
  accountPreferences: `${webappPathRoot}${pagePaths.accountPreferences}`,
  ai: `${webappPathRoot}${pagePaths.ai}`,
  analytics: `${webappPathRoot}${pagePaths.analytics}`,
  appSettings: `${webappPathRoot}${pagePaths.appSettings}`,
  chatPlusCalendar: `${webappPathRoot}${pagePaths.chatPlusCalendar}`,
  createOrJoinTeam: `${webappPathRoot}${pagePaths.createOrJoinTeam}`,
  experiments: `${webappPathRoot}${pagePaths.experiments}`,
  flexibleHolds: `${webappPathRoot}${pagePaths.flexibleHolds}`,
  flexibleMeetings: `${webappPathRoot}${pagePaths.flexibleMeetings}`,
  graphiql: `${webappPathRoot}${pagePaths.graphiql}`,
  landing: `${webappPathRoot}${pagePaths.landing}`,
  members: `${webappPathRoot}${pagePaths.members}`,
  onboarding: `${webappPathRoot}${pagePaths.onboarding}`,
  orgSettings: `${webappPathRoot}${pagePaths.orgSettings}`,
  planner: `${webappPathRoot}${pagePaths.planner}`,
  plansAndBilling: `${webappPathRoot}${pagePaths.plansAndBilling}`,
  preferences: `${webappPathRoot}${pagePaths.preferences}`,
  pricingPage: `${webappPathRoot}${pagePaths.pricing}`,
  proposal: `${webappPathRoot}${pagePaths.proposal}`,
  schedulingLinks: `${webappPathRoot}${pagePaths.schedulingLinks}`,
  sudo: `${webappPathRoot}${pagePaths.sudo}`,
  provisioning: `${webappPathRoot}${pagePaths.provisioning}`,
  teamSettings: `${webappPathRoot}${pagePaths.teamSettings}`,
  teamsSettings: `${webappPathRoot}${pagePaths.teamsSettings}`,
  webSettings: `${webappPathRoot}${pagePaths.webSettings}`,
} as const;
