/* eslint-disable react/prop-types */
import classNames from "classnames";
import React, { ButtonHTMLAttributes, forwardRef } from "react";
import { getInputDisabledClassNames } from "../constants/classes";
import { Sentiment } from "../constants/types";
import { CwIdProps } from "../types/cw-id";
import { SvgIconComponent } from "./Icons";

export type Variant = "solid" | "outlined" | "text";
/**
  Use Cases for Sizes
  Mini: When you need a button in a 12px font size that does not take up additional space
  XSmall: When you need a button in a 13px font size that does not take up additional space
*/
export type Size = "mini" | "xsmall" | "small" | "medium" | "large" | "jumbo";

export type Grouped = "first" | "last" | "middle" | "none";

export type TextAlignment = "left" | "center" | "right";

export interface Props
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "className">,
    CwIdProps {
  size?: Size;
  fullWidth?: boolean;
  rounded?: boolean;
  grouped?: Grouped;
  sentiment?: Sentiment;
  variant?: Variant;
  textAlign?: TextAlignment;
  /**
   * An icon to be displayed either alone or before a label.  Should render well at 18px size.
   */
  startIcon?: SvgIconComponent;
  /**
   * An icon to be displayed after a label.  Should render well at 18px size. Does not display without a label.
   */
  endIcon?: SvgIconComponent;
  iconColorClassOverride?: string;
}

export const COLOR_CLASSES_HASH: {
  [v in Variant]: { [s in Sentiment]: string };
} = {
  outlined: {
    positive:
      "cw-text-positive cw-border-positive hover:cw-bg-positive-hover active:cw-bg-positive-pressed cw-bg-default",
    info:
      "cw-text-info cw-border-info hover:cw-bg-info-hover active:cw-bg-info-pressed cw-bg-default",
    warning:
      "cw-text-warning cw-border-warning hover:cw-bg-warning-hover active:cw-bg-warning-pressed cw-bg-default",
    neutral:
      "cw-text-neutral cw-border-muted hover:cw-bg-neutral-hover active:cw-bg-neutral-hover cw-bg-default",
    destructive:
      "cw-text-destructive cw-border-destructive hover:cw-bg-destructive-hover active:cw-bg-destructive-pressed cw-bg-default",
    accent:
      "cw-text-accent cw-border-accent-emphasis hover:cw-bg-accent-emphasis/10 active:cw-bg-accent-emphasis/30 cw-bg-default",
  },
  solid: {
    positive:
      "cw-border-transparent cw-text-onEmphasis cw-bg-positive-emphasis hover:cw-bg-positive-emphasis-hover active:cw-bg-positive-emphasis-pressed",
    info:
      "cw-border-transparent cw-text-onEmphasis cw-bg-info-emphasis hover:cw-bg-info-emphasis-hover active:cw-bg-info-emphasis-pressed",
    warning:
      "cw-border-transparent cw-text-onEmphasis cw-bg-warning-emphasis hover:cw-bg-warning-emphasis-hover active:cw-bg-warning-emphasis-pressed",
    neutral:
      "cw-border-transparent cw-text-onEmphasis cw-bg-neutral-emphasis hover:cw-bg-neutral-emphasis-hover active:cw-bg-neutral-emphasis-pressed",
    destructive:
      "cw-border-transparent cw-text-onEmphasis cw-bg-destructive-emphasis hover:cw-bg-destructive-emphasis-hover active:cw-bg-destructive-emphasis-pressed",
    accent:
      "cw-border-transparent cw-text-onEmphasis cw-bg-accent-emphasis-pressed hover:cw-bg-accent-emphasis-hover active:cw-bg-accent-emphasis-pressed",
  },
  text: {
    positive:
      "cw-text-positive cw-border-transparent hover:cw-bg-positive-hover active:cw-bg-positive-pressed cw-bg-default/0",
    info:
      "cw-text-info cw-border-transparent hover:cw-bg-info-hover active:cw-bg-info-pressed cw-bg-default/0",
    warning:
      "cw-text-warning cw-border-transparent hover:cw-bg-warning-hover active:cw-bg-warning-pressed cw-bg-default/0",
    neutral:
      "cw-text-neutral cw-border-transparent hover:cw-bg-neutral-hover active:cw-bg-neutral-pressed cw-bg-default/0",
    destructive:
      "cw-text-destructive cw-border-transparent hover:cw-bg-destructive-hover active:cw-bg-destructive-pressed cw-bg-default/0",
    accent:
      "cw-text-accent cw-border-transparent hover:cw-bg-accent-emphasis/10 active:cw-bg-accent-emphasis/30 cw-bg-default/0",
  },
};

export const SIZE_CLASSES: { [s in Size]: string } = {
  mini: "cw-h-6 cw-text-12 cw-px-1.5 cw-gap-1 cw-font-medium",
  xsmall: "cw-h-7 cw-text-13 cw-px-2 cw-gap-1 cw-font-medium",
  small: "cw-h-8 cw-text-12 cw-px-2 cw-gap-1 cw-font-medium ",
  medium: "cw-h-9 cw-text-14 cw-px-3 cw-gap-1 cw-font-bold ",
  large: "cw-h-10 cw-text-14 cw-px-4 cw-gap-1 cw-font-bold ",
  jumbo: "cw-h-14 cw-text-20 cw-px-6 cw-gap-2 cw-font-bold ",
};

export const ICON_ONLY_SIZE_CLASSES: { [s in Size]: string } = {
  mini: "cw-h-6 cw-w-6 cw-text-12 cw-gap-1 cw-font-medium",
  xsmall: "cw-h-7 cw-w-7 cw-text-13 cw-gap-1 cw-font-medium",
  small: "cw-h-8 cw-w-8 cw-text-12 cw-gap-1 cw-font-medium",
  medium: "cw-h-9 cw-w-9 cw-text-14 cw-gap-1 cw-font-bold ",
  large: "cw-h-10 cw-w-10 cw-text-14 cw-gap-1 cw-font-bold ",
  jumbo: "cw-h-14 cw-w-14 cw-text-20 cw-gap-2 cw-font-bold ",
};

export const ICON_WITH_TEXT_SIZE_CLASSES: { [s in Size]: string } = {
  mini: "cw-w-4 cw-h-4",
  xsmall: "cw-w-4 cw-h-4",
  small: "cw-w-5 cw-h-5",
  medium: "cw-w-5 cw-h-5",
  large: "cw-w-5 cw-h-5",
  jumbo: "cw-w-5 cw-h-5",
};

export const ICON_COLOR_CLASS: { [s in Sentiment]: string } = {
  positive: "cw-text-positive-muted",
  info: "cw-text-info-muted",
  warning: "cw-text-warning-muted",
  neutral: "cw-text-neutral-muted",
  destructive: "cw-text-destructive-muted",
  accent: "cw-border-accent-emphasis",
};

export const DARK_ICON_COLOR_CLASS: { [s in Sentiment]: string } = {
  positive: "cw-text-positive",
  info: "cw-text-info",
  warning: "cw-text-warning",
  neutral: "cw-text-neutral",
  destructive: "cw-text-destructive",
  accent: "cw-border-accent-emphasis",
};

const getTextAlignClass = (alignment: TextAlignment) => {
  switch (alignment) {
    case "left":
      return "cw-justify-left";
    case "center":
      return "cw-justify-center";
    case "right":
      return "cw-justify-right";
  }
};

const getInputDisabledClasses = (disabled: boolean, sentiment: Sentiment) => {
  // For some reason accent classes are the only ones overwritten by the default classes
  if (sentiment === "accent") {
    return classNames(
      disabled ? "cw-cursor-not-allowed" : "cw-cursor-pointer",
      disabled ? "cw-text-default-disabled" : "",
      disabled ? "placeholder:cw-text-subtle-disabled" : "placeholder:cw-text-subtle",
      disabled ? "cw-bg-default" : "",
    );
  }

  return getInputDisabledClassNames(disabled);
};

export const Button = forwardRef<HTMLButtonElement, Props>(function ButtonWithRef(
  {
    size = "medium",
    sentiment = "neutral",
    variant = "solid",
    startIcon: StartIconComponent,
    endIcon: EndIconComponent,
    disabled,
    fullWidth = false,
    rounded = false,
    grouped = "none",
    textAlign = "center",
    children,
    iconColorClassOverride,
    ...props
  },
  ref,
) {
  const isIconOnly = !children && !!StartIconComponent;
  const iconColorClass =
    sentiment === "accent"
      ? iconColorClassOverride
      : variant === "solid"
      ? "cw-text-onEmphasis"
      : isIconOnly
      ? DARK_ICON_COLOR_CLASS[sentiment]
      : ICON_COLOR_CLASS[sentiment];

  const startIcon = StartIconComponent ? (
    <StartIconComponent
      className={`${ICON_WITH_TEXT_SIZE_CLASSES[size]} ${iconColorClass}`}
      aria-hidden
    />
  ) : null;
  const endIcon = EndIconComponent ? (
    <EndIconComponent
      className={`${ICON_WITH_TEXT_SIZE_CLASSES[size]} ${iconColorClass}`}
      aria-hidden
    />
  ) : null;

  const colorClasses = COLOR_CLASSES_HASH[variant][sentiment];
  const fullWidthClass = fullWidth ? "cw-w-full" : "";
  const sizeClasses = isIconOnly ? ICON_ONLY_SIZE_CLASSES[size] : SIZE_CLASSES[size];
  const textAlignClasses = getTextAlignClass(textAlign);
  const disabledClasses = disabled ? "cw-opacity-50" : "";
  let roundedClass = rounded ? "cw-rounded-full" : "cw-rounded-md";
  let borderExceptionClass = "";
  switch (grouped) {
    case "first":
      roundedClass = rounded ? "cw-rounded-l-full" : "cw-rounded-l-md";
      borderExceptionClass = "cw-border-r-0";
      break;
    case "middle":
      roundedClass = "cw-rounded-none";
      borderExceptionClass = "cw-border-r-0";
      break;
    case "last":
      roundedClass = rounded ? "cw-rounded-r-full" : "cw-rounded-r-md";
      break;
  }
  return (
    <button
      {...props}
      ref={ref}
      className={classNames(
        colorClasses,
        sizeClasses,
        fullWidthClass,
        textAlignClasses,
        roundedClass,
        disabledClasses,
        "focus-visible:cw-outline-2 focus-visible:cw-outline-offset-2",
        "cw-border cw-border-solid cw-transition-colors cw-ease-out cw-duration-200",
        borderExceptionClass,
        "cw-leading-none cw-font-body cw-whitespace-nowrap",
        "cw-flex cw-items-center",
        getInputDisabledClasses(!!disabled, sentiment),
      )}
      disabled={disabled}
    >
      {children ? (
        <>
          {startIcon}
          {children}
          {endIcon}
        </>
      ) : (
        startIcon
      )}
    </button>
  );
});

export const isButton = (
  component: React.ReactNode,
): component is React.ReactElement<typeof Button> =>
  !!(
    component &&
    React.isValidElement(component) &&
    typeof component.type !== "string" &&
    typeof component.type !== "number" &&
    component.type.name === Button.name
  );
