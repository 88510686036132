/**
 * Naive solution for a mutable config value.
 * Allows for overriding environment during app initialization.
 */

export type Environment = "production" | "preprod" | "staging" | "development" | "test";

const defaultEnvironment = (process.env.ENVIRONMENT ?? "development") as Environment;
let environment: Environment | undefined = undefined;

export const getEnvironment = () => environment ?? defaultEnvironment;
export const setEnvironment = (env: Environment) => (environment = env);
export const resetEnvironment = () => (environment = undefined);
