// IMPORTS
// schema
import * as ISchema from "#webapp/src/__schema__";

import { modernCache } from "#webapp/src/network/modern-network-layer";
import { commitMutation } from "react-relay";
import { Environment } from "relay-runtime";
import { sendM365TeamAvailabilityCalendarInviteMutation } from "./SendM365TeamAvailabilityCalendarInvite.gql";

// TYPES
interface ISendM365TeamAvailabilityCalendarInviteMutationResponse {
  sendM365TeamAvailabilityCalendarInvite: ISchema.ISendM365TeamAvailabilityCalendarInviteMutationPayload;
}

// MUTATION
export function sendM365TeamAvailabilityCalendarInvite(
  environment: Environment,
  input: ISchema.ISendM365TeamAvailabilityCalendarInviteMutationInput,
  onSuccess: (response?: ISchema.ISendM365TeamAvailabilityCalendarInviteMutationPayload) => void,
  onError: (error?: any) => void,
) {
  commitMutation(environment, {
    onError,
    mutation: sendM365TeamAvailabilityCalendarInviteMutation,
    variables: { input },
    onCompleted: (
      response: ISendM365TeamAvailabilityCalendarInviteMutationResponse,
      errors?: Error[],
    ) => {
      if (errors) {
        onError(errors[0]);
      } else {
        // if we're not in a modern environment, modernCache will be undefined
        if (modernCache) {
          modernCache.clear();
        }
        onSuccess(response.sendM365TeamAvailabilityCalendarInvite);
      }
    },
  });
}
