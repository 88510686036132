import { Button, Modal } from "@clockwise/design-system";
import { Event } from "@clockwise/design-system/icons";
import { Interval } from "luxon";
import React from "react";
import { getFormattedDateTimeTz } from "../../util/date.util";

export type Props = {
  slotTimeRange: Interval;
  timeZone: string;
  title: string;
  fullscreen: boolean;
  onClose: () => void;
};

export function TimeUnavailableModal({
  slotTimeRange,
  timeZone,
  title,
  fullscreen,
  onClose,
}: Props) {
  const startTime = slotTimeRange.start.setZone(timeZone);

  return (
    <Modal
      title="Book this time"
      opened
      centered={true}
      size="sm"
      onClose={onClose}
      fullScreen={fullscreen}
      returnFocus={false}
      lockScroll={false}
      cw-id="link-booking-modal"
    >
      <>
        <div>
          <div className="cw-flex cw-justify-center cw-gap-4 cw-bg-warning cw-border cw-border-solid cw-border-warning cw-p-4 cw-rounded-lg cw-leading-relaxed cw-mb-4">
            <span>
              <Event className="cw-text-[18px] cw-fill-muted" />
            </span>
            <div>
              <div className="cw-heading-base">{title}</div>
              <div className="cw-line-through cw-body-base cw-text-warning-disabled">
                {getFormattedDateTimeTz(startTime) + " " + startTime.offsetNameShort}
              </div>
              <div className="cw-body-base cw-text-warning cw-font-bold">
                This time is no longer available
              </div>
            </div>
          </div>
          <Button
            onClick={onClose}
            sentiment="positive"
            size="large"
            aria-label="choose another time"
            fullWidth
          >
            Choose another time
          </Button>
        </div>
      </>
    </Modal>
  );
}
