// schema
import * as ISchema from "#webapp/src/__schema__";

import { fromGlobalId } from "#webapp/src/util/graphql.util";

import {
  HasAnnotatedEvent,
  SubjectTypeEnum,
  tagAliases,
  TagState,
} from "@clockwise/client-commons/src/util/event-tag";
import { SubjectUrnTypeEnum } from "@clockwise/schema";

export {
  EAutopilotWarning,
  EFlex,
  generateAutopilotTags,
  generateRoomFlexibilityTag,
  getFlexFromEvent,
  getTagValue,
  IgnoreState,
  isAutopilotExplicitlyEnabled,
  maybeGetWarningType,
  ROOM_FLEXIBILITY_VALUES,
  SubjectTypeEnum,
  tagAliases,
  Tags,
} from "@clockwise/client-commons/src/util/event-tag";

export function getOrgTagsOffEvent(event?: ISchema.IEvent | HasAnnotatedEvent) {
  return !!event && !!event.annotatedEvent && event.annotatedEvent && event.annotatedEvent.orgTags;
}

export function getRecurringOrgTagsOffEvent(event?: ISchema.IEvent | HasAnnotatedEvent) {
  return (
    !!event &&
    !!event.annotatedRecurringEvent &&
    event.annotatedRecurringEvent &&
    event.annotatedRecurringEvent.orgTags
  );
}

export function hasAnyUserAnnotations(event?: ISchema.IEvent | HasAnnotatedEvent) {
  return (
    getAllOrgTagsOffEvent(event).filter((tag) => {
      return tag.state.subjectType === SubjectTypeEnum.User;
    }).length > 0
  );
}

export function getAllOrgTagsOffEvent(event?: ISchema.IEvent | HasAnnotatedEvent) {
  const orgTags = getOrgTagsOffEvent(event) || [];
  const recurringOrgTags = getRecurringOrgTagsOffEvent(event) || [];
  return [...orgTags, ...recurringOrgTags];
}

export function getTagState(
  event: HasAnnotatedEvent,
  tagName: string,
  ignoreAliases = false,
): TagState | undefined {
  // e.g. PIN -> { MovementType: None }
  const alias = tagAliases[tagName];
  // e.g. when tag === MovementType, this will be PIN
  const aliasedTags = alias
    ? []
    : Object.keys(tagAliases).filter((aliasName) => tagAliases[aliasName].name === tagName);

  const resolvedTagName = (alias && alias.name) || tagName;

  const orgTags = getOrgTagsOffEvent(event) || [];
  const recurringOrgTags = getRecurringOrgTagsOffEvent(event) || [];

  const findTag = (ts: { tag: string; state: TagState }[]) =>
    ts.find((t) => t.tag === resolvedTagName);
  const tagActive = (tag: any) => tag && tag.state && tag.state.active;

  const tagEdge = findTag(orgTags);
  const recurringTagEdge = findTag(recurringOrgTags);

  const eitherTagActive = tagActive(tagEdge) || tagActive(recurringTagEdge);
  let foundTagEdge;
  if (eitherTagActive) {
    // use the tag that's active
    foundTagEdge = tagActive(tagEdge) ? tagEdge : recurringTagEdge;
  } else {
    // use the tag that exists, if it exists
    foundTagEdge = tagEdge || recurringTagEdge;
  }

  const foundTag = foundTagEdge;

  // if no found tag, return undefined
  if (!foundTag) {
    return undefined;
  }

  // we've found a tag
  if (alias) {
    // if the alias value matches the tag value, return the tag, otherwise it doesn't exist
    return alias.value === foundTag.state.value ? foundTag.state : undefined;
  }

  const isFetchingAliasedValue = !!aliasedTags.find(
    (aliasedTag) => tagAliases[aliasedTag].value === foundTag.state.value,
  );

  // if the tag we're fetching is being used for an alias, e.g. fetched { MovementType: None }, which is aliased to PIN
  // return undefined, otherwise continue as normal
  if (ignoreAliases) {
    return foundTag.state;
  }
  return isFetchingAliasedValue ? undefined : foundTag.state;
}

export function isTagStateUserSet(tagState?: TagState): boolean {
  return !!tagState && !!tagState.subjectType && tagState.subjectType === SubjectTypeEnum.User;
}

// read tag off annotated event and check if active
export function isTagActive(event: HasAnnotatedEvent, tag: string): boolean {
  const orgTagState = getTagState(event, tag);
  return !!orgTagState && !!orgTagState.active;
}

export function checkIfEventTagsChanged(oldEvent: ISchema.IEvent, newEvent: ISchema.IEvent) {
  // basically all we're doing is checking if the tags have changed
  const prevTags = getAllOrgTagsOffEvent(oldEvent);
  const nextTags = getAllOrgTagsOffEvent(newEvent);
  const tagsChanged = JSON.stringify(prevTags) !== JSON.stringify(nextTags);
  return tagsChanged;
}

// ~~~~~~~~~~~~~~~~~~~~~~~~
// Rooms
// ~~~~~~~~~~~~~~~~~~~~~~~~
export function parseList(tagState?: TagState): string[] {
  return (tagState && tagState.value && JSON.parse(tagState.value)) || [];
}

// ~~~~~~~~~~~~~~~~~~~~~~~~
// NEW Event Annotations Stuff
// ~~~~~~~~~~~~~~~~~~~~~~~~

export enum EType {
  Reminder = "REMINDER",
  Hold = "HOLD",
  Meeting = "Meeting",
}

///////////////
// USER TAGS
///////////////
type UserTagState = {
  __typename: "TagState";
  value: string | null;
  subjectType: SubjectUrnTypeEnum;
  subjectValue: string;
  active: boolean;
  lastModified: number;
};

export type EventWithUserTags = {
  annotatedEvent: {
    __typename: "AnnotatedEvent";
    userTags:
      | {
          __typename: "UserTag";
          tag: string;
          states: UserTagState[] | null;
        }[]
      | null;
  } | null;
  annotatedRecurringEvent?: {
    __typename: "AnnotatedEvent";
    userTags:
      | {
          __typename: "UserTag";
          tag: string;
          states: UserTagState[] | null;
        }[]
      | null;
  } | null;
};

export function getUserTagsOffEvent(event: EventWithUserTags, tag?: string) {
  const baseTags = (event.annotatedEvent && event.annotatedEvent.userTags) || [];

  const recurringTags =
    (event.annotatedRecurringEvent && event.annotatedRecurringEvent.userTags) || [];

  const allUserTags = [...baseTags, ...recurringTags];

  if (tag) {
    return allUserTags.filter((userTag) => userTag.tag === tag);
  } else {
    return allUserTags;
  }
}

export function getUserTagValueOffEvent(
  event: EventWithUserTags,
  tag: string,
  userId: string | null,
) {
  // get all tags off the event
  const allUserTags = getUserTagsOffEvent(event, tag);
  // next, collect all tag states (user tags have multiple states)
  const allStates = allUserTags.reduce(
    (
      accum: UserTagState[],
      userTag: {
        __typename: "UserTag";
        tag: string;
        states: UserTagState[];
      },
    ) => {
      return accum.concat(userTag.states);
    },
    [],
  );
  // then get the services userId
  let userIdFromObj = "noUserID";
  if (userId) {
    const userIdObj = fromGlobalId(userId);
    userIdFromObj = !!userIdObj && userIdObj.id;
  }

  // now filter down to this user's states
  const userStates = allStates.filter((state) => {
    const isUserType = state.subjectType.toString() === SubjectTypeEnum.User;
    const isUsersTag = state.subjectValue === userIdFromObj;
    return isUserType && isUsersTag;
  });
  // and pull out states created by services
  const servicesStates = allStates.filter(
    (state) => state.subjectType.toString() === SubjectTypeEnum.Services,
  );

  if (userStates && userStates.length) {
    // prefer user states
    // tie break by modified date
    return userStates.sort((a, b) => {
      return a.lastModified - b.lastModified;
    })[0];
  } else if (servicesStates && servicesStates.length) {
    // fall-back to services states
    // tie break by modified date
    return servicesStates.sort((a, b) => {
      return a.lastModified - b.lastModified;
    })[0];
  } else {
    return null;
  }
}
