/**
 * @generated SignedSource<<3a7b97cea5892fb3f75263cbc4915f69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserDefinedTeamMutationInput = {
  calendarIds: ReadonlyArray<string | null>;
  clientMutationId?: string | null;
  userDefinedTeamRelayId: string;
};
export type UpdateUserDefinedTeamMutation$variables = {
  input: UpdateUserDefinedTeamMutationInput;
};
export type UpdateUserDefinedTeamMutation$data = {
  readonly updateUserDefinedTeam: {
    readonly userDefinedTeam: {
      readonly calendarIds: ReadonlyArray<string | null> | null;
    } | null;
  } | null;
};
export type UpdateUserDefinedTeamMutation = {
  variables: UpdateUserDefinedTeamMutation$variables;
  response: UpdateUserDefinedTeamMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calendarIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUserDefinedTeamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserDefinedTeamMutationPayload",
        "kind": "LinkedField",
        "name": "updateUserDefinedTeam",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserDefinedTeam",
            "kind": "LinkedField",
            "name": "userDefinedTeam",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateUserDefinedTeamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserDefinedTeamMutationPayload",
        "kind": "LinkedField",
        "name": "updateUserDefinedTeam",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserDefinedTeam",
            "kind": "LinkedField",
            "name": "userDefinedTeam",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c372bd42fb3bf8d31f298ceeea51af5",
    "id": null,
    "metadata": {},
    "name": "UpdateUserDefinedTeamMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUserDefinedTeamMutation(\n  $input: UpdateUserDefinedTeamMutationInput!\n) {\n  updateUserDefinedTeam(input: $input) {\n    userDefinedTeam {\n      calendarIds\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "312b878d7d468bd4474b37f5010e69c3";

export default node;
