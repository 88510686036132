/**
 * @generated SignedSource<<8c56477b9f832894b0223e907c14264b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type saveTeamUserSettingsMutationInput = {
  clientMutationId?: string | null;
  orgRelayId: string;
  teamId: string;
  useNoMeetingDay?: boolean | null;
  useTeamCalendar?: boolean | null;
};
export type SaveTeamUserSettingsMutation$variables = {
  input: saveTeamUserSettingsMutationInput;
};
export type SaveTeamUserSettingsMutation$data = {
  readonly saveTeamUserSettings: {
    readonly clientMutationId: string | null;
    readonly teamUserSettings: {
      readonly useTeamCalendar: boolean;
      readonly useNoMeetingDay: boolean;
      readonly lastUpdated: any;
    };
  } | null;
};
export type SaveTeamUserSettingsMutation = {
  variables: SaveTeamUserSettingsMutation$variables;
  response: SaveTeamUserSettingsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useTeamCalendar",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useNoMeetingDay",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdated",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SaveTeamUserSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "saveTeamUserSettingsMutationPayload",
        "kind": "LinkedField",
        "name": "saveTeamUserSettings",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamUserSettings",
            "kind": "LinkedField",
            "name": "teamUserSettings",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SaveTeamUserSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "saveTeamUserSettingsMutationPayload",
        "kind": "LinkedField",
        "name": "saveTeamUserSettings",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamUserSettings",
            "kind": "LinkedField",
            "name": "teamUserSettings",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7080606264eb0bcddedc20632d573a10",
    "id": null,
    "metadata": {},
    "name": "SaveTeamUserSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation SaveTeamUserSettingsMutation(\n  $input: saveTeamUserSettingsMutationInput!\n) {\n  saveTeamUserSettings(input: $input) {\n    clientMutationId\n    teamUserSettings {\n      useTeamCalendar\n      useNoMeetingDay\n      lastUpdated\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "88337f11130c633688c20f320cecc5c9";

export default node;
