import React, { useState } from "react";

import { countries, ICountry } from "./CountryPicker.util";

import { TextField } from "@material-ui/core";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";

interface CountryPickerProps {
  onSelectCountry: (countryId: string) => void;
}

export const CountryPicker = ({ onSelectCountry }: CountryPickerProps) => {
  const [selectedCountry, setSelectedCountry] = useState<ICountry>({
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  });

  const getOptionLabel = (option: ICountry) => {
    return option.label;
  };

  // ISO 3166-1 alpha-2
  // ⚠️ No support for IE 11
  const countryToFlag = (isoCode: string) => {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  };

  const onChange = (_e: React.ChangeEvent, option: ICountry) => {
    setSelectedCountry(option);
    onSelectCountry(option.code);
  };

  const renderOption = (option: ICountry) => {
    return (
      <React.Fragment>
        <span className="cw-mr-2 cw-body-lg">{countryToFlag(option.code)}</span>
        {option.label}
      </React.Fragment>
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        placeholder="Country"
        variant="outlined"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          ...params.inputProps,
          autoComplete: "new-password", // disable autocomplete and autofill
        }}
      />
    );
  };

  return (
    <Autocomplete
      id="country-select"
      options={countries}
      style={{ width: "100%" }}
      classes={{
        option: "cw-body-base",
        inputRoot: "cw-text-14 hover:cw-border-default-hover hover:cw-border",
        input: "cw-p-0",
      }}
      autoHighlight
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={renderInput}
      onChange={onChange}
      value={selectedCountry}
      disableClearable
    />
  );
};
