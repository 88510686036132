import { Button } from "@clockwise/design-system";
import { Check, Edit, Link } from "@clockwise/design-system/icons";
import React, { useCallback } from "react";
import { ClockwiseLogoFull } from "../../components/logo/ClockwiseLogoFull";
import { ArrowBack } from "../../components/svgs/ArrowBack";
import { TrackingEvents, useTracking } from "../../util/analytics.util";
import { useResettingBoolean } from "./hooks/useResettingBoolean";

type Props = {
  showOwnerBanner: boolean;
  link: {
    linkUrl: string;
    meetingSettings: {
      id: string;
      slug: string;
    };
  };
  onClickMyLinks: () => void;
  onEditLink: (slug: string) => void;
};

export function OwnerBanner({
  showOwnerBanner,
  link: {
    linkUrl,
    meetingSettings: { id, slug },
  },
  onClickMyLinks,
  onEditLink,
}: Props) {
  const trackEvent = useTracking();
  const [copied, setCopied] = useResettingBoolean(false);

  const onCopyLink = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      // Copy the given link to the clipboard
      await navigator.clipboard.writeText(linkUrl);
      setCopied(true);
      trackEvent(TrackingEvents.LINKS.LINK.COPY_LINK, {
        meetingSettingsId: id,
        surface: "owner-banner",
      });
    },
    [id, linkUrl, setCopied, trackEvent],
  );

  const onEdit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onEditLink(slug);
    },
    [onEditLink, slug],
  );

  if (showOwnerBanner) {
    return (
      <div className="cw-flex cw-gap-2 cw-items-center xs:cw-rounded-lg cw-border-solid cw-border-b cw-border-0 xs:cw-border cw-border-brand cw-bg-brand cw-px-3 cw-py-2">
        {window.history.length > 1 && (
          <Button
            variant="text"
            sentiment="positive"
            startIcon={ArrowBack}
            onClick={() => history.back()}
            cw-id="link-owner-bar-back"
          >
            <span className="cw-hidden xs:cw-inline">Back</span>
          </Button>
        )}
        <Button
          variant="text"
          sentiment="positive"
          onClick={onClickMyLinks}
          cw-id="link-owner-bar-my-links"
        >
          <span className="cw-hidden xs:cw-inline">My Links</span>
        </Button>
        <div className="cw-flex-1" />
        <Button
          variant="text"
          sentiment="positive"
          startIcon={Edit}
          onClick={onEdit}
          cw-id="link-owner-bar-edit"
        >
          <span className="cw-hidden xs:cw-inline">Edit link</span>
        </Button>
        <Button
          startIcon={copied ? Check : Link}
          sentiment="positive"
          onClick={onCopyLink}
          cw-id="link-owner-bar-copy"
        >
          {copied ? "Copied" : "Copy link"}
        </Button>
      </div>
    );
  } else {
    return (
      <div className="cw-flex cw-bg-neutral cw-justify-center xs:cw-justify-end cw-items-center cw-gap-2 cw-h-12 cw-px-3">
        <span className="cw-caption xs:cw-body-base cw-text-accent">Powered by</span>
        <a
          className="cw-leading-none"
          href="https://www.getclockwise.com/?utm_medium=clockwise_links&utm_source=referral&utm_campaign=links_availability_page"
          target="_blank"
          rel="noreferrer"
        >
          <ClockwiseLogoFull className="cw-h-4 xs:cw-h-6" />
        </a>
      </div>
    );
  }
}
