import { graphql } from "react-relay";

export const mixedCheckoutFormFragments = {
  viewer: graphql`
    fragment MixedCheckoutForm_viewer on Viewer {
      id
      ...OrgUserSelectForm_viewer
    }
  `,
  org: graphql`
    fragment MixedCheckoutForm_org on Org {
      ...OrgUserSelectForm_org
      ...TeamJoinCreateDialogRenderer_org
      id
      currentOrgPersons {
        __typename
        ... on OrgPersonList {
          list {
            __typename
            id
            primaryCalendarId
            isYou
            userId
            personId
            profile {
              givenName
              familyName
              externalImageUrl
            }
          }
        }
        ... on GraphEntityError {
          statusCode
          message
        }
      }
      id
      name
      experiments
      primaryOrgEmail
      primaryOrgCalendar
      primaryTeam {
        __typename
        ... on Team {
          teamId
          teamName
        }
        ... on GraphEntityError {
          statusCode
          message
        }
      }
      userTeams {
        __typename
        ... on TeamList {
          list {
            teamId
          }
        }
        ... on GraphEntityError {
          statusCode
          message
        }
      }

      primaryTeam {
        __typename
        ... on Team {
          teamId
        }
        ... on GraphEntityError {
          statusCode
          message
        }
      }

      billingGroups {
        ... on BillingGroupList {
          list {
            id
            name
            syncSources {
              type
              value
            }
            subscription {
              __typename
              ... on SubscriptionDetails {
                subscription {
                  id
                  productType
                }
              }
              ... on GraphEntityError {
                message
                statusCode
              }
            }
          }
        }
        ... on GraphEntityError {
          message
          statusCode
        }
      }
    }
  `,
  team: graphql`
    fragment MixedCheckoutForm_team on Team {
      teamName
      teamId
      teamMembers {
        person {
          personId
          profile {
            givenName
            familyName
          }
        }
      }
      invitedMembers {
        person {
          personId
          profile {
            givenName
            familyName
          }
        }
      }
    }
  `,
};

export const query = graphql`
  query MixedCheckoutFormQuery($orgRelayId: ID!) {
    viewer: viewer {
      ...MixedCheckoutForm_viewer
    }
    org: node(id: $orgRelayId) {
      ... on Org {
        ...MixedCheckoutForm_org
        id
        userTeams {
          __typename
          ... on TeamList {
            list {
              teamId
              ...MixedCheckoutForm_team
            }
          }
          ... on GraphEntityError {
            statusCode
            message
          }
        }
      }
    }
  }
`;
