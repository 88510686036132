/**
 * Represents the minimal Moment interface.
 */
interface IMoment {
  minutes(): number;
  format(formatString: string): string;
}

/**
 * Produces a string from a time which will only show minutes when minutes are not 0.
 *
 * @param time
 * @param showPeriod
 */
export function maybeShowMinutes(time: IMoment, showPeriod = false) {
  return (
    (time.minutes() !== 0 ? time.format("h:mm") : time.format("h")) +
    (showPeriod ? time.format("a") : "")
  );
}

/**
 * Produces a time range string utilizing maybeShowMinutes.
 *
 * @param start
 * @param end
 */
export function prettyTimeRange(start: IMoment, end: IMoment) {
  return `${maybeShowMinutes(start)} – ${maybeShowMinutes(end, true)}`;
}

export function getDaySuffix(day: number): string {
  if (day > 3 && day < 21) {
    return "th";
  } else {
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }
}
