import { useQuery } from "@apollo/client";
import { useWindowFocus } from "@clockwise/web-commons/src/util/react.util";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { GQLProposal } from "../../chat/ai-chat/utils/types";
import usePlannerMetaData from "../../web-app-calendar/hooks/usePlannerMetaData";
import { ShareableProposalByIdDocument } from "../apollo/__generated__/ShareableProposalById.generated";

export type SharedProposalContextValue = {
  ownerCalendarId: string | null;
  hasEditPermission: boolean;
  proposal: GQLProposal | null;
  proposalLoading: boolean;
  errorMessage: string | null;
  viewerIsProposalOwner: boolean;
  setUpdatedProposal: (proposal: GQLProposal | null) => void;
};

export const emptySharedProposalValue: SharedProposalContextValue = {
  ownerCalendarId: null,
  hasEditPermission: false,
  proposal: null,
  proposalLoading: false,
  errorMessage: null,
  viewerIsProposalOwner: false,
  setUpdatedProposal: () => {},
};

export const useSharedProposalContext = () => {
  const context = React.useContext(SharedProposalContext);
  if (!context) {
    throw new Error("useSharedProposalContext must be used within a SharedProposalContext");
  }
  return context;
};

export const SharedProposalContext = React.createContext<SharedProposalContextValue>(
  emptySharedProposalValue,
);

type ProviderProps = {
  children: ReactNode;
  proposalId: string;
};

// Default poll interval is 5 minutes
const DEFAULT_POLL_INTERVAL = 5 * 60 * 1000;

export const SharedProposalProvider = ({ children, proposalId }: ProviderProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updatedProposal, setUpdatedProposal] = useState<GQLProposal | null>(null);
  const [viewerIsProposalOwner, setViewerIsProposalOwner] = useState<boolean>(false);
  const { primaryCalendarId } = usePlannerMetaData();

  const { data, loading, refetch, startPolling, stopPolling } = useQuery(
    ShareableProposalByIdDocument,
    {
      variables: { proposalId: proposalId },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    },
  );
  const isWindowFocused = useWindowFocus();
  const responseData = data?.viewer.user?.fetchSharedProposal || null;
  const proposal = (updatedProposal || responseData?.proposal) ?? null;
  const ownerCalendarId = responseData?.ownerCalendarId ?? null;
  const hasEditPermission = (responseData?.hasEditPermission as boolean) ?? true;

  useEffect(() => {
    if (isWindowFocused) {
      void refetch();
      startPolling(DEFAULT_POLL_INTERVAL);
    } else {
      stopPolling();
    }
  }, [isWindowFocused, refetch, startPolling, stopPolling]);

  useEffect(() => {
    if (proposal) {
      setViewerIsProposalOwner(primaryCalendarId === ownerCalendarId);
    }
  }, [proposal, primaryCalendarId, ownerCalendarId]);

  const contextValue = useMemo(
    () => ({
      ownerCalendarId,
      hasEditPermission,
      proposal,
      viewerIsProposalOwner,
      proposalLoading: loading,
      errorMessage,
      setUpdatedProposal,
    }),
    [
      ownerCalendarId,
      hasEditPermission,
      proposal,
      loading,
      errorMessage,
      viewerIsProposalOwner,
      setUpdatedProposal,
    ],
  );

  return (
    <SharedProposalContext.Provider value={contextValue}>{children}</SharedProposalContext.Provider>
  );
};
