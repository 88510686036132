import { googleAuthNonce, isLocalStorageAvailable } from "#webapp/src/state/local-storage";
import { generateNonce } from "#webapp/src/util/third-party-oauth.util";
import { getSiteUrl } from "@clockwise/client-commons/src/config/api";
import { getDevenv } from "@clockwise/client-commons/src/config/devenv";
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { paths } from "@clockwise/client-commons/src/constants/site";
import { makeOAuthHook } from "./auth.util";

export const canAuth = () => {
  // Since our OAuth flows require validating a nonce from separate windows, local storage is a requirement
  return isLocalStorageAvailable();
};

const getOAuthClientId = () => {
  let clientId;
  switch (getEnvironment()) {
    case "staging":
      clientId = "744331920389-v2aaelb6eoirta1hqmeglpsu2lbrithp.apps.googleusercontent.com";
      break;
    case "preprod":
    case "production":
      clientId = "813252810479-k5kd9g4f569a3efhdptsdb70vdff4oda.apps.googleusercontent.com";
      break;
    default:
      clientId = "550663439598-uphe42i7mu6pms7vit5hi4ivud8o1cgq.apps.googleusercontent.com";
      break;
  }
  return clientId;
};

const getAuthRedirectUri = () => {
  const origin = getDevenv() ? "https://auth.dev.getclockwise.com" : getSiteUrl();
  return `${origin}${paths.googleAuthRedirect}`;
};

const getGoogleOAuthUrl = (scopes: string[]) => {
  const baseUrl = "https://accounts.google.com/o/oauth2/v2/auth";
  const redirectUri = getAuthRedirectUri();

  // Generate nonce for request and store for later verification
  const nonce = generateNonce();
  googleAuthNonce.set(nonce);

  const state = {
    origin: window.location.origin,
    nonce,
  };

  const params = new URLSearchParams();
  params.set("client_id", getOAuthClientId());
  params.set("response_type", "code");
  params.set("redirect_uri", redirectUri);
  params.set("access_type", "offline");
  params.set("scope", scopes.join(" "));
  params.set("state", JSON.stringify(state));
  params.set("prompt", "consent");
  // params.set("enable_granular_consent", "true");

  return `${baseUrl}?${params.toString()}`;
};

export const useGoogleOAuth = makeOAuthHook(getGoogleOAuthUrl);
