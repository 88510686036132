export enum ReschedulerCameFrom {
  Suggestion = "Rescheduler via Suggestion",
}

export interface IReschedulerAction {
  type: string;
  data?: {
    cameFrom?: ReschedulerCameFrom;
  };
}

export enum ReschedulerAction {
  HighlightBestOption = "RESCHEDULER_HighlightBestOption",
  ResetState = "RESCHEDULER_ResetState",
}

export const highlighBestOption = (cameFrom?: ReschedulerCameFrom) => ({
  type: ReschedulerAction.HighlightBestOption,
  data: { cameFrom },
});

export const resetState = () => ({
  type: ReschedulerAction.ResetState,
});
