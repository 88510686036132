// schema
import * as ISchema from "#webapp/src/__schema__";
import { EcosystemEnum, UrnType, UserStatusEnum } from "@clockwise/schema";

import * as Sentry from "@sentry/browser";
import { Store } from "redux";

import { paths } from "#webapp/src/constants/site.constant";
import { updateAuthed } from "#webapp/src/state/actions/auth.actions";
import { setMenuDrawerOpen } from "#webapp/src/state/actions/chrome-extension.actions";
import {
  analyticsUser,
  cwUser,
  jwt,
  orgPrimaryEmail,
  sudoJwt,
  xsrf,
} from "#webapp/src/state/local-storage";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
import { getReduxStore } from "#webapp/src/state/redux-store";
import { TrackingEvents, alias, identify, track } from "#webapp/src/util/analytics.util";
import { getDomainOffEmail } from "#webapp/src/util/email.util";
import { fromGlobalId } from "#webapp/src/util/graphql.util";
import { isValidJson } from "#webapp/src/util/json.util";
import { windowLocation } from "#webapp/src/util/location.util";
import { logger } from "#webapp/src/util/logger.util";
import {
  Edges,
  UserWithEmail,
  UserWithOrgs,
  getCurrentOrg,
  getCurrentOrgPrimaryEmailOrBestEffort,
} from "#webapp/src/util/org.util";
import { PostMessageManager } from "#webapp/src/util/post-message.util";
import { getApiUrl } from "@clockwise/client-commons/src/config/api";
import { getAuthedRequestInitForFetch } from "@clockwise/web-commons/src/util/fetch.util";
import { getPreCookie } from "@clockwise/web-commons/src/util/pre-cookie.util";
import { ZonedMoment } from "@clockwise/web-commons/src/util/time-zone.util";
import { sentryTransport } from "./logger.util";

import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { isImpersonated, parseJwt } from "@clockwise/client-commons/src/util/jwt";
import { appPaths, webappPathRoot } from "@clockwise/web-commons/src/constants/route.constants";
import {
  TrackingFunnel,
  getStepBasedOnTrackingFunnel,
  getStoredAuthTrackingFunnel,
} from "@clockwise/web-commons/src/util/analytics.util";
import { cwEcosystem, userDeactivated } from "@clockwise/web-commons/src/util/local-storage";
import { compact } from "lodash";
import { NavigateFunction } from "react-router-dom";
import {
  getNuxStatesFromFlowStates,
  getShouldRenderOnboarding,
} from "../hooks/useOnboarding/useOnboarding";

const getAuthOptionFromEcosystem = (ecosystem: EcosystemEnum) => {
  switch (ecosystem) {
    case EcosystemEnum.Google:
      return "google";
    case EcosystemEnum.Microsoft:
      return "microsoft";
    default:
      return "unknown"; // If ever "unknown", we should investigate why (or we added a new ecosystem).
  }
};

const isSudoDomain = (domain: string) => {
  const sudoDomains = ["getclockwise.com", "clockwisehq.onmicrosoft.com"];
  const env = getEnvironment();
  if (env === "development") {
    sudoDomains.push("wisedevr.com");
    sudoDomains.push("wisedevr.onmicrosoft.com");
  } else if (env === "staging") {
    sudoDomains.push("wisewidgetshq.com");
    sudoDomains.push("yggt0.onmicrosoft.com");
    sudoDomains.push("wisedevr.com");
    sudoDomains.push("wisedevr.onmicrosoft.com");
  }
  return sudoDomains.includes(domain);
};

const EXPERIMENT_DOMAINS = [
  // GSuite domains
  "getclockwise.com",
  "wisewidgetshq.com",
  "wisedevr.com",
  "getroofhorse.com",
  "getchurro.co",
  // M365 domains
  "clockwisehq.onmicrosoft.com",
  "yggt0.onmicrosoft.com",
  "wisedevr.onmicrosoft.com",
  "getroofhorse.onmicrosoft.com",
  "churroco.onmicrosoft.com",
  // Testlio domains
  "prodmanual1cw.onmicrosoft.com",
  "WiseDevRMC.onmicrosoft.com",
  "prod-manual-1-cw.com",
];
const experimentEmailDenyList = ["apple-tester@wisedevr.com"];

////////////////////////
// LOGIN
///////////////////////

interface UserWithTraits extends UserWithEmail {
  id: string;
  status: UserStatusEnum | null;
  givenName: string | null;
  familyName: string | null;
  createdTime: number | null;
}

function getTraitsFromUser(user?: UserWithTraits) {
  const { id: userId } = (user && fromGlobalId(user.id)) || {};
  const primaryEmail = (user && getCurrentOrgPrimaryEmailOrBestEffort(user)) || undefined;

  return {
    id: userId,
    email: primaryEmail,
    emails: user?.emails,
    status: user?.status,
    givenName: user?.givenName,
    familyName: user?.familyName,
    displayName: user && `${user.givenName} ${user.familyName}`,
    name: user && `${user.givenName} ${user.familyName}`,
    created: user?.createdTime ? new ZonedMoment(user.createdTime) : undefined,
  };
}

interface AuthedViewer {
  user: AuthedUser | null;
}

interface AuthedUser extends UserWithTraits, UserWithOrgs {
  orgs: Edges<Org>;
  flowStates: any;
}

type Org = {
  id: string;
  workingGroups: Edges<{
    id: string;
  }>;
  primaryOrgEmail: string;
  flowStates: any;
};

const getOnboardingStateFromFlowStates = (viewer: AuthedViewer) => {
  const org = getCurrentOrg(viewer);
  const userFlowStates = viewer.user?.flowStates?.edges ?? [];
  const orgFlowStates = org?.flowStates?.edges ?? [];

  const { webOnboardingState, deprecatedOnboardingState } = getNuxStatesFromFlowStates(
    userFlowStates,
    orgFlowStates,
  );
  return {
    shouldRenderOnboarding: getShouldRenderOnboarding(
      webOnboardingState,
      deprecatedOnboardingState,
    ),
  };
};

export function doUserLogin(
  viewer: AuthedViewer,
  userOperation: ISchema.RefreshTokenResultOperationEnum | null,
  orgOperation: ISchema.OrganizationOperationResultEnum | null,
  ecosystem: ISchema.EcosystemEnum,
  setShouldRenderOnboarding: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction,
  isChromeExtension: boolean,
  redirect?: string,
  preventLoginRedirect?: boolean,
) {
  const user = viewer.user;
  if (!user) {
    logger.error("Expected user in doUserLogin");
    return;
  }
  if (!user.createdTime) {
    logger.error("Expected user.createdTime in doUserLogin");
  }

  // gets the default org, or sets it
  const org = getCurrentOrg(viewer);
  if (!org) {
    logger.error("Expected org in doUserLogin");
    return;
  }
  if (!org.primaryOrgEmail) {
    logger.error("Expected org.primaryOrgEmail in doUserLogin");
    return;
  }
  orgPrimaryEmail.set(org.primaryOrgEmail);
  cwUser.set("true");
  cwEcosystem.set(ecosystem);

  getReduxStore().dispatch(updateAuthed(true, user.createdTime));
  getReduxStore().dispatch(setMenuDrawerOpen(false));

  const { id: userId } = fromGlobalId(user.id);
  const primaryEmail = getCurrentOrgPrimaryEmailOrBestEffort(user);
  analyticsUser.set(`User::${userId}`);

  // sentry
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: user.id,
      email: `${primaryEmail}`,
      username: `${user.givenName} ${user.familyName}`,
    });
  });

  // possibly send chrome extension our authed emails
  PostMessageManager.sendCalendarIds(user.emails);

  // analytics
  alias();
  identify(getTraitsFromUser(user));
  track(TrackingEvents.AUTH.LOGIN, {
    email: primaryEmail,
    emails: user.emails,
    familyName: user.familyName,
    givenName: user.givenName,
    id: userId,
    status: user.status,
    // Add operation property: signup, login
    userCreated: userOperation === ISchema.RefreshTokenResultOperationEnum.Create,
    orgCreated: orgOperation === ISchema.OrganizationOperationResultEnum.Create,
    latestUtms: getPreCookie().latestUtms,
  });

  const tracking_funnel = getStoredAuthTrackingFunnel() as TrackingFunnel;
  const tracking_step_login = getStepBasedOnTrackingFunnel(tracking_funnel, "logged_in");
  const tracking_step_register = getStepBasedOnTrackingFunnel(tracking_funnel, "signed_up");
  const tracking_step_org_created = getStepBasedOnTrackingFunnel(tracking_funnel, "org_created");
  // google tag manager
  const dataLayer = window && (window as any).dataLayer;
  if (dataLayer) {
    dataLayer.push({ event: "userLoginComplete" });
    // new GTM event for user login, dont delete one above until we confirm everything works correctly
    dataLayer.push({
      event: "funnel_step",
      universal_step: "LOGGED_IN",
      funnel_name: tracking_funnel,
      step_name: "logged_in",
      step_number: tracking_step_login,
      user_id: userId,
      user_email: primaryEmail,
      funnel_option: getAuthOptionFromEcosystem(ecosystem),
    });
  }

  if (userOperation === ISchema.RefreshTokenResultOperationEnum.Create) {
    track(TrackingEvents.AUTH.USER_SIGNED_UP, {
      user_id: userId,
      user_email: primaryEmail,
      funnel_name: tracking_funnel,
      funnel_option: getAuthOptionFromEcosystem(ecosystem),
    });
  }
  if (dataLayer && userOperation === ISchema.RefreshTokenResultOperationEnum.Create) {
    dataLayer.push({ event: "clockwiseUserCreated" });
    // new GTM event for user login, dont delete one above until we confirm everything works correctly
    dataLayer.push({
      event: "funnel_step",
      universal_step: "REGISTERED",
      funnel_name: tracking_funnel,
      step_name: "signed_up",
      step_number: tracking_step_register,
      user_id: userId,
      user_email: primaryEmail,
      funnel_option: getAuthOptionFromEcosystem(ecosystem),
    });
  }

  if (dataLayer && orgOperation === ISchema.OrganizationOperationResultEnum.Create) {
    dataLayer.push({ event: "clockwiseOrgCreated" });
    // new GTM event for user login, dont delete one above until we confirm everything works correctly
    dataLayer.push({
      event: "funnel_step",
      universal_step: "ORG_CREATED",
      funnel_name: tracking_funnel,
      step_name: "org_created",
      step_number: tracking_step_org_created,
      user_id: userId,
      user_email: primaryEmail,
      funnel_option: getAuthOptionFromEcosystem(ecosystem),
    });
  }

  userDeactivated.set(false);

  // redirect after auth
  const { shouldRenderOnboarding } = getOnboardingStateFromFlowStates(viewer);
  setShouldRenderOnboarding(shouldRenderOnboarding);
  routeAfterAuthComplete(
    navigate,
    isChromeExtension,
    shouldRenderOnboarding,
    redirect,
    preventLoginRedirect,
  );
}

export function doSudoLogin() {
  // store our new jwt, do a full logout and then set the jwt again
  const tempJwt = jwt.get();
  const tempXsrf = xsrf.get();

  doNormalLogout();

  if (tempJwt && tempXsrf) {
    jwt.set(tempJwt);
    xsrf.set(tempXsrf);

    getReduxStore().dispatch(updateAuthed(true, null));
  } else {
    logger.warn("Failed to complete doSudoLogin because jwt and/or xsrf were unset");
  }
}

const routeAfterAuthComplete = (
  navigate: NavigateFunction,
  isChromeExtension: boolean,
  shouldRenderOnboarding: boolean,
  redirect?: string,
  preventLoginRedirect?: boolean,
) => {
  if (preventLoginRedirect) {
    return;
  }
  if (isChromeExtension) {
    navigate("/chrome.html", { replace: true });
  } else if (shouldRenderOnboarding) {
    navigate(appPaths.onboarding, { replace: true });
  } else if (redirect) {
    navigate(`/${redirect}`, { replace: true });
  } else {
    navigate("/app", { replace: true });
  }
};

////////////////////////
// LOGOUT
///////////////////////

export function doUserLogout() {
  if (isImpersonated(jwt.get())) {
    doSudoLogout();
  } else {
    doNormalLogout();
  }
}

/**
 * This is a special case logout flow used exclusively by internal users who
 * are impersonating another user via sudo
 *
 * Replace impersonated auth tokens with the impersonator's original tokens and
 * reload/redirect the page/iframe
 */
function doSudoLogout() {
  // Restore original jwt, if available
  const originalJwt = sudoJwt.get();
  originalJwt ? jwt.set(originalJwt) : jwt.remove();
  sudoJwt.remove();
  analyticsUser.remove();

  // sentry
  Sentry.configureScope((scope) => scope.clear());

  // app redirect
  if (window.location.pathname.startsWith(paths.logout)) {
    windowLocation.assign("AuthUtilLogoutSwitchUserRedirect", "/app");
  } else {
    windowLocation.reload("AuthUtilLogoutSwitchUser");
  }
}

/**
 * This is the standard logout flow used by most users
 *
 * Remove auth tokens and redirect if needed
 */
function doNormalLogout() {
  // analytics first
  track(TrackingEvents.AUTH.LOGOUT);

  getReduxStore().dispatch(updateAuthed(false, null));
  jwt.remove();
  xsrf.remove();
  analyticsUser.remove();
  orgPrimaryEmail.remove();

  // sentry
  Sentry.configureScope((scope) => scope.clear());

  // app redirect
  if (
    window.location.pathname.startsWith(paths.webApp) ||
    window.location.pathname.startsWith(webappPathRoot) // DevNote: Cleanup Asana task: https://app.asana.com/0/0/1203591843615223/f
  ) {
    if (cwEcosystem.get() === "Microsoft") {
      void windowLocation.assign("AuthUtilLogout", paths.m365SignIn);
    } else {
      void windowLocation.assign("AuthUtilLogout", paths.login);
    }
  }
}

const isHeadersObject = (headers?: HeadersInit): headers is Headers => headers instanceof Headers;

////////////////////////
// SESSION HANDLING
///////////////////////
export async function getSession(store: Store<IReduxState>) {
  const request = getAuthedRequestInitForFetch();
  if (typeof fetch === "undefined") {
    return;
  }
  try {
    const response = await fetch(`${getApiUrl()}/session`, request);

    if (!response.ok) {
      // e.g non-2xx HTTP response
      if (isHeadersObject(request.headers)) {
        sentryTransport.addTag("txnId", request.headers.get("txnId") ?? "undefined");
        sentryTransport.addTag("clientId", request.headers.get("clientId") ?? "undefined");
      }
      logger.error("Error fetching the user session", new Error(response.statusText));
    }
    const text = await response.text();
    processSessionResponse(text, store);
  } catch (error) {
    /**
     * Errors are only thrown by `fetch` if there is an network error, most of which are
     * user-caused, like navigating to a new page while the request is in flight.
     *
     * Because of that, we'll only report these errors in non-prod environments, in case there is
     * an issue for the developer at that point.
     */
    if (getEnvironment() !== "production") {
      logger.error("getSession threw an error", error);
    }
  }
}

type Urn = {
  id: string;
  scope?: Urn | null;
  type: UrnType;
  value: string;
};

type User = {
  id: string;
  createdTime: number | null;
  emails: string[];
  externalImageUrl: string | null;
  familyName: string | null;
  givenName: string | null;
  members: Urn[];
  status: UserStatusEnum;
};

/**
 * Response from https://app.getclockwise.com/session, see:
 * https://github.com/clockwisehq/webserver/blob/main/src/server.ts#L576
 */
type SessionResponse = {
  authed: boolean;
  insightsAuthed: boolean;
  user: User | null;
  ecosystem: EcosystemEnum;
};

export function processSessionResponse(text: string, store: Store<IReduxState>) {
  // store auth state in redux (needed for route processing)
  const parsedText: SessionResponse | null = (isValidJson(text) && JSON.parse(text)) || null;
  const authed = parsedText?.authed ?? false;
  const user = parsedText?.user ?? null;
  const ecosystem = parsedText?.ecosystem || EcosystemEnum.NotApplicable;
  const userCreatedTime = user?.createdTime ?? null;
  const username = compact([user?.givenName, user?.familyName]).join(" ");
  store.dispatch(updateAuthed(authed, userCreatedTime));

  // analytics and sentry and set ecosystem
  if (user) {
    cwEcosystem.set(ecosystem);
    const primaryEmail = getCurrentOrgPrimaryEmailOrBestEffort(user) ?? "";
    if (primaryEmail) {
      orgPrimaryEmail.set(primaryEmail);
    }
    analyticsUser.set(`User::${user.id}`);

    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user.id,
        email: `${primaryEmail}`,
        username,
      });
    });

    // possibly send chrome extension our authed emails
    PostMessageManager.sendCalendarIds(user.emails);

    logger.info("Finishing reauthentication", {
      id: user.id,
      email: primaryEmail || "",
      emails: JSON.stringify(user.emails) || "[]",
    });

    alias();
    identify(getTraitsFromUser(user));
  }
}

// //////////////////////////////
// // ROUTER ONENTER CALLBACKS
// ////////////////////////////

export const requireLogout = () => {
  if (window.fetch) {
    if (!isImpersonated(jwt.get())) {
      void window.fetch("/clear", { credentials: "include" });
    }
    doUserLogout();
    return "/login";
  } else {
    logger.error("failed to fetch /clear");
    return webappPathRoot;
  }
};

export const createLoginRedirect = (
  location: { pathname: string; search: string },
  path = "/login",
) => {
  // cleanup
  if (jwt.get() || xsrf.get()) {
    jwt.remove();
    xsrf.remove();
  }

  if (location.pathname !== "/") {
    let nextPath = location.pathname;
    nextPath = nextPath.startsWith("/") ? nextPath.substr(1) : nextPath;
    path += `/${encodeURIComponent(nextPath)}`;
  }
  if (location.search) {
    path += location.search;
  }
  return path;
};

export function isAuthed() {
  const reduxState = getReduxStore().getState();
  return reduxState.auth.authed;
}

export function isAuthedSudo() {
  const userJwt = parseJwt(jwt.get());
  return (
    isAuthed() &&
    (userJwt?.emails ?? []).some((email) => isSudoDomain(getDomainOffEmail(email) ?? ""))
  );
}

export function canAccessExperiments() {
  const userJwt = parseJwt(jwt.get());
  // services jwt encoding, impropertly encodes apostrophes. keep settings from blowing up.
  try {
    return (
      isAuthed() &&
      (userJwt?.emails ?? []).some((email) => {
        const domain = getDomainOffEmail(email);
        if (experimentEmailDenyList.indexOf(email) !== -1) {
          return false;
        }
        return (domain && EXPERIMENT_DOMAINS.indexOf(domain) !== -1) || false;
      })
    );
  } catch {
    return false;
  }
}

export function canAccessSudo() {
  if (!isAuthed()) {
    return false;
  }

  if (!isAuthedSudo()) {
    return false;
  }

  const userJwt = parseJwt(jwt.get());

  const hasWisdevrDomain = userJwt?.emails?.some((email) => {
    const domain = getDomainOffEmail(email);
    return domain === "wisedevr.com";
  });

  return !hasWisdevrDomain;
}
