import { fg_onEmphasis } from "@clockwise/design-system/tokens";
import { SubscriptionInterval } from "@clockwise/schema";
import classNames from "classnames";
import { Clock } from "phosphor-react";
import pluralize from "pluralize";
import React from "react";
import { ITierContent } from "../../../constants/org-pricing.constants";
import { TrialDuration } from "../../../util/trial-constants";
import UpgradeButton from "../cta-buttons/UpgradeButton";
import { PlanTypes } from "../types";

import { Divider } from "@clockwise/design-system";
import {
  FeatureBullet,
  TierButton,
  TierCost,
  TierDescription,
  TierFeatureHeader,
  TierTitle,
} from "./components";

type ProTierHeaderProps = {
  billingRecurrence: SubscriptionInterval;
  daysLeftOnTrial: number;
  isUserOnTrial: boolean;
  onSelect: () => void;
  primarySubscriptionPlan: PlanTypes;
  tier: ITierContent;
  isFullWidth: boolean;
};

const ProTierHeader = ({
  billingRecurrence,
  daysLeftOnTrial,
  isUserOnTrial,
  onSelect,
  primarySubscriptionPlan,
  tier,
  isFullWidth,
}: ProTierHeaderProps) => {
  const isPrimarySubscriptionFreePlan = primarySubscriptionPlan === "Free";

  const costForRecurrence =
    billingRecurrence === SubscriptionInterval.Yearly
      ? tier.planPricing.yearlyCost
      : tier.planPricing.monthlyCost;

  const renderProTierBanner = () => {
    if (isUserOnTrial) {
      const daysLeft = daysLeftOnTrial;
      if (daysLeft && daysLeft >= 0) {
        return (
          <div
            className={classNames(
              "cw-absolute cw-top-0 cw-left-0 cw-right-0 cw-h-7 cw-w-full cw-rounded-t",
              "cw-flex cw-items-center cw-justify-center cw-gap-1",
              "cw-caption cw-text-onEmphasis cw-font-bold",

              {
                "cw-bg-warning-emphasis": daysLeft <= TrialDuration.lastChanceNotification,
                "cw-bg-brand-emphasis": daysLeft > TrialDuration.lastChanceNotification,
              },
            )}
          >
            <Clock color={fg_onEmphasis} size={16} />
            {`${pluralize("day", daysLeft, true)} left in Free trial`}
          </div>
        );
      }
    }

    return (
      <div
        className={classNames(
          "cw-absolute cw-top-0 cw-left-0 cw-right-0 cw-h-7 cw-w-full cw-rounded-t",
          "cw-flex cw-items-center cw-justify-center cw-gap-1",
          "cw-caption cw-text-onEmphasis cw-font-bold cw-bg-brand-emphasis",
        )}
      >
        {isPrimarySubscriptionFreePlan ? "Recommended" : "Popular"}
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "cw-border-solid cw-border cw-border-default-emphasis md:cw-h-[585px] cw-h-[525px]",
        "cw-flex-1 cw-flex cw-flex-col cw-items-center cw-relative",
        "cw-py-8 cw-px-4 cw-my-4 cw-rounded-md",
        "cw-h-[500px] md:cw-h-[585px]",
        {
          "lg:cw-max-w-[275px] lg:cw-min-w-[225px] lg:cw-my-0": isFullWidth,
          "xl:cw-max-w-[275px] xl:cw-min-w-[225px] xl:cw-my-0 cw-w-full": !isFullWidth,
        },
      )}
    >
      {renderProTierBanner()}
      <div className="cw-pt-6">
        <TierTitle text={"Teams"} />
      </div>
      <TierCost cost={costForRecurrence} />
      <TierDescription> {tier.costUnit}</TierDescription>
      <TierButton>
        <UpgradeButton
          isUserOnTrial={isUserOnTrial}
          primarySubscriptionPlan={primarySubscriptionPlan}
          onClick={onSelect}
        />
      </TierButton>

      <TierDescription>
        For teams looking to automatically create and protect Focus Time
      </TierDescription>

      <Divider className="cw-flex-shrink-0 cw-w-full" />
      <TierFeatureHeader text={tier.featureHeader} />

      <FeatureBullet>
        Optimize meeting times to free up uninterrupted blocks of Focus Time
      </FeatureBullet>
      <FeatureBullet>
        Protect your deep work time with auto-scheduled Focus Time holds
      </FeatureBullet>
      <FeatureBullet>
        Schedule seamlessly outside your organization with Scheduling Links
      </FeatureBullet>
    </div>
  );
};

export default ProTierHeader;
