import { graphql } from "react-relay";

export const upsertLunchMutation = graphql`
  mutation UpsertLunchMutation($input: UpsertLunchMutationInput!) {
    upsertLunch(input: $input) {
      org {
        id
        userGoals(first: 1000000) {
          edges {
            node {
              id
              goalId
              targetUrn {
                id
                type
                value
              }
              otherUrns {
                id
                type
                value
              }
              name
              isDeleted
              options {
                syncToGoogle
                specificOptions {
                  ... on GoalWithinDayOptions {
                    minTimeSlot
                    maxTimeSlot
                    minimumDuration
                    idealDuration
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
