import * as ISchema from "#webapp/src/__schema__";
import { PlannerViewActions } from "../actions/planner-highlight.actions";

export interface IPlannerHighlightState {
  slot: ISchema.ISchedulingSlot | undefined | null;
  meetingTitle: string | undefined | null;
}

export interface IPlannerHighlightData {
  slot: ISchema.ISchedulingSlot | null;
  meetingTitle: string | null;
}

interface IPlannerHighlightAction {
  type: PlannerViewActions;
  data: IPlannerHighlightData;
}

export const initialPlannerHighlightState: IPlannerHighlightState = {
  slot: undefined,
  meetingTitle: undefined,
};

export function plannerHighlightReducer(
  state: IPlannerHighlightState = initialPlannerHighlightState,
  action: IPlannerHighlightAction,
) {
  switch (action.type) {
    case PlannerViewActions.SetHighlightedSlot:
      return { ...state, slot: action.data.slot };
    case PlannerViewActions.SetMeetingTitle:
      return { ...state, meetingTitle: action.data.meetingTitle };
    default:
      return state;
  }
}
