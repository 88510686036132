/**
 * @generated SignedSource<<96cf90516eaf5a194347f63316fd649e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UrnType = "Date" | "Email" | "Event" | "FlowState" | "Guest" | "Org" | "Phone" | "Services" | "Setting" | "Team" | "Teammate" | "TimeZone" | "User" | "WorkOsOrg" | "WorkingGroup" | "WorkingHours" | "%future added value";
export type RemoveGoalMutationInput = {
  clientMutationId?: string | null;
  goal: GoalInput;
  orgId: string;
};
export type GoalInput = {
  goalId: string;
  id: string;
  isDeleted: boolean;
  name: string;
  options?: GoalOptionsInput | null;
  otherUrns: ReadonlyArray<UrnInput>;
  targetUrn: UrnInput;
  valueUrn?: UrnInput | null;
};
export type GoalOptionsInput = {
  syncToGoogle: boolean;
};
export type UrnInput = {
  id?: string | null;
  scope?: UrnInput | null;
  type?: UrnType | null;
  value?: string | null;
};
export type RemoveGoalMutation$variables = {
  input: RemoveGoalMutationInput;
};
export type RemoveGoalMutation$data = {
  readonly removeGoal: {
    readonly goal: {
      readonly id: string;
      readonly goalId: string;
      readonly targetUrn: {
        readonly id: string;
        readonly type: UrnType | null;
        readonly value: string | null;
      };
      readonly otherUrns: ReadonlyArray<{
        readonly id: string;
        readonly type: UrnType | null;
        readonly value: string | null;
      }>;
      readonly name: string;
      readonly isDeleted: boolean;
      readonly options: {
        readonly syncToGoogle: boolean;
      } | null;
    } | null;
  } | null;
};
export type RemoveGoalMutation = {
  variables: RemoveGoalMutation$variables;
  response: RemoveGoalMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveGoalMutationPayload",
    "kind": "LinkedField",
    "name": "removeGoal",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Goal",
        "kind": "LinkedField",
        "name": "goal",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "goalId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Urn",
            "kind": "LinkedField",
            "name": "targetUrn",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Urn",
            "kind": "LinkedField",
            "name": "otherUrns",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDeleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GoalOptions",
            "kind": "LinkedField",
            "name": "options",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "syncToGoogle",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveGoalMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveGoalMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "2914cfccc617ae3ceb32a231ff880bbc",
    "id": null,
    "metadata": {},
    "name": "RemoveGoalMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveGoalMutation(\n  $input: RemoveGoalMutationInput!\n) {\n  removeGoal(input: $input) {\n    goal {\n      id\n      goalId\n      targetUrn {\n        id\n        type\n        value\n      }\n      otherUrns {\n        id\n        type\n        value\n      }\n      name\n      isDeleted\n      options {\n        syncToGoogle\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c633664a589608b18fe2e24e05fd291b";

export default node;
