export const colors = {
  green: {
    lightester: "#e9fbec",
    lightest: "#d3f7d9",
    lighter: "#88d7b7",
    standard: "#5abf96",
    darker: "#40a47c",
    darkest: "#1d855b",
    darkester: "#3e6958",
  },
  sky: {
    lightester: "#dbf8fd",
    lightest: "#b8f2fb",
    lighter: "#5fd1e3",
    standard: "#44c3d7",
    darker: "#23a7bc",
    darkest: "#2493a5",
  },
  blue: {
    lightester: "#e3f2ff",
    lightest: "#c8e6ff",
    lighter: "#7fb8e5",
    standard: "#5393c6",
    darker: "#447ca9",
  },
  dusk: {
    lightester: "#dff2ff",
    lightest: "#c1dffe",
    lighter: "#6fa4d9",
    standard: "#225588",
    darker: "#204c77",
  },
  purple: {
    lightesterest: "#fef2fb",
    lightest: "#f1cef2",
    lighter: "#c288c4",
    standard: "#ad6faf",
    darker: "#99569b",
  },
  pink: {
    lightest: "#fcceeb",
    lighter: "#e395c6",
    standard: "#d582b6",
    darker: "#b86298",
  },
  rose: {
    lightest: "#fcd6e4",
    lighter: "#e36791",
    standard: "#d94a7b",
    darker: "#bc3a66",
  },
  red: {
    lightester: "#fde6e6",
    lightest: "#fbcdcd",
    lighter: "#fb7d7d",
    standard: "#ed5b5b",
    darker: "#e30303", // brightest
    darkest: "#cf0000",
    darkester: "#a60000",
  },
  peach: {
    lightesterest: "#fef5f2",
    lightester: "#fdece6",
    lightest: "#fbdacd",
    lighter: "#fbaa8a",
    standard: "#e9926f",
    darker: "#cf7a58",
    darkest: "#a55332",
  },
  yellow: {
    lightest: "#fff4c1",
    lighter: "#f8dd64",
    standard: "#eece3f",
    darker: "#d0b020",
    darkest: "#97842f",
  },
  birch: {
    lightest: "#f3eee8",
    standard: "#c3b09c",
    darker: "#7f7163",
  },
  slate: {
    lightesterest: "#eff2f5",
    lightester: "#dfe5eb",
    lightest: "#bfccd8", // cloudyBlue
    lighter: "#96acc0", // blueGrey
    standard: "#566d7f", // slate
    darker: "#303f50", // darkGreyBlue
    darkest: "#0c1f2f", // darkerGreyBlue
  },
  grey: {
    lightester: "#f7f7f7", // palePaleGrey
    lightest: "#eef0f1", // paleGrey
    lighter: "#e0e0e0", // borderGrey
    standard: "#9b9b9b", // lightishGrey
    darker: "#747474",
    darkest: "#585858",
  },
  white: "#ffffff",
  black: "#000000",
};
