import { IOrg, ITeam, SubscriptionInterval } from "#webapp/src/__schema__";
import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { Select, SelectOption } from "@clockwise/design-system";
import { fg_destructive } from "@clockwise/design-system/tokens";
import { LargeControlGroup } from "@clockwise/web-commons/src/components/large-control-group";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { Address } from "@stripe/stripe-js";
import classNames from "classnames";
import React from "react";
import { LocalizedAddressForm } from "../../localized-address-form";
import { ISeatPlanData, SeatOptions } from "../MixedCheckoutForm";
import { ControlContent } from "./ControlContent";

// convert these to tokens
const stripeInputStylingBase = {
  style: {
    invalid: {
      color: fg_destructive,
      iconColor: fg_destructive,
    },
  },
};

const stripeInputBase = classNames(
  "cw-border cw-border-solid cw-border-default cw-rounded cw-mb-2 cw-px-2 cw-pt-3 cw-pb-2",
  "cw-h-10 cw-text-14 cw-font-normal placeholder:cw-text-subtle",
);

interface CheckoutFormProps {
  org: IOrg;
  team: ITeam | null;
  onOpenOrgUserSelectForm: () => void;
  setTeamModalOpen: (open: boolean) => void;
  setTeamModalAction: (action: "select" | "join") => void;
  onChangeSeatPlanType: (_index: number, control: ISeatPlanData) => void;
  seatOptions: SeatOptions;
  selectedBillingRecurrence: SubscriptionInterval;
  setSelectedBillingRecurrence: (recurrance: SubscriptionInterval) => void;
  onChangeBillingRecurrenceFromParent?: (billingRecurrence: SubscriptionInterval) => void;
  billingAddress: Address;
  setBillingAddress: (address: Address) => void;
}

export const CheckoutForm = ({
  org,
  team,
  onOpenOrgUserSelectForm,
  setTeamModalOpen,
  setTeamModalAction,
  onChangeSeatPlanType,
  seatOptions,
  selectedBillingRecurrence,
  setSelectedBillingRecurrence,
  onChangeBillingRecurrenceFromParent,
  billingAddress,
  setBillingAddress,
}: CheckoutFormProps) => {
  const onChangeBillingRecurrence = (value: SubscriptionInterval) => {
    const billingRecurrence = value;
    setSelectedBillingRecurrence(billingRecurrence);

    track(TrackingEvents.CHECKOUT.SELECT_BILLING_RECURRENCE, { billingRecurrence });

    if (onChangeBillingRecurrenceFromParent) {
      onChangeBillingRecurrenceFromParent(billingRecurrence);
    }
  };

  const onAddressChange = (address: Address) => {
    setBillingAddress(address);
  };

  const renderControlContent = (control: ISeatPlanData) => {
    return (
      <ControlContent
        control={control}
        org={org}
        team={team}
        onOpenOrgUserSelectForm={onOpenOrgUserSelectForm}
        setTeamModalOpen={setTeamModalOpen}
        setTeamModalAction={setTeamModalAction}
      />
    );
  };

  const getOptionsArray: ISeatPlanData[] = Object.keys(seatOptions).map(
    (key: keyof SeatOptions) => seatOptions[key],
  );

  return (
    <div className="cw-mr-20">
      <div className="cw-mb-5">
        <div className="cw-heading-lg cw-mb-3">You're upgrading</div>
        <div className="cw-mb-5">
          <LargeControlGroup
            controls={getOptionsArray}
            renderControlContent={renderControlContent}
            onChange={onChangeSeatPlanType}
            allowPointerEvents={false}
          />
        </div>
        <div className="cw-heading-lg cw-mb-2">Billing cadence </div>
        <Select
          size="large"
          value={selectedBillingRecurrence}
          onChange={onChangeBillingRecurrence}
          fullWidth
        >
          <SelectOption value={SubscriptionInterval.Yearly}> Annual plan </SelectOption>
          <SelectOption value={SubscriptionInterval.Monthly}> Monthly plan</SelectOption>
        </Select>
      </div>
      <div className="cw-mb-5">
        <div className="cw-heading-lg cw-mb-3">Payment method</div>
        <div className="fs-exclude">
          <CardNumberElement
            id="plan-management-card-number"
            options={{
              placeholder: "Card number",
              classes: {
                base: stripeInputBase,
              },
              ...stripeInputStylingBase,
            }}
          />
        </div>
        <div className="fs-exclude">
          <CardExpiryElement
            id="plan-management-card-expiry"
            options={{
              placeholder: "MM/YY",
              classes: {
                base: stripeInputBase,
              },
              ...stripeInputStylingBase,
            }}
          />
          <CardCvcElement
            id="plan-management-card-cvc"
            options={{
              placeholder: "CVC",
              classes: {
                base: stripeInputBase,
              },
              ...stripeInputStylingBase,
            }}
          />
        </div>
      </div>
      <div className="cw-mb-5">
        <div className="cw-heading-lg cw-mb-3">Billing details</div>
        <div className="fs-exclude">
          <LocalizedAddressForm onChange={onAddressChange} initialBillingAddress={billingAddress} />
        </div>
      </div>
    </div>
  );
};
