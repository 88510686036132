// schema
import * as ISchema from "#webapp/src/__schema__";

// util
import { AUTOPILOT_EMOJI } from "#webapp/src/constants/event.constant";
import { toGlobalIdForEventParent } from "#webapp/src/util/graphql.util";
import { warnOutsideTests } from "#webapp/src/util/helpers.util";
import { getCurrentOrg } from "#webapp/src/util/org.util";
import { niceNameFromCalendarUrnValue } from "@clockwise/client-commons/src/util/event";
import { UrnUtil } from "@clockwise/web-commons/src/util/urn";
// get the first event off an eventParent or the event matching the calendarId, if passed
import {
  niceNameArrayFromPersonsWithCarryover,
  niceNamesAsArrayWithCarryover,
} from "@clockwise/client-commons/src/util/event";
import { DayOffsetTimeSlot, PreEventFlexibility } from "@clockwise/schema";
import {
  defaultDayOnOffMap,
  getDaysOfWeekFromDayOnOffMap,
} from "@clockwise/web-commons/src/ui/working-hours";
import { EAutopilotWarning, EFlex } from "./event-tag.util";

import { isRoom } from "@clockwise/client-commons/src/util/room";
import { isEmailInDomains } from "./email.util";

export {
  getAttendeeName,
  getTimeAllocation,
  isClockwiseUnsyncedEvent,
  isFocusTimeSmartHold,
  isLinkedSmartHold,
  isLocalOnlySyncOverride,
  isLunchSmartHold,
  isPrepTimeSmartHold,
  isSmartHold,
  isTeamCalendarOriginEvent,
  isTeamCalendarSyncDisabled,
  isTeamCalendarSyncEvent,
  isTravelTimeSmartHold,
  niceAttendeeNamesWithCarryover,
  niceNameArrayFromPersonsWithCarryover,
  niceNameFromPerson,
  niceNamesAsArrayWithCarryover,
  niceNamesAsString,
  niceRooms,
} from "@clockwise/client-commons/src/util/event";

export interface FragmentedTimeRange {
  startMillis: number;
  endMillis: number;
}

export function isMovementPaused(event: ISchema.IEvent) {
  return !!event?.annotatedEvent?.orgTags?.find(
    (orgTag: any) => orgTag.tag === "MovementPaused" && orgTag.state.active,
  );
}

// create eventParentRelayId
// honestly, this should replace the function below, but I didn't want to change behavior for something so central
function baseCreateEventParentRelayId(orgId: string, externalEventId: string) {
  const eventParentUrn = UrnUtil.createEventParentUrn(externalEventId, orgId);
  const serializedEventParentUrn = UrnUtil.serialize(eventParentUrn);
  const eventParentRelayId = toGlobalIdForEventParent(serializedEventParentUrn);
  return eventParentRelayId;
}

// create eventParentRelayId
export function createEventParentRelayIdFromOrgRelayId(
  orgRelayId: string,
  externalEventId: string,
) {
  if (externalEventId && orgRelayId) {
    const orgId = atob(orgRelayId).split(":")[1];
    return baseCreateEventParentRelayId(orgId, externalEventId);
  }
  return null;
}

// create eventParentRelayId from Event
export function createEventParentRelayIdFromEvent(event: ISchema.IEvent) {
  if (!event || !event.eventKey || !event.eventKey.externalEventId || !event.eventKey.orgId) {
    warnOutsideTests(
      "no event, eventKey, eventKey.externalEventId, org eventKey.orgId; check your fragments!",
    );
    return ""; // if the above warning isn't fixed in dev, this could happen in prod... which is bad
  }
  const { orgId, externalEventId } = event.eventKey;
  return baseCreateEventParentRelayId(orgId, externalEventId);
}

// returns true if the event was synced from personal calendar
export function isEventSyncedFromPersonalCalendar(eventParent: ISchema.IEventParent) {
  if (!eventParent.externalEventId) {
    warnOutsideTests("no externalEventId on eventParent; check your fragments!");
    return;
  }

  // the externalEventId will be prefixed with 'sinced' if it was synced from personal calendar
  // note: 'sinced' is pronounced 'synced'
  return eventParent.externalEventId.indexOf("sinced") === 0;
}

export function niceNamesAsArray(nameList: string[], maxCharacters = 10000000) {
  return niceNamesAsArrayWithCarryover(nameList, maxCharacters).niceNameArray;
}

export function isNameInNiceNamesArray(potentialName: string) {
  return potentialName.indexOf(",") === -1 && potentialName.indexOf("and") === -1;
}

// nice names list from attendees and persons
export function niceNameArrayFromPersons(
  persons: ISchema.IPerson[],
  calIds?: string[],
  maxCharacters?: number,
  fullName = false,
  lastInitial = false,
  isLeading = false,
) {
  return niceNameArrayFromPersonsWithCarryover(
    persons,
    calIds,
    maxCharacters,
    fullName,
    lastInitial,
    isLeading,
  ).niceNameArray;
}

// nice names list from attendees and persons
export function niceNameStringFromPersons(
  persons: ISchema.IPerson[],
  calIds?: string[],
  maxCharacters?: number,
  fullName = false,
  lastInitial = false,
) {
  const niceNameArray = niceNameArrayFromPersons(
    persons,
    calIds,
    maxCharacters,
    fullName,
    lastInitial,
    true,
  );
  return niceNameArray.join("");
}

// nice names list from attendees and profiles
export function niceAttendeeNames(
  attendees: ISchema.IAttendee[],
  persons: ISchema.IPerson[],
  maxCharacters?: number,
  _fullName = false,
) {
  const calIds = attendees
    .filter((attendee) => {
      if (!attendee || !attendee.urnValue) {
        warnOutsideTests("no urnValue on attendee; check your fragments!");
        return false;
      }
      return !isRoom(attendee.urnValue);
    })
    .map((attendee) => attendee.urnValue);
  return niceNameStringFromPersons(persons, calIds, maxCharacters);
}

type PersonProfile = {
  __typename: "UserProfile";
  givenName: string | null;
  familyName: string | null;
  externalImageUrl: string | null;
} | null;
export function niceName(
  person?: { profile: PersonProfile } | null,
  calendarId?: string | null,
  fullName = false,
) {
  if (!person && !calendarId) {
    return "";
  } else if (
    fullName &&
    person &&
    person.profile &&
    person.profile.givenName &&
    person.profile.familyName
  ) {
    return `${person.profile.givenName} ${person.profile.familyName}`;
  } else if (person && person.profile && person.profile.givenName) {
    return person.profile.givenName;
  } else if (calendarId) {
    return niceNameFromCalendarUrnValue(calendarId);
  } else {
    return "";
  }
}

export function nonRoomCalendarIds(attendees: ISchema.IAttendee[]) {
  return attendees
    .filter((attendee) => {
      if (!attendee || !attendee.urnValue) {
        warnOutsideTests("no urnValue on attendee; check your fragments!");
        return false;
      }
      return !isRoom(attendee.urnValue);
    })
    .map((attendee) => attendee.urnValue);
}

export function removeAutopilotEmoji(title: string): string;
export function removeAutopilotEmoji(title: string | null): string | null {
  if (title && title.indexOf(AUTOPILOT_EMOJI) <= 1) {
    return title.replace(`${AUTOPILOT_EMOJI} `, "");
  }
  return title;
}

export const maybeGetWarningFromDomains = (
  domains: ISchema.IOrg["domains"],
  calendarIds: string[],
) => {
  const isExternal = calendarIds.some((calendarId) => !isEmailInDomains(calendarId, domains));
  if (isExternal) {
    return EAutopilotWarning.ExternalAttendee;
  }
  return undefined;
};

export const maybeGetWarning = (viewer: ISchema.IViewer, calendarIds: string[]) => {
  const org = getCurrentOrg(viewer);
  if (!org) {
    return undefined;
  }

  return maybeGetWarningFromDomains(org.domains, calendarIds);
};

export const getPreEventFlexibility = (
  flex: EFlex,
  dayOnOffMap: typeof defaultDayOnOffMap,
  allowedStart: DayOffsetTimeSlot | null,
  allowedEnd: DayOffsetTimeSlot | null,
  roomFlexibility: ISchema.PreRoomFlexibility,
) => {
  const preEventFlexibility: PreEventFlexibility = {
    timeRangeFlexibility: {
      // Exactly one of these gets set below
      dayOfWeekFlexibility: undefined,
      rangeFlexibility: undefined,
    },
    roomFlexibility: roomFlexibility,
    timeOfDayFlexibility: {
      allowedStartTimeInclusive: allowedStart ?? undefined,
      allowedEndTimeInclusive: allowedEnd ?? undefined,
    },
  };

  // Because GQL doesn't have oneOf support yet, this is modelled
  // as nullable fields on the webserver, where exactly one field must be non-null.
  // Meaning, either dayOfWeekFlexibility or rangeFlexibility must be defined while the other is null.
  if (flex === EFlex.DayOfWeek) {
    const days = getDaysOfWeekFromDayOnOffMap(dayOnOffMap);
    preEventFlexibility.timeRangeFlexibility.dayOfWeekFlexibility = {
      allowedDays: (days as string[]) as ISchema.DayOfWeek[],
    };
  } else {
    preEventFlexibility.timeRangeFlexibility.rangeFlexibility =
      flex === EFlex.Day ? ISchema.PreRangeFlexibility.Day : ISchema.PreRangeFlexibility.Week;
  }

  return preEventFlexibility;
};
