import { useOnboarding } from "#webapp/src/hooks/useOnboarding/useOnboarding";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
import { doUserLogin } from "#webapp/src/util/auth.util";
import { logger } from "#webapp/src/util/logger.util";
import { useMutation } from "@apollo/client";
import { articleUrls } from "@clockwise/client-commons/src/constants/help-center";
import { Link } from "@clockwise/design-system";
import { EcosystemEnum, LoginStatus } from "@clockwise/schema";
import { merge } from "lodash";
import React from "react";
import toast, { ToastOptions } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GoogleBtn from "../google-btn";
import {
  LoginGoogleUserDocument,
  LoginGoogleUserMutation,
} from "./__generated__/LoginGoogleUser.generated";
import { IContainer } from "./LoginBtnTypes";

export const LoginBtn = (props: IContainer) => {
  const [loading, setLoading] = React.useState(false);

  const isChromeExtension = useSelector((state: IReduxState) => state.webExtension.isWebExtension);
  const authed = useSelector((state: IReduxState) => state.auth.authed);
  const navigate = useNavigate();

  const [loginGoogleUser] = useMutation(LoginGoogleUserDocument);
  const { setShouldRenderOnboarding } = useOnboarding();

  React.useEffect(() => {
    if (authed) {
      if (props.preventLoginRedirect) {
        return;
      }
      if (isChromeExtension) {
        navigate("/chrome.html", { replace: true });
      } else if (props.redirect) {
        navigate(`/${props.redirect}`, { replace: true });
      } else {
        navigate("/app", { replace: true });
      }
    }
  });

  const onGoogleSuccess = (response: { code: string }) => {
    const googleOfflineCode = response.code;
    setLoading(true);
    if (props.onStart) {
      props.onStart();
    }

    loginGoogleUser({
      variables: {
        input: {
          googleOfflineCode,
          verificationType: props.verificationType ? (props.verificationType as any) : undefined,
        },
      },
      onCompleted: onRelaySuccess,
      onError: onRelayFailure,
    });
  };

  const handleSuccess = () => {
    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  const handleFailure = (error?: any) => {
    if (props.onFailure) {
      props.onFailure(error);
    }

    if (error && !props.hideSnackBarMessages) {
      toast.error(error, getToastOptions());
    }
  };

  const handlePersonalEmailRedirect = () => {
    // Using the href because this handler doesn't render anything
    window.location.href = "https://www.getclockwise.com/personal-email-not-supported";
  };

  const onGoogleFailure = (error: any) => {
    logger.error(`OAuth failure - ${error.error}`, error);
    switch (error.error) {
      case "idpiframe_initialization_failed":
        break;
      default:
        handleFailure("Sorry, failed to connect to your Google account.");
        break;
    }
    if (props.onGoogleFailure) {
      props.onGoogleFailure(error);
    }
  };

  const getToastOptions = (): ToastOptions => {
    return {
      position: isChromeExtension ? "bottom-right" : "bottom-center",
      style: isChromeExtension ? { width: "268px" } : undefined,
    };
  };

  const onRelaySuccess = (data: LoginGoogleUserMutation) => {
    const { loginUser: payload } = data;

    if (!payload || !payload.viewer) {
      if (!payload) {
        logger.error("Incomplete fragment for loginGoogleUser - missing payload", { payload });
      } else if (!payload.viewer) {
        logger.error("Incomplete fragment for loginGoogleUser - missing viewer", { payload });
      }
      logger.error("Incomplete fragment for loginGoogleUser");
      return;
    }

    setLoading(false);
    let badLoginStatusMessage: string | JSX.Element = "";
    switch (payload.loginStatus) {
      // success!
      case LoginStatus.Success:
        doUserLogin(
          payload.viewer,
          payload.operation,
          payload.orgOperation,
          EcosystemEnum.Google,
          setShouldRenderOnboarding,
          navigate,
          isChromeExtension,
          props.redirect,
          props.preventLoginRedirect,
        );
        handleSuccess();
        if (!props.hideSnackBarMessages) {
          toast.success("Welcome to Clockwise!", getToastOptions());
        }
        break;
      // successful user login, but bad status
      case LoginStatus.GoogleContactSharingDisabled:
        badLoginStatusMessage = (
          <span>
            Please update Google to allow Clockwise to access your contacts.{" "}
            <Link href={articleUrls.contactSharing} target="_blank">
              Learn more
            </Link>
          </span>
        );
        handleSuccess();
        break;
      // successful user login, but bad status
      case LoginStatus.GoogleApiAccessDisabled:
        badLoginStatusMessage = "Please update Google to allow Clockwise to have API access.";
        handleSuccess();
        break;
      // generic failure of onboarding the org, has a user, but bad status
      case LoginStatus.NoCrawl:
        badLoginStatusMessage = "Sorry, there was a problem onboarding your account.";
        handleSuccess();
        break;
      // no user, bad login status
      case LoginStatus.PersonalEmail:
        badLoginStatusMessage = "Please use your work email to log into Clockwise.";
        handlePersonalEmailRedirect();
        break;
      // no user, bad login status
      case LoginStatus.WaitlistedEmail:
        badLoginStatusMessage =
          "Thanks for your interest in Clockwise. We are working hard " +
          "to increase access to our service. We have added you to our waitlist and will be in touch soon.";
        handleFailure();
        break;
      case LoginStatus.SecondaryDomainUsed:
        badLoginStatusMessage =
          "It appears you are using a secondary domain for your " +
          "organization. Please sign in using your Google account for your primary domain.";
        handleFailure();
        break;
      case LoginStatus.Deactivating:
        badLoginStatusMessage =
          "You recently deactivated your Clockwise account. While we process " +
          "your deactivation it is not possible to sign in. Apologies for the inconvenience, please try again later.";
        handleFailure();
        break;
      case LoginStatus.BlockedByAdmin:
        badLoginStatusMessage =
          "Your admin removed your access to Clockwise. To remove Clockwise from your calendar, " +
          "please delete the Chrome extension. If this was a mistake, contact your admins. ";
        handleFailure();
        break;
      case LoginStatus.InsufficientScopes:
        // Use a custom toast and leave badLoginStatusMessage empty to skip default error toast
        if (!props.hideSnackBarMessages) {
          toast.error(
            () => (
              <div>
                <div className="cw-body-lg cw-font-medium">
                  Clockwise needs specific permissions to view and edit events on your calendar.
                </div>
                <div className="cw-body-base cw-text-subtle">
                  Please select all permissions to sign up.{" "}
                  <Link
                    href="https://support.getclockwise.com/article/83-security-using-clockwise"
                    target="learn_more_permissions"
                  >
                    Learn more.
                  </Link>
                </div>
              </div>
            ),
            merge({ duration: 30000, style: { maxWidth: "400px" }, icon: null }, getToastOptions()),
          );
        }
        handleFailure();
        break;
      // meh
      default:
        handleFailure("Missing login status, sorry something went wrong.");
        break;
    }

    // if we have a bad login status, display it for 20 seconds
    if (badLoginStatusMessage && !props.hideSnackBarMessages) {
      toast.error(badLoginStatusMessage, { ...getToastOptions(), duration: 20000 });
    }
  };

  const onRelayFailure = (_response: any) => {
    setLoading(false);
    handleFailure("Sorry, there was a problem signing in.");
  };

  return (
    <GoogleBtn
      style={props.style}
      labelStyle={props.labelStyle}
      label={props.label || "Login"}
      disabled={loading}
      onSuccess={onGoogleSuccess}
      onFailure={onGoogleFailure}
      fullWidth={props.fullWidth}
      onClick={props.onClick}
      variant={props.variant || "googleStandard"}
      icon={props.icon}
      textAlign={props.textAlign}
      sentiment={props.sentiment}
      iconClassNames={props.iconClassNames}
    />
  );
};
