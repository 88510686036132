const baseExtensionURLs = {
  chrome:
    "https://chrome.google.com/webstore/detail/clockwise-time-management/hjcneejoopafkkibfbcaeoldpjjiamog",
  firefox: "https://addons.mozilla.org/en-US/firefox/addon/clockwise",
  safari: "https://www.getclockwise.com/login/app",
};

export const ExtensionURLs = {
  ...baseExtensionURLs,
  brave: baseExtensionURLs.chrome,
};
