// libraries
import {
  InterAppPayload,
  PayloadTypes,
} from "@clockwise/web-commons/src/util/post-message-common.util";
import { Dialog } from "@material-ui/core";
import { DialogProps } from "@material-ui/core/Dialog";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { newMessageEventDataFromChromeExtension } from "../../state/actions/chrome-extension.actions";
import { PostMessageManager } from "../../util/post-message.util";

export type IProps = DialogProps;

export const LockedDialog: React.FC<IProps> = (props) => {
  const { open, onClose } = props;

  const dispatch = useDispatch();
  const wasOpen = useRef(false);

  const updateChromeDialogOpen = useCallback(
    (open: boolean) => {
      // send to extension
      PostMessageManager.sendWebDialogOpen(open);

      // update local state
      dispatch(
        newMessageEventDataFromChromeExtension(
          new InterAppPayload(PayloadTypes.CHROME_EXTENSION_CHROME_DIALOG_OPEN, {
            chromeExtensionChromeDialogOpen: open,
          }),
        ),
      );
    },
    [dispatch],
  );

  const handleClose = useCallback(
    (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
      updateChromeDialogOpen(false);
      onClose?.(event, reason);
    },
    [onClose, updateChromeDialogOpen],
  );

  useEffect(() => {
    if (open !== wasOpen.current) {
      // Trigger an update on change
      updateChromeDialogOpen(open);
      wasOpen.current = open;
    }
    return () => updateChromeDialogOpen(false);
  }, [open, updateChromeDialogOpen]);

  return <Dialog {...props} onClose={handleClose} />;
};
