type TeammateNameData = {
  person: {
    profile?: {
      givenName: string;
      familyName: string;
    };
    primaryCalendarId: string;
  };
};
export const getFullNameOrCalendarId = (teammate: TeammateNameData) => {
  return teammate.person.profile
    ? `${teammate.person.profile.givenName} ${teammate.person.profile.familyName}`
    : teammate.person.primaryCalendarId;
};

export const getFirstNameOrCalendarId = (teammate: TeammateNameData) => {
  return teammate.person.profile
    ? teammate.person.profile.givenName
    : teammate.person.primaryCalendarId;
};
