/// <reference types="webpack/module" />
/* global __webpack_public_path__:writable */
import { getCdnUrl } from "@clockwise/client-commons/src/config/api";
import { setDevenv } from "@clockwise/client-commons/src/config/devenv";
import { Environment, setEnvironment } from "@clockwise/client-commons/src/config/environment";
import { devenvOverride, environmentOverride } from "../util/local-storage";

const isValidEnv = (env: unknown): env is Environment => {
  const validEnvironments: Environment[] = ["production", "preprod", "staging", "development"];
  return validEnvironments.includes(env as Environment);
};

const env = environmentOverride.get();
const devenv = devenvOverride.get();

// If we have a valid environment override, use it.
// Otherwise, skip this setup and keep using the original environment
if (isValidEnv(env)) {
  setEnvironment(env);
}

if (devenv) {
  setDevenv(devenv);
}
// This sets the public path for all assets to use the specified CDN. If set,
// it overrides the `output.publicPath` value used in the webpack config
// NOTE: This must be run before importing any asset urls
__webpack_public_path__ = getCdnUrl();
