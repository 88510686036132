import {
  OnboardingMeta,
  OnboardingType,
  OnboardingTypeSource,
} from "#webapp/src/components/onboarding-nux/OnboardingNUXTypes";
import { FastCrawlFlowState } from "#webapp/src/state/onboarding";
import { onboardingMeta } from "@clockwise/web-commons/src/util/local-storage";

export const setOnboardingMetaLocalStorage = (
  onboardingType: OnboardingType,
  onboardingTypeSource?: OnboardingTypeSource | null,
) => {
  onboardingMeta.clear();
  onboardingMeta.set("onboardingType", onboardingType);
  onboardingTypeSource && onboardingMeta.set("onboardingTypeSource", onboardingTypeSource);
};

export type TOnboardingFlowStatus = {
  state: string;
  percentComplete: number;
  errorMessage: string | null;
};

export type TOnboardingFlowState = {
  previousState: string | null; // if unstarted, previous state is null
  current: TOnboardingFlowStatus;
};

type TFastCrawlFlowStatus = {
  state: FastCrawlFlowState;
  percentComplete: number;
  errorMessage: string | null;
};

export type TFastCrawlFlowState = {
  previousState: FastCrawlFlowState;
  current: TFastCrawlFlowStatus;
  pending: TFastCrawlFlowStatus;
};

export enum OnboardingActions {
  SetOnboardingLoaded = "SetOnboardingLoaded",
  SetOnboardingFlowState = "SetOnboardingFlowState",

  SetFastCrawlFlowState = "SetFastCrawlFlowState",
  SetFastCrawlUserCrawledRefetchComplete = "SetFastCrawlUserCrawledRefetchComplete",
  SetFastCrawlTeammatesIdentifiedRefetchComplete = "SetFastCrawlTeammatesIdentifiedRefetchComplete",
  SetFastCrawlFinishedRefetchComplete = "SetFastCrawlFinishedRefetchComplete",

  SetOnboardingMeta = "SetOnboardingMeta",
}

export interface IOnboardingAction {
  type: OnboardingActions;
  data?: IOnboardingActionData;
}

export interface IOnboardingActionData {
  fastCrawlFlowState?: TFastCrawlFlowState;
  onboardingFlowState?: TOnboardingFlowState;
  onboardingLoaded?: boolean;
  onboardingLinks?: boolean;
  onboardingMeta?: OnboardingMeta;
}

export const setOnboardingLoaded = (onboardingLoaded: boolean) => ({
  type: OnboardingActions.SetOnboardingLoaded,
  data: {
    onboardingLoaded,
  },
});

export const setOnboardingFlowState = (
  onboardingFlowState: TOnboardingFlowState,
): IOnboardingAction => ({
  type: OnboardingActions.SetOnboardingFlowState,
  data: {
    onboardingFlowState,
  },
});

export const setFastCrawlFlowState = (
  fastCrawlFlowState: TFastCrawlFlowState,
): IOnboardingAction => ({
  type: OnboardingActions.SetFastCrawlFlowState,
  data: {
    fastCrawlFlowState,
  },
});

export const setFastCrawlUserCrawledRefetchComplete = (): IOnboardingAction => ({
  type: OnboardingActions.SetFastCrawlUserCrawledRefetchComplete,
});

export const setFastCrawlTeammatesIdentifiedRefetchComplete = (): IOnboardingAction => ({
  type: OnboardingActions.SetFastCrawlTeammatesIdentifiedRefetchComplete,
});

export const setFastCrawlFinishedRefetchComplete = (): IOnboardingAction => ({
  type: OnboardingActions.SetFastCrawlFinishedRefetchComplete,
});

export const setOnboardingMeta = (
  onboardingType: OnboardingType,
  onboardingTypeSource: OnboardingTypeSource | null,
) => {
  setOnboardingMetaLocalStorage(onboardingType, onboardingTypeSource);

  return {
    type: OnboardingActions.SetOnboardingMeta,
    data: {
      onboardingMeta: { onboardingType, onboardingTypeSource },
    },
  };
};
