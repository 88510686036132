import { graphql } from "react-relay";

export const updateTeamMutation = graphql`
  mutation UpdateTeamMutation($input: updateTeamMutationInput!) {
    updateTeam(input: $input) {
      clientMutationId
      team {
        id
        teamId
        teamName
        createdBy {
          id
          type
          value
        }
        teamCalendarId
        settings {
          __typename
          ... on TeamSettings {
            manageTeamCalendar
          }
        }
        userSettings {
          __typename
          ... on TeamUserSettings {
            useTeamCalendar
          }
        }
        teamMembers {
          role
          person {
            id
            userId
            personId
            primaryCalendarId
            calendarAliases
            redirectPersonId
            profile {
              givenName
              familyName
              externalImageUrl
            }
          }
        }
        invitedMembers {
          role
          person {
            id
            userId
            personId
            primaryCalendarId
            calendarAliases
            redirectPersonId
            profile {
              givenName
              familyName
              externalImageUrl
            }
          }
        }
      }
    }
  }
`;
