import { DateTime } from "luxon";

export function toISODateString(dateString: string): string | null {
  const date = DateTime.fromISO(dateString);

  if (!date.isValid) {
    return null;
  }

  return date.toISODate();
}
