import { genTxnId } from "@clockwise/client-commons/src/util/txn";
import { jwt, xsrf } from "./local-storage";

export const clientId = `c-${genTxnId()}`;

export function getAuthedRequestInitForFetch(): RequestInit {
  const headers = new Headers();

  const jwtToken = jwt.get();
  if (jwtToken) {
    headers.append("Authorization", `Bearer ${jwtToken}`);
  }

  const xsrfToken = xsrf.get();
  if (xsrfToken) {
    headers.append("xsrf", xsrfToken);
  }

  headers.append("txnId", genTxnId());
  headers.append("clientId", clientId);

  return { headers, credentials: "include" };
}
