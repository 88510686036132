export enum AnnotationDialogActions {
  DisableSkip = "ANNOTATION_DIALOG_DISABLE_SKIP",
  EnableSkip = "ANNOTATION_DIALOG_ENABLE_SKIP",
  MeetingRoomsWithDefaultsOn = "ANNOTATION_DIALOG_MEETING_ROOMS_WITH_DEFAULTS_ON",
  MeetingRoomsWithDefaultsOff = "ANNOTATION_DIALOG_MEETING_ROOMS_WITH_DEFAULTS_OFF",
}

export const disableSkip = () => ({
  type: AnnotationDialogActions.DisableSkip,
});

export const enableSkip = () => ({
  type: AnnotationDialogActions.EnableSkip,
});

export const saveMeetingRoomsWithDefaultOrgTags = () => ({
  type: AnnotationDialogActions.MeetingRoomsWithDefaultsOn,
});

export const doNotSaveMeetingRoomsWithDefaultOrgTags = () => ({
  type: AnnotationDialogActions.MeetingRoomsWithDefaultsOff,
});
