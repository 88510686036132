export type LogLevel = "info" | "debug" | "warn" | "error";

export function genericGetSharedMeta() {
  return {};
}

export abstract class LoggerTransport {
  public abstract log(message: string, level: LogLevel, ...meta: any[]): void;

  protected getStack() {
    const err = new Error();
    let stack = err.stack;
    if (stack) {
      stack = stack.split("\n").splice(6).join("\n");
    }
    return stack || "";
  }
}

export class Logger {
  public static getSharedMeta = genericGetSharedMeta;
  private transports: LoggerTransport[] = [];

  public add(transport: LoggerTransport) {
    this.transports.push(transport);
  }

  public info(message: string, ...metas: any[]) {
    this.transports.forEach((transport) =>
      transport.log(message, "info", Logger.getSharedMeta(), ...metas),
    );
  }

  public debug(message: string, ...metas: any[]) {
    this.transports.forEach((transport) =>
      transport.log(message, "debug", Logger.getSharedMeta(), ...metas),
    );
  }

  public warn(message: string, ...metas: any[]) {
    this.transports.forEach((transport) =>
      transport.log(message, "warn", Logger.getSharedMeta(), ...metas),
    );
  }

  public error(message: string, ...metas: any[]) {
    try {
      this.transports.forEach((transport) =>
        transport.log(message, "error", Logger.getSharedMeta(), ...metas),
      );
    } catch (error) {
      console?.warn(`Unable to log error: "${message}"`);
      console?.error("Logger error", error);
    }
  }
}

export class ConsoleTransport extends LoggerTransport {
  public log(message: string, level: LogLevel, ...metas: any[]) {
    switch (level) {
      case "info":
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        console.log(message, ...metas);
        return;
      case "debug":
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        console.debug(message, ...metas);
        return;
      case "warn":
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        console.warn(message, ...metas);
        return;
      case "error":
        // eslint-disable-next-line no-case-declarations
        const stack = this.getStack();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        console.error(message, ...metas, stack);
        return;
      default:
    }
  }
}

export class InMemoryCacheTransport extends LoggerTransport {
  private cache: Record<number, { message: string; level: LogLevel; metas: any[] }>;
  private minIndex = 0;
  private maxIndex = 0;

  constructor() {
    super();

    this.cache = {};
  }

  public log(message: string, level: LogLevel, ...metas: any[]) {
    if (level === "debug") {
      return;
    }

    const diff = this.maxIndex - this.minIndex;
    if (diff === 10000) {
      delete this.cache[this.minIndex];

      this.minIndex += 1;
    }

    this.maxIndex += 1;

    this.cache[this.maxIndex] = { message, level, metas };
  }

  public getLogsB64() {
    return btoa(JSON.stringify(this.getLogs()));
  }

  public getLogs() {
    return Object.values(this.cache);
  }
}

//////////////////
// Singleton
//////////////////
export const logger = new Logger();
