import {
  setPricingDialogOpen,
  updateShouldRefetch,
} from "#webapp/src/state/actions/payments.actions";
import { PageEvents, TrackingEvents, page, track } from "#webapp/src/util/analytics.util";
import { windowLocation } from "#webapp/src/util/location.util";
import { paths } from "@clockwise/client-commons/src/constants/site";
import { Button } from "@clockwise/design-system";
import { Confetti } from "phosphor-react";
import * as React from "react";
import { connect } from "react-redux";

export interface IContainer {
  isDialog: boolean;
}

export interface IProps extends IContainer {
  setPricingDialogOpen: typeof setPricingDialogOpen;
  updateShouldRefetch: typeof updateShouldRefetch;
}

const PaymentConfirmationCmpt = ({
  isDialog,
  setPricingDialogOpen,
  updateShouldRefetch,
}: IProps) => {
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    page(PageEvents.PAYMENT_CONFIRMATION);
    updateShouldRefetch(true);
  }, []);

  const onPaymentConfirmationGotItClicked = () => {
    track(TrackingEvents.CHECKOUT.PAYMENT_CONFIRMATION_GOT_IT_CLICKED);
    setLoading(true);
    if (!isDialog) {
      windowLocation.assign("PaymentConfirmationGotItClicked", paths.webApp);
    } else {
      setPricingDialogOpen(false);
    }
  };

  return (
    <div className="cw-flex cw-flex-col cw-items-center cw-text-center cw-w-[450px] cw-pb-[50%] cw-pt-10">
      <Confetti size={64} className="cw-h-[100px] cw-w-[100px]" />
      <div className="cw-heading-3xl cw-my-2">Congratulations!</div>
      <div className="cw-body-lg cw-my-2">
        You are now upgraded and will get a payment confirmation shortly!
      </div>
      <div className="cw-w-[150px] cw-my-4">
        <Button
          onClick={() => onPaymentConfirmationGotItClicked()}
          disabled={loading}
          sentiment="positive"
          size="large"
          fullWidth
        >
          Got it
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(_state: any, _ownProps: IContainer) {
  return {};
}
export const PaymentConfirmation = connect(mapStateToProps, {
  setPricingDialogOpen,
  updateShouldRefetch,
})(PaymentConfirmationCmpt);
