/**
 * @generated SignedSource<<ab89e8155b6a2ecd54eccfddf2537e16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SlackForTeamUpdateAction = "Delete" | "%future added value";
export type UpdateSlackAccountForTeamMutationInput = {
  action: SlackForTeamUpdateAction;
  clientMutationId?: string | null;
  orgRelayId: string;
  teamId: string;
};
export type UpdateSlackAccountForTeamMutation$variables = {
  input: UpdateSlackAccountForTeamMutationInput;
};
export type UpdateSlackAccountForTeamMutation$data = {
  readonly updateSlackAccountForTeam: {
    readonly clientMutationId: string | null;
  } | null;
};
export type UpdateSlackAccountForTeamMutation = {
  variables: UpdateSlackAccountForTeamMutation$variables;
  response: UpdateSlackAccountForTeamMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSlackAccountForTeamMutationPayload",
    "kind": "LinkedField",
    "name": "updateSlackAccountForTeam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSlackAccountForTeamMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSlackAccountForTeamMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d50fedffa31b32c6a443e3dd7f438ae6",
    "id": null,
    "metadata": {},
    "name": "UpdateSlackAccountForTeamMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSlackAccountForTeamMutation(\n  $input: UpdateSlackAccountForTeamMutationInput!\n) {\n  updateSlackAccountForTeam(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "b70967fd48981316f3db41d55a9298d2";

export default node;
