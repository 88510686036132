import { CSSProperties } from "react";

export const container: CSSProperties = {
  height: 24,
  width: 24,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const userImg: CSSProperties = {
  width: "100%",
  height: "100%",
  display: "inline-block",
  borderRadius: "50%",
};

export const noUserImg: CSSProperties = {
  background: "#5393c6",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "default",
};
