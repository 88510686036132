import classNames from "classnames";
import { DateTime, IANAZone, Interval } from "luxon";
import * as React from "react";

// types

// ui and styles
import { ArrowDropDown, ArrowDropUp } from "@clockwise/design-system/icons";
import { animated, config, useTransition } from "@react-spring/web";
import { CenteredCard } from "../card";

// util
import { TrackingEvents } from "../../util/analytics.util";
import { onSpaceOrEnterKey } from "../../util/html.util";
import { LinkTimeSlot } from "../scheduling-link";
import { isBad, isBest } from "../scheduling-link/utils";

export interface TimesForDayProps {
  times: LinkTimeSlot[];
  timeZone: IANAZone;
  onSelectTime: (timeRange: Interval) => void;
  trackSession: (event: string, data?: Record<string, any>) => void;
  className?: string;
  highlightBestTimes: boolean;
  dayVisible: boolean;
}

export const TimesForDay = ({
  times,
  timeZone,
  onSelectTime,
  trackSession,
  className,
  highlightBestTimes,
  dayVisible,
}: TimesForDayProps) => {
  const allTimesBad = React.useMemo(() => times.every(isBad), [times]);

  // Default to collapsing bad times, but when they're all bad, no collapse just show all.
  const [showingMoreTimes, setShowingMoreTimes] = React.useState(false);
  const [timesToRender, setTimesToRender] = React.useState<JSX.Element[]>([]);

  React.useEffect(() => {
    if (!dayVisible) {
      setShowingMoreTimes(false);
    }
  }, [dayVisible, setShowingMoreTimes]);

  // The react-spring useTransition hook needs to manage the array of data to render.
  // It is generated here outside of the render() function, stored in timesToRender,
  // and delivered to useTransition. React-spring has internal state on what is being
  // rendered or not in order to add transitions to items added, removed, or updated.
  const genTimesToRender = () => {
    const nextTimesToRender = [];
    const tabIndex = dayVisible ? 0 : -1; // If day is not visible, don't allow tabbing to it's times.

    // numBadRendered used when all the times are bad. When all times are bad, we want to show
    // just a single bad time, followed by the show more times button. Its too funky to only show the
    // "show more times" button when all times are bad.
    let numBadRendered = 0;

    times.forEach((time) => {
      const timeInterval = Interval.fromISO(time.timeSlot);
      const hasBestTag = isBest(time);
      const hasBadTag = isBad(time);

      if (allTimesBad && !showingMoreTimes) {
        // When all of the times are bad, we are not showing the showMoreTimes button, and we have already
        // rendered one bad time, return early to stop showing any more times. This accomplishes showing
        // just a single bad time when all of them are bad, followed by the show more button added later on.
        if (numBadRendered >= 1) {
          return;
        }
      } else {
        if (hasBadTag && !showingMoreTimes) {
          return;
        }
      }

      if (hasBadTag) {
        numBadRendered++;
      }

      const date = timeInterval.start.setZone(timeZone);
      const formattedTime = date
        .toLocaleString(DateTime.TIME_SIMPLE)
        .replace(/\s/g, "")
        .toLowerCase();

      const onClickTime = () => onSelectTime(timeInterval);

      nextTimesToRender.push(
        <CenteredCard
          key={time.timeSlot}
          cw-id={dayVisible ? "link-time-card" : undefined}
          className="cw-mt-2 cw-p-2 cw-box-content"
          onClick={onClickTime}
          onSpaceOrEnter={onSpaceOrEnterKey(onClickTime)}
          tabIndex={tabIndex}
          content={
            <p className="cw-body-lg cw-text-accent cw-font-semibold cw-m-0">
              {formattedTime}
              {hasBestTag && highlightBestTimes && (
                <span className="cw-bg-positive-inset cw-body-sm cw-text-positive cw-ml-2 cw-px-2 cw-py-1 cw-rounded">
                  BEST
                </span>
              )}
            </p>
          }
        />,
      );
    });

    // Used to determine if we show the "show more times" and the "show fewer times" buttons.
    const numBad = times.filter(isBad).length;

    // Show either the "show more times" or "show fewer times" button depending on
    // the state of showingMoreTimes boolean, as well as if there are any additional bad times to show.
    if (showingMoreTimes) {
      const onClickShowFewer = () => {
        setShowingMoreTimes(false);
        trackSession(TrackingEvents.LINKS.BOOKING.SHOW_FEWER_TIMES, {
          numTimesShown: times.length,
        });
      };

      nextTimesToRender.push(
        <CenteredCard
          key="fewer-times"
          cw-id={dayVisible ? "link-show-fewer-times" : undefined}
          aria-label="show fewer times"
          className="cw-mt-2 cw-p-2 cw-box-content"
          onClick={onClickShowFewer}
          onSpaceOrEnter={onSpaceOrEnterKey(onClickShowFewer)}
          tabIndex={tabIndex}
          subContent={
            <p className="cw-body-base cw-text-accent cw-flex cw-flex-row cw-justify-center cw-m-0">
              Show fewer times <ArrowDropUp className="-cw-mt-0.5" />
            </p>
          }
        />,
      );
    } else if (numBad - numBadRendered > 0) {
      const onClickShowMore = () => {
        setShowingMoreTimes(true);
        trackSession(TrackingEvents.LINK_SCHEDULER.SHOW_MORE_TIMES);
      };

      nextTimesToRender.push(
        <CenteredCard
          key="more-times"
          cw-id={dayVisible ? "link-show-more-times" : undefined}
          aria-label="show more times"
          className="cw-mt-2 cw-p-2 cw-box-content"
          onClick={onClickShowMore}
          onSpaceOrEnter={onSpaceOrEnterKey(onClickShowMore)}
          tabIndex={tabIndex}
          subContent={
            <p className="cw-body-base cw-text-accent cw-flex cw-flex-row cw-justify-center cw-m-0">
              Show more times <ArrowDropDown className="-cw-mt-0.5" />
            </p>
          }
        />,
      );
    }

    setTimesToRender(nextTimesToRender);
  };

  React.useEffect(() => {
    genTimesToRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showingMoreTimes, times, dayVisible]);

  const transitions = useTransition(timesToRender, {
    from: {
      opacity: 0,
      translateX: -2,
      translateY: -7,
    },
    enter: {
      opacity: 1,
      translateX: 0,
      translateY: 0,
    },
    delay: 125,
    trail: showingMoreTimes ? 65 : 80, // Delay between rendering each item in timesToRender.
    keys: (time) => time.key ?? JSON.stringify(time.props).substr(0, 15), // Should just be time.key.
    config: showingMoreTimes ? config.stiff : config.default, // This is where we can customize the animation.
  });

  return (
    <div className={classNames([className])}>
      {transitions((style, time, _, i) => (
        <animated.div key={i} style={style}>
          {time}
        </animated.div>
      ))}
    </div>
  );
};
