// IMPORTS
// schema
import * as ISchema from "#webapp/src/__schema__";

import { modernCache } from "#webapp/src/network/modern-network-layer";
import { PostMessageManager } from "#webapp/src/util/post-message.util";
import { commitMutation } from "react-relay";
import { Environment } from "relay-runtime";
import { updateFlagsForUserMutation } from "./UpdateFlagsForUser.gql";

interface IUpdateFlagsForUserMutationResponse {
  updateFlagsForUser: ISchema.IUpdateFlagsForUserMutationPayload;
}

// MUTATION
export function updateFlagsForUser(
  environment: Environment,
  input: ISchema.IUpdateFlagsForUserMutationInput,
  onSuccess: (response?: ISchema.IUpdateFlagsForUserMutationPayload) => void,
  onError: (error?: any) => void,
) {
  commitMutation(environment, {
    onError,
    variables: { input },
    mutation: updateFlagsForUserMutation,
    onCompleted: (response: IUpdateFlagsForUserMutationResponse, errors?: Error[]) => {
      if (errors) {
        onError(errors[0]);
      } else {
        // if we're not in a modern environment, modernCache will be undefined
        if (modernCache) {
          modernCache.clear();
        }
        PostMessageManager.sendUserFlags(response.updateFlagsForUser.flags.flags);
        onSuccess(response.updateFlagsForUser);
      }
    },
  });
}
