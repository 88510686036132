import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { compact } from "lodash";
import { PlannerMetadataOrg } from "../types";

export const parseCalendarIdsGQL = (org?: PlannerMetadataOrg) => {
  const { primaryCalendarId, teamCalendarIds } = parseOrgAndTeamCalendarIdsGQL(org);
  return compact([primaryCalendarId, ...teamCalendarIds]);
};

export const parseOrgAndTeamCalendarIdsGQL = (org?: PlannerMetadataOrg) => {
  const teamCalendars =
    getValue(org?.userTeams)
      ?.list?.filter(
        (t) =>
          getValue(t.userSettings)?.useTeamCalendar &&
          getValue(t.settings)?.manageTeamCalendar &&
          t.teamCalendarId,
      )
      .map((t) => t.teamCalendarId)
      .filter((t): t is string => t !== null) || [];

  return { primaryCalendarId: org?.primaryOrgCalendar, teamCalendarIds: [...teamCalendars] };
};
