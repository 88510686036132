/**
 * @generated SignedSource<<0e50cc8b790c0e282fbd11fc48aa6fef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserFlowMutationInput = {
  clientMutationId?: string | null;
  flowKey: string;
  newState: string;
};
export type UpdateFlowStateUserMutation$variables = {
  input: UpdateUserFlowMutationInput;
};
export type UpdateFlowStateUserMutation$data = {
  readonly updateFlowStateUser: {
    readonly flowState: {
      readonly id: string;
      readonly flowKey: string | null;
      readonly current: {
        readonly id: string;
        readonly state: string | null;
        readonly percentComplete: number | null;
        readonly errorMessage: string | null;
      } | null;
      readonly pending: {
        readonly id: string;
        readonly state: string | null;
        readonly percentComplete: number | null;
        readonly errorMessage: string | null;
      } | null;
    } | null;
  } | null;
};
export type UpdateFlowStateUserMutation = {
  variables: UpdateFlowStateUserMutation$variables;
  response: UpdateFlowStateUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "state",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "percentComplete",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "errorMessage",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserFlowMutationPayload",
    "kind": "LinkedField",
    "name": "updateFlowStateUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FlowState",
        "kind": "LinkedField",
        "name": "flowState",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "flowKey",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FlowStatus",
            "kind": "LinkedField",
            "name": "current",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FlowStatus",
            "kind": "LinkedField",
            "name": "pending",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateFlowStateUserMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateFlowStateUserMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "446de3a6bbb94b7681d39accc8844b1e",
    "id": null,
    "metadata": {},
    "name": "UpdateFlowStateUserMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateFlowStateUserMutation(\n  $input: UpdateUserFlowMutationInput!\n) {\n  updateFlowStateUser(input: $input) {\n    flowState {\n      id\n      flowKey\n      current {\n        id\n        state\n        percentComplete\n        errorMessage\n      }\n      pending {\n        id\n        state\n        percentComplete\n        errorMessage\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "411d6a596c3db4152adecb8aa740ef94";

export default node;
