/**
 * @generated SignedSource<<041126cb24587cbf160795dae9009720>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TeamMemberRole = "Admin" | "Member" | "%future added value";
export type UrnType = "Date" | "Email" | "Event" | "FlowState" | "Guest" | "Org" | "Phone" | "Services" | "Setting" | "Team" | "Teammate" | "TimeZone" | "User" | "WorkOsOrg" | "WorkingGroup" | "WorkingHours" | "%future added value";
export type updateTeamMutationInput = {
  addedTeamMembers?: ReadonlyArray<TeamRoleAndPersonIdInput> | null;
  clientMutationId?: string | null;
  newTeamName?: string | null;
  orgRelayId: string;
  removedTeamMembers?: ReadonlyArray<string> | null;
  teamId: string;
};
export type TeamRoleAndPersonIdInput = {
  personId: string;
  role: TeamMemberRole;
};
export type UpdateTeamMutation$variables = {
  input: updateTeamMutationInput;
};
export type UpdateTeamMutation$data = {
  readonly updateTeam: {
    readonly clientMutationId: string | null;
    readonly team: {
      readonly id: string;
      readonly teamId: string;
      readonly teamName: string;
      readonly createdBy: {
        readonly id: string;
        readonly type: UrnType | null;
        readonly value: string | null;
      };
      readonly teamCalendarId: string | null;
      readonly settings: {
        readonly __typename: "TeamSettings";
        readonly manageTeamCalendar: boolean;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
      readonly userSettings: {
        readonly __typename: "TeamUserSettings";
        readonly useTeamCalendar: boolean;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
      readonly teamMembers: ReadonlyArray<{
        readonly role: TeamMemberRole;
        readonly person: {
          readonly id: string;
          readonly userId: string | null;
          readonly personId: string;
          readonly primaryCalendarId: string;
          readonly calendarAliases: ReadonlyArray<string>;
          readonly redirectPersonId: string | null;
          readonly profile: {
            readonly givenName: string | null;
            readonly familyName: string | null;
            readonly externalImageUrl: string | null;
          } | null;
        };
      }>;
      readonly invitedMembers: ReadonlyArray<{
        readonly role: TeamMemberRole;
        readonly person: {
          readonly id: string;
          readonly userId: string | null;
          readonly personId: string;
          readonly primaryCalendarId: string;
          readonly calendarAliases: ReadonlyArray<string>;
          readonly redirectPersonId: string | null;
          readonly profile: {
            readonly givenName: string | null;
            readonly familyName: string | null;
            readonly externalImageUrl: string | null;
          } | null;
        };
      }>;
    };
  } | null;
};
export type UpdateTeamMutation = {
  variables: UpdateTeamMutation$variables;
  response: UpdateTeamMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Urn",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamCalendarId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manageTeamCalendar",
      "storageKey": null
    }
  ],
  "type": "TeamSettings",
  "abstractKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useTeamCalendar",
      "storageKey": null
    }
  ],
  "type": "TeamUserSettings",
  "abstractKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "role",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "OrgPerson",
    "kind": "LinkedField",
    "name": "person",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "personId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "primaryCalendarId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "calendarAliases",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "redirectPersonId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfile",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "givenName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "familyName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalImageUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamMember",
  "kind": "LinkedField",
  "name": "teamMembers",
  "plural": true,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamMember",
  "kind": "LinkedField",
  "name": "invitedMembers",
  "plural": true,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateTeamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "updateTeamMutationPayload",
        "kind": "LinkedField",
        "name": "updateTeam",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "team",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "userSettings",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateTeamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "updateTeamMutationPayload",
        "kind": "LinkedField",
        "name": "updateTeam",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "team",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "userSettings",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "02c24bf6542e2ca9a1b12f3139420bbb",
    "id": null,
    "metadata": {},
    "name": "UpdateTeamMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateTeamMutation(\n  $input: updateTeamMutationInput!\n) {\n  updateTeam(input: $input) {\n    clientMutationId\n    team {\n      id\n      teamId\n      teamName\n      createdBy {\n        id\n        type\n        value\n      }\n      teamCalendarId\n      settings {\n        __typename\n        ... on TeamSettings {\n          manageTeamCalendar\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      userSettings {\n        __typename\n        ... on TeamUserSettings {\n          useTeamCalendar\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      teamMembers {\n        role\n        person {\n          id\n          userId\n          personId\n          primaryCalendarId\n          calendarAliases\n          redirectPersonId\n          profile {\n            givenName\n            familyName\n            externalImageUrl\n          }\n        }\n      }\n      invitedMembers {\n        role\n        person {\n          id\n          userId\n          personId\n          primaryCalendarId\n          calendarAliases\n          redirectPersonId\n          profile {\n            givenName\n            familyName\n            externalImageUrl\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "39a0ce3ef1433aab48e7256a2f4b3b5b";

export default node;
