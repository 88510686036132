/**
 * @generated SignedSource<<0a1c9b4a8e05e0ca142964a3e17141d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleEventColoringSettingsMutationsInput = {
  clientMutationId?: string | null;
  enabled: boolean;
  eventCategoryColorings: ReadonlyArray<EventCategoryColoringInput | null>;
  orgRelayId: string;
};
export type EventCategoryColoringInput = {
  active: boolean;
  eventCategory: string;
  googleColorKey?: string | null;
  isUserSet: boolean;
};
export type UpdateToggleEventColoringSettingsMutation$variables = {
  input: ToggleEventColoringSettingsMutationsInput;
};
export type UpdateToggleEventColoringSettingsMutation$data = {
  readonly updateToggleEventColoringSettings: {
    readonly eventColoringSettings: {
      readonly eventCategoryColorings: ReadonlyArray<{
        readonly eventCategory: string;
        readonly googleColorKey: string | null;
        readonly active: boolean;
        readonly isUserSet: boolean;
      }> | null;
    } | null;
  } | null;
};
export type UpdateToggleEventColoringSettingsMutation = {
  variables: UpdateToggleEventColoringSettingsMutation$variables;
  response: UpdateToggleEventColoringSettingsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "EventCategoryColoring",
  "kind": "LinkedField",
  "name": "eventCategoryColorings",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventCategory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "googleColorKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUserSet",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateToggleEventColoringSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleEventColoringSettingsMutationsPayload",
        "kind": "LinkedField",
        "name": "updateToggleEventColoringSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventColoringSettings",
            "kind": "LinkedField",
            "name": "eventColoringSettings",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateToggleEventColoringSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleEventColoringSettingsMutationsPayload",
        "kind": "LinkedField",
        "name": "updateToggleEventColoringSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventColoringSettings",
            "kind": "LinkedField",
            "name": "eventColoringSettings",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fe1da7f1299447326132adf9f6d6fc86",
    "id": null,
    "metadata": {},
    "name": "UpdateToggleEventColoringSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateToggleEventColoringSettingsMutation(\n  $input: ToggleEventColoringSettingsMutationsInput!\n) {\n  updateToggleEventColoringSettings(input: $input) {\n    eventColoringSettings {\n      eventCategoryColorings {\n        eventCategory\n        googleColorKey\n        active\n        isUserSet\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d98bc647ed7aa80145d157eff3ddf6b6";

export default node;
