import { getDevenv } from "./devenv";
import { getEnvironment } from "./environment";

const getUrl = (protocol: "https" | "wss", gateway = false) => {
  switch (getEnvironment()) {
    case "production":
      return `${protocol}://app.getclockwise.com`;
    case "preprod":
      return `${protocol}://app.preprod.getclockwise.com`;
    case "staging":
      return `${protocol}://app.staging.getclockwise.com`;
    case "test":
      return `${protocol}://localhost:9999`; // This should not be actually queried.
    default: {
      const devenv = getDevenv();
      const service = gateway ? "gateway" : "webserver";
      const port = "8080";
      return devenv
        ? `${protocol}://${service}-${devenv}.dev.getclockwise.com`
        : `${protocol}://localhost:${port}`;
    }
  }
};

export const getApiUrl = (gateway = false) => getUrl("https", gateway);

export const getSocketUrl = (gateway = false) => getUrl("wss", gateway);

export const getSiteUrl = () => {
  switch (getEnvironment()) {
    case "production":
      return "https://www.getclockwise.com";
    case "preprod":
      return "https://www.preprod.getclockwise.com";
    case "staging":
      return "https://www.staging.getclockwise.com";
    default: {
      const devenv = getDevenv();
      return devenv ? `https://${devenv}.dev.getclockwise.com` : "https://localhost:8080";
    }
  }
};

export const getCdnUrl = () => {
  switch (getEnvironment()) {
    case "production":
      return "https://cdn.getclockwise.com/";
    case "preprod":
      return "https://cdn.preprod.getclockwise.com/";
    case "staging":
      return "https://cdn.staging.getclockwise.com/";
    case "test":
      return `https://localhost:9999`; // This should not be actually queried.
    default: {
      const devenv = getDevenv();
      return devenv ? `https://cdn.dev.getclockwise.com/${devenv}/` : "https://localhost:8080/";
    }
  }
};
