import { graphql } from "react-relay";

export const updateEventColoringSettingsMutation = graphql`
  mutation UpdateEventColoringSettingsMutation($input: EventColoringSettingsMutationsInput!) {
    updateEventColoringSettings(input: $input) {
      eventColoringSettings {
        eventCategoryColorings {
          eventCategory
          googleColorKey
          active
          isUserSet
        }
      }
    }
  }
`;
