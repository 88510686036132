import { Check, SvgIconComponent } from "@clockwise/icons";
import { Menu } from "@headlessui/react";
import { SvgIcon } from "@material-ui/core";
import classNames from "classnames";
import { isUndefined } from "lodash";
import React, { PropsWithChildren } from "react";
import { getListBoxClasses } from "../constants/classes";

type BaseProps = {
  onClick?: () => void;
  keepOpen?: boolean;
  disabled?: boolean;
  icon?: SvgIconComponent;
  iconProps?: { stroke?: string; fill?: string; className?: string; style?: { color?: string } };
};
type LinkProps = {
  href: string;
  /**
   @deprecated checked cannot be used with href prop
   */
  checked?: never;
} & BaseProps;

type NonLinkProps = {
  checked?: boolean;
  /**
   @deprecated href cannot be used with checked prop
   */
  href?: never;
} & BaseProps;

export const MenuItem: React.FC<PropsWithChildren<NonLinkProps | LinkProps>> = (props) => {
  const { children, onClick, icon: SelectIcon, keepOpen, disabled = false, iconProps = {} } = props;

  const checkable = !isUndefined(props.checked);
  const CheckedIcon = props.checked ? Check : SvgIcon;

  const hasLink = !isUndefined(props.href);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    // DevNote: https://github.com/tailwindlabs/headlessui/discussions/1122
    if (disabled) {
      return;
    }

    onClick?.();

    if (keepOpen) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <Menu.Item disabled={disabled}>
      {({ active }) => (
        <div
          {...(checkable ? { "aria-checked": props.checked } : {})}
          className={getListBoxClasses(active, disabled)}
          onClick={handleClick}
        >
          <div className="cw-flex cw-items-center cw-gap-2">
            {hasLink ? (
              <a
                href={props.href}
                target="_blank"
                rel="noopener noreferrer"
                className="cw-no-underline cw-text-default cw-w-full"
              >
                {children}
              </a>
            ) : (
              <>
                {checkable && <CheckedIcon className="cw-text-positive cw-mr-2 cw-text-sm" />}
                {SelectIcon && (
                  <SelectIcon
                    {...iconProps}
                    className={classNames("cw-w-4 cw-h-4", iconProps?.className)}
                  />
                )}
                {children}
              </>
            )}
          </div>
        </div>
      )}
    </Menu.Item>
  );
};
