import { Divider } from "@clockwise/design-system";
import { AddCircleOutline } from "@clockwise/design-system/icons";
import classNames from "classnames";
import React from "react";

type PricingFaqProps = {
  question: string;
  answer: string;
};

const PricingFaq = ({ question, answer }: PricingFaqProps) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div>
      <div
        className="cw-flex cw-items-center cw-heading-sm md:cw-heading-lg cw-cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <AddCircleOutline classes={{ root: "cw-w-6 cw-m-2" }} />
        <div>{question}</div>
      </div>
      <div
        className={classNames(
          " cw-text-xs md:cw-text-base cw-pl-10 cw-transition-all cw-ease-in-out cw-duration-250 cw-text-left cw-overflow-hidden",
          expanded ? "cw-max-h-[300px]" : "cw-max-h-0",
        )}
      >
        {answer}
      </div>
      <Divider />
    </div>
  );
};

export default PricingFaq;
