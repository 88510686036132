import { Button } from "@clockwise/design-system";
import { Booking } from "@clockwise/web-commons/src/ui/scheduling-link";
import { CurrentBookingDetails } from "@clockwise/web-commons/src/ui/scheduling-link/CurrentBookingDetails";
import { getCancelBookingPath } from "@clockwise/web-commons/src/util/scheduling.util";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  bookingData: Booking & { id: string };
  reason: "past" | "inactive";
  timeZone: string;
}

export const CannotRescheduleBooking = ({ bookingData, reason, timeZone }: Props) => {
  const navigate = useNavigate();

  const message =
    reason === "past"
      ? "This meeting can't be rescheduled because the booking is in the past. You can still cancel this meeting by clicking below."
      : "This meeting can't be rescheduled because the Scheduling Link used to book it is inactive. You can still cancel this meeting by clicking below.";

  return (
    <div className="cw-m-6 cw-mt-8 cw-flex cw-flex-col cw-items-center">
      <div className="cw-max-w-xl">
        <div className="cw-body-base cw-text-default">
          {message}
          <div className="cw-my-6">
            <Button
              variant="solid"
              sentiment="positive"
              onClick={() => navigate(getCancelBookingPath(bookingData.id))}
            >
              Cancel meeting
            </Button>
          </div>
        </div>
        <CurrentBookingDetails booking={bookingData} timeZone={timeZone} />
      </div>
    </div>
  );
};
