import { graphql } from "react-relay";

export const removeUserTagsMutation = graphql`
  mutation RemoveUserTagsMutation($input: RemoveOrgTagsMutationInput!) {
    removeOrgTags(input: $input) {
      eventParent {
        events {
          id
          annotatedEvent {
            id
            externalEventId
            userTags {
              tag
              states {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
          annotatedRecurringEvent {
            id
            externalEventId
            userTags {
              tag
              states {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
        }
      }
    }
  }
`;
