import socket from "@clockwise/web-commons/src/network/socket";
import { StatsDSend } from "@clockwise/web-commons/src/network/socket.constants";

type Tags = { [tag: string]: string };

// should match interface in webserver,
// see https://github.com/clockwisehq/webserver/blob/main/src/datadog.ts
interface StatsDRecord {
  action: string;
  metric: string;
  value: number;
  tags?: Tags;
}

const defaultTags: Tags = {
  client: "web",
} as any; // FIXME: TEMP FIX FOR GQL2TS ISSUES

function addDefaultTags(record: StatsDRecord): StatsDRecord {
  const providedTags: Tags = record.tags || {};

  Object.keys(defaultTags).forEach((key) => {
    if (!providedTags[key]) {
      providedTags[key] = defaultTags[key];
    }
  });

  record.tags = providedTags;

  return record;
}

function emitRecord(record: StatsDRecord): Promise<void> {
  return new Promise((resolve) => {
    const recordWithDefaultTags = addDefaultTags(record);
    socket.get().emit(StatsDSend, recordWithDefaultTags);
    setTimeout(resolve, 500); // Give socket.get().emit() a moment to send. Not sure anything is listening to this socket...
  });
}

export function recordDuration(metric: string, duration: number, tags?: Tags) {
  // see datadog.ts in the webserver (https://github.com/clockwisehq/webserver/blob/main/src/datadog.ts)
  const record = {
    metric,
    tags,
    action: "RecordDuration",
    value: duration,
  };

  return emitRecord(record);
}

export function recordValue(metric: string, value: number, tags?: Tags) {
  // see datadog.ts in the webserver (https://github.com/clockwisehq/webserver/blob/main/src/datadog.ts)
  const record = {
    metric,
    value,
    tags,
    action: "RecordValue",
  };

  return emitRecord(record);
}

export function incrementValue(metric: string, tags?: Tags) {
  // see datadog.ts in the webserver (https://github.com/clockwisehq/webserver/blob/main/src/datadog.ts)
  const record = {
    metric,
    tags,
    value: 1,
    action: "IncrementValue",
  };

  return emitRecord(record);
}
