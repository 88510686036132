import { animated, config, useSprings, useTransition } from "@react-spring/web";
import classNames from "classnames";
import React, { useMemo } from "react";
import { AvatarSize, UserAvatar } from "../UserAvatar";

type Props = {
  overlap?: boolean;
  size?: AvatarSize;
  maxShown?: number;
  disableAnimation?: boolean;
  extraOverflowCount?: number; // Count of Avatars not included as children that will be added to the overflow counted by AvatarStack.
  // I recommend not using this prop, its only here for backwards compatibility in refactoring webapp.
  disableOverflow?: boolean;
  toolTipContent?: React.ReactNode;
  overflowSizeNum?: number;
};

const OVERLAP_CHART: Record<AvatarSize, string> = {
  tiny: "-cw-ml-[6px]",
  small: "-cw-ml-[6px]",
  medium: "-cw-ml-[8px]",
  large: "-cw-ml-[18px]",
  xlarge: "-cw-ml-[22px]",
};

export function OverflowAvatar({
  size,
  extraCount,
  overlap,
  showMarginLeft,
  toolTipContent,
  overflowSizeNum,
}: {
  size: AvatarSize;
  extraCount: number;
  overlap?: boolean;
  showMarginLeft?: boolean;
  toolTipContent?: React.ReactNode;
  overflowSizeNum?: number;
}) {
  return (
    <UserAvatar
      key={`overflow`}
      profile={{
        givenName: extraCount.toString(),
      }}
      size={size}
      toolTipContent={toolTipContent}
      hideTooltip={!toolTipContent}
      prefix="+"
      avatarClassname={classNames(
        "cw-bg-neutral cw-border-solid cw-text-subtle",
        !overlap && "cw-border cw-border-neutral-muted",
        overlap && "cw-border-2 cw-border-default",
        showMarginLeft && "cw-ml-0.5",
      )}
      sizeNum={overflowSizeNum ?? undefined}
    />
  );
}

/**
 * @deprecated Use AttendeeAvatarStack instead.
 */
export function AvatarStack({
  children,
  overlap = true,
  size = "medium",
  maxShown = 6,
  disableAnimation = false,
  extraOverflowCount = 0,
  disableOverflow = false,
  toolTipContent,
  overflowSizeNum,
}: React.PropsWithChildren<Props>) {
  const avatarsCount = React.Children.count(children);

  const toRender = useMemo(() => {
    const avatars = React.Children.toArray(children);

    const toRender = avatars.slice(0, maxShown);
    if ((avatars.length > maxShown || extraOverflowCount) && !disableOverflow) {
      const extraCount = Math.max(avatars.length - maxShown, 0) + extraOverflowCount;
      toRender.push(
        <OverflowAvatar
          size={size}
          extraCount={extraCount}
          overlap={overlap}
          showMarginLeft
          toolTipContent={toolTipContent}
          overflowSizeNum={overflowSizeNum}
        />,
      );
    }
    return toRender;
  }, [children, extraOverflowCount, maxShown, overlap, size, disableOverflow, toolTipContent]);

  const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);

  const animationDisabled = disableAnimation || avatarsCount <= 1;

  const avatars = useTransition(toRender, {
    from: { translateY: animationDisabled ? 0 : -5 },
    enter: { translateY: 0 },
    trail: 50,
    config: config.stiff,
    immediate: animationDisabled,
    keys: (elem) =>
      (elem as JSX.Element).key ?? JSON.stringify((elem as JSX.Element).props).substring(0, 15), // If key not present, fingerprint the props.
  });

  const [springs] = useSprings(
    toRender.length,
    (index) => ({
      translateY: !animationDisabled && hoveredIndex === index ? -5 : 0,
      immediate: animationDisabled,
      config: config.stiff,
    }),
    [hoveredIndex],
  );

  return (
    <div className="cw-inline-flex cw-items-center">
      {avatars((transitionStyle, avatar, _, i) => (
        <animated.div
          style={{
            translateY: transitionStyle.translateY,
            zIndex: hoveredIndex === i ? toRender.length + 1 : toRender.length - i,
          }}
          className={overlap && i !== 0 ? OVERLAP_CHART[size] : ""}
          key={i}
          onMouseEnter={() => setHoveredIndex(i)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <animated.div style={springs[i]} className={overlap ? "" : "cw-m-0.5"}>
            {React.cloneElement(avatar as JSX.Element, {
              hasOverlap: overlap ? "true" : "none",
              size,
            })}
          </animated.div>
        </animated.div>
      ))}
    </div>
  );
}
