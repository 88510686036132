import classNames from "classnames";
import { nanoid } from "nanoid";
import * as React from "react";
import ReactTooltip from "react-tooltip";
import { IProps, ISegment } from "./SegmentedBarTypes";

const namedColors: Record<string, string | undefined> = {
  focusTime: "cw-bg-focusTime-emphasis",
  busy: "cw-bg-busy-emphasis",
  fragmented: "cw-bg-fragmented-emphasis",
  empty: "cw-bg-underlay",
};

export const SegmentedBar = ({ segments, animateIn, style }: IProps) => {
  const [ready, setReady] = React.useState(!animateIn);
  React.useEffect(() => {
    setTimeout(() => setReady(true), 0);
  }, []);

  const total = segments.reduce((accum, next) => accum + next.value, 0);
  return (
    <div
      className="cw-flex cw-items-center cw-rounded-full cw-w-full cw-h-full cw-overflow-hidden"
      style={style}
    >
      {segments.map((segment, i) => (
        <Segment key={i} total={total} segment={segment} ready={ready} />
      ))}
    </div>
  );
};
interface ISegmentProps {
  total: number;
  segment: ISegment;
  ready: boolean;
}

const Segment = ({ total, segment, ready }: ISegmentProps) => {
  const tooltipId = React.useMemo(() => nanoid(), []);

  const { color = "empty", value, isInset = false } = segment;
  const percentWidth = ready ? value / total : 0;
  const segmentStyle: React.CSSProperties = {
    width: `${percentWidth * 100}%`,
  };
  // Set custom colors via style attribute
  if (!namedColors[color]) {
    segmentStyle.backgroundColor = color;
  }

  const segmentClasses = classNames(
    "cw-grow cw-h-full cw-transition-[width] cw-duration-500",
    namedColors[color],
    { "cw-shadow-inner": isInset },
  );

  if (!segment.tipLabel) {
    return <div className={segmentClasses} style={segmentStyle} />;
  } else {
    return (
      <div className={segmentClasses} style={segmentStyle} data-tip data-for={tooltipId}>
        <ReactTooltip id={tooltipId}>{segment.tipLabel}</ReactTooltip>
      </div>
    );
  }
};
