import classNames from "classnames";
import React, { PropsWithChildren } from "react";

export type Variant = "default" | "legacy";

const SPACING_CLASSES: { [v in Variant]: string } = {
  default: "cw-gap-1",
  legacy: "cw-gap-2",
};

type Props = {
  variant?: Variant;
};

export const ButtonSet = ({ variant = "legacy", children }: PropsWithChildren<Props>) => {
  return (
    <div className={classNames("cw-flex cw-flex-col sm:cw-flex-row", SPACING_CLASSES[variant])}>
      {children}
    </div>
  );
};
