export const getArrayOfNumbersDivisbleByN = (
  startNum: number,
  endNum: number,
  numberToDivideBy: number,
) => {
  let finalArray = [];
  for (let currentNum = startNum; currentNum <= endNum; currentNum++) {
    if (currentNum % numberToDivideBy === 0) {
      finalArray.push(currentNum);
    }
  }
  return finalArray;
};
