import pluralize from "pluralize";

// util
import { Event } from "@clockwise/schema";
import { DateTime } from "luxon";
import { SetRequired } from "../types";
import { ZonedMoment } from "./ZonedMoment";
import { validateEventTime } from "./event-time";
/**
 * Relative time.
 */
const MILLIS_IN = {
  ONE_SECOND: 1000,
  ONE_MINUTE: 60000,
  ONE_HOUR: 3600000,
  ONE_DAY: 86400000,
  ONE_WEEK: 604800000,
  ONE_MONTH: 2628000000,
  ONE_YEAR: 31540000000,
};

const dayToTwoLetterAbbreviation = {
  Sunday: "SU",
  Monday: "MO",
  Tuesday: "TU",
  Wednesday: "WE",
  Thursday: "TH",
  Friday: "FR",
  Saturday: "SA",
};

function formatRelativeTime(time: number, unit: string, isPast: boolean, abbreviate?: boolean) {
  const value = abbreviate ? `${time}${unit}` : pluralize(unit, time, true);
  return `${value} ${isPast ? "from now" : "ago"}`;
}

export function getRelativeTimeMillis(time: number, abbreviate?: boolean) {
  return getRelativeTime(new ZonedMoment(time), abbreviate);
}

export function getRelativeTime(time: ZonedMoment, abbreviate?: boolean) {
  const now = new ZonedMoment();
  // + => future, - => past
  const diffInMillis = time.diff(now);

  if (Math.abs(diffInMillis) < MILLIS_IN.ONE_SECOND) {
    // less than one second
    return "just now";
  } else if (Math.abs(diffInMillis) < MILLIS_IN.ONE_MINUTE) {
    // less than one minute
    const seconds = Math.round(Math.abs(diffInMillis) / MILLIS_IN.ONE_SECOND);
    return formatRelativeTime(
      seconds,
      `${abbreviate ? "s" : "second"}`,
      diffInMillis > 0,
      abbreviate,
    );
  } else if (Math.abs(diffInMillis) < MILLIS_IN.ONE_HOUR) {
    // less than one hour
    const minutes = Math.round(Math.abs(diffInMillis) / MILLIS_IN.ONE_MINUTE);
    return formatRelativeTime(
      minutes,
      `${abbreviate ? "min" : "minute"}`,
      diffInMillis > 0,
      abbreviate,
    );
  } else if (Math.abs(diffInMillis) < MILLIS_IN.ONE_DAY) {
    // less than one day
    const hours = Math.round(Math.abs(diffInMillis) / MILLIS_IN.ONE_HOUR);
    return formatRelativeTime(hours, `${abbreviate ? "hr" : "hour"}`, diffInMillis > 0, abbreviate);
  } else if (Math.abs(diffInMillis) < MILLIS_IN.ONE_WEEK) {
    // less than one week
    const days = Math.round(Math.abs(diffInMillis) / MILLIS_IN.ONE_DAY);
    if (days === 1) {
      return `${diffInMillis > 0 ? "tomorrow" : "yesterday"}`;
    }
    return formatRelativeTime(days, `${abbreviate ? "d" : "day"}`, diffInMillis > 0, abbreviate);
  } else if (Math.abs(diffInMillis) < MILLIS_IN.ONE_MONTH) {
    // less than one month
    const weeks = Math.round(Math.abs(diffInMillis) / MILLIS_IN.ONE_WEEK);
    return formatRelativeTime(weeks, `${abbreviate ? "wk" : "week"}`, diffInMillis > 0, abbreviate);
  } else if (Math.abs(diffInMillis) < MILLIS_IN.ONE_YEAR) {
    // less than one year
    const months = Math.round(Math.abs(diffInMillis) / MILLIS_IN.ONE_MONTH);
    return formatRelativeTime(
      months,
      `${abbreviate ? "mo" : "month"}`,
      diffInMillis > 0,
      abbreviate,
    );
  } else {
    const years = Math.round(Math.abs(diffInMillis) / MILLIS_IN.ONE_YEAR);
    return formatRelativeTime(years, `${abbreviate ? "yr" : "year"}`, diffInMillis > 0, abbreviate);
  }
}

///////////////////
// Absolute Time //
///////////////////

function formatAbsoluteTime(startTime: string, endTime?: string) {
  if (!endTime) {
    return startTime;
  }

  return `${startTime} – ${endTime}`;
}

export function getAbsoluteTime(startTime: ZonedMoment, endTime?: ZonedMoment) {
  const start = startTime.format(`h:mma`);
  const end = endTime ? endTime.format(`h:mma`) : undefined;

  return formatAbsoluteTime(start, end);
}

export function getAbsoluteDate(
  startTime: ZonedMoment,
  endTime?: ZonedMoment,
  showDay?: boolean,
  forceShowYear?: boolean,
) {
  const now = new ZonedMoment();
  const showYear = forceShowYear || !startTime.isSame(endTime || now, "y");

  const formattedStart = startTime.format(
    `${showDay ? "ddd" : ""} MMM D${showYear ? ", YYYY" : ""}`,
  );

  if (!endTime || startTime.isSame(endTime, "d")) {
    return formatAbsoluteTime(formattedStart);
  }

  const formattedEnd = endTime.format(`${showDay ? "ddd" : ""} MMM D${showYear ? ", YYYY" : ""}`);
  return formatAbsoluteTime(formattedStart, formattedEnd);
}

export function getAbsoluteDateTime(startTime: ZonedMoment, endTime?: ZonedMoment) {
  const now = new ZonedMoment();
  const showYear = !startTime.isSame(endTime || now, "y");

  if (!endTime) {
    const startWithoutEnd = startTime.format(`ddd MMM D [at] h:mma${showYear ? ", YYYY" : ""}`);
    return formatAbsoluteTime(startWithoutEnd);
  }

  const isSameDate = startTime.isSame(endTime, "d");
  const start = startTime.format(`ddd MMM D, h:mma${showYear ? ", YYYY" : ""}`);
  const end = endTime.format(`${!isSameDate ? "ddd MMM D, " : ""}h:mma${showYear ? ", YYYY" : ""}`);

  return formatAbsoluteTime(start, end);
}

export function getAbsoluteDateTimeForMinMaxString(minDate: string, maxDate: string) {
  if (!minDate || minDate.split("-").length !== 3 || !maxDate || maxDate.split("-").length !== 3) {
    console.warn(
      "getAbsoluteDateTimeForMinMaxString passed invalid strings. Min and Max dates must be provided in YYYY-MM-DD formats",
    );
    return "";
  }
  const startDate = new ZonedMoment(minDate, "UTC", "YYYY-MM-DD");
  const endDate = new ZonedMoment(maxDate, "UTC", "YYYY-MM-DD");
  return getAbsoluteDate(startDate, endDate, true);
}

/////////////////////////////
// ABSOLUTE TIME FOR EVENT
/////////////////////////////
export function getAbsoluteDateTimeForEvent(
  event: SetRequired<Partial<Event>, "startTime" | "endTime">,
) {
  const startTime = event && event.startTime;
  const endTime = event && event.endTime;

  if (!validateEventTime(startTime) || !validateEventTime(endTime)) {
    return;
  }

  if (startTime.type === "Date") {
    // special handling for dates
    const startDate = new ZonedMoment(startTime.dateOrDateTime, "UTC");
    const endDate = new ZonedMoment(endTime.dateOrDateTime, "UTC").subtract(1, "days"); // end is exclusive
    return getAbsoluteDate(startDate, endDate);
  }

  return getAbsoluteDateTime(new ZonedMoment(startTime.millis), new ZonedMoment(endTime.millis));
}

export function getAbsoluteTimeForEvent(
  event: SetRequired<Partial<Event>, "startTime" | "endTime">,
  timezone: string,
) {
  const startTime = event && event.startTime;
  const endTime = event && event.endTime;

  if (!validateEventTime(startTime) || !validateEventTime(endTime)) {
    return;
  }

  if (startTime.type === "Date") {
    // time doesn't make sense for dates
    return;
  }

  return getAbsoluteTime(
    new ZonedMoment(startTime.millis, timezone),
    new ZonedMoment(endTime.millis, timezone),
  );
}

export function getAbsoluteDateForEvent(
  event?: SetRequired<Partial<Event>, "startTime" | "endTime">,
  showDay?: boolean,
) {
  const startTime = event && event.startTime;
  const endTime = event && event.endTime;

  if (!startTime || !endTime || !validateEventTime(startTime) || !validateEventTime(endTime)) {
    return;
  }

  if (startTime.type === "Date") {
    // special handling for dates
    const startDate = new ZonedMoment(startTime.dateOrDateTime, "UTC");
    const endDate = new ZonedMoment(endTime.dateOrDateTime, "UTC").subtract(1, "days"); // end is exclusive
    return getAbsoluteDate(startDate, endDate, showDay);
  }

  return getAbsoluteDate(
    new ZonedMoment(startTime.millis),
    new ZonedMoment(endTime.millis),
    showDay,
  );
}

export const getByDayFormat = (date: DateTime, showWeekdayCount?: boolean) => {
  const weekday = date.toLocaleString({ weekday: "long" });
  let firstDayOfMonth = Math.ceil(date.day / 7);
  if (firstDayOfMonth > 4) {
    // This slightly deviates from GCals logic, they would do "last day of month", but we dont have support for that
    // So instead of "5th Monday of the month" we do "4th Monday of the month"
    firstDayOfMonth = 4;
  }
  const twoLetterWeekday =
    dayToTwoLetterAbbreviation[weekday as keyof typeof dayToTwoLetterAbbreviation];

  if (showWeekdayCount) {
    return `${firstDayOfMonth}${twoLetterWeekday}`;
  } else {
    return twoLetterWeekday;
  }
};
