import { Public } from "@clockwise/design-system/icons";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import classNames from "classnames";
import React from "react";
import { getTimeZones, normalizeZone } from "../../util/time-zone.util";

interface ITimeZoneSuggestion {
  label: string;
  value: string;
}

export enum TimeZoneDisplayMode {
  JustPicker = "JustPicker",
  PickerAndIcon = "PickerAndIcon",
}

export interface Props {
  onChange: (timeZone: string) => void;
  value?: string;
  className?: string;
  timeZoneDisplayMode?: TimeZoneDisplayMode;
}

export const TimeZonePicker = ({ onChange, value, timeZoneDisplayMode, className }: Props) => {
  const getSuggestions = (): ITimeZoneSuggestion[] => {
    const timezones = getTimeZones();
    const suggestions = Object.keys(timezones).map((t) => ({ label: t, value: timezones[t] }));

    return suggestions;
  };

  const renderPicker = (_className?: string) => {
    const suggestions = getSuggestions();
    const suggestion = suggestions.find(
      (suggestion) => value && suggestion.value === normalizeZone(value),
    );

    const isPickerAndIcon = timeZoneDisplayMode === TimeZoneDisplayMode.PickerAndIcon;

    return (
      <Autocomplete
        aria-label="your timezone"
        className={classNames("cw-flex-1", _className)}
        options={suggestions}
        getOptionLabel={(option: ITimeZoneSuggestion) => option.label}
        value={suggestion}
        autoHighlight={true}
        fullWidth
        disableClearable
        classes={{
          inputRoot:
            "cw-py-1 cw-pl-2 cw-pr-6 cw-text-13 cw-border cw-border-solid cw-border-default cw-rounded cw-bg-default",
          option: "cw-text-13",
        }}
        style={{ maxWidth: isPickerAndIcon ? "300px" : "450px" }}
        renderInput={(params) => (
          <TextField
            {...params}
            multiline={!isPickerAndIcon}
            variant="filled"
            className="cw-mr-2"
          />
        )}
        onChange={(_e, suggestion: ITimeZoneSuggestion) => {
          if (suggestion) {
            onChange(suggestion.value);
          }
        }}
      />
    );
  };

  const renderPickerAndIcon = () => {
    return (
      <div
        className={classNames(
          "cw-flex cw-flex-row cw-items-center cw-gap-4 cw-text-default",
          className,
        )}
      >
        <Public />
        {renderPicker()}
      </div>
    );
  };

  switch (timeZoneDisplayMode) {
    case TimeZoneDisplayMode.PickerAndIcon:
      return renderPickerAndIcon();
    case TimeZoneDisplayMode.JustPicker:
    default:
      return renderPicker(className);
  }
};
