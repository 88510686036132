import { getPreviousWeekday } from "@clockwise/web-commons/src/util/getPreviousWeekday";
import { difference, isEmpty, keys, uniq } from "lodash";
import { DateTime, DurationLike } from "luxon";
import { useCallback, useMemo } from "react";
import { useFeatureFlag } from "../../../launch-darkly";
import { PlannerEventCardsByDay } from "../types";
import { useWorkingLocationEvents } from "./useWorkingLocationEvents";
import { useWorkingLocationMultiCal } from "./useWorkingLocationMultiCal";

export const useWorkingLocationEventCards = (
  date: string,
  calendarIds: string[],
  ownCalendarIds: string[],
) => {
  const [isOnWorkingLocation] = useFeatureFlag("WorkingLocation");

  const weekStartDate = useMemo(
    () => getPreviousWeekday(DateTime.fromISO(date), "sunday").toISODate(),
    [date],
  );

  const otherCalendarIds = useMemo(() => {
    return difference(calendarIds, ownCalendarIds);
  }, [calendarIds, ownCalendarIds]);

  const {
    workingLocationsByDay,
    loading,
    error,
    startPolling,
    stopPolling,
    refetch,
    fetchMore: fetchMore,
  } = useWorkingLocationEvents(ownCalendarIds, weekStartDate, isOnWorkingLocation);

  const {
    workingLocationsByDay: multiCalWorkingLocationsByDay,
    loading: loadingMultiCal,
    prefetch: prefetchMultiCal,
  } = useWorkingLocationMultiCal(otherCalendarIds, weekStartDate, isOnWorkingLocation);

  const refetchAndstartPolling = useCallback(
    (time: number) => {
      void refetch();
      startPolling(time);
    },
    [refetch, startPolling],
  );

  const prefetch = useCallback(
    (relativeDuration: DurationLike) => {
      const newWeekStartDate = getPreviousWeekday(
        DateTime.fromISO(weekStartDate).plus(relativeDuration),
        "sunday",
      ).toISODate();

      void fetchMore({
        variables: {
          weekStartDate: newWeekStartDate,
        },
      });
      if (!isEmpty(otherCalendarIds)) {
        void prefetchMultiCal(relativeDuration);
      }
    },
    [fetchMore, otherCalendarIds, prefetchMultiCal, weekStartDate],
  );

  const allWorkingLocationsByDay = useMemo(() => {
    if (isEmpty(multiCalWorkingLocationsByDay)) {
      return workingLocationsByDay;
    } else {
      const allWorkingLocationsByDay: PlannerEventCardsByDay = {};

      const dates = uniq([...keys(workingLocationsByDay), ...keys(multiCalWorkingLocationsByDay)]);
      dates.forEach((date) => {
        const multiCalCardsForDay = multiCalWorkingLocationsByDay[date] ?? [];
        const ownerCardsForDay = workingLocationsByDay[date] ?? [];
        allWorkingLocationsByDay[date] = ownerCardsForDay.concat(multiCalCardsForDay);
      });

      return allWorkingLocationsByDay;
    }
  }, [workingLocationsByDay, multiCalWorkingLocationsByDay]);

  return useMemo(
    () => ({
      allWorkingLocationsByDay,
      loading,
      loadingMultiCal,
      error,
      refetch: () => void refetch(),
      prefetch,
      startPolling: refetchAndstartPolling,
      stopPolling,
    }),
    [allWorkingLocationsByDay, error, loading, refetch, refetchAndstartPolling, stopPolling],
  );
};
