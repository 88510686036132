import { TypePolicies } from "@apollo/client";

/**
 * Inform the Apollo cache how to merge certain fields on certain types, so that `fetchMore`
 * behavior works correctly.
 */
export const typePolicies: TypePolicies = {
  AddDiffSummary: {
    fields: {
      action: {
        merge: true,
      },
    },
  },
  ModifyDiffSummary: {
    fields: {
      action: {
        merge: true,
      },
    },
  },
  YourConsequenceDiffSummary: {
    fields: {
      action: {
        merge: true,
      },
    },
  },
  OthersConsequenceDiffSummary: {
    fields: {
      action: {
        merge: true,
      },
    },
  },
  ProposalAttendees: {
    merge: true,
  },
  Event: {
    merge: true,
  },
  EventTime: {
    merge: true,
  },
  FeatureGrid: {
    merge: true,
  },
};
