import { OnboardingGetToKnowYouStepQuestion } from "./step-components/get-to-know-you-step/GetToKnowYouStep.util";

export enum FunnelType {
  None = "None",
  Meeter = "Meeter",
  ExternalMeeter = "ExternalMeeter",
}

export enum WebOnboardingStep {
  GetToKnowYou = "GetToKnowYou",
  TeamJoinCreate = "TeamJoinCreate",
  AIScheduler = "AIScheduler",
  Links = "Links",
  Invite = "Invite",
  FlexMeeting = "FlexMeeting",
  FocusTime = "FocusTime",
  Lunch = "Lunch",
  DownloadClockwise = "DownloadClockwise",
  Slack = "Slack",
  Schedule = "Schedule",
  Defrag = "Defrag",
  Finished = "Finished",
}

export const getFlowStateEnumFromString = (flowState: string) => {
  if (flowState in WebOnboardingStep) {
    return flowState as WebOnboardingStep;
  }
  return WebOnboardingStep.GetToKnowYou;
};

export const WebOnboardingStepMeeterList = [
  WebOnboardingStep.GetToKnowYou,
  WebOnboardingStep.TeamJoinCreate,
  WebOnboardingStep.AIScheduler,
  // Schedule Start
  WebOnboardingStep.Schedule,
  WebOnboardingStep.Links,
  // Automate Start
  WebOnboardingStep.Defrag,
  WebOnboardingStep.FlexMeeting,
  WebOnboardingStep.FocusTime,
  WebOnboardingStep.Lunch,
  WebOnboardingStep.Invite,
  // Hook Start
  WebOnboardingStep.DownloadClockwise,
  WebOnboardingStep.Slack,
  WebOnboardingStep.Finished,
];

export const WebOnboardingExternalMeeterList = [
  WebOnboardingStep.GetToKnowYou,
  WebOnboardingStep.TeamJoinCreate,
  WebOnboardingStep.AIScheduler,
  // Schedule Start
  WebOnboardingStep.Links,
  WebOnboardingStep.Schedule,
  // Automate Start
  WebOnboardingStep.Defrag,
  WebOnboardingStep.FlexMeeting,
  WebOnboardingStep.FocusTime,
  WebOnboardingStep.Lunch,
  WebOnboardingStep.Invite,
  // Hook Start
  WebOnboardingStep.DownloadClockwise,
  WebOnboardingStep.Slack,
  WebOnboardingStep.Finished,
];

export const skipableWebOnboardingStepMap = {
  [WebOnboardingStep.GetToKnowYou]: false,
  [WebOnboardingStep.TeamJoinCreate]: false,
  [WebOnboardingStep.AIScheduler]: false,
  [WebOnboardingStep.Links]: false,
  [WebOnboardingStep.FlexMeeting]: false,
  [WebOnboardingStep.FocusTime]: false,
  [WebOnboardingStep.Lunch]: false,
  [WebOnboardingStep.Invite]: false,
  [WebOnboardingStep.DownloadClockwise]: true,
  [WebOnboardingStep.Slack]: false,
  [WebOnboardingStep.Finished]: false,
  [WebOnboardingStep.Schedule]: false,
  [WebOnboardingStep.Defrag]: false,
};

export type StepComponentMapType = {
  [key in WebOnboardingStep]: JSX.Element;
};

export const getOrderingOfOnboarding = (surveyResponse: OnboardingGetToKnowYouStepQuestion) => {
  if (surveyResponse.responseType === "ScheduleExternalMeetingsSeamlessly") {
    return WebOnboardingExternalMeeterList;
  } else {
    return WebOnboardingStepMeeterList;
  }
};

export const getFunnelType = (surveyResponse: OnboardingGetToKnowYouStepQuestion) => {
  if (surveyResponse.responseType === "ScheduleExternalMeetingsSeamlessly") {
    return FunnelType.ExternalMeeter;
  } else {
    return FunnelType.Meeter;
  }
};
