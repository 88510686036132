import { Listbox, Portal, Transition } from "@headlessui/react";
import classNames from "classnames";
import { isBoolean } from "lodash";
import React, { PropsWithChildren, useMemo, useRef, useState } from "react";
import { usePopper } from "react-popper";
import {
  EXPAND_SELECT_ICON_CLASS,
  INPUT_BACKGROUND_CLASS,
  INPUT_BORDER_CLASS,
  INPUT_BORDER_COLOR_CLASS,
  INPUT_BORDER_RADIUS_CLASS,
  INPUT_BORDER_SHADOW_CLASS,
  INPUT_CONTENT_SPACING_CLASS,
  INPUT_HEIGHT_CLASS,
  INPUT_ICON_SIZES,
  INPUT_MIN_WIDTH_CLASS,
  INPUT_PADDING_CLASS,
  INPUT_TEXT_CLASS,
  getInputDisabledClassNames,
  getInputErrorClassNames,
  getInputFullWidthClassNames,
} from "../constants/classes";
import { MENU_Z_INDEX } from "../constants/consts";
import { Size, Variant as _Variant } from "../constants/types";
import { CwIdProps } from "../types/cw-id";
import { ExpandMore } from "./Icons";
import { SelectOption, SelectOptionsGroup } from "./SelectOptions";

export type Variant = Extract<_Variant, "default" | "inline" | "elevated">;

export interface SelectProps<V> extends CwIdProps {
  label?: string;
  hideLabel?: boolean;
  value: V;
  name?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: Size;
  variant?: Variant;
  showExpandIcon?: boolean | "default";
  error?: boolean;
  errorMessage?: string | null;
  iconOnly?: boolean;
  hideIcon?: boolean;
  onChange: (value: V) => void;
}

export function Select<V>({
  value: selectedValue,
  name,
  hideIcon = false,
  disabled = false,
  fullWidth = false,
  variant = "default",
  size = "medium",
  error = false,
  errorMessage = null,
  showExpandIcon = "default",
  onChange,
  "cw-id": cwId,
  iconOnly = false,
  children,
  label,
  hideLabel = false,
}: PropsWithChildren<SelectProps<V>>) {
  // Chevron visibility states are varied depending on variant. Users preference is king, but if there are no specifications:
  // Inline variant will not show the chevron, but default and elevated will.
  const showChevronBasedOnVariant = () => {
    if (isBoolean(showExpandIcon)) return showExpandIcon;
    return variant === "inline" ? false : true;
  };

  const wasOpen = useRef(false);
  const [trigger, setTrigger] = useState<HTMLButtonElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update: updatePopperInstance } = usePopper(trigger, container, {
    placement: "bottom-start",
    strategy: "fixed",
    modifiers: [
      { name: "flip", enabled: true },
      { name: "offset", options: { offset: [0, 4] } },
    ],
  });

  const selectedOption = useMemo(() => {
    if (!children) return;
    let result: React.ReactElement | undefined;
    const arrayOfChildren: any = [];
    React.Children.forEach(children, (child) => {
      if (child && React.isValidElement(child)) {
        if (typeof child.type !== "string" && child.type.name === SelectOption.name) {
          // find single ungrouped options
          arrayOfChildren.push(child);
        } else if (
          // find grouped options
          typeof child.type !== "string" &&
          child.type.name === SelectOptionsGroup.name &&
          child.props.children.length > 0
        ) {
          child.props.children.forEach((nestedChild: any) => {
            if (
              nestedChild &&
              React.isValidElement(nestedChild) &&
              typeof nestedChild.type !== "string" &&
              nestedChild.type.name === SelectOption.name
            ) {
              arrayOfChildren.push(nestedChild);
            }
          });
        } else if (typeof child.type !== "string" && child.type.name === SelectOptionsGroup.name) {
          // find grouped single options
          if (
            child.props.children.type &&
            typeof child.props.children.type !== "string" &&
            child.props.children.type.name &&
            child.props.children.type.name === SelectOption.name
          ) {
            arrayOfChildren.push(child.props.children);
          }
        }
      }
    });
    arrayOfChildren.forEach((child: any, index: number) => {
      if (index === 0) {
        result = child;
      }
      if (child.props.value === selectedValue) {
        result = child;
      }
    });
    return result;
  }, [children, selectedValue]);

  const onOptionSelect = (value: V) => {
    onChange(value);
  };

  const iconForSelect = useMemo(() => {
    let SelectedIcon;
    let propsForIcon;
    if (selectedOption) {
      SelectedIcon = selectedOption?.props?.icon;
      propsForIcon = selectedOption?.props?.iconProps;
    }
    return (
      SelectedIcon && (
        <SelectedIcon
          {...propsForIcon}
          className={classNames(INPUT_ICON_SIZES[size], propsForIcon?.className)}
        />
      )
    );
  }, [size, selectedOption]);

  const buttonContent =
    (selectedOption && selectedOption?.props.children) || selectedOption?.props.value;

  return (
    <Listbox
      value={selectedValue}
      onChange={onOptionSelect}
      cw-id={cwId}
      name={name}
      disabled={disabled}
      as="div"
      className={classNames(
        { "cw-w-full": fullWidth, "cw-cursor-not-allowed": disabled },
        "cw-max-w-full cw-relative",
      )}
    >
      {({ open }) => {
        /**
         * When the menu first transitions open, we need to tell Popper to update to ensure that the
         * menu is positioned correctly, because it won't be able to know how big the menu is until
         * it is open, and therefore won't know whether it can't fit into the current viewport in
         * the standard orientation.
         */
        if (open && !wasOpen.current) {
          wasOpen.current = true;
          setTimeout(() => {
            void updatePopperInstance?.();
          }, 0);
        } else if (!open && wasOpen) {
          wasOpen.current = false;
        }
        return (
          <>
            <div className="cw-font-body cw-max-w-full cw-relative">
              {label && hideLabel && <Listbox.Label className="cw-sr-only">{label}</Listbox.Label>}
              {label && !hideLabel && (
                <Listbox.Label className="cw-block cw-text-12 cw-font-body cw-font-semibold cw-text-default cw-mb-1">
                  {label}
                </Listbox.Label>
              )}
              <Listbox.Button
                ref={setTrigger}
                className={classNames(
                  INPUT_TEXT_CLASS[size],
                  INPUT_HEIGHT_CLASS[size],
                  INPUT_MIN_WIDTH_CLASS[size],
                  INPUT_BORDER_RADIUS_CLASS[size],
                  INPUT_BORDER_CLASS,
                  INPUT_BORDER_COLOR_CLASS[variant]["neutral"],
                  INPUT_BORDER_SHADOW_CLASS[variant],
                  INPUT_BACKGROUND_CLASS[variant]["neutral"],
                  INPUT_PADDING_CLASS[variant][size],
                  getInputDisabledClassNames(!!disabled),
                  getInputFullWidthClassNames(!!fullWidth),
                  getInputErrorClassNames(!!error),
                  "cw-max-w-full cw-flex cw-justify-center cw-items-center",
                )}
              >
                <div className="cw-block cw-truncate cw-w-full">
                  <div
                    className={classNames(
                      INPUT_CONTENT_SPACING_CLASS[size],
                      "cw-flex cw-items-center cw-justify-between",
                    )}
                  >
                    <div className="cw-flex cw-items-center cw-gap-1">
                      {!hideIcon && iconForSelect}
                      {!iconOnly && buttonContent}
                    </div>
                    {showChevronBasedOnVariant() && (
                      <ExpandMore
                        className={classNames(EXPAND_SELECT_ICON_CLASS[size])}
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </div>
              </Listbox.Button>

              <Portal>
                <div
                  ref={setContainer}
                  style={{
                    ...styles.popper,
                    minWidth: trigger?.scrollWidth,
                    zIndex: MENU_Z_INDEX,
                  }}
                  {...attributes.popper}
                >
                  <Transition
                    leave="cw-transition cw-ease-in cw-duration-100"
                    leaveFrom="cw-opacity-100"
                    leaveTo="cw-opacity-0"
                    enter="cw-transition cw-ease-in cw-duration-100"
                    enterFrom="cw-opacity-0"
                    enterTo="cw-opacity-100"
                  >
                    <Listbox.Options
                      className={classNames(
                        "cw-z-50 cw-min-w-full cw-max-h-[315px] cw-p-1 cw-overflow-auto",
                        "cw-font-body cw-font-normal cw-text-13 cw-bg-default cw-rounded-lg cw-shadow-selectPopup cw-min-w-[180px]",
                      )}
                    >
                      {children}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Portal>
            </div>
            {error && errorMessage && (
              <div className="cw-text-destructive cw-text-12 cw-font-body cw-mt-1 cw-max-w-full">
                {errorMessage}
              </div>
            )}
          </>
        );
      }}
    </Listbox>
  );
}
