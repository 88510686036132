import classNames from "classnames";
import * as React from "react";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

/**
 * @deprecated Prefer tailwind classes (e.g., cw-heading-sm)
 */
export const H3 = ({ className, style, children }: Props) => (
  <div className={classNames("cw-heading-sm cw-mb-1", className)} style={style}>
    {children}
  </div>
);
