import { graphql } from "react-relay";

export const createTeamMutation = graphql`
  mutation CreateTeamMutation($input: createTeamMutationInput!) {
    createTeam(input: $input) {
      clientMutationId
      team {
        __typename
        id
        teamId
        teamName
        teamCalendarId
        createdBy {
          id
          type
          value
        }
        teamMembers {
          role
          person {
            id
            userId
            personId
            primaryCalendarId
            calendarAliases
            redirectPersonId
            profile {
              givenName
              familyName
              externalImageUrl
            }
          }
        }
        invitedMembers {
          role
          person {
            id
            userId
            personId
            primaryCalendarId
            calendarAliases
            redirectPersonId
            profile {
              givenName
              familyName
              externalImageUrl
            }
          }
        }
        userSettings {
          __typename
          ... on TeamUserSettings {
            useTeamCalendar
            useNoMeetingDay
          }
        }
        settings {
          __typename
          ... on TeamSettings {
            manageTeamCalendar
            slackSettings {
              notificationEnabled
              channelId
              channelName
              timeSlot
            }
            syncWFHEvents
            minimumMinutesForSyncing
            useNoMeetingDay
            noMeetingDayOfWeek
            noMeetingDayEmailOnScheduleOver
          }
        }
      }
    }
  }
`;
