///////////////////////
// IMPORTS
///////////////////////
import { jwt, xsrf } from "#webapp/src/state/local-storage";
import {
  CacheConfig,
  GraphQLResponse,
  Observable,
  RequestParameters,
  Variables,
} from "relay-runtime";

import { Observer, SubscriptionClient } from "subscriptions-transport-ws";

import { logger } from "#webapp/src/util/logger.util";
import { getSocketUrl } from "@clockwise/client-commons/src/config/api";

///////////////////////
// TYPES
///////////////////////

// what we pass into the subscription layer class on create
// mirrors our socket, jwt, and xsrf objects
interface ISubLayerConfig {
  subscriptionPath: string;
  jwtInterface: typeof jwt;
  xsrfInterface: typeof xsrf;
}

type SubscribeFn = (
  operation: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig,
) => Observable<GraphQLResponse>;

///////////////////////
// SUBSCRIPTION LOGIC
///////////////////////

class ObserverAdapter<T> implements Observer<T> {
  constructor(
    public next: (value: T) => void,
    public error: (error: Error) => void,
    public complete: () => void,
  ) {}
}

let connectReason: string | null = "Initial Connect";
let connectCount = 0;

// derived from: https://github.com/apollographql/subscriptions-transport-ws/issues/342

/** Returns a SubscribeFn for use by Relay Modern Network Layer */
export function getSubscriptionHandler(subLayerConfig: ISubLayerConfig): SubscribeFn {
  // strip leading and trailing slashes
  const normalizedPath = subLayerConfig.subscriptionPath.replace(/^\//, "").replace(/\/$/, "");

  const subscriptionUrl = `${getSocketUrl()}/${normalizedPath}`;

  const subscriptionClient = new SubscriptionClient(subscriptionUrl, {
    lazy: true, // only connect when needed
    reconnect: true,
    connectionParams: () => ({
      Authorization:
        subLayerConfig.jwtInterface.get() && `Bearer ${subLayerConfig.jwtInterface.get()}`,
      Xsrf: subLayerConfig.xsrfInterface.get(),
      ConnectReason: connectReason,
      ConnectCount: connectCount,
    }),
    connectionCallback: (error) => {
      if (error) {
        console.error("Error connecting to subscriptions", error);
      }

      connectReason = null;
      ++connectCount;
    },
  });

  const addConnectReason = (newReason: string) => {
    connectReason = !!connectReason ? `${newReason} - ${connectReason}` : newReason;
  };

  const reconnect = (reason: string) => {
    addConnectReason(reason);

    // NB: this only works because reconnect: true is set in the subscriptionClient config
    logger.info("subscription-handler reconnect");
    return subscriptionClient.close(false, false);
  };

  // TODO: maybe debounce these
  subLayerConfig.jwtInterface.onChange(() => {
    logger.info("subscription-handler subLayerConfig jwtInterface onChange fired, reconnecting...");
    reconnect("Jwt Changed");
  });
  subLayerConfig.xsrfInterface.onChange(() => {
    logger.info(
      "subscription-handler subLayerConfig xsrfInterface onChange fired, reconnecting...",
    );
    reconnect("Xsrf Changed");
  });

  subscriptionClient.onDisconnected(() => {
    addConnectReason(`Disconnected by user? ${subscriptionClient["closedByUser"]}`);
  });

  subscriptionClient.onConnecting(() => {
    addConnectReason("client is connecting");
  });

  subscriptionClient.onReconnecting(() => {
    addConnectReason("client is reconnecting");
  });

  return (operation: RequestParameters, variables: Variables, _: CacheConfig) => {
    const query = operation.text!;

    return Observable.create(
      (sink) =>
        subscriptionClient
          .request({
            query,
            variables,
          })
          .subscribe(new ObserverAdapter(sink.next as any, sink.error, sink.complete)).unsubscribe,
    );
  };
}
