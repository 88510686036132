import { colors } from "@clockwise/web-commons/src/styles/color.styles";
import { CSSProperties } from "react";

const teamSizeWarning: CSSProperties = {
  display: "flex",
  alignItems: "center",
  margin: "15px 12px",
};

const warningIcon: CSSProperties = {
  color: colors.peach.darker,
  marginRight: 5,
  width: 16,
};

const warningLabel: CSSProperties = {
  flexGrow: 1,
  fontSize: 12,
  color: colors.peach.darker,
  paddingTop: 2,
};

export const styles = {
  teamSizeWarning,
  warningIcon,
  warningLabel,
};
