import { RadioGroup as HeadlessRadioGroup } from "@headlessui/react";
import classNames from "classnames";
import React, { MouseEventHandler } from "react";
import { CwIdProps } from "../types/cw-id";
import { CheckCircle, RadioButtonChecked, RadioButtonUnchecked, SvgIconComponent } from "./Icons";
import { RadioGroupContext, Sentiment, Size, Variant } from "./RadioGroup";

// type Sentiment = "neutral" | "warning";
// type Variant = "outlined" | "default";

const colorClassesBySentiment: {
  [S in Sentiment]: { icon: string; border: string; hover: string; focus: string; active: string };
} = {
  neutral: {
    icon: "cw-text-positive",
    border: "cw-border-positive hover:cw-border-positive-hover",
    hover: "group-hover:cw-bg-positive-emphasis/5",
    active: "group-active:cw-bg-positive-emphasis/30",
    focus: "group-focus-visible:cw-bg-positive-emphasis/30",
  },
  warning: {
    icon: "cw-text-warning",
    border: "cw-border-warning hover:cw-border-warning-hover",
    hover: "group-hover:cw-bg-warning-emphasis/5",
    active: "group-active:cw-bg-warning-emphasis/30",
    focus: "group-focus-visible:cw-bg-warning-emphasis/30",
  },
};

interface RadioIconProps {
  checked: boolean;
  disabled: boolean;
  sentiment: Sentiment;
  icon: SvgIconComponent;
  size: Size;
}

const ICON_SIZE: Record<Size, string> = {
  small: "cw-h-4 cw-w-4 cw-text-[16px]",
  large: "cw-h-6 cw-w-6 cw-text-[24px]",
};

const RadioIcon = ({ icon: IconClass, sentiment, size, disabled, checked }: RadioIconProps) => {
  const iconColor = disabled
    ? "cw-text-neutral-disabled"
    : checked
    ? colorClassesBySentiment[sentiment].icon
    : "cw-text-neutral";
  const hoverColor = checked
    ? colorClassesBySentiment[sentiment].hover
    : "group-hover:cw-bg-neutral-emphasis/5";
  const activeColor = checked
    ? colorClassesBySentiment[sentiment].active
    : "group-active:cw-bg-neutral-emphasis/30";
  const focusColor = checked
    ? colorClassesBySentiment[sentiment].focus
    : "group-focus-visible:cw-bg-neutral-emphasis/30";

  const iconSize = ICON_SIZE[size];
  return (
    <div
      className={classNames(
        "cw-inline-flex cw-shrink-0 cw-items-center cw-justify-center",
        "cw-text-center",
        "cw-my-2",
        "cw-relative",
        "cw-cursor-pointer",
        {
          "cw-pointer-events-none": disabled,
        },
      )}
    >
      {/* Invisible interaction target for easier clicking */}
      <span aria-hidden="true" className="cw-absolute -cw-inset-2 cw-bg-transparent" />
      {/* Radio icon aura */}
      <span
        aria-hidden="true"
        className={classNames(
          "cw-absolute cw-inset-0",
          "-cw-m-2",
          "cw-rounded-full",
          "cw-bg-transparent",
          hoverColor,
          activeColor,
          focusColor,
          "cw-pointer-events-none",
          "cw-transition cw-duration-200 cw-ease-in-out",
        )}
      />
      {/* Radio icon */}
      <IconClass className={classNames("cw-relative cw-leading-none", iconColor, iconSize)} />
    </div>
  );
};

export interface Props<V> extends CwIdProps {
  value: V;
  onClick?: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  label: React.ReactNode;
  sentiment?: Sentiment;
  variant?: Variant;
  size?: Size;
}

type OptionVariantProps<V> = Props<V> & { sentiment: Sentiment; size: Size };

const OutlinedRadioOption = <V extends string | number>({
  value,
  disabled: disabledRadio = false,
  label,
  sentiment,
  size,
  ...props
}: OptionVariantProps<V>) => {
  return (
    <HeadlessRadioGroup.Option as={React.Fragment} disabled={disabledRadio} value={value}>
      {({ checked, disabled: disabledGroup }) => {
        const disabled = disabledRadio || disabledGroup;
        const IconClass = checked ? CheckCircle : RadioButtonUnchecked;

        let borderColor: string;
        if (disabled) {
          borderColor = "cw-border-neutral-disabled";
        } else if (checked) {
          borderColor = colorClassesBySentiment[sentiment].border;
        } else {
          borderColor = "cw-border-neutral-interactable hover:cw-border-neutral-interactable-hover";
        }

        return (
          <div
            className={classNames(
              "cw-group",
              "cw-px-4 cw-py-2",
              "cw-my-2",
              "cw-border cw-border-solid cw-rounded-lg",
              "cw-transition cw-duration-200 cw-ease-in-out",
              borderColor,
              "cw-flex cw-items-center cw-gap-3",
              { "cw-cursor-pointer": !disabled, "cw-pointer-events-none": disabled },
            )}
            {...props}
          >
            <RadioIcon
              icon={IconClass}
              checked={checked}
              sentiment={sentiment}
              size={size}
              disabled={disabled}
            />
            <span
              className={classNames("cw-text-left cw-flex-1", {
                "cw-text-neutral-disabled": disabled,
                "cw-body-sm": size === "small",
                "cw-body-lg": size === "large",
              })}
            >
              {label}
            </span>
          </div>
        );
      }}
    </HeadlessRadioGroup.Option>
  );
};

const DefaultRadioOption = <V extends string | number>({
  value,
  disabled: disabledRadio = false,
  label,
  sentiment,
  size,
  ...props
}: OptionVariantProps<V>) => {
  return (
    <HeadlessRadioGroup.Option as={React.Fragment} disabled={disabledRadio} value={value}>
      {({ checked, disabled: disabledGroup }) => {
        const disabled = disabledRadio || disabledGroup;
        const IconClass = checked ? RadioButtonChecked : RadioButtonUnchecked;

        return (
          <div
            className={classNames("cw-group", "cw-flex cw-items-center cw-gap-3", {
              "cw-cursor-pointer": !disabled,
              "cw-pointer-events-none": disabled,
            })}
            {...props}
          >
            <RadioIcon
              icon={IconClass}
              checked={checked}
              sentiment={sentiment}
              size={size}
              disabled={disabled}
            />
            <span
              className={classNames("cw-text-left cw-flex-1", {
                "cw-text-neutral-disabled": disabled,
                "cw-body-sm": size === "small",
                "cw-body-lg": size === "large",
              })}
            >
              {label}
            </span>
          </div>
        );
      }}
    </HeadlessRadioGroup.Option>
  );
};

export const RadioOption = <V extends string | number>({
  variant: optionVariant,
  sentiment: optionSentiment,
  size: optionSize,
  ...radioProps
}: Props<V>) => {
  const { variant: groupVariant, sentiment: groupSentiment, size: groupSize } = React.useContext(
    RadioGroupContext,
  );
  const sentiment = optionSentiment || groupSentiment;
  const variant = optionVariant || groupVariant;
  const size = optionSize || groupSize;

  const RadioOptionComponent = variant === "outlined" ? OutlinedRadioOption : DefaultRadioOption;

  return <RadioOptionComponent sentiment={sentiment} size={size} {...radioProps} />;
};
