export const EXPERIMENT_FLAGS = {
  disableHealthChecks: "DisableHealthChecks",
  trackLogging: "TrackLogging",

  M365UnhideUI: "M365UnhideUI",
  MultiCalendarSelect: "MultiCalendarSelect",
  DebugMini: "DebugMini",
} as const;

export type ExperimentNameType = typeof EXPERIMENT_FLAGS[keyof typeof EXPERIMENT_FLAGS];
export type ExperimentMappingType = {
  [key in ExperimentNameType]: boolean;
};
