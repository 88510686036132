import * as ISchema from "#webapp/src/__schema__";
import { SpringScaleGrow } from "#webapp/src/components/spring-animations";
import { TeamSettingsSearch } from "#webapp/src/components/teams-settings/team-settings-search";
import { Button } from "@clockwise/design-system";
import React from "react";
import { InviteModalMode } from "./";
import { InviteModalCopy } from "./InviteModalCopy";
const EMPTY_ARRAY: [] = [];
const EMPTY_OBJECT = {};

const InviteLinkCopier = ({
  onClickCopyLink,
  linkCopied,
  inviteUrl,
}: {
  onClickCopyLink: () => void;
  linkCopied: boolean;
  inviteUrl?: string;
}) => {
  return inviteUrl ? (
    <>
      <h2 className="cw-heading-sm cw-pl-3">Share Clockwise via link</h2>
      <div className="cw-flex cw-mr-3 cw-mb-7 cw-mt-2 cw-pl-3 cw-ml-3 cw-pr-2 cw-justify-between cw-items-center cw-rounded-md cw-h-11 cw-bg-[#e0e0e033] cw-box-border cw-border cw-border-solid cw-border-[#e0e0e0] cw-w-[500px]">
        <p className="cw-text-16 cw-text-muted cw-font-normal cw-overflow-hidden cw-text-ellipsis">
          {inviteUrl}
        </p>
        {linkCopied ? (
          <Button sentiment="positive" onClick={onClickCopyLink}>
            <SpringScaleGrow className="cw-text-white">Link Copied</SpringScaleGrow>
          </Button>
        ) : (
          <Button sentiment="positive" onClick={onClickCopyLink}>
            Copy Link
          </Button>
        )}
      </div>
    </>
  ) : null;
};

export const InviteForms = ({
  linkCopied,
  onClickCopyLink,
  inviteUrl,
  currentOrg,
  onCheck,
  mode,
}: {
  linkCopied: boolean;
  onClickCopyLink: () => void;
  currentOrg: ISchema.IOrg | null;
  onCheck: (person: ISchema.IOrgPerson, checked: boolean) => void;
  mode: InviteModalMode;
  inviteUrl?: string;
}) => {
  return (
    <div className="cw-pt-12 cw-pb-[84px] cw-pr-10 cw-pl-[28px]">
      <h2 className="cw-heading-2xl cw-pl-3">{InviteModalCopy[mode].header}</h2>
      <div className="cw-leading-6 cw-my-5 cw-pl-3">{InviteModalCopy[mode].description}</div>
      {mode === InviteModalMode.Invite && (
        <InviteLinkCopier
          linkCopied={linkCopied}
          onClickCopyLink={onClickCopyLink}
          inviteUrl={inviteUrl}
        />
      )}
      {currentOrg && (
        <>
          <h2 className="cw-heading-sm cw-pl-3">{InviteModalCopy[mode].searchLabel}</h2>
          <div className="cw-min-h-[440px]">
            <TeamSettingsSearch
              mode="orgInvite"
              org={currentOrg}
              team={null}
              defaultMembers={EMPTY_ARRAY}
              invitedMembers={EMPTY_OBJECT}
              onCheck={onCheck}
            />
          </div>
        </>
      )}
    </div>
  );
};
