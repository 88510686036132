import { IOrg, IOrgPerson, ITeam, SubscriptionInterval } from "#webapp/src/__schema__";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Address } from "@stripe/stripe-js";
import { useState } from "react";

import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import {
  getOrgBillingGroup,
  getSubscriptionFromBillingGroup,
} from "#webapp/src/util/billing-group.util";
import { logger } from "#webapp/src/util/logger.util";
import {
  generateStringFromStripeErrorCode,
  isSubscriptionProductTypePro,
  isTeamInPaidSubscription,
} from "#webapp/src/util/pricing.util";

import { useMutation } from "@apollo/client";
import { useMonetization } from "../../../../hooks/useMonetization";
import { CheckoutFormRoute } from "../MixedCheckoutForm";
import { CreateOrgSubscriptionStripeDocument } from "../mutations/__generated__/CreateOrgSubscriptionStripe.generated";
import { CreateTeamAndTeamSubscriptionStripeDocument } from "../mutations/__generated__/CreateTeamAndTeamSubscriptionStripe.generated";
import { CreateTeamSubscriptionStripeDocument } from "../mutations/__generated__/CreateTeamSubscriptionStripe.generated";
import { DeleteTeamDocument } from "../mutations/__generated__/DeleteTeam.generated";

interface Props {
  org: IOrg;
  team: ITeam | null;
  selectedBillingRecurrence: SubscriptionInterval;
  paidSeatCount: number;
  currentUserInfo: IOrgPerson | null;
  selectedUserIds: { userId: string; isSuggested?: boolean }[]; // clean up type
  setRoute: (route: CheckoutFormRoute) => void;
}
export const useCreateSubscription = ({
  org,
  team,
  selectedBillingRecurrence,
  paidSeatCount,
  currentUserInfo,
  selectedUserIds,
  setRoute,
}: Props) => {
  const [billingAddress, setBillingAddress] = useState<Address>({
    city: "", // City, district, suburb, town, or village.
    country: "US", // 2-letter country code
    line1: "", // Address line 1 (e.g., street, PO Box, or company name).
    line2: "", // Address line 2 (e.g., apartment, suite, unit, or building).
    postal_code: "", // ZIP or postal code.
    state: "", // State, county, province, or region.
  });

  const [checkoutError, setCheckoutError] = useState<string>();
  const [stripeError, setStripeError] = useState<string>();
  const [paying, setPaying] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const { refetchFeatureGrid } = useMonetization();

  const [createOrgSubscriptionStripe] = useMutation(CreateOrgSubscriptionStripeDocument, {
    onCompleted: (data) => {
      setPaying(false);
      const requestStripeError = data?.createOrgSubscriptionStripe?.stripeError;
      if (requestStripeError) {
        setStripeError(generateStringFromStripeErrorCode(requestStripeError));
        return logger.error(
          `failed to createOrgSubscriptionStripe because of stripe error: ${requestStripeError?.code}`,
        );
      }
      refetchFeatureGrid();
      setRoute(CheckoutFormRoute.PaymentConfirmation);
    },
    onError: (error) => {
      setPaying(false);
      logger.error(`failed to createOrgSubscriptionStripe: ${error}`);
      setCheckoutError(`Failed to upgrade. Please try again.`);
    },
  });

  const [deleteTeam] = useMutation(DeleteTeamDocument);

  const [createTeamAndTeamSubscriptionStripe] = useMutation(
    CreateTeamAndTeamSubscriptionStripeDocument,
    {
      onCompleted: (data) => {
        const requestStripeError = data?.createTeamAndTeamSubscriptionStripe?.stripeError;
        if (requestStripeError) {
          if (data?.createTeamAndTeamSubscriptionStripe?.team?.teamId) {
            deleteTeamMadeForCheckout(data?.createTeamAndTeamSubscriptionStripe?.team.teamId);
          }
          setStripeError(generateStringFromStripeErrorCode(requestStripeError));
          logger.error(
            `failed to createTeamSubscriptionStripe because of stripe error: ${requestStripeError?.code}`,
          );
          setPaying(false);
          return;
        }
        setPaying(false);
        refetchFeatureGrid();
        setRoute(CheckoutFormRoute.PaymentConfirmation);
      },
      onError: (error) => {
        if (
          error.message.includes("TEAM ALREADY EXISTS") ||
          error.message.includes("Failed to create Team")
        ) {
          setCheckoutError(
            `Team creation failed. Please try creating a team by selecting "Create New Team"`,
          );
        } else {
          setCheckoutError(`Failed to upgrade. Please try again.`);
        }
        setPaying(false);
        logger.error(`failed to createTeamAndTeamSubscriptionStripe: ${error}`);
      },
    },
  );

  const [createTeamSubscriptionStripe] = useMutation(CreateTeamSubscriptionStripeDocument, {
    onCompleted: (data) => {
      const requestStripeError = data?.createTeamSubscriptionStripe?.stripeError;
      setPaying(false);
      if (requestStripeError) {
        setStripeError(generateStringFromStripeErrorCode(requestStripeError));
        logger.error(
          `failed to createTeamSubscriptionStripe because of stripe error: ${requestStripeError?.code}`,
        );
        return;
      }
      refetchFeatureGrid();
      setRoute(CheckoutFormRoute.PaymentConfirmation);
    },
    onError: (error) => {
      setPaying(false);
      logger.error(`failed to createTeamSubscriptionStripe ${error}`);
      setCheckoutError(`Failed to upgrade. Please try again.`);
    },
  });

  const createSubscription = (
    isTeamPlanSelected: boolean,
    isIndividualPlanSelected: boolean,
    promoCode?: string,
  ) => {
    if (team && isTeamPlanSelected) {
      // only go here if user has selected team
      return createTeamSubscription(false, team, promoCode);
    } else if ((!team && isTeamPlanSelected) || isIndividualPlanSelected) {
      // only go here if user has selected team AND has no teams
      return createTeamSubscription(true, undefined, promoCode);
    } else {
      return createOrgSubscription(promoCode);
    }
  };

  const createOrgSubscription = async (promoCode?: string) => {
    if (!stripe || !elements) {
      return;
    }

    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpiry = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);

    if (!cardNumber || !cardExpiry || !cardCvc) {
      return logger.error("couldnt find stripe elements");
    }

    track(TrackingEvents.CHECKOUT.CLICK_CHECKOUT);
    setPaying(true);
    setCheckoutError(undefined);

    const orgBg = org && getOrgBillingGroup(org);
    const orgBgSubscription = orgBg && getSubscriptionFromBillingGroup(orgBg);
    if (orgBgSubscription && isSubscriptionProductTypePro(orgBgSubscription.subscription)) {
      logger.error("user trying to pay when they have already paid");
      return;
    }

    const stripeResponse = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumber,
      billing_details: {
        address: {
          country: billingAddress.country ? billingAddress.country : undefined,
          line1: billingAddress.line1 ? billingAddress.line1 : undefined,
          line2: billingAddress.line2 ? billingAddress.line2 : undefined,
          postal_code: billingAddress.postal_code ? billingAddress.postal_code : undefined,
          state: billingAddress.state ? billingAddress.state : undefined,
        },
        email: org.primaryOrgEmail,
      },
    });

    setStripeError(generateStringFromStripeErrorCode(stripeResponse.error));

    if (stripeResponse.error) {
      setPaying(false);
      return logger.error(`stripe error with error code: ${stripeResponse.error.code}`);
    } else if (stripeResponse.paymentMethod) {
      void createOrgSubscriptionStripe({
        variables: {
          input: {
            orgRelayId: org.id,
            stripePaymentMethodId: stripeResponse.paymentMethod.id,
            initialUsers: selectedUserIds.map((u) => u.userId),
            recurrence: selectedBillingRecurrence as any, // HACK: i need to refactor this to all be ISchema.CreateOrgSubscriptionStripeRecurrenceEnum
            numSeats: paidSeatCount,
            promoCode: promoCode,
          },
        },
      });
    }
  };

  const deleteTeamMadeForCheckout = (teamId: string) => {
    deleteTeam({ variables: { input: { orgRelayId: org.id, teamId: teamId } } });
  };

  const createTeamSubscription = async (
    shouldCreateTeam: boolean,
    team?: ITeam,
    promoCode?: string,
  ) => {
    if (!stripe || !elements) {
      return;
    }
    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpiry = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);

    if (!cardNumber || !cardExpiry || !cardCvc) {
      return logger.error("couldnt find stripe elements");
    }

    if (team) {
      track(TrackingEvents.CHECKOUT.CLICK_CHECKOUT, { teamId: team.teamId });
    }
    setPaying(true);
    setCheckoutError(undefined);

    if (team && isTeamInPaidSubscription(org, team)) {
      return logger.error("user trying to pay when they have already paid");
    }

    const stripeResponse = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumber,
      billing_details: {
        address: {
          country: billingAddress.country ? billingAddress.country : undefined,
          line1: billingAddress.line1 ? billingAddress.line1 : undefined,
          line2: billingAddress.line2 ? billingAddress.line2 : undefined,
          postal_code: billingAddress.postal_code ? billingAddress.postal_code : undefined,
          state: billingAddress.state ? billingAddress.state : undefined,
        },
        email: org.primaryOrgEmail,
      },
    });

    setStripeError(generateStringFromStripeErrorCode(stripeResponse.error));

    if (stripeResponse.error) {
      setPaying(false);
      return logger.error(`stripe error with error code: ${stripeResponse.error.code}`);
    } else if (stripeResponse.paymentMethod) {
      // Create team here if we still dont have one
      if (!team && !!shouldCreateTeam) {
        void createTeamAndTeamSubscriptionStripe({
          variables: {
            input: {
              orgRelayId: org.id,
              subscriptionInterval: selectedBillingRecurrence,
              stripePaymentMethodId: stripeResponse.paymentMethod.id,
              seatCount: paidSeatCount,
              userEmail: currentUserInfo?.primaryCalendarId || "",
              personId: currentUserInfo?.personId || "",
              promoCode: promoCode,
            },
          },
        });
      } else if (team) {
        void createTeamSubscriptionStripe({
          variables: {
            input: {
              orgRelayId: org.id,
              teamId: team.teamId,
              subscriptionInterval: selectedBillingRecurrence,
              stripePaymentMethodId: stripeResponse.paymentMethod.id,
              seatCount: paidSeatCount,
              promoCode: promoCode,
            },
          },
        });
      }
    }
  };
  return {
    createSubscription,
    paying,
    checkoutError,
    stripeError,
    setBillingAddress,
    billingAddress,
  };
};
