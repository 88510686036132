//////////////////
// IMPORTS
//////////////////
// schema
import * as ISchema from "#webapp/src/__schema__";
// libraries
import React from "react";
import { QueryRenderer, ReadyState } from "react-relay";
// checkout-form imports
import { checkoutFormRendererQuery } from "./CheckoutFormRenderer.gql";
import { IRendererProps, IRendererState } from "./CheckoutFormTypes";
import { MixedCheckoutForm } from "./mixed-checkout-form";
import { StripeLoader } from "./stripe-loader";
// other components
// util
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { handleReadyStateError } from "#webapp/src/util/relay.util";
import { Loader } from "@clockwise/design-system/src/components/Loader";

//////////////////
// COMPONENT
//////////////////
export class CheckoutFormRenderer extends React.Component<IRendererProps, IRendererState> {
  // ~-~-~-~-~-~-~-
  // Setup
  // ~-~-~-~-~-~-~-
  // private refetchInterval?: ReturnType<typeof setInterval>;
  private shouldForce = true;

  private cachedViewer?: ISchema.IViewer;
  private cachedOrg?: ISchema.IOrg;

  constructor(props: IRendererProps) {
    super(props);
    this.state = {
      key: 0,
      selectedTeamId: props.teamId,
    };
  }

  // ~-~-~-~-~-~-~-
  // Life-Cycle
  // ~-~-~-~-~-~-~-
  public componentDidMount() {
    track(TrackingEvents.CHECKOUT.VIEW_CHECKOUT);
  }

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  private onChangeTeam = (teamId: string) => {
    this.shouldForce = true;
    this.setState({ selectedTeamId: teamId });
    if (this.props.onChangeTeam) {
      this.props.onChangeTeam(teamId);
    }
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  public render() {
    const { orgRelayId } = this.props;
    const { selectedTeamId } = this.state;
    const fetchVariables = { orgRelayId, selectedTeamId };

    return (
      <QueryRenderer
        key={this.state.key}
        environment={this.props.environment}
        query={checkoutFormRendererQuery}
        variables={fetchVariables}
        cacheConfig={{ force: this.shouldForce }}
        render={(readyState: ReadyState) => {
          // check for error or show a loader for first load
          if (readyState.error) {
            handleReadyStateError(readyState.error, "CheckoutFormRenderer");
            return null;
          }

          const viewer = (readyState.props && (readyState.props.viewer as ISchema.IViewer)) || null;
          const org =
            (readyState && readyState.props && (readyState.props.org as ISchema.IOrg)) || null;

          if (viewer) {
            this.cachedViewer = viewer;
          }
          if (org) {
            this.cachedOrg = org;
          }

          if (!this.cachedOrg || !this.cachedViewer) {
            return (
              <Loader
                size="xl"
                sentiment="positive"
                className="cw-w-full cw-h-screen cw-flex cw-justify-center cw-items-center"
              />
            );
          }

          const team =
            (this.cachedOrg &&
              selectedTeamId &&
              this.cachedOrg.userTeams.__typename === "TeamList" &&
              this.cachedOrg.userTeams.list.find((t) => t.teamId === selectedTeamId)) ||
            null;

          this.shouldForce = false;

          return (
            <StripeLoader>
              <MixedCheckoutForm
                viewer={this.cachedViewer}
                org={this.cachedOrg}
                team={team}
                onChangeTeam={this.onChangeTeam}
                billingRecurrence={this.props.billingRecurrence}
                onChangeBillingRecurrence={this.props.onChangeBillingRecurrence}
                isDialog={this.props.isDialog}
                startWithTeamSelected={this.props.startCheckoutWithTeamSelected}
              />
            </StripeLoader>
          );
        }}
      />
    );
  }
}
