export interface ICalColorSet {
  background: string;
  foreground: string;
  twForeground: string;
  name: string;
}

export type ICalendarColorsMap = Record<string, ICalColorSet>;

/**
 * String values map to values provided by services.
 */
export enum EventColorCategory {
  AdHoc = "AdHoc",
  External = "External",
  FocusTime = "FocusTime",
  IndividualHold = "IndividualHold",
  Holiday = "Holiday",
  OneOnOne = "OneOnOne",
  Other = "Other",
  PaceSetting = "PaceSetting",
  OutOfOffice = "OutOfOffice",
}

/**
 * Default event colors pulled from Google calendar API.
 */
export const googleCalendarColors: ICalendarColorsMap = {
  1: {
    background: "#a4bdfc",
    foreground: "#7986CB",
    twForeground: "cw-fill-[#7986CB]",
    name: "Lavender",
  },
  2: {
    background: "#7ae7bf",
    foreground: "#33B679",
    twForeground: "cw-fill-[#33B679]",
    name: "Sage",
  },
  3: {
    background: "#dbadff",
    foreground: "#8E24AA",
    twForeground: "cw-fill-[#8E24AA]",
    name: "Grape",
  },
  4: {
    background: "#ff887c",
    foreground: "#E67C73",
    twForeground: "cw-fill-[#E67C73]",
    name: "Flamingo",
  },
  5: {
    background: "#fbd75b",
    foreground: "#F6BF26",
    twForeground: "cw-fill-[#F6BF26]",
    name: "Banana",
  },
  6: {
    background: "#ffb878",
    foreground: "#F4511E",
    twForeground: "cw-fill-[#F4511E]",
    name: "Tangerine",
  },
  7: {
    background: "#46d6db",
    foreground: "#039BE5",
    twForeground: "cw-fill-[#039BE5]",
    name: "Peacock",
  },
  8: {
    background: "#e1e1e1",
    foreground: "#616161",
    twForeground: "cw-fill-[#616161]",
    name: "Graphite",
  },
  9: {
    background: "#5484ed",
    foreground: "#3F51B5",
    twForeground: "cw-fill-[#3F51B5]",
    name: "Blueberry",
  },
  10: {
    background: "#51b749",
    foreground: "#0B8043",
    twForeground: "cw-fill-[#0B8043]",
    name: "Basil",
  },
  11: {
    background: "#dc2127",
    foreground: "#D50000",
    twForeground: "cw-fill-[#D50000]",
    name: "Tomato",
  },
};

/*
  Figma showing new colors. Contain @amy if this link is out of date and update or delete link.
  https://www.figma.com/file/f8fco86I4zsIFCjTDpEdmv/Transition-%E2%80%94-Design-research?node-id=2%3A4278&t=ChxDHk2i3P0xFzA5-0
*/
export const cwCalendarColors: ICalendarColorsMap = {
  1: {
    background: "#FEF3F2",
    foreground: "#912018",
    twForeground: "cw-fill-[#912018]",
    name: "Red",
  },
  2: {
    background: "#FFFAEB",
    foreground: "#93370D",
    twForeground: "cw-fill-[#93370D]",
    name: "Orange",
  },
  3: {
    background: "#FFFFEC",
    foreground: "#93370D",
    twForeground: "cw-fill-[#93370D]",
    name: "Yellow",
  },
  4: {
    background: "#ECFDF3",
    foreground: "#056049",
    twForeground: "cw-fill-[#056049]",
    name: "Green",
  },
  5: {
    background: "#F0FCFF",
    foreground: "#066486",
    twForeground: "cw-fill-[#066486]",
    name: "Blue",
  },
  6: {
    background: "#F0FDF9",
    foreground: "#125D56",
    twForeground: "cw-fill-[#125D56]",
    name: "Dark Green",
  },
  7: {
    background: "#EFF8FF",
    foreground: "#1849A9",
    twForeground: "cw-fill-[#1849A9]",
    name: "Dark blue",
  },
  8: {
    background: "#F5F3FF",
    foreground: "#5720B7",
    twForeground: "cw-fill-[#5720B7]",
    name: "Violet",
  },
  9: {
    background: "#FDF2FA",
    foreground: "#9E165F",
    twForeground: "cw-fill-[#9E165F]",
    name: "Pink",
  },
  10: {
    background: "#FAFAFA",
    foreground: "#2B2B2B",
    twForeground: "cw-fill-[#2B2B2B]",
    name: "Grey",
  },
  11: {
    background: "#F3EFEB",
    foreground: "#473A2E",
    twForeground: "cw-fill-[#473A2E]",
    name: "Bronze",
  },
};

export const googleCalendarColorsForClockwiseCalendar: ICalendarColorsMap = {
  1: {
    background: "#EEF4FF",
    foreground: "#2D31A6",
    twForeground: "cw-fill-[#2D31A6]",
    name: "Purple",
  }, // This purple is not present in the new color palette or M365 color palette.
  2: cwCalendarColors[4],
  3: cwCalendarColors[8],
  4: cwCalendarColors[9],
  5: cwCalendarColors[3],
  6: cwCalendarColors[2],
  7: cwCalendarColors[5],
  8: cwCalendarColors[10],
  9: cwCalendarColors[7],
  10: cwCalendarColors[6],
  11: cwCalendarColors[1],
};
/**
 * Default event colors pulled from M365 calendar API.
 */
export const m365CalendarColors: ICalendarColorsMap = {
  1: {
    background: "#E74856",
    foreground: "#E74856",
    twForeground: "cw-fill-[#E74856]",
    name: "Red",
  },
  2: {
    background: "#FF8C00",
    foreground: "#FF8C00",
    twForeground: "cw-fill-[#FF8C00]",
    name: "Orange",
  },
  3: {
    background: "#FFF100",
    foreground: "#FFF100",
    twForeground: "cw-fill-[#FFF100]",
    name: "Yellow",
  },
  4: {
    background: "#47D041",
    foreground: "#47D041",
    twForeground: "cw-fill-[#47D041]",
    name: "Green",
  },
  5: {
    background: "#30C6CC",
    foreground: "#30C6CC",
    twForeground: "cw-fill-[#30C6CC]",
    name: "Light teal",
  },
  6: {
    background: "#73AA24",
    foreground: "#73AA24",
    twForeground: "cw-fill-[#73AA24]",
    name: "Olive",
  },
  7: {
    background: "#00BCF2",
    foreground: "#00BCF2",
    twForeground: "cw-fill-[#00BCF2]",
    name: "Blue",
  },
  8: {
    background: "#8764B8",
    foreground: "#8764B8",
    twForeground: "cw-fill-[#8764B8]",
    name: "Purple",
  },
  9: {
    background: "#F495BF",
    foreground: "#F495BF",
    twForeground: "cw-fill-[#F495BF]",
    name: "Pink",
  },
  10: {
    background: "#A0AEB2",
    foreground: "#A0AEB2",
    twForeground: "cw-fill-[#A0AEB2]",
    name: "Steel light",
  },
  11: {
    background: "#AB620D",
    foreground: "#AB620D",
    twForeground: "cw-fill-[#AB620D]",
    name: "Brown",
  },
};

export const defaultCWCalEventColor = cwCalendarColors[5];
export const defaultCWCalFocusTimeColor = cwCalendarColors[6];
export const defaultCWCalHoldColor = cwCalendarColors[1];

/**
 * This is the default set to save from the suggestion tip card.
 */
export const defaultGoogleSet = [
  {
    eventCategory: EventColorCategory.External,
    active: true,
    googleColorKey: "4", // flamingo
    isUserSet: true,
  },
  {
    eventCategory: EventColorCategory.IndividualHold,
    active: true,
    googleColorKey: "1", // lavender
    isUserSet: true,
  },
  {
    eventCategory: EventColorCategory.FocusTime,
    active: true,
    googleColorKey: "8", // graphite
    isUserSet: true,
  },
  {
    eventCategory: EventColorCategory.OneOnOne,
    active: true,
    googleColorKey: "2", // sage
    isUserSet: true,
  },
  {
    eventCategory: EventColorCategory.Holiday,
    active: true,
    googleColorKey: "5", // banana
    isUserSet: true,
  },
];

export const defaultM365Set = [
  {
    eventCategory: EventColorCategory.External,
    active: true,
    googleColorKey: "1", // Red
    isUserSet: true,
  },
  {
    eventCategory: EventColorCategory.IndividualHold,
    active: true,
    googleColorKey: "9", // Purple
    isUserSet: true,
  },
  {
    eventCategory: EventColorCategory.FocusTime,
    active: true,
    googleColorKey: "10", // Steel light
    isUserSet: true,
  },
  {
    eventCategory: EventColorCategory.OneOnOne,
    active: true,
    googleColorKey: "5", // Green
    isUserSet: true,
  },
  {
    eventCategory: EventColorCategory.Holiday,
    active: true,
    googleColorKey: "4", // Yellow
    isUserSet: true,
  },
];
