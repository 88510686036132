/**
 * @generated SignedSource<<8f53f80c2e5d547dbdaafd37a7e3f239>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrgUserSelectFormQuery$variables = {
  queryInput: string;
  orgRelayId: string;
  excludeCalendars?: ReadonlyArray<string | null> | null;
};
export type OrgUserSelectFormQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"OrgUserSelectForm_viewer">;
  };
};
export type OrgUserSelectFormQuery = {
  variables: OrgUserSelectFormQuery$variables;
  response: OrgUserSelectFormQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeCalendars"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orgRelayId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queryInput"
},
v3 = {
  "kind": "Variable",
  "name": "excludeCalendars",
  "variableName": "excludeCalendars"
},
v4 = {
  "kind": "Variable",
  "name": "orgRelayId",
  "variableName": "orgRelayId"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrgUserSelectFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "Variable",
                "name": "queryInput",
                "variableName": "queryInput"
              }
            ],
            "kind": "FragmentSpread",
            "name": "OrgUserSelectForm_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrgUserSelectFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "Variable",
                "name": "query",
                "variableName": "queryInput"
              }
            ],
            "concreteType": null,
            "kind": "LinkedField",
            "name": "orgPersonQueryResultErrorable",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "statusCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                  }
                ],
                "type": "GraphEntityError",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "query",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrgPerson",
                    "kind": "LinkedField",
                    "name": "persons",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "primaryCalendarId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "calendarAliases",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "kind": "LinkedField",
                        "name": "profile",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "givenName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "familyName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "externalImageUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isYou",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "OrgPersonQueryResult",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5a5d6f96b0f0fa2199af235fb634bd4e",
    "id": null,
    "metadata": {},
    "name": "OrgUserSelectFormQuery",
    "operationKind": "query",
    "text": "query OrgUserSelectFormQuery(\n  $queryInput: String!\n  $orgRelayId: ID!\n  $excludeCalendars: [String]\n) {\n  viewer {\n    ...OrgUserSelectForm_viewer_1L0Few\n    id\n  }\n}\n\nfragment OrgUserSelectForm_viewer_1L0Few on Viewer {\n  orgPersonQueryResultErrorable(query: $queryInput, orgRelayId: $orgRelayId, excludeCalendars: $excludeCalendars) {\n    __typename\n    ... on GraphEntityError {\n      statusCode\n      message\n    }\n    ... on OrgPersonQueryResult {\n      query\n      persons {\n        userId\n        primaryCalendarId\n        calendarAliases\n        profile {\n          givenName\n          familyName\n          externalImageUrl\n        }\n        isYou\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d50e6ae52ae35f1b4a3fef130ffa3705";

export default node;
