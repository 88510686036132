import { graphql } from "react-relay";

export const removeGoalMutation = graphql`
  mutation RemoveGoalMutation($input: RemoveGoalMutationInput!) {
    removeGoal(input: $input) {
      goal {
        id
        goalId
        targetUrn {
          id
          type
          value
        }
        otherUrns {
          id
          type
          value
        }
        name
        isDeleted
        options {
          syncToGoogle
        }
      }
    }
  }
`;
