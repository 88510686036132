import { RouteRenderer } from "#webapp/src/components/route-renderer";
import { paths } from "#webapp/src/constants/site.constant";
import { ApolloError } from "@apollo/client";
import { SchedulingPriorityEnum } from "@clockwise/schema/v2";
import { hasNotFoundError } from "@clockwise/web-commons/src/network/apollo/errors";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { BookedSlot, SchedulingLink } from "@clockwise/web-commons/src/ui/scheduling-link";
import { SchedulingLinkLoading } from "@clockwise/web-commons/src/ui/scheduling-link-loading";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { getFullName } from "@clockwise/web-commons/src/util/profile.util";
import {
  findOwner,
  getEditSchedulingLinkPath,
} from "@clockwise/web-commons/src/util/scheduling.util";
import { createUUID } from "@clockwise/web-commons/src/util/uuid";
import { compact, noop } from "lodash";
import { DateTime, Interval } from "luxon";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import linkSrc from "../scheduling-link-dashboard/images/link.png";
import { BannerVariants } from "./BannerVariants";
import { CannotRescheduleBooking } from "./CannotRescheduleBooking";
import {
  LinkRescheduleAvailableTimesDocument,
  LinkRescheduleDetailsDocument,
  LinkRescheduleDetailsQuery,
  RescheduleLinkBookingDocument,
} from "./__generated__/RescheduleBookingRendererGateway.v2.generated";
import { formatTimeFromParam, getInitialDuration } from "./utils";

export const RescheduleBookingRendererGateway = () => {
  const { bookingId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const timeSlotParam = searchParams.get("time") ?? "";
  const timeZone = DateTime.local().zoneName;

  const timeString = timeSlotParam ? `${formatTimeFromParam(timeSlotParam)} - ` : "";

  const [isInvalidLink, setIsInvalidLink] = useState(false);

  const { loading, data, error } = useGatewayQuery(LinkRescheduleDetailsDocument, {
    variables: {
      bookingId,
    },
    onError: (error) => {
      if (hasNotFoundError(error)) {
        setIsInvalidLink(true);
      }
    },
  });

  const bookingData = data?.linkBooking;
  const linkData = data?.linkBooking?.publicSchedulingLink;

  const ownerProfile = findOwner(linkData?.linkMembers ?? [])?.person;
  const ownerFullName = ownerProfile ? getFullName(ownerProfile) : "This user";

  const title = linkData
    ? compact([linkData.name, ownerFullName]).join(" with ")
    : "Clockwise Scheduling Link";

  const titleString = `${timeString}${title}`;
  const description = "Book the best time, every time with " + ownerFullName;

  let content: ReactNode;
  if (bookingData) {
    content = linkData ? (
      <InnerRescheduleBookingRendererGateway
        bookingData={bookingData}
        linkData={linkData}
        isAuthenticatedUser={!!data.currentUser?.id}
      />
    ) : (
      <CannotRescheduleBooking bookingData={bookingData} reason={"inactive"} timeZone={timeZone} />
    );
  } else {
    content = <SchedulingLinkLoading />;
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:card" content={linkSrc} />
        {title && <title>{titleString}</title>}
      </Helmet>
      <RouteRenderer
        variant="scheduling-link"
        error={!!error}
        notFound={isInvalidLink || (!loading && !bookingData)}
        loading={false /* handled by SchedulingLinkLoading */}
      >
        {content}
      </RouteRenderer>
    </>
  );
};

type BookingData = NonNullable<LinkRescheduleDetailsQuery["linkBooking"]>;
type LinkData = NonNullable<BookingData["publicSchedulingLink"]>;
interface InnerRescheduleBookingRendererGatewayProps {
  bookingData: BookingData;
  linkData: LinkData;
  isAuthenticatedUser: boolean;
}
const InnerRescheduleBookingRendererGateway = ({
  bookingData,
  linkData,
  isAuthenticatedUser,
}: InnerRescheduleBookingRendererGatewayProps) => {
  const trackEvent = useTracking();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const paramDate = searchParams.get("date") ?? "";
  const paramTime = searchParams.get("time") ?? "";
  const paramTimezone = searchParams.get("timezone") ?? "";
  const paramName = searchParams.get("name") ?? "";
  const paramEmail = searchParams.get("email") ?? "";

  const [currentDate, setCurrentDate] = useState(() => DateTime.now().toISODate());
  const [bookedSlot, setBookedSlot] = useState<BookedSlot | null>(null);
  const [bookingError, setBookingError] = useState<ApolloError | null>(null);
  const [selectedDuration, setSelectedDuration] = useState<number | undefined>(() =>
    getInitialDuration({
      preferredDuration: bookingData.duration,
      defaultDuration: linkData.defaultDuration,
      durations: linkData.durations,
    }),
  );

  const sessionId = useMemo(createUUID, []);

  const [renderedTimeZone, setRenderedTimeZone] = useState(DateTime.local().zoneName);

  const now = DateTime.now();
  const endOfAvailability = DateTime.fromISO(linkData.endOfAvailability).toISODate();

  const bookingId = bookingData.id;

  const { loading, data } = useGatewayQuery(LinkRescheduleAvailableTimesDocument, {
    variables: {
      bookingId,
      duration: selectedDuration,
      startDate: now.toISODate(),
      endDate: endOfAvailability,
      // Use the time zone that matches startDate
      timeZone: now.zoneName,
    },
    notifyOnNetworkStatusChange: true,
  });

  // Create a tracking events to pass into the view component, prefilled with the linkUrl and a sessionId.
  const trackSession = useCallback(
    (event: string, data: Record<string, string | object | number | boolean>) => {
      trackEvent(event, {
        sessionId,
        meetingSettingsId: linkData?.id,
        linkUrl: window?.location?.href,
        userAgent: window?.navigator?.userAgent,
        ...data,
      });
    },
    [trackEvent, sessionId, linkData?.id],
  );

  const [rescheduleBooking, { loading: bookingSaving }] = useGatewayMutation(
    RescheduleLinkBookingDocument,
    {
      onError: (error) => setBookingError(error),
    },
  );

  const linkTimes = data?.linkBooking?.linkTimes ?? [];

  // Ensure currentDate is within a date range with available times when possible
  const firstAvailableDate = linkTimes[0]
    ? Interval.fromISO(linkTimes[0].timeSlot).start.toISODate()
    : null;
  useEffect(() => {
    if (firstAvailableDate && currentDate < firstAvailableDate) {
      setCurrentDate(firstAvailableDate);
    }
  }, [currentDate, firstAvailableDate]);

  const handleRescheduleBooking = useCallback(
    async (reason: string | null, selectedTime: Interval) => {
      const startTime = selectedTime.start.toISO();
      const result = await rescheduleBooking({
        variables: {
          bookingId,
          timeRange: selectedTime.toISO(),
          reason,
        },
      });

      if (result.data?.rescheduleBooking) {
        setBookedSlot({
          bookedDateOrDateTime: startTime,
          bookedTimeZone: renderedTimeZone,
          bookedLinkMembersAsCalendarIds: [],
        });

        trackEvent(TrackingEvents.LINKS.BOOKING.MODAL_BOOK, {
          bookingData: {
            bookingId,
            startTime: selectedTime.start.toISO(),
            reason,
            timeZone: renderedTimeZone,
          },
          meetingSettingsId: linkData.id,
        });
      }
    },
    [linkData, rescheduleBooking, bookingId, trackEvent, renderedTimeZone],
  );

  const handleDurationChange = (duration: number) => {
    setSelectedDuration(duration);
  };

  const ownerProfile = findOwner(linkData.linkMembers ?? [])?.person;

  const queryParamDateTime = paramTimezone
    ? DateTime.fromISO(`${paramDate}T${paramTime}`, {
        zone: paramTimezone,
      }).setZone(renderedTimeZone)
    : DateTime.fromISO(`${paramDate}T${paramTime}`, {
        zone: renderedTimeZone,
      });

  return (
    <SchedulingLink
      id={linkData.id}
      linkName={linkData.linkName}
      slug={linkData.slug}
      logoUrl={linkData.linkLogoUrl}
      description={linkData.description}
      locationType={linkData.location}
      meetingName={linkData.name}
      duration={
        // Prefer the user's selection, if set
        selectedDuration ||
        // Else if still valid, try the the booking's duration
        (linkData.durations.includes(bookingData.duration) && bookingData.duration) ||
        // Finally, use the link's default
        linkData.defaultDuration
      }
      durations={linkData.durations}
      onDurationChange={handleDurationChange}
      linkMembers={linkData.linkMembers.filter(
        (lm) => lm.schedulingPriority !== SchedulingPriorityEnum.DoNotSchedule,
      )}
      attendeeQuestions={linkData.attendeeQuestions}
      timeZone={renderedTimeZone}
      onTimeZoneChange={setRenderedTimeZone}
      isOwner={linkData.ownedByMe}
      isMember={linkData.managedByMe}
      timeSlots={linkTimes}
      setCurrentDate={setCurrentDate}
      onMonthChange={noop} /* No longer used by this implementation, as we fetch all times */
      currentDate={currentDate}
      loading={loading}
      existingBooking={bookingData}
      onReschedule={handleRescheduleBooking}
      bookingError={bookingError && bookingError.toString?.()}
      clearBookingError={() => {
        if (bookingError) {
          setBookingError(null);
        }
      }}
      bookingLoading={bookingSaving}
      bookedSlot={(!bookingError && !bookingSaving && bookedSlot) || null}
      trackSession={trackSession}
      highlightBestTimes={linkData.highlightBestTimes}
      endOfAvailability={linkData.endOfAvailability}
      onEditLink={() => navigate(getEditSchedulingLinkPath(linkData.linkName, linkData.slug))}
      onClickMyLinks={() => navigate(paths.schedulingLinks)}
      ownerProfile={ownerProfile}
      banner={<BannerVariants isAuthenticatedUser={isAuthenticatedUser} />}
      allowAdditionalAttendees={false /* Rescheduling doesn't offer modifying attendees */}
      groupSchedulingStrategy={linkData.groupSchedulingStrategy}
      initDateTime={queryParamDateTime}
      initialName={paramName}
      initialEmail={paramEmail}
    />
  );
};
