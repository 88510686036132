import { fg_brand_muted } from "@clockwise/design-system/tokens";
import { CheckCircle, IconContext, IconProps } from "phosphor-react";
import React, { PropsWithChildren } from "react";

export type IconComponent = typeof CheckCircle;

export const PHOSPHOR_SETTINGS: IconProps = {
  color: fg_brand_muted,
  size: 24,
  weight: "duotone",
};

export function PhosphorProvider({ value, children }: PropsWithChildren<{ value?: IconProps }>) {
  return (
    <IconContext.Provider value={{ ...PHOSPHOR_SETTINGS, ...value }}>
      {children}
    </IconContext.Provider>
  );
}
