import { Select, SelectOption } from "@clockwise/design-system";
import { Link } from "@clockwise/design-system/icons";
import { CopyText } from "@clockwise/web-commons/src/ui/copy-text/CopyText";
import {
  TimeZoneDisplayMode,
  TimeZonePicker,
} from "@clockwise/web-commons/src/ui/time-zone-picker";
import { TrackingEvents, useTrackOnce } from "@clockwise/web-commons/src/util/analytics.util";
import { range } from "lodash";
import pluralize from "pluralize";
import React, { useCallback, useMemo } from "react";
import { CopyTimesPreview, formatDurationCopyTimes } from "./CopyTimesPreview";

interface SuggestedSlot {
  timeSlot: string;
}

export interface ICopyBestTimesContentProps {
  onCopy: (text: string) => void;
  linkUrl: string;
  linkName: string;
  name: string;
  slug: string;
  duration: number;
  durations: number[];
  onDurationChange: (duration: number) => void;
  windowSizeDays?: number | null;
  multipleUser: boolean;
  bestTimeSlots: SuggestedSlot[];
  loading: boolean;
  errorMsg: string | null;
  numDaysToShow: number;
  setNumDaysToShow: (numDaysToShow: number) => void;
  targetTimeZone: string;
  setTargetTimezone: (targetTimeZone: string) => void;
  renderedFrom: string;
}

export const CopyBestTimesContent = ({
  onCopy,
  linkUrl,
  linkName,
  name,
  slug,
  duration,
  durations,
  onDurationChange,
  windowSizeDays,
  multipleUser,
  bestTimeSlots,
  loading,
  errorMsg,
  numDaysToShow,
  setNumDaysToShow,
  targetTimeZone,
  setTargetTimezone,
  renderedFrom,
}: ICopyBestTimesContentProps) => {
  const trackCopyLink = useTrackOnce(TrackingEvents.LINKS.COPY_TIMES.COPY_LINK);
  const trackCopyLinkAiChat = useTrackOnce(TrackingEvents.CHAT.PROPOSAL.COPY_SCHEDULING_LINK.LINK);
  const trackCopyLinkAiChatLinkCard = useTrackOnce(
    TrackingEvents.CHAT.SCHEDULING_LINK.SHARE_TIMES_MODAL.COPY_LINK,
  );
  const trackCopyLinkShareableProposal = useTrackOnce(
    TrackingEvents.SHAREABLE_PROPOSAL.MODAL.SCHEDULING_LINK.COPY_LINK,
  );
  const copyLinkAriaLabel = "copy scheduling link url";

  const linkUrlWithDuration = useMemo(() => {
    const url = new URL(linkUrl);
    // Add duration to url if there are multiple durations
    if (durations.length > 1) {
      url.searchParams.set("duration", duration.toString());
    }
    return url.toString();
  }, [linkUrl, duration, durations.length]);

  const handleCopyLink = useCallback(
    (link: string) => {
      onCopy(link);
      if (renderedFrom === "scheduling-links") {
        trackCopyLink();
      } else if (renderedFrom === "ai-chat") {
        trackCopyLinkAiChat();
      } else if (renderedFrom === "ai-chat-scheduling-link-card") {
        trackCopyLinkAiChatLinkCard();
      } else if (renderedFrom === "shareable-proposal") {
        trackCopyLinkShareableProposal();
      }
    },
    [
      trackCopyLink,
      trackCopyLinkAiChat,
      trackCopyLinkAiChatLinkCard,
      trackCopyLinkShareableProposal,
      renderedFrom,
      onCopy,
    ],
  );

  return (
    <div className="cw-flex cw-flex-col cw-gap-3 cw-body-base">
      {renderedFrom != "shareable-proposal" && (
        <>
          <div>
            This link will allow recipients to book a{" "}
            <DurationSelect value={duration} durations={durations} onChange={onDurationChange} />{" "}
            meeting with {multipleUser ? "your group" : "you"}{" "}
            {!!windowSizeDays && (
              <>
                in the next
                <strong className="cw-font-semibold">
                  {" "}
                  {pluralize("day", windowSizeDays, true)}
                </strong>{" "}
              </>
            )}
            using your
            <strong className="cw-font-semibold"> {name}</strong> scheduling link
          </div>
          <CopyText
            copyAriaLabel={copyLinkAriaLabel}
            onCopy={() => handleCopyLink(linkUrlWithDuration)}
            startIcon={<Link />}
            text={linkUrlWithDuration}
            urlFormatting
          />
        </>
      )}

      {renderedFrom === "shareable-proposal" ? (
        <div className="cw-mt-2">
          Copy and paste this text to offer a few suggested times for{" "}
          <strong className="cw-font-semibold">{name}</strong> along with a link to book it for
          everyone.
        </div>
      ) : (
        <div className="cw-mt-2">
          Or, copy and paste this text to offer a few suggested times along with the link to{" "}
          <strong className="cw-font-semibold">{name}</strong>
        </div>
      )}
      <CopyTimesPreview
        durationMinutes={duration}
        linkUrl={linkUrlWithDuration}
        linkName={linkName}
        multipleUser={multipleUser}
        slots={bestTimeSlots}
        targetTimeZone={targetTimeZone}
        loading={loading}
        slug={slug}
        onCopyRichText={onCopy}
        errorMsg={errorMsg}
        numDaysToShow={numDaysToShow}
        renderedFrom={renderedFrom}
      />

      <div>
        Show up to{" "}
        <div className="cw-inline-block">
          <Select
            size="small"
            value={numDaysToShow.toString(10)}
            onChange={(value) => setNumDaysToShow(parseInt(value, 10))}
          >
            {range(1, 5).map((d) => (
              <SelectOption key={d} value={d.toString(10)}>
                {pluralize("day", d, true)}
              </SelectOption>
            ))}
          </Select>
        </div>{" "}
        of availability in
        <TimeZonePicker
          className="cw-mt-2"
          value={targetTimeZone}
          onChange={setTargetTimezone}
          timeZoneDisplayMode={TimeZoneDisplayMode.JustPicker}
        />
      </div>
    </div>
  );
};

const DurationSelect = ({
  value,
  durations,
  onChange,
}: {
  value: number;
  durations: number[];
  onChange: (value: number) => void;
}) => {
  if (durations.length === 1) {
    return <strong className="cw-font-semibold">{formatDurationCopyTimes(value)}</strong>;
  }
  return (
    <span className="cw-inline-block">
      <Select
        size="small"
        value={value.toString()}
        onChange={(newValue) => onChange(parseInt(newValue, 10))}
      >
        {durations.map((duration) => (
          <SelectOption key={duration} value={duration.toString(10)}>
            {formatDurationCopyTimes(duration)}
          </SelectOption>
        ))}
      </Select>
    </span>
  );
};
