import {
  getOwnCalendarColor,
  getUnknownCalendarColor,
} from "@clockwise/web-commons/src/util/calendar-coloring";
import {
  CalendarColorsAction,
  calendarColorsReducer,
  CalendarColorsState,
  CalendarId,
} from "@clockwise/web-commons/src/util/calendarColorsReducer";
import { clone } from "lodash";
import React, { createContext, Dispatch, ReactNode, useContext, useReducer } from "react";
import invariant from "tiny-invariant";

const StateContext = createContext<CalendarColorsState>({});
const DispatchContext = createContext<Dispatch<CalendarColorsAction> | undefined>(undefined);

type ProviderProps = {
  children: ReactNode;
  initValue?: CalendarColorsState;
};

export const CalendarColorsProvider = ({ children, initValue = {} }: ProviderProps) => {
  const [state, dispatch] = useReducer(calendarColorsReducer, initValue);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useReadCalendarColor = (calendarId?: CalendarId) => {
  const context = useContext(StateContext);

  if (!calendarId) {
    return undefined;
  }

  return context[calendarId];
};

export const useReadCalendarColors = () => {
  const context = useContext(StateContext);
  return clone(context);
};

export const useReadCalendarColorForCalendarId = (
  calendarId: CalendarId | undefined,
  isOwnEvent: boolean,
) => {
  const readContext = useReadCalendarColors();
  const ownCalendarColor = getOwnCalendarColor();
  const unknownCalendarColor = getUnknownCalendarColor();

  let calendarColor = ownCalendarColor;
  if (!isOwnEvent && calendarId) {
    const color = readContext[calendarId];
    if (color) {
      calendarColor = color;
    } else {
      calendarColor = unknownCalendarColor;
    }
  }

  return calendarColor;
};

export const useUpdateCalendarColors = () => {
  const context = useContext(DispatchContext);
  invariant(context, "useUpdateCalendarColors must be within CalendarColorsProvider");
  return context;
};
