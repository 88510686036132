import {
  ChromeFeedActions,
  IChromeFeedAction,
} from "#webapp/src/state/actions/chrome-feed.actions";

export interface IChromeFeedState {
  refetchFeed: boolean;
  lastViewedFeedMillis: number;
}

export const initialFeedState: IChromeFeedState = {
  refetchFeed: false,
  lastViewedFeedMillis: Infinity,
};

export function chromeFeedReducer(
  state: IChromeFeedState = initialFeedState,
  action: IChromeFeedAction,
) {
  switch (action.type) {
    case ChromeFeedActions.RefetchFeed:
      return {
        ...state,
        refetchFeed: !state.refetchFeed,
      };
    case ChromeFeedActions.SetLastViewedFeedMillis:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
