// schema
import * as ISchema from "#webapp/src/__schema__";

// libraries
import { Button } from "@clockwise/design-system";
import { CircularProgress as MuiCircularProgress } from "@material-ui/core";
import * as React from "react";

// components
import { TeamJoinCreate } from "#webapp/src/components/team-join-create";

// util
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { useWindowSize } from "@clockwise/web-commons/src/util/react.util";
import { LockedDialog } from "../invite-modal/LockedDialog";

// internal

export interface IProps {
  viewer: ISchema.IViewer;
  org: ISchema.IOrg;
  open: boolean;
  onClose: (teamId?: string) => void;
  forcedInitialView?: "join" | "create" | "select";
  mode: "onboarding" | "settings" | "checkout";
  selectMode?: boolean;
  shouldReloadAfterCreateOrJoin?: boolean;
}

export const TeamJoinCreateDialog = (props: IProps) => {
  const {
    open,
    onClose,
    org,
    selectMode,
    mode,
    forcedInitialView,
    viewer,
    shouldReloadAfterCreateOrJoin,
  } = props;
  const { width: windowWidth } = useWindowSize();
  const [saving, setSaving] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [currentView, setCurrentView] = React.useState<"join" | "create" | "select">("create");

  ///////////////
  // Listeners //
  ///////////////

  const onInit = ({
    nDisplayedTeams,
    nSuggestedTeams,
    nDisplayedInviteSuggestions,
  }: {
    nDisplayedTeams: number;
    nSuggestedTeams: number;
    nDisplayedInviteSuggestions: number;
  }) => {
    track(TrackingEvents.TEAMS.TEAM_SETTINGS_TEAM_CREATE_JOIN_SHOWN, {
      nDisplayedTeams,
      nSuggestedTeams,
      nDisplayedInviteSuggestions,
    });
  };

  ///////////////
  // Rendering //
  ///////////////

  return (
    <LockedDialog
      fullScreen={!!windowWidth && windowWidth < 500}
      open={open}
      onClose={() => onClose()}
    >
      <div className="cw-p-8 cw-overflow-auto">
        <TeamJoinCreate
          viewer={viewer}
          org={org}
          mode={mode}
          analyticsMode={mode}
          onInit={onInit}
          onChangeJoinMode={() => null}
          onChangeValidity={(_isValid) => setIsValid(_isValid)}
          onChangeView={(_currentView) => setCurrentView(_currentView)}
          onSave={onClose}
          save={saving}
          forcedInitialView={forcedInitialView}
          selectMode={selectMode}
          shouldReloadAfterCreateOrJoin={shouldReloadAfterCreateOrJoin}
        />
      </div>
      <div className="cw-flex cw-justify-between cw-p-3 cw-border-t cw-border-grey-lightester cw-border-solid">
        <Button variant="text" onClick={() => onClose()} cw-id="team-dialog-cancel">
          Cancel
        </Button>
        <Button
          variant="text"
          sentiment="positive"
          disabled={!isValid || saving}
          onClick={() => setSaving(true)}
        >
          {saving ? (
            <MuiCircularProgress size={24} style={{ marginRight: 4 }} />
          ) : currentView === "join" && !selectMode ? (
            "Join team"
          ) : currentView === "create" ? (
            "Create team"
          ) : (
            "Select team"
          )}
        </Button>
      </div>
    </LockedDialog>
  );
};
