import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { Org } from "@clockwise/schema";

type Maybe<T> = T | null | undefined;

type GraphEntityErrorLike = {
  __typename: "GraphEntityError";
  statusCode?: Maybe<number>;
  message?: Maybe<string>;
  body?: Maybe<string>;
};

export interface IPaymentStatusBadge {
  text: PaymentStatusBadgeTextEnum;
  type: PaymentStatusBadgeTypeEnum;
}

export enum PaymentStatusBadgeTextEnum {
  Free = "Free",
  Pro = "Pro",
  Business = "Business",
  Enterprise = "Enterprise",
  Trial = "Trial",
}

export enum PaymentStatusBadgeTypeEnum {
  Blue = "Blue",
  Gradient = "Gradient",
}

export interface IMixedFreemiumOrgUiState {
  autopilotPaused: boolean;
  moveRangesEnabled: boolean;
}

export enum MeetingReliefSettingVisibility {
  HideSetting = "HideSetting",
  DisabledByPaywall = "DisabledByPaywall",
  ShowWithBadge = "ShowWithBadge",
  ShowWithoutBadge = "ShowWithoutBadge",
}

export interface TrialMetadata {
  __typename: "TrialMetadata";
  trialEndDate: string;
  timeZone: string;
  isTrialExpired: boolean;
}

export interface IPlanTierFeatures {
  paymentStatusBadge: IPaymentStatusBadge | null;
}

export interface IFreePaidFeatures {
  autopilotWhenOrganizerCurrentlyAllowed: boolean;
  eligibleForNoMeetingDay: boolean;
  eligibleForTeamCalendarVisibility: boolean;
  focusTimeAllowed: boolean;
  moveRangesEnabled: boolean;
}

// ~-~-~-~-~-~-~-
// Redux
// ~-~-~-~-~-~-~-
export interface IFeatureGridReduxState {
  meetingReliefSettingVisibility: MeetingReliefSettingVisibility;
  trialMetadata: TrialMetadata | null;
  hasPaidPlan: boolean;
  planTierFeatures: IPlanTierFeatures;
  freePaidFeatures: IFreePaidFeatures;
}

export const initialFeatureGridState: IFeatureGridReduxState = {
  meetingReliefSettingVisibility: MeetingReliefSettingVisibility.HideSetting,
  trialMetadata: null,
  hasPaidPlan: false,
  planTierFeatures: {
    paymentStatusBadge: null,
  },
  freePaidFeatures: {
    autopilotWhenOrganizerCurrentlyAllowed: true,
    eligibleForNoMeetingDay: true,
    eligibleForTeamCalendarVisibility: true,
    focusTimeAllowed: true,
    moveRangesEnabled: true,
  },
};

// If any org requests for a custom "Request To Join" CTA to clockwise,
// add their full domain (second half of user's email) as a new property, with the custom message as the value"
export const domainMappedToCustomRequestToJoinMessage: { [keyof: string]: string } = {
  "uber.com": "To request a license, visit https://t.uber.com/clockwise-paid-access-request.",
  "atlassian.com":
    "Looking for a paid Enterprise License? License upgrades can be requested via the instructions listed internally at go/clockwise. Please note license upgrades made via the Clockwise portal direct will not be actioned.",
  "airtable.com":
    "You are currently on the Free plan. To join Airtable's Enterprise plan, email it@airtable.com",
  "shipt.com":
    "You are currently on the Free plan. To join Shipt's Enterprise plan, type /autobot access new in Slack",
  "guildeducation.com":
    "Guild Education has an existing Enterprise account with Clockwise. Paid licenses are available via the IT team.",
  "smartcontract.com": "To request a license, reach out to hanna.lorica@smartcontract.com",
  "epicgames.com":
    "Please request access to Clockwise via Sailpoint (IAM), located in your Epic Games Okta Dashboard",
  "figma.com":
    "To upgrade your Clockwise license, please request through go/opal|https://app.opal.dev/groups/8120037b-7a70-4f0c-93ce-1a6735588199?oktaAppId=24434585-08cd-47dc-ba32-24ec9dba167f",
  "reddit.com":
    "Hey Snoo! Please request a Clockwise license via the Sailpoint Request Center in Okta. Thanks!",
  "roblox.com":
    "Need to join the Roblox clockwise account? Contact corpeng-help@roblox.com to request a license upgrade. Please note, if you request a license upgrade directly in the clockwise portal here it will not be actioned. Thanks!",
  "liftoff.io": "For paid license, please submit a ticket to Liftoff IT",
  "scopely.com":
    "To join the paid plan, please submit a HelpDesk ticket at the following link: https://www.servicedesk.scopely.com",
  "robinhood.com":
    "Please visit go/oig in order to request access to clockwise from your Robinhood Administrators",
};

export const getCustomDomainRequestToJoinMessageFromUserEmail = (email?: string | null) => {
  if (!email) return;
  const splitEmail = (email && email.trim().split("@")) || [];
  if (splitEmail[1] && domainMappedToCustomRequestToJoinMessage[splitEmail[1]]) {
    return domainMappedToCustomRequestToJoinMessage[splitEmail[1]];
  }
  return;
};

type OrgLikeHasBillingGroup = {
  featureGrid?: Maybe<
    | {
        __typename: "FeatureGrid";
        stateMetadata?: Maybe<{
          primaryBillingGroupMetadata?: Maybe<{
            billingGroup?: unknown;
          }>;
        }>;
      }
    | GraphEntityErrorLike
  >;
  billingGroups?: Maybe<
    | {
        __typename: "BillingGroupList";
        list?: unknown[];
      }
    | GraphEntityErrorLike
  >;
};

export const userHasBillingGroup = (org: OrgLikeHasBillingGroup | null) => {
  if (!org) return false;
  let hasBillingGroup = false;
  const featureGrid = getValue(org?.featureGrid);
  if (featureGrid) {
    hasBillingGroup = Boolean(featureGrid.stateMetadata?.primaryBillingGroupMetadata?.billingGroup);
  }

  return hasBillingGroup;
};

// We show the "join plan" CTAs if a user is not on a paid plan, but belongs to an org that contains paid plans
export const shouldShowJoinExistingPlan = (org: OrgLikeHasBillingGroup) => {
  const billingGroups = getValue(org.billingGroups);
  return !userHasBillingGroup(org) && Boolean(billingGroups?.list?.length);
};

export const shouldShowJoinExistingPlanWithRedux = (bgList: string[], hasPaidPlan: boolean) => {
  return !hasPaidPlan && Boolean(bgList?.length);
};

export function getAugmentedTrialMetadataFromOrg(org: Org) {
  const userMetadata = getUserMetadataFromOrg(org);
  if (!userMetadata) {
    return null;
  }

  return userMetadata.trialMetadata ? userMetadata.trialMetadata : null;
}

export function getFeatureGridFromOrg(org: Org) {
  if (!org.featureGrid || !org.featureGrid.__typename) {
    return null;
  }

  if (org.featureGrid.__typename !== "FeatureGrid") {
    return null;
  }

  return org.featureGrid;
}

export function getStateMetadataFromOrg(org: Org) {
  const featureGrid = getFeatureGridFromOrg(org);
  if (!featureGrid) {
    return null;
  }

  return featureGrid.stateMetadata;
}

export function getUserMetadataFromOrg(org: Org) {
  const stateMetadata = getStateMetadataFromOrg(org);

  if (!stateMetadata) {
    return null;
  }

  return stateMetadata.userMetadata;
}
