import { EventTime } from "@clockwise/schema";

export function validateEventTime(eventTime?: EventTime) {
  const isValid =
    !!eventTime &&
    typeof eventTime.millis === "number" &&
    typeof eventTime.dateOrDateTime === "string" &&
    (typeof eventTime.timeZone === "string" || typeof eventTime.offsetMinutes === "number");
  return isValid;
}
