import React from "react";

// solution from: https://stackoverflow.com/a/47880284/1162945
export const copyTextToClipboard = (text: string, singeLineText = false): boolean => {
  let elem: HTMLElement;
  if (singeLineText) {
    elem = document.createElement("span");
  } else {
    elem = document.createElement("textarea");
  }
  elem.textContent = text;
  elem.style.height = "0px";
  elem.style.overflow = "hidden";
  document.body.appendChild(elem);

  // select the text via selection and range
  const selection = document.getSelection();
  if (!selection) {
    return false;
  }
  const range = document.createRange();
  range.selectNode(elem);
  selection.removeAllRanges();
  selection.addRange(range);
  const success = document.execCommand("copy");

  // clean up
  selection.removeAllRanges();
  document.body.removeChild(elem);
  return success;
};

export const classNames = (maybeClassNames: (string | false | undefined)[]) =>
  maybeClassNames.filter((className) => typeof className === "string").join(" ");

export const onSpaceOrEnterKey = (onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void) => {
  return (e: React.KeyboardEvent<HTMLElement>) => {
    switch (e.key) {
      case "Enter":
      case " ":
      case "Spacebar": {
        e.stopPropagation();
        onKeyDown?.(e);
        break;
      }
    }
  };
};

/**
 * Attempts to copy html text to clipboard in the form of rich text
 *
 * @privateRemarks
 * Broswer support for required APIs is spotty and this implements a number of fallbacks
 * This function should be updated as browser support changes
 *
 * @param text - html/text to be copied to clipboard
 * @param canFallbackToHTML - defaults true; if true final fallback to copy to clipboard will be as plain text with HTML tags
 * @returns Promise resolving to copy success
 */
export const writeRichTextToClipboard = (text: string, canFallbackToHTML = true) => {
  // FF does not support ClipboardItem as of v101.01
  if (typeof ClipboardItem !== "undefined") {
    const clipboardHtmlBlob = new ClipboardItem({
      "text/html": Promise.resolve(new Blob([text], { type: "text/html" })),
      "text/plain": Promise.resolve(new Blob([text], { type: "text/plain" })),
    });

    return navigator.clipboard.write([clipboardHtmlBlob]).then(() => true);
  }

  // Fallback #1 -> use `document.execCommand` despite being deprecated
  if (document?.execCommand) {
    const listener = (e: ClipboardEvent) => {
      if (e.clipboardData?.setData) {
        e.clipboardData.setData("text/html", text);
        e.clipboardData.setData("text/plain", text);
        e.preventDefault();
      }
    };

    document.addEventListener("copy", listener);
    const copied = document.execCommand("copy");
    document.removeEventListener("copy", listener);

    return Promise.resolve(copied);
  }

  // Fallback #2 -> copy as plain text with HTML
  if (canFallbackToHTML && navigator?.clipboard?.writeText) {
    return navigator.clipboard.writeText(text).then(() => true);
  }

  // Unable to copy text reslove false
  return Promise.resolve(false);
};
