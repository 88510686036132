import { DateTime } from "luxon";

export const getEventStartEndTimeText = (
  startDateOrDateTime: DateTime,
  endDateOrDateTime: DateTime,
) => {
  const start = DateTime.min(startDateOrDateTime, endDateOrDateTime);
  const end = DateTime.max(startDateOrDateTime, endDateOrDateTime);

  const startTimeText = start
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(/:00/g, "")
    .replace(/\s/g, "")
    .toLowerCase();
  const endTimeText = end
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(/:00/g, "")
    .replace(/\s/g, "")
    .toLowerCase();

  if (start === end) {
    return startTimeText;
  }

  return `${startTimeText} - ${endTimeText}`;
};
