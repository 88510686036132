import { UserProfile } from "../../../hooks/useUserProfile";

const DEFAULT_NAME_DELIMITER = " // ";

/**
 * Interface for attendees provided by the CreateProposalContext reducer.
 * In this case, all the info for each attendee is bundled into objects in
 * the attendees array.
 */
export interface DefaultEventNameBuilderParamsWithBundledAttendees {
  attendees: {
    id: string;
    person: {
      isMe: boolean;
      givenName: string | null;
      familyName: string | null;
      [k: string]: unknown;
    };
    [k: string]: unknown;
  }[];
  myProfile?: undefined;
  collaborators?: undefined;
}

/**
 * Interface for attendees provided by the CalendarWeek component.
 * In this case, attendees are an array of Calendar IDs and profile
 * information is provided in alternate objects (the useUserProfile
 * and useCollaborators contexts).
 */
export interface DefaultEventNameBuilderParamsWithCollaborators {
  attendees: string[];
  myProfile: UserProfile;
  collaborators: {
    calendarId: string;
    fullName?: string | null;
    [k: string]: unknown;
  }[];
}

export type DefaultEventNameBuilderParams =
  | DefaultEventNameBuilderParamsWithBundledAttendees
  | DefaultEventNameBuilderParamsWithCollaborators;

function isBundledAttendees(
  subject: DefaultEventNameBuilderParams,
): subject is DefaultEventNameBuilderParamsWithBundledAttendees {
  return typeof subject.attendees[0] !== "string";
}

export function getDefaultEventName(props: DefaultEventNameBuilderParams) {
  if (props.attendees.length < 2) {
    return "Hold";
  }

  if (props.attendees.length > 3) {
    return "Sync";
  }

  let possibleEventName: string;
  let foundUnknownName = false;

  // Else, generate a title based on the attendees' names.
  if (isBundledAttendees(props)) {
    let myName = "";
    possibleEventName =
      props.attendees
        .map((curr) => {
          if (curr.person.isMe) {
            myName = curr.person.givenName ?? "";
            return null;
          }
          if (!curr.person.givenName) {
            foundUnknownName = true;
            return null;
          }
          return curr.person.givenName.split(" ")[0];
        })
        .filter((curr) => curr !== null)
        .join(DEFAULT_NAME_DELIMITER) + (myName !== "" ? `${DEFAULT_NAME_DELIMITER}${myName}` : "");
  } else {
    possibleEventName =
      props.attendees
        .map((currAttendee) => {
          // You're always last in the event name. Save this for later.
          if (props.myProfile.primaryCalendar === currAttendee) {
            return null;
          }
          const collaborator = props.collaborators.find(
            (currCollab) => currCollab.calendarId === currAttendee,
          );
          if (!collaborator || !collaborator.fullName) {
            foundUnknownName = true;
            return null;
          }
          return collaborator.fullName.split(" ")[0];
        })
        .filter((curr) => curr !== null)
        .join(" // ") + ` // ${props.myProfile.givenName}`;
  }
  if (foundUnknownName) {
    return "Sync";
  }
  return possibleEventName;
}

export function isEventNameDefault(
  currEventName: string,
  builderProps: DefaultEventNameBuilderParams,
) {
  return currEventName === getDefaultEventName(builderProps);
}
