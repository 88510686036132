import { ChromeAppWrapperView } from "#webapp/src/util/chrome-extension.util";
import { INavTab } from "../reducers/nav-tabs.reducer";

export enum NavTabsAction {
  SetSelectedTabView = "NavTabs_SetSelectedTabView",
}

export interface INavTabsAction {
  type: NavTabsAction;
  data: {
    selectedTabView?: ChromeAppWrapperView;
    tabs?: INavTab[];
  };
}

export const setSelectedTabView = (selectedTabView: ChromeAppWrapperView) => ({
  type: NavTabsAction.SetSelectedTabView,
  data: {
    selectedTabView,
  },
});
