import { graphql } from "react-relay";

export const upsertTravelTimeMutation = graphql`
  mutation UpsertTravelTimeMutation($input: UpsertTravelTimeMutationInput!) {
    upsertTravelTime(input: $input) {
      org {
        id
        userGoals(first: 1000000) {
          edges {
            node {
              id
              goalId
              targetUrn {
                id
                type
                value
              }
              otherUrns {
                id
                type
                value
              }
              name
              isDeleted
              options {
                syncToGoogle
              }
            }
          }
        }
      }
    }
  }
`;
