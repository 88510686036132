/**
 * @generated SignedSource<<931215da98a8a71a6eb2aed9bf113082>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContainerType = "Multi" | "Single" | "%future added value";
export type UrnType = "Date" | "Email" | "Event" | "FlowState" | "Guest" | "Org" | "Phone" | "Services" | "Setting" | "Team" | "Teammate" | "TimeZone" | "User" | "WorkOsOrg" | "WorkingGroup" | "WorkingHours" | "%future added value";
export type ValueType = "Boolean" | "Long" | "Numeric" | "String" | "%future added value";
export type UpdateSettingMutationInput = {
  clientMutationId?: string | null;
  setting: SettingInput;
  value: ReadonlyArray<string | null>;
};
export type SettingInput = {
  id: string;
  key?: SettingKeyInput | null;
  type?: SettingTypeInput | null;
  value?: ReadonlyArray<string | null> | null;
};
export type SettingKeyInput = {
  id: string;
  name?: string | null;
  scope?: UrnInput | null;
  target?: UrnInput | null;
};
export type UrnInput = {
  id?: string | null;
  scope?: UrnInput | null;
  type?: UrnType | null;
  value?: string | null;
};
export type SettingTypeInput = {
  containerType?: ContainerType | null;
  id: string;
  required?: boolean | null;
  valueType?: ValueType | null;
};
export type UpdateSettingMutation$variables = {
  input: UpdateSettingMutationInput;
};
export type UpdateSettingMutation$data = {
  readonly updateSetting: {
    readonly setting: {
      readonly id: string;
      readonly key: {
        readonly id: string;
        readonly name: string | null;
        readonly scope: {
          readonly id: string;
          readonly type: UrnType | null;
          readonly value: string | null;
        } | null;
        readonly target: {
          readonly id: string;
          readonly type: UrnType | null;
          readonly value: string | null;
        } | null;
      } | null;
      readonly type: {
        readonly id: string;
        readonly valueType: ValueType | null;
        readonly containerType: ContainerType | null;
        readonly required: boolean | null;
      } | null;
      readonly value: ReadonlyArray<string | null> | null;
    } | null;
  } | null;
};
export type UpdateSettingMutation = {
  variables: UpdateSettingMutation$variables;
  response: UpdateSettingMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSettingMutationPayload",
    "kind": "LinkedField",
    "name": "updateSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Setting",
        "kind": "LinkedField",
        "name": "setting",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingKey",
            "kind": "LinkedField",
            "name": "key",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Urn",
                "kind": "LinkedField",
                "name": "scope",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Urn",
                "kind": "LinkedField",
                "name": "target",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingType",
            "kind": "LinkedField",
            "name": "type",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "valueType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "containerType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "required",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSettingMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSettingMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "e33b9dea2567f96b1e58eec55c249d13",
    "id": null,
    "metadata": {},
    "name": "UpdateSettingMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSettingMutation(\n  $input: UpdateSettingMutationInput!\n) {\n  updateSetting(input: $input) {\n    setting {\n      id\n      key {\n        id\n        name\n        scope {\n          id\n          type\n          value\n        }\n        target {\n          id\n          type\n          value\n        }\n      }\n      type {\n        id\n        valueType\n        containerType\n        required\n      }\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "07947921d8cec331c8e00fa5b8cc01cc";

export default node;
