import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateOrgSubscriptionStripeMutationVariables = Types.Exact<{
  input: Types.CreateOrgSubscriptionStripeInput;
}>;


export type CreateOrgSubscriptionStripeMutation = { __typename: 'Mutation', createOrgSubscriptionStripe: { __typename: 'CreateOrgSubscriptionStripePayload', clientMutationId: string | null, stripeError: { __typename: 'StripeError', code: string | null, message: string | null } | null } | null };


export const CreateOrgSubscriptionStripeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateOrgSubscriptionStripe"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateOrgSubscriptionStripeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createOrgSubscriptionStripe"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clientMutationId"}},{"kind":"Field","name":{"kind":"Name","value":"stripeError"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]} as unknown as DocumentNode<CreateOrgSubscriptionStripeMutation, CreateOrgSubscriptionStripeMutationVariables>;