/**
 * @generated SignedSource<<404586ab9407edacb1c05965586d78a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ValidateSlackChannelBySendingMessageMutationInput = {
  clientMutationId?: string | null;
  orgRelayId: string;
  slackChannelName: string;
  teamId: string;
};
export type ValidateSlackChannelBySendingMessageMutation$variables = {
  input: ValidateSlackChannelBySendingMessageMutationInput;
};
export type ValidateSlackChannelBySendingMessageMutation$data = {
  readonly validateSlackChannelBySendingMessage: {
    readonly clientMutationId: string | null;
    readonly validateSlackChannelResponse: {
      readonly __typename: string;
      readonly id: string;
      readonly isValid: boolean;
      readonly channel: {
        readonly __typename: string;
        readonly channelId: string;
        readonly name: string;
      } | null;
    };
  } | null;
};
export type ValidateSlackChannelBySendingMessageMutation = {
  variables: ValidateSlackChannelBySendingMessageMutation$variables;
  response: ValidateSlackChannelBySendingMessageMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ValidateSlackChannelBySendingMessageMutationPayload",
    "kind": "LinkedField",
    "name": "validateSlackChannelBySendingMessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidateSlackChannelResponse",
        "kind": "LinkedField",
        "name": "validateSlackChannelResponse",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isValid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SlackChannel",
            "kind": "LinkedField",
            "name": "channel",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "channelId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ValidateSlackChannelBySendingMessageMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ValidateSlackChannelBySendingMessageMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f8bba446f07b670c270a9e78878b3bd3",
    "id": null,
    "metadata": {},
    "name": "ValidateSlackChannelBySendingMessageMutation",
    "operationKind": "mutation",
    "text": "mutation ValidateSlackChannelBySendingMessageMutation(\n  $input: ValidateSlackChannelBySendingMessageMutationInput!\n) {\n  validateSlackChannelBySendingMessage(input: $input) {\n    clientMutationId\n    validateSlackChannelResponse {\n      __typename\n      id\n      isValid\n      channel {\n        __typename\n        channelId\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f26163f5a1d7b9aa1a436b2e66b8714b";

export default node;
