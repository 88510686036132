import { SubscriptionInterval } from "@clockwise/schema";
import React from "react";
import { ITierContent } from "../../../constants/org-pricing.constants";
import DowngradeButton from "../cta-buttons/DowngradeButton";

import { Divider } from "@clockwise/design-system";
import {
  FeatureBullet,
  Tier,
  TierButton,
  TierCost,
  TierDescription,
  TierFeatureHeader,
  TierTitle,
} from "./components";

type FreeTierHeaderProps = {
  billingRecurrence: SubscriptionInterval;
  isCurrentPlan: boolean;
  onSelect: () => void;
  tier: ITierContent;
  isFullWidth: boolean;
};

const FreeTierHeader = ({
  billingRecurrence,
  isCurrentPlan,
  onSelect,
  tier,
  isFullWidth,
}: FreeTierHeaderProps) => {
  const costForRecurrence =
    billingRecurrence === SubscriptionInterval.Yearly
      ? tier.planPricing.yearlyCost
      : tier.planPricing.monthlyCost;

  return (
    <Tier isFullWidth={isFullWidth}>
      <TierTitle text={tier.title} />
      <TierCost cost={costForRecurrence} showDecimal={false} />
      <TierDescription> {tier.costUnit}</TierDescription>
      <TierButton>
        <DowngradeButton isCurrentPlan={isCurrentPlan} onClick={onSelect} />
      </TierButton>

      <TierDescription>
        For teams who need smart calendar management for a healthier work day
      </TierDescription>

      <Divider className="cw-flex-shrink-0 cw-w-full" />
      <TierFeatureHeader text={tier.featureHeader} />

      <FeatureBullet>Automatically find the best time for your tasks and routines</FeatureBullet>
      <FeatureBullet>Protect personal time for meals, travel, and appointments</FeatureBullet>
      <FeatureBullet>Integrate your calendar with your favorite productivity tools</FeatureBullet>
    </Tier>
  );
};

export default FreeTierHeader;
