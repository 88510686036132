import classNames from "classnames";
import * as React from "react";

import { DefragRunFlowState, getDefragRunTag } from "../../util/event-history.util";

export interface NextDefragRunTagProps {
  nextDefrag: string;
}

export const NextDefragRunTag = ({ nextDefrag }: NextDefragRunTagProps) => {
  const { label, flowState } = getDefragRunTag(nextDefrag);

  if (!label) {
    return null;
  }

  return (
    <span
      className={classNames("cw-w-fit cw-rounded cw-px-[6px] cw-py-[1px]", {
        "cw-bg-brand-emphasis cw-text-onEmphasis": flowState === DefragRunFlowState.Running,
        "cw-bg-positive cw-text-positive": flowState !== DefragRunFlowState.Running,
      })}
    >
      {label}
    </span>
  );
};
