import { SnackBarContentVariant } from "#webapp/src/components/snack-bar/snack-bar-content/snack-bar-content";
import { snackBarActions } from "#webapp/src/state/actions/snack-bar.actions";

export interface ISnackBarState {
  open: boolean;
  message: string;
  actionLabel?: string;
  actionCallback?: () => void;
  dismissCallback?: () => void;
  variant?: SnackBarContentVariant;
  dismissable?: boolean;
  autoHideDuration: number | number;
}

interface ISnackBarAction {
  type?: string;
}

export const initialSnackBarState: ISnackBarState = {
  open: false,
  dismissable: false,
  message: "",
  actionLabel: undefined,
  actionCallback: undefined,
  dismissCallback: undefined,
  variant: undefined,
  autoHideDuration: 4000,
};

export function snackBarReducer(
  state: ISnackBarState = initialSnackBarState,
  action: ISnackBarAction,
) {
  switch (action.type) {
    case snackBarActions.SNACK_BAR_OPEN:
      const newState = Object.assign({}, initialSnackBarState, action);
      newState.open = true; // just in case
      delete newState.type;
      return newState;
    case snackBarActions.SNACK_BAR_CLOSE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}
