import { graphql } from "react-relay";

export const addOrgTagsMutation = graphql`
  mutation AddOrgTagsMutation($input: AddOrgTagsMutationInput!) {
    addOrgTags(input: $input) {
      eventParent {
        events {
          autopilotEventStatus {
            __typename

            ... on AutopilotEventStatus {
              status
            }
          }
          annotatedEvent {
            id
            externalEventId
            orgTags {
              tag
              state {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
            userTags {
              tag
              states {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
          annotatedRecurringEvent {
            id
            externalEventId
            orgTags {
              tag
              state {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
            userTags {
              tag
              states {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
          moveRangeInLocalTimeV3 {
            __typename
            ... on MoveRangeInLocalTimeV3 {
              allowedStartTimeInclusive {
                dayOffset
                timeSlot
              }
              allowedEndTimeInclusive {
                dayOffset
                timeSlot
              }
            }
            ... on GraphEntityError {
              message
            }
          }
        }
      }
    }
  }
`;
