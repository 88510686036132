import { SubscriptionInterval } from "@clockwise/schema";
import React from "react";
import { ITierContent } from "../../../constants/org-pricing.constants";
import ContactUsButton from "../cta-buttons/ContactUsButton";

import { Divider } from "@clockwise/design-system";

import {
  FeatureBullet,
  Tier,
  TierButton,
  TierCost,
  TierDescription,
  TierFeatureHeader,
  TierTitle,
} from "./components";

type BusinessTierHeaderProps = {
  billingRecurrence: SubscriptionInterval;
  tier: ITierContent;
  isCurrentPlan: boolean;
  onSelect: () => void;
  isFullWidth: boolean;
  isM365Beta: boolean;
};

export default function BusinessTierHeader({
  billingRecurrence,
  tier,
  onSelect,
  isCurrentPlan,
  isFullWidth,
  isM365Beta,
}: BusinessTierHeaderProps) {
  const costForRecurrence =
    billingRecurrence === SubscriptionInterval.Yearly
      ? tier.planPricing.yearlyCost
      : tier.planPricing.monthlyCost;

  return (
    <Tier isFullWidth={isFullWidth}>
      <TierTitle text={tier.title} />
      <TierCost cost={costForRecurrence} />
      <TierDescription> {tier.costUnit}</TierDescription>
      <TierButton>
        <ContactUsButton isCurrentPlan={isCurrentPlan} onClick={onSelect} isM365Beta={isM365Beta} />
      </TierButton>

      <TierDescription>
        For companies looking to automate calendar management org-wide
      </TierDescription>

      <Divider className="cw-flex-shrink-0 cw-w-full" />
      <TierFeatureHeader text={tier.featureHeader} />

      <FeatureBullet>
        Measure meeting load and focus time across your entire organization
      </FeatureBullet>
      <FeatureBullet>Access advanced billing management and admin controls</FeatureBullet>
      <FeatureBullet>Balance meeting distribution using round-robin scheduling logic</FeatureBullet>
    </Tier>
  );
}
