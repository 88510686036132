import * as React from "react";
import { ClockwiseLogoFull } from "./ClockwiseLogoFull";

interface IProps {
  style?: React.CSSProperties;
  // fill?: string;
  onEmphasis?: boolean;
  className?: string;
}

const baseStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

export const LogoHorizontal = ({
  onEmphasis = false,
  style,
  className = "cw-text-onEmphasis",
}: IProps) => (
  <div className={className} style={{ ...baseStyles, ...style }}>
    <ClockwiseLogoFull onEmphasis={onEmphasis} />
  </div>
);
