import React from "react";

export const ClockwiseLogoCircle = ({
  size = 32,
  fill = "#B6FF80",
  background = "#003F2E",
}: {
  size?: number | string;
  fill?: string;
  background?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={` 0 0 32 32`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0" width="32" height="32" rx="16" fill={background} />
      <path
        d="M15.5999 24.4342C15.5999 24.9698 15.5466 25.4931 15.446 26C14.1432 25.8235 12.9183 25.017 12.3233 23.5805L12.2721 23.4574C11.5724 21.7685 10.2327 20.4264 8.54206 19.7267L8.41485 19.6733C6.98482 19.0823 6.1785 17.8592 6 16.5561C6.50677 16.4556 7.03201 16.4022 7.56955 16.4022C12.0053 16.4022 15.5999 19.9975 15.5999 24.4342ZM15.5999 7.56782C15.5999 7.03222 15.5466 6.50687 15.446 6C14.1432 6.17648 12.9183 6.98297 12.3254 8.41945L12.2741 8.54463C11.5765 10.2335 10.2347 11.5736 8.54822 12.2733L8.41896 12.3267C6.98277 12.9198 6.1785 14.1428 6 15.4459C6.50677 15.5465 7.03201 15.5998 7.56955 15.5998C12.0053 15.5998 15.5999 12.0045 15.5999 7.56782ZM19.6746 23.5805L19.7259 23.4574C20.4255 21.7685 21.7673 20.4264 23.4559 19.7267L23.581 19.6754C25.0152 19.0823 25.8215 17.8572 26 16.5541C25.4932 16.4535 24.968 16.4002 24.4304 16.4002C19.9947 16.4002 16.4001 19.9955 16.4001 24.4322C16.4001 24.9678 16.4534 25.4911 16.554 25.9979C17.8568 25.8215 19.0817 25.015 19.6746 23.5785V23.5805ZM24.4304 15.5998C24.968 15.5998 25.4912 15.5465 26 15.4459C25.8215 14.1449 25.0172 12.9198 23.581 12.3267L23.4538 12.2733C21.7653 11.5736 20.4255 10.2335 19.7259 8.54463L19.6746 8.41945C19.0817 6.98297 17.8568 6.17648 16.554 6C16.4534 6.50687 16.4001 7.03017 16.4001 7.56782C16.4001 12.0045 19.9947 15.5998 24.4304 15.5998V15.5998Z"
        fill={fill}
      />
    </svg>
  );
};
