import { graphql } from "react-relay";

export const createDataSourceMutation = graphql`
  mutation CreateDataSourceMutation($input: CreateDataSourceMutationInput!) {
    createDataSource(input: $input) {
      dataSourceAuthError
      user {
        dataSources(first: 1000000) {
          edges {
            node {
              id
              calendarIds
              name
              username
              state
              type
              syncTargets {
                calendarId
                emailAddress
              }
            }
          }
        }
      }
      dataSource {
        id
        calendarIds
        name
        username
        state
        type
        syncTargets {
          calendarId
          emailAddress
        }
      }
    }
  }
`;
