import { TeamHealthActions } from "#webapp/src/state/actions/team-health.actions";

export interface ITeamHealthState {
  refetchTeamHealth: boolean;
}

interface ITeamHealthAction {
  type: TeamHealthActions;
}

export const initialTeamHealthState: ITeamHealthState = {
  refetchTeamHealth: false, // note that the value doesn't matter here, we just flip it back and forth
};

export function teamHealthReducer(
  state: ITeamHealthState = initialTeamHealthState,
  action: ITeamHealthAction,
) {
  switch (action.type) {
    case TeamHealthActions.RefetchTeamHealth:
      return { ...state, refetchTeamHealth: !state.refetchTeamHealth };
    default:
      return state;
  }
}
