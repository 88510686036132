// schema
import * as ISchema from "#webapp/src/__schema__";

// libraries
import * as React from "react";

import { createFragmentContainer, QueryRenderer, ReadyState, RelayProp } from "react-relay";

// internal
import { TeamJoinCreateDialog } from "./TeamJoinCreateDialog";
import { fragments, query } from "./TeamJoinCreateDialogRenderer.gql";

export interface IProps {
  org: ISchema.IOrg;
  open: boolean;
  onClose: (teamId?: string) => void;
  relay: RelayProp;
  forcedInitialView?: "join" | "create" | "select";
  mode: "onboarding" | "settings" | "checkout";
  selectMode?: boolean;
  shouldReloadAfterCreateOrJoin?: boolean;
}

const TeamJoinCreateDialogRendererComponent = (props: IProps) => {
  const { org, selectMode } = props;
  return (
    <QueryRenderer
      variables={{
        orgRelayId: org.id,
        myCalendarIds: [org.primaryOrgCalendar],
        excludeMyTeams: !selectMode,
      }}
      query={query}
      environment={props.relay.environment}
      render={(readyState: ReadyState) => {
        const viewer: ISchema.IViewer = readyState.props && readyState.props.viewer;

        if (viewer) {
          return <TeamJoinCreateDialog {...props} viewer={viewer} />;
        } else {
          return null;
        }
      }}
    />
  );
};

export const TeamJoinCreateDialogRenderer = createFragmentContainer(
  TeamJoinCreateDialogRendererComponent,
  fragments,
);
