import { Tooltip as DesignTooltip } from "@clockwise/design-system";
import classNames from "classnames";
import * as React from "react";

interface TooltipBodyProps {
  header?: string;
  body?: string;
}
const ToolTipBody = ({ body, header }: TooltipBodyProps) => {
  return (
    <div className="cw-p-1 cw-space-y-1">
      {!!header && (
        <div className="cw-body-sm cw-text-onEmphasis cw-text-12 cw-font-bold">{header}</div>
      )}
      <div
        className="cw-tooltip-content [&_a]:cw-no-underline [&_a]:cw-text-onEmphasis"
        dangerouslySetInnerHTML={{ __html: body ?? "" }}
      />
    </div>
  );
};

export interface TooltipProps {
  children: React.ReactNode;
  header?: string;
  body: string;
  width?: number;
  placement: "top" | "left" | "right" | "bottom";
  showArrow: boolean;
  styles?: Record<string, string>;
  className?: string;
}

/**
 * @deprecated Use `@clockwise/design-system` Tooltip component if possible.
 */
export const LegacyTooltip = ({
  children,
  header,
  body,
  width,
  placement,
  showArrow,
  className,
}: TooltipProps) => {
  if (!header && !body) {
    return <>{children}</>; // Don't render tooltip if there is no header or body
  }

  return (
    <DesignTooltip
      title={<ToolTipBody header={header} body={body} />}
      placement={placement}
      arrow={showArrow}
      maxWidth={width}
    >
      <div className={classNames(className)}>{children}</div>
    </DesignTooltip>
  );
};
