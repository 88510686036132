import { Tooltip } from "@clockwise/design-system";
import { bg_neutral_inset } from "@clockwise/design-system/tokens";
import { animated, config, useSprings, useTransition } from "@react-spring/web";
import classNames from "classnames";
import React, { useMemo } from "react";
import { AvatarSize, SIZE_TABLE } from "./AttendeeAvatar";

type StackProps = {
  overlap?: boolean;
  size?: AvatarSize;
  maxShown?: number;
  disableAnimation?: boolean;
  disableOverflow?: boolean;
  overflowToolTipContent?: string;
  overflowSizeNum?: number;
  overflowFillColor?: string;
};

// These are eyeballed, if you need to change them feel free
const OVERLAP_CHART: Record<AvatarSize, string> = {
  xsmall: "-cw-ml-[2px]",
  small: "-cw-ml-[3px]",
  medium: "-cw-ml-[4px]",
  large: "-cw-ml-[6px]",
  xlarge: "-cw-ml-[12px]",
};

export const OverflowAvatar = ({
  count,
  overflowTooltipContent,
  size,
  overflowFillColor = bg_neutral_inset,
}: {
  count: number;
  overflowTooltipContent?: string;
  size: AvatarSize;
  "cw-id"?: string;
  "aria-label"?: string;
  overflowFillColor?: string;
}) => {
  const sizeOfAvatar = SIZE_TABLE[size].size;
  return (
    <Tooltip title={overflowTooltipContent || ""}>
      <div
        style={{
          width: sizeOfAvatar,
          height: sizeOfAvatar,
        }}
        className="cw-p-0 cw-inline-flex cw-relative cw-shrink-0 cw-align-middle cw-box-border"
      >
        <div
          style={{ backgroundColor: overflowFillColor }}
          className="cw-text-muted  cw-border cw-border-solid cw-border-muted cw-flex cw-w-full cw-h-full cw-overflow-hidden cw-box-border cw-relative cw-items-center cw-leading-none cw-select-none cw-rounded-full cw-justify-center cw-font-body cw-font-semibold cw-text-[10px]"
        >
          <>{`${count.toString()}`}</>
        </div>
      </div>
    </Tooltip>
  );
};

export function AttendeeAvatarStack({
  children,
  overlap = true,
  size = "large",
  maxShown = 6,
  disableAnimation = false,
  disableOverflow = false,
  overflowToolTipContent,
  overflowFillColor,
}: React.PropsWithChildren<StackProps>) {
  const avatarsCount = React.Children.count(children);

  const toRender = useMemo(() => {
    const avatars = React.Children.toArray(children);

    const toRender = avatars.slice(0, maxShown);
    if (avatars.length > maxShown && !disableOverflow) {
      const extraCount = Math.max(avatars.length - maxShown, 0);
      toRender.push(
        <OverflowAvatar
          size={size}
          count={extraCount}
          overflowTooltipContent={overflowToolTipContent}
          overflowFillColor={overflowFillColor}
        />,
      );
    }
    return toRender;
  }, [children, maxShown, size, disableOverflow, overflowToolTipContent]);

  const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);

  const animationDisabled = disableAnimation || avatarsCount <= 1;

  const avatars = useTransition(toRender, {
    from: { translateY: animationDisabled ? 0 : -5 },
    enter: { translateY: 0 },
    trail: 50,
    config: config.stiff,
    immediate: animationDisabled,
    keys: (elem) =>
      (elem as JSX.Element).key ?? JSON.stringify((elem as JSX.Element).props).substring(0, 15), // If key not present, fingerprint the props.
  });

  const [springs] = useSprings(
    toRender.length,
    (index) => ({
      translateY: !animationDisabled && hoveredIndex === index ? -5 : 0,
      immediate: animationDisabled,
      config: config.stiff,
    }),
    [hoveredIndex],
  );

  return (
    <div
      className={classNames("cw-inline-flex cw-items-center", {
        "cw-gap-1": !overlap,
      })}
    >
      {avatars((transitionStyle, avatar, _, i) => (
        <animated.div
          style={{
            translateY: transitionStyle.translateY,
            zIndex: hoveredIndex === i ? toRender.length + 1 : toRender.length - i,
          }}
          className={overlap && i !== 0 ? OVERLAP_CHART[size] : ""}
          key={i}
          onMouseEnter={() => setHoveredIndex(i)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <animated.div style={springs[i]}>
            {React.cloneElement(avatar as JSX.Element, {
              size,
            })}
          </animated.div>
        </animated.div>
      ))}
    </div>
  );
}
