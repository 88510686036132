import React, { CSSProperties } from "react";

export const DeterioratingClock = ({ style }: { style?: CSSProperties }) => {
  return (
    <svg style={style} width="108px" height="130px" viewBox="0 0 108 130" version="1.1">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="error-state" transform="translate(-1333.000000, -171.000000)">
          <g id="404_hero_-02" transform="translate(1333.000000, 171.000000)">
            <polygon
              id="Rectangle"
              fill="#EEF0F1"
              transform="translate(44.937694, 16.498157) rotate(-54.590000) translate(-44.937694, -16.498157) "
              points="29.5109385 15.9605663 60.3626519 16.014944 60.3644489 17.0357485 29.5127356 16.9813708"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#EEF0F1"
              transform="translate(62.858267, 16.497955) rotate(-35.420000) translate(-62.858267, -16.497955) "
              points="62.3206727 1.07119071 63.3414766 1.07298795 63.3958609 31.9247203 62.375057 31.922923"
            ></polygon>
            <ellipse
              id="Oval"
              fill="#EEF0F1"
              cx="53.5178571"
              cy="3.37037037"
              rx="2.41071429"
              ry="2.40740741"
            ></ellipse>
            <path
              d="M53.5220381,22.1481481 C31.8760925,22.1467753 12.3607817,35.1670353 4.07628433,55.1374548 C-4.20821305,75.1078744 0.369641714,98.0955034 15.6751506,113.380988 C30.9806595,128.666472 53.9995743,133.239507 73.997952,124.967643 C93.9963297,116.695778 107.035714,97.2080643 107.035714,75.5918114 C107.035714,46.0770167 83.0773752,22.1500227 53.5220381,22.1481481 Z"
              id="Path"
              fill="#EEF0F1"
            ></path>
            <path
              d="M54.0017068,130 C24.2359114,130 0,105.810969 0,76.0757785 C0,46.3405876 24.2359114,22.1481481 54.0017068,22.1481481 C83.7675021,22.1481481 108,46.3508141 108,76.0757785 C108,105.800743 83.7777427,130 54.0017068,130 Z M54.0017068,22.6594721 C24.5089921,22.6594721 0.512026297,46.6235202 0.512026297,76.0757785 C0.512026297,105.528037 24.5089921,129.488676 54.0017068,129.488676 C83.4944214,129.488676 107.487974,105.528037 107.487974,76.0757785 C107.487974,46.6235202 83.4944214,22.6594721 54.0017068,22.6594721 Z"
              id="Shape"
              fill="#EEF0F1"
              fillRule="nonzero"
            ></path>
            <polygon
              id="Rectangle"
              fill="#6FA4D9"
              transform="translate(44.935945, 14.802008) rotate(-54.590000) translate(-44.935945, -14.802008) "
              points="29.5091896 14.2644169 60.3609029 14.3187946 60.3627 15.3395991 29.5109866 15.2852214"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6FA4D9"
              transform="translate(62.859007, 14.799257) rotate(-35.420000) translate(-62.859007, -14.799257) "
              points="62.3214126 -0.62750798 63.3422165 -0.625710747 63.3966007 30.2260216 62.3757969 30.2242243"
            ></polygon>
            <path
              d="M31.5009092,24.0047525 C4.56900018,36.1477576 -7.40645981,67.7944281 4.7528982,94.6897612 C16.9122562,121.585094 48.6019818,133.544487 75.5340732,121.401886 C102.466165,109.259284 114.4421,77.6127926 102.283146,50.7172775 C90.1225432,23.8226707 58.4334532,11.8635175 31.5009092,24.0047525 Z"
              id="Path"
              fill="#204C77"
            ></path>
            <path
              d="M97.8907477,52.6930017 C108.95327,77.1685443 98.0548787,105.96654 73.5484852,117.015189 C49.0420917,128.063838 20.2077313,117.179258 9.14507549,92.7037759 C-1.91758036,68.2282935 8.98065411,39.4302383 33.4869874,28.3814561 C45.2553103,23.0746729 58.6525111,22.6547894 70.7308323,27.2141917 C82.8091534,31.7735941 92.5790051,40.9387256 97.8907477,52.6930017 Z"
              id="Path"
              fill="#225588"
            ></path>
            <path
              d="M72.6506678,114.433417 C55.9443713,121.966653 36.33901,118.708019 22.976667,106.177031 C9.61432402,93.6460435 5.12653198,74.3105113 11.6059719,57.1866949 C18.0854119,40.0628786 34.2560458,28.5230819 52.5774221,27.9483728 C70.8987983,27.3736638 87.7627697,37.8772236 95.3055777,54.561187 C100.252366,65.5008089 100.644559,77.9548907 96.3958665,89.1833048 C92.1471737,100.411719 83.6056787,109.494553 72.6506678,114.433417 Z"
              id="Path"
              fill="#225588"
            ></path>
            <path
              d="M35.3519748,31.9451543 C18.6447597,39.4761751 8.12612885,56.3148268 8.70100009,74.6091177 C9.27587134,92.9034087 20.8310309,109.050503 37.9782196,115.520888 C55.1254083,121.991273 74.4876984,117.510685 87.0363061,104.168457 C99.5849139,90.8262293 102.848545,71.2499488 95.3053445,54.5681847 C85.0057593,31.7906859 58.1642247,21.6622002 35.3519748,31.9451543 Z"
              id="Path"
              fill="#204C77"
            ></path>
            <path
              d="M72.6454103,114.432591 C55.9411075,121.966654 36.3375378,118.70872 22.976126,106.17801 C9.61471421,93.6472995 5.12687277,74.3116267 11.6053463,57.1874921 C18.0838199,40.0633574 34.2527302,28.5232084 52.572283,27.9483815 C70.8918358,27.3735547 87.7541566,37.8772572 95.296104,54.5614622 C105.593616,77.3414944 95.4528832,104.145845 72.6454103,114.432591 Z"
              id="Path"
              fill="#204C77"
            ></path>
            <path
              d="M95.2975375,54.5606335 C105.593138,77.3396188 95.4507568,104.141475 72.6436258,114.424967 C49.8364948,124.708459 23.0007897,114.579627 12.703688,91.8013183 C2.40658626,69.0230099 12.5472009,42.2204868 35.3536541,31.9354956 C46.3066605,26.9960351 58.7757734,26.6048184 70.0176023,30.8479191 C81.2594313,35.0910197 90.3529819,43.6208187 95.2975375,54.5606335 Z"
              id="Path"
              fill="#FFFFFF"
            ></path>
            <polygon
              id="Rectangle"
              fill="#6FA4D9"
              transform="translate(57.981493, 61.947186) rotate(-69.300000) translate(-57.981493, -61.947186) "
              points="39.3950084 61.0728926 76.5658806 61.1188012 76.5679776 62.8214788 39.3971054 62.7755702"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#E9926F"
              transform="translate(44.936712, 73.617565) rotate(-5.760000) translate(-44.936712, -73.617565) "
              points="27.372701 73.1018771 62.5003427 73.1149368 62.5007226 74.1332519 27.373081 74.1201922"
            ></polygon>
            <polygon
              id="Path"
              fill="#225588"
              points="54 108.096445 45.5986239 111.757395 48.0422674 112.568233 47.6133683 113.62963 43.8866317 112.328113 43.3928571 111.266716 53.4341415 106.888889"
            ></polygon>
            <path
              d="M47.4593856,108.112023 C45.1090515,109.158096 42.0304934,109.297799 40.8397954,106.691137 C39.6490975,104.084474 41.7889025,101.90033 44.1392366,100.85085 C46.4895708,99.8013705 49.5888367,99.6718892 50.772632,102.264922 C51.9564274,104.857955 49.7959146,107.062544 47.4593856,108.112023 Z M44.698347,102.060478 C42.7828764,102.915736 41.0848376,104.408178 41.9131492,106.217508 C42.7414608,108.026838 44.9951586,107.754246 46.9106292,106.898988 C48.8260998,106.04373 50.5379438,104.544474 49.7130835,102.735143 C48.8882232,100.925813 46.6103663,101.20522 44.6914444,102.060478 L44.698347,102.060478 Z"
              id="Shape"
              fill="#225588"
              fillRule="nonzero"
            ></path>
            <path
              d="M68.4472329,80.8888889 L68.001287,79.934265 C70.2947233,75.6983351 71.490991,73.2655838 70.722973,71.6197984 C70.1496139,70.3914472 68.8577864,70.3367017 67.8384813,70.7815086 C66.7849655,71.2041285 65.9983921,72.0811506 65.7149292,73.1492497 L64.6071429,72.7215508 C64.9807639,71.3643236 65.9908188,70.2548648 67.3359073,69.7242369 C68.978121,69.0057028 71.0698198,69.1767823 71.9581725,71.0928734 C72.8465251,73.0089644 71.6537967,75.6983351 69.8841699,79.0001706 L74.7187902,76.8890488 L75.2142857,77.9497421 L68.4472329,80.8888889 Z"
              id="Path"
              fill="#225588"
            ></path>
            <path
              d="M56.8928571,108.506692 L57.8429053,108.022538 C58.1661344,109.086249 58.9494442,109.931109 59.9595093,110.305458 C61.3037951,110.83332 62.3798701,110.450031 62.7773392,109.353961 C63.2103544,108.157025 62.4541936,107.282858 61.0937505,106.748272 C60.7059757,106.596974 60.276192,106.412054 60.1243135,106.388518 L60.5217827,105.292448 C60.6671982,105.366416 61.0969819,105.534525 61.4718309,105.682461 C62.6351552,106.139717 63.7920165,106.048938 64.1959486,104.939419 C64.5998806,103.8299 63.8501828,102.922111 62.7288674,102.485028 C61.792759,102.104253 60.7418125,102.180771 59.865797,102.693483 L59.5006425,101.684829 C60.6370062,101.014898 62.0065949,100.926843 63.2135859,101.446114 C64.984424,102.142086 65.9894069,103.607996 65.4045133,105.225204 C64.8939432,106.620509 63.5205742,106.872672 62.6093035,106.681028 C63.4107047,107.091214 64.4447707,108.338583 63.8792659,109.895272 C63.313761,111.451961 61.6172464,112.17483 59.5911232,111.374631 C58.0335613,110.769439 57.1481422,109.596038 56.8928571,108.506692 Z"
              id="Path"
              fill="#225588"
            ></path>
            <path
              d="M55.1269508,93.0137812 C55.5990256,91.7305055 56.9923018,91.2635544 58.2905074,91.3576165 C57.3234098,90.6219166 56.5989062,89.4998902 57.0611461,88.2468487 C57.6971357,86.5167781 59.6247743,86.3924817 61.1819654,86.9938072 C62.7391565,87.5951328 64.1324327,89.0094235 63.4767733,90.7327755 C63.0145334,91.9858169 61.7327193,92.3284717 60.526306,92.2276909 C61.5589695,93.0406561 62.303143,94.3306506 61.8376248,95.6139263 C61.2376964,97.2398567 59.3592323,97.6295426 57.5168293,96.9207175 C55.6744264,96.2118924 54.5204659,94.6531492 55.1269508,93.0137812 Z M60.7000557,95.0293975 C61.1622956,93.7763561 59.7559062,92.6240954 59.2051523,92.3419091 C58.6019456,92.1907379 56.8021606,92.1033946 56.3399207,93.3497173 C55.9596383,94.3877597 56.7365947,95.4123646 57.9036684,95.8625189 C59.0707421,96.3126732 60.3296082,96.0640806 60.7131689,95.0260382 L60.7000557,95.0293975 Z M62.2310204,90.4371517 C62.5981897,89.4293436 61.9031907,88.4988007 60.7787349,88.0654432 C59.654279,87.6320857 58.5363797,87.8706003 58.1560973,88.864971 C57.7266404,90.0306691 59.0445157,90.9880869 59.5690433,91.3072261 C60.2083112,91.4248037 61.8146767,91.6129279 62.2441336,90.4337924 L62.2310204,90.4371517 Z"
              id="Shape"
              fill="#225588"
              fillRule="nonzero"
            ></path>
            <polygon
              id="Path"
              fill="#225588"
              points="76.6486416 92.4444444 69.5648857 85.5202822 67.6014716 90.5610985 66.5357143 90.1680524 69.013516 83.7777778 69.8372085 84.0823885 77.1428571 91.1539431"
            ></polygon>
            <path
              d="M70.8503041,106.372309 L71.2120423,105.233559 C72.1885501,105.796936 73.3752366,105.829254 74.3797869,105.319828 C75.6644646,104.726297 76.1580512,103.421911 75.6441802,102.255555 C75.0795982,100.985675 73.8354893,100.588838 72.5643345,101.175467 C71.744234,101.535544 71.1016424,102.218827 70.7826895,103.069933 L69.7887547,103.166555 L67.5,97.9904172 L73.2472421,95.3333333 L73.7205444,96.3996177 L69.1430351,98.5183833 L70.6237951,101.862168 C70.9730721,101.021569 71.6236712,100.348853 72.4426282,99.9815048 C74.0248102,99.2533948 75.9281616,99.6743873 76.8105323,101.665475 C77.692903,103.656562 76.7429177,105.51307 74.8497085,106.386112 C73.274288,107.114222 71.9760874,107.000347 70.8503041,106.372309 Z"
              id="Path"
              fill="#225588"
            ></path>
            <polygon
              id="Path"
              fill="#225588"
              points="84.608456 83.7777778 80.9310868 76.1506399 80.120108 78.3690867 79.0714286 77.9797133 80.3752867 74.5964184 81.4239661 74.1481481 85.8214286 83.2640666"
            ></polygon>
            <path
              d="M86.7580438,82.8148148 L86.3120978,81.8568604 C88.6055341,77.6213333 89.8018018,75.1922345 89.0337838,73.5466057 C88.4604247,72.3183712 87.1685972,72.2602097 86.1492921,72.7083955 C85.0944347,73.1291756 84.3071699,74.0068853 84.02574,75.0759115 L82.9285714,74.6482533 C83.3032115,73.2908903 84.3127281,72.1808625 85.6573359,71.6478031 C87.2995495,70.9327586 89.3912484,71.1038219 90.279601,73.0163095 C91.1679537,74.9287971 89.9752252,77.6213333 88.2055985,80.9228548 L93.0402188,78.8119338 L93.5357143,79.8691049 L86.7580438,82.8148148 Z"
              id="Path"
              fill="#225588"
            ></path>
            <polygon
              id="Path"
              fill="#225588"
              points="73.0442695 67.4074074 69.3748064 59.7802696 68.5698245 61.9987164 67.5 61.612615 68.8033041 58.22932 69.866159 57.7777778 74.25 66.8969683"
            ></polygon>
            <polygon
              id="Path"
              fill="#225588"
              points="77.8507949 65.4814815 74.1923714 57.8465608 73.3820932 60.0672714 72.3214286 59.6775006 73.6220468 56.2940287 74.6966218 55.8518519 79.0714286 64.9770723"
            ></polygon>
            <path
              d="M83.7727994,95.8726685 L84.7122324,93.5806522 L80.0357143,91.9525303 L80.4211227,91.0199168 L87.0350062,86.6666667 L88.7142857,87.251526 L86.3467769,93.0115999 L87.7232355,93.4952944 L87.3240625,94.4816517 L85.9476039,94.0011186 L85.0116121,96.2962963 L83.7727994,95.8726685 Z M87.0763,87.8743221 L81.6805823,91.3929622 L85.1217288,92.5911335 L87.0763,87.8743221 Z"
              id="Shape"
              fill="#225588"
              fillRule="nonzero"
            ></path>
            <path
              d="M83.4999316,60.6352332 C84.4912656,58.0003586 86.6165229,56.0592318 89.3528764,57.1015622 C90.4004511,57.4414665 91.2327714,58.2502808 91.6071429,59.2921648 L90.6531536,59.9756602 C90.4122226,59.13676 89.7779016,58.4701405 88.9556638,58.1917373 C87.0612652,57.4740672 85.4961796,59.0700288 84.7764439,60.9906507 C84.7289142,61.1239323 84.6847795,61.2401265 84.6338548,61.3734081 C85.6040573,60.6933252 86.8368679,60.5158613 87.9575398,60.8949614 C89.7025593,61.5579519 90.7414231,63.1436611 89.9571828,65.2249044 C89.3257166,66.9097204 87.4788477,67.931546 85.3671704,67.1284389 C82.6342118,66.0861085 82.4984126,63.2906126 83.4999316,60.6352332 Z M88.7078303,64.7943023 C89.247632,63.3589621 88.5109215,62.3097968 87.2751489,61.838185 C86.2582751,61.4935276 85.137142,61.6917017 84.2977518,62.3644764 C83.9582539,63.6186903 84.0193635,65.3718559 85.7066683,66.0143415 C86.2641923,66.2639663 86.898506,66.2750443 87.4642966,66.0450378 C88.0300872,65.8150313 88.4788684,65.3636511 88.7078303,64.7943023 Z"
              id="Shape"
              fill="#225588"
              fillRule="nonzero"
            ></path>
            <path
              d="M74.6225971,50.708597 L74.8171721,49.5628317 C75.5734478,49.9764957 76.4812177,49.9941841 77.252714,49.6102894 C79.1447879,48.7492705 79.0340814,46.4984179 78.2289436,44.6984138 C78.1758037,44.5745403 78.1164599,44.4534795 78.0511423,44.3357011 C77.8461721,45.4919488 77.113992,46.483008 76.0751999,47.0102835 C74.3978295,47.7797767 72.5560767,47.4068945 71.6570062,45.3967204 C70.9290274,43.7662082 71.5127523,41.7458646 73.5591443,40.8136591 C76.1657779,39.6238259 78.2054604,41.4712877 79.3561365,44.0475648 C80.5068127,46.6238419 80.3424304,49.471306 77.7223777,50.6577494 C76.7492962,51.145013 75.6108182,51.1636881 74.6225971,50.708597 Z M77.5915428,43.3865464 C76.9608515,42.2780692 75.6927595,41.0984055 74.0690649,41.8407801 C73.5062315,42.059949 73.0552131,42.5000703 72.8188297,43.0608082 C72.5824463,43.6215461 72.5808453,44.2551077 72.8143918,44.8170581 C73.4350189,46.203502 74.6796277,46.4204516 75.8839797,45.8712978 C76.8332624,45.389146 77.4760743,44.4537617 77.5915428,43.3865464 Z"
              id="Shape"
              fill="#225588"
              fillRule="nonzero"
            ></path>
            <polygon
              id="Path"
              fill="#225588"
              points="81 55.4154638 84.0175839 47.6800816 81.8616879 48.7015528 81.4191089 47.7318016 84.7719799 46.2222222 85.8214286 46.6068902 82.196975 55.8518519"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#225588"
              transform="translate(49.140971, 62.230235) rotate(-24.300000) translate(-49.140971, -62.230235) "
              points="47.9190569 40.6410268 50.3024113 40.6443569 50.3628858 83.8194428 47.9795314 83.8161127"
            ></polygon>
            <ellipse
              id="Oval"
              fill="#225588"
              cx="54"
              cy="73.1851852"
              rx="3.85714286"
              ry="3.85185185"
            ></ellipse>
            <ellipse
              id="Oval"
              fill="#FFFFFF"
              cx="54.4821429"
              cy="72.7037037"
              rx="1.44642857"
              ry="1.44444444"
            ></ellipse>
            <polygon
              id="Rectangle"
              fill="#6FA4D9"
              transform="translate(37.380866, 36.276065) rotate(-24.300000) translate(-37.380866, -36.276065) "
              points="37.0370973 33.9269765 37.7180557 33.9279279 37.724635 38.6251545 37.0436766 38.624203"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6FA4D9"
              transform="translate(17.319855, 89.169003) rotate(-24.290000) translate(-17.319855, -89.169003) "
              points="14.9649643 88.8260745 19.673794 88.8326518 19.6747452 89.5119322 14.9659155 89.505355"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6FA4D9"
              transform="translate(17.224850, 56.457582) rotate(-69.300000) translate(-17.224850, -56.457582) "
              points="16.882366 54.1023596 17.5615341 54.1031984 17.5673346 58.8128047 16.8881664 58.8119659"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6FA4D9"
              transform="translate(65.909893, 34.563035) rotate(-69.300000) translate(-65.909893, -34.563035) "
              points="63.5612498 34.2195992 68.2576975 34.2253996 68.2585363 34.9064707 63.5620886 34.9006703"
            ></polygon>
            <ellipse
              id="Oval"
              fill="#225588"
              cx="53.5178571"
              cy="2.40740741"
              rx="2.41071429"
              ry="2.40740741"
            ></ellipse>
          </g>
        </g>
      </g>
    </svg>
  );
};
