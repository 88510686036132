import { ApolloError } from "@apollo/client";
import { logger as _logger } from "@clockwise/client-commons/src/util/logger";

/**
 * Logs Apollo error if it is a meaningful error.
 *
 * @param options - The options for the error logger.
 * @param options.error - The Apollo error object.
 * @param options.errorContext - Additional context information for the error.
 * @param options.errorMessagePrefix - The prefix to be added to the error message.
 */
export const apolloErrorLogger = ({
  error,
  errorContext = {},
  errorMessagePrefix = "Apollo Error",
  logger = _logger,
}: {
  error: ApolloError | undefined;
  errorContext?: Record<string, unknown>;
  errorMessagePrefix?: string;
  logger?: typeof _logger;
}) => {
  if (!error) {
    // no error to log
    return;
  }

  if (error.message.toLowerCase().includes("failed to fetch")) {
    // no need to log network errors
    return;
  }

  if (error.networkError?.name === "ServerError") {
    // no need to log server errors
    return;
  }

  if (hasStatusCode(error.networkError) && error.networkError.statusCode >= 500) {
    // no need to log 500 errors
    return;
  }

  logger.error(`${errorMessagePrefix}: ${error.message}`, {
    ...errorContext,
    error,
  });
};

// Define an interface that includes the statusCode property
interface HasStatusCode {
  statusCode: number;
}
// Type guard function to check if an object has a statusCode property
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasStatusCode(obj: any): obj is HasStatusCode {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return obj && typeof obj === "object" && "statusCode" in obj;
}
