// schema
import * as ISchema from "#webapp/src/__schema__";
// libraries
import pluralize from "pluralize";
import * as React from "react";
import { connect } from "react-redux";
// org-invite-checkbox imports
import * as styles from "./OrgUserCheckbox.styles";
import { ICheckboxContainer, ICheckboxProps, ICheckboxState } from "./OrgUserCheckboxTypes";
// other internals
import { LogoClock } from "#webapp/src/components/logo";
import { InfoTip } from "#webapp/src/components/tool-tip";
import { A } from "#webapp/src/components/ui";
import { UserImage } from "#webapp/src/components/user-image";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
// material-ui
import { Checkbox } from "@clockwise/design-system";

//////////////////
// COMPONENT
//////////////////
class OrgUserCheckboxCmpt extends React.Component<ICheckboxProps, ICheckboxState> {
  constructor(props: ICheckboxProps) {
    super(props);
    const checked = !!props.checked;
    this.state = {
      checked,
    };
  }

  private renderInfoText() {
    let info = "";
    const person = this.props.person;
    if (person.numberOfOrganizedMeetings) {
      info = `Because ${person.givenName} organizes ${person.numberOfOrganizedMeetings} of your meetings`;
      if (person.numberOfSharedMeetings) {
        const meetings = pluralize("meeting", person.numberOfSharedMeetings);
        info += ` and you share ${person.numberOfSharedMeetings} other ${meetings}`;
      }
    } else if (person.numberOfSharedMeetings) {
      const meetings = pluralize("meeting", person.numberOfSharedMeetings);
      info = `Because you share ${person.numberOfSharedMeetings} ${meetings} with ${person.givenName}`;
    } else if (person.inviteReason == ISchema.SuggestedOrgInviteReason.MeetsWithYourTeammates) {
      info = `Because ${person.givenName} meets with your teammates`;
    } else {
      info = `Because you share meetings with ${person.givenName}`;
    }
    return <span style={styles.tooltip.content}>{info}</span>;
  }

  private onCheck = () => {
    const { person } = this.props;
    this.setState({ checked: !this.state.checked });
    if (this.props.onCheck) {
      this.props.onCheck(person.userId, !this.state.checked, person.isSuggested);
    }
  };

  private maybeRenderUserBadge = (isUser?: boolean, size?: number) => {
    if (!isUser) {
      return <span />;
    }

    const badgeStyle = {
      ...styles.badge,
      width: size && size > 50 ? 22 : 18,
      height: size && size > 50 ? 22 : 18,
      right: size && size > 60 ? 0 : -3,
      bottom: size && size > 60 ? 0 : -3,
    };
    const logoSize = badgeStyle.width - 4;

    return (
      <div style={badgeStyle}>
        <LogoClock size={logoSize} fill="bright" />
      </div>
    );
  };

  private maybeRenderAction = () => {
    const tipTarget = <A style={styles.suggested}>Suggested</A>;
    const { person, viewOnly } = this.props;

    // if (person.isUser) {
    //   return (
    //     <div style={{ ...styles.row, paddingRight: 18 }}>
    //       Joined!
    //     </div>
    //   );
    // }

    if (viewOnly) {
      return "";
    }

    return (
      <div style={styles.row} className="cw-gap-3">
        {person.isSuggested ? (
          <InfoTip text={this.renderInfoText()} targetElement={tipTarget} tipType={"info"} />
        ) : (
          ""
        )}
        <Checkbox
          disabled={person.isPending}
          checked={this.state.checked || person.isPending}
          onChange={this.onCheck}
        />
      </div>
    );
  };

  public render() {
    const { person } = this.props;

    return (
      <div>
        <div style={styles.item}>
          <div style={styles.row}>
            <div style={styles.row}>
              <div style={{ position: "relative" }}>
                <UserImage
                  imgUrl={person.externalImageUrl}
                  name={person.givenName}
                  style={{ width: 34, height: 34 }}
                />
                {this.maybeRenderUserBadge(person.isUser, 34)}
              </div>
              <div style={{ marginLeft: 10 }}>
                <div style={styles.name}>{`${person.givenName} ${person.familyName}`}</div>
                <div style={styles.email}>{person.targetCalendarId}</div>
              </div>
            </div>
            {this.maybeRenderAction()}
          </div>
        </div>
      </div>
    );
  }
}

//////////////////
// REDUX
//////////////////
function mapStateToProps(_state: IReduxState, _ownProps: ICheckboxContainer) {
  return {}; // add redux state here, if needed
}

export const OrgUserCheckbox = connect(mapStateToProps, {})(OrgUserCheckboxCmpt);
