import { graphql } from "react-relay";

export const checkoutFormRendererQuery = graphql`
  query CheckoutFormRendererQuery($orgRelayId: ID!) {
    viewer: viewer {
      ...MixedCheckoutForm_viewer
    }
    org: node(id: $orgRelayId) {
      ... on Org {
        ...MixedCheckoutForm_org
        id
        experiments
        userTeams {
          __typename
          ... on TeamList {
            list {
              teamId
              ...MixedCheckoutForm_team
            }
          }
          ... on GraphEntityError {
            statusCode
            message
          }
        }
      }
    }
  }
`;
