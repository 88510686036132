export function toGlobalId(type: string, id: string) {
  return btoa([type, id].join(":"));
}

export function fromGlobalId(globalId: string) {
  const unbasedGlobalId = atob(globalId);
  const delimiterPos = unbasedGlobalId.indexOf(":");

  return {
    type: unbasedGlobalId.substring(0, delimiterPos),
    id: unbasedGlobalId.substring(delimiterPos + 1),
  };
}

export function toGlobalIdForCalendarWeek(id: string) {
  return toGlobalId("CalendarWeek", id);
}

export function toGlobalIdForEventParent(id: string) {
  return toGlobalId("EventParent", id);
}

export function toGlobalIdForTeam(id: string) {
  return toGlobalId("Team", id);
}
