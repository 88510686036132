import { jwt, xsrf } from "#webapp/src/state/local-storage";

export const networkConfig = {
  headers: {
    get authorization() {
      const jwtToken = jwt.get();
      return jwtToken ? `Bearer ${jwtToken}` : "";
    },
    get xsrf() {
      const xsrfToken = xsrf.get();
      return xsrfToken ? xsrfToken : "";
    },
  },
  retryDelays: [30000],
};
