import { getApiUrl } from "@clockwise/client-commons/src/config/api";
import { ZonedMoment } from "@clockwise/client-commons/src/util/ZonedMoment";
import { isImpersonated } from "@clockwise/client-commons/src/util/jwt";
import { logger } from "@clockwise/client-commons/src/util/logger";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { getAuthedRequestInitForFetch } from "./fetch.util";
import { analyticsPre, analyticsUser, jwt } from "./local-storage";
import { getPreCookie } from "./pre-cookie.util";

export const shouldTrack = () => {
  return !isImpersonated(jwt.get() ?? "");
};

export const PageEvents = {
  ACCOUNT_SETTINGS_PAGE: "Account Settings Page",
  ACCOUNT_PREFERENCES_PAGE: "Account Preferences Page",
  ADMIN_PANEL: "Admin Panel",
  AUTOPILOT_LIST: "Autopilot List",
  ANALYTICS_PAGE: "Analytics Page",
  AI_PAGE: "AI Page",
  CAL: "Cal",
  CALENDAR_INSIGHTS_LOGIN: "Calendar Insights Login",
  CALENDAR_INSIGHTS_RESULTS: "Calendar Insights Results",
  CALENDAR_INSIGHTS: "Calendar Insights",
  CHAT: "Chat",
  CHAT_PLUS_CALENDAR: "Chat+Calendar",
  CHECKOUT_DIALOG: "Checkout Dialog",
  CHECKOUT: "Checkout",
  CHROME_DAY_VIEW: "Chrome Day View",
  CHROME_EXTENSION: "Chrome Extension",
  CHROME_LOGIN: "Chrome Extension Login",
  CHROME_SLACK_VERIFIER: "Chrome Slack Verifier",
  CONNECTED_APPS_SETTINGS: "Connected Apps Settings Page",
  EVENT_DRAWER: "Event Drawer",
  FLEXIBLE_MEETINGS_PAGE: "Flexible Meetings Page",
  FOCUS_TIME_PAGE: "Focus Time Page",
  GUEST: "Guest Page",
  HOME_ABOUT: "HomeAbout",
  HOME_CAREERS: "HomeCareers",
  HOME_COMMUNITY_GUIDELINES: "HomeCommunityGuidelines",
  HOME_COPYRIGHT: "HomeCopyrightDispute",
  HOME_PRIVACY: "HomePrivacy",
  HOME_PRODUCT: "HomeProduct",
  HOME_SECURITY: "HomeSecurity",
  HOME_SLACK: "HomeSlack",
  HOME_SMS: "HomeSms",
  HOME_TERMS: "HomeTermsOfService",
  HOME_UNINSTALL: "HomeUninstall",
  HOME: "Home",
  INVITE_DIALOG: "Invite Dialog",
  LINKS_ONBOARDING_LOGIN: "Links Onboarding Login",
  LOGIN: "Login",
  ONE_ON_ONES_AUTOPILOT: "One On Ones Page",
  ORGANIZATION_MEMBERS_PAGE: "Organization Members Page",
  ORGANIZATION_SETTINGS_PAGE: "Organization Settings Page",
  PAYMENT_CONFIRMATION: "Payment Confirmation",
  PLANNER: "Planner",
  PLANS_AND_BILLING_PAGE: "Plans And Billing Page",
  PRICING_DIALOG: "Pricing Dialog",
  PRICING: "Pricing",
  SCHEDULING_LINKS_PAGE: "Scheduling Events Page",
  SETTINGS: "Settings",
  SHARED_PROPOSAL: "Shared Proposal page",
  SIGNUP: "Signup",
  SMART_SCHEDULE: "Smart Schedule",
  SUDO: "Sudo",
  TEAM_ANALYTICS_DIALOG: "Team Analytics Dialog",
  TEAM_HEALTH_DASHBOARD: "Team Health Dashboard",
  TEAM_HEALTH_PROFILE: "Team Health Profile",
  TEAM_MEMBERSHIP_PAGE: "Team Membership Page",
  TEAM_SETTINGS_PAGE: "Team Settings Page",
  TEAMS_SETTINGS_PAGE: "Teams Settings Page",
  WEB_SETTINGS_CREATE_JOIN_TEAM: "Web Settings - Create or Join team",
  WEB_SETTINGS_EMAILS_AND_NOTIFICATIONS: "Web Settings - Emails and notifications",
  WEB_SETTINGS_EVENT_COLOR_CODING: "Web Settings - Event color coding",
  WEB_SETTINGS_EXPERIMENTS: "Web Settings - Experiments",
  WEB_SETTINGS_HOURS_AND_PREFERENCES: "Web Settings - Hours and preferences",
  WEB_SETTINGS_INTEGRATIONS: "Web Settings - Integrations",
  WEB_SETTINGS_ORGANIZATION_ANALYTICS: "Web Settings - Organization Analytics",
  WEB_SETTINGS_ORGANIZATION_MEMBERS: "Web Settings - Organization Members",
  WEB_SETTINGS_ORGANIZATION_PLANS_AND_BILLING: "Web Settings - Organization Plans and billing",
  WEB_SETTINGS_ORGANIZATION_SETTINGS: "Web Settings - Organization Settings",
  WEB_SETTINGS_SMART_HOLDS: "Web Settings - Smart Holds",
  WEB_SETTINGS_TEAM_AVAILABILITY_CALENDAR: "Web Settings - Team availability calendar",
  WEB_SETTINGS_TEAM_NO_MEETING_DAY: "Web Settings - Team no-meeting day",
  WEB_SETTINGS_TEAM: "Web Settings - Team",
  WEB_SETTINGS: "Web Settings",
  WELCOME: "Welcome",
  M365: "M365",
};

export const TrackingEvents = {
  FUNNELED_TO_DOWNLOAD_EXTENSION: {
    ONBOARDING_DIALOG_BOX:
      "Funneled To Download Extension - On click 'Add to <browser>' on Onboarding Dialog Box",
    CONNECT_APPS:
      "Funneled To Download Extension - On click 'Download Extension' button on Connected Apps",
    CW_WEB_HOME:
      "Funneled To Download Extension - On click 'Download Extension' button on Clockwise Web Home",
  },
  ACTIVE: {
    DAU: "Daily Active User",
  },
  AGENDA: {
    CLICKED_GO_TO_CHROME_STORE: "Agenda Canvas View: clicked to go to chrome store",
    DISMISSED_GO_TO_CHROME_STORE: "Agenda Canvas View: dismissed go to chrome store callout",
    CLICKED_GO_TO_THREAD_INFO: "Agenda Event View: clicked to go to thread info view",
    CHECKED_SEND_UPDATES_TO_GUESTS: "Agenda Event View: checked send updates to guest box",
    CLICKED_UPDATE_EVENT_DETAILS: "Agenda: clicked update event details",
    CLICKED_COPY_AGENDA_URL: "Agenda: clicked copy agenda url",
    OPENED_CANVAS_VIEW: "Agenda Event View: clicked clicked to open agenda canvas view",
    EXPANDED_AGENDA_SECTION: "Agenda: expanded agenda section",
    TOGGLE_AGENDA_SECTION_VISIBILITY: "Agenda: toggle agenda section visibility",
    CLICKED_HELP_CENTER: "Agenda Canvas View: clicked to go to help center",
    CHANGED_CANVAS_VIEW_SIDEBAR_TAB: "Agenda Canvas View: clicked to change agenda sidebar tab",
    CLICKED_DAY_EVENT: "Agenda Canvas View: clicked day event",
  },
  CHAT: {
    JOIN_WAITLIST_BANNER: {
      CLICKED: "Chat.Join.Waitlist.Banner.Clicked",
      DISMISSED: "Chat.Join.Waitlist.Banner.Dismissed",
    },

    TRY_AI_BANNER: {
      CLICKED: "Chat.TryAI.Banner.Clicked",
      DISMISSED: "Chat.TryAI.Waitlist.Banner.Dismissed",
    },
    NUX: {
      CHAT_RECOMMENDATION_CLICKED: "Chat.Nux.Chat.Recommendation.Clicked",
      SIDEBAR_SCHEDULE_MEETING_CHECKLIST_CLICKED: "Sidebar.Schedule.Meeting.Checklist.Clicked",
      SIDEBAR_COLLABORATOR_QUICK_CREATE_CLICKED: "Sidebar.Collaborator.Quickcreate.Clicked",
      SIDEBAR_MULTI_COLLABORATOR_QUICK_CREATE_CLICKED:
        "Sidebar.Multi.Collaborator.Quickcreate.Clicked",
      SIDEBAR_COLLABORATOR_VIEW_CALENDAR_CLICKED: "Sidebar.Collaborator.ViewCalendar.Clicked",
      SIDEBAR_NEXTMEETING_JOIN_CLICKED: "Sidebar.NextMeeting.Join.Clicked",
      SIDEBAR_NEXTMEETING_RESCHEDULE_CLICKED: "Sidebar.NextMeeting.Reschedule.Clicked",
    },
    ODD: {
      ODD_REQUEST_CLICKED: "Odd.Request.Clicked",
      ODD_SUGGESTIONS_RECEIVED: "Odd.Suggestions.Received",
      ODD_DIFF_REMOVED: "Odd.Diff.Removed",
      ODD_ACCEPTED: "Odd.Accepted",
      ODD_CANCELED: "Odd.Canceled",
    },
    CHAT_RECOMMENDATION_ABOVE_INPUT_CLICKED: "Chat.Recommendation.Above.Input.Clicked",
    BEGIN_PROCESS_CHAT_MESSAGE: "Begin.Process.Chat.Message",
    CHAT_MESSAGE_PROCESSING_FAILURE: "Chat.Message.Processing.Failed",
    CHAT_MESSAGE_PROCESSING_SUCCESS: "Chat.Message.Processing.Success",
    EVENT_SEARCH: {
      REPROCESSING_SUCCESS: "AI.EventSearch.Reprocessing.Success",
      REPROCESSING_FAILURE: "AI.EventSearch.Reprocessing.Failed",
      CHIP_EDITED: "AI.EventSearch.ChipEdited",
      CHIP_REMOVED: "AI.EventSearch.ChipRemoved",
    },

    RESET_CONVERSATION: "Reset.Conversation",
    ERROR: "Chat.Error",

    PROPOSAL: {
      CANCEL: "Proposal.Cancel",
      CONFIRM: "Proposal.Confirm",
      FEEDBACK_POSITIVE: "Proposal.Feedback.Positive",
      FEEDBACK_NEGATIVE: "Proposal.Feedback.Negative",
      SELECT_OPTION_CLICKED: "Proposal.ChangeOption",
      SHARE_TIMES_VIEWED: "Proposal.ShareTimes.Viewed",
      SHARE_TIMES: "Proposal.ShareTimes",
      COPY_AVAILABILITY_SUMMARY: "Proposal.CopyAvailabilitySummary",
      COPY_SCHEDULING_LINK: {
        LINK: "Proposal.CopySchedulingLink",
        TIMES: "Proposal.CopySchedulingLink.Times",
      },
    },
    LINKS_DROPDOWN: {
      COPY_LINK: "Chat.LinksDropdown.CopyLink.Clicked",
      SHARE_TIMES: "Chat.LinksDropdown.ShareTimes.Clicked",
      EDIT_LINK: "Chat.LinksDropdown.EditLink.Clicked",
      NEW_LINK: "Chat.LinksDropdown.NewLink.Clicked",
    },
    SCHEDULING_LINK: {
      VIEWED: "Chat.SchedulingLink.Viewed",
      COPY_LINK: "Chat.SchedulingLink.CopyLink",
      EDIT_LINK: "Chat.SchedulingLink.EditLink",
      SHARE_TIMES: "Chat.SchedulingLink.ShareTimes",
      SHARE_TIMES_MODAL: {
        COPY_LINK: "Chat.SchedulingLink.ShareTimesModal.CopyLink",
        COPY_TIMES: "Chat.SchedulingLink.ShareTimesModal.CopyTimes",
      },
    },

    UPDATE_ADD_EVENT_DIFF: {
      TIME: "Update.Add.Event.Diff.Time",
      REJECT: "Update.Add.Event.Diff.Reject",
    },
    UPDATE_EDIT_EVENT_DIFF: {
      TIME: "Update.Edit.Event.Diff.Time",
      CANCEL: "Update.Edit.Event.Diff.Cancel",
      REJECT: "Update.Edit.Event.Diff.Reject",
    },
    CANCEL: {
      CANCEL_PROCESS_MESSAGE: "AI.Cancel.ProcessMessage",
      CANCEL_REPROCESS_MESSAGE: "AI.Cancel.ReprocessMessage",
      PROCESS_MESSAGE_ERROR: "AI.ProcessMessage.Error.UserCanceledRequest",
      REPROCESS_MESSAGE_ERROR: "AI.RerocessMessage.Error.UserCanceledRequest",
    },
    SIDEBAR_COLLAPSED: "Chat.Sidebar.Collapsed",
    SIDEBAR_EXPANDED: "Chat.Sidebar.Expanded",
  },
  AUTH: {
    BEGIN_LOGIN: "Begin User Log In",
    LOGIN: "User Authenticated",
    LOGIN_FAILED: "User Log in Failed",
    BEGIN_LOGOUT: "Begin User Log Out",
    LOGOUT: "User Logged Out",
    USER_CREATED: "User Created",
    USER_SIGNED_UP: "User Signed Up",
    USER_DEACTIVATED: "User Deactivated",
    POPPED_OPEN_CANT_CONNECT: "Popped Open Cant Connect",
    M365_WAITLIST_CLICKED: "Login - M365 Waitlist Link Clicked",
  },
  BROWSER_NOTIFICATION: {
    BROWSER_NOTIFICATION_SHOW: "Browser Notification - Notification shown",
    BROWSER_NOTIFICATION_CLICKED: "Browser Notification - Notification clicked",
  },
  Q4_2022_SIGNUP_EXPERIMENT: {
    SIGNUP_EXPERIMENT_WELCOME_VISITED: "Signup Experiment - Test - Welcome visited",
    SIGNUP_EXPERIMENT_WELCOME_LOGIN: "Signup Experiment - Test - Log in complete - Welcome page",
    SIGNUP_EXPERIMENT_EXTENSION_REDIRECT: "Signup Experiment - Control - Extension redirect ",
    SIGNUP_EXPERIMENT_SIGNUP_REDIRECT: "Signup Experiment - Control - Signup redirect",
    SIGNUP_EXPERIMENT_EXTENSION_REDIRECT_LOGIN:
      "Signup Experiment - Control - Log in complete - Extension store",
  },
  AUTOPILOT_SUGGESTIONS: {
    DISMISS_AUTOPILOT_SUGGESTIONS_CARD: "Autopilot Suggestions Dismiss Card",
    AUTOPILOT_SUGGESTIONS_NAV_PREVIOUS_CLICKED: "Autopilot Suggestions Nav Previous Button Clicked",
    AUTOPILOT_SUGGESTIONS_NAV_NEXT_CLICKED: "Autopilot Suggestions Nav Previous Next Clicked",
    AUTOPILOT_SUGGESTIONS_NAV_DOT_INDICATOR_CLICKED:
      "Autopilot Suggestions Nav Dot Indicator Clicked",
    AUTOPILOT_SUGGESTION_INVITE_CLICKED: "Autopilot Suggestion Invite Clicked",
    AUTOPILOT_SUGGESTION_ENABLE_MEETING_CLICKED:
      "Autopilot Suggestion Enable Autopilot Meeting Clicked",
    AUTOPILOT_SUGGESTION_LESSEN_CONSTRAINT_CLICKED:
      "Autopilot Suggestion Lessen Constraint Clicked",
  },
  BANNERS: {
    CLICK_NEW_WEB: "Banner Click: new web promo banner",
    DISMISS_NEW_WEB: "Banner Dismiss: new web promo banner",
    BROWSER_NOTIFICATION_PERMISSION_BANNER_SHOWN: "Browser Notification Permission Banner - Shown",
    BROWSER_NOTIFICATION_PERMISSION_BANNER_SET:
      "Browser Notification Permission Banner - Permission set",
  },
  FLEX_SETTINGS: {
    CHANGE_FLEXIBLE_SETTINGS: "Change Flexible Settings: Flex Page",
  },
  CHROME_EXTENSION: {
    CLOSED: "Chrome Extension Closed",
    INSTALL_FAILED: "Install Failed",
    INSTALL_STARTED: "Install Extension Started",
    INSTALLED: "Install Extension",
    OPEN_MANUAL: "Chrome Extension Open Manual",
    OPEN: "Chrome Extension Open",
    UNINSTALL: "Uninstall Extension",
    POLL_IS_OPEN: "Poll - Chrome Extension Open",
    POLL_IS_CLOSED: "Poll - Chrome Extension Closed",
    REVIEW_START: "Chrome Web Store Start Review",
    REVIEW_DECLINE: "Chrome Web Store Decline Review",
    INLINE_AUTOPILOT_TOGGLE: "Chrome Extension: Toggle Autopilot",
    INLINE_AUTOPILOT_CHANGE_TIME_FLEX: "Chrome Extension: Change Timing Flexibility",
    INLINE_AUTOPILOT_CHANGE_ROOM_FLEX: "Chrome Extension: Change Room Flexibility",
    INLINE_AUTOPILOT_EXPAND_SETTINGS: "Chrome Extension: Toggle Expandable Settings",
    INLINE_AUTOPILOT_CHANGE_TIME_FLEXIBILITY: "Inline Autopilot Change Time Flexibility",
    INSTALL_REDIRECT_WELCOME: "Chrome Extension - Installed - Welcome Page Loaded",
    EXTENSION_INSTALL_GCAL_REDIRECT: "Chrome Extension - Installed - Calendar Redirect",
    EXTENSION_INSTALL_WEBAPP_REDIRECT: "Chrome Extension - Installed - Webapp Redirect ",
    SIDECAR: {
      CHANGE_CATEGORY: "Chrome Extension: Sidecar Change Category",
      CHANGE_FLEXIBLE_SETTINGS: "Chrome Extension: Sidecar Change Flexible Settings",
      DRAG_EVENT: "Chrome Extension: Sidecar Drag Event",
      EDIT_SMART_HOLD_SETTINGS: "Chrome Extension: Sidecar Edit Smart Hold Settings",
      EDIT_SMART_HOLD_STATE: "Chrome Extension: Sidecar Edit Smart Hold State",
      OPEN_IN_NEW: "Chrome Extension: Sidecar Open In New",
      RESCHEDULE_CONFIRM: "Chrome Extension: Sidecar Reschedule Confirm",
      RESCHEDULE_FIND_MORE_TIMES: "Chrome Extension: Sidecar Reschedule Find More Times",
      RESCHEDULE_SELECT: "Chrome Extension: Sidecar Reschedule Select",
      SHARE_FEEDBACK: "Chrome Extension: Sidecar Share Feedback",
      TOGGLE: "Chrome Extension: Sidecar Toggle",
    },
  },
  COLOR_CATEGORIES: {
    DROPDOWN_OPENED: "Color Category Dropdown Opened",
    SETTINGS_DISABLED: "Color Category Disabled from Settings",
    SETTINGS_ENABLED: "Color Category Enabled from Settings",
  },
  DAY_VIEW: {
    CLICK_EVENT: "Clicked Day View Event",
    PICK_NEXT_DAY: "Picked Next Day Using Next Day Chevron",
    PICK_PREV_DAY: "Picked Previous Day Using Previous Day Chevron",
    PICK_DATE: "Picked Date From Date Picker",
    PICK_TODAY: "Picked Today Using Today Button",
  },
  DEFRAG: {
    ANNOTATE_ON_CREATE: "Annotate on Create",
    CLICK_AUTOPILOT_FEED_STATUS: "Click Autopilot Feed Status",
    CHANGE_WEEK: "Change Week",
    ONBOARDING_ENABLE_DEFRAG_SETTING: "Onboarding Enable Defrag Setting",
    SEEN_ANNOTATION_SUGGESTION: "Seen Annotation Suggestion",
    SEND_FEEDBACK: "Send Daily Defrag Feedback",
    SEND_SURVEY_RESPONSE: "Send Defrag Survey Response",
    SEND_INVITE: "Send Invite",
    SKIP_ALL_ANNOTATION_SUGGESTION: "Skip All Annotation Suggestions",
    SKIP_ANNOTATION_SUGGESTION: "Skip Annotation Suggestion",
    UNPAUSE_AUTOPILOT: "Unpause Autopilot",
    TOGGLE_DEFRAG_SETTING: "Toggle Defrag Setting",
    TOGGLE_PREVIEW: "Toggle Defrag Preview",
    TOGGLE_SMART_HOLDS: "Toggle Smart Holds",
  },
  EMAIL_UNSUBSCRIBE: {
    GO_TO_PRODUCT_NOTIFICATIONS: "Unsubscribe email link - Change other product notifications",
    GO_TO_MARKETING_NOTIFICATIONS: "Unsubscribe email link - Change other marketing notifications",
  },
  EVENT: {
    UPDATED: "Event Updated",
    REMOVED: "Event Removed",
    DELETED: "Event Deleted",
    COPY_FOCUS_TIME_EVENT: "Copy Focus Time Event",
    UNSYNC_FOCUS_TIME_EVENT: "Unsync Focus Time Event",
    INVITE_NON_USER_ORGANIZER: "Invite Button Tapped For Non User Organizer of Event",
    INVITE_NON_USER_ORGANIZER_FLEX_COMP:
      "Invite Button Tapped For Non User in Flexibility Component",
    MODIFY_IGNORE: "Modify Ignore Tag of Event",
    REMOVE_SYNCED_PERSONAL_CALENDAR_EVENT: "Remove Synced Personal Calendar Event",
    REMOVE_LUNCH_EVENT: "Remove Lunch Event",
    REMOVE_TRAVEL_TIME_EVENT: "Remove Travel Time Event",
    EDIT_TRAVEL_TIME_EVENT: "Edit Duration of Travel Time Event",
    GOTO_SMART_HOLD_SETTINGS: "Go To Smart Hold Settings",
    TEAM_SECTION_SHOWN: "Team - Shown Team Section on Event",
    TEAM_MANAGE_SETTINGS: "Team - Clicked Manage Settings Link in Event",
    TEAM_TOGGLE_SYNC: "Team - Toggle Sync on Origin Event",
    TEAM_REMOVE_SYNC: "Team - Remove Sync via Sync Event",
    TEAM_GOTO_ORIGIN_EVENT: "Team - Go To Origin Event from Sync Event",
    TEAM_TIP_LEARN_MORE: "Team Tip - Click Learn More",
    TEAM_TIP_DISMISSED: "Team Tip - Dismissed",
    SCHEDULING: {
      CONFLICT_NOTIFICATION_DISPLAYED: "Sidebar.ConflictNotifiction.Displayed",
      CONFLICT_NOTIFICATION_CLICKED: "Sidebar.ConflictNotifiction.Clicked",
      RESCHEDULE_BUTTON_DISPLAYED: "Sidebar.RescheduleButton.Displayed",
      RESCHEDULE_SUGGESTION_CLICKED: "Sidebar.RescheduleSuggestion.Clicked",
      RESCHEDULE_SUGGESTION_CONFIRMED: "Sidebar.RescheduleSuggestion.Confirmed",
      UPDATE_RESPONSE_STATUS: "Update Response Status",
    },
  },
  EVENT_SCHEDULER: {
    SCHEDULE_BY_MOVING: {
      USER_CLICKED_FIX_IT_NOW: "Event Scheduler - Schedule by moving - User Clicked Fix It Now",
      USER_CLICKED_LEAVE_THE_CONFLICT:
        "Event Scheduler - Schedule by moving - User Clicked Leave The Conflict",
      USER_SELECTED_MAKE_IT_WORK_SLOT:
        "Event Scheduler - Schedule by moving - User selected Make It Work Slot",
      USER_SELECTED_A_NON_MAKE_IT_WORK_SLOT:
        "Event Scheduler - Schedule by moving - User selected a Non Make It Work Slot",
    },
  },
  EVENT_POPOVER: {
    EVENT_DETAILS_OPENED: "Popover.EventDetails.Opened",
    RESCHEDULE_CLICKED: "Popover.Reschedule.Clicked",
    PROPOSE_NEW_TIME_CLICKED: "Popover.ProposeNewTime.Clicked",
    RSVP_SELECTED: "Popover.RSVP.Selected",
    MEETING_JOINED: "Popover.Meeting.Joined",
    SMART_HOLD_UNPAUSED: "Popover.Status.Unpaused",
    SMART_HOLD_PAUSED: "Popover.Status.Paused",
    SMART_HOLD_PROTECTED: "Popover.Status.Protected",
    CHANGE_CATEGORY: "Popover.ChangeCategory",
    CHANGE_FLEXIBLE_SETTINGS: "Popover.ChangeFlexibleSettings",
    OPEN_IN_NEW: "Popover.OpenInNew",
    RESCHEDULE_CONFIRM: "Popover.RescheduleConfirm",
    RESCHEDULE_FIND_MORE_TIMES: "Popover.RescheduleFindMoreTimes",
    RESCHEDULE_SELECT: "Popover.RescheduleSelect",
    TOGGLE: "Popover.Toggle",
  },
  CONFLICT_RESOLUTION: {
    RECEIVED_CONFLICTS: "Conflict.Received",
    DISMISSED_CONFLICT: "Conflict.Dismissed",
    RSVP_MAYBE: "Conflict.Event.RSVP.Maybe",
    RSVP_DECLINED: "Conflict.Event.RSVP.Declined",
    DELETE_EVENT: "Conflict.Event.Deleted",
    RESCHEDULE_WITH_CHAT: "Conflict.Event.Reschedule.WithChat",
    SUGGESTION_RESCHEDULE: "Conflict.Event.Reschedule.FromSuggestions",
    FINISH_RESOLUTION: "Conflict.Finish.Resolution",
  },
  FLEXIBLE_MEETING: {
    ADJUST_DATE_RANGE: {
      AUTO_EXPAND: "FlexibleMeeting.AdjustDateRange.AutoExpand",
      CHANGE: "FlexibleMeeting.AdjustDateRange.Change",
      EXPAND: "FlexibleMeeting.AdjustDateRange.Expand",
      JOIN_PLAN_CLICKED: "FlexibleMeeting.AdjustDateRange.JoinPlanClicked",
    },
    ADJUST_TIME_OF_DAY: {
      EXPAND: "FlexibleMeeting.AdjustTimeOfDay.Expand",
      CHANGE: "FlexibleMeeting.AdjustTimeOfDay.Change",
      JOIN_PLAN_CLICKED: "FlexibleMeeting.AdjustTimeOfDay.JoinPlanClicked",
    },
    SET_FLEXIBLE_ON_CREATE: "FlexibleMeeting.SetFlexibleOnCreate",
    UPDATE_FLEXIBILITY_MASS: "FlexibleMeeting.UpdateFlexiblityMass",
    UPDATE_FLEXIBILITY: "FlexibleMeeting.UpdateFlexiblity",
    NUM_SUGGESTIONS: "FlexibleMeeting.NumSuggestions",
  },
  FLEXIBLE_HOLD: {
    UPDATE_FLEXIBILITY: "FlexibleHold.updateFlexibility",
  },
  SMART_HOLD: {
    STATE_UPDATED: "SmartHold.Event.StateUpdated",
    UNSYNCED: "SmartHold.Event.Unsynced",
  },
  HELP: {
    AUTOPILOT: "Learn More Autopilot",
    CLOCKWISE_CALENDAR: "Learn More Clockwise Calendar",
    EVENT_COLORING: "Learn More Event Coloring",
    PERSONAL_CALENDAR_SYNC: "Learn More Personal Calendar Sync",
    SLACK: "Learn More Slack",
    FOCUS_TIME: "Learn More Focus Time",
    FOCUS_TIME_SYNC: "Learn More Focus Time Sync",
    LUNCH_SYNC: "Learn More Lunch Sync",
    TRAVEL_TIME: "Learn More Travel Time",
    WHAT_MEETINGS_SHOULD_MARK_FLEX: "Learn More What Meetings Should Mark as Flex",
    ZOOM: "Learn More Zoom",
  },
  ONE_ON_ONES_AUTOPILOT: {
    ENABLE_AUTOPILOT_FOR_ONE_ON_ONE: "Autopilot enabled for one-on-one from one-on-ones page",
    DISABLE_AUTOPILOT_FOR_ONE_ON_ONE: "Autopilot disabled for one-on-one from one-on-ones page",
    ENABLE_AUTOPILOT_FOR_ALL: "Autopilot enabled for all one-on-ones from one-on-ones page",
    DISABLE_AUTOPILOT_FOR_ALL: "Autopilot not enabled for all one-on-ones from one-on-ones page",
    NOT_MEMBER_EXPERIMENT: "A user not part of the experiment navigated to the one-on-one page",
  },
  WEB_ONBOARDING: {
    STARTING_STEP: "Web Onboarding - Starting Step",
    CHROME_FIREFOX: "Web Onboarding - Chrome or Firefox Browser",
    ONBOARDING_STEP: "Stepping Through Web Onboarding",
    FINSIHED: "Web Onboarding - Finished",
    IS_MOBILE: "Web Onboarding - Mobile Device Detected",
    FLEX_MEETING_SUGGESTIONS: "Web Onboarding - Flex Meeting - Suggestions",
    FLEX_MEETING_NO_SUGGESTIONS: "Web Onboarding - Flex Meeting - No Suggestions - Slide Skipped",
    FLEX_MEETING_ENABLED: "Web Onboarding - Flex Meeting - Enabled",
    FLEX_MEETING_SKIPPED: "Web Onboarding - Flex Meeting - Skipped",
    INVITE_SENT: "Web Onboarding - Invite - Invites Sent",
    INVITE_SENT_ON_COMPLETE: "Web Onboarding - Invite - Invites Sent Successfully",
    INVITE_SKIPPED: "Web Onboarding - Invite - Skipped",
    DOWNLOAD_EXTENSION_SKIPPED: "Web Onboarding - Download Extension - Skipped",
    DOWNLOAD_EXTENSION_VIEWED: "Web Onboarding - Download Extension - Viewed",
    CHROME_EXTENSION: "Web Onboarding - Chrome Extension - Download Clicked",
    FIREFOX_EXTENSION: "Web Onboarding - Firefox Extension - Download Clicked",
    DOWNLOAD_CONFIRMATION_EXTENSION:
      "Web Onboarding - Extension Install Confirmation - Slack App Clicked",
    DOWNLOAD_CONFIRMATION_SLACK: "Web Onboarding - Slack Confirmation - Chrome Extension Clicked",
    CAROUSEL_SLIDE_SKIPPED: "Web Onboarding - Carousel - Skipped",
    CAROUSEL_SLIDE_NEXT: "Web Onboarding - Coursel - Next Clicked",
    CAROUSEL_SLIDE_BACK: "Web Onboarding - Coursel - Back Clicked",
    CAROUSEL_SLIDE_GO_TO: "Web Onboarding - Carousel - Go To Slide",
    ERROR: "Web Onboarding - Error",
    FAST_CRAWL_SKIP: "Web Onboarding - Slide Skipped - Fast crawl not complete",
    FAST_CRAWL_LOADING: "Web Onboarding - Fast Crawl - Loading",
    SLACK_STEP_CLICK_INSTALL: "Web Onboarding - Slack - Install Slack Clicked",
    SLACK_STEP_SKIPPED: "Web Onboarding - Slack - Skipped",
    SLACK_STEP_VIEWED: "Web Onboarding - Slack - Viewed",
    EXTENSION_ALREADY_INSTALLED: "Web Onboarding - Extension Already Installed",
    FOCUSTIME_STEP_ENABLED: "Web Onboarding - Focus time - Enabled",
    LUNCH_STEP_SKIPPED: "Web Onboarding - Lunch - Skipped",
    LUNCH_STEP_ENABLED: "Web Onboarding - Lunch - Enabled",
    LINKS_STEP_VIEWED: "Web Onboarding - Links - Viewed",
    LINKS_STEP_TRIGGERED_TO_SHOW: "Web Onboarding - Links - Triggered Links Slide to show",
    AI_SCHEDULER_STEP_VIEWED: "Web Onboarding - AI Scheduler - Viewed",
    SCHEDULE_STEP_VIEWED: "Web Onboarding - Schedule - Viewed",
    SBM_STEP_VIEWED: "Web Onboarding - SBM - Viewed",
    CHAT_STEP_VIEWED: "Web Onboarding - Chat - Viewed",
    DEFRAG_STEP_VIEWED: "Web Onboarding - Defrag - Viewed",
  },
  INVITE_SEARCH: {
    INVITE_SUGGESTIONS_SEEN: "Invite Search - Default Suggestions Seen",
    INVITE_BULK_SUGGESTIONS_SEEN: "Invite Search - Invite - Bulk Invite Suggestions Seen",
    SELECT_ALL_CLICKED: "Invite Search - Select All Suggested Teammates Clicked",
  },
  NUX_CHECKLIST: {
    STEP_FINISHED: "NUX Checklist - Step Finished",
    STEP_SKIPPED: "NUX Checklist - Step Skipped",
    NO_FLEX_MEETINGS: "NUX Checklist - No Flex Meetings Suggested - Flex Meetings Finished",
  },
  ONBOARDING: {
    ONBOARDING_DISMISS: "Onboarding Dismissed",
    ONBOARDING_CONTINUE: "Continue Onboarding via Finish Setup Button",
    ONBOARDING_UNSTARTED_STEP: "Onboarding Unstarted Step",
    ONBOARDING_STEP: "Stepping Through Onboarding",
    ONBOARDING_SKIP_STEP: "Skipping Step Automatically Through Onboarding",
    ONBOARDING_NAV_ITEM_CLICKED: "Onboarding Nav Item Clicked",
    ONBOARDING_CHROME_FEED_STEP: "Stepping Through Onboarding in Chrome Feed",
    ONBOARDING_CHROME_CLICKED_TRY_SHADOW_CAL:
      "Clicked to try the Shadow Calendar in Chrome Feed Onboarding",
    ONBOARDING_ERROR_GOOGLE_CONTACT_SHARING_DISABLED:
      "Onboarding error - GoogleContactSharingDisabled",
    ONBOARDING_ERROR_GOOGLE_API_ACCESS_DISABLED: "Onboarding error - GoogleApiAccessDisabled",
    ONBOARDING_ERROR_NO_CRAWL: "Onboarding error - Crawl was not started for some reason",
    ONBOARDING_ERROR_SHOULD_CRAWL:
      "Onboarding error - Crawl is ready to be started after prior error",
    ONBOARDING_ERROR_GENERIC: "Onboarding error - Generic crawl error",
    ONBOARDING_AUTOPILOT_EVENT_TOGGLED: "Onboarding Autopilot List Event Toggled",
    ONBOARDING_AUTOPILOT_ENABLE_AUTOPILOT_EVENTS:
      "Onboarding Autopilot List Enable Autopilot Events",
    ONBOARDING_AUTOPILOT_DISABLED_AUTOPILOT_EVENT_ID:
      "Onboarding Autopilot List Disabled Autopilot External Event Id",
    ONBOARDING_AUTOPILOT_ENABLED_AUTOPILOT_EVENT_ID:
      "Onboarding Autopilot List Enabled Autopilot External Event Id",
    ONBOARDING_AUTOPILOT_NOT_NOW_CLICKED: "Onboarding Autopilot List Not Now Clicked",
    NO_ONBOARDING_AUTOPILOT_SUGGESTIONS: "No Onboarding Autopilot Suggestions",
    ONBOARDING_EXTERNAL_AUTOPILOT_ENABLE_AUTOPILOT_EVENTS:
      "Onboarding External Autopilot List Enable Autopilot Events",
    ONBOARDING_EXTERNAL_AUTOPILOT_DISABLED_AUTOPILOT_EVENT_ID:
      "Onboarding External Autopilot List Disabled Autopilot External Event Id",
    ONBOARDING_EXTERNAL_AUTOPILOT_ENABLED_AUTOPILOT_EVENT_ID:
      "Onboarding External Autopilot List Enabled Autopilot External Event Id",
    ONBOARDING_EXTERNAL_AUTOPILOT_NOT_NOW_CLICKED: "Onboarding External Autopilot Not Now Clicked",
    NO_ONBOARDING_EXTERNAL_AUTOPILOT_SUGGESTIONS: "No Onboarding External Autopilot Suggestions",
    ONBOARDING_INVITES_SUGGESTED_INVITEES_COUNT: "Onbaording Invites Suggested Invitees Count",
    ONBOARDING_INVITES_SELECT_ALL_CLICKED: "Onbaording Invites Select All Clicked",
    ONBOARDING_INVITES_UNSELECT_ALL_CLICKED: "Onbaording Invites Unselect All Clicked",
    ONBOARDING_INVITES_BULK_INVITE_SELECTED: "A bulk invite event was selected",
    ONBOARDING_INVITES_BULK_INVITE_VIEWED: "User has seen bulk invite options",
    ONBOARDING_INVITES_SENT: "Onboarding Invites Sent",
    ONBOARDING_INVITES_SKIPPED: "Onboarding Invites Skipped",
    ONBOARDING_AUTOPILOT_CAROUSEL_NEXT_CLICKED: "Onboarding Autopilot Carousel Next Clicked",
    ONBOARDING_AUTOPILOT_CAROUSEL_PREV_CLICKED: "Onboarding Autopilot Carousel Previous Clicked",
    ONBOARDING_AUTOPILOT_CAROUSEL_SKIP_CLICKED: "Onboarding Autopilot Carousel Skip Clicked",
    ONBOARDING_AUTOPILOT_CAROUSEL_CLICK_SPECIFIC_SLIDE:
      "Onboarding Autopilot Carousel Specific Slide Clicked",
    ONBOARDING_COLOR_CODING_SKIPPED: "Onboarding Color Coding Skipped",
    ONBOARDING_COLOR_CODING_CONFIRMED: "Onboarding Color Coding Confirmed",
    ONBOARDING_PRIVATE_CALENDAR_SYNC_SKIPPED: "Onboarding Private Calendar Sync Skipped",
    ONBOARDING_PRIVATE_CALENDAR_SYNC_CONFIRMED: "Onboarding Private Calendar Sync Confirmed",
    ONBOARDING_TRAVEL_TIME_SKIPPED: "Onboarding Travel Time Skipped",
    ONBOARDING_TRAVEL_TIME_CONFIRMED: "Onboarding Travel Time Confirmed",
    ONBOARDING_SLACK_SKIPPED: "Onboarding Slack Skipped",
    ONBOARDING_SLACK_CONFIRMED: "Onboarding Slack Confirmed",
    ONBOARDING_SUMMARY_REVISITED: "Onboarding Summary Revisited Step",
    ONBOARDING_LANDING_CHROME_EXTENSION_INSTALL:
      "Onboarding Landing Chrome Extension Install Clicked",
    ONBOARDING_LANDING_EXPLORE_FEATURES: "Onboarding Landing Explore Features Clicked",
    ONBOARDING_LANDING_OPEN_SLACK: "Onboarding Landing Open Slack Clicked",
    ONBOARDING_EXIT_BUTTON: "Onboarding Exit Button Clicked",
    EXPERIMENT_ONBOARDING_DRAGGABLE_WORKING_HOURS_UTILIZED:
      "Draggable Working Hours Utilized to Modify Working Hours",
    EXPERIMENT_ONBOARDING_EXTERNAL_AUTOPILOT_SHOWN:
      "Experiment [Onboarding External Autopilot]: Shown",
    ONBOARDING_TEAM_JOIN_SKIPPED: "Onboarding Team Join Skipped",
    ONBOARDING_TEAM_CREATE_SKIPPED: "Onboarding Team Create Skipped",
    ONBOARDING_TEAM_STEP_INJECTED: "Onboarding Team Step Injected",
    ONBOARDING_TEAM_SHOWN: "Onboarding Team Step Shown",
    ONBOARDING_TEAM_CREATED: "Onboarding Team Created",
    ONBOARDING_TEAM_JOINED: "Onboarding Team Joined",
    ONBOARDING_SET_PRIMARY_TEAM: "Onboarding Set Primary Team",
    ONBOARDING_TEAM_CONFIRMATION_SHOWN: "Onboarding Single Team Confirmation Shown",
    ONBOARDING_TEAM_TAB_CHANGED: "Onboarding Team Join Create Tab Changed",
    ONBOARDING_TRIAL_SELECT_STEP_TRIAL_PLAN_SELECTED:
      "Onboarding Trial Select Step: Trial plan selected",
    ONBOARDING_UPDATE_SPLIT_WORKING_HOURS: "Onboarding Update Split Working Hours",
    EXPERIMENT_ONBOARDING_INVITE_ANIMATION_SHOWN: "Experiment [Onboarding Invite Animation]: Shown",
    EXPERIMENT_ONBOARDING_INVITE_WITH_NOTE_SHOWN: "Experiment [Onboarding Invite with Note]: Shown",
    INDIVIDUAL_JTBD_SURVEY_SUBMITTED: "Individual JTBD Survey Submitted",
    INDIVIDUAL_JTBD_SURVEY_SKIPPED: "Individual JTBD Survey Skipped",
    TEAM_JTBD_SURVEY_SUBMITTED: "Team JTBD Survey Submitted",
    TEAM_JTBD_SURVEY_SKIPPED: "Team JTBD Survey Skipped",
    ONBOARDING_LINKS_FINISH_ONBOARDING: "Onboarding - Links step - Clicked the Maybe Later button",
    ONBOARDING_LINKS_CONTINUE_ONBOARDING:
      "Onboarding - Links step - Clicked the Continue Onboarding button",
    ONBOARDING_LINKS_CONFIRMED: "Onboarding Links Confirmed",
    ONBOARDING_FOCUS_TIME_AND_LUNCH_HOLD_EXPAND_SETTINGS:
      "Onboarding - Focus Time Sync and Lunch Hold Step - Expand Settings",
    ONBOARDING_NO_FLEX_ONE_ON_ONES: "Onboarding - Flex One On Ones No Results",
    ONBOARDING_FLEX_ONE_ON_ONES_ENABLED: "Onboarding - Flex One On Ones Enabled",
    ONBOARDING_FLEX_ONE_ON_ONES_NOT_ENABLED: "Onboarding - Flex One On Ones Not Enabled",
    ONBOARDING_FLEX_ONE_ON_ONES_NO_RESULTS:
      "Onboarding Flex One on Ones No Applicable Events - Step Skipped",
    ONBOARDING_MANAGER_LINKS_ONBOARDING: "Onboarding - Manager - Segmented Summary - Links Clicked",
    ONBOARDING_MANAGER_CONTINUE_ONBOARDING:
      "Onboarding - Manager - Segmented Summary - Continue Onboarding Clicked",
    ONBOARDING_MANAGER_COMPLETE_ONBOARDING:
      "Onboarding - Manager - Segmented Summary - Finish Onboarding",
    ONBOARDING_INVITES_SENT_VIA_DIALOG: " Onboarding - Invite Dialog - Invites sent",
    ONBOARDING_MOBILE: "Onboarding - Mobile",
    ONBOARDING_TABLET_DESKTOP: "Onboarding - Tablet or Desktop",
    ONBOARDING_EXTENSION_CONTINUE_SETUP: "Extension - Onboarding - Continue Setup Clicked ",
    IDEAL_SCHEDULE_INPUT_VALUE: "Onboarding - Ideal Schedule Input Value",
  },
  ONBOARDING_CHECKLIST: {
    INVITE_TEAM_CLICKED: "Onboarding checklist - Invite Team link clicked",
    MAKE_MEETING_FLEX_CLICKED: "Onboarding checklist - Make meeting flexible clicked",
    LUNCH_CLICKED: "Onboarding checklist - Lunch link clicked",
    FOCUS_TIME_CLICKED: "Onboarding checklist - Focus time link clicked",
    SLACK_CLICKED: "Onboarding checklist - Add to Slack clicked",
    CHECKLIST_DISMISSED: "Onboarding checklist - Checklist dismissed",
    CHECKLIST_COMPLETE: "Onboarding checklist - Checklist complete",
    INVITE_SENT_THROUGH_SETTINGS: "Onboarding checklist - Invite sent through settings",
    LUNCH_ENABLED_THROUGH_SETTINGS: "Onboarding checklist -  Lunch Enabled through settings",
    FOCUS_TIME_ENABLED_THROUGH_SETTINGS:
      "Onboarding checklist - Focus time enabled through settings",
    SLACK_ENABLED_THROUGH_SETTINGS: "Onboarding checklist - Slack enabled through settings",
    CREATE_FLEX_MEETING_CLICKED: "Onboarding checklist - Create Flex Meeting Clicked",
    CHECKLIST_FORCE_OPENED: "Onboarding checklist - Checklist force opened",
  },
  OPTIMIZATION_NOTIFICATION: {
    SHOW_CARD: "Optimization Notification - Show Card",
    USER_CLICKED_CLOSE: "Optimization Notification - User Clicked Closed",
    USER_CLICKED_GET_MORE_OPTIMIZATIONS:
      "Optimization Notification - User Clicked More Optimizations",
    USER_CLICKED_GET_MORE_OPTIMIZATIONS_AND_SIDEBAR_OPENED:
      "Optimization Notification - User Clicked More Optimizations and Sidebar Opened",
  },
  MEETING_RELIEF_OPT_IN_NOTIFICATION: {
    SHOW_CARD: "Meeting Relief Opt In Notification - Show Card",
    USER_CLICKED_CLOSE: "Meeting Relief Opt In Notification - User Clicked Closed",
    USER_CLICKED_EDIT: "Meeting Relief Opt In Notification - User Clicked Edit My Breaks",
    SPLIT_MOST_LIKELY_GOT_ADBLOCK:
      "Meeting Relief Opt In Notification - Split most likely got adblocked",
  },
  MEETING_RELIEF_SUGGESTION_NOTIFICATION: {
    SHOW_CARD_FIRST_TIME: "Meeting Relief Suggestion Notification - Show Card First Time",
    SHOW_CARD_SECOND_TIME: "Meeting Relief Suggestion Notification - Show Card Second Time",
    USER_CLICKED_TURN_ON_SMART_BREAKS_FIRST_TIME:
      "Meeting Relief Suggestion Notification - User Clicked Turn On Smart Breaks Breaks First Time",
    USER_CLICKED_TURN_ON_SMART_BREAKS_SECOND_TIME:
      "Meeting Relief Suggestion Notification - User Clicked Turn On Smart Breaks Breaks Second Time",
    USER_CLICKED_EXPLORE_SETTINGS_FIRST_TIME:
      "Meeting Relief Suggestion Notification - User Clicked Explore Settings First Time",
    USER_CLICKED_EXPLORE_SETTINGS_SECOND_TIME:
      "Meeting Relief Suggestion Notification - User Clicked Explore Settings Second Time",
    USER_CLICKED_CLOSE_FIRST_TIME:
      "Meeting Relief Suggestion Notification - User Clicked Close First Time",
    USER_CLICKED_CLOSE_SECOND_TIME:
      "Meeting Relief Suggestion Notification - User Clicked Close Second Time",
  },
  HOME_NOTIFICATION_CARDS: {
    SHOWED_ANY_CARD: "Home Notification - Showed One of the Cards",
    SHOWED_HOME_NOTIFICATION_POTENTIAL: "Home Notification Potential - Showed Card",
    SHOWED_HOME_NOTIFICATION_VALUE_CREATED: "Home Notification Value Created - Showed Card",
    SHOWED_HOME_NOTIFICATION_DEFAULT: "Home Notification Value Default - Showed Card",
    USER_CLICKED_CTA_ON_ANY_CARD: "Home Notification - User Clicked CTA on One of the Cards",
    USER_CLICKED_CLOSE_ON_ANY_CARD: "Home Notification - User Clicked Close on One of the Cards",
    USER_CLICKED_CLOSE_HOME_NOTIFICATION_POTENTIAL:
      "Home Notification Potential - User Clicked Close",
    USER_CLICKED_CLOSE_HOME_NOTIFICATION_VALUE_CREATED:
      "Home Notification Value Created - User Clicked Close",
    USER_CLICKED_CLOSE_HOME_NOTIFICATION_DEFAULT: "Home Notification Default - User Clicked Close",
    USER_CLICKED_GET_MORE_TIME_IN_MY_DAY_HOME_NOTIFICATION_POTENTIAL:
      "Home Notification Potential - User Clicked Get more time in your day",
    USER_CLICKED_SET_UP_MY_IDEAL_DAY_HOME_NOTIFICATION_VALUE_CREATED:
      "Home Notification Value Created - User Clicked Set up your ideal day",
    USER_CLICKED_SET_UP_MY_IDEAL_DAY_HOME_NOTIFICATION_DEFAULT:
      "Home Notification Default - User Clicked Set up your ideal day",
  },
  SCHEDULE_WITH_AI_BUTTON_IN_GCAL: {
    HOVERED_ON_BUTTON: "Schedule with AI Button in GCal - Hovered on Button",
    HOVERED_ON_TOOLTIP: "Schedule with AI Button in GCal - Hovered on Tooltip",
    DISPLAYED_UP_ON_GCAL_SCREEN: "Schedule with AI Button in GCal - Displayed on GCal Screen",
    CLOSE_TEMPORARILY: "Schedule with AI Button in GCal - Close Temporarily",
    CLOSE_PERMANENTLY: "Schedule with AI Button in GCal - Dismiss",
  },
  RESCHEDULE_WITH_AI_BUUTON_IN_EVENT_MODAL: {
    CLICKED_RESCHEDULE_WITH_AI:
      "Reschedule with AI Button in Event Modal - Clicked on Reschedule with AI",
  },
  PLANNER: {
    SETTINGS: {
      HIDE_DECLINED: "Planner Settings - Hide declined events",
      HIDE_WEEKENDS: "Planner Settings - Hide weekends",
      DENSITY: "Planner Settings - Set density",
      COLLAPSE_HOLDS: "Planner Settings - Collapse Holds",
      FADE_PAST_EVENTS: "Planner Settings - Fade past events",
    },
  },
  APP_HOME: {
    CHROME_EXTENSION_INSTALL: "App Home Chrome Extension Install Clicked",
    CHROME_EXTENSION_INSTALL_DIMISS: "App Home Chrome Extension Install Dismiss Clicked",
    AUTOPILOT_LIST_EVENT: "Autopilot List Event Clicked",
  },
  WEB_LAYOUT: {
    COLLAPSE_EXPAND: {
      NO_DEFINITION_OF_SMALL_SCREEN:
        "Determined Collapse Default - No Definition of Small Screen, Default to Expanded",
      BIG_SCREEN_DEFAULT: "Determined Collapse Default - Big Screen Detected, Default to Expanded",
      SMALL_SCREEN_DEFAULT:
        "Determined Collapse Default - Small Screen Detected, Default to Collapse",
      USER_EXPANDED: "App Nav - User Clicked to Expand",
      USER_COLLAPSED: "App Nav - User Clicked to Collapse",
    },
  },
  APP_NAV: {
    PAGE_NOT_FOUND: "App Nav Page Not Found",
    LOGO: "App Nav Logo Clicked",
    OPEN_MENU: "App Nav Menu Open",
    MENU_SETTINGS: "App Nav Menu Settings Clicked",
    MENU_ACCOUNT: "App Nav Menu Account Clicked",
    MENU_LOGOUT: "App Nav Menu Logout Clicked",
    OPEN_ADVANCED_MENU: "App Nav Advanced Menu Open",
    MENU_INVITE: "App Nav Menu Invite Clicked",
    MENU_FEEDBACK: "App Nav Menu Feedback Clicked",
    MENU_HELP: "App Nav Menu Help Clicked",
    MENU_CLOCKWISE_ACADEMY: "App Nav Menu Clockwise Academy Clicked",
    MENU_PERSONAL_CALENDAR: "App Nav Menu Personal Calendar Clicked",
    MENU_SLACK: "App Nav Menu Slack Clicked",
    MENU_COLOR_CODING: "App Nav Menu Color Coding Clicked",
    MENU_SHADOW_CALENDAR: "App Nav Menu Shadow Calendar Clicked",
    REDIRECT: "App Nav Redirect",
  },
  APP_SLACK: {
    ADD_TO_SLACK: "App Slack Add to Slack Button Clicked",
    SUCCESSFUL_INSTALL: "App Slack Succesfully Installed Slack",
    SUCCESSFUL_CONNECT: "App Slack Succesfully Connected Slack to Clockwise",
    FAIL_INSTALL: "App Slack Failed to Install Slack",
    FAIL_CONNECT: "App Slack Failed to Connect Slack to Clockwise",
    STARTED_ONBOARDING_FROM_SLACK: "App Slack Started Onboarding From Slack",
    FINISHED_ONBOARDING_FROM_SLACK: "App Slack Finished Onboarding From Slack",
  },
  INSIGHTS: {
    LOGIN_MSFT: "Calendar Insights - Login - Microsoft",
    LOGIN_GOOGLE: "Calendar Insights - Login - Google",
    NAV_CLICK_LOGO: "Calendar Insights - Nav - Click Logo",
    NAV_CLICK_LOGOUT: "Calendar Insights - Nav - Click Logout",
    NAV_CLICK_TRY_CLOCKWISE: "Calendar Insights - Nav - Click Try Clockwise",
    SHARE_CLICK_COPY_LINK: "Calendar Insights - Share - Clicked Copy Link",
    SHARE_CLICK_FACEBOOK: "Calendar Insights - Share - Clicked Share on Facebook",
    SHARE_CLICK_LINKEDIN: "Calendar Insights - Share - Clicked Share on LinkedIn",
    SHARE_CLICK_TWITTER: "Calendar Insights - Share - Clicked Share on Twitter",
    SHARE_CLICK_EMAIL: "Calendar Insights - Share - Clicked Share via email",
    CHANGE_ROLE: "Calendar Insights - Filter - Change role",
    CHANGE_JOB: "Calendar Insights - Filter - Change job",
    CALLOUT_TRY_CLOCKWISE: "Calendar Insights - Callout - Clicked Try Clockwise",
    FOOTER_LINK_CLICKED: "Calendar Insights - Footer - Clicked Footer",
    SEE_EXAMPLE_CLICKED: "Calendar Insights - Login - See Example Clicked",
  },
  LINKS: {
    TUTORIAL_DIALOG: {
      VIEW: "SchedulingLinks.TutorialDialog.View",
      DISMISS: "SchedulingLinks.TutorialDialog.Dismiss",
      LEARN_MORE: "SchedulingLinks.TutorialDialog.LearnMore",
    },
    LINK: {
      CREATE_LINK: "SchedulingLinks.CreateSchedulingLink",
      COPY_TIMES: "SchedulingLink.OpenCopyTimes",
      COPY_LINK: "SchedulingLinks.CopySchedulingLink",
      VIEW_PREVIEW: "SchedulingLinks.ViewPreview",
      EDIT_LINK: "SchedulingLinks.EditLink",
      MANAGE_LINKS: "SchedulingLinks.ManageLinks",
    },
    EDIT_PAGE: {
      VIEW: "SchedulingLink.CreateEdit.View",
      VIEW_PREVIEW: "SchedulingLink.CreateEdit.ViewLiveLinkPage.Click",
      CREATE: "SchedulingLink.CreateEdit.CreateLink",
      SAVE_CHANGES: "SchedulingLink.CreateEdit.SaveEdits",
      DELETE: "SchedulingLink.CreateEdit.DeleteLink",
      CHANGE_ACTIVE: "SchedulingLink.CreateEdit.ChangeActive",
    },
    BOOKING: {
      VIEW: "SchedulingLink.Book.View",
      FEEDBACK_VIEW: "SchedulingLink.Book.FeedbackPage.View",
      MODAL_VIEW: "SchedulingLink.Book.BookTimeModal.View",
      MODAL_BOOK: "SchedulingLink.Book.BookTimeModal.Book",
      SHOW_FEWER_TIMES: "Link Scheduler - Show Fewer Times",
    },
    DASHBOARD: {
      VIEW: "SchedulingLink.Dashboard.View",
      CLONE: "SchedulingLink.Dashboard.Clone",
      DELETE: "SchedulingLink.Dashboard.Delete",
    },
    CONFIRMATION_PAGE: {
      VARIANT_CONTROL_VIEW: "SchedulingLink.Confirmation.ClockwiseBanner.Variant.Control.View",
      VARIANT_CONTROL_CLICKED:
        "SchedulingLink.Confirmation.ClockwiseBanner.Variant.Control.Clicked_Cta",
      VARIANT_GOOGLE_SIGN_IN_VIEW:
        "SchedulingLink.Confirmation.ClockwiseBanner.Variant.GoogleSignIn.View",
      VARIANT_GOOGLE_SIGN_IN_CLICKED:
        "SchedulingLink.Confirmation.ClockwiseBanner.Variant.GoogleSignIn.Clicked_Cta",
      VARIANT_HUBSPOT_FORM_VIEW:
        "SchedulingLink.Confirmation.ClockwiseBanner.Variant.HubspotForm.View",
      VARIANT_HUBSPOT_FORM_CLICKED:
        "SchedulingLink.Confirmation.ClockwiseBanner.Variant.HubspotForm.Clicked_Cta",
    },
    COPY_TIMES: {
      COPY_TIMES: "SchedulingLink.CopyTimes.Copy_Times",
      COPY_LINK: "SchedulingLink.CopyTimes.Copy_Link",
      UNAVAILABLE_TIME: "SchedulingLink.CopyTimes.Unavailable_Time",
      OPENED_FROM_TIME: "SchedulingLink.CopyTimes.Opened_From_Time",
    },
    ROUND_ROBIN: {
      MEETING_TYPE_SELECTED: "SchedulingLink.RoundRobin.Meeting_Type_Selected",
      UPGRADE_BUTTON_CLICKED: "SchedulingLink.RoundRobin.Upgrade_Button_Clicked",
      PRIORITY_CHANGED: "SchedulingLink.RoundRobin.Priority_Changed",
    },
  },
  /**
   * **DEPRECATED** Use `TrackingEvents.LINKS`.
   */
  LINK_SCHEDULER: {
    /**
     * **DEPRECATED** Use `TrackingEvents.LINKS`.
     */
    BOOKED: "Link Scheduler - Booked",
    /**
     * **DEPRECATED** Use `TrackingEvents.LINKS`.
     */
    COPY_LINK_CLICKED: "Link Scheduler - Copy Link Clicked",
    /**
     * **DEPRECATED** Use `TrackingEvents.LINKS`.
     */
    NO_TIMES: "Link Scheduler - No Times",
    /**
     * **DEPRECATED** Use `TrackingEvents.LINKS`.
     */
    OPEN_BOOKING_MODAL: "Link Scheduler - Open Booking Modal",
    /**
     * **DEPRECATED** Use `TrackingEvents.LINKS`.
     */
    OPEN_LINK_CLICKED: "Link Scheduler - Open Link Clicked",
    /**
     * **DEPRECATED** Use `TrackingEvents.LINKS`.
     */
    SHOW_MORE_TIMES: "Link Scheduler - Show More Times",
    /**
     * **DEPRECATED** Use `TrackingEvents.LINKS`.
     */
    EDIT_LINK_CLICKED: "Link Scheduler - Edit Link Clicked",
  },
  LINKS_ONBOARDING: {
    LOGIN: {
      CONNECT_CALENDER_CLICKED: "Links Onboarding - Login Page - Connect Your Calendar Clicked",
    },
    ONBOARDING_STEP: {
      GET_MY_LINK_CLICKED: "Links Onboarding - Links Onboarding Step - Get My Link Button Clicked",
      COPY_LINK_CLICKED: "Links Onboarding - Links Onboarding Step - Copy Link Button Clicked",
    },
  },
  SEGMENTED_ONBOARDING: {
    LINKS: "Segmented Onboarding - Links",
    MANAGER: "Segmented Onboarding - Manager",
  },
  SCHEDULING: {
    EXECUTED_RESCHEDULING_SUGGESTION: "Executed Rescheduling Suggestion",
    EXPANDED_RESCHEDULING_SUGGESTION: "Expanded Rescheduling Suggestion",
    GCAL_CREATE_EVENT_NEED_ROOM_CHANGED: "GCal Create Event Need Room Changed",
    GCAL_CREATE_EVENT_RANGE_CHANGED: "GCal Create Event Range Changed",
    GCAL_CREATE_EVENT_TIME_SELECTED: "GCal Create Event Time Selected",
    GCAL_EDIT_EVENT_USE_RESCHEDULING_SUGGESTION: "GCal Edit Event Page Use Rescheduling Suggestion",
    GCAL_ATTENDEES_INVITED_TO_CLOCKWISE: "GCal Attendees Invited To Clockwise",
    HOVERED_SUGGESTED_TIME: "Hovered Suggested Time",
    REQUESTED_EXTERNAL_RESCHEDULING_SUGGESTION: "Requested External Rescheduling Suggestion",
    REQUESTED_RESCHEDULING_SUGGESTION: "Requested Rescheduling Suggestion",
    RESCHEDULING_RANGE_CHANGED: "Rescheduling Range Changed",
    SELECTION_SCHEDULER_DURATION_CHANGED: "Selection Scheduler Duration Changed",
    SELECTION_SCHEDULER_EXPANDED: "Selection Scheduler Expanded",
    SELECTION_SCHEDULER_NEED_ROOM_CHANGED: "Selection Scheduler Need Room Changed",
    SELECTION_SCHEDULER_RANGE_CHANGED: "Selection Scheduler Range Changed",
    SELECTION_SCHEDULER_SCHEDULED: "Selection Scheduler Scheduled Event",
    SELECTION_SCHEDULER_TIME_SELECTED: "Selection Scheduler Time Selected",
    RESCHEDULER_STARTED: "Rescheduler Started",
    RESCHEDULER_CANCELLED: "Rescheduler Cancelled",
    RESCHEDULER_RESCHEDULED: "Rescheduler Rescheduled",
    RESCHEDULER_RESCHEDULED_CONFIRMATION_EDIT_EVENT_CLICKED:
      "Rescheduler Confirmation Edit Event Clicked",
    FEED_SCHEDULER_EXPANDED: "Feed Scheduler Expanded",
    FEED_SCHEDULER_CALENDAR_SELECTION_TIP_SHOWN: "Feed Scheduler Calendar Selection Tip Shown",
    FEED_SCHEDULER_CALENDAR_SELECTION_TIP_CLOSED: "Feed Scheduler Calendar Selection Tip Closed",
    FEED_SCHEDULER_INCLUDE_ROOM_CHANGE: "Feed Scheduler Include Room Change",
    FEED_SCHEDULER_AUTO_SCHEDULE_CLICKED: "Feed Scheduler Auto Schedule Button Clicked",
    FEED_SCHEDULER_CHOOSE_TIME_CLICKED: "Feed Scheduler Choose A Time Button Clicked",
    FEED_SCHEDULER_EVENT_SCHEDULED_CONFIRMATION_EDIT_EVENT_CLICKED:
      "Feed Scheduler Event Scheduled Confirmation Edit Event Link Clicked",
    FEED_SCHEDULER_EVENT_SCHEDULED_CONFIRMATION_EVENT_VIEW_CLICKED:
      "Feed Scheduler Event Scheduled Confirmation Event View Link Clicked",
    EVENT_SCHEDULER_TIME_INPUT_DURATION_CHANGED: "Event Scheduler Time Input Duration Changed",
    EVENT_SCHEDULER_TIME_INPUT_INCLUDE_ROOM_CHANGE:
      "Event Scheduler Time Input Include Room Change",
    EVENT_SCHEDULER_TIME_INPUT_RANGE_CHANGE: "Event Scheduler Time Input Range Change",
    EVENT_SCHEDULER_TIME_INPUT_SLOT_SELECTED: "Event Scheduler Time Input Slot Selected",
    EVENT_SCHEDULER_ATTENDEE_INPUT_CLICKED: "Event Scheduler Attendee Input Clicked",
    EVENT_SCHEDULER_ROOM_INPUT_EXPANDED: "Event Scheduler Room Input Expanded",
    EVENT_SCHEDULER_ROOM_INPUT_CLOSED: "Event Scheduler Room Input Closed",
    EVENT_SCHEDULER_ROOM_INPUT_ROOM_SELECTED: "Event Scheduler Room Input Room Selected",
    EVENT_SCHEDULER_ROOM_INPUT_ROOM_REMOVED: "Event Scheduler Room Input Room Removed",
    EVENT_SCHEDULER_CONFLICT_RESOLUTION_EXPANDED: "Event Scheduler Conflict Resolution Expanded",
    EVENT_SCHEDULER_CONFLICT_RESOLUTION_CLOSED: "Event Scheduler Conflict Resolution Closed",
    EVENT_SCHEDULER_MEETING_SCHEDULED: "Event Scheduler Meeting Scheduled",
    EVENT_SCHEDULER_EDIT_IN_GCAL: "Event Scheduler Edit in GCal",
    EVENT_SCHEDULER_LEAVE: "Event Scheduler Leave",
    EVENT_SCHEDULER_TOGGLE_AUTOPILOT_SETTING: "Event Scheduler Toggle Autopilot Setting",
    INLINE_RESCHEDULE_SHOWN: "Inline Reschedule Shown",
    INLINE_RESCHEDULE_CLICKED: "Inline Reschedule Button Clicked",
    TEAM_ANALYTICS_CARD_FIND_TIME_CLICKED: "Team Analytics Card - Find Time link - Clicked",
    FETCHED_SUGGESTED_TIMES: "Suggested times were fetched",
    NUM_SUGGESTED_TIMES_PRESENTED: "Number of suggestions presented",
    CREATE_EVENT_CLICKED: "Google Create event clicked",
    CW_CREATE_EVENT_CLICKED: "Clockwise Create Event Under Google Button Clicked",
    CW_SCHEDULE_EVENT_CLICKED: "Clockwise Schedule Event Under People Search Clicked",
    CAROUSEL_MORE_SUGGESTIONS: "User clicked More Suggestions to slide carousel right",
  },
  TASKS: {
    FEED_TASK_SCHEDULER_EXPANDED: "Feed Task Scheduler Expanded",
    FEED_TASK_SCHEDULER_TASK_SCHEDULED_CONFIRMATION_EDIT_EVENT_CLICKED:
      "Task Scheduled Confirmation Edit Event Link Clicked",
    FEED_TASK_SCHEDULER_TASK_SCHEDULED_CONFIRMATION_EVENT_VIEW_CLICKED:
      "Task Scheduled Confirmation Event View Link Clicked",
    FEED_TASK_SCHEDULER_AUTO_SCHEDULE_CLICKED: "Feed Task Scheduler Auto Schedule Button Clicked",
  },
  SETTINGS: {
    DATA_SOURCE_ADD: "Data Source Added to User",
    DATA_SOURCE_DELETED: "Data Source Deleted from User",
    DATA_SOURCE_SYNC_OFF: "Data Source Sync Turned Off",
    DATA_SOURCE_SYNC_ON: "Data Source Sync Turned On",
    DATA_SOURCE_TRY_PERSONAL: "Data Source Clicked to Try Personal Calendar Sync",
    DATA_SOURCE_DEACTIVATE_ACCOUNT: "Settings - Data sources - Deactivate Account",
    SETTINGS_DIALOG_OPEN: "Settings Dialog Open",
    SHADOW_CALENDAR_DISABLED: "Shadow Calendar Disabled/Not-visible",
    SHADOW_CALENDAR_ENABLED: "Shadow Calendar Enabled/Visible",
    UPDATE_PREFERRED_ROOMS: "Settings: Update Preferred Rooms",
    UPDATE_SMART_HOLD_SYNC: "Settings: Update Smart Hold Sync",
    UPDATE_SMART_HOLD_FREE_BUSY: "Settings: Update Smart Hold Free/Busy",
    UPDATE_SMART_HOLD_REMINDERS: "Settings: Update Smart Hold Reminders",
    SAVE_SMART_HOLD_SYNC: "Settings: Save Smart Hold Sync",
    COPY_INVITE_LINK: "Settings: Clicked button to copy invite link",
    UPDATE_PREFERRED_WORK: "Settings: Update Preferred Work",
    UPDATE_TRAVEL_TIME: "Settings: Update Travel Time Goal",
    SEND_INVITE_FROM_URL: "Settings: Send Invite from URL",
    SEND_INVITE: "Settings: Send Invite",
    WORK_ADDRESS_SELECT_RESULT: "Selected A Work Address From Search",
    WORK_ADDRESS_BEGIN_SEARCH: "Started Looking For Work Address",
    WORK_ADDRESS_REMOVE: "Removed Work Address",
    SECTION_OPEN: "Section Open",
    UPDATE_SMART_HOLD_SYNC_ALL: "Update Smart Hold Sync All",
    UPDATE_SMART_HOLD_IDEAL_MINUTES_PER_WEEK: "Update Smart Hold Ideal Minutes Per Week",
    TOGGLE_RECEIVE_DEFRAG_MOVE_VALUE_SUMMARY_EMAIL_SETTING:
      "Toggle Receive Defrag Move Value Summary Email Setting",
    TOGGLE_RECEIVE_INVITE_SUGGESTIONS_EMAIL_SETTING: "Toggle Invite Suggestions Email Setting",
    TOGGLE_RECEIVE_NOW_ON_AUTOPILOT_EMAIL_SETTING: "Toggle Receive Now On Autopilot Email Setting",
    TOGGLE_RECEIVE_WEEK_IN_REVIEW_EMAIL_SETTING: "Toggle Receive Week In Review Email Setting",
    TOGGLE_SEND_NOW_ON_AUTOPILOT_EMAIL_SETTING: "Toggle Send Now On Autopilot Email Setting",
    CLICK_MANAGE_SLACK_NOTIFICATION_SETTINGS: "Click Manage Slack Notification Settings",
    CLICK_ADMIN_PANEL: "Settings: Click Admin Panel Page Link",
    UPDATE_MEETING_RELIEF: "Toggled Meeting Relief",
    UPDATE_MEETING_RELIEF_THRESHOLD: "Updated Meeting Relief Threshold",
    UPDATE_MEETING_RELIEF_DURATION: "Updated Meeting Relief Duration",
    UPDATE_SPLIT_WORKING_HOURS: "Settings: Update Split Working Hours",
    UPDATE_SPLIT_MEETING_HOURS: "Settings: Update Split Meeting Hours",
    UPDATE_LUNCH: "Settings: Toggled Lunch",
    UPDATE_LUNCH_START: "Settings: Update Lunch Start Time",
    UPDATE_LUNCH_END: "Settings: Update Lunch End Time",
    UPDATE_LUNCH_DURATION_PREFERRED: "Settings: Update Lunch Duration Preferred",
    UPDATE_LUNCH_DURATION_MIN: "Settings: Update Lunch Duration Min",
    UPDATE_OPTIONS: "Settings: Update setting options",
    UPDATE_FT_AUTO_DECLINE: "Settings: Update FT Auto Decline",
    UPDATE_FT_DAILY_MAX: "Settings: Update FT Daily Max",
  },
  // The drawer that contains the month-view calendar "minimap"
  LEFT_DRAWER: {
    INITIAL_DRAWER_STATE: "Left Drawer - Records Whether It Starts Open",
    DRAWER_TOGGLED: "Left Drawer - Open/Close was Toggled",
  },
  CHROME_MENU: {
    CHROME_MENU_DRAWER_OPEN: "Chrome Menu: Drawer Open",
    CLICK_INVITE: "Chrome Menu: Invite Button Clicked",
    CLICK_YOUR_IDEAL_DAY: "Chrome Menu: Your Ideal Day Clicked",
    CLICK_YOUR_TEAMS: "Chrome Menu: Your Teams Clicked",
    CLICK_TEAM_CREATE: "Chrome Menu: Create Team Setup Clicked",
    CLICK_TEAM_JOIN: "Chrome Menu: Join Team Setup Clicked",
    CLICK_AUTOPILOT: "Chrome Menu: Autopilot Listing",
    CLICK_SHADOW_CALENDAR: "Chrome Menu: Shadow Calendar Settings Clicked",
    CLICK_PERSONAL_CALENDAR: "Chrome Menu: Personal Calendar Settings Clicked",
    CLICK_SLACK: "Chrome Menu: Slack Settings Clicked",
    CLICK_COLORING: "Chrome Menu: Event Color Settings Clicked",
    CLICK_SETTINGS: "Chrome Menu: Settings Clicked",
    CLICK_FEEDBACK: "Chrome Menu: Feedback Clicked",
    CLICK_HELP: "Chrome Menu: Help Center Clicked",
    CLICK_LOGOUT: "Chrome Menu: Logout Clicked",
    CLICK_UPGRADE: "Chrome Menu: Uprgrade Clicked",
    SELECT_TAB: "Chrome Menu: Tab Selected",
    CLICK_ADMIN_PANEL: "Chrome Menu: Admin Panel Clicked",
    CLICK_ONE_ON_ONES: "Chrome Menu: Click One On Ones",
  },
  SLACK: {
    ADDED: "Slack Account Added",
    BEGIN_ADD: "Start Adding Slack",
    DELETE: "Slack Account Deleted",
    OAUTH_WINDOW_CLOSED: "Slack OAuth Window Closed",
    UPDATE_SYNC: "Changed Slack Sync Permissions",
    UPDATE_DAILY_DIGEST: "Changed Daily Digest",
    UPDATE_DO_NOT_DISTURB: "Changed Do Not Disturb",
    UPDATE_NOTIFICATIONS: "Changed Notifications",
    SCOPES_UPGRADED: "Slack Scopes Upgraded",
    SLACK_CHANNEL_PICKER_SELECT: "Slack Channel Picker: Selected channel",
    TEAMS_REMOVED_SLACK: "Teams Slack: Removed Slack",
    TEAMS_CHANGED_DAILY_REMINDER_TIME: "Teams Slack: Changed daily reminder time",
  },
  SUGGESTIONS: {
    ACTION_CLICKED: "Suggestion Action Clicked",
    SHOWN: "Suggestion Shown",
    LOCAL_STORAGE_ALERT_CARD_SHOWN: "Local Storage Alert Card Shown",
  },
  TEAM_HEALTH: {
    DASHBOARD_ADD_TEAMMATE: "Team Health Dashboard Clicked Add Teammate",
    DASHBOARD_CHANGE_TIME_PERIOD_MANUALLY:
      "Team Health Dashboard Clicked Dropdown to Change Time Period",
    DASHBOARD_CHANGE_TIME_PERIOD:
      "Team Health Dashboard Moved Google Calendar to Change Time Period",
    DASHBOARD_VIEW: "Team Health Dashboard Loaded",
    HEALTH_AVATAR_HOVER: "Team Health Avatar Hovered to View Focus Time Stats",
    HOVER_TOGGLE_CALENDAR: "Hovered On Team Health Profile Toggle Calendar",
    PROFILE_BACK: "Team Health Profile Back Button Clicked",
    SEND_INVITE: "Team Health Profile Invite Sent",
    SELECT_TIME_RANGE: "Team Health Select New Time Range",
    SUMMARY_ADD_TEAMMATE: "Team Health Summary Clicked Add Teammate",
    SUMMARY_GO_TO_DASHBOARD: "Team Health Summary Clicked View Team",
    SUMMARY_GO_TO_OWN_PROFILE: "Team Health Clicked themselves to view Profile",
    SUMMARY_GO_TO_PROFILE: "Team Health Clicked Teammate to view Profile",
    SUMMARY_PAGE_BACK: "Team Health Summary Paged Back",
    SUMMARY_PAGE_FORWARD: "Team Health Summary Paged Forward",
    TOGGLE_CALENDAR: "Team Health Profile Toggled Calendar",
    CLICK_DEFINE_TEAM_FAB: "Team Health Define Team FAB Clicked",
    EXPERIMENT_TEAMMATE_HEALTH_CARD_INVITE_CLICKED:
      "Experiment [Gated Team Health]: Teammate Health Card Invite Button Clicked",
    EXPERIMENT_CALENDAR_PROFILE_GATED_TEAM_HEALTH_INVITE_CLICKED:
      "Experiment [Gated Team Health]: Calendar Profile Gated Team Health Invite Button Clicked",
    EXPERIMENT_SHOWN_GATED_TEAM_HEALTH_ON_CALENDAR_PROFILE:
      "Experiment [Gated Team Health]: Shown Gated Team Health on Calendar Profile",
    EXPERIMENT_SHOWN_GATED_TEAM_HEALTH_ON_TEAM_HEALTH_DASHBOARD:
      "Experiment [Gated Team Health]: Shown Gated Team Health on Teammate Health Dashboard",
    TEAMS_DROPDOWN_MANAGE_TEAM_SELECTED:
      "Team Health Dashboard - Teams Dropdown - Manage Team Selected",
    TEAMS_DROPDOWN_CREATE_TEAM_SELECTED:
      "Team Health Dashboard - Teams Dropdown - Create Team Selected",
    TEAMS_DROPDOWN_JOIN_TEAM_SELECTED:
      "Team Health Dashboard - Teams Dropdown - Join Team Selected",
    COPY_INVITE_LINK: "Team Health Dashboard - Copy Invite Button Clicked",
  },
  TEAMS: {
    CLICKED_CHANGE_PRIMARY_TEAM: "Teams Settings - Clicked change primary team link",
    CHANGED_PRIMARY_TEAM: "Teams Settings - Changed primary team",
    EMPTY_STATE_CLICKED_CREATE_TEAM_BUTTON:
      "Teams Settings - Empty Sate - Clicked create team button",
    EMPTY_STATE_CLICKED_JOIN_TEAM_BUTTON: "Teams Settings - Empty Sate - Changed join team button",
    CLOSE_CHANGE_PRIMARY_TEAM_MODAL: "Teams Settings - Set Primary Team Modal - Close Modal",
    CLICK_TEAM_TAB: "Teams Settings - Selected a team",
    CLICK_CREATE_JOIN_TEAM_TAB: "Teams Settings - Clicked create/join team tab button",
    TEAM_SETTING_SEARCH_CLICK_SEARCH_TOGGLE: "Team Settings Search - Click search toggle",
    TEAM_SETTING_SEARCH_CLICK_INVITE: "Team Settings Search - Click invite",
    TEAM_SETTING_SEARCH_CLICK_RESEND_INVITE: "Team Settings Search - Click resend invite",
    TEAM_SETTING_SEARCH_CLICK_DELETE: "Team Settings Search - Click delete",
    TEAM_SETTING_SEARCH_CLICK_CHECK: "Team Settings Search - Click checkbox",
    TEAM_SETTING_SEARCH_VIEW_RESULTS: "Team Settings Search - View search results",
    TEAM_SETTINGS_USER_TOGGLED_OOO_CALENDAR: "Team Settings - User Settings - Toggle OOO Calendar",
    TEAM_SETTINGS_ADMIN_TOGGLED_OOO_CALENDAR:
      "Team Settings - Admin Settings - Toggle OOO Calendar",
    TEAM_SETTINGS_ADMIN_DELETE_TEAM: "Team Settings - Admin Settings - Delete team",
    TEAM_SETTINGS_ADMIN_SET_TEAM_NAME: "Team Settings - Admin Settings - Set team name",
    TEAM_SETTINGS_ADMIN_TOGGLED_WFH_SYNC: "Team Settings - Admin Settings - Toggle WFH Sync",
    TEAM_SETTINGS_USER_UNSUBSCRIBED_TEAM_CALENDAR:
      "Team Settings - User Unsubscribed To Calendar via GCal",
    TEAM_SETTINGS_TEAM_CREATE_JOIN_SHOWN: "Team Settings - Team Create Join Shown",
    TEAM_SETTINGS_TEAM_CREATED: "Team Settings - Team Created",
    TEAM_SETTINGS_TEAM_JOINED: "Team Settings - Team Joined",
    TEAM_SETTINGS_TEAM_TAB_CHANGED: "Team Setting - Join Create Tab Changed",
    TEAM_CALENDAR_TOOLTIP_DISMISS_CLICKED: "Team Calendar Tooltip - Dismissed Clicked",
    TEAM_CALENDAR_TOOLTIP_LEARN_MORE_CLICKED: "Team Calendar Tooltip - Learn More Clicked",
    TEAMS_NO_MEETING_DAY_TOGGLED: "Team Settings - Admin Settings - No meeting day toggled",
    TEAMS_NO_MEETING_DAY_OF_WEEK_SELECTED:
      "Team Settings - Admin Settings - No meeting day of week selected",
    TEAMS_NO_MEETING_DAY_AUTOREPLY_TOGGLED:
      "Team Settings - Admin Settings - No meeting day autoreply toggled",
    TEAMS_NO_MEETING_DAY_TOGGLED_FOR_USER: "Team Settings - User Settings - No meeting day toggled",
    TEAMS_TEAM_ANALYTICS_ADD_TEAMMATES_BUTTON_CLICKED:
      "Team Analytics - No teams - Add teammates CTA clicked",
    M365_TEAM_SETTINGS_ADMIN_REMOVE_TEAM_CALENDAR:
      "Team Settings - Admin Settings - Remove team calendar",
    M365_TEAM_SETTINGS_ADMIN_RESEND_TEAM_CALENDAR_INVITES:
      "Team Settings - Admin Settings - Resend team calendar invites",
    M365_TEAM_SETTINGS_ADMIN_CREATE_TEAM_CALENDAR:
      "Team Settings - Admin Settings - Create team calendar",
    M365_TEAM_SETTINGS_SUBSCRIBED_TEAM_CALENDAR:
      "Team Settings - User Settings - Subscribed to team availability calendar",
    M365_TEAM_SETTINGS_UNSUBSCRIBED_TEAM_CALENDAR:
      "Team Settings - User Settings - Unsubscribed to team availability calendar",
  },
  TEAMS_MIGRATION: {
    TEAM_CREATED: "Teams Migration - Team Created",
    TEAM_JOINED: "Teams Migration - Team Joined",
    TAB_CHANGED: "Teams Migration - Tab Changed",
    SET_PRIMARY_TEAM: "Teams Migration - Set Primary Team",
    SINGLE_TEAM_CONFIRMATION: "Teams Migration - Single Team Confirmation",
    SINGLE_TEAM_CONFIRMATION_BYPASSED: "Teams Migration - Single Team Confirmation Bypassed",
    CLICKED_DONE: "Teams Migration - Clicked Done",
    CLICKED_SETTINGS: "Teams Migration - Clicked Settings",
    SHOWN: "Teams Migration - Shown",
  },
  AUTOPILOT_LIST: {
    EVENT_CLICKED: "Autopilot List Event Clicked",
    INVITE_CLICKED: "Autopilot List Invite Clicked",
    AUTOPILOT_CLICKED: "Autopilot List Autopilot Clicked",
  },
  WAITLIST: {
    LOADED_FROM_ALLOWED_LINK: "Waitlist flow: loaded with allowed status from query param",
    SUBMITTED_EMAIL_ERROR:
      "Waitlist flow: email submitted was invalid; front-end error message displayed",
    SUBMITTED_EMAIL: "Waitlist flow: email submitted",
    SUBMITTED_ROLE: "Waitlist flow: role submitted",
    FINISHED_FLOW: "Waitlist flow: finished",
    CLICKED_GO_TO_CHROME_STORE: "Waitlist flow: clicked to go to chrome store",
    CLICKED_TWITTER: "Waitlist flow: clicked to go to twitter",
    SUBMITTED_PERSONAL_EMAIL: "Waitlist flow: personal email submitted",
  },
  LANDING: {
    CLICKED_GO_TO_CHROME_STORE: "Landing Page: clicked to go to chrome store",
  },
  UNINSTALL: {
    LOGIN: "Login from uninstall page",
    DEACTIVATE_START: "Deactivate start from uninstall page",
    DEACTIVATE_ABORT: "Deactivate abort from uninstall page",
    DEACTIVATE_SUCCESS: "Deactivate success from uninstall page",
    DEACTIVATE_FAIL: "Deactivate fail from uninstall page",
    CANCEL: "Cancel (logout) from uninstall page",
    SUBMIT_SURVEY: "Submit Survey",
    SET_SURVEY_RESPONSE: "Uninstall - Survey - Set survey response",
  },
  WEB_LANDING_PAGE: {
    FEATURE_CARD_CLICKED: "WebLandingPage.FeatureCard.Clicked",
    PRODUCT_CHECKLIST: {
      ITEM_CLICKED: "WebLandingPage.ProductChecklist.ItemClicked",
      COMPLETE: "WebLandingPage.ProductChecklist.Complete",
      DISMISSED: "WebLandingPage.ProductChecklist.Dismissed",
    },
    PERSONALIZED_STATS: {
      ZERO_STATE_CTA_CLICKED: "WebLanding.PersonalizedStats.CTAClicked",
    },
  },
  WEEK_IN_REVIEW: {
    PLAY: "Week In Review Play",
    PAUSE: "Week In Review Pause",
    DRAG: "Week In Review Drag",
    SEND_INVITE: "Week In Review Invite Clicked",
  },
  NON_USER_DEFRAG_SURVEY: {
    CLICKED_TRY_CLOCKWISE: "Non-User Defrag Survey Try Clockwise Clicked",
    CLICKED_CTA: "Non-User Defrag Survey CTA Clicked",
  },
  NOTIFICATIONS: {
    NOTIFICATIONS_BADGE_SHOWN: "Notifications Badge Shown",
  },
  ZOOM: {
    ADDED: "Zoom: Account Level - Zoom Account Added",
    BEGIN_ADD: "Zoom: Account Level - Start Adding Zoom",
    DELETE: "Zoom: Account Level - Account Deleted",
    OAUTH_WINDOW_CLOSED: "Zoom: Account Level - Zoom OAuth Window Closed",

    PERSONAL_ADDED: "Zoom: User Level - Zoom Account Added",
    PERSONAL_BEGIN_ADD: "Zoom: User Level - Start Adding Zoom",
    PERSONAL_DELETE: "Zoom: User Level - Account Deleted",
    PERSONAL_OAUTH_WINDOW_CLOSED: "Zoom: User Level - Zoom OAuth Window Closed",
  },
  LOGGED_OUT_EXTENSION_BANNER: {
    SEEN: "Extension Logged Out Banner - Seen",
    CLICKED_LOGIN: "Extension Logged Out Banner - Clicked Login",
    CLICKED_DISMISS: "Extension Logged Out Banner - Clicked Dismiss",
  },
  PAYWALLS: {
    PAYMENT_PLAN_STATUS_CLICKED: "Paywalls - Payment Plan Status - Clicked",
    EXPERIMENT_PAYWALLS_ENABLED: "Paywalls - Experiment Enabled",
    M2_ANALYTICS_TEAM_CHECKOUT_CLICKED: "Paywalls - Analytics M2 paywall checkout clicked",
    M2_ANALYTICS_TEAM_PRICING_CLICKED: "Paywalls - Analytics M2 paywall pricing clicked",
    M2_ANALYTICS_TEAM_JOIN_PLAN_CLICKED: "Paywalls - Analytics M2 paywall join plan clicked",
    M2_LINKS_DASHBOARD_PRICING_CLICKED: "Paywalls - Links Dashboard pricing clicked",
    M2_LINKS_DASHBOARD_JOIN_PLAN_CLICKED: "Paywalls - Links Dashboard join plan clicked",
    M2_LINKS_DASHBOARD_PAYWALL_MODAL_OPEN_GROUP:
      "Paywalls - Links Dashboard - Group Link Paywalls Modal Open",
    M2_LINKS_DASHBOARD_PAYWALL_MODAL_OPEN_DEFAULT:
      "Paywalls - Links Dashboard - Link Paywalls Modal Open",
    M2_ADMIN_NMD_PRICING_CLICKED: "Paywalls - Admin NMD pricing clicked",
    M2_ADMIN_NMD_JOIN_PLAN_CLICKED: "Paywalls - Admin NMD join plan clicked",
    M2_ADMIN_AVAILABILTIY_PRICING_CLICKED: "Paywalls - Admin Availability pricing clicked",
    M2_ADMIN_AVAILABILTIY_JOIN_PLAN_CLICKED: "Paywalls - Admin Availability join plan clicked",
    M2_TEAM_SETTINGS_PRICING_CLICKED: "Paywalls - Team Settings pricing clicked",
    M2_TEAM_SETTINGS_JOIN_PLAN_CLICKED: "Paywalls - Team Settings join plan clicked",
    M2_FLEX_SIDEBAR_PRICING_CLICKED: "Paywalls - Flex Sidebar pricing clicked",
    M2_FLEX_SIDEBAR_JOIN_PLAN_CLICKED: "Paywalls - Flex Sidebar join plan clicked",
    ROUND_ROBIN_DASHBOARD_PRICING_CLICKED: "Paywalls - Links Dashboard Round Robin pricing clicked",
    ROUND_ROBIN_DASHBOARD_JOIN_PLAN_CLICKED:
      "Paywalls - Links Dashboard Round Robin join plan clicked",
    CHROME_EXTENSION_FREEMIUM_BANNER_SHOWN:
      "Paywalls - Chrome Extension - Freemium Org Status Banner Shown",
    CHROME_EXTENSION_FREEMIUM_BANNER_CLICK_VIEW_PLANS:
      "Paywalls - Chrome Extension - Freemium Org Status Banner - Click View plans",
    CHROME_EXTENSION_FREEMIUM_BANNER_CLICK_JOIN_A_PLAN:
      "Paywalls - Chrome Extension - Freemium Org Status Banner - Click Join a plan",
    CHROME_EXTENSION_FREEMIUM_BANNER_CLICK_DISMISS:
      "Paywalls - Chrome Extension - Freemium Org Status Banner - Click Dismiss",
    CHROME_EXTENSION_PRO_TRIAL_BANNER_CLICK_DISMISS:
      "Paywalls - Chrome Extension - Pro Trial Notification Banner - Click Dismiss",
    CHROME_EXTENSION_PRO_TRIAL_BANNER_CLICK_VIEW_PLANS:
      "Paywalls - Chrome Extension - Pro Trial Notification Banner - Click View plans",
    CHROME_EXTENSION_PRO_TRIAL_BANNER_CLICK_UPGRADE:
      "Paywalls - Chrome Extension - Pro Trial Notification Banner - Click Upgrade",
    CHROME_EXTENSION_PRO_TRIAL_BANNER_CLICK_JOIN_A_PLAN:
      "Paywalls - Chrome Extension - Pro Trial Notification Banner - Click Join a Plan",
    CHROME_EXTENSION_MOVE_RANGES_CLICK_VIEW_PLANS:
      "Paywalls - Chrome Extension - Move Range - Click View plans",
    CHROME_EXTENSION_PLAN_BADGE_CLICKED: "Paywalls - Chrome Nav - Plan Badge clicked",
    FREEMIUM_FEED_CALLOUT_CLICK_UPGRADE:
      "Paywalls - Feed - Freemium Org Status Callout - Click Upgrade",
    FREEMIUM_FEED_CALLOUT_CLICK_JOIN_PLAN:
      "Paywalls - Feed - Freemium Org Status Callout - Click Join Plan",
    FREEMIUM_EXTENSION_CALLOUT_CLICK_UPGRADE:
      "Paywalls - Chrome Extension - Freemium Org Status Callout - Click Upgrade",
    SETTINGS_MEETING_RELIEF_CALLOUT_CLICK_VIEW_PLANS:
      "Paywalls - Settings - Meeting Relief Callout - Click View Plans",
    SETTINGS_MEETING_RELIEF_CALLOUT_CLICK_JOIN_PLAN:
      "Paywalls - Settings - Meeting Relief Callout - Click Join Plan",
    FREEMIUM_FEED_CALLOUT_CLICK_CONTACT_US:
      "Paywalls - Feed - Freemium Org Status Callout - Click contact us",
    FREEMIUM_EVENT_AUTOPILOT_PAUSED_CLICK_CTA:
      "Paywalls - Event - Event Autopilot Paused - Click CTA",
    EXPERIMENT_FREEMIUM_ORG_Q42020_ENABLED: "Paywalls - FreemiumOrgQ42020 Experiment Enabled",
    CHROME_EXTENSION_AP_PAUSED_CLICK_VIEW_PLANS:
      "Paywalls - Chrome Extension - Autopilot Paused - Click View plans",
    CHROME_EXTENSION_AP_PAUSED_CLICK_JOIN_PLAN:
      "Paywalls - Chrome Extension - Autopilot Paused - Click Join plan",
    SIDEMENU_TRIAL_BANNER_UPGRADE_CLICKED: "Paywalls - Trial Banner Sidemenu - Click upgrade",
    SIDEMENU_TRIAL_BANNER_JOIN_PLAN_CLICKED: "Paywalls - Trial Banner Sidemenu - Click join plan",
    LINKS_UPGRADE_DIALOG_JOIN_PLAN_CLICKED:
      "Paywalls - Links Dashboard - Upgrade Dialog - Click Join Plan",
    LINKS_UPGRADE_DIALOG_UPGRADE_CLICKED:
      "Paywalls - Links Dashboard - Upgrade Dialog - Click Upgrade",
    LINKS_UPGRADE_DIALOG_COMPARE_PLANS_CLICKED:
      "Paywalls - Links Dashboard - Upgrade Dialog - Click Compare Plans",
    LINKS_SHARED_SETTINGS_DIALOG_UPGRADE_CLICKED:
      "Paywalls - Links Dashboard - Shared Settings Dialog - Click Upgrade",
  },
  TRIAL: {
    FOCUS_TIME_SIDEBAR_VIEW_ONE_DAY_LEFT_UPGRADE_CLICKED:
      "Focus Time - Sidebar View - One Day Remaining on Trial - Upgrade Clicked",
    FOCUS_TIME_GCAL_EVENT_ONE_DAY_LEFT_UPGRADE_CLICKED:
      "Focus Time - Google Event View - One Day Remaining on Trial - Upgrade Clicked",
    SIDEBAR_VIEW_TRIAL_EXPIRED_UPGRADE_CLICKED:
      "Sidebar View - Trial Expired Callout - Upgrade Clicked",
    SIDEBAR_VIEW_TRIAL_EXPIRED_JOIN_PLAN_CLICKED:
      "Sidebar View - Trial Expired Callout - Join Plan Clicked",
    FLEX_MEETING_GCAL_EVENT_TRIAL_EXPIRED_UPGRADE_CLICKED:
      "Flex Meeting - Google Event View - Trial Expired - Upgrade Clicked",
    FLEX_MEETING_GCAL_EVENT_ONE_DAY_LEFT_UPGRADE_CLICKED:
      "Flex Meeting - Google Event View - One Day Left - Upgrade Clicked",
    FLEX_MEETING_GCAL_EVENT_TRIAL_EXPIRED_JOIN_PLAN_CLICKED:
      "Flex Meeting - Google Event View - Trial Expired - Join Plan Clicked",
    FOCUS_TIME_GCAL_EVENT_ONE_DAY_LEFT_JOIN_PLAN_CLICKED:
      "Focus Time - Sidebar View - One Day Left - Join Plan Clicked",
    FLEX_MEETING_GCAL_EVENT_ONE_DAY_LEFT_JOIN_PLAN_CLICKED:
      "Flex Meeting - Google Event View - One Day Left - Join Plan Clicked",
  },
  PRICING: {
    TOGGLE_PRICING_RECURRENCE: "Pricing - Pricing page - Toggle pricing recurrence",
    TOGGLE_FEATURE_COMPARISON: "Pricing - Pricing page - Toggle feature comparison",
    SELECT_TEAM: "Pricing - Pricing page - Select team",
    UPGRADE_CLICKED: "Pricing - Pricing page - Upgrade now button clicked",
    DOWNGRADE_CLICKED: "Pricing - Pricing page - Downgrade button clicked",
    DOWNGRADE_CONFIRMATION_CLICKED: "Pricing - Pricing page - Downgrade confirmation clicked",
    CONTACT_US_CLICKED: "Pricing - Pricing page - Contact us button clicked",
    OPEN_TEAM_JOIN_CREATE_MODAL: "Pricing - Pricing page - Open Team Join/Create modal",
    CLOSE_TEAM_JOIN_CREATE_MODAL: "Pricing - Pricing page - Close Team Join/Create modal",
    VIEW_PRICING_GRID: "Pricing - Pricing Page - View Pricing Grid",
  },
  WORKING_LOCATION: {
    // WORKING_LOCATION_SETTING_CLOSE: "Working Location - Setting - Close",
  },
  CHECKOUT: {
    SELECT_BILLING_RECURRENCE: "Checkout - Checkout page - Select billing recurrence",
    SELECT_TEAM: "Checkout - Checkout page - Select team",
    OPEN_TEAM_JOIN_CREATE_MODAL: "Checkout - Checkout page - Open Team Join/Create modal",
    CLOSE_TEAM_JOIN_CREATE_MODAL: "Checkout - Checkout page - Close Team Join/Create modal",
    CLICK_CHECKOUT: "Checkout - Checkout page - Clicked Checkout",
    CLICK_MEMBER_PLAN_TYPE_EDIT: "Checkout - Checkout page - Member plan type - Clicked edit",
    PAYMENT_CONFIRMATION_GOT_IT_CLICKED: "Checkout - Payment confirmation page - Got it clicked",
    OPEN_ORG_USER_SELECT_MODAL: "Checkout - Checkout page - Open Org user select modal",
    CLOSE_ORG_USER_SELECT_MODAL: "Checkout - Checkout page - Close Org user select modal",
    ORG_USER_SELECT_MODAL_CONTACT_US_CLICKED:
      "Checkout - Checkout page - Org user select modal - click contact us",
    PAID_SEAT_COUNT_ABOVE_THRESHOLD:
      "Checkout - Checkout page - paid seat count is above self service threshold",
    VIEW_CHECKOUT: "Checkout - Checkout Page - View Checkout",
    TEAM_JOIN_CREATE_MODAL_TEAM_CREATED:
      "Checkout - Checkout page - Team Join/Create modal - Team created",
    TEAM_JOIN_CREATE_MODAL_TEAM_JOINED:
      "Checkout - Checkout page - Team Join/Create modal - Team joined",
    TEAM_JOIN_CREATE_MODAL_TAB_CHANGED:
      "Checkout - Checkout page - Team Join/Create modal - Tab changed",
    CONTACT_SALES_CLICKED: "Checkout - Checkout page - Plan exceeded max - contact sales clicked",
    ORG_OPTION_SELECTED: "Checkout - Checkout page - Org option selected",
    TEAM_OPTION_SELECTED: "Checkout - Checkout page - Team option selected",
    CREATE_TEAM_CLICKED: "Checkout - Checkout page - Create team clicked from team option",
    EXPERIMENT_CHECKOUT_PAGE_TEAMS_DEFAULT: "Checkout - Checkout page - Teams Default Experiment",
    INDIVIDUAL_OPTION_SELECTED: "Checkout - Checkout page - Individual option selected",
  },
  ADMIN_PANEL: {
    SIDEBAR_CLICK_ORG_OVERVIEW: "Admin Panel - Sidebar - Org Overview tab clicked",
    SIDEBAR_CLICK_MEMBERS: "Admin Panel - Sidebar - Members tab clicked",
    SIDEBAR_CLICK_SETTINGS: "Admin Panel - Sidebar - Organization Settings tab clicked",
    SIDEBAR_CLICK_PLANS_AND_BILLING: "Admin Panel - Sidebar - Plans and Billing tab clicked",
    MEMBERS_SEND_INVITE: "Admin Panel - Members - Invite button clicked",
    MEMBERS_SEND_SUGGESTED_INVITE: "Admin Panel - Members - Suggested invite button clicked",
    MEMBERS_SEARCH: "Admin Panel - Members - Search",
    MEMBERS_SORT: "Admin Panel - Members - Sort",
    MEMBERS_OPEN_INVITE_DIALOG: "Admin Panel - Members - Open invite dialog",
    MEMBERS_MULTIFILTER_FILTER_TEAM: "Admin Panel - Members - Multi filter - Filter by team",
    MEMBERS_MULTIFILTER_FILTER_BILLING_GROUP:
      "Admin Panel - Members - Multi filter - Filter by billing group",
    MEMBERS_SET_BILLING_GROUP_ROLE: "Admin Panel - Members - Set billing group role",
    MEMBERS_REMOVE_FROM_BILLING_GROUP: "Admin Panel - Members - Remove person from billing group",
    MEMBERS_ADD_TO_BILLING_GROUP: "Admin Panel - Members - Add person to billing group",
    MEMBERS_USE_BULK_MEMBER_ACTION: "Admin Panel - Members - Use bulk add/remove action",
    MEMBERS_REQUEST_TO_JOIN_BILLING_GROUP:
      "Admin Panel - Members - Request person to join billing group",
    PLANS_AND_BILLING_CLICK_VIEW_PLANS:
      "Admin Panel - Plans and Billing - View plans button clicked",
    PLANS_AND_BILLING_CLICK_STRIPE_BILLING_PORTAL:
      "Admin Panel - Plans and Billing - Stripe customer billing portal clicked",
    PLANS_AND_BILLING_CLICK_CONFIGURE_SCIM:
      "Admin Panel - Plans and Billing - Configure SCIM clicked",
    PLANS_AND_BILLING_SORT: "Admin Panel - Plans and Billing - Sort",
    PLANS_AND_BILLING_EXPAND_ROW: "Admin Panel - Plans and Billing - Expand row",
    PLANS_AND_BILLING_BILLING_GROUPS_SETTINGS_MANAGE_MEMBERS_CLICK:
      "Admin Panel - Plans and Billing - Biling Group Settings Click Manage member",
    PLANS_AND_BILLING_BILLING_GROUPS_SETTINGS_SET_ADMIN_PROVISIONING_PRIVILIGE:
      "Admin Panel - Plans and Billing - Biling Group Settings Set admin provisioning privilege",
    OVERVIEW_CALENDAR_DATA_MODAL_OPENED:
      "Admin Panel - Overview - Calendar Analytics Download - Download modal opened",
    OVERVIEW_CALENDAR_DATA_DOWNLOAD_CLICKED:
      "Admin Panel - Overview - Calendar Analytics Download - Download button clicked",
    OVERVIEW_CALENDAR_DATA_CANCEL_CLICKED:
      "Admin Panel - Overview - Calendar Analytics Download - Cancel button clicked",
    OVERVIEW_CALENDAR_DATA_OK_CLICKED:
      "Admin Panel - Overview - Calendar Analytics Download - Ok button clicked",
    OVERVIEW_CALENDAR_DATA_UPGRADE_CLICKED:
      "Admin Panel - Overview - Calendar Analytics Download - Upgrade callout clicked",
    OVERVIEW_USER_LIST_MODAL_OPENED:
      "Admin Panel - Users - User List Download - Download modal opened",
    OVERVIEW_USER_LIST_DOWNLOAD_CLICKED:
      "Admin Panel - Users - User List Download - Download button clicked",
    OVERVIEW_USER_LIST_DOWNLOAD_SUCCESS:
      "Admin Panel - Users - User List Download - Download Succeeded",
    OVERVIEW_USER_LIST_CANCEL_CLICKED:
      "Admin Panel - Users - User List Download - Cancel button clicked",
    APBILLING_CLICK_PRICING: "Billing Page: User clicked to Pricing Page",
    APBILLING_CLICK_PRIMARY_TEAM: "Billing Page: User clicked view Primary Team",
    APBILLING_CLICK_PRIMARY_MEMBERS: "Billing Page: User clicked view Primary Team Members",
    APBILLING_CLICK_OTHER_MEMBERS: "Billing Page: User clicked view Team Members of other teams",
    APBILLING_CLICK_ADD_USERS: "Billing Page: User clicked Add Users",
    APBILLING_CLICK_CONTACT_US: "Billing Page: User clicked Contact Us",
    APBILLING_CLICK_MANAGE_BILLING: "Billing Page: User clicked Manage Billing",
    APBILLING_USER_REQUEST_JOIN: "Billing Page: User requested to join a billing group",
    APBILLING_USER_ADD_SELF: "Billing Page: User added self to a billing group",
    APBILLING_SEAT_CHANGE_FORM: "Billing Page: Admin opened request to adjust seats",
    APBILLING_SEAT_CHANGE_SUBMIT: "Billing Page: Admin submitted request to adjust seats",
    APBILLING_REQUEST_SEATCOUNT_CHANGE: "Billing Page: Admin requested to adjust seats",
  },
  AUTOPILOT_HISTORY: {
    SIDEPANEL_FLEXIBLE_MEETING_HISTORY_VIEW:
      "Autopilot History - Sidepanel - Flexible Meeting History View",
    SIDEPANEL_FLEXIBLE_MEETING_HISTORY_SHOW_MORE:
      "Autopilot History - Sidepanel - Flexible Meeting History Show More",
    SIDEPANEL_FLEXIBLE_MEETING_HISTORY_GIVE_FEEDBACK:
      "Autopilot History - Sidepanel - Flexible Meeting History Give Feedback",
    SIDEBPANEL_VIEW_FLEXIBLE_MEETING_HISTORY_SCROLL_THRESHOLD_REACHED:
      "Autopilot History - Sidepanel - Scroll Threshold Reached",
  },
  WEB_SETTINGS: {
    EMAILS_AND_NOTIFICATIONS: {
      SLACK_NOTIFICATIONS_LINK_CLICKED:
        "Web Settings - Emails and notifications - Slack notifications link clicked",
      TOGGLE_RECEIVE_DEFRAG_MOVE_VALUE_SUMMARY_EMAIL_SETTING:
        "Web Settings - Emails and notifications - Toggle Receive Defrag Move Value Summary Email Setting",
      TOGGLE_RECEIVE_INVITE_SUGGESTIONS_EMAIL_SETTING:
        "Web Settings - Emails and notifications - Toggle Invite Suggestions Email Setting",
      TOGGLE_RECEIVE_NOW_ON_AUTOPILOT_EMAIL_SETTING:
        "Web Settings - Emails and notifications - Toggle Receive Now On Autopilot Email Setting",
      TOGGLE_RECEIVE_WEEK_IN_REVIEW_EMAIL_SETTING:
        "Web Settings - Emails and notifications - Toggle Receive Week In Review Email Setting",
      TOGGLE_RECEIVE_YOUR_WEEK_AHEAD_EMAIL_SETTING:
        "Web Settings - Emails and notifications - Toggle Receive Your Week Ahead Email Setting",
      TOGGLE_SEND_NOW_ON_AUTOPILOT_EMAIL_SETTING:
        "Web Settings - Emails and notifications - Toggle Send Now On Autopilot Email Setting",
      TOGGLE_RECEIVE_MANAGE_ONE_ON_ONES_EMAIL_SETTING:
        "Web Settings - Emails and notifications - Toggle Receive One-on-ones Email Setting",
      TOGGLE_BROWSER_NOTIFICATION_PERMISSION_GRANTED_SETTING:
        "Web Settings - Emails and notifications - Toggle Browser notification permission granted setting",
      TOGGLE_RECEIVE_FLEXIBLE_MEETING_NOTIFICATION_NOTIFICATION_SETTING:
        "Web Settings - Emails and notifications - Toggle Receive Flexible meeting notification setting",
    },
    EVENT_COLOR_CODING: {
      LEARN_MORE_CLICKED: "Web Settings - Event color coding - Learn more clicked",
    },
    EXPERIMENTS: {
      RESET_ONBOARDING_CLICKED: "Web Settings - Experiments - Reset onboarding clicked",
      CLEAR_USER_FLAGS_CLICKED: "Web Settings - Experiments - Clear user flags clicked",
      SUGGESTIONS_FETCH_DATE_OVERRIDE_SET:
        "Web Settings - Experiments - Set suggestions fetch date override",
      EXPERIMENT_TOGGLED: "Web Settings - Experiments - Experiment toggled",
      CLEAR_USER_DATA_CLICKED: "Web Settings - Experiments - Clear user data clicked",
    },
  },
  INVITE_DIALOG: {
    COPY_INVITE_LINK: "Invite Dialog - Copy Invite Link",
    CANCEL: "Invite Dialog - Cancelled",
    INVITED: "Invite Dialog - Invited",
    VIEWED: "Invite Dialog - Viewed",
  },
  TRIAL_EXTENSION: {
    INVITED: "Trial Extension - Users Invited",
    TRIAL_EXTENDED: "Trial Extension - Trial extended sucessfully",
    CTA_CLICKED: "Trial Extension - Extend Trial CTA Clicked",
    DISMISSED: "Trial Extension - Cancel Clicked",
    MODAL_VIEWED: "Trial Extension - Modal Viewed",
    CTA_CLICKED_EXTENSION_BANNER: "Trial Extension - Extend Trial CTA Clicked - Extension Banner",
  },
  TUTORIAL_DIALOG: {
    VIEW: "Tutorial Dialog - Viewed",
    DISMISS: "Tutorial Dialog - Dismissed",
    CONFIRM: "Tutorial Dialog - Confirmed",
  },
  INVITE_ATTRIBUTION: {
    INVITE_LINK_ACTIVATED: "Invite Link from app activated by user",
    USER_AUTHENTICATED_AFTER_APP_INVITE_LINK: "User authenticated after Invite Link",
  },
  LAUNCH_DARKLY: {
    LD_ERROR_CAUGHT: "Launch Darkly - Error caught",
  },
  ONBOARDING_FLEX_MEETING_SURVEY: {
    FLEX_MEETING_SURVEY_SHOWN: "Onboarding - Flex Meeting Survey - Survey shown to user",
  },
  ONBOARDING_FLEX_MEETING_CALCULATION_EXPERIMENT: {
    USER_ON_EXPERIMENT: "Onboarding Flex Meeting Calculation Experiment - User On Experiment",
    USER_ON_CONTROL: "Onboarding Flex Meeting Calculation Experiment - User On Control",
    EXPERIMENT_NO_FLEX_MEETINGS:
      "Onboarding Flex Meeting Calculation Experiment - User On Experiment - No Suggestions",
    EXPERIMENT_FLEX_MEETING_SUGGESTIONS:
      "Onboarding Flex Meeting Calculation Experiment - User On Experiment - Suggestion Count",
    CONTROL_NO_FLEX_MEETINGS:
      "Onboarding Flex Meeting Calculation Experiment - User On Control - No Suggestions",
    CONTROL_FLEX_MEETING_SUGGESTIONS:
      "Onboarding Flex Meeting Calculation Experiment - User On Control - Suggestion Count",
    NUMBER_EVENTS_RENDERED_PER_COLUMN:
      "Onboarding Flex Meeting Calculation Experiment - Events Number - Per Column",
  },
  MULTI_CALENDAR_SELECT: {
    CALENDAR_TOGGLED: "Multi Calendar Select - Calendar Toggled",
    BUTTON_CLICKED: "Multi Calendar Select - Button Clicked",
    CLEAR: "Multi Calendar Select - Clear all",
    SCHEDULE: "Multi Calendar Select - Schedule",
    MAX_REACHED: "Multi Calendar Select - Max Calendars Reached",
  },
  AI_WAITLIST: {
    MODAL_SHOWN: "AI Waitlist - Modal Shown",
    JOIN_BUTTON_CLICKED: "AI Waitlist - Button Clicked - Join waitlist",
    LEARN_BUTTON_CLICKED: "AI Waitlist - Button Clicked - Learn more",
    DISMISS_BUTTON_CLICKED: "AI Waitlist - Button Clicked - Not now",
  },
  SHAREABLE_PROPOSAL: {
    ERROR: "Shareable Proposal - Error",
    CANCEL: "Shareable Proposal - Cancel",
    SHARE_TIMES: {
      BUTTON_VIEWED: "Shareable Proposal - Share Times - Button Viewed",
      BUTTON_CLICKED: "Shareable Proposal - Share Times - Button Clicked",
    },
    SHARE_SUB_MENU: {
      SEND_TO_EMAIL: "Shareable Proposal - Share Sub Menu - Send to Email",
      COPY_PROPOSAL: "Shareable Proposal - Share Sub Menu - Copy Proposal",
      SHARE_ALL_TIMES: "Shareable Proposal - Share Sub Menu - Share All Times",
      SEND_PROPOSE_NEW_TIME_EMAIL:
        "Shareable Proposal - Share Sub Menu - Send Propose New Time Email",
      SEND_PREVIEW_PROPOSE_NEW_TIME_EMAIL:
        "Shareable Proposal - Share Sub Menu - Send Preview Propose New Time Email",
    },
    MODAL: {
      COPY_LINK: "Shareable Proposal - Modal - Copy link",
      COPY_TIMES: "Shareable Proposal - Modal - Copy times",
      SCHEDULING_LINK: {
        COPY_LINK: "Shareable Proposal - Modal - Scheduling Link - Copy Link",
        COPY_TIMES: "Shareable Proposal - Modal - Scheduling Link - Copy Times",
      },
    },
    VIEW: {
      RENDERED: "Shareable Proposal - View - Rendered",
      CONFIRM: "Shareable Proposal - Confirm",
      DECLINE: "Shareable Proposal - Decline",
      NO_TIME_WORKS: "Shareable Proposal - No Time Works",
      SELECT_OPTION_CLICKED: "Shareable Proposal - Select Option Clicked",
    },
    PUBLIC_VIEW: {
      VIEWED: "Shareable Proposal - Public View - Viewed",
      GOOGLE_SIGNIN: "Shareable Proposal - Public View - Google Signin",
      LOGIN_FAILURE: "Shareable Proposal - Public View - Login Failure",
      GOOGLE_FAILURE: "Shareable Proposal - Public View - Google Failure",
    },
  },
  AI_EXTENSION_CTA: {
    MODAL_SHOWN: "AI Extension CTA - Modal Shown",
    GO_TO_BUTTON_CLICKED: "AI Extension CTA - Button Clicked - Go to AI Page",
    DISMISS_BUTTON_CLICKED: "AI Extension CTA - Button Clicked - Dismiss",
    SCHEDULE_WITH_AI_CLICKED:
      "AI Extension CTA - User Clicked On Schedule with AI Below - Dismissed Modal",
  },
  AI_WEBAPP_CTA: {
    MODAL_SHOWN: "AI Webapp CTA - Modal Shown",
    GO_TO_BUTTON_CLICKED: "AI Webapp CTA - Button Clicked - Go to AI Page",
    DISMISS_BUTTON_CLICKED: "AI Webapp CTA - Button Clicked - Dismiss",
    PLANNER_BANNER_CLICKED: "AI Webapp CTA - Planner Banner Clicked",
    PRISM_BANNER_CLICKED: "AI Webapp CTA - Prism Banner Clicked",
    PRISM_BANNER_DISMISSED: "AI Webapp CTA - Prism Banner Dismissed",
  },
  QUICK_RESCHEDULE: {
    CONFIRMED: "Quick Reschedule - Confirmed",
    FIND_MORE_TIMES: "Quick Reschedule - Find More Times",
    PROPOSE_NEW_TIME: "Quick Reschedule - Propose New Time",
    SELECTED: "Quick Reschedule - Selected",
  },
  QUICK_RESCHEDULE_AI_SCHEDULER: {
    CONFIRMED: "Quick Reschedule - AI Scheduler - Confirmed",
    FIND_MORE_TIMES: "Quick Reschedule - AI Scheduler - Find More Times",
    SELECTED: "Quick Reschedule - AI Scheduler - Selected",
  },
  CALENDAR_SINGLE_DAY: {
    VIEWED: "AI.Calendar.SingleDay.Viewed",
    SPLIT_VIEW: "AI.Calendar.SplitView.Viewed",
  },
  DIRECT_MANIPULATION: {
    NEW_EVENT_CARD: {
      SAVE_BUTTON: {
        CLICKED: "NewEventCard.SaveButtonClicked",
      },
      SAVE_AND_FIX_BUTTON: {
        CLICKED: "NewEventCard.SaveAndFixButtonClicked",
      },
      DELETE_BUTTON: {
        CLICKED: "NewEventCard.DeleteButtonClicked",
      },
      UPDATED_TIME: "NewEventCard.UpdatedTime",
      UPDATED_FLEXIBILITY: "NewEventCard.UpdatedFlexibility",
    },
    CALENDAR: {
      CLICK_TO_CREATE: "Calendar.ClickedToCreate",
      CLICK_DRAG_TO_CREATE: "Calendar.ClickDraggedToCreate",
      CLICK_TO_EXPAND: "Calendar.ClickedToExpand",
    },
    RESCHEDULE_MODAL: {
      TRADEOFF: {
        BLOCK_EXPANDED: "RescheduleModal.TradeoffBlockExpanded",
        REMOVED: "RescheduleModal.TradeoffRemoved",
      },
      SAVE_AND_FIX_BUTTON: {
        CLICKED: "RescheduleModal.SaveAndFixButtonClicked",
      },
      SAVE_BUTTON: {
        CLICKED: "RescheduleModal.SaveButtonClicked",
      },
      FIND_MORE_TIMES_BUTTON: {
        CLICKED: "RescheduleModal.FindMoreTimesButtonClicked",
      },
    },
  },
  PROPOSAL_OPTIONS_OVERLAY: {
    TOGGLED_ON: "ProposalOptionsOverlay.ToggledOn",
    TOGGLED_OFF: "ProposalOptionsOverlay.ToggledOff",
    OPTION_CLICKED: "ProposalOptionsOverlay.OptionClicked",
  },
  PRISM_EDUCATION_MODAL: {
    DISMISSED: "PrismEducationModal.Dismissed",
    STEP_FORWARD: "PrismEducationModal.StepForward",
    STEP_BACK: "PrismEducationModal.StepBack",
  },
  PMF_SURVEY: {
    EXIT_DIALOG: "PMFSurvey.ExitDialog",
    EXIT_MODAL: "PMFSurvey.ExitModal",
    EMPTY_SUBMIT: "PMFSurvey.EmptySubmit",
    INITIAL_SUBMIT: "PMFSurvey.InitialQuestionSubmit",
    FREEFORM_SUBMIT: "PMFSurvey.FreeformQuestionsSubmit",
  },
  NUX_2025: {
    HAS_SEEN_NUX_2025: "Nux2025.HasSeenNux2025",
    TOOLTIP_FLOW: {
      TOOLTIP_STEP: "Nux2025.TooltipFlow.TooltipStep",
      COMPLETED: "Nux2025.TooltipFlow.Completed",
    },
    SUPERPOWERS_CHECKLIST: {
      DISMISSED: "Nux2025.SuperpowersChecklist.Dismissed",
      ALL_COMPLETED: "Nux2025.SuperpowersChecklist.AllCompleted",
      SCHEDULING_CLICKED: "Nux2025.SuperpowersChecklist.SchedulingClicked",
      SCHEDULING_MODAL_DISMISSED: "Nux2025.SuperpowersChecklist.SchedulingModal.Dismissed",
      SCHEDULING_MODAL_ACTION_CLICKED: "Nux2025.SuperpowersChecklist.SchedulingModal.ActionClicked",
      RESCHEDULING_CLICKED: "Nux2025.SuperpowersChecklist.ReschedulingClicked",
      RESCHEDULING_MODAL_DISMISSED: "Nux2025.SuperpowersChecklist.ReschedulingModal.Dismissed",
      RESCHEDULING_MODAL_ACTION_CLICKED:
        "Nux2025.SuperpowersChecklist.ReschedulingModal.ActionClicked",
      CALENDAR_PREFERENCES_CLICKED: "Nux2025.SuperpowersChecklist.CalendarPreferencesClicked",
      CALENDAR_PREFERENCES_MODAL_DISMISSED:
        "Nux2025.SuperpowersChecklist.CalendarPreferencesModal.Dismissed",
      CALENDAR_PREFERENCES_MODAL_ACTION_CLICKED:
        "Nux2025.SuperpowersChecklist.CalendarPreferencesModal.ActionClicked",
      VIDEO_STARTED: "Nux2025.SuperpowersChecklist.VideoStarted",
      VIDEO_COMPLETED: "Nux2025.SuperpowersChecklist.VideoEnded",
    },
    UNVISITED_LINKS: {
      UNVISITED_BADGES_SHOWN: "Nux2025.UnvisitedLinks.UnvisitedBadgesShown",
      LINKS_VISITED: "Nux2025.UnvisitedLinks.LinksVisited",
      FLEXIBILITY_VISITED: "Nux2025.UnvisitedLinks.FlexibilityVisited",
      IDEAL_DAY_VISITED: "Nux2025.UnvisitedLinks.IdealDayVisited",
    },
  },
};

// events that should be treated as DAU
export const DauEvents = [
  TrackingEvents.AUTH.BEGIN_LOGIN,
  TrackingEvents.AUTH.LOGIN,
  TrackingEvents.AUTH.USER_CREATED,
  TrackingEvents.CHROME_EXTENSION.OPEN_MANUAL,
  TrackingEvents.CHROME_EXTENSION.INLINE_AUTOPILOT_TOGGLE,
  TrackingEvents.CHROME_EXTENSION.INLINE_AUTOPILOT_EXPAND_SETTINGS,
  TrackingEvents.CHROME_EXTENSION.INLINE_AUTOPILOT_CHANGE_ROOM_FLEX,
  TrackingEvents.CHROME_EXTENSION.INLINE_AUTOPILOT_CHANGE_TIME_FLEX,
  TrackingEvents.DAY_VIEW.CLICK_EVENT,
  TrackingEvents.DAY_VIEW.PICK_DATE,
  TrackingEvents.DAY_VIEW.PICK_NEXT_DAY,
  TrackingEvents.DAY_VIEW.PICK_PREV_DAY,
  TrackingEvents.DAY_VIEW.PICK_TODAY,
  TrackingEvents.EVENT.COPY_FOCUS_TIME_EVENT,
  TrackingEvents.EVENT.MODIFY_IGNORE,
  TrackingEvents.SCHEDULING.FEED_SCHEDULER_EXPANDED,
  TrackingEvents.SCHEDULING.HOVERED_SUGGESTED_TIME,
  TrackingEvents.SCHEDULING.INLINE_RESCHEDULE_CLICKED,
  TrackingEvents.SCHEDULING.RESCHEDULING_RANGE_CHANGED,
  TrackingEvents.SCHEDULING.SELECTION_SCHEDULER_EXPANDED,
  TrackingEvents.SCHEDULING.RESCHEDULER_STARTED,
  TrackingEvents.SETTINGS.DATA_SOURCE_ADD,
  TrackingEvents.SETTINGS.SETTINGS_DIALOG_OPEN,
  TrackingEvents.SETTINGS.SHADOW_CALENDAR_ENABLED,
  TrackingEvents.SETTINGS.UPDATE_PREFERRED_ROOMS,
  TrackingEvents.SUGGESTIONS.ACTION_CLICKED,
  TrackingEvents.TEAM_HEALTH.DASHBOARD_ADD_TEAMMATE,
  TrackingEvents.TEAM_HEALTH.HEALTH_AVATAR_HOVER,
  TrackingEvents.TEAM_HEALTH.SUMMARY_GO_TO_DASHBOARD,
  TrackingEvents.TEAM_HEALTH.SUMMARY_GO_TO_OWN_PROFILE,
  TrackingEvents.TEAM_HEALTH.SUMMARY_GO_TO_PROFILE,
  TrackingEvents.TEAM_HEALTH.SUMMARY_PAGE_FORWARD,
  TrackingEvents.TEAMS.CLICKED_CHANGE_PRIMARY_TEAM,
  TrackingEvents.TEAMS.CHANGED_PRIMARY_TEAM,
  TrackingEvents.CHROME_MENU.CHROME_MENU_DRAWER_OPEN,
  TrackingEvents.CHROME_MENU.SELECT_TAB,
  TrackingEvents.WEEK_IN_REVIEW.PLAY,
  TrackingEvents.WEEK_IN_REVIEW.DRAG,
];

// date fields in Fresh Sales (must be manually added there first)
export const FreshSalesDateFields = {
  [TrackingEvents.ACTIVE.DAU]: "cf_dau",
  [TrackingEvents.AUTH.LOGIN]: "cf_signed_in",
  [TrackingEvents.AUTH.LOGOUT]: "cf_signed_out",
  [TrackingEvents.AUTH.USER_CREATED]: "cf_signed_up",
  [TrackingEvents.AUTH.USER_DEACTIVATED]: "cf_deactivated",
  [TrackingEvents.CHROME_EXTENSION.CLOSED]: "cf_extension_closed",
  [TrackingEvents.CHROME_EXTENSION.INSTALLED]: "cf_extension_installed",
  [TrackingEvents.CHROME_EXTENSION.OPEN]: "cf_extension_opened",
  [TrackingEvents.CHROME_EXTENSION.OPEN_MANUAL]: "cf_extension_opened_manual",
  [TrackingEvents.COLOR_CATEGORIES.SETTINGS_ENABLED]: "cf_color_settings_enabled",
  [TrackingEvents.EVENT.COPY_FOCUS_TIME_EVENT]: "cf_copy_focus_time_event",
  [TrackingEvents.SCHEDULING.EXECUTED_RESCHEDULING_SUGGESTION]: "cf_rescheduler_completed",
  [TrackingEvents.SCHEDULING.SELECTION_SCHEDULER_SCHEDULED]: "cf_quick_scheduler_completed",
  [TrackingEvents.SCHEDULING.HOVERED_SUGGESTED_TIME]: "cf_suggested_time_hovered",
  [TrackingEvents.SETTINGS.DATA_SOURCE_ADD]: "cf_data_source_added",
  [TrackingEvents.SETTINGS.DATA_SOURCE_SYNC_ON]: "cf_data_source_synced",
  [TrackingEvents.SETTINGS.SETTINGS_DIALOG_OPEN]: "cf_settings_dialog_open",
  [TrackingEvents.SETTINGS.SHADOW_CALENDAR_ENABLED]: "cf_shadow_calendar_toggled_on",
  [TrackingEvents.SETTINGS.SHADOW_CALENDAR_DISABLED]: "cf_shadow_calendar_toggled_off",
  [TrackingEvents.TEAM_HEALTH.HEALTH_AVATAR_HOVER]: "cf_profile_hover",
  [TrackingEvents.TEAM_HEALTH.SUMMARY_GO_TO_PROFILE]: "cf_profile_view",
  [TrackingEvents.TEAM_HEALTH.SUMMARY_GO_TO_OWN_PROFILE]: "cf_profile_view",
  [TrackingEvents.TEAM_HEALTH.SUMMARY_GO_TO_DASHBOARD]: "cf_team_dashboard_view",
  [TrackingEvents.SLACK.ADDED]: "cf_slack_added",
  [TrackingEvents.SUGGESTIONS.ACTION_CLICKED]: "cf_suggestion_action_clicked",
};

type TrackingEvent = {
  type: string;
  id?: string;
  userId?: string;
  anonymousId?: string;
  properties?: any;
};

let pendingActions: TrackingEvent[] = [];

// borrowed from https://stackoverflow.com/questions/23493726/how-to-properly-debounce-ajax-requests
function debounce(action: () => void, waitInterval: number): () => void {
  let timeout: number | undefined = undefined;

  return () => {
    if (timeout) {
      window.clearTimeout(timeout);
    }
    timeout = window.setTimeout(action, waitInterval);
  };
}

const flush = () => {
  const actionsToSend = pendingActions;
  const body = JSON.stringify(actionsToSend);
  pendingActions = [];

  const requestInit = getAuthedRequestInitForFetch();
  (requestInit.headers as Headers).append("Content-Type", "application/json");
  requestInit.method = "POST";
  requestInit.body = body;
  if (typeof fetch !== "undefined") {
    void fetch(`${getApiUrl()}/api/track`, requestInit);
  }
};

export const getAnonymousId = () => {
  let anonymousId = analyticsPre.get();
  // try our pre cookie if ls is not set
  if (!anonymousId) {
    const preCookie = getPreCookie();
    // Use the cookie's id
    anonymousId = preCookie.id;
    analyticsPre.set(anonymousId);
  }

  return anonymousId;
};

export const getUserId = () => {
  return analyticsUser.get();
};

export const getAnalyticsId = () => {
  // prefer the user
  return getUserId() || getAnonymousId();
};

const debouncedFlush = debounce(flush, 1000);

export const trackEvent = (trackingEvent: TrackingEvent) => {
  if (getUserId()) {
    trackingEvent.userId = getUserId() as string;
  }
  trackingEvent.anonymousId = getAnonymousId();
  pendingActions.push(trackingEvent);
  debouncedFlush();
};

const trackEventNoDebounce = (trackingEvent: TrackingEvent) => {
  if (getUserId()) {
    trackingEvent.userId = getUserId() as string;
  }
  trackingEvent.anonymousId = getAnonymousId();
  pendingActions.push(trackingEvent);
  flush();
};

export const page = (name: string, properties?: { [k: string]: any }) => {
  if (!shouldTrack()) {
    return;
  }

  trackEvent({
    type: "Screen", // this causes services to fire a Page event!
    id: name,
    properties: properties,
  });
};

// Optional endDate allows to build a tracking event that only runs up to a given date. Usuful for A/B testing
// tracking events, or tracking events only needed at the start of a new feature or project.
export const track = (
  trackingString: string,
  properties?: { [k: string]: any },
  endDate?: Date,
  noDebounce = false,
) => {
  if (endDate && new Date().getTime() > endDate.getTime()) {
    return;
  }

  if (!shouldTrack()) {
    return;
  }

  // if this event is a Fresh Sales Date Field (and logged in), identify it
  // const authed = getReduxStore().getState().auth.authed;
  const authed = !!getUserId();

  if (FreshSalesDateFields.hasOwnProperty(trackingString) && authed) {
    const freshSalesField = FreshSalesDateFields[trackingString];
    const freshSalesDate = new ZonedMoment().format();
    identify({ [freshSalesField]: freshSalesDate });
  }

  if (noDebounce) {
    trackEventNoDebounce({
      type: "TrackEvent",
      id: trackingString,
      properties: properties,
    });
  } else {
    trackEvent({
      type: "TrackEvent",
      id: trackingString,
      properties: properties,
    });
  }

  // if a dau event, send separate dau track
  if (DauEvents.indexOf(trackingString) > -1) {
    track(TrackingEvents.ACTIVE.DAU, { ...properties, dauEvent: trackingString });
  }
};

export const identify = (traits?: { [k: string]: any }) => {
  // ignore switch user
  if (!shouldTrack()) {
    return;
  }

  trackEvent({
    type: "Identify",
    properties: traits,
  });
};

export const alias = (traits?: { [k: string]: any }) => {
  if (!shouldTrack()) {
    return;
  }

  // only alias if we have a difference in ids (and are able to set anonymous id in local storage)
  const curId = getAnalyticsId();
  const preId = getAnonymousId();
  if (!analyticsPre.get() || curId === preId) {
    return;
  }

  trackEvent({
    type: "Alias",
    id: curId,
    properties: traits,
  });
};

export const TrackingContext = createContext<typeof track>((trackingString) => {
  console.warn(`
    \`${trackingString}\` event has not been recorded!

    TrackingContext.Provider has not yet injected a 'track' function into the context. Please ensure
    that you have the provider added somewhere above this component in the tree.
  `);
});

export function useTracking() {
  return useContext(TrackingContext);
}

/**
 * Capture a pageview based on the rendering of a component in the page.  Will only fire once
 * per component mount.
 */
export function useTrackPageview(...args: Parameters<typeof track>) {
  const trackEvent = useTracking();

  useEffect(() => {
    trackEvent(...args);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

// A hook that returns a wrapped track function that only fires once.
export function useTrackOnce(trackingEvent: Parameters<typeof track>[0]) {
  const trackEvent = useTracking();
  const [firedTrackEvent, setFiredTrackEvent] = useState(false);

  return useCallback(
    (properties?: Parameters<typeof track>[1]) => {
      if (!firedTrackEvent) {
        trackEvent(trackingEvent, properties);
        setFiredTrackEvent(true);
      }
    },
    [firedTrackEvent, trackEvent, trackingEvent],
  );
}

export const getInitialAuthTrackingFunnel = () => {
  if (!window?.location?.pathname || !window?.location?.href) {
    logger.error("failed to find window.location.pathname");
    sessionStorage.setItem("loginFunnel", "unknown_funnel");
    return "unknown_funnel";
  }
  const firstRouteValue = window.location.pathname.split("/");
  if (window.location.href.includes("slack")) {
    sessionStorage.setItem("loginFunnel", "slack_funnel");
    return "slack_funnel";
  }
  if (window?.location?.href.includes("m365")) {
    sessionStorage.setItem("loginFunnel", "m365_funnel");
    return "m365_funnel";
  }
  if (window.location.href.includes("scheduling-links")) {
    sessionStorage.setItem("loginFunnel", "link_funnel");
    return "link_funnel";
  }
  if (window.location.href.includes("cwinstall") || window.location.href.includes("chrome.html")) {
    sessionStorage.setItem("loginFunnel", "chrome_funnel");
    return "chrome_funnel";
  }
  if (window.location.href.includes("uninstall")) {
    sessionStorage.setItem("loginFunnel", "uninstall_funnel");
    return "uninstall_funnel";
  }
  if (
    firstRouteValue[1] === "login" ||
    firstRouteValue[1] === "signup" ||
    firstRouteValue[1] === "welcome" ||
    firstRouteValue[1] === "welcome-sign-in"
  ) {
    sessionStorage.setItem("loginFunnel", "web_funnel");
    return "web_funnel";
  }
  if (firstRouteValue[1] === "p") {
    sessionStorage.setItem("loginFunnel", "shared_proposal_funnel");
    return "shared_proposal_funnel";
  }
  sessionStorage.setItem("loginFunnel", "unknown_funnel");
  return "unknown_funnel";
};

// This is the step that the "click login button" is at.
// We can add from there to determine steps for registration, org creation and succesful login
const stepToFunnelMap = {
  link_funnel: 4,
  chrome_funnel: 3,
  slack_funnel: 3,
  web_funnel: 2,
  m365_funnel: 2,
  shared_proposal_funnel: 2,
  unknown_funnel: 2,
  uninstall_funnel: 2,
};

export type TrackingFunnel = ReturnType<typeof getInitialAuthTrackingFunnel>;

export const getStepBasedOnTrackingFunnel = (
  trackingFunnel: TrackingFunnel,
  step: "click_to_oauth" | "logged_in" | "signed_up" | "org_created",
) => {
  const lowestStep = stepToFunnelMap[trackingFunnel] || 0;
  switch (step) {
    case "click_to_oauth":
      return String(lowestStep);
    case "logged_in":
      return String(lowestStep + 1);
    case "signed_up":
      return String(lowestStep + 2);
    case "org_created":
      return String(lowestStep + 3);
    default:
      return String(lowestStep);
  }
};

export const getStoredAuthTrackingFunnel = () => {
  return sessionStorage.getItem("loginFunnel") || "unknown_funnel";
};
