import { ISettingsAction, SettingsActions } from "#webapp/src/state/actions/settings.actions";

export interface ISettingsState {
  expandAccount: boolean;
  expandMeetingBreaks: boolean;
  expandTeam: boolean;
  expandFeatures: boolean;
  expandColorCoding: boolean;
  lastRoomSettingsMillis: number;
  expandInvites: boolean;
  expandExperiments: boolean;
  expandPersonalCalendar: boolean;
  expandTravelTime: boolean;
  expandFocusTime: boolean;
  expandEmailsAndNotifications: boolean;
  expandOrgSettings: boolean;
  expandPlansAndBillingSettings: boolean;
  requiresSaveHours: boolean;
  triggerSaveHoursMillis: number;
  requiresSaveFocusTimeSync: boolean;
  triggerSaveFocusTimeSyncMillis: number;
  requiresSaveLunchSync: boolean;
  triggerSaveLunchSyncMillis: number;
  requiresSaveTravelTimeSync: boolean;
  triggerSaveTravelTimeSyncMillis: number;
  pendingSendInvite: string;
  teamSettingsId: string;
  teamSettingsSubTabIndex: number;
  teamSettingsCreateOrJoin: string;
  teamSettingsFeature: string;
  savedOnboardingChecklistSyncType: "";
}

export const initialExpansionStates = {
  expandAccount: false,
  expandHours: false,
  expandMeetingBreaks: false,
  expandTeam: false,
  expandFeatures: false,
  expandColorCoding: false,
  expandRooms: false,
  expandInvites: false,
  expandExperiments: false,
  expandPersonalCalendar: false,
  expandTravelTime: false,
  expandFocusTime: false,
  expandEmailsAndNotifications: false,
  expandOrgSettings: false,
  expandPlansAndBillingSettings: false,
};

export const initialSettingsState: ISettingsState = {
  lastRoomSettingsMillis: 0,
  requiresSaveHours: false,
  triggerSaveHoursMillis: 0,
  requiresSaveFocusTimeSync: false,
  triggerSaveFocusTimeSyncMillis: 0,
  requiresSaveLunchSync: false,
  triggerSaveLunchSyncMillis: 0,
  requiresSaveTravelTimeSync: false,
  triggerSaveTravelTimeSyncMillis: 0,
  pendingSendInvite: "",
  ...initialExpansionStates,
  teamSettingsId: "",
  teamSettingsSubTabIndex: -1,
  teamSettingsFeature: "",
  teamSettingsCreateOrJoin: "",
  savedOnboardingChecklistSyncType: "",
};

export function settingsReducer(state = initialSettingsState, action: ISettingsAction) {
  switch (action.type) {
    case SettingsActions.OpenPersonalCalendar:
      return {
        ...state,
        ...initialExpansionStates,
        expandPersonalCalendar: true,
        expandFeatures: true,
      };
    case SettingsActions.UpdatePreferredRoomsMillis:
      const now = new Date();
      return { ...state, lastRoomSettingsMillis: now.valueOf() };
    case SettingsActions.OpenInvites:
      return { ...state, ...initialExpansionStates, expandInvites: true };
    case SettingsActions.CloseSettingsDrawers:
      return { ...state, ...initialExpansionStates };
    case SettingsActions.SetRequireSaveHours:
      return { ...state, requiresSaveHours: action.data };
    case SettingsActions.SetRequireSaveFocusTimeSync:
      return { ...state, requiresSaveFocusTimeSync: action.data };
    case SettingsActions.TriggerSaveFocusTimeSync:
      return { ...state, triggerSaveFocusTimeSyncMillis: new Date().valueOf() };
    case SettingsActions.SetRequireSaveLunchSync:
      return { ...state, requiresSaveLunchSync: action.data };
    case SettingsActions.TriggerSaveLunchSync:
      return { ...state, triggerSaveLunchSyncMillis: new Date().valueOf() };
    case SettingsActions.SetRequireSaveTravelTimeSync:
      return { ...state, requiresSaveTravelTimeSync: action.data };
    case SettingsActions.TriggerSaveTravelTimeSync:
      return {
        ...state,
        triggerSaveTravelTimeSyncMillis: new Date().valueOf(),
      };
    case SettingsActions.SendInvite:
      return { ...state, pendingSendInvite: action.data };
    case SettingsActions.SetSavedOnboardingChecklistSyncType:
      return { ...state, savedOnboardingChecklistSyncType: action.data };
    default:
      return state;
  }
}
