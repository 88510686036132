/**
 * @generated SignedSource<<dcc97a8ae008fbfb358c68c3c035f862>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DisableDataSourceSyncInput = {
  clientMutationId?: string | null;
  orgRelayId: string;
  sourceCalId: string;
};
export type DisableDataSourceSyncMutation$variables = {
  input: DisableDataSourceSyncInput;
};
export type DisableDataSourceSyncMutation$data = {
  readonly disableDataSourceSync: {
    readonly dataSource: {
      readonly syncTargets: ReadonlyArray<{
        readonly calendarId: string | null;
        readonly emailAddress: string | null;
      } | null> | null;
    } | null;
  } | null;
};
export type DisableDataSourceSyncMutation = {
  variables: DisableDataSourceSyncMutation$variables;
  response: DisableDataSourceSyncMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "CrawlTarget",
  "kind": "LinkedField",
  "name": "syncTargets",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DisableDataSourceSyncMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DisableDataSourceSyncPayload",
        "kind": "LinkedField",
        "name": "disableDataSourceSync",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DataSource",
            "kind": "LinkedField",
            "name": "dataSource",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DisableDataSourceSyncMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DisableDataSourceSyncPayload",
        "kind": "LinkedField",
        "name": "disableDataSourceSync",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DataSource",
            "kind": "LinkedField",
            "name": "dataSource",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "84108825f7ee2f9506096ad5489c3744",
    "id": null,
    "metadata": {},
    "name": "DisableDataSourceSyncMutation",
    "operationKind": "mutation",
    "text": "mutation DisableDataSourceSyncMutation(\n  $input: DisableDataSourceSyncInput!\n) {\n  disableDataSourceSync(input: $input) {\n    dataSource {\n      syncTargets {\n        calendarId\n        emailAddress\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b3be6cef1a1ddbeb51e4d51e03b83e6";

export default node;
