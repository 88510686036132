const getNormalJoinCopy = (teamsLength: number) => {
  const copy =
    teamsLength === 1
      ? "We found a team existing within your organization."
      : "We found several teams within your organization.";
  return (
    copy +
    "Join the team that you most closely identify with, or create a new team if your primary team isn't listed."
  );
};

const getOtherJoinCopy = (teamsLength: number) => {
  let teamCountCopy = "several teams";
  if (teamsLength === 1) {
    teamCountCopy = "one team";
  } else if (teamsLength === 2) {
    teamCountCopy = "two teams";
  }
  const copy = `Your coworkers have added you to ${teamCountCopy} within your organization. `;
  return copy + " Let us know which one you most closely identify with.";
};

export const getCopyForBody = (isCheckout: boolean, isNormalJoin: boolean, teamsLength: number) => {
  if (isCheckout)
    return "Not ready to pay for your entire organization? You can start with upgrading one of your teams.";
  if (isNormalJoin) {
    return getNormalJoinCopy(teamsLength);
  } else {
    return getOtherJoinCopy(teamsLength);
  }
};

export const getCopyForHeader = (isCheckout: boolean, isNormalJoin: boolean) => {
  if (isCheckout) return "Which team would you like to upgrade?";
  if (isNormalJoin) {
    return "Which team are you on?";
  } else {
    return "Which team is your primary team?";
  }
};
