import { logger } from "#webapp/src/util/logger.util";

export enum NavEducationStep {
  Unstarted = "Unstarted",
  IntroModal = "IntroModal",
  FlexMeetingsPage = "FlexMeetingsPage",
  SchedulingLinksPage = "SchedulingLinksPage",
  CalendarPage = "CalendarPage",
  SuperpowerChecklist = "SuperpowerChecklist",
  Completed = "Completed",
}

export const mapOfStatesWithUIOverlay: Record<NavEducationStep, boolean> = {
  Unstarted: false,
  IntroModal: false,
  FlexMeetingsPage: true,
  SchedulingLinksPage: true,
  CalendarPage: true,
  SuperpowerChecklist: false,
  Completed: false,
};

export const NavEducationStepList: NavEducationStep[] = [
  //order matters
  NavEducationStep.Unstarted,
  NavEducationStep.IntroModal,
  NavEducationStep.FlexMeetingsPage,
  NavEducationStep.SchedulingLinksPage,
  NavEducationStep.CalendarPage,
  NavEducationStep.SuperpowerChecklist,
  NavEducationStep.Completed,
];

export const getNextEducationStep = (currentStep: NavEducationStep) => {
  const currentIndex = NavEducationStepList.indexOf(currentStep);
  if (currentStep === NavEducationStep.Completed) {
    // User should not be hitting this function,
    logger.error("User is in Nav EducationFlow, but has already completed it");
    return NavEducationStep.Completed;
  } else if (currentStep === NavEducationStep.Unstarted) {
    // User should not be hitting this function,
    logger.error("User is in Nav EducationFlow, but did not recently do onboarding");
    return NavEducationStep.Unstarted;
  } else {
    return NavEducationStepList[currentIndex + 1];
  }
};
