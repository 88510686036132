import { ApolloQueryResult } from "@apollo/client";
import { ProductTypeEnum, TrialMetadata } from "@clockwise/schema";
import { TrialProBadgeType } from "@clockwise/web-commons/src/ui/trial-pro-badge-with-tooltip";
import { DateTime } from "luxon";
import { MonetizationContextData } from "./useMonetization";
// NOTE: Ideally the exports in this file should only be called by the useMonetization hook,
// please note that the two files are very heavily linked

export type BadgeCopyType = "Group Links" | "Team Availability" | "NMD";

export type NewLink = { schedulingLink: { active: boolean; linkMembers: unknown[] } };
export type OldLink = { meetingSettings: { isActive: boolean; linkMembers: unknown[] } };
export type SchedulingLinkForActiveCheck = NewLink | OldLink;
export type SchedulingLinksForActiveCheck = NewLink[] | OldLink[];

// state should be as pre-M2 user would see/use our app
export const emptyMonetizationPayload: MonetizationContextData = {
  canTeamAvailabilityAndNMDBeEnabled: true,
  canUserMakeGroupLinks: true,
  canUserMarkLinkAsActive: () => true,
  canUserSeeLinksTeamsBadge: false,
  daysLeftInTrial: null,
  getCopyForMonetizationBadges: () => undefined,
  showLinksMonetizationBanner: false,
  showRoundRobinMonetizationBanner: false,
  canTeamHealthAnalyticsBeSeen: true,
  shouldUserSeeJoinExistingPlan: false,
  canUserAccessFlexMeetings: true,
  primaryBillingGroupId: "",
  isFeatureGridFetched: false,
  usersProductType: "Free",
  refetchFeatureGrid: () => Promise.resolve({} as ApolloQueryResult<any>),
  canUserSeeTrialExtension: false,
  canUserHaveFocusTimeHolds: true,
  isFiveDaysPastTrial: false,
  userIsOnActiveTrial: false,
  showPlanStatusCalloutChrome: false,
  trialExpirationStatus: {
    timeLeftInTrial: null,
    isTrialExpired: false,
  },
  shouldUserSeePricingPagePlansAndBilling: true,
};

export const getDaysLeftInTrial = (
  trialMetadata: TrialMetadata | null,
  lastFocusTabDate: DateTime,
  userHasPaidPlan: boolean,
) => {
  if (!trialMetadata || userHasPaidPlan) {
    return null;
  }
  const durationLeftOnTrial = getCurrentDurationRemainingOnTrial(trialMetadata, lastFocusTabDate);
  return durationLeftOnTrial && durationLeftOnTrial.days
    ? Math.ceil(durationLeftOnTrial.days)
    : null;
};

const getCurrentDurationRemainingOnTrial = (
  trialMetadata: TrialMetadata | null,
  lastFocusTabDate: DateTime,
) => {
  if (!trialMetadata || !trialMetadata.timeZone || !trialMetadata.trialEndDate) {
    return null;
  }
  // To keep the time remaining up to date if a user has their browser open for a really long time, we compare the lastFocusTabDate,
  // and update this date when the user refocuses
  let currentDateTime = lastFocusTabDate.setZone(trialMetadata.timeZone);
  if (!currentDateTime.isValid) {
    currentDateTime = lastFocusTabDate.setZone("US/Mountain");
  }
  const expirationDateTime = DateTime.fromFormat(trialMetadata.trialEndDate, "yyyy-MM-dd");
  return expirationDateTime.diff(currentDateTime, ["days"]);
};

export const getCanUserMarkLinkAsActive = ({
  userHasPaidPlan,
  userIsOnActiveTrial,
  linkType,
}: {
  userHasPaidPlan: boolean;
  userIsOnActiveTrial: boolean;
  linkType: "Group" | "1:1";
}) => {
  if (userIsOnActiveTrial || userHasPaidPlan) {
    return true;
  } else {
    // free user cannot enable group links
    return linkType !== "Group";
  }
};

type ProductTypes =
  | "Enterprise_NonStandard"
  | "Enterprise_Standard"
  | "Business_NonStandard"
  | "Business_Standard"
  | "Pro_NonStandard"
  | "Pro_Standard"
  | "Free";

export const getSimplifiedUserProductType = (productType: ProductTypes | null | undefined) => {
  switch (productType) {
    case ProductTypeEnum.Enterprise_NonStandard:
      return "Enterprise";
    case ProductTypeEnum.Enterprise_Standard:
      return "Enterprise";
    case ProductTypeEnum.Business_NonStandard:
      return "Business";
    case ProductTypeEnum.Business_Standard:
      return "Business";
    case ProductTypeEnum.Pro_NonStandard:
      return "Teams";
    case ProductTypeEnum.Pro_Standard:
      return "Teams";
    default:
      return "Free";
  }
};

export const getCopyForMonetizationBadgesUtil = ({
  userHasPaidPlan,
  userIsOnActiveTrial,
  badgeType,
}: {
  userHasPaidPlan: boolean;
  userIsOnActiveTrial: boolean;
  badgeType: BadgeCopyType;
}) => {
  if (userHasPaidPlan) return undefined;
  if (userIsOnActiveTrial) {
    switch (badgeType) {
      case "Group Links":
        return TrialProBadgeType.GroupLinksEnabled;
      case "Team Availability":
        return TrialProBadgeType.TeamAvailabilityEnabled;
      case "NMD":
        return TrialProBadgeType.NMDEnabled;
      default:
        return undefined;
    }
  } else {
    switch (badgeType) {
      case "Group Links":
        return TrialProBadgeType.GroupLinksExpired;
      case "Team Availability":
        return TrialProBadgeType.TeamAvailabilityExpired;
      case "NMD":
        return TrialProBadgeType.NMDExpired;
      default:
        return undefined;
    }
  }
};
