// schema
import * as ISchema from "#webapp/src/__schema__";
import { timeSlots } from "#webapp/src/constants/time-slots.constant";
import { isEqual } from "lodash";
import moment from "moment-timezone";

// actions

// util

// queries

//////////////////
// TYPES
//////////////////
// one-to-one with GraphQL input types for query
export interface IScoreNewEventInput {
  orgRelayId: string;
  timeZone: string;
  rangeMin: ISchema.IDayTimeSlotInput;
  rangeMax: ISchema.IDayTimeSlotInput;
  duration: number;
  roomNeeded: boolean;
  attendees: string[];
}

export enum EScheduleRange {
  NextTwo = "NextTwoDays",
  Today = "Today",
  Tomorrow = "Tomorrow",
  ThisWeek = "ThisWeek",
  NextSeven = "NextSevenDays",
  NextWeek = "NextWeek",
  CurrentView = "CurrentView",
  AroundDate = "AroundDate",
  SpecificDate = "SpecificDate",
  SevenBeforeAndAfter = "SevenBeforeAndAfter",
}

interface IMinMax {
  rangeMin: ISchema.IDayTimeSlotInput;
  rangeMax: ISchema.IDayTimeSlotInput;
}

//////////////////
// INTERNAL HELPERS
//////////////////

// create a min/max range
function createRangeOfDays(startDay: moment.Moment, numDays: number) {
  const rangeMin = {
    date: startDay.clone().format("YYYY-MM-DD"),
    timeSlot: timeSlots.all[0],
  };
  const rangeMax = {
    date: startDay.clone().add(numDays, "days").format("YYYY-MM-DD"),
    timeSlot: timeSlots.all[timeSlots.all.length - 1],
  };
  return { rangeMin, rangeMax };
}

export function getMaxMinSlotForRange(range: EScheduleRange, inputDate?: moment.Moment): IMinMax {
  const date = inputDate || moment();
  const isLate = date.hour() > 15; // TODO: use working hours
  let numDays = isLate ? 3 : 2;

  // otherwise switch on the range enum
  switch (range) {
    case EScheduleRange.Today:
    case EScheduleRange.SpecificDate:
      return createRangeOfDays(date, 0) as any; // FIXME: TEMP FIX FOR GQL2TS ISSUES
    case EScheduleRange.Tomorrow:
      return createRangeOfDays(date.clone().add(1, "day"), 0) as any; // FIXME: TEMP FIX FOR GQL2TS ISSUES
    case EScheduleRange.ThisWeek:
      numDays = 6 - date.day();
      return createRangeOfDays(date, numDays) as any; // FIXME: TEMP FIX FOR GQL2TS ISSUES
    case EScheduleRange.NextWeek:
      numDays = 6 - date.day();
      return createRangeOfDays(date.add(1, "weeks").startOf("isoWeek"), 6) as any; // FIXME: TEMP FIX FOR GQL2TS ISSUES
    case EScheduleRange.AroundDate:
      return createRangeOfDays(date.clone().subtract(1, "day"), 2) as any; // FIXME: TEMP FIX FOR GQL2TS ISSUES
    case EScheduleRange.NextSeven:
      let addedDays = 7;
      if (date.day() === 0 || date.day() === 6) {
        // we're on Saturday or Sunday, add an extra day
        // arguably, Saturday should add 2 full days...
        addedDays = isLate ? addedDays + 2 : addedDays + 1;
      } else if (date.day() === 4 || date.day() === 5) {
        // we're on Thursday or Friday, so push through the weekend
        // Thursday +9 -> Sat
        // Friday +9 -> Sun
        addedDays += 2;
      }
      return createRangeOfDays(date, addedDays) as any; // FIXME: TEMP FIX FOR GQL2TS ISSUES
    case EScheduleRange.SevenBeforeAndAfter:
      // Note that this makes a 14 day range with 7 days looking ahead
      return createRangeOfDays(date.subtract(6, "days"), 13) as any;
    case EScheduleRange.NextTwo:
    default:
      if (date.day() === 0 || date.day() === 6) {
        // we're on Saturday or Sunday, add an extra day
        // arguably, Saturday should add 2 full days...
        numDays++;
      } else if (date.day() === 4 || date.day() === 5) {
        // we're on Thursday or Friday, so push through the weekend
        numDays += 2;
      }
      return createRangeOfDays(date, numDays) as any; // FIXME: TEMP FIX FOR GQL2TS ISSUES
  }
}

//////////////////
// EXPORTED UTIL
//////////////////

export function slotsEqual(
  slot1: ISchema.ISchedulingSlot | undefined,
  slot2: ISchema.ISchedulingSlot | undefined,
) {
  if (!slot1 && !slot2) {
    return true;
  }

  if (!slot1 || !slot2) {
    return false;
  }

  const startTimeEqual = isEqual(
    slot1?.timeRange.startTime.dateOrDateTime,
    slot2?.timeRange.startTime.dateOrDateTime,
  );
  const endTimeEqual = isEqual(
    slot1?.timeRange.endTime.dateOrDateTime,
    slot2?.timeRange.endTime.dateOrDateTime,
  );
  const roomsEqual = isEqual(slot1?.roomImpacts?.[0]?.roomId, slot2?.roomImpacts?.[0]?.roomId);

  return startTimeEqual && endTimeEqual && roomsEqual;
}
