import Snackbar from "@material-ui/core/Snackbar";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React from "react";
import { connect } from "react-redux";

// components
import { SnackBarContent } from "./snack-bar-content";
import { SnackBarContentVariant } from "./snack-bar-content/snack-bar-content";

// state
import { snackBarClose } from "#webapp/src/state/actions/snack-bar.actions";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";

// internals
import { styles } from "./snack-bar.styles";

interface IProps extends WithStyles<typeof styles> {
  variant: SnackBarContentVariant | undefined;
  dismissable: boolean | undefined;
  open: boolean;
  message: string;
  actionLabel: string | undefined;
  dismissCallback: (() => void) | undefined;
  actionCallback: (() => void) | undefined;
  snackBarClose: typeof snackBarClose;
  autoHideDuration: number;
  isChromeExtension: boolean;
  chromeExtensionChromeDialogOpen: boolean;
}

class SnackBarComponent extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  ///////////////
  // Listeners //
  ///////////////

  private onClose = (_event?: React.SyntheticEvent<any>, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.props.snackBarClose();
    if (this.props.dismissCallback) {
      this.props.dismissCallback();
    }
  };

  ///////////////
  // Rendering //
  ///////////////

  public render() {
    const showChromeContainer =
      this.props.isChromeExtension && !this.props.chromeExtensionChromeDialogOpen;

    return (
      <Snackbar
        className={this.props.classes[showChromeContainer ? "chromeContainer" : "container"]}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: showChromeContainer ? "right" : "center",
        }}
        open={this.props.open}
        autoHideDuration={this.props.autoHideDuration}
        onClose={this.onClose}
      >
        <SnackBarContent
          dismissable={this.props.dismissable}
          isChromeExtension={showChromeContainer}
          variant={this.props.variant}
          message={this.props.message}
          actionLabel={this.props.actionLabel}
          onAction={this.props.actionCallback}
          onClose={this.onClose}
        />
      </Snackbar>
    );
  }
}

/////////////////
// With Styles //
/////////////////

export const SnackBarStyledComponent = withStyles(styles)(SnackBarComponent);

///////////
// Redux //
///////////

function mapStateToProps(state: IReduxState) {
  return {
    variant: state.snackBar.variant,
    dismissable: state.snackBar.dismissable,
    open: state.snackBar.open,
    message: state.snackBar.message,
    actionLabel: state.snackBar.actionLabel,
    dismissCallback: state.snackBar.dismissCallback,
    actionCallback: state.snackBar.actionCallback,
    autoHideDuration: state.snackBar.autoHideDuration,
    isChromeExtension: state.webExtension.isWebExtension,
    chromeExtensionChromeDialogOpen: state.webExtension.webExtensionDialogOpen,
  };
}

export const SnackBar = connect(mapStateToProps, { snackBarClose })(SnackBarStyledComponent);
