import { last } from "lodash";
import { DateTime } from "luxon";
import { CalendarMode } from "../components/calendar";
import { getPreviousWeekday } from "./getPreviousWeekday";
import { getSequentialDays } from "./getSequentialDays";
import { toISODateString } from "./toISODateString";

export type WeekStartDays = "sunday" | "monday";

export type CalendarState = {
  focusedDate: string;
  visibleDates: string[];
  weekendsShown: boolean;
  weekStartDay: WeekStartDays;
  anchorDate: string | null;
};

export type CalendarAction =
  | { type: "jumpTo-date"; payload: string }
  | { type: "jumpTo-weekOf"; payload: string }
  | { type: "set-view"; payload: { view: CalendarMode; date: string } }
  | { type: "set-weekendsShown"; payload: boolean }
  | { type: "set-weekStartDay"; payload: WeekStartDays }
  | { type: "step-backward" }
  | { type: "step-forward" }
  | { type: "set-anchor-date"; payload: string | null };

export const calendarReducer = (state: CalendarState, action: CalendarAction) => {
  switch (action.type) {
    case "jumpTo-date":
      return jumpToDate(state, action.payload);
    case "jumpTo-weekOf":
      return jumpToWeekOf(state, action.payload);
    case "set-view":
      return setView(state, action.payload);
    case "set-weekendsShown":
      return setWeekendsShown(state, action.payload);
    case "set-weekStartDay":
      return setWeekStartDay(state, action.payload);
    case "step-backward":
      return step(state, -1);
    case "step-forward":
      return step(state, 1);
    case "set-anchor-date":
      return setAnchorDate(state, action.payload);
    default:
      return state;
  }
};

const setAnchorDate = (state: CalendarState, payload: string | null) => {
  return {
    ...state,
    anchorDate: payload,
  };
};

const calculateVisibleDates = ({
  date,
  weekendsShown,
  view,
}: {
  date: string;
  weekendsShown: boolean;
  view: CalendarMode;
}) => {
  const dateTime = DateTime.fromISO(date);

  if (view === "day") {
    return [date];
  }

  const startOfWeek = getPreviousWeekday(dateTime, "sunday");
  const sequentialDays = getSequentialDays(startOfWeek, 7).map((date) => date.toISODate());

  return weekendsShown ? sequentialDays : sequentialDays.slice(1, 6);
};

const setView = (state: CalendarState, payload: { view: CalendarMode; date: string }) => {
  switch (payload.view) {
    case "day":
      return {
        ...state,
        focusedDate: payload.date,
        visibleDates: [payload.date],
      };
    case "week": {
      const visibleDates = calculateVisibleDates({
        date: payload.date,
        weekendsShown: state.weekendsShown,
        view: "week",
      });

      return {
        ...state,
        focusedDate: payload.date,
        visibleDates,
      };
    }
  }
};

const step = (state: CalendarState, stepDirection: 1 | -1) => {
  const view = state.visibleDates.length === 1 ? "day" : "week";
  const stepSize = view === "day" ? 1 : 7;
  const stepDays = stepSize * stepDirection;
  const focusedDateTime = DateTime.fromISO(state.focusedDate).plus({ days: stepDays });
  const visibleDates = calculateVisibleDates({
    date: focusedDateTime.toISODate(),
    weekendsShown: state.weekendsShown,
    view,
  });

  return {
    ...state,
    focusedDate: focusedDateTime.toISODate(),
    visibleDates,
  };
};

const jumpToDate = (state: CalendarState, date: string) => {
  const dateISO = toISODateString(date);
  if (!dateISO) {
    return state;
  }

  return {
    ...state,
    weekendsShown: state.weekendsShown,
    focusedDate: dateISO,
    visibleDates: [date],
  };
};

const jumpToWeekOf = (state: CalendarState, date: string) => {
  const dateISO = toISODateString(date);
  if (!dateISO) {
    return state;
  }

  const visibleDates = calculateVisibleDates({
    ...state,
    date: dateISO,
    view: "week",
  });

  return {
    ...state,
    focusedDate: dateISO,
    visibleDates,
  };
};

const setWeekendsShown = (state: CalendarState, weekendsShown: boolean) => {
  const view = state.visibleDates.length === 1 ? "day" : "week";
  const weekStartDay = (weekendsShown ? "sunday" : "monday") as WeekStartDays;
  const startOfWeek = getPreviousWeekday(
    DateTime.fromISO(last(state.visibleDates) || DateTime.now().toISODate()),
    weekStartDay,
  );

  const visibleDates = calculateVisibleDates({
    date: startOfWeek.toISODate(),
    weekendsShown,
    view,
  });

  return {
    ...state,
    weekStartDay,
    weekendsShown,
    visibleDates,
  };
};

const setWeekStartDay = (state: CalendarState, day: WeekStartDays) => {
  return {
    ...state,
    weekStartDay: day,
  };
};
