import {
  RelayNetworkLayerRequest,
  RelayNetworkLayerResponse,
} from "react-relay-network-modern/node8";

// util
// import { logger } from './logger.util';

type RRNLError = Error;

export interface RRNLRequestError extends RRNLError {
  req: RelayNetworkLayerRequest;
  res?: RelayNetworkLayerResponse;
}

export function isRRNLRequestError(error: any): error is RRNLRequestError {
  return error instanceof Error && error.name === "RRNLRequestError";
}

/**
 * Returns true if the error is a real ready state error.
 *
 * See note in modern-network-layer.ts#L217
 *
 * @param error
 * @param message
 */
export function handleReadyStateError(
  error: Error | null | undefined,
  _message: string,
  _meta?: any,
): boolean {
  if (
    (error && !isRRNLRequestError(error)) ||
    (error && error.res && error.res.status !== undefined)
  ) {
    // logger.error(message, error, meta);
    return true;
  }

  return false;
}
