import { getEnvironment } from "../config/environment";

/**
 * Helper function to only warn outside of test environment.
 *
 * @param message
 */
export function warnOutsideTests(message: string) {
  if (getEnvironment() === "test") {
    return;
  }
  console.warn(message);
}
