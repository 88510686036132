import { graphql } from "react-relay";

export const updateSmartHoldSyncSettingsMutation = graphql`
  mutation UpdateSmartHoldSyncSettingsMutation($input: UpdateSmartHoldSyncSettingsMutationInput!) {
    updateSmartHoldSyncSettings(input: $input) {
      shadowCalendarSettings {
        id
        focusTimeSyncEnabled
        lunchSyncEnabled
        travelTimeSyncEnabled
        focusTimeExternalTransparency
        focusTimeSyncAll
        focusTimeIdealMinutesPerWeek
        focusTimeRemindersEnabled
        travelTimeRemindersEnabled
        lunchRemindersEnabled
      }
    }
  }
`;
