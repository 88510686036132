import { graphql } from "react-relay";

export const removeOrgTagsMutation = graphql`
  mutation RemoveOrgTagsMutation($input: RemoveOrgTagsMutationInput!) {
    removeOrgTags(input: $input) {
      eventParent {
        events {
          id
          autopilotEventStatus {
            __typename

            ... on AutopilotEventStatus {
              status
            }
          }
          annotatedEvent {
            id
            externalEventId
            orgTags {
              tag
              state {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
          annotatedRecurringEvent {
            id
            externalEventId
            orgTags {
              tag
              state {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
        }
      }
    }
  }
`;
