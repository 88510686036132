// IMPORTS
// schema
import * as ISchema from "#webapp/src/__schema__";

import { commitMutation } from "react-relay";
import { Environment } from "relay-runtime";
import { updateSettingMutation } from "./UpdateSetting.gql"; // change

// TYPES
interface IUpdateSettingMutationResponse {
  updateSetting: ISchema.IUpdateSettingMutationPayload;
}

// MUTATION
export function updateSetting(
  environment: Environment,
  input: ISchema.IUpdateSettingMutationInput,
  onSuccess: (response?: ISchema.IUpdateSettingMutationPayload) => void,
  onError: (error?: any) => void,
) {
  commitMutation(environment, {
    onError,
    mutation: updateSettingMutation,
    variables: { input },
    onCompleted: (response: IUpdateSettingMutationResponse, errors?: Error[]) => {
      if (errors) {
        onError(errors[0]);
      } else {
        onSuccess(response.updateSetting);
      }
    },
  });
}
