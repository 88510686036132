import { articleUrls } from "@clockwise/client-commons/src/constants/help-center";
import { Link } from "@clockwise/design-system";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import { schedulingLink404 } from "../../assets/img/illustrations/";
import { paths } from "../../constants/site.constant";
import { LottiePlayer } from "../lottie-player/LottiePlayer";
import brokenClockAnimation from "./images/broken-digital-clock.lottie";

export const NoRouteView = () => {
  const location = useLocation();
  const track = useTracking();

  useEffect(() => {
    track(TrackingEvents.APP_NAV.PAGE_NOT_FOUND, { pathname: location.pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="cw-bg-default cw-w-full cw-h-full cw-pt-20 cw-px-5 sm:cw-px-10 cw-flex cw-flex-col cw-items-center cw-gap-4"
      cw-id="page-not-found"
    >
      <Helmet title="Page Not Found • Clockwise" />
      <LottiePlayer autoplay keepLastFrame src={brokenClockAnimation} />
      <div className="cw-text-center cw-max-w-[375px]">
        <h1 className="cw-heading-3xl cw-text-default cw-mb-5">Page not found!</h1>
        <p className="cw-body-lg cw-text-default">
          Head back to our <Link href={paths.webApp}>home page</Link>, or{" "}
          <Link href={articleUrls.feedback}>contact us</Link> if you can't find what you're looking
          for.
        </p>
      </div>
    </div>
  );
};

export const SchedulingLinkNotFound = () => (
  <div
    className="cw-flex cw-items-center cw-flex-col-reverse md:cw-flex-row cw-px-5 md:cw-px-24 sm:cw-py-20 cw-py-8 md:cw-py-24 cw-min-h-[500px] cw-h-full md:cw-gap-10 cw-gap-7 cw-justify-between cw-max-w-6xl"
    cw-id="link-not-found"
  >
    <div className="cw-flex cw-flex-col cw-justify-center cw-shrink-0 cw-max-w-sm">
      <h1 className="cw-heading-2xl cw-mt-2 cw-mb-4">This Scheduling Link is inactive</h1>
      <h2 className="cw-heading-base cw-mb-2">
        Please contact the link sender to provide a new link
      </h2>

      <br />
      <p className="cw-body-lg">
        If the link sender recently edited their Scheduling Links username, then the link above was
        marked inactive.
        <br />
        <br />
        If you are the owner of this link, you can <Link href={paths.login}>log in</Link> to
        activate this link.
        <br />
        <br />
        If you experience additional issues with this link, you can reach us{" "}
        <Link href={articleUrls.feedback}>here</Link>.
      </p>
    </div>
    <img className="cw-max-w-sm lg:cw-max-w-md cw-w-full" src={schedulingLink404} />
  </div>
);
