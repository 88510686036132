/**
 * @generated SignedSource<<1aeb0910a12d897069ee38c7dc525977>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventTimeType = "Date" | "DateTime" | "%future added value";
export type SubjectUrnTypeEnum = "Email" | "Integration" | "Org" | "Person" | "Phone" | "Services" | "Team" | "User" | "%future added value";
export type RescheduleEventParentMutationInput = {
  clientMutationId?: string | null;
  eventTime: EventTimeInput;
  externalEventId: string;
  orgRelayId: string;
};
export type EventTimeInput = {
  dateOrDateTime: string;
  millis: number;
  offsetMinutes?: number | null;
  timeZone?: string | null;
  type: EventTimeType;
};
export type RescheduleEventParentMutation$variables = {
  input: RescheduleEventParentMutationInput;
};
export type RescheduleEventParentMutation$data = {
  readonly rescheduleEventParent: {
    readonly eventParent: {
      readonly events: ReadonlyArray<{
        readonly id: string;
        readonly startTime: {
          readonly millis: number;
        };
        readonly endTime: {
          readonly millis: number;
        };
        readonly annotatedEvent: {
          readonly id: string;
          readonly externalEventId: string | null;
          readonly orgTags: ReadonlyArray<{
            readonly tag: string;
            readonly state: {
              readonly value: string | null;
              readonly subjectType: SubjectUrnTypeEnum;
              readonly subjectValue: string;
              readonly lastModified: number;
              readonly active: boolean;
            };
          }> | null;
        } | null;
        readonly annotatedRecurringEvent: {
          readonly id: string;
          readonly externalEventId: string | null;
          readonly orgTags: ReadonlyArray<{
            readonly tag: string;
            readonly state: {
              readonly value: string | null;
              readonly subjectType: SubjectUrnTypeEnum;
              readonly subjectValue: string;
              readonly lastModified: number;
              readonly active: boolean;
            };
          }> | null;
        } | null;
      }>;
    };
  } | null;
};
export type RescheduleEventParentMutation = {
  variables: RescheduleEventParentMutation$variables;
  response: RescheduleEventParentMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "millis",
    "storageKey": null
  }
],
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "externalEventId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "OrgTag",
    "kind": "LinkedField",
    "name": "orgTags",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tag",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TagState",
        "kind": "LinkedField",
        "name": "state",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subjectType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subjectValue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastModified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Event",
  "kind": "LinkedField",
  "name": "events",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EventTime",
      "kind": "LinkedField",
      "name": "startTime",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventTime",
      "kind": "LinkedField",
      "name": "endTime",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnnotatedEvent",
      "kind": "LinkedField",
      "name": "annotatedEvent",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnnotatedEvent",
      "kind": "LinkedField",
      "name": "annotatedRecurringEvent",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RescheduleEventParentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RescheduleEventParentMutationPayload",
        "kind": "LinkedField",
        "name": "rescheduleEventParent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventParent",
            "kind": "LinkedField",
            "name": "eventParent",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RescheduleEventParentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RescheduleEventParentMutationPayload",
        "kind": "LinkedField",
        "name": "rescheduleEventParent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventParent",
            "kind": "LinkedField",
            "name": "eventParent",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "239c9301a03f4ece87844010b7c50699",
    "id": null,
    "metadata": {},
    "name": "RescheduleEventParentMutation",
    "operationKind": "mutation",
    "text": "mutation RescheduleEventParentMutation(\n  $input: RescheduleEventParentMutationInput!\n) {\n  rescheduleEventParent(input: $input) {\n    eventParent {\n      events {\n        id\n        startTime {\n          millis\n        }\n        endTime {\n          millis\n        }\n        annotatedEvent {\n          id\n          externalEventId\n          orgTags {\n            tag\n            state {\n              value\n              subjectType\n              subjectValue\n              lastModified\n              active\n            }\n          }\n        }\n        annotatedRecurringEvent {\n          id\n          externalEventId\n          orgTags {\n            tag\n            state {\n              value\n              subjectType\n              subjectValue\n              lastModified\n              active\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9d35b5d09c141f88cdb01729272b2e02";

export default node;
