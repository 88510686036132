import { compact } from "lodash";

export type Profile = {
  givenName?: string | null;
  familyName?: string | null;
  externalImageUrl?: string | null;
};

export const getFullName = (owner: Profile | null | undefined) =>
  compact([owner?.givenName, owner?.familyName]).join(" ");

export const getInitials = (owner: Profile | null | undefined) =>
  compact([owner?.givenName?.[0], owner?.familyName?.[0]]).join("");

export const getShortName = (owner: Profile | null | undefined) =>
  owner?.givenName
    ? compact([owner.givenName, owner?.familyName?.[0]]).join(" ")
    : compact([owner?.familyName]).join(" ");

export const getStringListOfProfiles = (profiles: (Profile | null | undefined)[]) => {
  const numProfiles = profiles.length;

  const profileNameList = profiles
    .map((profile, index) => {
      const baseName = getFullName(profile);
      if (index === numProfiles - 2) {
        return baseName + " and";
      }
      if (index === numProfiles - 1) {
        return baseName;
      }
      return baseName + ",";
    })
    .join(" ");

  return profileNameList;
};

type Attendee = {
  person: {
    isMe: boolean;
    displayName: string;
  };
};
export const getStringListOfAttendees = (attendees: Attendee[]) => {
  const numAttendees = attendees.length;

  return attendees
    .map((attendee, index) => {
      const baseName = attendee.person.isMe ? "You" : attendee.person.displayName;
      if (index === numAttendees - 2) {
        return baseName + " and";
      }
      if (index === numAttendees - 1) {
        return baseName;
      }
      return baseName + ",";
    })
    .join(" ");
};

export const getShortStringFromListOfAttendees = (attendees: Attendee[]) => {
  const numAttendees = attendees.length;
  if (numAttendees === 0) {
    return "";
  }
  if (numAttendees === 1) {
    return attendees[0].person.isMe ? "You" : attendees[0].person.displayName;
  }
  return `${numAttendees} attendees`;
};
