/**
 * @generated SignedSource<<b8c72b45cf862a3f568aed0ccca430e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Day = "Friday" | "Monday" | "Saturday" | "Sunday" | "Thursday" | "Tuesday" | "Wednesday" | "%future added value";
export type ExcludedSlots = "T_00_00" | "T_00_30" | "T_01_00" | "T_01_30" | "T_02_00" | "T_02_30" | "T_03_00" | "T_03_30" | "T_04_00" | "T_04_30" | "T_05_00" | "T_05_30" | "T_06_00" | "T_06_30" | "T_07_00" | "T_07_30" | "T_08_00" | "T_08_30" | "T_09_00" | "T_09_30" | "T_10_00" | "T_10_30" | "T_11_00" | "T_11_30" | "T_12_00" | "T_12_30" | "T_13_00" | "T_13_30" | "T_14_00" | "T_14_30" | "T_15_00" | "T_15_30" | "T_16_00" | "T_16_30" | "T_17_00" | "T_17_30" | "T_18_00" | "T_18_30" | "T_19_00" | "T_19_30" | "T_20_00" | "T_20_30" | "T_21_00" | "T_21_30" | "T_22_00" | "T_22_30" | "T_23_00" | "T_23_30" | "%future added value";
export type MaxSlot = "T_00_00" | "T_00_30" | "T_01_00" | "T_01_30" | "T_02_00" | "T_02_30" | "T_03_00" | "T_03_30" | "T_04_00" | "T_04_30" | "T_05_00" | "T_05_30" | "T_06_00" | "T_06_30" | "T_07_00" | "T_07_30" | "T_08_00" | "T_08_30" | "T_09_00" | "T_09_30" | "T_10_00" | "T_10_30" | "T_11_00" | "T_11_30" | "T_12_00" | "T_12_30" | "T_13_00" | "T_13_30" | "T_14_00" | "T_14_30" | "T_15_00" | "T_15_30" | "T_16_00" | "T_16_30" | "T_17_00" | "T_17_30" | "T_18_00" | "T_18_30" | "T_19_00" | "T_19_30" | "T_20_00" | "T_20_30" | "T_21_00" | "T_21_30" | "T_22_00" | "T_22_30" | "T_23_00" | "T_23_30" | "%future added value";
export type MinSlot = "T_00_00" | "T_00_30" | "T_01_00" | "T_01_30" | "T_02_00" | "T_02_30" | "T_03_00" | "T_03_30" | "T_04_00" | "T_04_30" | "T_05_00" | "T_05_30" | "T_06_00" | "T_06_30" | "T_07_00" | "T_07_30" | "T_08_00" | "T_08_30" | "T_09_00" | "T_09_30" | "T_10_00" | "T_10_30" | "T_11_00" | "T_11_30" | "T_12_00" | "T_12_30" | "T_13_00" | "T_13_30" | "T_14_00" | "T_14_30" | "T_15_00" | "T_15_30" | "T_16_00" | "T_16_30" | "T_17_00" | "T_17_30" | "T_18_00" | "T_18_30" | "T_19_00" | "T_19_30" | "T_20_00" | "T_20_30" | "T_21_00" | "T_21_30" | "T_22_00" | "T_22_30" | "T_23_00" | "T_23_30" | "%future added value";
export type WorkingHoursTypeEnum = "Base" | "Meeting" | "PreferredFocusTime" | "%future added value";
export type UpdateWorkingHoursMutationInput = {
  clientMutationId?: string | null;
  saveMeetingAndWorkingHours?: boolean | null;
  workingHours: WorkingHoursInput;
};
export type WorkingHoursInput = {
  dayOverrides?: ReadonlyArray<DayOverrideInput | null> | null;
  daySettings?: ReadonlyArray<DaySettingInput | null> | null;
  id: string;
  timeZone?: string | null;
  type?: WorkingHoursTypeEnum | null;
};
export type DayOverrideInput = {
  date?: string | null;
  setting?: WorkingSettingInput | null;
};
export type WorkingSettingInput = {
  excludedSlots?: ReadonlyArray<ExcludedSlots | null> | null;
  maxSlot?: MaxSlot | null;
  minSlot?: MinSlot | null;
};
export type DaySettingInput = {
  day?: Day | null;
  setting?: WorkingSettingInput | null;
};
export type UpdateWorkingHoursMutation$variables = {
  input: UpdateWorkingHoursMutationInput;
};
export type UpdateWorkingHoursMutation$data = {
  readonly updateWorkingHours: {
    readonly updatedUserMeetingHours: {
      readonly id: string;
      readonly timeZone: string | null;
      readonly type: WorkingHoursTypeEnum | null;
      readonly daySettings: ReadonlyArray<{
        readonly day: Day | null;
        readonly setting: {
          readonly minSlot: MinSlot | null;
          readonly maxSlot: MaxSlot | null;
          readonly excludedSlots: ReadonlyArray<ExcludedSlots | null> | null;
        } | null;
      } | null> | null;
      readonly dayOverrides: ReadonlyArray<{
        readonly date: string | null;
        readonly setting: {
          readonly minSlot: MinSlot | null;
          readonly maxSlot: MaxSlot | null;
          readonly excludedSlots: ReadonlyArray<ExcludedSlots | null> | null;
        } | null;
      } | null> | null;
    } | null;
    readonly updatedUserWorkingHours: {
      readonly id: string;
      readonly timeZone: string | null;
      readonly type: WorkingHoursTypeEnum | null;
      readonly daySettings: ReadonlyArray<{
        readonly day: Day | null;
        readonly setting: {
          readonly minSlot: MinSlot | null;
          readonly maxSlot: MaxSlot | null;
          readonly excludedSlots: ReadonlyArray<ExcludedSlots | null> | null;
        } | null;
      } | null> | null;
      readonly dayOverrides: ReadonlyArray<{
        readonly date: string | null;
        readonly setting: {
          readonly minSlot: MinSlot | null;
          readonly maxSlot: MaxSlot | null;
          readonly excludedSlots: ReadonlyArray<ExcludedSlots | null> | null;
        } | null;
      } | null> | null;
    } | null;
    readonly updatedOrgMeetingHours: {
      readonly id: string;
      readonly timeZone: string | null;
      readonly type: WorkingHoursTypeEnum | null;
      readonly daySettings: ReadonlyArray<{
        readonly day: Day | null;
        readonly setting: {
          readonly minSlot: MinSlot | null;
          readonly maxSlot: MaxSlot | null;
          readonly excludedSlots: ReadonlyArray<ExcludedSlots | null> | null;
        } | null;
      } | null> | null;
      readonly dayOverrides: ReadonlyArray<{
        readonly date: string | null;
        readonly setting: {
          readonly minSlot: MinSlot | null;
          readonly maxSlot: MaxSlot | null;
          readonly excludedSlots: ReadonlyArray<ExcludedSlots | null> | null;
        } | null;
      } | null> | null;
    } | null;
    readonly userWorkingHours: {
      readonly id: string;
      readonly hasBeenSet: boolean | null;
      readonly workingHours: {
        readonly timeZone: string | null;
      } | null;
    } | null;
  } | null;
};
export type UpdateWorkingHoursMutation = {
  variables: UpdateWorkingHoursMutation$variables;
  response: UpdateWorkingHoursMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeZone",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "WorkingSetting",
  "kind": "LinkedField",
  "name": "setting",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minSlot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxSlot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "excludedSlots",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "DaySetting",
    "kind": "LinkedField",
    "name": "daySettings",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "day",
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "DayOverride",
    "kind": "LinkedField",
    "name": "dayOverrides",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "WorkingHours",
  "kind": "LinkedField",
  "name": "updatedUserMeetingHours",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "WorkingHours",
  "kind": "LinkedField",
  "name": "updatedUserWorkingHours",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "WorkingHours",
  "kind": "LinkedField",
  "name": "updatedOrgMeetingHours",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasBeenSet",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateWorkingHoursMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkingHoursMutationPayload",
        "kind": "LinkedField",
        "name": "updateWorkingHours",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserWorkingHours",
            "kind": "LinkedField",
            "name": "userWorkingHours",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkingHours",
                "kind": "LinkedField",
                "name": "workingHours",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateWorkingHoursMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkingHoursMutationPayload",
        "kind": "LinkedField",
        "name": "updateWorkingHours",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserWorkingHours",
            "kind": "LinkedField",
            "name": "userWorkingHours",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkingHours",
                "kind": "LinkedField",
                "name": "workingHours",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3dc949d793c4a37c9f1a626a9381c2f8",
    "id": null,
    "metadata": {},
    "name": "UpdateWorkingHoursMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateWorkingHoursMutation(\n  $input: UpdateWorkingHoursMutationInput!\n) {\n  updateWorkingHours(input: $input) {\n    updatedUserMeetingHours {\n      id\n      timeZone\n      type\n      daySettings {\n        day\n        setting {\n          minSlot\n          maxSlot\n          excludedSlots\n        }\n      }\n      dayOverrides {\n        date\n        setting {\n          minSlot\n          maxSlot\n          excludedSlots\n        }\n      }\n    }\n    updatedUserWorkingHours {\n      id\n      timeZone\n      type\n      daySettings {\n        day\n        setting {\n          minSlot\n          maxSlot\n          excludedSlots\n        }\n      }\n      dayOverrides {\n        date\n        setting {\n          minSlot\n          maxSlot\n          excludedSlots\n        }\n      }\n    }\n    updatedOrgMeetingHours {\n      id\n      timeZone\n      type\n      daySettings {\n        day\n        setting {\n          minSlot\n          maxSlot\n          excludedSlots\n        }\n      }\n      dayOverrides {\n        date\n        setting {\n          minSlot\n          maxSlot\n          excludedSlots\n        }\n      }\n    }\n    userWorkingHours {\n      id\n      hasBeenSet\n      workingHours {\n        timeZone\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "65caf50dfa70ff3d80eacdb877a438ef";

export default node;
