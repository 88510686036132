import { cwEcosystem } from "#clockwise/web-commons/src/util/local-storage";
import { EcosystemEnum } from "@clockwise/schema";
import React, { createContext, PropsWithChildren, useContext } from "react";

export const EcosystemContext = createContext(EcosystemEnum.NotApplicable);

export const EcosystemProvider = ({ children }: PropsWithChildren<object>) => {
  const ecosystem = (cwEcosystem.get() || EcosystemEnum.NotApplicable) as EcosystemEnum;
  return <EcosystemContext.Provider value={ecosystem}>{children}</EcosystemContext.Provider>;
};

/**
 * Provides information about the ecosystem the current user is operating in, e.g. `Google` or
 * `Microsoft`. If the user is not loaded, the value will be `NotApplicable`, so your code should
 * handle that case as well.
 */
export const useEcosystem = () => useContext(EcosystemContext);
