/**
 * @generated SignedSource<<f890d60658814217b98ee1f46ded69a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DataSourceAuthError = "GenericError" | "InsufficientScopes" | "SameDomainError" | "%future added value";
export type DataSourceProvider = "Google" | "Office365" | "%future added value";
export type DataSourceStatus = "Okay" | "RequiresReAuth" | "%future added value";
export type CreateDataSourceMutationInput = {
  clientMutationId?: string | null;
  googleOfflineCode: string;
  isPersonal?: boolean | null;
  orgRelayId: string;
};
export type CreateDataSourceMutation$variables = {
  input: CreateDataSourceMutationInput;
};
export type CreateDataSourceMutation$data = {
  readonly createDataSource: {
    readonly dataSourceAuthError: DataSourceAuthError | null;
    readonly user: {
      readonly dataSources: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly calendarIds: ReadonlyArray<string | null> | null;
            readonly name: string | null;
            readonly username: string | null;
            readonly state: DataSourceStatus | null;
            readonly type: DataSourceProvider | null;
            readonly syncTargets: ReadonlyArray<{
              readonly calendarId: string | null;
              readonly emailAddress: string | null;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
    readonly dataSource: {
      readonly id: string;
      readonly calendarIds: ReadonlyArray<string | null> | null;
      readonly name: string | null;
      readonly username: string | null;
      readonly state: DataSourceStatus | null;
      readonly type: DataSourceProvider | null;
      readonly syncTargets: ReadonlyArray<{
        readonly calendarId: string | null;
        readonly emailAddress: string | null;
      } | null> | null;
    } | null;
  } | null;
};
export type CreateDataSourceMutation = {
  variables: CreateDataSourceMutation$variables;
  response: CreateDataSourceMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataSourceAuthError",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "calendarIds",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "state",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CrawlTarget",
    "kind": "LinkedField",
    "name": "syncTargets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "calendarId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "emailAddress",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1000000
    }
  ],
  "concreteType": "DataSourceConnection",
  "kind": "LinkedField",
  "name": "dataSources",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DataSourceEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DataSource",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "dataSources(first:1000000)"
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "DataSource",
  "kind": "LinkedField",
  "name": "dataSource",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateDataSourceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDataSourceMutationPayload",
        "kind": "LinkedField",
        "name": "createDataSource",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateDataSourceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDataSourceMutationPayload",
        "kind": "LinkedField",
        "name": "createDataSource",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f83465686e11f21b437d33e62fe5b00",
    "id": null,
    "metadata": {},
    "name": "CreateDataSourceMutation",
    "operationKind": "mutation",
    "text": "mutation CreateDataSourceMutation(\n  $input: CreateDataSourceMutationInput!\n) {\n  createDataSource(input: $input) {\n    dataSourceAuthError\n    user {\n      dataSources(first: 1000000) {\n        edges {\n          node {\n            id\n            calendarIds\n            name\n            username\n            state\n            type\n            syncTargets {\n              calendarId\n              emailAddress\n            }\n          }\n        }\n      }\n      id\n    }\n    dataSource {\n      id\n      calendarIds\n      name\n      username\n      state\n      type\n      syncTargets {\n        calendarId\n        emailAddress\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4a6849c7036004dab3aa39e26a0e31e";

export default node;
