import { OnboardingNavButton } from "#webapp/src/components/onboarding-nux/onboarding-buttons/OnboardingNavButton";
import { Confetti } from "phosphor-react";
import * as React from "react";

export const TrialExtensionConfirmation = ({ onClose }: { onClose: () => void }) => {
  const onConfirmDone = () => {
    onClose();
  };

  return (
    <>
      <div className="cw-flex cw-flex-col cw-items-center cw-text-center cw-w-full cw-pt-32 cw-px-32 cw-h-[700px]">
        <Confetti size={64} className="cw-h-[100px] cw-w-[100px]" />
        <div className="cw-heading-3xl cw-my-2">
          Congrats! You've extended your trial by 90 days.
        </div>
        <div className="cw-body-lg cw-my-2">
          You and the teammates you've invited will have full access to Clockwise for the next 90
          days, including Flexible Meetings and the well-loved team features.
        </div>
        <div className="cw-absolute cw-bottom-0 cw-flex cw-z-20 cw-right-0">
          <OnboardingNavButton color="positive" onClick={onConfirmDone}>
            Done
          </OnboardingNavButton>
        </div>
      </div>
    </>
  );
};
