import { PossibleTypesMap } from "@apollo/client";

export const possibleTypes: PossibleTypesMap = {
  AssistantMessage: [
    "TextResponse",
    "ProposalResponse",
    "ViewEventsResponse",
    "SchedulingLinksResponse",
  ],
};
