import React from "react";

interface IProps {
  children: React.ReactNode;
}
export const BaseFeature = ({ children }: IProps) => {
  return (
    <div className="cw-h-full cw-flex-1 cw-w-2/5 cw-flex cw-items-center cw-justify-center cw-heading-sm cw-mx-2.5">
      {children}
    </div>
  );
};
