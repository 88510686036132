import React from "react";

const Header = () => {
  return (
    <div className="cw-pb-4">
      <div className="cw-flex cw-items-center cw-justify-between cw-mx-auto cw-w-fit">
        <div className="cw-heading-3xl cw-py-6 cw-text-center">
          Buy time for your team. Literally.
        </div>
      </div>
    </div>
  );
};

export default Header;
