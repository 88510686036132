import classNames from "classnames";
import { Sentiment as _Sentiment, Size, Variant } from "./types";

// -----------------SIZES START-----------------

type Sentiment = Extract<_Sentiment, "neutral" | "positive" | "destructive">;

// -----------------SIZES START-----------------

export const INPUT_HEIGHT_CLASS: { [s in Size]: string } = {
  mini: "cw-h-6",
  small: "cw-h-7",
  medium: "cw-h-8",
  large: "cw-h-10",
};

export const INPUT_MIN_WIDTH_CLASS: { [s in Size]: string } = {
  mini: "cw-min-w-6",
  small: "cw-min-w-7",
  medium: "cw-min-w-8",
  large: "cw-min-w-10",
};

export const INPUT_TEXT_CLASS: { [s in Size]: string } = {
  mini: "cw-text-12 cw-font-medium cw-font-body cw-leading-normal",
  small: "cw-text-13 cw-font-medium cw-font-body cw-leading-normal",
  medium: "cw-text-13 cw-font-medium cw-font-body cw-leading-normal",
  large: "cw-text-14 cw-font-medium cw-font-body cw-leading-normal",
};

export const INPUT_ICON_SIZES: { [s in Size]: string } = {
  mini: "cw-h-3.5 cw-w-3.5",
  small: "cw-h-4 cw-w-4",
  medium: "cw-h-4 cw-w-4",
  large: "cw-h-5 cw-w-5",
};

export const INPUT_BORDER_RADIUS_CLASS: { [s in Size]: string } = {
  mini: "cw-rounded-md",
  small: "cw-rounded-lg",
  medium: "cw-rounded-lg",
  large: "cw-rounded-xl",
};

export const EXPAND_SELECT_ICON_CLASS: { [s in Size]: string } = {
  mini: "cw-h-3 cw-w-3",
  small: "cw-h-[14px] cw-w-[14px]",
  medium: "cw-h-[14px] cw-w-[14px]",
  large: "cw-h-4 cw-w-4",
};

export const INPUT_PADDING_CLASS: { [v in Variant]: { [s in Size]: string } } = {
  default: {
    mini: "cw-px-[6px]",
    small: "cw-px-2",
    medium: "cw-px-2",
    large: "cw-px-3",
  },
  inline: {
    mini: "cw-px-1",
    small: "cw-px-1",
    medium: "cw-px-1",
    large: "cw-px-[6px]",
  },
  elevated: {
    mini: "cw-px-[6px]",
    small: "cw-px-2",
    medium: "cw-px-2",
    large: "cw-px-3",
  },
  filled: {
    mini: "cw-px-[6px]",
    small: "cw-px-2",
    medium: "cw-px-2",
    large: "cw-px-3",
  },
};

export const INPUT_CONTENT_SPACING_CLASS: { [s in Size]: string } = {
  mini: "cw-gap-1",
  small: "cw-gap-1.5",
  medium: "cw-gap-1.5",
  large: "cw-gap-1.5",
};

// -----------------SIZES END-----------------

export const INPUT_BORDER_CLASS = "cw-border cw-border-solid cw-box-border";

export const INPUT_BORDER_COLOR_CLASS: { [v in Variant]: { [s in Sentiment]: string } } = {
  default: {
    positive: "cw-border-positive focus:cw-border-positive disabled:cw-border-subtle",
    destructive: "cw-border-destructive focus:cw-border-destructive disabled:cw-border-subtle",
    neutral: "cw-border-subtle focus:cw-border-default",
  },
  elevated: {
    positive: "cw-border-positive focus:cw-border-positive disabled:cw-border-subtle",
    destructive: "cw-border-destructive focus:cw-border-destructive disabled:cw-border-subtle",
    neutral: "cw-border-[#FFF] focus:cw-border-default disabled:cw-border-subtle",
  },
  filled: {
    positive: "cw-border-transparent focus:cw-border-positive",
    destructive: "cw-border-transparent focus:cw-border-destructive",
    neutral: "cw-border-transparent focus:cw-border-default",
  },
  inline: {
    positive: "cw-border-[#FFF] focus:cw-border-positive",
    destructive: "cw-border-[#FFF] focus:cw-border-destructive",
    neutral: "cw-border-[#FFF] focus:cw-border-subtle",
  },
};

export const INPUT_BORDER_SHADOW_CLASS: { [v in Variant]: string } = {
  default: "",
  inline: "",
  elevated: "cw-shadow-select focus:cw-shadow-none disabled:cw-shadow-none",
  filled: "",
};

export const INPUT_BACKGROUND_CLASS: { [v in Variant]: { [s in Sentiment]: string } } = {
  default: {
    positive: "cw-bg-default hover:cw-bg-positive-hover active:cw-bg-positive-pressed",
    destructive: "cw-bg-default hover:cw-bg-destructive-hover active:cw-bg-destructive-pressed",
    neutral: "cw-bg-default hover:cw-bg-neutral focus:cw-bg-neutral",
  },
  inline: {
    positive: "cw-bg-default hover:cw-bg-positive-hover active:cw-bg-positive-pressed",
    destructive: "cw-bg-default hover:cw-bg-destructive-hover active:cw-bg-destructive-pressed",
    neutral: "cw-bg-default hover:cw-bg-neutral focus:cw-bg-neutral-inset",
  },
  elevated: {
    positive: "cw-bg-default hover:cw-bg-positive-hover active:cw-bg-positive-pressed",
    destructive: "cw-bg-default hover:cw-bg-destructive-hover active:cw-bg-destructive-pressed",
    neutral: "cw-bg-default hover:cw-bg-neutral focus:cw-bg-neutral",
  },
  filled: {
    positive:
      "cw-bg-positive-emphasis hover:cw-bg-positive-emphasis-hover active:cw-bg-positive-emphasis-pressed disabled:cw-opacity-50",
    destructive:
      "cw-bg-destructive-emphasis hover:cw-bg-destructive-emphasis-hover active:cw-bg-destructive-emphasis-pressed disabled:cw-opacity-50",
    neutral:
      "cw-bg-neutral-emphasis hover:cw-bg-neutral-emphasis-hover focus:cw-bg-neutral-emphasis-pressed disabled:cw-opacity-50",
  },
};

export const INPUT_TEXT_COLOR_CLASS: { [v in Variant]: { [s in Sentiment]: string } } = {
  default: {
    positive: "cw-text-positive disabled:cw-text-default-disabled",
    neutral: "cw-text-default disabled:cw-text-default-disabled",
    destructive: "cw-text-destructive disabled:cw-text-default-disabled",
  },
  inline: {
    positive: "cw-text-positive disabled:cw-text-default-disabled",
    neutral: "cw-text-default disabled:cw-text-default-disabled",
    destructive: "cw-text-destructive disabled:cw-text-default-disabled",
  },
  elevated: {
    positive: "cw-text-positive disabled:cw-text-default-disabled",
    neutral: "cw-text-default disabled:cw-text-default-disabled",
    destructive: "cw-text-destructive disabled:cw-text-default-disabled",
  },
  filled: {
    positive: "cw-text-onEmphasis",
    neutral: "cw-text-onEmphasis",
    destructive: "cw-text-onEmphasis",
  },
};

export const getInputDisabledClassNames = (disabled: boolean) =>
  classNames(
    disabled ? "cw-cursor-not-allowed" : "cw-cursor-pointer",
    disabled ? "cw-text-default-disabled" : "cw-text-default",
    disabled ? "placeholder:cw-text-subtle-disabled" : "placeholder:cw-text-subtle",
    disabled ? "cw-bg-default" : "cw-bg-default",
  );

export const getInputFullWidthClassNames = (fullWidth: boolean) =>
  classNames({
    "cw-w-full": fullWidth,
  });

export const getInputErrorClassNames = (error: boolean) =>
  classNames({
    "cw-border-destructive hover:cw-border-destructive-hover focus:cw-border-destructive-hover": error,
  });

export const getListBoxClasses = (active: boolean, disabled: boolean) => {
  return classNames(
    active ? "cw-bg-neutral-inset" : "",
    disabled
      ? "cw-cursor-not-allowed cw-text-default-disabled"
      : "cw-text-default cw-cursor-pointer",
    "cw-select-none cw-relative cw-px-2 cw-py-[5px] cw-rounded-lg cw-bg-default cw-border-none cw-w-full",
  );
};
