//////////////////
// IMPORTS
//////////////////
// libraries
import * as React from "react";
import { connect } from "react-redux";
// chrome-dialog
import * as s from "./chrome-dialog.styles";
import { IContainer, IProps, IState } from "./chrome-dialogTypes";
// other internals
import { newMessageEventDataFromChromeExtension } from "#webapp/src/state/actions/chrome-extension.actions";
import { getReduxStore } from "#webapp/src/state/redux-store";
import { PostMessageManager } from "#webapp/src/util/post-message.util";
import {
  InterAppPayload,
  PayloadTypes,
} from "@clockwise/web-commons/src/util/post-message-common.util";
// state
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
// material-ui
import { Dialog } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

//////////////////
// COMPONENT
//////////////////
class ChromeDialogCmpt extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  // ~-~-~-~-~-~-~-
  // Life-Cycle
  // ~-~-~-~-~-~-~-
  public componentDidMount() {
    // To handle the case where a ChromeDialog is already open, mounting a new Dialog in the closed
    // state should not set webExtensionDialogOpen to false.
    if (this.props.open) {
      this.updateChromeDialogOpen(true);
    }
  }

  public componentDidUpdate({ open: wasOpen }: IProps) {
    const { open } = this.props;
    if (open !== wasOpen) {
      // Trigger an update on change
      this.updateChromeDialogOpen(open);
    }
  }

  public componentWillUnmount() {
    this.updateChromeDialogOpen(false);
  }

  // ~-~-~-~-~-~-~-
  // Handlers
  // ~-~-~-~-~-~-~-

  private handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    this.updateChromeDialogOpen(false);
    if (this.props.onClose) {
      this.props.onClose(event, reason);
    }
  };

  // TODO: this being called on close fundamentally doesn't allow for nested dialogs.. we should fix this
  private updateChromeDialogOpen(open: boolean) {
    // send to extension
    PostMessageManager.sendWebDialogOpen(open);

    // update local state
    getReduxStore().dispatch(
      newMessageEventDataFromChromeExtension(
        new InterAppPayload(PayloadTypes.CHROME_EXTENSION_CHROME_DIALOG_OPEN, {
          chromeExtensionChromeDialogOpen: open,
        }),
      ),
    );
  }

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  public render() {
    const { BackdropProps, PaperProps, chromeDialogOpen, classes, ...dialogProps } = this.props;
    const { backdrop, paper, rounded, ...otherClasses } = classes;

    return (
      <Dialog
        {...dialogProps}
        classes={{ ...otherClasses }}
        BackdropProps={{
          ...BackdropProps,
          classes: { root: backdrop },
        }}
        PaperProps={{
          ...PaperProps,
          classes: { rounded, root: paper },
        }}
        onClose={this.handleClose}
        open={this.props.open && chromeDialogOpen}
      >
        {this.props.children}
      </Dialog>
    );
  }
}

/////////////////
// With Styles //
/////////////////

const ChromeDialogWithStyles = withStyles(s.styles)(ChromeDialogCmpt);

//////////////////
// REDUX
//////////////////
function mapStateToProps(state: IReduxState, _ownProps: IContainer) {
  return {
    chromeDialogOpen: state.webExtension.webExtensionDialogOpen,
  };
}

export const ChromeDialog = connect(mapStateToProps, {})(ChromeDialogWithStyles);
