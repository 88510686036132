import { graphql } from "react-relay";

export const updateSuggestedOrgInviteMutation = graphql`
  mutation UpdateSuggestedOrgInviteMutation($input: UpdateSuggestedOrgInviteInput!) {
    updateSuggestedOrgInvite(input: $input) {
      org {
        id
        suggestedInvites(first: 10) {
          edges {
            node {
              id
              orgId
              suggestedTime
              resolution
              totalScore
              numberOfOrganizedMeetings
              numberOfSharedMeetings
              targetCalendarId
              targetProfile {
                givenName
                familyName
                externalImageUrl
              }
            }
          }
        }
        pendingInvites(first: 20) {
          edges {
            node {
              id
              orgId
              lastInvitingUserId
              createdTime
              lastSentTime
              used
              targetCalendarId
              targetProfile {
                givenName
                familyName
                externalImageUrl
              }
            }
          }
        }
      }
    }
  }
`;
