import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";

type Callback = (() => Promise<any>) | (() => void);

/**
 * A custom hook that returns the most recent date the tab/window was focused.
 *
 * @param onChange callback to trigger when the date changes
 * @returns {DateTime} Most recent date the tab was focused
 */
export function useLastTabFocusDate({ onChange }: { onChange: Callback }): DateTime {
  const [lastFocusTabDate, setLastFocusTabDate] = useState(DateTime.now());
  const onTabFocusChange = useCallback(() => {
    const now = DateTime.now();
    // Only trigger onChange if the date has changed.
    if (now.startOf("day") > lastFocusTabDate.startOf("day")) {
      setLastFocusTabDate(DateTime.now());
      void onChange();
    }
  }, [lastFocusTabDate, onChange]);

  useEffect(() => {
    window.addEventListener("focus", onTabFocusChange);

    return () => window.removeEventListener("focus", onTabFocusChange);
  }, [onTabFocusChange]);

  return lastFocusTabDate;
}
