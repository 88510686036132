import { Info } from "@clockwise/design-system/icons";
import { greys } from "@clockwise/web-commons/src/styles/color.styles";
import React, { CSSProperties, useCallback } from "react";
import ReactTooltip from "react-tooltip";

const infoTip: CSSProperties = {
  fontSize: 16,
  height: 16,
  width: 16,
  left: 4,
  top: 1,
  color: greys.slate,
  position: "relative",
};

interface IInfoTipProps {
  text: JSX.Element | string;
  iconStyle?: CSSProperties;
  position?: "left" | "right" | "top" | "bottom";
  tipType?: "light" | "info";
  maxWidth?: number;
  targetElement?: JSX.Element;
  href?: string;
  targetElementContainerStyles?: CSSProperties;
}

export const InfoTip = ({
  text,
  iconStyle,
  maxWidth,
  position,
  targetElement,
  href,
  targetElementContainerStyles,
}: IInfoTipProps) => {
  const maybeOpenLink = useCallback(() => {
    if (href) {
      window.open(href, "_blank");
    }
  }, [href]);

  const iconStyles = { ...infoTip, ...iconStyle, cursor: href ? "pointer" : "default" };
  const tipText = typeof text === "string" ? <span>{text}</span> : text;
  const id = (href || "") + Math.random().toString(36).substring(2);

  return (
    <span>
      {targetElement ? (
        <div data-tip data-for={id} onClick={maybeOpenLink} style={targetElementContainerStyles}>
          {targetElement}
        </div>
      ) : (
        <Info data-tip data-for={id} style={iconStyles} onClick={maybeOpenLink} />
      )}
      <ReactTooltip id={id} effect="solid" place={position} multiline type="dark">
        <div style={{ maxWidth, lineHeight: 1.2, padding: "5px 2px" }}>{tipText}</div>
      </ReactTooltip>
    </span>
  );
};
