import { setFeatureGridStates } from "#webapp/src/state/actions/feature-grid.actions";
import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import {
  FreePaidFeatures,
  PaymentStatusBadgeTextEnum,
  PaymentStatusBadgeTypeEnum,
  ProductTypeEnum,
  TrialMetadata,
} from "@clockwise/schema";
import { PaymentStatus } from "@clockwise/schema/v2";
import { UserFeatureGridQueryDocument } from "@clockwise/web-commons/src/feature-grid/__generated__/UserFeatureGridQuery.generated";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { TrialProBadgeType } from "@clockwise/web-commons/src/ui/trial-pro-badge-with-tooltip";
import { IFeatureGridReduxState } from "@clockwise/web-commons/src/util/feature-grid.util";
import { OVER_EXPIRED_DAYS } from "@clockwise/web-commons/src/util/trial-constants";
import { isEmpty, isEqual } from "lodash";
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useFeatureFlag } from "../../launch-darkly";
import { useLastTabFocusDate } from "../useLastTabFocusDate";
import { UserMonetizationDocument } from "./__generated__/UserMonetization.v2.generated";
import {
  emptyMonetizationPayload,
  getCanUserMarkLinkAsActive,
  getCopyForMonetizationBadgesUtil,
  getDaysLeftInTrial,
  getSimplifiedUserProductType,
} from "./monetization.util";

type BadgeCopyType = "Group Links" | "Team Availability" | "NMD";

type SimpleUserProductType = "Enterprise" | "Business" | "Free" | "Teams";

export type MonetizationContextData = {
  canUserMarkLinkAsActive: (linkType: "Group" | "1:1") => boolean;
  canUserHaveFocusTimeHolds: boolean;
  canUserSeeLinksTeamsBadge: boolean;
  daysLeftInTrial: number | null;
  getCopyForMonetizationBadges: (
    badgeType: BadgeCopyType,
  ) =>
    | TrialProBadgeType.GroupLinksEnabled
    | TrialProBadgeType.GroupLinksExpired
    | TrialProBadgeType.TeamAvailabilityEnabled
    | TrialProBadgeType.TeamAvailabilityExpired
    | TrialProBadgeType.NMDEnabled
    | TrialProBadgeType.NMDExpired
    | undefined;
  showLinksMonetizationBanner: boolean;
  showRoundRobinMonetizationBanner: boolean;
  canTeamAvailabilityAndNMDBeEnabled: boolean;
  canUserMakeGroupLinks: boolean;
  canTeamHealthAnalyticsBeSeen: boolean;
  shouldUserSeeJoinExistingPlan: boolean;
  canUserAccessFlexMeetings: boolean;
  primaryBillingGroupId: string | null;
  isFeatureGridFetched: boolean;
  refetchFeatureGrid: () => Promise<any>;
  usersProductType: SimpleUserProductType;
  canUserSeeTrialExtension: boolean;
  isFiveDaysPastTrial: boolean;
  userIsOnActiveTrial: boolean;
  showPlanStatusCalloutChrome: boolean;
  trialExpirationStatus: {
    isTrialExpired: boolean;
    timeLeftInTrial: number | null;
  };
  shouldUserSeePricingPagePlansAndBilling: boolean;
};

export const Context = createContext<MonetizationContextData | null>(null);

export const useMonetization = (): MonetizationContextData => {
  const context = useContext(Context);

  // NOTE: this should never happen, i have noticed an error thrown on Sentry (for very few users) instead of having the page crash
  // We will provide a mock empty state, but still record an error to better learn why some people are managing to use the app without the provider
  if (!context) {
    console.error(
      "Tried to access monetization context but it is not available. Please ensure you are inside a MonetizationProvider.",
    );
    return emptyMonetizationPayload;
  }

  return context;
};

type FeatureGridState = {
  trialMetadata: TrialMetadata | null;
  hasPaidPlan: boolean;
  productType: ProductTypeEnum | null;
  freePaidFeatures: FreePaidFeatures;
};

export const useMonetizationForProvider = ({
  orgId,
  skip = false,
}: {
  orgId: string | null;
  skip?: boolean;
}): MonetizationContextData => {
  const [featureGrid, setFeatureGrid] = useState<FeatureGridState | null>(null);
  const [orgBillingGroupIds, setOrgBillingGroupsIds] = useState<string[]>([]);
  const [primaryBillingGroupId, setPrimaryBillingGroupId] = useState<string | null>(null);
  const dispatch = useDispatch();

  const { data, refetch: refetchFeatureGrid } = useQuery(UserFeatureGridQueryDocument, {
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      orgRelayId: orgId!,
    },
    skip: !orgId || skip,
  });
  const lastTabFocusDate = useLastTabFocusDate({ onChange: refetchFeatureGrid });

  useEffect(() => {
    const node = getValue(data?.node, "Org");

    const maybeFeatureGrid = getValue(node?.featureGrid);
    if (!maybeFeatureGrid) {
      setFeatureGrid(null);
      return;
    }

    const orgBillingGroupsFromOrg = getValue(node?.billingGroups);
    if (orgBillingGroupsFromOrg) {
      const listOfbillingGroups = orgBillingGroupsFromOrg?.list;
      const idsOfOrgBillingGroups = listOfbillingGroups
        .map((bg) => bg?.id || "")
        .filter((id) => Boolean(id));
      // check this to prevent overrendering
      if (isEmpty(orgBillingGroupIds)) {
        setOrgBillingGroupsIds(idsOfOrgBillingGroups);
      }
    }
    const trialMetadata = maybeFeatureGrid.stateMetadata.userMetadata?.trialMetadata || null;

    const maybePrimaryBillingGroupId =
      maybeFeatureGrid?.stateMetadata?.primaryBillingGroupMetadata?.billingGroup?.id;
    if (maybePrimaryBillingGroupId) {
      setPrimaryBillingGroupId(maybePrimaryBillingGroupId);
    }

    const newFeatureGrid = {
      trialMetadata,
      hasPaidPlan: Boolean(maybeFeatureGrid.stateMetadata?.primaryBillingGroupMetadata),
      productType:
        maybeFeatureGrid.stateMetadata?.primaryBillingGroupMetadata?.subscription?.productType ||
        null,
      freePaidFeatures: maybeFeatureGrid.featureStates.freePaidFeatures,
    } as FeatureGridState;
    // check this to prevent overrendering
    if (!isEqual(newFeatureGrid, featureGrid)) {
      setFeatureGrid(newFeatureGrid);

      const featureStates = maybeFeatureGrid?.featureStates;

      const featureGridReduxState: IFeatureGridReduxState = {
        ...featureStates,
        trialMetadata,
        hasPaidPlan: Boolean(maybeFeatureGrid.stateMetadata?.primaryBillingGroupMetadata),
        freePaidFeatures: maybeFeatureGrid.featureStates.freePaidFeatures,
        planTierFeatures: maybeFeatureGrid.featureStates.planTierFeatures,
      };

      dispatch(setFeatureGridStates(featureGridReduxState));
    }
    // Commenting out, if featuregrid stays null this will get triggered a bunch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isFeatureGridFetched = useMemo(() => {
    return Boolean(featureGrid);
  }, [featureGrid]);

  const userHasPaidPlan = featureGrid?.hasPaidPlan || false;

  const autopilotWhenOrganizerCurrentlyAllowed = useMemo(() => {
    if (isFeatureGridFetched && !!featureGrid) {
      return featureGrid.freePaidFeatures.autopilotWhenOrganizerCurrentlyAllowed;
    }
    return true;
  }, [featureGrid, isFeatureGridFetched]);

  const eligibleForNoMeetingDay = useMemo(() => {
    if (isFeatureGridFetched && !!featureGrid) {
      return featureGrid.freePaidFeatures.eligibleForNoMeetingDay;
    }
    return true;
  }, [featureGrid, isFeatureGridFetched]);

  const eligibleForTeamCalendarVisibility = useMemo(() => {
    if (isFeatureGridFetched && !!featureGrid) {
      return featureGrid.freePaidFeatures.eligibleForTeamCalendarVisibility;
    }
    return true;
  }, [featureGrid, isFeatureGridFetched]);

  const focusTimeAllowed = useMemo(() => {
    if (isFeatureGridFetched && !!featureGrid) {
      return featureGrid.freePaidFeatures.focusTimeAllowed;
    }
    return true;
  }, [featureGrid, isFeatureGridFetched]);

  // temporary fix for m365 billings groups
  const linksAllowed = useMemo(() => {
    if (isFeatureGridFetched && !!featureGrid) {
      return featureGrid.freePaidFeatures.focusTimeAllowed;
    }
    return true;
  }, [featureGrid, isFeatureGridFetched]);

  // temporary fix for m365 billings groups
  const teamHealthAllowed = useMemo(() => {
    if (isFeatureGridFetched && !!featureGrid) {
      return featureGrid.freePaidFeatures.focusTimeAllowed;
    }
    return true;
  }, [featureGrid, isFeatureGridFetched]);

  const hasTrialExpired = useMemo(() => {
    if (!featureGrid?.trialMetadata || userHasPaidPlan) {
      return false;
    }
    return featureGrid.trialMetadata ? featureGrid.trialMetadata?.isTrialExpired : false;
  }, [featureGrid?.trialMetadata, userHasPaidPlan]);

  const daysLeftInTrial = useMemo(() => {
    return getDaysLeftInTrial(
      featureGrid?.trialMetadata || null,
      lastTabFocusDate,
      userHasPaidPlan,
    );
  }, [featureGrid?.trialMetadata, userHasPaidPlan, lastTabFocusDate]);

  const userIsOnActiveTrial = Boolean(daysLeftInTrial && !hasTrialExpired);

  const userIsOrWasOnTrialAndIsFree = Boolean(featureGrid?.trialMetadata) && !userHasPaidPlan;

  const usersProductType: SimpleUserProductType = useMemo(() => {
    return getSimplifiedUserProductType(featureGrid?.productType);
  }, [featureGrid?.productType]);

  const showLinksMonetizationBanner = useMemo(() => {
    return !linksAllowed;
  }, [linksAllowed]);

  const showRoundRobinMonetizationBanner = useMemo(() => {
    const isBusinessOrEnterprise =
      featureGrid?.productType === ProductTypeEnum.Business_NonStandard ||
      featureGrid?.productType === ProductTypeEnum.Business_Standard ||
      featureGrid?.productType === ProductTypeEnum.Enterprise_NonStandard ||
      featureGrid?.productType === ProductTypeEnum.Enterprise_Standard;

    return !isBusinessOrEnterprise;
  }, [featureGrid?.productType]);

  const canTeamAvailabilityAndNMDBeEnabled = useMemo(() => {
    return eligibleForTeamCalendarVisibility && eligibleForNoMeetingDay;
  }, [eligibleForTeamCalendarVisibility, eligibleForNoMeetingDay]);

  const canTeamHealthAnalyticsBeSeen = useMemo(() => {
    return teamHealthAllowed;
  }, [teamHealthAllowed]);

  const shouldUserSeeJoinExistingPlan = useMemo(() => {
    return userIsOrWasOnTrialAndIsFree && Boolean(orgBillingGroupIds?.length);
  }, [userIsOrWasOnTrialAndIsFree, orgBillingGroupIds]);

  const shouldUserSeePricingPagePlansAndBilling = useMemo(() => {
    return !orgBillingGroupIds?.length && userIsOrWasOnTrialAndIsFree;
  }, [orgBillingGroupIds, userIsOrWasOnTrialAndIsFree]);

  const canUserMarkLinkAsActive = useCallback(
    (linkType: "Group" | "1:1") => {
      return getCanUserMarkLinkAsActive({
        userIsOnActiveTrial: userIsOnActiveTrial || false,
        userHasPaidPlan: linksAllowed,
        linkType,
      });
    },
    [userIsOnActiveTrial, linksAllowed],
  );

  const getCopyForMonetizationBadges = useCallback(
    (badgeType: BadgeCopyType) => {
      return getCopyForMonetizationBadgesUtil({
        userHasPaidPlan: focusTimeAllowed,
        userIsOnActiveTrial: userIsOnActiveTrial || false,
        badgeType,
      });
    },
    [userIsOnActiveTrial, focusTimeAllowed],
  );

  const canUserHaveFocusTimeHolds = useMemo(() => focusTimeAllowed, [focusTimeAllowed]);

  const canUserSeeLinksTeamsBadge = useMemo(() => {
    return userIsOrWasOnTrialAndIsFree && !linksAllowed && isFeatureGridFetched;
  }, [userIsOrWasOnTrialAndIsFree, isFeatureGridFetched, linksAllowed]);

  const canUserMakeGroupLinks = useMemo(() => {
    return linksAllowed;
  }, [linksAllowed]);

  const canUserAccessFlexMeetings = useMemo(() => autopilotWhenOrganizerCurrentlyAllowed, [
    autopilotWhenOrganizerCurrentlyAllowed,
  ]);

  const canUserSeeTrialExtension = useMemo(() => {
    if (isFeatureGridFetched) {
      return !userHasPaidPlan;
    }
    return false;
  }, [isFeatureGridFetched, userHasPaidPlan]);

  const isFiveDaysPastTrial = useMemo(
    () => !!daysLeftInTrial && daysLeftInTrial < OVER_EXPIRED_DAYS,
    [daysLeftInTrial],
  );

  const showPlanStatusCalloutChrome = useMemo(() => {
    const trialExpiredLessThanFiveDaysAgo =
      !!daysLeftInTrial && daysLeftInTrial > OVER_EXPIRED_DAYS;
    return (
      trialExpiredLessThanFiveDaysAgo && !userHasPaidPlan && isFeatureGridFetched && hasTrialExpired
    );
  }, [daysLeftInTrial, userHasPaidPlan, isFeatureGridFetched, hasTrialExpired]);

  const trialExpirationStatus = useMemo(() => {
    if (userHasPaidPlan || !featureGrid?.trialMetadata) {
      return {
        timeLeftInTrial: null,
        isTrialExpired: false,
      };
    }
    return {
      isTrialExpired: hasTrialExpired,
      timeLeftInTrial: !hasTrialExpired ? daysLeftInTrial : null,
    };
  }, [userHasPaidPlan, featureGrid?.trialMetadata, hasTrialExpired, daysLeftInTrial]);

  return useMemo(() => {
    return {
      canUserMarkLinkAsActive,
      canUserHaveFocusTimeHolds,
      canUserSeeLinksTeamsBadge,
      daysLeftInTrial,
      getCopyForMonetizationBadges,
      showLinksMonetizationBanner,
      showRoundRobinMonetizationBanner,
      canTeamAvailabilityAndNMDBeEnabled,
      canUserMakeGroupLinks,
      canTeamHealthAnalyticsBeSeen,
      shouldUserSeeJoinExistingPlan,
      canUserAccessFlexMeetings,
      primaryBillingGroupId,
      isFeatureGridFetched,
      refetchFeatureGrid,
      usersProductType,
      canUserSeeTrialExtension,
      isFiveDaysPastTrial,
      userIsOnActiveTrial,
      showPlanStatusCalloutChrome,
      trialExpirationStatus,
      shouldUserSeePricingPagePlansAndBilling,
    };
  }, [
    canUserMarkLinkAsActive,
    canUserHaveFocusTimeHolds,
    canUserSeeLinksTeamsBadge,
    daysLeftInTrial,
    getCopyForMonetizationBadges,
    showLinksMonetizationBanner,
    showRoundRobinMonetizationBanner,
    canTeamAvailabilityAndNMDBeEnabled,
    canUserMakeGroupLinks,
    canTeamHealthAnalyticsBeSeen,
    shouldUserSeeJoinExistingPlan,
    canUserAccessFlexMeetings,
    primaryBillingGroupId,
    isFeatureGridFetched,
    refetchFeatureGrid,
    usersProductType,
    canUserSeeTrialExtension,
    isFiveDaysPastTrial,
    userIsOnActiveTrial,
    showPlanStatusCalloutChrome,
    trialExpirationStatus,
    shouldUserSeePricingPagePlansAndBilling,
  ]);
};

export function useGatewayMonetizationForProvider(): MonetizationContextData {
  const { data, refetch } = useGatewayQuery(UserMonetizationDocument);
  const dispatch = useDispatch();
  const paidFeaturesLoaded = Boolean(data?.paidFeatures);
  const lastFocusTabDate = useLastTabFocusDate({ onChange: refetch });

  const paidFeatures = data?.paidFeatures ?? null; // Will be null if the user is unauthenticated.
  const trialMetadata = paidFeatures?.trialMetadata ?? null;
  const primaryBillingGroupId = paidFeatures?.primaryBillingGroup?.id ?? null;
  const autopilotWhenOrganizerCurrentlyAllowed =
    paidFeatures?.autopilotWhenOrganizerCurrentlyAllowed ?? true;
  const eligibleForNoMeetingDay = paidFeatures?.eligibleForNoMeetingDay ?? true;
  const eligibleForTeamCalendarVisibility = paidFeatures?.eligibleForTeamCalendarVisibility ?? true;
  const focusTimeAllowed = paidFeatures?.focusTimeAllowed ?? true;
  const linksAllowed = paidFeatures?.focusTimeAllowed ?? true; //temporary fix for m365 specific bgs
  const teamHealthAllowed = paidFeatures?.focusTimeAllowed ?? true; //temporary fix for m365 specific bgs
  const roundRobinEnabled = paidFeatures?.roundRobinEnabled ?? true;
  const hasPaidPlan = Boolean(primaryBillingGroupId);
  const hasTrialExpired = hasPaidPlan ? false : trialMetadata?.isTrialExpired ?? false;
  const daysLeftInTrial = getDaysLeftInTrial(trialMetadata, lastFocusTabDate, hasPaidPlan);
  const userIsOnActiveTrial = Boolean(daysLeftInTrial && !hasTrialExpired);
  const userIsOrWasOnTrialAndIsFree = Boolean(trialMetadata) && !hasPaidPlan;
  const usersProductType = getSimplifiedUserProductType(paidFeatures?.subscription?.productType);
  const showLinksMonetizationBanner = !linksAllowed;
  const showRoundRobinMonetizationBanner = !roundRobinEnabled;
  const canTeamAvailabilityAndNMDBeEnabled =
    eligibleForTeamCalendarVisibility && eligibleForNoMeetingDay;
  const canTeamHealthAnalyticsBeSeen = teamHealthAllowed;
  const orgBillingGroups = data?.currentOrg?.billingGroups ?? [];
  const shouldUserSeeJoinExistingPlan = userIsOrWasOnTrialAndIsFree && orgBillingGroups.length > 0;
  const shouldUserSeePricingPagePlansAndBilling =
    userIsOrWasOnTrialAndIsFree && orgBillingGroups.length === 0;

  useEffect(() => {
    if (!paidFeatures) {
      return;
    }

    const trialMetadata = paidFeatures.trialMetadata;

    const featureGridReduxState: IFeatureGridReduxState = {
      meetingReliefSettingVisibility: paidFeatures.meetingReliefSettingVisibility,
      trialMetadata,
      hasPaidPlan,
      freePaidFeatures: {
        autopilotWhenOrganizerCurrentlyAllowed: paidFeatures.autopilotWhenOrganizerCurrentlyAllowed,
        eligibleForNoMeetingDay: paidFeatures.eligibleForNoMeetingDay,
        eligibleForTeamCalendarVisibility: paidFeatures.eligibleForTeamCalendarVisibility,
        focusTimeAllowed: paidFeatures.focusTimeAllowed,
        moveRangesEnabled: paidFeatures.moveRangesEnabled,
      },
      planTierFeatures: {
        paymentStatusBadge: {
          text: toTextEnum(paidFeatures.paymentStatus),
          type:
            paidFeatures.paymentStatus === PaymentStatus.Free
              ? PaymentStatusBadgeTypeEnum.Blue
              : PaymentStatusBadgeTypeEnum.Gradient,
        },
      },
    };

    dispatch(setFeatureGridStates(featureGridReduxState));
  }, [dispatch, hasPaidPlan, paidFeatures]);

  const canUserMarkLinkAsActive = useCallback(
    (linkType: "Group" | "1:1") => {
      return getCanUserMarkLinkAsActive({
        userIsOnActiveTrial: userIsOnActiveTrial || false,
        userHasPaidPlan: linksAllowed,
        linkType,
      });
    },
    [userIsOnActiveTrial, linksAllowed],
  );

  const getCopyForMonetizationBadges = useCallback(
    (badgeType: BadgeCopyType) => {
      return getCopyForMonetizationBadgesUtil({
        userHasPaidPlan: hasPaidPlan,
        userIsOnActiveTrial: userIsOnActiveTrial || false,
        badgeType,
      });
    },
    [userIsOnActiveTrial, hasPaidPlan],
  );

  const canUserSeeLinksTeamsBadge = paidFeaturesLoaded && userIsOrWasOnTrialAndIsFree;
  const canUserMakeGroupLinks = linksAllowed;
  const canUserSeeTrialExtension = paidFeaturesLoaded && !hasPaidPlan;
  const isFiveDaysPastTrial = daysLeftInTrial !== null && daysLeftInTrial < OVER_EXPIRED_DAYS;
  const showPlanStatusCalloutChrome =
    daysLeftInTrial !== null &&
    daysLeftInTrial > OVER_EXPIRED_DAYS &&
    !hasPaidPlan &&
    paidFeaturesLoaded &&
    hasTrialExpired;
  const trialExpirationStatus = useMemo(
    () => ({
      timeLeftInTrial: !hasTrialExpired ? daysLeftInTrial : null,
      isTrialExpired: hasTrialExpired,
    }),
    [daysLeftInTrial, hasTrialExpired],
  );

  return useMemo(
    () => ({
      canUserMarkLinkAsActive,
      canUserHaveFocusTimeHolds: focusTimeAllowed,
      canUserSeeLinksTeamsBadge,
      daysLeftInTrial,
      getCopyForMonetizationBadges,
      showLinksMonetizationBanner,
      showRoundRobinMonetizationBanner,
      canTeamAvailabilityAndNMDBeEnabled,
      canUserMakeGroupLinks,
      canTeamHealthAnalyticsBeSeen,
      shouldUserSeeJoinExistingPlan,
      canUserAccessFlexMeetings: autopilotWhenOrganizerCurrentlyAllowed,
      primaryBillingGroupId,
      isFeatureGridFetched: paidFeaturesLoaded,
      refetchFeatureGrid: refetch,
      usersProductType,
      canUserSeeTrialExtension,
      isFiveDaysPastTrial,
      userIsOnActiveTrial,
      showPlanStatusCalloutChrome,
      trialExpirationStatus,
      shouldUserSeePricingPagePlansAndBilling,
    }),
    [
      autopilotWhenOrganizerCurrentlyAllowed,
      canTeamAvailabilityAndNMDBeEnabled,
      canTeamHealthAnalyticsBeSeen,
      canUserMakeGroupLinks,
      canUserMarkLinkAsActive,
      canUserSeeLinksTeamsBadge,
      canUserSeeTrialExtension,
      daysLeftInTrial,
      focusTimeAllowed,
      getCopyForMonetizationBadges,
      isFiveDaysPastTrial,
      paidFeaturesLoaded,
      primaryBillingGroupId,
      refetch,
      shouldUserSeeJoinExistingPlan,
      shouldUserSeePricingPagePlansAndBilling,
      showLinksMonetizationBanner,
      showRoundRobinMonetizationBanner,
      showPlanStatusCalloutChrome,
      trialExpirationStatus,
      userIsOnActiveTrial,
      usersProductType,
    ],
  );
}

type PropsForProvider = {
  orgId: string | null;
};

export const MonetizationProvider: FC<PropsWithChildren<PropsForProvider>> = ({
  children,
  orgId,
}) => {
  const [isUsingGateway] = useFeatureFlag("GatewayMonetization");
  const webserverValue = useMonetizationForProvider({ orgId });
  const gatewayValue = useGatewayMonetizationForProvider();
  return (
    <Context.Provider value={isUsingGateway ? gatewayValue : webserverValue}>
      {children}
    </Context.Provider>
  );
};

function toTextEnum(paymentStatus: PaymentStatus | null): PaymentStatusBadgeTextEnum {
  switch (paymentStatus) {
    case PaymentStatus.Enterprise:
      return PaymentStatusBadgeTextEnum.Enterprise;
    case PaymentStatus.Business:
      return PaymentStatusBadgeTextEnum.Business;
    case PaymentStatus.Pro:
      return PaymentStatusBadgeTextEnum.Pro;
    case PaymentStatus.Trial:
      return PaymentStatusBadgeTextEnum.Trial;
    case PaymentStatus.Free:
    default:
      return PaymentStatusBadgeTextEnum.Free;
  }
}
