import { AuthActions } from "#webapp/src/state/actions/auth.actions";
import { userDeactivated } from "@clockwise/web-commons/src/util/local-storage";

export interface IAuthState {
  authed: boolean;
  userCreatedTime: number | null;
  deactivated: boolean;
  uninstalled: boolean;
  experiments: string[];
}

interface IAuthAction {
  type: string;
  authed?: boolean;
  deactivated?: boolean;
  uninstalled?: boolean;
  userCreatedTime?: number | null;
  experiments?: string[];
}

export const initialAuthState: IAuthState = {
  authed: false,
  userCreatedTime: null,
  deactivated: false,
  uninstalled: false,
  experiments: [],
};

export function authReducer(state: IAuthState = initialAuthState, action: IAuthAction) {
  switch (action.type) {
    case AuthActions.UpdateAuthed:
      return { ...state, authed: action.authed, userCreatedTime: action.userCreatedTime };
    case AuthActions.SetDeactivated:
      userDeactivated.set(!!action.deactivated);
      return { ...state, deactivated: action.deactivated };
    case AuthActions.SetUninstalled:
      return { ...state, uninstalled: action.uninstalled };
    case AuthActions.SetExperiments:
      return { ...state, experiments: action.experiments };
    default:
      return state;
  }
}
