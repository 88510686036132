import classNames from "classnames";
import { nanoid } from "nanoid";
import React, { forwardRef, useMemo } from "react";
import {
  INPUT_BORDER_CLASS,
  INPUT_BORDER_COLOR_CLASS,
  INPUT_BORDER_RADIUS_CLASS,
  INPUT_HEIGHT_CLASS,
  INPUT_ICON_SIZES,
  INPUT_PADDING_CLASS,
  INPUT_TEXT_CLASS,
  getInputDisabledClassNames,
} from "../constants/classes";
import { Size } from "../constants/types";
import { SvgIconComponent } from "./Icons";
import { Loader } from "./Loader";

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "className"> {
  loading?: boolean;
  startIcon?: SvgIconComponent;
  label?: string;
  fieldSize?: Size;
  error?: boolean;
  errorMessage?: string | null;
  fullWidth?: boolean;
}

const iconShiftMap: { [s in Size]: { icon: string; leftPadding: string } } = {
  mini: { icon: "cw-left-[7px]", leftPadding: "cw-pl-6" },
  small: { icon: "cw-left-[9px]", leftPadding: "cw-pl-7" },
  medium: { icon: "cw-left-[9px]", leftPadding: "cw-pl-7" },
  large: { icon: "cw-left-[13px]", leftPadding: "cw-pl-9" },
};

const getIconPaddingClass = (hasIcon: boolean, size: Size) => {
  if (!hasIcon) return "";
  return iconShiftMap[size].leftPadding;
};

export const TextField = forwardRef<HTMLInputElement, Props>(function TextFieldWithRef(
  {
    fieldSize = "medium",
    label,
    startIcon: StartIcon,
    loading,
    error = false,
    errorMessage,
    fullWidth = false,
    disabled = false,
    ...props
  },
  ref,
) {
  const id = useMemo(() => props.id ?? nanoid(), [props.id]);
  const errorId = useMemo(() => nanoid(), []);

  return (
    <div
      className={classNames("cw-inline-flex cw-flex-col cw-gap-1 cw-flex-1", {
        "cw-w-full": fullWidth,
      })}
    >
      {label && (
        <label htmlFor={id} className="cw-font-body cw-text-14 cw-text-default cw-leading-none">
          {label}
        </label>
      )}
      <div className="cw-relative cw-flex cw-flex-col">
        {StartIcon && (
          <div
            className={classNames(
              "cw-flex cw-items-center cw-h-full cw-absolute",
              iconShiftMap[fieldSize].icon,
            )}
          >
            <StartIcon className={INPUT_ICON_SIZES[fieldSize]} />
          </div>
        )}
        {loading && (
          <div className="cw-flex cw-items-center cw-h-full cw-absolute cw-right-2 cw-text-subtle">
            <Loader sentiment="neutral" size="xs" className={INPUT_ICON_SIZES[fieldSize]} />
          </div>
        )}
        <input
          {...props}
          disabled={disabled}
          ref={ref}
          id={id}
          aria-invalid={error}
          aria-errormessage={error ? errorId : undefined}
          className={classNames(
            INPUT_HEIGHT_CLASS[fieldSize],
            INPUT_TEXT_CLASS[fieldSize],
            INPUT_PADDING_CLASS.default[fieldSize],
            INPUT_BORDER_CLASS,
            INPUT_BORDER_RADIUS_CLASS[fieldSize],
            INPUT_BORDER_COLOR_CLASS["default"]["neutral"],
            getInputDisabledClassNames(!!disabled),
            getIconPaddingClass(!!StartIcon, fieldSize),
            "cw-transition",
            "cw-outline-1",
            {
              "cw-flex-grow": fullWidth,
              "cw-pr-8": !!loading,
              " focus:cw-border-brand-interactable focus:hover:cw-border-brand-interactable-hover focus-visible:cw-outline-brand-interactable focus:cw-outline-brand-interactable focus:hover:cw-outline-brand-interactable-hover":
                !error && !disabled,
              "cw-bg-destructive cw-outline-destructive-interactable hover:cw-border-destructive-interactable-hover hover:cw-outline-destructive-interactable-hover focus-visible:cw-outline-destructive-interactable-pressed focus:cw-outline-destructive-interactable-pressed":
                error && !disabled,
            },
          )}
        />
        {error && errorMessage && (
          <div
            id={errorId}
            className="cw-text-destructive cw-text-12 cw-font-body cw-mt-1 cw-max-w-full"
          >
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
});
