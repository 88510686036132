import * as ISchema from "#webapp/src/__schema__";
import {
  augmentOrgPerson,
  ITeammateStatusPerson,
  TeammateInviteStatusEnum,
} from "#webapp/src/util/team.util";
import { getCurrentWorkingGroup } from "#webapp/src/util/working-group.util";

type CheckedMembers = {
  [keyof: string]: {
    checked: boolean;
    person: ISchema.IOrgPerson;
  };
};

type InvitedMembers = {
  [keyof: string]: {
    invited: boolean;
    person: ISchema.IOrgPerson;
  };
};

export const sortUsersForModifyMode = (defaultMembers: ITeammateStatusPerson[]) => {
  return [...defaultMembers].sort((a, b) => {
    if (a.person.isYou && !b.person.isYou) {
      return -1;
    } else if (b.person.isYou && !a.person.isYou) {
      return 1;
    }

    const aGivenNameString = a.person.profile
      ? a.person.profile.givenName
      : a.person.primaryCalendarId;
    const bGivenNameString = b.person.profile
      ? b.person.profile.givenName
      : b.person.primaryCalendarId;

    const aFamilyNameString = a.person.profile
      ? a.person.profile.familyName
      : a.person.primaryCalendarId;
    const bFamilyNameString = b.person.profile
      ? b.person.profile.familyName
      : b.person.primaryCalendarId;

    return (
      aGivenNameString.localeCompare(bGivenNameString) ||
      aFamilyNameString.localeCompare(bFamilyNameString)
    );
  });
};

export const getAugmentedPersonsFromSearchResults = (
  orgPersonQueryResult: ISchema.IOrgPersonQueryResult,
  checkedMembers: CheckedMembers,
  invitedMembersProps: InvitedMembers,
  team: ISchema.ITeam | null,
  org: ISchema.IOrg,
  mode?: "create" | "modify" | "orgInvite",
) => {
  const workingGroup = getCurrentWorkingGroup(org);
  const suggestedCalendarIds = (workingGroup && workingGroup.calendarIds) || [];

  let orgPersons: ISchema.IOrgPerson[] = orgPersonQueryResult.persons;

  if (!orgPersonQueryResult.query) {
    orgPersons = orgPersons
      .filter((person) => !checkedMembers[person.primaryCalendarId])
      .concat(Object.values(checkedMembers).map(({ person }) => person));
  }

  const persons = orgPersons.map((person: ISchema.IOrgPerson) => {
    const { teamMembers, invitedMembers } = team || {
      teamMembers: [] as ISchema.ITeamMember[],
      invitedMembers: [] as ISchema.ITeamMember[],
    };

    const checkedMember = person && checkedMembers[person.primaryCalendarId];
    const invitedMember = person && invitedMembersProps[person.primaryCalendarId];

    // check if person is already team member
    const maybeTeamMember = teamMembers.find(
      (tm) => tm.person.primaryCalendarId === person.primaryCalendarId,
    );
    if (maybeTeamMember) {
      return augmentOrgPerson(
        person,
        TeammateInviteStatusEnum.Accepted,
        maybeTeamMember.role,
        !!(checkedMember && checkedMember.checked),
        suggestedCalendarIds.findIndex((c) => c === person.primaryCalendarId) > -1,
        !!(invitedMember && invitedMember.invited),
      );
    }

    // check if person is already invited to team
    const maybeInvitedMember = invitedMembers.find(
      (im) => im.person.primaryCalendarId === person.primaryCalendarId,
    );
    if (maybeInvitedMember) {
      return augmentOrgPerson(
        person,
        TeammateInviteStatusEnum.Pending,
        maybeInvitedMember.role,
        !!(checkedMember && checkedMember.checked),
        suggestedCalendarIds.findIndex((c) => c === person.primaryCalendarId) > -1,
        !!(invitedMember && invitedMember.invited),
      );
    }

    if (mode === "orgInvite") {
      const { pendingInvites, currentOrgPersons } = org;

      // check if person is already in org
      if (currentOrgPersons && currentOrgPersons.__typename !== "GraphEntityError") {
        const maybeOrgMember = currentOrgPersons.list.find(
          (op) => op.primaryCalendarId === person.primaryCalendarId,
        );
        if (maybeOrgMember) {
          return augmentOrgPerson(
            person,
            TeammateInviteStatusEnum.Accepted,
            undefined,
            checkedMember && checkedMember.checked,
            suggestedCalendarIds.findIndex((c) => c === person.primaryCalendarId) > -1,
            invitedMember && invitedMember.invited,
          );
        }
      }

      // check if person is already invited to org
      const maybeInvitedOrgMember =
        pendingInvites &&
        pendingInvites.edges &&
        pendingInvites.edges.find((pi) => pi.node.targetCalendarId === person.primaryCalendarId);
      if (maybeInvitedOrgMember) {
        return augmentOrgPerson(
          person,
          TeammateInviteStatusEnum.Pending,
          undefined,
          checkedMember && checkedMember.checked,
          suggestedCalendarIds.findIndex((c) => c === person.primaryCalendarId) > -1,
          invitedMember && invitedMember.invited,
        );
      }
    }

    return augmentOrgPerson(
      person,
      TeammateInviteStatusEnum.Uninvited,
      undefined,
      !!(checkedMember && checkedMember.checked),
      suggestedCalendarIds.findIndex((c) => c === person.primaryCalendarId) > -1,
      !!(invitedMember && invitedMember.invited),
    );
  });

  const sortedAugmentedPersons = persons.sort((a, b) => {
    if (checkedMembers[a.person.primaryCalendarId] && !checkedMembers[b.person.primaryCalendarId]) {
      return 1;
    } else if (
      checkedMembers[b.person.primaryCalendarId] &&
      !checkedMembers[a.person.primaryCalendarId]
    ) {
      return -1;
    } else if (a.isSuggested && !b.isSuggested) {
      return -1;
    } else if (!a.isSuggested && b.isSuggested) {
      return 1;
    } else if (
      a.inviteStatus === TeammateInviteStatusEnum.Uninvited &&
      a.inviteStatus !== b.inviteStatus
    ) {
      return -1;
    } else if (
      b.inviteStatus === TeammateInviteStatusEnum.Uninvited &&
      a.inviteStatus !== b.inviteStatus
    ) {
      return 1;
    } else if (
      a.inviteStatus === TeammateInviteStatusEnum.Pending &&
      a.inviteStatus !== b.inviteStatus
    ) {
      return -1;
    } else if (
      b.inviteStatus === TeammateInviteStatusEnum.Pending &&
      a.inviteStatus !== b.inviteStatus
    ) {
      return 1;
    }

    const aGivenName = (a.person.profile && a.person.profile.givenName) || "";
    const bGivenName = (b.person.profile && b.person.profile.givenName) || "";

    const aFamilyName = (a.person.profile && a.person.profile.familyName) || "";
    const bFamilyName = (b.person.profile && b.person.profile.familyName) || "";

    return aGivenName.localeCompare(bGivenName) || aFamilyName.localeCompare(bFamilyName);
  });

  return sortedAugmentedPersons;
};
