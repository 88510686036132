import { Sentiment } from "@clockwise/design-system";
import { ChevronRight } from "@clockwise/design-system/icons";
import classNames from "classnames";
import React, { ReactNode } from "react";

const SENTIMENT_CLASSES: Record<Sentiment, string> = {
  info: "cw-bg-info cw-border-info",
  neutral: "cw-bg-grey-lightest/30 cw-border-grey-lighter",
  positive: "cw-bg-positive cw-border-positive",
  warning: "cw-bg-warning cw-border-warning",
  destructive: "cw-bg-destructive cw-border-destructive",
  accent: "cw-bg-accent-emphasis cw-border-accent",
};

const SENTIMENT_CLICKABLE_CLASSES: Record<Sentiment, string> = {
  info: "hover:cw-bg-info-hover hover:cw-border-info-hover",
  neutral: "hover:cw-bg-grey-lightest hover:cw-border-grey-lighter",
  positive: "hover:cw-bg-positive-hover hover:cw-border-positive-hover",
  warning: "hover:cw-bg-warning-hover hover:cw-border-warning-hover",
  destructive: "hover:cw-bg-destructive-hover hover:cw-border-destructive-hover",
  accent: "cw-bg-accent-emphasis cw-border-accent",
};

type Props = {
  sentiment: Sentiment;
  icon?: ReactNode;
  title?: ReactNode;
  body: ReactNode;
  onClick?: () => void;
};

export function CalloutBox({ sentiment, icon, title, body, onClick }: Props) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        "cw-p-4 cw-border-solid cw-border cw-flex cw-gap-4 cw-rounded-lg",
        SENTIMENT_CLASSES[sentiment],
        {
          [`cw-cursor-pointer ${SENTIMENT_CLICKABLE_CLASSES[sentiment]}`]: onClick,
        },
      )}
    >
      {icon && <div className="cw-self-start">{icon}</div>}
      <div className="cw-space-y-2">
        {title && <div className="cw-heading-lg">{title}</div>}
        <div className="cw-body-base">{body}</div>
      </div>
      {onClick && (
        <div className="cw-self-start cw-ml-auto">
          <ChevronRight className="cw-h-6 cw-w-6 cw-text-muted" />
        </div>
      )}
    </div>
  );
}
