// schema
import * as ISchema from "#webapp/src/__schema__";

import { StripeError } from "@stripe/stripe-js";

import { maybeGetBillingGroupsFromOrg } from "#webapp/src/util/billing-group.util";
import { getStateMetadataFromOrg } from "#webapp/src/util/feature-grid.util";
import { PaymentSubscription, ProductTypeEnum } from "@clockwise/schema";

//////////////////
// TYPES
//////////////////
export interface FeatureLine {
  info: {
    name: string;
    tooltip?: string;
  };
  plans: (string | boolean)[];
}

export interface FeatureSection {
  key: string;
  label: string;
  lines: FeatureLine[];
}

export type IPlanPricing = {
  monthlyCost: number;
  yearlyCost: number;
  contactSalesForPricing: boolean;
};

export type ITierType =
  | "FreemiumOrg"
  | "ProOrg"
  | "BusinessOrg"
  | "Starter"
  | "Team"
  | "Business"
  | "Enterprise";

export function getPrimarySubscription(org: ISchema.IOrg) {
  const stateMetadata = getStateMetadataFromOrg(org);
  if (!stateMetadata) {
    return null;
  }

  if (!stateMetadata.primaryBillingGroupMetadata) {
    return null;
  }

  return stateMetadata.primaryBillingGroupMetadata.subscription;
}

export function getPrimaryBillingGroup(org: ISchema.IOrg) {
  const stateMetadata = getStateMetadataFromOrg(org);
  if (!stateMetadata || !stateMetadata.primaryBillingGroupMetadata) {
    return null;
  }

  return stateMetadata.primaryBillingGroupMetadata.billingGroup;
}

//////////////////
// Primary Subscription Plan Helpers
//////////////////
export function isPrimarySubscriptionFreePlan(org: ISchema.IOrg) {
  return !getPrimarySubscription(org);
}

export function isPrimarySubscriptionProPlan(org: ISchema.IOrg) {
  const primarySubscription = getPrimarySubscription(org);

  if (!primarySubscription) {
    return false;
  }

  return isSubscriptionProductTypePro(primarySubscription);
}

export function isPrimarySubscriptionBusinessPlan(org: ISchema.IOrg) {
  const primarySubscription = getPrimarySubscription(org);

  if (!primarySubscription) {
    return false;
  }

  return isSubscriptionProductTypeBusiness(primarySubscription);
}

export function isPrimarySubscriptionEnterprisePlan(org: ISchema.IOrg) {
  const primarySubscription = getPrimarySubscription(org);

  if (!primarySubscription) {
    return false;
  }

  return isSubscriptionProductTypeEnterprise(primarySubscription);
}

export function isPrimarySubscriptionNonStandard(org: ISchema.IOrg) {
  const primarySubscription = getPrimarySubscription(org);

  if (!primarySubscription) {
    return false;
  }

  return isSubscriptionNonStandard(primarySubscription);
}

//////////////////
// Subscription Plan Helpers
//////////////////
export function isSubscriptionProductTypePro(subscription: ISchema.IPaymentSubscription) {
  return (
    subscription.productType === ISchema.ProductTypeEnum.Pro_NonStandard ||
    subscription.productType === ISchema.ProductTypeEnum.Pro_Standard
  );
}

export function isSubscriptionProductTypeBusiness(subscription: ISchema.IPaymentSubscription) {
  return (
    subscription.productType === ISchema.ProductTypeEnum.Business_NonStandard ||
    subscription.productType === ISchema.ProductTypeEnum.Business_Standard
  );
}

export function isSubscriptionProductTypeEnterprise(subscription: ISchema.IPaymentSubscription) {
  return (
    subscription.productType === ISchema.ProductTypeEnum.Enterprise_NonStandard ||
    subscription.productType === ISchema.ProductTypeEnum.Enterprise_Standard
  );
}

export function isSubscriptionNonStandard(subscription: ISchema.IPaymentSubscription) {
  return (
    subscription.productType === ISchema.ProductTypeEnum.Business_NonStandard ||
    subscription.productType === ISchema.ProductTypeEnum.Pro_NonStandard ||
    subscription.productType === ISchema.ProductTypeEnum.Enterprise_NonStandard
  );
}

export function isSubscriptionPaidPlan(subscription: ISchema.IPaymentSubscription) {
  return (
    isSubscriptionProductTypePro(subscription) ||
    isSubscriptionProductTypeBusiness(subscription) ||
    isSubscriptionProductTypeEnterprise(subscription)
  );
}

export function isTeamInPaidSubscription(org: ISchema.IOrg, team: ISchema.ITeam) {
  const billingGroups = maybeGetBillingGroupsFromOrg(org);

  if (!billingGroups) {
    return false;
  }

  return billingGroups.some((bg) => {
    return bg.syncSources.find((ss) => ss.type === "Team" && ss.value === team.teamId);
  });
}

export function getNicePlanNameForSubscription(subscription: PaymentSubscription) {
  switch (subscription.productType) {
    case ProductTypeEnum.Pro_NonStandard:
    case ProductTypeEnum.Pro_Standard:
      return "Teams";
    case ProductTypeEnum.Business_NonStandard:
    case ProductTypeEnum.Business_Standard:
      return "Business";
    case ProductTypeEnum.Enterprise_NonStandard:
    case ProductTypeEnum.Enterprise_Standard:
      return "Enterprise";
    default:
      return "";
  }
}

//////////////////
// Stripe Helpers
//////////////////

type ApolloStripeError = { __typename: "StripeError"; code: string | null; message: string | null };

export function generateStringFromStripeErrorCode(
  stripeError?: StripeError | ISchema.IStripeError | ApolloStripeError,
) {
  if (!stripeError) {
    return undefined;
  }

  switch (stripeError.code) {
    case "account_already_exists":
    case "account_country_invalid_address":
    case "account_invalid":
    case "account_number_invalid":
    case "alipay_upgrade_required":
    case "amount_too_large":
    case "amount_too_small":
    case "api_key_expired":
    case "authentication_required":
    case "balance_insufficient":
    case "bank_account_declined":
    case "bank_account_exists":
    case "bank_account_unusable":
    case "bank_account_unverified":
    case "bank_account_verification_failed":
    case "bitcoin_upgrade_required":
    case "card_decline_rate_limit_exceeded":
    case "charge_already_captured":
    case "charge_already_refunded":
    case "charge_disputed":
    case "charge_exceeds_source_limit":
    case "charge_expired_for_capture":
    case "charge_invalid_parameter":
    case "country_code_invalid":
    case "country_unsupported":
    case "coupon_expired":
    case "customer_max_payment_methods":
    case "customer_max_subscriptions":
    case "email_invalid":
    case "flinks_session_incomplete":
    case "idempotency_key_in_use":
    case "incorrect_address":
    case "instant_payouts_unsupported":
    case "intent_invalid_state":
    case "intent_verification_method_missing":
    case "invalid_card_type":
    case "invalid_characters":
    case "invalid_charge_amount":
    case "invalid_source_usage":
    case "invoice_no_customer_line_items":
    case "invoice_no_payment_method_types":
    case "invoice_no_subscription_line_items":
    case "invoice_not_editable":
    case "invoice_payment_intent_requires_action":
    case "invoice_upcoming_none":
    case "livemode_mismatch":
    case "lock_timeout":
    case "not_allowed_on_standard_account":
    case "order_creation_failed":
    case "order_required_settings":
    case "order_status_invalid":
    case "order_upstream_timeout":
    case "out_of_inventory":
    case "parameter_invalid_empty":
    case "parameter_invalid_integer":
    case "parameter_invalid_string_blank":
    case "parameter_invalid_string_empty":
    case "parameter_missing":
    case "parameter_unknown":
    case "parameters_exclusive":
    case "payment_intent_action_required":
    case "payment_intent_authentication_failure":
    case "payment_intent_incompatible_payment_method":
    case "payment_intent_invalid_parameter":
    case "payment_intent_payment_attempt_failed":
    case "payment_intent_unexpected_state":
    case "payment_method_invalid_parameter":
    case "payment_method_provider_decline":
    case "payment_method_provider_timeout":
    case "payment_method_unactivated":
    case "payment_method_unexpected_state":
    case "payouts_not_allowed":
    case "platform_api_key_expired":
    case "postal_code_invalid":
    case "product_inactive":
    case "rate_limit":
    case "resource_already_exists":
    case "resource_missing":
    case "routing_number_invalid":
    case "secret_key_required":
    case "sepa_unsupported_account":
    case "setup_attempt_failed":
    case "setup_intent_authentication_failure":
    case "setup_intent_invalid_parameter":
    case "setup_intent_unexpected_state":
    case "shipping_calculation_failed":
    case "sku_inactive":
    case "state_unsupported":
    case "tax_id_invalid":
    case "taxes_calculation_failed":
    case "terminal_location_country_unsupported":
    case "testmode_charges_only":
    case "tls_version_unsupported":
    case "token_already_used":
    case "token_in_use":
    case "transfers_not_allowed":
    case "upstream_order_creation_failed":
    case "url_invalid":

    case "invalid_number":
      return "The card number is not a valid credit card number";
    case "invalid_expiry_month":
      return "The card's expiration month is invalid";
    case "invalid_expiry_year":
      return "The card's expiration year is invalid";
    case "invalid_cvc":
      return "The card's security code is invalid";
    case "incorrect_number":
      return "The card number is incorrect";
    case "incomplete_number":
      return "The card number is incomplete";
    case "incomplete_cvc":
      return "The card's security code is incomplete";
    case "incomplete_expiry":
      return "The card's expiration date is incomplete";
    case "expired_card":
      return "The card has expired";
    case "incorrect_cvc":
      return "The card's security code is incorrect";
    case "incorrect_zip":
    case "customer_tax_location_invalid":
      return "The card's zip code failed validation";
    case "invalid_expiry_year_past":
      return "The card's expiration year is in the past";
    case "card_declined":
      return "The card was declined";
    case "missing":
      return "There is no card on a customer that is being charged";
    case "processing_error":
      return "An error occurred while processing the card";

    default:
      return "Something went wrong!";
  }
}
