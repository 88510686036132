import { getSiteUrl } from "@clockwise/client-commons/src/config/api";

export class InterAppPayload {
  public static seqId = 0;
  public readonly source: "clockwise" = "clockwise";
  public seqId: number;

  constructor(public type: string, public payload: Object | undefined) {
    this.seqId = InterAppPayload.seqId++;
  }
}

export const PayloadTypes = {
  AUTHENTICATED: "Authenticated",
  AUTOPILOT_CARDS_NOTIFICATION_COUNT: "AutopilotCardsNotificationCount",
  CALENDAR_MISMATCH: "CalendarMismatch",
  CALENDAR_TOGGLE: "CalendarToggle",
  CALENDAR_URL: "CalendarUrl",
  CALENDAR_USER_EMAIL: "CalendarUserEmail", // deprecate
  CALENDAR_VIEW: "CalendarView",
  RESET_SCHEDULE_WITH_AI_BUTTON_CLOSED: "ResetScheduleWithAiButtonClosed",

  /** @deprecated */
  CHROME_EXTENSION_CHROME_DIALOG_OPEN: "ChromeExtensionChromeDialogOpen",
  /** @deprecated */
  CHROME_EXTENSION_CLICKED: "ChromeExtensionClicked",
  /** @deprecated */
  CHROME_EXTENSION_ERROR: "ChromeExtensionError",
  /** @deprecated */
  CHROME_EXTENSION_LOG: "ChromeExtensionLog",
  /** @deprecated */
  CHROME_EXTENSION_HOVERED: "ChromeExtensionHovered",
  /** @deprecated */
  CHROME_EXTENSION_IDLE: "ChromeExtensionIdle",
  /** @deprecated */
  CHROME_EXTENSION_INTERCOM_OPEN: "ChromeExtensionIntercomOpen",
  /** @deprecated */
  CHROME_EXTENSION_OPEN: "ChromeExtensionOpen",
  /** @deprecated */
  CHROME_EXTENSION_SHADOW_CALENDAR_ACTIVE: "ChromeExtensionShadowCalendarActive",
  /** @deprecated */
  CHROME_EXTENSION_VERSION: "ChromeExtensionVersion",
  /** @deprecated */
  IS_CHROME_EXTENSION: "IsChromeExtension",

  WEB_EXTENSION_WEB_DIALOG_OPEN: "WebExtensionWebDialogOpen",
  WEB_EXTENSION_CLICKED: "WebExtensionClicked",
  /** @deprecated Errors are reported to Sentry directly within the extension now. */
  WEB_EXTENSION_ERROR: "WebExtensionError",
  WEB_EXTENSION_EVENT_INFO: "EventInfo",
  WEB_EXTENSION_LOG: "WebExtensionLog",
  WEB_EXTENSION_HOVERED: "WebExtensionHovered",
  WEB_EXTENSION_IDLE: "WebExtensionIdle",
  WEB_EXTENSION_INTERCOM_OPEN: "WebExtensionIntercomOpen",
  WEB_EXTENSION_OPEN: "WebExtensionOpen",
  WEB_EXTENSION_SHADOW_CALENDAR_ACTIVE: "WebExtensionShadowCalendarActive",
  WEB_EXTENSION_VERSION: "WebExtensionVersion",
  IS_WEB_EXTENSION: "IsWebExtension",

  CLEAR_QUERY_PARAMS: "ClearQueryParams",
  CREATE_EVENT: "CreateEvent",
  DEBUG_MESSAGE: "DebugMessage",
  EDIT_EVENT: "EditEvent",
  EDIT_EVENT_INFO: "EditEventInfo",
  ENABLE_EXPERIMENT: "EnableExperiment",
  EVENT_EDIT_HOVERED: "EventEditHovered",
  BULK_EXPERIMENTS: "BulkExperiments",
  EVENT_DELETE_BUTTON_CLICKED: "EventDeleteButtonClicked",
  EVENT_VIEW: "EventView",
  FAST_CRAWL_FLOW_STATE_CURRENT_STATUS: "FastCrawlFlowStateCurrentStatus",
  FETCH_QUERY: "FetchQuery",
  COMMIT_MUTATION: "CommitMutation",
  FETCH_APOLLO_QUERY: "FetchApolloQuery",
  COMMIT_APOLLO_MUTATION: "CommitApolloMutation",
  SEND_QUERY_COLLECTION: "SendQueryCollection",
  SEND_MUTATION_COLLECTION: "SendMutationCollection",
  LOGS: "Logs",
  GOOGLE_VERSION: "GoogleVersion",
  GO_TO_DATE: "GoToDate",
  GO_TO_EVENT_EDIT: "GoToEventEdit",
  HEALTH_CHECK: "HealthCheck",
  HIGHLIGHT_CALENDAR_SELECTION_BOXES: "HighlightCalendarSelectionBoxes",
  INITIAL_HANDSHAKE: "InitialHandshake",
  INITIAL_LOAD_COMPLETE: "InitialLoadComplete",
  INIT_TIME: "InitTime",
  ONBOARDING_FLOW_STATE_CURRENT_STATUS: "OnboardingFlowStateCurrentStatus",
  ORG_DOMAINS: "OrgDomains",
  PRE_EVENT_FLEXIBILITY: "PreEventFlexibility",
  PRICING_DIALOG_OPEN: "PricingDialogOpen",
  SCHEDULER_OPEN: "SchedulerOpen",
  SELECTION_STATES: "SelectionStates",
  SET_SELECTION_STATES: "SetSelectionStates",
  SET_EVENT_TAGS: "SetEventTags",
  SET_EVENT_AUTOPILOT_STATUS: "SetEventAutopilotStatus",
  SET_OPTIMIZATION_NOTIFICATION: "SetOptimizationNotification",
  RESET_OPTIMIZATION_NOTIFICATION: "RsetOptimizationNotification",
  SET_ACTIVE_EVENT: "SetActiveEvent",
  SHADOW_CALENDAR_SETTINGS: "ShadowCalendarSettings",
  SHADOW_CALENDAR_TOGGLE: "ShadowCalendarToggle",
  // Cleanup: https://app.asana.com/0/1204172057858026/1204351711275414
  SHOW_DAY_OF_WEEK: "ShowDayOfWeek",
  SHOW_EVENT_ON_CALENDAR: "ShowEventOnCalendar",
  SHOW_TEAM_CALENDAR_TIP: "ShowTeamCalendarTip",
  SIGN_IN_NEEDED: "SignInNeeded",
  STATS_D_ACTION: "StatsDAction",
  SUGGESTION_COUNT: "SuggestionCount",
  SYNC_ACTIVE_ORG: "SyncActiveOrg",
  TIME_ZONE: "TimeZone",
  TRACK: "Track",
  SNACK_BAR_OPEN: "SnackBarOpen",
  UNSUBSCRIBE_CALENDAR_ID: "UnsubscribeCalendarId",
  USER_CALENDAR_IDS: "UserCalendarIds",
  USER_FLAGS: "UserFlags",
  SMART_HOLD_UPDATED: "SmartHoldUpdated",
  EVENT_FLEX_UPDATED: "EventFlexUpdated",
  WEBAPP_UPDATE: "WebappUpdate",
  WINDOW_USER_EMAIL: "WindowUserEmail",
  SET_USER_GOALS: "SetUserGoals",
  SET_FEATURE_GRID: "SetFeatureGrid",
  SET_ORG_BILLING_GROUP_IDS: "SetOrgBillingGroupIds",
  SYNCED_ACTION: "SyncedAction",
  COLOR_SETTINGS: "ColorSettings",
  USER: "User",
  // webapp in the iframe cant access the browser's Clipboard API, so send to extension to access the Clipboard.
  COPY_TO_CLIPBOARD: "CopyToClipboard",
  COPY_RICH_TEXT_TO_CLIPBOARD: "CopyRichTextToClipboard",
  EXTENSION_TAB_VISIBILITY_CHANGED: "ExtensionTabVisibilityChanged",
  SET_OPEN_SHARE_WITH_COWORKERS_MODAL: "SetOpenShareWithCoworkersModal",
  SHOULD_EXCLUDE_AI: "ShouldExcludeAi",
  QUICK_RESCHEDULE: "QuickReschedule",
};

export const QueryStringParams = {
  Open: "cwopen",
  Invite: "cwinvite",
  Slack: "cwslack",
  SmartHolds: "cwsmartholds",
  PersonalCalendar: "cwpersonalcal",
  ColorCoding: "cwcolors",
  InstallEvent: "cwinstall",
  Plans: "cwplans",
  Lunch: "cwlunch",
  MeetingBreaks: "cwmeetingbreaks",
  EventId: "eid", // This is Gcal's event id params
  ShareSchedulingLink: "share-scheduling-link",
} as const;

export class BasePostMessageManager {
  private queuedMessages: InterAppPayload[];
  private window?: Window;
  public otherWindowUrl: string;
  public payloadTypes = PayloadTypes;

  constructor(url: string) {
    this.queuedMessages = [];
    this.otherWindowUrl = url;
  }

  public initBase = (window: Window) => {
    this.window = window;
    this.send();
  };

  private queuePayload = (data: InterAppPayload) => {
    this.queuedMessages.push(data);
  };

  private sendQueuedMessages = (window: Window) => {
    this.queuedMessages.map((message) => {
      window.postMessage(message, this.otherWindowUrl);
    });
    this.queuedMessages = [];
  };

  public send = (data?: InterAppPayload) => {
    if (data) {
      // queue data, if we have it
      this.queuePayload(data);
    }

    if (this.window) {
      // if we're ready to send, send away
      this.sendQueuedMessages(this.window);
    }
  };
}

export const ChromeIdleThreshold = 300000; // 5 MINUTES

/**
 * @deprecated Use `getSiteUrl` from `@clockwise/client-commons/src/config/api` instead
 */
export function getServerUrl() {
  return getSiteUrl();
}
