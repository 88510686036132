import jwtDecode, { JwtPayload } from "jwt-decode";

type JwtType = string;
type JwtStatus = string;
export interface ClockwiseJwtPayload extends JwtPayload {
  type?: JwtType;
  status?: JwtStatus;
  emails?: string[];
}

export const parseJwt = (jwt: string | null) => {
  if (!jwt) {
    return null;
  }
  return jwtDecode<ClockwiseJwtPayload>(jwt);
};

const ImpersonationTypes = ["Impersonated", "Obfuscated"];
export const isImpersonated = (jwt: string | null) => {
  const { type } = parseJwt(jwt) ?? {};
  return !!type && ImpersonationTypes.includes(type);
};
