import moment from "moment";
import { Inclusivity, MomentUnits, MomentUnitsWithAbbr, getTimeZoneGuess } from "./time-zone";

export class ZonedMoment {
  public static getRenderTimeZone = getTimeZoneGuess;
  public timeZone: string;
  private localizedMoment: moment.Moment;
  private wrapMoment = (m: moment.Moment) => new ZonedMoment(m, this.timeZone);
  private unwrapMoment(
    time: moment.MomentInput | ZonedMoment,
    format?: moment.MomentFormatSpecification,
    strict?: boolean,
  ) {
    if (time instanceof ZonedMoment) {
      return time.getMoment();
    }
    return moment(time, format, strict);
  }

  constructor(
    time?: moment.MomentInput | ZonedMoment,
    renderTimeZone?: string,
    format?: moment.MomentFormatSpecification,
    strict?: boolean,
  ) {
    this.timeZone = renderTimeZone ? renderTimeZone : ZonedMoment.getRenderTimeZone();
    this.localizedMoment = this.unwrapMoment(time, format, strict).tz(this.timeZone);
  }

  public getMoment = (): moment.Moment => this.localizedMoment;

  public clone = (): ZonedMoment => this.wrapMoment(this.localizedMoment.clone());

  public milliseconds(): number;
  public milliseconds(input: number): ZonedMoment;
  public milliseconds(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.milliseconds(input));
    }
    return this.localizedMoment.milliseconds();
  }

  public millisecond(): number;
  public millisecond(input: number): ZonedMoment;
  public millisecond(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.milliseconds(input);
    }
    return this.milliseconds();
  }

  public seconds(): number;
  public seconds(input: number): ZonedMoment;
  public seconds(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.seconds(input));
    }
    return this.localizedMoment.seconds();
  }

  public second(): number;
  public second(input: number): ZonedMoment;
  public second(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.seconds(input);
    }
    return this.seconds();
  }

  public minutes(): number;
  public minutes(input: number): ZonedMoment;
  public minutes(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.minutes(input));
    }
    return this.localizedMoment.minutes();
  }

  public minute(): number;
  public minute(input: number): ZonedMoment;
  public minute(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.minutes(input);
    }
    return this.minutes();
  }

  public hours(): number;
  public hours(input: number): ZonedMoment;
  public hours(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.hours(input));
    }
    return this.localizedMoment.hours();
  }

  public hour(): number;
  public hour(input: number): ZonedMoment;
  public hour(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.hours(input);
    }
    return this.hours();
  }

  public date(): number;
  public date(input: number): ZonedMoment;
  public date(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.date(input));
    }
    return this.localizedMoment.date();
  }

  public dates(): number;
  public dates(input: number): ZonedMoment;
  public dates(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.date(input);
    }
    return this.date();
  }

  public days(): number;
  public days(input: number | string): ZonedMoment;
  public days(input?: number | string): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.days(input));
    }
    return this.localizedMoment.days();
  }

  public day(): number;
  public day(input: number | string): ZonedMoment;
  public day(input?: number | string): number | ZonedMoment {
    if (input !== undefined) {
      return this.days(input);
    }
    return this.days();
  }

  public weekday(): number;
  public weekday(input: number): ZonedMoment;
  public weekday(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.weekday(input));
    }
    return this.localizedMoment.weekday();
  }

  public isoWeekday(): number;
  public isoWeekday(input: number): ZonedMoment;
  public isoWeekday(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.isoWeekday(input));
    }
    return this.localizedMoment.isoWeekday();
  }

  public dayOfYear(): number;
  public dayOfYear(input: number): ZonedMoment;
  public dayOfYear(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.dayOfYear(input));
    }
    return this.localizedMoment.dayOfYear();
  }

  public weeks(): number;
  public weeks(input: number): ZonedMoment;
  public weeks(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.weeks(input));
    }
    return this.localizedMoment.weeks();
  }

  public week(): number;
  public week(input: number): ZonedMoment;
  public week(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.weeks(input);
    }
    return this.weeks();
  }

  public isoWeeks(): number;
  public isoWeeks(input: number): ZonedMoment;
  public isoWeeks(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.isoWeeks(input));
    }
    return this.localizedMoment.isoWeeks();
  }

  public isoWeek(): number;
  public isoWeek(input: number): ZonedMoment;
  public isoWeek(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.isoWeeks(input);
    }
    return this.isoWeeks();
  }

  public months(): number;
  public months(input: number | string): ZonedMoment;
  public months(input?: number | string): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.months(input));
    }
    return this.localizedMoment.months();
  }

  public month(): number;
  public month(input: number | string): ZonedMoment;
  public month(input?: number | string): number | ZonedMoment {
    if (input !== undefined) {
      return this.months(input);
    }
    return this.months();
  }

  public quarter(): number;
  public quarter(input: number): ZonedMoment;
  public quarter(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.quarter(input));
    }
    return this.localizedMoment.quarter();
  }

  public year(): number;
  public year(input: number): ZonedMoment;
  public year(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.year(input));
    }
    return this.localizedMoment.year();
  }

  public years(): number;
  public years(input: number): ZonedMoment;
  public years(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.years(input);
    }
    return this.years();
  }

  public weekYear(): number;
  public weekYear(input: number): ZonedMoment;
  public weekYear(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.weekYear(input));
    }
    return this.localizedMoment.weekYear();
  }

  public isoWeekYear(): number;
  public isoWeekYear(input: number): ZonedMoment;
  public isoWeekYear(input?: number): number | ZonedMoment {
    if (input !== undefined) {
      return this.wrapMoment(this.localizedMoment.isoWeekYear(input));
    }
    return this.localizedMoment.isoWeekYear();
  }

  public weeksInYear = (): number => this.localizedMoment.weeksInYear();

  public isoWeeksInYear = (): number => this.localizedMoment.isoWeeksInYear();

  public max = (...moments: ZonedMoment[]): ZonedMoment => {
    const rawMoments = moments.map((m) => m.getMoment());
    return this.wrapMoment(this.localizedMoment.max(...rawMoments));
  };

  public min = (...moments: ZonedMoment[]): ZonedMoment => {
    const rawMoments = moments.map((m) => m.getMoment());
    return this.wrapMoment(this.localizedMoment.min(...rawMoments));
  };

  public add = (time: moment.DurationInputArg1, unit: MomentUnitsWithAbbr): ZonedMoment => {
    return this.wrapMoment(this.localizedMoment.add(time, unit));
  };

  public subtract = (time: moment.DurationInputArg1, unit: MomentUnitsWithAbbr): ZonedMoment => {
    return this.wrapMoment(this.localizedMoment.subtract(time, unit));
  };

  public startOf = (unit: MomentUnits): ZonedMoment => {
    return this.wrapMoment(this.localizedMoment.startOf(unit));
  };

  public endOf = (unit: MomentUnits): ZonedMoment => {
    return this.wrapMoment(this.localizedMoment.endOf(unit));
  };

  public utc = (): ZonedMoment => this.wrapMoment(this.localizedMoment.utc());

  public utcOffset(): number;
  public utcOffset(minutes: number): ZonedMoment;
  public utcOffset(minutes: number, keepExistingTimeOfDay: boolean): ZonedMoment;
  public utcOffset(
    minutes?: number | string,
    keepExistingTimeOfDay?: boolean,
  ): number | ZonedMoment {
    if (minutes === undefined) {
      return this.localizedMoment.utcOffset();
    } else if (!keepExistingTimeOfDay) {
      return this.wrapMoment(this.localizedMoment.utcOffset(minutes));
    }
    return this.wrapMoment(this.localizedMoment.utcOffset(minutes, keepExistingTimeOfDay));
  }

  public format = (input?: string): string => {
    if (input) {
      return this.localizedMoment.format(input);
    }
    return this.localizedMoment.format();
  };

  public diff = (
    time: moment.MomentInput | ZonedMoment,
    unit?: MomentUnitsWithAbbr,
    floatNumber?: boolean,
  ): number => {
    const m = this.unwrapMoment(time);
    if (!unit) {
      return this.localizedMoment.diff(m);
    } else if (!floatNumber) {
      return this.localizedMoment.diff(m, unit);
    }
    return this.localizedMoment.diff(m, unit, floatNumber);
  };

  public valueOf = (): number => this.localizedMoment.valueOf();

  public unix = (): number => this.localizedMoment.unix();

  public daysInMonth = (): number => this.localizedMoment.daysInMonth();

  public toDate = (): Date => this.localizedMoment.toDate();

  public toArray = (): number[] => this.localizedMoment.toArray();

  public toJSON = (): string => this.localizedMoment.toJSON();

  public toISOString = (): string => this.localizedMoment.toISOString();

  public toObject = (): object => this.localizedMoment.toObject();

  public toString = (): string => this.localizedMoment.toString();

  public inspect = (): string => this.localizedMoment.inspect();

  public isBefore = (
    time: moment.MomentInput | ZonedMoment,
    precision?: MomentUnitsWithAbbr,
  ): boolean => {
    return this.localizedMoment.isBefore(this.unwrapMoment(time), precision);
  };

  public isSame = (
    time: moment.MomentInput | ZonedMoment,
    precision?: MomentUnitsWithAbbr,
  ): boolean => {
    return this.localizedMoment.isSame(this.unwrapMoment(time), precision);
  };

  public isAfter = (
    time: moment.MomentInput | ZonedMoment,
    precision?: MomentUnitsWithAbbr,
  ): boolean => {
    return this.localizedMoment.isAfter(this.unwrapMoment(time), precision);
  };

  public isSameOrBefore = (
    time: moment.MomentInput | ZonedMoment,
    precision?: MomentUnitsWithAbbr,
  ): boolean => {
    return this.localizedMoment.isSameOrBefore(this.unwrapMoment(time), precision);
  };

  public isSameOrAfter = (
    time: moment.MomentInput | ZonedMoment,
    precision?: MomentUnitsWithAbbr,
  ): boolean => {
    return this.localizedMoment.isSameOrAfter(this.unwrapMoment(time), precision);
  };

  public isBetween = (
    timeA: moment.MomentInput | ZonedMoment,
    timeB: moment.MomentInput | ZonedMoment,
    precision?: MomentUnitsWithAbbr,
    inclusivity?: Inclusivity,
  ): boolean => {
    return this.localizedMoment.isBetween(
      this.unwrapMoment(timeA),
      this.unwrapMoment(timeB),
      precision,
      inclusivity,
    );
  };

  public isDST = (): boolean => this.localizedMoment.isDST();

  public isDSTShifted = (): boolean => this.localizedMoment.isDSTShifted();

  public isLeapYear = (): boolean => this.localizedMoment.isLeapYear();

  public fromNow = (noSuffix?: boolean): string => {
    return this.localizedMoment.fromNow(!!noSuffix);
  };

  public fractionalHour = (): number => this.hour() + this.minute() / 60;

  public toRelativeDayPercentage = (): number => this.fractionalHour() / 24;

  public calendar = (): string => this.localizedMoment.calendar();
}
