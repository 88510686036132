export const prettyList = (a: any[]) => {
  return [a.slice(0, -1).join(", "), a.slice(-1)[0]].join(a.length < 2 ? "" : ", and ");
};

export function capitalizeTxt(txt: string) {
  return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
}

export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
