import { first } from "lodash";
import {
  EventCardsByCalendar,
  EventCardsByDayAndCalendar,
  PlannerEventCard,
  PlannerEventCardsByDay,
} from "../types";

// Takes in prerprocessed normal, allDay, and allDayOOO events and returns them by day and calendar
export const getEventsByDayAndCalendar = (
  normalEventsByDay: PlannerEventCardsByDay,
  allDayOOOEventsByDay: PlannerEventCardsByDay,
  allDayEventsByDay: PlannerEventCardsByDay,
  calendarIds: string[],
  teamCalendarIds: string[],
  currentUserCalendarId: string,
): EventCardsByDayAndCalendar => {
  const calendarIdSet = new Set(calendarIds);
  const teamCalendarSet = new Set(teamCalendarIds);
  const multiCalEventsByDayAndCalendar: EventCardsByDayAndCalendar = {};
  Object.entries(normalEventsByDay ?? {}).forEach(([day, normalEvents]) => {
    const eventsForDay: EventCardsByCalendar = {
      allDayEvents: {},
      allDayOOOEvents: {},
      normalEvents: {},
      proposalEvents: [],
    };
    const allDayEvents = allDayEventsByDay[day] ?? [];
    const allDayOOOEvents = allDayOOOEventsByDay[day] ?? [];

    const normalEventsByCalendar: Record<string, PlannerEventCard[]> = {};
    const allDayEventsByCalendar: Record<string, PlannerEventCard[]> = {};
    const allDayOOOEventsByCalendar: Record<string, PlannerEventCard[]> = {};
    let proposalEventsForDay: PlannerEventCard[] = [];

    calendarIds.forEach((calendarId) => {
      normalEventsByCalendar[calendarId] = [];
      allDayEventsByCalendar[calendarId] = [];
      allDayOOOEventsByCalendar[calendarId] = [];
    });

    normalEvents.forEach((normalEvent) => {
      const calendarId = first(normalEvent.calendarIds);

      if (calendarId && calendarIdSet.has(calendarId)) {
        normalEventsByCalendar[calendarId] = [...normalEventsByCalendar[calendarId], normalEvent];
      } else if (calendarId && teamCalendarSet.has(calendarId)) {
        // if team calendar, add to primaryCalnedarId
        normalEventsByCalendar[currentUserCalendarId] = [
          ...normalEventsByCalendar[currentUserCalendarId],
          normalEvent,
        ];
      } else if (normalEvent.proposalMetaData) {
        // if proposal, add to current user b/c proposal cards don't have calendarIds
        if (!normalEvent.proposalMetaData.isConsequence) {
          // only add non consequence proposal events
          proposalEventsForDay.push(normalEvent);
        } else {
          normalEventsByCalendar[currentUserCalendarId] = [
            ...normalEventsByCalendar[currentUserCalendarId],
            normalEvent,
          ];
        }
      }
    });

    allDayEvents.forEach((allDayEvent) => {
      const calendarId = first(allDayEvent.calendarIds);
      if (calendarId && calendarIdSet.has(calendarId)) {
        allDayEventsByCalendar[calendarId] = [...allDayEventsByCalendar[calendarId], allDayEvent];

        // if all day event is longer than 1 day, add to the other applicable days
        if (allDayEvent.interval.toDuration().as("days") > 1) {
          const numberOfDays = allDayEvent.interval.toDuration().as("days");
          for (let i = 1; i < numberOfDays; i++) {
            const newDay = allDayEvent.interval.start.plus({ days: i }).toFormat("yyyy-MM-dd");
            const existingEvents = allDayEventsByDay[newDay] ?? [];
            allDayEventsByDay[newDay] = [...existingEvents, allDayEvent];
          }
        }
      } else if (calendarId && teamCalendarSet.has(calendarId)) {
        // if team calendar, add to primaryCalnedarId
        allDayEventsByCalendar[currentUserCalendarId] = [
          ...allDayEventsByCalendar[currentUserCalendarId],
          allDayEvent,
        ];
      } else if (allDayEvent.proposalType) {
        // if proposal, add to current user b/c proposal cards don't have calendarIds
        allDayEventsByCalendar[currentUserCalendarId] = [
          ...allDayEventsByCalendar[currentUserCalendarId],
          allDayEvent,
        ];
      }
    });

    allDayOOOEvents.forEach((allDayOOOEvent) => {
      const calendarId = first(allDayOOOEvent.calendarIds);
      if (calendarId && calendarIdSet.has(calendarId)) {
        allDayOOOEventsByCalendar[calendarId] = [
          ...allDayOOOEventsByCalendar[calendarId],
          allDayOOOEvent,
        ];
      } else if (calendarId && teamCalendarSet.has(calendarId)) {
        // if team calendar, add to primaryCalnedarId
        allDayOOOEventsByCalendar[currentUserCalendarId] = [
          ...allDayOOOEventsByCalendar[currentUserCalendarId],
          allDayOOOEvent,
        ];
      } else if (allDayOOOEvent.proposalType) {
        // if proposal, add to current user b/c proposal cards don't have calendarIds
        allDayOOOEventsByCalendar[currentUserCalendarId] = [
          ...allDayOOOEventsByCalendar[currentUserCalendarId],
          allDayOOOEvent,
        ];
      }
    });

    // if proposal events is larger than one, it's a bulk case and we need to move the events back into normal events
    if (proposalEventsForDay.length > 1) {
      proposalEventsForDay.forEach((proposalEvent) => {
        const proposalAttendees = proposalEvent.proposalMetaData?.attendeeIds;
        proposalAttendees?.forEach((attendeeId) => {
          if (calendarIds.includes(attendeeId)) {
            normalEventsByCalendar[attendeeId] = [
              ...normalEventsByCalendar[attendeeId],
              proposalEvent,
            ];
          }
        });
      });

      proposalEventsForDay = [];
    }

    eventsForDay.allDayEvents = allDayEventsByCalendar;
    eventsForDay.allDayOOOEvents = allDayOOOEventsByCalendar;
    eventsForDay.normalEvents = normalEventsByCalendar;
    eventsForDay.proposalEvents = proposalEventsForDay;

    multiCalEventsByDayAndCalendar[day] = eventsForDay;
  });

  return multiCalEventsByDayAndCalendar;
};
