import { paths } from "@clockwise/client-commons/src/constants/site";
import { InfoOutlined } from "@clockwise/design-system/icons";
import React from "react";

export const JoinExistingPlanBanner = () => {
  return (
    <div className="cw-flex cw-justify-center cw-items-center cw-w-full cw-rounded cw-py-5 cw-mt-7 cw-mb-4 cw-bg-positive cw-font-bold cw-body-base">
      <InfoOutlined className="cw-text-brand cw-mr-2" />
      Your organization has existing subscriptions. Visit{" "}
      <a
        href={`${paths.admin}/billing`}
        rel="noreferrer"
        target="_blank"
        className="cw-underline cw-mx-1 cw-cursor-pointer cw-text-default hover:cw-text-default-hover"
      >
        Plans and Billing
      </a>{" "}
      to join a plan.
    </div>
  );
};
