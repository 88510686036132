import { graphql } from "react-relay";

export const validateSlackChannelBySendingMessageMutation = graphql`
  mutation ValidateSlackChannelBySendingMessageMutation(
    $input: ValidateSlackChannelBySendingMessageMutationInput!
  ) {
    validateSlackChannelBySendingMessage(input: $input) {
      clientMutationId
      validateSlackChannelResponse {
        __typename
        id
        isValid
        channel {
          __typename
          channelId
          name
        }
      }
    }
  }
`;
