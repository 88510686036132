import { fg_accent_onEmphasis, fg_onEmphasis } from "@clockwise/design-system/tokens";
import * as React from "react";

type OtherLogoColors = "neutral" | "bright" | "default";
export interface IProps {
  className?: string;
  style?: React.CSSProperties;
  fill?: OtherLogoColors;
  size?: number | string;
  /**
   * @deprecated use fill="bright" instead
   */
  onEmphasis?: boolean;
}

// TODO: Use pellegrino token when available
const PELLEGRINO = "#00A972";

const logoOtherColors: { [k in OtherLogoColors]: string } = {
  neutral: fg_onEmphasis,
  bright: fg_accent_onEmphasis,
  default: PELLEGRINO,
};

export const LogoClock = ({
  className = undefined,
  style,
  fill = "default",
  size = "100%",
  onEmphasis,
}: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{ width: size, height: size, ...style }}
    viewBox="0 0 97.48 97.46"
  >
    <g>
      <path
        fill={logoOtherColors[onEmphasis ? "bright" : fill]}
        d="M46.79,89.83c0,2.61-.26,5.16-.75,7.63-6.35-.86-12.32-4.79-15.22-11.79l-.25-.6c-3.41-8.23-9.94-14.77-18.18-18.18l-.62-.26C4.8,63.75,.87,57.79,0,51.44c2.47-.49,5.03-.75,7.65-.75,21.62,0,39.14,17.52,39.14,39.14Zm0-82.19c0-2.61-.26-5.17-.75-7.64-6.35,.86-12.32,4.79-15.21,11.79l-.25,.61c-3.4,8.23-9.94,14.76-18.16,18.17l-.63,.26C4.79,33.72,.87,39.68,0,46.03c2.47,.49,5.03,.75,7.65,.75,21.62,0,39.14-17.52,39.14-39.14Zm19.86,78.03l.25-.6c3.41-8.23,9.95-14.77,18.18-18.18l.61-.25c6.99-2.89,10.92-8.86,11.79-15.21-2.47-.49-5.03-.75-7.65-.75-21.62,0-39.14,17.52-39.14,39.14,0,2.61,.26,5.16,.75,7.63,6.35-.86,12.32-4.79,15.21-11.79Zm23.18-38.89c2.62,0,5.17-.26,7.65-.75-.87-6.34-4.79-12.31-11.79-15.2l-.62-.26c-8.23-3.41-14.76-9.94-18.17-18.17l-.25-.61C63.76,4.79,57.79,.86,51.44,0c-.49,2.47-.75,5.02-.75,7.64,0,21.62,17.52,39.14,39.14,39.14Z"
      />
    </g>
  </svg>
);
