//////////////////////////////////////////
// NOTE: ONLY FOR USE IN OTHER UTILS
// DO NOT IMPORT INTO APP FILE DIRECTLY
//////////////////////////////////////////
// schema
import * as ISchema from "#webapp/src/__schema__";

// util
import { logger } from "#webapp/src/util/logger.util";
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";

// helper function to only warn outside of test environment
export function warnOutsideTests(message: string) {
  if (getEnvironment() === "test") {
    return;
  }
  logger.warn(message);
}

// helper function to only warn outside of test environment
export function maybeWarnOutsideTests(isValid: boolean, message: string) {
  !isValid && warnOutsideTests(message);
}

/////////////////////////
// FRAGMENT VALIDATORS
/////////////////////////

// ~-~-~-~-~-~-~-
// Team Health
// ~-~-~-~-~-~-~-
export function validateOrgWithTeamHealth(org: ISchema.IOrg, userHasNoTeams?: boolean) {
  const teamHealthScoreErrorable = userHasNoTeams
    ? org.teamHealthScoreJustForUserErrorable
    : org.teamHealthScoreForTeamErrorable;

  const isValidForTeamHealth =
    !!teamHealthScoreErrorable &&
    teamHealthScoreErrorable.__typename !== "GraphEntityError" &&
    !!teamHealthScoreErrorable.myFocusTimeScore &&
    !!teamHealthScoreErrorable.myFocusTimeScore.calendarId &&
    !!teamHealthScoreErrorable.calendarFocusTimeScores &&
    !!teamHealthScoreErrorable.persons &&
    !!teamHealthScoreErrorable.persons.__typename;

  if (!isValidForTeamHealth) {
    warnOutsideTests("team-health util was passed an incomplete org");
  }
  return isValidForTeamHealth;
}

export function validateOrgWithTeamHealthMaxMinutes(org: ISchema.IOrg, userHasNoTeams?: boolean) {
  const isValidForTeamHealth = validateOrgWithTeamHealth(org, userHasNoTeams);
  const teamHealthScoreErrorable = userHasNoTeams
    ? org.teamHealthScoreJustForUserErrorable
    : org.teamHealthScoreForTeamErrorable;

  const isValidForMaxMinutes =
    isValidForTeamHealth &&
    teamHealthScoreErrorable.__typename !== "GraphEntityError" &&
    typeof teamHealthScoreErrorable.myFocusTimeScore.totalMinutes === "number" &&
    (!teamHealthScoreErrorable.calendarFocusTimeScores.length ||
      typeof teamHealthScoreErrorable.calendarFocusTimeScores[0].totalMinutes === "number");

  if (!isValidForMaxMinutes) {
    warnOutsideTests(`team-health util needs totalMinutes on calendarFocusTime fragments`);
  }
  return isValidForMaxMinutes;
}
