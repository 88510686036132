import { Divider } from "@clockwise/design-system";
import React from "react";
import { ITierContent } from "../../../constants/org-pricing.constants";
import ContactUsButton from "../cta-buttons/ContactUsButton";
import {
  FeatureBullet,
  Tier,
  TierButton,
  TierDescription,
  TierFeatureHeader,
  TierTitle,
} from "./components";
type EnterpriseTierHeaderProps = {
  tier: ITierContent;
  isCurrentPlan: boolean;
  onSelect: () => void;
  isFullWidth: boolean;
  isM365Beta: boolean;
};

const EnterpriseTierHeader = ({
  tier,
  onSelect,
  isCurrentPlan,
  isFullWidth,
  isM365Beta,
}: EnterpriseTierHeaderProps) => {
  return (
    <Tier isFullWidth={isFullWidth}>
      <TierTitle text={tier.title} />
      <div className="cw-flex cw-items-center cw-gap-2 cw-my-2">
        <span className="cw-heading-3xl cw-text-center">Let's Talk!</span>
      </div>
      <TierDescription> {tier.costUnit}</TierDescription>
      <TierButton>
        <ContactUsButton isCurrentPlan={isCurrentPlan} onClick={onSelect} isM365Beta={isM365Beta} />
      </TierButton>
      <TierDescription>
        For organizations with advanced security and customization needs
      </TierDescription>
      <Divider className="cw-flex-shrink-0 cw-w-full" />
      <TierFeatureHeader text={tier.featureHeader} />
      <FeatureBullet>Centrally manage user provisioning and deprovisioning via SCIM </FeatureBullet>
      <FeatureBullet>
        Configure custom data processing parameters for enhanced security
      </FeatureBullet>
      <FeatureBullet>Receive guaranteed preferred support and maintenance</FeatureBullet>
    </Tier>
  );
};

export default EnterpriseTierHeader;
