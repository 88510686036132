// IMPORTS
// schema
import * as ISchema from "#webapp/src/__schema__";

import { modernCache } from "#webapp/src/network/modern-network-layer";
import { commitMutation } from "react-relay";
import { Environment } from "relay-runtime";
import { deleteTeamMutation } from "./DeleteTeam.gql"; // change

import { fromGlobalId, toGlobalIdForTeam } from "#webapp/src/util/graphql.util";
import { UrnUtil } from "@clockwise/web-commons/src/util/urn";

// TYPES
interface IDeleteTeamMutationResponse {
  deleteTeam: ISchema.IDeleteTeamMutationPayload;
}

// MUTATION
export function deleteTeam(
  environment: Environment,
  input: ISchema.IDeleteTeamMutationInput,
  onSuccess: (response?: ISchema.IDeleteTeamMutationPayload) => void,
  onError: (error?: any) => void,
) {
  commitMutation(environment, {
    onError,
    mutation: deleteTeamMutation,
    variables: { input },
    updater: (store, data: any) => {
      const { deletedTeamId } = data.deleteTeam;

      // get relay id for the line item we're going to delete
      // TODO: this fromGlobalId stuff is weird maybe? idk..
      const orgId = fromGlobalId(input.orgRelayId);
      const teamUrn = UrnUtil.createTeamUrn(deletedTeamId, orgId.id);
      const teamRelayId = toGlobalIdForTeam(UrnUtil.serialize(teamUrn));

      // get the org from store
      const orgProxy = store.get(input.orgRelayId);
      if (!orgProxy) {
        return;
      }

      // get the userTeams from the store org
      const userTeamsProxy = orgProxy.getLinkedRecord("userTeams");
      if (!userTeamsProxy) {
        return;
      }

      // get the list from the store userTeams
      const userTeamsListProxy = userTeamsProxy.getLinkedRecords("list");

      if (userTeamsListProxy) {
        // remove the team with corresponding team id
        const userTeamsList = userTeamsListProxy.filter(
          (ut) => ut && ut.getDataID() !== teamRelayId,
        );
        // reset in store
        userTeamsProxy.setLinkedRecords(userTeamsList, "list");
        store.delete(teamRelayId);

        // TODO: handle potentially needing a new primary team
      }
    },
    onCompleted: (response: IDeleteTeamMutationResponse, errors?: Error[]) => {
      if (errors) {
        onError(errors[0]);
      } else {
        // if we're not in a modern environment, modernCache will be undefined
        if (modernCache) {
          modernCache.clear();
        }
        onSuccess(response.deleteTeam);
      }
    },
  });
}
