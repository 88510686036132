///////////////////////
// IMPORTS
///////////////////////
// libraries
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
import { Store } from "redux";
import { Environment, RecordSource, Store as RelayStore } from "relay-runtime";
// internal
import { getModernNetworkLayer } from "../network/modern-network-layer";

///////////////////////
// HANDLER
///////////////////////

let currentEnvironment: Environment;

///////////////////////
// ENVIRONMENT CREATION
///////////////////////
export function createEnvironment(reduxStore: Store<IReduxState>) {
  const source = new RecordSource();
  const store = new RelayStore(source);
  const network = getModernNetworkLayer(reduxStore);

  const environment = new Environment({
    network,
    store,
  });

  currentEnvironment = environment;

  return environment;
}

export function getCurrentEnvironment() {
  return currentEnvironment;
}
