import { DiffActionTypeEnum, TransparencyEnum } from "@clockwise/schema";
import { EventType } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { Interval } from "luxon";
import { useFeatureFlag } from "../../../launch-darkly/useLaunchDarkly";
import { ModifiedPlannerEventCardDiff, PlannerEventCardsByDay } from "../../web-app-calendar/types";
import { useDefragProposal } from "../DefragProposalContext";

const useDefragProposalCards = (): {
  cardsByDay: PlannerEventCardsByDay;
  modifiedCardDiffs: ModifiedPlannerEventCardDiff[];
  isScheduleByMoving: boolean;
  error: null;
  loading: boolean;
} | null => {
  const [usingOdd] = useFeatureFlag("onDemandDefrag");
  const { onDemandDefrag } = useDefragProposal();
  const zone = getRenderTimeZone();

  if (!onDemandDefrag.visible || !usingOdd) {
    return null;
  }

  const cardsByDay: PlannerEventCardsByDay = {};

  onDemandDefrag?.proposal?.diffBlocks?.forEach((diffBlock) => {
    diffBlock?.diffs?.forEach((diff) => {
      if (diff.__typename !== "ModifyDiffSummary") {
        // For TS to know its a ModifyDiff
        return;
      }

      if (!diff.id || !diff.updatedTime || !diff.eventId) {
        return;
      }

      const date = Interval.fromISO(diff.updatedTime).start.toISODate();

      if (!cardsByDay[date]) {
        cardsByDay[date] = [];
      }

      cardsByDay[date].push({
        active: true,
        annotaion: undefined,
        deemphasis: false,
        externalEventId: diff.eventId,
        interval: Interval.fromISO(diff.updatedTime),
        updatedTimeInterval: Interval.fromISO(diff.updatedTime, { zone }),
        currentTimeInterval: Interval.fromISO(diff.currentTime, { zone }),
        proposedResponseState: undefined,
        isAllDay: false,
        isCancelled: false,
        smartHold: null, // Only calendar Events need this UI
        diffSummaryId: diff.id, // what does this do
        key: diff.id,
        proposalType: DiffActionTypeEnum.RESCHEDULE,
        suppressNewBadge: true,
        responseState: "Proposed",
        text: diff.title,
        calendarIds: [""], // Not sure if we need this of ODD SAM
        attendeesCalendarIds: [], // Intentionally empty so that the event stripes are not shown
        // Used to render a badge with the attendee's avatar for SBMs
        affectedAttendees: [],
        attendees: [],
        locked: false,
        transparency: TransparencyEnum.None,
        videoLink: null,
        eventPermissions: {
          canInviteOthers: false,
          canModify: false,
          canDelete: false,
          canRemove: false,
          canRSVP: false,
          canSeeOtherGuests: false,
        },
        flexStatus: null,
        attendeeCount: 0,
        proposalMetaData: {
          attendeeIds: [],
          // Used to determine if a proposal should be rendered in calendar day view
          isConsequence: true,
        },
        type: EventType.Event, // what does this do
      });
    });
  });

  return {
    cardsByDay,
    modifiedCardDiffs: [],
    isScheduleByMoving: true,
    error: null,
    loading: false,
  };
};

export default useDefragProposalCards;
