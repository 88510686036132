const fontFamily = {
  brand: ["PP Mori", "serif", "ui-serif"],
  body: ["Inter", "sans-serif", "ui-sans-serif", "system-ui"],
};

const fontSize = {
  // Major Second
  // numeric typescale
  11: "0.6875rem",
  12: "0.75rem",
  13: "0.8125rem",
  14: "0.875rem",
  16: "1rem",
  18: "1.125rem",
  20: "1.25rem",
  23: "1.4375rem",
  26: "1.625rem",
  29: "1.8125rem",
  32: "2rem",
  36: "2.25rem",
  41: "2.5625rem",
  46: "2.875rem",
  52: "3.25rem",
  58: "3.625rem",
  66: "4.125rem",
  74: "4.625rem",
  83: "5.1875rem",
  93: "5.8125rem",
};

module.exports = {
  fontFamily,
  fontSize,
};
