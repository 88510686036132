import { TranslationContext } from "@clockwise/client-commons/src/providers/TranslationContext";
import { ArrowsClockwise, Link, LockKey, RocketLaunch, UserSquare } from "phosphor-react";
import React, { ContextType } from "react";
import { IFeatureComparison } from "../types";
import { FeatureWithIcon } from "./FeatureWithIcon";

const getFeatureComparisons = ({
  t,
}: {
  t: ContextType<typeof TranslationContext>;
}): IFeatureComparison[] => [
  {
    feature: "Smart scheduling",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip:
      "Clockwise's AI-enabled scheduling experience that finds the least disruptive time for meetings and can shift events around to create availability.",
  },
  {
    feature: "Flexible holds",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip:
      "Recurring routines and one-off task holds that Clockwise will automatically shuffle as your calendar changes.",
  },
  {
    feature: "Lunch holds",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip:
      "A Smart Hold that automatically protects your lunch every day, based on when you want to take it and how long you like it to be.",
  },
  {
    feature: "Personal-to-work calendar sync",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip:
      'Automatically syncs events from your personal Google Calendar account to your work calendar. Coworkers will see a "Busy (via Clockwise)" event.',
  },
  {
    feature: "Travel time holds",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip:
      "A Smart Hold that automatically factors in transit time on your schedule, for meetings with a location in the invite.",
  },
  {
    feature: "Smart meeting breaks",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip:
      "Automatically build meeting breaks into your day, based on your personal preferences.",
  },
  {
    feature: "Automatic event color-coding",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip:
      "Organize your calendar quickly with automatic meeting color-coding, customized to your preferences.",
  },
  {
    feature: t("pricing:feature-comparison-grid-autopilot") as string,
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: t("pricing:feature-comparison-grid-autopilot-tooltip") as string,
  },
  {
    feature: "Focus Time holds",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip:
      "A Smart Hold that prevents others from scheduling over your Focus Time by blocking it on your work calendar. Focus Time holds will automatically update as your schedule changes.",
  },
  {
    feature: "Max Focus Time per day",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: "Set a daily maximum for Focus Time holds",
  },
  {
    feature: "Auto-protected Focus Time",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: "Have Clockwise dynamically protect Focus Time on your behalf",
  },
  {
    feature: "Extended optimization window",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Disabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip:
      "Clockwise will routinely optimize user's schedules and resolve flexible meeting conflicts up to four weeks out, instead of two, allowing better visibility into the month ahead.",
  },
  // ----- Section Header -----
  {
    feature: <FeatureWithIcon icon={Link} title="Scheduling Links" />,
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    isSectionHeader: true,
  },
  {
    feature: "Custom Scheduling Links",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "Unlimited" },
      pro: { enabled: "Enabled", description: "Unlimited" },
      business: { enabled: "Enabled", description: "Unlimited" },
      enterprise: { enabled: "Enabled", description: "Unlimited" },
    },
    tooltip: `Create and send as many individual Scheduling Links as you'd like.`,
  },
  {
    feature: "Number of bookings",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "Unlimited" },
      pro: { enabled: "Enabled", description: "Unlimited" },
      business: { enabled: "Enabled", description: "Unlimited" },
      enterprise: { enabled: "Enabled", description: "Unlimited" },
    },
    tooltip: `Share your Scheduling Links as frequently as you need to; Clockwise does not limit the amount of meetings that can be booked for any given link.`,
  },
  {
    feature: "Best meeting times highlighted",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Clockwise will find the best time(s) to meet, based on your Focus Time needs and scheduling preferences. The best meeting times will be highlighted on the availability page for your link.`,
  },
  {
    feature: "Zoom and Google Meet integrations",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `A Google Meet or Zoom link can be automatically added to any meeting scheduled with Scheduling Links.`,
  },
  {
    feature: "Automatic notifications and reminders",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Clockwise will automatically notify you and your attendees when a meeting scheduled with your link is confirmed, canceled, or rescheduled.`,
  },
  {
    feature: "Customizable meeting titles",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Customize the titles of meetings booked via Scheduling Links using simple variables like link name and attendee name.`,
  },
  {
    feature: "Customizable meeting availability",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Fine-tune scheduling flexibility to your preferences by allowing meetings to be booked outside of working hours or within specific time ranges.`,
  },
  {
    feature: "Multiple meeting durations",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Allow link recipients to select the meeting duration that best meets their needs.`,
  },
  {
    feature: "Maximum meetings per day/week",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Control external meeting load by customizing the maximum number of meetings that can be scheduled daily or weekly via a given Scheduling Link.`,
  },
  {
    feature: "Custom branding on booking page",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Users can add a custom logo to be displayed on the Scheduling Links booking page.`,
  },
  {
    feature: "Group Scheduling Links",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Easily schedule collective meetings with an external invitee and multiple members of your team.`,
  },
  {
    feature: "Round Robin Scheduling Links",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Disabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Balance meeting distribution among a team based on availability or priority.`,
  },
  {
    feature: "Administrative logo control",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Disabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Admins can add a logo on behalf of all users within their billing group. This logo will supersede any previously individually uploaded logos, providing greater control for admins and ensuring brand consistency across users.`,
  },
  // ----- Section Header -----
  {
    feature: <FeatureWithIcon icon={RocketLaunch} title="Team Productivity Tools" />,
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    isSectionHeader: true,
  },
  {
    feature: "Automatic team no-meeting day",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Automatically protects your team’s designated no-meeting day, and notifies meeting organizers when they try to schedule over your no-meeting day.`,
  },
  {
    feature: "Automatic out-of-office calendar",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Automatically syncs out of office events to a shared team calendar, keeping everyone on the same page regarding availability.`,
  },
  {
    feature: "At-a-glance team analytics",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `A weekly summary of statistics and visualization on team members' time allocation, working preference, and top collaborators.`,
  },
  {
    feature: "Meeting & calendar data exports",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Disabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Export meeting hours, Focus Time, and fragmented time data to identify areas for improvement and optimization.`,
  },
  {
    feature: "Company no-meeting day(s)",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Disabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Set a company-wide 'no-meeting day' that will apply to all employees of the company. Flexible meetings will not move to no-meeting day(s).`,
  },
  // ----- Section Header -----
  {
    feature: <FeatureWithIcon icon={ArrowsClockwise} title="Integrations" />,
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    isSectionHeader: true,
  },
  {
    feature: "Slack",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Automatically syncs your Slack status so your team can see if you're in a Focus Time block or out of office.`,
  },
  {
    feature: "Zoom",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: "Sync your account to add Zoom Meetings to your invites with one click.",
  },
  // ----- Section Header -----
  {
    feature: <FeatureWithIcon icon={LockKey} title="Security and support" />,
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    isSectionHeader: true,
  },
  {
    feature: "Google SSO",
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: "Sign in securely to Clockwise with your GSuite work account.",
  },
  {
    feature: "User management",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Ability to view and edit paid users in the Members and Billing portal.`,
  },
  {
    feature: "Admin controls & user roles",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Disabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Ability to assign different user roles and allow admins to control who can invite new users to join the subscription.`,
  },
  {
    feature: "Divisional plans",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Disabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Ability for buyers to buy for a Division. A Division is defined as a custom group of users that is between a small team of less than 50 users and a full org.`,
  },
  {
    feature: "Custom contract and invoicing",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Disabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: "Customize your contract or invoice, tailored to your organization’s needs.",
  },
  {
    feature: "Calendar exclusion list",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Disabled", description: "" },
      business: { enabled: "Disabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Option to not store meeting descriptions in the Clockwise database.`,
  },
  {
    feature: "User provisioning (SCIM)",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Disabled", description: "" },
      business: { enabled: "Disabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: `Allows admins to securely manage paid users in Clockwise by connecting directly to your  corporate identity provider.`,
  },
  // ----- Section Header -----
  {
    feature: <FeatureWithIcon icon={UserSquare} title="Customer service" />,
    tierFeatureMap: {
      free: { enabled: "Enabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    isSectionHeader: true,
  },
  {
    feature: "Priority Support",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Enabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: "Get quick answers to your Customer Support questions.",
  },
  {
    feature: "Dedicated Customer Success Manager",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Enabled", description: "3 months" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: "Receive best-in-class service from a dedicated Customer Success expert.",
  },
  {
    feature: "Meeting Culture Workshop",
    tierFeatureMap: {
      free: { enabled: "Disabled", description: "" },
      pro: { enabled: "Disabled", description: "" },
      business: { enabled: "Enabled", description: "" },
      enterprise: { enabled: "Enabled", description: "" },
    },
    tooltip: "Receive best-in-class service from a dedicated Customer Success expert.",
  },
];

export default getFeatureComparisons;
