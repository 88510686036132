export const compareStrings = (a: string, b: string, isAscending = true) => {
  if (a < b) {
    return isAscending ? -1 : 1;
  } else if (a > b) {
    return isAscending ? 1 : -1;
  }

  return 0;
};

export const compareBooleans = (a: boolean, b: boolean, isAscending = true) => {
  if (!a && b) {
    return isAscending ? -1 : 1;
  } else if (a && !b) {
    return isAscending ? 1 : -1;
  }

  return 0;
};
