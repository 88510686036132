import { TeammateInviteStatusEnum } from "#webapp/src/util/team.util";
import { Button, ButtonSet, Checkbox, Tooltip } from "@clockwise/design-system";
import { Delete, Email, PersonAdd } from "@clockwise/design-system/icons";
import React from "react";

export type TeammateForAction = {
  isUser: boolean;
  fullName: string;
  isYou: boolean;
  isChecked: boolean;
  isInvited: boolean;
  inviteStatus: TeammateInviteStatusEnum;
};

type ActionProps = {
  teammate: TeammateForAction;
  onClickSendInvite: () => void;
  onClickResendInvite: () => void;
  onClickCheck: () => void;
  onClickDeleteTeammate: () => void;
  mode: "modify" | "create" | "orgInvite";
  isSearchResult: boolean;
};

export const TeamSettingsTeammateAction = ({
  mode,
  onClickCheck,
  teammate,
  onClickSendInvite,
  onClickDeleteTeammate,
  onClickResendInvite,
  isSearchResult,
}: ActionProps) => {
  if (mode === "create") {
    return <Checkbox onChange={onClickCheck} checked={teammate.isChecked} />;
  }

  if (mode === "orgInvite") {
    switch (teammate.inviteStatus) {
      case TeammateInviteStatusEnum.Uninvited:
        return <Checkbox onChange={onClickCheck} checked={teammate.isChecked} />;
      case TeammateInviteStatusEnum.Pending:
        return (
          <div className="cw-flex cw-items-center cw-gap-3">
            <div className="cw-text-muted cw-text-[13px]">Invited</div>
            <Checkbox disabled={true} onChange={onClickCheck} checked={true} />
          </div>
        );
      case TeammateInviteStatusEnum.Accepted:
        return (
          <div className="cw-flex cw-items-center cw-gap-3">
            <div className="cw-text-muted cw-text-[13px]">Joined</div>
            <Checkbox disabled={true} onChange={onClickCheck} checked={true} />
          </div>
        );
    }
  }

  const tipText = teammate.isYou ? "Leave team" : `Remove ${teammate.fullName} from team`;

  switch (teammate.inviteStatus) {
    case TeammateInviteStatusEnum.Uninvited:
      return (
        <ButtonSet>
          <div className="cw-w-24">
            <Button
              variant="outlined"
              fullWidth
              startIcon={teammate.isUser ? PersonAdd : Email}
              onClick={onClickSendInvite}
            >
              {teammate.isUser ? "Add" : "Invite"}
            </Button>
          </div>
          <div className="cw-invisible">
            <Button startIcon={Delete} />
          </div>
        </ButtonSet>
      );
    case TeammateInviteStatusEnum.Pending:
      return (
        <ButtonSet>
          {teammate.isInvited ? (
            <div className="cw-px-4 cw-text-muted cw-w-[110] cw-flex cw-items-center">Invited!</div>
          ) : (
            <div className="cw-w-24">
              <Button variant="outlined" fullWidth startIcon={Email} onClick={onClickResendInvite}>
                Resend
              </Button>
            </div>
          )}
          <div className="group-hover:cw-visible cw-invisible">
            <Tooltip title={tipText} maxWidth={140}>
              <Button variant="text" startIcon={Delete} onClick={onClickDeleteTeammate} />
            </Tooltip>
          </div>
        </ButtonSet>
      );
    case TeammateInviteStatusEnum.Accepted:
      return (
        <ButtonSet>
          {isSearchResult && (
            <div className="cw-flex cw-items-center cw-justify-center cw-w-24 cw-text-muted">
              Joined!
            </div>
          )}
          <div className="group-hover:cw-visible cw-invisible">
            <Tooltip title={tipText} maxWidth={140}>
              <Button variant="text" startIcon={Delete} onClick={onClickDeleteTeammate} />
            </Tooltip>
          </div>
        </ButtonSet>
      );
    default:
      return <div></div>;
  }
};
