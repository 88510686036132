import { DateTime } from "luxon";
export const daysSinceUserCreation = (user: { createdTime: number }) => {
  const createdDateTime = DateTime.fromMillis(user.createdTime);
  const now = DateTime.local();

  const timeSinceCreation = now.diff(createdDateTime, ["days"]);
  return Math.ceil(timeSinceCreation.days);
};

export const getIsNewUser = (user?: { createdTime: number | undefined } | null) => {
  if (!user?.createdTime) {
    return false;
  } else {
    const createdTime = user.createdTime;
    return daysSinceUserCreation({ createdTime }) <= 30;
  }
};
