import { getEnvironment } from "./environment";

const DEV_ENV_REGEX = /^(?:www\.)?([a-z0-9-]+)\.dev\.getclockwise\.com$/i;
export const detectDevEnv = (hostname: string): string | null => {
  if (process.env.NODE_ENV === "production") {
    return null;
  }

  const [, devenv] = hostname.match(DEV_ENV_REGEX) ?? [];

  if (devenv) {
    console.log(`Detected devenv "${devenv}" for hostname "${hostname}"`);
  } else {
    if (getEnvironment() !== "test") {
      console.log(`No devenv detected for hostname "${hostname}"`);
    }
  }
  return devenv ?? null;
};

const isWorker = typeof self !== "undefined";
const hasWindow = typeof window !== "undefined";

const defaultDevenv: string | null = detectDevEnv(
  isWorker ? self.location.hostname : hasWindow ? window.location.hostname : "",
);
let devenv: string | undefined = undefined;

export const getDevenv = () => devenv ?? defaultDevenv;
export const setDevenv = (name: string) => (devenv = name);
export const resetDevenv = () => (devenv = undefined);
