//////////////////
// IMPORTS
//////////////////
// schema
import * as ISchema from "#webapp/src/__schema__";
import { isEmailInDomains } from "#webapp/src/util/email.util";
//////////////////
// TYPES
//////////////////
export interface ISortedDataSources {
  work: ISchema.IDataSource[];
  other: ISchema.IDataSource[];
}

//////////////////
// IMPORTS
//////////////////

// sorts into work, add, and other
export function sortDataSources(dsEdges: ISchema.IDataSourceEdge[], domains: string[]) {
  const initSorted: ISortedDataSources = { work: [], other: [] };
  return dsEdges.reduce((sorted: ISortedDataSources, dsEdge) => {
    const ds = dsEdge.node;
    const isWork = isEmailInDomains(ds.name, domains);

    if (isWork) {
      sorted.work = [...sorted.work, ds];
    } else {
      sorted.other = [...sorted.other, ds];
    }
    return sorted;
  }, initSorted);
}
