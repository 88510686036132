import { ApolloQueryResult } from "@apollo/client";
import { useResettingBoolean } from "@clockwise/web-commons/src/ui/scheduling-links/hooks/useResettingBoolean";
import { useReadCalendar } from "@clockwise/web-commons/src/util/CalendarContext";
import {
  IEventCategoryColoring,
  IEventColoringSettings,
} from "@clockwise/web-commons/src/util/event-category-coloring";
import {
  useEffectTimeout,
  useOnWindowFocusChange,
} from "@clockwise/web-commons/src/util/react.util";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { noop, without } from "lodash";
import React, {
  PropsWithChildren,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "usehooks-ts";
import { addUserCalendarIds } from "../../state/actions/multi-calendar.actions";
import { IReduxState } from "../../state/reducers/root.reducer";
import { useChatPlusCalEventCards } from "../chat-plus-calendar/hooks/useChatPlusCalEventCards";
import { ConflictClusterWithConversationId } from "../chat-plus-calendar/util/parseConflictClustersGQL";
import {
  PlannerEventsQueryQuery,
  PlannerEventsQueryQueryVariables,
} from "./apollo/__generated__/Planner.generated";
import { WorkingHours } from "./hooks/usePlannerMetaData";
import { EventCardsByDayAndCalendar, PlannerEventCardsByDay } from "./types";
import { getEventsByDayAndCalendar } from "./util/getEventsByDayAndCalendar";
import { getNormalAndAllDayEvents } from "./util/getNormalAndAllDayEvents";

export type PlannerContextValue = {
  allDayEvents?: PlannerEventCardsByDay;
  workingLocationsByDay?: PlannerEventCardsByDay;
  calendarDataLoading?: boolean;
  calendarError?: Error;
  calendarIds?: string[];
  colorSettings?: IEventColoringSettings<IEventCategoryColoring>;
  multiCalendarIds?: string[];
  normalEvents?: PlannerEventCardsByDay;
  hideDeclined?: boolean;
  refetchEvents?: (
    variables?: PlannerEventsQueryQueryVariables,
  ) => Promise<ApolloQueryResult<PlannerEventsQueryQuery>> | void;
  setHideDeclined?: (value: boolean) => void;
  selectedCalendarIds?: string[];
  workingHourBounds?: [number, number] | null;
  workingHours?: WorkingHours;
  conflictClusters?: ConflictClusterWithConversationId[];
  isCurrentlyShowingOptimisticProposals?: boolean;
  setIsResizingAnyEvent?: (value: boolean) => void;
  isResizingAnyEvent?: boolean;
  allDayOOOEvents?: PlannerEventCardsByDay;
  eventsByDayAndCalendar?: EventCardsByDayAndCalendar;
  primaryCalendarId?: string;
  handlePopoverChange?: (value: boolean) => void;
  popoverOpen?: boolean;
  popoverClosing?: boolean;
  isDraggingEvent?: boolean;
  setIsDraggingEvent?: (value: boolean) => void;
  scrollContainerRef?: RefObject<HTMLDivElement> | null;
};

// fetches the initial list of events
// prefetches the previous and next week
// sets up polling
export const emptyPlannerContext = {
  allDayEvents: {},
  workingLocationsByDay: {},
  calendarDataLoading: false,
  calendarError: undefined,
  calendarIds: undefined,
  colorSettings: undefined,
  multiCalendarIds: [],
  normalEvents: {},
  hideDeclined: false,
  refetchEvents: noop,
  setHideDeclined: noop,
  selectedCalendarIds: undefined,
  workingHourBounds: undefined,
  workingHours: undefined,
  conflictClusters: [],
  setIsResizingAnyEvent: noop,
  isResizingAnyEvent: false,
  allDayOOOEvents: {},
  primaryCalendarId: undefined,
  handlePopoverChange: noop,
  popoverOpen: false,
  popoverClosing: false,
  isDraggingEvent: false,
  setIsDraggingEvent: noop,
  scrollContainerRef: null,
};

export const PlannerContext = React.createContext<PlannerContextValue | undefined>(undefined);

export const usePlannerContext = (): PlannerContextValue => {
  const context = useContext(PlannerContext);

  if (!context) {
    console.error(
      "Tried to access planner context but it is not available; Please ensure you are inside a PlannerProvider.",
    );
    return emptyPlannerContext;
  }

  return context;
};

type UserCalendarIds = string[];

type PlannerProviderProps = {
  userCalendarIds: UserCalendarIds;
  workingHourBounds: [number, number] | null;
  workingHours: WorkingHours;
  colorSettings?: IEventColoringSettings<IEventCategoryColoring>;
  setIsResizingAnyEvent?: (value: boolean) => void;
  isResizingAnyEvent?: boolean;
  primaryCalendarId?: string;
};
export const PlannerProvider = ({
  children,
  userCalendarIds,
  workingHourBounds,
  workingHours,
  colorSettings,
  setIsResizingAnyEvent,
  isResizingAnyEvent,
  primaryCalendarId,
}: PropsWithChildren<PlannerProviderProps>) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [popoverClosing, setPopoverClosing] = useResettingBoolean(false, 100);
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [isDraggingEvent, setIsDraggingEvent] = React.useState(false);
  const { focusedDate } = useReadCalendar();

  const dispatch = useDispatch();
  const selectedCalendarIds = useSelector(
    (state: IReduxState) => state.multiCalendar.calendarIdsToFullyShow,
  );

  const handlePopoverChange = useCallback(
    (elemPresent: boolean) => {
      setPopoverOpen(!!elemPresent);
      setPopoverClosing(!elemPresent);
    },
    [setPopoverClosing],
  );

  useEffect(() => {
    dispatch(addUserCalendarIds(userCalendarIds));
  }, [userCalendarIds]);

  const timeZone = getRenderTimeZone();

  const [hideDeclined, setHideDeclined] = useLocalStorage<boolean>(
    "ai-chat:HideDeclinedEvents",
    false,
  );

  const calendarIds = useMemo(
    () => (selectedCalendarIds.length ? selectedCalendarIds : userCalendarIds),
    [selectedCalendarIds, userCalendarIds],
  );

  const multiCalendarIds = useMemo(() => without(calendarIds, ...userCalendarIds), [
    calendarIds,
    userCalendarIds,
  ]);
  const {
    error: calendarError,
    loading: calendarDataLoading,
    cardsByDay,
    workingLocationsByDay,
    refetch,
    prefetch,
    startPolling,
    stopPolling,
    conflictClusters,
    isCurrentlyShowingOptimisticProposals,
  } = useChatPlusCalEventCards(focusedDate, calendarIds, userCalendarIds);

  // Pre-fetch the previous and the following week.
  // Optional COULD-DO, only prefetch the next or previous week depending if the user navigated forward or backward rather than both.
  // Timeout the prefetch to give the main fetch time to kickoff and hopefully finish.
  useEffectTimeout(
    () => {
      prefetch({ weeks: -1 });
      prefetch({ weeks: 1 });
    },
    300,
    [prefetch, focusedDate],
  );

  // Start polling when the window is focused
  const startPollingCallback = useCallback(() => startPolling(10 * 1000), [startPolling]);
  useOnWindowFocusChange(startPollingCallback, stopPolling, stopPolling);

  const [normalEvents, allDayEvents, allDayOOOEvents] = useMemo(() => {
    return getNormalAndAllDayEvents(cardsByDay, timeZone, multiCalendarIds, primaryCalendarId);
  }, [cardsByDay, timeZone, primaryCalendarId, multiCalendarIds]);

  const eventsByDayAndCalendar = useMemo(() => {
    const multiCalPlusCurrent = primaryCalendarId
      ? [primaryCalendarId].concat(...multiCalendarIds)
      : [...multiCalendarIds];

    const teamCalendarIds = userCalendarIds.filter((id) => id !== primaryCalendarId);

    return getEventsByDayAndCalendar(
      normalEvents,
      allDayOOOEvents,
      allDayEvents,
      multiCalPlusCurrent,
      teamCalendarIds,
      primaryCalendarId ?? "",
    );
  }, [
    normalEvents,
    allDayEvents,
    allDayOOOEvents,
    multiCalendarIds,
    primaryCalendarId,
    calendarIds,
    userCalendarIds,
  ]);

  const value = useMemo(
    () => ({
      allDayEvents,
      workingLocationsByDay,
      calendarDataLoading,
      calendarError,
      calendarIds,
      colorSettings,
      multiCalendarIds,
      normalEvents,
      hideDeclined,
      isCurrentlyShowingOptimisticProposals,
      refetchEvents: refetch,
      setHideDeclined,
      selectedCalendarIds: userCalendarIds,
      workingHourBounds,
      workingHours,
      conflictClusters,
      setIsResizingAnyEvent,
      isResizingAnyEvent,
      allDayOOOEvents,
      eventsByDayAndCalendar,
      primaryCalendarId,
      handlePopoverChange,
      popoverOpen,
      popoverClosing,
      isDraggingEvent,
      setIsDraggingEvent,
      scrollContainerRef,
    }),
    [
      allDayEvents,
      workingLocationsByDay,
      calendarDataLoading,
      calendarError,
      calendarIds,
      colorSettings,
      multiCalendarIds,
      normalEvents,
      hideDeclined,
      isCurrentlyShowingOptimisticProposals,
      refetch,
      setHideDeclined,
      userCalendarIds,
      workingHourBounds,
      workingHours,
      conflictClusters,
      setIsResizingAnyEvent,
      isResizingAnyEvent,
      allDayOOOEvents,
      eventsByDayAndCalendar,
      primaryCalendarId,
      popoverOpen,
      handlePopoverChange,
      popoverClosing,
      isDraggingEvent,
      setIsDraggingEvent,
      scrollContainerRef,
    ],
  );

  return <PlannerContext.Provider value={value}>{children}</PlannerContext.Provider>;
};
