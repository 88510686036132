/**
 * @generated SignedSource<<f1a2a672128d775ba41bdb02532c496f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type UpdateTeamCalendarStateSubscriptionInput = {
  orgRelayId: string;
  teamId: string;
};
export type UpdateTeamCalendarStateSubscription$variables = {
  input: UpdateTeamCalendarStateSubscriptionInput;
};
export type UpdateTeamCalendarStateSubscription$data = {
  readonly updateTeamCalendarState: {
    readonly teamCalendarState: {
      readonly calendarId: string;
    } | null;
  };
};
export type UpdateTeamCalendarStateSubscription = {
  variables: UpdateTeamCalendarStateSubscription$variables;
  response: UpdateTeamCalendarStateSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateTeamCalendarStateSubscriptionPayload",
    "kind": "LinkedField",
    "name": "updateTeamCalendarState",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamCalendarState",
        "kind": "LinkedField",
        "name": "teamCalendarState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "calendarId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateTeamCalendarStateSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateTeamCalendarStateSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "44a6cb64d59ecbb73d2333b45ec44c9d",
    "id": null,
    "metadata": {},
    "name": "UpdateTeamCalendarStateSubscription",
    "operationKind": "subscription",
    "text": "subscription UpdateTeamCalendarStateSubscription(\n  $input: UpdateTeamCalendarStateSubscriptionInput!\n) {\n  updateTeamCalendarState(input: $input) {\n    teamCalendarState {\n      calendarId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d0aac8a6cc257f7ef002ae77eaa8c482";

export default node;
