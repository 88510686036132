import { RadioGroup as HeadlessRadioGroup } from "@headlessui/react";
import classNames from "classnames";
import React from "react";

export type Sentiment = "neutral" | "warning";
export type Variant = "outlined" | "default";
export type Size = "small" | "large";
export interface Props<V> {
  value: V;
  disabled?: boolean;
  onChange: (selected: V) => void;
  sentiment?: Sentiment;
  variant?: Variant;
  size?: Size;
  name?: string;
  fullWidth?: boolean;
  "aria-label"?: string;
  "aria-labelledby"?: string;
  children: React.ReactNode;
}
export const RadioGroup = <V extends string | number>({
  disabled = false,
  fullWidth = false,
  sentiment = "neutral",
  variant = "default",
  size = "large",
  children,
  ...props
}: Props<V>) => {
  const context: RadioGroupContextType = React.useMemo(
    () => ({
      sentiment,
      variant,
      size,
    }),
    [sentiment, variant],
  );
  return (
    <RadioGroupContext.Provider value={context}>
      <HeadlessRadioGroup
        className={classNames("cw-inline-flex cw-flex-col", { "cw-w-full": fullWidth })}
        disabled={disabled}
        {...props}
      >
        {children}
      </HeadlessRadioGroup>
    </RadioGroupContext.Provider>
  );
};

type RadioGroupContextType = {
  sentiment: Sentiment;
  variant: Variant;
  size: Size;
};
export const RadioGroupContext = React.createContext<RadioGroupContextType>({
  sentiment: "neutral",
  variant: "default",
  size: "large",
});
