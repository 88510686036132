export type CalendarId = string;
export type CalendarIds = CalendarId[];
export type DiffId = string;
export type DiffIds = DiffId[];
export type EventId = string;
export type EventIds = EventId[];
export type EventIdOrDiffIdToCalendarIds = Record<DiffId | EventId, CalendarIds>;

export enum MultiCalendarActions {
  AddCalendarIds = "MultiCalendar_AddCalendarIds",
  RemoveCalendarIds = "MultiCalendar_RemoveCalendarIds",
  ClearCalendarIds = "MultiCalendar_ClearCalendarIds",
  AddDiffIdsOrEventIds = "MultiCalendar_AddDiffIdsOrEventIds",
  RemoveDiffIdsOrEventIds = "MultiCalendar_RemoveDiffIdsOrEventIds",
  ClearDiffIdsAndEventIds = "MultiCalendar_ClearDiffIdsAndEventIds",
  AddEventIdOrDiffIdWithCalendarIds = "MultiCalendar_AddEventIdOrDiffIdWithCalendarIds",
  RemoveEventIdOrDiffIdWithCalendarIds = "MultiCalendar_RemoveEventIdOrDiffIdWithCalendarIds",
  AddUserCalendarIds = "MultiCalendar_AddUserCalendarIds",
  ClearAllSelected = "MultiCalendar_ClearAllSelected",
  SetCalendarIdsForCalendarPeek = "MultiCalendar_SetCalendarIdsForCalendarPeek",
  AddEventIdOrDiffIdWithCalendarIdsBeforeEdit = "MultiCalendar_AddEventIdOrDiffIdWithCalendarIdsBeforeEdit",
  ReplaceDiffIdsOrEventIds = "MultiCalendar_ReplaceDiffIdsOrEventIds",
}

export const addDiffIdWithCalendarIdsBeforeEdit = (diffId: DiffId, calendarIds: CalendarIds) => ({
  type: MultiCalendarActions.AddEventIdOrDiffIdWithCalendarIdsBeforeEdit,
  data: {
    diffIdOrEventId: diffId,
    calendarIds,
  },
});

export const addEventIdWithCalendarIdsBeforeEdit = (
  eventId: EventId,
  calendarIds: CalendarIds,
) => ({
  type: MultiCalendarActions.AddEventIdOrDiffIdWithCalendarIdsBeforeEdit,
  data: {
    diffIdOrEventId: eventId,
    calendarIds,
  },
});

export const addUserCalendarIds = (calendarIds: CalendarIds) => ({
  type: MultiCalendarActions.AddUserCalendarIds,
  data: {
    calendarIds,
  },
});

export const addDiffIdWithCalendarIds = (diffId: DiffId, calendarIds: CalendarIds) => ({
  type: MultiCalendarActions.AddEventIdOrDiffIdWithCalendarIds,
  data: {
    diffId,
    calendarIds,
  },
});

export const removeDiffIdWithCalendarIds = (diffIdOrEventId: DiffId) => ({
  type: MultiCalendarActions.RemoveEventIdOrDiffIdWithCalendarIds,
  data: {
    diffIdOrEventId,
  },
});

// For now the event id will be treated as a diff id
// This is because there should be no collisions between event ids and diff ids
// And also want to support toggling on layers for events
export const addEventIdWithCalendarIds = (eventId: EventId, calendarIds: CalendarIds) => ({
  type: MultiCalendarActions.AddEventIdOrDiffIdWithCalendarIds,
  data: {
    diffId: eventId,
    calendarIds,
  },
});

export const addDiffIds = (diffIds: DiffIds) => ({
  type: MultiCalendarActions.AddDiffIdsOrEventIds,
  data: {
    diffIdsOrEventIds: diffIds,
  },
});

export const replaceDiffIdsOrEventIds = (diffIds: DiffIds) => ({
  type: MultiCalendarActions.ReplaceDiffIdsOrEventIds,
  data: {
    diffIdsOrEventIds: diffIds,
  },
});

export const removeDiffIds = (diffIds: DiffIds) => ({
  type: MultiCalendarActions.RemoveDiffIdsOrEventIds,
  data: {
    diffIdsOrEventIds: diffIds,
  },
});

export const addEventIds = (eventIds: EventIds) => ({
  type: MultiCalendarActions.AddDiffIdsOrEventIds,
  data: {
    diffIdsOrEventIds: eventIds,
  },
});

export const removeEventIds = (eventIds: EventIds) => ({
  type: MultiCalendarActions.RemoveDiffIdsOrEventIds,
  data: {
    diffIdsOrEventIds: eventIds,
  },
});

export const clearDiffIdsAndEventIds = () => ({
  type: MultiCalendarActions.ClearDiffIdsAndEventIds,
  data: {},
});

export const addCalendarIds = (calendarIds: CalendarIds) => ({
  type: MultiCalendarActions.AddCalendarIds,
  data: {
    calendarIds,
  },
});

export const removeCalendarIds = (calendarIds: CalendarIds) => ({
  type: MultiCalendarActions.RemoveCalendarIds,
  data: {
    calendarIds,
  },
});

export const clearCalendarIds = () => ({
  type: MultiCalendarActions.ClearCalendarIds,
  data: {},
});

export const clearAllSelected = () => ({
  type: MultiCalendarActions.ClearAllSelected,
  data: {},
});

export const setCalendarIdsForCalendarPeek = (calendarIds: CalendarIds) => ({
  type: MultiCalendarActions.SetCalendarIdsForCalendarPeek,
  data: {
    calendarIds,
  },
});
