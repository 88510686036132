/**
 * @generated SignedSource<<ea679c70c6b8f3326efcb2fa52bc3342>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FocusTimeExternalTransparency = "None" | "Opaque" | "Transparent" | "%future added value";
export type UpdateSmartHoldSyncSettingsMutationInput = {
  clientMutationId?: string | null;
  focusTimeExternalTransparency?: FocusTimeExternalTransparency | null;
  focusTimeIdealMinutesPerWeek?: number | null;
  focusTimeRemindersEnabled?: boolean | null;
  focusTimeSyncAll?: boolean | null;
  focusTimeSyncEnabled?: boolean | null;
  lunchRemindersEnabled?: boolean | null;
  lunchSyncEnabled?: boolean | null;
  orgRelayId: string;
  travelTimeRemindersEnabled?: boolean | null;
  travelTimeSyncEnabled?: boolean | null;
};
export type UpdateSmartHoldSyncSettingsMutation$variables = {
  input: UpdateSmartHoldSyncSettingsMutationInput;
};
export type UpdateSmartHoldSyncSettingsMutation$data = {
  readonly updateSmartHoldSyncSettings: {
    readonly shadowCalendarSettings: {
      readonly id: string;
      readonly focusTimeSyncEnabled: boolean;
      readonly lunchSyncEnabled: boolean;
      readonly travelTimeSyncEnabled: boolean;
      readonly focusTimeExternalTransparency: FocusTimeExternalTransparency | null;
      readonly focusTimeSyncAll: boolean;
      readonly focusTimeIdealMinutesPerWeek: number;
      readonly focusTimeRemindersEnabled: boolean;
      readonly travelTimeRemindersEnabled: boolean;
      readonly lunchRemindersEnabled: boolean;
    };
  } | null;
};
export type UpdateSmartHoldSyncSettingsMutation = {
  variables: UpdateSmartHoldSyncSettingsMutation$variables;
  response: UpdateSmartHoldSyncSettingsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSmartHoldSyncSettingsMutationPayload",
    "kind": "LinkedField",
    "name": "updateSmartHoldSyncSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ShadowCalendarSettings",
        "kind": "LinkedField",
        "name": "shadowCalendarSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "focusTimeSyncEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lunchSyncEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "travelTimeSyncEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "focusTimeExternalTransparency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "focusTimeSyncAll",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "focusTimeIdealMinutesPerWeek",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "focusTimeRemindersEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "travelTimeRemindersEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lunchRemindersEnabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSmartHoldSyncSettingsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSmartHoldSyncSettingsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6200d14398d708ad2ff0513738caf747",
    "id": null,
    "metadata": {},
    "name": "UpdateSmartHoldSyncSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSmartHoldSyncSettingsMutation(\n  $input: UpdateSmartHoldSyncSettingsMutationInput!\n) {\n  updateSmartHoldSyncSettings(input: $input) {\n    shadowCalendarSettings {\n      id\n      focusTimeSyncEnabled\n      lunchSyncEnabled\n      travelTimeSyncEnabled\n      focusTimeExternalTransparency\n      focusTimeSyncAll\n      focusTimeIdealMinutesPerWeek\n      focusTimeRemindersEnabled\n      travelTimeRemindersEnabled\n      lunchRemindersEnabled\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "79da223a2389a4f6167051735afcc37e";

export default node;
