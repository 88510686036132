export type FlowState = {
  current: {
    state: string | null;
  } | null;
};

const hasState = (state: string) => (flowState: FlowState | null) =>
  flowState?.current?.state === state;
const doesNotHaveState = (state: string) => (flowState: FlowState | null) =>
  !!flowState?.current?.state && flowState.current.state !== state;

export const isFlowStateFinished = hasState("Finished");
export const isFlowStateUnfinished = doesNotHaveState("Finished");
