import {
  bg_destructive,
  bg_positive,
  fg_destructive,
  fg_positive,
} from "@clockwise/design-system/tokens";
import React from "react";
import { Toaster } from "react-hot-toast";

export const ClockwiseToaster = () => (
  <Toaster
    position="bottom-center"
    toastOptions={{
      className: "clockwise-toast cw-body-base",
      success: {
        duration: 3000,
        className: "clockwise-toast cw-body-base cw-border cw-border-solid cw-border-default",
        iconTheme: { primary: fg_positive, secondary: bg_positive },
      },
      error: {
        duration: 5000,
        className: "clockwise-toast cw-body-base cw-border cw-border-solid cw-border-default",
        iconTheme: { primary: fg_destructive, secondary: bg_destructive },
      },
    }}
  />
);
