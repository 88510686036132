import { SnackBarContentVariant } from "#webapp/src/components/snack-bar/snack-bar-content/snack-bar-content";

export const snackBarActions = {
  SNACK_BAR_OPEN: "SNACK_BAR_OPEN",
  SNACK_BAR_CLOSE: "SNACK_BAR_CLOSE",
};

export const snackBarOpen = (
  message: React.ReactNode,
  variant?: SnackBarContentVariant,
  dismissable?: boolean,
  actionLabel?: string,
  actionCallback?: () => void,
  dismissCallback?: () => void,
  autoHideDuration?: number | null,
) => ({
  message,
  variant,
  dismissable,
  actionLabel,
  actionCallback,
  dismissCallback,
  type: snackBarActions.SNACK_BAR_OPEN,
  open: true,
  autoHideDuration: autoHideDuration !== undefined ? autoHideDuration : 4000,
});

export const snackBarClose = () => ({
  type: snackBarActions.SNACK_BAR_CLOSE,
});
