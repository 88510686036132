import { LocationTypeEnum } from "@clockwise/schema/v2";
import React from "react";
import { MeetingDuration } from "../meeting-duration";
import { DynamicMeetingDuration } from "../meeting-duration/meeting-duration";
import { MEETING_LOCATION_DETAILS } from "../scheduling-confirmation/event-summary";
import { MeetingTitle } from "./MeetingTitle";
import { LinkMember } from "./types";

type Props = {
  linkMembers: LinkMember[];
  isOwner: boolean;
  meetingName: string;
  description: string | null;
  duration: number;
  durations: number[];
  onDurationChange: (duration: number) => void;
  locationType: LocationTypeEnum | null;
  isRoundRobin: boolean;
};

export function MeetingDetails({
  linkMembers,
  isOwner,
  meetingName,
  description,
  duration,
  durations,
  onDurationChange,
  locationType,
  isRoundRobin,
}: Props) {
  const meetingLocation = locationType && MEETING_LOCATION_DETAILS[locationType];

  return (
    <div className="cw-flex cw-flex-col cw-gap-4" cw-id="link-meeting-details">
      <div className="cw-flex cw-flex-col cw-gap-4">
        <MeetingTitle linkMembers={linkMembers} isOwner={isOwner} isRoundRobin={isRoundRobin} />
        <div
          title={meetingName}
          className="cw-heading-2xl cw-text-ellipsis cw-max-w-xs xs:cw-max-w-[98%] xs:cw-line-clamp-2"
          cw-id="link-meeting-name"
          aria-label="meeting name"
        >
          {meetingName}
        </div>
      </div>
      <div className="cw-body-base cw-text-muted cw-font-bold cw-flex cw-flex-wrap cw-items-center cw-gap-x-5 cw-gap-y-2">
        {durations.length > 1 ? (
          <DynamicMeetingDuration
            duration={duration}
            durations={durations}
            onChange={onDurationChange}
          />
        ) : (
          <MeetingDuration key="duration" minutes={duration} />
        )}
        {meetingLocation && (
          <div className="cw-flex cw-items-center">
            <span className="cw-mb-[-3px]" cw-id="link-location-icon">
              {meetingLocation.locationIcon}
            </span>
            <span className="cw-ml-2" cw-id="link-location-type" aria-label="meeting location">
              {meetingLocation.locationName}
            </span>
          </div>
        )}
      </div>
      {description && (
        <p
          className="cw-body-base cw-text-muted cw-max-w-[96%] cw-line-clamp-14 cw-text-ellipsis xs:cw-line-clamp-none xs:cw-max-h-[250px] xs:cw-overflow-auto cw-whitespace-pre-line"
          cw-id="link-description"
        >
          {description}
        </p>
      )}
    </div>
  );
}
