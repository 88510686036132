// state
import { getReduxStore } from "#webapp/src/state/redux-store";
// network
import { LogSend } from "#webapp/src/network/socket.constants";
import socket from "@clockwise/web-commons/src/network/socket";
// local-storage
import { analyticsUser } from "#webapp/src/state/local-storage";
// util
import { getChromeSite } from "#webapp/src/util/chrome-extension.util";
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import {
  ConsoleTransport,
  InMemoryCacheTransport,
  logger,
  Logger,
} from "@clockwise/client-commons/src/util/logger";
import { SentryTransport } from "@clockwise/web-commons/src/util/logger.util";
export { logger };

export function getSharedMeta() {
  if (getEnvironment() === "test") {
    return {};
  }

  const userId = analyticsUser.get();
  const chromeSite = getChromeSite();
  const {
    webExtensionVersion,
    googleVersion,
    calendarUrl,
  } = getReduxStore().getState().webExtension;

  return {
    userId,
    chromeSite,
    webExtensionVersion,
    googleVersion,
    calendarUrl,
    webappVersion: process.env.RELEASE,
  };
}

export function ddLog(
  level: string,
  message: string,
  service: string,
  meta: Record<string, string>,
) {
  return new Promise<void>((resolve) => {
    socket.get().emit(LogSend, {
      level,
      message,
      service,
      meta: { ...getSharedMeta(), ...meta },
    });
    resolve();
  });
}

//////////////////
// Singleton
//////////////////
// override the default getSharedMeta defined by the common Logger
Logger.getSharedMeta = getSharedMeta;

export const sentryTransport = new SentryTransport();
export const inMemoryCacheTransport = new InMemoryCacheTransport();

logger.add(inMemoryCacheTransport);

if (getEnvironment() !== "production") {
  logger.add(new ConsoleTransport());
}

if (getEnvironment() === "production") {
  logger.add(sentryTransport);
}
