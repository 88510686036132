import React from "react";

// components
import { Downtime } from "#webapp/src/components/downtime";

// util
import { windowLocation } from "#webapp/src/util/location.util";
import { PostMessageManager } from "#webapp/src/util/post-message.util";
import { enhancedFetch } from "@clockwise/client-commons/src/util/network";
import { getTimeZoneGuess } from "@clockwise/client-commons/src/util/time-zone";
import { toHashCode } from "@clockwise/web-commons/src/util/hash";

// state
import { analyticsPre, cwInternal, cwUser, jwt } from "#webapp/src/state/local-storage";
import { getApiUrl } from "@clockwise/client-commons/src/config/api";
import { createRoot } from "react-dom/client";
import { recordDuration } from "./stats.util";

export interface HealthPayload {
  health: "alive" | "dead";
  statusImage?: string;
  statusText?: string;
  allowUsers?: boolean;
  allowInternal?: boolean;
  minReloadMillis?: number;
  maxReloadMillis?: number;
  rollbackPercentage?: number;
}

export const defaultHealthPayload: HealthPayload = {
  health: "alive",
};

let downtimeStatusInterval: ReturnType<typeof setInterval> | null = null;

export function getOnlineStatus(): Promise<boolean> {
  return new Promise((resolve) => {
    const start = new Date().valueOf();
    const tz = getTimeZoneGuess();

    const tags: Record<string, string> = {};
    tags.tz = tz;

    enhancedFetch(`${getApiUrl()}/health`, {
      timeout: 20000,
      after: (response) => {
        const now = new Date().valueOf();

        const maybeHeader =
          response &&
          response.headers.get("Received-Millis") &&
          Number.parseInt(response.headers.get("Received-Millis")!);

        if (maybeHeader && !isNaN(maybeHeader)) {
          // assumes clocks are synced
          recordDuration("client.health.client_to_server", maybeHeader - start, tags);
          recordDuration("client.health.server_to_client", now - maybeHeader, tags);
        }

        recordDuration("client.health", now - start, tags);
      },
    })
      .then((response) => resolve(response.status === 200))
      .catch(() => resolve(false));
  });
}

/**
 * Returns the current health state via fetch.
 */
export function getHealth(): Promise<HealthPayload> {
  return new Promise((resolve) => {
    enhancedFetch("/health.json", { timeout: 2000 })
      .then(async (response) => {
        const text = await response.text();
        try {
          const healthPayload = JSON.parse(text);
          if (
            !healthPayload ||
            !healthPayload.health ||
            (healthPayload.health !== "alive" && healthPayload.health !== "dead")
          ) {
            return resolve(defaultHealthPayload);
          }

          return resolve(healthPayload as HealthPayload);
        } catch (e) {
          return resolve(defaultHealthPayload);
        }
      })
      .catch(() => resolve(defaultHealthPayload));
  });
}

/**
 * Returns the current health state via fetch, augmented if allowUsers or allowInternal is set.
 */
export function getHealthAugmented(): Promise<HealthPayload> {
  const preId = analyticsPre.get();

  return getHealth().then(async (healthPayload) => {
    const userBucket = preId ? Math.abs((toHashCode(preId) % 1000) / 10) : 100;

    if (healthPayload.allowUsers && (jwt.get() || cwUser.get())) {
      healthPayload.health = "alive";
    } else if (healthPayload.allowInternal && cwInternal.get()) {
      healthPayload.health = "alive";
    } else if (healthPayload.rollbackPercentage && userBucket <= healthPayload.rollbackPercentage) {
      healthPayload.health = "alive";
    }

    return healthPayload;
  });
}

export async function checkDowntimeStatus(lastStatus: HealthPayload, isChromeExtension?: boolean) {
  const healthPayload = await getHealthAugmented();

  const minReloadMillis = lastStatus.minReloadMillis || 1 * 60 * 1000;
  const maxReloadMillis = lastStatus.maxReloadMillis || 10 * 60 * 1000;

  if (lastStatus.health !== healthPayload.health) {
    if (downtimeStatusInterval) {
      clearInterval(downtimeStatusInterval);
      downtimeStatusInterval = null;
    }

    setTimeout(() => {
      if (
        isChromeExtension &&
        healthPayload.health === "alive" &&
        (!window.location.search || !/[?&]cwopen=/.test(window.location.search))
      ) {
        window.location.search += window.location.search ? "&cwopen=1" : "?cwopen=1";
      }

      windowLocation.reload("HealthUtilDowntime");
    }, minReloadMillis + Math.random() * (maxReloadMillis - minReloadMillis));
  }
}

export function setupDowntimeInterval(lastStatus: HealthPayload, isChromeExtension?: boolean) {
  if (downtimeStatusInterval) {
    return;
  }

  downtimeStatusInterval = setInterval(() => {
    void checkDowntimeStatus(lastStatus, isChromeExtension);
  }, 3 * 60 * 1000);
}

export function loadDowntime(healthPayload: HealthPayload, isChromeExtension?: boolean) {
  PostMessageManager.sendInitialLoadComplete(false);

  const rootElem = document.getElementById("root");
  if (!rootElem) {
    throw new Error("Root element not found");
  }
  createRoot(rootElem).render(
    <Downtime healthPayload={healthPayload} isChromeExtension={isChromeExtension} />,
  );
}
