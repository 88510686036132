import { graphql } from "react-relay";

export const updateWorkingHoursMutation = graphql`
  mutation UpdateWorkingHoursMutation($input: UpdateWorkingHoursMutationInput!) {
    updateWorkingHours(input: $input) {
      updatedUserMeetingHours {
        id
        timeZone
        type
        daySettings {
          day
          setting {
            minSlot
            maxSlot
            excludedSlots
          }
        }
        dayOverrides {
          date
          setting {
            minSlot
            maxSlot
            excludedSlots
          }
        }
      }
      updatedUserWorkingHours {
        id
        timeZone
        type
        daySettings {
          day
          setting {
            minSlot
            maxSlot
            excludedSlots
          }
        }
        dayOverrides {
          date
          setting {
            minSlot
            maxSlot
            excludedSlots
          }
        }
      }
      updatedOrgMeetingHours {
        id
        timeZone
        type
        daySettings {
          day
          setting {
            minSlot
            maxSlot
            excludedSlots
          }
        }
        dayOverrides {
          date
          setting {
            minSlot
            maxSlot
            excludedSlots
          }
        }
      }
      userWorkingHours {
        id
        hasBeenSet
        workingHours {
          timeZone
        }
      }
    }
  }
`;
