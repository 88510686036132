/**
 * @generated SignedSource<<1f5a12f509a697667289aa1175dc1653>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductTypeEnum = "Business_NonStandard" | "Business_Standard" | "Enterprise_NonStandard" | "Enterprise_Standard" | "Pro_NonStandard" | "Pro_Standard" | "%future added value";
export type UrnType = "Date" | "Email" | "Event" | "FlowState" | "Guest" | "Org" | "Phone" | "Services" | "Setting" | "Team" | "Teammate" | "TimeZone" | "User" | "WorkOsOrg" | "WorkingGroup" | "WorkingHours" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MixedCheckoutForm_org$data = {
  readonly id: string;
  readonly currentOrgPersons: {
    readonly __typename: "OrgPersonList";
    readonly list: ReadonlyArray<{
      readonly __typename: string;
      readonly id: string;
      readonly primaryCalendarId: string;
      readonly isYou: boolean;
      readonly userId: string | null;
      readonly personId: string;
      readonly profile: {
        readonly givenName: string | null;
        readonly familyName: string | null;
        readonly externalImageUrl: string | null;
      } | null;
    }>;
  } | {
    readonly __typename: "GraphEntityError";
    readonly statusCode: number | null;
    readonly message: string | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly name: string | null;
  readonly experiments: ReadonlyArray<string | null> | null;
  readonly primaryOrgEmail: string;
  readonly primaryOrgCalendar: string;
  readonly primaryTeam: {
    readonly __typename: "Team";
    readonly teamId: string;
    readonly teamName: string;
  } | {
    readonly __typename: "GraphEntityError";
    readonly statusCode: number | null;
    readonly message: string | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly userTeams: {
    readonly __typename: "TeamList";
    readonly list: ReadonlyArray<{
      readonly teamId: string;
    }>;
  } | {
    readonly __typename: "GraphEntityError";
    readonly statusCode: number | null;
    readonly message: string | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly billingGroups: {
    readonly list?: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly syncSources: ReadonlyArray<{
        readonly type: UrnType | null;
        readonly value: string | null;
      }>;
      readonly subscription: {
        readonly __typename: "SubscriptionDetails";
        readonly subscription: {
          readonly id: string;
          readonly productType: ProductTypeEnum | null;
        };
      } | {
        readonly __typename: "GraphEntityError";
        readonly message: string | null;
        readonly statusCode: number | null;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
    }>;
    readonly message?: string | null;
    readonly statusCode?: number | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"OrgUserSelectForm_org" | "TeamJoinCreateDialogRenderer_org">;
  readonly " $fragmentType": "MixedCheckoutForm_org";
};
export type MixedCheckoutForm_org$key = {
  readonly " $data"?: MixedCheckoutForm_org$data;
  readonly " $fragmentSpreads": FragmentRefs<"MixedCheckoutForm_org">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusCode",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "type": "GraphEntityError",
  "abstractKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamId",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    (v2/*: any*/)
  ],
  "type": "GraphEntityError",
  "abstractKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MixedCheckoutForm_org",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrgUserSelectForm_org"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TeamJoinCreateDialogRenderer_org"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "currentOrgPersons",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrgPerson",
              "kind": "LinkedField",
              "name": "list",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "primaryCalendarId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isYou",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "personId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserProfile",
                  "kind": "LinkedField",
                  "name": "profile",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "givenName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "familyName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "externalImageUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "OrgPersonList",
          "abstractKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "experiments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryOrgEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryOrgCalendar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "primaryTeam",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "teamName",
              "storageKey": null
            }
          ],
          "type": "Team",
          "abstractKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "userTeams",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Team",
              "kind": "LinkedField",
              "name": "list",
              "plural": true,
              "selections": [
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "TeamList",
          "abstractKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "billingGroups",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BillingGroup",
              "kind": "LinkedField",
              "name": "list",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Urn",
                  "kind": "LinkedField",
                  "name": "syncSources",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "value",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "subscription",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PaymentSubscription",
                          "kind": "LinkedField",
                          "name": "subscription",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "productType",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "SubscriptionDetails",
                      "abstractKey": null
                    },
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "BillingGroupList",
          "abstractKey": null
        },
        (v7/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Org",
  "abstractKey": null
};
})();

(node as any).hash = "712ea7a3e3828a44768d3f1b64286e9d";

export default node;
