import { graphql } from "react-relay";

export const updateToggleEventColoringSettingsMutation = graphql`
  mutation UpdateToggleEventColoringSettingsMutation(
    $input: ToggleEventColoringSettingsMutationsInput!
  ) {
    updateToggleEventColoringSettings(input: $input) {
      eventColoringSettings {
        eventCategoryColorings {
          eventCategory
          googleColorKey
          active
          isUserSet
        }
      }
    }
  }
`;
