import { reduce } from "lodash";
import { Duration, DurationObjectUnits, ToHumanDurationOptions } from "luxon";

/**
 * Create a normalized duration from a given number of minutes. Shifting the duration into days, hours, and minutes as needed.
 *
 * e.g. 180 minutes becomes 3 hours, 10 minutes
 */
export function normalizeDuration(minutes: number, seconds = 0) {
  const durationObj = Duration.fromObject({ days: 0, hours: 0, minutes, seconds })
    .normalize()
    .toObject();

  // Filter out all 0 values so they don't pollute formatting
  const cleanDuration = reduce<DurationObjectUnits, DurationObjectUnits>(
    durationObj,
    (acc, value, unit) => {
      if (value) {
        acc[unit as keyof DurationObjectUnits] = value;
      }
      return acc;
    },
    {},
  );

  return Duration.fromObject(cleanDuration);
}

/**
 * Formats a duration in minutes to a string with appropriate units for the length.
 *
 * e.g. `180` becomes `3 hours, 10 minutes`
 *
 * @param minutes Number of minutes in the duration
 * @param opts Options object for `toHuman` function
 * @returns Formatted string displaying the duration in adjusted units.
 */
export const formatDuration = (minutes: number, opts?: ToHumanDurationOptions) => {
  minutes = Math.round(minutes);
  const formatted = normalizeDuration(minutes).toHuman(opts).replace(",", "");
  return minutes > 60 ? formatted.replace("minutes", "mins").replace("minute", "min") : formatted;
};

/**
 * Returns a duration in minutes
 *
 * @param startMillis Event start millis
 * @param endMillis Event end millis
 * @returns Number representing the duration in minutes
 */
export const getDurationMinutes = (startMillis: number, endMillis: number) => {
  return Math.round((endMillis - startMillis) / 60000);
};
