// internal
import { ISharedState } from "./shared.types";

export enum SharedActions {
  MergeInitialStates = "MergeInitialStates",
  SetNetworkFailureScale = "SetNetworkFailureScale",
}

export interface SharedAction {
  type: SharedActions;
  isDispatched?: boolean;
  initialState?: ISharedState;
  data: Partial<ISharedState>;
}

export const setNetworkFailureScale = (networkFailureScale: number): SharedAction => ({
  type: SharedActions.SetNetworkFailureScale,
  data: {
    networkFailureScale,
  },
});
