import { ZonedMoment } from "@clockwise/client-commons/src/util/ZonedMoment";
import { Link } from "@clockwise/design-system";
import { MoreVert } from "@clockwise/design-system/icons";
import { EventLogType } from "@clockwise/schema/v2";
import { animated, config, useTransition } from "@react-spring/web";
import { default as classnames, default as classNames } from "classnames";
import { DateTime } from "luxon";
import * as React from "react";
import ReactTooltip from "react-tooltip";
import { getFormattedZonedTimestamp } from "../../util/date.util";

export enum TimelineLogType {
  PastLog = "PastLog",
  FutureLog = "FutureLog",
  Action = "Action",
  Info = "Info",
}
export interface TimelineLog {
  id: string;
  icon: React.ReactNode | null;
  description: React.ReactNode;
  defragInfo: React.ReactNode;
  timestamp: number | null;
  type: TimelineLogType;
  // mav todo: make the following fields required
  // will be optional for now because the timeline component
  // is used both in prism and in the extension, but
  // we'll be deprecating the extension timeline
  eventOriginalStartTime?: number | null;
  eventNewStartTime?: number | null;
  eventLogType?: EventLogType;
  username?: string;
}

export interface TimelineRowProps {
  icon: React.ReactNode;
  description: React.ReactNode;
  defragInfo: React.ReactNode;
  timestamp: number | null;
  // mav todo: make the following fields required
  // will be optional for now because the timeline component
  // is used both in prism and in the extension, but
  // we'll be deprecating the extension timeline
  eventOriginalStartTime?: number | null;
  eventNewStartTime?: number | null;
  eventLogType?: EventLogType;
  username?: string;
  type: TimelineLogType;
  index: number;
  numLogs: number;
  timelineCollapsed: boolean;
  isMissingHistory?: boolean;
  onClickShowMoreHistory: () => void;
}
export interface TimelineProps {
  timelineLogs: TimelineLog[];
  isMissingHistory?: boolean; // will be removing this once extension sidebar is deprecated
  trackTimelineExpanded: (payload: Record<string, unknown>) => void;
}

const getTimestampLabel = (timestamp: number): string => {
  return new ZonedMoment(timestamp).fromNow();
};

const INITIAL_HISTORY_VIEW_LOG_LIMIT = 5;

export const EVENT_HISTORY_DEFAULT_USERNAME = "Clockwise";

// ex: Tue Aug 27 at 12:00pm
const EVENT_HISTORY_DATETIME_FORMAT = "ccc LLL d 'at' h:mma";

interface TimelineRowDescriptionProps {
  eventOriginalStartTime?: string;
  eventNewStartTime?: string;
  description: React.ReactNode;
  // mav todo: make the following fields required
  // will be optional for now because the timeline component
  // is used both in prism and in the extension, but
  // we'll be deprecating the extension timeline
  username?: string;
  eventLogType?: EventLogType;
}

const getAugmentedDescription = ({
  username,
  eventOriginalStartTime,
  eventNewStartTime,
  eventLogType,
  description,
}: TimelineRowDescriptionProps): React.ReactNode => {
  // mav todo: remove this condition once history in extension is deprecated
  if (eventLogType === undefined) {
    return description;
  }

  const boldClassNames = "cw-body-sm cw-w-fit cw-font-medium";
  if (eventLogType === EventLogType.EventCreated) {
    return (
      <div>
        <b className={classNames(boldClassNames)}>{username}</b> created this event.
      </div>
    );
  }
  if (eventLogType === EventLogType.FlexTurnedOn) {
    return (
      <div>
        <b className={classNames(boldClassNames)}>{username}</b> marked this event as flexible.
      </div>
    );
  }
  if (eventLogType === EventLogType.FlexTurnedOff) {
    return (
      <div>
        <b className={classNames(boldClassNames)}>{username}</b> turned off flexibility for this
        event.
      </div>
    );
  }
  if (
    eventLogType === EventLogType.ClockwiseMeetingMove ||
    eventLogType === EventLogType.ClockwiseEventRescheduled ||
    eventLogType === EventLogType.ClockwiseSecondaryReschedule
  ) {
    const children =
      eventOriginalStartTime && eventNewStartTime ? (
        <>
          <b className={classNames(boldClassNames)}>{username}</b> moved this event from <br />
          <b className={classNames(boldClassNames)}>{eventOriginalStartTime}</b> to{" "}
          <b className={classNames(boldClassNames)}>{eventNewStartTime}.</b>
        </>
      ) : (
        <>
          <b className={classNames(boldClassNames)}>{username}</b> moved this event.
        </>
      );

    return <div>{children}</div>;
  }
  if (eventLogType === EventLogType.ClockwiseMovePaused) {
    return (
      <div>
        <b className={classNames(boldClassNames)}>{username}</b> stopped looking for better times
        for this event, because it was manually rescheduled.
      </div>
    );
  }
  if (eventLogType === EventLogType.ClockwiseMoveUnpaused) {
    return (
      <div>
        <b className={classNames(boldClassNames)}>{username}</b> allowed Clockwise to move this
        event again if a better time became available
      </div>
    );
  }
  if (eventLogType === EventLogType.ClockwiseCouldNotResolveConflict) {
    return (
      <div>
        <b className={classNames(boldClassNames)}>{username}</b> was unable to resolve conflict
        because no alternative times were available
      </div>
    );
  }
  if (eventLogType === EventLogType.EventRescheduled) {
    return (
      <div>
        An attendee not using Clockwise moved this event from <br />
        <b className={classNames(boldClassNames)}>{eventOriginalStartTime}</b> to{" "}
        <b className={classNames(boldClassNames)}>{eventNewStartTime}.</b>
      </div>
    );
  }

  return description;
};

const TimelineRow = ({
  icon,
  description,
  defragInfo,
  type,
  eventLogType,
  eventOriginalStartTime,
  eventNewStartTime,
  username,
  timestamp,
  index,
  numLogs,
  timelineCollapsed,
  isMissingHistory,
  onClickShowMoreHistory,
}: TimelineRowProps) => {
  // the vertical lines connecting timeline icons
  const renderTimelineSeparator = () => {
    const isDisconnectedTimelineLog = timelineCollapsed
      ? index >= numLogs - 1
      : index >= numLogs - 2;

    // if we are missing history and in the expanded timeline view,
    // the second to last log represents a break in the timeline
    // so we don't render a separator after the last *three* logs.
    const isDisconnectedMissingHistoryLog =
      isMissingHistory && !timelineCollapsed && index >= numLogs - 3;

    if (isDisconnectedTimelineLog || isDisconnectedMissingHistoryLog) {
      return null;
    }

    return (
      <div
        className={classnames(
          "cw-border cw-border-neutral cw-flex-1 cw-m-1 cw-mb-[3px] cw-min-h-[10px]",
          {
            "cw-border-dashed": type === TimelineLogType.FutureLog,
            "cw-border-solid": type !== TimelineLogType.FutureLog,
          },
        )}
      />
    );
  };

  const augmentedDescription = getAugmentedDescription({
    username,
    // In practice, these numbers won't be null for the given event log type
    eventOriginalStartTime: DateTime.fromMillis(eventOriginalStartTime ?? 0).toFormat(
      EVENT_HISTORY_DATETIME_FORMAT,
    ),
    eventNewStartTime: DateTime.fromMillis(eventNewStartTime ?? 0).toFormat(
      EVENT_HISTORY_DATETIME_FORMAT,
    ),
    eventLogType,
    description,
  });

  return (
    <div className="cw-flex cw-gap-3" cw-id="history-timeline-event">
      <div
        className={classnames(
          "cw-flex cw-flex-col cw-items-center cw-flex-none cw-min-w-[16px] cw-pt-[1px]",
          {
            "cw-mt-3": type === TimelineLogType.FutureLog,
          },
        )}
      >
        <span className="cw-h-4 cw-w-4 cw-text-subtle">{icon}</span>
        {renderTimelineSeparator()}
      </div>
      <div>
        {type === TimelineLogType.Action ? (
          <div className="cw-text-13">
            <Link onClick={onClickShowMoreHistory}>{description}</Link>
          </div>
        ) : (
          <div
            className={classnames("cw-body-sm cw-text-neutral-muted", {
              "cw-text-subtle cw-mb-3": type === TimelineLogType.Info,
            })}
          >
            {augmentedDescription}
            {defragInfo}
          </div>
        )}
        {timestamp && (
          <div
            data-for={`${timestamp}-${index}`}
            data-tip=""
            /* Needed to render empty tooltip */
            className="cw-caption cw-leading-tight cw-text-subtle cw-mb-3"
          >
            {getTimestampLabel(timestamp)}
            <ReactTooltip multiline type="dark" id={`${timestamp}-${index}`}>
              {getFormattedZonedTimestamp(timestamp)}
            </ReactTooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export const Timeline = ({
  timelineLogs,
  isMissingHistory,
  trackTimelineExpanded,
}: TimelineProps) => {
  // always collapse history if we're over the initial log limit
  const [timelineCollapsed, setTimedlineCollapsed] = React.useState(
    timelineLogs.length - 1 > INITIAL_HISTORY_VIEW_LOG_LIMIT,
  );

  const augmentTimelineLogs = () => {
    // the BE supplies logs in chronological order,
    // but we want to render them reverse-chronologically
    const orderedLogs = [...timelineLogs].reverse();

    let displayLogs: TimelineLog[];

    // if we're not collapsing the view, or we have
    // fewer log entries than our limit return every log
    if (!timelineCollapsed) {
      displayLogs = [...orderedLogs];
      if (isMissingHistory) {
        const missingHistoryInfoLog: TimelineLog = {
          id: "Missing History",
          icon: null,
          description: `Clockwise doesn't keep history older than 14 days`,
          defragInfo: null,
          timestamp: null,
          type: TimelineLogType.Info,
          eventLogType: EventLogType.NextFlexRun, // placeholder log type value
          eventOriginalStartTime: null,
          eventNewStartTime: null,
          username: EVENT_HISTORY_DEFAULT_USERNAME,
        };

        // if we're missing history, and showing an expanded timeline
        // add an info log in the second to last position
        // where orderedLogs.length -1 is the index at which to start
        // changing the array
        displayLogs.splice(orderedLogs.length - 1, 0, missingHistoryInfoLog);
      }
    } else {
      // if we're collapsing history,
      // limit the number of initial logs displayed
      // and insert a button in the second to last log position
      const showMoreHistoryActionLog: TimelineLog = {
        id: "Show More",
        icon: <MoreVert className="cw-w-4 cw-h-4" />,
        description: "Show more history",
        defragInfo: null,
        timestamp: null,
        type: TimelineLogType.Action,
        eventLogType: EventLogType.NextFlexRun, // placeholder log type value
        eventOriginalStartTime: null,
        eventNewStartTime: null,
        username: EVENT_HISTORY_DEFAULT_USERNAME,
      };

      displayLogs = [
        ...orderedLogs.slice(0, INITIAL_HISTORY_VIEW_LOG_LIMIT),
        showMoreHistoryActionLog,
        orderedLogs[orderedLogs.length - 1],
      ];
    }

    return displayLogs;
  };

  const [augmentedTimelineLogs, setAugmentedTimelineLogs] = React.useState<TimelineLog[]>(
    augmentTimelineLogs(),
  );

  React.useEffect(() => {
    const updatedLogs = augmentTimelineLogs();
    setAugmentedTimelineLogs(updatedLogs);
  }, [timelineLogs, isMissingHistory, timelineCollapsed]);

  const transitions = useTransition(augmentedTimelineLogs, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    trail: 25,
    keys: augmentedTimelineLogs.map((log) => log.id),
    config: config.molasses,
  });

  const onClickShowMoreHistory = () => {
    setTimedlineCollapsed(false);
    trackTimelineExpanded({
      numItemsTotal: timelineLogs.length,
    });
  };

  return (
    <div className="cw-flex cw-flex-col">
      {transitions(
        (
          { opacity },
          {
            type,
            description,
            defragInfo,
            icon,
            timestamp,
            eventOriginalStartTime,
            eventNewStartTime,
            eventLogType,
            username,
          },
          _,
          index,
        ) => (
          <animated.div
            key={index}
            style={{
              // only transition "collapsed" events
              opacity:
                index > INITIAL_HISTORY_VIEW_LOG_LIMIT && !timelineCollapsed
                  ? opacity.to({ range: [0.0, 1.0], output: [0, 1] })
                  : 1,
            }}
          >
            <TimelineRow
              icon={icon}
              description={description}
              defragInfo={defragInfo}
              type={type}
              eventLogType={eventLogType}
              username={username}
              timestamp={timestamp}
              index={index}
              numLogs={augmentedTimelineLogs.length}
              timelineCollapsed={timelineCollapsed}
              eventNewStartTime={eventNewStartTime}
              eventOriginalStartTime={eventOriginalStartTime}
              isMissingHistory={isMissingHistory}
              onClickShowMoreHistory={onClickShowMoreHistory}
            />
          </animated.div>
        ),
      )}
    </div>
  );
};
