// schema
import * as ISchema from "#webapp/src/__schema__";
import { logger } from "#webapp/src/util/logger.util";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import {
  BillingGroup,
  BillingGroupsOfCalendarIdList,
  BillingGroupTypeEnum,
  Maybe,
  OrgPersonListErrorable,
} from "@clockwise/schema";
import { compact } from "lodash";
import { BillingGroupRoleEnum } from "../components/admin-panel/admin-panel-view/ap-members/APMembers.util";

export const maybeGetBillingGroupsFromOrg = (org: ISchema.IOrg) => {
  if (org.billingGroups?.__typename === "GraphEntityError") {
    logger.error("failed to maybeGetBillingGroupsFromOrg");
    return;
  }
  if (!org.billingGroups) {
    return;
  }

  return org.billingGroups.list;
};

export const getBillingGroupForId = (org: ISchema.IOrg, billingGroupId: string) => {
  const billingGroupsForOrg = maybeGetBillingGroupsFromOrg(org);

  if (!billingGroupsForOrg) {
    return;
  }

  return billingGroupsForOrg.find((bg) => bg.id === billingGroupId);
};

export function getOrgBillingGroup(org: ISchema.IOrg) {
  const billingGroups = maybeGetBillingGroupsFromOrg(org);
  if (!billingGroups) {
    return null;
  }

  const maybeOrgBillingGroup = billingGroups.find((bg) => {
    return bg.syncSources.find((ss) => ss.type === "Org");
  });
  return maybeOrgBillingGroup || null;
}

type BillingGroupLikeHasAdmins = {
  adminOrgPersons: OrgPersonListErrorable;
};

export const getAdminsFromBillingGroup = (billingGroup: BillingGroupLikeHasAdmins) => {
  if (billingGroup?.adminOrgPersons?.__typename === "GraphEntityError") {
    logger.error("failed to getAdminsFromBillingGroup");
    return;
  }

  return billingGroup?.adminOrgPersons?.list;
};

export const getSubscriptionFromBillingGroup = (billingGroup: ISchema.IBillingGroup) => {
  if (!billingGroup.subscription || billingGroup.subscription.__typename === "GraphEntityError") {
    logger.error("failed to getSubscriptionFromBillingGroup");
    return;
  }

  return billingGroup.subscription;
};

export const isCurrentUserInBillingGroup = (
  currentUsersBillingGroups: BillingGroup[],
  billingGroupIdToCheck: string,
) => {
  const billingGroupMatch = currentUsersBillingGroups.find((bg) => bg.id === billingGroupIdToCheck);
  return Boolean(billingGroupMatch);
};

export const getUserRoleForBillingGroup = (
  billingGroup: BillingGroup,
  orgPersonUserId?: string,
) => {
  if (!orgPersonUserId) {
    return BillingGroupRoleEnum.None;
  }

  const adminOrgPersons = getValue(billingGroup.adminOrgPersons)?.list;

  if (!adminOrgPersons) {
    return BillingGroupRoleEnum.Member;
  }

  return adminOrgPersons.findIndex((a) => a.userId === orgPersonUserId) !== -1
    ? BillingGroupRoleEnum.Admin
    : BillingGroupRoleEnum.Member;
};

export function isBillingGroupTypeSSO(billingGroupType?: Maybe<BillingGroupTypeEnum> | undefined) {
  if (!billingGroupType) {
    logger.error("failed to call isBillingGroupTypeSSO because incomplete fragment");
    return false;
  }

  return billingGroupType === BillingGroupTypeEnum.SSO;
}

export function getPersonListCountFromBillingGroup(billingGroup: ISchema.IBillingGroup) {
  if (billingGroup.personListPaginatedErrorable.__typename === "GraphEntityError") {
    logger.error("failed to personListCount");
    return;
  }

  return billingGroup.personListPaginatedErrorable.count;
}

export const getAllCurrentUsersBillingGroups = (
  billingGroupsOfSelectedUsers: BillingGroupsOfCalendarIdList[],
  currentUserCalendarId: string,
) => {
  const bgDataOfCurrentUser = billingGroupsOfSelectedUsers.find((bgDataOfUser) => {
    return bgDataOfUser.calendarId === currentUserCalendarId;
  });
  if (bgDataOfCurrentUser?.allBillingGroupsOfCalendarId?.length) {
    const nonErrorableResponse = bgDataOfCurrentUser?.allBillingGroupsOfCalendarId.map((bg) =>
      getValue(bg),
    );
    return compact(nonErrorableResponse);
  } else {
    return [];
  }
};
