//////////////////
// IMPORTS
//////////////////
import {
  IReschedulerAction,
  ReschedulerAction,
  ReschedulerCameFrom,
} from "#webapp/src/state/actions/rescheduler.actions";

//////////////////
// TYPEs
//////////////////
export interface IReschedulerState {
  highlightBestOption: boolean;
  cameFrom?: ReschedulerCameFrom;
}

//////////////////
// INITIAL DATA
//////////////////
export const initialState: IReschedulerState = {
  highlightBestOption: false,
};

export function reschedulerReducer(state = initialState, action: IReschedulerAction) {
  switch (action.type) {
    case ReschedulerAction.HighlightBestOption:
      const cameFrom = action.data ? action.data.cameFrom : state.cameFrom;
      return { ...state, cameFrom, highlightBestOption: true };
    case ReschedulerAction.ResetState:
      return initialState;
    default:
      return state;
  }
}
