import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateTeamAndTeamSubscriptionStripeMutationVariables = Types.Exact<{
  input: Types.CreateTeamAndTeamSubscriptionStripeInput;
}>;


export type CreateTeamAndTeamSubscriptionStripeMutation = { __typename: 'Mutation', createTeamAndTeamSubscriptionStripe: { __typename: 'CreateTeamAndTeamSubscriptionStripePayload', clientMutationId: string | null, team: { __typename: 'Team', id: string, teamId: string, teamName: string, teamCalendarId: string | null, createdBy: { __typename: 'Urn', id: string, type: Types.UrnType | null, value: string | null } }, stripeError: { __typename: 'StripeError', code: string | null, message: string | null } | null } | null };


export const CreateTeamAndTeamSubscriptionStripeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTeamAndTeamSubscriptionStripe"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateTeamAndTeamSubscriptionStripeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTeamAndTeamSubscriptionStripe"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clientMutationId"}},{"kind":"Field","name":{"kind":"Name","value":"team"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"teamId"}},{"kind":"Field","name":{"kind":"Name","value":"teamName"}},{"kind":"Field","name":{"kind":"Name","value":"teamCalendarId"}},{"kind":"Field","name":{"kind":"Name","value":"createdBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"stripeError"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]} as unknown as DocumentNode<CreateTeamAndTeamSubscriptionStripeMutation, CreateTeamAndTeamSubscriptionStripeMutationVariables>;