import { graphql } from "react-relay";

export const updateUserDefinedTeamMutation = graphql`
  mutation UpdateUserDefinedTeamMutation($input: UpdateUserDefinedTeamMutationInput!) {
    updateUserDefinedTeam(input: $input) {
      userDefinedTeam {
        calendarIds
      }
    }
  }
`;
