import { Button } from "@clockwise/design-system";
import { Undo } from "@clockwise/design-system/icons";
import { DateTime, Interval } from "luxon";
import React, { ComponentProps, useCallback } from "react";
import { DateString } from "../../scheduling-link";

export type Props = ComponentProps<typeof Button> & {
  currentDate: string | null | undefined;
  earliestTimeSlot: string | undefined;
  disabled?: boolean;
  onShowEarliest: (date: DateString) => void;
};

export const ShowEarliestButton = ({
  currentDate,
  earliestTimeSlot,
  disabled = false,
  onShowEarliest,
  ...props
}: Props) => {
  const earliestDate = earliestTimeSlot
    ? Interval.fromISO(earliestTimeSlot).start.toISODate()
    : DateTime.now().toISODate();

  const handleShowEarliest = useCallback(() => onShowEarliest(earliestDate), [
    earliestDate,
    onShowEarliest,
  ]);

  const isDisabled = disabled || currentDate === earliestDate;

  return (
    <Button
      {...props}
      size="small"
      variant="outlined"
      rounded
      startIcon={Undo}
      aria-label="scroll to earliest available times"
      cw-id="link-show-earliest"
      onClick={handleShowEarliest}
      disabled={isDisabled}
    >
      Show soonest
    </Button>
  );
};
