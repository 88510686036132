import { ZonedMoment } from "@clockwise/web-commons/src/util/time-zone.util";

export enum DayViewActions {
  SetSelectedMoment = "DayView_SetSelectedMoment",
  SetLastOffline = "DayView_SetLastOffline",
  SetScrollOffset = "DayView_SetScrollOffset",
  SetSnapToSelectedMomentOnNextMount = "DayView_SetSnapToSelectedMomentOnNextMount",
}

// sets the current selected day for the day view
export const setSelectedMoment = (selectedMoment: ZonedMoment) => ({
  type: DayViewActions.SetSelectedMoment,
  data: {
    selectedMoment,
  },
});

// sets the last offline value
export const setLastOffline = (lastOffline: number) => ({
  type: DayViewActions.SetLastOffline,
  data: {
    lastOffline,
  },
});

// sets the scroll offset on the day view
export const setScrollOffset = (scrollOffset: number) => ({
  type: DayViewActions.SetScrollOffset,
  data: {
    scrollOffset,
  },
});

export const setSnapToSelectedMomentOnNextMount = (snapToSelectedMomentOnNextMount: boolean) => ({
  type: DayViewActions.SetSnapToSelectedMomentOnNextMount,
  data: {
    snapToSelectedMomentOnNextMount,
  },
});
