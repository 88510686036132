import {
  bg_neutral,
  bg_neutral_emphasis,
  bg_neutral_emphasis_hover,
  border_neutral,
  fg_neutral,
} from "@clockwise/design-system/tokens";
import {
  bg_cal1,
  bg_cal1_Emphasis,
  bg_cal1_Hover,
  bg_cal2,
  bg_cal2_Emphasis,
  bg_cal2_Hover,
  bg_cal3,
  bg_cal3_Emphasis,
  bg_cal3_Hover,
  bg_cal4,
  bg_cal4_Emphasis,
  bg_cal4_Hover,
  bg_cal6,
  bg_cal6_Emphasis,
  bg_cal6_Hover,
  bg_cal7,
  bg_cal7_Emphasis,
  bg_cal7_Hover,
  bg_cal8,
  bg_cal8_Emphasis,
  bg_cal8_Hover,
  bg_cal9,
  bg_cal9_Emphasis,
  bg_cal9_Hover,
  bg_calOwn,
  bg_calOwn_Emphasis,
  bg_calOwn_Hover,
  border_cal1,
  border_cal2,
  border_cal3,
  border_cal4,
  border_cal6,
  border_cal7,
  border_cal8,
  border_cal9,
  border_calOwn,
  fg_cal1,
  fg_cal2,
  fg_cal3,
  fg_cal4,
  fg_cal6,
  fg_cal7,
  fg_cal8,
  fg_cal9,
  fg_calOwn,
} from "../constants/calendarColorTokens";
import { CalendarColorsState } from "./calendarColorsReducer";

// Generated these names from 'htmlcsscolor.com' based on the border hex values
type HumanReadableColor =
  | "Ruby Pink"
  | "Mango Orange"
  | "Ce Soir Purple"
  | "Confetti Yellow"
  | "Fuego Green"
  | "Biloba Purple"
  | "Tangerine Orange"
  | "Ironstone Brown"
  | "Neon Blue"
  | "Neutral";
type ReservedColorForUsersCalendar = "Neon Blue";
type UnknownColorForUsersCalendar = "Neutral";
type ColorsForOtherUsersCalendars = Exclude<
  HumanReadableColor,
  ReservedColorForUsersCalendar | UnknownColorForUsersCalendar
>;

export type CalendarColorSet = {
  bg: string;
  bg_Emphasis: string;
  bg_Hover: string;
  border: string;
  fg: string;
  humanReadableColor: HumanReadableColor;
};

export const calendarColorRotation: CalendarColorSet[] = [
  {
    bg: bg_cal1,
    bg_Emphasis: bg_cal1_Emphasis,
    bg_Hover: bg_cal1_Hover,
    border: border_cal1,
    fg: fg_cal1,
    humanReadableColor: "Ruby Pink",
  },
  {
    bg: bg_cal2,
    bg_Emphasis: bg_cal2_Emphasis,
    bg_Hover: bg_cal2_Hover,
    border: border_cal2,
    fg: fg_cal2,
    humanReadableColor: "Mango Orange",
  },
  {
    bg: bg_cal3,
    bg_Emphasis: bg_cal3_Emphasis,
    bg_Hover: bg_cal3_Hover,
    border: border_cal3,
    fg: fg_cal3,
    humanReadableColor: "Ce Soir Purple",
  },
  {
    bg: bg_cal4,
    bg_Emphasis: bg_cal4_Emphasis,
    bg_Hover: bg_cal4_Hover,
    border: border_cal4,
    fg: fg_cal4,
    humanReadableColor: "Confetti Yellow",
  },
  {
    bg: bg_cal6,
    bg_Emphasis: bg_cal6_Emphasis,
    bg_Hover: bg_cal6_Hover,
    border: border_cal6,
    fg: fg_cal6,
    humanReadableColor: "Fuego Green",
  },
  {
    bg: bg_cal7,
    bg_Emphasis: bg_cal7_Emphasis,
    bg_Hover: bg_cal7_Hover,
    border: border_cal7,
    fg: fg_cal7,
    humanReadableColor: "Biloba Purple",
  },
  {
    bg: bg_cal8,
    bg_Emphasis: bg_cal8_Emphasis,
    bg_Hover: bg_cal8_Hover,
    border: border_cal8,
    fg: fg_cal8,
    humanReadableColor: "Tangerine Orange",
  },
  {
    bg: bg_cal9,
    bg_Emphasis: bg_cal9_Emphasis,
    bg_Hover: bg_cal9_Hover,
    border: border_cal9,
    fg: fg_cal9,
    humanReadableColor: "Ironstone Brown",
  },
];

export const getOwnCalendarColor = (): CalendarColorSet => {
  return {
    bg: bg_calOwn,
    bg_Emphasis: bg_calOwn_Emphasis,
    bg_Hover: bg_calOwn_Hover,
    border: border_calOwn,
    fg: fg_calOwn,
    humanReadableColor: "Neon Blue",
  };
};

export const getUnknownCalendarColor = (): CalendarColorSet => {
  return {
    bg: bg_neutral,
    bg_Emphasis: bg_neutral_emphasis,
    bg_Hover: bg_neutral_emphasis_hover,
    border: border_neutral,
    fg: fg_neutral,
    humanReadableColor: "Neutral",
  };
};

export const getADefaultCalendarColor = (colorsInUse: CalendarColorsState): CalendarColorSet => {
  const colorOrdering: ColorsForOtherUsersCalendars[] = [
    "Ruby Pink",
    "Mango Orange",
    "Ce Soir Purple",
    "Confetti Yellow",
    "Fuego Green",
    "Biloba Purple",
    "Tangerine Orange",
    "Ironstone Brown",
  ];
  const colorDistribution: Record<ColorsForOtherUsersCalendars, number> = {
    "Ruby Pink": 0,
    "Mango Orange": 0,
    "Ce Soir Purple": 0,
    "Confetti Yellow": 0,
    "Fuego Green": 0,
    "Biloba Purple": 0,
    "Tangerine Orange": 0,
    "Ironstone Brown": 0,
  };

  // Count the number of times each color is used
  for (const calendarId in colorsInUse) {
    const color = colorsInUse[calendarId]?.humanReadableColor;

    if (!color) {
      continue;
    }

    // Skip the reserved color
    if (color === "Neon Blue" || color === "Neutral") {
      continue;
    }

    colorDistribution[color]++;
  }

  // Find the color with the least amount of usage
  const minimumUsage = Math.min(...Object.values(colorDistribution));
  const allColorsWithMinimumUsage = colorOrdering.filter(
    (color) => colorDistribution[color] === minimumUsage,
  );

  const firstLeastUsedColor = allColorsWithMinimumUsage[0];
  const aLeastColorObject = calendarColorRotation.find(
    (color) => color.humanReadableColor === firstLeastUsedColor,
  ) as CalendarColorSet;

  return aLeastColorObject;
};
