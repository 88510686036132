export const confettiActions = {
  RUN: "CONFETTI_RUN",
  STOP: "CONFETTI_STOP",
};

export const run = () => ({
  type: confettiActions.RUN,
});

export const stop = () => ({
  type: confettiActions.STOP,
});
