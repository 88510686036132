import { Button } from "@clockwise/design-system";
import React from "react";
import { PlanTypes } from "../types";

type UpgradeButtonProps = {
  isUserOnTrial: boolean;
  onClick: () => void;
  primarySubscriptionPlan: PlanTypes;
};

const UpgradeButton = ({ onClick, isUserOnTrial, primarySubscriptionPlan }: UpgradeButtonProps) => {
  const isTeamsPlan = primarySubscriptionPlan === "Teams";

  return (
    <Button disabled={isTeamsPlan} fullWidth onClick={onClick} sentiment="positive" variant="solid">
      {isUserOnTrial ? "Upgrade now" : isTeamsPlan ? "Current plan" : "Select"}
    </Button>
  );
};

export default UpgradeButton;
