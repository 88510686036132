import { SchedulingAttendanceSummaryTag } from "@clockwise/schema";
import { constant as c, cond, range } from "lodash";
import { DateTime, Duration, Interval } from "luxon";
import { getRelativeDate } from "../components/suggested-times-row/utils/date";
import { getRenderTimeZone } from "./time-zone.util";

export function isSameDayMonthYear(date1: Date, date2: Date) {
  return DateTime.fromJSDate(date1).hasSame(DateTime.fromJSDate(date2), "day");
}

export function getFormattedDateTimeTz(date: DateTime, showGMTOffset = false) {
  const formattedDate = date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY).replace(/,/g, "");
  const formattedTime = date.toLocaleString(DateTime.TIME_SIMPLE).replace(/\s/g, "").toLowerCase();
  const GMTOffsetString = showGMTOffset ? ` (${date.toFormat("'GMT' ZZ").replace(/\s/g, "")})` : "";

  return `${formattedDate} at ${formattedTime}${GMTOffsetString}`;
}

const luxorCustomFormats: { [keyof: string]: Intl.DateTimeFormatOptions } = {
  fullTime: {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  },
  monthDateFullTime: {
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  },
  monthDateYearFullTime: {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  },
};

// Formats a timestamp into a user friendly format (like slack's timestamp on hover)
export const getFormattedZonedTimestamp = (timestamp: number) => {
  const { fullTime, monthDateFullTime, monthDateYearFullTime } = luxorCustomFormats;
  const timeStampDate = DateTime.fromMillis(timestamp);
  const today = DateTime.now();
  const yesterday = DateTime.now().minus({ days: 1 });
  const isTimeStampToday = today.hasSame(timeStampDate, "day");
  if (isTimeStampToday) {
    return `Today at ${timeStampDate.toLocaleString(fullTime)}`;
  }

  const isTimeStampYesterday = yesterday.hasSame(timeStampDate, "day");
  if (isTimeStampYesterday) {
    return `Yesterday at ${timeStampDate.toLocaleString(fullTime)}`;
  }

  const isTimeStampCurrentYear = today.hasSame(timeStampDate, "year");
  if (isTimeStampCurrentYear) {
    return timeStampDate.toLocaleString(monthDateFullTime);
  }

  return timeStampDate.toLocaleString(monthDateYearFullTime);
};

export type CalendarSpecKey =
  | "today"
  | "tomorrow"
  | "next"
  | "this"
  | "yesterday"
  | "other"
  | "last";
export type CalendarSpec = Record<CalendarSpecKey, string>;

function getCalendarRelativeSpecKey(myDateTime: DateTime, now: DateTime): CalendarSpecKey {
  // this function mimics logic from moment-timezone
  // ref: https://github.com/moment/moment/blob/e96809208c9d1b1bbe22d605e76985770024de42/dist/moment.js#L3702

  const nowIsWeekend = [6, 7].includes(now.weekday);
  const diff = myDateTime.startOf("day").diff(now.startOf("day"), "days").as("days");
  // Special condition for nowIsWeekend
  const prefixAsLast = nowIsWeekend
    ? myDateTime <= now.endOf("week") &&
      myDateTime >= now.endOf("week").minus({ weeks: 1, days: 2 })
    : myDateTime < now.startOf("week") && myDateTime >= now.minus({ weeks: 1 }).startOf("week");
  // Special condition for nowIsWeekend
  const prefixAsThis = nowIsWeekend
    ? myDateTime > now && myDateTime <= now.plus({ days: 7 }).endOf("week").minus({ days: 2 })
    : myDateTime < now.endOf("week");
  const prefixAsNext = myDateTime > now && myDateTime < now.endOf("week").plus({ weeks: 1 });

  return cond<void, CalendarSpecKey>([
    [() => diff === 0, c("today")],
    [() => diff === 1, c("tomorrow")],
    [() => diff === -1, c("yesterday")],
    [() => myDateTime < now.minus({ weeks: 1 }).startOf("week"), c("other")],
    [() => prefixAsLast, c("last")],
    [() => prefixAsThis, c("this")],
    [() => prefixAsNext, c("next")], // Important to  have this check after the check for "this"
    [() => true, c("other")],
  ])();
}

export function getRelativeFormat(
  targetDateTime: DateTime,
  formats: CalendarSpec,
  now: DateTime | null = null,
) {
  if (!now) {
    now = DateTime.now().setZone(targetDateTime.zone);
  }
  const format = getCalendarRelativeSpecKey(targetDateTime, now);
  return targetDateTime.toFormat(formats[format]);
}

/**
 * Generates an array of ISO8601 dates for the given range, inclusive.
 *
 * ## Examples
 * ```ts
 * getDateRange("2020-01-01", "2020-01-01") === ["2020-01-01"]
 * getDateRange("2020-01-01", "2020-01-02") === ["2020-01-01", "2020-01-02"]
 * ```
 */
export function getDateRange(startDate: string, endDate: string) {
  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate);
  const completeInterval = Interval.fromDateTimes(start, end.plus({ day: 1 }));
  return completeInterval.splitBy(Duration.fromObject({ days: 1 })).map((i) => i.start.toISODate());
}

/**
 * Convert an object with an ISO8601 `startTime` and `endTime` into a duration in minutes.
 */
export function asMinutes({
  startTime,
  endTime,
  timeSlot,
}: {
  startTime?: string;
  endTime?: string;
  timeSlot?: string;
}) {
  const interval =
    startTime && endTime
      ? Interval.fromISO(`${startTime}/${endTime}`)
      : timeSlot
      ? Interval.fromISO(timeSlot)
      : null;
  if (!interval) {
    throw new Error(
      "You must provide either startTime and endTime, or a timeSlot ISO8601 interval",
    );
  }
  return interval.toDuration().as("minutes");
}

export const getRelevantTagString = (tags: SchedulingAttendanceSummaryTag[]) => {
  if (tags.includes(SchedulingAttendanceSummaryTag.Best)) {
    return " [Best]";
  }
  if (tags.includes(SchedulingAttendanceSummaryTag.Conflict)) {
    return " [Conflict]";
  }
  if (tags.includes(SchedulingAttendanceSummaryTag.Soonest)) {
    return " [Soonest]";
  }
  return "";
};

export const getFormattedEventStartWithTags = (
  start: string,
  tags: SchedulingAttendanceSummaryTag[],
) => {
  const startTime = DateTime.fromISO(start, { zone: getRenderTimeZone() });
  const taggedString = getRelevantTagString(tags);
  const formattedStartDate = startTime
    .toLocaleString({
      day: "numeric",
      month: "short",
      weekday: "short",
    })
    .replace(/,/g, "");

  const formattedStartTime = startTime
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(/\s/g, "")
    .toLowerCase();

  return `${formattedStartDate}, ${formattedStartTime}${taggedString}`;
};

/**
 *
 * Convert an object with an ISO08601 `start` and `end` time into a string
 * representing the event's time of occurence
 */
export const getFormattedEventTime = ({ start, end }: { start: string; end: string }) => {
  const startTime = DateTime.fromISO(start, { zone: getRenderTimeZone() });
  const endTime = DateTime.fromISO(end, { zone: getRenderTimeZone() });
  const interval = Interval.fromDateTimes(startTime, endTime);
  const isSameDate = interval.hasSame("day");
  const isSameYear = interval.hasSame("year");

  const formattedStartDate = startTime
    .toLocaleString({
      day: "numeric",
      month: "short",
      weekday: "short",
      year: isSameYear ? undefined : "numeric",
    })
    .replace(/,/g, "");

  const formattedStartTime = startTime
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(/\s/g, "")
    .toLowerCase();

  const formattedEndDate = endTime
    .toLocaleString({
      day: "numeric",
      month: "short",
      year: isSameYear ? undefined : "numeric",
      weekday: "short",
    })
    .replace(/,/g, "");

  const formattedEndTime = endTime
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(/\s/g, "")
    .toLowerCase();

  return isSameDate
    ? `${formattedStartDate}, ${formattedStartTime} - ${formattedEndTime}`
    : `${formattedStartDate}, ${formattedStartTime} - ${formattedEndDate}, ${formattedEndTime}`;
};

/**
 *
 * Convert an object with an ISO08601 `start` and `end` time into a string
 * representing the event's date of occurance
 */
export type Options = {
  showRelativeDay: boolean;
};

export const getFormattedDateRange = ({
  start,
  end,
  options = { showRelativeDay: true },
}: {
  start: string;
  end: string;
  options?: Options;
}) => {
  const startTime = DateTime.fromISO(start, { zone: getRenderTimeZone() });
  let endTime = DateTime.fromISO(end, { zone: getRenderTimeZone() });
  const interval = Interval.fromDateTimes(startTime, endTime);
  const isSameDate = interval.hasSame("day");
  const isSameMonth = interval.hasSame("month");
  const showTimeRange =
    !startTime.equals(startTime.startOf("day")) || !endTime.equals(endTime.startOf("day"));
  const showRelativeDay = options.showRelativeDay;

  if (!isSameDate && endTime.toISOTime() === endTime.startOf("day").toISOTime()) {
    endTime = endTime.minus({ minute: 1 });
  }

  const within24Hours = endTime.diff(startTime) < Duration.fromObject({ days: 1 });

  const formattedStartTime = startTime
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(/:00/g, "")
    .replace(/\s/g, "")
    .toLowerCase();

  const formattedEndTime = endTime
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(/:00/g, "")
    .replace(/\s/g, "")
    .toLowerCase();

  const formattedStartDate = startTime
    .toLocaleString({
      day: "numeric",
      month: "short",
    })
    .replace(/,/g, "");

  const formattedEndDate = endTime
    .toLocaleString({
      day: "numeric",
      month: isSameMonth ? undefined : "short",
    })
    .replace(/,/g, "");

  if (within24Hours && showTimeRange) {
    if (!showRelativeDay) {
      return `${formattedStartTime} - ${formattedEndTime}`;
    }

    return `${getRelativeDate(startTime)}, ${formattedStartTime} - ${formattedEndTime}`;
  }

  return isSameDate ? formattedStartDate : `${formattedStartDate} - ${formattedEndDate}`;
};

export type RelativeTimeSpec =
  | { type: "fixed"; spec: "same-day" | "next-day" }
  | { type: "relative"; spec: "same" | "next" | "later"; duration: "week" };

/**
 * Creates an Interval based on a relative time specification
 * @param anchorDate The reference DateTime to create the interval from
 * @param spec The relative time specification (fixed or relative with duration)
 * @returns Interval spanning the specified time range
 *
 * @example
 * // Get same day's interval
 * getRelativeInterval(DateTime.now(), { type: 'fixed', spec: 'same-day' })
 *
 * // Get next week's interval
 * getRelativeInterval(DateTime.now(), { type: 'relative', spec: 'next', duration: 'week' })
 */

const toStartOfWorkingDay = (date: DateTime) => date.startOf("day").set({ hour: 9 });
const toEndOfWorkingDay = (date: DateTime) =>
  date.endOf("day").set({ hour: 17, minute: 0, second: 0, millisecond: 0 });

const getWorkingDayInterval = (date: DateTime): Interval =>
  Interval.fromDateTimes(toStartOfWorkingDay(date), toEndOfWorkingDay(date));

export function getRelativeInterval(anchorDate: DateTime, spec: RelativeTimeSpec): Interval[] {
  const now = anchorDate.startOf("day");

  if (spec.type === "fixed") {
    switch (spec.spec) {
      case "same-day":
        return [getWorkingDayInterval(now)];

      case "next-day":
        return [getWorkingDayInterval(now.plus({ days: 1 }))];
    }
  }

  const { duration } = spec;
  switch (spec.spec) {
    case "same": {
      const weekStart = now.startOf(duration);
      return range(0, 5).map((
        days, // Monday to Friday
      ) => getWorkingDayInterval(weekStart.plus({ days })));
    }

    case "next": {
      const nextWeekStart = now.plus({ weeks: 1 }).startOf("week");
      return range(0, 5).map((
        days, // Monday to Friday
      ) => getWorkingDayInterval(nextWeekStart.plus({ days })));
    }

    case "later": {
      const tomorrow = now.plus({ days: 1 });
      const weekEnd = now.endOf("week");
      const daysUntilWeekend = weekEnd.diff(tomorrow, "days").days;
      return range(0, Math.max(0, daysUntilWeekend)).map((days) =>
        getWorkingDayInterval(tomorrow.plus({ days })),
      );
    }
  }
}

/**
 * Attempts to determine the RelativeTimeSpec that would generate the given interval
 * @param interval The interval to analyze
 * @param anchorDate The reference DateTime to compare against
 * @returns RelativeTimeSpec if a match is found, null otherwise
 */
export function getRelativeTimeSpecFromInterval(
  intervals: Interval[],
  anchorDate: DateTime,
): RelativeTimeSpec | null {
  const anchor = anchorDate.startOf("day");

  // Helper to check if two interval arrays are equal
  const areIntervalsEqual = (a: Interval[], b: Interval[]) =>
    a.length === b.length && a.every((interval, i) => interval.equals(b[i]));

  // Check fixed specs first
  const sameDayIntervals = getRelativeInterval(anchor, { type: "fixed", spec: "same-day" });
  if (areIntervalsEqual(intervals, sameDayIntervals)) {
    return { type: "fixed", spec: "same-day" };
  }

  const nextDayIntervals = getRelativeInterval(anchor, { type: "fixed", spec: "next-day" });
  if (areIntervalsEqual(intervals, nextDayIntervals)) {
    return { type: "fixed", spec: "next-day" };
  }

  // Check week-based specs
  const thisWeekIntervals = getRelativeInterval(anchor, {
    type: "relative",
    spec: "same",
    duration: "week",
  });
  if (areIntervalsEqual(intervals, thisWeekIntervals)) {
    return { type: "relative", spec: "same", duration: "week" };
  }

  const nextWeekIntervals = getRelativeInterval(anchor, {
    type: "relative",
    spec: "next",
    duration: "week",
  });
  if (areIntervalsEqual(intervals, nextWeekIntervals)) {
    return { type: "relative", spec: "next", duration: "week" };
  }

  const laterThisWeekIntervals = getRelativeInterval(anchor, {
    type: "relative",
    spec: "later",
    duration: "week",
  });
  if (areIntervalsEqual(intervals, laterThisWeekIntervals)) {
    return { type: "relative", spec: "later", duration: "week" };
  }

  return null;
}
