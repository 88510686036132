/**
 * @generated SignedSource<<c97512da5433bb6aa4bda01ba19aea10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PreferredWorkType = "Afternoon" | "Any" | "Morning" | "%future added value";
export type UrnType = "Date" | "Email" | "Event" | "FlowState" | "Guest" | "Org" | "Phone" | "Services" | "Setting" | "Team" | "Teammate" | "TimeZone" | "User" | "WorkOsOrg" | "WorkingGroup" | "WorkingHours" | "%future added value";
export type UpsertPreferredWorkMutationInput = {
  clientMutationId?: string | null;
  options: GoalPatchPreferredWorkOptionsInput;
  orgId: string;
};
export type GoalPatchPreferredWorkOptionsInput = {
  keepEndOfDayOpen?: boolean | null;
  keepStartOfDayOpen?: boolean | null;
  preferredWorkType?: PreferredWorkType | null;
};
export type UpsertPreferredWorkMutation$variables = {
  input: UpsertPreferredWorkMutationInput;
};
export type UpsertPreferredWorkMutation$data = {
  readonly upsertPreferredWork: {
    readonly org: {
      readonly id: string;
      readonly userGoals: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly goalId: string;
            readonly targetUrn: {
              readonly id: string;
              readonly type: UrnType | null;
              readonly value: string | null;
            };
            readonly otherUrns: ReadonlyArray<{
              readonly id: string;
              readonly type: UrnType | null;
              readonly value: string | null;
            }>;
            readonly name: string;
            readonly isDeleted: boolean;
            readonly options: {
              readonly syncToGoogle: boolean;
              readonly specificOptions: {
                readonly preferredWorkType?: PreferredWorkType;
                readonly keepEndOfDayOpen?: boolean | null;
                readonly keepStartOfDayOpen?: boolean | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type UpsertPreferredWorkMutation = {
  variables: UpsertPreferredWorkMutation$variables;
  response: UpsertPreferredWorkMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000000
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "goalId",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Urn",
  "kind": "LinkedField",
  "name": "targetUrn",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Urn",
  "kind": "LinkedField",
  "name": "otherUrns",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDeleted",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "syncToGoogle",
  "storageKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preferredWorkType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "keepEndOfDayOpen",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "keepStartOfDayOpen",
      "storageKey": null
    }
  ],
  "type": "GoalPreferredWorkOptions",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpsertPreferredWorkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertPreferredWorkMutationPayload",
        "kind": "LinkedField",
        "name": "upsertPreferredWork",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Org",
            "kind": "LinkedField",
            "name": "org",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "GoalConnection",
                "kind": "LinkedField",
                "name": "userGoals",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GoalEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Goal",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GoalOptions",
                            "kind": "LinkedField",
                            "name": "options",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "specificOptions",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "userGoals(first:1000000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpsertPreferredWorkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertPreferredWorkMutationPayload",
        "kind": "LinkedField",
        "name": "upsertPreferredWork",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Org",
            "kind": "LinkedField",
            "name": "org",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "GoalConnection",
                "kind": "LinkedField",
                "name": "userGoals",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GoalEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Goal",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GoalOptions",
                            "kind": "LinkedField",
                            "name": "options",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "specificOptions",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                  },
                                  (v11/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "userGoals(first:1000000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a6ccc79818b2e27037d4d7198606fc75",
    "id": null,
    "metadata": {},
    "name": "UpsertPreferredWorkMutation",
    "operationKind": "mutation",
    "text": "mutation UpsertPreferredWorkMutation(\n  $input: UpsertPreferredWorkMutationInput!\n) {\n  upsertPreferredWork(input: $input) {\n    org {\n      id\n      userGoals(first: 1000000) {\n        edges {\n          node {\n            id\n            goalId\n            targetUrn {\n              id\n              type\n              value\n            }\n            otherUrns {\n              id\n              type\n              value\n            }\n            name\n            isDeleted\n            options {\n              syncToGoogle\n              specificOptions {\n                __typename\n                ... on GoalPreferredWorkOptions {\n                  preferredWorkType\n                  keepEndOfDayOpen\n                  keepStartOfDayOpen\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d1ce6282b05bb840b609bc3078f45cd7";

export default node;
