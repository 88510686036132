// IMPORTS
// schema
import * as ISchema from "#webapp/src/__schema__";

import { modernCache } from "#webapp/src/network/modern-network-layer";
import { jwt, sudoJwt } from "@clockwise/web-commons/src/util/local-storage";
import { commitMutation } from "react-relay";
import { Environment } from "relay-runtime";
import { sudoSwitchUserMutation } from "./SudoSwitchUser.gql";

interface ISudoSwitchUserMutationResponse {
  sudoSwitchUser: ISchema.ISudoSwitchUserMutationPayload;
}

// MUTATION
export function sudoSwitchUser(
  environment: Environment,
  input: ISchema.ISudoSwitchUserMutationInput,
  onSuccess: (response?: ISchema.ISudoSwitchUserMutationPayload) => void,
  onError: (error?: Error) => void,
) {
  // Get the impersonator's JWT before attempting hte mutation.
  // If successful, `jwt` will be replaced with the impersonatee's JWT.
  const originalJwt = jwt.get();

  commitMutation(environment, {
    onError,
    variables: { input },
    mutation: sudoSwitchUserMutation,
    onCompleted: (response: ISudoSwitchUserMutationResponse, errors?: Error[]) => {
      if (errors) {
        console.error("Sudo errors", errors);
        onError(errors[0]);
      } else {
        // if we're not in a modern environment, modernCache will be undefined
        if (modernCache) {
          modernCache.clear();
        }
        // Stash the impersonator's original JWT to be restored later
        originalJwt ? sudoJwt.set(originalJwt) : sudoJwt.remove();
        onSuccess(response.sudoSwitchUser);
      }
    },
  });
}
