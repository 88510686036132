import { logger, LoggerTransport, LogLevel } from "@clockwise/client-commons/src/util/logger";
import * as Sentry from "@sentry/browser";
import { SeverityLevel } from "@sentry/browser";

const sentrySeverityMap: Record<LogLevel, SeverityLevel> = {
  info: "info",
  warn: "warning",
  debug: "debug",
  error: "error",
};

export class SentryTransport extends LoggerTransport {
  public tags: Record<string, string> = {};

  /**
   * Adds a tag key/value that will be attributed to the next error log.
   *
   * @param key
   * @param value
   */
  public addTag(key: string, value: string) {
    this.tags[key] = value;
  }

  public log(message: string, level: LogLevel, ...metas: any[]) {
    const errors: Error[] = [];
    const cleanMeta: { [key: string]: string } = {};

    metas.forEach((meta: any) => {
      if (meta instanceof Error) {
        errors.push(meta);
      } else if (typeof meta === "object") {
        try {
          Object.getOwnPropertyNames(meta).forEach((key) => {
            let value = meta[key];
            if (typeof value !== "string") {
              value = JSON.stringify(meta[key], undefined, "  ");
            }
            cleanMeta[key] = value;
          });
        } catch (err) {
          logger.error("Failed to clean meta", err);
        }
      }
    });

    switch (level) {
      case "error":
        const stack = this.getStack();

        errors.forEach((error) => {
          Sentry.withScope((scope) => {
            Object.getOwnPropertyNames(cleanMeta).forEach((key) =>
              scope.setExtra(key, cleanMeta[key]),
            );

            const newError = new Error(message);
            newError.name = error.name;
            newError.stack = (error.stack || "")
              .split("\n")
              .slice(0, 1)
              .concat(stack.split("\n"), (error.stack || "").split("\n").slice(1))
              .join("\n");

            Object.keys(this.tags).forEach((key) => scope.setTag(key, this.tags[key]));

            scope.setExtra("originalMessage", error.message);

            Sentry.captureException(newError);
          });
        });

        if (!errors.length) {
          Sentry.withScope((scope) => {
            Object.getOwnPropertyNames(cleanMeta).forEach((key) =>
              scope.setExtra(key, cleanMeta[key]),
            );

            Object.keys(this.tags).forEach((key) => scope.setTag(key, this.tags[key]));

            Sentry.captureMessage(message);
          });
        }

        this.tags = {};
        break;
      case "debug":
      case "warn":
      default:
        Sentry.addBreadcrumb({
          message,
          data: cleanMeta,
          level: sentrySeverityMap[level],
        });
    }
  }
}
