import { getCurrentOrg } from "#webapp/src/util/org.util";
import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { Day, MaxSlot, MinSlot } from "@clockwise/schema";
import { useMemo } from "react";
import { PlannerMetadataQueryDocument } from "../apollo/__generated__/Planner.generated";
import { parseCalendarIdsGQL, parseOrgAndTeamCalendarIdsGQL } from "../util/parseCalendarIdsGQL";
import { parseColorSettingsGQL } from "../util/parseColorSettingsGQL";
import { parseWorkingHourBoundsGQL } from "../util/parseWorkingHourBoundsGQL";

export type WorkingHours =
  | {
      __typename: "WorkingHours";
      id: string;
      daySettings:
        | ({
            __typename: "DaySetting";
            day: Day | null;
            setting: {
              __typename: "WorkingSetting";
              minSlot: MinSlot | null;
              maxSlot: MaxSlot | null;
            } | null;
          } | null)[]
        | null;
    }
  | null
  | undefined;

const usePlannerMetaData = () => {
  const { data, loading, error } = useQuery(PlannerMetadataQueryDocument);

  const viewer = getValue(data?.viewer, "Viewer");
  const org = getCurrentOrg(viewer);

  const userCalendarIds = useMemo(() => parseCalendarIdsGQL(org), [org]);
  const { primaryCalendarId, teamCalendarIds } = useMemo(() => parseOrgAndTeamCalendarIdsGQL(org), [
    org,
  ]);

  const colorSettings = parseColorSettingsGQL(org);
  const workingHourBounds = parseWorkingHourBoundsGQL(org);
  const workingHours: WorkingHours = getValue(org?.userWorkingHours)?.workingHours;

  return {
    userCalendarIds,
    primaryCalendarId,
    teamCalendarIds,
    colorSettings,
    workingHourBounds,
    workingHours,
    loading,
    error,
  };
};

export default usePlannerMetaData;
