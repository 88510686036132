import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import {
  ConferencingType,
  EventCategoryType,
  FlexDetailsInput,
  FlexRange,
  ProposalState,
  ResponseStatusEnum,
} from "@clockwise/schema/v2";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime, Interval } from "luxon";
import React, { ReactNode, createContext, useCallback, useContext, useReducer } from "react";
import invariant from "tiny-invariant";
import { EventVisibility } from "../event-card/atoms/SelectEventVisibility";
import { Transparency } from "../event-card/atoms/SelectTransparency";
import { TradeoffBlock } from "../reschedule-confirmation-modal/RescheduleConfirmationModal";
import { PersistedProposalProvider } from "./PersistedProposalContext";
import { getNewTitle } from "./util/getNewTitle";
import { toCurrentProposalAttendee } from "./util/toCurrentProposalAttendee";

export type Person = {
  email: string;
  displayName: string;
  givenName: string | null;
  familyName: string | null;
  externalImageUrl: string | null;
  isMe: boolean;
  id: string;
};

export type Attendee = {
  id: string;
  isOptional: boolean | null;
  isOrganizer: boolean | null;
  responseStatus: ResponseStatusEnum | null;
  person: Person;
  user: {
    id: string;
  } | null;
};

export type FetchedEventAttendee = {
  id: string;
  email: string;
  responseStatus: ResponseStatusEnum | null;
  isOrganizer: boolean | null;
  isOptional: boolean | null;
  person: {
    id: string;
    displayName: string;
    givenName: string | null;
    familyName: string | null;
    externalImageUrl: string | null;
    email: string;
    isMe: boolean;
  };
  member: {
    user: {
      id: string;
    } | null;
  } | null;
};

export type EventCategory = {
  type: EventCategoryType;
  color?: string | null;
};

export type EventPermissions = {
  canRSVP: boolean;
  canSeeOtherGuests: boolean;
  canInviteOthers: boolean;
  canModify: boolean;
  canModifyAll: boolean;
  canModifyFuture: boolean;
  canDelete: boolean;
  canRemove: boolean;
};

export interface EventForProposal {
  calendarId: string;
  externalEventId: string;
  title: string;
  description?: string | null;
  dateOrTimeRange?: string | null;
  isAllDay: boolean;
  attendees: Array<FetchedEventAttendee>;
  areAttendeesOmitted: boolean;
  recurrenceRule?: string | null;
  conferencing?: {
    type: ConferencingType;
  } | null;
  location?: string | null;
  flexibility?: FlexDetailsInput | null;
  category: EventCategory;
  responseStatus: ResponseStatusEnum;
  permissions: EventPermissions;
  transparency: Transparency;
  visibility: EventVisibility;
  calendar: {
    id: string;
    displayName: string;
  };
  meta?: {
    isFindATime?: boolean;
  };
}

export type CurrentProposal = {
  proposalId: string | null;
  title: string;
  startTime: DateTime;
  endTime: DateTime;
  timeZone: string;
  allDay: boolean;
  initialAttendeeIds: string[];
  attendees: Attendee[];
  removedAttendees: Record<string, Attendee>;
  upsertedAttendees: Record<string, Attendee>;
  areAttendeesOmitted: boolean;
  description: string;
  recurrenceRule: RecurrenceRule | null;
  conferenceType: ConferencingType | null;
  location: string | null;
  initialized: boolean;
  flexDetails: FlexDetailsInput;
  meta: {
    isDragging: boolean;
    /**
     * Stores info on what the state of the proposal was before we toggle on
     * the All Day flag, so we can restore that if All Day is toggled back off.
     */
    stateBeforeAllDay?: {
      startTime: DateTime;
      endTime: DateTime;
    };
    /**
     * Set to true when we are in the Find a Time tab
     */
    isFindATime: boolean;
  };
  eventId?: string;
  eventCalendarId?: string;
  calendar?: {
    displayName: string;
    calendarId: string;
  };
  eventTimeInterval?: Interval;
  responseStatus?: ResponseStatusEnum;
  eventCategory?: EventCategory;
  permissions?: EventPermissions;
  transparency: Transparency;
  visibility: EventVisibility;
  searchTimeRanges?: Interval[];
};

type CurrentProposalReadObj = {
  currentProposal: CurrentProposal;
};

type CurrentProposalWriteObj = {
  initializeFromEvent: <E extends EventForProposal>(event: E) => void;
  initializeProposal: (proposal: Omit<CurrentProposal, "initialized">) => void;
  initOrRescheduleProposal: (
    proposal: Omit<
      CurrentProposal,
      | "initialized"
      | "removedAttendees"
      | "upsertedAttendees"
      | "areAttendeesOmitted"
      | "searchTimeRanges"
    >,
  ) => void;
  updateTitle: (title: string) => void;
  updateTime: (startTime: DateTime, endTime: DateTime) => void;
  updateAllDay: (allDay: boolean) => void;
  updateDescription: (description: string) => void;
  setAttendeeCalendarIds: (attendeeCalendarIds: string[]) => void;
  setAttendees: (attendees: Attendee[]) => void;
  addAttendee: (attendee: Attendee) => void;
  removeAttendee: (email: string) => void;
  updateAttendeeOptionality: (email: string, isOptional: boolean) => void;
  updateRecurrenceRule: (recurrenceRule: RecurrenceRule) => void;
  updateConferenceType: (conferenceType: ConferencingType) => void;
  updateLocation: (location: string) => void;
  clearProposal: () => void;
  updateFlexDetails: (flexDetails: FlexDetailsInput) => void;
  updateProposalMeta: (meta: Partial<CurrentProposal["meta"]>) => void;
  updateResponseStatus: (responseStatus: ResponseStatusEnum) => void;
  updateEventCategory: (eventCategory: EventCategory) => void;
  selectExistingEvent: (
    eventId: string,
    calendarId: string,
    meta?: { isFindATime?: boolean },
  ) => void;
  updateCalendar: (calendar?: { displayName: string; calendarId: string }) => void;
  updateSearchTimeRanges: (searchTimeRanges: Interval[]) => void;
};

type InitProposalFromEventAction<E extends EventForProposal> = {
  type: "InitProposalFromEvent";
  event: E;
};

type InitProposalAction = {
  type: "InitProposal";
  proposal: Omit<CurrentProposal, "initialized">;
};

type InitOrRescheduleProposalAction = {
  type: "InitOrRescheduleProposal";
  proposal: Omit<
    CurrentProposal,
    "initialized" | "removedAttendees" | "upsertedAttendees" | "areAttendeesOmitted"
  >;
};

type ClearProposalAction = {
  type: "ClearProposal";
};

type UpdateTitleAction = {
  type: "UpdateTitle";
  title: string;
};

type UpdateTimeAction = {
  type: "UpdateTime";
  startTime: DateTime;
  endTime: DateTime;
};

type UpdateAllDayAction = {
  type: "UpdateAllDay";
  allDay: boolean;
};

type UpdateDescriptionAction = {
  description: string;
  type: "UpdateDescription";
};

type SetAttendeeCalendarIdsAction = {
  type: "SetAttendeeCalendarIds";
  initialAttendeeIds: string[];
};

type SetAttendeesAction = {
  type: "SetAttendees";
  attendees: Attendee[];
};

type AddAttendeeAction = {
  type: "AddAttendee";
  attendee: Attendee;
};

type RemoveAttendeeAction = {
  type: "RemoveAttendee";
  email: string;
};

type UpdateAttendeeOptionalityAction = {
  type: "UpdateAttendeeOptionality";
  email: string;
  isOptional: boolean;
};

type UpdateRecurrenceRuleAction = {
  type: "UpdateRecurrenceRule";
  recurrenceRule: RecurrenceRule;
};

type UpdateConferenceTypeAction = {
  type: "UpdateConferenceType";
  conferenceType: ConferencingType | null;
};

type UpdateLocationAction = {
  type: "UpdateLocation";
  location: string;
};

type UpdateFlexDetailsAction = {
  type: "UpdateFlexDetails";
  flexDetails: FlexDetailsInput;
};

type UpdatePersistedProposalAction = {
  type: "UpdatePersistedProposal";
  proposalId: string;
  proposalStatus: ProposalState;
  tradeoffBlocks?: TradeoffBlock[];
};

type UpdateProposalMetaAction = {
  type: "UpdateProposalMeta";
  meta: Partial<CurrentProposal["meta"]>;
};

type UpdateResponseStatusAction = {
  type: "UpdateResponseStatus";
  responseStatus: ResponseStatusEnum;
};

type UpdateEventCategoryAction = {
  type: "UpdateEventCategory";
  eventCategory: EventCategory;
};

type SelectExistingEventAction = {
  type: "SelectExistingEvent";
  eventId: string;
  calendarId: string;
  meta?: {
    isFindATime?: boolean;
  };
};

type UpdateCalendarAction = {
  type: "UpdateCalendar";
  calendar?: {
    displayName: string;
    calendarId: string;
  };
};

type UpdateSearchTimeRangesAction = {
  type: "UpdateSearchTimeRanges";
  searchTimeRanges: Interval[];
};

type ProposalAction<E extends EventForProposal> =
  | InitProposalFromEventAction<E>
  | InitProposalAction
  | InitOrRescheduleProposalAction
  | ClearProposalAction
  | UpdateTitleAction
  | UpdateTimeAction
  | UpdateAllDayAction
  | UpdateDescriptionAction
  | SetAttendeeCalendarIdsAction
  | SetAttendeesAction
  | AddAttendeeAction
  | RemoveAttendeeAction
  | UpdateAttendeeOptionalityAction
  | UpdateRecurrenceRuleAction
  | UpdateConferenceTypeAction
  | UpdateLocationAction
  | UpdateFlexDetailsAction
  | UpdatePersistedProposalAction
  | UpdateProposalMetaAction
  | UpdateResponseStatusAction
  | UpdateEventCategoryAction
  | SelectExistingEventAction
  | UpdateCalendarAction
  | UpdateSearchTimeRangesAction;

function proposalReducer<E extends EventForProposal>(
  state: CurrentProposal,
  action: ProposalAction<E>,
) {
  switch (action.type) {
    case "InitProposalFromEvent": {
      const {
        title,
        description,
        dateOrTimeRange,
        isAllDay,
        attendees,
        recurrenceRule,
        conferencing,
        location,
        flexibility,
        externalEventId,
        calendarId,
        category,
        responseStatus,
        permissions,
        transparency,
        visibility,
        areAttendeesOmitted,
        calendar,
        meta,
      } = action.event;

      if (!dateOrTimeRange) {
        return state;
      }

      const zone = getRenderTimeZone();
      const timeInterval = Interval.fromISO(dateOrTimeRange, { zone });

      return {
        proposalId: null,
        title,
        startTime: timeInterval?.start,
        endTime: timeInterval?.end,
        allDay: isAllDay,
        description: description ?? "",
        initialAttendeeIds: attendees.map((attendee) => attendee.person.email),
        conferenceType: conferencing?.type ?? null,
        location: location ?? null,
        flexDetails: flexibility
          ? {
              isFlexible: flexibility.isFlexible,
              flexRange: flexibility.flexRange,
              allowedDays: flexibility.allowedDays,
              timeOfDayRange: flexibility.timeOfDayRange,
            }
          : { isFlexible: true, flexRange: FlexRange.Day },
        removedAttendees: {},
        upsertedAttendees: {},
        areAttendeesOmitted,
        attendees: attendees.map((attendee) => toCurrentProposalAttendee(attendee)),
        recurrenceRule: recurrenceRule ? new RecurrenceRule(recurrenceRule) : null,
        eventId: externalEventId,
        eventCalendarId: calendarId,
        calendar: {
          displayName: calendar.displayName ?? calendarId,
          calendarId,
        },
        eventTimeInterval: timeInterval,
        eventCategory: category,
        responseStatus: responseStatus,
        timeZone: getRenderTimeZone(),
        permissions,
        meta: {
          isDragging: false,
          isFindATime: meta?.isFindATime ?? false,
        },
        initialized: true,
        transparency: transparency,
        visibility: visibility,
      } as CurrentProposal;
    }
    case "InitProposal": {
      return { ...action.proposal, initialized: true };
    }
    case "InitOrRescheduleProposal": {
      if (state.initialized) {
        const duration = state.endTime.diff(state.startTime);
        return {
          ...state,
          startTime: action.proposal.startTime,
          endTime: action.proposal.startTime.plus(duration),
        };
      } else {
        return {
          ...action.proposal,
          areAttendeesOmitted: false,
          removedAttendees: {},
          upsertedAttendees: {},
          initialized: true,
        };
      }
    }
    case "ClearProposal":
      return { ...INITIAL_PROPOSAL };
    case "UpdateTitle":
      return {
        ...state,
        title: action.title,
      };
    case "UpdateTime": {
      const zone = getRenderTimeZone();
      return {
        ...state,
        startTime: action.startTime.setZone(zone),
        endTime: action.endTime.setZone(zone),
      };
    }
    case "UpdateAllDay": {
      if (action.allDay) {
        return {
          ...state,
          allDay: true,
          startTime: state.startTime.startOf("day"),
          endTime: state.startTime.plus({ days: 1 }).startOf("day"),
          meta: {
            ...state.meta,
            stateBeforeAllDay: {
              startTime: state.startTime,
              endTime: state.endTime,
            },
          },
        };
      } else {
        // If we have info about what the state was before the user toggled on the All Day
        // option, reset it to the *time* it was before. If the user has changed the day
        // after toggling on All Day, we want to honor that. Otherwise, we'll make our
        // best guess based on the current day/time.
        const startTime = state.meta.stateBeforeAllDay
          ? state.meta.stateBeforeAllDay.startTime.set({ day: state.startTime.day })
          : state.startTime.set({ hour: DateTime.local().hour });
        const endTime = state.meta.stateBeforeAllDay
          ? state.meta.stateBeforeAllDay.endTime.set({ day: state.startTime.day })
          : startTime.plus({ minutes: 30 });
        return {
          ...state,
          allDay: false,
          startTime,
          endTime,
        };
      }
    }
    case "UpdateDescription":
      return {
        ...state,
        description: action.description,
      };
    case "SetAttendeeCalendarIds":
      return {
        ...state,
        initialAttendeeIds: action.initialAttendeeIds, // Changed from attendeeCalendarIds
      };
    case "SetAttendees": {
      return {
        ...state,
        attendees: action.attendees,
        title: getNewTitle(state.title, state.attendees, action.attendees),
        removedAttendees: {},
        upsertedAttendees: {},
      };
    }
    case "AddAttendee": {
      const newRemovedAttendees = { ...state.removedAttendees };
      delete newRemovedAttendees[action.attendee.person.email];

      const newAttendeeSet = [...state.attendees, action.attendee];

      return {
        ...state,
        attendees: newAttendeeSet,
        title: getNewTitle(state.title, state.attendees, newAttendeeSet),
        removedAttendees: newRemovedAttendees,
        upsertedAttendees: {
          ...state.upsertedAttendees,
          [action.attendee.person.email]: action.attendee,
        },
      };
    }
    case "RemoveAttendee": {
      const removedAttendee = state.attendees.find(
        (attendee) => attendee.person.email === action.email,
      );

      const newUpsertedAttendees = { ...state.upsertedAttendees };
      delete newUpsertedAttendees[action.email];

      const newAttendeeSet = state.attendees.filter(
        (attendee) => attendee.person.email !== action.email,
      );

      return {
        ...state,
        attendees: newAttendeeSet,
        title: getNewTitle(state.title, state.attendees, newAttendeeSet),
        removedAttendees: removedAttendee
          ? {
              ...state.removedAttendees,
              [action.email]: removedAttendee,
            }
          : state.removedAttendees,
        upsertedAttendees: newUpsertedAttendees,
      };
    }
    case "UpdateAttendeeOptionality": {
      const updatedAttendees = state.attendees.map((attendee) =>
        attendee.person.email === action.email
          ? { ...attendee, isOptional: action.isOptional }
          : attendee,
      );
      const updatedAttendee = updatedAttendees.find(
        (attendee) => attendee.person.email === action.email,
      );

      return updatedAttendee
        ? {
            ...state,
            attendees: updatedAttendees,
            upsertedAttendees: {
              ...state.upsertedAttendees,
              [action.email]: updatedAttendee,
            },
          }
        : {
            ...state,
            attendees: updatedAttendees,
          };
    }
    case "UpdateRecurrenceRule":
      return {
        ...state,
        recurrenceRule: action.recurrenceRule,
      };
    case "UpdateConferenceType":
      return {
        ...state,
        conferenceType: action.conferenceType,
      };

    case "UpdateLocation":
      return {
        ...state,
        location: action.location,
      };

    case "UpdateFlexDetails":
      return {
        ...state,
        flexDetails: action.flexDetails,
      };
    case "UpdatePersistedProposal":
      return {
        ...state,
        proposalId: action.proposalId,
        proposalStatus: action.proposalStatus,
        tradeoffBlocks: action.tradeoffBlocks,
      };
    case "UpdateProposalMeta":
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.meta,
        },
      };
    case "UpdateResponseStatus":
      return {
        ...state,
        responseStatus: action.responseStatus,
      };
    case "UpdateEventCategory":
      return {
        ...state,
        eventCategory: action.eventCategory,
      };
    case "SelectExistingEvent":
      return {
        ...INITIAL_PROPOSAL,
        eventId: action.eventId,
        eventCalendarId: action.calendarId,
        meta: {
          ...state.meta,
          isFindATime: action.meta?.isFindATime ?? false,
        },
      };
    case "UpdateCalendar":
      return {
        ...state,
        calendar: action.calendar,
      };
    case "UpdateSearchTimeRanges":
      return {
        ...state,
        searchTimeRanges: action.searchTimeRanges,
      };
  }
}

export const NEW_EVENT_PERMISSIONS: EventPermissions = {
  canRSVP: true,
  canSeeOtherGuests: true,
  canInviteOthers: true,
  canModify: true,
  canModifyAll: false,
  canModifyFuture: false,
  canDelete: false,
  canRemove: false,
};

export const DEFAULT_PERMISSIONS: EventPermissions = {
  canRSVP: false,
  canSeeOtherGuests: false,
  canInviteOthers: false,
  canModify: false,
  canModifyAll: false,
  canModifyFuture: false,
  canDelete: false,
  canRemove: false,
};

const INITIAL_PROPOSAL: CurrentProposal = {
  proposalId: null,
  title: "",
  startTime: DateTime.local(),
  endTime: DateTime.local().plus({ hours: 1 }),
  timeZone: "America/Los_Angeles",
  allDay: false,
  description: "",
  initialAttendeeIds: [],
  attendees: [],
  removedAttendees: {},
  upsertedAttendees: {},
  areAttendeesOmitted: false,
  recurrenceRule: null,
  conferenceType: null,
  location: null,
  initialized: false,
  flexDetails: {
    isFlexible: true,
    flexRange: FlexRange.Day,
  },
  meta: {
    isDragging: false, // Set if we are manipulating the proposal with clickDrag
    isFindATime: false, // Set to init to Find a Time tab
  },
  eventId: undefined,
  eventCalendarId: undefined,
  calendar: undefined,
  eventTimeInterval: undefined,
  responseStatus: undefined,
  eventCategory: undefined,
  transparency: "opaque",
  visibility: "default",
  searchTimeRanges: undefined,
};

const ReadContext = createContext<CurrentProposalReadObj>({
  currentProposal: INITIAL_PROPOSAL,
});
const WriteContext = createContext<CurrentProposalWriteObj | null>(null);

export const CurrentProposalProvider = ({ children }: { children: ReactNode }) => {
  const [proposal, dispatch] = useReducer(proposalReducer, INITIAL_PROPOSAL);

  const handleInitializeFromEvent = <E extends EventForProposal>(event: E) => {
    dispatch({ type: "InitProposalFromEvent", event });
  };

  const handleInitializeProposal = (proposal: Omit<CurrentProposal, "initialized">) => {
    dispatch({ type: "InitProposal", proposal });
  };

  const handleInitOrRescheduleProposal = (
    proposal: Omit<CurrentProposal, "initialized" | "removedAttendees" | "upsertedAttendees">,
  ) => {
    dispatch({ type: "InitOrRescheduleProposal", proposal });
  };

  const handleUpdateTitle = useCallback(
    (title: string) => {
      dispatch({ type: "UpdateTitle", title });
    },
    [dispatch],
  );

  const handleUpdateTime = (startTime: DateTime, endTime: DateTime) => {
    dispatch({ type: "UpdateTime", startTime, endTime });
  };

  const handleUpdateAllDay = (allDay: boolean) => {
    dispatch({ type: "UpdateAllDay", allDay });
  };

  const handleClearProposal = () => {
    dispatch({ type: "ClearProposal" });
  };

  const handleUpdateDescription = useCallback(
    (description: string) => {
      dispatch({ type: "UpdateDescription", description });
    },
    [dispatch],
  );

  const handleSetAttendeeCalendarIds = (initialAttendeeIds: string[]) => {
    dispatch({ type: "SetAttendeeCalendarIds", initialAttendeeIds });
  };

  const handleSetAttendees = (attendees: Attendee[]) => {
    dispatch({ type: "SetAttendees", attendees });
  };

  const handleAddAttendee = (attendee: Attendee) => {
    dispatch({ type: "AddAttendee", attendee });
  };

  const handleRemoveAttendee = (email: string) => {
    dispatch({ type: "RemoveAttendee", email });
  };

  const handleUpdateAttendeeOptionality = (email: string, isOptional: boolean) => {
    dispatch({ type: "UpdateAttendeeOptionality", email, isOptional });
  };

  const handleUpdateRecurrenceRule = (recurrenceRule: RecurrenceRule) => {
    dispatch({ type: "UpdateRecurrenceRule", recurrenceRule });
  };

  const handleUpdateConferenceType = (conferenceType: ConferencingType | null) => {
    dispatch({ type: "UpdateConferenceType", conferenceType });
  };

  const handleUpdateLocation = useCallback(
    (location: string) => {
      dispatch({ type: "UpdateLocation", location });
    },
    [dispatch],
  );

  const handleUpdateFlexDetails = (flexDetails: FlexDetailsInput) => {
    dispatch({ type: "UpdateFlexDetails", flexDetails });
  };

  const handleUpdateProposalMeta = (meta: Partial<CurrentProposal["meta"]>) => {
    dispatch({ type: "UpdateProposalMeta", meta });
  };

  const handleUpdateResponseStatus = (responseStatus: ResponseStatusEnum) => {
    dispatch({ type: "UpdateResponseStatus", responseStatus });
  };

  const handleUpdateEventCategory = (eventCategory: EventCategory) => {
    dispatch({ type: "UpdateEventCategory", eventCategory });
  };

  const handleSelectExistingEvent = (
    eventId: string,
    calendarId: string,
    meta?: { isFindATime?: boolean },
  ) => {
    dispatch({ type: "SelectExistingEvent", eventId, calendarId, meta });
  };

  const handleUpdateCalendar = (calendar?: { displayName: string; calendarId: string }) => {
    dispatch({ type: "UpdateCalendar", calendar });
  };

  const handleUpdateSearchTimeRanges = (searchTimeRanges: Interval[]) => {
    dispatch({ type: "UpdateSearchTimeRanges", searchTimeRanges });
  };

  return (
    <WriteContext.Provider
      value={{
        initializeFromEvent: handleInitializeFromEvent,
        initializeProposal: handleInitializeProposal,
        initOrRescheduleProposal: handleInitOrRescheduleProposal,
        updateTitle: handleUpdateTitle,
        clearProposal: handleClearProposal,
        updateTime: handleUpdateTime,
        updateAllDay: handleUpdateAllDay,
        updateDescription: handleUpdateDescription,
        setAttendeeCalendarIds: handleSetAttendeeCalendarIds,
        setAttendees: handleSetAttendees,
        addAttendee: handleAddAttendee,
        removeAttendee: handleRemoveAttendee,
        updateAttendeeOptionality: handleUpdateAttendeeOptionality,
        updateRecurrenceRule: handleUpdateRecurrenceRule,
        updateConferenceType: handleUpdateConferenceType,
        updateLocation: handleUpdateLocation,
        updateFlexDetails: handleUpdateFlexDetails,
        updateProposalMeta: handleUpdateProposalMeta,
        updateResponseStatus: handleUpdateResponseStatus,
        updateEventCategory: handleUpdateEventCategory,
        selectExistingEvent: handleSelectExistingEvent,
        updateCalendar: handleUpdateCalendar,
        updateSearchTimeRanges: handleUpdateSearchTimeRanges,
      }}
    >
      <ReadContext.Provider value={{ currentProposal: proposal }}>
        <PersistedProposalProvider {...proposal}>{children}</PersistedProposalProvider>
      </ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const useCurrentProposal = () => useContext(ReadContext);
export const useUpdateCurrentProposal = () => {
  const context = useContext(WriteContext);
  invariant(context, "useUpdateCurrentProposal must be within CurrentProposalProvider");
  return context;
};
