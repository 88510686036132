// schema

import { EScheduleRange } from "#webapp/src/util/scheduler.util";

export enum FeedSchedulerActions {
  UpdateScheduledEventIds = "UPDATE_SCHEDULED_EVENT_IDS",
  ResetScheduledEventIds = "RESET_SCHEDULED_EVENT_IDS",
  UpdateFeedSchedulerInput = "UPDATE_FEED_SCHEDULER_INPUT",
  UpdateCalendarIds = "UPDATE_CALENDAR_IDS",
  UpdateRoomRequired = "UPDATE_ROOM_REQUIRED",
  UpdateSchedulingSessionId = "UPDATE_SCHEDULING_SESSION_ID",
  UpdateAutoSchedule = "UPDATE_AUTO_SCHEDULE",
  UpdateDuration = "UPDATE_FEED_SCHEDULER_DURATION",
  UpdateRange = "UPDATE_FEED_SCHEDULER_RANGE",
}

export const updateScheduledEventIds = (
  scheduledExternalEventId: string,
  scheduledCalendarId: string,
) => ({
  type: FeedSchedulerActions.UpdateScheduledEventIds,
  data: {
    scheduledExternalEventId,
    scheduledCalendarId,
  },
});

export const resetScheduledEventIds = () => ({
  type: FeedSchedulerActions.ResetScheduledEventIds,
  data: {},
});

export const updateFeedSchedulerInput = (
  calendarIds: string[],
  roomRequired: boolean,
  useAutoSchedule: boolean,
  minutesDuration: number,
  range: EScheduleRange,
  specifiedDate: Date | null,
) => ({
  type: FeedSchedulerActions.UpdateFeedSchedulerInput,
  data: {
    calendarIds,
    roomRequired,
    useAutoSchedule,
    minutesDuration,
    range,
    specifiedDate,
  },
});

export const updateCalendarIds = (calendarIds: string[]) => ({
  type: FeedSchedulerActions.UpdateCalendarIds,
  data: {
    calendarIds,
  },
});

export const updateRoomRequired = (roomRequired: boolean) => ({
  type: FeedSchedulerActions.UpdateRoomRequired,
  data: {
    roomRequired,
  },
});

export const updateSchedulingSessionId = (schedulingSessionId: string) => ({
  type: FeedSchedulerActions.UpdateSchedulingSessionId,
  data: {
    schedulingSessionId,
  },
});

export const updateUseAutoSchedule = (useAutoSchedule: boolean) => ({
  type: FeedSchedulerActions.UpdateAutoSchedule,
  data: {
    useAutoSchedule,
  },
});

export const updateDuration = (minutesDuration: number) => ({
  type: FeedSchedulerActions.UpdateDuration,
  data: {
    minutesDuration,
  },
});

export const updateRange = (range: EScheduleRange, specifiedDate: Date | null) => ({
  type: FeedSchedulerActions.UpdateRange,
  data: {
    range,
    specifiedDate,
  },
});
