import { CalendarColorSet, getADefaultCalendarColor } from "./calendar-coloring";

export type CalendarId = string;
export type CalendarColorsState = Partial<Record<CalendarId, CalendarColorSet>>;

export type CalendarColorsAction =
  | { type: "add-calendar"; payload: CalendarId }
  | { type: "add-calendars"; payload: CalendarId[] }
  | { type: "set-calendarColors"; payload: CalendarColorsState }
  | { type: "remove-calendar"; payload: CalendarId }
  | { type: "remove-calendars"; payload: CalendarId[] }
  | { type: "reset" };

export const calendarColorsReducer = (state: CalendarColorsState, action: CalendarColorsAction) => {
  switch (action.type) {
    case "add-calendar": {
      if (state[action.payload]) {
        return state;
      }

      return {
        ...state,
        [action.payload]: getADefaultCalendarColor(state),
      };
    }
    case "add-calendars": {
      const newState: CalendarColorsState = { ...state };
      action.payload.forEach((calendarId) => {
        if (newState[calendarId]) {
          return;
        }
        newState[calendarId] = getADefaultCalendarColor(newState);
      });
      return newState;
    }
    case "set-calendarColors": {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    case "remove-calendar": {
      const { [action.payload]: _, ...newState } = state;
      return newState;
    }
    case "remove-calendars": {
      const newState = { ...state };
      action.payload.forEach((calendarId) => {
        delete newState[calendarId];
      });
      return newState;
    }
    case "reset":
      return {};
    default:
      return state;
  }
};
