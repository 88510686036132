export enum AuthActions {
  UpdateAuthed = "Auth_UpdateAuthed",
  SetDeactivated = "Auth_SetDeactivated",
  SetUninstalled = "Auth_SetUninstalled",
  SetExperiments = "Auth_SetExperiments",
}

export const updateAuthed = (authed: boolean, userCreatedTime: number | null) => ({
  authed,
  userCreatedTime,
  type: AuthActions.UpdateAuthed,
});

export const setDeactivated = (deactivated: boolean) => ({
  type: AuthActions.SetDeactivated,
  deactivated,
});

export const setUninstalled = (uninstalled: boolean) => ({
  type: AuthActions.SetUninstalled,
  uninstalled,
});

export const setExperiments = (experiments: string[]) => ({
  type: AuthActions.SetExperiments,
  experiments,
});
