import { DayViewActions } from "#webapp/src/state/actions/day-view.actions";
import { ZonedMoment } from "@clockwise/client-commons/src/util/ZonedMoment";

interface IDayViewAction {
  type: DayViewActions;
  data: {
    scrollOffset?: number;
    selectedMoment?: ZonedMoment;
    lastOffline?: number;
    scrollIsLatched?: boolean;
    snapToSelectedMomentOnNextMount?: boolean;
  };
}

export interface IDayViewState {
  scrollOffset: number;
  selectedMoment: ZonedMoment;
  lastOffline: number;
  scrollIsLatched: boolean;
  snapToSelectedMomentOnNextMount: boolean;
}

const initialSelectedMoment = new ZonedMoment();

export const initialDayViewState: IDayViewState = {
  selectedMoment: initialSelectedMoment,
  lastOffline: 0,
  scrollOffset: 0,
  scrollIsLatched: true,
  snapToSelectedMomentOnNextMount: false,
};

export function dayViewReducer(state: IDayViewState = initialDayViewState, action: IDayViewAction) {
  let selectedMoment = state.selectedMoment;

  if (action.data && action.data.selectedMoment) {
    selectedMoment = action.data.selectedMoment;
  }

  switch (action.type) {
    case DayViewActions.SetSelectedMoment:
      return {
        ...state,
        selectedMoment,
      };
    case DayViewActions.SetLastOffline:
      return {
        ...state,
        lastOffline: action.data.lastOffline,
      };
    case DayViewActions.SetScrollOffset:
      return {
        ...state,
        scrollOffset: action.data.scrollOffset,
      };
    case DayViewActions.SetSnapToSelectedMomentOnNextMount:
      return {
        ...state,
        snapToSelectedMomentOnNextMount: action.data.snapToSelectedMomentOnNextMount,
      };
    default:
      return {
        ...state,
      };
  }
}
