// IMPORTS
// schema
import * as ISchema from "#webapp/src/__schema__";

import { modernCache } from "#webapp/src/network/modern-network-layer";
import { commitMutation } from "react-relay";
import { Environment } from "relay-runtime";
import { createTeamMutation } from "./CreateTeam.gql"; // change

// TYPES
interface ICreateTeamMutationResponse {
  createTeam: ISchema.ICreateTeamMutationPayload;
}

// MUTATION
export function createTeam(
  environment: Environment,
  input: ISchema.ICreateTeamMutationInput,
  onSuccess: (response?: ISchema.ICreateTeamMutationPayload) => void,
  onError: (error?: any) => void,
) {
  commitMutation(environment, {
    onError,
    mutation: createTeamMutation,
    variables: { input },
    updater: (store, data: any) => {
      const { team } = data.createTeam;

      // get the org from store
      const orgProxy = store.get(input.orgRelayId);
      if (!orgProxy) {
        return;
      }

      // get the userTeams from the store org
      const userTeamsProxy = orgProxy.getLinkedRecord("userTeams");
      if (!userTeamsProxy) {
        return;
      }

      // get the list from the store userTeams
      const userTeamsListProxy = userTeamsProxy.getLinkedRecords("list");

      if (userTeamsListProxy) {
        // find the team from store and add to userTeams
        const newTeam = store.get(team.id);

        // if it already exists (because of subscription) do not add
        const match = userTeamsListProxy.find((t) => {
          return !!t && !!newTeam && t.getValue("id") === newTeam.getValue("id");
        });

        if (!match && newTeam) {
          userTeamsProxy.setLinkedRecords(
            [...userTeamsListProxy.map((aip) => aip), newTeam],
            "list",
          );
        }
      }
    },
    onCompleted: (response: ICreateTeamMutationResponse, errors?: Error[]) => {
      if (errors) {
        onError(errors[0]);
      } else {
        // if we're not in a modern environment, modernCache will be undefined
        if (modernCache) {
          modernCache.clear();
        }
        onSuccess(response.createTeam);
      }
    },
  });
}
