import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { LDContext } from "launchdarkly-js-client-sdk";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { pick } from "lodash";
import React, { PropsWithChildren } from "react";
import { getUrnWithTwoColons } from "./LaunchDarkly.util";

const isProd = () => getEnvironment() === "production";

const LD_KEY = isProd() ? "62ab54ea739ca215736249f1" : "62ab54ea739ca215736249f0";
const LD_REACT_OPTIONS = { useCamelCaseFlagKeys: false };
const LD_OPTIONS = { sendEventsOnlyForVariation: true };

export const LaunchDarklyClient = ({
  children,
  context,
}: PropsWithChildren<{ context: LDContext | undefined }>) => {
  return (
    <LDProvider
      clientSideID={LD_KEY}
      deferInitialization
      reactOptions={LD_REACT_OPTIONS}
      options={LD_OPTIONS}
      context={context}
    >
      {children}
    </LDProvider>
  );
};

export type User = {
  id: string;
  rolloutProperties: Record<string, any>;
};

export type Org = {
  id: string;
};

export const buildLaunchDarklyContext = (user: User, org: Org): LDContext => {
  const userKey = getUrnWithTwoColons(user.id); // Use the backend user id so that split keys are consistent across FE/BE systems
  const orgKey = getUrnWithTwoColons(org.id); // Use the backend orgid so that split keys are consistent across FE/BE systems;

  const orgRolloutProperties = getOrgRolloutPropertiesFromUser(user.rolloutProperties || null);
  const userRolloutProperties = getUserRolloutPropertiesFromUser(user.rolloutProperties || null);
  return {
    kind: "multi",
    user: {
      key: userKey,
      anonymous: false,
      ...userRolloutProperties,
    },
    org: {
      key: orgKey,
      anonymous: false,
      ...orgRolloutProperties,
    },
  };
};

export const getOrgRolloutPropertiesFromUser = (rolloutProperties: Record<string, any> | null) => ({
  OrgCreationMillis: null,
  OrgHighestPlanTier: null,
  OrgEcosystem: null,
  ...pick(rolloutProperties, ["OrgCreationMillis", "OrgHighestPlanTier", "OrgEcosystem"]),
});

export const getUserRolloutPropertiesFromUser = (
  rolloutProperties: Record<string, any> | null,
) => ({
  UserCreationMillis: null,
  ...pick(rolloutProperties, ["UserCreationMillis"]),
});
