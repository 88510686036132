import { graphql } from "react-relay";

export const updateFlowStateUserMutation = graphql`
  mutation UpdateFlowStateUserMutation($input: UpdateUserFlowMutationInput!) {
    updateFlowStateUser(input: $input) {
      flowState {
        id
        flowKey
        current {
          id
          state
          percentComplete
          errorMessage
        }
        pending {
          id
          state
          percentComplete
          errorMessage
        }
      }
    }
  }
`;
