import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
// import fetch from "cross-fetch";
import "cross-fetch/polyfill";
import { GRAPHQL_BATCH_URL, GRAPHQL_URL, SUBSCRIPTION_URL } from "../environment";
import { possibleTypes } from "../possibleTypes";
import { typePolicies } from "../typePolicies";
import {
  getAuthLink,
  getBatchLink,
  getGraphQLLink,
  getSocketLink,
  isSubscriptionOperation,
  isUsingBatching,
  updateTokensLink,
} from "./utils";

const authLink = getAuthLink();
const authLinkAsync = getAuthLink(true);

const singleHttpLink = getGraphQLLink(GRAPHQL_URL);
const batchHttpLink = getBatchLink(GRAPHQL_BATCH_URL);
const httpLink = split(isUsingBatching, batchHttpLink, singleHttpLink);

let splitLink: ApolloLink = httpLink;
let splitLinkAsync: ApolloLink = httpLink;

if (getEnvironment() !== "test") {
  const wsLink = getSocketLink(SUBSCRIPTION_URL);
  const wsLinkAsync = getSocketLink(SUBSCRIPTION_URL, true);
  splitLink = split(isSubscriptionOperation, wsLink, httpLink);
  splitLinkAsync = split(isSubscriptionOperation, wsLinkAsync, httpLink);
}

/**
 * Need to let the cache know about our union types.
 * See https://www.apollographql.com/docs/react/advanced/fragments.html#fragment-matcher.
 */
export const cache = new InMemoryCache({ typePolicies, possibleTypes });

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), (key, value) => {
      return key === "__typename" ? undefined : value;
    });
  }

  if (!forward) {
    return null;
  }

  return forward(operation);
});

let _client: ApolloClient<NormalizedCacheObject> | null = null;

export const getClient = () => {
  if (!_client) {
    _client = new ApolloClient({
      cache,
      connectToDevTools: getEnvironment() !== "production",
      link: ApolloLink.from([updateTokensLink, omitTypenameLink, authLink, splitLink]),
    });
  }
  return _client;
};

let _workerClient: ApolloClient<NormalizedCacheObject> | null = null;

export const getWorkerClient = () => {
  if (!_workerClient) {
    _workerClient = new ApolloClient({
      cache,
      connectToDevTools: getEnvironment() !== "production",
      link: ApolloLink.from([updateTokensLink, omitTypenameLink, authLinkAsync, splitLinkAsync]),
    });
  }
  return _workerClient;
};
