//////////////////
// IMPORTS
//////////////////
// libraries
// user-image imports
import { InfoTip } from "#webapp/src/components/tool-tip";
import * as React from "react";
import * as styles from "./user-image.styles";
import { IProps } from "./user-imageTypes";

//////////////////
// COMPONENT
//////////////////
export const UserImage = ({
  name,
  showFullName,
  imgUrl,
  showInfoTipName,
  infoTipText,
  style,
}: IProps) => {
  const getImageText = () => {
    if (!name || !name[0]) {
      return "C";
    }
    if (showFullName) {
      return name;
    } else {
      return name[0];
    }
  };

  const userImage = (
    <div style={{ ...styles.container, ...style }}>
      {!!imgUrl ? (
        <img style={styles.userImg} src={imgUrl} />
      ) : (
        <div style={{ ...styles.userImg, ...styles.noUserImg }}>{getImageText()}</div>
      )}
    </div>
  );

  let render = userImage;
  if (showInfoTipName || infoTipText) {
    const text = infoTipText ? infoTipText : name;
    render = <InfoTip targetElement={userImage} text={text} tipType={"info"} />;
  }

  return render;
};
