/**
 * @generated SignedSource<<ac05129eada4d2451ecf0d5fe9ca7ad1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductTypeEnum = "Business_NonStandard" | "Business_Standard" | "Enterprise_NonStandard" | "Enterprise_Standard" | "Pro_NonStandard" | "Pro_Standard" | "%future added value";
export type SuggestedTeamReasonType = "NumMeetingsWithOverlap" | "NumOneOnOnesWithOverlap" | "%future added value";
export type UrnType = "Date" | "Email" | "Event" | "FlowState" | "Guest" | "Org" | "Phone" | "Services" | "Setting" | "Team" | "Teammate" | "TimeZone" | "User" | "WorkOsOrg" | "WorkingGroup" | "WorkingHours" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TeamJoinCreate_org$data = {
  readonly id: string;
  readonly primaryOrgEmail: string;
  readonly workingGroups: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly calendarIds: ReadonlyArray<string | null> | null;
      } | null;
    } | null> | null;
  };
  readonly primaryOrgCalendar: string;
  readonly orgPersonForUserErrorable: {
    readonly personId?: string;
  };
  readonly primaryTeam: {
    readonly __typename: "Team";
    readonly teamId: string;
    readonly teamName: string;
  } | {
    readonly __typename: "GraphEntityError";
    readonly statusCode: number | null;
    readonly message: string | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly featureGrid: {
    readonly __typename: "FeatureGrid";
    readonly stateMetadata: {
      readonly primaryBillingGroupMetadata: {
        readonly subscription: {
          readonly id: string;
        };
      } | null;
      readonly userMetadata: {
        readonly person: {
          readonly primaryCalendarId: string;
        };
        readonly trialMetadata: {
          readonly trialEndDate: string;
          readonly timeZone: string;
          readonly isTrialExpired: boolean;
        } | null;
      } | null;
    };
  } | {
    readonly __typename: "GraphEntityError";
    readonly statusCode: number | null;
    readonly message: string | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly billingGroups: {
    readonly list?: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly syncSources: ReadonlyArray<{
        readonly type: UrnType | null;
        readonly value: string | null;
      }>;
      readonly subscription: {
        readonly __typename: "SubscriptionDetails";
        readonly subscription: {
          readonly id: string;
          readonly productType: ProductTypeEnum | null;
        };
      } | {
        readonly __typename: "GraphEntityError";
        readonly message: string | null;
        readonly statusCode: number | null;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
    }>;
    readonly message?: string | null;
    readonly statusCode?: number | null;
  };
  readonly userTeams: {
    readonly __typename: "TeamList";
    readonly list: ReadonlyArray<{
      readonly id: string;
      readonly teamId: string;
      readonly teamName: string;
      readonly createdBy: {
        readonly value: string | null;
      };
      readonly teamCalendarId: string | null;
      readonly settings: {
        readonly __typename: "TeamSettings";
        readonly manageTeamCalendar: boolean;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
      readonly userSettings: {
        readonly __typename: "TeamUserSettings";
        readonly useTeamCalendar: boolean;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
      readonly teamMembers: ReadonlyArray<{
        readonly person: {
          readonly __typename: string;
          readonly id: string;
          readonly primaryCalendarId: string;
          readonly isYou: boolean;
          readonly userId: string | null;
          readonly personId: string;
          readonly profile: {
            readonly givenName: string | null;
            readonly familyName: string | null;
            readonly externalImageUrl: string | null;
          } | null;
        };
      }>;
      readonly invitedMembers: ReadonlyArray<{
        readonly person: {
          readonly __typename: string;
          readonly id: string;
          readonly primaryCalendarId: string;
          readonly isYou: boolean;
          readonly userId: string | null;
          readonly personId: string;
          readonly profile: {
            readonly givenName: string | null;
            readonly familyName: string | null;
            readonly externalImageUrl: string | null;
          } | null;
        };
      }>;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly suggestedTeams: {
    readonly __typename: "SuggestedTeamList";
    readonly list: ReadonlyArray<{
      readonly team: {
        readonly id: string;
        readonly teamId: string;
        readonly teamName: string;
        readonly createdBy: {
          readonly value: string | null;
        };
        readonly teamMembers: ReadonlyArray<{
          readonly person: {
            readonly __typename: string;
            readonly id: string;
            readonly primaryCalendarId: string;
            readonly isYou: boolean;
            readonly userId: string | null;
            readonly personId: string;
            readonly profile: {
              readonly givenName: string | null;
              readonly familyName: string | null;
              readonly externalImageUrl: string | null;
            } | null;
          };
        }>;
        readonly invitedMembers: ReadonlyArray<{
          readonly person: {
            readonly __typename: string;
            readonly id: string;
            readonly primaryCalendarId: string;
            readonly isYou: boolean;
            readonly userId: string | null;
            readonly profile: {
              readonly givenName: string | null;
              readonly familyName: string | null;
              readonly externalImageUrl: string | null;
            } | null;
          };
        }>;
      };
      readonly reasons: ReadonlyArray<{
        readonly type: SuggestedTeamReasonType;
        readonly value: number;
      }>;
      readonly score: number;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly " $fragmentType": "TeamJoinCreate_org";
};
export type TeamJoinCreate_org$key = {
  readonly " $data"?: TeamJoinCreate_org$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamJoinCreate_org">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "personId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusCode",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "type": "GraphEntityError",
  "abstractKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primaryCalendarId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  (v9/*: any*/)
],
v11 = {
  "kind": "InlineFragment",
  "selections": [
    (v6/*: any*/),
    (v5/*: any*/)
  ],
  "type": "GraphEntityError",
  "abstractKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Urn",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": [
    (v9/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isYou",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "givenName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "familyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalImageUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OrgPerson",
    "kind": "LinkedField",
    "name": "person",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v8/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v1/*: any*/),
      (v15/*: any*/)
    ],
    "storageKey": null
  }
],
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamMember",
  "kind": "LinkedField",
  "name": "teamMembers",
  "plural": true,
  "selections": (v16/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamJoinCreate_org",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryOrgEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1000000
        }
      ],
      "concreteType": "WorkingGroupConnection",
      "kind": "LinkedField",
      "name": "workingGroups",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkingGroupEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorkingGroup",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "calendarIds",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "workingGroups(first:1000000)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryOrgCalendar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "orgPersonForUserErrorable",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/)
          ],
          "type": "OrgPerson",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "primaryTeam",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "type": "Team",
          "abstractKey": null
        },
        (v7/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "featureGrid",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentStateMetadata",
              "kind": "LinkedField",
              "name": "stateMetadata",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PrimaryBillingGroupMetadata",
                  "kind": "LinkedField",
                  "name": "primaryBillingGroupMetadata",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PaymentSubscription",
                      "kind": "LinkedField",
                      "name": "subscription",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserMetadata",
                  "kind": "LinkedField",
                  "name": "userMetadata",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrgPerson",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v8/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TrialMetadata",
                      "kind": "LinkedField",
                      "name": "trialMetadata",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "trialEndDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "timeZone",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isTrialExpired",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "FeatureGrid",
          "abstractKey": null
        },
        (v7/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "billingGroups",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BillingGroup",
              "kind": "LinkedField",
              "name": "list",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Urn",
                  "kind": "LinkedField",
                  "name": "syncSources",
                  "plural": true,
                  "selections": (v10/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "subscription",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PaymentSubscription",
                          "kind": "LinkedField",
                          "name": "subscription",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "productType",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "SubscriptionDetails",
                      "abstractKey": null
                    },
                    (v11/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "BillingGroupList",
          "abstractKey": null
        },
        (v11/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "userTeams",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Team",
              "kind": "LinkedField",
              "name": "list",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v12/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "teamCalendarId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "settings",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "manageTeamCalendar",
                          "storageKey": null
                        }
                      ],
                      "type": "TeamSettings",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "userSettings",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "useTeamCalendar",
                          "storageKey": null
                        }
                      ],
                      "type": "TeamUserSettings",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v17/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamMember",
                  "kind": "LinkedField",
                  "name": "invitedMembers",
                  "plural": true,
                  "selections": (v16/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "TeamList",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "suggestedTeams",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SuggestedTeam",
              "kind": "LinkedField",
              "name": "list",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Team",
                  "kind": "LinkedField",
                  "name": "team",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v12/*: any*/),
                    (v17/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TeamMember",
                      "kind": "LinkedField",
                      "name": "invitedMembers",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "OrgPerson",
                          "kind": "LinkedField",
                          "name": "person",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v0/*: any*/),
                            (v8/*: any*/),
                            (v13/*: any*/),
                            (v14/*: any*/),
                            (v15/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SuggestedTeamReason",
                  "kind": "LinkedField",
                  "name": "reasons",
                  "plural": true,
                  "selections": (v10/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "score",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "SuggestedTeamList",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Org",
  "abstractKey": null
};
})();

(node as any).hash = "433d5d121ed6f0611608929434786091";

export default node;
