/**
 * @generated SignedSource<<d10621745d71dc6f47c2d3aa61878cc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventTimeType = "Date" | "DateTime" | "%future added value";
export type SmartHoldTypeEnum = "FocusTime" | "Lunch" | "MeetingRelief" | "MissingPriority" | "NoMeetingDay" | "OnboardingTestEvent" | "Pain" | "ShadowCalendar" | "SyncedEvent" | "TeamEvent" | "TravelTime" | "TwoWaySync" | "%future added value";
export type SubjectUrnTypeEnum = "Email" | "Integration" | "Org" | "Person" | "Phone" | "Services" | "Team" | "User" | "%future added value";
export type ScheduleNewSmartHoldMutationInput = {
  calendarId: string;
  clientMutationId?: string | null;
  description?: string | null;
  orgRelayId: string;
  smartHoldType: SmartHoldTypeEnum;
  timeRange: TimeRangeInput;
};
export type TimeRangeInput = {
  endTime?: EventTimeInput | null;
  id: string;
  startTime?: EventTimeInput | null;
};
export type EventTimeInput = {
  dateOrDateTime: string;
  millis: number;
  offsetMinutes?: number | null;
  timeZone?: string | null;
  type: EventTimeType;
};
export type ScheduleNewSmartHoldMutation$variables = {
  input: ScheduleNewSmartHoldMutationInput;
};
export type ScheduleNewSmartHoldMutation$data = {
  readonly scheduleNewSmartHold: {
    readonly eventParent: {
      readonly id: string;
      readonly externalEventId: string;
      readonly events: ReadonlyArray<{
        readonly id: string;
        readonly startTime: {
          readonly millis: number;
        };
        readonly annotatedEvent: {
          readonly id: string;
          readonly externalEventId: string | null;
          readonly orgTags: ReadonlyArray<{
            readonly tag: string;
            readonly state: {
              readonly value: string | null;
              readonly subjectType: SubjectUrnTypeEnum;
              readonly subjectValue: string;
              readonly lastModified: number;
              readonly active: boolean;
            };
          }> | null;
        } | null;
        readonly annotatedRecurringEvent: {
          readonly id: string;
          readonly externalEventId: string | null;
          readonly orgTags: ReadonlyArray<{
            readonly tag: string;
            readonly state: {
              readonly value: string | null;
              readonly subjectType: SubjectUrnTypeEnum;
              readonly subjectValue: string;
              readonly lastModified: number;
              readonly active: boolean;
            };
          }> | null;
        } | null;
      }>;
    };
  } | null;
};
export type ScheduleNewSmartHoldMutation = {
  variables: ScheduleNewSmartHoldMutation$variables;
  response: ScheduleNewSmartHoldMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalEventId",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "OrgTag",
    "kind": "LinkedField",
    "name": "orgTags",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tag",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TagState",
        "kind": "LinkedField",
        "name": "state",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subjectType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subjectValue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastModified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ScheduleNewSmartHoldMutationPayload",
    "kind": "LinkedField",
    "name": "scheduleNewSmartHold",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventParent",
        "kind": "LinkedField",
        "name": "eventParent",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "events",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EventTime",
                "kind": "LinkedField",
                "name": "startTime",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "millis",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AnnotatedEvent",
                "kind": "LinkedField",
                "name": "annotatedEvent",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AnnotatedEvent",
                "kind": "LinkedField",
                "name": "annotatedRecurringEvent",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleNewSmartHoldMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScheduleNewSmartHoldMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "347169a4e6ae5b4e897d095114daea74",
    "id": null,
    "metadata": {},
    "name": "ScheduleNewSmartHoldMutation",
    "operationKind": "mutation",
    "text": "mutation ScheduleNewSmartHoldMutation(\n  $input: ScheduleNewSmartHoldMutationInput!\n) {\n  scheduleNewSmartHold(input: $input) {\n    eventParent {\n      id\n      externalEventId\n      events {\n        id\n        startTime {\n          millis\n        }\n        annotatedEvent {\n          id\n          externalEventId\n          orgTags {\n            tag\n            state {\n              value\n              subjectType\n              subjectValue\n              lastModified\n              active\n            }\n          }\n        }\n        annotatedRecurringEvent {\n          id\n          externalEventId\n          orgTags {\n            tag\n            state {\n              value\n              subjectType\n              subjectValue\n              lastModified\n              active\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "97d3ec0ca382138cad00518887aa8b27";

export default node;
