import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { EFlex } from "@clockwise/client-commons/src/util/event-tag";
import { EventFlexibilityDocument } from "@clockwise/web-commons/src/ui/autopilot-flexibility/__generated__/AutopilotFlexibility.generated";
import { UseEventFlexibility } from "@clockwise/web-commons/src/util/use-event-flexibility.util";

export const useEventFlexibility = ({
  calendarId,
  orgId,
  externalEventId,
}: {
  calendarId: string;
  orgId: string;
  externalEventId: string | undefined;
}): UseEventFlexibility => {
  const skip = !calendarId || !orgId || !externalEventId;
  const { data: flexData, refetch: refetchEventFlexibility } = useQuery(EventFlexibilityDocument, {
    variables: {
      calendarId,
      CalendarId: calendarId,
      orgId,
      externalEventId: externalEventId!, // or skip
    },
    skip: skip,
  });

  if (skip) {
    return {
      eventFlexibility: null,
      eventFlexibilityConstraints: null,
      eFlex: EFlex.Pin,
      refetchEventFlexibility: undefined,
    };
  }

  const flexOrg = getValue(flexData?.org, "Org");
  const forceFetchEventParentErrorable = getValue(flexOrg?.forceFetchEventParentErrorable);
  const eventFlexibility = getValue(forceFetchEventParentErrorable?.flexibility);
  const eventFlexibilityConstraints = getValue(
    forceFetchEventParentErrorable?.flexibilityConstraints,
  );
  const timeRangeFlexibility = getValue(eventFlexibility?.timeRangeFlexibility);
  const eFlex =
    timeRangeFlexibility == null
      ? EFlex.Pin
      : timeRangeFlexibility?.__typename == "DayOfWeekFlexibility"
      ? EFlex.DayOfWeek
      : ((timeRangeFlexibility?.range as unknown) as EFlex);

  return {
    eventFlexibility,
    eventFlexibilityConstraints,
    eFlex,
    refetchEventFlexibility,
  };
};

export const useDayOfWeekFlexibilityEligible = ({
  calendarId,
  orgId,
  externalEventId,
}: {
  calendarId: string;
  orgId: string;
  externalEventId: string | undefined;
}): boolean => {
  const skip = !calendarId || !orgId || !externalEventId;
  const { data: dayOfWeekData } = useQuery(EventFlexibilityDocument, {
    variables: {
      calendarId,
      CalendarId: calendarId,
      orgId,
      externalEventId: externalEventId!, // or skip
    },
    skip: skip,
  });

  if (skip) {
    return false;
  }

  const flexOrg = getValue(dayOfWeekData?.org, "Org");
  const forceFetchEventParentErrorable = getValue(flexOrg?.forceFetchEventParentErrorable);
  const eventFlexibilityConstraints = getValue(
    forceFetchEventParentErrorable?.flexibilityConstraints,
  );

  return !!eventFlexibilityConstraints?.dayOfWeekFlexibilityEligible;
};
