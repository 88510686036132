import { useQuery } from "@apollo/client";
import { noop } from "lodash";
import React, { useCallback, useEffect } from "react";
import { removeCalendarIds } from "../../../state/actions/multi-calendar.actions";
import {
  CalendarProposalDocument,
  CalendarProposalQuery,
} from "../apollo/__generated__/CalendarProposal.generated";

import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useDispatch, useSelector } from "react-redux";
import { IReduxState } from "../../../state/reducers/root.reducer";
import { useReadDismissedConflictClusters } from "../util/DismissedConflictClusterContext";
import {
  ConflictClusterWithConversationId,
  parseConflictClustersGQL,
} from "../util/parseConflictClustersGQL";

export const useConflictClusters = () => {
  const dispatch = useDispatch();
  const track = useTracking();
  const calendarIdsToFullyShow = useSelector(
    (state: IReduxState) => state.multiCalendar.calendarIdsToFullyShow,
  );
  const dismissedConflictIds = useReadDismissedConflictClusters();
  const [conflictClusters, setConflictClusters] = React.useState<
    ConflictClusterWithConversationId[]
  >([]);

  const parseConflictClusters = useCallback(
    (data: CalendarProposalQuery, dismissedConflictIds: string[]) => {
      const newConflictClusters = parseConflictClustersGQL(data, dismissedConflictIds);
      setConflictClusters(newConflictClusters || []);
      if (newConflictClusters?.length) {
        track(TrackingEvents.CONFLICT_RESOLUTION.RECEIVED_CONFLICTS, {
          clusterIds: newConflictClusters.map((cluster) => cluster.id),
          conversationIds: newConflictClusters.map((cluster) => cluster.conversationId),
        });
        dispatch(removeCalendarIds(calendarIdsToFullyShow));
      }
    },
    [calendarIdsToFullyShow, dispatch],
  );

  const { loading, error, data } = useQuery(CalendarProposalDocument);

  useEffect(() => {
    if (!data) return;
    parseConflictClusters(data, dismissedConflictIds);
  }, [JSON.stringify(data), JSON.stringify(dismissedConflictIds)]);

  const refetchAndstartPolling = noop;
  const stopPolling = noop;
  const refetch = noop;
  const prefetch = noop;

  return {
    conflictClusters,
    loading,
    error,
    refetch,
    prefetch,
    startPolling: refetchAndstartPolling,
    stopPolling,
  };
};
