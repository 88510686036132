import * as CwIcons from "@clockwise/icons";

export { FindTime } from "./FindTime";
export { Prism } from "./Prism";

export const AccessTime = CwIcons.Schedule;
export const AccessTimeFilled = CwIcons.ScheduleFilled;
export const AccountCircle = CwIcons.AccountCircleFilled;
export const Add = CwIcons.Add;
export const AddCircle = CwIcons.AddCircleFilled;
export const AddCircleOutline = CwIcons.AddCircle;
export const AllInclusive = CwIcons.AllInclusiveFilled;
export const ArrowBack = CwIcons.ArrowBackFilled;
export const ArrowBackIos = CwIcons.ArrowBackIosFilled;
export const ArrowDropDown = CwIcons.ArrowDropDownFilled;
export const ArrowDropDownCircle = CwIcons.ArrowDropDownCircleFilled;
export const ArrowDropUp = CwIcons.ArrowDropUpFilled;
export const ArrowForward = CwIcons.ArrowForwardFilled;
export const ArrowLeft = CwIcons.ArrowLeftFilled;
export const ArrowRight = CwIcons.ArrowRightFilled;
export const ArrowUpward = CwIcons.ArrowUpwardFilled;
export const Assistant = CwIcons.AssistantFilled;
export const Autorenew = CwIcons.AutorenewFilled;
export const AvgPace = CwIcons.AvgPace;
export const AvTimer = CwIcons.AvTimerFilled;
export const AvTimerOutlined = CwIcons.AvTimer;
export const BarChart = CwIcons.BarChartFilled;
export const Beenhere = CwIcons.BeenhereFilled;
export const Block = CwIcons.BlockFilled;
export const Brightness1 = CwIcons.Brightness1Filled;
export const Brightness3 = CwIcons.Brightness3Filled;
export const Business = CwIcons.Domain;
export const CalendarToday = CwIcons.CalendarTodayFilled;
export const Cancel = CwIcons.CancelFilled;
export const Category = CwIcons.CategoryFilled;
export const Check = CwIcons.CheckFilled;
export const CheckBox = CwIcons.CheckBoxFilled;
export const CheckBoxOutlineBlank = CwIcons.CheckBoxOutlineBlankFilled;
export const CheckCircle = CwIcons.CheckCircleFilled;
export const CheckCircleOutline = CwIcons.CheckCircle;
export const CheckCircleHistory = CwIcons.CheckCircleHistoryFilled;
export const ChevronLeft = CwIcons.ChevronLeftFilled;
export const ChevronRight = CwIcons.ChevronRightFilled;
export const Clear = CwIcons.CloseFilled;
export const ClockwiseIntelligence = CwIcons.ClockwiseIntelligence;
export const ClockwiseIntelligenceFilled = CwIcons.ClockwiseIntelligenceFilled;
export const Close = CwIcons.CloseFilled;
export const Colorize = CwIcons.ColorizeFilled;
export const ControlPoint = CwIcons.AddCircle;
export const Create = CwIcons.EditFilled;
export const CreditCard = CwIcons.CreditCard;
export const DateRange = CwIcons.DateRangeFilled;
export const Delete = CwIcons.DeleteFilled;
export const DeleteOutline = CwIcons.Delete;
export const DirectionsRun = CwIcons.DirectionsRunFilled;
export const Diversity2 = CwIcons.Diversity2;
export const Dns = CwIcons.DnsFilled;
export const Domain = CwIcons.DomainFilled;
export const Done = CwIcons.DoneFilled;
export const DragIndicator = CwIcons.DragIndicatorFilled;
export const Edit = CwIcons.EditFilled;
export const Email = CwIcons.MailFilled;
export const Error = CwIcons.ErrorFilled;
export const ErrorHistory = CwIcons.ErrorHistoryFilled;
export const ErrorOutlineOutlined = CwIcons.Error;
export const Event = CwIcons.EventFilled;
export const EventAvailable = CwIcons.EventAvailableFilled;
export const EventBusy = CwIcons.EventBusyFilled;
export const ExitToApp = CwIcons.ExitToAppFilled;
export const ExpandLess = CwIcons.ExpandLessFilled;
export const ExpandMore = CwIcons.ExpandMoreFilled;
export const Fastfood = CwIcons.FastfoodFilled;
export const Feedback = CwIcons.FeedbackFilled;
export const FiberManualRecord = CwIcons.FiberManualRecordFilled;
export const FileCopyOutlined = CwIcons.FileCopy;
export const Flag = CwIcons.FlagFilled;
export const GetApp = CwIcons.Download;
export const Group = CwIcons.GroupFilled;
export const GroupAdd = CwIcons.GroupAddFilled;
export const Headset = CwIcons.HeadphonesFilled;
export const Help = CwIcons.HelpFilled;
export const HelpOutline = CwIcons.Help;
export const IndeterminateCheckBox = CwIcons.IndeterminateCheckBoxFilled;
export const Info = CwIcons.InfoFilled;
export const InfoOutlined = CwIcons.Info;
export const InsertChart = CwIcons.InsertChartFilled;
export const KeyboardArrowDown = CwIcons.KeyboardArrowDownFilled;
export const KeyboardArrowLeft = CwIcons.KeyboardArrowLeftFilled;
export const KeyboardArrowRight = CwIcons.KeyboardArrowRightFilled;
export const KeyboardArrowUp = CwIcons.KeyboardArrowUpFilled;
export const Kitchen = CwIcons.Kitchen;
export const Label = CwIcons.LabelFilled;
export const LabelOutlined = CwIcons.Label;
export const Lightbulb = CwIcons.Lightbulb;
export const Link = CwIcons.LinkFilled;
export const LocalAirport = CwIcons.FlightFilled;
export const LocationOn = CwIcons.LocationOnFilled;
export const LockOutlined = CwIcons.Lock;
export const Mail = CwIcons.MailFilled;
export const MeetingRoom = CwIcons.MeetingRoomFilled;
export const Menu = CwIcons.MenuFilled;
export const MoodBad = CwIcons.MoodBad;
export const MoreHoriz = CwIcons.MoreHorizFilled;
export const MoreVert = CwIcons.MoreVertFilled;
export const NavigateBefore = CwIcons.NavigateBeforeFilled;
export const NavigateNext = CwIcons.NavigateNextFilled;
export const NoMeetingRoom = CwIcons.NoMeetingRoom;
export const Notes = CwIcons.NotesFilled;
export const Notifications = CwIcons.NotificationsFilled;
export const NotInterested = CwIcons.BlockFilled;
export const OfflineBolt = CwIcons.OfflineBoltFilled;
export const OpenInNew = CwIcons.OpenInNewFilled;
export const Palette = CwIcons.PaletteFilled;
export const Pause = CwIcons.PauseFilled;
export const PauseCircleFilled = CwIcons.PauseCircleFilled;
export const PauseCircleOutline = CwIcons.PauseCircle;
export const People = CwIcons.GroupFilled;
export const Person = CwIcons.PersonFilled;
export const PersonAdd = CwIcons.PersonAddFilled;
export const PersonOutline = CwIcons.Person;
export const PlayArrow = CwIcons.PlayArrowFilled;
export const PlayCircleFilled = CwIcons.PlayCircleFilled;
export const PlayCircleOutline = CwIcons.PlayCircle;
export const Pool = CwIcons.PoolFilled;
export const Public = CwIcons.PublicFilled;
export const RadioButtonChecked = CwIcons.RadioButtonChecked;
export const RadioButtonUnchecked = CwIcons.RadioButtonUnchecked;
export const RemoveCircleOutline = CwIcons.DoNotDisturbOn;
export const RemoveRedEye = CwIcons.VisibilityFilled;
export const Replay = CwIcons.ReplayFilled;
export const Reply = CwIcons.ReplyFilled;
export const ReportProblemOutlined = CwIcons.Warning;
export const Room = CwIcons.LocationOnFilled;
export const Save = CwIcons.SaveFilled;
export const School = CwIcons.SchoolFilled;
export const Search = CwIcons.SearchFilled;
export const Send = CwIcons.SendFilled;
export const Settings = CwIcons.SettingsFilled;
export const SettingsApplications = CwIcons.SettingsApplicationsFilled;
export const Star = CwIcons.StarFilled;
export const Subject = CwIcons.SubjectFilled;
export const Subway = CwIcons.SubwayFilled;
export const SwapHoriz = CwIcons.SwapHorizFilled;
export const SwapVert = CwIcons.SwapVertFilled;
export const Sync = CwIcons.SyncFilled;
export const SyncDisabled = CwIcons.SyncDisabledFilled;
export const SyncProblem = CwIcons.SyncProblemFilled;
export const TagFaces = CwIcons.Mood;
export const Timelapse = CwIcons.Timelapse;
export const Today = CwIcons.TodayFilled;
export const Undo = CwIcons.UndoFilled;
export const Update = CwIcons.UpdateFilled;
export const Videocam = CwIcons.VideocamFilled;
export const Warning = CwIcons.WarningFilled;
export const WarningHistoryFilled = CwIcons.WarningHistoryFilled;
// Not sure why but the existing WarningOutlined icon is identical to the filled Warning icon
export const WarningOutlined = CwIcons.WarningFilled;
export const WarningTwoTone = CwIcons.Warning;
export const Waves = CwIcons.WavesFilled;
export const WorkOutline = CwIcons.Work;
