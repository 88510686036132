import { graphql } from "react-relay";

export const unpauseEventMutation = graphql`
  mutation UnpauseEventMutation($input: UnpauseEventMutationInput!, $calendarId: String!) {
    unpauseEvent(input: $input) {
      eventParent {
        id
        externalEventId
        events {
          startTime {
            millis
          }
          autopilotEventStatus(calendarId: $calendarId) {
            __typename
            ... on AutopilotEventStatus {
              status
            }
          }
          annotatedEvent {
            id
            externalEventId
            orgTags {
              tag
              state {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
          annotatedRecurringEvent {
            id
            externalEventId
            orgTags {
              tag
              state {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
        }
      }
    }
  }
`;
