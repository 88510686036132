//////////////////
// IMPORTS
//////////////////
// libraries
import React, { PropsWithChildren } from "react";
import { Helmet } from "react-helmet";

import { Link } from "@clockwise/design-system";
import { ErrorScreen } from "@clockwise/web-commons/src/components/error-screen";
import { LoaderClock } from "@clockwise/web-commons/src/components/loader-clock";
import { LoaderClockWithMessage } from "@clockwise/web-commons/src/components/loader-clock-with-message";
import { NoRouteView, SchedulingLinkNotFound } from "../no-route-view";

type Props = PropsWithChildren<{
  variant?: "scheduling-link";
  loading: boolean;
  loadingMessage?: string;
  notFound?: boolean;
  error: boolean;
}>;

//////////////////
// COMPONENT
//////////////////

/*
 * This compenents takes care of the common tasks of rending a loading screen, a possible route not found screen,
 * as well as a possible error screen.
 * In this order:
 * Renders a loading screen if the route is loading prop is true.
 * Renders a loading screen with a message if loading is true and a loadingMessage is provided.
 * Renders a not found screen if the route is not found.
 * Renders an error screen if there is an error.
 */

export const RouteRenderer = ({
  variant,
  notFound,
  loading,
  loadingMessage,
  children,
  error,
}: Props) => {
  if (loading) {
    return (
      <div className="cw-absolute cw-inset-0 cw-flex cw-justify-center cw-items-center">
        {loadingMessage ? <LoaderClockWithMessage message={loadingMessage} /> : <LoaderClock />}
      </div>
    );
  }

  if (notFound) {
    let NoRouteViewComponent;
    switch (variant) {
      case "scheduling-link":
        NoRouteViewComponent = SchedulingLinkNotFound;
        break;
      default:
        NoRouteViewComponent = NoRouteView;
    }

    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <NoRouteViewComponent />
      </>
    );
  }

  if (error) {
    return (
      <div style={{ margin: 40, minWidth: 265 }} cw-id="something-went-wrong">
        <ErrorScreen title="Sorry, something went wrong">
          If the issue persists, contact us at{" "}
          <Link href="mailto:support@getclockwise.com">support@getclockwise.com</Link>.
        </ErrorScreen>
      </div>
    );
  }

  return <>{children}</>;
};
