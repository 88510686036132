// util
import { incrementValue } from "#webapp/src/util/stats.util";

class WindowLocation {
  public async reload(location: string) {
    await incrementValue("client.reload", { location });

    window.location.reload();
  }

  public async assign(location: string, path: string) {
    await incrementValue("client.reload", { location });

    window.location.assign(path);
  }
}

export const windowLocation = new WindowLocation();
