import { Button } from "@clockwise/design-system";
import React from "react";

type DowngradeButtonProps = {
  isCurrentPlan: boolean;
  onClick: () => void;
};

const DowngradeButton = ({ isCurrentPlan, onClick }: DowngradeButtonProps) => {
  return (
    <Button
      onClick={onClick}
      disabled={isCurrentPlan}
      fullWidth
      sentiment={isCurrentPlan ? "positive" : "neutral"}
      variant={isCurrentPlan ? "solid" : "outlined"}
    >
      {isCurrentPlan ? "Current plan" : "Downgrade"}
    </Button>
  );
};

export default DowngradeButton;
