import { Profile, getFullName } from "#clockwise/web-commons/src/util/profile.util";
import { Tooltip } from "@clockwise/design-system";
import { Cancel, CheckCircle } from "@clockwise/design-system/icons";
import { bg_default } from "@clockwise/design-system/tokens";
import { ResponseStatusEnum } from "@clockwise/schema";
import { Avatar, Badge } from "@material-ui/core";
import classNames from "classnames";
import React, { CSSProperties, useMemo } from "react";
import { LogoClock } from "../components/logo";
import {
  bg_cal1_Emphasis,
  bg_cal2_Emphasis,
  bg_cal3_Emphasis,
  bg_cal4_Emphasis,
  bg_cal6_Emphasis,
  bg_cal7_Emphasis,
  bg_cal8_Emphasis,
  bg_cal9_Emphasis,
} from "../constants/calendarColorTokens";
import { hashString } from "./hashString";

export type AvatarSize = "tiny" | "small" | "medium" | "large" | "xlarge";

const avatarColors = [
  bg_cal1_Emphasis,
  bg_cal2_Emphasis,
  bg_cal3_Emphasis,
  bg_cal4_Emphasis,
  bg_cal6_Emphasis,
  bg_cal7_Emphasis,
  bg_cal8_Emphasis,
  bg_cal9_Emphasis,
];

// Hash the name into a bucket to pick a color
const getAvatarColor = (name: string) =>
  avatarColors[Math.abs(hashString(name)) % avatarColors.length];

export type Props = {
  profile: Profile;
  calendarId?: string;
  size: AvatarSize;
  // Manual override for size, in case the size prop is not sufficient
  sizeNum?: number;
  // Manual override for fontSize, in case the size prop is not sufficient. Recommended if you use sizeNum
  fontSizeNum?: number;
  hasOverlap?: boolean;
  "cw-id"?: string;
  "aria-label"?: string;
  rsvp?: ResponseStatusEnum | null;
  onClick?: () => void;
  toolTipContent?: React.ReactNode;
  showUserBadge?: boolean;
  hideTooltip?: boolean;
  avatarClassname?: string;
  prefix?: string;
  borderColor?: string;
};

export const SIZE_TABLE: Record<AvatarSize, string> = {
  tiny: "20px",
  small: "24px",
  medium: "30px",
  large: "40px",
  xlarge: "75px",
};

const TentativeAttendeeStatus = ({ style }: { style: CSSProperties }) => (
  <div
    className={`cw-rounded-full cw-border-[${bg_default}] cw-border-solid cw-border-2`}
    style={style}
  >
    <div className="cw-rounded-full cw-border cw-w-full cw-h-full cw-border-dashed cw-border-warning-emphasis cw-bg-warning-inset" />
  </div>
);

/**
 * @deprecated Use AttendeeAvatar instead
 */
export function UserAvatar({
  profile,
  calendarId,
  size,
  sizeNum,
  fontSizeNum,
  "cw-id": cwId,
  "aria-label": ariaLabel,
  hasOverlap = false,
  rsvp,
  onClick,
  toolTipContent,
  showUserBadge = false,
  hideTooltip = false,
  avatarClassname,
  prefix,
  borderColor,
}: Props) {
  const sizeOfAvatar = sizeNum ? `${sizeNum}px` : SIZE_TABLE[size];
  const fontSizeManual = fontSizeNum ? `cw-text-${fontSizeNum}` : "";

  const badgeContent = useMemo(() => {
    // Make badge height and width a function of the avatar size, with a max of 20.
    const dimension = Math.min(Math.floor(parseInt(sizeOfAvatar) / 1.6), 26);
    if (rsvp !== undefined && rsvp !== null) {
      if (rsvp === ResponseStatusEnum.Accepted) {
        return (
          <CheckCircle
            style={{ height: dimension, width: dimension }}
            className="cw-fill-positive cw-bg-default cw-rounded-full"
          />
        );
      }
      if (rsvp === ResponseStatusEnum.Tentative) {
        return <TentativeAttendeeStatus style={{ height: dimension, width: dimension }} />;
      }
      if (rsvp === ResponseStatusEnum.Declined) {
        return (
          <Cancel
            style={{ height: dimension, width: dimension }}
            className="cw-fill-destructive cw-bg-default cw-rounded-full"
          />
        );
      }
    } else if (showUserBadge) {
      return (
        <div
          className="cw-box-border cw-border-[#fff] cw-border-2 cw-border-solid cw-bg-accent-emphasis cw-rounded-full cw-relative"
          style={{ height: dimension, width: dimension }}
        >
          <div
            className={classNames("cw-absolute cw-flex cw-items-center cw-justify-center", {
              "cw-inset-1": dimension >= 25,
              "cw-inset-[2px]": dimension < 25,
            })}
          >
            <LogoClock fill="bright" />
          </div>
        </div>
      );
    }
    return null;
  }, [rsvp, showUserBadge, size]);

  const { givenName, familyName, externalImageUrl } = profile;
  if (!externalImageUrl && !givenName && !familyName && !calendarId) {
    return null;
  }

  const displayName = getFullName(profile);
  const fullName = displayName.length > 0 ? displayName : calendarId ?? "";
  const helperText = ariaLabel || `${fullName || "user"} avatar`;

  const badge = (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      showZero={false}
      badgeContent={badgeContent}
      className="cw-p-0"
    >
      <Avatar
        alt={fullName}
        src={externalImageUrl || undefined}
        style={{
          width: sizeOfAvatar,
          height: sizeOfAvatar,
          borderColor: borderColor || "transparent",
          backgroundColor: getAvatarColor(fullName),
        }}
        aria-label={externalImageUrl ? undefined : helperText}
        imgProps={{ "aria-label": helperText }}
        className={classNames(
          "cw-text-onEmphasis",
          fontSizeManual,
          {
            "cw-border-2 cw-border-default cw-border-solid": hasOverlap && !borderColor,
            "cw-border-2 cw-border-solid": borderColor,
            "cw-text-12": !fontSizeManual && size === "tiny",
            "cw-text-14": !fontSizeManual && size === "small",
            "cw-text-16": !fontSizeManual && size === "medium",
            "cw-text-20": !fontSizeManual && size === "large",
            "cw-text-32": !fontSizeManual && size === "xlarge",
          },
          avatarClassname,
        )}
        cw-id={cwId}
        onClick={onClick}
      >
        {prefix ?? ""}
        {fullName[0]?.toUpperCase()}
      </Avatar>{" "}
    </Badge>
  );

  if (hideTooltip) {
    return badge;
  } else {
    return <Tooltip title={toolTipContent || fullName}>{badge}</Tooltip>;
  }
}
