import classNames from "classnames";
import { nanoid } from "nanoid";
import React, { ComponentProps, PropsWithChildren, forwardRef, useMemo } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { CwIdProps } from "../types/cw-id";
import { SvgIconComponent } from "./Icons";
import { Loader } from "./Loader";

type FieldSize = "small" | "medium" | "large" | "xlarge";

const SIZE_CLASSES_HASH: { [v in FieldSize]: string } = {
  small: "cw-min-h-[32px] cw-text-12 cw-font-normal cw-leading-tight",
  medium: "cw-min-h-[36px] cw-text-14 cw-font-normal cw-leading-tight",
  large: "cw-min-h-[40px] cw-text-14 cw-font-normal cw-leading-normal",
  xlarge: "cw-min-h-[48px] cw-text-20 cw-font-bold cw-leading-normal",
};

interface Props extends Omit<ComponentProps<typeof TextareaAutosize>, "className">, CwIdProps {
  loading?: boolean;
  startIcon?: SvgIconComponent;
  label?: string;
  fieldSize?: FieldSize;
  error?: boolean;
  errorMessage?: string | null;
  fullWidth?: boolean;
  chat?: boolean;
  inline?: boolean;
  endIcon?: SvgIconComponent;
  borderless?: boolean;
  onIconClick?: () => void;
}

export const TextArea = forwardRef<HTMLTextAreaElement, PropsWithChildren<Props>>(
  function TextAreaWithRef(
    {
      fieldSize = "medium",
      label,
      startIcon: StartIcon,
      endIcon: EndIcon,
      loading,
      error = false,
      errorMessage,
      fullWidth = false,
      disabled = false,
      chat = false,
      inline = false,
      borderless = false,
      onIconClick,
      ...props
    },
    ref,
  ) {
    const id = useMemo(() => props.id ?? nanoid(), [props.id]);
    const errorId = useMemo(() => nanoid(), []);

    return (
      <div
        className={classNames("cw-inline-flex cw-flex-col cw-gap-1 cw-flex-1", {
          "cw-w-full": fullWidth,
        })}
      >
        {label && (
          <label htmlFor={id} className="cw-font-body cw-text-14 cw-text-default cw-leading-none">
            {label}
          </label>
        )}
        <div className="cw-relative cw-flex cw-flex-col">
          {StartIcon && (
            <div
              className="cw-flex cw-items-start cw-h-5 cw-absolute cw-left-2 cw-top-2"
              onClick={onIconClick}
            >
              <StartIcon className="cw-w-5 cw-h-5" />
            </div>
          )}
          {loading && (
            <div className="cw-flex cw-items-start cw-h-5 cw-absolute cw-right-2 cw-top-2 cw-text-subtle">
              <Loader sentiment="neutral" size={20} />
            </div>
          )}
          <TextareaAutosize
            {...props}
            ref={ref}
            disabled={disabled}
            id={id}
            aria-invalid={error}
            aria-errormessage={error ? errorId : undefined}
            className={classNames(
              SIZE_CLASSES_HASH[fieldSize],
              "cw-box-border",
              "cw-py-2 cw-resize-none",
              borderless ? "cw-border cw-border-transparent" : "cw-border cw-border-solid",
              "cw-font-body",
              "cw-transition",
              borderless ? "cw-outline-none" : "cw-outline-1",
              {
                "cw-flex-grow": fullWidth,
                "cw-pl-8": !!StartIcon,
                "cw-pr-8": !!loading || !!EndIcon,
                "cw-bg-default cw-text-default placeholder:cw-text-subtle": !disabled,
                "cw-bg-default-disabled cw-text-default-disabled placeholder:cw-text-subtle-disabled cw-border-default-disabled cw-cursor-not-allowed": disabled,
                "cw-border-default hover:cw-border-default-hover focus:cw-border-brand-interactable focus:hover:cw-border-brand-interactable-hover focus-visible:cw-outline-brand-interactable focus:cw-outline-brand-interactable focus:hover:cw-outline-brand-interactable-hover":
                  !error && !disabled && !chat && !borderless,
                "cw-border-gray-600 hover:cw-border-default-hover focus:cw-border-brand-interactable focus:hover:cw-border-brand-interactable-hover focus-visible:cw-outline-brand-interactable focus:cw-outline-brand-interactable focus:hover:cw-outline-brand-interactable-hover":
                  !error && !disabled && chat && !borderless,
                "cw-bg-destructive cw-outline cw-border-destructive-interactable cw-outline-destructive-interactable hover:cw-border-destructive-interactable-hover hover:cw-outline-destructive-interactable-hover focus-visible:cw-outline-destructive-interactable-pressed focus:cw-outline-destructive-interactable-pressed":
                  error && !disabled,
                "cw-rounded cw-px-2": !chat && !inline && !borderless,
                "cw-rounded cw-px-1": !chat && inline && !borderless,
                "cw-rounded-[10px] cw-pl-[14px]": chat,
              },
            )}
            style={{ boxShadow: chat ? "2px 2px 0px #003F2E" : undefined }}
          />
          {!loading && EndIcon && (
            <div
              className={classNames("cw-flex cw-items-end cw-h-5 cw-absolute cw-right-2 cw-top-2", {
                "cw-cursor-pointer": !!onIconClick,
              })}
              onClick={onIconClick}
            >
              <EndIcon className="cw-w-5 cw-h-5" />
            </div>
          )}
          {error && errorMessage && (
            <div
              id={errorId}
              className="cw-text-destructive-muted cw-text-12 cw-font-body cw-mt-1 cw-max-w-full"
            >
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  },
);
