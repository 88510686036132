import * as ISchema from "#webapp/src/__schema__";

import React, { useEffect, useMemo, useState } from "react";
import { createRefetchContainer } from "react-relay";
import { CheckoutForm, ReceiptItems } from "./components";

import { CalloutBox } from "#webapp/src/components/callout-box";
import { OrgUserSelectForm } from "#webapp/src/components/org-user-select-form";
import { TeamJoinCreateDialog } from "#webapp/src/components/team-join-create-dialog";
import { PaymentConfirmation } from "../payment-confirmation";

import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { isTeamInPaidSubscription } from "#webapp/src/util/pricing.util";

import { salesEmail } from "#webapp/src/constants/site.constant";

import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { Link } from "@clockwise/design-system";
import { fg_destructive } from "@clockwise/design-system/tokens";
import { Warning } from "phosphor-react";
import { RelayRefetchProp } from "react-relay";
import { useCreateSubscription } from "./hooks/useCreateSubscription";
import { mixedCheckoutFormFragments, query } from "./MixedCheckoutForm.gql";
import { getCurrentOrgPersons, orgAlreadyHasOrgPlan } from "./MixedCheckoutForm.util";

// internal

// other

import { ControlData } from "@clockwise/web-commons/src/components/large-control-group";
import { isPromoCodeValid } from "./util/validatePromoCode";

const MAX_ORG_SEAT_SIZE = 150;
const MAX_TEAM_SEAT_SIZE = 50;
const MAX_ORG_SIZE_DEFAULT_TEAM = 50;

export enum CheckoutFormRoute {
  Main = "Main",
  ManagePayment = "ManagePayment",
  PaymentConfirmation = "PaymentConfirmation",
}

export interface ISeatPlanData extends ControlData {
  seatType: "org" | "team" | "individual";
}

export type SeatOptions = {
  org: ISeatPlanData;
  team: ISeatPlanData;
  individual: ISeatPlanData;
};

export interface IContainer {
  viewer: ISchema.IViewer;
  org: ISchema.IOrg;
  team: ISchema.ITeam | null;
  onChangeTeam?: (teamId: string) => void;
  billingRecurrence?: ISchema.SubscriptionInterval;
  onChangeBillingRecurrence?: (billingRecurrence: ISchema.SubscriptionInterval) => void;
  isDialog: boolean;
  startWithTeamSelected?: boolean;
}

export interface IProps extends IContainer {
  relay: RelayRefetchProp;
}

const MixedCheckoutFormComponent = ({
  org,
  viewer,
  team,
  onChangeTeam,
  billingRecurrence,
  onChangeBillingRecurrence,
  isDialog,
  startWithTeamSelected,
  relay,
}: IProps) => {
  // ~-~-~-~-~-~-~-
  // Init hooks
  // ~-~-~-~-~-~-~-

  useEffect(() => {
    track(TrackingEvents.CHECKOUT.EXPERIMENT_CHECKOUT_PAGE_TEAMS_DEFAULT);
  }, []);

  const [seatOptions, setSeatOptions] = useState<SeatOptions>({
    org: {
      seatType: "org",
      on: true,
      disabled: orgAlreadyHasOrgPlan(org) || false,
      hidden: false,
    },
    team: {
      seatType: "team",
      on: false,
      disabled: !!team && !!isTeamInPaidSubscription(org, team),
      hidden: false,
    },
    individual: {
      seatType: "individual",
      on: false,
      disabled: false,
      hidden: false,
    },
  });

  const [isTeamPlanSelected, setIsTeamPlanSelected] = useState(false);
  const [isIndividualPlanSelected, setIsIndividualPlanSelected] = useState(false);

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-

  const onOrgUserSelectConfirm = (
    newSelectedUserIds: { userId: string; isSuggested?: boolean }[],
  ) => {
    setSelectedUserIds(newSelectedUserIds);
  };

  const getSeatCount = () => {
    if (isTeamPlanSelected) {
      return (team && team.teamMembers && (team.teamMembers || []).length) || 0;
    }
    if (isIndividualPlanSelected) {
      return 1;
    }

    return selectedUserIds.length;
  };

  const minimumSeatCount = isIndividualPlanSelected || isTeamPlanSelected ? 1 : 5;

  const shouldFormSubmitBeDisabled = () => {
    const isTeamSubscriptionValid = !!(
      isTeamPlanSelected &&
      team &&
      !!isTeamInPaidSubscription(org, team)
    );

    return paying || paidSeatCount < minimumSeatCount || isTeamSubscriptionValid;
  };

  // ~-~-~-~-~-~-~-
  // State
  // ~-~-~-~-~-~-~-
  const [route, setRoute] = useState(CheckoutFormRoute.Main);

  const [selectedBillingRecurrence, setSelectedBillingRecurrence] = useState(
    billingRecurrence ? billingRecurrence : ISchema.SubscriptionInterval.Yearly,
  );

  const defaultUserIds = getCurrentOrgPersons(org).map((aop) => ({ userId: aop.userId }));
  const [selectedUserIds, setSelectedUserIds] = useState<
    { userId: string; isSuggested?: boolean }[]
  >(defaultUserIds);

  const numSeats = getSeatCount();
  const [paidSeatCount, setPaidSeatCount] = useState(
    numSeats >= minimumSeatCount ? numSeats : minimumSeatCount,
  );

  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [teamModalAction, setTeamModalAction] = useState<"select" | "join">("join");

  const [orgUserSelectModal, setOrgUserSelectModal] = useState(false);

  const currentUserInfo = useMemo(() => {
    const currentOrgPersons = getCurrentOrgPersons(org);
    const currentUser = currentOrgPersons.filter((person: ISchema.IOrgPerson) => person.isYou)[0];
    if (currentUser.personId && currentUser.primaryCalendarId) {
      return currentUser;
    } else {
      return null;
    }
  }, [org]);

  const {
    createSubscription,
    paying,
    checkoutError,
    stripeError,
    setBillingAddress,
    billingAddress,
  } = useCreateSubscription({
    org,
    team,
    selectedBillingRecurrence,
    paidSeatCount,
    currentUserInfo,
    selectedUserIds,
    setRoute,
  });

  // ~-~-~-~-~-~-~-
  // Lifecycle
  // ~-~-~-~-~-~-~-
  useEffect(() => {
    if (getSeatCount() > MAX_ORG_SEAT_SIZE) {
      track(TrackingEvents.CHECKOUT.PAID_SEAT_COUNT_ABOVE_THRESHOLD);
    }

    const currentOrgPersonsCount = getCurrentOrgPersons(org)?.length || 0;

    const shouldTeamBePreselectedByDefault =
      currentOrgPersonsCount > MAX_ORG_SIZE_DEFAULT_TEAM || orgAlreadyHasOrgPlan(org);

    const newSeatOptions = { ...seatOptions };

    // Set Team or Individual Selection as default for organizations with over 50 persons
    if (shouldTeamBePreselectedByDefault || startWithTeamSelected) {
      newSeatOptions.org.on = false;
      if (team) {
        // if there is a team, default to teams control
        newSeatOptions.team.on = true;
        newSeatOptions.individual.on = false;
      } else {
        // if there is no team, default to individual control
        newSeatOptions.team.on = false;
        newSeatOptions.individual.on = true;
      }
      // if there is an org plan, hide iniidivual
      if (orgAlreadyHasOrgPlan(org)) {
        newSeatOptions.individual.hidden = true;
      }

      setSeatOptions(newSeatOptions);
    }
  }, [org]);

  useEffect(() => {
    // set how many seats have been selected based on new team
    const seatCount = getSeatCount();
    setPaidSeatCount(seatCount >= minimumSeatCount ? seatCount : minimumSeatCount);

    // If there is already a paid team, disable Team selection
    const newSeatOptions = { ...seatOptions };
    newSeatOptions.team.disabled = !!team && !!isTeamInPaidSubscription(org, team);
    // If no team hide the team option
    newSeatOptions.team.hidden = !team;
    // if team subscription already hide inidiividual selector
    newSeatOptions.individual.hidden = !!team && !!isTeamInPaidSubscription(org, team);
    setSeatOptions(newSeatOptions);
  }, [team]);

  useEffect(() => {
    const teamSeatTypeSelected = seatOptions.team.on;
    const individualSeatTypeSelected = seatOptions.individual.on;

    setIsTeamPlanSelected(teamSeatTypeSelected);
    setIsIndividualPlanSelected(individualSeatTypeSelected);
  }, [seatOptions]);

  useEffect(() => {
    const seatCount = getSeatCount();
    const minSeat = isTeamPlanSelected || isIndividualPlanSelected ? 1 : 5;
    setPaidSeatCount(seatCount >= minSeat ? seatCount : minSeat);
  }, [isTeamPlanSelected, isIndividualPlanSelected]);

  // ~-~-~-~-~-~-~-
  // Handlers
  // ~-~-~-~-~-~-~-
  const onChangeSeatPlanType = (_index: number, control: ISeatPlanData) => {
    if (!control.on) {
      return;
    }

    if (control.seatType === "org" && orgAlreadyHasOrgPlan(org)) {
      return;
    }

    switch (control.seatType) {
      case "org":
        track(TrackingEvents.CHECKOUT.ORG_OPTION_SELECTED);
      case "team":
        track(TrackingEvents.CHECKOUT.TEAM_OPTION_SELECTED);
      case "individual":
        track(TrackingEvents.CHECKOUT.INDIVIDUAL_OPTION_SELECTED);
    }

    const newOptions = { ...seatOptions };
    Object.keys(newOptions).forEach((key: keyof SeatOptions) => {
      if (key === control.seatType) {
        newOptions[key].on = true;
      } else {
        newOptions[key].on = false;
      }
    });
    setSeatOptions(newOptions);
  };

  const onCloseOrgUserSelectForm = () => {
    setOrgUserSelectModal(false);
    track(TrackingEvents.CHECKOUT.CLOSE_ORG_USER_SELECT_MODAL);
  };

  const onOpenOrgUserSelectForm = () => {
    setOrgUserSelectModal(true);
    track(TrackingEvents.CHECKOUT.OPEN_ORG_USER_SELECT_MODAL);
  };

  const onCloseTeamModal = (teamId?: string) => {
    setTeamModalOpen(false);

    if (teamId) {
      if (onChangeTeam) {
        onChangeTeam(teamId);
      }

      relay.refetch({ orgRelayId: org.id }, undefined, undefined, { force: true });
    }

    track(TrackingEvents.CHECKOUT.CLOSE_TEAM_JOIN_CREATE_MODAL, { teamId });
  };

  const createStripeSubscription = (promoCode?: string) => {
    const environment = getEnvironment();
    if (promoCode && isPromoCodeValid(promoCode, environment)) {
      void createSubscription(isTeamPlanSelected, isIndividualPlanSelected, promoCode);
    } else {
      void createSubscription(isTeamPlanSelected, isIndividualPlanSelected);
    }
  };

  const renderCheckoutFlow = () => {
    return (
      <div>
        <div className="cw-heading-3xl cw-mt-5 cw-mb-10">Upgrade to the Teams plan</div>
        {stripeError ? (
          <CalloutBox className="cw-flex cw-items-center cw-mb-4 cw-bg-destructive" error>
            <Warning size={18} color={fg_destructive} className="cw-mr-2" />
            <span>
              {stripeError}. If the issue persists, please contact{" "}
              <Link target="_blank" href={`mailto:${salesEmail}`}>
                {salesEmail}
              </Link>
            </span>
          </CalloutBox>
        ) : null}
        {checkoutError ? (
          <CalloutBox className="cw-flex cw-items-center cw-mb-4 cw-bg-destructive" error>
            <Warning size={18} color={fg_destructive} className="cw-mr-2" />
            <span>
              {checkoutError}. If the issue persists, please contact{" "}
              <Link target="_blank" href={`mailto:${salesEmail}`}>
                {salesEmail}
              </Link>
            </span>
          </CalloutBox>
        ) : null}
        <div className="cw-flex cw-justify-around cw-items-baseline">
          <CheckoutForm
            org={org}
            team={team}
            onOpenOrgUserSelectForm={onOpenOrgUserSelectForm}
            setTeamModalOpen={setTeamModalOpen}
            setTeamModalAction={setTeamModalAction}
            onChangeSeatPlanType={onChangeSeatPlanType}
            seatOptions={seatOptions}
            selectedBillingRecurrence={selectedBillingRecurrence}
            setSelectedBillingRecurrence={setSelectedBillingRecurrence}
            onChangeBillingRecurrenceFromParent={onChangeBillingRecurrence}
            billingAddress={billingAddress}
            setBillingAddress={setBillingAddress}
          />
          <ReceiptItems
            selectedBillingRecurrence={selectedBillingRecurrence}
            isTeamPlanSelected={isTeamPlanSelected || isIndividualPlanSelected}
            maxOrgSeatSize={MAX_ORG_SEAT_SIZE}
            maxTeamSeatSize={MAX_TEAM_SEAT_SIZE}
            getSeatCount={getSeatCount}
            createSubscription={createStripeSubscription}
            submitDisabled={shouldFormSubmitBeDisabled()}
            setPaidSeatCount={setPaidSeatCount}
            paidSeatCount={paidSeatCount}
            minimumSeatCount={minimumSeatCount}
            isLoading={paying}
          />
          <div>
            <OrgUserSelectForm
              viewer={viewer}
              org={org}
              showCurrentUser={true}
              onConfirm={onOrgUserSelectConfirm}
              selectedUserIds={selectedUserIds}
              isDialog
              open={orgUserSelectModal}
              onRequestClose={onCloseOrgUserSelectForm}
              viewOnly
            />
          </div>
          <TeamJoinCreateDialog
            open={teamModalOpen}
            onClose={onCloseTeamModal}
            org={org}
            forcedInitialView={teamModalAction === "select" ? "select" : "create"}
            mode="checkout"
            selectMode={teamModalAction === "select"}
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    switch (route) {
      case CheckoutFormRoute.PaymentConfirmation:
        return <PaymentConfirmation isDialog={isDialog} />;
      case CheckoutFormRoute.Main:
      default:
        return renderCheckoutFlow();
    }
  };

  return (
    <div className="cw-flex-1 cw-flex cw-flex-col cw-items-center cw-justify-start cw-w-full cw-bg-default">
      <div className="cw-flex cw-flex-col cw-justify-center cw-items-center cw-max-w-[1250px] cw-pt-8 cw-pb-16 cw-px-32">
        {renderContent()}
      </div>
    </div>
  );
};

///////////
// Relay //
///////////

export const MixedCheckoutForm = createRefetchContainer<IContainer>(
  MixedCheckoutFormComponent,
  mixedCheckoutFormFragments,
  query,
);
