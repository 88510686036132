import { DiffActionTypeEnum, TransparencyEnum } from "@clockwise/schema";
import { ResponseStatusEnum, TradeoffType } from "@clockwise/schema/v2";
import { EventType } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { Interval } from "luxon";
import {
  ModifiedPlannerEventCardDiff,
  PlannerEventCard,
  PlannerEventCardsByDay,
} from "../../web-app-calendar/types";
import { useCurrentProposal } from "../CurrentProposalContext";
import { usePersistedProposal } from "../PersistedProposalContext";

const useCurrentProposalCards = (): {
  cardsByDay: PlannerEventCardsByDay;
  modifiedCardDiffs: ModifiedPlannerEventCardDiff[];
  isScheduleByMoving: boolean;
  error: null;
  loading: boolean;
} | null => {
  const { currentProposal } = useCurrentProposal();
  const { tradeoffBlocks } = usePersistedProposal();
  const zone = getRenderTimeZone();

  const currentProposalTime = Interval.fromDateTimes(
    currentProposal.startTime,
    currentProposal.endTime,
  );
  // If expanding an existing event but not yet changing its time, we don't need to show the proposal
  const proposalTimeUnchanged =
    !!currentProposal.eventTimeInterval &&
    currentProposalTime.equals(currentProposal.eventTimeInterval);
  let proposalType = DiffActionTypeEnum.ADD;
  if (currentProposal.eventId) {
    proposalType = DiffActionTypeEnum.RESCHEDULE;
  } else if (proposalTimeUnchanged) {
    proposalType = DiffActionTypeEnum.UNCHANGED;
  }

  if (!currentProposal.initialized) {
    return null;
  }

  const proposalAttendeeIds = currentProposal.attendees.map((a) => a.person.email);

  const cardsByDay: PlannerEventCardsByDay = {};

  // transform current proposal into a PlannerEventCard
  const plannerEventCard: PlannerEventCard = {
    active: false,
    annotaion: undefined,
    deemphasis: false,
    externalEventId: currentProposal.eventId,
    interval: currentProposalTime,
    updatedTimeInterval: proposalTimeUnchanged ? undefined : currentProposalTime,
    currentTimeInterval: proposalTimeUnchanged ? undefined : currentProposal.eventTimeInterval,
    proposedResponseState: undefined,
    isAllDay: currentProposal.allDay,
    isCancelled: false,
    smartHold: null, // Only calendar Events need this UI
    diffSummaryId: "", // what does this do
    key: `${currentProposal.title}-${currentProposal.startTime.toISO()}`,
    proposalType,
    suppressNewBadge: true,
    responseState: "Proposed",
    text: currentProposal.title,
    calendarIds: [],
    attendeesCalendarIds: [], // Intentionally empty so that the event stripes are not shown
    affectedAttendees: [],
    attendees: currentProposal.attendees.map((a) => ({
      __typename: "Attendee",
      id: a.id,
      email: a.person.email,
      responseStatus: a.person.isMe ? ResponseStatusEnum.Accepted : ResponseStatusEnum.NeedsAction,
    })),
    locked: false,
    transparency: TransparencyEnum.None,
    videoLink: null,
    eventPermissions: {
      canInviteOthers: false,
      canModify: false,
      canDelete: false,
      canRemove: false,
      canRSVP: false,
      canSeeOtherGuests: false,
    },
    flexStatus: null,
    attendeeCount: currentProposal.attendees.length,
    proposalMetaData: {
      // Used to determine the width of the proposal event in calendar day view
      // Attendees are set on a proposal only once we display a proposal card and
      // fetch member info from the BE.
      // Here we know the initial attendees from the proposal as well as any others added
      // manually by the user
      attendeeIds:
        proposalAttendeeIds.length > 0 ? proposalAttendeeIds : currentProposal.initialAttendeeIds,
      // Used to determine if a proposal should be rendered in calendar day view
      isConsequence: false,
    },
    type: EventType.Event, // what does this do
  };

  cardsByDay[plannerEventCard.interval.start.toISODate()] = [plannerEventCard];

  // transform tradeoffs into a PlannerEventCard
  const fixableConflictsBlocks = tradeoffBlocks?.filter(
    (b) => b.tradeoffType === TradeoffType.FixableConflict,
  );
  const fixableConflicts = fixableConflictsBlocks?.flatMap((b) => b.tradeoffs) ?? [];

  fixableConflicts.forEach((t) => {
    let renderInterval: string | null = null;
    let calendarId = "";

    if (
      !t.diffId ||
      t.updatedTime?.__typename !== "DateTimeRange" ||
      t.event?.dateOrTimeRange?.__typename !== "DateTimeRange" ||
      !t.event?.externalEventId
    ) {
      return;
    }

    renderInterval = t.updatedTime.dateTimeRange;
    const affectsOtherAttendees = t.attendees.every((a) => !a.person.isMe);
    calendarId = affectsOtherAttendees ? t.attendees[0].person.email : "";

    if (!renderInterval) {
      return;
    }

    const date = Interval.fromISO(renderInterval).start.toISODate();

    if (!cardsByDay[date]) {
      cardsByDay[date] = [];
    }

    cardsByDay[date].push({
      active: true,
      annotaion: undefined,
      deemphasis: false,
      externalEventId: t.event.externalEventId,
      interval: Interval.fromISO(renderInterval, { zone }),
      updatedTimeInterval: Interval.fromISO(t.updatedTime.dateTimeRange, { zone }),
      currentTimeInterval: Interval.fromISO(t.event.dateOrTimeRange.dateTimeRange, { zone }),
      proposedResponseState: undefined,
      isAllDay: false,
      isCancelled: false,
      smartHold: null, // Only calendar Events need this UI
      diffSummaryId: t.diffId, // what does this do
      key: t.diffId,
      proposalType: DiffActionTypeEnum.RESCHEDULE,
      suppressNewBadge: true,
      responseState: "Proposed",
      text: t.event.title,
      calendarIds: [calendarId],
      attendeesCalendarIds: [], // Intentionally empty so that the event stripes are not shown
      // Used to render a badge with the attendee's avatar for SBMs
      affectedAttendees: affectsOtherAttendees
        ? t.attendees.map((a) => ({
            __typename: "AffectedPerson",
            id: a.person.email,
            calendarId: a.person.email,
            profile: {
              givenName: a.person.givenName,
              familyName: a.person.familyName,
              externalImageUrl: a.person.externalImageUrl,
            },
            person: {
              primaryCalendarId: a.person.email,
            },
          }))
        : [],
      attendees: [],
      locked: false,
      transparency: TransparencyEnum.None,
      videoLink: null,
      eventPermissions: {
        canInviteOthers: false,
        canModify: false,
        canDelete: false,
        canRemove: false,
        canRSVP: false,
        canSeeOtherGuests: false,
      },
      flexStatus: null,
      attendeeCount: affectsOtherAttendees ? t.attendees.length : 0,
      proposalMetaData: {
        attendeeIds: affectsOtherAttendees ? t.attendees.map((a) => a.person.email) : [],
        // Used to determine if a proposal should be rendered in calendar day view
        isConsequence: true,
      },
      type: EventType.Event, // what does this do
    });
  });

  return {
    cardsByDay,
    modifiedCardDiffs: fixableConflicts.map((t) => ({
      externalEventId: t.diffId ?? "",
      text: t.title,
    })),
    isScheduleByMoving: fixableConflicts.length > 0,
    error: null,
    loading: false,
  };
};

export default useCurrentProposalCards;
