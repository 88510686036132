import * as React from "react";
import { ProBadge } from "../pro-badge/pro-badge";
import { LegacyTooltip } from "../tooltip";
import { colorClasses, TrialProBadgeColors } from "./trial-pro-badge-with-tooltip-colors";
import { copy } from "./trial-pro-badge-with-tooltip-copy";

export interface TrialProBadgeWithTooltipProps {
  tooltipType?: TrialProBadgeType;
  showToolTip: boolean;
  daysLeftOnTrial?: number;
  placement?: "top" | "left" | "right" | "bottom";
  width?: number;
  colorOption?: TrialProBadgeColors;
  badgeText?: string;
}

export enum TrialProBadgeType {
  FocusTime = "FocusTime",
  FlexMeeting = "FlexMeeting",
  FocusTimeEnabled = "FocusTimeEnabled",
  FlexMeetingEnabled = "FlexMeetingEnabled",
  FlexMeetingExpired = "FlexMeetingExpired",
  FocusTimeSettings = "FocusTimeSettings",
  GroupLinksEnabled = "GroupLinksEnabled",
  GroupLinksExpired = "GroupLinksExpired",
  TeamAvailabilityEnabled = "TeamAvailabilityEnabled",
  TeamAvailabilityExpired = "TeamAvailabilityExpired",
  NMDEnabled = "NMDEnabled",
  NMDExpired = "NMDExpired",
  OrganizerPaidUserFree = "OrganizerPaidUserFree",
  AIBeta = "AIBeta",
  PrismBeta = "PrismBeta",
}

type BadgeWrapperProps = {
  colorOption: TrialProBadgeColors;
  badgeText?: string;
} & Record<string, unknown>;

const ProBadgeWrapper = React.forwardRef<HTMLDivElement, BadgeWrapperProps>((props, ref) => {
  return (
    <div ref={ref} className="cw-inline-block">
      <ProBadge text={props.badgeText} className={colorClasses[props.colorOption]} />
    </div>
  );
});

ProBadgeWrapper.displayName = "ProBadgeWrapper";

export const TrialProBadgeWithTooltip = ({
  tooltipType,
  showToolTip,
  daysLeftOnTrial,
  placement,
  width,
  colorOption = "blue",
  badgeText,
}: TrialProBadgeWithTooltipProps) => {
  let header = "";
  let body = "";
  if (showToolTip && !!tooltipType) {
    header = copy(daysLeftOnTrial || 0)[tooltipType].header;
    body = copy(daysLeftOnTrial || 0)[tooltipType].text;
  }

  return showToolTip && tooltipType ? (
    <LegacyTooltip
      showArrow
      placement={placement || "bottom"}
      width={width}
      header={header}
      body={body}
    >
      <div className="cw-inline-block">
        <ProBadge text={badgeText} className={colorClasses[colorOption]} />
      </div>
    </LegacyTooltip>
  ) : (
    <ProBadge text={badgeText} className={colorClasses[colorOption]} />
  );
};
