import { getFullName } from "#clockwise/web-commons/src/util/profile.util";
import { Tooltip } from "@clockwise/design-system";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { GroupSchedulingStrategyEnum, LocationTypeEnum } from "@clockwise/schema/v2";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { animated, config, useSpring, useTransition } from "@react-spring/web";
import classNames from "classnames";
import { DateTime, IANAZone, Interval } from "luxon";
import { Moment } from "moment";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useSwipeable } from "react-swipeable";
import { narrowCutoff, schedulingLinkUseStyles } from ".";
import { LogoHorizontal } from "../../components/logo";
import { TrackingEvents, useTrackOnce, useTracking } from "../../util/analytics.util";
import { asMinutes } from "../../util/date.util";
import { formatDuration } from "../../util/duration.util";
import { Profile } from "../../util/profile.util";
import { useWindowSize } from "../../util/react.util";
import { getFormattedDate, getSchedulingLinkUrl } from "../../util/scheduling.util";
import { Notification } from "../Notification";
import { BookingModal } from "../booking-modal";
import { ConfirmPayload } from "../booking-modal/booking-modal";
import { ConfirmationType, SchedulingConfirmation } from "../scheduling-confirmation";
import { OwnerBanner } from "../scheduling-links/OwnerBanner";
import { TimeUnavailableModal } from "../time-unavailable-modal/TimeUnavailableModal";
import { TimeZoneDisplayMode, TimeZonePicker } from "../time-zone-picker";
import { MAX_DAYS_TO_RENDER, TOTAL_DAY_WIDTH, TimesForDays } from "../times-for-days";
import { CurrentBookingDetails } from "./CurrentBookingDetails";
import { MeetingDetails } from "./MeetingDetails";
import { MeetingTitle } from "./MeetingTitle";
import {
  AttendeeQuestion,
  AttendeeResponseToQuestion,
  BookedSlot,
  Booking,
  DateString,
  LinkMember,
  LinkTimeSlot,
} from "./types";
import { groupTimeSlotsByDate, isBad } from "./utils";

interface SchedulingLinkProps {
  id: string;
  linkName: string;
  slug: string;
  logoUrl?: string | null;
  meetingName: string;
  duration: number;
  durations: number[];
  description: string | null;
  locationType: LocationTypeEnum | null;
  linkMembers: LinkMember[];
  attendeeQuestions: AttendeeQuestion[];
  timeSlots: LinkTimeSlot[];
  timeZone: string;
  onTimeZoneChange: (timeZone: string) => void;
  setCurrentDate: Dispatch<SetStateAction<DateString>>;
  onMonthChange: Dispatch<SetStateAction<DateString>>;
  currentDate: DateString | null;
  loading: boolean;
  existingBooking?: Booking | null;
  onBook?: (
    attendeeName: string,
    attendeeEmailAddress: string,
    selectedTime: Interval,
    attendeeResponsesToQuestions: AttendeeResponseToQuestion[],
    additionalAttendeeEmails: string[],
    duration: number,
  ) => void;
  onReschedule?: (reason: string, selectedTime: Interval) => void;
  bookingLoading: boolean;
  bookingError?: string | null;
  clearBookingError: (date: string) => void | Promise<void>;
  bookedSlot: BookedSlot | null;
  trackSession: (event: string, data?: Record<string, string | object | number | boolean>) => void;
  highlightBestTimes: boolean;
  endOfAvailability: string;
  isOwner: boolean;
  isMember: boolean;
  onClickMyLinks: () => void;
  onEditLink: () => void;
  banner: React.ReactNode;
  ownerProfile: Profile | null | undefined;
  allowAdditionalAttendees: boolean;
  onDurationChange: (duration: number) => void;
  groupSchedulingStrategy: GroupSchedulingStrategyEnum | null;
  initDateTime: DateTime;
  initialName: string;
  initialEmail: string;
}

// This is of course super basic, but its used a couple places in this component
// so wanted to have it in one location for clarity.
const getDateKey = (date: Moment): DateString => date.format("YYYY-MM-DD");

export const SchedulingLink = ({
  id,
  linkName,
  slug,
  logoUrl,
  meetingName,
  duration,
  durations,
  description,
  locationType,
  linkMembers,
  attendeeQuestions,
  timeSlots,
  timeZone,
  onTimeZoneChange,
  setCurrentDate,
  onMonthChange,
  currentDate,
  loading,
  existingBooking,
  onBook,
  onReschedule,
  bookedSlot,
  bookingLoading,
  bookingError,
  clearBookingError,
  trackSession,
  highlightBestTimes,
  endOfAvailability,
  isOwner,
  isMember,
  onClickMyLinks,
  onEditLink,
  banner,
  ownerProfile,
  allowAdditionalAttendees,
  onDurationChange,
  groupSchedulingStrategy,
  initDateTime,
  initialName,
  initialEmail,
}: SchedulingLinkProps) => {
  const showOwnerBanner = isMember || isOwner;
  const ownerName = getFullName(ownerProfile) || "This user";

  const linkUrl = useMemo(() => getSchedulingLinkUrl(linkName, slug), [linkName, slug]);
  const classes = schedulingLinkUseStyles();
  const [selectedTime, setSelectedTimeRaw] = useState<Interval | undefined>(undefined);
  const setSelectedTime = useCallback((time: Interval | undefined) => {
    setSelectedTimeRaw(time);
    const state = "book";
    if (time) {
      history.pushState(state, "", window.location.href);
    } else {
      if (history.state === state) {
        history.back();
      }
    }
  }, []);
  const [unavailableSlot, setUnavailableSlot] = useState<Interval | undefined>(undefined);
  const [fromParams, setFromParams] = useState(false);
  const { width: windowWidth } = useWindowSize();
  const initialLoad = React.useRef(false);
  const [attendeeEmail, setAttendeeEmail] = useState("");
  const [additionalAttendeeEmails, setAdditionalAttendeeEmails] = useState<string[]>([]);

  const trackEvent = useTracking();
  const trackViewOnce = useTrackOnce(TrackingEvents.LINKS.BOOKING.VIEW);
  const [trackedNoTimes, setTrackedNoTimes] = useState(false);

  const isNarrow = windowWidth < narrowCutoff;

  useEffect(() => {
    if (!loading && timeSlots) {
      trackViewOnce({
        meetingSettingsId: id,
        nLinkMembers: linkMembers.length,
        nTimeSlotsDisplayed: timeSlots.length,
      });
    }
  }, [trackViewOnce, loading, id, linkMembers.length, timeSlots]);

  useEffect(() => {
    if (initDateTime && !loading && !initialLoad.current) {
      // we only want to run this once when the page loads
      initialLoad.current = true;

      if (!initDateTime.isValid) {
        return;
      }

      const selectedTimeSlot = timeSlots.find(
        (slot) => Interval.fromISO(slot.timeSlot).start.toISO() === initDateTime.toISO(),
      );

      if (selectedTimeSlot) {
        setSelectedTime(Interval.fromISO(selectedTimeSlot.timeSlot));
        setFromParams(true);
        trackEvent(TrackingEvents.LINKS.COPY_TIMES.OPENED_FROM_TIME, {
          dateTime: initDateTime.toISO(),
        });
      } else {
        setUnavailableSlot(
          Interval.fromDateTimes(initDateTime, initDateTime.plus({ minutes: duration })),
        );

        trackEvent(TrackingEvents.LINKS.COPY_TIMES.UNAVAILABLE_TIME, {
          dateTime: initDateTime.toISO(),
        });
      }
    }
  }, [initDateTime, duration, loading, setSelectedTime, timeSlots, timeZone, trackEvent]);

  const onDateChange = useCallback(
    (dateChange: MaterialUiPickersDate) => {
      if (!dateChange || loading) {
        return;
      }

      setCurrentDate(getDateKey(dateChange));
    },
    [loading, setCurrentDate],
  );

  const onDatePickerMonthChange = useCallback(
    (date: MaterialUiPickersDate) => {
      if (!date) {
        return;
      }
      onMonthChange(getDateKey(date));
    },
    [onMonthChange],
  );

  const onClose = useCallback(() => {
    if (selectedTime) {
      void clearBookingError(selectedTime.start.toISO());
    }
    setSelectedTime(undefined);
    setUnavailableSlot(undefined);
    setFromParams(false);
  }, [selectedTime, setSelectedTime, clearBookingError]);

  useEffect(() => {
    window.onpopstate = (_: PopStateEvent) => {
      if (bookedSlot) {
        window.location.href = linkUrl;
      } else {
        // Close booking modal
        onClose();
      }
    };

    return () => {
      window.onpopstate = null;
    };
  }, [bookedSlot, linkUrl, onClose]);

  // track details of booked event
  useEffect(() => {
    if (bookedSlot) {
      const bookedSlotTag =
        timeSlots
          .find(
            (slot) =>
              Interval.fromISO(slot.timeSlot).start ===
              DateTime.fromISO(bookedSlot.bookedDateOrDateTime),
          )
          ?.tag?.toLowerCase() ?? "normal";

      trackSession(TrackingEvents.LINK_SCHEDULER.BOOKED, {
        slotConvenience: bookedSlotTag,
        dateOrDateTime: bookedSlot.bookedDateOrDateTime,
        fromParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookedSlot]);

  // Generate object indicating which days on the calendar have times,
  // and which have nothing but "bad" times.
  const daysWithTimes = groupTimeSlotsByDate(timeSlots).reduce<
    Record<string, { hasTimes: boolean; allBadTimes: boolean }>
  >((acc, slot) => {
    if (slot.times.length) {
      acc[slot.date] = {
        hasTimes: true,
        allBadTimes: slot.times.every(isBad),
      };
    }
    return acc;
  }, {});

  // Allows for customizing the render of each day on the calendar.
  // Using this to allow changing the color based on the availability, as well
  // as settings a tooltip.
  const renderDay = useCallback(
    (
      date: MaterialUiPickersDate,
      selectedDate: MaterialUiPickersDate,
      _: boolean, // unused
      dayComponent: JSX.Element,
    ) => {
      if (!date || !selectedDate) {
        return dayComponent;
      }

      const availableTimes = daysWithTimes[getDateKey(date)];

      const wrapperClassName = classNames([
        {
          [classes.hasTimes]: availableTimes?.hasTimes,
          [classes.hasNoTimes]: !availableTimes?.hasTimes,
        },
      ]);

      const printableDate = getFormattedDate(date.toDate(), true, true);
      if (isNarrow) {
        return (
          <div className={wrapperClassName} aria-label={"availability on" + printableDate}>
            {dayComponent}
          </div>
        );
      }

      let tooltipText = "Available";
      let ariaPrefix = "Availability";

      if (!availableTimes) {
        tooltipText = "Not available";
        ariaPrefix = "No " + ariaPrefix;
      }

      return (
        <Tooltip title={tooltipText} placement="top">
          <div className={wrapperClassName} aria-label={ariaPrefix + " " + printableDate}>
            {dayComponent}
          </div>
        </Tooltip>
      );
    },
    [classes.hasNoTimes, classes.hasTimes, daysWithTimes, isNarrow],
  );

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      const rightBtn = document.querySelectorAll<HTMLButtonElement>(
        ".MuiPickersCalendarHeader-switchHeader > button",
      )?.[1];
      if (rightBtn) {
        rightBtn.click?.();
      }
    },
    onSwipedRight: () => {
      const leftBtn = document.querySelectorAll<HTMLButtonElement>(
        ".MuiPickersCalendarHeader-switchHeader > button",
      )?.[0];
      if (leftBtn) {
        leftBtn.click?.();
      }
    },
  });

  const shouldCenterView = windowWidth > 1100;

  const meetingDurationChanged = !!existingBooking && duration !== asMinutes(existingBooking);

  // React-spring useTransition needs this array of stuff to animate this stuff in, so I put it in a
  // memo to be more efficient.
  const leftColumnContent = useMemo(
    () => [
      existingBooking ? (
        <div key="current-booking-details" className="cw-flex cw-flex-col cw-gap-2">
          <MeetingTitle
            linkMembers={linkMembers}
            attendees={existingBooking?.attendees}
            isOwner={isOwner}
            isReschedule
            isRoundRobin={
              groupSchedulingStrategy === GroupSchedulingStrategyEnum.RoundRobinMaxAvailability
            }
          />
          {meetingDurationChanged && (
            <Notification sentiment="info">
              This booking link was edited. If you reschedule this meeting, the new duration will be{" "}
              <strong className="cw-font-bold">
                {formatDuration(duration, {
                  unitDisplay: "short",
                })}
              </strong>
              .
            </Notification>
          )}
          <CurrentBookingDetails booking={existingBooking} timeZone={timeZone} />
        </div>
      ) : (
        <div key="meeting-details">
          <MeetingDetails
            linkMembers={linkMembers}
            isOwner={isOwner}
            meetingName={meetingName}
            description={description}
            duration={duration}
            durations={durations}
            onDurationChange={onDurationChange}
            locationType={locationType}
            isRoundRobin={
              groupSchedulingStrategy === GroupSchedulingStrategyEnum.RoundRobinMaxAvailability
            }
          />
        </div>
      ),
      <div key="loading-indicator" className="cw-flex cw-items-center cw-pl-1">
        <Loader
          size={18}
          sentiment="positive"
          className={loading ? "cw-visible" : "cw-invisible"}
        />
      </div>,
      <div className="-cw-ml-[19px] -cw-mt-4" key="calendar">
        <div className={classes.calendarHeader} {...swipeHandlers} key="date-picker">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              variant="static"
              value={currentDate}
              onChange={onDateChange}
              onMonthChange={onDatePickerMonthChange}
              renderDay={renderDay}
              disablePast
              disableToolbar
              maxDate={DateTime.fromISO(endOfAvailability).toJSDate()}
              shouldDisableDate={(date) =>
                !!date &&
                (DateTime.fromISO(date.toISOString()) >
                  DateTime.fromISO(endOfAvailability).endOf("day") ||
                  !daysWithTimes[getDateKey(date)])
              }
              fullWidth
              id="scheduling-link-date-picker"
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="cw-ml-3 md:cw-ml-4 cw-my-3">
          <TimeZonePicker
            className="cw-w-full"
            value={timeZone}
            onChange={onTimeZoneChange}
            timeZoneDisplayMode={TimeZoneDisplayMode.JustPicker}
          />
        </div>
      </div>,
    ],
    [
      existingBooking,
      linkMembers,
      isOwner,
      meetingDurationChanged,
      duration,
      durations,
      onDurationChange,
      timeZone,
      onTimeZoneChange,
      meetingName,
      description,
      locationType,
      classes.calendarHeader,
      loading,
      swipeHandlers,
      currentDate,
      onDateChange,
      onDatePickerMonthChange,
      renderDay,
      endOfAvailability,
      daysWithTimes,
    ],
  );

  const leftItemsTransition = useTransition(leftColumnContent, {
    trail: 120, // Delay between the daysToRender getting rendered.
    from: { opacity: 0 }, // The default values.
    enter: { opacity: 1 }, // The value that it animates to upon enter.
    keys: (item) => item.key ?? JSON.stringify(item.props).substr(0, 15),
  });

  const containerStyles = useSpring({
    opacity: selectedTime || unavailableSlot ? 0.15 : 1,
    config: { mass: 1, tension: 140, friction: 17 },
  });

  const { opacity: noTimesOpacity } = useSpring({
    from: { opacity: 0 }, // The default values.
    to: { opacity: 1 }, // The value that it animates to upon enter.
    delay: 500, // Increasing this delay to allow more time for all the
    // suggestions fetches to finishes. See comment on noTimesAvailable variable.
  });

  const maxWidthTwoCols = 942; // The maxWidth that shows two times per day columns.
  const minCenteredPadding = 125; // maxWidth will only increase if this amount of padding can be maintained.
  const centeredContentMaxWidth = useSpring({
    maxWidth: Math.max(
      maxWidthTwoCols,
      maxWidthTwoCols +
        Math.min(
          Math.floor((windowWidth - minCenteredPadding - maxWidthTwoCols) / TOTAL_DAY_WIDTH),
          MAX_DAYS_TO_RENDER - 2, // Number of additional columns to render after our two initial.
        ) *
          TOTAL_DAY_WIDTH,
    ), // This creates a stepwise increase in the max width, with maxWidthTwoCols as the minimum maxWidth.
    config: config.default,
  });

  const timeTaken = !!bookingError && bookingError.includes?.("slot no longer available");

  let errorMsg = bookingError ? "Please try again" : "";
  errorMsg = timeTaken ? "This time is no longer available" : errorMsg;

  const noTimesAvailable = !loading && timeSlots.length === 0;

  // FIXME (lsanwick) Determine if this is firing at unexpected times. It should only happen when
  // we've finished fetching all possible times and there are still no time slots.
  useEffect(() => {
    if (noTimesAvailable && !trackedNoTimes) {
      trackSession(TrackingEvents.LINK_SCHEDULER.NO_TIMES);
      setTrackedNoTimes(true);
    }
  }, [noTimesAvailable, trackSession, trackedNoTimes]);

  const onSelectTimeSlot = useCallback(
    (timeSlot: Interval | undefined) => {
      setSelectedTime(timeSlot);
      trackSession(TrackingEvents.LINKS.BOOKING.MODAL_VIEW, {
        startTime: timeSlot?.start.toISO() || "",
        duration,
        meetingSettingsId: id,
      });
    },
    [duration, id, setSelectedTime, trackSession],
  );

  // once a booking is created, render confirmation page
  if (bookedSlot) {
    if (existingBooking) {
      // Rescheduling case.
      return (
        <>
          <SchedulingConfirmation
            id={id}
            meetingName={meetingName}
            logoUrl={logoUrl}
            duration={duration}
            locationType={locationType}
            description={description}
            linkName={linkName}
            slug={slug}
            attendees={existingBooking.attendees}
            linkMembers={linkMembers}
            showOwnerBanner={showOwnerBanner}
            prevSelectedTime={Interval.fromISO(existingBooking.timeSlot).start.toISO()}
            bookedSlot={bookedSlot}
            variant={ConfirmationType.Reschedule}
            completed
            banner={banner}
            onEditLink={onEditLink}
            onClickMyLinks={onClickMyLinks}
          />
          {!(windowWidth < 1000) && (
            <div className="cw-flex cw-justify-center cw-mb-4">
              <LogoHorizontal style={{ width: 165 }} />
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          <SchedulingConfirmation
            id={id}
            meetingName={meetingName}
            logoUrl={logoUrl}
            duration={duration}
            locationType={locationType}
            description={description}
            linkMembers={linkMembers}
            linkName={linkName}
            attendees={[attendeeEmail, ...additionalAttendeeEmails]}
            slug={slug}
            showOwnerBanner={showOwnerBanner}
            bookedSlot={bookedSlot}
            variant={ConfirmationType.Book}
            completed
            banner={banner}
            onEditLink={onEditLink}
            onClickMyLinks={onClickMyLinks}
          />
          {!(windowWidth < 1000) && (
            <div className="cw-flex cw-justify-center cw-mb-4">
              <LogoHorizontal style={{ width: 165 }} />
            </div>
          )}
        </>
      );
    }
  }

  const onConfirmBooking = (payload: ConfirmPayload, selectedTime: Interval) => {
    switch (payload.action) {
      case "book":
        setAttendeeEmail(payload.attendeeEmailAddress);
        setAdditionalAttendeeEmails(payload.additionalAttendeeEmails);
        return onBook?.(
          payload.attendeeName,
          payload.attendeeEmailAddress,
          selectedTime,
          payload.attendeeResponsesToQuestions,
          payload.additionalAttendeeEmails,
          duration,
        );
      case "reschedule":
        return onReschedule?.(payload.reason, selectedTime);
    }
  };

  return (
    <div className="cw-overflow-auto cw-w-screen cw-h-[100dvh] cw-bg-default xs:cw-bg-neutral">
      <Helmet>
        <body className="xs:cw-bg-neutral" />
      </Helmet>
      <div className="cw-flex cw-flex-col cw-bg-default xs:cw-bg-neutral xs:cw-pt-4 xs:cw-px-3 cw-pb-6 cw-items-center xs:cw-min-w-[955px]">
        <animated.div
          className="cw-relative cw-flex cw-flex-col cw-gap-2 cw-w-full"
          style={{ ...(shouldCenterView ? centeredContentMaxWidth : {}), ...containerStyles }}
        >
          <OwnerBanner
            showOwnerBanner={showOwnerBanner}
            link={{
              linkUrl: getSchedulingLinkUrl(linkName, slug),
              meetingSettings: { id, slug },
            }}
            onClickMyLinks={onClickMyLinks}
            onEditLink={onEditLink}
          />
          <div className="cw-max-w-full cw-bg-default cw-ease-in-out cw-rounded-lg cw-p-2 cw-pb-6 cw-border-solid cw-border-0 xs:cw-border cw-border-neutral cw-min-h-[80vh] cw-flex cw-flex-col">
            <div
              className={classNames(
                "cw-flex cw-items-start cw-justify-start",
                "cw-flex-wrap md:cw-flex-nowrap",
                "cw-min-h-0 md:cw-min-h-[640px]",
                "cw-scrollbar-none md:cw-scrollbar-auto",
                "[&::-webkit-scrollbar]:cw-bg-transparent md:[&::-webkit-scrollbar]:cw-bg-[unset]",
                "[&::-webkit-scrollbar]:cw-w-0 md:[&::-webkit-scrollbar]:cw-w-[unset]",
              )}
              style={{ msOverflowStyle: isNarrow ? "none" : undefined }}
            >
              <div
                className={classNames(
                  classes.leftContent,
                  "cw-w-[330px]",
                  "cw-flex cw-flex-col cw-flex-shrink-0",
                  "cw-ml-8 cw-mt-6",
                  "cw-flex-grow-[1] md:cw-flex-grow-[unset]",
                  "cw-flex-shrink-[1] md:cw-flex-shrink-[unset]",
                  "cw-max-w-[98%] md:cw-max-w-[330px]",
                  "cw-gap-6 md:cw-gap-4",
                )}
                style={selectedTime ? { pointerEvents: "none" } : {}}
              >
                {logoUrl && (
                  <img
                    className="cw-max-w-[300px] cw-max-h-[150px] cw-object-contain cw-self-start"
                    src={logoUrl}
                    alt="logo"
                  />
                )}
                {leftItemsTransition(({ opacity }, item, _) => (
                  <animated.div style={{ opacity }}>{item}</animated.div>
                ))}
              </div>
              {noTimesAvailable ? (
                <div
                  className={classNames(
                    "cw-flex cw-items-center cw-justify-center",
                    "cw-flex-grow-[1]",
                    "cw-self-center",
                  )}
                >
                  <animated.div
                    className={classNames(
                      "cw-flex cw-items-center",
                      "cw-h-[230px] cw-w-[230px]",
                      "cw-max-w-[550px]",
                      "cw-bg-neutral",
                      "cw-rounded-full",
                      "cw-p-5",
                      "cw-m-[50px]",
                    )}
                    style={{ opacity: noTimesOpacity }}
                  >
                    <span
                      cw-id="link-no-times"
                      className={classNames(
                        "cw-text-center",
                        "cw-text-neutral",
                        "cw-text-base",
                        "cw-font-body",
                        "cw-overflow-hidden",
                        "cw-line-height-[140%]",
                      )}
                    >
                      {linkMembers.length > 1
                        ? "No times available to book"
                        : `${ownerName} has no times available to book`}
                    </span>
                  </animated.div>
                </div>
              ) : (
                <div
                  className={classNames(
                    "cw-flex cw-flex-col",
                    "cw-flex-grow-[2] cw-flex-shrink-[1]",
                    "cw-min-w-[250px]",
                    "cw-m-[38px_13px_20px_18px] md:cw-m-[24px_16px_20px_71px]",
                    "cw-max-h-[95%] md:cw-max-h-[70vh]",
                  )}
                  style={selectedTime ? { pointerEvents: "none" } : {}}
                >
                  <TimesForDays
                    timeSlots={timeSlots}
                    timeZone={IANAZone.create(timeZone)}
                    currentDate={currentDate}
                    onChangeDate={setCurrentDate}
                    onSelectTime={onSelectTimeSlot}
                    trackSession={trackSession}
                    loading={loading}
                    highlightBestTimes={highlightBestTimes}
                  />
                </div>
              )}
            </div>
          </div>
        </animated.div>
        <div className="cw-bg-default xs:cw-bg-neutral" />
        {unavailableSlot && (
          <TimeUnavailableModal
            slotTimeRange={unavailableSlot}
            timeZone={timeZone}
            title={meetingName}
            fullscreen={isNarrow}
            onClose={onClose}
          />
        )}
        {selectedTime && (
          <BookingModal
            loading={bookingLoading}
            meetingSettingsId={id}
            existingBooking={existingBooking}
            title={meetingName}
            duration={duration}
            slotTimeRange={selectedTime}
            timeZone={timeZone}
            onConfirm={timeTaken ? onClose : onConfirmBooking}
            onClose={onClose}
            errorMsg={errorMsg}
            fullscreen={isNarrow}
            timeTaken={timeTaken}
            attendeeCalendarIds={
              existingBooking?.attendees.map((a) => {
                const linkMember = linkMembers.find((lm) => lm.id === a);
                const optional = linkMember ? linkMember.required === false : false;
                return `${a}${optional ? " (optional)" : ""}`;
              }) ?? []
            }
            attendeeQuestions={attendeeQuestions}
            allowAdditionalAttendees={allowAdditionalAttendees}
            initialName={initialName}
            initialEmail={initialEmail}
          />
        )}
      </div>
    </div>
  );
};
