/**
 * @generated SignedSource<<4800eec0a17062d0a6b60f254994a138>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendM365TeamAvailabilityCalendarInvitesForTeamMutationInput = {
  clientMutationId?: string | null;
  teamId: string;
};
export type SendM365TeamAvailabilityCalendarInvitesForTeamMutation$variables = {
  input: SendM365TeamAvailabilityCalendarInvitesForTeamMutationInput;
};
export type SendM365TeamAvailabilityCalendarInvitesForTeamMutation$data = {
  readonly sendM365TeamAvailabilityCalendarInvitesForTeam: {
    readonly clientMutationId: string | null;
  } | null;
};
export type SendM365TeamAvailabilityCalendarInvitesForTeamMutation = {
  variables: SendM365TeamAvailabilityCalendarInvitesForTeamMutation$variables;
  response: SendM365TeamAvailabilityCalendarInvitesForTeamMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendM365TeamAvailabilityCalendarInvitesForTeamMutationPayload",
    "kind": "LinkedField",
    "name": "sendM365TeamAvailabilityCalendarInvitesForTeam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendM365TeamAvailabilityCalendarInvitesForTeamMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendM365TeamAvailabilityCalendarInvitesForTeamMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e1c1a31224fb33fc2c1b963e8214bf15",
    "id": null,
    "metadata": {},
    "name": "SendM365TeamAvailabilityCalendarInvitesForTeamMutation",
    "operationKind": "mutation",
    "text": "mutation SendM365TeamAvailabilityCalendarInvitesForTeamMutation(\n  $input: SendM365TeamAvailabilityCalendarInvitesForTeamMutationInput!\n) {\n  sendM365TeamAvailabilityCalendarInvitesForTeam(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "b895917e14335b89165bf410c95b8683";

export default node;
