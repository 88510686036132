import { ChevronLeft, ChevronRight } from "@clockwise/icons";
import {
  Button,
  Combobox,
  Divider,
  Input,
  InputBase,
  InputWrapper,
  MantineTheme,
  Modal,
  Popover,
  Tooltip,
  createTheme,
} from "@mantine/core";
import { Calendar, DatePicker, DatePickerInput } from "@mantine/dates";
import React from "react";
import {
  bg_destructive,
  bg_emphasis,
  border_subtle,
  palette,
} from "../themes/__generated__/tokens.new";
import { fontFamily } from "../themes/constants/typography";

const getHeight = (size: string) => {
  return {
    xs: "20px",
    sm: "24px",
    md: "28px",
    lg: "32px",
    xl: "36px",
  }[size];
};
const getFontSize = (size: string, theme: MantineTheme) => {
  return {
    xs: theme.fontSizes.sm,
    sm: theme.fontSizes.sm,
    md: theme.fontSizes.md,
    lg: theme.fontSizes.md,
    xl: theme.fontSizes.lg,
  }[size];
};

const commonCalendarVars = {
  day: {
    "--day-size-xs": "24px",
    "--day-size-sm": "24px",
    "--day-size-md": "32px",
    "--day-size-lg": "36px",
    "--day-size-xl": "42px",
    "--mantine-radius-default": "9999px",
  },
} as const;
const commonCalendarClassNames = {
  calendarHeader: "cw-text-default",
  calendarHeaderControl: "cw-py-2",
} as const;

const getCommonInputVars = (
  theme: MantineTheme,
  { size = "sm", hasError = true }: { size?: string; hasError?: boolean },
) => {
  const height = getHeight(size);
  const fontSize = getFontSize(size, theme);
  const leftSectionSize = {
    xs: "24px",
    sm: "24px",
    md: "28px",
    lg: "28px",
    xl: "36px",
  }[size];
  return {
    wrapper: {
      "--input-left-section-size": leftSectionSize,
      "--input-height": height,
      "--input-size": height,
      "--input-fz": fontSize,
      "--input-bd": border_subtle,
      "--input-bg": hasError ? bg_destructive : undefined,
      "--input-padding-y": "0",
      "--input-padding": {
        xs: "6px",
        sm: "6px",
        md: "8px",
        lg: "8px",
        xl: "12px",
      }[size],
    },
  };
};

export const theme = createTheme({
  // Mantine expects em sizes for breakpoints. These should match Tailwind breakpoint sizes.
  breakpoints: {
    xs: "35em", // 560px
    sm: "40em", // 640px
    md: "48em", // 768px
    lg: "64em", // 1024px
    xl: "80em", // 1280px
  },
  fontFamily: fontFamily.body.join(", "),
  headings: {
    fontFamily: fontFamily.brand.join(", "),
  },
  fontSizes: {
    xs: "10px",
    sm: "12px",
    md: "13px",
    lg: "15px",
    xl: "18px",
  },
  spacing: {
    xs: "4px",
    sm: "8px",
    md: "12px",
    lg: "16px",
    xl: "24px",
  },
  radius: {
    xs: "2px",
    sm: "6px",
    md: "8px",
    lg: "12px",
    xl: "32px",
  },
  primaryColor: "green",
  primaryShade: 5,
  colors: {
    blue: [
      palette.blue[50],
      palette.blue[100],
      palette.blue[200],
      palette.blue[300],
      palette.blue[400],
      palette.blue[500],
      palette.blue[600],
      palette.blue[700],
      palette.blue[800],
      palette.blue[900],
    ],
    gray: [
      palette.gray[50],
      palette.gray[100],
      palette.gray[200],
      palette.gray[300],
      palette.gray[400],
      palette.gray[500],
      palette.gray[600],
      palette.gray[700],
      palette.gray[800],
      palette.gray[900],
    ],
    red: [
      palette.red[50],
      palette.red[100],
      palette.red[200],
      palette.red[300],
      palette.red[400],
      palette.red[500],
      palette.red[600],
      palette.red[700],
      palette.red[800],
      palette.red[900],
    ],
    indigo: [
      palette.purple[50],
      palette.purple[100],
      palette.purple[200],
      palette.purple[300],
      palette.purple[400],
      palette.purple[500],
      palette.purple[600],
      palette.purple[700],
      palette.purple[800],
      palette.purple[900],
    ],
    green: [
      palette.green[50],
      palette.green[100],
      palette.green[200],
      palette.green[300],
      palette.green[400],
      palette.green[500],
      palette.green[600],
      palette.green[700],
      palette.green[800],
      palette.green[900],
    ],
    orange: [
      palette.orange[50],
      palette.orange[100],
      palette.orange[200],
      palette.orange[300],
      palette.orange[400],
      palette.orange[500],
      palette.orange[600],
      palette.orange[700],
      palette.orange[800],
      palette.orange[900],
    ],
  },
  components: {
    Button: Button.extend({
      // Specify font weights to match existing buttons
      classNames: (_theme, { size = "sm" }) => ({
        inner: {
          xs: "cw-font-medium",
          sm: "cw-font-medium",
          md: "cw-font-bold",
          lg: "cw-font-bold",
          xl: "cw-font-bold",
        }[size as string],
      }),
      vars: (theme, { size = "sm" }) => {
        const height = getHeight(size);
        const fontSize = getFontSize(size, theme);
        return {
          root: {
            "--button-height": height,
            "--button-fz": fontSize,
          },
        };
      },
    }),
    Input: Input.extend({
      classNames: {
        input: "cw-text-default cw-font-medium placeholder:cw-text-subtle",
        wrapper: "cw-my-0",
      },
      vars: (theme, { size, error, withErrorStyles = true }) =>
        getCommonInputVars(theme, { size: size, hasError: !!(error && withErrorStyles) }),
    }),
    InputBase: InputBase.extend({
      vars: (theme, { size, error, withErrorStyles = true }) =>
        getCommonInputVars(theme, { size: size, hasError: !!(error && withErrorStyles) }),
    }),
    InputWrapper: InputWrapper.extend({
      classNames: {
        error: "cw-font-body cw-text-12 cw-text-destructive cw-leading-none",
        label: "cw-font-body cw-font-normal cw-text-14 cw-text-default cw-leading-none",
        root: "cw-inline-flex cw-flex-col cw-gap-1 cw-flex-1",
      },
    }),
    Popover: Popover.extend({
      classNames: {
        // Need to set z-index > 1300 since MUI uses 1300 for its modal layer
        // TODO: Investigate if there's a cleaner way to globally define z-index layers for Mantine
        dropdown: "cw-bg-default cw-border-0 cw-rounded-lg cw-shadow-selectPopup cw-z-[1301]",
      },
    }),
    Calendar: Calendar.extend({
      defaultProps: {
        nextIcon: <ChevronRight className="cw-h-full" />,
        previousIcon: <ChevronLeft className="cw-h-full" />,
      },
      classNames: { ...commonCalendarClassNames },
      vars: () => ({ ...commonCalendarVars }),
    }),

    DatePicker: DatePicker.extend({
      classNames: { ...commonCalendarClassNames },
      vars: () => ({ ...commonCalendarVars }),
    }),
    DatePickerInput: DatePickerInput.extend({
      classNames: { ...commonCalendarClassNames },
      vars: () => ({ ...commonCalendarVars }),
    }),
    Combobox: Combobox.extend({
      vars: (theme, { size: _size = "md" }) => {
        const fontSize = getFontSize("md", theme);
        return {
          dropdown: {},
          options: {
            "--combobox-option-padding": "5px 8px",
            "--combobox-option-fz": fontSize,
          },
        };
      },
    }),
    Tooltip: Tooltip.extend({
      classNames: {
        // Need to set z-index > 10000 since MUI uses 1300 for its modal layer, and Popper use 10,000 for popups
        // TODO: Investigate if there's a cleaner way to globally define z-index layers for Mantine
        tooltip: "cw-body-sm cw-text-onEmphasis cw-py-1 cw-px-2 cw-z-[10001]",
      },
      vars: () => ({
        tooltip: {
          "--tooltip-bg": bg_emphasis,
        },
      }),
      defaultProps: {
        multiline: true,
        maw: 300,
      },
    }),
    Divider: Divider.extend({
      vars: () => ({
        root: {
          "--divider-color": border_subtle,
        },
      }),
    }),
    Modal: Modal.extend({
      classNames: {
        title: "cw-heading-2xl",
        body: "cw-body-base",
        close: "cw-self-start cw-ml-4",
      },
    }),
  },
});
