import {
  EventColorCategory,
  ICalColorSet,
} from "@clockwise/client-commons/src/util/event-category-coloring";
import {
  AutopilotEventStatusEnum,
  DiffActionTypeEnum,
  ResponseStatusEnum,
} from "@clockwise/schema";
import { ConferencingType, Exact, SmartHoldState, SmartHoldType } from "@clockwise/schema/v2";
import { CalendarMode } from "@clockwise/web-commons/src/components/calendar";
import { EventType } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { Interval } from "luxon";
import React from "react";
import { CalendarEventsQuery } from "./apollo/__generated__/CalendarEvents.generated";
import { MultiCalEventsQuery } from "./apollo/__generated__/MultiCalEvents.generated";
import {
  PlannerEventsQueryQuery,
  PlannerMetadataQueryQuery,
} from "./apollo/__generated__/Planner.generated";

export type PlannerMetadataOrg = Exclude<
  Exclude<
    Exclude<Exclude<PlannerMetadataQueryQuery["viewer"]["user"], null>["orgs"], null>["edges"],
    null
  >[0],
  null
>["node"];

export interface HashParams {
  mode: CalendarMode;
  year?: string;
  month?: string;
  day?: string;
}

export type PlannerEventList = Extract<
  Exclude<
    Extract<
      Exclude<PlannerEventsQueryQuery["org"], null>,
      { __typename: "Org" }
    >["eventParentBaseWrapperByWeeksObject"],
    null
  >,
  { __typename: "EventParentBaseWrapperByWeekObjectList" }
>["list"];

type GQLEvent = Exclude<
  PlannerEventList[0]["eventParentsByDay"][0]["eventParents"],
  null
>[0]["events"][0];

// sets __typename as an optional property on Event
export type PlannerEvent = Omit<GQLEvent, "__typename"> & Partial<Pick<GQLEvent, "__typename">>;

// --- START: AI Calendar Types ---
export type CalendarEventList = Extract<
  Exclude<
    Extract<
      Exclude<CalendarEventsQuery["org"], null>,
      { __typename: "Org" }
    >["eventParentBaseWrapperByWeeksObject"],
    null
  >,
  { __typename: "EventParentBaseWrapperByWeekObjectList" }
>["list"];

type GQLCalendarEvent = Exclude<
  CalendarEventList[0]["eventParentsByDay"][0]["eventParents"],
  null
>[0]["events"][0];

// sets __typename as an optional property on Event
export type CalendarEvent = Omit<GQLCalendarEvent, "__typename"> &
  Partial<Pick<GQLCalendarEvent, "__typename">>;
// --- END: AI Calendar Types ---

// --- START: Multi Calendar Types ---
export type MultiCalEventList = Extract<
  Exclude<
    Extract<
      Exclude<MultiCalEventsQuery["org"], null>,
      { __typename: "Org" }
    >["eventParentBaseWrapperByWeeksObject"],
    null
  >,
  { __typename: "EventParentBaseWrapperByWeekObjectList" }
>["list"];

type GQLMultiCalEvent = Exclude<
  MultiCalEventList[0]["eventParentsByDay"][0]["eventParents"],
  null
>[0]["events"][0];

// sets __typename as an optional property on Event
export type MultiCalEvent = Omit<GQLMultiCalEvent, "__typename"> &
  Partial<Pick<GQLMultiCalEvent, "__typename">>;
// --- END: AI Calendar Types ---

export enum RescheduleProposalType {
  BEFORE = "BEFORE",
  AFTER = "AFTER",
}

export enum TransparencyEnum {
  None = "None",
  Opaque = "Opaque",
  Transparent = "Transparent",
  Tentative = "Tentative",
}

export type EventPermissions = Exact<{
  canRSVP: boolean;
  canSeeOtherGuests: boolean;
  canInviteOthers: boolean;
  canModify: boolean;
  canDelete: boolean;
  canRemove: boolean;
}>;

export type PlannerEventCard = {
  active: boolean;
  affectedAttendees?: { __typename: "AffectedPerson"; id: string; calendarId: any }[];
  annotaion: string | undefined;
  badge?: React.ReactNode;
  calendarIds: string[];
  currentTimeInterval?: Interval;
  deemphasis: boolean;
  diffSummaryId?: string;
  eventCategory?: EventColorCategory;
  eventCategoryColor?: ICalColorSet;
  externalEventId?: string;
  fade?: boolean;
  pulse?: boolean;
  interval: Interval;
  isAllDay: boolean;
  isCancelled: boolean;
  key: string;
  keyAddendum?: string;
  locked: boolean | null;
  proposalType?: DiffActionTypeEnum;
  proposedResponseState?: ResponseStatusEnum | null;
  rescheduleProposalType?: RescheduleProposalType;
  responseState: ResponseStatusEnum | "Proposed";
  subText?: string;
  text: string;
  transparency: TransparencyEnum | null;
  updatedTimeInterval?: Interval;
  videoLink: {
    __typename: "VideoLink";
    uri: string;
    type: ConferencingType;
  } | null;
  eventPermissions: EventPermissions;
  attendeeCount: number;
  attendeesCalendarIds: string[];
  attendees: {
    __typename: "Attendee";
    id: string;
    email: string;
    responseStatus: ResponseStatusEnum | null;
  }[];
  flexStatus: AutopilotEventStatusEnum | null;
  smartHold: {
    holdType: SmartHoldType;
    state: SmartHoldState;
  } | null;
  proposalMetaData?: {
    attendeeIds: string[];
    isConsequence: boolean;
  };
  type: EventType;
  isRecurring?: boolean;
  recurringEventId?: string | null;
  isHold?: boolean;
  suppressNewBadge?: boolean;
  isStale?: boolean;
};

export type PlannerEventCardsByDay = Record<string, PlannerEventCard[]>;

export type EventCardsByCalendar = {
  allDayEvents: {
    [calendarId: string]: PlannerEventCard[];
  };
  allDayOOOEvents: {
    [calendarId: string]: PlannerEventCard[];
  };
  normalEvents: {
    [calendarId: string]: PlannerEventCard[];
  };
  proposalEvents: PlannerEventCard[];
};

// Key is date string
export type EventCardsByDayAndCalendar = Record<string, EventCardsByCalendar>;

export type ModifiedPlannerEventCardDiff = {
  externalEventId: string;
  text: string;
};

export type PlannerTimeCard = {
  index: number;
  key: string;
  isSelected: boolean;
  text: string;
  interval: Interval;
};
