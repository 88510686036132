export const errorActions = {
  SET_ERROR: "ERROR_SET_ERROR",
};

export const displayError = (error: string | Error) => {
  const errorMessage = error instanceof Error ? (error as Error).message : (error as string);

  return {
    type: errorActions.SET_ERROR,
    message: errorMessage,
  };
};
