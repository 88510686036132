import { DateTime } from "luxon";
import { WeekStartDays } from "./calendarReducer";

const weekStartDays: Record<WeekStartDays, number> = {
  sunday: 7,
  monday: 1,
};

/** Given a refrence DateTime and target weekday, return DateTime from the prior 6 days inclusive of refrence DateTime */
export const getPreviousWeekday = (
  fromDateTime: DateTime,
  targetWeekday: WeekStartDays,
): DateTime => {
  // count weekdays from reference date's weekday to target weekday
  let weekdayDiff = fromDateTime.weekday - weekStartDays[targetWeekday];

  // if count is negative; add 7, as we want the privious weekday
  if (weekdayDiff < 0) weekdayDiff += 7;

  // minus day duration to get DateTime for previous weekday
  return fromDateTime.minus({ day: weekdayDiff });
};
