import { graphql } from "react-relay";

export const sendM365TeamAvailabilityCalendarInviteMutation = graphql`
  mutation SendM365TeamAvailabilityCalendarInviteMutation(
    $input: SendM365TeamAvailabilityCalendarInviteMutationInput!
  ) {
    sendM365TeamAvailabilityCalendarInvite(input: $input) {
      clientMutationId
    }
  }
`;
