import { compact, isEmpty, isEqual, uniq } from "lodash";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import { RRule, Frequency as RRuleFrequency, Weekday } from "rrule";
import { getDaySuffix } from "../util/date";

// Re-exporting to avoid the need to include `rrule` in every other package. Since both enums and
// classes are types *and* values, they need special treatment when re-exporting
export { RRuleFrequency as RRuleFrequency, Weekday as Weekday };

export enum Frequency {
  YEARLY = RRuleFrequency.YEARLY,
  MONTHLY = RRuleFrequency.MONTHLY,
  WEEKLY = RRuleFrequency.WEEKLY,
  DAILY = RRuleFrequency.DAILY,
  HOURLY = RRuleFrequency.HOURLY,
  MINUTELY = RRuleFrequency.MINUTELY,
  SECONDLY = RRuleFrequency.SECONDLY,
  FORTNIGHTLY = 7, // We are adding fortnightly as a custom frequency
}

export type SupportedReccurenceOptions = {
  freq: RRuleFrequency;
  interval?: number;
  byDay?: Weekday[];
  until?: DateTime;
  count?: number;
  byMonth?: number[];
};

export const frequencyToRecurrenceTextMap = {
  [Frequency.HOURLY]: "Other",
  [Frequency.MINUTELY]: "Other",
  [Frequency.SECONDLY]: "Other",
  [Frequency.DAILY]: "Daily",
  [Frequency.WEEKLY]: "Weekly",
  [Frequency.FORTNIGHTLY]: "Biweekly",
  [Frequency.MONTHLY]: "Monthly",
};

type NthDayPair = [number, number];

export class RecurrenceRule {
  static getDay = (weekday: number) =>
    [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU][weekday - 1];
  static fromOpts(opts: SupportedReccurenceOptions) {
    return new RecurrenceRule(
      new RRule({
        freq: opts.freq,
        interval: opts.interval ?? 1,
        byweekday: opts.byDay,
        until: opts.until?.toJSDate(),
        count: opts.count,
        bymonth: opts.byMonth,
      }).toString(),
    );
  }
  static fromRRule(rrule: RRule) {
    return new RecurrenceRule(rrule.toString());
  }
  private static parse(value: string) {
    const lines = value.split(/\n/);
    const rruleString = lines.find((s) => s.startsWith("RRULE:"));

    const allowedProperties = /^(RRULE|EXDATE)\b/;
    // Ensure no unknown properties are present
    if (!lines.every((line) => allowedProperties.test(line))) {
      throw new Error("Invalid properties in recurrence rule string");
    }

    if (!rruleString) throw new Error("Missing RRULE in recurrence rule string");
    const rrule: RRule = RRule.fromString(rruleString);

    if (rrule.options.until && rrule.options.count) {
      throw new Error("Cannot have both UNTIL and COUNT in recurrence rule string");
    }

    return rrule;
  }

  private value: string;
  private rrule: RRule;
  constructor(value: string) {
    this.value = value;
    try {
      this.rrule = RecurrenceRule.parse(value);
    } catch (e) {
      throw new Error("RecurrenceRule must be a valid RRULE");
    }
  }

  get freq() {
    return this.rrule.options.freq;
  }
  /**
   * Return an array representing BYDAY values without indices (BYDAY=MO,TH)
   */
  get byDay() {
    // Make sure BYDAY was actaully set and RRule isn't just inferring the day from the start date
    if (this.rrule.origOptions.byweekday) {
      // RRule uses 0-6 for Monday-Sunday, but we want 1-7 to match the ISO and RFC standards
      return (this.rrule.options.byweekday ?? []).map((n) => n + 1).sort();
    }
    return [];
  }
  /**
   * Return an array of [day, n] pairs representing BYDAY values with indices (BYDAY=+2MO,-1TH)
   */
  get byNthDay(): NthDayPair[] {
    // RRule uses 0-6 for Monday-Sunday, but we want 1-7 to match the ISO and RFC standards
    return (this.rrule.options.bynweekday ?? [])
      .map(([day, n]): NthDayPair => [day + 1, n])
      .sort((a, b) => {
        if (a[0] === b[0]) {
          return a[1] - b[1];
        } else {
          return a[0] - b[0];
        }
      });
  }
  get byMonth() {
    // Make sure BYMONTH was actaully set and RRule isn't just inferring a month from the start date
    if (this.rrule.origOptions.bymonth) {
      return this.rrule.options.bymonth ?? [];
    }
    return [];
  }
  get interval() {
    return this.rrule.options.interval;
  }
  get count() {
    return this.rrule.options.count;
  }
  get until(): DateTime | null {
    const until = this.rrule.options.until;
    return until && DateTime.fromISO(until.toISOString());
  }

  /**
   * Returns the name of all rule parts (e.g., FREQ, BYDAY) specified in the original rrule:
   *   "RRULE:FREQ=WEEKLY;INTERVAL=2;BYDAY=MO,TU" => ["FREQ", "INTERVAL", "BYDAY"]
   */
  keys(): string[] {
    return this.entries().map(([key]) => key);
  }
  /**
   * Returns [name, value] pairs for all rule parts specified in the original rrule:
   *   "RRULE:FREQ=WEEKLY;INTERVAL=2" => [["FREQ","WEEKLY"], ["INTERVAL", "2"]]
   */
  entries(): [string, string][] {
    const [, unprefixedRule = ""] = /RRULE:(\S+)/.exec(this.value) ?? [];
    return unprefixedRule.split(";").map((part) => {
      const [key, value] = part.split("=");
      return [key, value ?? ""];
    });
  }

  public toString() {
    return this.value;
  }
  public toText(opts: { date: string; timezone: string; prefixString?: string }) {
    const zone = opts.timezone ?? "local";
    const dt = DateTime.fromISO(opts.date, { zone });
    return getRecurrenceDescription(this, dt, zone, opts.prefixString);
  }

  public toFrequencyString(): Frequency {
    const freqForString = this.freq;
    if (freqForString === RRuleFrequency.WEEKLY && this.interval === 2) {
      return Frequency.FORTNIGHTLY;
    } else {
      return freqForString;
    }
  }
}

/// Human-readable recurrence description utilities

const DAY_DISPLAY_MAP = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const getWeekDayName = (weekday: number) => {
  return DAY_DISPLAY_MAP[weekday - 1];
};
const getMonthDayName = (monthday: number) => {
  return `${monthday}${getDaySuffix(monthday)}`;
};

const getMonthName = (month: number): string => {
  return DateTime.now().set({ month }).monthShort;
};

const getByDayDisplay = (byDay: number[], byNthDay: [number, number][] = []) => {
  const consolidatedDays = uniq([...byNthDay.map(([day]) => day), ...byDay]);
  return ` on ${consolidatedDays.map((day) => getWeekDayName(day)).join(", ")}`;
};

const getByNthDayDisplay = (byNthDay: [number, number][]) => {
  const daysToDisplay = byNthDay.map(([day, n]) => {
    const ordinals: string[] =
      n < 0
        ? ["last", "second to last", "third to last", "fourth to last", "fifth to last"]
        : ["first", "second", "third", "fourth", "fifth"];
    return `${ordinals[Math.abs(n) - 1]} ${getWeekDayName(day)}`;
  });
  return ` on the ${daysToDisplay.join(", ")}`;
};

const getFrequencyIntervalDescription = (frequency: string, interval: number) =>
  compact(["Every", interval > 1 && `${interval}`, pluralize(frequency, interval)]).join(" ");

const getDailyDescription = (rrule: RecurrenceRule) => {
  return getFrequencyIntervalDescription("day", rrule.interval);
};

const getWeeklyDescription = (rrule: RecurrenceRule) => {
  const { interval, byDay } = rrule;
  const isWeekday = isEqual(byDay, [1, 2, 3, 4, 5]);
  let description = "";
  if (isWeekday && interval === 1) {
    return "Every weekday";
  } else if (isWeekday) {
    description = " on weekdays";
  } else if (!isEmpty(byDay)) {
    description = getByDayDisplay(byDay);
  }
  return [getFrequencyIntervalDescription("week", interval), description].join("");
};

const getMonthlyDescription = (rrule: RecurrenceRule, date: DateTime) => {
  const { byDay, byNthDay, interval } = rrule;
  let description = "";
  if (!isEmpty(byDay)) {
    description = getByDayDisplay(byDay, byNthDay);
  } else if (!isEmpty(byNthDay)) {
    description = getByNthDayDisplay(byNthDay);
  } else {
    description = ` on the ${getMonthDayName(date.day)}`;
  }
  return [getFrequencyIntervalDescription("month", interval), description].join("");
};

const getYearlyDescription = (rrule: RecurrenceRule, date: DateTime) => {
  const { byDay, byNthDay, byMonth, interval } = rrule;
  let description = "";
  // Add specfic days of the week, if specified
  if (!isEmpty(byDay)) {
    description = getByDayDisplay(byDay, byNthDay);
  } else if (!isEmpty(byNthDay)) {
    description = getByNthDayDisplay(byNthDay);
  }
  // Add month, if specified
  if (!isEmpty(byMonth)) {
    description += ` in ${byMonth.map((month) => getMonthName(month)).join(", ")}`;
  }
  // If still empty, default to showing the day of the month
  if (!description) {
    description = ` on ${date.toFormat("MMM d")}`;
  }
  return [getFrequencyIntervalDescription("year", interval), description].join("");
};

const addEnd = (rrule: RecurrenceRule, desc: string, timezone: string) => {
  const { until, count } = rrule;
  // Handle any UNTIL or COUNT
  let limit = "";
  if (until) {
    limit = ` until ${until.setZone(timezone).toFormat("MMM d")}`;
  } else if (count) {
    // This reads weird if the description already ends with a comma
    const delimiter = desc.includes(",") ? ";" : ",";
    limit = `${delimiter} ${count} ${pluralize("time", count)}`;
  }
  return desc + limit;
};

const getRecurrenceDescription = (
  rrule: RecurrenceRule,
  date: DateTime,
  timezone: string,
  prefixString?: string,
) => {
  let description: string;
  switch (rrule.freq) {
    case RRuleFrequency.DAILY:
      description = getDailyDescription(rrule);
      break;
    case RRuleFrequency.WEEKLY:
      description = getWeeklyDescription(rrule);
      break;
    case RRuleFrequency.MONTHLY:
      description = getMonthlyDescription(rrule, date);
      break;
    case RRuleFrequency.YEARLY:
      description = getYearlyDescription(rrule, date);
      break;
    default:
      return "Custom recurrence";
  }
  const updatedDescription = prefixString
    ? description.replace("Every", prefixString)
    : description;
  return addEnd(rrule, updatedDescription, timezone);
};
