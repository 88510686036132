import React from "react";

export const FormSubHeader = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <div
      className="cw-flex cw-items-center cw-body-sm cw-w-fit cw-cursor-pointer cw-text-subtle cw-border-solid cw-border-b cw-leading-4 hover:cw-text-brand"
      onClick={onClick}
    >
      {children}
    </div>
  );
};
