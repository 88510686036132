import { DiffActionTypeEnum } from "@clockwise/schema";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { AttendeeAvatarStack } from "@clockwise/web-commons/src/ui/AttendeeAvatarStack";
import { UserAvatar } from "@clockwise/web-commons/src/ui/UserAvatar";
import { AvatarStack } from "@clockwise/web-commons/src/ui/avatar-stack/AvatarStack";
import { useReadCalendar } from "@clockwise/web-commons/src/util/CalendarContext";
import { getFormattedDateRange } from "@clockwise/web-commons/src/util/date.util";
import classNames from "classnames";
import {
  compact,
  concat,
  difference,
  find,
  flatten,
  flow,
  forEach,
  groupBy,
  intersection,
  isEmpty,
  orderBy,
  remove,
  sortBy,
  values,
} from "lodash";
import { DateTime, Interval } from "luxon";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmProposalUIDone } from "../../../state/actions/ui-flow-on-screen.actions";
import { IReduxState } from "../../../state/reducers/root.reducer";
import { getTitleFromPillMarkdown } from "../../chat/ai-chat/components/ai-markdown/AIMarkdown.util";
import { useSharedProposalContext } from "../../shareable-proposal/hooks/SharedProposalProvider";
import { useReadCalendarColors } from "../../web-app-calendar/hooks/CalendarColorsContext";
import { useCalendarEventCards } from "../../web-app-calendar/hooks/useCalendarEventCards";
import { useWorkingLocationEventCards } from "../../web-app-calendar/hooks/useWorkingLocationEventCards";
import {
  PlannerEventCard,
  PlannerEventCardsByDay,
  RescheduleProposalType,
} from "../../web-app-calendar/types";
import { SharedProposalEventCards } from "../SharedProposalEventCards";
import { useReadHoverEvent } from "../util/HoverEventContext";
import { grabEventIdsFromConflictClusters } from "../util/parseConflictClustersGQL";
import { useConflictClusters } from "./useConflictClusters";
import useCurrentProposalCard from "./useCurrentProposalCard";
import useDefragProposalCards from "./useDefragProposalCard";
import { useProposalEventCards } from "./useProposalEventCards";

type ProposalPlannerEventCard = PlannerEventCard & {
  updatedTimeInterval?: Interval | undefined;
  currentTimeInterval?: Interval | undefined;
  affectedAttendees?: string[];
};

export const useChatPlusCalEventCards = (
  date: string,
  calendarIds: string[],
  ownCalendarIds: string[],
) => {
  const calendarIdsToFullyShow = useSelector(
    (state: IReduxState) => state.multiCalendar.calendarIdsToFullyShow,
  );
  const chatMessageUIisProcessing = useSelector(
    (state: IReduxState) => state.uiFlowOnScreen.chatMessageUI.isProcessing,
  );
  const proposalUIisLoading = useSelector(
    (state: IReduxState) => state.uiFlowOnScreen.proposalUI.isLoading,
  );
  const proposalUIisConfirming = useSelector(
    (state: IReduxState) => state.uiFlowOnScreen.proposalUI.isConfirming,
  );
  const proposalUIisCanceling = useSelector(
    (state: IReduxState) => state.uiFlowOnScreen.proposalUI.isCanceling,
  );
  // UI Note: There is still calendar flicker when switching scheduling options
  // especially for complex SBM proposals
  const proposalUIisSwitchingSchedulingOption = useSelector(
    (state: IReduxState) => state.uiFlowOnScreen.proposalUI.isSwitchSchedulingOption,
  );
  const selectedSchedulingOption = useSelector(
    (state: IReduxState) => state.rescheduleEventModal.selectedRescheduleOption,
  );
  const recurringEventId = selectedSchedulingOption?.recurringEventId;

  const uiFlowIsProccesing =
    chatMessageUIisProcessing ||
    proposalUIisLoading ||
    proposalUIisCanceling ||
    proposalUIisConfirming ||
    proposalUIisSwitchingSchedulingOption;

  const { proposal: sharedProposal } = useSharedProposalContext();
  const nonShareableProposalEventCards = useProposalEventCards(date);
  const shareableProposalEventCards = SharedProposalEventCards(sharedProposal);
  const currentProposalCards = useCurrentProposalCard();
  const defragProposalCards = useDefragProposalCards();

  // NB: we only want to display one of these at any point
  const proposalEventCards = !isEmpty(sharedProposal)
    ? shareableProposalEventCards
    : defragProposalCards
    ? defragProposalCards
    : currentProposalCards
    ? currentProposalCards
    : nonShareableProposalEventCards;

  const calendarState = useReadCalendar();
  const { conflictClusters } = useConflictClusters();
  const workingLocationCards = useWorkingLocationEventCards(date, calendarIds, ownCalendarIds);
  const plannerEventCards = useCalendarEventCards(date, calendarIds, ownCalendarIds);
  const hoveredEventIdOrDiffId = useReadHoverEvent();
  const userIsHoveringOverEventOrDiff = !!hoveredEventIdOrDiffId;
  const hasHoveredDiffId = ({ key }: { key?: string }) => key === hoveredEventIdOrDiffId;
  const calendarColorMap = useReadCalendarColors();
  const previousProposalEventCardsRef = useRef(proposalEventCards);
  const dispatch = useDispatch();

  const proposalIsProcessingLastTimeRef = useRef<DateTime>(DateTime.now());
  const proposalIsProcessingLastTime = useMemo(() => {
    if (proposalUIisConfirming) {
      const newNow = DateTime.now();
      proposalIsProcessingLastTimeRef.current = newNow;

      return newNow;
    }
    return proposalIsProcessingLastTimeRef.current;
  }, [proposalUIisConfirming]);

  const within10SecondsOfProposalIsProcessingLastTime =
    DateTime.now().diff(proposalIsProcessingLastTime).as("seconds") < 10;

  if (
    proposalEventCards !== previousProposalEventCardsRef.current &&
    !within10SecondsOfProposalIsProcessingLastTime
  ) {
    previousProposalEventCardsRef.current = proposalEventCards;
  }

  // Cards that have NOT bee dedeuped for single day split view
  const useAllCardsByDay = calendarState.visibleDates.length === 1;

  // Might need to useMemo these
  const previousProposalCards = flattenCards(
    previousProposalEventCardsRef.current.cardsByDay,
  ) as ProposalPlannerEventCard[];
  const plannerCards = flattenCards(
    useAllCardsByDay ? plannerEventCards.allCardsByDay : plannerEventCards.cardsByDay,
  ) as PlannerEventCard[];

  const getCardKeyInfo = (card?: PlannerEventCard | ProposalPlannerEventCard) => {
    if (!card) {
      return null;
    }

    return `${card.text}-${card.interval.start.toISOTime()}-${card.interval.start.toISOTime()}`;
  };

  const previousProposalCardKey = previousProposalCards
    ? getCardKeyInfo(previousProposalCards[0])
    : null;
  const replacementEventCardExistsForPreviousProposal: boolean = plannerCards
    .map((plannerCard) => getCardKeyInfo(plannerCard))
    .some((plannerCardKey) => plannerCardKey === previousProposalCardKey);

  useEffect(() => {
    if (replacementEventCardExistsForPreviousProposal) {
      dispatch(setConfirmProposalUIDone());
    }
  }, [replacementEventCardExistsForPreviousProposal]);

  const shouldUsePreviousProposalCard =
    within10SecondsOfProposalIsProcessingLastTime &&
    !replacementEventCardExistsForPreviousProposal &&
    // Upon refresh, the previousProposalCards will be empty and we still want to show the latestProposalCards
    !isEmpty(previousProposalCards);

  const cardsByDay = useMemo(() => {
    const latestProposalCards = flattenCards(
      proposalEventCards.cardsByDay,
    ) as ProposalPlannerEventCard[];

    const proposalCards = shouldUsePreviousProposalCard
      ? previousProposalCards
      : latestProposalCards;

    const eventIdsInCluster = grabEventIdsFromConflictClusters(conflictClusters);
    const { modifiedCardDiffs } = proposalEventCards;

    modifiedCardDiffs.forEach((diff) => {
      const modifiedTitle = getTitleFromPillMarkdown(diff.text);
      const idx = plannerCards.findIndex((card) => card.externalEventId === diff.externalEventId);

      if (!modifiedTitle || idx === -1) {
        return;
      }

      plannerCards[idx].text = modifiedTitle;
    });

    const currentlyHoveredOnProposal = proposalCards.find(hasHoveredDiffId);

    const isOnlyShowingOwnCalendar = isEmpty(difference(calendarIdsToFullyShow, ownCalendarIds));

    forEach(plannerCards, (plannerCard) => {
      plannerCard.annotaion = undefined;

      const proposalCardIsShowing = !isEmpty(proposalCards);
      const plannerCardBelongsToUser = !isEmpty(
        intersection(ownCalendarIds, plannerCard.calendarIds),
      );

      if (userIsHoveringOverEventOrDiff) {
        if (!hasHoveredDiffId(plannerCard)) {
          plannerCard.fade = true;
        }
      } else {
        plannerCard.fade = false;
      }

      plannerCard.deemphasis =
        proposalCardIsShowing &&
        plannerCardBelongsToUser &&
        isOnlyShowingOwnCalendar &&
        !uiFlowIsProccesing &&
        // After the flow is complete we want to avoid the flickering between when `proposalCardIsShowing` (true) until the `replacementEventCardExistsForPreviousProposal` is true
        shouldUsePreviousProposalCard;

      // UX Note: the `recurringEvent` siblings do not yet have up-to-date data
      // right after a save, thus mark it as stale
      plannerCard.isStale = !!recurringEventId && recurringEventId === plannerCard.recurringEventId;

      // Deemphasis for Conflict View
      if (eventIdsInCluster.length) {
        plannerCard.deemphasis = !eventIdsInCluster.includes(plannerCard.externalEventId || "");
      }
    });

    // For quick reschedules, show a ghost of the event at the new time while the confirmation modal is open
    if (selectedSchedulingOption?.showACopyOfNewProposedEvent) {
      const rescheduledCard = find(
        plannerCards,
        ({ externalEventId }) => externalEventId === selectedSchedulingOption.externalEventId,
      );

      if (rescheduledCard) {
        const newStartTime = DateTime.fromISO(selectedSchedulingOption.proposedStartTimeISO);
        const newEndTime = newStartTime.plus({
          minutes: selectedSchedulingOption.newDuration ?? selectedSchedulingOption.duration,
        });
        const newInteval = Interval.fromDateTimes(newStartTime, newEndTime);
        const newSubText = getFormattedDateRange({
          start: newStartTime.toISO(),
          end: newEndTime.toISO(),
          options: { showRelativeDay: false },
        });

        const ghostPlannerCard: PlannerEventCard = {
          ...rescheduledCard,
          fade: false,
          interval: newInteval,
          subText: newSubText,
          key: `${rescheduledCard.key}-reschedule-proposed-time`,
          keyAddendum: `${rescheduledCard.keyAddendum}-reschedule-proposed-time`,
        };

        if (selectedSchedulingOption.hideOriginalEvent) {
          // remove the original copy
          remove(plannerCards, rescheduledCard);
        }

        plannerCards.push(ghostPlannerCard);
      }
    }

    if (proposalCards.length) {
      // manage proposed edits to exiting events
      forEach(proposalCards, (proposalCard) => {
        const plannerCard = find(
          plannerCards,
          ({ externalEventId }) => externalEventId === proposalCard.externalEventId,
        );

        if (plannerCard) {
          if (
            "proposalType" in proposalCard &&
            (proposalCard.proposalType === DiffActionTypeEnum.EDIT_DETAILS ||
              proposalCard.proposalType === DiffActionTypeEnum.RSVP ||
              proposalCard.proposalType === DiffActionTypeEnum.UNCHANGED)
          ) {
            remove(plannerCards, plannerCard);
          } else if (!hasHoveredDiffId(proposalCard)) {
            remove(plannerCards, plannerCard);
          } else if (proposalEventCards.isScheduleByMoving) {
            remove(plannerCards, plannerCard);
          } else {
            plannerCard.annotaion = "Before";
            plannerCard.deemphasis = false;
            plannerCard.fade = false;
          }
        }
      });

      // manage proposals
      if (proposalEventCards.isScheduleByMoving) {
        // handle the duplication of the before and afters
        // also handle the avatar badges
        if (userIsHoveringOverEventOrDiff) {
          // clear all the other badges
          forEach(proposalCards, (proposalCard) => {
            proposalCard.badge = null;
          });

          if (currentlyHoveredOnProposal) {
            if (!isEmpty(currentlyHoveredOnProposal?.affectedAttendees)) {
              // Copy for every attendee
              const affectedAttendees = sortBy(
                currentlyHoveredOnProposal.affectedAttendees || [],
                "calendarId",
              );
              forEach(affectedAttendees, (attendee: any) => {
                if (currentlyHoveredOnProposal?.currentTimeInterval) {
                  const beforeCopy = {
                    ...currentlyHoveredOnProposal,
                    annotaion: "Before",
                    interval: currentlyHoveredOnProposal?.currentTimeInterval,
                    badge: null,
                    keyAddendum: `-before-${attendee?.person?.primaryCalendarId}`, // Required for React map keys
                    deemphasis: false,
                    rescheduleProposalType: RescheduleProposalType.BEFORE,
                    fade: false,
                  };

                  proposalCards.push(beforeCopy);
                }

                if (currentlyHoveredOnProposal?.updatedTimeInterval) {
                  const afterCopy = {
                    ...currentlyHoveredOnProposal,
                    annotaion: "After",
                    interval: currentlyHoveredOnProposal?.updatedTimeInterval,
                    keyAddendum: `-after-${attendee?.person?.primaryCalendarId}`, // Required for React map keys
                    deemphasis: false,
                    fade: false,
                  };

                  // handle the avatar badges
                  const badge = (
                    <AttendeeAvatarStack size="small" overlap maxShown={3}>
                      {compact(
                        [attendee].map((attendee: any) => {
                          if (!attendee.person) return null;

                          const { profile, primaryCalendarId: primaryCalendar } = attendee.person;
                          const calendarColors = calendarColorMap[primaryCalendar];

                          return (
                            <div
                              key={primaryCalendar}
                              className={classNames([
                                `cw-flex cw-w-fit cw-rounded-full`,
                                "cw-border-[3px] cw-border-solid",
                              ])}
                              style={{
                                borderColor: calendarColors?.border || "#000000",
                              }}
                            >
                              <AttendeeAvatar
                                key={primaryCalendar}
                                profile={{
                                  ...profile,
                                  givenName: profile?.givenName || primaryCalendar,
                                }}
                                isInOverlap
                                size="small"
                              />
                            </div>
                          );
                        }),
                      )}
                    </AttendeeAvatarStack>
                  );
                  afterCopy.badge = badge;

                  proposalCards.push(afterCopy);
                }

                if (currentlyHoveredOnProposal.proposalType !== "ADD") {
                  remove(proposalCards, currentlyHoveredOnProposal);
                }
              });
            } else {
              if (currentlyHoveredOnProposal?.currentTimeInterval) {
                const beforeCopy = {
                  ...currentlyHoveredOnProposal,
                  annotaion: "Before",
                  interval: currentlyHoveredOnProposal?.currentTimeInterval,
                  badge: null,
                  keyAddendum: "-before", // Required for React map keys
                  rescheduleProposalType: RescheduleProposalType.BEFORE,
                  fade: false,
                };

                proposalCards.push(beforeCopy);
              }

              if (currentlyHoveredOnProposal?.updatedTimeInterval) {
                const afterCopy = {
                  ...currentlyHoveredOnProposal,
                  annotaion: "After",
                  interval: currentlyHoveredOnProposal?.updatedTimeInterval,
                  keyAddendum: "-after", // Required for React map keys
                  fade: false,
                };

                // handle the avatar badges
                const affectedAttendees = afterCopy?.affectedAttendees || [];
                if (!isEmpty(affectedAttendees)) {
                  const badge = (
                    <AttendeeAvatarStack size="small" overlap maxShown={3}>
                      {compact(
                        affectedAttendees.map((attendee: any) => {
                          if (!attendee.person) return null;

                          const { profile, primaryCalendarId: primaryCalendar } = attendee.person;
                          const calendarColors = calendarColorMap[primaryCalendar];

                          return (
                            <AttendeeAvatar
                              key={primaryCalendar}
                              isInOverlap
                              profile={{
                                ...profile,
                                givenName: profile?.givenName || primaryCalendar,
                              }}
                              borderColor={calendarColors?.border}
                              size="small"
                            />
                          );
                        }),
                      )}
                    </AttendeeAvatarStack>
                  );
                  afterCopy.badge = badge;
                }

                proposalCards.push(afterCopy);
              }

              if (currentlyHoveredOnProposal.proposalType !== "ADD") {
                remove(proposalCards, currentlyHoveredOnProposal);
              }
            }
          }
        } else {
          // duplicate all the reschedules
          forEach(proposalCards, (proposalCard) => {
            if (!isEmpty(proposalCard?.affectedAttendees)) {
              // remove the original copy
              remove(proposalCards, proposalCard);

              // Copy for every attendee
              const affectedAttendees = proposalCard.affectedAttendees || [];
              forEach(affectedAttendees, (attendee: any) => {
                const attendeeCopy = {
                  ...proposalCard,
                  keyAddendum: `reschedule-${attendee?.person?.primaryCalendarId}`, // Required for React map keys
                  affectedAttendees: [attendee],
                  calendarId: attendee?.person?.primaryCalendarId,
                };
                // handle the avatar badges
                const badge = (
                  <AvatarStack size="small" overlap maxShown={3}>
                    {compact(
                      [attendee].map((attendee: any) => {
                        if (!attendee.person) return null;
                        const { profile, primaryCalendarId: primaryCalendar } = attendee.person;
                        const calendarColors = calendarColorMap[primaryCalendar];
                        return (
                          <div
                            key={primaryCalendar}
                            className={classNames([
                              `cw-flex cw-w-fit cw-rounded-full`,
                              "cw-border-[3px] cw-border-solid",
                            ])}
                            style={{
                              borderColor: calendarColors?.border || "#000000",
                            }}
                          >
                            <UserAvatar
                              key={primaryCalendar}
                              profile={{
                                ...profile,
                                givenName: profile?.givenName || primaryCalendar,
                              }}
                              size="tiny"
                            />
                          </div>
                        );
                      }),
                    )}
                  </AvatarStack>
                );
                attendeeCopy.badge = badge;
                // attendeeCopy.badge = null;
                proposalCards.push(attendeeCopy);
              });
            }
          });
        }

        forEach(proposalCards, (proposalCard: ProposalPlannerEventCard) => {
          // handle the avatar badges
          const affectedAttendees = proposalCard.affectedAttendees || [];
          if (!userIsHoveringOverEventOrDiff && !isEmpty(affectedAttendees)) {
            const badge = (
              <AvatarStack size="small" overlap maxShown={3}>
                {compact(
                  affectedAttendees.map((attendee: any) => {
                    if (!attendee.person) return null;

                    const { profile, primaryCalendarId: primaryCalendar } = attendee.person;
                    const calendarColors = calendarColorMap[primaryCalendar];

                    return (
                      <div
                        key={primaryCalendar}
                        className={classNames([
                          `cw-flex cw-w-fit cw-rounded-full`,
                          "cw-border-[3px] cw-border-solid",
                        ])}
                        style={{
                          borderColor: calendarColors?.border || "#000000",
                        }}
                      >
                        <UserAvatar
                          key={primaryCalendar}
                          profile={{
                            ...profile,
                            givenName: profile?.givenName || primaryCalendar,
                          }}
                          size="tiny"
                        />
                      </div>
                    );
                  }),
                )}
              </AvatarStack>
            );
            proposalCard.badge = badge;
          }

          if (userIsHoveringOverEventOrDiff) {
            if (proposalCard.responseState === "Proposed" && hasHoveredDiffId(proposalCard)) {
              switch (proposalCard.proposalType) {
                case DiffActionTypeEnum.ADD:
                  proposalCard.annotaion = "New";
                  break;
                case DiffActionTypeEnum.CANCEL:
                case DiffActionTypeEnum.RESCHEDULE:
                case DiffActionTypeEnum.EDIT_DETAILS:
                case DiffActionTypeEnum.RSVP:
                case DiffActionTypeEnum.UNCHANGED:
              }
              proposalCard.active = true;
              proposalCard.deemphasis = false;
              proposalCard.fade = false;
              proposalCard.pulse = true;
            }

            if (proposalCard.responseState === "Proposed" && !hasHoveredDiffId(proposalCard)) {
              proposalCard.annotaion = undefined;
              proposalCard.active = true;
              proposalCard.deemphasis = false;
              proposalCard.fade = true;
              proposalCard.pulse = false;
            }
          } else {
            if (proposalCard.responseState === "Proposed") {
              switch (proposalCard.proposalType) {
                case DiffActionTypeEnum.ADD:
                case DiffActionTypeEnum.CANCEL:
                case DiffActionTypeEnum.RESCHEDULE:
                case DiffActionTypeEnum.EDIT_DETAILS:
                case DiffActionTypeEnum.RSVP:
                case DiffActionTypeEnum.UNCHANGED:
                  break;
              }
              proposalCard.annotaion = undefined;
              proposalCard.active = true;
              proposalCard.deemphasis = false;
              proposalCard.fade = false;
              proposalCard.pulse = true;
            } else {
              proposalCard.annotaion = undefined;
              proposalCard.active = true;
              proposalCard.deemphasis = true;
              proposalCard.fade = false;
              proposalCard.pulse = true;
            }
          }
        });
      } else {
        forEach(proposalCards, (proposalCard) => {
          const proposalIsHoveredOn =
            hasHoveredDiffId(proposalCard) && proposalCard.responseState === "Proposed";
          if (userIsHoveringOverEventOrDiff) {
            proposalCard.fade = !proposalIsHoveredOn;
            proposalCard.pulse = proposalIsHoveredOn;
          } else {
            proposalCard.fade = false;
            proposalCard.pulse = true;
          }
          if (proposalIsHoveredOn) {
            switch (proposalCard.proposalType) {
              case DiffActionTypeEnum.ADD:
                if (proposalCard.suppressNewBadge) {
                  break;
                }
                proposalCard.annotaion = "New";
                break;
              case DiffActionTypeEnum.CANCEL:
              case DiffActionTypeEnum.RESCHEDULE:
                proposalCard.annotaion = "After";
                break;
              case DiffActionTypeEnum.EDIT_DETAILS:
              case DiffActionTypeEnum.RSVP:
              case DiffActionTypeEnum.UNCHANGED:
                proposalCard.annotaion = "Before/After";
                break;
            }
            proposalCard.active = true;
          } else {
            proposalCard.annotaion = undefined;
            proposalCard.active = true;
          }
        });
      }
    }

    // In SBM flows, when users hover over suggested moves and multipe moves coincide,
    // (e.g. Meeting1 and Meeting2 are both suggested to move to 2pm - 3pm)
    // the plannerCards switch to proposalCards
    // and vice versa. However we want the cards to stay in the same order even if they change card types
    // so that in the planner it's a stable view. Thus we're going to pre-sort by the 'text' in the card
    // which preservers the order of the cards even if they change types.
    const mergedCards = orderBy(
      concat(plannerCards, proposalCards),
      [
        "text",
        "calendarId", // in the event of a tie, sort by calendarId
      ],
      ["desc", "asc"],
    );
    const mergedCardsByDay = groupBy(mergedCards, ({ interval }: { interval: Interval }) =>
      interval.start.toISODate(),
    ) as PlannerEventCardsByDay;

    return mergedCardsByDay;
  }, [
    plannerEventCards,
    proposalEventCards,
    hoveredEventIdOrDiffId,
    calendarColorMap,
    conflictClusters,
  ]);

  const workingLocationsByDay = useMemo(() => {
    return workingLocationCards.allWorkingLocationsByDay;
  }, [workingLocationCards]);

  return {
    cardsByDay,
    workingLocationsByDay,
    loading: proposalEventCards.loading || plannerEventCards.loading,
    error: proposalEventCards.error || plannerEventCards.error,
    refetch: plannerEventCards.refetch,
    prefetch: plannerEventCards.prefetch,
    startPolling: plannerEventCards.startPolling,
    stopPolling: plannerEventCards.stopPolling,
    conflictClusters,
    isCurrentlyShowingOptimisticProposals: shouldUsePreviousProposalCard,
  };
};

const flattenCards = flow([values, flatten]);
