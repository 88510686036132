import { UIFlowOnScreenActions } from "../actions/ui-flow-on-screen.actions";

export type IUiFlowOnScreenState = {
  chatMessageUI: {
    isProcessing: boolean;
  };
  proposalUI: {
    isLoading: boolean;
    isSwitchSchedulingOption: boolean;
    isConfirming: boolean;
    isCanceling: boolean;
  };
};

export const initialUiFlowOnScreenState: IUiFlowOnScreenState = {
  chatMessageUI: {
    isProcessing: false,
  },
  proposalUI: {
    isLoading: false,
    isSwitchSchedulingOption: false,
    isConfirming: false,
    isCanceling: false,
  },
};

interface UIFlowOnScreenAction {
  type: UIFlowOnScreenActions;
}

export function uiFlowOnScreenReducer(
  state: IUiFlowOnScreenState = initialUiFlowOnScreenState,
  action: UIFlowOnScreenAction,
): IUiFlowOnScreenState {
  switch (action.type) {
    case UIFlowOnScreenActions.SetNone:
      return initialUiFlowOnScreenState;
    case UIFlowOnScreenActions.SetProcessChatMessageUIInFlight:
      return {
        ...state,
        chatMessageUI: {
          isProcessing: true,
        },
      };
    case UIFlowOnScreenActions.SetProcessChatMessageUIDone:
      return {
        ...state,
        chatMessageUI: {
          isProcessing: false,
        },
      };
    case UIFlowOnScreenActions.SetLoadingProposalUIInFlight:
      return {
        ...state,
        proposalUI: {
          isLoading: true,
          isSwitchSchedulingOption: false,
          isConfirming: false,
          isCanceling: false,
        },
      };
    case UIFlowOnScreenActions.SetLoadingProposalUIDone:
      return {
        ...state,
        proposalUI: {
          isLoading: false,
          isSwitchSchedulingOption: false,
          isConfirming: false,
          isCanceling: false,
        },
      };
    case UIFlowOnScreenActions.SetSwitchingProposalUIInFlight:
      return {
        ...state,
        proposalUI: {
          isLoading: false,
          isSwitchSchedulingOption: true,
          isConfirming: false,
          isCanceling: false,
        },
      };
    case UIFlowOnScreenActions.SetConfirmProposalUIInFlight:
      return {
        ...state,
        proposalUI: {
          isLoading: false,
          isSwitchSchedulingOption: false,
          isConfirming: true,
          isCanceling: false,
        },
      };
    case UIFlowOnScreenActions.SetConfirmProposalUIDone:
      return {
        ...state,
        proposalUI: {
          isLoading: false,
          isSwitchSchedulingOption: false,
          isConfirming: false,
          isCanceling: false,
        },
      };
    case UIFlowOnScreenActions.SetCancelProposalUIInFlight:
      return {
        ...state,
        proposalUI: {
          isLoading: false,
          isSwitchSchedulingOption: false,
          isConfirming: false,
          isCanceling: true,
        },
      };
    case UIFlowOnScreenActions.SetCancelProposalUIDone:
      return {
        ...state,
        proposalUI: {
          isLoading: false,
          isSwitchSchedulingOption: false,
          isConfirming: false,
          isCanceling: false,
        },
      };
    default:
      return state;
  }
}

export default uiFlowOnScreenReducer;
