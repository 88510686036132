import { Profile, getFullName } from "#clockwise/web-commons/src/util/profile.util";
import { Tooltip } from "@clockwise/design-system";
import { Assistant, Block, Cancel, CheckCircle, Person } from "@clockwise/design-system/icons";
import { bg_default } from "@clockwise/design-system/tokens";
import { ClockwiseIntelligenceFilled, SvgIconComponent } from "@clockwise/icons";
import { ResponseStatusEnum } from "@clockwise/schema";
import { Stars } from "@material-ui/icons";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { ClockwiseLogoCircle } from "../components/svgs/ClockwiseLogoCircle";

import React, { CSSProperties } from "react";

export type AvatarSize = "xsmall" | "small" | "medium" | "large" | "xlarge";

export type UserAvatarProps = {
  profile?: Profile;
  calendarId?: string;
  size?: AvatarSize;
  "cw-id"?: string;
  "aria-label"?: string;
  iconType?: IconType;
  onClick?: () => void;
  toolTipContent?: React.ReactNode;
  hideTooltip?: boolean;
  avatarClassname?: string;
  borderColor?: string;
  isOrganizer?: boolean;
  isExternalUser?: boolean;
  displayName?: string;
  isInOverlap?: boolean;
};

export const SIZE_TABLE: {
  [k in AvatarSize]: {
    fontSize: string;
    fontWeight: string;
    size: string;
    coloredBorderWidth: string;
    whiteBorderWidth: string;
    userBadgeSize: string | undefined;
    externalBadgeSize: string | undefined;
  };
} = {
  xsmall: {
    size: "12px",
    fontSize: "8px",
    fontWeight: "cw-font-bold",
    coloredBorderWidth: "1px",
    whiteBorderWidth: "0.5px",
    userBadgeSize: undefined, // No badges at this size
    externalBadgeSize: undefined, // No badges at this size
  },
  small: {
    size: "16px",
    fontSize: "10px",
    fontWeight: "cw-font-bold",
    coloredBorderWidth: "1px",
    whiteBorderWidth: "1px",
    userBadgeSize: undefined, // No badges at this size
    externalBadgeSize: undefined, // No badges at this size
  },
  medium: {
    size: "20px",
    fontSize: "10px",
    fontWeight: "cw-font-bold",
    coloredBorderWidth: "1.5px",
    whiteBorderWidth: "1px",
    userBadgeSize: "10px",
    externalBadgeSize: "7px",
  },
  large: {
    size: "24px",
    fontSize: "12px",
    fontWeight: "cw-font-semibold",
    coloredBorderWidth: "1.5px",
    whiteBorderWidth: "1px",
    userBadgeSize: "12px",
    externalBadgeSize: "8px",
  },
  xlarge: {
    size: "32px",
    fontSize: "13px",
    fontWeight: "cw-font-semibold",
    coloredBorderWidth: "2px",
    whiteBorderWidth: "1.5px",
    userBadgeSize: "14px",
    externalBadgeSize: "10px",
  },
};

const TentativeAttendeeStatus = ({ style }: { style: CSSProperties }) => (
  <div
    className={`cw-rounded-full cw-border-solid cw-border`}
    style={{ ...style, borderColor: bg_default }}
  >
    <div className="cw-rounded-full cw-border cw-w-full cw-h-full cw-border-dashed cw-border-warning-emphasis cw-bg-warning-inset" />
  </div>
);

type IconType = ResponseStatusEnum | "assistant" | "block" | "prism" | "logo" | null;

const getIconColorByType = (
  iconType: IconType,
  isOrganizer: boolean,
): { icon: "custom-tentative" | "logo" | SvgIconComponent | undefined; color: string } => {
  switch (iconType) {
    case ResponseStatusEnum.Accepted:
      return {
        icon: isOrganizer ? Stars : CheckCircle,
        color: "cw-fill-positive",
      };
    case ResponseStatusEnum.Tentative:
      return {
        icon: isOrganizer ? Stars : "custom-tentative",
        color: "cw-fill-warning-muted",
      };
    case ResponseStatusEnum.Declined:
      return {
        icon: isOrganizer ? Stars : Cancel,
        color: "cw-fill-destructive",
      };
    case "assistant":
      return {
        icon: Assistant,
        color: "cw-fill-subtle",
      };
    case "block":
      return {
        icon: Block,
        color: "cw-fill-subtle",
      };
    case "prism":
      return {
        icon: ClockwiseIntelligenceFilled,
        color: "cw-fill-subtle",
      };
    case "logo":
      return {
        icon: "logo",
        color: "cw-fill-subtle",
      };
    default:
      return {
        icon: isOrganizer ? Stars : undefined,
        color: "cw-fill-subtle",
      };
  }
};

const UserStatusBadgeContent = ({
  size,
  iconType = null,
  isOrganizer = false,
}: {
  size: AvatarSize;
  iconType?: IconType;
  isOrganizer?: boolean;
}) => {
  const iconValues = getIconColorByType(iconType, isOrganizer);
  const dimension = SIZE_TABLE[size].userBadgeSize;
  if (!iconValues.icon || !dimension) return null; // These icons are too small to show any badges
  if (iconValues.icon === "custom-tentative") {
    return <TentativeAttendeeStatus style={{ height: dimension, width: dimension }} />;
  } else if (iconValues.icon === "logo") {
    return <ClockwiseLogoCircle size={dimension} />;
  } else {
    const StatusIcon = iconValues.icon;
    return (
      <StatusIcon
        style={{ height: dimension, width: dimension }}
        className={`${iconValues.color}  cw-bg-default cw-rounded-full`}
      />
    );
  }
};

export function AttendeeAvatar({
  profile,
  calendarId,
  size = "large",
  "cw-id": cwId,
  "aria-label": ariaLabel,
  iconType,
  onClick,
  toolTipContent,
  hideTooltip = false,
  avatarClassname,
  borderColor,
  isInOverlap = false,
  isOrganizer = false,
  isExternalUser = false,
  displayName,
}: UserAvatarProps) {
  const [isImageError, setIsImageError] = React.useState(false);
  const sizeOfAvatar = SIZE_TABLE[size].size;
  const sizeOfBorder = borderColor ? SIZE_TABLE[size].coloredBorderWidth : 0;
  const showWhiteBorder = Boolean(borderColor || isInOverlap);

  const generatedDisplayName = displayName ?? getFullName(profile);
  const fullName = generatedDisplayName.length > 0 ? generatedDisplayName : calendarId ?? "";
  const helperText = ariaLabel || `${fullName || "user"} avatar`;

  const toolTipDefault = (
    <div className="cw-text-center">
      <div>{fullName}</div>
      {isOrganizer && <div>(organizer)</div>}
      {isExternalUser && <div>(external)</div>}
    </div>
  );

  const handleImageError = () => {
    setIsImageError(true);
  };

  const badge = (
    <div
      style={{
        width: sizeOfAvatar,
        height: sizeOfAvatar,
      }}
      className="cw-p-0 cw-inline-flex cw-relative cw-shrink-0 cw-align-middle cw-box-border"
    >
      {/* External Border */}
      <div
        style={{
          borderColor: borderColor || "transparent",
          borderWidth: sizeOfBorder,
        }}
        className="cw-rounded-full cw-border-solid cw-w-full cw-h-full"
      >
        {/* Contents (with white border if applicable) */}
        <div
          style={{
            borderColor: showWhiteBorder ? "white" : "",
            borderWidth: showWhiteBorder ? SIZE_TABLE[size].whiteBorderWidth : 0,
            fontSize: SIZE_TABLE[size].fontSize,
          }}
          cw-id={cwId}
          onClick={onClick}
          aria-label={profile?.externalImageUrl ? undefined : helperText}
          className={classNames(
            "cw-text-onEmphasis cw-bg-brand-emphasis cw-flex cw-w-full cw-h-full cw-overflow-hidden cw-box-border cw-relative cw-items-center cw-leading-none cw-select-none cw-rounded-[50%] cw-justify-center cw-font-body",
            SIZE_TABLE[size].fontWeight,
            {
              "cw-border-solid": showWhiteBorder,
            },
            avatarClassname,
          )}
        >
          {profile?.externalImageUrl && !isImageError ? (
            <img
              aria-label={profile?.externalImageUrl && helperText}
              className="cw-text-transparent cw-w-full cw-h-full cw-object-cover cw-text-center cw-indent-[10000px]"
              src={profile.externalImageUrl}
              onError={handleImageError}
            />
          ) : !isEmpty(fullName) ? (
            <>{`${fullName[0]?.toUpperCase()}`}</>
          ) : (
            <Person className="cw-bg-positive-emphasis cw-h-full cw-w-full" />
          )}
        </div>
      </div>
      {/* Attendee status Badge */}
      <div className="cw-flex cw-z-[1] cw-absolute cw-flex-wrap cw-box-border cw-items-center cw-leading-none cw-content-center cw-flex-row cw-justify-center cw-scale-100 cw-translate-x-2/4 cw-right-[14%] cw-bottom-[14%] cw-translate-y-2/4">
        <UserStatusBadgeContent size={size} iconType={iconType} isOrganizer={isOrganizer} />
      </div>
      {/* External Attendee Badge */}
      {isExternalUser && SIZE_TABLE[size].externalBadgeSize && (
        <div className="cw-flex cw-z-[1] cw-absolute cw-flex-wrap cw-box-border cw-items-center cw-leading-none cw-content-center cw-flex-row cw-justify-center cw-scale-100 cw-translate-x-2/4 cw-right-[14%] cw-top-[14%] -cw-translate-y-2/4">
          <div
            style={{
              width: SIZE_TABLE[size].externalBadgeSize,
              height: SIZE_TABLE[size].externalBadgeSize,
            }}
            className="cw-bg-warning-emphasis cw-rounded-full cw-border cw-border-solid cw-border-[#FFF] cw-box-border"
          />
        </div>
      )}
    </div>
  );

  if (hideTooltip) {
    return badge;
  } else {
    return <Tooltip title={toolTipContent || toolTipDefault}>{badge}</Tooltip>;
  }
}
