import { graphql } from "react-relay";

export const setWorkAddressForUserMutation = graphql`
  mutation SetWorkAddressForUserMutation($input: SetWorkAddressForUserMutationInput!) {
    setWorkAddressForUser(input: $input) {
      clientMutationId
      address {
        rawAddress
      }
    }
  }
`;
