import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import { getApiUrl } from "@clockwise/client-commons/src/config/api";
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { gatewayTypePolicies } from "@clockwise/web-commons/src/network/apollo/typePolicies";
import {
  getAuthLink,
  getBatchLink,
  getGraphQLLink,
  getUploadLink,
  isUsingBatching,
  updateTokensLink,
} from "./utils";

// TODO Implement subscriptions with the new gateway
// const SUBSCRIPTION_URL = `${getSocketUrl(true, !inDevelopment)}/graphql`;
export const GATEWAY_GRAPHQL_URL = `${getApiUrl(true)}/graphql/v2`;

const authLink = getAuthLink();
const authLinkAsync = getAuthLink(true);

// const wsLink = getSocketLink(SUBSCRIPTION_URL);
// const wsLinkAsync = getSocketLink(SUBSCRIPTION_URL, true);

const singleHttpLink = getGraphQLLink(GATEWAY_GRAPHQL_URL);
const batchHttpLink = getBatchLink(GATEWAY_GRAPHQL_URL);
const httpLink = split(isUsingBatching, batchHttpLink, singleHttpLink);

const uploadLink = getUploadLink(GATEWAY_GRAPHQL_URL);

function isObject(node: unknown): node is Record<string, unknown> {
  return typeof node === "object" && node !== null;
}

// rough first draft, could probably be optimised in a loads of different ways.
const hasFiles = (node: Record<string, unknown>, found: Array<File | Blob> = []) => {
  Object.keys(node).forEach((key) => {
    const value = node[key];
    if (!isObject(value) || found.length > 0) {
      return;
    }

    if (
      (typeof File !== "undefined" && value instanceof File) ||
      (typeof Blob !== "undefined" && value instanceof Blob)
    ) {
      found.push(value);
      return;
    }

    hasFiles(value, found);
  });

  return found.length > 0;
};

const link = split(({ variables }) => hasFiles(variables), uploadLink, httpLink);

// const splitLink = split(isSubscriptionOperation, wsLink, batchHttpLink);
// const splitLinkAsync = split(isSubscriptionOperation, wsLinkAsync, batchHttpLink);

export const cache = new InMemoryCache({ typePolicies: gatewayTypePolicies });

let _gatewayClient: ApolloClient<NormalizedCacheObject> | null = null;

export const getGatewayClient = () => {
  if (!_gatewayClient) {
    _gatewayClient = new ApolloClient({
      cache,
      connectToDevTools: getEnvironment() !== "production",
      link: ApolloLink.from([updateTokensLink, authLink, link]),
    });
  }
  return _gatewayClient;
};

let _gatewayWorkerClient: ApolloClient<NormalizedCacheObject> | null = null;

export const getGatewayWorkerClient = () => {
  if (!_gatewayWorkerClient) {
    _gatewayWorkerClient = new ApolloClient({
      cache,
      connectToDevTools: getEnvironment() !== "production",
      link: ApolloLink.from([updateTokensLink, authLinkAsync, link]),
    });
  }
  return _gatewayWorkerClient;
};
