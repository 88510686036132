// Action types
export enum UIFlowOnScreenActions {
  SetNone = "SetNone",
  SetProcessChatMessageUIInFlight = "UIFlowOnScreen_SetProcessChatMessageUIInFlight",
  SetProcessChatMessageUIDone = "UIFlowOnScreen_SetProcessChatMessageUIDone",
  SetLoadingProposalUIInFlight = "UIFlowOnScreen_SetLoadingProposalUIInFlight",
  SetLoadingProposalUIDone = "UIFlowOnScreen_SetLoadingProposalUIDone",
  SetSwitchingProposalUIInFlight = "UIFlowOnScreen_SetSwitchingProposalUIInFlight",
  SetSwitchingProposalUIDone = "UIFlowOnScreen_SetSwitchingProposalUIDone",
  SetConfirmProposalUIInFlight = "UIFlowOnScreen_SetConfirmProposalUIInFlight",
  SetConfirmProposalUIDone = "UIFlowOnScreen_SetConfirmProposalUIDone",
  SetCancelProposalUIInFlight = "UIFlowOnScreen_SetCancelProposalUIInFlight",
  SetCancelProposalUIDone = "UIFlowOnScreen_SetCancelProposalUIDone",
}

export const setNone = () => ({
  type: UIFlowOnScreenActions.SetNone,
});

export const setProcessChatMessageUIInFlight = () => ({
  type: UIFlowOnScreenActions.SetProcessChatMessageUIInFlight,
});

export const setProcessChatMessageUIDone = () => ({
  type: UIFlowOnScreenActions.SetProcessChatMessageUIDone,
});

export const setLoadingProposalUIInFlight = () => ({
  type: UIFlowOnScreenActions.SetLoadingProposalUIInFlight,
});

export const setLoadingProposalUIDone = () => ({
  type: UIFlowOnScreenActions.SetLoadingProposalUIDone,
});

export const setSwitchingProposalUIInFlight = () => ({
  type: UIFlowOnScreenActions.SetSwitchingProposalUIInFlight,
});

export const setSwitchingProposalUIDone = () => ({
  type: UIFlowOnScreenActions.SetSwitchingProposalUIDone,
});

export const setConfirmProposalUIInFlight = () => ({
  type: UIFlowOnScreenActions.SetConfirmProposalUIInFlight,
});

export const setConfirmProposalUIDone = () => ({
  type: UIFlowOnScreenActions.SetConfirmProposalUIDone,
});

export const setCancelProposalUIInFlight = () => ({
  type: UIFlowOnScreenActions.SetCancelProposalUIInFlight,
});

export const setCancelProposalUIDone = () => ({
  type: UIFlowOnScreenActions.SetCancelProposalUIDone,
});
