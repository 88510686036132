import { getDaySuffix } from "@clockwise/client-commons/src/util/date";
import { SuggestedSlotTagEnum } from "@clockwise/schema/v2";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { compact, groupBy } from "lodash";
import { DateTime, Interval } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedSlot } from "./CopyTimesPreview";
import { BestTimesDocument } from "./__generated__/CopyBestTimes.v2.generated";

export function suggestSlotToFormattedSlot(
  timeslot: string,
  targetTimeZone: string,
): FormattedSlot {
  const startTime = Interval.fromISO(timeslot).start.setZone(targetTimeZone);

  const now = DateTime.now().setZone(targetTimeZone);
  const isToday = startTime.hasSame(now, "day");
  const isTomorrow = startTime.hasSame(now.plus({ days: 1 }), "day");

  let relativeDateString = "";
  if (isToday) {
    relativeDateString = "Today";
  } else if (isTomorrow) {
    relativeDateString = "Tomorrow";
  } else {
    relativeDateString = startTime.toFormat("ccc");
  }
  relativeDateString += startTime.toFormat(", LLL d") + getDaySuffix(startTime.day);

  return {
    startTime12h: startTime.toFormat("h:mma").toLowerCase(),
    startTime24h: startTime.toFormat("HH:mm"),
    isoDate: startTime.toISODate(),
    relativeDateString: relativeDateString,
    startMillis: startTime.toMillis(),
    timeZoneName: startTime.offsetNameShort,
  };
}

export const generateSuggestedTimes = (
  slots: { timeSlot: string; tag: SuggestedSlotTagEnum | null }[],
  numDaysToShow: number,
  timeZone: string,
) => {
  const slotsByDay = groupBy(slots, (slot) =>
    Interval.fromISO(slot.timeSlot).start.setZone(timeZone).toISODate(),
  );

  const newBestTimes = Object.values(slotsByDay)
    .map((slotsInDay) => {
      // Suggestions per day is contructed from:
      // "best" time, if present.
      // 1 non best time, arbitrarily choosen to be the middle time of the array until we have a backend based rank of best times.
      const bestSlot = slotsInDay.find(({ tag }) => tag === SuggestedSlotTagEnum.Best);

      const inconvenientSlots = slotsInDay.filter(
        ({ tag }) => tag === SuggestedSlotTagEnum.Inconvenient,
      );
      const neutralSlots = slotsInDay.filter(
        ({ tag }) => tag !== SuggestedSlotTagEnum.Inconvenient && tag !== SuggestedSlotTagEnum.Best,
      );
      const alternateSlots = neutralSlots.length ? neutralSlots : inconvenientSlots;
      return compact([bestSlot, alternateSlots?.[Math.floor(alternateSlots.length / 2)]]).sort(
        (a, b) => {
          return Interval.fromISO(a.timeSlot).start < Interval.fromISO(b.timeSlot).start ? -1 : 1;
        },
      );
    })
    .filter((suggestedSlots) => suggestedSlots.length)
    .slice(0, numDaysToShow);

  return [newBestTimes.length, newBestTimes.flat()] as const;
};

const DAYS_TO_FETCH_AT_ONE_TIME = 7;

const getInitialRange = () => {
  return { start: DateTime.now(), end: DateTime.now().plus({ days: 7 }) };
};

export function usePaginatedBestTimes(
  linkName: string,
  slug: string,
  duration: number,
  timeZone: string,
  numDaysToShow: number,
) {
  // Fixed for life of this component
  const initialRange = useMemo(getInitialRange, []);

  // Keep track of the last range we fetched
  const latestRangeRef = useRef(initialRange);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const initialVariables = useMemo(() => {
    const startDate = initialRange.start.toISODate();
    const endDate = initialRange.end.toISODate();

    // Reset latest range if any initial vars change since we'll be refetching data
    latestRangeRef.current = initialRange;

    return { linkName, slug, duration, startDate, endDate, timeZone };
  }, [linkName, slug, duration, initialRange, timeZone]);

  const { loading, error, data, fetchMore } = useGatewayQuery(BestTimesDocument, {
    notifyOnNetworkStatusChange: true,
    variables: initialVariables,
    onCompleted: () => {
      setErrorMsg("");
    },
    onError: () => {
      setErrorMsg("No available times were found.");
    },
  });

  const linkData = data?.publicSchedulingLink;
  const endOfAvailability = linkData && DateTime.fromISO(linkData.endOfAvailability);

  const fetchMoreIfPossible = useCallback(() => {
    const lastFetchedDate = latestRangeRef.current.end;

    if (!endOfAvailability || endOfAvailability <= lastFetchedDate) {
      return;
    }

    latestRangeRef.current = {
      start: latestRangeRef.current.end,
      end: latestRangeRef.current.end.plus({ days: DAYS_TO_FETCH_AT_ONE_TIME }),
    };

    void fetchMore({
      variables: {
        startDate: latestRangeRef.current.start.toISODate(),
        endDate: latestRangeRef.current.end.toISODate(),
      },
    });
  }, [endOfAvailability, fetchMore]);

  const [daysFound, bestTimeSlots] = useMemo(() => {
    const linkTimes = linkData?.linkTimes;
    return linkTimes ? generateSuggestedTimes(linkTimes, numDaysToShow, timeZone) : [0, []];
  }, [linkData?.linkTimes, numDaysToShow, timeZone]);

  const needMore = daysFound < numDaysToShow;
  useEffect(() => {
    if (!loading && needMore) {
      fetchMoreIfPossible();
    }
  }, [loading, needMore, fetchMoreIfPossible]);

  return {
    loading,
    bestTimeSlots,
    error,
    errorMsg,
  };
}
