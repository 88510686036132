import { CSSProperties } from "@material-ui/core/styles/withStyles";

// styles
import { CHROME_MAX_WIDTH } from "#webapp/src/components/chrome-wrapper/ChromeWrapper.styles";
import { colors, greys } from "@clockwise/web-commons/src/styles/color.styles";

const content: CSSProperties = {
  lineHeight: "24px",
  height: "auto",
  textAlign: "center",
  minWidth: "100px",
  flexWrap: "unset",
};

const chromeContent: CSSProperties = {
  ...content,
  minHeight: "60px",
  boxShadow: "none",
  padding: "6px 12px",
  borderRadius: 0,
  width: CHROME_MAX_WIDTH,
  lineHeight: "18px",
  "@media (max-width: 959.95px)": {
    flexGrow: "unset",
  },
};

const action: CSSProperties = {
  flexDirection: "column",
};

const chromeAction: CSSProperties = {
  ...action,
  paddingLeft: 8,
};

const messageContainer: CSSProperties = {
  lineHeight: "16px",
  flex: 1,
};

const messageContainerWithActions: CSSProperties = {
  ...messageContainer,
  textAlign: "left",
};

const successContent = {
  backgroundColor: colors.green.standard,
};

const errorContent = {
  backgroundColor: colors.red.standard,
};

const infoContent = {
  backgroundColor: colors.blueDusk.standard,
};

const warningContent = {
  backgroundColor: colors.yellow.standard,
};

const defaultContent = {
  backgroundColor: greys.dark,
};

export const styles: Record<string, CSSProperties> = {
  content,
  chromeContent,
  messageContainer,
  messageContainerWithActions,
  action,
  chromeAction,
  successContent,
  errorContent,
  infoContent,
  warningContent,
  defaultContent,
};
