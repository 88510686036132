import { AvailabilityRestrictionEnum } from "@clockwise/schema/v2";

export const AVAILABILITY_TEXT: { [type in AvailabilityRestrictionEnum]: string } = {
  [AvailabilityRestrictionEnum.MaximizeAvailability]: "Maximize availability",
  [AvailabilityRestrictionEnum.BalanceAvailabilityAndFocus]: "Balance availability and Focus Time",
  [AvailabilityRestrictionEnum.MaximizeFocusTime]: "Protect Focus Time",
};

export const AVAILABILITY_TEXT_SHORT: { [type in AvailabilityRestrictionEnum]: string } = {
  [AvailabilityRestrictionEnum.MaximizeAvailability]: "Maximize availability",
  [AvailabilityRestrictionEnum.BalanceAvailabilityAndFocus]: "Balance availability",
  [AvailabilityRestrictionEnum.MaximizeFocusTime]: "Protect Focus Time",
};
