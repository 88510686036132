import { graphql } from "react-relay";

export const scheduleNewSmartHoldMutation = graphql`
  mutation ScheduleNewSmartHoldMutation($input: ScheduleNewSmartHoldMutationInput!) {
    scheduleNewSmartHold(input: $input) {
      eventParent {
        id
        externalEventId
        events {
          id
          startTime {
            millis
          }
          annotatedEvent {
            id
            externalEventId
            orgTags {
              tag
              state {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
          annotatedRecurringEvent {
            id
            externalEventId
            orgTags {
              tag
              state {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
        }
      }
    }
  }
`;
