import { RouteRenderer } from "#webapp/src/components/route-renderer";
import { ApolloError } from "@apollo/client";
import { paths } from "@clockwise/client-commons/src/constants/site";
import { SchedulingPriorityEnum } from "@clockwise/schema/v2";
import {
  hasInvalidError,
  hasNotFoundError,
} from "@clockwise/web-commons/src/network/apollo/errors";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import {
  AttendeeResponseToQuestion,
  BookedSlot,
  SchedulingLink,
} from "@clockwise/web-commons/src/ui/scheduling-link";
import { SchedulingLinkLoading } from "@clockwise/web-commons/src/ui/scheduling-link-loading";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { getFullName } from "@clockwise/web-commons/src/util/profile.util";
import {
  findOwner,
  getEditSchedulingLinkPath,
} from "@clockwise/web-commons/src/util/scheduling.util";
import { createUUID } from "@clockwise/web-commons/src/util/uuid";
import { compact, noop } from "lodash";
import { DateTime, Interval } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import linkSrc from "../scheduling-link-dashboard/images/link.png";
import { BannerVariants } from "./BannerVariants";
import {
  CreateLinkBookingDocument,
  LinkBookingAvailableTimesDocument,
  LinkBookingDetailsDocument,
  LinkBookingDetailsQuery,
} from "./__generated__/SchedulingLinkRenderer.v2.generated";
import { formatTimeFromParam, getInitialDate, getInitialDuration } from "./utils";

/**
 * Loads the link details and renders the frame of the booking page
 */
export const SchedulingLinkRenderer = () => {
  const { linkName = "", slug = "" } = useParams();
  const [searchParams] = useSearchParams();
  const timeSlotParam = searchParams.get("time") ?? "";
  const [isInvalidLink, setIsInvalidLink] = useState(false);

  const timeString = timeSlotParam ? `${formatTimeFromParam(timeSlotParam)} - ` : "";

  const { loading, data, error } = useGatewayQuery(LinkBookingDetailsDocument, {
    variables: {
      linkName,
      slug,
    },
    onError: (error) => {
      if (hasNotFoundError(error) || hasInvalidError(error)) {
        setIsInvalidLink(true);
      }
    },
  });

  const linkData = data?.publicSchedulingLink;

  const ownerProfile = findOwner(linkData?.linkMembers ?? [])?.person;
  const ownerFullName = ownerProfile ? getFullName(ownerProfile) : "This user";

  const title = linkData
    ? compact([linkData.name, ownerFullName]).join(" with ")
    : "Clockwise Scheduling Link";

  const titleString = `${timeString}${title}`;
  const description = "Book the best time, every time with " + ownerFullName;

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:card" content={linkSrc} />
        {title && <title>{titleString}</title>}
      </Helmet>
      <RouteRenderer
        variant="scheduling-link"
        error={!!error && !loading}
        notFound={isInvalidLink}
        loading={false /* handled by SchedulingLinkLoading */}
      >
        {!error && linkData ? (
          <InnerSchedulingLinkRendererGateway
            linkName={linkName}
            slug={slug}
            linkData={linkData}
            isAuthenticatedUser={!!data.currentUser?.id}
          />
        ) : (
          <SchedulingLinkLoading />
        )}
      </RouteRenderer>
    </>
  );
};

type LinkData = NonNullable<LinkBookingDetailsQuery["publicSchedulingLink"]>;
interface InnerSchedulingLinkRendererGatewayProps {
  linkName: string;
  slug: string;
  linkData: LinkData;
  isAuthenticatedUser: boolean;
}

/**
 * Loads the available time slots and renders the booking UI
 */
const InnerSchedulingLinkRendererGateway = ({
  linkName,
  slug,
  linkData,
  isAuthenticatedUser,
}: InnerSchedulingLinkRendererGatewayProps) => {
  const trackEvent = useTracking();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const paramDate = searchParams.get("date") ?? "";
  const paramTime = searchParams.get("time") ?? "";
  const paramTimezone = searchParams.get("timezone") ?? "";
  const paramName = searchParams.get("name") ?? "";
  const paramEmail = searchParams.get("email") ?? "";

  const [renderedTimeZone, setRenderedTimeZone] = useState(DateTime.local().zoneName);

  const [currentDate, setCurrentDate] = useState(
    getInitialDate({
      date: paramDate,
      time: paramTime,
      timezone: paramTimezone,
    }),
  );
  const [bookedSlot, setBookedSlot] = useState<BookedSlot | null>(null);
  const [bookingError, setBookingError] = useState<ApolloError | null>(null);
  const [selectedDuration, setSelectedDuration] = useState(() =>
    getInitialDuration({
      preferredDuration: parseInt(searchParams.get("duration") ?? "", 10),
      durations: linkData.durations,
      defaultDuration: linkData.defaultDuration,
    }),
  );

  const ownerProfile = findOwner(linkData.linkMembers)?.person;
  const endOfAvailability = DateTime.fromISO(linkData.endOfAvailability).toISODate();

  const now = DateTime.now();

  const { data, loading } = useGatewayQuery(LinkBookingAvailableTimesDocument, {
    variables: {
      linkName,
      slug,
      duration: selectedDuration,
      startDate: now.toISODate(),
      endDate: endOfAvailability,
      // Use the time zone that matches startDate
      timeZone: now.zoneName,
    },
  });

  const linkTimes = data?.publicSchedulingLink?.linkTimes ?? [];

  // Ensure currentDate is within a date range with available times when possible
  const firstAvailableDate = linkTimes[0]
    ? Interval.fromISO(linkTimes[0].timeSlot).start.toISODate()
    : null;
  useEffect(() => {
    if (firstAvailableDate && currentDate < firstAvailableDate) {
      setCurrentDate(firstAvailableDate);
    }
  }, [currentDate, firstAvailableDate]);

  const [createBooking, { loading: bookingSaving }] = useGatewayMutation(
    CreateLinkBookingDocument,
    { onError: (error) => setBookingError(error) },
  );
  const handleCreateBooking = useCallback(
    async (
      attendeeName: string,
      email: string,
      selectedTime: Interval,
      questionResponses: AttendeeResponseToQuestion[],
      additionalAttendeeEmails: string[],
      duration: number,
    ) => {
      const startTime = selectedTime.start.toISO();
      const result = await createBooking({
        variables: {
          attendee: {
            email,
            name: attendeeName,
            questionResponses,
          },
          duration,
          linkName,
          slug,
          startTime,
          timeZone: renderedTimeZone,
          additionalAttendeeEmails,
        },
      });

      if (result.data?.createBooking) {
        setBookedSlot({
          bookedDateOrDateTime: startTime,
          bookedTimeZone: renderedTimeZone,
          bookedLinkMembersAsCalendarIds: (result.data.createBooking.attendees || []).filter(
            (a) => linkData.linkMembers.findIndex((lm) => lm.id === a) !== -1,
          ),
        });

        trackEvent(TrackingEvents.LINKS.BOOKING.MODAL_BOOK, {
          bookingData: {
            attendee: {
              email,
              name: attendeeName,
              questionResponses,
            },
            linkName,
            slug,
            startTime: selectedTime.start.toISO(),
            timeZone: renderedTimeZone,
            additionalAttendeeEmails,
          },
          meetingSettingsId: linkData.id,
        });
        window.history.replaceState("booked", "", window.location.href);
      }
    },
    [createBooking, linkData, linkName, slug, trackEvent, renderedTimeZone],
  );

  const sessionId = useMemo(createUUID, []);
  // Create a tracking events to pass into the view component, prefilled with the linkUrl and a sessionId.
  const trackSession = useCallback(
    (event: string, data: Record<string, string | object | number | boolean>) => {
      trackEvent(event, {
        sessionId,
        meetingSettingsId: linkData?.id,
        linkUrl: window?.location?.href,
        userAgent: window?.navigator?.userAgent,
        ...data,
      });
    },
    [trackEvent, sessionId, linkData.id],
  );

  const handleDurationChange = (duration: number) => {
    setSelectedDuration(duration);
  };

  const queryParamDateTime = paramTimezone
    ? DateTime.fromISO(`${paramDate}T${paramTime}`, {
        zone: paramTimezone,
      }).setZone(renderedTimeZone)
    : DateTime.fromISO(`${paramDate}T${paramTime}`, {
        zone: renderedTimeZone,
      });

  return (
    <SchedulingLink
      id={linkData.id}
      linkName={linkName}
      slug={slug}
      logoUrl={linkData.linkLogoUrl}
      description={linkData.description}
      locationType={linkData.location}
      meetingName={linkData.name}
      duration={selectedDuration ?? linkData.defaultDuration}
      durations={linkData.durations}
      onDurationChange={handleDurationChange}
      linkMembers={linkData.linkMembers.filter(
        (lm) => lm.schedulingPriority !== SchedulingPriorityEnum.DoNotSchedule,
      )}
      attendeeQuestions={linkData.attendeeQuestions}
      timeZone={renderedTimeZone}
      onTimeZoneChange={setRenderedTimeZone}
      isOwner={linkData.ownedByMe}
      isMember={linkData.managedByMe}
      timeSlots={linkTimes}
      setCurrentDate={setCurrentDate}
      onMonthChange={noop} /* No longer used by this implementation, as we fetch all times */
      currentDate={currentDate}
      loading={loading}
      onBook={handleCreateBooking}
      bookingError={bookingError && bookingError.toString?.()}
      clearBookingError={() => {
        if (bookingError) {
          setBookingError(null);
        }
      }}
      bookingLoading={bookingSaving}
      bookedSlot={(!bookingError && !bookingSaving && bookedSlot) || null}
      trackSession={trackSession}
      highlightBestTimes={linkData.highlightBestTimes}
      endOfAvailability={linkData.endOfAvailability}
      onEditLink={() => navigate(getEditSchedulingLinkPath(linkName, slug))}
      onClickMyLinks={() => navigate(paths.schedulingLinks)}
      ownerProfile={ownerProfile}
      allowAdditionalAttendees={linkData.allowAdditionalAttendees}
      banner={<BannerVariants isAuthenticatedUser={isAuthenticatedUser} />}
      groupSchedulingStrategy={linkData.groupSchedulingStrategy}
      initDateTime={queryParamDateTime}
      initialName={paramName}
      initialEmail={paramEmail}
    />
  );
};
