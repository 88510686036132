import { graphql } from "react-relay";

export const upsertPreferredWorkMutation = graphql`
  mutation UpsertPreferredWorkMutation($input: UpsertPreferredWorkMutationInput!) {
    upsertPreferredWork(input: $input) {
      org {
        id
        userGoals(first: 1000000) {
          edges {
            node {
              id
              goalId
              targetUrn {
                id
                type
                value
              }
              otherUrns {
                id
                type
                value
              }
              name
              isDeleted
              options {
                syncToGoogle
                specificOptions {
                  ... on GoalPreferredWorkOptions {
                    preferredWorkType
                    keepEndOfDayOpen
                    keepStartOfDayOpen
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
