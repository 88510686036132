import classNames from "classnames";
import React from "react";

interface IProps {
  children: React.ReactNode;
  isFullWidth: boolean;
}

export const Tier = ({ children, isFullWidth }: IProps) => {
  return (
    <div
      className={classNames(
        "cw-flex-1 cw-flex cw-flex-col cw-items-center cw-relative",
        "cw-py-8 cw-px-4 cw-my-4 cw-rounded-md",
        "cw-border cw-border-solid cw-border-default",
        "cw-h-[500px] md:cw-h-[560px]",
        {
          "lg:cw-max-w-[275px] lg:cw-min-w-[225px] lg:cw-my-0": isFullWidth,
          "xl:cw-max-w-[275px] xl:cw-min-w-[225px] xl:cw-my-0 cw-w-full": !isFullWidth,
        },
      )}
    >
      {children}
    </div>
  );
};
