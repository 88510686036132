export enum OnboardingStep {
  Unstarted = "Unstarted",
  Survey = "Survey",
  TeamJoinCreate = "TeamJoinCreate",
  WorkingHours = "WorkingHours",
  Lunch = "Lunch",
  IntroduceFocusTime = "IntroduceFocusTime",
  CarouselIntroduction = "CarouselIntroduction",
  AutopilotCarousel = "AutopilotCarousel",
  FlexMeetingSurvey = "FlexMeetingSurvey",
  Links = "Links",
  FocusTimeSync = "FocusTimeSync",
  Invite = "Invite",
  ColorCoding = "ColorCoding",
  PrivateCalendarSync = "PrivateCalendarSync",
  TravelTime = "TravelTime",
  Slack = "Slack",
  SegmentedSummary = "SegmetedSummary",
  Summary = "Summary",
  Finished = "Finished",
}
