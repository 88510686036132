import { getPreviousWeekday } from "@clockwise/web-commons/src/util/getPreviousWeekday";
import { compact, keys, uniq } from "lodash";
import { DateTime, DurationLike } from "luxon";
import { useCallback, useMemo } from "react";
import { PlannerEventCardsByDay } from "../types";
import { useWorkingLocationEvents } from "./useWorkingLocationEvents";

export function useWorkingLocationMultiCal(
  calendarIds: string[],
  weekStartDate: string,
  isOnWorkingLocation: boolean,
) {
  // Due to the Rules of Hooks, we can't call hooks in a loop, so we have to
  // call them individually and then combine the results.
  // This is a hacky solution, but it allows us to properly cache events in 99% of use cases.
  // We try and fetch the first 8 individually to allow them to be cached independently, as Apollo
  // separates cache entries by their arguments.
  const [cal1, cal2, cal3, cal4, cal5, cal6, cal7, cal8, ...otherCals] = calendarIds;

  const result1 = useWorkingLocationEvents(cal1, weekStartDate, isOnWorkingLocation);
  const result2 = useWorkingLocationEvents(cal2, weekStartDate, isOnWorkingLocation);
  const result3 = useWorkingLocationEvents(cal3, weekStartDate, isOnWorkingLocation);
  const result4 = useWorkingLocationEvents(cal4, weekStartDate, isOnWorkingLocation);
  const result5 = useWorkingLocationEvents(cal5, weekStartDate, isOnWorkingLocation);
  const result6 = useWorkingLocationEvents(cal6, weekStartDate, isOnWorkingLocation);
  const result7 = useWorkingLocationEvents(cal7, weekStartDate, isOnWorkingLocation);
  const result8 = useWorkingLocationEvents(cal8, weekStartDate, isOnWorkingLocation);
  const otherResults = useWorkingLocationEvents(otherCals, weekStartDate, isOnWorkingLocation);

  const results = useMemo(
    () => [result1, result2, result3, result4, result5, result6, result7, result8, otherResults],
    [result1, result2, result3, result4, result5, result6, result7, result8, otherResults],
  );

  // Need to call out each of these individually as we only want to update when a fetchMore function changes.
  const fetchMoreFns = useMemo(
    () => [
      result1.fetchMore,
      result2.fetchMore,
      result3.fetchMore,
      result4.fetchMore,
      result5.fetchMore,
      result6.fetchMore,
      result7.fetchMore,
      result8.fetchMore,
      otherResults.fetchMore,
    ],
    [
      result1.fetchMore,
      result2.fetchMore,
      result3.fetchMore,
      result4.fetchMore,
      result5.fetchMore,
      result6.fetchMore,
      result7.fetchMore,
      result8.fetchMore,
      otherResults.fetchMore,
    ],
  );
  // Need to call out each of these individually as we only want to update when the workingLocationsByDay actually change.
  const calendars = useMemo(
    () =>
      compact([
        result1.workingLocationsByDay,
        result2.workingLocationsByDay,
        result3.workingLocationsByDay,
        result4.workingLocationsByDay,
        result5.workingLocationsByDay,
        result6.workingLocationsByDay,
        result7.workingLocationsByDay,
        result8.workingLocationsByDay,
        otherResults.workingLocationsByDay,
      ]),
    [
      result1.workingLocationsByDay,
      result2.workingLocationsByDay,
      result3.workingLocationsByDay,
      result4.workingLocationsByDay,
      result5.workingLocationsByDay,
      result6.workingLocationsByDay,
      result7.workingLocationsByDay,
      result8.workingLocationsByDay,
      otherResults.workingLocationsByDay,
    ],
  );
  const loading = results.some((r) => r.loading);

  const prefetch = useCallback(
    (relativeDuration: DurationLike) => {
      const newWeekStartDate = getPreviousWeekday(
        DateTime.fromISO(weekStartDate).plus(relativeDuration),
        "sunday",
      ).toISODate();

      // Only call `fetchMore` on calendars have been passed in, rather than on all hooks.
      // Calling `fetchMore` on a query that is `skip`ped appears to perform the network request
      // with bad arguments.
      fetchMoreFns.slice(0, calendarIds.length).forEach((fetchMore) => {
        void fetchMore({
          variables: {
            weekStartDate: newWeekStartDate,
          },
        });
      });
    },
    [calendarIds.length, fetchMoreFns, weekStartDate],
  );

  // Merge all calendars into one object
  const workingLocationsByDay = useMemo(() => {
    const combinedWorkingLocationsByDay: PlannerEventCardsByDay = {};

    const dates = uniq(calendars.flatMap((cal) => keys(cal)));
    dates.forEach((date) => {
      const workingLocationsForDay = calendars.map((cal) => cal[date] ?? []);
      combinedWorkingLocationsByDay[date] = workingLocationsForDay.flat();
    });

    return combinedWorkingLocationsByDay;
  }, [calendars]);

  return {
    workingLocationsByDay,
    prefetch,
    loading,
  };
}
