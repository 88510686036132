/**
 * @generated SignedSource<<59939e8b364282b51e7988caa2692a18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamJoinCreateDialogRenderer_org$data = {
  readonly id: string;
  readonly primaryOrgCalendar: string;
  readonly " $fragmentSpreads": FragmentRefs<"TeamJoinCreate_org">;
  readonly " $fragmentType": "TeamJoinCreateDialogRenderer_org";
};
export type TeamJoinCreateDialogRenderer_org$key = {
  readonly " $data"?: TeamJoinCreateDialogRenderer_org$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamJoinCreateDialogRenderer_org">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamJoinCreateDialogRenderer_org",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TeamJoinCreate_org"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryOrgCalendar",
      "storageKey": null
    }
  ],
  "type": "Org",
  "abstractKey": null
};

(node as any).hash = "1d7c006dd91f123a6fff6c71248723bd";

export default node;
