export const formatEventTitle = (
  text: string | null | undefined = undefined,
  locked: boolean | null | undefined = undefined,
) => {
  if (locked) {
    return "Busy";
  }

  return text?.trim() ? text.trim() : "(No title)";
};
