import { PaymentStatusBadgeTypeEnum } from "@clockwise/web-commons/src/util/feature-grid.util";
import classNames from "classnames";
import React from "react";

export const PlanBadge = ({
  type,
  text,
  className,
  onClick,
}: {
  type: PaymentStatusBadgeTypeEnum;
  text: string;
  className?: string;
  onClick?: () => void;
}) => {
  const colorClasses =
    type === PaymentStatusBadgeTypeEnum.Blue
      ? "cw-text-info cw-bg-info-inset"
      : "cw-text-onEmphasis cw-bg-brand-emphasis";
  const sizeClasses = text.length > 4 ? "cw-text-[10px]" : "cw-text-11";

  return (
    <div
      cw-id="home-plan-badge"
      className={classNames(
        "cw-inline-block cw-rounded cw-p-1 cw-font-body cw-text-center cw-uppercase cw-font-bold cw-cursor-pointer",
        colorClasses,
        sizeClasses,
        className,
      )}
      onClick={onClick}
    >
      {text}
    </div>
  );
};
