import { graphql } from "react-relay";

export const setPrimaryTeamMutation = graphql`
  mutation SetPrimaryTeamTeamMutation($input: setPrimaryTeamMutationInput!) {
    setPrimaryTeam(input: $input) {
      clientMutationId
      team {
        id
        teamId
        teamName
        createdBy {
          id
          type
          value
        }
        teamMembers {
          role
          person {
            id
            userId
            personId
            primaryCalendarId
            calendarAliases
            redirectPersonId
            profile {
              givenName
              familyName
              externalImageUrl
            }
          }
        }
        invitedMembers {
          role
          person {
            id
            userId
            personId
            primaryCalendarId
            calendarAliases
            redirectPersonId
            profile {
              givenName
              familyName
              externalImageUrl
            }
          }
        }
        settings {
          __typename
          ... on TeamSettings {
            __typename
            manageTeamCalendar
            slackSettings {
              __typename
              notificationEnabled
              channelId
              timeSlot
            }
            syncWFHEvents
            minimumMinutesForSyncing
            useNoMeetingDay
            noMeetingDayOfWeek
            noMeetingDayEmailOnScheduleOver
          }
          ... on GraphEntityError {
            statusCode
            message
          }
        }
        userSettings {
          __typename
          ... on TeamUserSettings {
            __typename
            useTeamCalendar
            useNoMeetingDay
          }
          ... on GraphEntityError {
            statusCode
            message
          }
        }
      }
    }
  }
`;
