import { SharedAction, SharedActions } from "./shared.actions";
import { ISharedState } from "./shared.types";

export type DispatchProxy = (action: SharedAction) => void;
export type InitialStateHandler = (
  initialState: Readonly<ISharedState>,
  otherInitialState?: Readonly<ISharedState>,
) => ISharedState;

export const initialSharedState: ISharedState = {
  networkFailureScale: 0,
};

export const sharedReducerFactory = (
  initialState = initialSharedState,
  dispatchProxy?: DispatchProxy,
  initialStateHandler?: InitialStateHandler,
  stateChangeHandler?: (
    prevState: Readonly<ISharedState>,
    newState: Readonly<ISharedState>,
  ) => void,
) => (state: ISharedState = initialState, action: SharedAction): ISharedState => {
  if (!action.isDispatched && dispatchProxy) {
    dispatchProxy({ isDispatched: true, ...action });
  }

  const newState = (() => {
    switch (action.type) {
      case SharedActions.MergeInitialStates:
        return initialStateHandler
          ? initialStateHandler(state, action.initialState)
          : { ...state, ...action.initialState };
      case SharedActions.SetNetworkFailureScale:
        return {
          ...state,
          networkFailureScale: Math.max(
            0,
            Math.min(10, action.data.networkFailureScale ?? state.networkFailureScale),
          ),
        };
      default:
        return state;
    }
  })();

  if (stateChangeHandler) {
    stateChangeHandler(state, newState);
  }

  return newState;
};
