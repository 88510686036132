import * as ISchema from "#webapp/src/__schema__";
import { AvatarRow } from "#webapp/src/components/avatar-row";
import { niceNameArrayFromPersonsWithCarryover } from "@clockwise/client-commons/src/util/event";
import { Tooltip } from "@clockwise/design-system";
import {
  CheckCircle,
  NotInterested,
  RadioButtonUnchecked,
  Warning,
} from "@clockwise/design-system/icons";
import { ProBadge } from "@clockwise/web-commons/src/ui/pro-badge";
import React from "react";
import { TeamData } from "../TeamJoinCreateTypes";

type TeamProps = {
  isSelectPrimary: boolean;
  team: TeamData;
  personMap: { [keyof: string]: ISchema.IOrgPerson };
  isInCheckout: boolean;
  isTeamPaid: boolean;
  selectedTeamId: string;
};

export const TeamContent = ({
  isSelectPrimary,
  team,
  personMap,
  isInCheckout,
  isTeamPaid,
  selectedTeamId,
}: TeamProps) => {
  let overflowCount = 0;

  const getSuggestedTeamReasons = (suggestedTeam: ISchema.ISuggestedTeam) => {
    return suggestedTeam.reasons
      .map((str) => {
        switch (str.type) {
          case ISchema.SuggestedTeamReasonType.NumOneOnOnesWithOverlap:
            return `You have ${str.value} one on one meetings with members in this team.`;
          case ISchema.SuggestedTeamReasonType.NumMeetingsWithOverlap:
          default:
            return `You have ${str.value} shared meetings with members in this team.`;
        }
      })
      .join("\n");
  };

  const persons = team.persons
    .map((personId) => personMap[personId])
    .sort((a, b) => {
      if (a.isYou && !b.isYou) {
        return -1;
      } else if (b.isYou && !a.isYou) {
        return 1;
      }

      return 0;
    })
    .filter((person, i) => {
      if (person && i < 50) {
        return true;
      }

      overflowCount++;
      return false;
    });

  const { niceNameArray, carryover } = niceNameArrayFromPersonsWithCarryover(
    persons,
    undefined,
    28,
    undefined,
    true,
    true,
    overflowCount,
  );

  const niceCarryover =
    carryover.length === 1
      ? carryover
      : carryover.length > 0
      ? [
          ...carryover.slice(0, carryover.length - 1),
          `and ${carryover[carryover.length - 1]}`,
        ].join(", ")
      : undefined;

  const showWarning = team.disabled && !isInCheckout;

  const niceNames = niceNameArray.map((name, i) => {
    if (i < niceNameArray.length - 1 || !niceCarryover) {
      return <span key={i}>{name}</span>;
    }

    return (
      <Tooltip title={niceCarryover} key={i}>
        <span key={i} className="cw-underline cw-decoration-dashed	">
          {name}
        </span>
      </Tooltip>
    );
  });

  const getIcon = () => {
    return team.disabled ? (
      <Tooltip title="This team is already on the Teams plan.">
        <NotInterested />
      </Tooltip>
    ) : team.id === selectedTeamId ? (
      <CheckCircle />
    ) : (
      <RadioButtonUnchecked />
    );
  };

  return (
    <div className={`cw-w-full cw-p-3 ${showWarning ? "cw-flex cw-flex-col" : ""}`}>
      <div className="cw-flex cw-items-center cw-gap-4">
        {getIcon()}
        <div className="cw-flex cw-flex-1 cw-items-center cw-overflow-hidden">
          <div className="cw-flex-1">
            <div className="cw-body-md cw-text-default cw-whitespace-nowrap cw-flex cw-items-center">
              {team.name}
              {!isSelectPrimary && team.suggestedTeam && (
                <Tooltip title={getSuggestedTeamReasons(team.suggestedTeam)}>
                  <span className="cw-ml-2 cw-cursor-pointer cw-text-positive cw-body-base cw-border-b cw-border-positive cw-border-dashed">
                    Suggested
                  </span>
                </Tooltip>
              )}
              {isInCheckout && isTeamPaid && <ProBadge className="cw-ml-3" />}
            </div>
            {niceNames.length > 0 ? (
              <div className="cw-body-base cw-text-muted">{niceNames}</div>
            ) : null}
          </div>
          <AvatarRow
            orgPersons={persons}
            extraOverflowCount={overflowCount}
            size="medium"
            maxAvatarCount={3}
            disableAnimation={true}
          />
        </div>
      </div>
      {showWarning && (
        <div className="cw-flex cw-items-center">
          <Warning className="cw-text-warning cw-w-4 cw-mx-1" />
          <span className="cw-grow cw-body-sm cw-text-warning">
            This team has reached its maximum size of 50 people
          </span>
        </div>
      )}
    </div>
  );
};
