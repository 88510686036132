import { DateTime } from "luxon";
import { ExtensionURLs } from "../constants/stores.constant";
import { lastSignInDate } from "./local-storage";

declare const InstallTrigger: Object | undefined;

const isWindow = typeof window !== "undefined";
const isNavigator = typeof navigator !== "undefined";
const maybeNavigator = isNavigator ? navigator : undefined;
const maybeWindow = isWindow ? window : undefined;

// Edge also has a .chrome property, but we don't support Edge!
export const isChrome = !!(maybeWindow as any)?.chrome;
export const isFirefox = typeof InstallTrigger !== "undefined";
export const isBrave = (maybeNavigator as any)?.brave?.isBrave();
export const isSafari = !!(maybeWindow as any)?.safari;
export const isElectron =
  (maybeNavigator as any)?.userAgent.toLowerCase().indexOf(" electron/") > -1;
export const electronAppVersion =
  ((maybeWindow as any)?.versions?.appVersion as string) || undefined;

const getBrowserName = (): string | null => {
  if (isChrome) return "Chrome";
  if (isFirefox) return "Firefox";
  if (isBrave) return "Brave";
  if (isSafari) return "Safari";
  return null;
};

const getExtensionURLForBrowser = (): string | null => {
  if (isChrome) {
    return ExtensionURLs.chrome;
  }
  if (isFirefox) {
    return ExtensionURLs.firefox;
  }

  if (isBrave) {
    return ExtensionURLs.brave;
  }

  if (isSafari) {
    return ExtensionURLs.safari;
  }

  return null;
};

export const browserName = getBrowserName();
export const extensionURLForBrowser = getExtensionURLForBrowser();

export const getRandomIntInRange = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/*
 * All Uber users were signed out after their breach and we can no longer connect to their Gcal.
 * In an effort to bring them back, we are doing a limited time differentiation of the Chrome login screen as
 * well as the extension open button.
 * We are starting this effort Monday, October 17th and running through the week of Nov 1st.
 */
export const showUberCantConnect = (windowUserEmail: string) =>
  windowUserEmail.includes("@uber.com") &&
  DateTime.now().startOf("day") >= DateTime.fromISO("2022-10-17") &&
  DateTime.now().startOf("day") < DateTime.fromISO("2022-11-05") &&
  !lastSignInDate.get(); // If they sign back in, end the special treatment.
