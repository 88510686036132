import { graphql } from "react-relay";

export const fragments = {
  viewer: graphql`
    fragment OrgUserSelectForm_viewer on Viewer
    @argumentDefinitions(
      queryInput: { type: "String!", defaultValue: "" }
      orgRelayId: { type: "ID!", defaultValue: "" }
      excludeCalendars: { type: "[String]" }
    ) {
      orgPersonQueryResultErrorable(
        query: $queryInput
        orgRelayId: $orgRelayId
        excludeCalendars: $excludeCalendars
      ) {
        __typename
        ... on GraphEntityError {
          statusCode
          message
        }
        ... on OrgPersonQueryResult {
          query
          persons {
            userId
            primaryCalendarId
            calendarAliases
            profile {
              givenName
              familyName
              externalImageUrl
            }
            isYou
          }
        }
      }
    }
  `,
  org: graphql`
    fragment OrgUserSelectForm_org on Org {
      id
      experiments

      currentOrgPersons {
        __typename
        ... on GraphEntityError {
          message
        }
        ... on OrgPersonList {
          list {
            userId
            primaryCalendarId
            calendarAliases
            profile {
              givenName
              familyName
              externalImageUrl
            }
            isYou
          }
        }
      }
    }
  `,
};

export const refetchQuery = graphql`
  query OrgUserSelectFormQuery(
    $queryInput: String!
    $orgRelayId: ID!
    $excludeCalendars: [String]
  ) {
    viewer {
      ... on Viewer {
        ...OrgUserSelectForm_viewer
          @arguments(
            queryInput: $queryInput
            orgRelayId: $orgRelayId
            excludeCalendars: $excludeCalendars
          )
      }
    }
  }
`;
