import { isLocalStorageAvailable } from "#webapp/src/state/local-storage";
import { logger } from "#webapp/src/util/logger.util";
import { getSiteUrl } from "@clockwise/client-commons/src/config/api";
import { useCallback, useEffect, useRef, useState } from "react";

export const canAuth = () => {
  // Since our OAuth flows require validating a nonce from separate windows, local storage is a requirement
  return isLocalStorageAvailable();
};

/**
 * Auth response message utils
 */

export type AuthResponsePayload = {
  success: boolean;
  message: string;
  authCode?: string;
  error?: string;
};

export const sendMessageToParentWindow = (payload: AuthResponsePayload) => {
  const targetOrigin = new URL(getSiteUrl()).origin;
  const parentWindow: Window | undefined = window.opener;
  if (!parentWindow?.postMessage) {
    return logger.error("failed to find window.opener for auth redirect");
  }

  parentWindow.postMessage(payload, targetOrigin);
};

export const OAUTH_COMPLETE_MESSAGE = "cw-oauth-complete";

const isAuthResponseMessage = (event: MessageEvent): event is MessageEvent<AuthResponsePayload> => {
  const redirectOrigin = new URL(getSiteUrl()).origin;
  return event.origin === redirectOrigin && event.data?.message === OAUTH_COMPLETE_MESSAGE;
};

/**
 * Auth hooks
 */

type OAuthPromptOpts = {
  onSuccess?: (result: { code: string }) => void;
  onFailure?: (result: { error: string }) => void;
};

const useMessageHandler = <E extends MessageEvent>() => {
  const messageHandler = useRef<(e: E) => void>();
  const unlisten = useCallback(() => {
    if (messageHandler.current) {
      window.removeEventListener("message", messageHandler.current);
    }
    messageHandler.current = undefined;
  }, []);
  const listen = useCallback(
    (handler: (e: E) => void) => {
      unlisten();
      window.addEventListener("message", handler);
      messageHandler.current = handler;
    },
    [unlisten],
  );

  // Remove active listeners before unmounting to prevent memory leak
  useEffect(() => unlisten, [unlisten]);
  return { listen, unlisten };
};

export const makeOAuthHook = (buildOAuthUrl: (scopes: string[]) => string) => () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState<{ code: string }>();

  const { listen, unlisten } = useMessageHandler<MessageEvent<AuthResponsePayload>>();

  const prompt = useCallback(
    (scopes: string[], { onSuccess, onFailure }: OAuthPromptOpts = {}) => {
      setLoading(true);

      const oauthUrl = buildOAuthUrl(scopes);

      listen((event) => {
        if (!isAuthResponseMessage(event)) return;

        // We got the correct event, no need to listen anymore
        unlisten();

        const { success, authCode, error } = event.data;

        if (error || !success || !authCode) {
          const errorMessage = error || "Invalid auth response";
          setError(errorMessage);
          onFailure?.({ error: errorMessage });
        } else {
          const response = { code: authCode };
          setData(response);
          setError("");
          onSuccess?.(response);
        }
        setLoading(false);
      });

      const windowFeatures = [
        "scrollbars=yes,resizable=yes",
        "toolbar=no,directories=no,status=no,menubar=no,copyhistory=no",
        "width=600,height=870",
      ].join();
      window.open(oauthUrl, "_blank", windowFeatures);
    },
    [listen, unlisten],
  );

  return {
    prompt,
    loading,
    error,
    data,
  } as const;
};

export const m365NonceUrlParam = "m365Nonce";
