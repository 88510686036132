import classNames from "classnames";
import React, { MouseEventHandler, ReactNode } from "react";
import { CwIdProps } from "../types/cw-id";

const isButtonLink = (
  onClick?: MouseEventHandler<HTMLAnchorElement> | MouseEventHandler<HTMLButtonElement>,
  href?: string,
): onClick is MouseEventHandler<HTMLButtonElement> => !!onClick && !href;

interface Props extends CwIdProps {
  onEmphasis?: boolean;
  href?: string;
  rel?: string;
  target?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  children?: ReactNode;
}

export function Link({ href, onEmphasis, "cw-id": cwId, children, onClick, rel, target }: Props) {
  const colorClasses = onEmphasis
    ? "cw-text-onEmphasis cw-border-onEmphasis hover:cw-text-onEmphasis-hover active:cw-text-onEmphasis-pressed"
    : "cw-text-brand cw-border-brand hover:cw-text-brand-hover active:cw-text-brand-pressed";

  if (isButtonLink(onClick, href)) {
    // Render a button if we don't have an href for a legal `a` tag.
    return (
      <button
        className={classNames(
          "cw-bg-transparent cw-border-none cw-cursor-pointer cw-p-0 cw-inline",
          "cw-pointer cw-transition-colors cw-duration-300",
          "cw-underline cw-underline-offset-2 cw-decoration-1 cw-decoration-dashed",
          colorClasses,
          "hover:cw-decoration-solid",
        )}
        style={{ fontSize: "inherit", fontWeight: "inherit", lineHeight: "inherit" }}
        onClick={onClick}
        cw-id={cwId}
      >
        {children}
      </button>
    );
  }

  return (
    <a
      className={classNames(
        "cw-pointer cw-transition-colors cw-duration-300",
        "cw-inherit  cw-underline cw-underline-offset-2 cw-decoration-1 cw-decoration-dashed",
        colorClasses,
        "hover:cw-decoration-solid",
      )}
      style={{ fontSize: "inherit", fontWeight: "inherit", lineHeight: "inherit" }}
      href={href}
      onClick={onClick}
      target={target}
      rel={rel}
      cw-id={cwId}
    >
      {children}
    </a>
  );
}
