import classnames from "classnames";
import * as React from "react";
import { colors, helpers } from "../../styles/color.styles";

export interface ProBadgeProps {
  className?: string;
  text?: string;
}

export const ProBadge = ({ className, text = "Teams" }: ProBadgeProps) => {
  return (
    <span
      className={classnames(
        "cw-inline-block cw-p-1 cw-text-[10px] cw-leading-tight cw-font-bold cw-rounded cw-uppercase cw-cursor-default",
        className,
      )}
      style={{
        color: colors.blueDusk.lighter,
        background: helpers.opacity(colors.blueDusk.lighter, 0.3),
      }}
    >
      {text}
    </span>
  );
};
