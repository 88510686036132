/**
 * @generated SignedSource<<7e8e9a3ccfdb69cae8253bcaf6f0596a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddSlackAccountV2MutationInput = {
  clientMutationId?: string | null;
  orgRelayId: string;
  redirect: string;
  verificationCode: string;
};
export type AddSlackAccountV2Mutation$variables = {
  input: AddSlackAccountV2MutationInput;
};
export type AddSlackAccountV2Mutation$data = {
  readonly addSlackAccountV2: {
    readonly clientMutationId: string | null;
  } | null;
};
export type AddSlackAccountV2Mutation = {
  variables: AddSlackAccountV2Mutation$variables;
  response: AddSlackAccountV2Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddSlackAccountV2MutationPayload",
    "kind": "LinkedField",
    "name": "addSlackAccountV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddSlackAccountV2Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddSlackAccountV2Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "623744ca739b4c48a2549b72ab9c9b0b",
    "id": null,
    "metadata": {},
    "name": "AddSlackAccountV2Mutation",
    "operationKind": "mutation",
    "text": "mutation AddSlackAccountV2Mutation(\n  $input: AddSlackAccountV2MutationInput!\n) {\n  addSlackAccountV2(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "c944ecdccb4fc6a33d108ef4868114df";

export default node;
