import React, { ReactNode, createContext, useContext, useReducer } from "react";
import invariant from "tiny-invariant";
import { OnDemandDefragQuery } from "./apollo/__generated__/OnDemandDefrag.generated";

type SchedulingProposalDefrag = NonNullable<
  OnDemandDefragQuery["viewer"]["user"]
>["onDemandDefrag"];

const INIT_ODD_PROPOSAL: OddProposal = {
  visible: false,
  proposal: null,
};

export type Person = {
  email: string;
  displayName: string;
  givenName: string | null;
  familyName: string | null;
  externalImageUrl: string | null;
  isMe: boolean;
  id: string;
};

export type Attendee = {
  id: string;
  isOptional: boolean;
  person: Person;
  user: {
    id: string;
  } | null;
};

export type OddProposal = {
  visible: boolean;
  proposal: SchedulingProposalDefrag | null;
};

type DefragProposalReadObj = {
  onDemandDefrag: OddProposal;
};

type DefragProposalWriteObj = {
  handleOnDemandDefragRequest: (proposal: SchedulingProposalDefrag | null) => void;
  cancelOnDemandDefragRequest: () => void;
  removeDiffInOnDemandDefrag: (diffId: string) => void;
};

type InitOddProposalAction = {
  type: "InitOddProposal";
  proposal: OddProposal;
};

type ClearOddProposalAction = {
  type: "ClearOddProposal";
};

type RemoveDiffProposalAction = {
  type: "RemoveDiffOddProposal";
  diffId: string;
};

type OddProposalAction = InitOddProposalAction | ClearOddProposalAction | RemoveDiffProposalAction;

function oddProposalReducer(state: OddProposal, action: OddProposalAction) {
  switch (action.type) {
    case "InitOddProposal":
      return { ...state, ...action.proposal };
    case "ClearOddProposal":
      return { ...INIT_ODD_PROPOSAL };
    case "RemoveDiffOddProposal":
      if (state.proposal) {
        const newDiffs = state.proposal?.diffBlocks?.[0].diffs?.filter(
          (diff) => diff.id !== action.diffId,
        );
        return {
          ...state,
          proposal: ({
            ...state.proposal,
            diffBlocks: [{ ...state.proposal?.diffBlocks, diffs: newDiffs }],
          } as unknown) as SchedulingProposalDefrag,
        };
      }
      return { ...state };
  }
}

const ReadContext = createContext<DefragProposalReadObj>({
  onDemandDefrag: INIT_ODD_PROPOSAL,
});
const WriteContext = createContext<DefragProposalWriteObj | null>(null);

export const DefragProposalProvider = ({ children }: { children: ReactNode }) => {
  const [onDemandDefrag, dispatchODD] = useReducer(oddProposalReducer, INIT_ODD_PROPOSAL);

  const handleOddRequest = (proposal: SchedulingProposalDefrag | null) => {
    if (proposal) {
      dispatchODD({
        type: "InitOddProposal",
        proposal: {
          visible: true,
          proposal,
        },
      });
    }
  };

  const removeOddDiff = (diffId: string) => {
    dispatchODD({ type: "RemoveDiffOddProposal", diffId });
  };

  const clearOddRequest = () => {
    dispatchODD({ type: "ClearOddProposal" });
  };

  return (
    <WriteContext.Provider
      value={{
        handleOnDemandDefragRequest: handleOddRequest,
        cancelOnDemandDefragRequest: clearOddRequest,
        removeDiffInOnDemandDefrag: removeOddDiff,
      }}
    >
      <ReadContext.Provider value={{ onDemandDefrag: onDemandDefrag }}>
        {children}
      </ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const useDefragProposal = () => useContext(ReadContext);
export const useUpdateDefragProposal = () => {
  const context = useContext(WriteContext);
  invariant(context, "useUpdateDefragProposal must be within DefragProposalProvider");
  return context;
};
