import { getSiteUrl } from "@clockwise/client-commons/src/config/api";
import { paths } from "@clockwise/client-commons/src/constants/site";
import { appPaths, webappPathRoot } from "../constants/route.constants";

export const billingPageUrl = `${getSiteUrl()}${appPaths.plansAndBilling}`;
export const pricingPageUrl = `${getSiteUrl()}/app${paths.pricing}`;
export const trialExtensionUrl = `${getSiteUrl()}${webappPathRoot}?trialExtension=true`;
export const openBillingPage = () => {
  window.open(billingPageUrl, "billing");
};

export const openPricingPage = () => {
  window.open(pricingPageUrl, "pricing");
};

export const openTrialExtensionDialog = () => {
  window.open(trialExtensionUrl, "trialExtension");
};
