import { noop } from "lodash";
import React, { ReactNode, createContext, useContext } from "react";
import invariant from "tiny-invariant";

type EventDiffId = string;

const ReadContext = createContext<EventDiffId | null>(null);
const WriteContext = createContext<(hoverEvent: EventDiffId | null) => void>(noop);

type ProviderProps = {
  children: ReactNode;
  initValue?: EventDiffId | null;
};

export const HoverEventProvider = ({ children, initValue = null }: ProviderProps) => {
  const [hoverEvent, setHoverEvent] = React.useState<EventDiffId | null>(initValue);

  return (
    <WriteContext.Provider value={setHoverEvent}>
      <ReadContext.Provider value={hoverEvent}>{children}</ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const useReadHoverEvent = () => useContext(ReadContext);

export const useUpdateHoverEvent = () => {
  const context = useContext(WriteContext);
  invariant(context, "useUpdateHoverEvent must be within HoverEventProvider");
  return context;
};
