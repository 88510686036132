import { Button } from "@clockwise/design-system";
import { Close } from "@clockwise/design-system/icons";
import { narrowMediaQuery } from "@clockwise/web-commons/src/ui/scheduling-link/scheduling-link.styles";
import { Dialog, Paper, PaperProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { animated, config, useSpring } from "@react-spring/web";
import React from "react";
import { CopyBestTimesContent } from "./CopyBestTimesContent";

interface SuggestedSlot {
  timeSlot: string;
}

const useStyles = makeStyles({
  paper: {
    padding: "26px 34px 30px",
    minWidth: 320,
    maxWidth: 550,
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    borderRadius: 8,

    [narrowMediaQuery]: {
      height: "100%",
    },
  },
});

const AnimatedPaper = animated(Paper);

export interface ICopyBestTimesModalProps {
  dialogOpen: boolean;
  fullscreen: boolean;
  onClose: () => void;
  onCopy: (text: string) => void;
  linkUrl: string;
  linkName: string;
  name: string;
  slug: string;
  duration: number;
  durations: number[];
  onDurationChange: (duration: number) => void;
  windowSizeDays?: number | null;
  multipleUser: boolean;
  bestTimeSlots: SuggestedSlot[];
  loading: boolean;
  errorMsg: string | null;
  numDaysToShow: number;
  setNumDaysToShow: (numDaysToShow: number) => void;
  targetTimeZone: string;
  setTargetTimezone: (targetTimeZone: string) => void;
  renderedFrom: string;
  renderContentInModal?: boolean;
}

export const CopyBestTimesModal = ({
  dialogOpen = true,
  fullscreen = false,
  onClose,
  onCopy,
  linkUrl,
  linkName,
  name,
  slug,
  duration,
  durations,
  onDurationChange,
  windowSizeDays,
  multipleUser,
  bestTimeSlots,
  loading,
  errorMsg,
  numDaysToShow,
  setNumDaysToShow,
  targetTimeZone,
  setTargetTimezone,
  renderedFrom,
  renderContentInModal = true,
}: ICopyBestTimesModalProps) => {
  const classes = useStyles();

  const modalStyles = useSpring({
    from: {
      scale: 0.83,
      opacity: 0,
    },
    to: {
      scale: 1,
      opacity: 1,
    },
    config: config.stiff,
  });

  const content = () => {
    return (
      <CopyBestTimesContent
        onCopy={onCopy}
        linkUrl={linkUrl}
        linkName={linkName}
        name={name}
        slug={slug}
        duration={duration}
        durations={durations}
        onDurationChange={onDurationChange}
        windowSizeDays={windowSizeDays}
        multipleUser={multipleUser}
        bestTimeSlots={bestTimeSlots}
        loading={loading}
        errorMsg={errorMsg}
        numDaysToShow={numDaysToShow}
        setNumDaysToShow={setNumDaysToShow}
        targetTimeZone={targetTimeZone}
        setTargetTimezone={setTargetTimezone}
        renderedFrom={renderedFrom}
      />
    );
  };

  const renderWithModal = () => {
    return (
      <Dialog
        open={dialogOpen}
        onClose={onClose}
        fullScreen={fullscreen}
        disableRestoreFocus
        disableScrollLock
        PaperComponent={AnimatedPaper as React.ComponentType<PaperProps>}
        PaperProps={{
          style: {
            // PaperProps wants CSS properties so must cast the animated values to number
            scale: (modalStyles.scale as unknown) as number,
            opacity: (modalStyles.opacity as unknown) as number,
            visibility: dialogOpen ? "visible" : "hidden",
          },
          className: classes.paper,
        }}
        cw-id="link-copy-best-times-modal"
      >
        <div className="cw-flex cw-flex-col cw-justify-between cw-items-center cw-mb-3">
          <div className="cw-flex cw-w-full cw-align-middle">
            <h2 className="cw-heading-xl cw-align-middle cw-flex-grow">Share your availability</h2>
            <div className="-cw-mr-[10px]">
              <Button
                variant="text"
                size="large"
                startIcon={Close}
                onClick={onClose}
                cw-id="close-copy-best-times-modal"
              />
            </div>
          </div>
        </div>
        {content()}
      </Dialog>
    );
  };

  return (
    <>
      {renderContentInModal && renderWithModal()}
      {!renderContentInModal && content()}
    </>
  );
};
