// schema
import * as ISchema from "#webapp/src/__schema__";
import { logger } from "#webapp/src/util/logger.util";
//////////////////
// Org Level Feature Grid Helpers
//////////////////

export function getFeatureGridFromOrg(org: ISchema.IOrg) {
  if (!org.featureGrid || !org.featureGrid.__typename) {
    logger.error("incomplete fragment getFeatureGridFromOrg");
    return null;
  }

  if (org.featureGrid.__typename !== "FeatureGrid") {
    logger.error("failed to getFeatureGridFromOrg");
    return null;
  }

  return org.featureGrid;
}

export function getStateMetadataFromOrg(org: ISchema.IOrg) {
  const featureGrid = getFeatureGridFromOrg(org);
  if (!featureGrid) {
    return null;
  }

  return featureGrid.stateMetadata;
}

export function getUserMetadataFromOrg(org: ISchema.IOrg) {
  const stateMetadata = getStateMetadataFromOrg(org);

  if (!stateMetadata) {
    return null;
  }

  return stateMetadata.userMetadata;
}

export function getAugmentedTrialMetadataFromOrg(org: ISchema.IOrg) {
  const userMetadata = getUserMetadataFromOrg(org);
  if (!userMetadata) {
    return null;
  }

  return userMetadata.trialMetadata ? userMetadata.trialMetadata : null;
}

export const getReduxFeatureGridFromOrg = (org: ISchema.IOrg) => {
  const featureGrid = getFeatureGridFromOrg(org);
  if (featureGrid) {
    const trialMetadata = getAugmentedTrialMetadataFromOrg(org);
    return {
      ...featureGrid.featureStates,
      trialMetadata: trialMetadata ? trialMetadata : null,
      hasPaidPlan: !!featureGrid.stateMetadata?.primaryBillingGroupMetadata,
    };
  }
  return null;
};
//////////////////
// Event Level Feature Grid Helpers
//////////////////
export function getOrganizerFeatureStatesFromEvent(event: ISchema.IEvent) {
  if (!event.featureStatesForOrganizer) {
    return null;
  }

  if (!event.featureStatesForOrganizer.__typename) {
    logger.error("incomplete fragment getOrganizerFeatureStatesFromEvent");
    return null;
  }

  if (event.featureStatesForOrganizer.__typename !== "FeatureStates") {
    logger.error("failed to getOrganizerFeatureStatesFromEvent");
    return null;
  }

  return event.featureStatesForOrganizer;
}
