import { Button, Tooltip } from "@clockwise/design-system";
import { Close, Menu } from "@clockwise/design-system/icons";
import classNames from "classnames";
import React from "react";
import { webappDefaultWindowName } from "../../constants/route.constants";
import { TrackingEvents, track } from "../../util/analytics.util";
import { getUrlForFeature } from "../../util/routes.util";
import { LogoHorizontal } from "../logo";

type Props = {
  showMenu?: boolean;
  showCollapse?: boolean;
  badge?: JSX.Element;
  onClickMenu?: () => void;
  onClickCollapse: () => void;
};

export function Nav({
  badge,
  showMenu = false,
  showCollapse = true,
  onClickMenu,
  onClickCollapse,
}: Props) {
  const trackAppClick = () => {
    track(TrackingEvents.APP_NAV.LOGO);
  };

  return (
    <div className="cw-flex cw-items-center cw-h-[60px] cw-justify-between">
      <div className={classNames("cw-ml-2", showMenu ? "cw-visible" : "cw-invisible")}>
        <Tooltip title="Menu">
          <Button variant="text" startIcon={Menu} onClick={onClickMenu} />
        </Tooltip>
      </div>
      <div className="cw-flex cw-items-center cw-gap-2">
        <a href={getUrlForFeature("ai")} target={webappDefaultWindowName} onClick={trackAppClick}>
          <LogoHorizontal className="cw-w-[132px]" />
        </a>
        {badge}
      </div>
      <Tooltip title="Close">
        <div
          className={classNames(
            "cw-mr-2 cw-transition-opacity cw-ease-in-out",
            showCollapse ? "cw-opacity-1" : "cw-opacity-0 cw-pointer-events-none cw-w-0",
          )}
        >
          <Button variant="text" rounded startIcon={Close} id="cwClose" onClick={onClickCollapse} />
        </div>
      </Tooltip>
    </div>
  );
}
