import { graphql } from "react-relay";

export const sendBillingGroupRequestEmailMutation = graphql`
  mutation SendBillingGroupRequestEmailMutation(
    $input: SendBillingGroupRequestEmailMutationInput!
  ) {
    sendBillingGroupRequestEmail(input: $input) {
      clientMutationId
    }
  }
`;
