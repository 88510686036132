import { CLOCKWISE_DOMAINS, PERSONAL_DOMAINS, SPECIAL_GOOGLE_DOMAINS } from "../constants/email";

//////////////////
// TYPES
//////////////////
export enum InvalidEmailReason {
  Incomplete,
  InvalidFormat,
  InvalidCharacters,
  PersonalDomain,
}

//////////////////////
// EMAIL VALIDATION
/////////////////////
// tests only for whitespace or multiple periods
function isValidCharacters(email: string) {
  const invalidChars = email.match(/\s|\.\.+/g);
  return invalidChars === null;
}

// regex from: https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression#201378
export function isValidEmailFormat(email: string) {
  const matches = email
    .trim()
    .match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  return !!matches && matches.length > 0;
}

// check our list of personal domains
export function isPersonalDomain(email: string) {
  const domain = getDomainOffEmail(email);
  return !!domain && PERSONAL_DOMAINS.indexOf(domain) > -1;
}

export function isClockwiseDomain(email: string) {
  const domain = getDomainOffEmail(email);
  return !!domain && CLOCKWISE_DOMAINS.indexOf(domain) > -1;
}

export function isSpecialGoogleDomain(email: string) {
  const domain = getDomainOffEmail(email);
  return !!domain && SPECIAL_GOOGLE_DOMAINS.indexOf(domain) > -1;
}

export function isValidEncodedGoogleEventId(email: string) {
  return isValidCharacters(email) && (isValidEmailFormat(email) || email.endsWith("@g")); // weird way to handle cut off decoded google ids that happen with group ids..)
}

export function maybeDecodeGoogleEventId(email: string | undefined) {
  if (email && email.endsWith("@g")) {
    return email.substr(0, email.length - 2) + "@group.calendar.google.com";
  }

  return email;
}

// finally, roll it all together
export function isValidEmail(email: string) {
  const validCharacters = isValidCharacters(email);
  const validFormat = isValidEmailFormat(email);
  const notPersonalDomain = !isPersonalDomain(email);
  const notSpecialGoogleDomain = !isSpecialGoogleDomain(email);
  const valid = validCharacters && validFormat && notPersonalDomain && notSpecialGoogleDomain;

  return {
    validCharacters,
    validFormat,
    notPersonalDomain,
    notSpecialGoogleDomain,
    valid,
  };
}

/////////////////////
// DOMAIN HANDLING
/////////////////////
// email format should be 'foo@bar...'
export function getDomainOffEmail(email: string) {
  const splitEmail = (email && email.trim().split("@")) || [];
  return splitEmail[1] || undefined;
}

export function isEmailInDomains(email: string, domains: string[]) {
  // org.domains is always lower-cased versions of all domains associated with an org
  const domainSet = new Set(domains);
  // because email could take a case-sensitive email or a lower-cased calendarId
  // we are gonna lower case it so we're comparing lower cased domains
  const emailDomain = getDomainOffEmail(email)?.toLowerCase();

  return !!emailDomain && domainSet.has(emailDomain);
}
