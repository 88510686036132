/**
 * @generated SignedSource<<af18f30d59dda6a01d1e43beebda7c5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AutopilotEventStatusEnum = "CanMove" | "EventExceedsDefragRange" | "EventOutsideTimeRange" | "External" | "NoDuration" | "OverQuota" | "Paused" | "Pinned" | "PrivateEvent" | "SmartHold" | "TrialExpired" | "%future added value";
export type SubjectUrnTypeEnum = "Email" | "Integration" | "Org" | "Person" | "Phone" | "Services" | "Team" | "User" | "%future added value";
export type TimeSlot = "T_00_00" | "T_00_30" | "T_01_00" | "T_01_30" | "T_02_00" | "T_02_30" | "T_03_00" | "T_03_30" | "T_04_00" | "T_04_30" | "T_05_00" | "T_05_30" | "T_06_00" | "T_06_30" | "T_07_00" | "T_07_30" | "T_08_00" | "T_08_30" | "T_09_00" | "T_09_30" | "T_10_00" | "T_10_30" | "T_11_00" | "T_11_30" | "T_12_00" | "T_12_30" | "T_13_00" | "T_13_30" | "T_14_00" | "T_14_30" | "T_15_00" | "T_15_30" | "T_16_00" | "T_16_30" | "T_17_00" | "T_17_30" | "T_18_00" | "T_18_30" | "T_19_00" | "T_19_30" | "T_20_00" | "T_20_30" | "T_21_00" | "T_21_30" | "T_22_00" | "T_22_30" | "T_23_00" | "T_23_30" | "%future added value";
export type BatchAddAndRemoveOrgTagsMutationInput = {
  clientMutationId?: string | null;
  tagPairings?: ReadonlyArray<BatchAddAndRemoveOrgTagsTagPairings> | null;
};
export type BatchAddAndRemoveOrgTagsTagPairings = {
  calendarId?: string | null;
  eventParentRelayId: string;
  moveRangeV3?: MoveRangeInLocalTimeV3Input | null;
  orgRelayId: string;
  tagNamesToRemove: ReadonlyArray<string>;
  tagsToAdd?: ReadonlyArray<TagInput> | null;
};
export type MoveRangeInLocalTimeV3Input = {
  allowedEndTimeInclusive?: DayOffsetTimeSlotInput | null;
  allowedStartTimeInclusive?: DayOffsetTimeSlotInput | null;
};
export type DayOffsetTimeSlotInput = {
  dayOffset: number;
  timeSlot: TimeSlot;
};
export type TagInput = {
  name: string;
  value?: string | null;
};
export type BatchAddAndRemoveOrgTagsMutation$variables = {
  input: BatchAddAndRemoveOrgTagsMutationInput;
};
export type BatchAddAndRemoveOrgTagsMutation$data = {
  readonly batchAddAndRemoveOrgTags: {
    readonly eventParents: ReadonlyArray<{
      readonly events: ReadonlyArray<{
        readonly autopilotEventStatus: {
          readonly __typename: "AutopilotEventStatus";
          readonly status: AutopilotEventStatusEnum | null;
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        } | null;
        readonly annotatedEvent: {
          readonly id: string;
          readonly externalEventId: string | null;
          readonly orgTags: ReadonlyArray<{
            readonly tag: string;
            readonly state: {
              readonly value: string | null;
              readonly subjectType: SubjectUrnTypeEnum;
              readonly subjectValue: string;
              readonly lastModified: number;
              readonly active: boolean;
            };
          }> | null;
        } | null;
        readonly annotatedRecurringEvent: {
          readonly id: string;
          readonly externalEventId: string | null;
          readonly orgTags: ReadonlyArray<{
            readonly tag: string;
            readonly state: {
              readonly value: string | null;
              readonly subjectType: SubjectUrnTypeEnum;
              readonly subjectValue: string;
              readonly lastModified: number;
              readonly active: boolean;
            };
          }> | null;
        } | null;
        readonly moveRangeInLocalTimeV3: {
          readonly __typename: "MoveRangeInLocalTimeV3";
          readonly allowedStartTimeInclusive: {
            readonly dayOffset: number;
            readonly timeSlot: TimeSlot;
          } | null;
          readonly allowedEndTimeInclusive: {
            readonly dayOffset: number;
            readonly timeSlot: TimeSlot;
          } | null;
        } | {
          readonly __typename: "GraphEntityError";
          readonly message: string | null;
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        };
      }>;
    }> | null;
  } | null;
};
export type BatchAddAndRemoveOrgTagsMutation = {
  variables: BatchAddAndRemoveOrgTagsMutation$variables;
  response: BatchAddAndRemoveOrgTagsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "autopilotEventStatus",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "type": "AutopilotEventStatus",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "externalEventId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "OrgTag",
    "kind": "LinkedField",
    "name": "orgTags",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tag",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TagState",
        "kind": "LinkedField",
        "name": "state",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subjectType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subjectValue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastModified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "AnnotatedEvent",
  "kind": "LinkedField",
  "name": "annotatedEvent",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AnnotatedEvent",
  "kind": "LinkedField",
  "name": "annotatedRecurringEvent",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dayOffset",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "timeSlot",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "moveRangeInLocalTimeV3",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DayOffsetTimeSlot",
          "kind": "LinkedField",
          "name": "allowedStartTimeInclusive",
          "plural": false,
          "selections": (v8/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DayOffsetTimeSlot",
          "kind": "LinkedField",
          "name": "allowedEndTimeInclusive",
          "plural": false,
          "selections": (v8/*: any*/),
          "storageKey": null
        }
      ],
      "type": "MoveRangeInLocalTimeV3",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }
      ],
      "type": "GraphEntityError",
      "abstractKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BatchAddAndRemoveOrgTagsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BatchAddAndRemoveOrgTagsMutationPayload",
        "kind": "LinkedField",
        "name": "batchAddAndRemoveOrgTags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventParent",
            "kind": "LinkedField",
            "name": "eventParents",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Event",
                "kind": "LinkedField",
                "name": "events",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BatchAddAndRemoveOrgTagsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BatchAddAndRemoveOrgTagsMutationPayload",
        "kind": "LinkedField",
        "name": "batchAddAndRemoveOrgTags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventParent",
            "kind": "LinkedField",
            "name": "eventParents",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Event",
                "kind": "LinkedField",
                "name": "events",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e4b7239a782a3db40157b2f5227c6e8",
    "id": null,
    "metadata": {},
    "name": "BatchAddAndRemoveOrgTagsMutation",
    "operationKind": "mutation",
    "text": "mutation BatchAddAndRemoveOrgTagsMutation(\n  $input: BatchAddAndRemoveOrgTagsMutationInput!\n) {\n  batchAddAndRemoveOrgTags(input: $input) {\n    eventParents {\n      events {\n        autopilotEventStatus {\n          __typename\n          ... on AutopilotEventStatus {\n            status\n          }\n        }\n        annotatedEvent {\n          id\n          externalEventId\n          orgTags {\n            tag\n            state {\n              value\n              subjectType\n              subjectValue\n              lastModified\n              active\n            }\n          }\n        }\n        annotatedRecurringEvent {\n          id\n          externalEventId\n          orgTags {\n            tag\n            state {\n              value\n              subjectType\n              subjectValue\n              lastModified\n              active\n            }\n          }\n        }\n        moveRangeInLocalTimeV3 {\n          __typename\n          ... on MoveRangeInLocalTimeV3 {\n            allowedStartTimeInclusive {\n              dayOffset\n              timeSlot\n            }\n            allowedEndTimeInclusive {\n              dayOffset\n              timeSlot\n            }\n          }\n          ... on GraphEntityError {\n            message\n          }\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "163c04899babe797aa8dc03591dce987";

export default node;
