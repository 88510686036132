/**
 * @generated SignedSource<<69e4cb6aca36c022c6658517376387f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type deleteTeamMutationInput = {
  clientMutationId?: string | null;
  orgRelayId: string;
  teamId: string;
};
export type DeleteTeamMutation$variables = {
  input: deleteTeamMutationInput;
};
export type DeleteTeamMutation$data = {
  readonly deleteTeam: {
    readonly deletedTeamId: string;
  } | null;
};
export type DeleteTeamMutation = {
  variables: DeleteTeamMutation$variables;
  response: DeleteTeamMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "deleteTeamMutationPayload",
    "kind": "LinkedField",
    "name": "deleteTeam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedTeamId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteTeamMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteTeamMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a8512b4bcbe560131532c0928ffe5e32",
    "id": null,
    "metadata": {},
    "name": "DeleteTeamMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteTeamMutation(\n  $input: deleteTeamMutationInput!\n) {\n  deleteTeam(input: $input) {\n    deletedTeamId\n  }\n}\n"
  }
};
})();

(node as any).hash = "884779243f2a7693bae2a1e0d57a8056";

export default node;
