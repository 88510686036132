// IMPORTS
// schema
import * as ISchema from "#webapp/src/__schema__";

import { modernCache } from "#webapp/src/network/modern-network-layer";
import { commitMutation } from "react-relay";
import { Environment } from "relay-runtime";
import { setPrimaryTeamMutation } from "./SetPrimaryTeam.gql"; // change

// TYPES
interface ISetPrimaryTeamMutationResponse {
  setPrimaryTeam: ISchema.ISetPrimaryTeamMutationPayload;
}

// MUTATION
export function setPrimaryTeam(
  environment: Environment,
  input: ISchema.ISetPrimaryTeamMutationInput,
  onSuccess: (response?: ISchema.ISetPrimaryTeamMutationPayload) => void,
  onError: (error?: any) => void,
) {
  commitMutation(environment, {
    onError,
    mutation: setPrimaryTeamMutation,
    variables: { input },
    updater: (store, data: any) => {
      const { team } = data.setPrimaryTeam;

      // get the org from store
      const orgProxy = store.get(input.orgRelayId);
      if (!orgProxy) {
        return;
      }

      // find the primaryTeam from store and set it on org
      const newPrimaryTeam = store.get(team.id);
      if (newPrimaryTeam) {
        orgProxy.setLinkedRecord(newPrimaryTeam, "primaryTeam");
      }
    },
    onCompleted: (response: ISetPrimaryTeamMutationResponse, errors?: Error[]) => {
      if (errors) {
        onError(errors[0]);
      } else {
        // if we're not in a modern environment, modernCache will be undefined
        if (modernCache) {
          modernCache.clear();
        }
        onSuccess(response.setPrimaryTeam);
      }
    },
  });
}
