import React, { useMemo } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { debounce } from "throttle-debounce";

/**
 * @deprecated
 */
export const withDefaultProps = <P extends object, DP extends Partial<P>>(
  defaultProps: DP,
  component: React.ComponentType<P>,
) => {
  type ActualProps = Partial<DP> & Pick<P, Exclude<keyof P, keyof DP>>;
  component.defaultProps = defaultProps;
  return (component as any) as React.ComponentType<ActualProps>;
};

import { useEffect, useState } from "react";

function getElementDimensions(element: Element | null) {
  if (!element) {
    return { width: Number.POSITIVE_INFINITY, height: Number.POSITIVE_INFINITY };
  }

  const { clientWidth: width, clientHeight: height } = element;
  return {
    width,
    height,
  };
}

// Taken from https://usehooks.com/useDebounce/
export const useDebounceTrailing = <T,>(value: T, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

export const useElementDimensions = (element: HTMLElement | null, duration = 5) => {
  const [elementMounted, setElementMounted] = React.useState(!!element);
  const [elementDimensions, setElementDimensions] = useState(getElementDimensions(element));
  const debouncedElementDimensions = useDebounceTrailing(elementDimensions, duration);

  useEffect(() => {
    if (!elementMounted && element) {
      setElementMounted(true);
    }
  }, [element]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!elementMounted || !element) {
      return;
    }

    setElementDimensions(getElementDimensions(element));

    const resizeObserver = new ResizeObserver(() => {
      // Wrap in a requestAnimationFrame to ensure that the updates to the element's dimensions
      // happen after the DOM has been updated to prevent resize loop notification error in dev.
      // You wanna see what that looks like, remove the requestAnimationFrame and load the scheduling link page.
      window.requestAnimationFrame(() => {
        setElementDimensions(getElementDimensions(element));
      });
    });
    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, [elementMounted]); // eslint-disable-line react-hooks/exhaustive-deps

  return debouncedElementDimensions;
};

/**
 * @returns An object with the height and width of the window.
 */
export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export const wrapWithClassComponent = <T extends {}>(
  Component: React.FunctionComponent<T>,
): React.ComponentClass<T> => {
  return class Wrapper extends React.PureComponent<T> {
    render() {
      return <Component {...this.props} />;
    }
  };
};

export const isClassComponent = <T extends {}>(
  C: React.ComponentType<T>,
): C is React.ComponentClass<T> => {
  // Detect if a component is a class by checking for the isReactComponent flag, included on the
  // prototype by extending React.Component
  return !!C?.prototype?.isReactComponent;
};

// Key listener hook that takes in keys to listen for and a callback.
export const useKeyListener = (keys: string[], callback: (event: KeyboardEvent) => void) => {
  const keyDownHandler = (event: KeyboardEvent) => {
    if (keys.includes(event?.key)) {
      callback(event);
    }
    return true;
  };

  useEffect(() => {
    window.addEventListener("keydown", keyDownHandler);

    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [keys, callback]);
};

export const CONFIRM_UNSAVED_ONUNLOAD_TEXT =
  "Your changes have not been saved. They will be lost if you continue.";

/**
 * Subscribes / Unsubscribes to `beforeunload` event and triggers browser warning if `isConfirmationRequired`
 * Allows users to cancel unloading and remain on page
 *
 * @remarks
 * per MDN `event.returnValue` is deprecated
 * however, currently is the only way to do this in FF, Chrome, Safari
 * additionally the browser may not respect message provided and use its own langauge
 *
 * @example
 * const [hasChanges, setChanges] = useState(false);
 * useConfirmBeforeUnloadEvent(hasChanges);
 */
export const useConfirmBeforeUnloadEvent = (isConfirmRequired: boolean) => {
  const confirmHandler = React.useCallback((event: BeforeUnloadEvent) => {
    event.returnValue = CONFIRM_UNSAVED_ONUNLOAD_TEXT;
    return CONFIRM_UNSAVED_ONUNLOAD_TEXT;
  }, []);

  React.useEffect(() => {
    if (isConfirmRequired) {
      window.addEventListener("beforeunload", confirmHandler);
    } else {
      window.removeEventListener("beforeunload", confirmHandler);
    }

    return () => {
      window.removeEventListener("beforeunload", confirmHandler);
    };
  }, [isConfirmRequired, confirmHandler]);
};

/**
 Returns a boolean indicating if the current window is being rendered within an iframe.
*/
export const useIsInIframe = () => {
  // We are not in an iframe if window.location and window.parent.location are the same.
  return isInIframe();
};

export const isInIframe = () => {
  // We are not in an iframe if window.location and window.parent.location are the same.
  return window.location === window.parent.location ? false : true;
};

/**
 * Returns if the user has a touch screen. Indicates if user is on mobile.
 * Taken directly from MDN docs recommendation for detecting touch screen.
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
 */
export const isTouchScreen = () => {
  let hasTouchScreen = false;
  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    // @ts-ignore-next-line - msMaxTouchPoints is not in the TS definition for Navigator
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = window.matchMedia?.("(pointer:coarse)");
    if (mQ?.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = (navigator as any).userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }
  return hasTouchScreen;
};

export const useIsTouchScreen = () => useMemo(() => isTouchScreen(), []);

// Taken from https://github.com/jpalumickas/use-window-focus/blob/main/src/index.ts
const hasFocus = () => typeof document !== "undefined" && document.hasFocus();

export const useWindowFocus = () => {
  const [focused, setFocused] = useState(hasFocus); // Focus for first render

  useEffect(() => {
    setFocused(hasFocus()); // Focus for additional renders

    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  return focused;
};

export const useOnWindowFocusChange = (
  onFocus?: () => void,
  onBlur?: () => void,
  cleanup?: () => void,
) => {
  const isWindowFocused = useWindowFocus();
  const [focused, setFocused] = useState<boolean | null>(null);

  useEffect(() => {
    if (focused !== isWindowFocused) {
      setFocused(isWindowFocused);

      if (isWindowFocused) {
        onFocus?.();
      } else {
        onBlur?.();
      }
    }

    return () => {
      cleanup?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWindowFocused]);
};

export const useEffectTimeout = (
  callback: () => void,
  duration: number,
  dependencies: React.DependencyList,
) => {
  const callbackRef = React.useRef<() => void>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      callbackRef.current?.();
    }, duration);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export type SessionScrollKeys = "chatFeedDiv" | "testDiv";

/**
 * Stores the scrollTop of the node in sessionStorage using the key provided. Make sure to provide a unique key.
 * Add any new keys to this exported list of string literals.
 */
export const useSessionScrollPosition = (
  key: SessionScrollKeys,
  node: HTMLDivElement | HTMLSpanElement | null,
) => {
  const [initialScrollOffsetSet, setInitialScrollOffsetSet] = useState(false);
  // On scroll of node, save the scroll position to sessionStorage using key
  const handleScroll = useMemo(
    () =>
      debounce(20, () => {
        if (node) {
          sessionStorage.setItem(key, node.scrollTop.toString());
        }
      }),
    [key, node],
  );

  // On mount, set the scroll position of node to the value in sessionStorage using key
  useEffect(() => {
    if (node) {
      const scrollPosition = sessionStorage.getItem(key);
      if (scrollPosition) {
        const parsedScrollPosition = parseInt(scrollPosition, 10);
        // Make sure is not NaN
        if (typeof parsedScrollPosition === "number") {
          node.scrollTop = parsedScrollPosition;
        }
      }
      setInitialScrollOffsetSet(true);
    }
  }, [key, node]);

  // On scroll of node, save the scroll position to sessionStorage using key
  useEffect(() => {
    if (!initialScrollOffsetSet) {
      return;
    }

    if (node) {
      node.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (node) {
        node.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, initialScrollOffsetSet, node]);
};
