import { getTimeZoneGuess } from "@clockwise/client-commons/src/util/time-zone";
import React, { useState } from "react";
import copyToClipboard from "../../util/copyToClipboard";
import { CopyBestTimesModal } from "../copy-best-times-modal/CopyBestTimesModal";
import { usePaginatedBestTimes } from "../copy-best-times-modal/copy-best-times-utils";

interface IProps {
  url: string;
  rescheduleUrl?: string | null;
  linkName: string;
  name: string;
  slug: string;
  durations: number[];
  defaultDuration: number;
  windowSizeDays?: number | null;
  multipleUser: boolean;
  onClose: () => void;
  fullscreen?: boolean;
  renderedFrom?: string;
  renderContentInModal?: boolean;
}

const DEFAULT_NUM_DAYS_TO_SHOW = 3;

export const ShareBestTimesLinkModal = ({
  url,
  rescheduleUrl = null,
  linkName,
  name,
  slug,
  durations,
  defaultDuration,
  windowSizeDays,
  multipleUser,
  onClose,
  fullscreen = false,
  renderedFrom = "scheduling-links",
  renderContentInModal = true,
}: IProps) => {
  const [dialogOpen, setDialogOpen] = useState(true);
  const [numDaysToShow, setNumDaysToShow] = useState(DEFAULT_NUM_DAYS_TO_SHOW);
  const [targetTimeZone, setTargetTimezone] = useState(getTimeZoneGuess());
  const [duration, setDuration] = useState(defaultDuration);

  const { loading, error, errorMsg, bestTimeSlots } = usePaginatedBestTimes(
    linkName,
    slug,
    duration,
    targetTimeZone,
    numDaysToShow,
  );

  const handleDurationChange = (newDuration: number) => {
    setDuration(newDuration);
  };

  const errorText = errorMsg || (error && "Sorry, something went wrong") || "";

  return (
    <CopyBestTimesModal
      dialogOpen={dialogOpen}
      loading={loading}
      fullscreen={fullscreen}
      onClose={() => {
        setDialogOpen(false);
        onClose();
      }}
      onCopy={copyToClipboard}
      linkUrl={rescheduleUrl || url}
      linkName={linkName}
      name={name}
      slug={slug}
      durations={durations}
      duration={duration}
      onDurationChange={handleDurationChange}
      windowSizeDays={windowSizeDays}
      multipleUser={multipleUser}
      bestTimeSlots={bestTimeSlots}
      errorMsg={errorText}
      numDaysToShow={numDaysToShow}
      setNumDaysToShow={setNumDaysToShow}
      targetTimeZone={targetTimeZone}
      setTargetTimezone={setTargetTimezone}
      renderedFrom={renderedFrom}
      renderContentInModal={renderContentInModal}
    />
  );
};
