/**
 * @generated SignedSource<<1160647dac692d03eb5a8cede9b19387>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SuggestedOrgInviteResolution = "Accepted" | "Dismissed" | "%future added value";
export type UpdateSuggestedOrgInviteInput = {
  calendarIds?: ReadonlyArray<string | null> | null;
  clientMutationId?: string | null;
  note?: string | null;
  orgRelayId: string;
  resolution?: SuggestedOrgInviteResolution | null;
};
export type UpdateSuggestedOrgInviteMutation$variables = {
  input: UpdateSuggestedOrgInviteInput;
};
export type UpdateSuggestedOrgInviteMutation$data = {
  readonly updateSuggestedOrgInvite: {
    readonly org: {
      readonly id: string;
      readonly suggestedInvites: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly orgId: string | null;
            readonly suggestedTime: number | null;
            readonly resolution: SuggestedOrgInviteResolution | null;
            readonly totalScore: number | null;
            readonly numberOfOrganizedMeetings: number | null;
            readonly numberOfSharedMeetings: number | null;
            readonly targetCalendarId: string | null;
            readonly targetProfile: {
              readonly givenName: string | null;
              readonly familyName: string | null;
              readonly externalImageUrl: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      readonly pendingInvites: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly orgId: string | null;
            readonly lastInvitingUserId: string | null;
            readonly createdTime: number | null;
            readonly lastSentTime: number | null;
            readonly used: boolean | null;
            readonly targetCalendarId: string | null;
            readonly targetProfile: {
              readonly givenName: string | null;
              readonly familyName: string | null;
              readonly externalImageUrl: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type UpdateSuggestedOrgInviteMutation = {
  variables: UpdateSuggestedOrgInviteMutation$variables;
  response: UpdateSuggestedOrgInviteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orgId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetCalendarId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "targetProfile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "givenName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "familyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalImageUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSuggestedOrgInvitePayload",
    "kind": "LinkedField",
    "name": "updateSuggestedOrgInvite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Org",
        "kind": "LinkedField",
        "name": "org",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10
              }
            ],
            "concreteType": "SuggestedOrgInviteConnection",
            "kind": "LinkedField",
            "name": "suggestedInvites",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SuggestedOrgInviteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SuggestedOrgInvite",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "suggestedTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "resolution",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalScore",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numberOfOrganizedMeetings",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numberOfSharedMeetings",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "suggestedInvites(first:10)"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              }
            ],
            "concreteType": "OrgInviteConnection",
            "kind": "LinkedField",
            "name": "pendingInvites",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrgInviteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrgInvite",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastInvitingUserId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastSentTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "used",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "pendingInvites(first:20)"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSuggestedOrgInviteMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSuggestedOrgInviteMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "6411cf6fc56218ee5c0f6bfeba15d4ba",
    "id": null,
    "metadata": {},
    "name": "UpdateSuggestedOrgInviteMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSuggestedOrgInviteMutation(\n  $input: UpdateSuggestedOrgInviteInput!\n) {\n  updateSuggestedOrgInvite(input: $input) {\n    org {\n      id\n      suggestedInvites(first: 10) {\n        edges {\n          node {\n            id\n            orgId\n            suggestedTime\n            resolution\n            totalScore\n            numberOfOrganizedMeetings\n            numberOfSharedMeetings\n            targetCalendarId\n            targetProfile {\n              givenName\n              familyName\n              externalImageUrl\n            }\n          }\n        }\n      }\n      pendingInvites(first: 20) {\n        edges {\n          node {\n            id\n            orgId\n            lastInvitingUserId\n            createdTime\n            lastSentTime\n            used\n            targetCalendarId\n            targetProfile {\n              givenName\n              familyName\n              externalImageUrl\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f5b15792fcd2e1e413422059ebf68ad9";

export default node;
