import { graphql } from "react-relay";

export const upsertMeetingReliefMutation = graphql`
  mutation UpsertMeetingReliefMutation($input: UpsertMeetingReliefMutationInput!) {
    upsertMeetingRelief(input: $input) {
      org {
        id
        userGoals(first: 1000000) {
          edges {
            node {
              id
              goalId
              targetUrn {
                id
                type
                value
              }
              otherUrns {
                id
                type
                value
              }
              name
              isDeleted
              options {
                syncToGoogle
                specificOptions {
                  ... on GoalMeetingReliefOptions {
                    fatigueThresholdMinutes
                    meetingReliefBreakMinutes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
