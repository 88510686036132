// util
// types
import { SubjectUrnTypeEnum } from "@clockwise/schema";
// constants
import { includes } from "lodash";
import { defaultTo, find, head } from "lodash/fp";
import { articleUrls } from "../constants/help-center";
import { DayTimeSlot } from "../constants/time-slot";
import { ZonedMoment } from "./ZonedMoment";
import { EventColorCategory } from "./event-category-coloring";
import { Tags, getTagValue, getUserTagsOffEvent, isTagActive } from "./event-tag";
import { warnOutsideTests } from "./logging";
import { isRoom } from "./room";
import { capitalizeTxt } from "./text";
import { transform } from "./transform.util";
import { translate } from "./translations";

export type IDayTimeSlot = DayTimeSlot;

export const enum TypeEnum {
  Add = "Add",
  Move = "Move",
  UndoMove = "UndoMove",
  Remove = "Remove",
}

interface IEventTime {
  dateOrDateTime: string;
  millis: number;
}

export const enum TimeSlot {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
}

export const enum EventTimeType {
  Date = "Date",
  DateTime = "DateTime",
}

interface IAttendee {
  displayName: string | null;
  urnValue: string | null;
}

interface IPersonProfile {
  familyName?: string | null | undefined;
  givenName?: string | null | undefined;
}

interface IPerson {
  isYou: boolean | null;
  primaryEmail?: string;
  primaryCalendarId?: string;
  profile?: IPersonProfile | null | undefined;
}

interface IEvent {
  attendees: IAttendee[];
}

export const autopilotDescriptionPreface = "--------------------------------------------";
export const autopilotDescription =
  `❇️️Clockwise Autopilot is enabled for this event. The timing of this ` +
  `event may automatically change if less disruptive times come available. ` +
  `<a href="${articleUrls.autopilot}">Learn more</a>`;

export const autopilotNewDescription = new RegExp(
  `\n?\n?…\n*❇️️Clockwise Autopilot may automatically move this meeting to the least ` +
    `disruptive time for attendees. To manage this event: <a href=".+">open in Clockwise</a>.`,
);

export const rWFHTitle = /^\s*(\S+\s+)*wfh(\s+\S+)*\s*$/gi;

type EventWithCalendarIds = {
  calendarIds: string[];
};

export function eventFromParent<E extends EventWithCalendarIds>(
  eventParent: { events: Array<E> } | null,
  calendarId?: string,
): E | null {
  const events = eventParent?.events ?? [];

  return transform(
    events,
    find((e) => includes(e.calendarIds, calendarId)),
    defaultTo<E | null>(head(events) ?? null),
  );
}

namespace isNonPainClockwiseEventNS {
  interface IEventKey {
    externalEventId: string;
  }

  interface IEvent {
    eventKey: IEventKey;
    isClockwiseEvent: boolean | null;
  }

  export function isNonPainClockwiseEvent<E extends IEvent>(event: E) {
    if (event.isClockwiseEvent && !event.eventKey.externalEventId.startsWith("pain")) {
      return true;
    }

    return false;
  }
}

export const { isNonPainClockwiseEvent } = isNonPainClockwiseEventNS;

namespace isPersonalCalendarNS {
  interface IEventKey {
    externalEventId: string;
  }

  interface IEvent {
    eventKey: IEventKey;
  }

  export function isPersonalCalendarSyncedEvent(event: IEvent) {
    if (!event.eventKey || !event.eventKey.externalEventId) {
      console.error("Expected an externalEventId in isPersonalCalendarSyncedEvent");
    }

    return event.eventKey.externalEventId.indexOf("sinced") === 0;
  }

  // Hack for chrome extension to see if event is a synced event because
  // there isnt a good way to see without the tags :(
  export function isPersonalCalendarSyncedEventByExternalEventId(externalEventId: string) {
    return externalEventId.indexOf("sinced") === 0;
  }
}

export const {
  isPersonalCalendarSyncedEvent,
  isPersonalCalendarSyncedEventByExternalEventId,
} = isPersonalCalendarNS;

export namespace isSmartHoldNS {
  interface IEventKey {
    externalEventId: string;
  }

  export type TagState = {
    active: boolean;
    value: string | null;
    subjectType: string;
    subjectValue: string;
    lastModified: number;
  };
  type OrgTag = { tag: string; state: TagState };
  type UserTag = { tag: string; states: TagState[] | null };
  type AnnotatedEvent = {
    orgTags?: OrgTag[] | null;
    userTags?: UserTag[] | null;
  };

  export interface IEvent {
    isClockwiseEvent: boolean;
    title?: string | null;
    annotatedEvent?: AnnotatedEvent | null;
    eventKey: IEventKey;
  }

  export function isMeetingReliefSmartHold(externalEventId: string) {
    return externalEventId.startsWith("meetingrelief");
  }

  // ~-~-~-~-~-~-~-
  // Lunch Smart Holds
  // ~-~-~-~-~-~-~-
  export function isSyncedLunchSmartHold(externalEventId: string) {
    return externalEventId.startsWith("lunch");
  }

  export function isShadowLunchSmartHold(externalEventId: string) {
    return externalEventId.startsWith("shadovlunch");
  }

  export function isLunchSmartHoldFromExternalEventId(externalEventId: string) {
    return isSyncedLunchSmartHold(externalEventId) || isShadowLunchSmartHold(externalEventId);
  }

  export function isLunchSmartHold(event: IEvent) {
    // check other data requirements
    if (!event.eventKey || !event.eventKey.externalEventId) {
      console.error("Expected an externalEventId in isLunchSmartHold");
    }

    return isLunchSmartHoldFromExternalEventId(event.eventKey.externalEventId);
  }

  // ~-~-~-~-~-~-~-
  // Focus Time Smart Holds
  // ~-~-~-~-~-~-~-
  export function isSyncedFocusTimeSmartHold(externalEventId: string) {
    return externalEventId.startsWith("focustime");
  }

  export function isShadowFocusTimeSmartHold(externalEventId: string) {
    return externalEventId.startsWith("shadovfocustime");
  }

  export function isFocusTimeSmartHoldFromExternalEventId(externalEventId: string) {
    return (
      isSyncedFocusTimeSmartHold(externalEventId) || isShadowFocusTimeSmartHold(externalEventId)
    );
  }

  export function isFocusTimeSmartHold(event: Pick<IEvent, "eventKey">) {
    // check other data requirements
    if (!event.eventKey || !event.eventKey.externalEventId) {
      console.error("Expected an externalEventId in isFocusTimeSmartHold");
    }

    return isFocusTimeSmartHoldFromExternalEventId(event.eventKey.externalEventId);
  }

  // ~-~-~-~-~-~-~-
  // Travel Time Smart Holds
  // ~-~-~-~-~-~-~-
  export function isSyncedTravelTimeSmartHold(externalEventId: string) {
    return externalEventId.startsWith("traveltime");
  }

  export function isShadowTravelTimeSmartHold(externalEventId: string) {
    return externalEventId.startsWith("shadovtraveltime");
  }

  export function isTravelTimeSmartHoldFromExternalEventId(externalEventId: string) {
    return (
      isSyncedTravelTimeSmartHold(externalEventId) || isShadowTravelTimeSmartHold(externalEventId)
    );
  }

  export function isTravelTimeSmartHold(event: IEvent) {
    // check other data requirements
    if (!event.eventKey || !event.eventKey.externalEventId) {
      console.error("Expected an externalEventId in isTravelTimeSmartHold");
    }

    return isTravelTimeSmartHoldFromExternalEventId(event.eventKey.externalEventId);
  }

  // ~-~-~-~-~-~-~-
  // Prep Time Smart Holds
  // ~-~-~-~-~-~-~-
  export function isSyncedPrepTimeSmartHold(externalEventId: string) {
    return externalEventId.startsWith("preptime");
  }

  export function isShadowPrepTimeSmartHold(externalEventId: string) {
    return externalEventId.startsWith("shadovpreptime");
  }

  export function isTeamCalendarSyncedEvent(externalEventId: string) {
    return externalEventId.startsWith("teamevent");
  }

  export function isPrepTimeSmartHoldFromExternalEventId(externalEventId: string) {
    return isSyncedPrepTimeSmartHold(externalEventId) || isShadowPrepTimeSmartHold(externalEventId);
  }

  export function isPrepTimeSmartHold(event: IEvent) {
    // check other data requirements
    if (!event.eventKey || !event.eventKey.externalEventId) {
      console.error("Expected an externalEventId in isPrepTimeSmartHold");
    }

    return isPrepTimeSmartHoldFromExternalEventId(event.eventKey.externalEventId);
  }

  // ~-~-~-~-~-~-~-
  // General Smart Hold methods
  // ~-~-~-~-~-~-~-
  export function isSmartHold(event: IEvent) {
    return (
      isFocusTimeSmartHold(event) ||
      isLunchSmartHold(event) ||
      isTravelTimeSmartHold(event) ||
      isPrepTimeSmartHold(event)
    );
  }

  export function isLocalOnlySyncOverride(event: IEvent): boolean {
    if (!event.annotatedEvent || !event.annotatedEvent.orgTags) {
      console.error("Expected orgTags in isLocalOnlySyncOverride");
      return false;
    }
    const syncOverrideTag = getTagValue(event, Tags.SyncOverride);
    return !!syncOverrideTag && syncOverrideTag === "LocalOnly";
  }

  export function isClockwiseUnsyncedEvent(event: IEvent, isShadowCalendar?: boolean) {
    const isLocalOnly = isLocalOnlySyncOverride(event);
    return isLocalOnly || isShadowCalendar;
  }

  export function isLinkedSmartHold(externalEventId: string) {
    return (
      isShadowTravelTimeSmartHold(externalEventId) ||
      isSyncedTravelTimeSmartHold(externalEventId) ||
      isShadowPrepTimeSmartHold(externalEventId) ||
      isSyncedPrepTimeSmartHold(externalEventId) ||
      isTeamCalendarSyncedEvent(externalEventId)
    );
  }

  export function isSmartHoldByExternalEventId(externalEventId: string) {
    return (
      isShadowTravelTimeSmartHold(externalEventId) ||
      isSyncedTravelTimeSmartHold(externalEventId) ||
      isShadowPrepTimeSmartHold(externalEventId) ||
      isSyncedPrepTimeSmartHold(externalEventId) ||
      isShadowFocusTimeSmartHold(externalEventId) ||
      isSyncedFocusTimeSmartHold(externalEventId) ||
      isShadowLunchSmartHold(externalEventId) ||
      isSyncedLunchSmartHold(externalEventId) ||
      isMeetingReliefSmartHold(externalEventId)
    );
  }

  // ~~~~~~~~~~~~~
  // Team Calendar
  // ~~~~~~~~~~~~~

  export function isTeamCalendarSyncDisabled(event: IEvent) {
    if (!event.annotatedEvent || !event.annotatedEvent.orgTags) {
      console.error("Expected orgTags in isTeamCalendarSyncDisabled");
    }

    return isTagActive(event, Tags.TeamCalendarSyncDisabled);
  }

  export function isOOOEvent(event: IEvent) {
    if (!event.annotatedEvent || !event.annotatedEvent.userTags) {
      console.error("Expected userTags in isOOO");
    }

    const category = getUserTagsOffEvent(event, Tags.EventColoringCategory);

    return (
      category.length &&
      category[0].states &&
      category[0].states.length &&
      !!category[0].states.find(
        (state) => state.active && state.value === EventColorCategory.OutOfOffice,
      )
    );
  }

  export function isWFHEvent(event: IEvent) {
    rWFHTitle.lastIndex = 0;
    return rWFHTitle.test(event.title || "");
  }

  export function isTeamCalendarSyncEvent(event: IEvent) {
    return (
      (event && event.eventKey && event.eventKey.externalEventId.startsWith("teamevent")) || false
    );
  }

  interface IOriginEvent extends IEvent {
    startTime: IEventTime;
    endTime: IEventTime;
  }

  export function isTeamCalendarOriginEvent(event: IOriginEvent) {
    return (
      (isWFHEvent(event) || isOOOEvent(event)) &&
      event.endTime.millis - event.startTime.millis >= 90 * 60 * 1000
    ); // < 90 minutes
  }
}

export function isSmartHoldPaused(
  annotatedEvent: { orgTags?: { tag: string; state: { active: boolean } }[] | null } | null,
) {
  return (
    annotatedEvent?.orgTags?.some(
      (orgTag) => orgTag.tag === "MovementPaused" && orgTag.state.active,
    ) || false
  );
}

export const {
  isMeetingReliefSmartHold,
  isSyncedLunchSmartHold,
  isShadowLunchSmartHold,
  isLunchSmartHoldFromExternalEventId,
  isLunchSmartHold,
  isSyncedFocusTimeSmartHold,
  isShadowFocusTimeSmartHold,
  isFocusTimeSmartHoldFromExternalEventId,
  isFocusTimeSmartHold,
  isSyncedTravelTimeSmartHold,
  isShadowTravelTimeSmartHold,
  isTravelTimeSmartHoldFromExternalEventId,
  isTravelTimeSmartHold,
  isSyncedPrepTimeSmartHold,
  isShadowPrepTimeSmartHold,
  isPrepTimeSmartHoldFromExternalEventId,
  isPrepTimeSmartHold,
  isSmartHold,
  isLocalOnlySyncOverride,
  isClockwiseUnsyncedEvent,
  isLinkedSmartHold,
  isSmartHoldByExternalEventId,
  isTeamCalendarSyncDisabled,
  isOOOEvent,
  isWFHEvent,
  isTeamCalendarOriginEvent,
  isTeamCalendarSyncEvent,
} = isSmartHoldNS;

namespace getScheduleTextNS {
  interface IPerson {
    emails: string[];
    isYou: boolean | null;
    primaryEmail: string;
    profile: IPersonProfile | null;
  }

  interface IEventKey {
    externalEventId: string;
  }

  type TagState = {
    active: boolean;
    value: string | null;
    subjectType: SubjectUrnTypeEnum;
    subjectValue: string;
    lastModified: number;
  };
  type OrgTag = { tag: string; state: TagState };
  type UserTag = { tag: string; states: TagState[] | null };
  export type AnnotatedEvent = {
    orgTags?: OrgTag[] | null;
    userTags?: UserTag[] | null;
  };

  interface IEvent {
    calendarIds: string[];
    createdMillis: number;
    updatedMillis: number;
    isClockwiseEvent: boolean;
    title: string | null;
    annotatedEvent: AnnotatedEvent | null;
    eventKey: IEventKey;
    organizer: IAttendee | null;
  }

  interface IGraphEntityError {
    __typename: "GraphEntityError";
  }

  interface IPersonList {
    __typename: "PersonList";
    list: IPerson[];
  }

  type PersonListErrorable = IPersonList | IGraphEntityError;

  /**
   * Get scheduled text for an event.
   * Optional originEvent beause the scheduled by is always gonna be the calendar owner in copied events
   *
   * @param event
   */
  export function getScheduleText(
    event: IEvent,
    personListErrorable: PersonListErrorable,
    t: ReturnType<typeof translate>,
    originEvent?: IEvent,
  ) {
    const startTime = new ZonedMoment(event.createdMillis);
    const startAgo = startTime.fromNow();
    const updatedTime = new ZonedMoment(event.updatedMillis);
    const updatedAgo = updatedTime.fromNow();
    const isSmartHoldEvent = isSmartHold(event);

    if (isSmartHoldEvent) {
      return t("event:smart-hold-updated", { updatedAgo }) as string;
    }

    if (!startTime.getMoment().isValid()) {
      return "";
    }

    const originalEvent = originEvent || event;
    let name = "";
    if (
      personListErrorable.__typename === "PersonList" &&
      originalEvent.organizer &&
      originalEvent.organizer.urnValue
    ) {
      const organizer = personListErrorable.list.find(
        (p) => p.emails.indexOf(originalEvent.organizer!.urnValue!) > -1,
      );
      if (organizer) {
        if (organizer.isYou) {
          name = "You";
        } else if (organizer.profile && organizer.profile.givenName) {
          name = organizer.profile.givenName;
        } else {
          name = "";
        }
      }
    }

    const scheduled = name ? `${name} scheduled` : "Scheduled";
    return `${scheduled} ${startAgo}`;
  }
}

export const { getScheduleText } = getScheduleTextNS;
export type AnnotatedEvent = getScheduleTextNS.AnnotatedEvent;

export function niceNameFromCalendarUrnValue(urnValue: string | null) {
  if (!urnValue) {
    warnOutsideTests("no urnValue on attendee; check your fragments!");
    return "";
  }
  const splitAt = urnValue.split("@");
  const splitPeriod = !!splitAt[0] && splitAt[0].split(".");
  return (splitPeriod && !!splitPeriod[0] && capitalizeTxt(splitPeriod[0])) || "";
}

export function niceNamesAsString(
  nameList: string[],
  maxCharacters = 10000000,
  maxNames = 1000000,
) {
  return nameList.reduce((accum, name, i, list) => {
    const newLength = accum.length + 5 + name.length; // ' and ' === 5

    // conditions that change the string output
    const onlyTwo = list.length === 2;
    const allDone = accum.length > maxCharacters || i + 1 > maxNames; // already hit the limit
    const lastOne = newLength > maxCharacters || i + 1 === maxNames || list.length === i + 1; // this one is the last one
    const keepAdding = !lastOne && newLength < maxCharacters; // still have room after next
    const remainingCount = list.length - (i + 1); // how many are left

    // helper
    const pluralOther = remainingCount === 1 ? "other" : "others";

    if (allDone) {
      return accum;
    } else if (i === 0 && lastOne && remainingCount > 0) {
      return `${name} and ${remainingCount} ${pluralOther}`;
    } else if (i === 0) {
      return name;
    } else if (onlyTwo) {
      return `${accum} and ${name}`;
    } else if (keepAdding) {
      return [accum, name].join(", ");
    } else if (lastOne && remainingCount > 0) {
      return `${accum}, ${name}, and ${remainingCount} ${pluralOther}`;
    } else if (lastOne && remainingCount === 0) {
      return `${accum}, and ${name}`;
    } else {
      return accum;
    }
  }, "");
}

/**
 * Either pull the displayName or make something from the urnValue.
 * @param attendee
 */
export function getAttendeeName(attendee: IAttendee) {
  return (
    (attendee && attendee.displayName) || niceNameFromCalendarUrnValue(attendee.urnValue) || ""
  );
}

export function niceRooms(attendees: IAttendee[] | null, maxCharacters?: number) {
  if (!attendees) {
    return "";
  }
  // reduce this to a string of some acceptable length
  const nameList = attendees
    .filter((attendee) => {
      if (!attendee || !attendee.urnValue) {
        return false;
      }
      return isRoom(attendee.urnValue);
    })
    .map(getAttendeeName);

  return niceNamesAsString(nameList, maxCharacters);
}

/**
 * Nice names list from attendees and profiles.
 *
 * @param attendees
 * @param persons
 * @param maxCharacters
 * @param _fullName
 */
//
export function niceAttendeeNamesWithCarryover(
  attendees: IAttendee[],
  persons: IPerson[],
  maxCharacters?: number,
  _fullName = false,
) {
  const calIds: string[] = [];
  const matchedPersons: IPerson[] = [];
  attendees
    .filter((attendee) => {
      if (!attendee || !attendee.urnValue) {
        warnOutsideTests("no urnValue on attendee; check your fragments!");
        return false;
      }
      return !isRoom(attendee.urnValue);
    })
    .forEach((attendee) => {
      const match = persons.find((person) => person.primaryEmail === attendee.urnValue);
      if (match && match.profile) {
        matchedPersons.push(match);
      } else {
        calIds.push(attendee.displayName || attendee.urnValue || "");
      }
    });

  return niceNameArrayFromPersonsWithCarryover(
    matchedPersons,
    calIds,
    maxCharacters,
    false,
    true,
    true,
  );
}

export function niceNameArrayFromPersonsWithCarryover<P extends IPerson>(
  persons: P[],
  calIds?: string[],
  maxCharacters?: number,
  fullName = false,
  lastInitial = false,
  isLeading = false,
  overflowCount?: number,
) {
  // filter out you while generating names
  let you: IPerson | null = null;
  const filteredPersons = persons
    .filter((person) => {
      you = person.isYou ? person : you;
      return !person.isYou;
    })
    .sort((personA, personB) =>
      (personA.primaryEmail || "").localeCompare(personB.primaryEmail || ""),
    );

  const lastInitials: boolean[] = [];

  // we will decide to show last initials based on the occurrences of the first name
  // > 1, show last initial
  if (lastInitial) {
    const lastInitialMap: { [key: string]: number } = {};
    const givenNames = filteredPersons.map((person) =>
      person.profile ? person.profile.givenName || "" : "",
    );
    givenNames.forEach(
      (givenName) => (lastInitialMap[givenName] = (lastInitialMap[givenName] || 0) + 1),
    );
    givenNames.forEach((givenName) => lastInitials.push(lastInitialMap[givenName] > 1));
  }

  const names = filteredPersons
    .map((person, i) => niceNameFromPerson(person, fullName, lastInitials[i], isLeading) || "")
    .concat(calIds || []);

  // if you were found, shift you to the front
  if (you) {
    filteredPersons.splice(0, 0, you);
    names.splice(0, 0, niceNameFromPerson(you, fullName, lastInitial, isLeading) || "");
  }
  return niceNamesAsArrayWithCarryover(names, maxCharacters, filteredPersons, overflowCount);
}

export function niceNamesAsArrayWithCarryover<P extends IPerson>(
  nameList: string[],
  maxCharacters = 10000000,
  persons?: P[],
  overflowCount = 0,
) {
  const carryover: string[] = [];
  const niceNameArray = nameList.reduce((accum: string[], name, i, list) => {
    const accumLength = accum.reduce((totalLength: number, accName: string) => {
      return totalLength + accName.length;
    }, 0);
    const newLength = accumLength + 5 + name.length; // ' and ' === 5

    // conditions that change the string output
    const onlyTwo = !overflowCount && list.length === 2;
    const allDone = accumLength > maxCharacters; // already hit the limit
    const lastOne = newLength >= maxCharacters || list.length === i + 1; // this one is the last one
    const keepAdding = !lastOne && newLength < maxCharacters; // still have room after next
    const remainingCount = overflowCount + list.length - (i + 1); // how many are left

    if (allDone) {
      if (persons && persons[i] && persons[i].primaryEmail) {
        carryover.push(persons[i].primaryEmail || "");
      } else {
        carryover.push(name);
      }
    }

    // helper
    const pluralOther = remainingCount === 1 ? "other" : "others";

    if (allDone) {
      return accum;
    } else if (i === 0 && lastOne && remainingCount > 0) {
      return [name, " and ", `${remainingCount} ${pluralOther}`];
    } else if (i === 0) {
      return [name];
    } else if (onlyTwo) {
      return [...accum, " and ", name];
    } else if (keepAdding) {
      return [...accum, ", ", name];
    } else if (lastOne && remainingCount > 0) {
      return [...accum, ", ", name, ", and ", `${remainingCount} ${pluralOther}`];
    } else if (lastOne && remainingCount === 0) {
      return [...accum, ", and ", name];
    } else {
      return accum;
    }
  }, []);

  return { carryover, niceNameArray };
}

export function niceNameFromPerson<
  P extends {
    primaryCalendarId?: string;
    primaryEmail?: string;
    isYou?: boolean | null;
    profile?:
      | {
          familyName?: string | null | undefined;
          givenName?: string | null | undefined;
        }
      | null
      | undefined;
  }
>(person: P | null, fullName = false, lastInitial = false, isLeading = false) {
  if (!person) {
    return "Teammate";
  }
  if (person.isYou) {
    return isLeading ? "You" : "you";
  } else if (person.profile && person.profile.givenName && person.profile.familyName && fullName) {
    return `${person.profile.givenName} ${person.profile.familyName}`;
  } else if (
    person.profile &&
    person.profile.givenName &&
    person.profile.familyName &&
    lastInitial
  ) {
    return `${person.profile.givenName} ${person.profile.familyName[0]}`;
  } else if (person.profile && !person.profile.givenName && person.profile.familyName) {
    return person.profile.familyName;
  } else if (person.profile && person.profile.givenName) {
    return person.profile.givenName;
  } else if (person.primaryEmail) {
    return person.primaryEmail;
  } else if (person.primaryCalendarId) {
    return person.primaryCalendarId;
  } else {
    // kind of sucks
    return "Teammate";
  }
}

/**
 * Return only the rooms off a list of attendees.
 *
 * @param attendees
 */
export function getRoomsFromAttendees(attendees: IAttendee[] | null) {
  return (
    (!!attendees &&
      attendees.filter((attendee) => {
        if (!!attendee && !attendee.urnValue) {
          warnOutsideTests("no urnValue on attendee; check your fragments!");
          return false;
        }
        return isRoom(attendee.urnValue || "");
      })) ||
    []
  );
}

export function eventHasRoom(event: IEvent) {
  const attendees = (!!event && event.attendees) || [];
  const rooms = getRoomsFromAttendees(attendees);
  return !!rooms && !!rooms.length;
}

/**
 * Removes autogenerated text from event descriptions.
 *
 * @param description
 */
export function cleanDescription(description: string) {
  let newDescription = description.replace(
    `${autopilotDescriptionPreface}\n\n${autopilotDescription}`,
    "",
  );
  newDescription = newDescription.replace(autopilotDescription, "");
  newDescription = newDescription.replace(autopilotNewDescription, "");

  return newDescription;
}

namespace bucketEventsByDayOfWeekNS {
  interface IEventTime {
    dateOrDateTime: string;
  }

  export interface IEvent {
    startTime: IEventTime;
    endTime: IEventTime;
  }

  /**
   * Buckets events based on the day of week.  The events that are passed in should only span across a single week,
   * not across multiple.
   *
   * @param events the events to bucket
   */
  export const bucketEventsByDayOfWeek = (events: IEvent[]): IEvent[][] => {
    const buckets: IEvent[][] = new Array(7).fill(null).map((_) => []);

    events.forEach((event) => {
      const startDay = new ZonedMoment(event.startTime.dateOrDateTime).day();
      const endDay = new ZonedMoment(event.endTime.dateOrDateTime).day();

      for (let i = startDay; i <= endDay; i++) {
        buckets[i].push(event);
      }
    });

    return buckets;
  };
}

export const { bucketEventsByDayOfWeek } = bucketEventsByDayOfWeekNS;
export type BucketEventsByDayOfWeekEvent = bucketEventsByDayOfWeekNS.IEvent;

namespace getTimeAllocationNS {
  export interface FragmentedTimeRange {
    startMillis: number;
    endMillis: number;
  }

  enum TimeAllocationEventClassification {
    Meeting,
    Focus,
  }

  interface IEventKey {
    externalEventId: string;
  }

  export interface IEvent {
    isClockwiseEvent: boolean;
    title?: string | null;
    annotatedEvent?: AnnotatedEvent | null;
    eventKey: IEventKey;
    endTime: {
      millis: number;
    };
    startTime: {
      millis: number;
    };
  }

  /**
   * Parses a set of events (O(n)) and generates stats / fragmented time ranges based on the set.
   *
   * @param events events to parse
   * @param startMillis start or min millis
   * @param endMillis end or max millis
   */
  export function getTimeAllocation(events: IEvent[], startMillis: number, endMillis: number) {
    const fragmentedRanges: FragmentedTimeRange[] = [];
    let meetingMinutes = 0;
    let focusMinutes = 0;
    let fragmentedMinutes = 0;
    let currentClassification: TimeAllocationEventClassification | undefined = undefined;
    let currentStartMillis = startMillis;
    let currentEndMillis = startMillis;

    events
      .sort((a, b) => a.startTime.millis - b.startTime.millis)
      .forEach((event) => {
        const isFocusTime =
          isFocusTimeSmartHold(event) || (event.title || "").indexOf("FocusTime") !== -1;
        const hasGap = currentEndMillis < event.startTime.millis;

        const changedClassification =
          (isFocusTime && currentClassification === TimeAllocationEventClassification.Meeting) ||
          (!isFocusTime && currentClassification === TimeAllocationEventClassification.Focus);

        if (hasGap) {
          const minutes = (currentEndMillis - currentStartMillis) / 60000;

          if (currentClassification === TimeAllocationEventClassification.Focus) {
            focusMinutes += minutes;
          } else {
            meetingMinutes += minutes;
          }

          fragmentedRanges.push({
            startMillis: currentEndMillis,
            endMillis: event.startTime.millis,
          });

          fragmentedMinutes += (event.startTime.millis - currentEndMillis) / 60000;

          currentStartMillis = event.startTime.millis;
          currentEndMillis = event.startTime.millis;
        }

        if (changedClassification) {
          const minutes = (currentEndMillis - currentStartMillis) / 60000;

          if (currentClassification === TimeAllocationEventClassification.Focus) {
            focusMinutes += minutes;
          } else {
            meetingMinutes += minutes;
          }

          currentStartMillis = event.startTime.millis;
        }

        currentEndMillis = event.endTime.millis;
        currentClassification = isFocusTime
          ? TimeAllocationEventClassification.Focus
          : TimeAllocationEventClassification.Meeting;
      });

    const minutes = (currentEndMillis - currentStartMillis) / 60000;

    if (currentClassification === TimeAllocationEventClassification.Focus) {
      focusMinutes += minutes;
    } else {
      meetingMinutes += minutes;
    }

    if (endMillis > currentEndMillis) {
      fragmentedRanges.push({
        startMillis: currentEndMillis,
        endMillis: endMillis,
      });

      fragmentedMinutes += (endMillis - currentEndMillis) / 60000;
    }

    return { fragmentedRanges, meetingMinutes, focusMinutes, fragmentedMinutes };
  }
}

export const { getTimeAllocation } = getTimeAllocationNS;
