// libraries
import React, { useMemo, useState } from "react";
// schema
import * as ISchema from "#webapp/src/__schema__";
// utils
import { ITeammateStatusPerson, TeammateInviteStatusEnum } from "#webapp/src/util/team.util";
// components
import { AvatarPerson } from "#webapp/src/components/avatar";
import { InfoTip } from "#webapp/src/components/tool-tip";
import { Link } from "@clockwise/design-system";
import { TeamSettingsDeleteModal } from "./TeamSettingsDeleteModal";
import { getFirstNameOrCalendarId, getFullNameOrCalendarId } from "./TeamSettingsTeammate.util";
import { TeamSettingsTeammateAction } from "./TeamSettingsTeammateAction";

type IProps = {
  teammate: ITeammateStatusPerson;
  onInvite: (person: ISchema.IOrgPerson, resend?: boolean) => void;
  onDelete: (person: ISchema.IOrgPerson) => void;
  onCheck: (person: ISchema.IOrgPerson, checked: boolean) => void;
  mode?: "modify" | "create" | "orgInvite";
  isSearchResult?: boolean;
};

export const TeamSettingsTeammate = ({
  teammate,
  onInvite,
  onDelete,
  onCheck,
  mode = "modify",
  isSearchResult,
}: IProps) => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const teammateForAction = useMemo(() => {
    return {
      isUser: Boolean(teammate?.person?.userId),
      isYou: Boolean(teammate?.person?.isYou),
      isChecked: Boolean(teammate?.checked),
      isInvited: Boolean(teammate?.isInvited),
      fullName: getFullNameOrCalendarId(teammate),
      inviteStatus: teammate.inviteStatus,
    };
  }, [teammate]);

  const teammateFirstName = getFirstNameOrCalendarId(teammate);
  const teammateFullName = getFullNameOrCalendarId(teammate);

  const onDeleteConfirmationModalClose = () => {
    setShowDeleteConfirmationModal(false);
  };

  const onClickCheck = () => {
    onCheck(teammate.person, !teammate.checked);
  };

  const onClickConfirmDelete = () => {
    onDelete(teammate.person);
    setShowDeleteConfirmationModal(false);
  };

  const onClickDeleteTeammate = () => {
    setShowDeleteConfirmationModal(true);
  };

  const onClickSendInvite = () => {
    onInvite(teammate.person);
  };

  const onClickResendInvite = () => {
    onInvite(teammate.person, true);
  };

  const maybeRenderSuggestedTip = () => {
    const isOrgInviteAndNotUninvited =
      mode === "orgInvite" && teammate.inviteStatus !== TeammateInviteStatusEnum.Uninvited;
    if (
      teammate.inviteStatus === TeammateInviteStatusEnum.Accepted ||
      !teammate.isSuggested ||
      isOrgInviteAndNotUninvited
    ) {
      return "";
    }

    const tipTarget = (
      <div className="md:cw-body-base cw-body-sm">
        {" "}
        <Link>Suggested</Link>
      </div>
    );
    return (
      <div>
        <InfoTip
          text={`Suggested because you share meetings with ${teammateFirstName}`}
          targetElement={tipTarget}
          tipType="info"
        />
      </div>
    );
  };

  return (
    <div
      className={`cw-group cw-p-4 cw-items-center cw-flex cw-w-full hover:cw-bg-default-hover`}
      style={mode === "create" ? { cursor: "pointer" } : {}}
      onClick={mode === "create" ? onClickCheck : undefined}
    >
      <AvatarPerson
        person={null}
        orgPerson={teammate.person}
        backupCalendarId={teammate.person.primaryCalendarId}
        size="large"
        showUserBadge
      />
      <div className="cw-flex cw-flex-col cw-flex-1 cw-ml-3 ">
        <div className="md:cw-text-base cw-text-sm cw-text-slate-darker">
          {`${teammateFullName}${teammate.person.isYou ? " (you)" : ""}`}
        </div>
        <div className="md:cw-text-sm cw-text-xs cw-text-slate-standard">
          {teammate.person.primaryCalendarId}
        </div>
      </div>
      <div className="cw-flex cw-items-center cw-gap-3">
        {maybeRenderSuggestedTip()}
        <TeamSettingsTeammateAction
          teammate={teammateForAction}
          onClickSendInvite={onClickSendInvite}
          onClickResendInvite={onClickResendInvite}
          onClickDeleteTeammate={onClickDeleteTeammate}
          onClickCheck={onClickCheck}
          isSearchResult={isSearchResult || false}
          mode={mode}
        />
      </div>
      <TeamSettingsDeleteModal
        isYou={teammate?.person?.isYou || false}
        firstName={teammateFirstName}
        fullName={teammateFullName}
        onClickConfirmDelete={onClickConfirmDelete}
        showDeleteConfirmationModal={showDeleteConfirmationModal}
        onDeleteConfirmationModalClose={onDeleteConfirmationModalClose}
      />
    </div>
  );
};
