import { FloatingPosition, Tooltip as MantineTooltip } from "@mantine/core";
import React from "react";
export type { TooltipProps } from "@mantine/core";

type Props = {
  arrow?: boolean;
  placement?: FloatingPosition;
  open?: boolean;
  maxWidth?: number;
  inline?: boolean;
  openDelay?: number;
  closeDelay?: number;
  title: React.ReactNode;
  children: React.ReactNode;
};

export function Tooltip({
  arrow,
  title,
  placement,
  open,
  maxWidth,
  inline,
  openDelay,
  closeDelay,
  children,
}: Props) {
  if (!title) {
    return children;
  }

  return (
    <MantineTooltip
      withArrow={arrow}
      arrowSize={8}
      arrowOffset={12}
      position={placement}
      maw={maxWidth}
      label={title}
      inline={inline}
      openDelay={openDelay}
      closeDelay={closeDelay}
      transitionProps={{ transition: "pop" }}
      opened={open}
    >
      {children}
    </MantineTooltip>
  );
}
