/**
 * @generated SignedSource<<55c12b0cdca54644fe045db143e25c40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ConferenceTypeEnum = "Meet" | "Removed" | "TeamsForBusiness" | "Zoom" | "%future added value";
export type EventTimeType = "Date" | "DateTime" | "%future added value";
export type SubjectUrnTypeEnum = "Email" | "Integration" | "Org" | "Person" | "Phone" | "Services" | "Team" | "User" | "%future added value";
export type ScheduleNewEventV2MutationInput = {
  attendees?: ReadonlyArray<string> | null;
  calendarId: string;
  clientMutationId?: string | null;
  conferenceType?: ConferenceTypeEnum | null;
  conflictResolutionDefragId?: string | null;
  description?: string | null;
  includeZoomLink?: boolean | null;
  location?: string | null;
  orgRelayId: string;
  timeRange: TimeRangeInput;
  timeZone: string;
  title?: string | null;
};
export type TimeRangeInput = {
  endTime?: EventTimeInput | null;
  id: string;
  startTime?: EventTimeInput | null;
};
export type EventTimeInput = {
  dateOrDateTime: string;
  millis: number;
  offsetMinutes?: number | null;
  timeZone?: string | null;
  type: EventTimeType;
};
export type ScheduleNewEventV2Mutation$variables = {
  input: ScheduleNewEventV2MutationInput;
};
export type ScheduleNewEventV2Mutation$data = {
  readonly scheduleNewEventV2: {
    readonly eventParent: {
      readonly id: string;
      readonly externalEventId: string;
      readonly events: ReadonlyArray<{
        readonly startTime: {
          readonly millis: number;
        };
        readonly annotatedEvent: {
          readonly id: string;
          readonly externalEventId: string | null;
          readonly orgTags: ReadonlyArray<{
            readonly tag: string;
            readonly state: {
              readonly value: string | null;
              readonly subjectType: SubjectUrnTypeEnum;
              readonly subjectValue: string;
              readonly lastModified: number;
              readonly active: boolean;
            };
          }> | null;
        } | null;
        readonly annotatedRecurringEvent: {
          readonly id: string;
          readonly externalEventId: string | null;
          readonly orgTags: ReadonlyArray<{
            readonly tag: string;
            readonly state: {
              readonly value: string | null;
              readonly subjectType: SubjectUrnTypeEnum;
              readonly subjectValue: string;
              readonly lastModified: number;
              readonly active: boolean;
            };
          }> | null;
        } | null;
      }>;
    };
  } | null;
};
export type ScheduleNewEventV2Mutation = {
  variables: ScheduleNewEventV2Mutation$variables;
  response: ScheduleNewEventV2Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalEventId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "EventTime",
  "kind": "LinkedField",
  "name": "startTime",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "millis",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "OrgTag",
    "kind": "LinkedField",
    "name": "orgTags",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tag",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TagState",
        "kind": "LinkedField",
        "name": "state",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subjectType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subjectValue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastModified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "AnnotatedEvent",
  "kind": "LinkedField",
  "name": "annotatedEvent",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AnnotatedEvent",
  "kind": "LinkedField",
  "name": "annotatedRecurringEvent",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleNewEventV2Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleNewEventV2MutationPayload",
        "kind": "LinkedField",
        "name": "scheduleNewEventV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventParent",
            "kind": "LinkedField",
            "name": "eventParent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Event",
                "kind": "LinkedField",
                "name": "events",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScheduleNewEventV2Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleNewEventV2MutationPayload",
        "kind": "LinkedField",
        "name": "scheduleNewEventV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventParent",
            "kind": "LinkedField",
            "name": "eventParent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Event",
                "kind": "LinkedField",
                "name": "events",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0daa3e9a813f0cf76c2ab0594da79dd2",
    "id": null,
    "metadata": {},
    "name": "ScheduleNewEventV2Mutation",
    "operationKind": "mutation",
    "text": "mutation ScheduleNewEventV2Mutation(\n  $input: ScheduleNewEventV2MutationInput!\n) {\n  scheduleNewEventV2(input: $input) {\n    eventParent {\n      id\n      externalEventId\n      events {\n        startTime {\n          millis\n        }\n        annotatedEvent {\n          id\n          externalEventId\n          orgTags {\n            tag\n            state {\n              value\n              subjectType\n              subjectValue\n              lastModified\n              active\n            }\n          }\n        }\n        annotatedRecurringEvent {\n          id\n          externalEventId\n          orgTags {\n            tag\n            state {\n              value\n              subjectType\n              subjectValue\n              lastModified\n              active\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "239d18299ec86aa711d357d61ade4e72";

export default node;
