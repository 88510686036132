// ~-~-~-~-~-~-~-
// Constants
// ~-~-~-~-~-~-~-
export const COUNTRIES_WITH_STATES = ["AU", "US"];

export const COUNTRIES_WITH_PROVINCES = [
  "GB",
  "CA",
  "MX",
  "CN",
  "DE",
  "BE",
  "NL",
  "DK",
  "TR",
  "ID",
  "JO",
  "IN",
  "KH",
  "ET",
  "PE",
  "CU",
  "AR",
  "CL",
  "BO",
  "ES",
  "BD",
  "PK",
  "NG",
  "JP",
  "AT",
  "BR",
  "PH",
  "VN",
  "CR",
];

export const COUNTRIES_WITHOUT_POSTCODES = [
  "AO",
  "AG",
  "AW",
  "BS",
  "BZ",
  "BJ",
  "BW",
  "BF",
  "BI",
  "CM",
  "CF",
  "KM",
  "CG",
  "CD",
  "CK",
  "CI",
  "DJ",
  "DM",
  "GQ",
  "ER",
  "FJ",
  "TF",
  "GM",
  "GH",
  "GD",
  "GN",
  "GY",
  "HK",
  "IE",
  "JM",
  "KE",
  "KI",
  "MO",
  "MW",
  "ML",
  "MR",
  "MU",
  "MS",
  "NR",
  "AN",
  "NU",
  "KP",
  "PA",
  "QA",
  "RW",
  "KN",
  "LC",
  "ST",
  "SA",
  "SC",
  "SL",
  "SB",
  "SO",
  "ZA",
  "SR",
  "SY",
  "TZ",
  "TL",
  "TK",
  "TO",
  "TT",
  "TV",
  "UG",
  "AE",
  "VU",
  "YE",
  "ZW",
];

// ~-~-~-~-~-~-~-
// Helpers
// ~-~-~-~-~-~-~-
export function isStateShown(countryCode: string | null) {
  return (
    [...COUNTRIES_WITH_STATES, ...COUNTRIES_WITH_PROVINCES].findIndex((c) => c === countryCode) > -1
  );
}

export function isPostalCodeShown(countryCode: string | null) {
  return COUNTRIES_WITHOUT_POSTCODES.findIndex((c) => c === countryCode) === -1;
}

export function getCityLabel(countryCode: string | null) {
  switch (countryCode) {
    case "AU":
      return "City / Suburb";
    // citySpan.innerText = 'City / Suburb';
    // cityInput.placeholder = 'Melbourne';
    case "US":
      return "City";
    // citySpan.innerText = 'City';
    // cityInput.placeholder = 'San Francisco';
    default:
      return "City, district, suburb, town, or village";
  }
}

export function getPostalCodeLabel(countryCode: string | null) {
  switch (countryCode) {
    case "US":
      return "Zip Code";
    // zipSpan.innerText = 'ZIP';
    // zipInput.placeholder = '94103';
    case "GB":
      return "Postcode";
    // zipSpan.innerText = 'Postcode';
    // zipInput.placeholder = 'EC1V 9NR';
    case "AU":
      return "Postcode";
    // zipSpan.innerText = 'Postcode';
    // zipInput.placeholder = '3000';
    default:
      return "Postcode";
    // zipSpan.innerText = 'Postal Code';
    // zipInput.placeholder = '94103';
  }
}
