// schema
// mutations
import { createTeam, createTeamCalendar, setPrimaryTeam, updateTeam } from "#webapp/src/mutations";
// state
import * as ISchema from "#webapp/src/__schema__";
import { updateTeamCalendarState } from "#webapp/src/subscriptions/update-team-calendar-state/UpdateTeamCalendarState.subscription";
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { PostMessageManager } from "#webapp/src/util/post-message.util";
// util
import { isTeamInPaidSubscription } from "#webapp/src/util/pricing.util";
import { MAX_TEAM_SIZE } from "#webapp/src/util/team.util";
// libraries
import * as React from "react";
import { createRefetchContainer } from "react-relay";
import { fragments, query } from "./TeamJoinCreate.gql";
// internal
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import toast from "react-hot-toast";
import { useFeatureFlagNumber } from "../../launch-darkly";
import { getValue } from "../../util/errorable.util";
import { TeamCreate } from "./team-create-form/TeamCreate";
import { TeamJoinCreatePills } from "./team-join-create-pills/TeamJoinCreatePills";
import { TeamJoinForm } from "./team-join-form/TeamJoinForm";
import { IContainer, IProps, IState, TeamsFormMode } from "./TeamJoinCreateTypes";

const teamCreatedEvents: Record<IProps["analyticsMode"], string> = {
  onboarding: TrackingEvents.ONBOARDING.ONBOARDING_TEAM_CREATED,
  settings: TrackingEvents.TEAMS.TEAM_SETTINGS_TEAM_CREATED,
  checkout: TrackingEvents.CHECKOUT.TEAM_JOIN_CREATE_MODAL_TEAM_CREATED,
  migration: TrackingEvents.TEAMS_MIGRATION.TEAM_CREATED,
};

const teamJoinedEvents: Record<IProps["analyticsMode"], string> = {
  onboarding: TrackingEvents.ONBOARDING.ONBOARDING_TEAM_JOINED,
  settings: TrackingEvents.TEAMS.TEAM_SETTINGS_TEAM_JOINED,
  checkout: TrackingEvents.CHECKOUT.TEAM_JOIN_CREATE_MODAL_TEAM_JOINED,
  migration: TrackingEvents.TEAMS_MIGRATION.TEAM_JOINED,
};

const teamTabChangedEvents: Record<IProps["analyticsMode"], string> = {
  onboarding: TrackingEvents.ONBOARDING.ONBOARDING_TEAM_TAB_CHANGED,
  settings: TrackingEvents.TEAMS.TEAM_SETTINGS_TEAM_TAB_CHANGED,
  checkout: TrackingEvents.CHECKOUT.TEAM_JOIN_CREATE_MODAL_TAB_CHANGED,
  migration: TrackingEvents.TEAMS_MIGRATION.TAB_CHANGED,
};

class TeamJoinCreateComponent extends React.Component<IProps, IState> {
  private changeSearchTimeout?: ReturnType<typeof setTimeout>;
  private hasInitialized = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      personMap: {},
      teamName: "",
      currentView: "create",
      joinMode: "off",
      selectedPersonIds: {},
      teamsToChooseFrom: [],
      query: "",
      teamNameIsValid: false,
      loading: false,
      selectedTeamId: "",
    };
  }

  /////////////
  // Helpers //
  /////////////
  private isMinimumRequiredInvitesSatisfied = (props: IProps) => {
    if (!props.minimumRequiredInvitesToCreate) {
      return true;
    }

    return Object.keys(this.state.selectedPersonIds).length >= props.minimumRequiredInvitesToCreate;
  };

  private isTeamNameValid = (teamName: string, teamNameIsValid: boolean) => {
    return teamNameIsValid && !!teamName.length;
  };

  private onCheckTeammate = (person: ISchema.IOrgPerson, checked: boolean) => {
    if (checked) {
      this.state.selectedPersonIds[person.personId] = person.primaryCalendarId;
    } else {
      delete this.state.selectedPersonIds[person.personId];
    }

    this.setState({ selectedPersonIds: this.state.selectedPersonIds });
    if (this.props.minimumRequiredInvitesToCreate) {
      this.props.onChangeValidity(
        this.isMinimumRequiredInvitesSatisfied(this.props) &&
          this.isTeamNameValid(this.state.teamName, this.state.teamNameIsValid),
      );
    }
  };

  private onInitSearch = ({
    nDisplayedInviteSuggestions,
  }: {
    nDisplayedInviteSuggestions: number;
  }) => {
    const { org } = this.props;

    if (!this.hasInitialized && this.props.onInit) {
      const suggestedTeams =
        (org.suggestedTeams &&
          org.suggestedTeams.__typename !== "GraphEntityError" &&
          org.suggestedTeams.list) ||
        [];

      this.props.onInit({
        nSuggestedTeams: suggestedTeams.length,
        nDisplayedTeams: this.state.teamsToChooseFrom.length,
        nDisplayedInviteSuggestions,
      });

      this.hasInitialized = true;
    }
  };

  private onChangeTeamName = (teamName: string, teamNameIsValid: boolean) => {
    if (this.state.currentView === "create" && this.state.teamName !== teamName) {
      this.props.onChangeValidity(
        this.isTeamNameValid(teamName, teamNameIsValid) &&
          this.isMinimumRequiredInvitesSatisfied(this.props),
      );
      this.setState({ teamName, teamNameIsValid });
    }
  };

  private onChangeTeam = (newTeamId: string) => {
    const getTeamFromID = this.state.teamsToChooseFrom.find((team) => newTeamId === team.id);
    this.props.onChangeValidity(!!getTeamFromID);
    this.setState({ selectedTeamId: newTeamId });
  };

  private onChangeSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { org } = this.props;

    const query = (e.target && e.target.value) || "";

    if (this.changeSearchTimeout) {
      clearTimeout(this.changeSearchTimeout);
      delete this.changeSearchTimeout;
    }

    this.changeSearchTimeout = setTimeout(() => {
      this.props.relay.refetch(
        {
          query,
          orgRelayId: org.id,
          excludeMyTeams: !this.props.selectMode,
        },
        undefined,
        () => {
          this.setState({ loading: false });
        },
      );
    }, 500);

    this.setState({ query, loading: true });
  };

  private onSave = () => {
    const { org, mode, analyticsMode } = this.props;

    const yourPersonId =
      org.orgPersonForUserErrorable.__typename !== "GraphEntityError" &&
      org.orgPersonForUserErrorable.personId;

    const onTeam =
      (org.userTeams &&
        org.userTeams.__typename !== "GraphEntityError" &&
        org.userTeams.list &&
        !!org.userTeams.list.length) ||
      false;

    const userTeams =
      (org.userTeams?.__typename !== "GraphEntityError" && org.userTeams.list) || [];

    const isCreating = this.state.currentView === "create";

    const isSelecting = !isCreating && this.props.selectMode;

    const isSettingPrimary =
      !isCreating &&
      this.state.currentView === "join" &&
      (this.state.joinMode === "select-primary" || this.state.joinMode === "single-team") &&
      this.state.teamsToChooseFrom.length;

    const isJoining =
      !isSettingPrimary && this.state.currentView === "join" && this.state.teamsToChooseFrom.length;

    if (!isCreating && this.props.onPreSave) {
      this.props.onPreSave();
    }

    if (!yourPersonId) {
      if (this.props.onSave) {
        this.props.onSave();
      }
      return;
    }
    // if select mode, no need for any mutations, just tell us what was selected
    if (isSelecting) {
      if (this.props.onSave) {
        const teamId = this.state.selectedTeamId;
        this.props.onSave(teamId);
      }
    } else if (isCreating) {
      if (userTeams && userTeams.length >= 5) {
        toast.error(
          "You have joined too many teams. Please remove yourself from a team if you wish to create a new one.",
        );
        return;
      }

      const teamMembers = Array.from([
        yourPersonId,
        ...Object.keys(this.state.selectedPersonIds),
      ]).map((personId) => ({ personId, role: ISchema.TeamMemberRole.Member }));

      createTeam(
        this.props.relay.environment,
        {
          teamMembers,
          orgRelayId: org.id,
          teamName: this.state.teamName,
          teamType: ISchema.TeamType.Other,
          setAsPrimary: (mode === "settings" && !onTeam) || mode === "onboarding",
        },
        (response) => {
          const performPostSave = async () => {
            if (this.props.onPreSave) {
              this.props.onPreSave();
            }
            if (this.props.onSave) {
              this.props.onSave(response?.team?.teamId);
            }

            if (response) {
              updateTeamCalendarState(this.props.relay.environment, {
                orgRelayId: org.id,
                teamId: response.team.teamId,
              });

              createTeamCalendar(
                this.props.relay.environment,
                {
                  orgRelayId: org.id,
                  teamId: response.team.teamId,
                },
                () => {
                  if (!this.props.onPreSave) {
                    toast.success("Your team has been created!");

                    if (this.props.shouldReloadAfterCreateOrJoin) {
                      // Send user to the new team settings page
                      window.location.href = `${appPaths.teamSettings}/${response.team.teamId}`;
                    }
                  }

                  track(teamCreatedEvents[analyticsMode], {
                    nInvited: teamMembers.length,
                    teamName: this.state.teamName,
                    teamId: response?.team.teamId,
                    calendarIds: Object.values(this.state.selectedPersonIds),
                  });
                },
              );
            }
          };
          void performPostSave();
        },
        (error) => {
          if (
            mode === "onboarding" &&
            error.message.includes("Unable to create team - teammate is on too many teams")
          ) {
            toast.error(
              "Sorry, we can't create your team - someone you invited is on too many teams",
            );
            if (this.props.onFail) {
              this.props.onFail();
            }
          } else if (!this.props.onPreSave) {
            toast.error("Sorry, something went wrong creating your team");
          }
        },
      );
    } else if (isSettingPrimary) {
      const teamId = this.state.selectedTeamId;

      setPrimaryTeam(
        this.props.relay.environment,
        {
          teamId,
          orgRelayId: this.props.org.id,
        },
        () => {
          if (this.props.onSave) {
            this.props.onSave();
          }

          if (!this.props.onPreSave) {
            toast.success("Your primary team has been set!");
          }
        },
        () => {
          if (!this.props.onPreSave) {
            toast.error("Sorry, something went wrong setting your primary team");
          }
        },
      );

      userTeams.forEach((team) => {
        if (
          team.teamCalendarId &&
          team.settings &&
          team.settings.__typename !== "GraphEntityError" &&
          team.settings.manageTeamCalendar &&
          team.userSettings &&
          team.userSettings.__typename !== "GraphEntityError" &&
          team.userSettings.useTeamCalendar
        ) {
          PostMessageManager.toggleCalendar(team.teamCalendarId, true);
        }
      });

      if (analyticsMode === "onboarding" || analyticsMode === "migration") {
        track(
          mode === "onboarding"
            ? TrackingEvents.ONBOARDING.ONBOARDING_SET_PRIMARY_TEAM
            : TrackingEvents.TEAMS_MIGRATION.SET_PRIMARY_TEAM,
          { teamId },
        );
      }
    } else if (isJoining) {
      const team = this.state.teamsToChooseFrom.find(
        (team) => team.id === this.state.selectedTeamId,
      )!;
      const teamId = team.id;

      updateTeam(
        this.props.relay.environment,
        {
          teamId,
          orgRelayId: this.props.org.id,
          addedTeamMembers: [
            {
              personId: yourPersonId,
              role: ISchema.TeamMemberRole.Member,
            },
          ],
        },
        (response) => {
          if (this.props.onSave) {
            this.props.onSave(response?.team.teamId);
          }

          if (!this.props.onPreSave) {
            toast.success("You have joined your team!");

            if (this.props.shouldReloadAfterCreateOrJoin) {
              window.location.reload();
            }
          }

          if (
            response?.team.teamCalendarId &&
            response.team.settings &&
            response.team.settings.__typename !== "GraphEntityError" &&
            response.team.settings.manageTeamCalendar &&
            response.team.userSettings &&
            response.team.userSettings.__typename !== "GraphEntityError" &&
            response.team.userSettings.useTeamCalendar
          ) {
            PostMessageManager.toggleCalendar(response.team.teamCalendarId, true);
          }
        },
        () => {
          if (!this.props.onPreSave) {
            toast.error("Sorry, something went wrong joining your team");
          }
        },
      );

      track(teamJoinedEvents[analyticsMode], { teamId, isSuggested: !!team.suggestedTeam });
    }
  };

  private onCreateTeamBtnClick = () => {
    this.props.onChangeValidity(
      this.isTeamNameValid(this.state.teamName, this.state.teamNameIsValid),
    );
    this.props.onChangeView("create");
    this.setState({ currentView: "create" });
  };

  ///////////////
  // Lifecycle //
  ///////////////
  private updateTeamsFromProps(props: IProps, stateChanges?: any, stateCallback?: () => void) {
    const { org, viewer, mode } = props;

    const userTeams =
      (org.userTeams?.__typename !== "GraphEntityError" && org.userTeams.list) || undefined;

    const teamQueryResult =
      (viewer.teamQueryResultErrorable?.__typename !== "GraphEntityError" &&
        viewer.teamQueryResultErrorable) ||
      undefined;

    const teamsQueryTeams = teamQueryResult?.teams;

    const suggestedTeams =
      (org.suggestedTeams &&
        org.suggestedTeams.__typename !== "GraphEntityError" &&
        org.suggestedTeams.list) ||
      [];

    const suggestedTeamsMap: Record<string, ISchema.ISuggestedTeam> = {};

    (suggestedTeams || []).forEach((st) => (suggestedTeamsMap[st.team.teamId] = st));

    const augmentedTeams = teamQueryResult &&
      teamQueryResult.query === "" && [
        ...suggestedTeams.map((st) => st.team),
        ...(teamsQueryTeams || []).filter((team) => !suggestedTeamsMap[team.teamId]),
      ];

    const primaryTeam = getValue(org.primaryTeam);

    const personMap: Record<string, ISchema.IOrgPerson> = {};

    (
      (viewer.teamQueryResultErrorable &&
        viewer.teamQueryResultErrorable.__typename !== "GraphEntityError" &&
        viewer.teamQueryResultErrorable.teamMembers) ||
      []
    ).forEach((teamMember) => (personMap[teamMember.person.personId] = teamMember.person));

    userTeams &&
      userTeams.forEach((userTeam) =>
        [...(userTeam.teamMembers || []), ...(userTeam.invitedMembers || [])].forEach(
          (teamMember) => {
            personMap[teamMember.person.personId] = teamMember.person;
          },
        ),
      );

    const newTeams = [];
    const teamGroup =
      (mode !== "settings" && userTeams?.length && userTeams) ||
      augmentedTeams ||
      teamsQueryTeams ||
      [];
    let newSelectedId = "";
    for (let i = 0; i < teamGroup.length; i++) {
      const team = teamGroup[i];
      const teamPersons =
        ((team.invitedMembers || team.teamMembers) &&
          [...(team.invitedMembers || []), ...(team.teamMembers || [])].map(
            (teamMember) => teamMember.person.personId,
          )) ||
        team.teamRoleAndPersonIds?.map(({ personId }) => personId) ||
        [];

      const maxTeamSize = props.maxTeamSizeOverride || MAX_TEAM_SIZE;
      const isDisabled =
        teamPersons.length >= maxTeamSize ||
        (mode === "checkout" && isTeamInPaidSubscription(props.org, team));
      if (this.getSelectedTeam(mode, isDisabled, primaryTeam, team, i === 0, suggestedTeamsMap)) {
        newSelectedId = team.teamId;
      }
      newTeams.push({
        team,
        name: team.teamName,
        id: team.teamId,
        persons: teamPersons,
        suggestedTeam: suggestedTeamsMap[team.teamId],
        disabled: isDisabled,
      });
    }

    this.setState(
      { ...stateChanges, personMap, teamsToChooseFrom: newTeams, selectedTeamId: newSelectedId },
      stateCallback,
    );
  }

  private getSelectedTeam = (
    mode: TeamsFormMode,
    isTeamDisabled: boolean,
    primaryTeam: ISchema.ITeam | null,
    currentTeam: ISchema.ITeam,
    isFirstTeam: boolean,
    suggestedTeamsMap: Record<string, ISchema.ISuggestedTeam>,
  ) => {
    const inCheckoutAndPrimary = mode === "checkout" && primaryTeam && !isTeamDisabled;
    const isThisThePrimaryTeam =
      mode !== "settings" && primaryTeam?.teamId === currentTeam.teamId && !isTeamDisabled;
    const isSuggestedAndNoPrimaryTeam =
      (mode === "settings" || !primaryTeam) &&
      isFirstTeam &&
      !!suggestedTeamsMap[currentTeam.teamId];
    return inCheckoutAndPrimary || isThisThePrimaryTeam || isSuggestedAndNoPrimaryTeam;
  };

  public componentDidMount() {
    const { org, viewer, mode, forcedInitialView } = this.props;

    const userTeams =
      org.userTeams && org.userTeams.__typename !== "GraphEntityError" && org.userTeams.list;

    const teamQueryResult =
      viewer.teamQueryResultErrorable &&
      viewer.teamQueryResultErrorable.__typename !== "GraphEntityError" &&
      viewer.teamQueryResultErrorable;

    const teams = teamQueryResult && teamQueryResult.teams;

    const joinMode =
      mode !== "settings" && userTeams && userTeams.length
        ? userTeams.length === 1
          ? "single-team"
          : "select-primary"
        : teams && teams.length
        ? "normal"
        : "off";
    const currentView =
      forcedInitialView ||
      (mode !== "settings" && ((teams && teams.length) || (userTeams && userTeams.length))
        ? this.props.selectMode
          ? "select"
          : "join"
        : "create");

    this.props.onChangeView(currentView);
    this.props.onChangeJoinMode(joinMode);
    this.props.onChangeValidity(false);

    this.updateTeamsFromProps(
      this.props,
      {
        joinMode,
        currentView: joinMode === "off" ? "create" : currentView,
      },
      () => {
        const selectedTeam = this.state.teamsToChooseFrom.find(
          (team) => team.id === this.state.selectedTeamId,
        );
        if (
          (this.state.currentView === "join" || this.state.currentView === "select") &&
          selectedTeam
        ) {
          this.props.onChangeValidity(true);
        }
      },
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const query =
      this.props.viewer &&
      this.props.viewer.teamQueryResultErrorable.__typename !== "GraphEntityError" &&
      this.props.viewer.teamQueryResultErrorable.query;

    const nextQuery =
      nextProps.viewer &&
      nextProps.viewer.teamQueryResultErrorable.__typename !== "GraphEntityError" &&
      nextProps.viewer.teamQueryResultErrorable.query;

    if (!this.props.save && nextProps.save) {
      this.onSave();
    }

    if (query !== nextQuery) {
      this.updateTeamsFromProps(nextProps);
    }
  }

  private onSelectPill = (pillOption: "join" | "select" | "create") => {
    const { analyticsMode } = this.props;
    let isValidTeam = !!this.state.selectedTeamId;
    if (pillOption === "create") {
      isValidTeam = this.isTeamNameValid(this.state.teamName, this.state.teamNameIsValid);
    }
    this.props.onChangeValidity(isValidTeam);
    this.props.onChangeView(pillOption);
    this.setState({ currentView: pillOption });
    track(teamTabChangedEvents[analyticsMode], { currentView: pillOption });
  };

  private renderCurrentView = () => {
    const { currentView } = this.state;

    const suggestedTeams =
      (this.props.org.suggestedTeams &&
        this.props.org.suggestedTeams.__typename !== "GraphEntityError" &&
        this.props.org.suggestedTeams.list) ||
      [];

    switch (currentView) {
      case "join":
      case "select":
        return (
          <>
            <TeamJoinForm
              joinMode={this.state.joinMode}
              mode={this.props.mode}
              org={this.props.org}
              loading={this.state.loading}
              query={this.state.query}
              teams={this.state.teamsToChooseFrom}
              hasSuggestedTeams={!!suggestedTeams.length}
              personMap={this.state.personMap}
              onChangeSearchQuery={this.onChangeSearchQuery}
              onChangeTeam={this.onChangeTeam}
              onCreateTeamBtnClick={this.onCreateTeamBtnClick}
              selectedTeamId={this.state.selectedTeamId}
            />
          </>
        );
      case "create":
      default:
        return (
          <TeamCreate
            joinMode={this.state.joinMode}
            mode={this.props.mode}
            onCheckTeammate={this.onCheckTeammate}
            onChangeTeamName={this.onChangeTeamName}
            onInitializeSearch={this.onInitSearch}
            org={this.props.org}
          />
        );
    }
  };

  public render() {
    return (
      <>
        <TeamJoinCreatePills
          currentView={this.state.currentView}
          joinMode={this.state.joinMode}
          mode={this.props.mode}
          onSelectPill={this.onSelectPill}
          selectMode={this.props.selectMode}
        />
        {this.renderCurrentView()}
      </>
    );
  }
}

const TeamJoinCreateRelay = createRefetchContainer(TeamJoinCreateComponent, fragments, query);

export const TeamJoinCreate = (props: IContainer) => {
  const [maxTeamSizeOverride] = useFeatureFlagNumber("MaxTeamSizeOverride");
  return <TeamJoinCreateRelay {...props} maxTeamSizeOverride={maxTeamSizeOverride} />;
};
