import { IDBPDatabase, openDB } from "idb";

// ~-~-~-~-~-~-~-
// IDB Keys
// ~-~-~-~-~-~-~-
// authenticated jwt for auth/login
const JWT = "Jwt";

// current org
const XSRF = "Xsrf";

// current org
const ORG_ID = "OrgId";

// ~-~-~-~-~-~-~-
// useIDB
// ~-~-~-~-~-~-~-
export const useIDB = async () => {
  const db = await openDB("cw-keyval-store", 1, {
    upgrade(db) {
      db.createObjectStore("cw-keyval");
    },
  });

  return {
    jwt: new IndexedDB(JWT, db),
    xsrf: new IndexedDB(XSRF, db),
    orgId: new IndexedDB(ORG_ID, db),
  };
};

// ~-~-~-~-~-~-~-
// IDB Class
// ~-~-~-~-~-~-~-
class IndexedDB {
  private key: string;
  private db: IDBPDatabase<any>;

  constructor(key: string, db: IDBPDatabase<any>) {
    this.key = key;
    this.db = db;
  }

  public set = async (value: string) => {
    if (!value) {
      console.error("Attempted to set idb value to undefined/null/empty. Use remove instead.");
    }

    return await this.db.put("cw-keyval", value, this.key);
  };

  public get = async () => {
    return await this.db.get("cw-keyval", this.key);
  };

  public remove = async () => {
    return await this.db.delete("cw-keyval", this.key);
  };
}
