import { CountryPicker } from "#webapp/src/components/country-picker";
import { TextField } from "@clockwise/design-system";
import { Address } from "@stripe/stripe-js";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  getCityLabel,
  getPostalCodeLabel,
  isPostalCodeShown,
  isStateShown,
} from "./LocalizedAddressForm.util";
interface LocalizedAddressFormProps {
  initialBillingAddress: Address;
  onChange: (address: Address) => void;
}
export const LocalizedAddressForm = ({
  initialBillingAddress,
  onChange,
}: LocalizedAddressFormProps) => {
  const addressLine1Label = "Address";
  const addressLine2Label = "Apartment, suite, etc.";
  const stateLabel = "State, county, province, or region";

  const [address, setAddress] = useState<Address>({
    city: "", // City, district, suburb, town, or village.
    country: "US", // 2-letter country code
    line1: "", // Address line 1 (e.g., street, PO Box, or company name).
    line2: "", // Address line 2 (e.g., apartment, suite, unit, or building).
    postal_code: "", // ZIP or postal code.
    state: "", // State, county, province, or region.
  });

  const [postalCodeLabel, setPostalCodeLabel] = useState("Zip Code");
  const [cityLabel, setCityLabel] = useState("City");

  useEffect(() => {
    setAddress(initialBillingAddress);
  }, []);

  const onChangeHandler = (_address: Address) => {
    onChange(_address);
  };

  const debouncedOnChangeHandler = useCallback(debounce(onChangeHandler, 500), []);

  useEffect(() => {
    debouncedOnChangeHandler(address);
  }, [address]);

  const showRelevantFormFields = (country: string) => {
    const previousAddress = { ...address };
    setAddress({
      ...address,
      country: country,
      state: isStateShown(country) ? previousAddress.state : "",
      postal_code: isPostalCodeShown(country) ? previousAddress.postal_code : "",
    });

    // Update the ZIP label to make it more relevant for each country.
    setPostalCodeLabel(getPostalCodeLabel(country));

    // Update the 'City' to appropriate name
    setCityLabel(getCityLabel(country));
  };

  // ~-~-~-~-~-~-~-
  // Handlers
  // ~-~-~-~-~-~-~-
  const onChangeAddressLine1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setAddress({ ...address, line1: value });
  };

  const onChangeAddressLine2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAddress({ ...address, line2: value });
  };

  const onChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setAddress({ ...address, city: value });
  };

  const onChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAddress({ ...address, state: value });
  };

  const onChangePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAddress({ ...address, postal_code: value });
  };

  const onChangeCountry = (countryId: string) => {
    showRelevantFormFields(countryId);
  };

  const maybeRenderStateInput = () => {
    if (!isStateShown(address.country)) {
      return null;
    }

    return (
      <div className="cw-flex cw-justify-between cw-my-2">
        <TextField
          fullWidth
          placeholder={stateLabel}
          value={address.state || ""}
          onChange={onChangeState}
          fieldSize="large"
        />
      </div>
    );
  };

  const maybeRenderPostalCodeInput = () => {
    if (!isPostalCodeShown(address.country)) {
      return null;
    }

    return (
      <TextField
        fullWidth
        placeholder={postalCodeLabel}
        value={address.postal_code || ""}
        onChange={onChangePostalCode}
        fieldSize="large"
      />
    );
  };

  return (
    <div>
      <div className="cw-flex cw-justify-between cw-my-2">
        <TextField
          fullWidth
          placeholder={addressLine1Label}
          value={address.line1 || ""}
          onChange={onChangeAddressLine1}
          fieldSize="large"
        />
      </div>
      <div className="cw-flex cw-justify-between cw-my-2">
        <TextField
          fullWidth
          placeholder={addressLine2Label}
          value={address.line2 || ""}
          onChange={onChangeAddressLine2}
          fieldSize="large"
        />
      </div>
      {maybeRenderStateInput()}
      <div className="cw-flex cw-justify-between cw-my-2">
        <TextField
          fullWidth
          style={{ marginRight: 10 }}
          placeholder={cityLabel}
          value={address.city || ""}
          onChange={onChangeCity}
          fieldSize="large"
        />
        {maybeRenderPostalCodeInput()}
      </div>
      <CountryPicker onSelectCountry={onChangeCountry} />
    </div>
  );
};
