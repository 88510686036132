// eslint-disable
// graphql typescript definitions

export type Maybe<T> = T | null;

export interface IGraphQLResponseRoot {
  data: IQuery | IMutation | ISubscription;
  errors: Array<IGraphQLResponseError>;
}

export interface IGraphQLResponseError {
  /** Required for all errors */
  message: string;
  locations: Array<IGraphQLResponseErrorLocation>;
  /** 7.2.2 says 'GraphQL servers may provide additional entries to error' */
  [propName: string]: any;
}

export interface IGraphQLResponseErrorLocation {
  line: number;
  column: number;
}

export interface IQuery {
  __typename: "Query";
  viewer: IViewer;
}

/**
 * The base node for all queries
 */
export interface IViewer {
  __typename: "Viewer";

  /**
   * Search external groups against a query string
   */
  groupQueryResult: ExternalGroupSearchResultErrorable;

  /**
   * The ID of an object
   */
  id: string;

  /**
   * Non-User Defrag Email Survey Response
   */
  nonUserDefragEmailResponse: NonUserDefragEmailResponseErrorable;

  /**
   * Search org persons against a query string
   */
  orgPersonQueryResultErrorable: OrgPersonQueryResultErrorable;

  /**
   * Search persons against a query string
   */
  personQueryResultErrorable: PersonQueryResultErrorable;

  /**
   * Search external rooms against a query string
   */
  roomQueryResult: ExternalRoomSearchResultErrorable;
  sudo: ISudo;

  /**
   * Search teams within an org
   */
  teamQueryResultErrorable: TeamQueryResultErrorable;

  /**
   * A Clockwise user
   */
  user: IUser;

  /**
   * Checks that a verification code is valid and which external client it will connect
   */
  verificationCodeResponse: IVerificationCodeResponse;
}

export interface IGroupQueryResultOnViewerArguments {
  /**
   * Calendars to not search for
   * @default []
   */
  excludeCalendars?: Array<string>;

  /**
   * @default 5
   */
  limit?: number;
  orgRelayId: string;
  query: string;
}

export interface INonUserDefragEmailResponseOnViewerArguments {
  emailInstanceId: string;
}

export interface IOrgPersonQueryResultErrorableOnViewerArguments {
  /**
   * @default []
   */
  excludeCalendars?: Array<string>;

  /**
   * @default 5
   */
  limit?: number;
  orgRelayId: string;
  query: string;
}

export interface IPersonQueryResultErrorableOnViewerArguments {
  /**
   * @default []
   */
  excludeCalendars?: Array<string>;

  /**
   * @default 5
   */
  limit?: number;
  orgRelayId: string;
  query: string;
}

export interface IRoomQueryResultOnViewerArguments {
  excludeCalendars: Array<string>;

  /**
   * @default 5
   */
  limit?: number;
  orgRelayId?: string;
  query: string;
}

export interface ITeamQueryResultErrorableOnViewerArguments {
  /**
   * @default false
   */
  excludeMyTeams?: boolean;

  /**
   * @default 5
   */
  limit?: number;
  orgRelayId: string;
  query: string;
}

export type ExternalGroupSearchResultErrorable = IExternalGroupSearchResult | IGraphEntityError;

/**
 * Result of searching for external groups
 */
export interface IExternalGroupSearchResult {
  __typename: "ExternalGroupSearchResult";
  groups: Array<IExternalGroupSearchMetadata>;
  inputQuery: string;
}

/**
 * Metadata about an external group
 */
export interface IExternalGroupSearchMetadata {
  __typename: "ExternalGroupSearchMetadata";
  emailAddress: string;
  groupSize: number;
}

/**
 * An error wrapper
 */
export interface IGraphEntityError {
  __typename: "GraphEntityError";
  body: string;
  message: string;
  statusCode: number;
}

export type NonUserDefragEmailResponseErrorable = IGraphEntityError | INonUserDefragEmailResponse;

/**
 * Response to non-user defrag email survey
 */
export interface INonUserDefragEmailResponse {
  __typename: "NonUserDefragEmailResponse";
  additionalInformation: string;

  /**
   * The ID of an object
   */
  id: string;
  responseType: NonUserDefragEmailResponseType;
}

export enum NonUserDefragEmailResponseType {
  No = "No",
  NotSure = "NotSure",
  Yes = "Yes",
}

export type OrgPersonQueryResultErrorable = IGraphEntityError | IOrgPersonQueryResult;

/**
 * Search results for org persons
 */
export interface IOrgPersonQueryResult {
  __typename: "OrgPersonQueryResult";

  /**
   * Persons that match the query
   */
  persons: Array<IOrgPerson>;

  /**
   * The input query string
   */
  query: string;
}

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface IOrgPerson {
  __typename: "OrgPerson";
  calendarAliases: Array<string>;
  eligibleForTeams: boolean;

  /**
   * The ID of an object
   */
  id: string;
  isClockwiseUser: boolean;
  isYou: boolean;
  personId: string;
  primaryBillingGroupIdErrorable: PrimaryBillingGroupIdErrorable;
  primaryCalendarId: string;
  profile: IUserProfile;
  redirectPersonId: string;
  signUpMillis: any;
  userId: string;
}

export type PrimaryBillingGroupIdErrorable = IGraphEntityError | IPrimaryBillingGroupId;

export interface IPrimaryBillingGroupId {
  __typename: "PrimaryBillingGroupId";
  primaryBillingGroupId: string;
}

/**
 * A User Profile includes just the basic information about a Calendar User (not necessarily a Clockwise user)
 */
export interface IUserProfile {
  __typename: "UserProfile";

  /**
   * The URL for a User's profile image
   */
  externalImageUrl: string;

  /**
   * A user's last name
   */
  familyName: string;

  /**
   * A user's first name
   */
  givenName: string;
}

export type PersonQueryResultErrorable = IGraphEntityError | IPersonQueryResult;

/**
 * Search results for persons
 */
export interface IPersonQueryResult {
  __typename: "PersonQueryResult";

  /**
   * Persons that match the query
   */
  persons: Array<IPerson>;

  /**
   * The input query string
   */
  query: string;
}

/**
 * A person
 */
export interface IPerson {
  __typename: "Person";

  /**
   * The emails of the person
   */
  emails: Array<string>;

  /**
   * The ID of an object
   */
  id: string;
  isOutsideOrg: boolean;
  isUser: boolean;
  isYou: boolean;

  /**
   * The primary calendar of the person
   */
  primaryCalendar: any;

  /**
   * The primary email of the person
   */
  primaryEmail: string;

  /**
   * The profile of the person, may be null
   */
  profile: IUserProfile;
  userId: string;
}

export type ExternalRoomSearchResultErrorable = IExternalRoomSearchResult | IGraphEntityError;

/**
 * Result of searching for a room by name
 */
export interface IExternalRoomSearchResult {
  __typename: "ExternalRoomSearchResult";

  /**
   * Whether the result of the query is cacheable (e.g. was there an error)
   */
  cacheable: boolean;
  rooms: Array<IRoom>;
  searchQuery: string;
}

/**
 * A room
 */
export interface IRoom {
  __typename: "Room";
  estimatedCapacity: number;

  /**
   * The ID of an object
   */
  id: string;

  /**
   * @deprecated "Not populated, always null"
   */
  locationId: string;
  name: string;
  orgId: string;
  reportedCapacity: number;
  roomId: string;
}

export interface ISudo {
  __typename: "Sudo";

  /**
   * Last update times for the chrome app
   */
  chromeAppUpdateTimes: IChromeAppUpdateTimes;

  /**
   * All emails for all users
   */
  claimedEmails: Array<string>;

  /**
   * The ID of an object
   */
  id: string;
  listCliCommands: CliCommandListErrorable;
  modifyUser: IUserAndExtendedInfo;
  resetFlowState: IFlowState;
}

export interface IResetFlowStateOnSudoArguments {
  /**
   * @default ""
   */
  email?: string;
}

export interface IChromeAppUpdateTimes {
  __typename: "ChromeAppUpdateTimes";

  /**
   * Last update of the chrome app with immediate priority
   */
  forceTime: any;

  /**
   * Last update of the chrome app with idle priority
   */
  idleTime: any;
}

export type CliCommandListErrorable = ICliCommandList | IGraphEntityError;

export interface ICliCommandList {
  __typename: "CliCommandList";
  list: Array<ICliCommand>;
}

/**
 * The schema for a CLI command
 */
export interface ICliCommand {
  __typename: "CliCommand";
  description: string;

  /**
   * The ID of an object
   */
  id: string;
  name: string;
  options: Array<ICliOption>;
}

/**
 * The schema for an option of a CLI command
 */
export interface ICliOption {
  __typename: "CliOption";
  description: string;
  name: string;
  type: CliOptionType;
}

export enum CliOptionType {
  Boolean = "Boolean",
  String = "String",
}

export interface IUserAndExtendedInfo {
  __typename: "UserAndExtendedInfo";
  ecosystem: EcosystemEnum;

  /**
   * User's feature grid
   */
  featureStatesJson: string;

  /**
   * Org's current accounting period
   */
  orgAccountingPeriod: OrgAccountingPeriodErrorable;

  /**
   * User's subscriptions
   */
  subscriptions: PaymentSubscriptionListErrorable;

  /**
   * User
   */
  user: IUser;

  /**
   * User's teams
   */
  userTeams: TeamListErrorable;
}

export enum EcosystemEnum {
  Google = "Google",
  Microsoft = "Microsoft",
  NotApplicable = "NotApplicable",
}

export type OrgAccountingPeriodErrorable = IGraphEntityError | IOrgAccountingPeriod;

/**
 * Freemium Org accounting period info
 */
export interface IOrgAccountingPeriod {
  __typename: "OrgAccountingPeriod";
  enforcementStarted: boolean;
  enforcementState: EnforcementStateEnum;
  periodRange: IDateRange;
  quotaMax: number;
  quotaUsed: number;
}

export enum EnforcementStateEnum {
  ApproachingLimit = "ApproachingLimit",
  NoEnforcement = "NoEnforcement",
  ReachedLimit = "ReachedLimit",
}

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface IDateRange {
  __typename: "DateRange";
  maxDate: string;
  minDate: string;
}

export type PaymentSubscriptionListErrorable = IGraphEntityError | IPaymentSubscriptionList;

export interface IPaymentSubscriptionList {
  __typename: "PaymentSubscriptionList";
  list: Array<IPaymentSubscription>;
}

/**
 * A product subscription under an org for a particular subjectt
 */
export interface IPaymentSubscription {
  __typename: "PaymentSubscription";
  endMillis: number;

  /**
   * The ID of an object
   */
  id: string;
  productType: ProductTypeEnum;
  seatCount: number;
  startMillis: number;
  subjectUrn: IUrn;
  type: SubscriptionTypeEnum;
}

export enum ProductTypeEnum {
  Business_NonStandard = "Business_NonStandard",
  Business_Standard = "Business_Standard",
  Enterprise_NonStandard = "Enterprise_NonStandard",
  Enterprise_Standard = "Enterprise_Standard",
  Pro_NonStandard = "Pro_NonStandard",
  Pro_Standard = "Pro_Standard",
}

/**
 * A Uniform Resource Name
 */
export interface IUrn {
  __typename: "Urn";

  /**
   * The ID of an object
   */
  id: string;
  scope: IUrn;
  type: UrnType;
  value: string;
}

export enum UrnType {
  Date = "Date",
  Email = "Email",
  Event = "Event",
  FlowState = "FlowState",
  Guest = "Guest",
  Org = "Org",
  Phone = "Phone",
  Services = "Services",
  Setting = "Setting",
  Team = "Team",
  Teammate = "Teammate",
  TimeZone = "TimeZone",
  User = "User",
  WorkOsOrg = "WorkOsOrg",
  WorkingGroup = "WorkingGroup",
  WorkingHours = "WorkingHours",
}

export enum SubscriptionTypeEnum {
  Expired = "Expired",
  FreemiumOrg = "FreemiumOrg",
  LegacyPrePaywall = "LegacyPrePaywall",
  PaidBillingGroup = "PaidBillingGroup",
  PaidOrgPro = "PaidOrgPro",
  PaidTeamPro = "PaidTeamPro",
  PaidUserPro = "PaidUserPro",
  SelfServePremiumOrgQ42020 = "SelfServePremiumOrgQ42020",
  SelfServePremiumTeamQ12021 = "SelfServePremiumTeamQ12021",
}

/**
 * A person who uses our app
 */
export interface IUser {
  __typename: "User";
  chatHistory: IChatHistory;

  /**
   * The user's bucket o' booleans for the chrome extension
   */
  chromeExtensionFlagsErrorable: FlagsErrorable;
  createdTime: any;

  /**
   * A user's collection of data sources
   */
  dataSources: IDataSourceConnection;
  diffDetailsById: DiffDetails;
  diffDetailsByIdAndProposalId: DiffDetails;
  emails: Array<string>;

  /**
   * The profile image of the user
   */
  externalImageUrl: string;

  /**
   * The last name of the user
   */
  familyName: string;
  fetchSharedProposal: ISharedProposal;

  /**
   * The collection of flow states for the user
   */
  flowStates: IFlowStateConnection;

  /**
   * The first name of the user
   */
  givenName: string;

  /**
   * The ID of an object
   */
  id: string;
  jwtIssuedTime: any;

  /**
   * A list of primary email/org urns for this user
   */
  members: Array<IUrn>;

  /**
   * The collection of orgs the user belongs to
   */
  orgs: IOrgConnection;
  rolloutProperties: any;
  scoreTime: IScoredTime;

  /**
   * The user's bucket o' booleans for the services
   */
  servicesFlagsErrorable: FlagsErrorable;

  /**
   * Status of the user
   */
  status: UserStatusEnum;

  /**
   * The user's bucket o' booleans for the web app
   */
  webAppFlagsErrorable: FlagsErrorable;
}

export interface IDataSourcesOnUserArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;

  /**
   * @default "all"
   */
  name?: string;
}

export interface IDiffDetailsByIdOnUserArguments {
  diffId: string;
}

export interface IDiffDetailsByIdAndProposalIdOnUserArguments {
  diffId: string;
  proposalId: string;
}

export interface IFetchSharedProposalOnUserArguments {
  proposalId: string;
}

export interface IFlowStatesOnUserArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

export interface IOrgsOnUserArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  orgRelayId?: string;
}

export interface IScoreTimeOnUserArguments {
  attendees: Array<any>;
  endTime: any;
  startTime: any;
}

/**
 * A list of messages that make up a conversation between a user and Clockwise AI.
 */
export interface IChatHistory {
  __typename: "ChatHistory";

  /**
   * The ID of an object
   */
  id: string;

  /**
   * Whether or not the user's message is still being processed
   */
  isProcessing: boolean;
  messages: Array<ChatMessage>;

  /**
   * Whether or not the user can use Clockwise AI's Chat functionality
   */
  shouldExcludeAi: boolean;
}

/**
 * Either a user message or an assistant response: text, view events, or a (re)scheduling proposal
 */
export type ChatMessage =
  | IProposalResponse
  | ISchedulingLinksResponse
  | ITextResponse
  | IUserMessage
  | IViewEventsResponse;

/**
 * A proposal response from the assistant in response to a (re)schedule or update events request
 */
export interface IProposalResponse {
  __typename: "ProposalResponse";
  conversationId: string;
  id: string;
  proposal: ISchedulingProposal;
}

/**
 * A proposal for (re)scheduling or updating events
 */
export interface ISchedulingProposal {
  __typename: "SchedulingProposal";
  availability: IAvailability;

  /**
   * List of conflicts
   */
  conflictClusters: Array<IConflictCluster>;

  /**
   * Message of changes the user has done to resolve conflicts
   */
  conflictReceipt: IConflictReceipt;

  /**
   * A block describing the consequences of the proposed changes to the user's calendar corresponding to the currently selected scheduling option, if any
   */
  consequencesBlock: IConsequencesBlock;

  /**
   * A list of diffs describing the proposed changes to the user's calendar corresponding to the currently selected scheduling option, if any
   */
  diffBlocks: Array<DiffBlock>;
  eventSearchBlocks: Array<IEventSearchBlock>;
  id: string;

  /**
   * Whether this proposal is shared with others
   */
  isShared: boolean;
  options: ProposalOptions;

  /**
   * The ID of the proposal used for shared proposal, i.e. isShared = true
   */
  proposalId: string;
  state: SchedulingProposalStateEnum;
}

export interface IAvailability {
  __typename: "Availability";

  /**
   * The eventDiffId to use for scheduling link creation for this proposal
   */
  baseEventDiffId: string;

  /**
   * A summary of availabilities for this proposal
   */
  summaryText: string;
}

export interface IConflictCluster {
  __typename: "ConflictCluster";

  /**
   * The events involved in the conflict
   */
  conflictEvents: Array<IConflictEvent>;
  id: string;

  /**
   * Whether the conflict cluster is active and visible in the UI
   */
  isActiveConflict: boolean;

  /**
   * The time range for the conflict cluster
   */
  timeBound: any;
}

export interface IConflictEvent {
  __typename: "ConflictEvent";

  /**
   * The actions available to the current user to resolve the conflict
   */
  availableActions: Array<ConflictAction>;

  /**
   * The external event id of the event
   */
  eventId: string;

  /**
   * The ID of an object
   */
  id: string;

  /**
   * Whether the event is flexible
   */
  isFlexible: boolean;

  /**
   * The organizer's calendar id
   */
  organizerCalId: string;

  /**
   * The title of the event
   */
  title: string;
}

export enum ConflictAction {
  CANCEL = "CANCEL",
  DECLINE = "DECLINE",
  RESCHEDULE = "RESCHEDULE",
  RSVP_MAYBE = "RSVP_MAYBE",
}

export interface IConflictReceipt {
  __typename: "ConflictReceipt";

  /**
   * A receipt of the actions taken to resolve users conflicts
   */
  receipt: string;
}

export interface IConsequencesBlock {
  __typename: "ConsequencesBlock";
  changesText: string;
  otherCalDiffs: Array<IOthersConsequenceDiffSummary>;
  yourCalDiffs: Array<IYourConsequenceDiffSummary>;
}

/**
 * A summary of a consequences for a diff proposed for other users' calendars
 */
export interface IOthersConsequenceDiffSummary {
  __typename: "OthersConsequenceDiffSummary";
  action: IDiffAction;

  /**
   * False if the diff has been rejected by the user
   */
  active: boolean;
  affectedAttendees: Array<IAffectedPerson>;
  currentTime: any;

  /**
   * The ID of the event being modified
   */
  eventId: string;
  id: string;

  /**
   * Whether or not the event is flexible
   */
  isFlexible: boolean;

  /**
   * Either the existing title or the proposed new title
   */
  title: string;

  /**
   * Null if the diff's time is not being updated or there were no times found
   */
  updatedTime: any;
}

/**
 * A diff summarizing the change we are proposing to a user's calendar
 */
export type DiffSummary =
  | IOthersConsequenceDiffSummary
  | IYourConsequenceDiffSummary
  | IAddDiffSummary
  | IModifyDiffSummary;

/**
 * A diff summarizing the change we are proposing to a user's calendar
 */
export interface IDiffSummary {
  __typename: "DiffSummary";
  action: IDiffAction;
  active: boolean;
  id: string;
  title: string;
}

/**
 * The update being made to the diff, e.g. ADD, RESCHEDULE, CANCEL, RSVP, UNCHANGED, EDIT_DETAILS along with a label
 */
export interface IDiffAction {
  __typename: "DiffAction";
  label: string;

  /**
   * The updated RSVP status of the user when the action is of type RSVP, null otherwise
   */
  rsvp: ResponseStatusEnum;
  type: DiffActionTypeEnum;
}

export enum ResponseStatusEnum {
  Accepted = "Accepted",
  Declined = "Declined",
  NeedsAction = "NeedsAction",
  NotApplicable = "NotApplicable",
  Tentative = "Tentative",
}

export enum DiffActionTypeEnum {
  ADD = "ADD",
  CANCEL = "CANCEL",
  EDIT_DETAILS = "EDIT_DETAILS",
  PROPOSE_NEW_TIME = "PROPOSE_NEW_TIME",
  RESCHEDULE = "RESCHEDULE",
  RSVP = "RSVP",
  UNCHANGED = "UNCHANGED",
}

/**
 * A person affected by the changes of a scheduling proposal
 */
export interface IAffectedPerson {
  __typename: "AffectedPerson";

  /**
   * The affected calendar ID of the person
   */
  calendarId: any;
  id: string;

  /**
   * The affected person
   */
  person: IOrgPerson;
}

/**
 * A summary of a consequences for a diff proposed for the current user's calendar
 */
export interface IYourConsequenceDiffSummary {
  __typename: "YourConsequenceDiffSummary";
  action: IDiffAction;

  /**
   * False if the diff has been rejected by the user
   */
  active: boolean;
  attendees: IProposalAttendees;
  currentTime: any;

  /**
   * The ID of the event being modified
   */
  eventId: string;
  id: string;

  /**
   * Whether or not the event is flexible
   */
  isFlexible: boolean;
  recurrence: IEventRecurrence;

  /**
   * The recurrence rule for the event
   */
  recurrenceRule: any;

  /**
   * Either the existing title or the proposed new title
   */
  title: string;

  /**
   * Null if the diff's time is not being updated or there were no times found
   */
  updatedTime: any;
}

/**
 * A list of attendees on a diff along with any attendee ambiguities
 */
export interface IProposalAttendees {
  __typename: "ProposalAttendees";
  ambiguities: Array<IAttendeeAmbiguity>;

  /**
   * @deprecated "use proposalAttendees instead, it contains responseStatus information"
   */
  attendeePeopleErrorable: PersonListErrorable;
  proposalAttendees: Array<IProposalAttendee>;
}

export interface IAttendeeAmbiguity {
  __typename: "AttendeeAmbiguity";
  attendeeRequested: string;

  /**
   * A list of attendee people that match the requested attendee string
   */
  disambiguations: PersonListErrorable;

  /**
   * Whether or not the ambiguity is a group, false if for a person
   */
  isGroup: boolean;
}

export type PersonListErrorable = IGraphEntityError | IPersonList;

export interface IPersonList {
  __typename: "PersonList";
  list: Array<IPerson>;
}

export interface IProposalAttendee {
  __typename: "ProposalAttendee";
  attendeePerson: IPerson;
  attendeeResponseStatus: ResponseStatusEnum;
  isOptional: boolean;
  isOrganizer: boolean;
}

/**
 * Recurrence rules for an event
 */
export interface IEventRecurrence {
  __typename: "EventRecurrence";

  /**
   * Interval of the event recurrence
   */
  intervalCount: number;

  /**
   * Type of the event recurrence
   */
  intervalType: RecurrenceIntervalTypeEnum;

  /**
   * String representation of the recurrence
   */
  recurrenceString: string;
}

export enum RecurrenceIntervalTypeEnum {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  NONE = "NONE",
  WEEKLY = "WEEKLY",
  YEARLY = "YEARLY",
}

/**
 * Text describing the proposed changes to a user's calendar along with a list of diffs representing the changes
 */
export type DiffBlock = IAddDiffBlock | IModifyDiffBlock;

export interface IAddDiffBlock {
  __typename: "AddDiffBlock";
  diffs: Array<IAddDiffSummary>;
  text: string;
}

/**
 * A summary of an event we are proposing to add to a user's calendar
 */
export interface IAddDiffSummary {
  __typename: "AddDiffSummary";
  action: IDiffAction;

  /**
   * False if the diff has been rejected by the user
   */
  active: boolean;
  attendees: IProposalAttendees;
  id: string;
  isFlexible: boolean;
  recurrence: IEventRecurrence;

  /**
   * The recurrence rule for the event
   */
  recurrenceRule: any;

  /**
   * Null if there were no times found
   */
  time: any;
  title: string;
  tradeoffBlocks: Array<ITradeoffBlock>;
}

/**
 * A summary of scheduling tradeoffs for a diff
 */
export interface ITradeoffBlock {
  __typename: "TradeoffBlock";

  /**
   * Deduplicated list of all attendees impacted
   */
  aggregateAttendees: Array<IOrgPerson>;
  id: string;

  /**
   * List of granular scheduling tradeoffs
   */
  schedulingTradeoffs: Array<ISchedulingTradeoff>;
  title: string;
  tradeoffType: TradeoffTypeEnum;
}

/**
 * A specific scheduling tradeoff for a diff
 */
export interface ISchedulingTradeoff {
  __typename: "SchedulingTradeoff";
  attendees: Array<IOrgPerson>;

  /**
   * The title of the event
   */
  eventTitle: string;
  externalEventId: string;
  id: string;

  /**
   * Why this meeting could not be rescheduled, thus making it a tradeoff.
   */
  reasonForInaction: TradeoffInactionReason;

  /**
   * The title of the tradeoff, includes the pill structuring.
   */
  title: string;
}

export enum TradeoffInactionReason {
  ExternalMeeting = "ExternalMeeting",
  IsActionable = "IsActionable",
  LowVisibility = "LowVisibility",
  Other = "Other",
}

export enum TradeoffTypeEnum {
  AVAILABILITY_ISSUE = "AVAILABILITY_ISSUE",
  FIXABLE_CONFLICT = "FIXABLE_CONFLICT",
  INCONVENIENCE = "INCONVENIENCE",
  LOW_VIZ_INCONVENIENCE = "LOW_VIZ_INCONVENIENCE",
  OUT_OF_TIME_RANGE = "OUT_OF_TIME_RANGE",
}

export interface IModifyDiffBlock {
  __typename: "ModifyDiffBlock";
  diffs: Array<IModifyDiffSummary>;
  text: string;
}

/**
 * A summary of an event we are proposing to update on a user's calendar
 */
export interface IModifyDiffSummary {
  __typename: "ModifyDiffSummary";
  action: IDiffAction;

  /**
   * False if the diff has been rejected by the user
   */
  active: boolean;

  /**
   * The list of attendees on the event after the proposed change as well as any ambiguities. Will include existing attendees plus any added ones, and minus any removed ones
   */
  attendees: IProposalAttendees;
  currentTime: any;

  /**
   * The ID of the event being modified
   */
  eventId: string;
  id: string;
  isFlexible: boolean;
  recurrence: IEventRecurrence;
  recurrenceRule: any;

  /**
   * Either the existing title or the proposed new title
   */
  title: string;
  tradeoffBlocks: Array<ITradeoffBlock>;

  /**
   * Null if the diff's time is not being updated or there were no times found
   */
  updatedTime: any;
}

/**
 * Facets used to search for events
 */
export interface IEventSearchBlock {
  __typename: "EventSearchBlock";
  attendeeFilter: IEventSearchAttendeeFilter;
  closeMatches: Array<IEventSearchCloseMatch>;

  /**
   * DiffIds of diffs that were sourced from the search
   */
  diffIds: Array<string>;
  eventIdFilters: Array<string>;

  /**
   * The ID of an object
   */
  id: string;
  ordinalModifier: IEventSearchOrdinalModifier;
  rsvpFilters: Array<RsvpFilterEnum>;

  /**
   * Event category filter
   */
  tagFilters: Array<TagFilterEnum>;
  timeRangeFilter: IEventSearchTimeRangeFilter;
  titleFilter: IEventSearchTitleFilter;
}

export interface IEventSearchAttendeeFilter {
  __typename: "EventSearchAttendeeFilter";
  modifier: AttendeeFilterModifierEnum;
  resolvedAttendees: Array<IPerson>;
  unresolvedAttendees: Array<string>;
}

export enum AttendeeFilterModifierEnum {
  ALL = "ALL",
  ANY = "ANY",
  EXACT = "EXACT",
}

/**
 * Events that are close matches to the search
 */
export interface IEventSearchCloseMatch {
  __typename: "EventSearchCloseMatch";
  externalEventId: string;
  title: string;
}

/**
 * Ordinal filter for search
 */
export interface IEventSearchOrdinalModifier {
  __typename: "EventSearchOrdinalModifier";
  modifier: OrdinalModifierEnum;
  ordinal: number;
}

export enum OrdinalModifierEnum {
  ALL = "ALL",
  FIRST = "FIRST",
  LAST = "LAST",
  NEXT = "NEXT",
  NTH = "NTH",
}

export enum RsvpFilterEnum {
  Accepted = "Accepted",
  Declined = "Declined",
  NeedsAction = "NeedsAction",
  Tentative = "Tentative",
}

export enum TagFilterEnum {
  Conflict = "Conflict",
  External = "External",
  Flexible = "Flexible",
  Hold = "Hold",
  Internal = "Internal",
  OOO = "OOO",
}

export interface IEventSearchTimeRangeFilter {
  __typename: "EventSearchTimeRangeFilter";
  excluded: Array<any>;
  included: Array<any>;
}

export interface IEventSearchTitleFilter {
  __typename: "EventSearchTitleFilter";
  modifier: TitleFilterModifierEnum;
  title: string;
}

export enum TitleFilterModifierEnum {
  CONTAINS = "CONTAINS",
  IS = "IS",
  LIKE = "LIKE",
}

export type ProposalOptions = INoSchedulingOptions | ISchedulingOptions;

/**
 * A placeholder for when there are no scheduling options provided
 */
export interface INoSchedulingOptions {
  __typename: "NoSchedulingOptions";
  nop: any;
}

/**
 * A list of scheduling options for a proposal
 */
export interface ISchedulingOptions {
  __typename: "SchedulingOptions";

  /**
   * The details for each option (in the correct order for `selectedIndex`)
   */
  optionDetails: Array<ISchedulingOption>;

  /**
   * The index of the selected option
   */
  selectedIndex: number;
}

export interface ISchedulingOption {
  __typename: "SchedulingOption";

  /**
   * An optional category for this scheduling option: availability issue, fixable conflict,  etc.
   */
  category: SchedulingOptionCategoryTypeEnum;
  consequencesBlock: IConsequencesBlock;

  /**
   * The ID of an object
   */
  id: string;
  interval: any;

  /**
   * The label for this option
   */
  label: string;
  tradeoffBlocks: Array<ITradeoffBlock>;

  /**
   * Whether this option is an INITIAL, CUSTOM, or ADDITIONAL suggestion
   */
  type: SchedulingOptionTypeEnum;
}

export enum SchedulingOptionCategoryTypeEnum {
  AVAILABILITY_ISSUE = "AVAILABILITY_ISSUE",
  FIXABLE_CONFLICT = "FIXABLE_CONFLICT",
  INCONVENIENCE = "INCONVENIENCE",
  OUT_OF_TIME_RANGE = "OUT_OF_TIME_RANGE",
}

export enum SchedulingOptionTypeEnum {
  ADDITIONAL = "ADDITIONAL",
  CUSTOM = "CUSTOM",
  INITIAL = "INITIAL",
}

export enum SchedulingProposalStateEnum {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  INCOMPLETE = "INCOMPLETE",
  LOADING_SUGGESTIONS = "LOADING_SUGGESTIONS",
  SOFT_CONFIRM = "SOFT_CONFIRM",
}

/**
 * A response from the assistant for creating or updating scheduling link(s) request
 */
export interface ISchedulingLinksResponse {
  __typename: "SchedulingLinksResponse";
  conversationId: string;
  id: string;
  linksMetadata: Array<ISchedulingLinkMetadata>;
  responseText: string;
}

/**
 * Metadata for a scheduling link
 */
export interface ISchedulingLinkMetadata {
  __typename: "SchedulingLinkMetadata";
  link: ISchedulingLink;
  operation: SchedulingLinkOperationEnum;
}

/**
 * Scheduling Link
 */
export interface ISchedulingLink {
  __typename: "SchedulingLink";
  linkName: string;
  linkUrl: string;
  meetingSettings: IMeetingSettings;
}

/**
 * Meeting Settings
 */
export interface IMeetingSettings {
  __typename: "MeetingSettings";
  description: string;
  id: string;
  isActive: boolean;
  linkMembers: Array<ILinkMember>;
  name: string;
  slug: string;
  timingSettings: ITimingSettings;
}

/**
 * Attendees of a scheduling link
 */
export interface ILinkMember {
  __typename: "LinkMember";
  calendarId: string;
  isRequired: boolean;
  type: LinkMemberTypeEnum;
  userProfile: IUserProfile;
}

export enum LinkMemberTypeEnum {
  Attendee = "Attendee",
  Owner = "Owner",
}

/**
 * Timing setttings for a Meeting Settings
 */
export interface ITimingSettings {
  __typename: "TimingSettings";
  defaultDuration: number;
  durations: Array<number>;
  periodType: TimingSettingsPeriodTypeEnum;
  timeRange: ITimeRangeSettings;
}

export enum TimingSettingsPeriodTypeEnum {
  Range = "Range",
  RollingWindow = "RollingWindow",
}

/**
 * Time range setting for a Timing Settings in a Meeting Settings
 */
export interface ITimeRangeSettings {
  __typename: "TimeRangeSettings";
  rangeEndTime: string;
  rangeStartTime: string;
  windowSizeDays: number;
}

export enum SchedulingLinkOperationEnum {
  CREATE = "CREATE",
  FIND = "FIND",
  SET_ACTIVE = "SET_ACTIVE",
  SET_INACTIVE = "SET_INACTIVE",
  UPDATE = "UPDATE",
}

/**
 * A text response from the assistant
 */
export interface ITextResponse {
  __typename: "TextResponse";
  conversationId: string;
  id: string;
  text: string;
  type: TextResponseTypeEnum;
}

export enum TextResponseTypeEnum {
  BASIC_TEXT = "BASIC_TEXT",
  MARKDOWN_TEXT = "MARKDOWN_TEXT",
}

export interface IUserMessage {
  __typename: "UserMessage";
  id: string;
  text: string;
}

/**
 * A view events response from the assistant in response to a query for events
 */
export interface IViewEventsResponse {
  __typename: "ViewEventsResponse";
  conversationId: string;

  /**
   * The date range the events are rendered within, expressed as a ISO8601 Interval
   */
  dateRange: any;

  /**
   * Event Ids for the returned events
   */
  eventIds: Array<string>;
  eventSearchBlocks: Array<IEventSearchBlock>;
  id: string;
  text: string;
}

export type FlagsErrorable = IFlags | IGraphEntityError;

/**
 * bucket o' booleans
 */
export interface IFlags {
  __typename: "Flags";
  flags: Array<string>;

  /**
   * The ID of an object
   */
  id: string;
}

/**
 * A connection to a list of items.
 */
export interface IDataSourceConnection {
  __typename: "DataSourceConnection";

  /**
   * A list of edges.
   */
  edges: Array<IDataSourceEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface IDataSourceEdge {
  __typename: "DataSourceEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: IDataSource;
}

/**
 * A data source
 */
export interface IDataSource {
  __typename: "DataSource";

  /**
   * List of calendarIds associated with this datasource
   */
  calendarIds: Array<string>;

  /**
   * The email associated with a data source
   */
  email: string;

  /**
   * The ID of an object
   */
  id: string;

  /**
   * The name of the data source
   */
  name: string;

  /**
   * The provider of the data source
   * @deprecated "use type enum instead"
   */
  provider: number;
  state: DataSourceStatus;

  /**
   * The status of the data source
   * @deprecated "use state enum instead"
   */
  status: number;

  /**
   * other calendars this data-source is syncing to, if any
   */
  syncTargets: Array<ICrawlTarget>;
  type: DataSourceProvider;

  /**
   * The username associated with a data source
   */
  username: string;
}

export enum DataSourceStatus {
  Okay = "Okay",
  RequiresReAuth = "RequiresReAuth",
}

/**
 * a calendar that another calendar's events are synced to
 */
export interface ICrawlTarget {
  __typename: "CrawlTarget";

  /**
   * The target calendarId
   */
  calendarId: string;

  /**
   * The target emailAddress
   */
  emailAddress: string;
}

export enum DataSourceProvider {
  Google = "Google",
  Office365 = "Office365",
}

/**
 * Information about pagination in a connection.
 */
export interface IPageInfo {
  __typename: "PageInfo";

  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string;

  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;

  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;

  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string;
}

/**
 * Details about a diff we are proposing to a user's calendar
 */
export type DiffDetails = IAddDiffDetails | IModifyDiffDetails;

/**
 * Details about a diff we are proposing to a user's calendar
 */
export interface IDiffDetails {
  __typename: "DiffDetails";
  actionType: DiffActionTypeEnum;
  attendees: IProposalAttendees;
  attendeesOmitted: boolean;
  description: string;
  descriptionOmitted: boolean;
  id: string;
  isFlexible: boolean;
  location: string;
  title: string;
  videoConferencingType: ConferencingType;
}

export enum ConferencingType {
  AROUND = "AROUND",
  MEET = "MEET",
  OTHER = "OTHER",
  TEAMS_FOR_BUSINESS = "TEAMS_FOR_BUSINESS",
  ZOOM = "ZOOM",
}

/**
 * A shared proposal for the user to view/edit or confirm/decline
 */
export interface ISharedProposal {
  __typename: "SharedProposal";
  hasEditPermission: boolean;
  ownerCalendarId: string;
  proposal: ISchedulingProposal;
}

/**
 * A connection to a list of items.
 */
export interface IFlowStateConnection {
  __typename: "FlowStateConnection";

  /**
   * A list of edges.
   */
  edges: Array<IFlowStateEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface IFlowStateEdge {
  __typename: "FlowStateEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: IFlowState;
}

/**
 * A state for a flow defined by a flowKey for a given user/org
 */
export interface IFlowState {
  __typename: "FlowState";

  /**
   * The current status of the flow
   */
  current: IFlowStatus;

  /**
   * The key for looking up the state
   */
  flowKey: string;

  /**
   * The ID of an object
   */
  id: string;

  /**
   * The pending/next status of the flow
   */
  pending: IFlowStatus;

  /**
   * The previous state of the flow
   */
  previousState: string;
}

/**
 * A state for a flow defined by a flowKey for a given user/org
 */
export interface IFlowStatus {
  __typename: "FlowStatus";

  /**
   * Any associated error with the current state
   */
  errorMessage: string;

  /**
   * The ID of an object
   */
  id: string;

  /**
   * A percentage of completion for the state
   */
  percentComplete: number;

  /**
   * The current state of flow
   */
  state: string;
}

/**
 * A connection to a list of items.
 */
export interface IOrgConnection {
  __typename: "OrgConnection";

  /**
   * A list of edges.
   */
  edges: Array<IOrgEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface IOrgEdge {
  __typename: "OrgEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: IOrg;
}

/**
 * A collection of users with the same email domain.
 */
export interface IOrg {
  __typename: "Org";

  /**
   * Details of a users Asana account (never actual credentials) and related settings
   */
  asanaAccountErrorable: AsanaAccountErrorable;

  /**
   * Autopilot events for the user
   */
  autopilotEventsResponse: AutopilotEventsResponseErrorable;

  /**
   * Autopilot onboarding suggestions for the user
   */
  autopilotOnboardingSuggestionsResponse: AutopilotOnboardingSuggestionsResponseErrorable;

  /**
   * a billing group for given id
   */
  billingGroupForId: BillingGroupErrorable;

  /**
   * a list of billing groups for current org
   */
  billingGroups: BillingGroupListErrorable;

  /**
   * A list of billing group ids based from the selected calendar ids
   */
  billingGroupsByCalendarIds: Array<IBillingGroupsOfCalendarIdList>;

  /**
   * Calculates smart hold events without reserving them on a user calendar
   */
  calculateSmartHoldsErrorable: SmartHoldCalculationResultErrorable;

  /**
   * User gcal notification
   */
  calendarNotification: CalendarNotification;

  /**
   * The calendar status for a given calendarId
   */
  calendarStatusErrorable: CalendarStatusErrorable;

  /**
   * Teammate time stats for a given time period
   */
  calendarTimeStats: ICalendarTimeStats;

  /**
   * Teammate time stats for a given time period and set of calendars
   */
  calendarTimeStatsList: CalendarTimeStatsListErrorable;

  /**
   * For a given user and event can an event be rescheduled
   * @deprecated "use canScheduleResponse"
   */
  canRescheduleEvent: boolean;

  /**
   * For a given user and event can an event be resheduled or put on autopilot
   */
  canScheduleResponse: CanScheduleResponseListErrorable;

  /**
   * Teammate time stats with other teammates for a given time period
   * @deprecated "In favor of new Errorable version"
   */
  collaboratorStats: ICollaboratorStats;

  /**
   * Errorable teammate time stats with other teammates for a given time period
   */
  collaboratorStatsErrorable: CollaboratorStatsErrorable;

  /**
   * Compute pain points on an event's currently scheduled slot
   */
  computeCurrentSlotImpactErrorable: CurrentScheduledSlotImpactErrorable;
  crawlTest: OrgCrawlTest;

  /**
   * Crawl status for calendarIds in this org
   */
  crawledCalendarInfo: ICrawledCalendarInfo;

  /**
   * Crawl status for given calendarIds
   */
  crawledCalendarInfoForCalendars: ICrawledCalendarInfo;
  createdTime: any;

  /**
   * A list of all `active` users in an org
   */
  currentOrgPersons: OrgPersonListErrorable;

  /**
   * The domains associated with this org
   */
  domains: Array<string>;

  /**
   * An enum for the type of the current user's data source (eg. Google, Microsoft)
   */
  ecosystem: EcosystemEnum;

  /**
   * The user's event coloring settings for the org
   */
  eventColoringSettingsErrorable: EventColoringSettingsErrorable;

  /**
   * Event with a given id on the given calendar (unlike forceFetchEventParentErrorable does not do a pull from Google for the latest info)
   */
  eventForCalendar: EventErrorable;

  /**
   * Event parents and event parent bases
   */
  eventParentBaseWrapperByDates: EventParentBaseWrapperErrorable;

  /**
   * Event parents and event parent bases by week
   * @deprecated "In favor of object version which is supported by both Apollo and Relay"
   */
  eventParentBaseWrapperByWeeks: EventParentBaseWrapperByWeekListErrorable;

  /**
   * Event parents and event parent bases by week
   */
  eventParentBaseWrapperByWeeksObject: EventParentBaseWrapperByWeekObjectListErrorable;

  /**
   * An org's collection of events from all queried calendars and dates
   */
  eventParentWrappersByDatesErrorable: EventParentWrapperConnectionErrorable;

  /**
   * An org's collection of events from all queried calendars and dates
   */
  eventParentsByDates: IEventParentConnection;

  /**
   * A list of all experiment strings enabled for this org
   */
  experiments: Array<string>;

  /**
   * Exportable list of records for the analytics per user of the org
   */
  exportedCalendarAnalytics: ExportedCalendarAnalyticsListErrorable;

  /**
   * External groups for emails
   */
  externalGroups: ExternalGroupListErrorable;
  featureGrid: FeatureGridErrorable;
  findSchedulingSuggestionsV2: Array<IConflictEventNewTimes>;

  /**
   * Flexible meetings dashboard for a user
   */
  flexibleMeetingsDashboard: IFlexibleMeetingsDashboard;

  /**
   * The collection of flow states for the user
   */
  flowStates: IFlowStateConnection;

  /**
   * The total number of hours saved across an org
   */
  focusTimeSavedInHours: number;

  /**
   * Focus Time stats for the user
   */
  focusTimeStats: FocusTimeStatsErrorable;

  /**
   * Force fetch an event from Google (does a pull from Google to ensure the event has latest info)
   */
  forceFetchEventParentErrorable: EventParentErrorable;

  /**
   * Whether user has nudges enabled on their account
   */
  hasNudgesEnabled: boolean;

  /**
   * User analytics for the home page
   */
  homeAnalytics: IHomeAnalytics;

  /**
   * User notification for home page launch
   */
  homeNotification: HomeNotification;

  /**
   * The ID of an object
   */
  id: string;

  /**
   * Fetch a Microsoft event from our database using the eventId that we get from an Outlook event. Used to fetch events from Outlook Add-in
   */
  m365Event: EventParentErrorable;

  /**
   * The meeting hours for the current org, a set of users (via userIds), or any set of non-users (via calendarIds)
   */
  meetingHoursQueryResult: IMeetingHoursQueryResult;

  /**
   * Finds any long meeting blocks for meeting relief nudge notification
   */
  meetingReliefSuggestion: MeetingReliefSuggestion;
  myBillingGroups: IMyBillingGroups;

  /**
   * The name of the org
   */
  name: string;

  /**
   * The next defrag time for the current org
   */
  nextDefragTime: DefragTimeErrorable;

  /**
   * Onboarding checklist for a user
   */
  onboardingChecklist: IOnboardingChecklist;

  /**
   * List of a user's one on ones
   */
  oneOnOnesForCalendar: EventListErrorable;

  /**
   * Optimistic savings for a user, used to determine how much time can be saved in a week
   */
  optimisticSavingsErrorable: OptimisticSavingsErrorable;

  /**
   * Optimistic savings for current user for next week
   */
  optimisticSavingsForCurrentUserNextWeekErrorable: OptimisticSavingsErrorable;
  optimizationNotification: OptimizationNotification;
  orgAccountingPeriod: OrgAccountingPeriodErrorable;

  /**
   * The collection of flow states for the org
   */
  orgFlowStates: FlowStateListErrorable;

  /**
   * The default meeting hours for the current org
   */
  orgMeetingHours: IOrgMeetingHours;

  /**
   * Overview of organization-wide Clockwise usage
   */
  orgOverview: OrgOverviewErrorable;
  orgPersonForUserErrorable: OrgPersonErrorable;

  /**
   * Object defining a list of org persons and how to paginate the list
   */
  orgPersonListPaginatedErrorable: OrgPersonListPaginatedErrorable;

  /**
   * Look up org persons by calendar id
   */
  orgPersonsByCalendarIdsErrorable: OrgPersonListErrorable;

  /**
   * Pending org invites outstanding
   */
  pendingInvites: IOrgInviteConnection;

  /**
   * A collection of teammates, suggestions, invitees and you
   */
  personCorpusErrorable: PersonListErrorable;
  personForUserErrorable: PersonErrorable;

  /**
   * Look up persons by calendar id
   * @deprecated "use orgPersonsByCalendarIdsErrorable"
   */
  personsByCalendarIdsErrorable: PersonListErrorable;

  /**
   * Look up persons by user id
   */
  personsByUserIdsErrorable: PersonListErrorable;

  /**
   * The users primary calendar in this org
   */
  primaryOrgCalendar: string;

  /**
   * The users email thats part of this org
   */
  primaryOrgEmail: string;

  /**
   * Primary team for user
   */
  primaryTeam: TeamErrorable;

  /**
   * A list of profile time zones
   */
  profileTimeZonesErrorable: ProfileTimeZoneListErrorable;

  /**
   * List of public Slack channels for the slack workspace for a given Clockwise team
   */
  publicChannelsForSlackForTeam: SlackChannelListErrorable;

  /**
   * External rooms for the given ids
   */
  rooms: RoomListErrorable;

  /**
   * The collection of settings for this user in this org
   */
  settings: ISettingConnection;

  /**
   * The user's shadow calendar settings for the org
   */
  shadowCalendarSettingsErrorable: ShadowCalendarSettingsErrorable;

  /**
   * Slack Settings for a given team
   */
  slackAccountForTeamErrorable: SlackAccountErrorable;

  /**
   * The user's list of Slack Accounts
   */
  slackAccountsErrorable: SlackAccountListErrorable;

  /**
   * The user's list of Slack Account Settings
   */
  slackSettingsErrorable: SlackSettingsListErrorable;
  smartHoldEventInfo: SmartHoldEventInfoErrorable;

  /**
   * Goals/Preferences for smart hold
   */
  smartHoldGoals: ISmartHoldGoals;

  /**
   * A link, which expires in 5 minutes, that can be used to configure sso for a billing group.
   */
  ssoAdminPortalLink: SsoAdminPortalLinkErrorable;
  suggestedFlexibleMeetings: ISuggestedFlexibleMeetings;

  /**
   * Suggested invites to make for this org
   */
  suggestedInvites: ISuggestedOrgInviteConnection;

  /**
   * The suggested and selected room preferences
   */
  suggestedRoomPreferences: SuggestedRoomPreferencesErrorable;

  /**
   * A list of all suggested teams for user
   */
  suggestedTeams: SuggestedTeamListErrorable;

  /**
   * Working preferences for a teammate
   */
  summarizedWorkingPreferences: ISummarizedWorkingPreferences;

  /**
   * Event with a given id on the given calendar
   */
  syncedOriginEvent: EventErrorable;

  /**
   * Fetches (most recent) tailored defrag summary
   */
  tailoredDefragCardSummary: TailoredDefragCardSummaryErrorable;

  /**
   * Calendar statuses for everyone on the team
   */
  teamCalendarStatusErrorable: CalendarStatusListErrorable;

  /**
   * A team for org from given teamId
   */
  teamForTeamId: TeamErrorable;

  /**
   * Team health score for calendars for a given time period
   */
  teamHealthScoreForCalendarsErrorable: TeamHealthScoreErrorable;

  /**
   * Team health scorefor team for a given time period
   */
  teamHealthScoreForTeamErrorable: TeamHealthScoreErrorable;

  /**
   * Team health score for team for a given time period
   */
  teamHealthScoreForTeamWithSubTeamsErrorable: TeamHealthScoreWithSubTeamsErrorable;

  /**
   * Team health score just for user for a given time period
   */
  teamHealthScoreJustForUserErrorable: TeamHealthScoreErrorable;

  /**
   * Overall settings for a team
   */
  teamSettings: TeamSettingsErrorable;

  /**
   * User's settings for a team
   */
  teamUserSettings: TeamUserSettingsErrorable;

  /**
   * A list of all teams for org
   */
  teamsForOrg: TeamListErrorable;

  /**
   * A nudge to help the user improve their calendar. Only returns unread nudges.
   */
  unviewedNudges: LifeCycleNudge;

  /**
   * The user's team
   */
  userDefinedTeam: IUserDefinedTeam;

  /**
   * The users's goals
   */
  userGoals: IGoalConnection;

  /**
   * The meeting hours for the current user
   */
  userMeetingHours: IUserMeetingHours;

  /**
   * The current user's profile
   */
  userPerson: PersonErrorable;

  /**
   * The user responses from the onboarding survey
   */
  userSurveyResponseErrorable: UserSurveyResponseListErrorable;

  /**
   * The user responses wrapped by ID for the onboarding survey
   */
  userSurveyResponseWrapperErrorable: UserSurveyResponseWrapperErrorable;

  /**
   * A list of all teams for user
   */
  userTeams: TeamListErrorable;

  /**
   * The working hours for the current user
   */
  userWorkingHours: IUserWorkingHours;

  /**
   * A user's working locations given an id
   */
  userWorkingLocationForId: IWorkingLocation;

  /**
   * A list of this users working locations
   */
  userWorkingLocations: WorkingLocationListErrorable;

  /**
   * The meeting hours for the multiple users
   */
  usersMeetingHours: Array<IUserMeetingHours>;

  /**
   * The working hours for the multiple users
   */
  usersWorkingHours: Array<IUserWorkingHours>;

  /**
   * The user work address for this org
   */
  workAddressForUser: AddressErrorable;

  /**
   * Working groups for this user for this org
   */
  workingGroups: IWorkingGroupConnection;

  /**
   * The working hours for any given set of users
   */
  workingHoursQueryResult: IWorkingHoursQueryResult;

  /**
   * User's weekly schedule settings for working location
   */
  workingLocationWeeklySchedule: WorkingLocationWeeklyScheduleErrorable;

  /**
   * Connected account-level zoom account for org
   */
  zoomAccount: ZoomAccountErrorable;

  /**
   * Connected user-level zoom account for org
   */
  zoomUserLevelAccount: ZoomAccountErrorable;
}

export interface IAutopilotEventsResponseOnOrgArguments {
  timeZone: string;
}

export interface IBillingGroupForIdOnOrgArguments {
  billingGroupId: string;
}

export interface IBillingGroupsByCalendarIdsOnOrgArguments {
  calendarIds: Array<string>;
}

export interface ICalculateSmartHoldsErrorableOnOrgArguments {
  week: any;
}

export interface ICalendarStatusErrorableOnOrgArguments {
  calendarId: string;
  timeZone: string;
}

export interface ICalendarTimeStatsOnOrgArguments {
  calendarId: string;

  /**
   * The maximum time slot of the time range
   */
  rangeMax: IDayTimeSlotInput;

  /**
   * The minimum time slot of the time range
   */
  rangeMin: IDayTimeSlotInput;
  timeZone: string;
}

export interface ICalendarTimeStatsListOnOrgArguments {
  calendarIds: Array<string>;
  dates: Array<any>;
  timeZone: string;
}

export interface ICanRescheduleEventOnOrgArguments {
  calendarIds?: Array<string>;
  eventParentRelayId?: string;
}

export interface ICanScheduleResponseOnOrgArguments {
  calendarIds?: Array<string>;
  externalEventIds: Array<string>;
}

export interface ICollaboratorStatsOnOrgArguments {
  calendarId: string;

  /**
   * The maximum time slot of the time range
   */
  rangeMax: IDayTimeSlotInput;

  /**
   * The minimum time slot of the time range
   */
  rangeMin: IDayTimeSlotInput;
  timeZone: string;
}

export interface ICollaboratorStatsErrorableOnOrgArguments {
  calendarId: string;

  /**
   * The maximum time slot of the time range
   */
  rangeMax: IDayTimeSlotInput;

  /**
   * The minimum time slot of the time range
   */
  rangeMin: IDayTimeSlotInput;
  timeZone: string;
}

export interface IComputeCurrentSlotImpactErrorableOnOrgArguments {
  /**
   * The external event id
   */
  externalEventId: string;
}

export interface ICrawledCalendarInfoForCalendarsOnOrgArguments {
  calendarIds: Array<string>;
}

export interface IEventForCalendarOnOrgArguments {
  calendarId: string;
  externalEventId: string;
}

export interface IEventParentBaseWrapperByDatesOnOrgArguments {
  calendarIds: Array<string>;
  dates: Array<any>;
  includeAllDay?: boolean;
  timeZone: string;
}

export interface IEventParentBaseWrapperByWeeksOnOrgArguments {
  calendarIds: Array<string>;
  includeAllDay?: boolean;
  timeZone: string;
  weekStartDates: Array<any>;
}

export interface IEventParentBaseWrapperByWeeksObjectOnOrgArguments {
  calendarIds: Array<string>;
  includeAllDay?: boolean;
  timeZone: string;
  weekStartDates: Array<any>;
}

export interface IEventParentWrappersByDatesErrorableOnOrgArguments {
  after?: string;
  before?: string;
  calendarIds: Array<string>;
  dates: Array<any>;
  first?: number;
  includeAllDay?: boolean;
  last?: number;
  timeZone: string;
}

export interface IEventParentsByDatesOnOrgArguments {
  after?: string;
  before?: string;
  calendarIds: Array<string>;
  dates: Array<any>;
  first?: number;
  last?: number;
  timeZone: string;
}

export interface IExternalGroupsOnOrgArguments {
  emails: Array<string>;
}

export interface IFindSchedulingSuggestionsV2OnOrgArguments {
  /**
   * The is of the conflict cluster
   */
  clusterId: string;

  /**
   * The external event id
   */
  externalEventId: string;
}

export interface IFlowStatesOnOrgArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

export interface IForceFetchEventParentErrorableOnOrgArguments {
  calendarId: string;
  externalEventId: string;
}

export interface IM365EventOnOrgArguments {
  calendarId: string;
  m365EventId: string;
}

export interface IMeetingHoursQueryResultOnOrgArguments {
  nonUserCalendarIds?: Array<string>;

  /**
   * @default true
   */
  resolveOverrides?: boolean;
  userIds?: Array<string>;
}

export interface IOptimisticSavingsErrorableOnOrgArguments {
  calendarId: string;

  /**
   * The LocalDate representation of the start of a week (Sunday)
   */
  week: any;
}

export interface IOrgFlowStatesOnOrgArguments {
  flowKeys: Array<string>;
}

export interface IOrgOverviewOnOrgArguments {
  maxDate?: string;
  minDate?: string;
}

export interface IOrgPersonListPaginatedErrorableOnOrgArguments {
  activityFilter?: ActivityFilter;
  billingGroupId?: string;
  excludeNonUsers?: boolean;
  fetchNext?: boolean;
  freePersonsOnly?: boolean;
  limit?: number;

  /**
   * pageToken as first personId in the current page along with fetchNext false fetches previous page, pageToken as last personId in the current page along with fetchNext true fetches next page.
   */
  pageToken?: string;
  primaryBillingGroupOnly?: boolean;
  searchQuery?: string;
  sortDirection: SortDirection;
  sortType: SortType;
  teamId?: string;
}

export interface IOrgPersonsByCalendarIdsErrorableOnOrgArguments {
  calendarIds: Array<string>;
}

export interface IPendingInvitesOnOrgArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

export interface IPersonsByCalendarIdsErrorableOnOrgArguments {
  calendarIds: Array<string>;

  /**
   * @default 50
   */
  limit?: number;
}

export interface IPersonsByUserIdsErrorableOnOrgArguments {
  /**
   * @default 50
   */
  limit?: number;
  userIds: Array<string>;
}

export interface IProfileTimeZonesErrorableOnOrgArguments {
  calendarIds: Array<string>;
}

export interface IPublicChannelsForSlackForTeamOnOrgArguments {
  teamId: string;
}

export interface IRoomsOnOrgArguments {
  roomIds: Array<string>;
}

export interface ISettingsOnOrgArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

export interface ISlackAccountForTeamErrorableOnOrgArguments {
  teamId: string;
}

export interface ISmartHoldEventInfoOnOrgArguments {
  externalEventId: string;
}

export interface ISsoAdminPortalLinkOnOrgArguments {
  /**
   * BillingGroup id to configure
   */
  bgId: string;

  /**
   * One of Dsync (default, used for billing) or Sso (used for an extra login screen, requires Dsync to work)
   */
  intent?: SsoIntent;
}

export interface ISuggestedFlexibleMeetingsOnOrgArguments {
  /**
   * @default {"inRange":null,"types":["OneOnOne"]}
   */
  filter: ISuggestedFlexibleMeetingFilter;

  /**
   * @default 5
   */
  limit: number;
}

export interface ISuggestedInvitesOnOrgArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

export interface ISummarizedWorkingPreferencesOnOrgArguments {
  calendarId: string;
}

export interface ISyncedOriginEventOnOrgArguments {
  externalEventId: string;
  startTime: IEventTimeInput;
}

export interface ITailoredDefragCardSummaryOnOrgArguments {
  newerThan?: any;
}

export interface ITeamCalendarStatusErrorableOnOrgArguments {
  timeZone: string;
}

export interface ITeamForTeamIdOnOrgArguments {
  teamId: string;
}

export interface ITeamHealthScoreForCalendarsErrorableOnOrgArguments {
  calendarIds: Array<string>;

  /**
   * The maximum time slot of the time range
   */
  rangeMax: IDayTimeSlotInput;

  /**
   * The minimum time slot of the time range
   */
  rangeMin: IDayTimeSlotInput;
  timeZone: string;
}

export interface ITeamHealthScoreForTeamErrorableOnOrgArguments {
  /**
   * The maximum time slot of the time range
   */
  rangeMax: IDayTimeSlotInput;

  /**
   * The minimum time slot of the time range
   */
  rangeMin: IDayTimeSlotInput;
  teamId: string;
  timeZone: string;
}

export interface ITeamHealthScoreForTeamWithSubTeamsErrorableOnOrgArguments {
  /**
   * The maximum time slot of the time range
   */
  rangeMax: IDayTimeSlotInput;

  /**
   * The minimum time slot of the time range
   */
  rangeMin: IDayTimeSlotInput;
  teamId: string;
  timeZone: string;
}

export interface ITeamHealthScoreJustForUserErrorableOnOrgArguments {
  /**
   * The maximum time slot of the time range
   */
  rangeMax: IDayTimeSlotInput;

  /**
   * The minimum time slot of the time range
   */
  rangeMin: IDayTimeSlotInput;
  timeZone: string;
}

export interface ITeamSettingsOnOrgArguments {
  teamId: string;
}

export interface ITeamUserSettingsOnOrgArguments {
  teamId: string;
}

export interface IUserGoalsOnOrgArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

export interface IUserMeetingHoursOnOrgArguments {
  /**
   * @default true
   */
  resolveOverrides?: boolean;
}

export interface IUserSurveyResponseErrorableOnOrgArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

export interface IUserSurveyResponseWrapperErrorableOnOrgArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

export interface IUserWorkingHoursOnOrgArguments {
  /**
   * @default true
   */
  resolveOverrides?: boolean;
}

export interface IUserWorkingLocationForIdOnOrgArguments {
  workingLocationId: string;
}

export interface IUsersMeetingHoursOnOrgArguments {
  calendarIds?: Array<any>;

  /**
   * @default 50
   */
  limit?: number;

  /**
   * @default true
   */
  resolveOverrides?: boolean;
}

export interface IUsersWorkingHoursOnOrgArguments {
  calendarIds?: Array<any>;

  /**
   * @default 50
   */
  limit?: number;

  /**
   * @default false
   */
  resolveOverrides?: boolean;

  /**
   * @default 0
   */
  weekOffset?: number;
}

export interface IWorkingGroupsOnOrgArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

export interface IWorkingHoursQueryResultOnOrgArguments {
  nonUserCalendarIds?: Array<string>;

  /**
   * @default true
   */
  resolveOverrides?: boolean;
  userIds?: Array<string>;
}

export type AsanaAccountErrorable = IAsanaAccount | IGraphEntityError;

/**
 * Asana Account Details
 */
export interface IAsanaAccount {
  __typename: "AsanaAccount";

  /**
   * boolean describing if the user has Asana oauth credentials stored.
   */
  hasAsanaCredentials: boolean;

  /**
   * The ID of an object
   */
  id: string;
}

export type AutopilotEventsResponseErrorable = IAutopilotEventsResponse | IGraphEntityError;

/**
 * A list of autopilot events that are enabled or suggested
 */
export interface IAutopilotEventsResponse {
  __typename: "AutopilotEventsResponse";
  enabledAttending: Array<IEvent>;
  enabledOrganized: Array<IEvent>;
  suggestedAttending: Array<IEvent>;
  suggestedOrganized: Array<IEvent>;
}

/**
 * An event on a calendar
 */
export interface IEvent {
  __typename: "Event";

  /**
   * The annotations for this event
   */
  annotatedEvent: IAnnotatedEvent;

  /**
   * The annotations for the recurring version of this event
   * @deprecated "Use annotatedEvent"
   */
  annotatedRecurringEvent: IAnnotatedEvent;
  attendeePeople: PersonListErrorable;

  /**
   * The attendees that are associated with this event
   */
  attendees: Array<IAttendee>;

  /**
   * In some cases when the event has many attendees, this flag will be set and the set of attendees will be filtered to just the queried calendar
   */
  attendeesOmitted: boolean;

  /**
   * For autopilot candidates, the status of the current autopilot
   */
  autopilotEventStatus: AutopilotEventStatusErrorable;

  /**
   * A list of calendar ids that the event is associated with
   */
  calendarIds: Array<string>;

  /**
   * When the event was created, in millis
   */
  createdMillis: any;

  /**
   * Attendee that created the event
   */
  creator: IAttendee;

  /**
   * A description of the event
   */
  description: string;

  /**
   * Indicates the description has been omitted
   */
  descriptionOmitted: boolean;

  /**
   * EventTime object representing the end time of the event
   */
  endTime: IEventTime;

  /**
   * Not sure what this is...
   */
  etag: string;

  /**
   * A calendar event's autopilot history
   */
  eventAutopilotHistory: EventAutopilotHistoryErrorable;

  /**
   * Contains all flex permutations of the event flexibility string
   * @deprecated "Use EventFlexibility.description instead"
   */
  eventFlexibilityErrorable: EventFlexibilityNewListErrorable;

  /**
   * The unique event key for this event
   */
  eventKey: IEventKey;
  eventParentBaseId: string;
  eventParentBaseTimestamp: any;

  /**
   * The id of the event parent that contains this event as a version
   */
  eventParentRelayId: string;

  /**
   * Edit permissions for the event and current user
   */
  eventPermissions: EventPermissions;

  /**
   * The feature grid feature states for the organizer of this event
   */
  featureStatesForOrganizer: FeatureStatesErrorable;

  /**
   * Indicates that attendees are allowed to invite others
   */
  guestsCanInviteOthers: boolean;

  /**
   * Indicates that attendees can modify the event
   */
  guestsCanModify: boolean;

  /**
   * Indicates that attendees can see other attendees
   */
  guestsCanSeeOtherGuests: boolean;

  /**
   * A Google Hangout link for the event
   */
  hangoutLink: string;

  /**
   * A link associated with the event
   */
  htmlLink: string;
  id: string;

  /**
   * Indicates that this is an all-day event
   */
  isAllDay: boolean;

  /**
   * Represents when an event is in the canceled state (e.g. the organizer deleted it, so its canceled on your calendar)
   */
  isCancelled: boolean;

  /**
   * Is this a Clockwise generated event (eg. Smart Hold)
   */
  isClockwiseEvent: boolean;

  /**
   * Is this a Hold event with no attendees
   */
  isHold: boolean;

  /**
   * The location of the event
   */
  location: string;

  /**
   * Indicates that this event is locked
   */
  locked: boolean;
  moveRangeInLocalTimeV3: MoveRangeInLocalTimeV3Errorable;

  /**
   * The attendee who organized the event
   */
  organizer: IAttendee;

  /**
   * Additionally resolve the OrgPerson for the organizer
   */
  organizerOrgPerson: OrgPersonErrorable;

  /**
   * Additionally resolve the OrgPerson for the organizer
   * @deprecated "Replacing with Organizer Org Person"
   */
  organizerPerson: PersonErrorable;

  /**
   * Whether or not this event is paused
   */
  paused: boolean;

  /**
   * Indicates that this is a private copy...?
   */
  privateCopy: boolean;

  /**
   * The recurrence for the event, more formatted for editing and display
   * @deprecated "Use recurrenceRule instead"
   */
  recurrence: IEventRecurrence;

  /**
   * The recurrence rule for the event
   */
  recurrenceRule: any;

  /**
   * The recurrence rules for the event
   * @deprecated "Use recurrenceRule instead"
   */
  recurrenceRules: IRecurrenceRules;

  /**
   * The recurring id for the event, if it recurs.
   */
  recurringEventId: string;
  scrubbedEvent: boolean;

  /**
   * EventTime object representing the start time of the event
   */
  startTime: IEventTime;
  suggestedAutopilotSettingsResponseV3: SuggestedAutopilotSettingsResponseV3Errorable;

  /**
   * Description of the event time & recurrence, ignoring flexibility. Null if unable to compute
   */
  timeDescription: string;

  /**
   * The name of the event
   */
  title: string;

  /**
   * Can this time be scheduled over? (None = 0; Opaque = 1; Transparent = 2)
   */
  transparency: TransparencyEnum;

  /**
   * When the event was updated, in millis
   */
  updatedMillis: any;

  /**
   * The conferencing link for the event
   */
  videoLink?: Maybe<IVideoLink> | undefined;
}

export interface IAutopilotEventStatusOnEventArguments {
  calendarId?: string;
}

/**
 * An event annotated with user and org tags
 */
export interface IAnnotatedEvent {
  __typename: "AnnotatedEvent";
  externalEventId: string;

  /**
   * The ID of an object
   */
  id: string;

  /**
   * The org tags for this event
   */
  orgTags: Array<IOrgTag>;

  /**
   * The user tags for this event
   */
  userTags: Array<IUserTag>;
}

/**
 * A per org tag on an event
 */
export interface IOrgTag {
  __typename: "OrgTag";

  /**
   * The TagState for this tag
   */
  state: ITagState;

  /**
   * The tag name
   */
  tag: string;
}

/**
 * The state of a tag on an event
 */
export interface ITagState {
  __typename: "TagState";

  /**
   * Whether a tag is active or deleted
   */
  active: boolean;

  /**
   * Timestamp in millis of last change
   */
  lastModified: number;

  /**
   * Type of attendee urn
   */
  subjectType: SubjectUrnTypeEnum;

  /**
   * Attendee's urn (note this relates to urnType)
   */
  subjectValue: string;

  /**
   * If a tag has an associated value, it goes here
   */
  value: string;
}

export enum SubjectUrnTypeEnum {
  Email = "Email",
  Integration = "Integration",
  Org = "Org",
  Person = "Person",
  Phone = "Phone",
  Services = "Services",
  Team = "Team",
  User = "User",
}

/**
 * A per user tag on an event
 */
export interface IUserTag {
  __typename: "UserTag";

  /**
   * The aggregate count for this tag
   */
  count: number;

  /**
   * The states of this user tag
   */
  states: Array<ITagState>;

  /**
   * The tag name
   */
  tag: string;
}

/**
 * A person or room attached to a meeting
 */
export interface IAttendee {
  __typename: "Attendee";

  /**
   * What to call the attendee
   */
  displayName: string;
  id: string;

  /**
   * Whether the attendee has been marked as optional for the meeting
   */
  optional: boolean;

  /**
   * Whether the attendee is the organizer of the meeting
   */
  organizer: boolean;

  /**
   * The ID of the calendar user's profile
   */
  profileId: string;

  /**
   * Indicates that the attendee is a resource
   */
  resource: boolean;

  /**
   * Invite response status
   */
  responseStatus: ResponseStatusEnum;

  /**
   * Type of attendee urn
   */
  urnType: UrnTypeEnum;

  /**
   * Attendee's urn (note this relates to urnType)
   */
  urnValue: string;
}

export enum UrnTypeEnum {
  Email = "Email",
  Integration = "Integration",
  Org = "Org",
  Phone = "Phone",
  User = "User",
}

export type AutopilotEventStatusErrorable = IAutopilotEventStatus | IGraphEntityError;

/**
 * the current status of an autopilot event candidate
 */
export interface IAutopilotEventStatus {
  __typename: "AutopilotEventStatus";

  /**
   * the reason why autopilot cannot move this event
   */
  status: AutopilotEventStatusEnum;
}

export enum AutopilotEventStatusEnum {
  CanMove = "CanMove",
  EventExceedsDefragRange = "EventExceedsDefragRange",
  EventOutsideTimeRange = "EventOutsideTimeRange",
  External = "External",
  NoDuration = "NoDuration",
  OverQuota = "OverQuota",
  Paused = "Paused",
  Pinned = "Pinned",
  PrivateEvent = "PrivateEvent",
  SmartHold = "SmartHold",
  TrialExpired = "TrialExpired",
}

/**
 * A rich description of a time
 */
export interface IEventTime {
  __typename: "EventTime";

  /**
   * String representation of the date or datetime
   */
  dateOrDateTime: string;

  /**
   * Time represented in milliseconds (DateTime is )
   */
  millis: number;

  /**
   * Timezone offset in minutes
   */
  offsetMinutes: number;

  /**
   * Standard IANA timezone string
   */
  timeZone: string;

  /**
   * The type of date
   */
  type: EventTimeType;
}

export enum EventTimeType {
  Date = "Date",
  DateTime = "DateTime",
}

export type EventAutopilotHistoryErrorable = IEventAutopilotHistory | IGraphEntityError;

/**
 * An event's AP history
 */
export interface IEventAutopilotHistory {
  __typename: "EventAutopilotHistory";

  /**
   * AP history logs including next defrag run info
   */
  actions: Array<IAutopilotHistoryActionLog>;

  /**
   * AP history status: On/Off/Incomplete/Not Visible
   */
  status: StatusEnum;
}

/**
 * A single action performed on an AP event
 */
export interface IAutopilotHistoryActionLog {
  __typename: "AutopilotHistoryActionLog";
  actionInfo: Array<string>;
  actionString: string;
  actionType: ActionTypeEnum;
  timestamp: any;
}

export enum ActionTypeEnum {
  AutopilotTurnedOff = "AutopilotTurnedOff",
  AutopilotTurnedOn = "AutopilotTurnedOn",
  ClockwiseCouldNotResolveConflict = "ClockwiseCouldNotResolveConflict",
  ClockwiseMeetingMove = "ClockwiseMeetingMove",
  ClockwiseMovePaused = "ClockwiseMovePaused",
  ClockwiseMoveUnpaused = "ClockwiseMoveUnpaused",
  EventCreated = "EventCreated",
  EventRescheduled = "EventRescheduled",
  NextAutopilotRun = "NextAutopilotRun",
}

export enum StatusEnum {
  AutopilotHistoryIncomplete = "AutopilotHistoryIncomplete",
  AutopilotHistoryNotVisible = "AutopilotHistoryNotVisible",
  AutopilotHistoryOff = "AutopilotHistoryOff",
  AutopilotHistoryOn = "AutopilotHistoryOn",
}

export type EventFlexibilityNewListErrorable = IEventFlexibilityNewList | IGraphEntityError;

export interface IEventFlexibilityNewList {
  __typename: "EventFlexibilityNewList";
  list: Array<IEventFlexibilityNew>;
}

/**
 * An event flex and corresponding flex string
 */
export interface IEventFlexibilityNew {
  __typename: "EventFlexibilityNew";
  flex: string;
  flexString: string;
}

/**
 * A query object for fetching events
 */
export interface IEventKey {
  __typename: "EventKey";

  /**
   * The unique event key for an event
   */
  calendarIds: Array<string>;

  /**
   * The non unique event id
   */
  externalEventId: string;

  /**
   * A hash of all the calendarIds deduped and sorted
   */
  hashedCalendars: string;

  /**
   * The ID of an object
   */
  id: string;
  orgId: string;
}

export enum EventPermissions {
  ALL = "ALL",
  INVITE = "INVITE",
  ISATTENDEE = "ISATTENDEE",
  MODIFY = "MODIFY",
  NONE = "NONE",
}

export type FeatureStatesErrorable = IFeatureStates | IGraphEntityError;

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface IFeatureStates {
  __typename: "FeatureStates";
  freePaidFeatures: IFreePaidFeatures;
  meetingReliefSettingVisibility: MeetingReliefSettingVisibility;
  mixedFreemiumOrgUIState: IMixedFreemiumOrgUiState;
  planTierFeatures: IPlanTierFeatures;

  /**
   * @deprecated "use the entity within mixedFreemiumOrgUIState"
   */
  quotaAutopilotStatusCallout: QuotaAutopilotStatusCalloutEnumType;

  /**
   * @deprecated "use the entity within mixedFreemiumOrgUIState"
   */
  quotaStatusBanner: QuotaStatusBannerEnumType;
}

/**
 * Availability of features based on user's monetization state
 */
export interface IFreePaidFeatures {
  __typename: "FreePaidFeatures";
  autopilotWhenOrganizerCurrentlyAllowed: boolean;
  eligibleForNoMeetingDay: boolean;
  eligibleForTeamCalendarVisibility: boolean;
  focusTimeAllowed: boolean;
  moveRangesEnabled: boolean;
}

export enum MeetingReliefSettingVisibility {
  DisabledByPaywall = "DisabledByPaywall",
  HideSetting = "HideSetting",
  ShowWithBadge = "ShowWithBadge",
  ShowWithoutBadge = "ShowWithoutBadge",
}

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface IMixedFreemiumOrgUiState {
  __typename: "MixedFreemiumOrgUiState";
  autopilotPaused: boolean;
  moveRangesEnabled: boolean;
  paymentStatusBadge: IPaymentStatusBadge;
  quotaStatusBanner: QuotaStatusBannerEnum;
}

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface IPaymentStatusBadge {
  __typename: "PaymentStatusBadge";
  text: PaymentStatusBadgeTextEnum;
  type: PaymentStatusBadgeTypeEnum;
}

export enum PaymentStatusBadgeTextEnum {
  Business = "Business",
  Enterprise = "Enterprise",
  Free = "Free",
  Pro = "Pro",
  Trial = "Trial",
}

export enum PaymentStatusBadgeTypeEnum {
  Blue = "Blue",
  Gradient = "Gradient",
}

export enum QuotaStatusBannerEnum {
  ApproachingLimit = "ApproachingLimit",
  NoEnforcement = "NoEnforcement",
  ReachedLimit = "ReachedLimit",
}

/**
 * Plan tier information
 */
export interface IPlanTierFeatures {
  __typename: "PlanTierFeatures";
  paymentStatusBadge: IPaymentStatusBadge;
}

export enum QuotaAutopilotStatusCalloutEnumType {
  ApproachingLimit = "ApproachingLimit",
  NoEnforcement = "NoEnforcement",
  ReachedLimit = "ReachedLimit",
}

export enum QuotaStatusBannerEnumType {
  ApproachingLimit = "ApproachingLimit",
  NoEnforcement = "NoEnforcement",
  ReachedLimit = "ReachedLimit",
}

export type MoveRangeInLocalTimeV3Errorable = IGraphEntityError | IMoveRangeInLocalTimeV3;

/**
 * A move range specified by two day offset time slots
 */
export interface IMoveRangeInLocalTimeV3 {
  __typename: "MoveRangeInLocalTimeV3";
  allowedEndTimeInclusive: IDayOffsetTimeSlot;
  allowedStartTimeInclusive: IDayOffsetTimeSlot;
}

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface IDayOffsetTimeSlot {
  __typename: "DayOffsetTimeSlot";
  dayOffset: number;
  timeSlot: TimeSlot;
}

/**
 * A time slot
 */

export enum TimeSlot {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
}

export type OrgPersonErrorable = IGraphEntityError | IOrgPerson;

export type PersonErrorable = IGraphEntityError | IPerson;

/**
 * Full set of rules for how an event recurs
 */
export interface IRecurrenceRules {
  __typename: "RecurrenceRules";

  /**
   * Instances that have been removed from this set of recurrence rules
   */
  cancelledInstances: Array<string>;

  /**
   * The start time of the recurrence rules
   */
  firstInstanceStartTime: IEventTime;

  /**
   * The interval definition for the recurrence
   */
  interval: IIntervalDefinition;

  /**
   * The timezone of the recurrence rules
   */
  timeZone: string;
}

/**
 * The definition of the interval on which a recurrence recurs
 */
export interface IIntervalDefinition {
  __typename: "IntervalDefinition";

  /**
   * Which days the recurrence falls on — e.g., “MO,TU,FR”
   */
  byDay: string;

  /**
   * Defines the poisition in the given frequence set that recurs; used for things like the first Monday of every month
   */
  bySetPos: Array<number>;

  /**
   * How many times it recurs (null here AND for untilTime means it recurs forever)
   */
  count: number;

  /**
   * The frequency type of the interval
   */
  freqType: IntervalDefinitionFreqTypeEnum;

  /**
   * The pacing of the interval — e.g., a bi-weekly interval is 2
   */
  interval: number;

  /**
   * When the recurrence terminates (null here AND for count means it recurs forever)
   */
  untilTime: IEventTime;
}

export enum IntervalDefinitionFreqTypeEnum {
  Daily = "Daily",
  Monthly = "Monthly",
  Weekly = "Weekly",
  Yearly = "Yearly",
}

export type SuggestedAutopilotSettingsResponseV3Errorable =
  | IGraphEntityError
  | ISuggestedAutopilotSettingsResponseV3;

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface ISuggestedAutopilotSettingsResponseV3 {
  __typename: "SuggestedAutopilotSettingsResponseV3";
  calendarIdToMeetingHours: Array<ISuggestedAutotpilotSettingsResponseV3CalendarIdMeetingHours>;
  id: string;
  intersectionOfAttendeesMeetingHours: IDayOffsetTimeSlotRange;
  suggestedAutopilotSettings: ISuggestedAutopilotSettingsV3;
}

export interface ISuggestedAutotpilotSettingsResponseV3CalendarIdMeetingHours {
  __typename: "SuggestedAutotpilotSettingsResponseV3CalendarIdMeetingHours";
  calendarId: string;
  dayOffsetTimeSlotRanges: Array<IDayOffsetTimeSlotRange>;
}

/**
 * A range between two day offset time slots
 */
export interface IDayOffsetTimeSlotRange {
  __typename: "DayOffsetTimeSlotRange";
  maxSlot: IDayOffsetTimeSlot;
  minSlot: IDayOffsetTimeSlot;
}

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface ISuggestedAutopilotSettingsV3 {
  __typename: "SuggestedAutopilotSettingsV3";
  allowedEndTime: IDayOffsetTimeSlot;
  allowedStartTime: IDayOffsetTimeSlot;
  isRoomFlexible: boolean;
  movementType: SuggestedAutopilotSettingsV3MovementType;
}

export enum SuggestedAutopilotSettingsV3MovementType {
  Day = "Day",
  Week = "Week",
}

export enum TransparencyEnum {
  None = "None",
  Opaque = "Opaque",
  Transparent = "Transparent",
}

/**
 * A video conference link for the event, found in the description, the location, or the conferenceData.
 */
export interface IVideoLink {
  __typename: "VideoLink";
  type: ConferencingType;
  uri: string;
}

export type AutopilotOnboardingSuggestionsResponseErrorable =
  | IAutopilotOnboardingSuggestionsResponse
  | IGraphEntityError;

/**
 * Represents a set of suggestion events to put on autopilot
 */
export interface IAutopilotOnboardingSuggestionsResponse {
  __typename: "AutopilotOnboardingSuggestionsResponse";
  flexible: Array<IEvent>;
  notFlexible: Array<IEvent>;
  veryFlexible: Array<IEvent>;
}

export type BillingGroupErrorable = IBillingGroup | IGraphEntityError;

/**
 * Represents a billing group
 */
export interface IBillingGroup {
  __typename: "BillingGroup";
  adminOrgPersons: OrgPersonListErrorable;
  createdAtMillis: any;
  dsyncConfigured: DsyncConfiguredErrorable;
  id: string;
  invitePolicy: InvitePolicyEnum;
  isAutoSyncEnabled: boolean;
  manualMembers: BillingGroupManualMemberListErrorable;
  name: string;
  personListPaginatedErrorable: OrgPersonListPaginatedErrorable;

  /**
   * A link, which expires in 5 minutes, that can be used to configure sso for a billing group.
   */
  ssoAdminPortalLink: SsoAdminPortalLinkErrorable;
  stripeCustomerPortalSession: StripeCustomerPortalSessionErrorable;
  subscription: SubscriptionDetailsErrorable;
  syncSources: Array<IUrn>;
  type: BillingGroupTypeEnum;
  upcomingBillingDetails: UpcomingBillingDetailsErrorable;
}

export interface IPersonListPaginatedErrorableOnBillingGroupArguments {
  limit?: number;
  searchQuery?: string;
}

export interface ISsoAdminPortalLinkOnBillingGroupArguments {
  /**
   * One of Dsync (default, used for billing) or Sso (used for an extra login screen, requires Dsync to work)
   */
  intent?: SsoIntentInput;
}

export type OrgPersonListErrorable = IGraphEntityError | IOrgPersonList;

export interface IOrgPersonList {
  __typename: "OrgPersonList";
  list: Array<IOrgPerson>;
}

export type DsyncConfiguredErrorable = IDsyncConfigured | IGraphEntityError;

/**
 * Given a billing group, is SSO Dsync configured
 */
export interface IDsyncConfigured {
  __typename: "DsyncConfigured";
  isDsyncConfigured: boolean;
}

export enum InvitePolicyEnum {
  AdminOnly = "AdminOnly",
  AnyMember = "AnyMember",
}

export type BillingGroupManualMemberListErrorable =
  | IBillingGroupManualMemberList
  | IGraphEntityError;

export interface IBillingGroupManualMemberList {
  __typename: "BillingGroupManualMemberList";
  list: Array<IBillingGroupManualMember>;
}

/**
 * Represents a manual member of a billing group
 */
export interface IBillingGroupManualMember {
  __typename: "BillingGroupManualMember";
  calendarId: string;
  externalUserId: string;

  /**
   * The associated person
   */
  orgPerson: OrgPersonErrorable;
  personId: string;
  userId: string;
}

export type OrgPersonListPaginatedErrorable = IGraphEntityError | IOrgPersonListPaginated;

/**
 * List of org persons along with a nexPersonId and previousPersonId for pagination.
 */
export interface IOrgPersonListPaginated {
  __typename: "OrgPersonListPaginated";
  count: number;
  inputQuery: string;
  nextPersonId: string;
  persons: Array<IOrgPerson>;
  previousPersonId: string;
  searchPersons: Array<ISearchPerson>;
}

/**
 * A wrapper around org person for the admin panel members tab
 */
export interface ISearchPerson {
  __typename: "SearchPerson";
  person: IOrgPerson;
  status: SearchPersonStatusResponse;
}

export enum SearchPersonStatusResponse {
  Deactivated = "Deactivated",
  Invited = "Invited",
  NonUser = "NonUser",
  User = "User",
}

export enum SsoIntentInput {
  Dsync = "Dsync",
  Sso = "Sso",
}

export type SsoAdminPortalLinkErrorable = IGraphEntityError | ISsoAdminPortalLink;

/**
 * Generated link to WorkOS admin portal
 */
export interface ISsoAdminPortalLink {
  __typename: "SsoAdminPortalLink";
  link: string;
}

export type StripeCustomerPortalSessionErrorable = IGraphEntityError | IStripeCustomerPortalSession;

/**
 * Generated stripe customer portal session for a given subscription
 */
export interface IStripeCustomerPortalSession {
  __typename: "StripeCustomerPortalSession";
  account: IAccountStripe;
  sessionUrl: string;
}

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface IAccountStripe {
  __typename: "AccountStripe";
  accountId: string;
  stripeCustomerId: string;
}

export type SubscriptionDetailsErrorable = IGraphEntityError | ISubscriptionDetails;

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface ISubscriptionDetails {
  __typename: "SubscriptionDetails";
  subscription: IPaymentSubscription;
}

export enum BillingGroupTypeEnum {
  Manual = "Manual",
  SSO = "SSO",
}

export type UpcomingBillingDetailsErrorable = IGraphEntityError | IUpcomingBillingDetails;

/**
 * Details concerning the upcoming billing cycle for a billing group.
 */
export interface IUpcomingBillingDetails {
  __typename: "UpcomingBillingDetails";

  /**
   * The number of seats that will be billed for the next billing cycle
   */
  billedQuantity: number;

  /**
   * Id of the billing group in question
   */
  billingGroupId: string;

  /**
   * The number of occupied seats currently in the billing group
   */
  currentUsageQuantity: number;

  /**
   * When the billing group will be billed next (formatted YYYY-MM-DD)
   */
  nextBillingDate: string;

  /**
   * When the billing group will be trued-up (formatted YYYY-MM-DD)
   */
  nextTrueUpDate: string;

  /**
   * What the user has requested the new seat count to be
   */
  requestedUpdatedQuantity: number;
}

export type BillingGroupListErrorable = IBillingGroupList | IGraphEntityError;

export interface IBillingGroupList {
  __typename: "BillingGroupList";
  list: Array<IBillingGroup>;
}

export interface IBillingGroupsOfCalendarIdList {
  __typename: "BillingGroupsOfCalendarIdList";
  allBillingGroupsOfCalendarId: Array<BillingGroupErrorable>;
  calendarId: string;
  primaryBillingGroup: BillingGroupErrorable;
}

export type SmartHoldCalculationResultErrorable = IGraphEntityError | ISmartHoldCalculationResult;

/**
 * Calculates smart hold slots without reserving them on the calendar
 */
export interface ISmartHoldCalculationResult {
  __typename: "SmartHoldCalculationResult";
  events: Array<IEvent>;
  hadGoals: boolean;
  smartHolds: Array<IEvent>;
}

export type CalendarNotification = ICalendarNotificationResults | INoCalendarNotifications;

/**
 * User gcal notification.
 */
export interface ICalendarNotificationResults {
  __typename: "CalendarNotificationResults";
  closeTracking: ITrackingData;
  content: CalendarNotificationContent;

  /**
   * The ID of an object
   */
  id: string;
  showTracking: ITrackingData;
  type: string;
}

/**
 * Tracking data for notifications.
 */
export interface ITrackingData {
  __typename: "TrackingData";
  data: string;
  id: string;
}

export type CalendarNotificationContent = IStandardNotification;

/**
 * A simple markdown text notification.
 */
export interface IStandardNotification {
  __typename: "StandardNotification";
  body: string;
  buttons: Array<NotificationButton>;
  showConfetti: boolean;
  title: string;
}

export type NotificationButton = INotificationLink | IWebappLink;

export interface INotificationButton {
  __typename: "NotificationButton";
  text: string;
  tracking: ITrackingData;
}

/**
 * No calendar notifications exist
 */
export interface INoCalendarNotifications {
  __typename: "NoCalendarNotifications";
  void: any;
}

export type CalendarStatusErrorable = ICalendarStatus | IGraphEntityError;

/**
 * Current Calendar Status
 */
export interface ICalendarStatus {
  __typename: "CalendarStatus";
  calendarId: string;

  /**
   * The ID of an object
   */
  id: string;
  moonPhase: MoonPhase;
  status: CalendarStatusResponse;
}

export enum MoonPhase {
  FirstQuarterMoon = "FirstQuarterMoon",
  FullMoon = "FullMoon",
  LastQuarterMoon = "LastQuarterMoon",
  NewMoon = "NewMoon",
  WaningCrescentMoon = "WaningCrescentMoon",
  WaningGibbousMoon = "WaningGibbousMoon",
  WaxingCrescentMoon = "WaxingCrescentMoon",
  WaxingGibbousMoon = "WaxingGibbousMoon",
}

export enum CalendarStatusResponse {
  AfterHours = "AfterHours",
  BeforeWorkingHours = "BeforeWorkingHours",
  DayOff = "DayOff",
  FocusTime = "FocusTime",
  Holiday = "Holiday",
  Lunch = "Lunch",
  Meeting = "Meeting",
  Other = "Other",
  OutOfOffice = "OutOfOffice",
  Weekend = "Weekend",
  WorkingHours = "WorkingHours",
}

export interface IDayTimeSlotInput {
  date: string;
  timeSlot: TimeSlot;
}

/**
 * Calendar stats for a teammate
 */
export interface ICalendarTimeStats {
  __typename: "CalendarTimeStats";
  busyMinutes: number;
  calendarId: string;
  focusMinutes: number;
  fragmentedMinutes: number;
  scoresByDate: Array<ICalendarFocusTimeScore>;
  scoresByDay: Array<ICalendarFocusTimeScore>;
  totalMinutes: number;
  unclassifiedMinutes: number;
}

/**
 * Health Score for a given calendarId
 */
export interface ICalendarFocusTimeScore {
  __typename: "CalendarFocusTimeScore";
  busyMinutes: number;
  calendarId: string;
  categoryAdHoc: number;
  categoryExternal: number;
  categoryFocusTime: number;
  categoryIndividualHold: number;
  categoryOneOnOne: number;
  categoryOther: number;
  categoryOutOfOffice: number;
  categoryPaceSetting: number;
  crawledStatus: CrawledStatus;
  focusMinutes: number;
  fragmentedMinutes: number;
  secondaryKey: string;
  totalMinutes: number;
}

export enum CrawledStatus {
  NoAccess = "NoAccess",
  None = "None",
  Pending = "Pending",
  Stale = "Stale",
  Synced = "Synced",
  VeryStale = "VeryStale",
}

export type CalendarTimeStatsListErrorable = ICalendarTimeStatsList | IGraphEntityError;

export interface ICalendarTimeStatsList {
  __typename: "CalendarTimeStatsList";
  list: Array<ICalendarTimeStats>;
}

export type CanScheduleResponseListErrorable = ICanScheduleResponseList | IGraphEntityError;

export interface ICanScheduleResponseList {
  __typename: "CanScheduleResponseList";
  list: Array<ICanScheduleResponse>;
}

/**
 * An object representing whether a user can reschedule or enable autopilot
 */
export interface ICanScheduleResponse {
  __typename: "CanScheduleResponse";
  attendeeNotInDomain: boolean;
  canAutopilot: boolean;
  canReschedule: boolean;
  externalEventId: string;
  isAllDayEvent: boolean;
  organizerNotClockwiseUser: boolean;
}

/**
 * A list of teammates that you work with the most
 */
export interface ICollaboratorStats {
  __typename: "CollaboratorStats";
  calendarId: string;
  topCollaborators: Array<ICollaboratorScore>;
}

/**
 * A teammates shared minutes
 */
export interface ICollaboratorScore {
  __typename: "CollaboratorScore";
  calendarId: string;
  orgPerson: IOrgPerson;
  sharedMinutes: number;
}

export type CollaboratorStatsErrorable = ICollaboratorStats | IGraphEntityError;

export type CurrentScheduledSlotImpactErrorable = ICurrentScheduledSlotImpact | IGraphEntityError;

/**
 * Current Scheduled Slot Impact
 */
export interface ICurrentScheduledSlotImpact {
  __typename: "CurrentScheduledSlotImpact";

  /**
   * The current slot associated to the event
   */
  currentSlot: ISchedulingSlot;

  /**
   * The calendar Ids that have not been crawled yet
   */
  uncrawledCalendars: Array<string>;
}

/**
 * Scheduling Slot
 */
export interface ISchedulingSlot {
  __typename: "SchedulingSlot";
  additionalChanges: Array<ISchedulingMove>;
  attendanceImpacts: Array<ISchedulingAttendanceImpact>;
  roomImpacts: Array<ISchedulingRoomImpact>;
  summary: ISchedulingAttendanceSummary;
  timeRange: ITimeRange;
}

export interface ISchedulingMove {
  __typename: "SchedulingMove";
  event: IEventParent;
  from: any;
  organizer: any;
  to: any;
}

/**
 * An object that holds all event versions for a given externalEventId
 */
export interface IEventParent {
  __typename: "EventParent";

  /**
   * All calendarIds found on any version of this event
   */
  calendarIds: Array<string>;
  eventParentBaseId: string;
  eventParentBaseTimestamp: any;

  /**
   * The services parentEventId shared by event parents referring to the same recurrence
   * @deprecated "EventParentWrapper shouldn't be used"
   */
  eventParentWrapperId: string;

  /**
   * All active versions of an event for an externalEventId
   */
  events: Array<IEvent>;

  /**
   * The externalEventId shared by all versions, used in the id as well
   */
  externalEventId: string;

  /**
   * All calendarIds used to fetch this EventParent.  An empty array represents all calendarIds in a working group
   */
  fetchedWithCalendarIds: Array<string>;

  /**
   * Event flexibility, if this event is flexible
   */
  flexibility: EventFlexibilityErrorable;

  /**
   * Constraints around setting flexibility, for the given event
   */
  flexibilityConstraints: FlexibilityConstraintsErrorable;

  /**
   * The ID of an object
   */
  id: string;

  /**
   * Given a specific calendarId, smart hold information for the event; null if the event is not a smart hold
   */
  smartHold: SmartHold;

  /**
   * Smart hold information for the event matching any calendarId on eventParent, null if the event cannot be found or is not a smart hold
   */
  smartHoldMatch: SmartHold;

  /**
   * For personal calendar synced events, the actual event from the personal calendar
   */
  syncedOriginEventErrorable: EventErrorable;
}

export interface IFlexibilityOnEventParentArguments {
  calendarId: any;
}

export interface IFlexibilityConstraintsOnEventParentArguments {
  calendarId: any;
}

export interface ISmartHoldOnEventParentArguments {
  calendarId: any;
}

export interface ISyncedOriginEventErrorableOnEventParentArguments {
  /**
   * Calendar that event is synced to. If null, an unspecified event version is used
   */
  calendarId?: string;
}

export type EventFlexibilityErrorable = IEventFlexibility | IGraphEntityError;

/**
 * Flexibility for a given event
 */
export interface IEventFlexibility {
  __typename: "EventFlexibility";

  /**
   * String describing the events flexibility. Null if unable to fetch
   */
  description: string;

  /**
   * Whether the event can change rooms, null if the event has no room
   */
  isRoomFlexible: boolean;

  /**
   * When this event will next be considered for movement
   */
  movableAt: EventMovement;
  movableUntil: IMovableUntil;

  /**
   * How an event can move within a day, null if not configured
   */
  timeOfDayFlexibility: ITimeOfDayFlexibility;

  /**
   * How an event can move between days
   */
  timeRangeFlexibility: TimeRangeFlexibility;
}

export type EventMovement = IAnyTime | IOrgDefragTime;

/**
 * The event can move at any time
 */
export interface IAnyTime {
  __typename: "AnyTime";
  void: any;
}

/**
 * The event can move at the next defrag
 */
export interface IOrgDefragTime {
  __typename: "OrgDefragTime";
  nextDefrag: any;
}

/**
 * When the event will no longer be eligible to move
 */
export interface IMovableUntil {
  __typename: "MovableUntil";

  /**
   * How soon before an event will it no longer be eligible to move
   */
  durationBefore: any;
}

export interface ITimeOfDayFlexibility {
  __typename: "TimeOfDayFlexibility";

  /**
   * End of allowed time range for moving an event, null if not bounded by an explicit end time
   */
  end: any;

  /**
   * Time slot indicating the ending bound of the time of day flexibility
   */
  endSlot: TimeOfDayTimeSlot;

  /**
   * Beginning of allowed time range for moving an event, null if not bounded by an explicit start time
   */
  start: any;

  /**
   * Time slot indicating the starting bound of the time of day flexibility
   */
  startSlot: TimeOfDayTimeSlot;
}

export enum TimeOfDayTimeSlot {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
  Unbounded = "Unbounded",
}

export type TimeRangeFlexibility = IDayOfWeekFlexibility | IRangeFlexibility;

export interface IDayOfWeekFlexibility {
  __typename: "DayOfWeekFlexibility";
  allowedDays: Array<DayOfWeek>;
}

export enum DayOfWeek {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export interface IRangeFlexibility {
  __typename: "RangeFlexibility";
  range: FlexibilityRange;
}

export enum FlexibilityRange {
  /**
   * The event can only move within its currently scheduled day
   */
  Day = "Day",

  /**
   * The event can move anywhere within its currently scheduled week
   */
  Week = "Week",
}

export type FlexibilityConstraintsErrorable = IFlexibilityConstraints | IGraphEntityError;

/**
 * Constraints for modifying event flexibilty
 */
export interface IFlexibilityConstraints {
  __typename: "FlexibilityConstraints";

  /**
   * Whether the user is allowed to modify the flexibility settings for this event. Null if unable to compute
   */
  allowedToModify: boolean;

  /**
   *
   *               Days of the week that are available for all attendees of an event; the intersection of working days for attendees.
   *               Null if unable to compute.
   *
   */
  availableMeetingDays: Array<DayOfWeek>;

  /**
   * The intersection of meeting hours, on the day the event is currently scheduled. Null if there are no available hours
   */
  availableMeetingHours: IAvailableMeetingHours;

  /**
   * Whether day of week flexibility is allowed by the user's plan
   */
  dayOfWeekFlexibilityAllowedByPlan: boolean;

  /**
   * Whether this event is eligible for day of week flexibility. Null if unable to compute
   */
  dayOfWeekFlexibilityEligible: boolean;

  /**
   * Whether this event is external, and is ineligible for flexibility. Null if unable to compute
   */
  externalEvent: boolean;

  /**
   * Whether time of day flexibility is allowed by the user's plan. Null if unable to compute
   */
  timeOfDayAllowedByPlan: boolean;
}

/**
 * Start and end of hours that are available to meet, in the querying user's time zone
 */
export interface IAvailableMeetingHours {
  __typename: "AvailableMeetingHours";
  end: any;
  start: any;
}

export type SmartHold = IFocusTimeHold | ILunchHold | IMeetingReliefHold | ITravelTimeHold;

export interface IFocusTimeHold {
  __typename: "FocusTimeHold";

  /**
   * null if the hold doesn't belong to the user
   */
  goals: IFocusTimeGoals;
  state: SmartHoldState;
  times: IDateTimeRange;
}

export interface IFocusTimeGoals {
  __typename: "FocusTimeGoals";
  autoDeclineSettings: IAutoDeclineSettings;
  dailyMaxSettings: IDailyMaxSettings;
  enabled: boolean;

  /**
   * The ID of an object
   */
  id: string;
  preferredFocusIn: PreferredFocusTime;

  /**
   * @deprecated "use `preferredFocusIn` instead"
   */
  preferredFocusTime: PreferredFocusTime;
  remoteHoldSettings: IRemoteSmartHoldSettings;
  threshold: FocusTimeThreshold;
}

export interface IAutoDeclineSettings {
  __typename: "AutoDeclineSettings";
  enabled: boolean;
  threshold: any;
}

export interface IDailyMaxSettings {
  __typename: "DailyMaxSettings";
  enabled: boolean;
  maxAmount: any;
}

export enum PreferredFocusTime {
  Afternoon = "Afternoon",
  Morning = "Morning",
  NoPreference = "NoPreference",
}

/**
 * Settings about how to represent the smart hold in a remote system
 */
export interface IRemoteSmartHoldSettings {
  __typename: "RemoteSmartHoldSettings";
  notificationStrategy: NotificationStrategy;
  scheduleAs: ScheduleAs;
}

export enum NotificationStrategy {
  DontNotify = "DontNotify",
  NotifyInGCal = "NotifyInGCal",
}

export enum ScheduleAs {
  Busy = "Busy",
  Free = "Free",
}

export type FocusTimeThreshold = IAllFocusTime | ISomeFocusTime;

export interface IAllFocusTime {
  __typename: "AllFocusTime";
  nop: any;
}

export interface ISomeFocusTime {
  __typename: "SomeFocusTime";
  idealFocus: any;
}

export type SmartHoldState = IPaused | IProtected | IUnpaused;

export interface IPaused {
  __typename: "Paused";
  nop: any;
}

/**
 *
 *           Smart holds that are protected won't be moved automatically, and will modify
 *           any conflicting user events, to ensure there is dedicated time for the smart hold
 *
 */
export interface IProtected {
  __typename: "Protected";
  nop: any;
}

export interface IUnpaused {
  __typename: "Unpaused";
  nop: any;
}

export interface IDateTimeRange {
  __typename: "DateTimeRange";
  duration: any;
  end: any;
  start: any;
}

export interface ILunchHold {
  __typename: "LunchHold";

  /**
   * null if the hold doesn't belong to the user
   */
  goals: ILunchGoals;
  state: SmartHoldState;
  times: IDateTimeRange;
}

export interface ILunchGoals {
  __typename: "LunchGoals";
  enabled: boolean;

  /**
   * The ID of an object
   */
  id: string;
  preferredLunchTimes: IPreferredLunchTimes;
  remoteHoldSettings: IRemoteSmartHoldSettings;
}

export interface IPreferredLunchTimes {
  __typename: "PreferredLunchTimes";
  earliestStart: TimeSlot;
  idealDuration: any;
  latestEnd: TimeSlot;

  /**
   * @deprecated "use `latestEnd` instead"
   */
  maxSlot: TimeSlot;

  /**
   * @deprecated "use `minimumDuration` instead"
   */
  minDuration: any;

  /**
   * @deprecated "use `earliestStart` instead"
   */
  minSlot: TimeSlot;
  minimumDuration: any;
}

export interface IMeetingReliefHold {
  __typename: "MeetingReliefHold";

  /**
   * null if the hold doesn't belong to the user
   */
  goals: IMeetingReliefGoals;
  state: SmartHoldState;
  times: IDateTimeRange;
}

export interface IMeetingReliefGoals {
  __typename: "MeetingReliefGoals";
  enabled: boolean;

  /**
   * The ID of an object
   */
  id: string;
  meetingReliefThreshold: IMeetingReliefThreshold;
  remoteHoldSettings: IRemoteSmartHoldSettings;
}

export interface IMeetingReliefThreshold {
  __typename: "MeetingReliefThreshold";
  breakDuration: any;
  fatigueThreshold: any;

  /**
   * @deprecated "use `breakDuration` instead"
   */
  meetingReliefBreak: any;
}

export interface ITravelTimeHold {
  __typename: "TravelTimeHold";
  state: SmartHoldState;
  times: IDateTimeRange;
}

export type EventErrorable = IEvent | IGraphEntityError;

/**
 * Scheduling Attendance Impact
 */
export interface ISchedulingAttendanceImpact {
  __typename: "SchedulingAttendanceImpact";
  calendarId: string;
  conflictingMeetings: Array<string>;
  focusTimeMinutesCost: number;
  hasConflict: boolean;
  inconveniences: Array<Inconveniences>;
  unavailabilities: Array<Unavailabilities>;
}

export enum Inconveniences {
  External = "External",
  MissedLunch = "MissedLunch",
  NoMeetingRelief = "NoMeetingRelief",
  OnNoMeetingDay = "OnNoMeetingDay",
  Other = "Other",
  OutsideMeetingHours = "OutsideMeetingHours",
  OutsideWorkingHours = "OutsideWorkingHours",
  Uncrawled = "Uncrawled",
}

export enum Unavailabilities {
  FarOutsideWorkingHours = "FarOutsideWorkingHours",
}

/**
 * Scheduling Room Impact
 */
export interface ISchedulingRoomImpact {
  __typename: "SchedulingRoomImpact";
  available: boolean;
  roomId: string;
  tooLarge: boolean;
  tooSmall: boolean;
}

/**
 * Scheduling Attendance Summary
 */
export interface ISchedulingAttendanceSummary {
  __typename: "SchedulingAttendanceSummary";
  availableRooms: Array<string>;
  conflictingAttendees: Array<string>;
  focusTimeMinutesCost: number;
  freeAttendees: Array<string>;
  inconvenientForAttendees: Array<string>;
  tags: Array<SchedulingAttendanceSummaryTag>;
  unavailableAttendees: Array<string>;
}

export enum SchedulingAttendanceSummaryTag {
  Best = "Best",
  Conflict = "Conflict",
  Current = "Current",
  Good = "Good",
  InFocusTime = "InFocusTime",
  Inconvenient = "Inconvenient",
  MostFocus = "MostFocus",
  OverLunch = "OverLunch",
  Soonest = "Soonest",
}

/**
 * A time range
 */
export interface ITimeRange {
  __typename: "TimeRange";

  /**
   * The end time in the range
   */
  endTime: IEventTime;

  /**
   * The ID of an object
   */
  id: string;

  /**
   * The start time in the range
   */
  startTime: IEventTime;
}

export enum OrgCrawlTest {
  GoogleApiAccessDisabled = "GoogleApiAccessDisabled",
  GoogleContactSharingDisabled = "GoogleContactSharingDisabled",
  NoCrawl = "NoCrawl",
  NoExternalOrganization = "NoExternalOrganization",
  ShouldCrawl = "ShouldCrawl",
  Skipped = "Skipped",
  StartedCrawl = "StartedCrawl",
}

/**
 * For a given org, which crawl states calendar Ids fall into
 */
export interface ICrawledCalendarInfo {
  __typename: "CrawledCalendarInfo";

  /**
   * The ID of an object
   */
  id: string;

  /**
   * Calendar Ids that have crawl status of 'None'
   */
  none: Array<string>;

  /**
   * Calendar Ids that have crawl status of 'Pending'
   */
  pending: Array<string>;

  /**
   * Calendar Ids that have crawl status of 'Stale'
   */
  stale: Array<string>;

  /**
   * Calendar Ids that have crawl status of 'Synced'
   */
  synced: Array<string>;
}

export type EventColoringSettingsErrorable = IEventColoringSettings | IGraphEntityError;

/**
 * A User's settings for how their events should be colored
 */
export interface IEventColoringSettings {
  __typename: "EventColoringSettings";

  /**
   * A user's collection of event-category coloring preferences
   */
  eventCategoryColorings: Array<IEventCategoryColoring>;

  /**
   * The ID of an object
   */
  id: string;
}

/**
 * Color settings for a given event category
 */
export interface IEventCategoryColoring {
  __typename: "EventCategoryColoring";
  active: boolean;
  eventCategory: string;
  googleColorKey: string;
  isUserSet: boolean;
}

export type EventParentBaseWrapperErrorable = IEventParentBaseWrapper | IGraphEntityError;

/**
 * Event parents and event parent bases for those event parents
 */
export interface IEventParentBaseWrapper {
  __typename: "EventParentBaseWrapper";
  eventParentBases: Array<IEventParentBase>;
  eventParents: Array<IEventParent>;
}

/**
 * A record indicating which version of an event is current
 */
export interface IEventParentBase {
  __typename: "EventParentBase";

  /**
   * The ID of an object
   */
  id: string;

  /**
   * Ignore all event parents with a eventParentBaseTimestamp less than this
   */
  timestamp: any;
}

export type EventParentBaseWrapperByWeekListErrorable =
  | IEventParentBaseWrapperByWeekList
  | IGraphEntityError;

export interface IEventParentBaseWrapperByWeekList {
  __typename: "EventParentBaseWrapperByWeekList";
  list: Array<IEventParentBaseWrapperByWeek>;
}

/**
 * Event parent bases and parents grouped by day
 */
export interface IEventParentBaseWrapperByWeek {
  __typename: "EventParentBaseWrapperByWeek";
  eventParentBases: Array<IEventParentBase>;
  eventParentsByDay: Array<Array<IEventParent>>;
}

export type EventParentBaseWrapperByWeekObjectListErrorable =
  | IEventParentBaseWrapperByWeekObjectList
  | IGraphEntityError;

export interface IEventParentBaseWrapperByWeekObjectList {
  __typename: "EventParentBaseWrapperByWeekObjectList";
  list: Array<IEventParentBaseWrapperByWeekObject>;
}

/**
 * Event parent bases and parents grouped by day
 */
export interface IEventParentBaseWrapperByWeekObject {
  __typename: "EventParentBaseWrapperByWeekObject";
  eventParentBases: Array<IEventParentBase>;
  eventParentsByDay: Array<IEventParentsForDay>;
}

export interface IEventParentsForDay {
  __typename: "EventParentsForDay";
  eventParents: Array<IEventParent>;
}

export type EventParentWrapperConnectionErrorable =
  | IEventParentWrapperConnection
  | IGraphEntityError;

/**
 * A connection to a list of items.
 */
export interface IEventParentWrapperConnection {
  __typename: "EventParentWrapperConnection";

  /**
   * A list of edges.
   */
  edges: Array<IEventParentWrapperEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface IEventParentWrapperEdge {
  __typename: "EventParentWrapperEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: IEventParentWrapper;
}

/**
 * A top level entity that encompasses a group of evereturn eventParents
 */
export interface IEventParentWrapper {
  __typename: "EventParentWrapper";
  deleted: boolean;
  eventParents: Array<IEventParent>;

  /**
   * The ID of an object
   */
  id: string;
}

/**
 * A connection to a list of items.
 */
export interface IEventParentConnection {
  __typename: "EventParentConnection";

  /**
   * A list of edges.
   */
  edges: Array<IEventParentEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface IEventParentEdge {
  __typename: "EventParentEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: IEventParent;
}

export type ExportedCalendarAnalyticsListErrorable =
  | IExportedCalendarAnalyticsList
  | IGraphEntityError;

export interface IExportedCalendarAnalyticsList {
  __typename: "ExportedCalendarAnalyticsList";
  list: Array<IExportedCalendarAnalytics>;
}

/**
 * Exportable list of records for the analytics per user for the org
 */
export interface IExportedCalendarAnalytics {
  __typename: "ExportedCalendarAnalytics";
  data: ICalendarAnalyticsData;
  user: ICalendarAnalyticsUser;
}

/**
 * Entity to show relevant analytics for given user and week
 */
export interface ICalendarAnalyticsData {
  __typename: "CalendarAnalyticsData";

  /**
   * The week of this analytics
   */
  dateOfWeek: string;

  /**
   * The number minutes of focus time the user had this week
   */
  focusMinutes: number;

  /**
   * The number minutes of fragmented time the user had this week
   */
  fragmentedMinutes: number;

  /**
   * The user's selected job from onboarding
   */
  job: string;

  /**
   * The number minutes of meetings the user had this week
   */
  meetingMinutes: number;

  /**
   * The user's selected role from onboarding
   */
  role: string;
}

/**
 * An entity to contain relevant user information for exported calendar analytics
 */
export interface ICalendarAnalyticsUser {
  __typename: "CalendarAnalyticsUser";
  emailAddress: string;
  familyName: string;
  givenName: string;

  /**
   * The associated org person for this user
   */
  orgPerson: OrgPersonErrorable;
}

export type ExternalGroupListErrorable = IExternalGroupList | IGraphEntityError;

export interface IExternalGroupList {
  __typename: "ExternalGroupList";
  list: Array<IExternalGroup>;
}

/**
 * A group of people defined in an external system
 */
export interface IExternalGroup {
  __typename: "ExternalGroup";
  email: string;

  /**
   * The ID of an object
   */
  id: string;
  memberEmails: Array<string>;
  members: PersonListErrorable;
}

export type FeatureGridErrorable = IFeatureGrid | IGraphEntityError;

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface IFeatureGrid {
  __typename: "FeatureGrid";
  featureStates: IFeatureStates;
  stateMetadata: IPaymentStateMetadata;
}

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface IPaymentStateMetadata {
  __typename: "PaymentStateMetadata";
  primaryBillingGroupMetadata: IPrimaryBillingGroupMetadata;
  userMetadata: IUserMetadata;
}

/**
 * Primary billing group metadata field
 */
export interface IPrimaryBillingGroupMetadata {
  __typename: "PrimaryBillingGroupMetadata";
  billingGroup: IBillingGroup;
  subscription: IPaymentSubscription;
}

/**
 * User metadata
 */
export interface IUserMetadata {
  __typename: "UserMetadata";
  person: IOrgPerson;
  trialMetadata: ITrialMetadata;
}

/**
 * Trial metadata
 */
export interface ITrialMetadata {
  __typename: "TrialMetadata";
  isTrialExpired: boolean;
  timeZone: string;
  trialEndDate: string;
}

export interface IConflictEventNewTimes {
  __typename: "ConflictEventNewTimes";
  time: any;
}

/**
 * Lists of flexible meetings for the flexible meeting dashboard
 */
export interface IFlexibleMeetingsDashboard {
  __typename: "FlexibleMeetingsDashboard";

  /**
   * The ID of an object
   */
  id: string;
  myFlexibleHolds: Array<IFlexibleEvent>;
  myFlexibleMeetings: Array<IFlexibleEvent>;
  myOneOffHolds: Array<ICalendarEvent>;

  /**
   * The amount of time I spend in 1/1s per week (on average)
   */
  myOneOnOneAverage: any;
  myOneOnOnes: Array<IRecurringEvent>;
  myRecurringHolds: Array<IRecurringEvent>;
  oneOnOnes: Array<IEvent>;
  recurringMeetings: Array<IEvent>;
  suggestedFlexibleMeetings: Array<ICalendarEvent>;
}

export interface IMyFlexibleMeetingsOnFlexibleMeetingsDashboardArguments {
  /**
   * @default {"exclude":[]}
   */
  input: IFlexibleMeetingsInput;
}

export interface IFlexibleEvent {
  __typename: "FlexibleEvent";
  event: ICalendarEvent;
  flexibility: IEventFlexibility;
}

/**
 *
 *             An event specific to a single calendar.
 *
 *             A couple assumptions made here:
 *             - the event will always be "public"; this object will never represent
 *                 a private event that the current user doesn't have access to
 *             - the event will never be an "all-day" event, start & end will always have a time component
 */
export interface ICalendarEvent {
  __typename: "CalendarEvent";
  attendees: Array<IEventAttendee>;
  createdMillis: any;
  creator: IEventCreator;
  endTime: any;

  /**
   * @deprecated "Misspelled, use \"flexibilityConstraints\""
   */
  flexibililityConstraints: IFlexibilityConstraints;
  flexibility: FlexibleOrNot;
  flexibilityConstraints: IFlexibilityConstraints;
  id: any;
  organizer: IEventOrganizer;
  startTime: any;

  /**
   * A human-readable string describing the time of the event (including recurrence & flexibility if exists)
   */
  timeDescription: string;
  title: string;
}

export interface IEventAttendee {
  __typename: "EventAttendee";
  email: any;
  person: IPerson;
  response: AttendeeResponse;
}

export enum AttendeeResponse {
  Accepted = "Accepted",
  Declined = "Declined",
  NotResponded = "NotResponded",
  TentativelyAccepted = "TentativelyAccepted",
}

export interface IEventCreator {
  __typename: "EventCreator";
  email: any;
  person: IPerson;
}

export type FlexibleOrNot = IEventFlexibility | INotFlexible;

/**
 * The event is not flexible
 */
export interface INotFlexible {
  __typename: "NotFlexible";
  void: any;
}

export interface IEventOrganizer {
  __typename: "EventOrganizer";
  calendar: any;
}

export interface IFlexibleMeetingsInput {
  exclude: Array<ExcludedMeetingType>;
}

export enum ExcludedMeetingType {
  /**
   * Events with only one attendee (regardless of what the color category is set to)
   * @deprecated "This is implicitly applied"
   */
  Hold = "Hold",
  OneOnOne = "OneOnOne",
}

export interface IRecurringEvent {
  __typename: "RecurringEvent";

  /**
   *
   *             The id of a random instance in the recurrence, useful for mutations that operate on an instance but can
   *             actually mutate the recurrence (e.g. flexibility).
   */
  anInstanceId: any;
  attendees: Array<IEventAttendee>;
  creator: IEventCreator;
  duration: any;
  firstInstanceStart: any;
  flexibility: FlexibleOrNot;
  flexibilityConstraints: IFlexibilityConstraints;
  id: any;
  organizer: IEventOrganizer;
  recurrenceType: RecurrenceType;

  /**
   * Human-readable description of the events time (and flexibility if enabled)
   */
  timeDescription: string;
  title: string;
}

/**
 *
 *               A simplification of recurrence; we classify a recurrence by the frequency which is _greater or equal_ its actual frequency.
 *
 *               examples
 *               | Event             | RecurrenceType  |
 *               | ----------------- | --------------- |
 *               | every day         | Daily           |
 *               | twice a week      | Weekly          |
 *               | every other week  | Fortnightly     |
 *               | every third week  | Monthly         |
 *               | once a year       | LessThanMonthly |
 */

export enum RecurrenceType {
  Daily = "Daily",
  Fortnightly = "Fortnightly",
  LessThanMonthly = "LessThanMonthly",
  Monthly = "Monthly",
  Weekly = "Weekly",
}

export type FocusTimeStatsErrorable = IFocusTimeStats | IGraphEntityError;

/**
 * Average focus time stats (for use in Focus Time Sync)
 */
export interface IFocusTimeStats {
  __typename: "FocusTimeStats";
  minutesPerWeek: number;
}

export type EventParentErrorable = IEventParent | IGraphEntityError;

/**
 * User analytics for home page
 */
export interface IHomeAnalytics {
  __typename: "HomeAnalytics";
  currentWeek: IHomeAnalyticsData;
  previousWeek: IHomeAnalyticsData;
}

export interface IHomeAnalyticsData {
  __typename: "HomeAnalyticsData";
  conflictsResolved: number;
  focusTimeHeld: any;
  meetingTime: any;
}

export type HomeNotification = IHomeNotificationResults | INoHomeNotification;

/**
 * User home launch notification.
 */
export interface IHomeNotificationResults {
  __typename: "HomeNotificationResults";
  data: IHomeNotificationData;

  /**
   * The ID of an object
   */
  id: string;
  notificationType: string;
}

/**
 * User home launch notification data.
 */
export interface IHomeNotificationData {
  __typename: "HomeNotificationData";
  conflictsResolved: number;
  focusHoursCreated: number;
  potentialFlexMeetings: number;
  potentialFocusHours: number;
}

/**
 * No home notification exist
 */
export interface INoHomeNotification {
  __typename: "NoHomeNotification";
  void: any;
}

/**
 * Meeting Hours for a given user id
 */
export interface IMeetingHoursQueryResult {
  __typename: "MeetingHoursQueryResult";
  calendarMeetingHours: ICalendarMeetingHoursConnection;
  orgMeetingHours: IOrgMeetingHours;
  userMeetingHours: IUserMeetingHoursConnection;
}

export interface ICalendarMeetingHoursOnMeetingHoursQueryResultArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

export interface IUserMeetingHoursOnMeetingHoursQueryResultArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

/**
 * A connection to a list of items.
 */
export interface ICalendarMeetingHoursConnection {
  __typename: "CalendarMeetingHoursConnection";

  /**
   * A list of edges.
   */
  edges: Array<ICalendarMeetingHoursEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface ICalendarMeetingHoursEdge {
  __typename: "CalendarMeetingHoursEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: ICalendarMeetingHours;
}

/**
 * Meeting Hours for a given calendar id
 */
export interface ICalendarMeetingHours {
  __typename: "CalendarMeetingHours";
  calendarId: string;

  /**
   * The ID of an object
   */
  id: string;
  workingHours: IWorkingHours;
}

/**
 * Working Hours
 */
export interface IWorkingHours {
  __typename: "WorkingHours";
  dayOverrides: Array<IDayOverride>;
  daySettings: Array<IDaySetting>;

  /**
   * The ID of an object
   */
  id: string;
  timeZone: string;

  /**
   * Can be working hours or meeting hours (if you have PreferredFocusTime type, something's wrong...
   */
  type: WorkingHoursTypeEnum;
}

/**
 * An override for a day
 */
export interface IDayOverride {
  __typename: "DayOverride";
  date: string;
  setting: IWorkingSetting;
}

/**
 * Working time constraints for a time period
 */
export interface IWorkingSetting {
  __typename: "WorkingSetting";
  excludedSlots: Array<ExcludedSlots>;
  maxSlot: MaxSlot;
  minSlot: MinSlot;
}

export enum ExcludedSlots {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
}

export enum MaxSlot {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
}

export enum MinSlot {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
}

/**
 * A working setting for a day
 */
export interface IDaySetting {
  __typename: "DaySetting";
  day: Day;
  setting: IWorkingSetting;
}

export enum Day {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export enum WorkingHoursTypeEnum {
  Base = "Base",
  Meeting = "Meeting",
  PreferredFocusTime = "PreferredFocusTime",
}

/**
 * Meeting hours for the org
 */
export interface IOrgMeetingHours {
  __typename: "OrgMeetingHours";
  hasBeenSet: boolean;

  /**
   * The ID of an object
   */
  id: string;
  orgId: string;
  workingHours: IWorkingHours;
}

/**
 * A connection to a list of items.
 */
export interface IUserMeetingHoursConnection {
  __typename: "UserMeetingHoursConnection";

  /**
   * A list of edges.
   */
  edges: Array<IUserMeetingHoursEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface IUserMeetingHoursEdge {
  __typename: "UserMeetingHoursEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: IUserMeetingHours;
}

/**
 * Meeting Hours for a given user id
 */
export interface IUserMeetingHours {
  __typename: "UserMeetingHours";
  hasBeenSet: boolean;

  /**
   * The ID of an object
   */
  id: string;
  userId: string;
  workingHours: IWorkingHours;
}

export type MeetingReliefSuggestion = IMeetingReliefNoSuggestion | IMeetingReliefSuggestionResults;

/**
 * No meeting relief suggestion exists
 */
export interface IMeetingReliefNoSuggestion {
  __typename: "MeetingReliefNoSuggestion";
  void: any;
}

/**
 * Notification of long meeting block to suggest meeting relief. Long meeting block will be within the current week.
 */
export interface IMeetingReliefSuggestionResults {
  __typename: "MeetingReliefSuggestionResults";

  /**
   * The ID of an object
   */
  id: string;
  longMeetingBlock: IDateTimeRange;
}

export interface IMyBillingGroups {
  __typename: "MyBillingGroups";
  allGroups: Array<IBillingGroup>;
  primaryBillingGroup: IBillingGroup;
}

export type DefragTimeErrorable = IDefragTime | IGraphEntityError;

/**
 * Represents the time in which a defrag will run
 */
export interface IDefragTime {
  __typename: "DefragTime";
  millis: any;
}

/**
 * Onboarding Checklist
 */
export interface IOnboardingChecklist {
  __typename: "OnboardingChecklist";
  flexMeetingEnabled: FlexMeetingEnabledErrorable;
  focusTimeEnabled: FocusTimeEnabledErrorable;
  hasInvitedTeammate: HasInvitedTeammateErrorable;
  hasSuggestedFlexMeetings: HasSuggestedFlexMeetingsErrorable;
  lunchEnabled: LunchEnabledErrorable;
  slackEnabled: SlackEnabledErrorable;
}

export interface IHasSuggestedFlexMeetingsOnOnboardingChecklistArguments {
  timeZone: string;
}

export type FlexMeetingEnabledErrorable = IFlexMeetingEnabled | IGraphEntityError;

export interface IFlexMeetingEnabled {
  __typename: "FlexMeetingEnabled";
  flexMeetingEnabled: boolean;
}

export type FocusTimeEnabledErrorable = IFocusTimeEnabled | IGraphEntityError;

export interface IFocusTimeEnabled {
  __typename: "FocusTimeEnabled";
  focusTimeEnabled: boolean;
}

export type HasInvitedTeammateErrorable = IGraphEntityError | IHasInvitedTeammate;

export interface IHasInvitedTeammate {
  __typename: "HasInvitedTeammate";
  hasInvitedTeammate: boolean;
}

export type HasSuggestedFlexMeetingsErrorable = IGraphEntityError | IHasSuggestedFlexMeetings;

export interface IHasSuggestedFlexMeetings {
  __typename: "HasSuggestedFlexMeetings";
  hasSuggestedFlexMeetings: boolean;
}

export type LunchEnabledErrorable = IGraphEntityError | ILunchEnabled;

export interface ILunchEnabled {
  __typename: "LunchEnabled";
  lunchEnabled: boolean;
}

export type SlackEnabledErrorable = IGraphEntityError | ISlackEnabled;

export interface ISlackEnabled {
  __typename: "SlackEnabled";
  slackEnabled: boolean;
}

export type EventListErrorable = IEventList | IGraphEntityError;

export interface IEventList {
  __typename: "EventList";
  list: Array<IEvent>;
}

export type OptimisticSavingsErrorable = IGraphEntityError | IOptimisticSavings;

/**
 * Optimistic savings for a user, used to determine how much time can be saved in a week
 */
export interface IOptimisticSavings {
  __typename: "OptimisticSavings";
  couldCalculate: boolean;
  savingMinutes: number;
}

export type OptimizationNotification = INoNotification | IOptimizationResults;

/**
 * No notification exists
 */
export interface INoNotification {
  __typename: "NoNotification";
  void: any;
}

/**
 * Notification of what was optimized for your account
 */
export interface IOptimizationResults {
  __typename: "OptimizationResults";
  conflictsResolved: any;
  expiresAt: any;
  focusTimeAdded: any;

  /**
   * The ID of an object
   */
  id: string;
  optimizedAt: any;
}

export type FlowStateListErrorable = IFlowStateList | IGraphEntityError;

export interface IFlowStateList {
  __typename: "FlowStateList";
  list: Array<IFlowState>;
}

export type OrgOverviewErrorable = IGraphEntityError | IOrgOverview;

/**
 * Overview of organization-wide Clockwise usage
 */
export interface IOrgOverview {
  __typename: "OrgOverview";
  conflictsResolvedCount: Array<IIntegerDataPoint>;

  /**
   * False if the org doesn't have enough data to display yet
   */
  doNotShowOverview: boolean;
  focusTimeHoursCreatedCount: Array<IIntegerDataPoint>;
  numUsersByFunction: Array<IIntegerDataPoint>;
  numUsersByRole: Array<IIntegerDataPoint>;
  scheduleAssistCount: Array<IIntegerDataPoint>;
  scheduleAssistLimit: number;
  userCount: Array<IIntegerDataPoint>;
}

/**
 * Label and value pair
 */
export interface IIntegerDataPoint {
  __typename: "IntegerDataPoint";
  label: string;
  value: number;
}

export enum ActivityFilter {
  CurrentUser = "CurrentUser",
  CurrentUserOrInvited = "CurrentUserOrInvited",
  Invited = "Invited",
  NonUser = "NonUser",
}

export enum SortDirection {
  Ascending = "Ascending",
  Descending = "Descending",
}

export enum SortType {
  Collaborators = "Collaborators",
  FullName = "FullName",
  Status = "Status",
}

/**
 * A connection to a list of items.
 */
export interface IOrgInviteConnection {
  __typename: "OrgInviteConnection";

  /**
   * A list of edges.
   */
  edges: Array<IOrgInviteEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface IOrgInviteEdge {
  __typename: "OrgInviteEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: IOrgInvite;
}

/**
 * An invitation to join an org
 */
export interface IOrgInvite {
  __typename: "OrgInvite";
  createdTime: number;

  /**
   * The ID of an object
   */
  id: string;
  lastInvitingUserId: string;
  lastSentTime: number;
  orgId: string;
  targetCalendarId: string;

  /**
   * @deprecated "Does not exist"
   */
  targetProfile: IUserProfile;
  used: boolean;
}

export type TeamErrorable = IGraphEntityError | ITeam;

/**
 * Your team and its members
 */
export interface ITeam {
  __typename: "Team";
  createdBy: IUrn;

  /**
   * The ID of an object
   */
  id: string;
  invitedMembers: Array<ITeamMember>;
  settings: TeamSettingsErrorable;
  teamCalendarId: string;
  teamId: string;
  teamMembers: Array<ITeamMember>;
  teamName: string;
  teamRoleAndPersonIds: Array<ITeamRoleAndPersonId>;
  userSettings: TeamUserSettingsErrorable;
}

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface ITeamMember {
  __typename: "TeamMember";
  addedBy: string;
  person: IOrgPerson;
  role: TeamMemberRole;
}

export enum TeamMemberRole {
  Admin = "Admin",
  Member = "Member",
}

export type TeamSettingsErrorable = IGraphEntityError | ITeamSettings;

/**
 * overall settings about a team
 */
export interface ITeamSettings {
  __typename: "TeamSettings";

  /**
   * The ID of an object
   */
  id: string;
  lastUpdated: any;
  m365TeamCalendarDetails: TeamCalendarDetailsErrorable;
  manageTeamCalendar: boolean;
  minimumMinutesForSyncing: number;
  noMeetingDayEmailOnScheduleOver: boolean;
  noMeetingDayOfWeek: NoMeetingDayOfWeek;
  slackSettings: ITeamSlackSettings;
  syncWFHEvents: boolean;
  useNoMeetingDay: boolean;
}

export type TeamCalendarDetailsErrorable = IGraphEntityError | ITeamCalendarDetails;

/**
 * Entity that represents m365 team calendar
 */
export interface ITeamCalendarDetails {
  __typename: "TeamCalendarDetails";
  calendarName: string;
  members: Array<ITeamCalendarMember>;
  owner: IOrgPerson;
  teamId: string;
}

/**
 * Entity that represents m365 team calendar member
 */
export interface ITeamCalendarMember {
  __typename: "TeamCalendarMember";
  personId: string;
  status: TeamCalendarMemberInviteStatus;
}

export enum TeamCalendarMemberInviteStatus {
  Accepted = "Accepted",
  NotInvited = "NotInvited",
  Pending = "Pending",
  Unknown = "Unknown",
}

export enum NoMeetingDayOfWeek {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface ITeamSlackSettings {
  __typename: "TeamSlackSettings";
  channelId: string;
  channelName: string;
  notificationEnabled: boolean;
  timeSlot: TimeSlotEnum;
}

export enum TimeSlotEnum {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
}

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface ITeamRoleAndPersonId {
  __typename: "TeamRoleAndPersonId";
  personId: string;
  role: TeamMemberRole;
}

export type TeamUserSettingsErrorable = IGraphEntityError | ITeamUserSettings;

/**
 * individual user settings for a team
 */
export interface ITeamUserSettings {
  __typename: "TeamUserSettings";

  /**
   * The ID of an object
   */
  id: string;
  lastUpdated: any;
  useNoMeetingDay: boolean;
  useTeamCalendar: boolean;
}

export type ProfileTimeZoneListErrorable = IGraphEntityError | IProfileTimeZoneList;

export interface IProfileTimeZoneList {
  __typename: "ProfileTimeZoneList";
  list: Array<IProfileTimeZone>;
}

/**
 * A time zone for a profile
 */
export interface IProfileTimeZone {
  __typename: "ProfileTimeZone";
  calendarId: string;
  timeZone: string;
  userId: string;
}

export type SlackChannelListErrorable = IGraphEntityError | ISlackChannelList;

export interface ISlackChannelList {
  __typename: "SlackChannelList";
  list: Array<ISlackChannel>;
}

/**
 * Slack channel
 */
export interface ISlackChannel {
  __typename: "SlackChannel";
  channelId: string;
  name: string;
}

export type RoomListErrorable = IGraphEntityError | IRoomList;

export interface IRoomList {
  __typename: "RoomList";
  list: Array<IRoom>;
}

/**
 * A connection to a list of items.
 */
export interface ISettingConnection {
  __typename: "SettingConnection";

  /**
   * A list of edges.
   */
  edges: Array<ISettingEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface ISettingEdge {
  __typename: "SettingEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: ISetting;
}

/**
 * A setting
 */
export interface ISetting {
  __typename: "Setting";

  /**
   * The ID of an object
   */
  id: string;
  key: ISettingKey;
  type: ISettingType;
  value: Array<string>;
}

/**
 * A setting key
 */
export interface ISettingKey {
  __typename: "SettingKey";

  /**
   * The ID of an object
   */
  id: string;
  name: string;
  scope: IUrn;
  target: IUrn;
}

/**
 * A setting type
 */
export interface ISettingType {
  __typename: "SettingType";
  containerType: ContainerType;

  /**
   * The ID of an object
   */
  id: string;
  required: boolean;
  valueType: ValueType;
}

export enum ContainerType {
  Multi = "Multi",
  Single = "Single",
}

export enum ValueType {
  Boolean = "Boolean",
  Long = "Long",
  Numeric = "Numeric",
  String = "String",
}

export type ShadowCalendarSettingsErrorable = IGraphEntityError | IShadowCalendarSettings;

/**
 * A container of settings for the users shadow calendar
 */
export interface IShadowCalendarSettings {
  __typename: "ShadowCalendarSettings";
  enabled: boolean;
  focusTimeExternalTransparency: FocusTimeExternalTransparency;
  focusTimeIdealMinutesPerWeek: number;
  focusTimeRemindersEnabled: boolean;
  focusTimeSyncAll: boolean;
  focusTimeSyncEnabled: boolean;

  /**
   * The ID of an object
   */
  id: string;
  lunchRemindersEnabled: boolean;
  lunchSyncEnabled: boolean;
  shadowCalendarId: string;
  travelTimeRemindersEnabled: boolean;
  travelTimeSyncEnabled: boolean;
}

export enum FocusTimeExternalTransparency {
  None = "None",
  Opaque = "Opaque",
  Transparent = "Transparent",
}

export type SlackAccountErrorable = IGraphEntityError | ISlackAccount;

/**
 * Slack Account
 */
export interface ISlackAccount {
  __typename: "SlackAccount";

  /**
   * The ID of an object
   */
  id: string;
  needsUpdate: boolean;
  slackTeamId: string;
  slackUserId: string;
  teamDisplayName: string;
}

export type SlackAccountListErrorable = IGraphEntityError | ISlackAccountList;

export interface ISlackAccountList {
  __typename: "SlackAccountList";
  list: Array<ISlackAccount>;
}

export type SlackSettingsListErrorable = IGraphEntityError | ISlackSettingsList;

export interface ISlackSettingsList {
  __typename: "SlackSettingsList";
  list: Array<ISlackSettings>;
}

/**
 * Slack calendar sync settings
 */
export interface ISlackSettings {
  __typename: "SlackSettings";
  calendarStatus: ISlackCalendarStatusSync;

  /**
   * @deprecated "use calendarStatus"
   */
  calendarStatusEnabled: boolean;
  dailyDigestSettings: ISlackDailyDigestSettings;
  dndSettings: ISlackDndSettings;

  /**
   * The ID of an object
   */
  id: string;

  /**
   * @deprecated "use calendarStatus"
   */
  includeMeetingCategory: boolean;

  /**
   * @deprecated "use calendarStatus"
   */
  includeMeetingTitle: boolean;
  notificationSettings: ISlackNotificationSettings;
  slackTeamId: string;
  slackUserId: string;
}

export interface ISlackCalendarStatusSync {
  __typename: "SlackCalendarStatusSync";
  calendarStatusEnabled: boolean;
  includeMeetingCategory: boolean;
  includeMeetingTitle: boolean;
}

export interface ISlackDailyDigestSettings {
  __typename: "SlackDailyDigestSettings";
  enabled: boolean;
  timeSlot: DigestTimeSlot;
}

export enum DigestTimeSlot {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
}

export interface ISlackDndSettings {
  __typename: "SlackDndSettings";
  focusTime: boolean;
  meeting: boolean;
}

export interface ISlackNotificationSettings {
  __typename: "SlackNotificationSettings";
  defragSummary: boolean;
}

export type SmartHoldEventInfoErrorable = IGraphEntityError | ISmartHoldEventInfo;

/**
 * Metadata about a Smart Hold event
 */
export interface ISmartHoldEventInfo {
  __typename: "SmartHoldEventInfo";

  /**
   * The ID of an object
   */
  id: string;
  isPostTravelTime: boolean;
  isPreTravelTime: boolean;
  isTeamCalendarSyncEvent: boolean;
  originEvent: IEvent;
}

export interface ISmartHoldGoals {
  __typename: "SmartHoldGoals";
  focusTime: IFocusTimeGoals;

  /**
   * The ID of an object
   */
  id: string;
  lunch: ILunchGoals;
  meetingRelief: IMeetingReliefGoals;
  travelTime: ITravelTimeGoals;
}

export interface ITravelTimeGoals {
  __typename: "TravelTimeGoals";
  enabled: boolean;

  /**
   * The ID of an object
   */
  id: string;
  remoteHoldSettings: IRemoteSmartHoldSettings;
  userAddress: AddressIfSet;
}

export type AddressIfSet = IAddress | INoAddress;

export interface IAddress {
  __typename: "Address";
  rawAddress: string;
}

/**
 * No address has been configured
 */
export interface INoAddress {
  __typename: "NoAddress";
  nop: any;
}

export enum SsoIntent {
  Dsync = "Dsync",
  Sso = "Sso",
}

export interface ISuggestedFlexibleMeetingFilter {
  /**
   * a datetime range that an event must be within to be suggested. Null if we don't want to filter down to a date time range
   * @default null
   */
  inRange?: ISuggestedMeetingDateTimeRangeFilter;
  types: Array<SuggestedMeetingType>;
}

export interface ISuggestedMeetingDateTimeRangeFilter {
  end: any;
  start: any;
}

export enum SuggestedMeetingType {
  OneOnOne = "OneOnOne",
}

/**
 * Meetings that are suggested to be flexible
 */
export interface ISuggestedFlexibleMeetings {
  __typename: "SuggestedFlexibleMeetings";
  meetings: Array<IEventParent>;
  totalNumberOfSuggestions: any;
}

/**
 * A connection to a list of items.
 */
export interface ISuggestedOrgInviteConnection {
  __typename: "SuggestedOrgInviteConnection";

  /**
   * A list of edges.
   */
  edges: Array<ISuggestedOrgInviteEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface ISuggestedOrgInviteEdge {
  __typename: "SuggestedOrgInviteEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: ISuggestedOrgInvite;
}

/**
 * A suggestion to invite someone to join an org
 */
export interface ISuggestedOrgInvite {
  __typename: "SuggestedOrgInvite";

  /**
   * The ID of an object
   */
  id: string;
  inviteReason: SuggestedOrgInviteReason;
  numberOfOrganizedMeetings: number;
  numberOfSharedMeetings: number;
  orgId: string;
  resolution: SuggestedOrgInviteResolution;
  suggestedTime: number;
  targetCalendarId: string;
  targetProfile: IUserProfile;
  totalScore: number;
}

export enum SuggestedOrgInviteReason {
  AttendSameMeetings = "AttendSameMeetings",
  MeetsWithYourTeammates = "MeetsWithYourTeammates",
  OnYourTeam = "OnYourTeam",
  OrganizedYourMeetings = "OrganizedYourMeetings",
}

export enum SuggestedOrgInviteResolution {
  Accepted = "Accepted",
  Dismissed = "Dismissed",
}

export type SuggestedRoomPreferencesErrorable = IGraphEntityError | ISuggestedRoomPreferences;

/**
 * The suggested room preferences for the user
 */
export interface ISuggestedRoomPreferences {
  __typename: "SuggestedRoomPreferences";

  /**
   * The ID of an object
   */
  id: string;
  suggestedRooms: Array<ISuggestedRoomPreference>;
}

/**
 * A selected or suggested preferred room
 */
export interface ISuggestedRoomPreference {
  __typename: "SuggestedRoomPreference";

  /**
   * Has the room been selected as a preferred room by the user
   */
  isPreferred: boolean;

  /**
   * Is the room suggested as a preferred room for the user
   */
  isSuggested: boolean;
  room: IRoom;
}

export type SuggestedTeamListErrorable = IGraphEntityError | ISuggestedTeamList;

export interface ISuggestedTeamList {
  __typename: "SuggestedTeamList";
  list: Array<ISuggestedTeam>;
}

/**
 * A suggested team and its reasons
 */
export interface ISuggestedTeam {
  __typename: "SuggestedTeam";
  reasons: Array<ISuggestedTeamReason>;
  score: number;
  team: ITeam;
}

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface ISuggestedTeamReason {
  __typename: "SuggestedTeamReason";
  type: SuggestedTeamReasonType;
  value: number;
}

export enum SuggestedTeamReasonType {
  NumMeetingsWithOverlap = "NumMeetingsWithOverlap",
  NumOneOnOnesWithOverlap = "NumOneOnOnesWithOverlap",
}

/**
 * A summary of working preferences for a teammate
 */
export interface ISummarizedWorkingPreferences {
  __typename: "SummarizedWorkingPreferences";
  busiestDay: Day;
  calendarId: string;
  mostBookedRoom: string;
  mostFocusTime: Day;
}

export interface IEventTimeInput {
  dateOrDateTime: string;
  millis: number;
  offsetMinutes?: number;
  timeZone?: string;
  type: EventTimeType;
}

export type TailoredDefragCardSummaryErrorable = IGraphEntityError | ITailoredDefragCardSummary;

/**
 * A tailored set of defrag moves summarized for the user
 */
export interface ITailoredDefragCardSummary {
  __typename: "TailoredDefragCardSummary";
  calendarValueStrings: Array<ITailoredDefragCalendarValueStrings>;
  defragTime: any;
  impactedOtherCalendarIds: Array<string>;
  knobs: IDefragValueKnobs;
  persons: PersonListErrorable;
  referencedEvents: Array<IEvent>;
  rooms: RoomListErrorable;
  valueStrings: Array<string>;
  yourEventMoves: Array<IDefragExplainedMove>;
}

export interface ITailoredDefragCalendarValueStrings {
  __typename: "TailoredDefragCalendarValueStrings";
  calendarId: string;
  valueStrings: Array<string>;
}

/**
 * Defrag Value Knobs
 */
export interface IDefragValueKnobs {
  __typename: "DefragValueKnobs";
  autopilotMeetingKnobs: Array<IAutopilotMeetingKnob>;
  calendarId: string;
  inviteOrganizerKnobs: Array<IInviteOrganizerKnob>;
  lessenAutopilotConstraintKnobs: Array<ILessenAutopilotConstraintKnob>;
}

/**
 * Affected Goal For Event
 */
export interface IAutopilotMeetingKnob {
  __typename: "AutopilotMeetingKnob";
  affectedGoals: Array<AffectedGoals>;
  externalEventId: string;
  knobScore: number;
  knobUrn: IUrn;
}

export enum AffectedGoals {
  AttendMeeting = "AttendMeeting",
  ConvenientMeetingTime = "ConvenientMeetingTime",
  FocusTask = "FocusTask",
  FocusTime = "FocusTime",
  Lunch = "Lunch",
  MeetingRelief = "MeetingRelief",
  NoMeetingDay = "NoMeetingDay",
  PersonalObligation = "PersonalObligation",
  PreferredWork = "PreferredWork",
  PrepTime = "PrepTime",
  Task = "Task",
  TravelTime = "TravelTime",
  WithinMeetingHours = "WithinMeetingHours",
  WithinWorkingHours = "WithinWorkingHours",
}

/**
 * Inviter Organizer Knob
 */
export interface IInviteOrganizerKnob {
  __typename: "InviteOrganizerKnob";
  affectedEventIds: Array<string>;
  affectedGoals: Array<AffectedGoals>;
  affectedGoalsForEvents: Array<IAffectedGoalForEvent>;
  knobScore: number;
  knobUrn: IUrn;
  suggestedInvitee: string;
  suggestedInviteePerson: PersonErrorable;
}

/**
 * The event and reasons why it is affected
 */
export interface IAffectedGoalForEvent {
  __typename: "AffectedGoalForEvent";
  affectedGoals: Array<AffectedGoals>;
  externalEventId: string;
}

/**
 * Lessen Autopilot Constraint Knob
 */
export interface ILessenAutopilotConstraintKnob {
  __typename: "LessenAutopilotConstraintKnob";
  affectedGoals: Array<AffectedGoals>;
  externalEventId: string;
  knobScore: number;
  knobUrn: IUrn;
}

/**
 * The explanation for a singular move within a defrag
 */
export interface IDefragExplainedMove {
  __typename: "DefragExplainedMove";
  addedRooms: Array<string>;
  event: IEvent;
  moveReasons: Array<string>;
  newStartTime: IEventTime;
  originalStartTime: IEventTime;
  removedRooms: Array<string>;
}

export type CalendarStatusListErrorable = ICalendarStatusList | IGraphEntityError;

export interface ICalendarStatusList {
  __typename: "CalendarStatusList";
  list: Array<ICalendarStatus>;
}

export type TeamHealthScoreErrorable = IGraphEntityError | ITeamHealthScore;

/**
 * Team Health Score
 */
export interface ITeamHealthScore {
  __typename: "TeamHealthScore";
  calendarFocusTimeScores: Array<ICalendarFocusTimeScore>;
  myFocusTimeScore: ICalendarFocusTimeScore;
  orgId: string;
  orgPersons: OrgPersonListErrorable;
  persons: PersonListErrorable;
  profileTimeZones: Array<IProfileTimeZone>;
}

export type TeamHealthScoreWithSubTeamsErrorable = IGraphEntityError | ITeamHealthScoreWithSubTeams;

/**
 * Team Health Score With Sub Teams
 */
export interface ITeamHealthScoreWithSubTeams {
  __typename: "TeamHealthScoreWithSubTeams";
  teamCalendars: Array<ITeamCalendarMap>;
  teamHealthScore: ITeamHealthScore;
}

export interface ITeamCalendarMap {
  __typename: "teamCalendarMap";
  calendarIds: Array<string>;
  teamId: string;
  teamName: string;
}

export type TeamListErrorable = IGraphEntityError | ITeamList;

export interface ITeamList {
  __typename: "TeamList";
  list: Array<ITeam>;
}

/**
 * Life Cycle Nudge
 */
export type LifeCycleNudge =
  | IConflictNudge
  | IDefragCelebrationNudge
  | IFTCostNudge
  | IFlexMeetingSuggestionNudge
  | ILowFTNudge
  | IMeetingReliefNudge;

/**
 * Nudge generated due to an event conflicting with another event
 */
export interface IConflictNudge {
  __typename: "ConflictNudge";

  /**
   * The eventId of the event that the nudge is associated with. Used in accessing and modifying the event.
   */
  eventId: string;

  /**
   * The title of the event that the nudge is associated with.
   */
  eventTitle: string;
  highlightedEventBlocks: Array<IHighlightedCalendarBlock>;

  /**
   * The unique identifier of the nudge. Used in marking nudges as viewed.
   */
  nudgeKey: string;
}

/**
 * Provides information needed to highlight a block of time on the calendar.
 */
export interface IHighlightedCalendarBlock {
  __typename: "HighlightedCalendarBlock";

  /**
   * The duration of the block in minutes, for highlight height
   */
  blockLengthMinutes: string;

  /**
   * The start time of the block to be highlighted in GCal
   */
  blockStartTime: string;

  /**
   * A boolean determining if the block should have a label. The logic is on the client side will determine what the label should be.
   */
  hasLabel: boolean;
}

/**
 * Nudge generated for a users first ever defrag
 */
export interface IDefragCelebrationNudge {
  __typename: "DefragCelebrationNudge";
  conflictResolved: number;
  ftHoursCreated: number;
  highlightedEventBlocks: Array<IHighlightedCalendarBlock>;

  /**
   * The unique identifier of the nudge. Used in marking nudges as viewed.
   */
  nudgeKey: string;
}

/**
 * Nudge generated due to an event costing significant Focus Time Cost
 */
export interface IFTCostNudge {
  __typename: "FTCostNudge";

  /**
   * The eventId of the event that the nudge is associated with. Used in accessing and modifying the event.
   */
  eventId: string;

  /**
   * The title of the event that the nudge is associated with.
   */
  eventTitle: string;
  focusTimeMinutes: number;
  highlightedEventBlocks: Array<IHighlightedCalendarBlock>;

  /**
   * The unique identifier of the nudge. Used in marking nudges as viewed.
   */
  nudgeKey: string;
}

/**
 * Nudge generated for a users first flex meeting suggestion
 */
export interface IFlexMeetingSuggestionNudge {
  __typename: "FlexMeetingSuggestionNudge";
  highlightedEventBlocks: Array<IHighlightedCalendarBlock>;

  /**
   * The unique identifier of the nudge. Used in marking nudges as viewed.
   */
  nudgeKey: string;
}

/**
 * Nudge generated due to a user not having focus time enabled
 */
export interface ILowFTNudge {
  __typename: "LowFTNudge";
  focusTimeMinutes: number;
  highlightedEventBlocks: Array<IHighlightedCalendarBlock>;

  /**
   * The unique identifier of the nudge. Used in marking nudges as viewed.
   */
  nudgeKey: string;
}

/**
 * Nudge generated for a users first flex meeting suggestion
 */
export interface IMeetingReliefNudge {
  __typename: "MeetingReliefNudge";
  highlightedEventBlocks: Array<IHighlightedCalendarBlock>;

  /**
   * The unique identifier of the nudge. Used in marking nudges as viewed.
   */
  nudgeKey: string;
}

/**
 * The users team as defined by the user
 */
export interface IUserDefinedTeam {
  __typename: "UserDefinedTeam";
  calendarIds: Array<string>;

  /**
   * The ID of an object
   */
  id: string;
}

/**
 * A connection to a list of items.
 */
export interface IGoalConnection {
  __typename: "GoalConnection";

  /**
   * A list of edges.
   */
  edges: Array<IGoalEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface IGoalEdge {
  __typename: "GoalEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: IGoal;
}

/**
 * A calendar's goal
 */
export interface IGoal {
  __typename: "Goal";

  /**
   * The id of the goal, used internally by the services
   */
  goalId: string;

  /**
   * The ID of an object
   */
  id: string;

  /**
   * Whether this goal is deleted or not
   */
  isDeleted: boolean;

  /**
   * The name associated with this goal
   */
  name: string;

  /**
   * Options associated with this goal
   */
  options: IGoalOptions;

  /**
   * Additional ids that this goal is associated with
   */
  otherUrns: Array<IUrn>;

  /**
   * The id of this goal's target, generally a calendar id scoped to an org
   */
  targetUrn: IUrn;

  /**
   * The id associated with an object used to represent this goal, e.g. an event id if the goal is represented by an event
   */
  valueUrn: IUrn;
}

/**
 * Options describing a goal
 */
export interface IGoalOptions {
  __typename: "GoalOptions";
  specificOptions: GoalSpecificOptions;

  /**
   * If this goal is saved on the user's google calendar
   */
  syncToGoogle: boolean;
}

export type GoalSpecificOptions =
  | IGoalMeetingReliefOptions
  | IGoalPreferredWorkOptions
  | IGoalWithinDayOptions
  | IGoalWithinWeekOptions;

/**
 * Options for the preferred work option
 */
export interface IGoalMeetingReliefOptions {
  __typename: "GoalMeetingReliefOptions";

  /**
   * The number of consecutive minutes of meetings that are allowed before a gap is requested
   */
  fatigueThresholdMinutes: number;

  /**
   * The number of minutes placed between consecutive meetings when fatigue threshold met
   */
  meetingReliefBreakMinutes: number;
}

/**
 * Options for the preferred work option
 */
export interface IGoalPreferredWorkOptions {
  __typename: "GoalPreferredWorkOptions";

  /**
   * Whether to keep the end of a user's day open, or to schedule meetings until the end
   */
  keepEndOfDayOpen: boolean;

  /**
   * Whether to keep the start of a user's day open, or to schedule meetings at the start
   */
  keepStartOfDayOpen: boolean;

  /**
   * When the user prefers to work
   */
  preferredWorkType: PreferredWorkType;
}

export enum PreferredWorkType {
  Afternoon = "Afternoon",
  Any = "Any",
  Morning = "Morning",
}

/**
 * Options for goals that move within a day
 */
export interface IGoalWithinDayOptions {
  __typename: "GoalWithinDayOptions";

  /**
   * The ideal and usually maximum duration of the goal
   */
  idealDuration: number;

  /**
   * The maximum time slot in a day that the goal can be in
   */
  maxTimeSlot: TimeSlot;

  /**
   * The minimum time slot in a day that the goal can be in
   */
  minTimeSlot: TimeSlot;

  /**
   * The minimum duration of the goal
   */
  minimumDuration: number;
}

/**
 * Options for creating a goal that operates in the entire week context. e.g. focus time
 */
export interface IGoalWithinWeekOptions {
  __typename: "GoalWithinWeekOptions";

  /**
   * The maximum duration in minutes of each individual block, where a block is typically an event on the calendar
   */
  maxMinutesPerBlock: number;
}

export type UserSurveyResponseListErrorable = IGraphEntityError | IUserSurveyResponseList;

export interface IUserSurveyResponseList {
  __typename: "UserSurveyResponseList";
  list: Array<IUserSurveyResponse>;
}

/**
 * Onboarding survey reponses from user
 */
export interface IUserSurveyResponse {
  __typename: "UserSurveyResponse";
  freeformResponse: string;
  questionText: string;
  questionType: QuestionType;
  responseText: string;
  responseType: ResponseType;
}

export enum QuestionType {
  DoNotKnowHowItWillAffectTeammates = "DoNotKnowHowItWillAffectTeammates",
  DoNotNeedClockwiseToMoveMeetings = "DoNotNeedClockwiseToMoveMeetings",
  DoNotTrustClockwiseToMoveMeetings = "DoNotTrustClockwiseToMoveMeetings",
  DoNotUnderstandFlexibleMeetings = "DoNotUnderstandFlexibleMeetings",
  HelpWithFindingUninterruptedTimeToFocus = "HelpWithFindingUninterruptedTimeToFocus",
  HelpWithSchedulingExternalMeetingsEffortlessly = "HelpWithSchedulingExternalMeetingsEffortlessly",
  HelpWithSettingHealthyWorkLifeBoundaries = "HelpWithSettingHealthyWorkLifeBoundaries",
  HelpWithSimplifyingSchedulingWithCoworkers = "HelpWithSimplifyingSchedulingWithCoworkers",
  HelpWithSomethingElse = "HelpWithSomethingElse",
  HowDidYouHearAboutClockwise = "HowDidYouHearAboutClockwise",
  HowManyPeopleOnTeam = "HowManyPeopleOnTeam",
  OtherFreeFormReason = "OtherFreeFormReason",
  SuggestedMeetingsAreNotMovable = "SuggestedMeetingsAreNotMovable",
  WhatIsYourCurrentPosition = "WhatIsYourCurrentPosition",
  WhatTypeOfWorkDoYouDo = "WhatTypeOfWorkDoYouDo",
  WhatsYourTimeManagementGoal = "WhatsYourTimeManagementGoal",
}

export enum ResponseType {
  AccountingOrFinance = "AccountingOrFinance",
  Administrative = "Administrative",
  Between5And9 = "Between5And9",
  Between10And15 = "Between10And15",
  Between15And20 = "Between15And20",
  BusinessOwner = "BusinessOwner",
  Checked = "Checked",
  CreateMoreTimeForFocusedWork = "CreateMoreTimeForFocusedWork",
  CustomerSupport = "CustomerSupport",
  DesignOrUxResearch = "DesignOrUxResearch",
  DirectorOrDepartmentLeader = "DirectorOrDepartmentLeader",
  ExecutiveOrBusinessLeader = "ExecutiveOrBusinessLeader",
  FoundOnline = "FoundOnline",
  FriendOrFamily = "FriendOrFamily",
  IDoNotKnow = "IDoNotKnow",
  IDoNotWorkOnAnyTeams = "IDoNotWorkOnAnyTeams",
  IndividualContributor = "IndividualContributor",
  InternetSearch = "InternetSearch",
  It = "It",
  JustLookingAround = "JustLookingAround",
  Legal = "Legal",
  LessThan5 = "LessThan5",
  Manager = "Manager",
  Marketing = "Marketing",
  MoreThan20 = "MoreThan20",
  MyMeetingsOnAutopilot = "MyMeetingsOnAutopilot",
  OnlineAdvertisement = "OnlineAdvertisement",
  Operations = "Operations",
  Other = "Other",
  PeopleOpsOrHr = "PeopleOpsOrHr",
  ProductManagement = "ProductManagement",
  ProgramOrProjectManagement = "ProgramOrProjectManagement",
  Recruiting = "Recruiting",
  ReserveTimeForPersonalObligations = "ReserveTimeForPersonalObligations",
  ResolveConflictsAutomatically = "ResolveConflictsAutomatically",
  SalesOrBusinessDevelopment = "SalesOrBusinessDevelopment",
  ScheduleEasilyWithMyTeam = "ScheduleEasilyWithMyTeam",
  ScheduleExternalMeetingsSeamlessly = "ScheduleExternalMeetingsSeamlessly",
  SocialMedia = "SocialMedia",
  SoftwareEngineering = "SoftwareEngineering",
  TeammateAutopilot = "TeammateAutopilot",
  TeammateCalendar = "TeammateCalendar",
  TeammateFocusTime = "TeammateFocusTime",
  TeammateInvited = "TeammateInvited",
  TeammatePersonalCalendar = "TeammatePersonalCalendar",
  TeammatePrepTime = "TeammatePrepTime",
  TeammateSlackStatus = "TeammateSlackStatus",
  TeammateTravelTime = "TeammateTravelTime",
  Unchecked = "Unchecked",
  VpOrDivisionLeader = "VpOrDivisionLeader",
}

export type UserSurveyResponseWrapperErrorable = IGraphEntityError | IUserSurveyResponseWrapper;

/**
 * A wrapper for UserSurveyResponses to give them an ID.
 */
export interface IUserSurveyResponseWrapper {
  __typename: "UserSurveyResponseWrapper";

  /**
   * The ID of an object
   */
  id: string;
  userSurveyResponses: Array<IUserSurveyResponse>;
}

/**
 * Working Hours for a given user id
 */
export interface IUserWorkingHours {
  __typename: "UserWorkingHours";
  hasBeenSet: boolean;

  /**
   * The ID of an object
   */
  id: string;
  userId: string;
  workingHours: IWorkingHours;
}

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface IWorkingLocation {
  __typename: "WorkingLocation";
  address: string;
  id: string;
  locationType: LocationTypeEnum;

  /**
   * Name of the working location.
   */
  name: string;
  timeZone: string;
}

export enum LocationTypeEnum {
  HOME = "HOME",
  OFFICE = "OFFICE",
  OTHER = "OTHER",
}

export type WorkingLocationListErrorable = IGraphEntityError | IWorkingLocationList;

export interface IWorkingLocationList {
  __typename: "WorkingLocationList";
  list: Array<IWorkingLocation>;
}

export type AddressErrorable = IAddress | IGraphEntityError;

/**
 * A connection to a list of items.
 */
export interface IWorkingGroupConnection {
  __typename: "WorkingGroupConnection";

  /**
   * A list of edges.
   */
  edges: Array<IWorkingGroupEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface IWorkingGroupEdge {
  __typename: "WorkingGroupEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: IWorkingGroup;
}

/**
 * a collection of calendarIds in a shared group
 */
export interface IWorkingGroup {
  __typename: "WorkingGroup";

  /**
   * The people that make up the working group
   */
  calendarIds: Array<string>;

  /**
   * Calendars associated with this org
   */
  calendarWeeks: ICalendarWeekConnection;

  /**
   * Calendars associated with this org
   */
  calendars: ICalendarConnection;

  /**
   * An org's collection of events from all queried calendars and dates
   */
  eventParents: IEventParentConnection;

  /**
   * The ID of an object
   */
  id: string;

  /**
   * The name of the working group
   */
  name: string;

  /**
   * The org id this working group belongs to
   */
  orgId: string;

  /**
   * The list of rooms associated with this working group
   */
  rooms: Array<IRoom>;
}

export interface ICalendarWeeksOnWorkingGroupArguments {
  after?: string;
  before?: string;
  calendarIds: Array<string>;
  first?: number;
  last?: number;
  startDate: any;
  timeZone: string;
}

export interface ICalendarsOnWorkingGroupArguments {
  after?: string;
  before?: string;
  calendarIds?: Array<string>;
  first?: number;
  last?: number;
  orgId?: string;
}

export interface IEventParentsOnWorkingGroupArguments {
  after?: string;
  before?: string;
  calendarIds?: Array<string>;
  dates?: Array<any>;
  eventParentRelayId?: string;
  first?: number;
  last?: number;

  /**
   * @default "America/Los_Angeles"
   */
  timeZone?: string;
}

/**
 * A connection to a list of items.
 */
export interface ICalendarWeekConnection {
  __typename: "CalendarWeekConnection";

  /**
   * A list of edges.
   */
  edges: Array<ICalendarWeekEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface ICalendarWeekEdge {
  __typename: "CalendarWeekEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: ICalendarWeek;
}

/**
 * A week of events for a given calendar id
 */
export interface ICalendarWeek {
  __typename: "CalendarWeek";
  calendarId: string;
  calendarMeetingHours: ICalendarMeetingHours;
  eventParents: IEventParentConnection;

  /**
   * The ID of an object
   */
  id: string;
  orgId: string;
  startDate: any;
  timeZone: string;
  userId: string;
  userMeetingHours: IUserMeetingHours;
}

export interface IEventParentsOnCalendarWeekArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

/**
 * A connection to a list of items.
 */
export interface ICalendarConnection {
  __typename: "CalendarConnection";

  /**
   * A list of edges.
   */
  edges: Array<ICalendarEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface ICalendarEdge {
  __typename: "CalendarEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: ICalendar;
}

/**
 * A calendar belonging to someone or thing in the organization
 */
export interface ICalendar {
  __typename: "Calendar";

  /**
   * The id of the calendar — usually an email address
   */
  calendarId: string;

  /**
   * The ID of an object
   */
  id: string;

  /**
   * The id of the organization this calendar belongs to
   */
  orgId: string;
}

/**
 * Working Hours for a given user id
 */
export interface IWorkingHoursQueryResult {
  __typename: "WorkingHoursQueryResult";
  userWorkingHours: IUserWorkingHoursConnection;
}

export interface IUserWorkingHoursOnWorkingHoursQueryResultArguments {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

/**
 * A connection to a list of items.
 */
export interface IUserWorkingHoursConnection {
  __typename: "UserWorkingHoursConnection";

  /**
   * A list of edges.
   */
  edges: Array<IUserWorkingHoursEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface IUserWorkingHoursEdge {
  __typename: "UserWorkingHoursEdge";

  /**
   * A cursor for use in pagination
   */
  cursor: string;

  /**
   * The item at the end of the edge
   */
  node: IUserWorkingHours;
}

export type WorkingLocationWeeklyScheduleErrorable =
  | IGraphEntityError
  | IWorkingLocationWeeklySchedule;

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface IWorkingLocationWeeklySchedule {
  __typename: "WorkingLocationWeeklySchedule";

  /**
   * User's working location preference by day of the week.
   */
  workingLocationByDay: Array<IWorkingLocationByDay>;
}

export interface IWorkingLocationByDay {
  __typename: "WorkingLocationByDay";
  day: string;
  workingLocation: WorkingLocationErrorable;
  workingLocationId: string;
}

export type WorkingLocationErrorable = IGraphEntityError | IWorkingLocation;

export type ZoomAccountErrorable = IGraphEntityError | IZoomAccount;

/**
 * Zoom Account
 */
export interface IZoomAccount {
  __typename: "ZoomAccount";
  accountId: string;
  connected: string;

  /**
   * Domain of the zoom account that’s been connected
   */
  displayName: string;

  /**
   * boolean whether the user has a valid zoom account for the connected zoom integration
   */
  hasValidZoomAccount: boolean;

  /**
   * The ID of an object
   */
  id: string;
  ownerPersons: PersonListErrorable;

  /**
   * userId of the person who connected the zoom account
   */
  ownerUserId: string;
}

export interface IScoredTime {
  __typename: "ScoredTime";
  tradeoffs: Array<ITradeoffBlock>;
}

export enum UserStatusEnum {
  Active = "Active",
  Preview = "Preview",
  Restricted = "Restricted",
}

export type TeamQueryResultErrorable = IGraphEntityError | ITeamQueryResult;

/**
 * The result of a team query
 */
export interface ITeamQueryResult {
  __typename: "TeamQueryResult";
  query: string;
  searchId: number;
  teamMembers: Array<ITeamMember>;
  teams: Array<ITeam>;
}

/**
 * A verification for a verification code including the external account that will be connected
 */
export interface IVerificationCodeResponse {
  __typename: "VerificationCodeResponse";
  slackTeam: string;
  slackUsername: string;
  valid: boolean;
  verificationAttempted: boolean;
  verificationCode: string;
  verificationType: VerificationType;
}

export enum VerificationType {
  Slack = "Slack",
}

export interface IMutation {
  __typename: "Mutation";
  addAdminForBillingGroup: IAddAdminForBillingGroupMutationPayload;
  addAndRemoveOrgTags: IAddAndRemoveOrgTagsMutationPayload;
  addManualMembersForBillingGroup: IAddManualMembersForBillingGroupMutationPayload;
  addOrgTags: IAddOrgTagsMutationPayload;
  addSlackAccount: IAddSlackAccountMutationPayload;
  addSlackAccountForTeam: IAddSlackAccountForTeamMutationPayload;
  addSlackAccountV2: IAddSlackAccountV2MutationPayload;
  addZoomAccount: IAddZoomAccountMutationPayload;
  batchAddAndRemoveOrgTags: IBatchAddAndRemoveOrgTagsMutationPayload;
  batchModifyAutopilotForEvents: IBatchModifyAutopilotForEventsMutationPayload;

  /**
   * Modifies events flexibilities, returns the event parents that have been updated
   * @deprecated "Always and only returns event parents, use \"setDayRangeFlexibility\" instead"
   */
  batchUpdateFlexibility: Array<IEventParent>;

  /**
   * Confirms a chat proposal made by the assistant
   */
  cancelChatProposal: ICancelChatProposalPayload;

  /**
   * Triggers cancelling of a modify event from the event diff
   */
  cancelEventDiffDetails: ICancelEventDiffDetailsPayload;

  /**
   * Cancels an in flight processes message request from the user
   */
  cancelProcessMessage: ICancelProcessMessagePayload;

  /**
   * Confirms a proposal by ID
   */
  cancelProposal: ICancelSharedProposalPayload;
  cancelTestSubscription: ICancelTestSubscriptionsPayload;
  clearUserData: IClearUserDataMutationPayload;

  /**
   * Confirms a chat proposal made by the assistant
   */
  confirmChatProposal: IConfirmChatProposalPayload;

  /**
   * Confirms a proposal by ID
   */
  confirmProposal: IConfirmSharedProposalPayload;
  createDataSource: ICreateDataSourceMutationPayload;
  createOrgSubscriptionStripe: ICreateOrgSubscriptionStripePayload;
  createTeam: ICreateTeamMutationPayload;
  createTeamAndTeamSubscriptionStripe: ICreateTeamAndTeamSubscriptionStripePayload;
  createTeamCalendar: ICreateTeamCalendarMutationPayload;
  createTeamSubscriptionStripe: ICreateTeamSubscriptionStripePayload;
  createTestSubscriptionStripe: ICreateTestSubscriptionStripePayload;
  createWorkingLocation: ICreateWorkingLocationMutationPayload;
  deactivateUser: IDeactivateUserMutationPayload;

  /**
   * mutation to delete Asana credentials.
   */
  deleteAsanaCredentials: IDeleteAsanaCredentialsPayload;
  deleteDataSource: IDeleteDataSourcePayload;
  deleteEvent: IDeleteEventMutationPayload;
  deleteTeam: IDeleteTeamMutationPayload;
  deleteUserLevelZoomAccount: IDeleteUserLevelZoomAccountMutationPayload;
  deleteWorkingLocation: IDeleteWorkingLocationMutationPayload;
  deleteZoomAccount: IDeleteZoomAccountMutationPayload;
  disableDataSourceSync: IDisableDataSourceSyncPayload;

  /**
   * Disambiguates an attendee on an add or modify event diff
   */
  disambiguateProposalAttendee: IDisambiguateProposalAttendeePayload;
  editExistingEvent: IEditExistingEventPayload;
  enableDataSourceSync: IEnableDataSourceSyncPayload;
  enableOrDisableTeamSyncForEvent: IEnableOrDisableTeamSyncForEventPayload;
  executeCliCommand: IExecuteCliCommandMutationPayload;
  extendTrial: IExtendTrialPayload;
  installSlackApp: IInstallSlackAppMutationPayload;
  invitePersonsToTeam: IInvitePersonsToTeamPayload;
  inviteToBillingGroup: IInviteToBillingGroupMutationPayload;
  loginMicrosoftUser: ILoginMicrosoftUserMutationPayload;
  loginUser: ILoginUserMutationPayload;
  logoutInsightsUser: ILogoutInsightsUserPayload;
  logoutUser: ILogoutUserMutationPayload;
  markANudgeAsViewed: IMarkNudgeAsViewedPayload;
  markDefragValueKnobsAsAccepted: IMarkDefragValueKnobsAsAcceptedMutationPayload;
  markDefragValueKnobsAsDismissed: IMarkDefragValueKnobsAsDismissedMutationPayload;
  markDefragValueKnobsAsPresented: IMarkDefragValueKnobsAsPresentedMutationPayload;
  maybeOnboardOrg: IMaybeOnboardOrgMutationPayload;
  overrideSyncStatusForUserEvent: IOverrideSyncStatusForUserEventMutationPayload;
  patchBillingGroup: IPatchBillingGroupMutationPayload;
  patchSlackSettings: IPatchSlackSettingsMutationPayload;
  pauseHold: IPauseHoldPayload;
  preAddOrgTags: IPreAddOrgTagsMutationPayload;

  /**
   * Pre sets event flexibility, before the event has been created, based on a set of event properties
   */
  preSetEventFlexibility: IPreSetFlexibilityResponse;

  /**
   * Processes a message from the user
   */
  processMessage: IProcessMessagePayload;
  protectHold: IProtectedHoldPayload;
  recordChromeInstall: IRecordChromeInstallMutationPayload;
  recordChromeUninstall: IRecordChromeUninstallMutationPayload;
  recordDefragResponse: IRecordDefragResponseMutationPayload;
  recordImpression: IRecordImpressionMutationPayload;
  registerEmailUpdates: IRegisterEmailUpdatesPayload;
  removeAdminForBillingGroup: IRemoveAdminForBillingGroupMutationPayload;

  /**
   * Removes a diff from a scheduling proposal, marking it inactive
   */
  removeDiffFromProposal: IRemoveDiffFromProposalPayload;
  removeGoal: IRemoveGoalMutationPayload;
  removeLunchHold: IRemoveLunchHoldMutationPayload;
  removeManualMembersForBillingGroup: IRemoveManualMembersForBillingGroupMutationPayload;
  removeOrgTags: IRemoveOrgTagsMutationPayload;
  rescheduleEventParent: IRescheduleEventParentMutationPayload;

  /**
   * Resets the activer conversation between the user and Clocky
   */
  resetConversation: IResetConversationPayload;

  /**
   * Modifies an event during the on demand conflict resolution
   */
  resolveConflict: IResolveConflictPayload;

  /**
   * Saves the details of an add event diff
   */
  saveAddEventDiffDetails: ISaveAddEventDiffDetailsPayload;

  /**
   * mutation to set auth code recieved from Asana via redirect uri upon successful authentication.
   */
  saveAsanaCredentials: ISaveAsanaCredentialsPayload;

  /**
   * Updates the flexibility  for a proposal
   */
  saveFlexStatus: ISaveFlexStatusPayload;

  /**
   * Saves the details of a modify event diff
   */
  saveModifyEventDiffDetails: ISaveModifyEventDiffDetailsPayload;

  /**
   * All the information that the application needs to send a push message: an endpoint and the encryption key needed for sending data.
   */
  savePushServiceSubscription: ISavePushServiceSubscriptionMutationPayload;

  /**
   * Saves the details of an add event diff on a shared proposal
   */
  saveSharedProposalEventDiffDetails: ISaveSharedProposalEventDiffDetailsPayload;
  saveTeamSettings: ISaveTeamSettingsMutationPayload;
  saveTeamUserSettings: ISaveTeamUserSettingsMutationPayload;
  saveUninstallResponse: ISaveUninstallResponseMutationPayload;
  scheduleNewEventV2: IScheduleNewEventV2MutationPayload;
  scheduleNewSmartHold: IScheduleNewSmartHoldMutationPayload;

  /**
   * Updates the scheduling option selection for an add or edit event diff
   */
  selectSchedulingOption: ISelectSchedulingOptionPayload;

  /**
   * Selects a shared option for a proposal
   */
  selectSharedOption: ISelectSharedOptionPayload;
  sendBillingGroupRequestEmail: ISendBillingGroupRequestEmailMutationPayload;
  sendM365TeamAvailabilityCalendarInvite: ISendM365TeamAvailabilityCalendarInviteMutationPayload;
  sendM365TeamAvailabilityCalendarInvitesForTeam: ISendM365TeamAvailabilityCalendarInvitesForTeamMutationPayload;

  /**
   * mutation that sends admin's request to change billing group seat quantity.
   */
  sendSeatQuantityChangeRequest: ISendSeatQuantityChangeRequestMutationPayload;

  /**
   * Enables/Disables flexibility (infers specific flexibility setting) for these calendar events
   */
  setCalendarEventFlexibility: ISetCalendarEventFlexibilityPayload;

  /**
   * Modifies events's day range flexibility
   */
  setDayRangeFlexibility: IDayRangeFlexibilityUpdated;

  /**
   * Updates various event flexibility properties
   */
  setFlexibility: IFlexibilityUpdated;
  setPreferredRooms: ISetPreferredRoomsMutationPayload;
  setPrimaryTeam: ISetPrimaryTeamMutationPayload;

  /**
   * Modifies the room flexibility for the supplied event(s)
   */
  setRoomFlexibility: IRoomFlexibilityUpdated;

  /**
   * Modifies the time of day flexibility
   */
  setTimeOfDayFlexibility: ITimeOfDayFlexibilityUpdated;
  setWorkAddressForUser: ISetWorkAddressForUserMutationPayload;

  /**
   * Mark a proposal as shared
   */
  shareProposal: IShareProposalPayload;
  submitHubspotForm: ISubmitHubspotFormMutationPayload;
  sudoDeactivateUser: ISudoDeactivateUserMutationPayload;
  sudoDeleteEverything: ISudoDeleteEverythingMutationPayload;
  sudoModifyUser: ISudoModifyUserMutationPayload;
  sudoResetFlowState: ISudoResetFlowStateMutationPayload;
  sudoSwitchUser: ISudoSwitchUserMutationPayload;
  sudoTriggerClientUpdate: ISudoTriggerClientUpdateMutationPayload;

  /**
   * Update setting for syncing meeting relief holds
   */
  syncMeetingRelief: ISyncMeetingReliefPayload;
  toggleAutoSyncForBillingGroup: IToggleAutoSyncForBillingGroupMutationPayload;
  toggleFocusTime: IToggleFocusTimeGoalResponse;
  toggleLunch: IToggleGoalResponse;
  toggleMeetingRelief: IToggleMeetingReliefResponse;
  toggleTravelTime: IToggleTravelTimeResponse;
  toggleUserNudgeUsage: IToggleUserNudgeUsagePayload;
  triggerDefragRun: ITriggerDefragRunMutationPayload;

  /**
   * triggers optimization notification for all users in the org
   */
  triggerOptimizationNotification: IOptimizationNotificationTriggerResult;
  unpauseEvent: IUnpauseEventMutationPayload;
  unpauseHold: IUnpauseHoldPayload;
  unprotectHold: IUnprotectedHoldPayload;
  unsubscribeEmail: IUnsubscribeEmailPayload;
  unsyncFocusTimeBlock: IUnsyncFocusTimeBlockMutationPayload;
  unsyncFocusTimeEvent: IUnsyncFocusTimeEventMutationPayload;

  /**
   * Removes a smart hold event from a user's calendar
   */
  unsyncHold: IUnsyncHoldPayload;

  /**
   * Removes a meeting relief event from a user's calendar
   */
  unsyncMeetingReliefEvent: IUnsyncMeetingReliefEvent;
  updateDeviceToken: IUpdateDeviceTokenPayload;
  updateEventColoringSettings: IEventColoringSettingsMutationsPayload;
  updateEventResponseStatus: IUpdateEventResponseStatusMutationPayload;
  updateFlagsForUser: IUpdateFlagsForUserMutationPayload;
  updateFlowStateUser: IUpdateUserFlowMutationPayload;
  updateFlowStateUserOrg: IUpdateUserOrgFlowMutationPayload;
  updateFocusTime: IUpdateFocusTimeGoalResponse;
  updateLunch: IUpdateLunchGoalResponse;
  updateMeetingRelief: IUpdateMeetingReliefGoalResponse;
  updateOrgInvite: IUpdateOrgInvitePayload;
  updateSetting: IUpdateSettingMutationPayload;
  updateSettings: IUpdateSettingsMutationPayload;
  updateShadowCalendarSettings: IUpdateShadowCalendarSettingsMutationPayload;
  updateSlackAccount: IUpdateSlackAccountMutationPayload;
  updateSlackAccountForTeam: IUpdateSlackAccountForTeamMutationPayload;
  updateSmartHoldSyncSettings: IUpdateSmartHoldSyncSettingsMutationPayload;
  updateSuggestedOrgInvite: IUpdateSuggestedOrgInvitePayload;
  updateTeam: IUpdateTeamMutationPayload;
  updateToggleEventColoringSettings: IToggleEventColoringSettingsMutationsPayload;
  updateTravelTime: IUpdateTravelTimeGoalResponse;
  updateTravelTimeDuration: IUpdateTravelTimeDurationPayload;
  updateUserDefinedTeam: IUpdateUserDefinedTeamMutationPayload;
  updateWorkingHours: IUpdateWorkingHoursMutationPayload;
  updateWorkingLocation: IUpdateWorkingLocationMutationPayload;
  updateWorkingLocationWeeklySchedule: IUpdateWorkingLocationWeeklyScheduleMutationPayload;
  upsertFocusTime: IUpsertFocusTimeMutationPayload;
  upsertLunch: IUpsertLunchMutationPayload;
  upsertMeetingRelief: IUpsertMeetingReliefMutationPayload;
  upsertNonUserDefragEmailResponse: IUpsertNonUserDefragEmailResponsePayload;
  upsertPreferredWork: IUpsertPreferredWorkMutationPayload;
  upsertTravelTime: IUpsertTravelTimeMutationPayload;

  /**
   * Marks that the user has interacted with the the notification
   */
  userInteractedWithNotification: IUserInteractedWithNotification;
  userSurveyResponseMutations: ISaveUserSurveyResponsesMutationPayload;
  userSurveyResponseWrapperMutations: ISaveUserSurveyResponseWrapperMutationPayload;
  validateSlackChannelBySendingMessage: IValidateSlackChannelBySendingMessageMutationPayload;
}

export interface IAddAdminForBillingGroupOnMutationArguments {
  input: IAddAdminForBillingGroupMutationInput;
}

export interface IAddAndRemoveOrgTagsOnMutationArguments {
  input: IAddAndRemoveOrgTagsMutationInput;
}

export interface IAddManualMembersForBillingGroupOnMutationArguments {
  input: IAddManualMembersForBillingGroupMutationInput;
}

export interface IAddOrgTagsOnMutationArguments {
  input: IAddOrgTagsMutationInput;
}

export interface IAddSlackAccountOnMutationArguments {
  input: IAddSlackAccountMutationInput;
}

export interface IAddSlackAccountForTeamOnMutationArguments {
  input: IAddSlackAccountForTeamMutationInput;
}

export interface IAddSlackAccountV2OnMutationArguments {
  input: IAddSlackAccountV2MutationInput;
}

export interface IAddZoomAccountOnMutationArguments {
  input: IAddZoomAccountMutationInput;
}

export interface IBatchAddAndRemoveOrgTagsOnMutationArguments {
  input: IBatchAddAndRemoveOrgTagsMutationInput;
}

export interface IBatchModifyAutopilotForEventsOnMutationArguments {
  input: IBatchModifyAutopilotForEventsMutationInput;
}

export interface IBatchUpdateFlexibilityOnMutationArguments {
  input: IBatchModifyFlexibilityInput;
}

export interface ICancelEventDiffDetailsOnMutationArguments {
  input: ICancelEventDiffDetailsInput;
}

export interface ICancelProposalOnMutationArguments {
  input: ISharedProposalInput;
}

export interface ICancelTestSubscriptionOnMutationArguments {
  input: ICancelTestSubscriptionsInput;
}

export interface IClearUserDataOnMutationArguments {
  input: IClearUserDataMutationInput;
}

export interface IConfirmChatProposalOnMutationArguments {
  input?: IConfirmChatMessageInput;
}

export interface IConfirmProposalOnMutationArguments {
  input: ISharedProposalInput;
}

export interface ICreateDataSourceOnMutationArguments {
  input: ICreateDataSourceMutationInput;
}

export interface ICreateOrgSubscriptionStripeOnMutationArguments {
  input: ICreateOrgSubscriptionStripeInput;
}

export interface ICreateTeamOnMutationArguments {
  input: ICreateTeamMutationInput;
}

export interface ICreateTeamAndTeamSubscriptionStripeOnMutationArguments {
  input: ICreateTeamAndTeamSubscriptionStripeInput;
}

export interface ICreateTeamCalendarOnMutationArguments {
  input: ICreateTeamCalendarMutationInput;
}

export interface ICreateTeamSubscriptionStripeOnMutationArguments {
  input: ICreateTeamSubscriptionStripeInput;
}

export interface ICreateTestSubscriptionStripeOnMutationArguments {
  input: ICreateTestSubscriptionStripeInput;
}

export interface ICreateWorkingLocationOnMutationArguments {
  input: ICreateWorkingLocationMutationInput;
}

export interface IDeactivateUserOnMutationArguments {
  input: IDeactivateUserMutationInput;
}

export interface IDeleteAsanaCredentialsOnMutationArguments {
  input: IDeleteAsanaCredentialsInput;
}

export interface IDeleteDataSourceOnMutationArguments {
  input: IDeleteDataSourceInput;
}

export interface IDeleteEventOnMutationArguments {
  input: IDeleteEventMutationInput;
}

export interface IDeleteTeamOnMutationArguments {
  input: IDeleteTeamMutationInput;
}

export interface IDeleteUserLevelZoomAccountOnMutationArguments {
  input: IDeleteUserLevelZoomAccountMutationInput;
}

export interface IDeleteWorkingLocationOnMutationArguments {
  input: IDeleteWorkingLocationMutationInput;
}

export interface IDeleteZoomAccountOnMutationArguments {
  input: IDeleteZoomAccountMutationInput;
}

export interface IDisableDataSourceSyncOnMutationArguments {
  input: IDisableDataSourceSyncInput;
}

export interface IDisambiguateProposalAttendeeOnMutationArguments {
  input: IDisambiguateAttendeeInput;
}

export interface IEditExistingEventOnMutationArguments {
  input: IEditExistingEventInput;
}

export interface IEnableDataSourceSyncOnMutationArguments {
  input: IEnableDataSourceSyncInput;
}

export interface IEnableOrDisableTeamSyncForEventOnMutationArguments {
  input: IEnableOrDisableTeamSyncForEventInput;
}

export interface IExecuteCliCommandOnMutationArguments {
  input: IExecuteCliCommandMutationInput;
}

export interface IExtendTrialOnMutationArguments {
  input: IExtendTrialInput;
}

export interface IInstallSlackAppOnMutationArguments {
  input: IInstallSlackAppMutationInput;
}

export interface IInvitePersonsToTeamOnMutationArguments {
  input: IInvitePersonsToTeamInput;
}

export interface IInviteToBillingGroupOnMutationArguments {
  input: IInviteToBillingGroupMutationInput;
}

export interface ILoginMicrosoftUserOnMutationArguments {
  input: ILoginMicrosoftUserMutationInput;
}

export interface ILoginUserOnMutationArguments {
  input: ILoginUserMutationInput;
}

export interface ILogoutInsightsUserOnMutationArguments {
  input: ILogoutInsightsUserInput;
}

export interface ILogoutUserOnMutationArguments {
  input: ILogoutUserMutationInput;
}

export interface IMarkANudgeAsViewedOnMutationArguments {
  input: IMarkNudgeAsViewedInput;
}

export interface IMarkDefragValueKnobsAsAcceptedOnMutationArguments {
  input: IMarkDefragValueKnobsAsAcceptedMutationInput;
}

export interface IMarkDefragValueKnobsAsDismissedOnMutationArguments {
  input: IMarkDefragValueKnobsAsDismissedMutationInput;
}

export interface IMarkDefragValueKnobsAsPresentedOnMutationArguments {
  input: IMarkDefragValueKnobsAsPresentedMutationInput;
}

export interface IMaybeOnboardOrgOnMutationArguments {
  input: IMaybeOnboardOrgMutationInput;
}

export interface IOverrideSyncStatusForUserEventOnMutationArguments {
  input: IOverrideSyncStatusForUserEventMutationInput;
}

export interface IPatchBillingGroupOnMutationArguments {
  input: IPatchBillingGroupMutationInput;
}

export interface IPatchSlackSettingsOnMutationArguments {
  input: IPatchSlackSettingsMutationInput;
}

export interface IPauseHoldOnMutationArguments {
  input: IPauseHoldInput;
}

export interface IPreAddOrgTagsOnMutationArguments {
  input: IPreAddOrgTagsMutationInput;
}

export interface IPreSetEventFlexibilityOnMutationArguments {
  input: IPreSetFlexibilityInput;
}

export interface IProcessMessageOnMutationArguments {
  input: IProcessChatMessageInput;
}

export interface IProtectHoldOnMutationArguments {
  input: IProtectHoldInput;
}

export interface IRecordChromeInstallOnMutationArguments {
  input: IRecordChromeInstallMutationInput;
}

export interface IRecordChromeUninstallOnMutationArguments {
  input: IRecordChromeUninstallMutationInput;
}

export interface IRecordDefragResponseOnMutationArguments {
  input: IRecordDefragResponseMutationInput;
}

export interface IRecordImpressionOnMutationArguments {
  input: IRecordImpressionMutationInput;
}

export interface IRegisterEmailUpdatesOnMutationArguments {
  input: IRegisterEmailUpdatesInput;
}

export interface IRemoveAdminForBillingGroupOnMutationArguments {
  input: IRemoveAdminForBillingGroupMutationInput;
}

export interface IRemoveDiffFromProposalOnMutationArguments {
  input?: IRemoveDiffFromProposalInput;
}

export interface IRemoveGoalOnMutationArguments {
  input: IRemoveGoalMutationInput;
}

export interface IRemoveLunchHoldOnMutationArguments {
  input: IRemoveLunchHoldMutationInput;
}

export interface IRemoveManualMembersForBillingGroupOnMutationArguments {
  input: IRemoveManualMembersForBillingGroupMutationInput;
}

export interface IRemoveOrgTagsOnMutationArguments {
  input: IRemoveOrgTagsMutationInput;
}

export interface IRescheduleEventParentOnMutationArguments {
  input: IRescheduleEventParentMutationInput;
}

export interface IResolveConflictOnMutationArguments {
  input: IResolveConflictInput;
}

export interface ISaveAddEventDiffDetailsOnMutationArguments {
  input: ISaveAddEventDiffDetailsInput;
}

export interface ISaveAsanaCredentialsOnMutationArguments {
  input: ISaveAsanaCredentialsInput;
}

export interface ISaveFlexStatusOnMutationArguments {
  input: ISaveFlexStatusInput;
}

export interface ISaveModifyEventDiffDetailsOnMutationArguments {
  input: ISaveModifyEventDiffDetailsInput;
}

export interface ISavePushServiceSubscriptionOnMutationArguments {
  input: ISavePushServiceSubscriptionMutationInput;
}

export interface ISaveSharedProposalEventDiffDetailsOnMutationArguments {
  input: ISaveSharedProposalEventDiffDetailsInput;
}

export interface ISaveTeamSettingsOnMutationArguments {
  input: ISaveTeamSettingsMutationInput;
}

export interface ISaveTeamUserSettingsOnMutationArguments {
  input: ISaveTeamUserSettingsMutationInput;
}

export interface ISaveUninstallResponseOnMutationArguments {
  input: ISaveUninstallResponseMutationInput;
}

export interface IScheduleNewEventV2OnMutationArguments {
  input: IScheduleNewEventV2MutationInput;
}

export interface IScheduleNewSmartHoldOnMutationArguments {
  input: IScheduleNewSmartHoldMutationInput;
}

export interface ISelectSchedulingOptionOnMutationArguments {
  input: ISelectSchedulingOptionInput;
}

export interface ISelectSharedOptionOnMutationArguments {
  input: IGraphQLSelectSharedSchedulingOptionInput;
}

export interface ISendBillingGroupRequestEmailOnMutationArguments {
  input: ISendBillingGroupRequestEmailMutationInput;
}

export interface ISendM365TeamAvailabilityCalendarInviteOnMutationArguments {
  input: ISendM365TeamAvailabilityCalendarInviteMutationInput;
}

export interface ISendM365TeamAvailabilityCalendarInvitesForTeamOnMutationArguments {
  input: ISendM365TeamAvailabilityCalendarInvitesForTeamMutationInput;
}

export interface ISendSeatQuantityChangeRequestOnMutationArguments {
  input: ISendSeatQuantityChangeRequestMutationInput;
}

export interface ISetCalendarEventFlexibilityOnMutationArguments {
  input: ISetCalendarEventFlexibilityInput;
}

export interface ISetDayRangeFlexibilityOnMutationArguments {
  input: IUpdateDayRangeFlexibilityInput;
}

export interface ISetFlexibilityOnMutationArguments {
  input: IUpdateFlexibilityInput;
}

export interface ISetPreferredRoomsOnMutationArguments {
  input: ISetPreferredRoomsMutationInput;
}

export interface ISetPrimaryTeamOnMutationArguments {
  input: ISetPrimaryTeamMutationInput;
}

export interface ISetRoomFlexibilityOnMutationArguments {
  input: IBatchUpdateRoomFlexibilityInput;
}

export interface ISetTimeOfDayFlexibilityOnMutationArguments {
  input: IUpdateTimeOfDayFlexibilityInput;
}

export interface ISetWorkAddressForUserOnMutationArguments {
  input: ISetWorkAddressForUserMutationInput;
}

export interface IShareProposalOnMutationArguments {
  input: ISharedProposalInput;
}

export interface ISubmitHubspotFormOnMutationArguments {
  input: ISubmitHubspotFormMutationInput;
}

export interface ISudoDeactivateUserOnMutationArguments {
  input: ISudoDeactivateUserMutationInput;
}

export interface ISudoDeleteEverythingOnMutationArguments {
  input: ISudoDeleteEverythingMutationInput;
}

export interface ISudoModifyUserOnMutationArguments {
  input: ISudoModifyUserMutationInput;
}

export interface ISudoResetFlowStateOnMutationArguments {
  input: ISudoResetFlowStateMutationInput;
}

export interface ISudoSwitchUserOnMutationArguments {
  input: ISudoSwitchUserMutationInput;
}

export interface ISudoTriggerClientUpdateOnMutationArguments {
  input: ISudoTriggerClientUpdateMutationInput;
}

export interface ISyncMeetingReliefOnMutationArguments {
  input?: ISyncMeetingReliefInput;
}

export interface IToggleAutoSyncForBillingGroupOnMutationArguments {
  input: IToggleAutoSyncForBillingGroupMutationInput;
}

export interface IToggleFocusTimeOnMutationArguments {
  /**
   * Should focus time be enabled
   */
  enabled: boolean;

  /**
   * The graph org id
   */
  orgId: string;
}

export interface IToggleLunchOnMutationArguments {
  /**
   * Should lunch be enabled
   */
  enabled: boolean;

  /**
   * The graph org id
   */
  orgId: string;
}

export interface IToggleMeetingReliefOnMutationArguments {
  /**
   * Should meeting relief be enabled
   */
  enabled: boolean;

  /**
   * The graph org id
   */
  orgId: string;
}

export interface IToggleTravelTimeOnMutationArguments {
  /**
   * Should travel time be enabled
   */
  enabled: boolean;

  /**
   * The graph org id
   */
  orgId: string;
}

export interface IToggleUserNudgeUsageOnMutationArguments {
  input: IToggleUserNudgeUsageInput;
}

export interface ITriggerDefragRunOnMutationArguments {
  input: ITriggerDefragRunMutationInput;
}

export interface ITriggerOptimizationNotificationOnMutationArguments {
  orgId: string;
}

export interface IUnpauseEventOnMutationArguments {
  input: IUnpauseEventMutationInput;
}

export interface IUnpauseHoldOnMutationArguments {
  input: IUnpauseHoldInput;
}

export interface IUnprotectHoldOnMutationArguments {
  input: IUnprotectHoldInput;
}

export interface IUnsubscribeEmailOnMutationArguments {
  input: IUnsubscribeEmailInput;
}

export interface IUnsyncFocusTimeBlockOnMutationArguments {
  input: IUnsyncFocusTimeBlockMutationInput;
}

export interface IUnsyncFocusTimeEventOnMutationArguments {
  input: IUnsyncFocusTimeEventMutationInput;
}

export interface IUnsyncHoldOnMutationArguments {
  input: IUnsyncHoldInput;
}

export interface IUnsyncMeetingReliefEventOnMutationArguments {
  input: IUnsyncMeetingReliefEventInput;
}

export interface IUpdateDeviceTokenOnMutationArguments {
  input: IUpdateDeviceTokenInput;
}

export interface IUpdateEventColoringSettingsOnMutationArguments {
  input: IEventColoringSettingsMutationsInput;
}

export interface IUpdateEventResponseStatusOnMutationArguments {
  input: IUpdateEventResponseStatusMutationInput;
}

export interface IUpdateFlagsForUserOnMutationArguments {
  input: IUpdateFlagsForUserMutationInput;
}

export interface IUpdateFlowStateUserOnMutationArguments {
  input: IUpdateUserFlowMutationInput;
}

export interface IUpdateFlowStateUserOrgOnMutationArguments {
  input: IUpdateUserOrgFlowMutationInput;
}

export interface IUpdateFocusTimeOnMutationArguments {
  /**
   * Whether or not autodecline meetings within focus time is enabled, null if unchanged
   * @default null
   */
  autoDeclineEnabled?: boolean;

  /**
   * Minimum number of hours of focus time before auto decline is enabled
   */
  autoDeclineThreshold?: IUpdateAutoDeclineThreshold;

  /**
   * Maxiumum amount of focus time to schedule per day
   */
  dailyMaxAmount?: IUpdateDailyMax;

  /**
   * Whether the user selects a max focus time per day, null if unchanged
   * @default null
   */
  dailyMaxEnabled?: boolean;

  /**
   * How should the user be notified about these events starting, in the remote calendar system, null if unchanged
   * @default null
   */
  notificationStrategy?: UpdateNotificationStrategyInput;

  /**
   * The graph org id
   */
  orgId: string;

  /**
   * When in the day does the user prefer to have focus time holds, null if unchanged
   * @default null
   */
  preferredFocusIn?: UpdatePreferredFocusTime;

  /**
   * Whether we should schedule holds for all focus time on a calendar, this takes precendence over `scheduleAtMost` if both are specified, null if unchanged
   * @default null
   */
  scheduleAllFocusTime?: boolean;

  /**
   * How to schedule holds of this type on a remote calendar, null if unchanged
   * @default null
   */
  scheduleAs?: UpdateScheduleAs;

  /**
   * Only schedule up to the specified number of hours of focus time smart holds on the user's calendar, null if unchanged
   * @default null
   */
  scheduleAtMost?: IUpdateAtMostFocusHours;
}

export interface IUpdateLunchOnMutationArguments {
  /**
   * The earliest slot that lunch can start at, null if unchanged
   * @default null
   */
  earliestStart?: TimeSlot;

  /**
   * The ideal duration of the lunch hold, null if unchanged
   * @default null
   */
  idealDuration?: any;

  /**
   * The latest slot that lunch can occupy, null if unchanged
   * @default null
   */
  latestEnd?: TimeSlot;

  /**
   * The shortest duration that we are allowed to schedule a hold for, null if unchanged
   * @default null
   */
  minimumDuration?: any;

  /**
   * How should the user be notified about these events starting, in the remote calendar system, null if unchanged
   * @default null
   */
  notificationStrategy?: UpdateNotificationStrategyInput;

  /**
   * The graph org id
   */
  orgId: string;

  /**
   * How to schedule holds of this type on a remote calendar, null if unchanged
   * @default null
   */
  scheduleAs?: UpdateScheduleAs;
}

export interface IUpdateMeetingReliefOnMutationArguments {
  /**
   * Duration of the break hold, null if unchanged
   * @default null
   */
  breakDuration?: any;

  /**
   * How many minutes of consecutive meetings can be tolerated, null if unchanged
   * @default null
   */
  fatigueThreshold?: any;

  /**
   * How should the user be notified about these events starting, in the remote calendar system, null if unchanged
   * @default null
   */
  notificationStrategy?: UpdateNotificationStrategyInput;

  /**
   * The graph org id
   */
  orgId: string;

  /**
   * How to schedule holds of this type on a remote calendar, null if unchanged
   * @default null
   */
  scheduleAs?: UpdateScheduleAs;
}

export interface IUpdateOrgInviteOnMutationArguments {
  input: IUpdateOrgInviteInput;
}

export interface IUpdateSettingOnMutationArguments {
  input: IUpdateSettingMutationInput;
}

export interface IUpdateSettingsOnMutationArguments {
  input: IUpdateSettingsMutationInput;
}

export interface IUpdateShadowCalendarSettingsOnMutationArguments {
  input: IUpdateShadowCalendarSettingsMutationInput;
}

export interface IUpdateSlackAccountOnMutationArguments {
  input: IUpdateSlackAccountMutationInput;
}

export interface IUpdateSlackAccountForTeamOnMutationArguments {
  input: IUpdateSlackAccountForTeamMutationInput;
}

export interface IUpdateSmartHoldSyncSettingsOnMutationArguments {
  input: IUpdateSmartHoldSyncSettingsMutationInput;
}

export interface IUpdateSuggestedOrgInviteOnMutationArguments {
  input: IUpdateSuggestedOrgInviteInput;
}

export interface IUpdateTeamOnMutationArguments {
  input: IUpdateTeamMutationInput;
}

export interface IUpdateToggleEventColoringSettingsOnMutationArguments {
  input: IToggleEventColoringSettingsMutationsInput;
}

export interface IUpdateTravelTimeOnMutationArguments {
  /**
   * Whether to remove the user's stored address, this will take precedence over the `userAddress` field if both specified
   * @default false
   */
  clearAddress: boolean;

  /**
   * How should the user be notified about these events starting, in the remote calendar system, null if unchanged
   * @default null
   */
  notificationStrategy?: UpdateNotificationStrategyInput;

  /**
   * The graph org id
   */
  orgId: string;

  /**
   * How to schedule holds of this type on a remote calendar, null if unchanged
   * @default null
   */
  scheduleAs?: UpdateScheduleAs;

  /**
   * The user's address, null if unchanged
   * @default null
   */
  userAddress?: string;
}

export interface IUpdateTravelTimeDurationOnMutationArguments {
  input: IUpdateTravelTimeDurationInput;
}

export interface IUpdateUserDefinedTeamOnMutationArguments {
  input: IUpdateUserDefinedTeamMutationInput;
}

export interface IUpdateWorkingHoursOnMutationArguments {
  input: IUpdateWorkingHoursMutationInput;
}

export interface IUpdateWorkingLocationOnMutationArguments {
  input: IUpdateWorkingLocationMutationInput;
}

export interface IUpdateWorkingLocationWeeklyScheduleOnMutationArguments {
  input: IUpdateWorkingLocationWeeklyScheduleMutationInput;
}

export interface IUpsertFocusTimeOnMutationArguments {
  input: IUpsertFocusTimeMutationInput;
}

export interface IUpsertLunchOnMutationArguments {
  input: IUpsertLunchMutationInput;
}

export interface IUpsertMeetingReliefOnMutationArguments {
  input: IUpsertMeetingReliefMutationInput;
}

export interface IUpsertNonUserDefragEmailResponseOnMutationArguments {
  input: IUpsertNonUserDefragEmailResponseInput;
}

export interface IUpsertPreferredWorkOnMutationArguments {
  input: IUpsertPreferredWorkMutationInput;
}

export interface IUpsertTravelTimeOnMutationArguments {
  input: IUpsertTravelTimeMutationInput;
}

export interface IUserInteractedWithNotificationOnMutationArguments {
  input: IUserInteractedWithNotificationInput;
}

export interface IUserSurveyResponseMutationsOnMutationArguments {
  input: ISaveUserSurveyResponsesMutationInput;
}

export interface IUserSurveyResponseWrapperMutationsOnMutationArguments {
  input: ISaveUserSurveyResponseWrapperMutationInput;
}

export interface IValidateSlackChannelBySendingMessageOnMutationArguments {
  input: IValidateSlackChannelBySendingMessageMutationInput;
}

export interface IAddAdminForBillingGroupMutationInput {
  billingGroupId: string;
  clientMutationId?: string;
  orgRelayId: string;
  userId: string;
}

export interface IAddAdminForBillingGroupMutationPayload {
  __typename: "AddAdminForBillingGroupMutationPayload";
  clientMutationId: string;
}

export interface IAddAndRemoveOrgTagsMutationInput {
  calendarId?: string;
  clientMutationId?: string;
  eventParentRelayId: string;
  moveRangeV3?: IMoveRangeInLocalTimeV3Input;
  orgRelayId: string;
  tagNamesToRemove: Array<string>;
  tagsToAdd?: Array<ITagInput>;
}

export interface IMoveRangeInLocalTimeV3Input {
  allowedEndTimeInclusive?: IDayOffsetTimeSlotInput;
  allowedStartTimeInclusive?: IDayOffsetTimeSlotInput;
}

export interface IDayOffsetTimeSlotInput {
  dayOffset: number;
  timeSlot: TimeSlot;
}

export interface ITagInput {
  name: string;
  value?: string;
}

export interface IAddAndRemoveOrgTagsMutationPayload {
  __typename: "AddAndRemoveOrgTagsMutationPayload";
  clientMutationId: string;
  eventParent: IEventParent;
}

export interface IAddManualMembersForBillingGroupMutationInput {
  addExclusions?: boolean;
  billingGroupId: string;
  clientMutationId?: string;
  orgRelayId: string;
  personIds: Array<string>;
}

export interface IAddManualMembersForBillingGroupMutationPayload {
  __typename: "AddManualMembersForBillingGroupMutationPayload";
  clientMutationId: string;
}

export interface IAddOrgTagsMutationInput {
  calendarId?: string;
  clientMutationId?: string;
  debuggingReason?: string;
  eventParentRelayId: string;
  moveRangeV3?: IMoveRangeInLocalTimeV3Input;
  orgRelayId: string;
  tags?: Array<ITagInput>;
}

export interface IAddOrgTagsMutationPayload {
  __typename: "AddOrgTagsMutationPayload";
  clientMutationId: string;
  eventParent: IEventParent;
}

export interface IAddSlackAccountMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
  redirect: string;
  verificationCode: string;
}

export interface IAddSlackAccountMutationPayload {
  __typename: "AddSlackAccountMutationPayload";
  clientMutationId: string;
}

export interface IAddSlackAccountForTeamMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
  redirect: string;
  teamId: string;
  verificationCode: string;
}

export interface IAddSlackAccountForTeamMutationPayload {
  __typename: "AddSlackAccountForTeamMutationPayload";
  clientMutationId: string;
}

export interface IAddSlackAccountV2MutationInput {
  clientMutationId?: string;
  orgRelayId: string;
  redirect: string;
  verificationCode: string;
}

export interface IAddSlackAccountV2MutationPayload {
  __typename: "AddSlackAccountV2MutationPayload";
  clientMutationId: string;
}

export interface IAddZoomAccountMutationInput {
  clientMutationId?: string;

  /**
   * @default false
   */
  isUserLevelZoomAccount?: boolean;
  orgRelayId: string;
  redirect: string;
  verificationCode: string;
}

export interface IAddZoomAccountMutationPayload {
  __typename: "AddZoomAccountMutationPayload";
  clientMutationId: string;
}

export interface IBatchAddAndRemoveOrgTagsMutationInput {
  clientMutationId?: string;
  tagPairings?: Array<IBatchAddAndRemoveOrgTagsTagPairings>;
}

export interface IBatchAddAndRemoveOrgTagsTagPairings {
  calendarId?: string;
  eventParentRelayId: string;
  moveRangeV3?: IMoveRangeInLocalTimeV3Input;
  orgRelayId: string;
  tagNamesToRemove: Array<string>;
  tagsToAdd?: Array<ITagInput>;
}

export interface IBatchAddAndRemoveOrgTagsMutationPayload {
  __typename: "BatchAddAndRemoveOrgTagsMutationPayload";
  clientMutationId: string;
  eventParents: Array<IEventParent>;
}

export interface IBatchModifyAutopilotForEventsMutationInput {
  clientMutationId?: string;
  events?: Array<IAutopilotEventToModify>;
  orgRelayId: string;
}

/**
 * Events to modify
 */
export interface IAutopilotEventToModify {
  externalEventId: string;
  movementType: ModifyAutopilotForEventMovementTypeEnum;
}

export enum ModifyAutopilotForEventMovementTypeEnum {
  Day = "Day",
  None = "None",
  Week = "Week",
}

export interface IBatchModifyAutopilotForEventsMutationPayload {
  __typename: "BatchModifyAutopilotForEventsMutationPayload";
  clientMutationId: string;
}

export interface IBatchModifyFlexibilityInput {
  /**
   * Event ids that should be updated to day flexibility
   * @default []
   */
  dayFlexibleEvents: Array<string>;

  /**
   * Events that should be updated to day of week flexibility, and the allowed days for the flexibility
   * @default []
   */
  dayOfWeekFlexibleEvents: Array<IDayOfWeekFlexibleEvent>;

  /**
   * Event ids that should not be flexible
   * @default []
   */
  notFlexibleEvents: Array<string>;

  /**
   * The relay org id
   */
  orgRelayId?: string;

  /**
   * Event ids that should be updated to week flexibility
   * @default []
   */
  weekFlexibleEvents: Array<string>;
}

export interface IDayOfWeekFlexibleEvent {
  /**
   * The days that this event is allowed to move among
   */
  allowedDays: Array<DayOfWeek>;

  /**
   * The backend event id
   */
  eventId: string;
}

export interface ICancelChatProposalPayload {
  __typename: "CancelChatProposalPayload";

  /**
   * @deprecated "Use updatedHistory instead"
   */
  message: AssistantMessage;
  success: boolean;

  /**
   * The updated chat history, including the user message and processed assistant response
   */
  updatedHistory: IChatHistory;
}

/**
 * Either a text response, view events response, or a (re)scheduling proposal
 */
export type AssistantMessage =
  | IProposalResponse
  | ISchedulingLinksResponse
  | ITextResponse
  | IViewEventsResponse;

export interface ICancelEventDiffDetailsInput {
  diffId: string;

  /**
   * Whether or not to delete future events, just this instance or all instances
   */
  repeatingEventSaveOption?: RepeatingEventSaveOption;

  /**
   * Whether or not the event should be cancelled, false will set the action to unchanged and reverse the cancel
   */
  shouldCancel: boolean;
}

export enum RepeatingEventSaveOption {
  AllInstances = "AllInstances",
  ThisAndFutureInstances = "ThisAndFutureInstances",
  ThisInstanceOnly = "ThisInstanceOnly",
}

export interface ICancelEventDiffDetailsPayload {
  __typename: "CancelEventDiffDetailsPayload";
  message: AssistantMessage;
}

export interface ICancelProcessMessagePayload {
  __typename: "CancelProcessMessagePayload";

  /**
   * A text, viewEvents, or proposal response from the assistant
   * @deprecated "Use updatedHistory instead"
   */
  assistantMessage: AssistantMessage;

  /**
   * an id for the chat conversation
   */
  conversationId: string;
  debugInfo: string;

  /**
   * The updated chat history, including the user message and processed assistant response
   */
  updatedHistory: IChatHistory;

  /**
   * The user-submitted message
   * @deprecated "Use updatedHistory instead"
   */
  userMessage: IUserMessage;
}

export interface ISharedProposalInput {
  proposalId: string;
}

export interface ICancelSharedProposalPayload {
  __typename: "CancelSharedProposalPayload";
  proposal: ISchedulingProposal;
}

export interface ICancelTestSubscriptionsInput {
  clientMutationId?: string;
  orgRelayId: string;
}

export interface ICancelTestSubscriptionsPayload {
  __typename: "CancelTestSubscriptionsPayload";
  clientMutationId: string;
}

export interface IClearUserDataMutationInput {
  clientMutationId?: string;
}

export interface IClearUserDataMutationPayload {
  __typename: "ClearUserDataMutationPayload";
  clientMutationId: string;
}

export interface IConfirmChatMessageInput {
  /**
   * Optionally clear the chat history as part of confirming the proposal
   */
  clearHistory: boolean;
}

export interface IConfirmChatProposalPayload {
  __typename: "ConfirmChatProposalPayload";
  eventIds: Array<string>;

  /**
   * @deprecated "Use updatedHistory instead"
   */
  message: AssistantMessage;

  /**
   * The list of events that were updated as part of the confirmation
   */
  updatedEvents: Array<IUpdatedEvent>;

  /**
   * The updated chat history, including the user message and processed assistant response
   */
  updatedHistory: IChatHistory;
}

export interface IUpdatedEvent {
  __typename: "UpdatedEvent";
  eventDiffId: string;
  externalEventId: string;
  operation: OperationEnum;
  success: boolean;
  title: string;
}

export enum OperationEnum {
  ADD = "ADD",
  CANCEL = "CANCEL",
  EDIT_DETAILS = "EDIT_DETAILS",
  PROPOSE_NEW_TIME = "PROPOSE_NEW_TIME",
  RESCHEDULE = "RESCHEDULE",
  RSVP = "RSVP",
  UNCHANGED = "UNCHANGED",
}

export interface IConfirmSharedProposalPayload {
  __typename: "ConfirmSharedProposalPayload";
  proposal: ISchedulingProposal;
}

export interface ICreateDataSourceMutationInput {
  clientMutationId?: string;
  googleOfflineCode: string;

  /**
   * @default false
   */
  isPersonal?: boolean;

  /**
   * The front-end ID of the organization the work calendar is part of
   */
  orgRelayId: string;
}

export interface ICreateDataSourceMutationPayload {
  __typename: "CreateDataSourceMutationPayload";
  clientMutationId: string;

  /**
   * The new data source that was just created
   */
  dataSource: IDataSource;
  dataSourceAuthError: DataSourceAuthError;
  user: IUser;
}

export enum DataSourceAuthError {
  GenericError = "GenericError",
  InsufficientScopes = "InsufficientScopes",
  SameDomainError = "SameDomainError",
}

export interface ICreateOrgSubscriptionStripeInput {
  clientMutationId?: string;
  initialUsers: Array<string>;
  numSeats?: number;
  orgRelayId: string;
  recurrence: CreateOrgSubscriptionStripeRecurrenceEnum;
  stripePaymentMethodId: string;
}

export enum CreateOrgSubscriptionStripeRecurrenceEnum {
  Every2Years = "Every2Years",
  Every3Years = "Every3Years",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  SemiAnnual = "SemiAnnual",
  Yearly = "Yearly",
}

export interface ICreateOrgSubscriptionStripePayload {
  __typename: "CreateOrgSubscriptionStripePayload";
  clientMutationId: string;
  stripeError: IStripeError;
}

/**
 * Stripe error
 */
export interface IStripeError {
  __typename: "StripeError";
  code: string;
  message: string;
}

export interface ICreateTeamMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
  setAsPrimary?: boolean;
  teamMembers: Array<ITeamRoleAndPersonIdInput>;
  teamName: string;
  teamType: TeamType;
}

export interface ITeamRoleAndPersonIdInput {
  personId: string;
  role: TeamMemberRole;
}

export enum TeamType {
  Managerial = "Managerial",
  Other = "Other",
}

export interface ICreateTeamMutationPayload {
  __typename: "createTeamMutationPayload";
  clientMutationId: string;
  team: ITeam;
}

export interface ICreateTeamAndTeamSubscriptionStripeInput {
  clientMutationId?: string;
  orgRelayId: string;
  personId: string;
  seatCount: number;
  stripePaymentMethodId: string;
  subscriptionInterval: SubscriptionInterval;
  userEmail: string;
}

export enum SubscriptionInterval {
  Monthly = "Monthly",
  Yearly = "Yearly",
}

export interface ICreateTeamAndTeamSubscriptionStripePayload {
  __typename: "CreateTeamAndTeamSubscriptionStripePayload";
  clientMutationId: string;
  stripeError: IStripeError;
  team: ITeam;
}

export interface ICreateTeamCalendarMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
  teamId: string;
}

export interface ICreateTeamCalendarMutationPayload {
  __typename: "createTeamCalendarMutationPayload";
  clientMutationId: string;
}

export interface ICreateTeamSubscriptionStripeInput {
  clientMutationId?: string;
  orgRelayId: string;
  seatCount: number;
  stripePaymentMethodId: string;
  subscriptionInterval: SubscriptionInterval;
  teamId: string;
}

export interface ICreateTeamSubscriptionStripePayload {
  __typename: "CreateTeamSubscriptionStripePayload";
  clientMutationId: string;
  stripeError: IStripeError;
  team: ITeam;
}

export interface ICreateTestSubscriptionStripeInput {
  clientMutationId?: string;
  initialUsers: Array<string>;
  orgRelayId: string;
  paidProductType: CreateTestSubscriptionPaidProductTypeEnum;
}

export enum CreateTestSubscriptionPaidProductTypeEnum {
  Business_NonStandard = "Business_NonStandard",
  Business_Standard = "Business_Standard",
  Enterprise_NonStandard = "Enterprise_NonStandard",
  Enterprise_Standard = "Enterprise_Standard",
  Pro_NonStandard = "Pro_NonStandard",
  Pro_Standard = "Pro_Standard",
}

export interface ICreateTestSubscriptionStripePayload {
  __typename: "CreateTestSubscriptionStripePayload";
  clientMutationId: string;
  stripeError: IStripeError;
}

export interface ICreateWorkingLocationMutationInput {
  address: string;
  clientMutationId?: string;
  locationType: LocationTypeEnum;
  name: string;
  timeZone: string;
}

export interface ICreateWorkingLocationMutationPayload {
  __typename: "CreateWorkingLocationMutationPayload";
  clientMutationId: string;
}

export interface IDeactivateUserMutationInput {
  clientMutationId?: string;
}

export interface IDeactivateUserMutationPayload {
  __typename: "DeactivateUserMutationPayload";
  clientMutationId: string;
  user: IUser;
  viewer: IViewer;
}

export interface IDeleteAsanaCredentialsInput {
  clientMutationId?: string;

  /**
   * org id
   */
  orgRelayId: string;
}

export interface IDeleteAsanaCredentialsPayload {
  __typename: "DeleteAsanaCredentialsPayload";
  clientMutationId: string;
}

export interface IDeleteDataSourceInput {
  clientMutationId?: string;

  /**
   * The relay ID of the organization the calendar is part of
   */
  orgRelayId: string;

  /**
   * The ID of the calendar that is being deleted
   */
  sourceCalId: string;
}

export interface IDeleteDataSourcePayload {
  __typename: "DeleteDataSourcePayload";
  clientMutationId: string;
}

export interface IDeleteEventMutationInput {
  calendarId: string;
  clientMutationId?: string;
  emailAddress: string;
  externalEventId: string;

  /**
   * optional, when true will delete an entire recurrence
   * @default false
   */
  isRecurring?: boolean;

  /**
   * Whether or not to delete future events, just this instance or all instances
   */
  repeatingDeleteOption?: RepeatingEventSaveOption;
}

export interface IDeleteEventMutationPayload {
  __typename: "DeleteEventMutationPayload";
  clientMutationId: string;
}

export interface IDeleteTeamMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
  teamId: string;
}

export interface IDeleteTeamMutationPayload {
  __typename: "deleteTeamMutationPayload";
  clientMutationId: string;
  deletedTeamId: string;
}

export interface IDeleteUserLevelZoomAccountMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
}

export interface IDeleteUserLevelZoomAccountMutationPayload {
  __typename: "DeleteUserLevelZoomAccountMutationPayload";
  clientMutationId: string;
}

export interface IDeleteWorkingLocationMutationInput {
  clientMutationId?: string;
  workingLocationId: string;
}

export interface IDeleteWorkingLocationMutationPayload {
  __typename: "DeleteWorkingLocationMutationPayload";
  clientMutationId: string;
}

export interface IDeleteZoomAccountMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
  zoomAccountId: string;
}

export interface IDeleteZoomAccountMutationPayload {
  __typename: "DeleteZoomAccountMutationPayload";
  clientMutationId: string;
  deletedZoomAccountId: string;
}

export interface IDisableDataSourceSyncInput {
  clientMutationId?: string;

  /**
   * The relay ID of the organization the calendar is part of
   */
  orgRelayId: string;

  /**
   * The ID of the calendar that is being disabled
   */
  sourceCalId: string;
}

export interface IDisableDataSourceSyncPayload {
  __typename: "DisableDataSourceSyncPayload";
  clientMutationId: string;
  dataSource: IDataSource;
}

export interface IDisambiguateAttendeeInput {
  attendeeString: string;

  /**
   * The calendar id of the selected attendee
   */
  calendarId: any;
  diffId: string;
}

export interface IDisambiguateProposalAttendeePayload {
  __typename: "DisambiguateProposalAttendeePayload";
  message: AssistantMessage;
}

export interface IEditExistingEventInput {
  attendees?: Array<IAttendeesForEditEvent>;
  category?: ITagInput;

  /**
   * Type of conference for the event. Removed is selected if the users explicitly removed the conference
   */
  conferenceType?: EventConferenceType;

  /**
   * Description of the event
   */
  description?: string;

  /**
   * The externaleventId of the event you are editing
   */
  externalEventId: string;
  flexUpdates?: IUpdateEventFlexibility;

  /**
   * Whether the event is an all day event
   */
  isAllDay?: boolean;

  /**
   * Toggle whether the event is flexible or not
   */
  isFlexible?: boolean;

  /**
   * Location of the event
   */
  location?: string;

  /**
   * The calendarId of the organizer
   */
  organizerCalendarId: string;
  recurrence?: IEventRecurrenceInput;

  /**
   * Whether or not to save future events, just this instance or all instances
   */
  repeatingEventSaveOption: RepeatingEventSaveOption;

  /**
   * Invite response status of attendee
   */
  responseStatus?: ResponseStatusEnum;

  /**
   * Mark as true if you want others to be notified of their event changing
   */
  sendNotifications: boolean;

  /**
   * Interval of Event (start/end time)
   */
  time?: any;

  /**
   * Title of the event
   */
  title?: string;
}

/**
 * Necessary attendee information to edit an event
 */
export interface IAttendeesForEditEvent {
  /**
   * CalendarId of attendee
   */
  calendarId: string;

  /**
   * Whether the attendee is optional
   */
  isOptional?: boolean;

  /**
   * Whether the attendee is the organizer of the meeting
   */
  isOrganizer: boolean;

  /**
   * Invite response status of attendee
   */
  responseStatus: ResponseStatusEnum;
}

export enum EventConferenceType {
  Meet = "Meet",
  Removed = "Removed",
  TeamsForBusiness = "TeamsForBusiness",
  Zoom = "Zoom",
}

export interface IUpdateEventFlexibility {
  dayRangeFlexibility: IUpdateDayRangeFlexibility;
  eventId: any;

  /**
   * @default {"roomChangePolicy":"Unchanged"}
   */
  roomFlexibility: IUpdateRoomFlexibility;

  /**
   *
   *           Update the time of day flexibility, if provided. 'null' indicates that the time of day flexibility should remain unchanged
   *
   *           The start & end values each take an 'Unbounded' option, this indicates that the time of day flexibility won't be explicitly
   *           bounded by any set time, and will be implicitly bounded by the attendees meeting hours.
   *
   */
  timeOfDayFlexibility?: IUpdateTimeOfDayFlexibility;
}

export interface IUpdateDayRangeFlexibility {
  /**
   *
   *                 Allowed days for the DayOfWeek flexibility.
   *                 Should be provided if and only if the flexibility type is 'DayOfWeek'
   *
   * @default null
   */
  allowedDays?: Array<DayOfWeek>;
  type: DayRangeFlexibilityType;
}

export enum DayRangeFlexibilityType {
  Day = "Day",

  /**
   *
   *                       Set the event flexibility to move within specific days of the week.
   *                       Requires setting the 'allowedDays' also in the mutation.
   *
   */
  DayOfWeek = "DayOfWeek",
  Week = "Week",
}

export interface IUpdateRoomFlexibility {
  roomChangePolicy: RoomChangePolicy;
}

export enum RoomChangePolicy {
  CanChangeRooms = "CanChangeRooms",
  CannotChangeRooms = "CannotChangeRooms",

  /**
   * Don't change the room flexibility
   */
  Unchanged = "Unchanged",
}

export interface IUpdateTimeOfDayFlexibility {
  allowedEnd: UpdateTimeOfDayTimeSlot;
  allowedStart: UpdateTimeOfDayTimeSlot;
}

export enum UpdateTimeOfDayTimeSlot {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
  Unbounded = "Unbounded",
}

/**
 * Modifications to recurrence rules for an event
 */
export interface IEventRecurrenceInput {
  /**
   * string representing the day(s) the recurrence occurs on.
   *       MO,TU,WE,TH,FR indicates all workdays,
   *       2TU represents the second Tuesday of the month.
   */
  byDay?: string;

  /**
   * Datetime of startTime
   */
  eventStartTime: any;

  /**
   * Interval of the event recurrence
   */
  intervalCount?: number;

  /**
   * Type of the event recurrence
   */
  intervalType?: RecurrenceIntervalTypeEnum;

  /**
   * Recurrence rule for the event
   */
  recurrenceRule?: any;

  /**
   * Standard IANA timezone string
   */
  timeZone: string;
}

export interface IEditExistingEventPayload {
  __typename: "EditExistingEventPayload";
  updatedEventParent: IEventParent;
}

export interface IEnableDataSourceSyncInput {
  clientMutationId?: string;

  /**
   * The front-end ID of the organization the work calendar is part of
   */
  orgRelayId: string;

  /**
   * The ID of the calendar that is being synced to the work calendar
   */
  sourceCalId: string;
}

export interface IEnableDataSourceSyncPayload {
  __typename: "EnableDataSourceSyncPayload";
  clientMutationId: string;
  dataSource: IDataSource;
}

export interface IEnableOrDisableTeamSyncForEventInput {
  calendarId: string;
  clientMutationId?: string;
  disable: boolean;
  externalEventId: string;
  orgRelayId: string;
}

export interface IEnableOrDisableTeamSyncForEventPayload {
  __typename: "EnableOrDisableTeamSyncForEventPayload";
  clientMutationId: string;
  disabled: boolean;
}

export interface IExecuteCliCommandMutationInput {
  clientMutationId?: string;
  command: string;
  options?: Array<ICliOptionInput>;
}

export interface ICliOptionInput {
  name: string;
  values: Array<string>;
}

export interface IExecuteCliCommandMutationPayload {
  __typename: "ExecuteCliCommandMutationPayload";
  clientMutationId: string;
  result: string;
}

export interface IExtendTrialInput {
  clientMutationId?: string;
  endDate: string;
}

export interface IExtendTrialPayload {
  __typename: "ExtendTrialPayload";
  clientMutationId: string;
  trialMetadata: ITrialMetadata;
}

export interface IInstallSlackAppMutationInput {
  clientMutationId?: string;
  installSlackAppCode: string;
  redirect: string;

  /**
   * @default false
   */
  useOAuthV2: boolean;
}

export interface IInstallSlackAppMutationPayload {
  __typename: "InstallSlackAppMutationPayload";
  clientMutationId: string;
  installSlackAppResult: boolean;
  viewer: IViewer;
}

export interface IInvitePersonsToTeamInput {
  clientMutationId?: string;
  orgRelayId: string;
  personIds: Array<string>;
  teamId: string;
}

export interface IInvitePersonsToTeamPayload {
  __typename: "InvitePersonsToTeamPayload";
  clientMutationId: string;
}

export interface IInviteToBillingGroupMutationInput {
  billingGroupId: string;
  clientMutationId?: string;
  orgRelayId: string;
  personIds: Array<string>;
}

export interface IInviteToBillingGroupMutationPayload {
  __typename: "InviteToBillingGroupMutationPayload";
  clientMutationId: string;
}

export interface ILoginMicrosoftUserMutationInput {
  clientMutationId?: string;
  microsoftAuthCode: string;
  redirectUri: string;
}

export interface ILoginMicrosoftUserMutationPayload {
  __typename: "LoginMicrosoftUserMutationPayload";
  clientMutationId: string;
  loginStatus: LoginStatus;
  operation: RefreshTokenResultOperationEnum;
  orgOperation: OrganizationOperationResultEnum;
  verifyCodeMessage: string;
  verifyCodeResult: boolean;
  viewer: IViewer;
}

export enum LoginStatus {
  BlockedByAdmin = "BlockedByAdmin",
  Deactivating = "Deactivating",
  GoogleApiAccessDisabled = "GoogleApiAccessDisabled",
  GoogleContactSharingDisabled = "GoogleContactSharingDisabled",
  InsufficientScopes = "InsufficientScopes",
  NoCrawl = "NoCrawl",
  PersonalEmail = "PersonalEmail",
  SecondaryDomainUsed = "SecondaryDomainUsed",
  Success = "Success",
  WaitlistedEmail = "WaitlistedEmail",
}

export enum RefreshTokenResultOperationEnum {
  Create = "Create",
  Fetch = "Fetch",
}

export enum OrganizationOperationResultEnum {
  Add = "Add",
  Create = "Create",
  Delete = "Delete",
  Fetch = "Fetch",
}

export interface ILoginUserMutationInput {
  clientMutationId?: string;
  deviceIdAndRegistrationToken?: IDeviceIdAndRegistrationToken;
  googleOfflineCode: string;
  verificationType?: VerificationType;
}

export interface IDeviceIdAndRegistrationToken {
  /**
   * A unique id that identifies the calling device
   */
  deviceId: string;

  /**
   * The registration token of the device that allows sending push notifications
   */
  registrationToken: string;
}

export interface ILoginUserMutationPayload {
  __typename: "LoginUserMutationPayload";
  clientMutationId: string;
  loginStatus: LoginStatus;
  operation: RefreshTokenResultOperationEnum;
  orgOperation: OrganizationOperationResultEnum;
  verifyCodeMessage: string;
  verifyCodeResult: boolean;
  viewer: IViewer;
}

export interface ILogoutInsightsUserInput {
  clientMutationId?: string;
}

export interface ILogoutInsightsUserPayload {
  __typename: "LogoutInsightsUserPayload";
  clientMutationId: string;
}

export interface ILogoutUserMutationInput {
  clientMutationId?: string;
}

export interface ILogoutUserMutationPayload {
  __typename: "LogoutUserMutationPayload";
  clientMutationId: string;
  user: IUser;
  viewer: IViewer;
}

export interface IMarkNudgeAsViewedInput {
  /**
   * The unique identifier of the nudge
   */
  nudgeKey: string;

  /**
   * The nudge type
   */
  nudgeType: string;

  /**
   * The graph id of the org
   */
  orgId: string;
}

export interface IMarkNudgeAsViewedPayload {
  __typename: "MarkNudgeAsViewedPayload";
  success: boolean;
}

export interface IMarkDefragValueKnobsAsAcceptedMutationInput {
  clientMutationId?: string;
  knobUrns: Array<IUrnInput>;
  orgRelayId: string;
}

export interface IUrnInput {
  id: string;
  scope?: IUrnInput;
  type?: UrnType;
  value?: string;
}

export interface IMarkDefragValueKnobsAsAcceptedMutationPayload {
  __typename: "MarkDefragValueKnobsAsAcceptedMutationPayload";
  clientMutationId: string;
}

export interface IMarkDefragValueKnobsAsDismissedMutationInput {
  clientMutationId?: string;
  knobUrns: Array<IUrnInput>;
  orgRelayId: string;
}

export interface IMarkDefragValueKnobsAsDismissedMutationPayload {
  __typename: "MarkDefragValueKnobsAsDismissedMutationPayload";
  clientMutationId: string;
}

export interface IMarkDefragValueKnobsAsPresentedMutationInput {
  clientMutationId?: string;
  knobUrns: Array<IUrnInput>;
  orgRelayId: string;
}

export interface IMarkDefragValueKnobsAsPresentedMutationPayload {
  __typename: "MarkDefragValueKnobsAsPresentedMutationPayload";
  clientMutationId: string;
}

export interface IMaybeOnboardOrgMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
}

export interface IMaybeOnboardOrgMutationPayload {
  __typename: "MaybeOnboardOrgMutationPayload";
  clientMutationId: string;
  crawlTest: OrgCrawlTest;
}

export interface IOverrideSyncStatusForUserEventMutationInput {
  clientMutationId?: string;
  externalEventId: string;
}

export interface IOverrideSyncStatusForUserEventMutationPayload {
  __typename: "OverrideSyncStatusForUserEventMutationPayload";
  clientMutationId: string;
}

export interface IPatchBillingGroupMutationInput {
  billingGroupId: string;
  clientMutationId?: string;
  invitePolicy: InvitePolicyEnum;
  name?: string;
  orgRelayId: string;
}

export interface IPatchBillingGroupMutationPayload {
  __typename: "PatchBillingGroupMutationPayload";
  clientMutationId: string;
}

export interface IPatchSlackSettingsMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
  slackSettings: ISlackSettingsInput;
}

export interface ISlackSettingsInput {
  calendarStatus?: ISlackCalendarStatusSyncInput;
  calendarStatusEnabled: boolean;
  dailyDigestSettings?: ISlackDailyDigestSettingsInput;
  dndSettings?: ISlackDndSettingsInput;
  id: string;
  includeMeetingCategory: boolean;
  includeMeetingTitle: boolean;
  notificationSettings?: ISlackNotificationSettingsInput;
  slackTeamId: string;
  slackUserId: string;
}

export interface ISlackCalendarStatusSyncInput {
  calendarStatusEnabled: boolean;
  includeMeetingCategory: boolean;
  includeMeetingTitle: boolean;
}

export interface ISlackDailyDigestSettingsInput {
  enabled: boolean;
  timeSlot: DigestTimeSlot;
}

export interface ISlackDndSettingsInput {
  focusTime: boolean;
  meeting: boolean;
}

export interface ISlackNotificationSettingsInput {
  defragSummary: boolean;
}

export interface IPatchSlackSettingsMutationPayload {
  __typename: "PatchSlackSettingsMutationPayload";
  clientMutationId: string;
  slackSettings: ISlackSettings;
}

export interface IPauseHoldInput {
  /**
   * The event id of the hold to protect
   */
  holdId: any;

  /**
   * The graph id of the org
   */
  orgId: string;
}

export interface IPauseHoldPayload {
  __typename: "PauseHoldPayload";
  success: boolean;
  updatedEvent: IEventParent;
}

export interface IPreAddOrgTagsMutationInput {
  clientMutationId?: string;
  moveRangeV3?: IMoveRangeInLocalTimeV3Input;
  orgRelayId: string;
  preAnnotation: IPreAnnotationInput;
}

export interface IPreAnnotationInput {
  attendees: Array<string>;
  calendarId: string;
  description?: string;
  endTime: IInferredEventTimeInput;
  startTime: IInferredEventTimeInput;
  tags: Array<ITagInput>;
  title?: string;
}

export interface IInferredEventTimeInput {
  date: string;
  time: string;
  timeZone?: string;
}

export interface IPreAddOrgTagsMutationPayload {
  __typename: "PreAddOrgTagsMutationPayload";
  clientMutationId: string;
}

export interface IPreSetFlexibilityInput {
  calendarId: any;
  orgRelayId: string;

  /**
   * Event flexibility configuration
   */
  preEventFlexibility: IPreEventFlexibility;

  /**
   * A set of event properties that allows us to identify an event without its id
   */
  preEventKey: IPreEventKey;
}

export interface IPreEventFlexibility {
  roomFlexibility: PreRoomFlexibility;

  /**
   * @default "Other"
   */
  scheduledBy: EventScheduledBy;
  timeOfDayFlexibility: IMoveRangeInLocalTimeV3Input;
  timeRangeFlexibility: IPreTimeRangeFlexibility;
}

export enum PreRoomFlexibility {
  KeepCurrentRoom = "KeepCurrentRoom",
  SimilarRoomsAllowed = "SimilarRoomsAllowed",
}

export enum EventScheduledBy {
  /**
   * The event was scheduled using Clockwise, and Clockwise should record the event as scheduled by the app
   */
  Clockwise = "Clockwise",
  Other = "Other",
}

/**
 *
 *                         OneOf RangeFlexibility or DayOfWeekFlexibility.
 *                         Because we don't have oneOf support yet, this is modelled as nullable fields, where exactly one field must be non-null
 *                         This is unfortunately validated at runtime, but it's the best we have for now.
 *
 */
export interface IPreTimeRangeFlexibility {
  dayOfWeekFlexibility?: IPreDayOfWeekFlexibility;
  rangeFlexibility?: PreRangeFlexibility;
}

export interface IPreDayOfWeekFlexibility {
  allowedDays: Array<DayOfWeek>;
}

export enum PreRangeFlexibility {
  Day = "Day",
  Week = "Week",
}

export interface IPreEventKey {
  attendees: Array<any>;
  endTime: IInferredEventTimeInput;
  startTime: IInferredEventTimeInput;

  /**
   * The event title, null if the event doesn't have a title
   */
  title?: string;
}

export interface IPreSetFlexibilityResponse {
  __typename: "PreSetFlexibilityResponse";
  successful: boolean;
}

export interface IProcessChatMessageInput {
  /**
   * User message direct CQL use in special cases to bypass gpt
   */
  cql?: string;
  mentionsV2: IMentionsV2;
  text: string;
}

export interface IMentionsV2 {
  eventMentions: Array<IEventMention>;
  personMentions: Array<IPersonMention>;
}

export interface IEventMention {
  externalEventId: string;

  /**
   * ISO string from startTime.dateOrDateTime
   */
  startTime: string;
  title: string;
}

export interface IPersonMention {
  /**
   * The calendarId of the person mentioned. Acts as a unique identifier
   */
  calendarId: any;

  /**
   * The name (given + family) of the person in the mention. String matches what was seen in chat
   */
  name: string;
}

export interface IProcessMessagePayload {
  __typename: "ProcessMessagePayload";

  /**
   * A text, viewEvents, or proposal response from the assistant
   * @deprecated "Use updatedHistory instead"
   */
  assistantMessage: AssistantMessage;

  /**
   * an id for the chat conversation
   */
  conversationId: string;
  debugInfo: string;

  /**
   * The updated chat history, including the user message and processed assistant response
   */
  updatedHistory: IChatHistory;

  /**
   * The user-submitted message
   * @deprecated "Use updatedHistory instead"
   */
  userMessage: IUserMessage;
}

export interface IProtectHoldInput {
  /**
   * The event id of the hold to protect
   */
  holdId: any;

  /**
   * The graph id of the org
   */
  orgId: string;
}

export interface IProtectedHoldPayload {
  __typename: "ProtectedHoldPayload";
  success: boolean;
  updatedEvent: IEventParent;
}

export interface IRecordChromeInstallMutationInput {
  clientMutationId?: string;
}

export interface IRecordChromeInstallMutationPayload {
  __typename: "RecordChromeInstallMutationPayload";
  clientMutationId: string;
}

export interface IRecordChromeUninstallMutationInput {
  clientMutationId?: string;
}

export interface IRecordChromeUninstallMutationPayload {
  __typename: "RecordChromeUninstallMutationPayload";
  clientMutationId: string;
}

export interface IRecordDefragResponseMutationInput {
  clientMutationId?: string;
  feedback?: DefragFeedback;
  orgId: string;
  otherReason?: string;
  preDefinedReasons?: Array<string>;
  responseKeys: Array<any>;
}

export enum DefragFeedback {
  LooksGood = "LooksGood",
  Maybe = "Maybe",
  Meh = "Meh",
}

export interface IRecordDefragResponseMutationPayload {
  __typename: "RecordDefragResponseMutationPayload";
  clientMutationId: string;
  response: boolean;
}

export interface IRecordImpressionMutationInput {
  clientMutationId?: string;
  name: string;
  treatmentType: string;
}

export interface IRecordImpressionMutationPayload {
  __typename: "RecordImpressionMutationPayload";
  clientMutationId: string;
}

export interface IRegisterEmailUpdatesInput {
  clientMutationId?: string;
  email: string;
}

export interface IRegisterEmailUpdatesPayload {
  __typename: "RegisterEmailUpdatesPayload";
  clientMutationId: string;
}

export interface IRemoveAdminForBillingGroupMutationInput {
  billingGroupId: string;
  clientMutationId?: string;
  forceDelete?: boolean;
  orgRelayId: string;
  userId: string;
}

export interface IRemoveAdminForBillingGroupMutationPayload {
  __typename: "RemoveAdminForBillingGroupMutationPayload";
  clientMutationId: string;
}

export interface IRemoveDiffFromProposalInput {
  diffId: string;
}

export interface IRemoveDiffFromProposalPayload {
  __typename: "RemoveDiffFromProposalPayload";
  message: AssistantMessage;
}

export interface IRemoveGoalMutationInput {
  clientMutationId?: string;
  goal: IGoalInput;
  orgId: string;
}

export interface IGoalInput {
  goalId: string;
  id: string;
  isDeleted: boolean;
  name: string;
  options?: IGoalOptionsInput;
  otherUrns: Array<IUrnInput>;
  targetUrn: IUrnInput;
  valueUrn?: IUrnInput;
}

export interface IGoalOptionsInput {
  syncToGoogle: boolean;
}

export interface IRemoveGoalMutationPayload {
  __typename: "RemoveGoalMutationPayload";
  clientMutationId: string;
  goal: IGoal;
}

export interface IRemoveLunchHoldMutationInput {
  calendarId?: string;
  clientMutationId?: string;
  isPaused?: boolean;
  lunchEventId: string;
  orgRelayId: string;
}

export interface IRemoveLunchHoldMutationPayload {
  __typename: "RemoveLunchHoldMutationPayload";
  clientMutationId: string;
}

export interface IRemoveManualMembersForBillingGroupMutationInput {
  addExclusions?: boolean;
  billingGroupId: string;
  clientMutationId?: string;
  orgRelayId: string;
  personIds: Array<string>;
}

export interface IRemoveManualMembersForBillingGroupMutationPayload {
  __typename: "RemoveManualMembersForBillingGroupMutationPayload";
  clientMutationId: string;
}

export interface IRemoveOrgTagsMutationInput {
  clientMutationId?: string;
  eventParentRelayId: string;
  orgRelayId: string;
  tagNames: Array<string>;
}

export interface IRemoveOrgTagsMutationPayload {
  __typename: "RemoveOrgTagsMutationPayload";
  clientMutationId: string;
  eventParent: IEventParent;
}

export interface IRescheduleEventParentMutationInput {
  clientMutationId?: string;
  eventTime: IEventTimeInput;
  externalEventId: string;
  orgRelayId: string;
}

export interface IRescheduleEventParentMutationPayload {
  __typename: "RescheduleEventParentMutationPayload";
  clientMutationId: string;
  eventParent: IEventParent;
}

export interface IResetConversationPayload {
  __typename: "ResetConversationPayload";
  success: boolean;
  text: string;
}

export interface IResolveConflictInput {
  /**
   * The action to resolve the conflict
   */
  actionToResolve: ConflictAction;
  clusterId: string;
  eventId: string;

  /**
   * The new start time for the event
   */
  newStartTime?: any;
}

export interface IResolveConflictPayload {
  __typename: "ResolveConflictPayload";
  eventId: string;
  message: AssistantMessage;
}

export interface ISaveAddEventDiffDetailsInput {
  attendees: Array<any>;
  attendeesList?: Array<IAttendeesListSaveAddEventDiff>;

  /**
   * Type of video conference used for the event
   */
  conferenceType?: ConferencingType;
  description: string;
  diffId: string;
  flexDetails?: IProposalFlexibilityInput;

  /**
   * Whether the event is an all day event
   */
  isAllDay?: boolean;
  isFlexible?: boolean;
  location: string;
  recurrence?: IProposalRecurrenceInput;
  time: any;
  title: string;
}

export interface IAttendeesListSaveAddEventDiff {
  calendarId: any;
  optional: boolean;
}

/**
 * Flexibility settings of a proposal event
 */
export interface IProposalFlexibilityInput {
  /**
   * Whether the event is marked as flex
   */
  isFlexible: boolean;

  /**
   * How an event can move within a day, null if not configured
   */
  timeOfDayFlexibility?: IProposalTimeOfDayFlexInput;

  /**
   * How an event can move between days
   */
  timeRangeFlexibility?: IProposalTimeRangeFlexibilityInput;
}

export interface IProposalTimeOfDayFlexInput {
  /**
   * End of allowed time range for moving an event, null if not bounded by an explicit end time
   */
  endTime?: any;

  /**
   * Beginning of allowed time range for moving an event, null if not bounded by an explicit start time
   */
  startTime?: any;
}

export interface IProposalTimeRangeFlexibilityInput {
  allowedDays: Array<DayOfWeek>;
  range?: FlexibilityRange;
}

/**
 * Modifications to recurrence rules for an event
 */
export interface IProposalRecurrenceInput {
  /**
   * Interval of the event recurrence
   */
  intervalCount?: number;

  /**
   * Type of the event recurrence
   */
  intervalType?: RecurrenceIntervalTypeEnum;

  /**
   * Recurrence rule for the event
   */
  recurrenceRule?: any;
}

export interface ISaveAddEventDiffDetailsPayload {
  __typename: "SaveAddEventDiffDetailsPayload";
  message: AssistantMessage;
}

export interface ISaveAsanaCredentialsInput {
  clientMutationId?: string;

  /**
   * Code recieved from Asana via redirect uri upon successful authentication.
   */
  code: string;

  /**
   * org id
   */
  orgRelayId: string;

  /**
   * Redirect uri generated and used by the frontend for Oath. Required for future token exchange.
   */
  redirectUri: string;
}

export interface ISaveAsanaCredentialsPayload {
  __typename: "SaveAsanaCredentialsPayload";
  clientMutationId: string;
}

export interface ISaveFlexStatusInput {
  diffId: string;
  isFlexible?: boolean;
}

export interface ISaveFlexStatusPayload {
  __typename: "SaveFlexStatusPayload";
  message: AssistantMessage;
}

export interface ISaveModifyEventDiffDetailsInput {
  attendees: Array<any>;
  attendeesList?: Array<IAttendeesListSaveModifyEventDiff>;

  /**
   * Type of video conference used for the event
   */
  conferenceType?: ConferencingType;
  description: string;
  diffId: string;
  flexDetails?: IProposalFlexibilityInput;

  /**
   * Whether the event is an all day event
   */
  isAllDay?: boolean;
  isFlexible?: boolean;
  location: string;
  recurrence?: IProposalRecurrenceInput;

  /**
   * Whether or not to save future events or just this instance
   */
  repeatingEventSaveOption: RepeatingEventSaveOption;
  rsvp: ResponseStatusEnum;
  time: any;
  title: string;
}

export interface IAttendeesListSaveModifyEventDiff {
  calendarId: any;
  optional: boolean;
}

export interface ISaveModifyEventDiffDetailsPayload {
  __typename: "SaveModifyEventDiffDetailsPayload";
  message: AssistantMessage;
}

export interface ISavePushServiceSubscriptionMutationInput {
  /**
   * An authentication secret
   */
  auth: string;
  clientMutationId?: string;

  /**
   * A string containing the endpoint associated with the push subscription.
   */
  endpoint: string;

  /**
   * The client public key, which will be sent to the server and used in encrypting push message data
   */
  publicKey: string;
}

export interface ISavePushServiceSubscriptionMutationPayload {
  __typename: "SavePushServiceSubscriptionMutationPayload";
  clientMutationId: string;
}

export interface ISaveSharedProposalEventDiffDetailsInput {
  attendees: Array<any>;
  attendeesList?: Array<IAttendeesListSharedProposalSaveAddEventDiff>;

  /**
   * Type of video conference used for the event
   */
  conferenceType?: ConferencingType;
  description: string;
  diffId: string;
  flexDetails?: IProposalFlexibilityInput;

  /**
   * Whether the event is an all-day event
   */
  isAllDay?: boolean;
  isFlexible?: boolean;
  location: string;
  proposalId: string;
  recurrence?: IProposalRecurrenceInput;
  time: any;
  title: string;
}

export interface IAttendeesListSharedProposalSaveAddEventDiff {
  calendarId: any;
  optional: boolean;
}

export interface ISaveSharedProposalEventDiffDetailsPayload {
  __typename: "SaveSharedProposalEventDiffDetailsPayload";
  proposal: ISchedulingProposal;
}

export interface ISaveTeamSettingsMutationInput {
  clientMutationId?: string;
  manageTeamCalendar?: boolean;
  minimumMinutesForSyncing?: number;
  noMeetingDayEmailOnScheduleOver?: boolean;
  noMeetingDayOfWeek?: NoMeetingDayOfWeek;
  orgRelayId: string;
  slackChannelId?: string;
  slackChannelName?: string;
  slackNotificationEnabled?: boolean;
  slackNotificationTimeSlot?: TimeSlotEnum;
  syncWFHEvents?: boolean;
  teamId: string;
  useNoMeetingDay?: boolean;
}

export interface ISaveTeamSettingsMutationPayload {
  __typename: "saveTeamSettingsMutationPayload";
  clientMutationId: string;
  teamSettings: ITeamSettings;
}

export interface ISaveTeamUserSettingsMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
  teamId: string;
  useNoMeetingDay?: boolean;
  useTeamCalendar?: boolean;
}

export interface ISaveTeamUserSettingsMutationPayload {
  __typename: "saveTeamUserSettingsMutationPayload";
  clientMutationId: string;
  teamUserSettings: ITeamUserSettings;
}

export interface ISaveUninstallResponseMutationInput {
  clientMutationId?: string;
  considerClockwiseFuture?: string;
  more?: string;
  name?: string;
  reason?: string;
}

export interface ISaveUninstallResponseMutationPayload {
  __typename: "SaveUninstallResponseMutationPayload";
  clientMutationId: string;
}

export interface IScheduleNewEventV2MutationInput {
  /**
   * @default []
   */
  attendees?: Array<string>;
  calendarId: string;
  clientMutationId?: string;
  conferenceType?: ConferenceTypeEnum;
  conflictResolutionDefragId?: string;
  description?: string;
  includeZoomLink?: boolean;
  location?: string;
  orgRelayId: string;
  timeRange: ITimeRangeInput;
  timeZone: string;
  title?: string;
}

export enum ConferenceTypeEnum {
  Meet = "Meet",
  Removed = "Removed",
  TeamsForBusiness = "TeamsForBusiness",
  Zoom = "Zoom",
}

export interface ITimeRangeInput {
  endTime?: IEventTimeInput;
  id: string;
  startTime?: IEventTimeInput;
}

export interface IScheduleNewEventV2MutationPayload {
  __typename: "ScheduleNewEventV2MutationPayload";
  clientMutationId: string;
  eventParent: IEventParent;
}

export interface IScheduleNewSmartHoldMutationInput {
  calendarId: string;
  clientMutationId?: string;
  description?: string;
  orgRelayId: string;
  smartHoldType: SmartHoldTypeEnum;
  timeRange: ITimeRangeInput;
}

export enum SmartHoldTypeEnum {
  FocusTime = "FocusTime",
  Lunch = "Lunch",
  MeetingRelief = "MeetingRelief",
  MissingPriority = "MissingPriority",
  NoMeetingDay = "NoMeetingDay",
  OnboardingTestEvent = "OnboardingTestEvent",
  Pain = "Pain",
  ShadowCalendar = "ShadowCalendar",
  SyncedEvent = "SyncedEvent",
  TeamEvent = "TeamEvent",
  TravelTime = "TravelTime",
  TwoWaySync = "TwoWaySync",
}

export interface IScheduleNewSmartHoldMutationPayload {
  __typename: "ScheduleNewSmartHoldMutationPayload";
  clientMutationId: string;
  eventParent: IEventParent;
}

export interface ISelectSchedulingOptionInput {
  selectedIndex: number;
}

export interface ISelectSchedulingOptionPayload {
  __typename: "SelectSchedulingOptionPayload";
  message: AssistantMessage;
  updatedProposal: ISchedulingProposal;
}

export interface IGraphQLSelectSharedSchedulingOptionInput {
  proposalId: string;
  selectedIndex: number;
}

export interface ISelectSharedOptionPayload {
  __typename: "SelectSharedOptionPayload";
  proposal: ISchedulingProposal;
}

export interface ISendBillingGroupRequestEmailMutationInput {
  billingGroupId: string;
  clientMutationId?: string;
  orgRelayId: string;
  personId: string;
}

export interface ISendBillingGroupRequestEmailMutationPayload {
  __typename: "SendBillingGroupRequestEmailMutationPayload";
  clientMutationId: string;
}

export interface ISendM365TeamAvailabilityCalendarInviteMutationInput {
  clientMutationId?: string;
  personIds: Array<string>;
  teamId: string;
}

export interface ISendM365TeamAvailabilityCalendarInviteMutationPayload {
  __typename: "SendM365TeamAvailabilityCalendarInviteMutationPayload";
  clientMutationId: string;
}

export interface ISendM365TeamAvailabilityCalendarInvitesForTeamMutationInput {
  clientMutationId?: string;
  teamId: string;
}

export interface ISendM365TeamAvailabilityCalendarInvitesForTeamMutationPayload {
  __typename: "SendM365TeamAvailabilityCalendarInvitesForTeamMutationPayload";
  clientMutationId: string;
}

export interface ISendSeatQuantityChangeRequestMutationInput {
  billingGroupId: string;
  clientMutationId?: string;
  orgId: string;
  requestedQuantity: number;
}

export interface ISendSeatQuantityChangeRequestMutationPayload {
  __typename: "SendSeatQuantityChangeRequestMutationPayload";
  clientMutationId: string;
}

export interface ISetCalendarEventFlexibilityInput {
  /**
   * @default []
   */
  eventsToDisable: Array<any>;

  /**
   * @default []
   */
  eventsToEnable: Array<any>;

  /**
   * The id field of the org
   */
  orgId: string;
}

export interface ISetCalendarEventFlexibilityPayload {
  __typename: "SetCalendarEventFlexibilityPayload";
  updatedEvents: Array<ICalendarEvent>;
}

export interface IUpdateDayRangeFlexibilityInput {
  /**
   * Event ids that should be updated to day flexibility
   * @default []
   */
  dayFlexibleEvents: Array<any>;

  /**
   * Events that should be updated to day of week flexibility, and the allowed days for the flexibility
   * @default []
   */
  dayOfWeekFlexibleEvents: Array<IDayOfWeekFlexibleEventNew>;

  /**
   * Event ids that should not be flexible
   * @default []
   */
  notFlexibleEvents: Array<any>;

  /**
   * The relay org id
   */
  orgRelayId?: string;

  /**
   * Event ids that should be updated to week flexibility
   * @default []
   */
  weekFlexibleEvents: Array<any>;
}

export interface IDayOfWeekFlexibleEventNew {
  /**
   * The days that this event is allowed to move among
   */
  allowedDays: Array<DayOfWeek>;

  /**
   * The backend event id
   */
  eventId: any;
}

export interface IDayRangeFlexibilityUpdated {
  __typename: "DayRangeFlexibilityUpdated";
  updatedEvents: Array<IEventParent>;
}

export interface IUpdatedEventsOnDayRangeFlexibilityUpdatedArguments {
  calendarId?: any;
}

export interface IUpdateFlexibilityInput {
  eventUpdates: Array<IUpdateEventFlexibility>;
  orgRelayId: string;
}

export interface IFlexibilityUpdated {
  __typename: "FlexibilityUpdated";
  updatedEvents: Array<IEventParent>;
}

export interface IUpdatedEventsOnFlexibilityUpdatedArguments {
  calendarId?: any;
}

export interface ISetPreferredRoomsMutationInput {
  clientMutationId?: string;
  orgRelayId?: string;
  preferredRoomIds: Array<string>;
}

export interface ISetPreferredRoomsMutationPayload {
  __typename: "SetPreferredRoomsMutationPayload";
  clientMutationId: string;
  suggestedRoomPreferences: ISuggestedRoomPreferences;
}

export interface ISetPrimaryTeamMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
  teamId: string;
}

export interface ISetPrimaryTeamMutationPayload {
  __typename: "setPrimaryTeamMutationPayload";
  clientMutationId: string;
  team: ITeam;
}

export interface IBatchUpdateRoomFlexibilityInput {
  /**
   * @default []
   */
  canChangeRooms: Array<any>;

  /**
   * @default []
   */
  cannotChangeRooms: Array<any>;

  /**
   * The relay org id
   */
  orgRelayId: string;
}

export interface IRoomFlexibilityUpdated {
  __typename: "RoomFlexibilityUpdated";
  updatedEvents: Array<IEventParent>;
}

export interface IUpdatedEventsOnRoomFlexibilityUpdatedArguments {
  calendarId?: any;
}

export interface IUpdateTimeOfDayFlexibilityInput {
  orgRelayId: string;
  updates: Array<ITimeOfDayFlexibilityUpdate>;
}

export interface ITimeOfDayFlexibilityUpdate {
  allowedEnd: UpdateTimeOfDayTimeSlot;
  allowedStart: UpdateTimeOfDayTimeSlot;
  eventId: any;
}

export interface ITimeOfDayFlexibilityUpdated {
  __typename: "TimeOfDayFlexibilityUpdated";
  updatedEvents: Array<IEventParent>;
}

export interface IUpdatedEventsOnTimeOfDayFlexibilityUpdatedArguments {
  calendarId?: any;
}

export interface ISetWorkAddressForUserMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
  rawAddress?: string;
}

export interface ISetWorkAddressForUserMutationPayload {
  __typename: "SetWorkAddressForUserMutationPayload";
  address: IAddress;
  clientMutationId: string;
}

export interface IShareProposalPayload {
  __typename: "ShareProposalPayload";
  sharedProposalLink: string;
}

export interface ISubmitHubspotFormMutationInput {
  clientMutationId?: string;
  formFields: Array<IHubspotFormFieldInput>;
  formId: string;
}

export interface IHubspotFormFieldInput {
  key: string;
  value: string;
}

export interface ISubmitHubspotFormMutationPayload {
  __typename: "SubmitHubspotFormMutationPayload";
  clientMutationId: string;
}

export interface ISudoDeactivateUserMutationInput {
  clientMutationId?: string;
  email: string;
}

export interface ISudoDeactivateUserMutationPayload {
  __typename: "SudoDeactivateUserMutationPayload";
  clientMutationId: string;
}

export interface ISudoDeleteEverythingMutationInput {
  clientMutationId?: string;
}

export interface ISudoDeleteEverythingMutationPayload {
  __typename: "SudoDeleteEverythingMutationPayload";
  clientMutationId: string;
}

export interface ISudoModifyUserMutationInput {
  clientMutationId?: string;
  status: string;
}

export interface ISudoModifyUserMutationPayload {
  __typename: "SudoModifyUserMutationPayload";
  clientMutationId: string;
  user: IUser;
  viewer: IViewer;
}

export interface ISudoResetFlowStateMutationInput {
  clientMutationId?: string;
  flowKey: string;
  orgRelayId?: string;
}

export interface ISudoResetFlowStateMutationPayload {
  __typename: "SudoResetFlowStateMutationPayload";
  clientMutationId: string;
  flowState: IFlowState;
  viewer: IViewer;
}

export interface ISudoSwitchUserMutationInput {
  clientMutationId?: string;
  email: string;
  jwtType: string;
}

export interface ISudoSwitchUserMutationPayload {
  __typename: "SudoSwitchUserMutationPayload";
  clientMutationId: string;
  user: IUser;
  viewer: IViewer;
}

export interface ISudoTriggerClientUpdateMutationInput {
  clientMutationId?: string;
  force: boolean;
}

export interface ISudoTriggerClientUpdateMutationPayload {
  __typename: "SudoTriggerClientUpdateMutationPayload";
  clientMutationId: string;
  sudo: ISudo;
}

export interface ISyncMeetingReliefInput {
  orgId: string;

  /**
   * Should we sync meeting relief breaks to the user's calendar
   */
  shouldSync: boolean;
}

export interface ISyncMeetingReliefPayload {
  __typename: "SyncMeetingReliefPayload";
  goal: IGoal;
}

export interface IToggleAutoSyncForBillingGroupMutationInput {
  billingGroupId: string;
  clientMutationId?: string;
  isAutoSyncEnabled: boolean;
  orgRelayId: string;
  teamId?: string;
}

export interface IToggleAutoSyncForBillingGroupMutationPayload {
  __typename: "ToggleAutoSyncForBillingGroupMutationPayload";
  clientMutationId: string;
}

export interface IToggleFocusTimeGoalResponse {
  __typename: "ToggleFocusTimeGoalResponse";
  focusTimeGoal: IFocusTimeGoals;
}

export interface IToggleGoalResponse {
  __typename: "ToggleGoalResponse";
  lunchGoal: ILunchGoals;
}

export interface IToggleMeetingReliefResponse {
  __typename: "ToggleMeetingReliefResponse";
  meetingReliefGoal: IMeetingReliefGoals;
}

export interface IToggleTravelTimeResponse {
  __typename: "ToggleTravelTimeResponse";
  travelTimeGoal: ITravelTimeGoals;
}

export interface IToggleUserNudgeUsageInput {
  /**
   * Whether the user enabled or disabled nudges
   */
  disable: boolean;

  /**
   * The graph id of the org
   */
  orgId: string;
}

export interface IToggleUserNudgeUsagePayload {
  __typename: "ToggleUserNudgeUsagePayload";
  hasNudgesEnabled: boolean;
  success: boolean;
}

export interface ITriggerDefragRunMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
}

export interface ITriggerDefragRunMutationPayload {
  __typename: "TriggerDefragRunMutationPayload";
  clientMutationId: string;
}

export interface IOptimizationNotificationTriggerResult {
  __typename: "OptimizationNotificationTriggerResult";
  org: IOrg;
}

export interface IUnpauseEventMutationInput {
  clientMutationId?: string;
  externalEventId: string;
  orgRelayId: string;
}

export interface IUnpauseEventMutationPayload {
  __typename: "UnpauseEventMutationPayload";
  clientMutationId: string;
  eventParent: IEventParent;
}

export interface IUnpauseHoldInput {
  /**
   * The event id of the hold to protect
   */
  holdId: any;

  /**
   * The graph id of the org
   */
  orgId: string;
}

export interface IUnpauseHoldPayload {
  __typename: "UnpauseHoldPayload";
  success: boolean;
  updatedEvent: IEventParent;
}

export interface IUnprotectHoldInput {
  /**
   * The event id of the hold to protect
   */
  holdId: any;

  /**
   * The graph id of the org
   */
  orgId: string;
}

export interface IUnprotectedHoldPayload {
  __typename: "UnprotectedHoldPayload";
  success: boolean;
  updatedEvent: IEventParent;
}

export interface IUnsubscribeEmailInput {
  clientMutationId?: string;
  email: string;
  token: string;
}

export interface IUnsubscribeEmailPayload {
  __typename: "UnsubscribeEmailPayload";
  clientMutationId: string;
}

export interface IUnsyncFocusTimeBlockMutationInput {
  calendarId?: string;
  clientMutationId?: string;
  email?: string;
  externalEventId?: string;
  isPaused?: boolean;
  orgRelayId: string;
  timeRange: ITimeRangeInput;
}

export interface IUnsyncFocusTimeBlockMutationPayload {
  __typename: "UnsyncFocusTimeBlockMutationPayload";
  clientMutationId: string;
}

export interface IUnsyncFocusTimeEventMutationInput {
  calendarId?: string;
  clientMutationId?: string;
  email?: string;
  externalEventId?: string;
  isPaused?: boolean;
  orgRelayId: string;
  timeRange: ITimeRangeInput;
}

export interface IUnsyncFocusTimeEventMutationPayload {
  __typename: "UnsyncFocusTimeEventMutationPayload";
  clientMutationId: string;
}

export interface IUnsyncHoldInput {
  /**
   * The event id of the hold to unsync
   */
  holdId: any;

  /**
   * The graph id of the org
   */
  orgId: string;
}

export interface IUnsyncHoldPayload {
  __typename: "UnsyncHoldPayload";
  success: boolean;
}

export interface IUnsyncMeetingReliefEventInput {
  externalEventId: any;
  orgId: string;
}

export interface IUnsyncMeetingReliefEvent {
  __typename: "UnsyncMeetingReliefEvent";
  successful: boolean;
}

export interface IUpdateDeviceTokenInput {
  clientMutationId?: string;

  /**
   * A unique identifier for the calling device
   */
  deviceId: string;

  /**
   * The registration token that allows us to send push notifications to the device
   */
  registrationToken: string;
}

export interface IUpdateDeviceTokenPayload {
  __typename: "UpdateDeviceTokenPayload";
  clientMutationId: string;
}

export interface IEventColoringSettingsMutationsInput {
  clientMutationId?: string;
  eventCategoryColorings: Array<IEventCategoryColoringInput>;

  /**
   * The front-end ID for the organization we're saving event coloring settings for
   */
  orgRelayId: string;
}

export interface IEventCategoryColoringInput {
  active: boolean;
  eventCategory: string;
  googleColorKey?: string;
  isUserSet: boolean;
}

export interface IEventColoringSettingsMutationsPayload {
  __typename: "EventColoringSettingsMutationsPayload";
  clientMutationId: string;
  eventColoringSettings: IEventColoringSettings;
}

export interface IUpdateEventResponseStatusMutationInput {
  calendarId: string;
  clientMutationId?: string;
  externalEventId: string;
  orgRelayId: string;
  responseStatus: ResponseStatusEnum;
}

export interface IUpdateEventResponseStatusMutationPayload {
  __typename: "UpdateEventResponseStatusMutationPayload";
  clientMutationId: string;
  eventParent: IEventParent;
}

export interface IUpdateFlagsForUserMutationInput {
  clientMutationId?: string;
  enable: boolean;
  flagNames: Array<string>;
  flagNamespace: string;
}

export interface IUpdateFlagsForUserMutationPayload {
  __typename: "UpdateFlagsForUserMutationPayload";
  clientMutationId: string;
  flags: IFlags;
}

export interface IUpdateUserFlowMutationInput {
  clientMutationId?: string;
  flowKey: string;
  newState: string;
}

export interface IUpdateUserFlowMutationPayload {
  __typename: "UpdateUserFlowMutationPayload";
  clientMutationId: string;
  flowState: IFlowState;
}

export interface IUpdateUserOrgFlowMutationInput {
  clientMutationId?: string;
  flowKey: string;
  newState: string;
  orgRelayId: string;
}

export interface IUpdateUserOrgFlowMutationPayload {
  __typename: "UpdateUserOrgFlowMutationPayload";
  clientMutationId: string;
  flowState: IFlowState;
  org: IOrg;
  user: IUser;
}

export interface IUpdateAutoDeclineThreshold {
  hours: number;
}

export interface IUpdateDailyMax {
  hours: number;
}

export enum UpdateNotificationStrategyInput {
  DontNotify = "DontNotify",
  NotifyInGCal = "NotifyInGCal",
}

export enum UpdatePreferredFocusTime {
  Afternoon = "Afternoon",
  Morning = "Morning",
  NoPreference = "NoPreference",
}

export enum UpdateScheduleAs {
  Busy = "Busy",
  Free = "Free",
}

export interface IUpdateAtMostFocusHours {
  hours: number;
}

export interface IUpdateFocusTimeGoalResponse {
  __typename: "UpdateFocusTimeGoalResponse";
  focusTimeGoal: IFocusTimeGoals;
}

export interface IUpdateLunchGoalResponse {
  __typename: "UpdateLunchGoalResponse";
  lunchGoal: ILunchGoals;
}

export interface IUpdateMeetingReliefGoalResponse {
  __typename: "UpdateMeetingReliefGoalResponse";
  meetingReliefGoal: IMeetingReliefGoals;
}

export interface IUpdateOrgInviteInput {
  calendarIds?: Array<string>;
  clientMutationId?: string;
  note?: string;
  orgRelayId: string;

  /**
   * @default "Defrag"
   */
  productType?: ProductType;
}

export enum ProductType {
  Defender = "Defender",
  Defrag = "Defrag",
}

export interface IUpdateOrgInvitePayload {
  __typename: "UpdateOrgInvitePayload";
  calendarIds: Array<string>;
  clientMutationId: string;
  org: IOrg;
}

export interface IUpdateSettingMutationInput {
  clientMutationId?: string;
  setting: ISettingInput;
  value: Array<string>;
}

export interface ISettingInput {
  id: string;
  key?: ISettingKeyInput;
  type?: ISettingTypeInput;
  value?: Array<string>;
}

export interface ISettingKeyInput {
  id: string;
  name?: string;
  scope?: IUrnInput;
  target?: IUrnInput;
}

export interface ISettingTypeInput {
  containerType?: ContainerType;
  id: string;
  required?: boolean;
  valueType?: ValueType;
}

export interface IUpdateSettingMutationPayload {
  __typename: "UpdateSettingMutationPayload";
  clientMutationId: string;
  setting: ISetting;
}

export interface IUpdateSettingsMutationInput {
  clientMutationId?: string;
  settings: Array<ISettingInput>;
}

export interface IUpdateSettingsMutationPayload {
  __typename: "UpdateSettingsMutationPayload";
  clientMutationId: string;
  setting: Array<ISetting>;
}

export interface IUpdateShadowCalendarSettingsMutationInput {
  clientMutationId?: string;
  enabled: boolean;

  /**
   * The front-end ID for the organization we're saving shadow cal settings for
   */
  orgRelayId: string;
}

export interface IUpdateShadowCalendarSettingsMutationPayload {
  __typename: "UpdateShadowCalendarSettingsMutationPayload";
  clientMutationId: string;
  shadowCalendarSettings: IShadowCalendarSettings;
}

export interface IUpdateSlackAccountMutationInput {
  action: SlackUpdateAction;
  clientMutationId?: string;
  slackAccountRelayId: string;
}

export enum SlackUpdateAction {
  Delete = "Delete",
}

export interface IUpdateSlackAccountMutationPayload {
  __typename: "UpdateSlackAccountMutationPayload";
  clientMutationId: string;
}

export interface IUpdateSlackAccountForTeamMutationInput {
  action: SlackForTeamUpdateAction;
  clientMutationId?: string;
  orgRelayId: string;
  teamId: string;
}

export enum SlackForTeamUpdateAction {
  Delete = "Delete",
}

export interface IUpdateSlackAccountForTeamMutationPayload {
  __typename: "UpdateSlackAccountForTeamMutationPayload";
  clientMutationId: string;
}

export interface IUpdateSmartHoldSyncSettingsMutationInput {
  clientMutationId?: string;
  focusTimeExternalTransparency?: FocusTimeExternalTransparency;
  focusTimeIdealMinutesPerWeek?: number;
  focusTimeRemindersEnabled?: boolean;
  focusTimeSyncAll?: boolean;
  focusTimeSyncEnabled?: boolean;
  lunchRemindersEnabled?: boolean;
  lunchSyncEnabled?: boolean;
  orgRelayId: string;
  travelTimeRemindersEnabled?: boolean;
  travelTimeSyncEnabled?: boolean;
}

export interface IUpdateSmartHoldSyncSettingsMutationPayload {
  __typename: "UpdateSmartHoldSyncSettingsMutationPayload";
  clientMutationId: string;
  shadowCalendarSettings: IShadowCalendarSettings;
}

export interface IUpdateSuggestedOrgInviteInput {
  calendarIds?: Array<string>;
  clientMutationId?: string;
  note?: string;
  orgRelayId: string;
  resolution?: SuggestedOrgInviteResolution;
}

export interface IUpdateSuggestedOrgInvitePayload {
  __typename: "UpdateSuggestedOrgInvitePayload";
  calendarIds: Array<string>;
  clientMutationId: string;
  org: IOrg;
}

export interface IUpdateTeamMutationInput {
  addedTeamMembers?: Array<ITeamRoleAndPersonIdInput>;
  clientMutationId?: string;
  newTeamName?: string;
  orgRelayId: string;
  removedTeamMembers?: Array<string>;
  teamId: string;
}

export interface IUpdateTeamMutationPayload {
  __typename: "updateTeamMutationPayload";
  clientMutationId: string;
  team: ITeam;
}

export interface IToggleEventColoringSettingsMutationsInput {
  clientMutationId?: string;
  enabled: boolean;
  eventCategoryColorings: Array<IEventCategoryColoringInput>;

  /**
   * The front-end ID for the organization we're saving event coloring settings for
   */
  orgRelayId: string;
}

export interface IToggleEventColoringSettingsMutationsPayload {
  __typename: "ToggleEventColoringSettingsMutationsPayload";
  clientMutationId: string;
  eventColoringSettings: IEventColoringSettings;
}

export interface IUpdateTravelTimeGoalResponse {
  __typename: "UpdateTravelTimeGoalResponse";
  travelTimeGoals: ITravelTimeGoals;
}

export interface IUpdateTravelTimeDurationInput {
  /**
   * The new duration in minutes
   */
  durationMinutes: number;

  /**
   * The event id of the hold to protect
   */
  holdId: any;

  /**
   * The graph id of the org
   */
  orgId: string;
}

export interface IUpdateTravelTimeDurationPayload {
  __typename: "UpdateTravelTimeDurationPayload";
  success: boolean;
  updatedEvent: IEventParent;
}

export interface IUpdateUserDefinedTeamMutationInput {
  calendarIds: Array<string>;
  clientMutationId?: string;
  userDefinedTeamRelayId: string;
}

export interface IUpdateUserDefinedTeamMutationPayload {
  __typename: "UpdateUserDefinedTeamMutationPayload";
  clientMutationId: string;
  userDefinedTeam: IUserDefinedTeam;
}

export interface IUpdateWorkingHoursMutationInput {
  clientMutationId?: string;

  /**
   * if true, will be saved as both Base and Meeting, regardless of the type on the working hours argument
   */
  saveMeetingAndWorkingHours?: boolean;
  workingHours: IWorkingHoursInput;
}

export interface IWorkingHoursInput {
  dayOverrides?: Array<IDayOverrideInput>;
  daySettings?: Array<IDaySettingInput>;
  id: string;
  timeZone?: string;
  type?: WorkingHoursTypeEnum;
}

export interface IDayOverrideInput {
  date?: string;
  setting?: IWorkingSettingInput;
}

export interface IWorkingSettingInput {
  excludedSlots?: Array<ExcludedSlots>;
  maxSlot?: MaxSlot;
  minSlot?: MinSlot;
}

export interface IDaySettingInput {
  day?: Day;
  setting?: IWorkingSettingInput;
}

export interface IUpdateWorkingHoursMutationPayload {
  __typename: "UpdateWorkingHoursMutationPayload";
  clientMutationId: string;
  updatedOrgMeetingHours: IWorkingHours;
  updatedUserMeetingHours: IWorkingHours;
  updatedUserWorkingHours: IWorkingHours;
  userWorkingHours: IUserWorkingHours;
}

export interface IUpdateWorkingLocationMutationInput {
  clientMutationId?: string;
  workingLocation: IWorkingLocationInput;
  workingLocationId: string;
}

export interface IWorkingLocationInput {
  address?: string;
  id: string;
  locationType: LocationTypeEnum;
  name?: string;
  timeZone?: string;
}

export interface IUpdateWorkingLocationMutationPayload {
  __typename: "UpdateWorkingLocationMutationPayload";
  clientMutationId: string;
}

export interface IUpdateWorkingLocationWeeklyScheduleMutationInput {
  clientMutationId?: string;
  workingLocationByDay: Array<IWorkingLocationByDayInput>;
}

export interface IWorkingLocationByDayInput {
  day: string;
  workingLocationId: string;
}

export interface IUpdateWorkingLocationWeeklyScheduleMutationPayload {
  __typename: "UpdateWorkingLocationWeeklyScheduleMutationPayload";
  clientMutationId: string;
}

export interface IUpsertFocusTimeMutationInput {
  clientMutationId?: string;
  options: IGoalPatchFocusTimeOptionsInput;
  orgId: string;
}

export interface IGoalPatchFocusTimeOptionsInput {
  maxMinutesPerBlock?: number;
  syncToGoogle?: boolean;
}

export interface IUpsertFocusTimeMutationPayload {
  __typename: "UpsertFocusTimeMutationPayload";
  clientMutationId: string;
  goal: IGoal;
  goalEdge: IGoalEdge;
  org: IOrg;
}

export interface IUpsertLunchMutationInput {
  clientMutationId?: string;
  options: IGoalPatchLunchOptionsInput;
  orgId: string;
}

export interface IGoalPatchLunchOptionsInput {
  idealMinutesDuration?: number;
  maxTimeSlot?: TimeSlot;
  minTimeSlot?: TimeSlot;
  minimumMinutesDuration?: number;
  syncToGoogle?: boolean;
}

export interface IUpsertLunchMutationPayload {
  __typename: "UpsertLunchMutationPayload";
  clientMutationId: string;
  goal: IGoal;
  goalEdge: IGoalEdge;
  org: IOrg;
}

export interface IUpsertMeetingReliefMutationInput {
  clientMutationId?: string;
  options: IGoalPatchMeetingReliefOptionsInput;
  orgId: string;
}

export interface IGoalPatchMeetingReliefOptionsInput {
  fatigueThresholdMinutes?: number;
  meetingReliefBreakMinutes?: number;
}

export interface IUpsertMeetingReliefMutationPayload {
  __typename: "UpsertMeetingReliefMutationPayload";
  clientMutationId: string;
  goal: IGoal;
  goalEdge: IGoalEdge;
  org: IOrg;
}

export interface IUpsertNonUserDefragEmailResponseInput {
  additionalInformation?: string;
  clientMutationId?: string;
  emailInstanceId: string;
  responseType: NonUserDefragEmailResponseType;
}

export interface IUpsertNonUserDefragEmailResponsePayload {
  __typename: "UpsertNonUserDefragEmailResponsePayload";
  clientMutationId: string;
  nonUserDefragEmailResponse: INonUserDefragEmailResponse;
}

export interface IUpsertPreferredWorkMutationInput {
  clientMutationId?: string;
  options: IGoalPatchPreferredWorkOptionsInput;
  orgId: string;
}

export interface IGoalPatchPreferredWorkOptionsInput {
  keepEndOfDayOpen?: boolean;
  keepStartOfDayOpen?: boolean;
  preferredWorkType?: PreferredWorkType;
}

export interface IUpsertPreferredWorkMutationPayload {
  __typename: "UpsertPreferredWorkMutationPayload";
  clientMutationId: string;
  goal: IGoal;
  goalEdge: IGoalEdge;
  org: IOrg;
}

export interface IUpsertTravelTimeMutationInput {
  clientMutationId?: string;
  options: IGoalPatchTravelTimeOptionsInput;
  orgId: string;
}

export interface IGoalPatchTravelTimeOptionsInput {
  syncToGoogle?: boolean;
}

export interface IUpsertTravelTimeMutationPayload {
  __typename: "UpsertTravelTimeMutationPayload";
  clientMutationId: string;
  goal: IGoal;
  goalEdge: IGoalEdge;
  org: IOrg;
}

export interface IUserInteractedWithNotificationInput {
  orgId: string;
  type: string;
}

export interface IUserInteractedWithNotification {
  __typename: "UserInteractedWithNotification";
  successful: boolean;
}

export interface ISaveUserSurveyResponsesMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
  userSurveyResponses?: Array<IUserSurveyResponseInput>;
}

export interface IUserSurveyResponseInput {
  freeformResponse?: string;
  questionText?: string;
  questionType?: QuestionType;
  responseText?: string;
  responseType?: ResponseType;
}

export interface ISaveUserSurveyResponsesMutationPayload {
  __typename: "SaveUserSurveyResponsesMutationPayload";
  clientMutationId: string;
  userSurveyResponse: IUserSurveyResponse;
}

export interface ISaveUserSurveyResponseWrapperMutationInput {
  clientMutationId?: string;
  userSurveyResponseWrapper: IUserSurveyResponseWrapperInput;
}

export interface IUserSurveyResponseWrapperInput {
  id: string;
  userSurveyResponses: Array<IUserSurveyResponseInput>;
}

export interface ISaveUserSurveyResponseWrapperMutationPayload {
  __typename: "SaveUserSurveyResponseWrapperMutationPayload";
  clientMutationId: string;
  userSurveyResponseWrapper: IUserSurveyResponseWrapper;
}

export interface IValidateSlackChannelBySendingMessageMutationInput {
  clientMutationId?: string;
  orgRelayId: string;
  slackChannelName: string;
  teamId: string;
}

export interface IValidateSlackChannelBySendingMessageMutationPayload {
  __typename: "ValidateSlackChannelBySendingMessageMutationPayload";
  clientMutationId: string;
  validateSlackChannelResponse: IValidateSlackChannelResponse;
}

/**
 * DESCRIBE NEW FIELD HERE
 */
export interface IValidateSlackChannelResponse {
  __typename: "ValidateSlackChannelResponse";
  channel: ISlackChannel;

  /**
   * The ID of an object
   */
  id: string;
  isValid: boolean;
}

export interface ISubscription {
  __typename: "Subscription";
  updateEventParentBaseWrapper: IEventParentBaseWrapper;
  updateEventParentWrappers: IUpdateEventParentWrappersSubscriptionPayload;
  updateFlowState: IUpdateFlowStateSubscriptionPayload;
  updateOrgFlowState: IUpdateOrgFlowStateSubscriptionPayload;
  updateTeamCalendarState: IUpdateTeamCalendarStateSubscriptionPayload;
}

export interface IUpdateEventParentBaseWrapperOnSubscriptionArguments {
  input: IUpdateEventParentBaseWrappersSubscriptionInput;
}

export interface IUpdateEventParentWrappersOnSubscriptionArguments {
  input: IUpdateEventParentWrappersSubscriptionInput;
}

export interface IUpdateFlowStateOnSubscriptionArguments {
  input: IUpdateFlowStateSubscriptionInput;
}

export interface IUpdateOrgFlowStateOnSubscriptionArguments {
  input: IUpdateOrgFlowStateSubscriptionInput;
}

export interface IUpdateTeamCalendarStateOnSubscriptionArguments {
  input: IUpdateTeamCalendarStateSubscriptionInput;
}

export interface IUpdateEventParentBaseWrappersSubscriptionInput {
  calendarIds: Array<string>;
  dates: Array<any>;
  includeAllDay?: boolean;
  orgRelayId: string;
  timeZone: string;
}

export interface IUpdateEventParentWrappersSubscriptionInput {
  calendarIds: Array<string>;
  dates: Array<any>;
  includeAllDay?: boolean;
  orgRelayId: string;
  timeZone: string;
}

export interface IUpdateEventParentWrappersSubscriptionPayload {
  __typename: "UpdateEventParentWrappersSubscriptionPayload";
  eventParentBases: Array<IEventParentBase>;
  eventParentWrappers: Array<IEventParentWrapper>;
}

export interface IUpdateFlowStateSubscriptionInput {
  flowStateRelayId: string;
}

export interface IUpdateFlowStateSubscriptionPayload {
  __typename: "UpdateFlowStateSubscriptionPayload";
  flowState: IFlowState;
}

export interface IUpdateOrgFlowStateSubscriptionInput {
  flowStateRelayId: string;
}

export interface IUpdateOrgFlowStateSubscriptionPayload {
  __typename: "UpdateOrgFlowStateSubscriptionPayload";
  flowState: IFlowState;
}

export interface IUpdateTeamCalendarStateSubscriptionInput {
  orgRelayId: string;
  teamId: string;
}

export interface IUpdateTeamCalendarStateSubscriptionPayload {
  __typename: "UpdateTeamCalendarStateSubscriptionPayload";
  teamCalendarState: ITeamCalendarState;
}

/**
 * The state of a team calendar
 */
export interface ITeamCalendarState {
  __typename: "TeamCalendarState";
  calendarId: string;
}

/**
 * The details of an event we are proposing to add to a user's calendar
 */
export interface IAddDiffDetails {
  __typename: "AddDiffDetails";

  /**
   * The type of action being proposed for this diff
   */
  actionType: DiffActionTypeEnum;
  attendees: IProposalAttendees;

  /**
   * Indicates that not all the attendees were returned
   */
  attendeesOmitted: boolean;
  description: string;

  /**
   * Indicates the description has been omitted
   */
  descriptionOmitted: boolean;
  flexibility: IProposalFlexibility;
  id: string;
  isFlexible: boolean;
  location: string;
  recurrence: IEventRecurrence;
  recurrenceRule: any;

  /**
   * The start and end time of the proposed event formatted as an ISO8601 interval. Null if there were no times found
   */
  time: any;
  title: string;
  tradeoffBlocks: Array<ITradeoffBlock>;

  /**
   * The type of video conferencing link found in the event
   */
  videoConferencingType: ConferencingType;
}

/**
 * Flexibility settings of a proposal event
 */
export interface IProposalFlexibility {
  __typename: "ProposalFlexibility";

  /**
   * Whether the event is marked as flex
   */
  isFlexible: boolean;
  roomIsFlexible: boolean;

  /**
   * How an event can move within a day, null if not configured
   */
  timeOfDayFlexibility: IProposalTimeOfDayFlex;

  /**
   * How an event can move between days
   */
  timeRangeFlexibility: TimeRangeFlexibility;
}

export interface IProposalTimeOfDayFlex {
  __typename: "ProposalTimeOfDayFlex";

  /**
   * End of allowed time range for moving an event, null if not bounded by an explicit end time
   */
  endTime: any;

  /**
   * Beginning of allowed time range for moving an event, null if not bounded by an explicit start time
   */
  startTime: any;
}

/**
 * The details of an event we are proposing to update on a user's calendar
 */
export interface IModifyDiffDetails {
  __typename: "ModifyDiffDetails";

  /**
   * The type of action being proposed for this diff
   */
  actionType: DiffActionTypeEnum;
  attendees: IProposalAttendees;

  /**
   * Indicates that not all the attendees were returned
   */
  attendeesOmitted: boolean;

  /**
   * The existing start and end time of the event formatted as an ISO8601 interval
   */
  currentTime: any;
  description: string;

  /**
   * Indicates the description has been omitted
   */
  descriptionOmitted: boolean;

  /**
   * The ID of the event being modified
   */
  eventId: string;

  /**
   * Edit permissions for the event and current user
   */
  eventPermissions: EventPermissions;
  flexibility: IProposalFlexibility;
  id: string;
  isFlexible: boolean;
  location: string;
  recurrence: IEventRecurrence;
  recurrenceRule: any;
  rsvp: ResponseStatusEnum;
  title: string;
  tradeoffBlocks: Array<ITradeoffBlock>;

  /**
   * The start and end time of the modified event formatted as an ISO8601 interval. Null if the event is being modified without a time change or there were no times found
   */
  updatedTime: any;

  /**
   * The type of video conferencing link found in the event
   */
  videoConferencingType: ConferencingType;
}

/**
 * Button that links to any page
 */
export interface INotificationLink {
  __typename: "NotificationLink";
  text: string;
  tracking: ITrackingData;
  url: string;
}

/**
 * Button that links to a webapp page
 */
export interface IWebappLink {
  __typename: "WebappLink";
  surface: WebappSurface;
  text: string;
  tracking: ITrackingData;
}

export enum WebappSurface {
  Breaks = "Breaks",
  FlexibleMeetings = "FlexibleMeetings",
  FocusTime = "FocusTime",
  Home = "Home",
}

// eslint-enable
