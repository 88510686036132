type colorType = "hex" | "rgb" | "rgba" | "hsl" | "hsla";

function expandShorthandHex(hex: string) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  return hex.replace(shorthandRegex, (_m, r, g, b) => {
    return r + r + g + g + b + b;
  });
}

export function hexToRgbObj(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(expandShorthandHex(hex));
  if (!result) {
    console.warn("failed to parse string into a hex color, so you're goin to see a lot of black");
    return { r: 0, g: 0, b: 0 };
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}

export function hexToRgb(hex: string) {
  const { r, g, b } = hexToRgbObj(hex);
  return `rgb(${r}, ${g}, ${b})`;
}

export function rgbToHex(r: number, g: number, b: number) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function rgbToRgba(rgb: string, a: number) {
  if (rgb.indexOf("a") === -1) {
    return rgb.replace(")", `, ${a})`).replace("rgb", "rgba");
  }
  return rgb;
}

export function hslToHsla(hsl: string, a: number) {
  if (hsl.indexOf("a") === -1) {
    return hsl.replace(")", `, ${a})`).replace("hsl", "hsla");
  }
  return hsl;
}

export function getColorType(color: string): colorType | null {
  if (color.indexOf("#") >= 0) {
    if (color.trim().length !== 4 && color.trim().length !== 7) {
      console.warn('"getColorType" called with a hex of an invalid length');
      return null;
    }
    return "hex";
  } else if (color.indexOf("rgb") >= 0 || color.indexOf("RGB") >= 0) {
    return /a/i.test(color) ? "rgba" : "rgb";
  } else if (color.indexOf("hsl") >= 0 || color.indexOf("HSL") >= 0) {
    return /a/i.test(color) ? "hsla" : "hsl";
  } else {
    console.warn("invalid color type!");
    return null;
  }
}

export function opacity(color: string, alpha = 1) {
  const type = getColorType(color);
  switch (type) {
    case "hex":
      return rgbToRgba(hexToRgb(color), alpha);
    case "rgb":
      return rgbToRgba(color, alpha);
    case "rgba":
      return color.replace(/[\d\.]+\)$/g, `${alpha})`);
    case "hsl":
      return hslToHsla(color, alpha);
    case "hsla":
      return color.replace(/[\d\.]+\)$/g, `${alpha})`);
    default:
      console.warn("tried to change opacity of an invalid color");
      return color;
  }
}
