import { getFullName } from "#clockwise/web-commons/src/util/profile.util";
import classNames from "classnames";
import pluralize from "pluralize";
import React from "react";
import { AttendeeAvatar } from "../AttendeeAvatar";
import { AttendeeAvatarStack } from "../AttendeeAvatarStack";
import { LinkMember } from "../scheduling-link";
import { ConfirmationType } from "./scheduling-confirmation";

export interface TitleProps {
  linkMembers: LinkMember[];
  attendees?: string[];
  variant: ConfirmationType;
  completed: boolean; // true when successfully booked, cancelled, or rescheduled.
  reasonForChange?: string | null;
  isYou: boolean;
}

const PAST_PARTICIPLE: { [T in ConfirmationType]: string } = {
  Book: "booked",
  Cancel: "cancelled",
  Reschedule: "rescheduled",
};

const REASON_HEADER: { [T in ConfirmationType]: string } = {
  Book: "Reason for booking",
  Cancel: "Reason for cancellation",
  Reschedule: "Reason for reschedule",
};

const CancelRescheduleReason = ({
  reason,
  variant,
}: {
  reason: string;
  variant: ConfirmationType;
}) => (
  <div
    className={classNames("cw-p-1 cw-pl-2 cw-mt-4 cw-mb-1 cw-rounded-lg cw-body-lg", {
      "cw-text-warning cw-bg-warning": variant === "Cancel",
      "cw-text-neutral cw-bg-neutral": variant !== "Cancel",
    })}
  >
    <p className="cw-font-bold cw-m-2" cw-id="cancel-reschedule-header">
      {REASON_HEADER[variant]}
    </p>
    <p className="cw-m-2" cw-id="cancel-reschedule-reason">
      {reason}
    </p>
  </div>
);

const ConfirmationTitleSingleMember = ({
  linkMembers,
  variant,
  completed,
  reasonForChange,
  isYou,
}: TitleProps) => {
  const name = getFullName(linkMembers[0]?.person);
  const showReason = !!reasonForChange && ["Cancel", "Reschedule"].includes(variant);
  return (
    <div>
      <div className="cw-flex cw-items-center cw-gap-4">
        <AttendeeAvatar profile={linkMembers[0]?.person} size="xlarge" cw-id="booking-avatar" />
        <div cw-id="booking-confirmation-message">
          {completed ? (
            <h1 className="cw-heading-2xl">
              Your meeting
              {!isYou && (
                <>
                  {" "}
                  with
                  <br />
                  {name}{" "}
                </>
              )}{" "}
              is {PAST_PARTICIPLE[variant]}
              {variant === ConfirmationType.Book ? "!" : ""}
            </h1>
          ) : (
            <h1 className="cw-heading-2xl">
              {variant} meeting
              {!isYou && (
                <>
                  <br />
                  with {name}
                </>
              )}
            </h1>
          )}
        </div>
      </div>
      {showReason && <CancelRescheduleReason reason={reasonForChange} variant={variant} />}
    </div>
  );
};

const ConfirmationTitleMultiMember = ({
  linkMembers,
  attendees,
  variant,
  completed,
  isYou,
  reasonForChange,
}: TitleProps) => {
  const name = getFullName(linkMembers[0]?.person);
  const numAttendees = (attendees?.length || 0) + linkMembers.length;
  const showReason = !!reasonForChange && ["Cancel", "Reschedule"].includes(variant);

  return (
    <div>
      <div className="cw-flex cw-items-center cw-justify-between">
        <div cw-id="booking-confirmation-message">
          {completed ? (
            <h1 className="cw-heading-2xl">
              Your meeting
              {!isYou && (
                <>
                  {" "}
                  with <br />
                  {name} and {`${pluralize("other", numAttendees - 1, true)}`}
                  <br />
                </>
              )}{" "}
              is {PAST_PARTICIPLE[variant]}
              {variant === ConfirmationType.Book ? "!" : ""}
            </h1>
          ) : (
            <h1 className="cw-heading-2xl">
              {variant} meeting
              {!isYou && (
                <>
                  <br />
                  with {name} and {pluralize("other", numAttendees - 1, true)}
                </>
              )}
            </h1>
          )}
        </div>
        <AttendeeAvatarStack maxShown={5} size="xlarge">
          {linkMembers.map((lm) => (
            <AttendeeAvatar profile={lm.person} size="xlarge" isInOverlap key={lm.id} />
          ))}
        </AttendeeAvatarStack>
      </div>
      {showReason && <CancelRescheduleReason reason={reasonForChange} variant={variant} />}
    </div>
  );
};

export const ConfirmationTitle = (props: TitleProps) => {
  // if linkMembers > 1 you can assume group link or round robin link
  // if attendees > 1 then you can assume the person booking added extra attendees
  if (props.linkMembers.length > 1 || (props.attendees && props.attendees.length > 1)) {
    return <ConfirmationTitleMultiMember {...props} />;
  } else {
    return <ConfirmationTitleSingleMember {...props} />;
  }
};
