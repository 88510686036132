// libraries
import * as React from "react";
// callout-box imports
import * as s from "./CalloutBox.styles";
import { IProps } from "./CalloutBoxTypes";

/**
 * @deprecated Use CalloutBox.tailwind
 */
export function CalloutBox(props: IProps) {
  if (
    (props.error && props.warning) ||
    (props.error && props.info) ||
    (props.warning && props.info)
  ) {
    console.warn(
      "CalloutInfo should only take ONE of the following attributes: warning, info, error",
    );
  }
  const styles = props.error ? s.error : props.warning ? s.warning : s.info;

  return (
    <div className={props.className} style={{ ...styles, ...props.style }}>
      {props.children}
    </div>
  );
}
