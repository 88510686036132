export const sudoActions = {
  SET_SUDO_TARGET: "SET_SUDO_TARGET",
  CLEAR_SUDO_TARGET: "CLEAR_SUDO_TARGET",
};

export const setSudoTarget = (sudoTarget: string) => {
  return {
    type: sudoActions.SET_SUDO_TARGET,
    sudoTarget,
  };
};

export const clearSudoTarget = () => {
  return {
    type: sudoActions.CLEAR_SUDO_TARGET,
  };
};
