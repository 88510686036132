/**
 * NOTE:
 *
 * This is a legacy-compatible version of the Errorable utilities, use this only if you are using the old GQL2TS
 * generated types. All use cases using the `@clockwise/schema` package should use the standard errorable utilities.
 */

import { IGraphEntityError } from "#webapp/src/__schema__";

type GenericGraphQLObject = { __typename: string };

export type Errorable<T extends GenericGraphQLObject> = T | IGraphEntityError;

export function isError<T extends GenericGraphQLObject>(
  value: Errorable<T>,
): value is IGraphEntityError {
  return value.__typename === "GraphEntityError";
}

export function isValue<T extends GenericGraphQLObject>(value: Errorable<T>): value is T {
  return !isError(value);
}

export function getValue<T extends GenericGraphQLObject>(
  value: Errorable<T> | null | undefined,
): T | null {
  if (!value) {
    return null;
  }

  return isValue(value) ? value : null;
}

export function getError<T extends GenericGraphQLObject>(
  value: Errorable<T> | null | undefined,
): IGraphEntityError | null {
  if (!value) {
    return null;
  }

  return isError(value) ? value : null;
}
