import MuiTypography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";

export type Variant =
  | "body1"
  | "body2"
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2";

export type Color = "initial" | "primary" | "error";

const colorMap: { [color in Color]: string } = {
  primary: "cw-text-brand",
  error: "cw-text-destructive",
  initial: "",
};
export interface Props {
  children: React.ReactNode;
  variant?: Variant;
  className?: string;
  style?: React.CSSProperties;
  color?: Color;
}

/**
 *
 * @deprecated Prefer tailwind classes:
 *    h1 => cw-heading-3xl
 *    h2 => cw-heading-2xl
 *    h3 => cw-heading-xl
 *    h5 => cw-heading-2xl
 *    h6 => cw-heading-xl
 *    body1 => cw-body-lg
 *    body2 => cw-body-base
 *    subtitle1 => cw-body-lg
 *    subtitle2 => cw-body-base
 *    caption => cw-caption
 */
export const Typography = ({
  color = "initial",
  variant = "body1",
  className,
  style,
  children,
}: Props) => {
  const colorClass = color && colorMap[color];
  switch (variant) {
    case "h1":
      return (
        <h1 className={classNames("cw-heading-3xl cw-m-0", colorClass, className)} style={style}>
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2 className={classNames("cw-heading-2xl cw-m-0", colorClass, className)} style={style}>
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3 className={classNames("cw-heading-xl cw-m-0", colorClass, className)} style={style}>
          {children}
        </h3>
      );
    case "h5":
      return (
        <h5 className={classNames("cw-heading-2xl cw-m-0", colorClass, className)} style={style}>
          {children}
        </h5>
      );
    case "h6":
      return (
        <h6 className={classNames("cw-heading-xl cw-m-0", colorClass, className)} style={style}>
          {children}
        </h6>
      );
    case "body1":
      return (
        <p className={classNames("cw-body-lg cw-m-0", colorClass, className)} style={style}>
          {children}
        </p>
      );
    case "body2":
      return (
        <p className={classNames("cw-body-base cw-m-0", colorClass, className)} style={style}>
          {children}
        </p>
      );
    case "caption":
      return (
        <span className={classNames("cw-caption cw-m-0", colorClass, className)} style={style}>
          {children}
        </span>
      );
    case "subtitle1":
      return (
        <h6
          className={classNames("cw-body-lg cw-m-0", colorClass || "cw-text-muted", className)}
          style={style}
        >
          {children}
        </h6>
      );
    case "subtitle2":
      return (
        <h6 className={classNames("cw-body-base cw-m-0", colorClass, className)} style={style}>
          {children}
        </h6>
      );
    default:
      return (
        <MuiTypography color={color} variant={variant} className={className} style={style}>
          {children}
        </MuiTypography>
      );
  }
};
