import { noop } from "lodash";
import React, { ReactNode, createContext, useContext } from "react";
import invariant from "tiny-invariant";

type ConflictClusterId = string;

const ReadContext = createContext<ConflictClusterId[]>([]);
const WriteContext = createContext<(hoverEvent: ConflictClusterId[]) => void>(noop);

type ProviderProps = {
  children: ReactNode;
  initValue: ConflictClusterId[];
};

export const DismissedConflictClusterProvider = ({ children, initValue = [] }: ProviderProps) => {
  const [dismissedConflictClusterIds, setDismissedConflictClusterIds] = React.useState<
    ConflictClusterId[]
  >(initValue);

  return (
    <WriteContext.Provider value={setDismissedConflictClusterIds}>
      <ReadContext.Provider value={dismissedConflictClusterIds}>{children}</ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const useReadDismissedConflictClusters = () => useContext(ReadContext);

export const useUpdateDismissedConflictClusters = () => {
  const context = useContext(WriteContext);
  invariant(
    context,
    "useUpdateDismissedConflictCluster must be within DismissedConflictClusterProvider",
  );
  return context;
};
