import { graphql } from "react-relay";

export const updateFlagsForUserMutation = graphql`
  mutation UpdateFlagsForUserMutation($input: UpdateFlagsForUserMutationInput!) {
    updateFlagsForUser(input: $input) {
      flags {
        id
        flags
      }
    }
  }
`;
