import { AnnotationDialogActions } from "#webapp/src/state/actions/annotation-dialog.actions";

export interface IAnnotationDialogState {
  skipDisabled: boolean;
  saveMeetingRoomsWithDefaultOrgTags: boolean;
}

interface IAnnotationDialogAction {
  type: string;
}

export const initialAnnotationDialogState: IAnnotationDialogState = {
  skipDisabled: false,
  saveMeetingRoomsWithDefaultOrgTags: false,
};

export function annotationDialogReducer(
  state = initialAnnotationDialogState,
  action: IAnnotationDialogAction,
) {
  switch (action.type) {
    case AnnotationDialogActions.DisableSkip:
      return { ...state, skipDisabled: true };
    case AnnotationDialogActions.EnableSkip:
      return { ...state, skipDisabled: false };
    case AnnotationDialogActions.MeetingRoomsWithDefaultsOn:
      return { ...state, saveMeetingRoomsWithDefaultOrgTags: true };
    case AnnotationDialogActions.MeetingRoomsWithDefaultsOff:
      return { ...state, saveMeetingRoomsWithDefaultOrgTags: false };
    default:
      return state;
  }
}
