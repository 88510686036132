// schema
import * as ISchema from "#webapp/src/__schema__";

const minutesPer = (24 * 60) / Object.keys(ISchema.TimeSlot).length;

export type Slot =
  | "T_00_00"
  | "T_00_30"
  | "T_01_00"
  | "T_01_30"
  | "T_02_00"
  | "T_02_30"
  | "T_03_00"
  | "T_03_30"
  | "T_04_00"
  | "T_04_30"
  | "T_05_00"
  | "T_05_30"
  | "T_06_00"
  | "T_06_30"
  | "T_07_00"
  | "T_07_30"
  | "T_08_00"
  | "T_08_30"
  | "T_09_00"
  | "T_09_30"
  | "T_10_00"
  | "T_10_30"
  | "T_11_00"
  | "T_11_30"
  | "T_12_00"
  | "T_12_30"
  | "T_13_00"
  | "T_13_30"
  | "T_14_00"
  | "T_14_30"
  | "T_15_00"
  | "T_15_30"
  | "T_16_00"
  | "T_16_30"
  | "T_17_00"
  | "T_17_30"
  | "T_18_00"
  | "T_18_30"
  | "T_19_00"
  | "T_19_30"
  | "T_20_00"
  | "T_20_30"
  | "T_21_00"
  | "T_21_30"
  | "T_22_00"
  | "T_22_30"
  | "T_23_00"
  | "T_23_30";

const min: { [slot: string]: string } = {
  T_00_00: "12:00am",
  T_00_30: "12:30am",
  T_01_00: "1:00am",
  T_01_30: "1:30am",
  T_02_00: "2:00am",
  T_02_30: "2:30am",
  T_03_00: "3:00am",
  T_03_30: "3:30am",
  T_04_00: "4:00am",
  T_04_30: "4:30am",
  T_05_00: "5:00am",
  T_05_30: "5:30am",
  T_06_00: "6:00am",
  T_06_30: "6:30am",
  T_07_00: "7:00am",
  T_07_30: "7:30am",
  T_08_00: "8:00am",
  T_08_30: "8:30am",
  T_09_00: "9:00am",
  T_09_30: "9:30am",
  T_10_00: "10:00am",
  T_10_30: "10:30am",
  T_11_00: "11:00am",
  T_11_30: "11:30am",
  T_12_00: "12:00pm",
  T_12_30: "12:30pm",
  T_13_00: "1:00pm",
  T_13_30: "1:30pm",
  T_14_00: "2:00pm",
  T_14_30: "2:30pm",
  T_15_00: "3:00pm",
  T_15_30: "3:30pm",
  T_16_00: "4:00pm",
  T_16_30: "4:30pm",
  T_17_00: "5:00pm",
  T_17_30: "5:30pm",
  T_18_00: "6:00pm",
  T_18_30: "6:30pm",
  T_19_00: "7:00pm",
  T_19_30: "7:30pm",
  T_20_00: "8:00pm",
  T_20_30: "8:30pm",
  T_21_00: "9:00pm",
  T_21_30: "9:30pm",
  T_22_00: "10:00pm",
  T_22_30: "10:30pm",
  T_23_00: "11:00pm",
  T_23_30: "11:30pm",
};

const max: { [slot: string]: string } = {
  T_00_00: "12:30am",
  T_00_30: "1:00am",
  T_01_00: "1:30am",
  T_01_30: "2:00am",
  T_02_00: "2:30am",
  T_02_30: "3:00am",
  T_03_00: "3:30am",
  T_03_30: "4:00am",
  T_04_00: "4:30am",
  T_04_30: "5:00am",
  T_05_00: "5:30am",
  T_05_30: "6:00am",
  T_06_00: "6:30am",
  T_06_30: "7:00am",
  T_07_00: "7:30am",
  T_07_30: "8:00am",
  T_08_00: "8:30am",
  T_08_30: "9:00am",
  T_09_00: "9:30am",
  T_09_30: "10:00am",
  T_10_00: "10:30am",
  T_10_30: "11:00am",
  T_11_00: "11:30am",
  T_11_30: "12:00pm",
  T_12_00: "12:30pm",
  T_12_30: "1:00pm",
  T_13_00: "1:30pm",
  T_13_30: "2:00pm",
  T_14_00: "2:30pm",
  T_14_30: "3:00pm",
  T_15_00: "3:30pm",
  T_15_30: "4:00pm",
  T_16_00: "4:30pm",
  T_16_30: "5:00pm",
  T_17_00: "5:30pm",
  T_17_30: "6:00pm",
  T_18_00: "6:30pm",
  T_18_30: "7:00pm",
  T_19_00: "7:30pm",
  T_19_30: "8:00pm",
  T_20_00: "8:30pm",
  T_20_30: "9:00pm",
  T_21_00: "9:30pm",
  T_21_30: "10:00pm",
  T_22_00: "10:30pm",
  T_22_30: "11:00pm",
  T_23_00: "11:30pm",
  T_23_30: "12:00am",
};

const keys: { [slot: string]: Slot } = {
  T_00_00: "T_00_00" as Slot,
  T_00_30: "T_00_30" as Slot,
  T_01_00: "T_01_00" as Slot,
  T_01_30: "T_01_30" as Slot,
  T_02_00: "T_02_00" as Slot,
  T_02_30: "T_02_30" as Slot,
  T_03_00: "T_03_00" as Slot,
  T_03_30: "T_03_30" as Slot,
  T_04_00: "T_04_00" as Slot,
  T_04_30: "T_04_30" as Slot,
  T_05_00: "T_05_00" as Slot,
  T_05_30: "T_05_30" as Slot,
  T_06_00: "T_06_00" as Slot,
  T_06_30: "T_06_30" as Slot,
  T_07_00: "T_07_00" as Slot,
  T_07_30: "T_07_30" as Slot,
  T_08_00: "T_08_00" as Slot,
  T_08_30: "T_08_30" as Slot,
  T_09_00: "T_09_00" as Slot,
  T_09_30: "T_09_30" as Slot,
  T_10_00: "T_10_00" as Slot,
  T_10_30: "T_10_30" as Slot,
  T_11_00: "T_11_00" as Slot,
  T_11_30: "T_11_30" as Slot,
  T_12_00: "T_12_00" as Slot,
  T_12_30: "T_12_30" as Slot,
  T_13_00: "T_13_00" as Slot,
  T_13_30: "T_13_30" as Slot,
  T_14_00: "T_14_00" as Slot,
  T_14_30: "T_14_30" as Slot,
  T_15_00: "T_15_00" as Slot,
  T_15_30: "T_15_30" as Slot,
  T_16_00: "T_16_00" as Slot,
  T_16_30: "T_16_30" as Slot,
  T_17_00: "T_17_00" as Slot,
  T_17_30: "T_17_30" as Slot,
  T_18_00: "T_18_00" as Slot,
  T_18_30: "T_18_30" as Slot,
  T_19_00: "T_19_00" as Slot,
  T_19_30: "T_19_30" as Slot,
  T_20_00: "T_20_00" as Slot,
  T_20_30: "T_20_30" as Slot,
  T_21_00: "T_21_00" as Slot,
  T_21_30: "T_21_30" as Slot,
  T_22_00: "T_22_00" as Slot,
  T_22_30: "T_22_30" as Slot,
  T_23_00: "T_23_00" as Slot,
  T_23_30: "T_23_30" as Slot,
};

const all = Object.keys(min) as Slot[];

export const timeSlots = {
  min,
  max,
  minutesPer,
  keys,
  all,
};
