export interface IChromeFeedAction {
  type: ChromeFeedActions;
  data?: IChromeFeedActionPayload;
}

export interface IChromeFeedActionPayload {
  lastViewedFeedMillis?: number;
}

export enum ChromeFeedActions {
  RefetchFeed = "ChromeFeed_Refetch",
  SetLastViewedFeedMillis = "SetLastViewedFeedMillis",
}

export const refetchFeed = (): IChromeFeedAction => ({
  type: ChromeFeedActions.RefetchFeed,
});

export const setLastViewedFeedMillis = (lastViewedFeedMillis: number): IChromeFeedAction => ({
  type: ChromeFeedActions.SetLastViewedFeedMillis,
  data: {
    lastViewedFeedMillis,
  },
});
