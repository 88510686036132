import { getSiteUrl } from "@clockwise/client-commons/src/config/api";
import { isEmpty } from "lodash";
import { appPaths } from "../constants/route.constants";

type Hash = string;

type Options = {
  paramsObj?: ParamObj;
  hash?: Hash;
  relative?: boolean;
};

export function getUrlForFeature(featureName: keyof typeof appPaths, options?: Options) {
  const { relative = false, paramsObj = {}, hash = "" } = options || {};

  const base = relative ? "" : getSiteUrl();
  const baseUrl = `${base}${appPaths[featureName]}`;

  const searchParams = new URLSearchParams(paramsObj);
  const queryParams = searchParams.toString();

  const urlWithParams = isEmpty(queryParams) ? baseUrl : `${baseUrl}?${queryParams}`;

  const urlWithHash = `${urlWithParams}${hash}`;

  return urlWithHash;
}

export const isOnAppRouteAIScheduler = () => window.location.href.includes(getUrlForFeature("ai"));

interface CWWindow {
  kind: "window";
  target?: string;
  features?: string;
}

export type ParamObj = Record<string, string>;

export function routeToFeature({
  featureName,
  routerState,
  options,
}: {
  featureName: keyof typeof appPaths;
  routerState: CWWindow;
  options?: Options;
}) {
  const url = getUrlForFeature(featureName, options);

  if (routerState.kind === "window") {
    window.open(url, routerState.target, routerState.features);
  }
}

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};
