import pluralize from "pluralize";
export const copy = (daysLeftOnTrial: number) => {
  return {
    FocusTime: {
      text:
        "Tell Clockwise how much Focus Time you need each week, and we will automatically protect that time on your calendar.",
      header: `🕑 ${pluralize("day", daysLeftOnTrial, true)} left to try Focus Time for free`,
    },
    FlexMeeting: {
      text:
        "Tell Clockwise which meetings are flexible, and we will optimize your schedule and free up blocks of uninterrupted time, so you can focus on what matters.",
      header: `🕑 ${pluralize(
        "day",
        daysLeftOnTrial,
        true,
      )} left to try flexible meetings for free`,
    },
    FocusTimeEnabled: {
      text:
        "You’re upgraded to a free 30-day trial of the Teams plan. Try the full range of features on Clockwise before deciding which plan is right for you.",
      header: `🕑 ${pluralize("day", daysLeftOnTrial, true)} left to try Focus Time for free`,
    },
    FocusTimeSettings: {
      text: "Let Clockwise help you create and protect uninterrupted Focus Time on your calendar.",
      header: `🕑 ${pluralize("day", daysLeftOnTrial, true)} left to try Focus Time for free`,
    },

    FlexMeetingEnabled: {
      text:
        "You’re upgraded to a free 30-day trial of the Teams plan. Try the full range of features on Clockwise before deciding which plan is right for you.",
      header: `🕑 ${pluralize(
        "day",
        daysLeftOnTrial,
        true,
      )} left to try flexible meetings for free`,
    },
    FlexMeetingExpired: {
      text:
        "Tell Clockwise which meetings are flexible, and we will optimize your schedule and free up blocks of uninterrupted time for you and your teammates.",
      header: "Upgrade to mark meetings as flexible",
    },
    GroupLinksEnabled: {
      text:
        "Group Links help you easily schedule external meetings involving multiple members of your team.",
      header: `🕑 ${pluralize("day", daysLeftOnTrial, true)} left to try Group Links for free`,
    },
    GroupLinksExpired: {
      text:
        "Group Links help you easily schedule external meetings involving multiple members of your team.",
      header: "Upgrade to unlock group scheduling",
    },
    TeamAvailabilityEnabled: {
      text:
        "See when your teammates will be unavailable with a team calendar that updates automatically, no manual data entry needed.",
      header: `🕑 ${pluralize(
        "day",
        daysLeftOnTrial,
        true,
      )} left to try team availability calendar for free`,
    },
    TeamAvailabilityExpired: {
      text:
        "See when your teammates will be unavailable with a team calendar that updates automatically, no manual data entry needed.",
      header: "Upgrade to unlock team availability calendar",
    },
    NMDEnabled: {
      text:
        "Use Clockwise to automatically create and protect your no-meeting day so you and your team can maximize your Focus Time.",
      header: `🕑 ${pluralize(
        "day",
        daysLeftOnTrial,
        true,
      )} left to try team no-meeting day for free`,
    },
    NMDExpired: {
      text:
        "Use Clockwise to automatically create and protect your no-meeting day so you and your team can maximize your Focus Time.",
      header: "Upgrade to unlock team no-meeting day",
    },
    OrganizerPaidUserFree: {
      text:
        "The meeting organizer is on a paid plan and made this meeting flexible. You’re currently on the Free plan, please upgrade to make your meetings flexible.",
      header: "Meeting organizer is on a paid plan",
    },
    AIBeta: {
      text: "AI Scheduler is 100% free for you and anyone you invite.",
      header: "We're in beta!",
    },
    PrismBeta: {
      text: "Prism is 100% free for you and anyone you invite.",
      header: "We're in beta!",
    },
  };
};
