import { graphql } from "react-relay";

export const saveTeamSettingsMutation = graphql`
  mutation SaveTeamSettingsMutation($input: saveTeamSettingsMutationInput!) {
    saveTeamSettings(input: $input) {
      clientMutationId
      teamSettings {
        manageTeamCalendar
        slackSettings {
          notificationEnabled
          channelId
          timeSlot
        }
        syncWFHEvents
        minimumMinutesForSyncing
        useNoMeetingDay
        noMeetingDayOfWeek
        noMeetingDayEmailOnScheduleOver
        lastUpdated
      }
    }
  }
`;
