import { Switch as HeadlessSwitch } from "@headlessui/react";
import classNames from "classnames";
import { AriaAttributes, MouseEventHandler, default as React } from "react";
import { CwIdProps } from "../types/cw-id";
import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from "./Icons";
export type Size = "small" | "medium";

type Sentiment = "neutral" | "warning";

// In lieu of preflight, reset some native button style props so this looks consistent
const buttonResetClasses =
  "cw-bg-none cw-bg-transparent cw-text-inherit cw-border-0 cw-p-0 cw-align-left";

export interface Props extends CwIdProps {
  checked: boolean;
  onChange?: (checked: boolean) => void;
  onClick?: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  indeterminate?: boolean;
  sentiment?: Sentiment;
  name?: string;
  tabIndex?: number;
  "aria-label"?: AriaAttributes["aria-label"];
  "aria-labelledby"?: AriaAttributes["aria-labelledby"];
  label?: React.ReactNode;
  labelPlacement?: "start" | "end";
  size?: Size;
}

const colorClassesBySentiment: {
  [S in Sentiment]: { icon: string; hover: string; focus: string; active: string };
} = {
  neutral: {
    icon: "cw-text-positive",
    hover: "group-hover:cw-bg-positive-emphasis/5",
    active: "group-active:cw-bg-positive-emphasis/30",
    focus: "group-focus-visible:cw-bg-positive-emphasis/30",
  },
  warning: {
    icon: "cw-text-warning-muted",
    hover: "group-hover:cw-bg-warning-emphasis/5",
    active: "group-active:cw-bg-warning-emphasis/30",
    focus: "group-focus-visible:cw-bg-warning-emphasis/30",
  },
};

export const Checkbox = ({
  checked,
  onChange,
  onClick,
  disabled = false,
  size = "medium",
  indeterminate = false,
  sentiment = "neutral",
  label,
  labelPlacement = "end",
  ...switchProps
}: Props) => {
  const handleChange = React.useCallback(
    (_checked: boolean) => {
      if (onChange) {
        onChange(_checked);
      }
    },
    [onChange],
  );

  const iconColor = disabled
    ? "cw-text-neutral-disabled"
    : checked
    ? colorClassesBySentiment[sentiment].icon
    : "cw-text-neutral";
  const hoverColor = checked
    ? colorClassesBySentiment[sentiment].hover
    : "group-hover:cw-bg-neutral-emphasis/5";
  const activeColor = checked
    ? colorClassesBySentiment[sentiment].active
    : "group-active:cw-bg-neutral-emphasis/30";
  const focusColor = checked
    ? colorClassesBySentiment[sentiment].focus
    : "group-focus-visible:cw-bg-neutral-emphasis/30";
  const iconSize =
    size === "small" ? "cw-h-5 cw-w-5 cw-text-[20px]" : "cw-h-6 cw-w-6 cw-text-[24px]";
  const labelSize = size === "small" ? "cw-body-base" : "cw-body-lg";

  const isReversed = labelPlacement === "start";

  let IconClass = CheckBoxOutlineBlank;
  if (indeterminate) {
    IconClass = IndeterminateCheckBox;
  } else if (checked) {
    IconClass = CheckBox;
  }

  return (
    <HeadlessSwitch.Group
      as="span"
      className={classNames("cw-group", "cw-inline-flex cw-items-center cw-gap-3", {
        "cw-pointer-events-none": disabled,
        "cw-flex-row": !isReversed,
        "cw-flex-row-reverse cw-space-x-reverse": isReversed,
      })}
    >
      <HeadlessSwitch
        {...switchProps}
        checked={checked}
        onChange={handleChange}
        onClick={onClick}
        disabled={disabled}
        className={classNames(
          buttonResetClasses,
          "focus:cw-outline-none",
          "cw-cursor-pointer",
          "cw-group",
        )}
      >
        {/* The Checkbox */}
        <div
          className={classNames(
            "cw-inline-flex cw-shrink-0 cw-items-center cw-justify-center",
            "cw-text-center",
            "cw-my-2",
            "cw-relative",
            "cw-cursor-pointer",
            {
              "cw-pointer-events-none": disabled,
            },
          )}
        >
          {/* Invisible interaction target for easier clicking */}
          <span aria-hidden="true" className="cw-absolute -cw-inset-2 cw-bg-transparent" />
          {/* Checkbox aura */}
          <span
            aria-hidden="true"
            className={classNames(
              "cw-absolute cw-inset-0",
              "-cw-m-2",
              "cw-rounded-full",
              "cw-bg-transparent",
              hoverColor,
              activeColor,
              focusColor,
              "cw-pointer-events-none",
              "cw-transition cw-duration-200 cw-ease-in-out",
            )}
          />
          {/* Checkbox icon */}
          <IconClass className={classNames("cw-relative cw-leading-none", iconColor, iconSize)} />
        </div>
      </HeadlessSwitch>
      {/* Label */}
      {label && (
        <HeadlessSwitch.Label
          className={classNames("cw-text-left cw-cursor-pointer", labelSize, {
            "cw-text-neutral-disabled": disabled,
          })}
          onClick={onClick}
        >
          {label}
        </HeadlessSwitch.Label>
      )}
    </HeadlessSwitch.Group>
  );
};
