import { incrementValue, recordValue } from "#webapp/src/util/stats.util";

const HEAP_RECORD_INTERVAL = 1000 * 60 * 10; // 10 minutes
const NETWORK_RECORD_INTERVAL = 1000 * 60 * 2; // 2 minutes

export class Monitor {
  private readonly project: string;

  private startTime: number;
  private heapTimer: number;
  private networkTimer: number;

  constructor(project: string) {
    this.project = project;
  }

  public start = () => {
    this.startTime = new Date().getTime();

    this.heapTimer = window.setInterval(() => this.recordHeap(), HEAP_RECORD_INTERVAL);
    this.networkTimer = window.setInterval(() => this.recordNetwork(), NETWORK_RECORD_INTERVAL);
  };

  public stop = () => {
    if (this.heapTimer) {
      window.clearInterval(this.heapTimer);
    }

    if (this.networkTimer) {
      window.clearInterval(this.networkTimer);
    }
  };

  // buckets runtime duration into a string containing the 60 minute boundaries surrounding the duration
  private bucketRuntime = (runtime: number) => {
    const howManyHours = Math.floor(runtime / 60 / 1000 / 60);

    const bottom = howManyHours * 60;
    const top = (howManyHours + 1) * 60;

    return `${bottom}-${top}_minutes`;
  };

  private recordHeap = () => {
    // NB: this will not be an accurate recording
    // https://webplatform.github.io/docs/apis/timing/properties/memory/#Notes
    const memory = (window.performance as any).memory;

    if (!memory || !memory.usedJSHeapSize) {
      return;
    }

    const tags: { [key: string]: string } = {};

    const runtime = new Date().getTime() - this.startTime;
    tags["duration"] = this.bucketRuntime(runtime);
    tags["project"] = this.project;

    recordValue("client.heap.size", memory.usedJSHeapSize, tags);
  };

  private recordNetwork = () => {
    const networkEffectiveType =
      navigator && (navigator as any).connection && (navigator as any).connection.effectiveType;

    if (!networkEffectiveType) {
      return;
    }

    const tags: { [key: string]: string } = {};
    tags["project"] = this.project;
    tags["networkEffectiveType"] = networkEffectiveType;

    incrementValue("client.network", tags);
  };
}
