import { planCancellationRequestUrl } from "@clockwise/client-commons/src/constants/site";
import { Button } from "@clockwise/design-system";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";

type DowngradeDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DowngradeDialog = ({ isOpen, onClose, onConfirm }: DowngradeDialogProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} PaperProps={{ style: { maxWidth: 700, width: 700 } }}>
      <DialogTitle>
        <div className="cw-heading-2xl">Downgrade to the Free Plan?</div>
      </DialogTitle>
      <DialogContent className="cw-pt-0">
        <div className="cw-body-base">
          Our Customer Support team can assist you with downgrading your team to a Clockwise Free
          plan.
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <a
          className="cw-no-underline"
          href={planCancellationRequestUrl}
          rel="noreferrer"
          target="_blank"
        >
          <Button variant="text" onClick={onConfirm} sentiment="positive">
            Contact support
          </Button>
        </a>
      </DialogActions>
    </Dialog>
  );
};

export default DowngradeDialog;
