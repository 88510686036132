// schema
import * as ISchema from "#webapp/src/__schema__";

export { FastCrawlFlowState } from "@clockwise/client-commons/src/util/onboarding";

////////////
// COMMON
///////////
import { FLOW_STATE_KEYS } from "./flow-state";

// get the flow state off an org or user
function getGenericFlowState(
  flowStates: Omit<ISchema.IFlowStateConnection, "pageInfo"> | undefined,
  flowKey: string,
) {
  if (!flowStates || !flowStates.edges) {
    return undefined;
  }
  const flowState = flowStates.edges.filter((fs) => fs?.node?.flowKey === flowKey);
  return (flowState && flowState.length && flowState?.[0]?.node) || undefined;
}

///////////////
// FAST CRAWL
//////////////
export function getFastCrawlFlowState(org: ISchema.IOrg) {
  return getGenericFlowState(org?.flowStates, FLOW_STATE_KEYS.FAST_CRAWL);
}

export type UserWithFlowStates = {
  flowStates?: Omit<ISchema.IFlowStateConnection, "pageInfo">;
};

////////////////
// ONBOARDING
//////////////
// gets the onboarding flow state object off the user
export function getOnboardingFlowState(user: UserWithFlowStates) {
  return getGenericFlowState(user?.flowStates, FLOW_STATE_KEYS.INITIAL_ONBOARDING);
}

export function getWebOnboardingFlowState(user: UserWithFlowStates) {
  return getGenericFlowState(user?.flowStates, FLOW_STATE_KEYS.WEB_ONBOARDING);
}

export const getOnboardingFlowState2 = (
  flowStates?: {
    edges:
      | ({
          node?: {
            flowKey?: string | null;
            current?: {
              state?: string | null;
            } | null;
          } | null;
        } | null)[]
      | null;
  } | null,
) => {
  const flowState = flowStates?.edges?.filter(
    (fs) => fs?.node?.flowKey === FLOW_STATE_KEYS.INITIAL_ONBOARDING,
  );
  return flowState?.[0]?.node?.current?.state;
};
