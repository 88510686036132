/**
 * @generated SignedSource<<63fcf3b6f583641229f75ca7742b73c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DataSourceProvider = "Google" | "Office365" | "%future added value";
export type DataSourceStatus = "Okay" | "RequiresReAuth" | "%future added value";
export type EnableDataSourceSyncInput = {
  clientMutationId?: string | null;
  orgRelayId: string;
  sourceCalId: string;
};
export type EnableDataSourceSyncMutation$variables = {
  input: EnableDataSourceSyncInput;
};
export type EnableDataSourceSyncMutation$data = {
  readonly enableDataSourceSync: {
    readonly dataSource: {
      readonly id: string;
      readonly name: string | null;
      readonly email: string | null;
      readonly state: DataSourceStatus | null;
      readonly type: DataSourceProvider | null;
      readonly syncTargets: ReadonlyArray<{
        readonly calendarId: string | null;
        readonly emailAddress: string | null;
      } | null> | null;
    } | null;
  } | null;
};
export type EnableDataSourceSyncMutation = {
  variables: EnableDataSourceSyncMutation$variables;
  response: EnableDataSourceSyncMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EnableDataSourceSyncPayload",
    "kind": "LinkedField",
    "name": "enableDataSourceSync",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DataSource",
        "kind": "LinkedField",
        "name": "dataSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CrawlTarget",
            "kind": "LinkedField",
            "name": "syncTargets",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calendarId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emailAddress",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EnableDataSourceSyncMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EnableDataSourceSyncMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e3fd972d315b2669a4fd3a30bcb3f699",
    "id": null,
    "metadata": {},
    "name": "EnableDataSourceSyncMutation",
    "operationKind": "mutation",
    "text": "mutation EnableDataSourceSyncMutation(\n  $input: EnableDataSourceSyncInput!\n) {\n  enableDataSourceSync(input: $input) {\n    dataSource {\n      id\n      name\n      email\n      state\n      type\n      syncTargets {\n        calendarId\n        emailAddress\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d63cacd38c1637cf0dbf7f88bf8a5e6f";

export default node;
