/**
 * @generated SignedSource<<6da50f32b178f1a52535131e7b9c3ba6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamJoinCreateDialogRendererQuery$variables = {
  orgRelayId: string;
  excludeMyTeams?: boolean | null;
};
export type TeamJoinCreateDialogRendererQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"TeamJoinCreate_viewer">;
  };
};
export type TeamJoinCreateDialogRendererQuery = {
  variables: TeamJoinCreateDialogRendererQuery$variables;
  response: TeamJoinCreateDialogRendererQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeMyTeams"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orgRelayId"
},
v2 = {
  "kind": "Variable",
  "name": "excludeMyTeams",
  "variableName": "excludeMyTeams"
},
v3 = {
  "kind": "Variable",
  "name": "orgRelayId",
  "variableName": "orgRelayId"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "personId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamJoinCreateDialogRendererQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "TeamJoinCreate_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TeamJoinCreateDialogRendererQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "query",
                "value": ""
              }
            ],
            "concreteType": null,
            "kind": "LinkedField",
            "name": "teamQueryResultErrorable",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "query",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Team",
                    "kind": "LinkedField",
                    "name": "teams",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "teamName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "teamId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TeamRoleAndPersonId",
                        "kind": "LinkedField",
                        "name": "teamRoleAndPersonIds",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamMember",
                    "kind": "LinkedField",
                    "name": "teamMembers",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrgPerson",
                        "kind": "LinkedField",
                        "name": "person",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "primaryCalendarId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isYou",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "userId",
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "givenName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "familyName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "externalImageUrl",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "TeamQueryResult",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "367db718664721b7cf67f0b4f8e942ff",
    "id": null,
    "metadata": {},
    "name": "TeamJoinCreateDialogRendererQuery",
    "operationKind": "query",
    "text": "query TeamJoinCreateDialogRendererQuery(\n  $orgRelayId: ID!\n  $excludeMyTeams: Boolean\n) {\n  viewer {\n    ...TeamJoinCreate_viewer_3wymcI\n    id\n  }\n}\n\nfragment TeamJoinCreate_viewer_3wymcI on Viewer {\n  user {\n    id\n  }\n  id\n  teamQueryResultErrorable(query: \"\", orgRelayId: $orgRelayId, excludeMyTeams: $excludeMyTeams) {\n    __typename\n    ... on TeamQueryResult {\n      query\n      teams {\n        teamName\n        teamId\n        teamRoleAndPersonIds {\n          role\n          personId\n        }\n        id\n      }\n      teamMembers {\n        role\n        person {\n          __typename\n          id\n          primaryCalendarId\n          isYou\n          userId\n          personId\n          profile {\n            givenName\n            familyName\n            externalImageUrl\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c81612c454f29aae2fc815f33340e96a";

export default node;
