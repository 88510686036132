import { graphql } from "react-relay";

export const disableDataSourceSyncMutation = graphql`
  mutation DisableDataSourceSyncMutation($input: DisableDataSourceSyncInput!) {
    disableDataSourceSync(input: $input) {
      dataSource {
        syncTargets {
          calendarId
          emailAddress
        }
      }
    }
  }
`;
