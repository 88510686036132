import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { flatten } from "lodash";
import { Just, Maybe, Nothing } from "purify-ts/Maybe";
import { CalendarProposalQuery } from "../apollo/__generated__/CalendarProposal.generated";

type QueriedChatMessages = NonNullable<
  NonNullable<CalendarProposalQuery["viewer"]["user"]>["chatHistory"]
>["messages"];

type QueriedProposalResponse = Extract<QueriedChatMessages[0], { __typename: "ProposalResponse" }>;

export type ConflictCluster = Extract<
  QueriedChatMessages[0],
  { __typename: "ProposalResponse" }
>["proposal"]["conflictClusters"][0];

export type ConflictClusterWithConversationId = ConflictCluster & { conversationId: string };

const reduceToConflictResponses = (messages: QueriedChatMessages) =>
  messages.reduce((acc, message) => {
    if (message.__typename === "ProposalResponse") {
      acc.push(message);
    }
    return acc;
  }, [] as QueriedProposalResponse[]);

const reduceToActiveConflictResponses = (assistantResponses: QueriedProposalResponse[]) =>
  assistantResponses
    .reduce((acc) => {
      if (acc?.isNothing()) {
        return Just(assistantResponses.find((message) => message.proposal?.state === "ACTIVE"));
      }
      return acc;
    }, Nothing as Maybe<QueriedProposalResponse>)
    .extract();

export const parseConflictClustersGQL = (
  data: CalendarProposalQuery,
  dismissedConflictIds: string[],
): ConflictClusterWithConversationId[] => {
  const maybeConflict =
    Maybe.fromNullable(data)
      .chainNullable((data) => getValue(data?.viewer?.user?.chatHistory, "ChatHistory"))
      .map((chatHistory) => chatHistory?.messages)
      .map(reduceToConflictResponses)
      .map(reduceToActiveConflictResponses)
      .map((activeAssResp) => {
        return { ...activeAssResp?.proposal, conversationId: activeAssResp?.conversationId || "" };
      })
      .map((proposal) => {
        const valueWithConvoId = proposal?.conflictClusters?.map((cluster) => {
          return { ...cluster, conversationId: proposal?.conversationId || "" };
        });
        const retVal = [...flatten(valueWithConvoId || [])];
        return retVal;
      })
      .extract() || [];
  return maybeConflict.filter(
    (conflict) => conflict.isActiveConflict && !dismissedConflictIds.includes(conflict.id),
  );
};

export const grabEventIdsFromConflictClusters = (conflictClusters: ConflictCluster[]) => {
  const eventIds: string[] = [];
  conflictClusters.forEach((cluster) => {
    cluster.conflictEvents.forEach((event) => {
      eventIds.push(event.eventId);
    });
  });
  return eventIds;
};
