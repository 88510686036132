import { Button, Modal } from "@clockwise/design-system";
import React from "react";

const CHROME_MAX_WIDTH = 300;
export interface IProps {
  isChromeExtension?: boolean;
  open?: boolean;
  handleDismiss?: () => void;
}

export const CookieDialog = (props: IProps) => {
  const width = props.isChromeExtension ? CHROME_MAX_WIDTH : "lg";
  return (
    <Modal
      title="Please enable cookies to sign in to Clockwise"
      opened={!!props.open}
      size={width}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      onClose={() => window.location.reload()}
      classNames={{
        content: "cw-p-4",
      }}
    >
      <div className="cw-flex cw-flex-col cw-gap-4">
        <p>Google sign in requires that third party cookies are enabled to work properly.</p>
        <ul className="cw-list-decimal cw-ml-8 cw-flex cw-flex-col cw-gap-2 marker:cw-heading-xl">
          <li className="cw-pl-2">
            Open cookie settings by going to this url: chrome://settings/cookies
          </li>
          <li className="cw-pl-2">At the bottom, go to the the "Customized behaviors" section</li>
          <li className="cw-pl-2">
            Next to the "Sites that can always use cookies" section, click "Add"
          </li>
          <li className="cw-pl-2">
            In the dialog that opens, copy and paste: [*.]getclockwise.com
          </li>
          <li className="cw-pl-2">
            Repeat step 3, this time in the dialog copy and paste: [*.]google.com
          </li>
        </ul>
        <p>
          Without this setting, Clockwise may be unable to log you in or keep your session active.
        </p>
        <div className="cw-w-full cw-flex cw-justify-end cw-gap-4">
          {props.isChromeExtension && (
            <Button sentiment="neutral" onClick={props.handleDismiss}>
              Close
            </Button>
          )}
          <Button sentiment="positive" onClick={() => window.location.reload()}>
            Try again
          </Button>
        </div>
      </div>
    </Modal>
  );
};
