/**
 * @generated SignedSource<<02313dc139bb534f9a5de334b7422c22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InviteToBillingGroupMutationInput = {
  billingGroupId: string;
  clientMutationId?: string | null;
  orgRelayId: string;
  personIds: ReadonlyArray<string>;
};
export type InviteToBillingGroupMutation$variables = {
  input: InviteToBillingGroupMutationInput;
};
export type InviteToBillingGroupMutation$data = {
  readonly inviteToBillingGroup: {
    readonly clientMutationId: string | null;
  } | null;
};
export type InviteToBillingGroupMutation = {
  variables: InviteToBillingGroupMutation$variables;
  response: InviteToBillingGroupMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InviteToBillingGroupMutationPayload",
    "kind": "LinkedField",
    "name": "inviteToBillingGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteToBillingGroupMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteToBillingGroupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "218d473a936e6363ce7d107257f8b10d",
    "id": null,
    "metadata": {},
    "name": "InviteToBillingGroupMutation",
    "operationKind": "mutation",
    "text": "mutation InviteToBillingGroupMutation(\n  $input: InviteToBillingGroupMutationInput!\n) {\n  inviteToBillingGroup(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "2d2d11ed7ee09ac672a15b00322e33c8";

export default node;
