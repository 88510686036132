// IMPORTS
// schema
import * as ISchema from "#webapp/src/__schema__";

import { modernCache } from "#webapp/src/network/modern-network-layer";
import { commitMutation } from "react-relay";
import { Environment } from "relay-runtime";
import { updateOrgInviteMutation } from "./UpdateOrgInvite.gql";

// TYPES
interface IUpdateOrgInviteMutationResponse {
  updateOrgInvite: ISchema.IUpdateOrgInvitePayload;
}

// MUTATION
export function updateOrgInvite(
  environment: Environment,
  input: ISchema.IUpdateOrgInviteInput,
  onSuccess: (response?: ISchema.IUpdateOrgInvitePayload) => void,
  onError: (error?: any) => void,
) {
  commitMutation(environment, {
    onError,
    mutation: updateOrgInviteMutation,
    variables: { input },
    onCompleted: (response: IUpdateOrgInviteMutationResponse, errors?: Error[]) => {
      if (errors) {
        onError(errors[0]);
      } else {
        // if we're not in a modern environment, modernCache will be undefined
        if (modernCache) {
          modernCache.clear();
        }
        onSuccess(response.updateOrgInvite);
      }
    },
  });
}
