import { graphql } from "react-relay";

export const updateSettingMutation = graphql`
  mutation UpdateSettingMutation($input: UpdateSettingMutationInput!) {
    updateSetting(input: $input) {
      setting {
        id
        key {
          id
          name
          scope {
            id
            type
            value
          }
          target {
            id
            type
            value
          }
        }
        type {
          id
          valueType
          containerType
          required
        }
        value
      }
    }
  }
`;
