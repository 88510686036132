/**
 * @generated SignedSource<<281c7209c3ad518323c31c3b36a9b16b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UrnType = "Date" | "Email" | "Event" | "FlowState" | "Guest" | "Org" | "Phone" | "Services" | "Setting" | "Team" | "Teammate" | "TimeZone" | "User" | "WorkOsOrg" | "WorkingGroup" | "WorkingHours" | "%future added value";
export type UpsertTravelTimeMutationInput = {
  clientMutationId?: string | null;
  options: GoalPatchTravelTimeOptionsInput;
  orgId: string;
};
export type GoalPatchTravelTimeOptionsInput = {
  syncToGoogle?: boolean | null;
};
export type UpsertTravelTimeMutation$variables = {
  input: UpsertTravelTimeMutationInput;
};
export type UpsertTravelTimeMutation$data = {
  readonly upsertTravelTime: {
    readonly org: {
      readonly id: string;
      readonly userGoals: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly goalId: string;
            readonly targetUrn: {
              readonly id: string;
              readonly type: UrnType | null;
              readonly value: string | null;
            };
            readonly otherUrns: ReadonlyArray<{
              readonly id: string;
              readonly type: UrnType | null;
              readonly value: string | null;
            }>;
            readonly name: string;
            readonly isDeleted: boolean;
            readonly options: {
              readonly syncToGoogle: boolean;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type UpsertTravelTimeMutation = {
  variables: UpsertTravelTimeMutation$variables;
  response: UpsertTravelTimeMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpsertTravelTimeMutationPayload",
    "kind": "LinkedField",
    "name": "upsertTravelTime",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Org",
        "kind": "LinkedField",
        "name": "org",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1000000
              }
            ],
            "concreteType": "GoalConnection",
            "kind": "LinkedField",
            "name": "userGoals",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GoalEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Goal",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "goalId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Urn",
                        "kind": "LinkedField",
                        "name": "targetUrn",
                        "plural": false,
                        "selections": (v2/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Urn",
                        "kind": "LinkedField",
                        "name": "otherUrns",
                        "plural": true,
                        "selections": (v2/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDeleted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GoalOptions",
                        "kind": "LinkedField",
                        "name": "options",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "syncToGoogle",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "userGoals(first:1000000)"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpsertTravelTimeMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpsertTravelTimeMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "3f2e2b0df213333396f506a1f7a3b219",
    "id": null,
    "metadata": {},
    "name": "UpsertTravelTimeMutation",
    "operationKind": "mutation",
    "text": "mutation UpsertTravelTimeMutation(\n  $input: UpsertTravelTimeMutationInput!\n) {\n  upsertTravelTime(input: $input) {\n    org {\n      id\n      userGoals(first: 1000000) {\n        edges {\n          node {\n            id\n            goalId\n            targetUrn {\n              id\n              type\n              value\n            }\n            otherUrns {\n              id\n              type\n              value\n            }\n            name\n            isDeleted\n            options {\n              syncToGoogle\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ed4ac2027383aeeb720c9e81fe1c33b";

export default node;
