import { Button, TextField, Typography } from "@clockwise/design-system";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { LocationTypeEnum } from "@clockwise/schema/v2";
import { animated, config, useSpring } from "@react-spring/web";
import confetti from "canvas-confetti";
import { trim, union } from "lodash";
import React, { useMemo } from "react";
import { getSchedulingLinkUrl } from "../../util/scheduling.util";
import { BookedSlot, LinkMember } from "../scheduling-link";
import { OwnerBanner } from "../scheduling-links/OwnerBanner";
import { ConfirmationTitle } from "./ConfirmationTitle";
import { EventSummary } from "./event-summary";
import { Notification } from "./notification";

// here

export enum ConfirmationType {
  Book = "Book",
  Cancel = "Cancel",
  Reschedule = "Reschedule",
}

export interface SchedulingConfirmationProps {
  id?: string;
  meetingName: string;
  logoUrl?: string | null;
  description: string | null;
  duration: number;
  locationType: LocationTypeEnum | null;
  linkName?: string | null;
  slug?: string;
  showOwnerBanner: boolean;
  linkMembers: LinkMember[];
  attendees?: string[];
  prevSelectedTime?: string;
  bookedSlot: BookedSlot;
  eventDescription?: string | null;
  variant: ConfirmationType;
  completed: boolean;
  cancelRescheduleReason?: string;
  showConfetti?: boolean;
  banner: React.ReactNode;
  onCancel?: (reason: string) => Promise<void>;
  onEditLink: (slug: string) => void;
  onClickMyLinks: () => void;
}

const ANIMATION_DURATION = 5500;
const MAX_REASON_LENGTH = 400;

export const SchedulingConfirmation = ({
  id,
  meetingName,
  logoUrl,
  duration,
  locationType,
  description,
  linkName,
  slug,
  showOwnerBanner,
  linkMembers,
  attendees,
  prevSelectedTime,
  bookedSlot,
  variant,
  completed,
  cancelRescheduleReason,
  showConfetti = true,
  banner,
  onCancel,
  onEditLink,
  onClickMyLinks,
}: SchedulingConfirmationProps) => {
  const [cancelReason, setCancelReason] = React.useState("");
  const [cancelling, setCancelling] = React.useState(false);
  const [deleteFailed, setDeleteFailed] = React.useState(false);
  const confettiCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const confettiRef = React.useRef<confetti.CreateTypes | null>(null);
  const reason = cancelRescheduleReason || bookedSlot.reason;

  const confirmationStyles = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 200,
    config: config.slow,
  });

  // requestAnimationFrame returns a request id that we pass
  // to cancelAnimationFrame when we clean up the effect below
  const animationRequestRef = React.useRef<number | undefined>();

  const animate = React.useCallback(
    (timestamp: number) => {
      if (confettiCanvasRef.current) {
        if (!confettiRef.current) {
          confettiRef.current = confetti.create(confettiCanvasRef.current, {
            resize: true,
          });
        }

        // launch a few from the left edge
        void confettiRef.current({
          particleCount: 20,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
          disableForReducedMotion: true,
        });

        // and launch a few from the right edge
        void confettiRef.current({
          particleCount: 20,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
          disableForReducedMotion: true,
        });

        if (timestamp < ANIMATION_DURATION) {
          animationRequestRef.current = requestAnimationFrame(animate);
        }
      }
    },
    [showConfetti],
  );

  React.useEffect(() => {
    if (!showConfetti) {
      return;
    }

    // Reset some references
    if (confettiCanvasRef.current) {
      confettiRef.current = confetti.create(confettiCanvasRef.current, {
        resize: true,
      });
    }
  }, [animate, showConfetti]);

  React.useEffect(() => {
    if ([ConfirmationType.Book, ConfirmationType.Reschedule].includes(variant) && completed) {
      animationRequestRef.current = requestAnimationFrame(animate);
    }

    return () => {
      if (animationRequestRef.current) {
        cancelAnimationFrame(animationRequestRef.current);
      }
    };
  }, [animate, completed, variant]);

  // TODO:
  // so basically we've always assumed `linkMembers` are people on the scheduling link
  // and `attendees` are people who book on the scheduling link
  // and you could assume that if `linkMembers` > 1, it's a group link otherwise it's a normal link
  // but now in the round robin world `linkMembers` can be > 1 but we only book with 1 person...

  // so we need to have some way of knowing in these scenarios:
  // scheduled:
  // check attendees on return from mutation and resolve linkMembers to it to get profile info
  // rescheduled:
  // fetch attendees from scheduled meeting and resolve linkMembers to it to get profile info
  // cancelled:
  // fetch attendees from scheduled meeting and resolve linkMembers to it to get profile info

  // mergedAttendeesAsLinkMembersandCalendarIds exists because we cannot assume
  // that all `linkMembers` are also attendees.
  const mergedAttendeesAsLinkMembersandCalendarIds = useMemo(() => {
    const bookedLinkMembers: LinkMember[] = [];
    const bookedAttendees: string[] = [];

    union(bookedSlot.bookedLinkMembersAsCalendarIds, attendees).forEach((a) => {
      const linkMember = linkMembers.find((lm) => lm.id === a);
      if (linkMember) {
        bookedLinkMembers.push(linkMember);
      } else {
        bookedAttendees.push(a);
      }
    });

    return {
      bookedLinkMembers,
      bookedAttendees,
    };
  }, [linkMembers, bookedSlot]);

  const attendeeCalendarIdsWithOptionals = useMemo(
    () =>
      union(bookedSlot.bookedLinkMembersAsCalendarIds, attendees).map((a) => {
        const linkMember = linkMembers.find((lm) => lm.id === a);
        const optional = linkMember ? linkMember.required === false : false;
        return `${a}${optional ? " (optional)" : ""}`;
      }),
    [attendees, linkMembers, bookedSlot],
  );

  return (
    <animated.div className="cw-flex cw-justify-center" style={confirmationStyles}>
      <div className="cw-flex cw-flex-col cw-justify-start cw-w-full cw-max-w-[600px] cw-mx-5 cw-mt-9 cw-gap-8 cw-mb-[50px] sm:cw-mb-10">
        {showOwnerBanner && linkName && slug && id && (
          <OwnerBanner
            showOwnerBanner={showOwnerBanner}
            link={{ linkUrl: getSchedulingLinkUrl(linkName, slug), meetingSettings: { id, slug } }}
            onEditLink={onEditLink}
            onClickMyLinks={onClickMyLinks}
          />
        )}
        <div cw-id="booking-confirmation" className="cw-flex cw-flex-col cw-gap-5">
          {logoUrl && (
            <img
              src={logoUrl}
              className="cw-max-w-[300px] cw-max-h-[150px] cw-object-contain cw-self-start"
              alt="logo"
            />
          )}
          <ConfirmationTitle
            linkMembers={mergedAttendeesAsLinkMembersandCalendarIds.bookedLinkMembers}
            attendees={mergedAttendeesAsLinkMembersandCalendarIds.bookedAttendees}
            variant={variant}
            completed={completed}
            reasonForChange={reason}
            isYou={showOwnerBanner}
          />
          <EventSummary
            title={meetingName}
            duration={duration}
            prevDateTime={prevSelectedTime}
            dateTime={bookedSlot.bookedDateOrDateTime}
            timeZone={bookedSlot.bookedTimeZone}
            location={locationType}
            description={description}
            attendees={attendeeCalendarIdsWithOptionals}
            variant={variant}
            cancelled={variant === ConfirmationType.Cancel && completed}
          >
            {variant === ConfirmationType.Cancel && !completed && (
              <div className="cw-flex cw-flex-col cw-gap-4 cw-mt-3">
                <div className="cw-heading-2xl">Reason for cancellation</div>
                <TextField
                  autoFocus
                  fullWidth
                  placeholder="Reason"
                  aria-label="reason for cancelling"
                  value={cancelReason}
                  onChange={(e) => {
                    if (e.target.value.length > MAX_REASON_LENGTH) {
                      return;
                    }
                    setCancelReason(e.target.value);
                    setDeleteFailed(false);
                  }}
                />
                <div className="cw-flex cw-flex-col cw-gap-2">
                  <div className="cw-flex cw-items-center cw-gap-4">
                    <Button
                      onClick={async () => {
                        setCancelling(true);
                        setDeleteFailed(false);
                        try {
                          await onCancel?.(trim(cancelReason));
                          setCancelling(false);
                        } catch {
                          setCancelling(false);
                          setDeleteFailed(true);
                        }
                      }}
                      sentiment="positive"
                      disabled={cancelling}
                      aria-label="cancel meeting"
                    >
                      Cancel meeting
                    </Button>
                    {cancelling && <Loader size={18} sentiment="positive" />}
                  </div>
                  {deleteFailed && (
                    <Typography variant="body2" color="error">
                      There was an error cancelling the meeting. Please try again.
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </EventSummary>
          {[ConfirmationType.Book, ConfirmationType.Reschedule].includes(variant) && completed && (
            <Notification
              sentiment="success"
              message="A meeting invite has been sent to your email"
            />
          )}
        </div>
        <animated.div style={confirmationStyles}>{banner}</animated.div>
      </div>
    </animated.div>
  );
};
