import { CheckCircle } from "phosphor-react";
import React, { PropsWithChildren } from "react";
type Props = {};

export function FeatureBullet({ children }: PropsWithChildren<Props>) {
  return (
    <div className="cw-flex cw-self-start cw-my-1">
      <span className="cw-pt-[1px]">
        <CheckCircle width={16} height={16} />
      </span>
      <div className="cw-caption cw-ml-1">{children}</div>
    </div>
  );
}
