export const InviteModalCopy = {
  Invite: {
    header: "Share Clockwise with your coworkers",
    description:
      "When everyone you meet with is using Clockwise, it increases the likelihood of finding a better time for your meetings.",
    buttonText: "Send Invites",
    searchLabel: "Share Clockwise via email",
  },
  TrialExtension: {
    header: "Need more time? Extend your trial by inviting 3 others to Clockwise",
    description:
      "Clockwise works best when used as a team. Invite 3 or more colleagues to Clockwise and we'll give you all 90 days to trial the product together.",
    buttonText: "Done",
    searchLabel: "Invite teammates",
  },
};
