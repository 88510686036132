import { ApolloError } from "@apollo/client";
import { curry } from "lodash/fp";

export enum ErrorCode {
  NOT_FOUND = "NOT_FOUND",
  UNAUTHORIZED = "UNAUTHORIZED",
  INVALID = "INVALID",
  UNKNOWN = "UNKNOWN",
}

const ALL_KNOWN_ERROR_CODES = Object.values(ErrorCode);

/**
 * Returns all GraphQL errors and ensures each includes a valid ErrorCode in its `extensions` data
 * Any unknown or missing codes will be replaced with `ErrorCode.UNKNOWN`
 */
const getGraphQLErrors = (error: ApolloError) => {
  return error.graphQLErrors.map((e) => {
    return {
      ...e,
      extensions: {
        ...e.extensions,
        code:
          ALL_KNOWN_ERROR_CODES.find((code) => code === e.extensions?.code) ?? ErrorCode.UNKNOWN,
      },
    };
  });
};

const hasKnownError = curry((codes: ErrorCode[], error: ApolloError) =>
  getGraphQLErrors(error).some((e) => codes.includes(e.extensions.code)),
);

/**
 * Check if an Apollo error response from Gateway contains a "Not Found" error.
 */
export const hasNotFoundError = hasKnownError([ErrorCode.NOT_FOUND]);

/**
 * Check if an Apollo error response from Gateway contains an "Invalid" error.
 */
export const hasInvalidError = hasKnownError([ErrorCode.INVALID]);

/**
 * Check if an Apollo error response from Gateway contains a "Unauthorized" error.
 *
 * This typically indicates the user is not currently logged in (expired session, incognito mode,
 * etc) or lacks permissions (not an admin, link member, etc) to view the requested data
 */
export const hasUnauthorizedError = hasKnownError([ErrorCode.UNAUTHORIZED]);
