import { confettiActions } from "../actions/confetti.actions";

export interface IConfettiState {
  running: boolean;
}

interface IConfettiAction {
  type: string;
}

export const initialConfettiState = {
  running: false,
};

export function confettiReducer(
  state: IConfettiState = initialConfettiState,
  action: IConfettiAction,
) {
  switch (action.type) {
    case confettiActions.RUN:
      return { running: true };
    case confettiActions.STOP:
      return { running: false };
    default:
      return state;
  }
}
