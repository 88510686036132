import { Button } from "@clockwise/design-system";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";

type DeleteModalProps = {
  firstName: string;
  fullName: string;
  showDeleteConfirmationModal: boolean;
  onDeleteConfirmationModalClose: () => void;
  onClickConfirmDelete: () => void;
  isYou: boolean;
};

export const TeamSettingsDeleteModal = ({
  firstName,
  fullName,
  showDeleteConfirmationModal,
  onDeleteConfirmationModalClose,
  onClickConfirmDelete,
  isYou,
}: DeleteModalProps) => {
  return (
    <Dialog
      open={showDeleteConfirmationModal}
      onClose={onDeleteConfirmationModalClose}
      PaperProps={{ className: "cw-w-[700px] cw-text-center" }}
    >
      <DialogTitle>
        <h1 className="cw-heading-xl">
          {isYou
            ? "Are you sure you want to leave this team?"
            : `Remove ${fullName} from this team?`}
        </h1>
      </DialogTitle>
      <DialogContent className="cw-py-1">
        <p className="cw-text-muted">
          {isYou
            ? `If you leave this team, you will lose access to the team and all existing team features.`
            : `If you remove ${firstName}, they will lose access to the team and all existing team features.`}
        </p>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onDeleteConfirmationModalClose}>
          Cancel
        </Button>
        <Button variant="text" sentiment="destructive" onClick={onClickConfirmDelete}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
