import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import { Button, Link } from "@clockwise/design-system";
import { ExitToApp } from "@clockwise/icons";
import { CWIDProps } from "@clockwise/web-commons/src/util/cw-id.util";
import React from "react";
import { useLogout } from "../hooks/useLogout";

interface IProps extends CWIDProps {
  isMenuItem?: boolean;
  hasIcon?: boolean;
  anchor?: boolean;
  onLogout?: () => void;
}

export const LogoutBtn = ({
  anchor,
  isMenuItem,
  hasIcon,
  onLogout,
  "cw-id": cwId,
}: IProps & CWIDProps) => {
  const [logoutUser] = useLogout({
    onError: (error) => logger.error("An error occurred on logout", error),
  });

  const logout = async () => {
    track(TrackingEvents.AUTH.BEGIN_LOGOUT);
    await logoutUser();
    onLogout?.();
  };

  if (anchor) {
    return (
      <Link cw-id={cwId} onClick={logout}>
        Sign out
      </Link>
    );
  } else if (isMenuItem) {
    return (
      <>
        <button
          onClick={logout}
          cw-id={cwId}
          className="cw-w-full cw-cursor-pointer hover:cw-bg-default-hover cw-min-h-12 cw-flex cw-items-center cw-bg-default cw-gap-4 cw-border-none cw-px-4 cw-py-1.5"
          aria-label="Sign out"
        >
          {hasIcon && <ExitToApp className="cw-text-muted" />}
          <span className="cw-body-base cw-text-muted">Sign out</span>
        </button>{" "}
      </>
    );
  } else {
    return (
      <Button cw-id={cwId} onClick={logout}>
        <span>Sign out</span>
      </Button>
    );
  }
};
