import { Attendee, FetchedEventAttendee } from "../CurrentProposalContext";

export function toCurrentProposalAttendee<E extends FetchedEventAttendee>(attendee: E): Attendee {
  return {
    id: attendee.id,
    isOptional: attendee.isOptional ?? false,
    isOrganizer: attendee.isOrganizer,
    responseStatus: attendee.responseStatus,
    person: attendee.person,
    user: attendee.member?.user ?? null,
  };
}
