// schema
import * as ISchema from "#webapp/src/__schema__";

// libraries
import { PaymentMethod } from "@stripe/stripe-js";

// state
import { PaymentsActions } from "#webapp/src/state/actions/payments.actions";

import { PostMessageManager } from "#webapp/src/util/post-message.util";
import { IPlanPricing } from "#webapp/src/util/pricing.util";
import { proTier as proOrgTier } from "@clockwise/web-commons/src/constants/org-pricing.constants";

export interface IPricingDialogState {
  pricingDialogOpen: boolean;
  teamId?: string;
  initialView?: "Pricing" | "Checkout";
}

export interface IPaymentsAction {
  type: PaymentsActions;
  data: {
    paymentMethod?: PaymentMethod;
    plan?: IPlanPricing;
    subscriptionInterval?: ISchema.SubscriptionInterval;
    pricingDialogState?: IPricingDialogState;
    shouldRefetchDueToPlanUpgrade?: boolean;
    orgBillingGroupIds?: string[];
  };
}

export interface IPaymentsState {
  paymentMethod?: PaymentMethod;
  plan: IPlanPricing;
  subscriptionInterval: ISchema.SubscriptionInterval;
  pricingDialogState: IPricingDialogState;
  shouldRefetchDueToPlanUpgrade?: boolean;
  orgBillingGroupIds: string[];
}

export const initialPaymentsState: IPaymentsState = {
  plan: proOrgTier.planPricing,
  subscriptionInterval: ISchema.SubscriptionInterval.Yearly,
  pricingDialogState: {
    pricingDialogOpen: false,
  },
  shouldRefetchDueToPlanUpgrade: false,
  orgBillingGroupIds: [],
};

export function paymentsReducer(
  state: IPaymentsState = initialPaymentsState,
  action: IPaymentsAction,
) {
  switch (action.type) {
    case PaymentsActions.ClearPaymentMethod:
      return {
        ...state,
        paymentMethod: undefined,
        subscriptionInterval: ISchema.SubscriptionInterval.Yearly,
      };
    case PaymentsActions.UpdatePaymentMethod:
      return {
        ...state,
        paymentMethod: action.data.paymentMethod ?? state.paymentMethod,
        plan: action.data.plan ?? state.plan,
        subscriptionInterval: action.data.subscriptionInterval ?? state.subscriptionInterval,
      };
    case PaymentsActions.PricingDialogOpen:
      PostMessageManager.openSidebar();
      PostMessageManager.sendWebExtensionHovered(true);
      return {
        ...state,
        pricingDialogState: action.data.pricingDialogState ?? state.pricingDialogState,
      };
    case PaymentsActions.UpdateShouldRefetch:
      return {
        ...state,
        shouldRefetchDueToPlanUpgrade: action.data.shouldRefetchDueToPlanUpgrade,
      };
    case PaymentsActions.SetOrgBillingGroupIds:
      PostMessageManager.sendOrgBillingGroupIds(
        action.data.orgBillingGroupIds || state.orgBillingGroupIds,
      );
      return {
        ...state,
        orgBillingGroupIds: action.data.orgBillingGroupIds ?? state.orgBillingGroupIds,
      };
    default:
      return {
        ...state,
      };
  }
}
