import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { EventType } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { flatten, isEmpty } from "lodash";
import { Interval } from "luxon";
import { Just, Maybe, Nothing } from "purify-ts/Maybe";
import { GQLProposal } from "../../chat/ai-chat/utils/types";
import {
  ModifiedPlannerEventCardDiff,
  PlannerEventCardsByDay,
  TransparencyEnum,
} from "../../web-app-calendar/types";
import { CalendarProposalQuery } from "../apollo/__generated__/CalendarProposal.generated";

type QueriedChatMessages = NonNullable<
  NonNullable<CalendarProposalQuery["viewer"]["user"]>["chatHistory"]
>["messages"];

type QueriedProposalResponse = Extract<QueriedChatMessages[0], { __typename: "ProposalResponse" }>;

const reduceToProposalResponses = (messages: QueriedChatMessages) =>
  messages.reduce((acc, message) => {
    if (message.__typename === "ProposalResponse") {
      acc.push(message);
    }
    return acc;
  }, [] as QueriedProposalResponse[]);

const reduceToActiveProposals = (assistantResponses: QueriedProposalResponse[]) =>
  assistantResponses
    .reduce((acc) => {
      if (acc?.isNothing()) {
        return Just(assistantResponses.find((message) => message.proposal?.state === "ACTIVE"));
      }
      return acc;
    }, Nothing as Maybe<QueriedProposalResponse>)
    .extract();

type QueriedProposal = QueriedProposalResponse["proposal"];
type QueriedDiff = QueriedProposal["diffBlocks"][0]["diffs"][0];
type QueriedAddDiffSummary = Extract<QueriedDiff, { __typename: "AddDiffSummary" }>;
type QueriedModifyDiffSummary = Extract<QueriedDiff, { __typename: "ModifyDiffSummary" }>;
type QueriedConsequencesBlock = NonNullable<QueriedProposal["consequencesBlock"]>;
type QueriedYourConsequenceDiffSummary = NonNullable<QueriedConsequencesBlock["yourCalDiffs"]>[0];
type QueriedOthersConsequenceDiffSummary = NonNullable<
  QueriedConsequencesBlock["otherCalDiffs"]
>[0];

type QueriedConsequencesBlockDiffSummary =
  | QueriedYourConsequenceDiffSummary
  | QueriedOthersConsequenceDiffSummary;

type AggregateDiffSummaries = (
  | QueriedAddDiffSummary
  | QueriedModifyDiffSummary
  | QueriedConsequencesBlockDiffSummary
)[];

const reduceToEventCardsByDay = (
  diffSummaries?: AggregateDiffSummaries,
): PlannerEventCardsByDay => {
  if (!diffSummaries) {
    return {};
  }

  return diffSummaries.reduce((acc, diffSummary) => {
    let renderInterval: string | null = null;
    let calendarId = "";

    switch (diffSummary.__typename) {
      case "AddDiffSummary":
        renderInterval = diffSummary?.time ?? null;
        break;
      case "ModifyDiffSummary":
      case "YourConsequenceDiffSummary":
        renderInterval = diffSummary.updatedTime || diffSummary.currentTime;
        break;
      case "OthersConsequenceDiffSummary":
        renderInterval = diffSummary.updatedTime || diffSummary.currentTime;
        const affectedAttendees = diffSummary?.affectedAttendees;
        calendarId = affectedAttendees?.[0]?.calendarId;
        break;
      default:
        break;
    }

    if (!renderInterval) {
      return acc;
    }

    const date = Interval.fromISO(renderInterval).start.toISODate();

    if (!acc[date]) {
      acc[date] = [];
    }

    const zone = getRenderTimeZone();

    const isConsequenceDiffSummary =
      diffSummary.__typename === "YourConsequenceDiffSummary" ||
      diffSummary.__typename === "OthersConsequenceDiffSummary";

    const attendeesCalendarIds =
      diffSummary.__typename === "ModifyDiffSummary" || diffSummary.__typename === "AddDiffSummary"
        ? diffSummary.attendees.proposalAttendees.map(
            (attendee) => attendee.attendeePerson.primaryCalendar,
          )
        : [];

    const attendeeCount = attendeesCalendarIds.length;

    acc[date].push({
      active: false,
      annotaion: undefined,
      deemphasis: false,
      externalEventId:
        diffSummary.__typename === "ModifyDiffSummary" || isConsequenceDiffSummary
          ? diffSummary.eventId
          : undefined,
      interval: Interval.fromISO(renderInterval, { zone }),
      updatedTimeInterval:
        (diffSummary.__typename === "ModifyDiffSummary" || isConsequenceDiffSummary) &&
        diffSummary.updatedTime
          ? Interval.fromISO(diffSummary.updatedTime, { zone })
          : undefined,
      currentTimeInterval:
        (diffSummary.__typename === "ModifyDiffSummary" || isConsequenceDiffSummary) &&
        diffSummary.currentTime
          ? Interval.fromISO(diffSummary.currentTime, { zone })
          : undefined,
      proposedResponseState:
        (diffSummary.__typename === "ModifyDiffSummary" || isConsequenceDiffSummary) &&
        diffSummary.action.rsvp
          ? diffSummary.action.rsvp
          : undefined,
      isAllDay: false,
      isCancelled: false,
      smartHold: null, // Only calendar Events need this UI
      diffSummaryId: diffSummary.id,
      key: diffSummary.id,
      proposalType: diffSummary.action.type,
      responseState: "Proposed",
      text: diffSummary.title,
      calendarIds: [calendarId],
      attendeesCalendarIds: [], // Intentionally empty so that the event stripes are not shown
      attendees: [],
      affectedAttendees:
        (diffSummary.__typename === "OthersConsequenceDiffSummary" &&
          diffSummary.affectedAttendees) ||
        [],
      locked: false,
      transparency: TransparencyEnum.None,
      videoLink: null,
      eventPermissions: {
        canInviteOthers: false,
        canModify: false,
        canDelete: false,
        canRemove: false,
        canRSVP: false,
        canSeeOtherGuests: false,
      },
      flexStatus: null,
      attendeeCount,
      proposalMetaData: {
        attendeeIds: attendeesCalendarIds,
        isConsequence: isConsequenceDiffSummary,
      },
      type: EventType.Event,
    });

    return acc;
  }, {} as PlannerEventCardsByDay);
};

export const parseCalendarProposalGQL = (
  data: CalendarProposalQuery,
): Maybe<PlannerEventCardsByDay> => {
  return Maybe.fromNullable(data)
    .chainNullable((data) => getValue(data?.viewer?.user?.chatHistory, "ChatHistory"))
    .map((chatHistory) => chatHistory?.messages)
    .map(reduceToProposalResponses)
    .map(reduceToActiveProposals)
    .map((activeAssResp) => activeAssResp?.proposal)
    .map((proposal) => {
      const retVal = [
        ...flatten(proposal?.diffBlocks.map((block: any) => block.diffs) || []),
        ...(proposal?.consequencesBlock?.yourCalDiffs || []),
        ...(proposal?.consequencesBlock?.otherCalDiffs || []),
      ];

      return retVal;
    })
    .map(reduceToEventCardsByDay);
};

const collectDiffs = (proposal: GQLProposal): AggregateDiffSummaries => {
  return [
    ...flatten(proposal?.diffBlocks.map((block: any) => block.diffs) || []),
    ...(proposal?.consequencesBlock?.yourCalDiffs || []),
    ...(proposal?.consequencesBlock?.otherCalDiffs || []),
  ];
};

export const parseCalendarForSharedProposalGQL = (
  proposal: GQLProposal,
): Maybe<PlannerEventCardsByDay> => {
  return Maybe.of(reduceToEventCardsByDay(collectDiffs(proposal)));
};

const reduceToModifiedPlannerEventCardDiffs = (
  diffSummaries?: AggregateDiffSummaries,
): ModifiedPlannerEventCardDiff[] => {
  if (!diffSummaries) {
    return [];
  }

  return diffSummaries.reduce((acc, diffSummary) => {
    if (
      diffSummary.__typename !== "ModifyDiffSummary" &&
      diffSummary.__typename !== "AddDiffSummary"
    ) {
      return acc;
    }

    const schedulingTradeoffs = flatten(
      diffSummary.tradeoffBlocks.map((block) => block.schedulingTradeoffs),
    ).filter((tradeoff) => tradeoff.externalEventId);

    schedulingTradeoffs
      .filter((tradeoff) => !!tradeoff.externalEventId)
      .forEach((tradeoff) => {
        acc.push({
          externalEventId: tradeoff.externalEventId as string,
          text: tradeoff.title,
        });
      });

    return acc;
  }, [] as ModifiedPlannerEventCardDiff[]);
};

export const parseTradeoffsFromCalendarProposalGQL = (
  data: CalendarProposalQuery,
): Maybe<ModifiedPlannerEventCardDiff[]> => {
  return Maybe.fromNullable(data)
    .chainNullable((data) => getValue(data?.viewer?.user?.chatHistory, "ChatHistory"))
    .map((chatHistory) => chatHistory?.messages)
    .map(reduceToProposalResponses)
    .map(reduceToActiveProposals)
    .map((activeAssResp) => activeAssResp?.proposal)
    .map((proposal) => {
      const retVal = [
        ...flatten(proposal?.diffBlocks.map((block: any) => block.diffs) || []),
        ...(proposal?.consequencesBlock?.yourCalDiffs || []),
        ...(proposal?.consequencesBlock?.otherCalDiffs || []),
      ];

      return retVal;
    })
    .map(reduceToModifiedPlannerEventCardDiffs);
};

export const parseTradeoffsFromCalendarForSharedProposalGQL = (
  proposal: GQLProposal,
): Maybe<ModifiedPlannerEventCardDiff[]> => {
  return Maybe.of(reduceToModifiedPlannerEventCardDiffs(collectDiffs(proposal)));
};

export const isScheduleByMovingProposal = (data?: CalendarProposalQuery): boolean => {
  const proposalsWithConsequencesBlock =
    Maybe.fromNullable(data)
      .chainNullable((data) => getValue(data?.viewer?.user?.chatHistory, "ChatHistory"))
      .map((chatHistory) => chatHistory?.messages)
      .map(reduceToProposalResponses)
      .map(reduceToActiveProposals)
      .map((activeAssResp) => activeAssResp?.proposal)
      .filter((proposal) => !isEmpty(proposal?.consequencesBlock)) || [];

  return proposalsWithConsequencesBlock != Nothing;
};
