import { isValidEmailFormat } from "@clockwise/client-commons/src/util/email";
import { TextField } from "@clockwise/design-system";
import { animated, useTransition } from "@react-spring/web";
import { compact } from "lodash";
import { nanoid } from "nanoid";
import React, { useCallback, useState } from "react";

export const MAX_ADDITIONAL_ATTENDEES = 10; // Keep this in-sync with the backend.

export const emailsFromString = (_emails: string) => {
  const emails = _emails
    .split(",")
    .flatMap((email) => email.split(";"))
    .map((email) => {
      // When coping a list of attendees from Gcal, you get names in the format of "Name <email>",
      // which is a common format. If there's a name, remove it.
      if (email.includes("<")) {
        return email.split("<")[1].split(">")[0];
      }
      return email;
    })
    .map((email) => email.trim());

  return compact(emails);
};

// Note, we're using the word "attendee" in code (FE and BE), but using the word "guest" in the UI.
export const useAdditionalAttendees = () => {
  const [additionalAttendees, setAdditionalAttendees] = useState([{ email: "", key: nanoid() }]);

  const numInvalidAttendeeEmail = additionalAttendees.filter(
    (attendee) => attendee.email && !isValidEmailFormat(attendee.email),
  ).length;

  const numAdditionalAttendees = additionalAttendees.filter((a) => a.email).length;

  return {
    numInvalidAttendeeEmail,
    numAdditionalAttendees,
    additionalAttendees,
    setAdditionalAttendees,
  };
};

interface Props {
  additionalAttendees: { email: string; key: string }[];
  setAdditionalAttendees: React.Dispatch<React.SetStateAction<{ email: string; key: string }[]>>;
  setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
  showErrors: boolean;
}

export const AdditionalAttendees = ({
  additionalAttendees,
  setAdditionalAttendees,
  setShowErrors,
  showErrors,
}: Props) => {
  // Animates in the additional attendees in order to make the UI less jarring as the height increases.
  const attendees = useTransition(additionalAttendees, {
    from: { maxHeight: 0, opacity: 0 },
    enter: { maxHeight: 60, opacity: 1 },
    leave: { maxHeight: 0, opacity: 0, marginTop: -12, visibility: "hidden" },
    keys: (item) => item.key,
  });

  // Attempts to spead a string of emails into multiple attendees.
  const splitEmails = useCallback(
    (emailsString: string) => {
      const emailsArr = emailsFromString(emailsString);

      // If emailsString processed into multiple emails,
      //remove the list from the attendees and add the new ones each as its own entry.
      if (emailsArr.length > 1) {
        const newAdditionalAttendees = emailsArr.map((email) => ({
          email: email.trim(),
          key: nanoid(),
        }));

        setAdditionalAttendees([
          ...additionalAttendees.filter((a) => a.email && a.email !== emailsString),
          ...newAdditionalAttendees,
          {
            email: "",
            key: nanoid(),
          },
        ]);
      }
    },
    [additionalAttendees, setAdditionalAttendees],
  );

  // Keep track of this to be able to focus it upon deleting an attendee.
  const lastAttendeeTextField = React.useRef<HTMLInputElement | null>(null);

  return (
    <div>
      <legend
        className="cw-font-body cw-text-14 cw-text-default cw-leading-none cw-mb-1"
        aria-label="additional attendees"
      >
        Additional guests
      </legend>
      <fieldset className="cw-flex cw-flex-col cw-gap-3" aria-labelledby="additional attendees">
        {attendees((style, attendee, _, i) => (
          <animated.div style={style} key={attendee.key}>
            <TextField
              key={attendee.key}
              type="email"
              aria-label="additional guest"
              onKeyDown={(e) => {
                if (lastAttendeeTextField.current && e.key === "Enter") {
                  lastAttendeeTextField.current.focus();
                }
              }}
              error={
                // Last element is always empty, so don't show error for it.
                showErrors &&
                i !== additionalAttendees.length - 1 &&
                !isValidEmailFormat(attendee.email)
              }
              placeholder="Enter an email, or paste a list"
              value={attendee.email}
              onBlur={() => splitEmails(attendee.email)}
              ref={i === additionalAttendees.length - 1 ? lastAttendeeTextField : null}
              onPaste={(e) => {
                // Using onPaste to separate the responsibility from onChange for readability.
                if (attendee.email === "") {
                  splitEmails(e.clipboardData.getData("text/plain"));
                  return;
                }
              }}
              onChange={(e) => {
                setShowErrors(false);

                // Update attendee
                attendee.email = e.target.value;

                // After updating attendee, remove all empty entries other than the last one
                const newAdditionalAttendees = additionalAttendees.filter(
                  (a, i) => a.email !== "" || i === additionalAttendees.length - 1,
                );

                // Add an empty to the end if not there
                if (newAdditionalAttendees[newAdditionalAttendees.length - 1].email !== "") {
                  newAdditionalAttendees.push({
                    email: "",
                    key: nanoid(),
                  });
                }

                // If any removed, autofocus the last (empty) one to make it easier to add more and to keep focus for keyboard use.
                if (
                  newAdditionalAttendees.length < additionalAttendees.length &&
                  lastAttendeeTextField.current
                ) {
                  lastAttendeeTextField.current.focus();
                }

                setAdditionalAttendees(newAdditionalAttendees);
              }}
              fullWidth
            />
          </animated.div>
        ))}
      </fieldset>
    </div>
  );
};
