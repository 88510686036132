// schema
import * as ISchema from "#webapp/src/__schema__";
import { GraphEntityError } from "@clockwise/schema";
import { TeamWithIdAndName } from "@clockwise/web-commons/src/util/web-settings";

import { logger } from "#webapp/src/util/logger.util";

export const MAX_TEAM_SIZE = 50;

//////////////////
// TYPES
//////////////////
export enum TeammateInviteStatusEnum {
  Accepted = "Accepted",
  Pending = "Pending",
  Uninvited = "Uninvited",
}

export interface ITeammateStatusPerson {
  role?: ISchema.TeamMemberRole;
  person: ISchema.IOrgPerson;
  inviteStatus: TeammateInviteStatusEnum;
  isSuggested?: boolean;
  checked?: boolean;
  isInvited?: boolean;
}

// ~-~-~-~-~-~-~-
// Team Functions
// ~-~-~-~-~-~-~-
export const validateTeamErrorable = (teamErrorable: ISchema.TeamErrorable) => {
  if (teamErrorable.__typename !== "Team") {
    logger.error("fragment incomplete for validateTeamSettingsErrorable!");
    return;
  }
  return teamErrorable;
};

export const maybeGetPrimaryTeamFromOrg = (
  primaryTeam: TeamWithIdAndName | GraphEntityError | null,
) => {
  if (!primaryTeam) {
    return;
  } else if (primaryTeam.__typename !== "Team") {
    logger.error("failed to get primary team from org!");
    return;
  }
  return primaryTeam;
};

export const maybeGetOrgTeamsFromOrg = (org: ISchema.IOrg) => {
  if (!org.teamsForOrg) {
    logger.error("fragment incomplete for maybeGetOrgTeamsFromOrg");
    return;
  } else if (org.teamsForOrg.__typename !== "TeamList") {
    logger.error("failed to maybeGetOrgTeamsFromOrg");
    return;
  }

  return org.teamsForOrg.list;
};

// ~-~-~-~-~-~-~-
// Other functions
// ~-~-~-~-~-~-~-
export function augmentOrgPerson(
  person: ISchema.IOrgPerson,
  inviteStatus: TeammateInviteStatusEnum,
  role?: ISchema.TeamMemberRole,
  checked?: boolean,
  isSuggested?: boolean,
  isInvited?: boolean,
): ITeammateStatusPerson {
  return { role, person, inviteStatus, checked, isSuggested, isInvited };
}

export function augmentTeamMembers(
  teamMembers: ISchema.ITeamMember[],
  suggestedCalendarIds: string[],
  locallyInvitedMembers: Record<string, { invited: boolean; person: ISchema.IOrgPerson }>,
): ITeammateStatusPerson[] {
  return teamMembers.map((tm) => {
    const isSuggested =
      suggestedCalendarIds.findIndex((c) => c === tm.person.primaryCalendarId) > -1;
    const maybeInvitedMember = locallyInvitedMembers[tm.person.primaryCalendarId];
    const isInvited = maybeInvitedMember && maybeInvitedMember.invited;
    return augmentOrgPerson(
      tm.person,
      TeammateInviteStatusEnum.Accepted,
      tm.role,
      undefined,
      isSuggested,
      isInvited,
    );
  });
}

// ~-~-~-~-~-~-~-
// Compare userTeams
// ~-~-~-~-~-~-~-
export function isUserTeamsSame(
  userTeamsA: ISchema.TeamListErrorable,
  userTeamsB: ISchema.TeamListErrorable,
) {
  const teamsA = userTeamsA.__typename === "TeamList" ? userTeamsA.list : null;
  const teamsB = userTeamsB.__typename === "TeamList" ? userTeamsB.list : null;
  if (!teamsA && !teamsB) {
    return true;
  }

  if (!teamsA || !teamsB) {
    return false;
  }

  if (teamsA.length !== teamsB.length) {
    return false;
  }

  return teamsA.every((a) => teamsB.find((b) => b.teamId === a.teamId));
}

// Creates a team name using the creator's slug (first part of their email before the "@")
// Created for the use when an individual want to pay for pro, but does not have a team
// We create the team and assign this as the name
export const createTeamNameFromUserEmail = (userEmail: string) => {
  if (!userEmail) return null;
  const emailSegment = userEmail.split("@")[0];
  if (!emailSegment) return null;
  const capitalizedName = emailSegment[0].toUpperCase() + emailSegment.substring(1);
  return `${capitalizedName}'s team`;
};
