import { DayOffsetTimeSlot } from "@clockwise/schema";
import { fromGlobalId } from "./graphql";
import { translate } from "./translations";

namespace tagStateNS {
  export interface IEvent {
    annotatedEvent?: AnnotatedEvent | null;
    annotatedRecurringEvent?: AnnotatedEvent | null;
  }

  export enum Tags {
    AllowedEnd = "AllowedEnd",
    AllowedStart = "AllowedStart",
    EventColoringCategory = "EventColoringCategory",
    ExternalMeeting = "ExternalMeeting",
    Externality = "Externality",
    GoalMultiplier = "GoalMultiplier",
    IgnoreState = "IgnoreState",
    MinDuration = "MinDuration",
    MovementType = "MovementType",
    NotAMeeting = "NotAMeeting",
    Pin = "Pin",
    PostTravelTime = "PostTravelTime",
    PostTravelTimeManualOverride = "PostTravelTimeManualOverride",
    PreTravelTime = "PreTravelTime",
    PreTravelTimeManualOverride = "PreTravelTimeManualOverride",
    RoomBlacklist = "RoomBlacklist",
    RoomFlexibility = "RoomFlexibility",
    RoomWhitelist = "RoomWhitelist",
    SmartHold = "SmartHold",
    SyncOverride = "SyncOverride",
    TeamCalendarSyncDisabled = "TeamCalendarSyncDisabled",
    TravelTimeManualOverride = "TravelTimeManualOverride",
    ClockwiseScheduled = "ClockwiseScheduled",
  }

  export enum EFlex {
    Week = "Week",
    Day = "Day",
    Pin = "None",
    DayOfWeek = "DayOfWeek",
  }

  export enum IgnoreState {
    DoNotIgnore = "DoNotIgnore",
    IgnoreForAll = "IgnoreForAll",
    IgnoreForMe = "IgnoreForMe",
  }

  export enum SubjectTypeEnum {
    Email = "Email",
    Integration = "Integration",
    Org = "Org",
    Phone = "Phone",
    User = "User",
    Services = "Services",
  }

  export type TagState = {
    active: boolean;
    value: string | null;
    subjectType: string;
    subjectValue: string;
    lastModified: number;
  };

  export type OrgTag = {
    tag: string;
    state: TagState;
  };

  export type UserTag = {
    tag: string;
    states: TagState[] | null;
  };

  export type AnnotatedEvent = {
    orgTags?: OrgTag[] | null;
    userTags?: UserTag[] | null;
  };

  export type HasAnnotatedEvent = {
    annotatedEvent?: AnnotatedEvent | null;
    annotatedRecurringEvent?: AnnotatedEvent | null;
  };

  export interface IAutopilotTimeRange {
    allowedStart: DayOffsetTimeSlot | null;
    allowedEnd: DayOffsetTimeSlot | null;
  }

  export interface IAutopilotGenerateTagsOptions {
    flex?: EFlex;
    allowSimilarRooms?: boolean | null;
    timeRange?: IAutopilotTimeRange;
  }

  export const ROOM_FLEXIBILITY_VALUES = {
    FIXED: "Fixed",
    FLEXIBLE: "Flexible",
  };

  export const tagAliases: { [alias: string]: { name: string; value?: string } } = {
    [Tags.Pin]: { name: Tags.MovementType, value: "None" },
    [Tags.ExternalMeeting]: { name: Tags.Externality, value: "External" },
  };

  export function createTagForMutation(
    tagName: string,
    value?: string,
  ): { name: string; value?: string } {
    const alias = tagAliases[tagName];
    return alias || { value, name: tagName };
  }

  export function isTagStateUserSet(tagState?: TagState): boolean {
    return !!tagState && !!tagState.subjectType && tagState.subjectType === SubjectTypeEnum.User;
  }

  export function getOrgTagsOffEvent<E extends IEvent>(event?: E | HasAnnotatedEvent) {
    return (
      !!event && !!event.annotatedEvent && event.annotatedEvent && event.annotatedEvent.orgTags
    );
  }

  export function getRecurringOrgTagsOffEvent<E extends IEvent>(event?: E | HasAnnotatedEvent) {
    return (
      !!event &&
      !!event.annotatedRecurringEvent &&
      event.annotatedRecurringEvent &&
      event.annotatedRecurringEvent.orgTags
    );
  }

  export function getTagState(
    event: HasAnnotatedEvent,
    tagName: string,
    ignoreAliases = false,
  ): TagState | undefined {
    // e.g. PIN -> { MovementType: None }
    const alias = tagAliases[tagName];
    // e.g. when tag === MovementType, this will be PIN
    const aliasedTags = alias
      ? []
      : Object.keys(tagAliases).filter((aliasName) => tagAliases[aliasName].name === tagName);

    const resolvedTagName = (alias && alias.name) || tagName;

    const orgTags = getOrgTagsOffEvent(event) || [];
    const recurringOrgTags = getRecurringOrgTagsOffEvent(event) || [];

    const findTag = (ts: OrgTag[] | null) => ts && ts.find((t) => t.tag === resolvedTagName);
    const tagActive = (tag: any) => tag && tag.state && tag.state.active;

    const tagEdge = findTag(orgTags);
    const recurringTagEdge = findTag(recurringOrgTags);

    const eitherTagActive = tagActive(tagEdge) || tagActive(recurringTagEdge);
    let foundTagEdge;
    if (eitherTagActive) {
      // use the tag that's active
      foundTagEdge = tagActive(tagEdge) ? tagEdge : recurringTagEdge;
    } else {
      // use the tag that exists, if it exists
      foundTagEdge = tagEdge || recurringTagEdge;
    }

    const foundTag = foundTagEdge;

    // if no found tag, return undefined
    if (!foundTag) {
      return undefined;
    }

    // we've found a tag
    if (alias) {
      // if the alias value matches the tag value, return the tag, otherwise it doesn't exist
      return alias.value === foundTag.state.value ? foundTag.state : undefined;
    }

    const isFetchingAliasedValue = !!aliasedTags.find(
      (aliasedTag) => tagAliases[aliasedTag].value === foundTag.state.value,
    );

    // if the tag we're fetching is being used for an alias, e.g. fetched { MovementType: None }, which is aliased to PIN
    // return undefined, otherwise continue as normal
    if (ignoreAliases) {
      return foundTag.state;
    }
    return isFetchingAliasedValue ? undefined : foundTag.state;
  }

  export function getFlexFromEvent(event: IEvent, ignoreServicesAnnotations: boolean | undefined) {
    const orgTagState = getTagState(event, Tags.MovementType, true);
    const isUserSetFlex = isTagStateUserSet(orgTagState);

    if (!orgTagState || !orgTagState.value) {
      return EFlex.Pin;
    }

    if (!isUserSetFlex && ignoreServicesAnnotations) {
      return EFlex.Pin;
    }

    switch (orgTagState.value) {
      case EFlex.Week:
        return EFlex.Week;
      case EFlex.Day:
        return EFlex.Day;
      case EFlex.DayOfWeek:
        return EFlex.DayOfWeek;
      case EFlex.Pin:
      default:
        return EFlex.Pin;
    }
  }

  export function isAutopilotExplicitlyEnabled<E extends IEvent>(event: E) {
    const flex = getFlexFromEvent(event, true);

    if (flex) {
      return flex === EFlex.Day || flex === EFlex.Week || flex === EFlex.DayOfWeek;
    }

    return false;
  }

  export function getUserTagsOffEvent<E extends IEvent>(event: E, tag?: string) {
    const baseTags = (event.annotatedEvent && event.annotatedEvent.userTags) || [];

    const recurringTags =
      (event.annotatedRecurringEvent && event.annotatedRecurringEvent.userTags) || [];

    const allUserTags = [...baseTags, ...recurringTags];

    if (tag) {
      return allUserTags.filter((userTag) => userTag.tag === tag);
    } else {
      return allUserTags;
    }
  }

  export function getUserTagValueOffEvent<E extends IEvent>(
    event: E,
    tag: string,
    relayUserId?: string | null,
  ) {
    // get all tags off the event
    const allUserTags = getUserTagsOffEvent(event, tag);
    // next, collect all tag states (user tags have multiple states)
    const allStates = allUserTags.reduce((accum: TagState[], userTag) => {
      return accum.concat(userTag.states || []);
    }, []);
    // then get the services userId
    const userIdObj = relayUserId ? fromGlobalId(relayUserId) : null;
    const userId = !!userIdObj && userIdObj.id;
    if (!userId) {
      console.warn("failed to get a userId off relayUserId; check passed data");
      return null;
    }

    // now filter down to this user's states
    const userStates = allStates.filter((state) => {
      const isUserType = state.subjectType === SubjectTypeEnum.User;
      const isUsersTag = state.subjectValue === userId;
      return isUserType && isUsersTag;
    });
    // and pull out states created by services
    const servicesStates = allStates.filter(
      (state) => state.subjectType === SubjectTypeEnum.Services,
    );

    if (userStates && userStates.length) {
      // prefer user states
      // tie break by modified date
      return userStates.sort((a, b) => {
        return a.lastModified - b.lastModified;
      })[0];
    } else if (servicesStates && servicesStates.length) {
      // fall-back to services states
      // tie break by modified date
      return servicesStates.sort((a, b) => {
        return a.lastModified - b.lastModified;
      })[0];
    } else {
      return null;
    }
  }

  export function generateRoomFlexibilityTag(isFlexible: boolean) {
    const flexibility = isFlexible
      ? ROOM_FLEXIBILITY_VALUES.FLEXIBLE
      : ROOM_FLEXIBILITY_VALUES.FIXED;

    return {
      name: Tags.RoomFlexibility,
      value: flexibility,
    };
  }

  export function generateAutopilotTags({
    flex,
    allowSimilarRooms,
  }: IAutopilotGenerateTagsOptions) {
    const tagsToAdd: { name: string; value?: string }[] = [];
    const tagNamesToRemove: string[] = [];

    if (flex) {
      tagsToAdd.push({
        name: Tags.MovementType,
        value: flex,
      });
    }

    // handle rooms
    const userSetAllowSimilarRooms = typeof allowSimilarRooms === "boolean";
    if (userSetAllowSimilarRooms) {
      const roomFlexibilityTag = generateRoomFlexibilityTag(!!allowSimilarRooms);
      tagsToAdd.push(roomFlexibilityTag);
    }

    return {
      tagsToAdd,
      tagNamesToRemove,
    };
  }

  export function getUserIdFromTagState(tagState?: TagState) {
    if (tagState && tagState.subjectType === SubjectTypeEnum.User) {
      return tagState.subjectValue;
    }

    return null;
  }

  export function getUserIdFromEvent(event: IEvent, tagName: string) {
    const tagState = getTagState(event, tagName, true);
    return getUserIdFromTagState(tagState);
  }

  export function getTagValue(event: HasAnnotatedEvent, tag: string): string | undefined {
    const orgTagState = getTagState(event, tag);
    return orgTagState && orgTagState.value ? orgTagState.value : undefined;
  }
  export function isTagActive(event: HasAnnotatedEvent, tag: string): boolean {
    const orgTagState = getTagState(event, tag);
    return !!orgTagState && !!orgTagState.active;
  }

  /**
   * Get the raw user id of the Autopilot enabler from an event.
   *
   * @param event the event to extract from
   * @param onlyActive whether this applies to only active flexibility settings
   */
  export function getAutopilotEnablerUserId(
    event: IEvent | HasAnnotatedEvent,
    onlyActive = true,
  ): string | undefined {
    const tag = getTagState(event, "RoomFlexibility");

    if (!tag || tag.subjectType !== "User" || (onlyActive && !tag.active)) {
      return;
    }

    return tag.subjectValue;
  }
}

export const {
  Tags,
  EFlex,
  IgnoreState,
  SubjectTypeEnum,
  ROOM_FLEXIBILITY_VALUES,
  tagAliases,
  isTagStateUserSet,
  getOrgTagsOffEvent,
  getRecurringOrgTagsOffEvent,
  getTagState,
  getFlexFromEvent,
  isAutopilotExplicitlyEnabled,
  getUserTagsOffEvent,
  getUserTagValueOffEvent,
  generateRoomFlexibilityTag,
  generateAutopilotTags,
  getUserIdFromTagState,
  getUserIdFromEvent,
  getTagValue,
  isTagActive,
  getAutopilotEnablerUserId,
  createTagForMutation,
} = tagStateNS;

export type IEvent = tagStateNS.IEvent;
export type Tags = tagStateNS.Tags;
export type TagState = tagStateNS.TagState;
export type OrgTag = tagStateNS.OrgTag;
export type UserTag = tagStateNS.UserTag;
export type AnnotatedEvent = tagStateNS.AnnotatedEvent;
export type HasAnnotatedEvent = tagStateNS.HasAnnotatedEvent;
export type EFlex = tagStateNS.EFlex;
export type IgnoreState = tagStateNS.IgnoreState;
export type SubjectTypeEnum = tagStateNS.SubjectTypeEnum;
export type IAutopilotTimeRange = tagStateNS.IAutopilotTimeRange;

namespace autopilotTagNS {
  interface IAttendee {
    urnValue: string;
  }

  export interface IEvent {
    annotatedEvent: AnnotatedEvent | null;
    locked: boolean | null;
    isAllDay: boolean | null;
    organizer: IAttendee;
  }

  export enum EAutopilotWarning {
    AllDayEvent,
    ExternalAttendee,
    NonUserOrganizer,
    NoPermission,
    NotAMeeting,
    Private,
    OrganizerApPaused,
  }

  export function isOrganizedByGroup(event: IEvent) {
    if (!event.organizer || !event.organizer.urnValue) {
      return false;
    }

    return /@group\.calendar\.google\.com$/.test(event.organizer.urnValue);
  }

  // checks the event for potential autopilot warnings
  export function maybeGetWarningType(
    event: IEvent,
    canAutopilot: boolean,
    attendeeNotInDomain: boolean,
    organizerNotClockwiseUser: boolean,
    isAllDayEvent: boolean,
    isOrganizerFreeAndUserPaid?: boolean,
  ) {
    const isNotAMeeting = isTagActive(event, Tags.NotAMeeting);
    const isGroupOrganizer = isOrganizedByGroup(event);
    // note: order matters here since the first one hit will be the warning displayed, event if multiple match

    if (event.locked) {
      return EAutopilotWarning.Private;
    } else if (isAllDayEvent) {
      return EAutopilotWarning.AllDayEvent;
    } else if (isNotAMeeting) {
      return EAutopilotWarning.NotAMeeting;
    } else if (attendeeNotInDomain) {
      return EAutopilotWarning.ExternalAttendee;
    } else if (!canAutopilot && isGroupOrganizer) {
      return EAutopilotWarning.NoPermission;
    } else if (!canAutopilot && organizerNotClockwiseUser) {
      return EAutopilotWarning.NonUserOrganizer;
    } else if (isOrganizerFreeAndUserPaid) {
      return EAutopilotWarning.OrganizerApPaused;
    }
    return undefined;
  }

  // TODO (lsanwick) Update all warning text for AP rebrand
  export function getWarningText(
    t: ReturnType<typeof translate>,
    warning?: EAutopilotWarning,
    organizerEmail?: string,
  ) {
    switch (warning) {
      case EAutopilotWarning.AllDayEvent:
        return t("autopilot-warning:all-day-event");
      case EAutopilotWarning.NonUserOrganizer:
        if (organizerEmail) {
          return t("autopilot-warning:non-user-organizer-with-email", { organizerEmail });
        } else {
          return t("autopilot-warning:non-user-organizer");
        }
      case EAutopilotWarning.ExternalAttendee:
        return t("autopilot-warning:external-attendee");
      case EAutopilotWarning.NoPermission:
        return t("autopilot-warning:no-permission");
      case EAutopilotWarning.NotAMeeting:
        return t("autopilot-warning:not-a-meeting");
      case EAutopilotWarning.Private:
        return t("autopilot-warning:private");
      case EAutopilotWarning.OrganizerApPaused:
        return "Clockwise will not move this meeting because the organizer is on a free plan.";
      default:
        return "";
    }
  }
}

export const {
  EAutopilotWarning,
  isOrganizedByGroup,
  maybeGetWarningType,
  getWarningText,
} = autopilotTagNS;

export type EAutopilotWarning = autopilotTagNS.EAutopilotWarning;
export type IEventTags = autopilotTagNS.IEvent;
