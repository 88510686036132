import { DateTime } from "luxon";
import { useState } from "react";
import { useInterval } from "./useInterval";

/**
 * Get the current time updated at a regular interval.
 *
 * @param refreshMs How frequently to update the returned current time. Defaults to 10s
 * @returns DateTime of the current time.
 */
export function useCurrentTime(refreshMs = 10_000) {
  const [currentTime, setCurrentTime] = useState(DateTime.now());
  useInterval(() => setCurrentTime(DateTime.now()), refreshMs);

  return currentTime;
}
