import { colors, greys } from "@clockwise/web-commons/src/styles/color.styles";
import * as typeStyles from "@clockwise/web-commons/src/styles/type.styles";

/////////////////////////////
// Checkbox styles
/////////////////////////////
export const item = {
  padding: "5px 2px",
} as const;

export const row = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
} as const;

export const suggested = {
  fontSize: 13,
} as const;

export const tooltip = {
  icon: {
    color: greys.cloudyBlue,
    top: "1px",
  },
  content: {
    color: greys.white,
  },
} as const;

export const name = {
  color: greys.dark,
} as const;

export const email = {
  fontSize: typeStyles.size.md,
  color: greys.slate,
} as const;

export const badge = {
  position: "absolute",
  backgroundColor: colors.blueCool.standard,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 2,
} as const;
