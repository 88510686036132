import { getSiteUrl } from "@clockwise/client-commons/src/config/api";
import { paths } from "@clockwise/client-commons/src/constants/site";
import { Errorable, getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { DAY_STRINGS } from "@clockwise/client-commons/src/util/event-recurrence";
import { SuggestedSlotTagEnum } from "@clockwise/schema/v2";
import { find } from "lodash";
import { DateTime } from "luxon";
import { LinkMember } from "../ui/scheduling-link";

export const getFormattedDateLuxon = (date: DateTime, forceLong = false, forceVeryLong = false) => {
  const endOfCurrentWeek = new Date();
  endOfCurrentWeek.setDate(endOfCurrentWeek.getDate() + Math.abs(6 - endOfCurrentWeek.getDay()));

  date.setLocale("en-US");

  if (date.toMillis() <= endOfCurrentWeek.getTime() && !forceLong) {
    // This week, a relative time
    return date.weekdayShort;
  } else if (forceVeryLong) {
    return date.weekdayShort + ", " + date.toLocaleString({ month: "short", day: "numeric" });
  } else {
    return date.toLocaleString({ month: "short", day: "numeric" });
  }
};

export const getFormattedDate = (date: Date, forceLong = false, forceVeryLong = false) => {
  const endOfCurrentWeek = new Date();
  endOfCurrentWeek.setDate(endOfCurrentWeek.getDate() + Math.abs(6 - endOfCurrentWeek.getDay()));

  if (date <= endOfCurrentWeek && !forceLong) {
    // This week
    return DAY_STRINGS[date.getDay()].substring(0, 3);
  } else if (forceVeryLong) {
    return (
      DAY_STRINGS[date.getDay()].substring(0, 3) +
      ", " +
      new Intl.DateTimeFormat("en-US", { month: "short", day: "numeric" }).format(new Date(date))
    );
  } else {
    return new Intl.DateTimeFormat("en-US", { month: "short", day: "numeric" }).format(
      new Date(date),
    );
  }
};

export const getFormattedTimeLuxon = (date: DateTime) => {
  return date
    .setLocale("en-US")
    .toLocaleString({ hour: "numeric", minute: "numeric" })
    .toLowerCase()
    .replace(/\s/g, "");
};

export const getSchedulingLinkPath = (username: string, slug: string) =>
  paths.schedulingLink.replace(":linkName", username).replace(":slug", slug);
export const getSchedulingLinkUrl = (username: string, slug: string, removeHttps = false) => {
  const url = `${getSiteUrl()}${getSchedulingLinkPath(username, slug)}`;
  return removeHttps
    ? url
        .replace("http://www.", "")
        .replace("https://www.", "")
        .replace("http://", "")
        .replace("https://", "")
    : url;
};

export const getEditSchedulingLinkPath = (linkName: string, slug: string) =>
  paths.editSchedulingLink.replace(":slug", slug).replace(":linkName", linkName);
export const getEditSchedulingLinkUrl = (linkName: string, slug: string) => {
  return `${getSiteUrl()}${getEditSchedulingLinkPath(linkName, slug)}`;
};
export const getCancelBookingPath = (bookingId: string) => `/booking/${bookingId}/cancel`;

export const sanitizeSlug = (rawSlug: string) => rawSlug.toLowerCase().replace(/\s/g, "-");

export const validateSlug = (slug: string) => {
  let slugError: string | null = null;
  let blockChange = false;
  if (slug.length === 0) {
    slugError = "A name is needed to create a URL.";
  } else if (slug.length > 40) {
    slugError = "This name is too long, the max length is 40 characters.";
    blockChange = true;
  } else if (slug.match(/[^a-zA-Z0-9-_]/)) {
    slugError = "Link URLs can only contain letters, numbers, dashes, and underscores.";
    blockChange = true;
  } else {
    slugError = "";
  }

  return { slugError, blockChange };
};

export type CanRescheduleArgs =
  | Errorable<{
      __typename: "CanScheduleResponseList";
      list: { canReschedule: boolean }[];
    }>
  | null
  | undefined;

/**
 * For a given user and event, can an event
 * be rescheduled
 * @param canScheduleResponse
 * @returns boolean
 */
export const canReschedule = (canScheduleResponse: CanRescheduleArgs) => {
  return getValue(canScheduleResponse)?.list[0]?.canReschedule || false;
};

export const findOwner = (members: LinkMember[]) => find(members, { owner: true });

/// Enum utils

export const toGatewayTagType = (
  tag: "Best" | "Inconvenient" | null,
): SuggestedSlotTagEnum | null => {
  switch (tag) {
    case "Best":
      return SuggestedSlotTagEnum.Best;
    case "Inconvenient":
      return SuggestedSlotTagEnum.Inconvenient;
    default:
      return null;
  }
};
