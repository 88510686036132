const spacing = {
  xxlg: 36,
  xlg: 30,
  lg24: 24,
  lg: 20,
  std: 15,
  sm: 10,
  xs: 5,
  xxs: 2,
};

export default spacing;
