import { graphql } from "react-relay";

export const subscription = graphql`
  subscription UpdateTeamCalendarStateSubscription(
    $input: UpdateTeamCalendarStateSubscriptionInput!
  ) {
    updateTeamCalendarState(input: $input) {
      teamCalendarState {
        calendarId
      }
    }
  }
`;
