import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { MockLink, MockedResponse } from "@apollo/client/testing";
import React, { PropsWithChildren, useContext } from "react";
import { gatewayTypePolicies } from "./typePolicies";
const GatewayApolloContext = React.createContext<ApolloClient<NormalizedCacheObject> | null>(null);

export const GatewayApolloProvider = ({
  client,
  children,
}: PropsWithChildren<{ client: ApolloClient<NormalizedCacheObject> }>) => {
  return <GatewayApolloContext.Provider value={client}>{children}</GatewayApolloContext.Provider>;
};

export const useGatewayClient = () => {
  const client = useContext(GatewayApolloContext);
  if (!client) {
    throw new Error(
      "The Apollo client for gateway is not set. useGatewayClient must be a descendent of GatewayApolloProvider.",
    );
  }
  return client;
};
export const useGatewayMutation: typeof useMutation = (mutation, options) => {
  const client = useGatewayClient();
  return useMutation(mutation, { ...options, client });
};

export const useGatewayQuery: typeof useQuery = (query, options) => {
  const client = useGatewayClient();
  return useQuery(query, { ...options, client });
};

export const useGatewayLazyQuery: typeof useLazyQuery = (query, options) => {
  const client = useGatewayClient();
  return useLazyQuery(query, { ...options, client });
};

export const GatewayMockedProvider = ({
  mocks = [],
  children,
}: PropsWithChildren<{ mocks?: MockedResponse<Record<string, any>>[] }>) => {
  const mockLink = new MockLink(mocks);
  const errorLoggingLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );

    if (networkError) console.log(`[Network error]:`, networkError);
  });
  const link = ApolloLink.from([errorLoggingLink, mockLink]);

  const mockGateway = new ApolloClient({
    link,
    cache: new InMemoryCache({ addTypename: true, typePolicies: gatewayTypePolicies }),
  });
  return <GatewayApolloProvider client={mockGateway}>{children}</GatewayApolloProvider>;
};
