import React, { PropsWithChildren } from "react";
import { DeterioratingClock } from "../svgs/DeterioratingClock";

export function ErrorScreen({ title, children }: PropsWithChildren<{ title?: string }>) {
  return (
    <div className="cw-flex cw-items-center cw-text-center cw-flex-col">
      <DeterioratingClock />
      {title ? <div className="cw-heading-xl cw-mt-4">{title}</div> : null}
      <div className="cw-body-lg cw-p-5">{children}</div>
    </div>
  );
}
