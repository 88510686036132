import { ApolloError, useQuery, useSubscription } from "@apollo/client";
import { isInFatalState } from "@clockwise/client-commons/src/util/onboarding";
import { transform } from "@clockwise/web-commons/src/util/transform.util";
import { find, isNull } from "lodash";
import { compact, map } from "lodash/fp";
import React, { PropsWithChildren, createContext, useContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { TOnboardingFlowStatus, setOnboardingFlowState } from "../state/actions/onboarding.actions";
import { getCurrentOrg } from "../util/org.util";
import { CurrentFlowStateDocument } from "./__generated__/CurrentFlowState.generated";
import { FlowStateSubscriptionDocument } from "./__generated__/FlowStateSubscription.generated";
export enum FlowState {
  InitialOnboarding = "InitialOnboarding",
  FastCrawl = "FastCrawl",
  ChromeFeedOnboarding = "ChromeFeedOnboarding",
  WebOnboarding = "WebOnboarding",
}

const safelyGetReduxStateFromFlowState = (flowState: FlowStateResult) => {
  const current = flowState.current;

  if (!isNull(current) && !isNull(current.state) && !isNull(current.percentComplete)) {
    const reduxCurrentState: TOnboardingFlowStatus = {
      state: current.state,
      percentComplete: current.percentComplete,
      errorMessage: current.errorMessage,
    };
    return {
      previousState: flowState.previousState,
      current: reduxCurrentState,
    };
  }

  // if any fields are null that we don't expect, return null
  return null;
};

export function useFlowStateQuery() {
  const dispatch = useDispatch();
  const { data, loading, error } = useQuery(CurrentFlowStateDocument, {
    pollInterval: 10_000,
  });
  const org = getCurrentOrg(data?.viewer);
  const orgId = org?.id;

  const userFlowStates = data?.viewer.user?.flowStates?.edges ?? [];
  const orgFlowStates = org?.flowStates?.edges ?? [];
  const flowStates = [...userFlowStates, ...orgFlowStates];

  const getFlowState = (flowKey: FlowState) =>
    transform(flowStates, compact, map("node"), (s) => find(s, { flowKey }) ?? null);

  const onboardingState = getFlowState(FlowState.InitialOnboarding);
  const fastCrawlState = getFlowState(FlowState.FastCrawl);
  const webOnboardingState = getFlowState(FlowState.WebOnboarding);

  useSubscription(FlowStateSubscriptionDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    variables: { flowStateId: onboardingState?.id! },
    skip: !onboardingState,
  });
  useSubscription(FlowStateSubscriptionDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    variables: { flowStateId: fastCrawlState?.id! },
    skip: !fastCrawlState,
  });

  useSubscription(FlowStateSubscriptionDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    variables: { flowStateId: webOnboardingState?.id! },
    skip: !webOnboardingState,
  });

  useEffect(() => {
    if (webOnboardingState) {
      const webReduxState = safelyGetReduxStateFromFlowState(webOnboardingState);
      if (webReduxState) {
        dispatch(setOnboardingFlowState(webReduxState));
      }
    }
  }, [webOnboardingState]);

  const fastCrawlError = useMemo(() => isInFatalState(fastCrawlState), [fastCrawlState]);

  return useMemo(() => {
    return {
      fastCrawlState,
      loading,
      error,
      fastCrawlError,
    };
  }, [orgId, fastCrawlState, loading, error, fastCrawlError]);
}

export type FlowStateResult = {
  id: string;
  flowKey: string | null;
  previousState: string | null;
  current: {
    id: string;
    state: string | null;
    percentComplete: number | null;
    errorMessage: string | null;
  } | null;
  pending: {
    id: string;
    state: string | null;
    percentComplete: number | null;
    errorMessage: string | null;
  } | null;
};

type FlowStateContextType = {
  fastCrawlState: FlowStateResult | null;
  loading: boolean;
  error: ApolloError | undefined;
  provided: boolean;
  fastCrawlError: boolean;
};

export const FlowStateContext = createContext<FlowStateContextType>({
  fastCrawlState: null,
  loading: false,
  error: undefined,
  provided: false,
  fastCrawlError: false,
});

export const FlowStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const flowState = useFlowStateQuery();
  const state = useMemo(() => ({ ...flowState, provided: true }), [flowState]);
  return <FlowStateContext.Provider value={state}>{children}</FlowStateContext.Provider>;
};

export function useFlowState() {
  const state = useContext(FlowStateContext);

  if (!state.provided) {
    console.warn(`
      You are calling useFlowState in a component that does not appear to have the provider in
      its ancestor tree.

      Please verify that <FlowStateProvider> is wrapping this component or an ancestor.
    `);
  }

  return state;
}
