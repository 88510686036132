import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import { getCWCalendarColors } from "@clockwise/web-commons/src/util/event-category-coloring";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { isArray } from "lodash";
import { useMemo } from "react";
import { WorkingLocationEventsDocument } from "../apollo/__generated__/WorkingLocationEvents.v2.generated";
import { toPlannerWorkingLocations } from "../util/toPlannerEvents";

export function useWorkingLocationEvents(
  calendarIdOrIds: string | string[] | undefined,
  weekStartDate: string,
  isOnWorkingLocation: boolean,
) {
  const ecosystem = useEcosystem();
  const colors = getCWCalendarColors(ecosystem);
  const { data, loading, error, startPolling, stopPolling, refetch, fetchMore } = useGatewayQuery(
    WorkingLocationEventsDocument,
    {
      variables: {
        weekStartDate,
        timeZone: getRenderTimeZone(),
        calendarIds: isArray(calendarIdOrIds) ? calendarIdOrIds : [calendarIdOrIds!],
      },
      errorPolicy: "all", // This allows us to receive both error and data to able to load partial data.
      skip:
        !isOnWorkingLocation ||
        !weekStartDate ||
        (isArray(calendarIdOrIds) ? calendarIdOrIds.length === 0 : !calendarIdOrIds),
    },
  );

  const workingLocationsByDay = useMemo(
    () => (data ? toPlannerWorkingLocations(data, colors) : {}),
    [colors, data],
  );

  return {
    workingLocationsByDay,
    loading,
    error,
    startPolling,
    stopPolling,
    refetch,
    fetchMore,
  };
}
