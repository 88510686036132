import { graphql } from "react-relay";

export const rescheduleEventParentMutation = graphql`
  mutation RescheduleEventParentMutation($input: RescheduleEventParentMutationInput!) {
    rescheduleEventParent(input: $input) {
      eventParent {
        events {
          id
          startTime {
            millis
          }
          endTime {
            millis
          }
          annotatedEvent {
            id
            externalEventId
            orgTags {
              tag
              state {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
          annotatedRecurringEvent {
            id
            externalEventId
            orgTags {
              tag
              state {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
        }
      }
    }
  }
`;
