import React from "react";
import { RadioControl } from "./";

import { ProBadge } from "@clockwise/web-commons/src/ui/pro-badge";

import { isTeamInPaidSubscription } from "#webapp/src/util/pricing.util";
import { getDomainOffEmail } from "@clockwise/client-commons/src/util/email";

import { IOrg, ITeam } from "#webapp/src/__schema__";
import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { ISeatPlanData } from "../MixedCheckoutForm";
import { getCurrentOrgPersons } from "../MixedCheckoutForm.util";
import { FormHeader, FormSubHeader, FormSubHeaderTooltip } from "./";

interface ControlContentProps {
  control: ISeatPlanData;
  org: IOrg;
  team: ITeam | null;
  onOpenOrgUserSelectForm: () => void;
  setTeamModalOpen: (open: boolean) => void;
  setTeamModalAction: (action: "select" | "join") => void;
}
const inidividualTooltipText = `We'll create a team for you during checkout so you can use the paid version of Clockwise as a "team of one." You can always add others to your plan later.`;
export const ControlContent = ({
  control,
  org,
  team,
  setTeamModalOpen,
  setTeamModalAction,
  onOpenOrgUserSelectForm,
}: ControlContentProps) => {
  const onOpenTeamModal = (action: "select" | "join") => {
    setTeamModalOpen(true);
    setTeamModalAction(action);

    track(TrackingEvents.CHECKOUT.OPEN_TEAM_JOIN_CREATE_MODAL);
  };

  if (team && control.seatType === "team") {
    return (
      <RadioControl on={control.on} disabled={control.disabled}>
        <FormHeader>
          <div>{`My Team (${team.teamName})`}</div>
          {isTeamInPaidSubscription(org, team) && <ProBadge className="cw-ml-2" />}
        </FormHeader>
        <FormSubHeader onClick={() => onOpenTeamModal("select")}>Change team</FormSubHeader>
      </RadioControl>
    );
  } else if (control.seatType === "individual") {
    return (
      <RadioControl on={control.on} disabled={control.disabled}>
        <FormHeader>
          <div>{"Just myself for now"}</div>
        </FormHeader>
        <FormSubHeaderTooltip tooltipText={inidividualTooltipText}>
          {" "}
          What does this mean?
        </FormSubHeaderTooltip>
      </RadioControl>
    );
  } else if (control.seatType === "org") {
    const currentOrgPersonsCount = getCurrentOrgPersons(org).length;
    return (
      <RadioControl on={control.disabled ? false : control.on} disabled={control.disabled}>
        <FormHeader>My organization ({getDomainOffEmail(org.primaryOrgCalendar)})</FormHeader>
        <FormSubHeader onClick={onOpenOrgUserSelectForm}>
          {currentOrgPersonsCount} current user{currentOrgPersonsCount === 1 ? "" : "s"}
        </FormSubHeader>
      </RadioControl>
    );
  }
  return null;
};
