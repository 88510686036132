import { devPromoCodeMap, promoCodeMap } from "./constants";

export const validatePromoCode = (promoCode: string) => {
  return !!promoCodeMap[promoCode];
};

export const validateDevPromoCode = (promoCode: string) => {
  return !!devPromoCodeMap[promoCode];
};

export const isPromoCodeValid = (promoCode: string, environment: string) => {
  return environment === "production"
    ? validatePromoCode(promoCode)
    : validateDevPromoCode(promoCode);
};
