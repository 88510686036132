// schema
import * as ISchema from "#webapp/src/__schema__";

import { FeedSchedulerActions } from "#webapp/src/state/actions/feed-scheduler.actions";
import { EScheduleRange } from "#webapp/src/util/scheduler.util";

interface IFeedSchedulerAction {
  type: string;
  data: {
    active?: boolean;
    calendarIds?: string[];
    roomRequired?: boolean;
    scheduledExternalEventId?: string;
    scheduledCalendarId?: string;
    schedulingSessionId?: string;
    reschedulerEvent?: ISchema.IEvent;
    useAutoSchedule?: boolean;
    minutesDuration?: number;
    range?: EScheduleRange;
    specifiedDate?: Date | null;
  };
}

export interface IFeedSchedulerState {
  calendarIds: string[];
  roomRequired: boolean;
  eventHasBeenScheduled: boolean;
  scheduledExternalEventId: string;
  scheduledCalendarId: string;
  schedulingSessionId: string;
  reschedulerEvent: ISchema.IEvent | undefined;
  useAutoSchedule: boolean;
  minutesDuration: number;
  range: EScheduleRange;
  specifiedDate: Date | null;
}

export const initialFeedSchedulerState: IFeedSchedulerState = {
  calendarIds: [],
  roomRequired: false,
  eventHasBeenScheduled: false,
  scheduledExternalEventId: "",
  scheduledCalendarId: "",
  schedulingSessionId: "",
  reschedulerEvent: undefined,
  useAutoSchedule: false,
  minutesDuration: 30,
  range: EScheduleRange.NextSeven,
  specifiedDate: null,
};

export function feedSchedulerReducer(
  state: IFeedSchedulerState = initialFeedSchedulerState,
  action: IFeedSchedulerAction,
) {
  switch (action.type) {
    case FeedSchedulerActions.UpdateScheduledEventIds:
      return {
        ...state,
        eventHasBeenScheduled: true,
        scheduledExternalEventId: action.data.scheduledExternalEventId,
        scheduledCalendarId: action.data.scheduledCalendarId,
      };
    case FeedSchedulerActions.ResetScheduledEventIds:
      return {
        ...state,
        eventHasBeenScheduled: false,
        scheduledExternalEventId: "",
        scheduledCalendarId: "",
      };
    case FeedSchedulerActions.UpdateFeedSchedulerInput: {
      const {
        calendarIds,
        roomRequired,
        useAutoSchedule,
        minutesDuration,
        range,
        specifiedDate,
      } = action.data;
      return {
        ...state,
        calendarIds,
        roomRequired,
        useAutoSchedule,
        minutesDuration,
        range,
        specifiedDate,
      };
    }
    case FeedSchedulerActions.UpdateCalendarIds:
      return { ...state, calendarIds: action.data.calendarIds };
    case FeedSchedulerActions.UpdateRoomRequired:
      return { ...state, roomRequired: action.data.roomRequired };
    case FeedSchedulerActions.UpdateSchedulingSessionId:
      return { ...state, schedulingSessionId: action.data.schedulingSessionId };
    case FeedSchedulerActions.UpdateAutoSchedule:
      return { ...state, useAutoSchedule: action.data.useAutoSchedule };
    case FeedSchedulerActions.UpdateDuration:
      return { ...state, minutesDuration: action.data.minutesDuration };
    case FeedSchedulerActions.UpdateRange:
      return { ...state, range: action.data.range, specifiedDate: action.data.specifiedDate };
    default:
      return state;
  }
}
