/// <reference types="webpack/module" />
//////////////
// IMPORTS
//////////////
import { AnyAction, Store } from "redux";
import { IReduxState } from "./reducers/root.reducer";

//////////////
// COMMON
//////////////

// this will get set via one of the below methods
let reduxStore: Store<IReduxState>;

////////////////
// HOT RELOAD
////////////////
// hot-reloading without destroying the store
if (module.hot) {
  module.hot.accept("./reducers/root.reducer", () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires
    const nextRootReducer = require("./reducers/root.reducer");
    getReduxStore().replaceReducer(nextRootReducer);
  });
}

////////////////
// API
////////////////
// initialization is needed in order to allow for other components to import
// the store without knowing if it's in chrome or not
export function initReduxStore(store: Store<IReduxState, AnyAction>) {
  reduxStore = store;
}

// we want to always guarantee this returns something
export function getReduxStore() {
  return reduxStore;
}
