/**
 * @generated SignedSource<<ec37b70ade1e52447ab1e3c76d0bc5e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventColoringSettingsMutationsInput = {
  clientMutationId?: string | null;
  eventCategoryColorings: ReadonlyArray<EventCategoryColoringInput | null>;
  orgRelayId: string;
};
export type EventCategoryColoringInput = {
  active: boolean;
  eventCategory: string;
  googleColorKey?: string | null;
  isUserSet: boolean;
};
export type UpdateEventColoringSettingsMutation$variables = {
  input: EventColoringSettingsMutationsInput;
};
export type UpdateEventColoringSettingsMutation$data = {
  readonly updateEventColoringSettings: {
    readonly eventColoringSettings: {
      readonly eventCategoryColorings: ReadonlyArray<{
        readonly eventCategory: string;
        readonly googleColorKey: string | null;
        readonly active: boolean;
        readonly isUserSet: boolean;
      }> | null;
    } | null;
  } | null;
};
export type UpdateEventColoringSettingsMutation = {
  variables: UpdateEventColoringSettingsMutation$variables;
  response: UpdateEventColoringSettingsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "EventCategoryColoring",
  "kind": "LinkedField",
  "name": "eventCategoryColorings",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventCategory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "googleColorKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUserSet",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateEventColoringSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EventColoringSettingsMutationsPayload",
        "kind": "LinkedField",
        "name": "updateEventColoringSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventColoringSettings",
            "kind": "LinkedField",
            "name": "eventColoringSettings",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateEventColoringSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EventColoringSettingsMutationsPayload",
        "kind": "LinkedField",
        "name": "updateEventColoringSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventColoringSettings",
            "kind": "LinkedField",
            "name": "eventColoringSettings",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "55239d6b8ee4386489f626a218fa130d",
    "id": null,
    "metadata": {},
    "name": "UpdateEventColoringSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateEventColoringSettingsMutation(\n  $input: EventColoringSettingsMutationsInput!\n) {\n  updateEventColoringSettings(input: $input) {\n    eventColoringSettings {\n      eventCategoryColorings {\n        eventCategory\n        googleColorKey\n        active\n        isUserSet\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "da330c4a63f66f966a7d3556a5c5b643";

export default node;
