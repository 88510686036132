import { EventColorCategory } from "@clockwise/client-commons/src/util/event-category-coloring";
import { compact, first } from "lodash";
import { DateTime, Interval } from "luxon";
import { hasIntervalAllDayLong } from "../ooo-events/utils/hasIntervalAllDayLong";
import { PlannerEventCard, PlannerEventCardsByDay } from "../types";

export const getNormalAndAllDayEvents = (
  events: PlannerEventCardsByDay,
  zone: string,
  multiCalendarIds: string[],
  primaryCalendarId?: string,
) => {
  const normalEventsByDay: PlannerEventCardsByDay = {};
  const allDayEventsByDay: PlannerEventCardsByDay = {};
  const allDayOOOEventsByDay: PlannerEventCardsByDay = {};

  Object.entries(events || {}).forEach(([day, events]) => {
    const normalEvents: PlannerEventCard[] = [];
    const allDayEvents: PlannerEventCard[] = [];
    const allDayOOOEvents: PlannerEventCard[] = [];

    events.forEach((event) => {
      if (
        event.isAllDay ||
        event.interval.toDuration().as("days") >= 1 ||
        hasIntervalAllDayLong(event)
      ) {
        // All day event endTime is either the day it ends, or sometimes the next day at 00-00-00 hours/minutes/seconds. So,
        // subtract 1 second from the end day to check if the end date falls on 'day', which is the calendar day being rendered.
        const isAllDayOOOEvent =
          event.eventCategory === EventColorCategory.OutOfOffice &&
          (event.interval.toDuration().as("days") >= 1 || hasIntervalAllDayLong(event));

        const spansToday =
          event.interval.end.minus({ seconds: 1 }) >=
          DateTime.fromISO(day, {
            zone: zone,
          });

        const sameDayOOOEvent =
          event.interval.start === event.interval.end && event.interval.start.toISODate() === day;

        const eventOwnerCalendarId = first(event.calendarIds) ?? "";
        const currentUserOrMultiCalEvent =
          (primaryCalendarId && primaryCalendarId === eventOwnerCalendarId) ||
          (multiCalendarIds && multiCalendarIds.includes(eventOwnerCalendarId));

        // Team calendarOOO events should be calculated as all day, multi call all dayOOO events should be shown as allDayOOO
        if (spansToday && (sameDayOOOEvent || isAllDayOOOEvent) && currentUserOrMultiCalEvent) {
          allDayOOOEvents.push(event);
        } else {
          allDayEvents.push({
            ...event,
            isAllDay: true,
          });
        }
      } else {
        normalEvents.push(event);
      }
    });

    normalEventsByDay[day] = normalEvents;

    allDayOOOEvents.forEach((outOfOfficeEvent) => {
      // If multi day OOO event, split it into multiple events, one for each day.
      if (outOfOfficeEvent.interval.toDuration().minus({ second: 1 }).as("days") > 1) {
        const eventStart = outOfOfficeEvent.interval.start.setZone(zone);
        const eventEnd = outOfOfficeEvent.interval.end.setZone(zone);

        const daysToIterateThrough = eventEnd.diff(eventStart, "days").days;
        const roundedDaysToIterateThrough =
          eventEnd.hour >= 12 ? Math.floor(daysToIterateThrough) : Math.round(daysToIterateThrough);

        for (let i = 0; i <= roundedDaysToIterateThrough; i++) {
          const newDay = eventStart.plus({ days: i });

          const intervalStartTime = i === 0 ? eventStart : newDay.startOf("day");
          const intervalEndTime =
            i === roundedDaysToIterateThrough ? eventEnd : newDay.endOf("day");
          const newEvent = {
            ...outOfOfficeEvent,
            interval: Interval.fromDateTimes(
              intervalStartTime.setZone(zone),
              intervalEndTime.setZone(zone),
            ), // handle partial days
          };

          const newDayString = newDay.toFormat("yyyy-MM-dd");
          const existingOOOEventForNewDay = allDayOOOEventsByDay[newDayString] ?? [];
          const newAllDayOOOEventsForDay = [...existingOOOEventForNewDay, newEvent];

          allDayOOOEventsByDay[newDayString] = newAllDayOOOEventsForDay;
        }
      } else {
        // if single day OOO event add it to current day
        const existingOOOEventFoCurrentDay = allDayOOOEventsByDay[day] ?? [];
        allDayOOOEventsByDay[day] = [...existingOOOEventFoCurrentDay, outOfOfficeEvent];
      }
    });
    allDayEventsByDay[day] = getArrangedEvents(day, allDayEvents, allDayEventsByDay); // The compact shouldnt be removing anything, just here to satisfy the type CalendarDayIEvent[];
  });

  return [normalEventsByDay, allDayEventsByDay, allDayOOOEventsByDay];
};

const getArrangedEvents = (
  day: string,
  allDayEvents: PlannerEventCard[],
  allDayEventsByDay: PlannerEventCardsByDay,
) => {
  // Next, we align all day events up with their position in the previous day so that they appear continuous.
  // To better understand this code, add a bunch (6+ or so) multi day events to your calendar that overlap in different ways and see how they get arranged.
  // instantiate allDayEventsArranged with an empty array for each day plus the day before and after the first and last day
  const priorDay = DateTime.fromISO(day).minus({ days: 1 }).toISODate();
  const allDayEventsArranged: (PlannerEventCard | null)[] = new Array<null>(
    allDayEvents.length + (allDayEventsByDay[priorDay]?.length || 0),
  ).fill(null); // Create array with lots of room to fit all of this days events with the worst-case that we need to add a fake event for each event in the previous day.

  const eventsToSortAndThenInsert: PlannerEventCard[] = [];

  allDayEvents.forEach((eThisDay) => {
    if (!allDayEventsByDay[priorDay]) {
      // Happens when we are at the start of the week.
      eventsToSortAndThenInsert.push(eThisDay);
      return;
    }

    const indexPriorDay = allDayEventsByDay[priorDay]?.findIndex(
      (ePriorDay) =>
        !!ePriorDay.externalEventId &&
        !!eThisDay.externalEventId &&
        eThisDay.externalEventId === ePriorDay.externalEventId,
    );

    if (indexPriorDay > -1) {
      allDayEventsArranged[indexPriorDay] = eThisDay;
    } else {
      eventsToSortAndThenInsert.push(eThisDay);
    }
  });

  // Then, sort new events (events that start on this day) by endTime to minimize fake padding events we may need to add, then startTime or id for stability.
  eventsToSortAndThenInsert.sort((a, b) => {
    // Minus seconds 1 to match how we do in other places. Happens when Google tells us that the event ends at 12:00 the next day AKA the end of the prior day.
    const aStart = a.interval.start.minus({ seconds: 1 }).startOf("day");
    const bStart = b.interval.start.minus({ seconds: 1 }).startOf("day");
    const aEnd = a.interval.end.minus({ seconds: 1 }).startOf("day");
    const bEnd = b.interval.end.minus({ seconds: 1 }).startOf("day");

    if (aEnd === bEnd) {
      if (aStart === bStart) {
        return !!a.externalEventId && !!b.externalEventId && a.externalEventId < b.externalEventId
          ? -1
          : 1;
      }
      return aStart > bStart ? -1 : 1;
    }

    return aEnd > bEnd ? -1 : 1;
  });

  // For each event of eventsToSortAndThenInsert, find the first empty slot in allDayEventsArranged and insert it there.
  eventsToSortAndThenInsert.forEach((e) => {
    const index = allDayEventsArranged.findIndex((e) => e === null);
    allDayEventsArranged[index] = e;
  });

  // Count how many elements to remove from the end of the array, then remove them.
  const toRemoveFromEnd = [...allDayEventsArranged].reverse().findIndex((e) => e !== null);
  if (toRemoveFromEnd > 0) {
    allDayEventsArranged.splice(allDayEventsArranged.length - toRemoveFromEnd);
  }

  return compact(allDayEventsArranged);
};
