import { analyticsPre, cwEcosystem } from "#webapp/src/state/local-storage";
import { getReduxStore } from "#webapp/src/state/redux-store";
import { ChromeSite, getChromeSite } from "#webapp/src/util/chrome-extension.util";
import { ExperimentNameType, isOn, isOnTrackLogging } from "#webapp/src/util/experiment.util";
import { logger } from "#webapp/src/util/logger.util";
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import {
  DauEvents,
  FreshSalesDateFields,
  PageEvents,
  TrackingEvents,
  alias as baseAlias,
  identify as baseIdentify,
  page as basePage,
  track as baseTrack,
  getAnalyticsId,
  getAnonymousId,
  getUserId,
  shouldTrack,
  trackEvent,
} from "@clockwise/web-commons/src/util/analytics.util";
import { browserName } from "@clockwise/web-commons/src/util/browser.util";
import { md } from "@clockwise/web-commons/src/util/mobile.util";
import { PreCookie, UtmProp, getPreCookie } from "@clockwise/web-commons/src/util/pre-cookie.util";
import { ZonedMoment } from "@clockwise/web-commons/src/util/time-zone.util";
export { DauEvents, FreshSalesDateFields, PageEvents, TrackingEvents };

// hover delay
export const TrackingHoverDelay = 500;

type Analytics = {
  analytics: {
    identify: (userId: string, traits?: { [k: string]: any }) => void;
    group: (groupId: string, traits?: { [k: string]: any }) => void;
    alias: (userId: string, traits?: { [k: string]: any }) => void;
    track: (trackingString: string, properties?: { [k: string]: any }) => void;
    page: (name: string, properties?: { [k: string]: any }) => void;
  };
};

type AnalyticsWindow = Analytics & Window & { ga: Function };
const isWindow = typeof window !== "undefined";
const analyticsWindow = isWindow ? ((window as unknown) as AnalyticsWindow) : null;

function filterGlobalPreProperties(preCookie: PreCookie) {
  const prePropertyAllowlist = ["id", "referrer", "invite", "utms"] as const;
  return prePropertyAllowlist.reduce((data: Partial<PreCookie>, prop) => {
    return { ...data, [prop]: preCookie[prop] };
  }, {});
}

// for identify and alias
function augmentIdentifyTraits(traits?: Object) {
  const augmentedTraits: { [k: string]: any } = traits ? traits : {};
  const state = getReduxStore().getState();

  augmentedTraits.chromeExtensionVersion = state.webExtension.webExtensionVersion;
  augmentedTraits.occuredOn = new ZonedMoment().format();
  augmentedTraits.userId = getUserId();
  augmentedTraits.anonymousId = getAnonymousId();
  augmentedTraits.chromeSite = getChromeSite();

  // context (for google analytics and segment)
  augmentedTraits.context = {
    user_agent: navigator.userAgent,
  };
  if (analyticsWindow?.ga) {
    analyticsWindow.ga((tracker: any) => {
      const clientId = tracker.get("clientId");
      augmentedTraits.context["Google Analytics"] = {
        clientId,
      };
    });
  }

  // read from pre cookie
  const preCookie = getPreCookie();

  augmentedTraits.pre = filterGlobalPreProperties(preCookie);

  // Freshsales contact setting
  augmentedTraits.fs_contact = true;

  // Freshsales created time
  if (augmentedTraits.hasOwnProperty("created")) {
    augmentedTraits.cf_signed_up = augmentedTraits.created;
  }

  Object.values(UtmProp).forEach((utmParam) => {
    // Following the previously logic, values are included if defined regardless of truthiness
    if (utmParam in preCookie.utms) {
      augmentedTraits[utmParam] = preCookie.utms[utmParam];
    }
  });

  augmentedTraits.clientMetadata = {
    isWebExtension: state.webExtension.isWebExtension,
    webExtensionVesion: state.webExtension.webExtensionVersion,
    browserName: browserName,
    sidebarOpen: state.webExtension.webExtensionOpen,
  };

  return augmentedTraits;
}

function augmentGroupTraits(traits?: Object) {
  const augmentedTraits: { [k: string]: any } = traits ? traits : {};
  augmentedTraits.occuredOn = new ZonedMoment().format();

  return augmentedTraits;
}

type BaseAnalyticsData = {
  isChromeExtension: boolean;
  chromeExtensionVersion: string;
  chromeExtensionOpen: boolean;
  clientMetadata: {
    isWebExtension: boolean;
    webExtensionVesion: string;
    browserName: string | null;
    sidebarOpen: boolean;
  };
  chromeSite: ChromeSite;
  /** NOTE: Typo, do not continue to use. */
  occuredOn: string;
  occurredOn: string;
  anonymousId: string;
  userId: string | null;
  isMobile: boolean;
  /** Mobile operating system */
  os?: string;
  /** For Google Analytics and Segment */
  context: {
    user_agent: string;
    "Google Analytics"?: {
      clientId: string;
    };
  };
  pre: ReturnType<typeof filterGlobalPreProperties>;
} & Record<string, any>;

const getDisplayMode = () => {
  if (window.matchMedia("(display-mode: standalone)").matches) {
    return "standalone";
  } else if (window.matchMedia("(display-mode: fullscreen)").matches) {
    return "fullscreen";
  } else if (window.matchMedia("(display-mode: minimal-ui)").matches) {
    return "minimal-ui";
  } else {
    return "browser";
  }
};

// for track, page
function augmentProperties(properties?: Record<string, any>): BaseAnalyticsData {
  const state = getReduxStore().getState();
  const occurredOn = new ZonedMoment().format();

  let clientId = null;
  if (analyticsWindow?.ga) {
    analyticsWindow.ga((tracker: any) => {
      clientId = tracker.get("clientId");
    });
  }

  return {
    ...(properties ?? {}),
    isChromeExtension: state.webExtension.isWebExtension,
    chromeExtensionVersion: state.webExtension.webExtensionVersion,
    chromeExtensionOpen: state.webExtension.webExtensionOpen,
    clientMetadata: {
      isWebExtension: state.webExtension.isWebExtension,
      webExtensionVesion: state.webExtension.webExtensionVersion,
      browserName: browserName,
      sidebarOpen: state.webExtension.webExtensionOpen,
    },
    chromeSite: getChromeSite(),
    occuredOn: occurredOn,
    occurredOn,
    anonymousId: getAnonymousId(),
    userId: getUserId(),
    isMobile: !!md.mobile(),
    displayMode: getDisplayMode(),
    os: md.os(),
    context: {
      user_agent: navigator.userAgent,
      "Google Analytics": clientId ? { clientId } : undefined,
    },
    pre: filterGlobalPreProperties(getPreCookie()),
    ecosystem: cwEcosystem.get(),
  };
}

export function identify(traits?: { [k: string]: any }) {
  // ignore switch user
  if (!shouldTrack()) {
    return;
  }

  const augmentedTraits = augmentIdentifyTraits(traits);
  baseIdentify(augmentedTraits);

  // identify user for segment
  analyticsWindow?.analytics.identify(getAnalyticsId(), augmentedTraits);
}

export function identifyExperiment(experiment: ExperimentNameType) {
  const on = isOn(experiment);

  identify({
    [`cwExperiment${experiment}`]: on,
  });
}

export function alias() {
  if (!shouldTrack()) {
    return;
  }

  // only alias if we have a difference in ids (and are able to set anonymous id in local storage)
  const curId = getAnalyticsId();
  const preId = getAnonymousId();
  if (!analyticsPre.get() || curId === preId) {
    return;
  }

  const augmentedTraits = augmentIdentifyTraits({
    previousId: preId,
  });

  baseAlias(augmentedTraits);

  analyticsWindow?.analytics.alias(curId, augmentedTraits);
}

export function group(groupUrn: string, traits?: { [k: string]: any }) {
  if (!shouldTrack()) {
    return;
  }

  trackEvent({
    type: "Group",
    id: groupUrn,
    properties: augmentGroupTraits(traits),
  });

  // front-end track for full story
  analyticsWindow?.analytics.group(groupUrn, augmentGroupTraits(traits));
}

export function track(trackingString: string, properties?: { [k: string]: any }, endDate?: Date) {
  // ignore switch user
  if (!shouldTrack()) {
    return;
  }

  if (isOnTrackLogging()) {
    logger.info(`Tracking event: ${trackingString}`, properties);
  }

  baseTrack(trackingString, augmentProperties(properties), endDate);
}

export function trackWithData(data: Object): typeof track {
  type TrackParameters = Parameters<typeof track>;

  return (trackingString: TrackParameters[0], properties?: TrackParameters[1]) => {
    track(trackingString, { ...data, ...properties });
  };
}

export function page(name: string, properties?: { [k: string]: any }) {
  if (getEnvironment() === "test") {
    return; // there's no window in the test environment
  }

  // ignore switch user
  if (!shouldTrack()) {
    return;
  }

  basePage(name, augmentProperties(properties));
}
