export const baseUrl = "https://getclockwise.helpscoutdocs.com/";

export const articleUrls = {
  autopilot: `${baseUrl}article/184-flexible-meetings`,
  autopilotFaq: `${baseUrl}article/122-why-didnt-clockwise-resolve-my-conflict`,
  colors: `${baseUrl}article/58-enabling-event-color-coding`,
  feedback: `${baseUrl}hc/en-us/requests/new`,
  flexibilityFaq: `${baseUrl}article/184-flexible-meetings#What-meetings-can-you-mark-as-flexible--K1si_`,
  focusTime: `${baseUrl}article/189-focus-time`,
  noMeetingDay: `${baseUrl}article/44-team-no-meeting-day`,
  personalCalSync: `${baseUrl}article/170-personal-calendar-sync`,
  plans: `${baseUrl}article/75-clockwise-plan-offerings`,
  slack: `${baseUrl}article/160-slack-integration`,
  focusTimeSync: `${baseUrl}article/189-focus-time`,
  lunchSync: `${baseUrl}article/171-lunch-events`,
  travelTime: `${baseUrl}article/172-travel-time`,
  teamCalendar: `${baseUrl}article/43-team-automatic-out-of-office-calendar`,
  zoom: `${baseUrl}article/162-zoom-integration`,
  schedulerOverview: `${baseUrl}article/173-holds-lunch-travel-and-personal-time`,
  scheduleAssists: `${baseUrl}article/75-clockwise-plan-offerings`,
  holiday: `${baseUrl}article/85-how-does-clockwise-work-with-holidays-using-google-calendar`,
  contactSharing: `${baseUrl}article/148-what-does-a-contact-sharing-error-mean`,
  smartMeetingBreaks: `${baseUrl}article/174-smart-meeting-breaks`,
  roundRobin: `${baseUrl}article/169-using-round-robin`,
  m365: `${baseUrl}article/100-integrating-clockwise-with-microsoft-outlook`,
};
