import { IOnboardingAction, OnboardingActions } from "#webapp/src/state/actions/onboarding.actions";
import { FastCrawlFlowState } from "#webapp/src/state/onboarding";
import { OnboardingStep } from "@clockwise/web-commons/src/util/onboarding.util";

import {
  OnboardingMeta,
  OnboardingType,
} from "#webapp/src/components/onboarding-nux/OnboardingNUXTypes";
import { WebOnboardingStep } from "#webapp/src/components/web-onboarding/WebOnboarding.util";
import { isInFatalState } from "@clockwise/client-commons/src/util/onboarding";
import { onboardingMeta } from "@clockwise/web-commons/src/util/local-storage";

export interface IOnboardingState {
  onboardingLoaded: boolean;
  onboardingFlowStateCurrentStatus: string;
  onboardingFinished: boolean;

  fastCrawlFlowStateCurrentStatus: FastCrawlFlowState;
  fastCrawlFlowStateErrorMessage: string | null;
  fastCrawlFinished: boolean;
  fastCrawlUserCrawledComplete: boolean;
  fastCrawlTeammatesIdentifiedComplete: boolean;
  fastCrawlRunning: boolean;
  fastCrawlFatalState: boolean;
  fastCrawlUserCrawledRefetchComplete: boolean;
  fastCrawlTeammatesIdentifiedRefetchComplete: boolean;
  fastCrawlFinishedRefetchComplete: boolean;

  onboardingMeta: OnboardingMeta;
}

export const initialOnboardingState: IOnboardingState = {
  onboardingLoaded: false,
  onboardingFlowStateCurrentStatus: OnboardingStep.Finished,
  onboardingFinished: true,

  fastCrawlFlowStateCurrentStatus: FastCrawlFlowState.Finished,
  fastCrawlFlowStateErrorMessage: null,
  fastCrawlFinished: true,
  fastCrawlUserCrawledComplete: true,
  fastCrawlTeammatesIdentifiedComplete: true,
  fastCrawlRunning: false,
  fastCrawlFatalState: false,
  fastCrawlUserCrawledRefetchComplete: false,
  fastCrawlTeammatesIdentifiedRefetchComplete: true,
  fastCrawlFinishedRefetchComplete: true,

  onboardingMeta: {
    onboardingType: onboardingMeta.get("onboardingType") || OnboardingType.Default,
    onboardingTypeSource: onboardingMeta.get("onboardingTypeSource") || null,
  },
};

export function onboardingReducer(state = initialOnboardingState, action: IOnboardingAction) {
  switch (action.type) {
    case OnboardingActions.SetOnboardingLoaded:
      return {
        ...state,
        onboardingLoaded: (action.data && action.data.onboardingLoaded) || false,
      };

    case OnboardingActions.SetOnboardingFlowState:
      // sanity
      if (!action.data || !action.data.onboardingFlowState) {
        return state;
      }

      // setup
      const onboardingFlowState = action.data.onboardingFlowState;
      const onboardingFlowStateCurrentStatus = onboardingFlowState.current.state;

      // statuses
      let onboardingFinished = false;
      switch (onboardingFlowStateCurrentStatus) {
        case WebOnboardingStep.Finished:
          onboardingFinished = true;
          break;
        case OnboardingStep.Finished:
          onboardingFinished = true;
          break;
        default:
          break;
      }
      return {
        ...state,
        onboardingFlowStateCurrentStatus,
        onboardingFinished,
      };

    case OnboardingActions.SetFastCrawlUserCrawledRefetchComplete:
      return {
        ...state,
        fastCrawlUserCrawledRefetchComplete: true,
      };
    case OnboardingActions.SetFastCrawlTeammatesIdentifiedRefetchComplete:
      return {
        ...state,
        fastCrawlTeammatesIdentifiedRefetchComplete: true,
      };
    case OnboardingActions.SetFastCrawlFinishedRefetchComplete:
      return {
        ...state,
        fastCrawlFinishedRefetchComplete: true,
        fastCrawlTeammatesIdentifiedRefetchComplete: true,
        fastCrawlUserCrawledRefetchComplete: true,
      };
    case OnboardingActions.SetFastCrawlFlowState:
      // sanity
      if (!action.data || !action.data.fastCrawlFlowState) {
        return state;
      }

      // setup
      const fastCrawlFlowState = action.data.fastCrawlFlowState;
      const fastCrawlFlowStateCurrentStatus = fastCrawlFlowState.current.state;
      const fastCrawlFlowStateErrorMessage = fastCrawlFlowState.current.errorMessage;

      // for statuses which need refreshes, we care if we just changed to it
      // we special case changing from Finished since that is the initial state
      // and we assume its the first time loading data
      const statusChanged =
        state.fastCrawlFlowStateCurrentStatus !== fastCrawlFlowStateCurrentStatus &&
        state.fastCrawlFlowStateCurrentStatus !== "Finished";

      // statuses
      // IMO these should all start off as their original states (like fastCrawlUserCrawledRefetchComplete does)
      // I'm only changing that value for now however as i'm too worried what all of these values changing at once will lead to without extensive testing
      let fastCrawlUserCrawledComplete = true;

      let fastCrawlUserCrawledRefetchComplete = state.fastCrawlUserCrawledRefetchComplete;

      let fastCrawlTeammatesIdentifiedComplete = true;
      let fastCrawlTeammatesIdentifiedRefetchComplete = true;
      let fastCrawlFinished = true;
      let fastCrawlFinishedRefetchComplete = true;
      switch (fastCrawlFlowStateCurrentStatus) {
        case "Unstarted":
        case "CredentialsVerified":
          fastCrawlUserCrawledComplete = false;
          fastCrawlUserCrawledRefetchComplete = false;
          fastCrawlTeammatesIdentifiedComplete = false;
          fastCrawlTeammatesIdentifiedRefetchComplete = false;
          fastCrawlFinished = false;
          fastCrawlFinishedRefetchComplete = false;
          break;
        case "UserCrawled":
          if (statusChanged) {
            fastCrawlUserCrawledRefetchComplete = false;
          }
          fastCrawlTeammatesIdentifiedComplete = false;
          fastCrawlTeammatesIdentifiedRefetchComplete = false;
          fastCrawlFinished = false;
          fastCrawlFinishedRefetchComplete = false;
          break;
        case "TeammatesIdentified":
          if (statusChanged) {
            fastCrawlTeammatesIdentifiedRefetchComplete = false;
          }
          fastCrawlFinished = false;
          fastCrawlFinishedRefetchComplete = false;
          break;
        case "InvitesSuggested":
        case "TeammatesEventsCrawled":
        case "CalendarAliasesResolved":
        case "TeammatesEventsSaved":
          fastCrawlFinished = false;
          fastCrawlFinishedRefetchComplete = false;
          break;
        case "Finished":
          if (statusChanged) {
            fastCrawlFinishedRefetchComplete = false;
          }
          break;
        default:
          break;
      }

      // running and errors
      const fastCrawlRunning = !fastCrawlFinished;
      const fastCrawlFatalState = isInFatalState(fastCrawlFlowState);

      // finish up
      const newState = {
        ...state,
        fastCrawlFlowStateCurrentStatus,
        fastCrawlFlowStateErrorMessage,
        fastCrawlUserCrawledComplete,
        fastCrawlUserCrawledRefetchComplete,
        fastCrawlTeammatesIdentifiedComplete,
        fastCrawlTeammatesIdentifiedRefetchComplete,
        fastCrawlFinished,
        fastCrawlFinishedRefetchComplete,
        fastCrawlFatalState,
        fastCrawlRunning,
      };
      return newState;
    case OnboardingActions.SetOnboardingMeta:
      return {
        ...state,
        onboardingMeta: {
          onboardingType: action.data?.onboardingMeta?.onboardingType ?? OnboardingType.Default,
          onboardingTypeSource: action.data?.onboardingMeta?.onboardingTypeSource ?? null,
        },
      };
    default:
      return state;
  }
}
