import { Progress } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { Sentiment } from "../constants/types";
import { CwIdProps } from "../types/cw-id";

export interface LinearProgressProps extends CwIdProps {
  /**
   * The value of the progress indicator (0-100)
   * If not provided, the progress will be indeterminate
   */
  value?: number;

  /**
   * Whether the progress bar should be animated
   * @default true
   */
  animated?: boolean;

  /**
   * The size of the progress bar
   * @default "sm"
   */
  size?: "xs" | "sm" | "md" | "lg" | "xl";

  /**
   * The sentiment of the progress bar
   * @default "positive"
   */
  sentiment?: Sentiment;

  /**
   * Custom CSS class name
   */
  className?: string;

  /**
   * ARIA label for accessibility
   */
  "aria-label"?: string;
}

const SENTIMENT_CLASSES: { [s in Sentiment]: string } = {
  positive: "cw-bg-positive-emphasis",
  info: "cw-bg-info-emphasis",
  warning: "cw-bg-warning-emphasis",
  neutral: "cw-bg-neutral-emphasis",
  destructive: "cw-bg-destructive-emphasis",
  accent: "cw-bg-accent-emphasis",
};

export const LinearProgress: React.FC<LinearProgressProps> = ({
  value,
  animated = true,
  size = "sm",
  sentiment = "positive",
  className,
  "aria-label": ariaLabel,
  "cw-id": cwId,
}) => {
  const isIndeterminate = value === undefined;

  return (
    <Progress
      value={isIndeterminate ? 100 : value}
      animated={animated && isIndeterminate}
      striped={isIndeterminate}
      size={size}
      aria-label={ariaLabel}
      data-testid={cwId}
      classNames={{
        root: "",
        section: classNames(SENTIMENT_CLASSES[sentiment]),
      }}
      className={classNames(
        "cw-w-full",
        {
          "cw-animate-pulse": isIndeterminate,
        },
        className,
      )}
    />
  );
};

// Add display name for debugging
LinearProgress.displayName = "LinearProgress";
