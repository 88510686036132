import { ConferencingType, EventPermissions, ResponseStatusEnum } from "@clockwise/schema";
import {
  ConferencingType as GatewayConferencingType,
  ResponseStatusEnum as GatewayResponseStatusEnum,
} from "@clockwise/schema/v2";
import { EventPermissions as EventPermissionsBooleans } from "../types";

export function toNewConferencingType(type: ConferencingType): GatewayConferencingType;
export function toNewConferencingType(
  type: ConferencingType | null,
): GatewayConferencingType | null;
export function toNewConferencingType(
  type: ConferencingType | undefined,
): GatewayConferencingType | undefined;
export function toNewConferencingType(
  type: ConferencingType | null | undefined,
): GatewayConferencingType | null | undefined {
  if (!type) return null;

  switch (type) {
    case ConferencingType.MEET:
      return GatewayConferencingType.Meet;
    case ConferencingType.TEAMS_FOR_BUSINESS:
      return GatewayConferencingType.TeamsForBusiness;
    case ConferencingType.ZOOM:
      return GatewayConferencingType.Zoom;
    case ConferencingType.OTHER:
    default:
      return GatewayConferencingType.Other;
  }
}

// TODO (lsanwick) Use this to convert the old event permissions to the new capability booleans,
// after migration.
export const toEventPermissions = (enumValue: EventPermissions): EventPermissionsBooleans => {
  switch (enumValue) {
    case EventPermissions.ALL:
      return {
        canRSVP: true,
        canSeeOtherGuests: true,
        canInviteOthers: true,
        canModify: true,
        canDelete: true,
        canRemove: true,
      };
    case EventPermissions.MODIFY:
      return {
        canRSVP: true,
        canSeeOtherGuests: true,
        canInviteOthers: true,
        canModify: true,
        canDelete: false,
        canRemove: true,
      };
    case EventPermissions.INVITE:
      return {
        canRSVP: true,
        canSeeOtherGuests: true,
        canInviteOthers: true,
        canModify: false,
        canDelete: false,
        canRemove: true,
      };
    case EventPermissions.ISATTENDEE:
      return {
        canRSVP: true,
        canSeeOtherGuests: true,
        canInviteOthers: false,
        canModify: false,
        canDelete: false,
        canRemove: true,
      };
    case EventPermissions.NONE:
      return {
        canRSVP: false,
        canSeeOtherGuests: false,
        canInviteOthers: false,
        canModify: false,
        canDelete: false,
        canRemove: false,
      };
  }
};

export function toWebserverResponseStatus(
  gatewayResponseStatus: GatewayResponseStatusEnum,
): ResponseStatusEnum {
  switch (gatewayResponseStatus) {
    case GatewayResponseStatusEnum.Accepted:
      return ResponseStatusEnum.Accepted;
    case GatewayResponseStatusEnum.Declined:
      return ResponseStatusEnum.Declined;
    case GatewayResponseStatusEnum.Tentative:
      return ResponseStatusEnum.Tentative;
    case GatewayResponseStatusEnum.NeedsAction:
      return ResponseStatusEnum.NeedsAction;
    case GatewayResponseStatusEnum.NotApplicable:
      return ResponseStatusEnum.NotApplicable;
  }
}
