import { DateTime } from "luxon";
import { CalendarSpec, getRelativeFormat } from "../../../util/date.util";

const CALENDAR_FORMAT_OPTIONS: CalendarSpec = {
  today: "'Today at' h:mm a",
  tomorrow: "'Tomorrow at' h:mm a",
  next: "'Next' ccc',' MMM d 'at' h:mm a",
  yesterday: "'Yesterday' h:mm a",
  this: "'This' ccc',' MMM d 'at' h:mm a",
  last: "'Last' ccc',' MMM d 'at' h:mm a",
  other: "ccc',' MMM d 'at' h:mm a",
};

const DATE_CALENDAR_FORMAT_OPTIONS: CalendarSpec = {
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  yesterday: "'Yesterday'",
  next: "'Next' ccc',' MMM d",
  this: "'This' ccc',' MMM d",
  last: "'Last' ccc',' MMM d",
  other: "ccc',' MMM d",
};

export const asDateTime = (date: string, timeZone?: string) => {
  return DateTime.fromISO(date, timeZone ? { zone: timeZone } : { setZone: true }).setLocale(
    "en-US",
  );
};

export const getRelativeDate = (dateTime: DateTime) =>
  getRelativeFormat(dateTime, DATE_CALENDAR_FORMAT_OPTIONS);

export const getRelativeDateAndTime = (dateTime: DateTime, timeZone: string) => {
  return `${getRelativeDate(dateTime).replace(",", "")}, ${dateTime
    .setZone(timeZone)
    .toFormat("h:mma")
    .toLowerCase()}`;
};

export function getRelativeDateTime({
  date,
  timeZone,
  format = CALENDAR_FORMAT_OPTIONS,
}: {
  date: string;
  timeZone?: string;
  format?: CalendarSpec;
}) {
  return getRelativeFormat(asDateTime(date, timeZone), format);
}

export function getMultilineRelativeDateTime({
  date,
  timeZone,
}: {
  date: string;
  timeZone?: string;
}): [string, string] {
  const dateTime = asDateTime(date, timeZone);
  return [getRelativeDate(dateTime), dateTime.toFormat("h:mm a")];
}

export const getFormattedFocusTime = (focusTime: number) => {
  if (focusTime === 25 || focusTime === 30) {
    return "-30m";
  }

  if (focusTime < 25) {
    return "-15m";
  }

  // Over 10 hours, round up to nearest hour.
  if (focusTime > 600) {
    focusTime = Math.ceil(focusTime / 60) * 60;
  }

  const minutes = Math.ceil(focusTime / 30) * 30; // Turn speedy meeting duration into regular.
  return `-${minutes / 60}h`;
};
