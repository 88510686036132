import * as React from "react";

// styles
import { AccessTime } from "@clockwise/design-system/icons";

// util
import { Select, SelectOption } from "@clockwise/design-system";
import { formatDuration } from "../../util/duration.util";

export interface MeetingDurationProps {
  minutes: number;
  additionalText?: string;
}

export const MeetingDuration = ({ minutes, additionalText = "" }: MeetingDurationProps) => {
  const formattedDuration = formatDuration(minutes);
  return (
    <div className="cw-inline-flex cw-flex-row cw-gap-2 cw-items-center cw-body-base">
      <AccessTime />
      <span className="cw-text-muted" cw-id="meeting-duration" aria-label="meeting duration">
        {formattedDuration}
        {additionalText}
      </span>
    </div>
  );
};

export const DynamicMeetingDuration = ({
  duration,
  durations,
  onChange,
}: {
  duration: number;
  durations: number[];
  onChange: (duration: number) => void;
}) => {
  return (
    <div className="cw-inline-flex cw-flex-row cw-gap-2 cw-items-center cw-body-base">
      <AccessTime />
      <span className="cw-text-muted" cw-id="meeting-duration" aria-label="meeting duration">
        <Select
          value={duration.toString()}
          onChange={(value) => {
            onChange(parseInt(value, 10));
          }}
        >
          {durations.map((d) => (
            <SelectOption key={d} value={d.toString(10)}>
              {formatDuration(d)}
            </SelectOption>
          ))}
        </Select>
      </span>
    </div>
  );
};
