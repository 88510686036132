import { graphql } from "react-relay";

/////////
// GQL //
/////////

export const fragments = {
  org: graphql`
    fragment TeamJoinCreate_org on Org {
      id
      primaryOrgEmail
      workingGroups(first: 1000000) {
        edges {
          node {
            calendarIds
          }
        }
      }
      primaryOrgCalendar
      orgPersonForUserErrorable {
        ... on OrgPerson {
          personId
        }
      }
      primaryTeam {
        __typename
        ... on Team {
          teamId
          teamName
        }
        ... on GraphEntityError {
          statusCode
          message
        }
      }
      featureGrid {
        __typename
        ... on FeatureGrid {
          stateMetadata {
            primaryBillingGroupMetadata {
              subscription {
                id
              }
            }
            userMetadata {
              person {
                primaryCalendarId
              }
              trialMetadata {
                trialEndDate
                timeZone
                isTrialExpired
              }
            }
          }
        }
        ... on GraphEntityError {
          statusCode
          message
        }
      }
      billingGroups {
        ... on BillingGroupList {
          list {
            id
            name
            syncSources {
              type
              value
            }
            subscription {
              __typename
              ... on SubscriptionDetails {
                subscription {
                  id
                  productType
                }
              }
              ... on GraphEntityError {
                message
                statusCode
              }
            }
          }
        }
        ... on GraphEntityError {
          message
          statusCode
        }
      }
      userTeams {
        __typename
        ... on TeamList {
          list {
            id
            teamId
            teamName
            createdBy {
              value
            }
            teamCalendarId
            settings {
              __typename
              ... on TeamSettings {
                manageTeamCalendar
              }
            }
            userSettings {
              __typename
              ... on TeamUserSettings {
                useTeamCalendar
              }
            }
            teamMembers {
              person {
                __typename
                id
                primaryCalendarId
                isYou
                userId
                personId
                profile {
                  givenName
                  familyName
                  externalImageUrl
                }
              }
            }
            invitedMembers {
              person {
                __typename
                id
                primaryCalendarId
                isYou
                userId
                personId
                profile {
                  givenName
                  familyName
                  externalImageUrl
                }
              }
            }
          }
        }
      }
      suggestedTeams {
        __typename
        ... on SuggestedTeamList {
          list {
            team {
              id
              teamId
              teamName
              createdBy {
                value
              }
              teamMembers {
                person {
                  __typename
                  id
                  primaryCalendarId
                  isYou
                  userId
                  personId
                  profile {
                    givenName
                    familyName
                    externalImageUrl
                  }
                }
              }
              invitedMembers {
                person {
                  __typename
                  id
                  primaryCalendarId
                  isYou
                  userId
                  profile {
                    givenName
                    familyName
                    externalImageUrl
                  }
                }
              }
            }
            reasons {
              type
              value
            }
            score
          }
        }
      }
    }
  `,
  viewer: graphql`
    fragment TeamJoinCreate_viewer on Viewer
    @argumentDefinitions(
      orgRelayId: { type: "ID!" }
      query: { type: "String!", defaultValue: "" }
      excludeMyTeams: { type: "Boolean", defaultValue: true }
    ) {
      user {
        id
      }
      id
      teamQueryResultErrorable(
        query: $query
        orgRelayId: $orgRelayId
        excludeMyTeams: $excludeMyTeams
      ) {
        ... on TeamQueryResult {
          query
          teams {
            teamName
            teamId
            teamRoleAndPersonIds {
              role
              personId
            }
          }
          teamMembers {
            role
            person {
              __typename
              id
              primaryCalendarId
              isYou
              userId
              personId
              profile {
                givenName
                familyName
                externalImageUrl
              }
            }
          }
        }
      }
    }
  `,
};

export const query = graphql`
  query TeamJoinCreateQuery($orgRelayId: ID!, $query: String!) {
    viewer {
      ...TeamJoinCreate_viewer @arguments(query: $query, orgRelayId: $orgRelayId)
    }
  }
`;
