import { MantineProvider } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import {
  MuiThemeProvider,
  StylesProvider,
  ThemeOptions,
  createGenerateClassName,
  createTheme,
} from "@material-ui/core";
import React, { ReactNode, useMemo } from "react";
import { theme } from "../mantine/theme";
import { cwMuiBase } from "../themes/cwMuiBase";

type Props = {
  // Options for MUI-specific providers
  mui?: { productionPrefix?: string; theme?: ThemeOptions };
  children: ReactNode;
};

export const ThemeProvider = ({ mui = {}, children }: Props) => {
  const generateClassName = useMemo(() => {
    if (mui.productionPrefix) {
      return createGenerateClassName({ productionPrefix: mui.productionPrefix });
    } else {
      return createGenerateClassName();
    }
  }, [mui.productionPrefix]);
  const muiTheme = useMemo(() => {
    if (mui.theme) {
      return createTheme(mui.theme);
    } else {
      return createTheme(cwMuiBase);
    }
  }, [mui.theme]);
  return (
    <MantineProvider theme={theme}>
      <DatesProvider settings={{ firstDayOfWeek: 0, weekendDays: [] }}>
        <MuiThemeProvider theme={muiTheme}>
          <StylesProvider generateClassName={generateClassName}>{children}</StylesProvider>
        </MuiThemeProvider>
      </DatesProvider>
    </MantineProvider>
  );
};
