// schema
import * as ISchema from "#webapp/src/__schema__";
// libraries
import { getCurrentEnvironment } from "#webapp/src/state/relay-environment";
import { cloneDeep, debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
// graphql
import { invitePersonsToTeam, updateTeam } from "#webapp/src/mutations";
// styles
import { styles } from "./TeamSettingsSearch.styles";
// util
import { TrackingEvents } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import { ITeammateStatusPerson, MAX_TEAM_SIZE } from "#webapp/src/util/team.util";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import {
  getAugmentedPersonsFromSearchResults,
  sortUsersForModifyMode,
} from "./TeamSettingsSearch.util";
// components
import { CalloutBox } from "#webapp/src/components/callout-box";
import { useQuery } from "@apollo/client";
import { Button, TextField } from "@clockwise/design-system";
import { Clear, PersonAdd, Search, Warning } from "@clockwise/design-system/icons";
import { EcosystemEnum } from "@clockwise/schema";
import { useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import { useFeatureFlagNumber } from "../../../launch-darkly";
import { TeamSettingsTeammate } from "../team-settings-teammate";
import { GetTeamMembersSearchDocument } from "./__generated__/TeamSearch.generated";

type SettingsSearchProps = {
  org: ISchema.IOrg;
  team: ISchema.ITeam | null;
  defaultMembers: ITeammateStatusPerson[];
  containerClassName?: string;
  onCheck?: (person: ISchema.IOrgPerson, checked: boolean) => void;
  onInit?: ({ nDisplayedInviteSuggestions }: { nDisplayedInviteSuggestions: number }) => void;
  mode?: "create" | "modify" | "orgInvite";
  refetchTeams?: () => void;
  invitedMembers: Record<string, { invited: boolean; person: ISchema.IOrgPerson }>;
  onInvitedMembersChange?: (
    invitedMembers: Record<string, { invited: boolean; person: ISchema.IOrgPerson }>,
  ) => void;
};

type CheckedMembers = {
  [keyof: string]: {
    checked: boolean;
    person: ISchema.IOrgPerson;
  };
};

export const TeamSettingsSearch = ({
  org,
  team,
  defaultMembers,
  containerClassName,
  onCheck,
  onInit,
  mode = "modify",
  refetchTeams,
  invitedMembers,
  onInvitedMembersChange,
}: SettingsSearchProps) => {
  const ecosystem = useEcosystem();
  const track = useTracking();

  const [maxTeamSizeOverride] = useFeatureFlagNumber("MaxTeamSizeOverride");
  const maxTeamSize = maxTeamSizeOverride || MAX_TEAM_SIZE;

  const [isSearchMode, setIsSearchMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryForApollo, setSearchQueryForApollo] = useState("");
  const [checkedMembers, setCheckedMembers] = useState<CheckedMembers>({});
  const [sortedPersons, setSortedPersons] = useState<ITeammateStatusPerson[]>([]);
  const ref = useRef({
    hasInitialized: false,
  });
  const environment = getCurrentEnvironment();

  const { data, loading } = useQuery(GetTeamMembersSearchDocument, {
    variables: {
      orgRelayId: org.id,
      queryInput: searchQueryForApollo,
      myCalendarIds: [org.primaryOrgCalendar],
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUpdateInputDebounced = useCallback(debounce(setSearchQueryForApollo, 500), []);

  useEffect(() => {
    if (!loading) {
      track(TrackingEvents.TEAMS.TEAM_SETTING_SEARCH_VIEW_RESULTS, {
        searchQuery: searchQueryForApollo,
        count: sortedPersons.length ?? 0,
        mode: mode,
      });
    }
  }, [sortedPersons]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onUpdateInputDebounced(searchQuery);
  }, [searchQuery, onUpdateInputDebounced]);

  useEffect(() => {
    setIsSearchMode(mode === "create" || mode === "orgInvite");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loading) return;
    const queryResult = getValue(data?.viewer?.orgPersonQueryResultErrorable);
    const nextQueryMatchesSearch = queryResult && queryResult.query === searchQuery;
    cleanupOldStates(nextQueryMatchesSearch || false);
    sortPersonsFromResults(queryResult as ISchema.IOrgPersonQueryResult);
  }, [data, defaultMembers, mode]); // eslint-disable-line react-hooks/exhaustive-deps

  const sortPersonsFromResults = (queryResult: ISchema.IOrgPersonQueryResult) => {
    if (!isSearchMode && mode !== "create" && mode !== "orgInvite") {
      const sortedMembers = sortUsersForModifyMode(defaultMembers);
      setSortedPersons(sortedMembers);
      return;
    }
    if (!queryResult) {
      logger.error("failed to search");
      setSortedPersons([]);
      return;
    }

    const persons = getAugmentedPersonsFromSearchResults(
      queryResult,
      checkedMembers,
      invitedMembers,
      team,
      org,
      mode,
    );
    // weird to do here but its ensured to only happen once
    if (!ref.current.hasInitialized && onInit) {
      ref.current.hasInitialized = true;
      onInit({
        nDisplayedInviteSuggestions: persons.filter((p) => p.isSuggested).length,
      });
    }
    setSortedPersons(persons);
  };

  const cleanupOldStates = (nextQueryMatchesSearch: boolean) => {
    if (nextQueryMatchesSearch) {
      const checkedMembersCopy = cloneDeep(checkedMembers);
      // cleanup any old unchecked cached members
      for (const calendarId in checkedMembersCopy) {
        const checkedMember = checkedMembersCopy[calendarId];
        if (!checkedMember.checked) {
          delete checkedMembersCopy[calendarId];
        }
      }
      setCheckedMembers(checkedMembersCopy);
    }

    if (
      defaultMembers.length &&
      defaultMembers.some(
        (dm) =>
          defaultMembers.findIndex((m) => m?.person?.personId === dm?.person?.personId) === -1,
      )
    ) {
      setIsSearchMode(mode === "create" || mode === "orgInvite");
      setSearchQuery("");
    }
  };

  const onClickSearchButton = (isSearchModeNew: boolean) => {
    setIsSearchMode(isSearchModeNew);
    if (searchQuery !== "") {
      setSearchQuery("");
    }
    track(TrackingEvents.TEAMS.TEAM_SETTING_SEARCH_CLICK_SEARCH_TOGGLE, {
      showSearch: isSearchMode,
    });
  };

  const renderRow = (teammate: ITeammateStatusPerson, i: number) => {
    return (
      <TeamSettingsTeammate
        key={i}
        mode={mode}
        teammate={teammate}
        onInvite={onInvite}
        onDelete={onDelete}
        onCheck={onCheckClicked}
        isSearchResult={mode === "create" || mode === "orgInvite" || isSearchMode}
      />
    );
  };

  const renderResults = () => {
    if (!sortedPersons.length && mode === "create" && searchQuery.length) {
      return (
        <div className="cw-min-h-[380px]">
          <div className="cw-text-center cw-p-5">No results found</div>
        </div>
      );
    }
    return <div className="cw-min-h-[380px]">{sortedPersons.map(renderRow)}</div>;
  };

  const onCheckClicked = (person: ISchema.IOrgPerson, checked: boolean) => {
    const checkedMembersDupe = cloneDeep(checkedMembers);
    checkedMembersDupe[person.primaryCalendarId] = { person, checked };
    setCheckedMembers(checkedMembersDupe);

    if (onCheck) {
      onCheck(person, checked);
    }

    const dupeOfSortedPersons = cloneDeep(sortedPersons);
    for (const sortedPerson of dupeOfSortedPersons) {
      if (sortedPerson?.person?.primaryCalendarId === person.primaryCalendarId) {
        sortedPerson.checked = checked;
      }
    }
    setSortedPersons(dupeOfSortedPersons);

    track(TrackingEvents.TEAMS.TEAM_SETTING_SEARCH_CLICK_CHECK, { checked });
  };

  const onInvite = (person: ISchema.IOrgPerson, resend?: boolean) => {
    if (!team) {
      return;
    }

    if (resend) {
      invitePersonsToTeam(
        environment,
        { orgRelayId: org.id, teamId: team.teamId, personIds: [person.personId] },
        () => {
          toast.success("Invite resent!");
        },
        () => {
          toast.error("Failed to resend invite");
          logger.error("Failed to resend invite");
        },
      );

      track(TrackingEvents.TEAMS.TEAM_SETTING_SEARCH_CLICK_RESEND_INVITE, {
        calendarId: person.primaryCalendarId,
      });
    } else {
      updateTeam(
        environment,
        {
          orgRelayId: org.id,
          teamId: team.teamId,
          addedTeamMembers: [{ personId: person.personId, role: ISchema.TeamMemberRole.Member }],
        },
        () => {
          const msg = person.userId ? "This user was added to your team!" : "Invite sent!";
          toast.success(msg);
        },
        () => {
          const msg = person.userId ? "Failed to add user to your team!" : "Failed to send invite!";
          toast.error(msg);
          logger.error(msg);
        },
      );

      invitePersonsToTeam(
        environment,
        { orgRelayId: org.id, teamId: team.teamId, personIds: [person.personId] },
        () => null,
        () => {
          logger.error("Failed to send invite");
        },
      );

      track(TrackingEvents.TEAMS.TEAM_SETTING_SEARCH_CLICK_INVITE, {
        calendarId: person.primaryCalendarId,
      });
    }

    if (onInvitedMembersChange) {
      const invitedMembersCopy = cloneDeep(invitedMembers);
      invitedMembersCopy[person.primaryCalendarId] = { person, invited: true };
      onInvitedMembersChange(invitedMembersCopy);
    }
  };

  const onDelete = (person: ISchema.IOrgPerson) => {
    if (!team) {
      return;
    }

    // TEMP: until backend can automatically re-assign team calendar owner
    // do not allow removing the team calendar owner from team
    const teamSettings = getValue(team.settings);
    const m365TeamCalendarDetails = getValue(teamSettings?.m365TeamCalendarDetails);
    if (
      ecosystem === EcosystemEnum.Microsoft &&
      m365TeamCalendarDetails?.owner.personId === person.personId
    ) {
      toast.error(
        "Cannot remove teammate because they are this team's Availability Calendar owner",
      );
      return;
    }

    updateTeam(
      environment,
      {
        orgRelayId: org.id,
        teamId: team.teamId,
        removedTeamMembers: [person.personId],
      },
      () => {
        toast.success("Removed!");
        if (org.primaryOrgCalendar === person.primaryCalendarId && refetchTeams) {
          refetchTeams(); // refetch teams if removing yourself
        }
      },
      () => {
        toast.error("Failed to remove teammate");
        logger.error("Failed to remove teammate");
      },
    );

    track(TrackingEvents.TEAMS.TEAM_SETTING_SEARCH_CLICK_DELETE, { isSelf: person.isYou });

    if (onInvitedMembersChange) {
      const invitedMembersCopy = cloneDeep(invitedMembers);
      invitedMembersCopy[person.primaryCalendarId] = { person, invited: false };
      onInvitedMembersChange(invitedMembersCopy);
    }
  };

  const renderSearch = () => {
    const { teamMembers, invitedMembers } = team || {
      teamMembers: [] as ISchema.ITeamMember[],
      invitedMembers: [] as ISchema.ITeamMember[],
    };
    const isTeamMaxSize = teamMembers.length + invitedMembers.length >= maxTeamSize;
    if (isTeamMaxSize) {
      return (
        <CalloutBox warning style={styles.teamSizeWarning}>
          <Warning style={styles.warningIcon} />
          <span style={styles.warningLabel}>
            This team has reached its maximum size of 50 people
          </span>
        </CalloutBox>
      );
    }

    if (isSearchMode) {
      return (
        <div className="cw-p-3 cw-flex cw-items-center cw-gap-2 cw-text-subtle">
          <TextField
            placeholder="Search for teammates"
            startIcon={Search}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            autoFocus={mode !== "create"}
            loading={loading}
          />
          {/* if mode is not create, clear field and go back to view only mode */}
          {mode !== "orgInvite" && (
            <Button
              size="large"
              variant="text"
              startIcon={Clear}
              onClick={() => onClickSearchButton(mode === "create")}
            />
          )}
        </div>
      );
    }

    return (
      <div className="cw-p-3">
        <Button
          size="large"
          variant="text"
          sentiment="positive"
          startIcon={PersonAdd}
          onClick={() => onClickSearchButton(true)}
        >
          Invite new teammate
        </Button>
      </div>
    );
  };

  return (
    <div className={containerClassName}>
      {renderSearch()}
      {renderResults()}
    </div>
  );
};
