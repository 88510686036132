// IMPORTS
// schema
import * as ISchema from "#webapp/src/__schema__";

import { commitMutation } from "react-relay";
import { Environment } from "relay-runtime";
import { createTeamCalendarMutation } from "./CreateTeamCalendar.gql"; // change

// TYPES
interface ICreateTeamCalendarMutationResponse {
  createTeamCalendar: ISchema.ICreateTeamCalendarMutationPayload;
}

// MUTATION
export function createTeamCalendar(
  environment: Environment,
  input: ISchema.ICreateTeamCalendarMutationInput,
  onSuccess?: (response?: ISchema.ICreateTeamCalendarMutationPayload) => void,
  onError?: (error?: any) => void,
) {
  commitMutation(environment, {
    onError,
    mutation: createTeamCalendarMutation,
    variables: { input },
    onCompleted: (_response: ICreateTeamCalendarMutationResponse, errors?: Error[]) => {
      if (errors) {
        onError?.(errors[0]);
      } else {
        onSuccess?.();
      }
    },
  });
}
