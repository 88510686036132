///////////////////////
// IMPORTS
///////////////////////

// libraries
import {
  Middleware,
  MiddlewareNextFn,
  QueryResponseCache,
  RelayNetworkLayer,
  RelayNetworkLayerRequest,
  RelayNetworkLayerRequestBatch,
  RelayNetworkLayerResponse,
  batchMiddleware,
  cacheMiddleware,
  errorMiddleware,
  urlMiddleware,
} from "react-relay-network-modern/node8";
import { Store } from "redux";
import shajs from "sha.js";

// internal
import { networkConfig } from "./network-config";
import { getSubscriptionHandler } from "./subscription-handler";

// constants
import { getApiUrl } from "@clockwise/client-commons/src/config/api";

// state
import { setIsOnline } from "#webapp/src/state/actions/chrome-extension.actions";
import { clearSudoTarget } from "#webapp/src/state/actions/sudo.actions";
import { jwt, xsrf } from "#webapp/src/state/local-storage";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";

// util
import { doUserLogout } from "#webapp/src/util/auth.util";
import { logger, sentryTransport } from "#webapp/src/util/logger.util";
import { isRRNLRequestError } from "#webapp/src/util/relay.util";
import { recordDuration } from "#webapp/src/util/stats.util";
import { isImpersonated } from "@clockwise/client-commons/src/util/jwt";
import { genTxnId } from "@clockwise/client-commons/src/util/txn";
import { setNetworkFailureScale } from "@clockwise/web-commons/src/state";
import { clientId } from "@clockwise/web-commons/src/util/fetch.util";
import { getReduxStore } from "../state/redux-store";

///////////////////////
// TYPES
///////////////////////
interface IClockwiseMiddlewareOpts {
  config: { headers: { [header: string]: any } };
  reduxStore: Store<IReduxState>;
  logoutCallback: () => void;
  jwtInterface: typeof jwt;
  xsrfInterface: typeof xsrf;
  clearSudo: typeof clearSudoTarget;
}

interface PersistedQueryRequestBody {
  operationName: string;
  variables: RelayNetworkLayerRequest["variables"];
  extensions: {
    persistedQuery: {
      version: number;
      sha256Hash: string;
    };
  };
}

enum GraphqlResponseActionEnum {
  PersistedQueryRefetch = "PersistedQueryRefetch",
  IncrementNetworkFailureScale = "IncrementNetworkFailureScale",
  DecrementNetworkFailureScale = "DecrementNetworkFailureScale",
}

///////////////////////
// CLOCKWISE MIDDLEWARE
///////////////////////

function extractRequestTags(
  req: RelayNetworkLayerRequest | RelayNetworkLayerRequestBatch,
): string[] {
  if (req instanceof RelayNetworkLayerRequest) {
    return [req.id];
  }

  const ids = req.requests.map((r) => r.id);
  return ids;
}

const NO_CACHE_DIRECTIVES = ["no-cache", "no-store"];

const isCacheable = (response: RelayNetworkLayerResponse): boolean => {
  // @ts-ignore
  const cacheControl = response.headers && response.headers.get("cache-control");

  // do not cache on undefined or non 200 status codes
  if (response.status === undefined || response.status !== 200) {
    return false;
  }

  if (!cacheControl) {
    // default to everything is cacheable w/out a cache-control header
    return true;
  }

  const cacheDirectives = (cacheControl as string).split(",").map((directive) => directive.trim());

  // TODO: figure out expiration directives
  const hasNoCacheDirective = NO_CACHE_DIRECTIVES.some(
    (directive) => cacheDirectives.indexOf(directive) > -1,
  );

  return !hasNoCacheDirective;
};

function clockwiseCacheMiddleware(cachingMiddleware: Middleware): Middleware {
  return (next: MiddlewareNextFn) => (req: RelayNetworkLayerRequest) => {
    return cachingMiddleware(next)(req).then((res) => {
      if (modernCache && !isCacheable(res)) {
        const queryId = req.getID();
        const variables = req.getVariables();
        modernCache.set(queryId, variables, undefined as any);
      }

      return res;
    });
  };
}

function clockwiseMiddleware({
  config,
  reduxStore,
  logoutCallback,
  jwtInterface,
  xsrfInterface,
  clearSudo,
}: IClockwiseMiddlewareOpts): Middleware {
  return (next: MiddlewareNextFn) => (req: RelayNetworkLayerRequest) => {
    // squelch most mutations while impersonated
    if (
      isImpersonated(jwtInterface.get()) &&
      req.operation &&
      req.operation.operationKind === "mutation" &&
      req.operation.name
    ) {
      // A lot of error handlers silently swallow the exception, so log this
      // here. Sometimes you might be stuck in sudo and considered
      // impersonating yourself, which is a bug:
      // https://app.asana.com/0/357266512179263/1200795347801593
      logger.info(`Preventing mutation ${req.operation.name} while impersonated.`);
      throw new Error("preventing mutation while impersonated");
    }

    const txnId = genTxnId();

    // set up fetch
    req.fetchOpts.method = "POST";
    req.fetchOpts.credentials = "include";
    req.fetchOpts.headers.txnid = txnId;
    req.fetchOpts.headers.clientId = clientId;

    const isBatch = req instanceof RelayNetworkLayerRequestBatch;

    // setup necessary data for "auto" persisted queries
    const requests: RelayNetworkLayerRequest[] = [];
    const persistedQueryRequestBodies: PersistedQueryRequestBody[] = [];
    const queryTexts: string[] = [];

    // we need to handle batch / non-batch differently
    if (req instanceof RelayNetworkLayerRequestBatch) {
      requests.push(...req.requests);
    } else {
      requests.push(req);
    }

    // for every "request", we need to generate a persistedQuery extension to work with Apollo Server's "Automatic Persisted Queries"
    requests.forEach((request) => {
      const { id, text, name } = request.operation;

      // Since we don't do Relay-style persisted queries, text should always be present and id should always be null.
      // For more information on Persisted Queries, see https://relay.dev/docs/v10.1.3/persisted-queries/
      if (id) {
        logger.warn(
          "Operation ID found in Relay request. This should not exist because we do not support pregenerated persisted queries.",
        );
      }
      if (!text) {
        const error = new Error(`Query text not found in Relay request (name=${name}) id=${id})`);
        // Make sure we log this error!
        logger.error("Relay request missing query text", error);
        // Also throw it so that the request fails
        throw error;
      }
      const sha256Hash = shajs("sha256").update(text).digest("hex");

      persistedQueryRequestBodies.push({
        operationName: name,
        variables: request.variables,
        extensions: {
          persistedQuery: {
            version: 1,
            sha256Hash,
          },
        },
      });

      // we save our query texts in case we have a miss later
      queryTexts.push(text);
    });

    // we set the body differently depending on whether batch or not
    req.fetchOpts.body = !isBatch
      ? JSON.stringify(persistedQueryRequestBodies[0])
      : JSON.stringify(persistedQueryRequestBodies);

    logger.debug("Sending request from client", { txnId, clientId, requestId: req.getID() });

    // copy in our network layer config headers
    Object.keys(config.headers).forEach((header) => {
      if (config.headers[header]) {
        req.fetchOpts.headers[header] = config.headers[header];
      }
    });

    // sudo target header
    const sudoTarget = reduxStore.getState().sudo.sudoTarget;
    if (sudoTarget) {
      req.fetchOpts.headers.st = sudoTarget;
      reduxStore.dispatch(clearSudo());
    }

    const queries = extractRequestTags(req);
    const startTime = new Date().getTime();

    // send out the request
    return next(req)
      .then(async (rootRes: RelayNetworkLayerResponse) => {
        const store = getReduxStore();
        let res = rootRes;

        // here we collect errors, on the root response and possibly within our batch json payloads
        const foundErrors = [
          ...(res.errors ? [res.errors] : []),
          // @ts-ignore
          ...((res.json.length && res.json) || [])
            .filter((d: any) => d.errors)
            .map((d: any) => d.errors),
        ];

        // if one query does not have a persisted query cached, we will resend everything
        // NOTE: this is non-optimal, but shouldn't matter since this approach will eventually be deprecated
        //       and this only affects the first user of a query during a webservers lifetime
        //       go/persisted-queries#da8d8dd3a35448bb8fb308455f4bbf6a

        const graphqlAction =
          foundErrors.length === 0
            ? GraphqlResponseActionEnum.DecrementNetworkFailureScale // if no errors are found, decrement network failure scale
            : foundErrors.some((errors) => {
                return errors?.[0]?.message === "PersistedQueryNotFound";
              })
            ? GraphqlResponseActionEnum.PersistedQueryRefetch // if errors are found AND persisted query not found, send full query
            : GraphqlResponseActionEnum.IncrementNetworkFailureScale; // if errors are found AND no persisted query not found, increment network failure scale

        if (graphqlAction === GraphqlResponseActionEnum.PersistedQueryRefetch) {
          const newBody = requests.map((_, i) => ({
            ...persistedQueryRequestBodies[i],
            query: queryTexts[i],
          }));

          req.fetchOpts.body = JSON.stringify(!isBatch ? newBody[0] : newBody);

          res = await next(req);
        } else if (graphqlAction === GraphqlResponseActionEnum.IncrementNetworkFailureScale) {
          store.dispatch(setNetworkFailureScale(store.getState().shared.networkFailureScale + 1));
        } else if (graphqlAction === GraphqlResponseActionEnum.DecrementNetworkFailureScale) {
          store.dispatch(setNetworkFailureScale(store.getState().shared.networkFailureScale - 1));
        }

        // record the request duration
        const duration = new Date().getTime() - startTime;

        queries.forEach((query) =>
          recordDuration("request.duration", duration, { request: query, batch: String(isBatch) }),
        );

        // read from response headers
        if (res && res.headers) {
          // @ts-ignore
          const responseTxnId = res.headers.get("txnid");
          if (responseTxnId) {
            logger.debug("Reading response txnId", { txnId, responseTxnId });
          }

          // did we get a logout header?
          // @ts-ignore
          const logoutHeader = res.headers.get("logout");
          if (logoutHeader) {
            logoutCallback();
          } else {
            // maybe set the jwt
            // @ts-ignore
            const jwtToken = res.headers.get("jwt");
            if (jwtToken && jwtToken !== jwtInterface.get()) {
              jwtInterface.set(jwtToken);
            }

            // maybe set the xsrf
            // @ts-ignore
            const xsrfToken = res.headers.get("xsrf");
            if (xsrfToken && xsrfToken !== xsrfInterface.get()) {
              xsrfInterface.set(xsrfToken);
            }
          }

          // set online state
          const currentState = store.getState();

          if (!currentState.webExtension.isOnline) {
            store.dispatch(setIsOnline(true));
          }
        }

        if (!isBatch) {
          return res;
        } else {
          const newJson = (res.json as any[]).map((data, index) => {
            if (data.id) {
              return data;
            }

            // apollo server doesn't send back the id for the query, just uses the order
            const id = queries[index];

            return {
              id,
              ...data,
            };
          });

          res.json = newJson;
          return res;
        }
      })
      .catch((anyRes: any) => {
        const store = getReduxStore();

        if (isRRNLRequestError(anyRes)) {
          // const requestSize = JSON.stringify(anyRes.req.fetchOpts.headers).length;

          // logger.error('Network layer RRNLRequestError detected', anyRes, {
          //   requestSize,
          //   status: anyRes.res!.status,
          //   isBodyUndefined: anyRes.res!.body === undefined ? 'true' : 'false',
          // });

          store.dispatch(setNetworkFailureScale(store.getState().shared.networkFailureScale + 1));

          return anyRes.res!;
        }

        const res = anyRes as RelayNetworkLayerResponse;

        const error = (res.errors && res.errors.length && res.errors[0]) || undefined;
        const msg = (!!res.text && res.text) || "";
        // @ts-ignore
        const responseTxnId = res.headers && res.headers.get("txnid");

        if (!responseTxnId && !res.status && (!res.errors || !res.errors.length)) {
          // in the case we have no errors in the RelayPayload, and our status, text and response txnId are undefined
          // we are going to ingore this error as it is likely due to being either offline or a parent prop changing which
          // will trigger the request to be aborted
          return res;
        } else if (responseTxnId) {
          logger.debug("Failed request has response txnId", { txnId, responseTxnId });
        }

        store.dispatch(setNetworkFailureScale(store.getState().shared.networkFailureScale + 1));

        sentryTransport.addTag("txnId", responseTxnId || txnId);
        sentryTransport.addTag("clientId", clientId);
        logger.error(`clockwiseMiddleware network exception ${msg}`, error, {
          status: res.status || "undefined",
          statusText: res.statusText || "undefined",
          hasData: res.data ? "true" : "false",
          errorCount: (res.errors && res.errors.length) || 0,
        });
        return res;
      });
  };
}

/////////////////////
// NETWORK LAYER
////////////////////

// make the cache available for manipulation
export let modernCache: QueryResponseCache | undefined = undefined;
export const batchTimeout = 10;

// create a new modern network layer
export function getModernNetworkLayer(reduxStore: Store<IReduxState>) {
  // middlewares that process requests
  const middleWares = [
    // FIXME: this middleware caches failed queries (eg. on an autocomplete, fast typing
    // causes queries before the last character to be canceled, this middleware will
    // never then refetch if you go back to a query of any of those inbetween results)
    clockwiseCacheMiddleware(
      cacheMiddleware({
        size: 100, // max 100 requests
        ttl: 900000, // 15 minutes
        onInit: (cache) => {
          modernCache = cache;
        },
      }),
    ),
    urlMiddleware({
      url: (_req) => {
        return Promise.resolve(`${getApiUrl()}/graphql`);
      },
    }),
    batchMiddleware({
      batchTimeout,
      batchUrl: (_requstMap) => {
        return Promise.resolve(`${getApiUrl()}/graphql/batch`);
      },
    }),
    // loggerMiddleware(), ENABLE IF YOU NEED TO DEBUG
    errorMiddleware(),
    clockwiseMiddleware({
      reduxStore,
      clearSudo: clearSudoTarget,
      config: networkConfig,
      logoutCallback: doUserLogout,
      jwtInterface: jwt,
      xsrfInterface: xsrf,
    }),
    // needs to be last to handle 500 errors
    // retryMiddleware({
    //   fetchTimeout: 60000,
    //   retryDelays: (attempt: any) => Math.min(3600000, Math.pow(3, attempt + 2) * 100),
    //   statusCodes: [500, 502, 503, 504],
    //   beforeRetry: ({ delay, attempt, lastError }) => {
    //     logger.info(`beforeRetry attempt ${attempt}, delay: ${delay}`, lastError);
    //   },
    // }),
  ];
  // subscribe function for subscriptions
  const subscribeFn = getSubscriptionHandler({
    subscriptionPath: "/subscription",
    jwtInterface: jwt,
    xsrfInterface: xsrf,
  });
  // putting it all together
  // @ts-ignore
  return new RelayNetworkLayer(middleWares, { subscribeFn });
}
