import * as React from "react";

interface Props {
  style?: React.CSSProperties;
  children: React.ReactNode;
}

/**
 * @deprecated Prefer tailwind classes (e.g., cw-body-lg)
 */
export const P = (props: Props) => {
  return (
    <div className="cw-body-lg cw-mb-4" style={{ ...props.style }}>
      {props.children}
    </div>
  );
};
