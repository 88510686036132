import {
  scaleInterpolateOutput,
  scaleInterpolateRange,
  textScaleConfig,
} from "#webapp/src/util/spring-animations.util";
import { animated, useSpring } from "@react-spring/web";
import * as React from "react";

interface ISpringTextScaleProps {
  className: string;
  children: React.ReactNode;
}

export const SpringScaleGrow = ({ className, children }: ISpringTextScaleProps) => {
  const { textScale } = useSpring({
    from: { textScale: 0 },
    to: { textScale: 1 },
    config: textScaleConfig,
  });

  return (
    <animated.div
      className={className}
      style={{
        transform: textScale
          .to({
            range: scaleInterpolateRange,
            output: scaleInterpolateOutput,
          })
          .to((x) => `scale(${x})`),
      }}
    >
      {children}
    </animated.div>
  );
};
