/**
 * @generated SignedSource<<dd5baef3c84d500475942cfdc5ce7c9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NoMeetingDayOfWeek = "Friday" | "Monday" | "Saturday" | "Sunday" | "Thursday" | "Tuesday" | "Wednesday" | "%future added value";
export type TeamMemberRole = "Admin" | "Member" | "%future added value";
export type TeamType = "Managerial" | "Other" | "%future added value";
export type TimeSlotEnum = "T_00_00" | "T_00_30" | "T_01_00" | "T_01_30" | "T_02_00" | "T_02_30" | "T_03_00" | "T_03_30" | "T_04_00" | "T_04_30" | "T_05_00" | "T_05_30" | "T_06_00" | "T_06_30" | "T_07_00" | "T_07_30" | "T_08_00" | "T_08_30" | "T_09_00" | "T_09_30" | "T_10_00" | "T_10_30" | "T_11_00" | "T_11_30" | "T_12_00" | "T_12_30" | "T_13_00" | "T_13_30" | "T_14_00" | "T_14_30" | "T_15_00" | "T_15_30" | "T_16_00" | "T_16_30" | "T_17_00" | "T_17_30" | "T_18_00" | "T_18_30" | "T_19_00" | "T_19_30" | "T_20_00" | "T_20_30" | "T_21_00" | "T_21_30" | "T_22_00" | "T_22_30" | "T_23_00" | "T_23_30" | "%future added value";
export type UrnType = "Date" | "Email" | "Event" | "FlowState" | "Guest" | "Org" | "Phone" | "Services" | "Setting" | "Team" | "Teammate" | "TimeZone" | "User" | "WorkOsOrg" | "WorkingGroup" | "WorkingHours" | "%future added value";
export type createTeamMutationInput = {
  clientMutationId?: string | null;
  orgRelayId: string;
  setAsPrimary?: boolean | null;
  teamMembers: ReadonlyArray<TeamRoleAndPersonIdInput>;
  teamName: string;
  teamType: TeamType;
};
export type TeamRoleAndPersonIdInput = {
  personId: string;
  role: TeamMemberRole;
};
export type CreateTeamMutation$variables = {
  input: createTeamMutationInput;
};
export type CreateTeamMutation$data = {
  readonly createTeam: {
    readonly clientMutationId: string | null;
    readonly team: {
      readonly __typename: string;
      readonly id: string;
      readonly teamId: string;
      readonly teamName: string;
      readonly teamCalendarId: string | null;
      readonly createdBy: {
        readonly id: string;
        readonly type: UrnType | null;
        readonly value: string | null;
      };
      readonly teamMembers: ReadonlyArray<{
        readonly role: TeamMemberRole;
        readonly person: {
          readonly id: string;
          readonly userId: string | null;
          readonly personId: string;
          readonly primaryCalendarId: string;
          readonly calendarAliases: ReadonlyArray<string>;
          readonly redirectPersonId: string | null;
          readonly profile: {
            readonly givenName: string | null;
            readonly familyName: string | null;
            readonly externalImageUrl: string | null;
          } | null;
        };
      }>;
      readonly invitedMembers: ReadonlyArray<{
        readonly role: TeamMemberRole;
        readonly person: {
          readonly id: string;
          readonly userId: string | null;
          readonly personId: string;
          readonly primaryCalendarId: string;
          readonly calendarAliases: ReadonlyArray<string>;
          readonly redirectPersonId: string | null;
          readonly profile: {
            readonly givenName: string | null;
            readonly familyName: string | null;
            readonly externalImageUrl: string | null;
          } | null;
        };
      }>;
      readonly userSettings: {
        readonly __typename: "TeamUserSettings";
        readonly useTeamCalendar: boolean;
        readonly useNoMeetingDay: boolean;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
      readonly settings: {
        readonly __typename: "TeamSettings";
        readonly manageTeamCalendar: boolean;
        readonly slackSettings: {
          readonly notificationEnabled: boolean;
          readonly channelId: string | null;
          readonly channelName: string | null;
          readonly timeSlot: TimeSlotEnum | null;
        };
        readonly syncWFHEvents: boolean;
        readonly minimumMinutesForSyncing: number;
        readonly useNoMeetingDay: boolean;
        readonly noMeetingDayOfWeek: NoMeetingDayOfWeek;
        readonly noMeetingDayEmailOnScheduleOver: boolean;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
    };
  } | null;
};
export type CreateTeamMutation = {
  variables: CreateTeamMutation$variables;
  response: CreateTeamMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamCalendarId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Urn",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "role",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "OrgPerson",
    "kind": "LinkedField",
    "name": "person",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "personId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "primaryCalendarId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "calendarAliases",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "redirectPersonId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfile",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "givenName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "familyName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalImageUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamMember",
  "kind": "LinkedField",
  "name": "teamMembers",
  "plural": true,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamMember",
  "kind": "LinkedField",
  "name": "invitedMembers",
  "plural": true,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useNoMeetingDay",
  "storageKey": null
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useTeamCalendar",
      "storageKey": null
    },
    (v12/*: any*/)
  ],
  "type": "TeamUserSettings",
  "abstractKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manageTeamCalendar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamSlackSettings",
      "kind": "LinkedField",
      "name": "slackSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notificationEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "channelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "channelName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timeSlot",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "syncWFHEvents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimumMinutesForSyncing",
      "storageKey": null
    },
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "noMeetingDayOfWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "noMeetingDayEmailOnScheduleOver",
      "storageKey": null
    }
  ],
  "type": "TeamSettings",
  "abstractKey": null
},
v15 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTeamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "createTeamMutationPayload",
        "kind": "LinkedField",
        "name": "createTeam",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "team",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "userSettings",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTeamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "createTeamMutationPayload",
        "kind": "LinkedField",
        "name": "createTeam",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "team",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "userSettings",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v13/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "22f350c70b38bfc175adc8f25f3e8faf",
    "id": null,
    "metadata": {},
    "name": "CreateTeamMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTeamMutation(\n  $input: createTeamMutationInput!\n) {\n  createTeam(input: $input) {\n    clientMutationId\n    team {\n      __typename\n      id\n      teamId\n      teamName\n      teamCalendarId\n      createdBy {\n        id\n        type\n        value\n      }\n      teamMembers {\n        role\n        person {\n          id\n          userId\n          personId\n          primaryCalendarId\n          calendarAliases\n          redirectPersonId\n          profile {\n            givenName\n            familyName\n            externalImageUrl\n          }\n        }\n      }\n      invitedMembers {\n        role\n        person {\n          id\n          userId\n          personId\n          primaryCalendarId\n          calendarAliases\n          redirectPersonId\n          profile {\n            givenName\n            familyName\n            externalImageUrl\n          }\n        }\n      }\n      userSettings {\n        __typename\n        ... on TeamUserSettings {\n          useTeamCalendar\n          useNoMeetingDay\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      settings {\n        __typename\n        ... on TeamSettings {\n          manageTeamCalendar\n          slackSettings {\n            notificationEnabled\n            channelId\n            channelName\n            timeSlot\n          }\n          syncWFHEvents\n          minimumMinutesForSyncing\n          useNoMeetingDay\n          noMeetingDayOfWeek\n          noMeetingDayEmailOnScheduleOver\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ce87a85b99e8e5f4f4fe2253b8f81ef9";

export default node;
