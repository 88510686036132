import { graphql } from "react-relay";

// export const modifyAutopilotForEventMutation = graphql`
//   mutation ModifyAutopilotForEventMutation(
//     $input: ModifyAutopilotForEventMutationInput!
//   ) {
//     modifyAutopilotForEvent(input: $input) {
//       clientMutationId
//     }
//   }
// `;

export const batchModifyAutopilotForEventsMutation = graphql`
  mutation ModifyAutopilotForEventsBatchMutation(
    $input: BatchModifyAutopilotForEventsMutationInput!
  ) {
    batchModifyAutopilotForEvents(input: $input) {
      clientMutationId
    }
  }
`;
