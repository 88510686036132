import { Loader } from "@clockwise/design-system/src/components/Loader";
import { animated, config, useSpring, useTransition } from "@react-spring/web";
import { groupBy, map, orderBy } from "lodash";
import { DateTime, IANAZone, Interval } from "luxon";
import pluralize from "pluralize";
import * as React from "react";
import { useMemo } from "react";
import { useSwipeable } from "react-swipeable";
import { useElementDimensions, useWindowSize } from "../../util/react.util";
import { transform } from "../../util/transform.util";
import { HoverableChevron } from "../hoverable-chevron";
import { DateString, LinkTimeSlot } from "../scheduling-link";
import { narrowCutoff } from "../scheduling-link/scheduling-link.styles";
import { TimesForDay } from "../times-for-day";
import { ShowEarliestButton } from "./ShowEarliestButton";

export interface TimesForDaysProps {
  timeSlots: LinkTimeSlot[];
  timeZone: IANAZone;
  currentDate?: DateString | null;
  onChangeDate: (date: DateString) => void;
  onSelectTime: (timeRange: Interval) => void;
  loading: boolean;
  trackSession: (event: string, data?: Record<string, any>) => void;
  highlightBestTimes: boolean;
}

function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

const DAY_MARGIN_LEFT = 8;
const DAY_MARGIN_RIGHT = 8;

type LinkTimeSlotsByDate = {
  date: string;
  times: LinkTimeSlot[];
};

// Takes in a list of days, and renders them in a paginated carousel.
// As we preemptively fetch more days and update the days props, genDaysToRender updates
// daysToRender, which is the array of days passed to react-spring, useTransition to render.

export const MAX_DAYS_TO_RENDER = 5;

// These constants are exports to be used in calculations in other components.
export const DAY_WIDTH_PRE_MARGIN = 211;
export const TOTAL_DAY_WIDTH = DAY_WIDTH_PRE_MARGIN + DAY_MARGIN_LEFT + DAY_MARGIN_RIGHT; // 16 accounts for 8 left and 8 right padding.

export const TimesForDays = ({
  timeSlots,
  timeZone,
  currentDate,
  onChangeDate,
  onSelectTime,
  loading,
  trackSession,
  highlightBestTimes,
}: TimesForDaysProps) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const { width } = useElementDimensions(containerRef.current, 3);
  const { width: windowWidth } = useWindowSize();
  const isNarrow = windowWidth < narrowCutoff;
  const [daysToRender, setDaysToRender] = React.useState<JSX.Element[]>([]);

  const days: LinkTimeSlotsByDate[] = useMemo(
    () =>
      transform(
        timeSlots,
        (slots) =>
          groupBy(slots, (slot) =>
            Interval.fromISO(slot.timeSlot).start.setZone(timeZone).toISODate(),
          ),
        (groupedSlots) =>
          map(groupedSlots, (times, date) => ({
            date,
            times: orderBy(times, ["timeSlot", "asc"]),
          })),
        (groupedList) => orderBy(groupedList, ["date", "asc"]),
      ),
    [timeSlots, timeZone],
  );

  const startIndex = React.useMemo(() => {
    if (!currentDate) return 0;

    const currentDateTime = DateTime.fromISO(currentDate).setZone(timeZone);
    const index = days.findIndex(
      (day) =>
        day.times.length &&
        DateTime.fromISO(day.date).setZone(timeZone).hasSame(currentDateTime, "day"),
    );

    return index >= 0 ? index : 0;
  }, [days, currentDate, timeZone]);

  const numDaysToRender = React.useMemo(() => {
    if (isNarrow) {
      return 1;
    }

    return Math.min(Math.floor(width / TOTAL_DAY_WIDTH), MAX_DAYS_TO_RENDER) || 1;
  }, [width, isNarrow]);

  // Adding numDays to give us an idea of the window width users are viewing the link with.
  const trackSessionWithNumDays = React.useCallback(
    (event: string, data?: Record<string, any>) =>
      trackSession(event, {
        ...data,
        numDaysInView: numDaysToRender,
      }),
    [trackSession, numDaysToRender],
  );

  const onLeft = () => {
    const newIndex = Math.max(0, startIndex - numDaysToRender);
    onChangeDate(days[newIndex]?.date);
  };

  const onRight = () => {
    const newIndex = Math.min(startIndex + numDaysToRender, days.length - numDaysToRender);
    onChangeDate(days[newIndex]?.date);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: onRight,
    onSwipedRight: onLeft,
  });

  const getDaysToRender = React.useCallback(
    () =>
      days
        .map(({ date, times }, i) => {
          if (!times.length) {
            return;
          }

          // Only need to populate enough days to render out of view to the right in order
          // to be able to scroll them in and out nicely.
          if (i >= startIndex + numDaysToRender * 2) {
            return;
          }

          const dayVisible = i >= startIndex && i < startIndex + numDaysToRender;
          return (
            <div key={date} className="cw-h-full">
              <h2
                className="cw-heading-xl cw-pb-4 cw-px-[10px] -cw-mx-[10px] cw-top-0 cw-z-10 xs:cw-sticky"
                style={{
                  background: "linear-gradient(180deg, white 75%, rgba(255, 255, 255, 0) 110%)",
                }}
                cw-id={dayVisible ? "link-day-header" : undefined}
              >
                {DateTime.fromISO(date).toLocaleString({
                  weekday: "short",
                  month: "short",
                  day: "numeric",
                })}
              </h2>
              <TimesForDay
                times={times}
                timeZone={timeZone}
                onSelectTime={onSelectTime}
                trackSession={trackSessionWithNumDays}
                highlightBestTimes={highlightBestTimes}
                className="cw-pb-[1px]"
                dayVisible={dayVisible}
              />
            </div>
          );
        })
        .filter(nonNullable),
    [
      days,
      highlightBestTimes,
      numDaysToRender,
      onSelectTime,
      startIndex,
      timeZone,
      trackSessionWithNumDays,
    ],
  );

  React.useEffect(() => {
    // To avoid flashing of days when loading, only rerender when not loading.
    if (!loading) {
      setDaysToRender(getDaysToRender());
    }
  }, [getDaysToRender, loading]);

  const transitions = useTransition(daysToRender, {
    trail: startIndex > 4 ? 0 : 200, // Stop trail delay after first items render.
    from: { opacity: 0 }, // The default values.
    enter: { opacity: 1 }, // The value that it animates to upon enter.
    immediate: true, // Don't animate anything after the first items.
    keys: (day) => day.key ?? JSON.stringify(day.props).substr(0, 15),
    // Should be just day.key, but it needs a fallback incase it is undefined.
  });

  // Spring to animate page left and page right.
  const leftRightSpring = useSpring(
    isNarrow
      ? {
          x: -startIndex * (width - 12), // 12 acconuts for left and right padding.
          containerWidth: 0, // Not used, react spring needs a number here though.
          config: { ...config.stiff }, // Stiff to make swiping left/right feel more responsive.
        }
      : {
          x: -startIndex * TOTAL_DAY_WIDTH,
          containerWidth:
            TOTAL_DAY_WIDTH * Math.min(numDaysToRender, days.length - startIndex) + 14,
          config: { ...config.default },
        },
    [startIndex, numDaysToRender, daysToRender, isNarrow, width],
  );

  const [{ x, containerWidth }] = leftRightSpring;
  const hasMoreDays = startIndex + numDaysToRender < days.length;

  return (
    <div className="cw-flex cw-flex-col cw-overflow-auto" ref={containerRef}>
      <animated.div
        className="cw-flex cw-justify-between cw-items-center cw-pb-[26px] cw-ml-3"
        style={{ maxWidth: isNarrow ? "100%" : containerWidth }}
      >
        <div className="cw-body-lg cw-text-muted cw-flex-shrink-0 cw-mr-5">Choose a time</div>
        <div className="cw-flex cw-items-center cw-mr-[9px]">
          <Loader
            className={`${loading ? "cw-visible" : "cw-invisible"} cw-mr-[6px]`}
            size={18}
            sentiment="positive"
          />

          <div className="cw-hidden xs:cw-block">
            <ShowEarliestButton
              currentDate={currentDate}
              earliestTimeSlot={timeSlots[0]?.timeSlot}
              disabled={loading}
              onShowEarliest={onChangeDate}
            />
          </div>

          <HoverableChevron
            direction="left"
            onClick={onLeft}
            disabled={startIndex === 0}
            cw-id="link-prev-week-btn"
            aria-label={
              startIndex === 0
                ? "No earlier availability"
                : `Previous ${pluralize("day", numDaysToRender, true)} with availability`
            }
          />
          <HoverableChevron
            direction="right"
            onClick={onRight}
            disabled={!hasMoreDays}
            cw-id="link-next-week-btn"
            aria-label={
              hasMoreDays
                ? `Next ${pluralize("day", numDaysToRender, true)} with availability`
                : "No further availability"
            }
          />
        </div>
      </animated.div>
      <animated.div
        {...swipeHandlers}
        className="cw-flex cw-pb-[5px] cw-pl-[6px] cw-overflow-x-hidden"
        style={{
          maxWidth: isNarrow ? "100%" : containerWidth,
          overflowY: isNarrow ? "hidden" : "auto",
        }}
      >
        <animated.div
          className="cw-flex cw-h-full"
          style={{
            x,
          }}
        >
          {transitions(({ opacity }, day) => {
            return (
              <animated.div
                // `cw-mx-2` matches DAY_MARGIN_LEFT and DAY_MARGIN_RIGHT
                className="cw-mx-2 cw-flex-shrink-0 xxs:cw-flex-shrink"
                style={{
                  opacity,
                  width: isNarrow ? width - 28 : DAY_WIDTH_PRE_MARGIN,
                  maxHeight: isNarrow // 60 height of date header, 60 height of time card including margins. An extra 60 for the show more/less card.
                    ? 50 + 65 + days[startIndex]?.times.length * 65 || "100%"
                    : "100%",
                }}
                key={day.key}
              >
                {day}
              </animated.div>
            );
          })}
        </animated.div>
      </animated.div>
    </div>
  );
};
