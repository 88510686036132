export enum IOnboardingChecklistSyncType {
  slackEnabled = "SlackEnabled",
  inviteSent = "InviteSent",
}
export enum SettingsActions {
  CloseSettingsDrawers = "Settings_Close_Drawers",
  OpenPersonalCalendar = "Settings_OpenPersonalCalendar",
  UpdatePreferredRoomsMillis = "Settings_UpdatePreferredRoomsMillis",
  OpenInvites = "Settings_OpenInvites",
  SetRequireSaveHours = "Settings_SetRequireSaveHours",
  SetRequireSaveFocusTimeSync = "Settings_SetRequireSaveFocusTimeSync",
  TriggerSaveFocusTimeSync = "Settings_TriggerSaveFocusTimeSync",
  SetRequireSaveLunchSync = "Settings_SetRequireSaveLunchSync",
  TriggerSaveLunchSync = "Settings_TriggerSaveLunchSync",
  SetRequireSaveTravelTimeSync = "Settings_SetRequireSaveTravelTimeSync",
  TriggerSaveTravelTimeSync = "Settings_TriggerSaveTravelTimeSync",
  SendInvite = "Settings_SendInvite",
  SetSavedOnboardingChecklistSyncType = "Settings_SetSavedOnboardingChecklistSyncType",
}

export interface ISettingsAction {
  type: SettingsActions;
  data?: any;
}

export const closeSettingsDrawers = (): ISettingsAction => ({
  type: SettingsActions.CloseSettingsDrawers,
});

export const updatePreferredRoomsMillis = (): ISettingsAction => ({
  type: SettingsActions.UpdatePreferredRoomsMillis,
});

export const openInvites = (): ISettingsAction => ({
  type: SettingsActions.OpenInvites,
});

export const setRequireSaveHours = (requireSaveHours: boolean): ISettingsAction => ({
  type: SettingsActions.SetRequireSaveHours,
  data: requireSaveHours,
});

export const setRequireSaveFocusTimeSync = (
  requireSaveFocusTimeSync: boolean,
): ISettingsAction => ({
  type: SettingsActions.SetRequireSaveFocusTimeSync,
  data: requireSaveFocusTimeSync,
});

export const triggerSaveFocusTimeSync = (): ISettingsAction => ({
  type: SettingsActions.TriggerSaveFocusTimeSync,
});

export const setRequireSaveLunchSync = (requireSaveLunchSync: boolean): ISettingsAction => ({
  type: SettingsActions.SetRequireSaveLunchSync,
  data: requireSaveLunchSync,
});

export const setSavedOnboardingChecklistSyncType = (
  savedOnboardingChecklistSyncType: string,
): ISettingsAction => ({
  type: SettingsActions.SetSavedOnboardingChecklistSyncType,
  data: savedOnboardingChecklistSyncType,
});

export const triggerSaveLunchSync = (): ISettingsAction => ({
  type: SettingsActions.TriggerSaveLunchSync,
});

export const setRequireSaveTravelTimeSync = (
  requireSaveTravelTimeSync: boolean,
): ISettingsAction => ({
  type: SettingsActions.SetRequireSaveTravelTimeSync,
  data: requireSaveTravelTimeSync,
});

export const triggerSaveTravelTimeSync = (): ISettingsAction => ({
  type: SettingsActions.TriggerSaveTravelTimeSync,
});

export const sendInvite = (email: string): ISettingsAction => ({
  type: SettingsActions.SendInvite,
  data: email,
});
