import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import {
  convertTimeMaxSlotToHourMinutes,
  convertTimeMinSlotToHourMinutes,
} from "../../../util/time-slot.util";
import { PlannerMetadataOrg } from "../types";

export const parseWorkingHourBoundsGQL = (org?: PlannerMetadataOrg): [number, number] | null => {
  const workingHours = getValue(org?.userWorkingHours)?.workingHours?.daySettings;
  if (!workingHours || workingHours.length === 0) {
    return null;
  }

  const min = Math.min(
    ...workingHours.map(
      (wh) =>
        (wh?.setting?.minSlot
          ? convertTimeMinSlotToHourMinutes(wh?.setting?.minSlot)?.minutesIntoDay
          : 0) / 60,
    ),
  );

  const max = Math.max(
    ...workingHours.map(
      (wh) =>
        (wh?.setting?.maxSlot
          ? convertTimeMaxSlotToHourMinutes(wh.setting.maxSlot).minutesIntoDay
          : 0) / 60,
    ),
  );

  return [min, max];
};
