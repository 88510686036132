import { PostMessageManager } from "#webapp/src/util/post-message.util";
import { writeRichTextToClipboard } from "@clockwise/web-commons/src/util/html.util";

const copyToClipboard = (text: string) => {
  writeRichTextToClipboard(text).catch(() => {
    // iframes can't access navigator.clipboard, so when this fails,
    // send the clipboard items over to the extension to do the copying.
    PostMessageManager.copyRichTextToClipboard(text);
  });
};

export default copyToClipboard;
