import moment from "moment";

export type IFlagsErrorable = IFlags | IGraphEntityError;

export interface IGraphEntityError {
  __typename: "GraphEntityError";
}

export interface IFlags {
  __typename: "Flags";
  flags: string[];
}

export interface IUser {
  createdTime: any;
}

export const FlagNamespaces = {
  ChromeExtension: "ChromeExtension",
  Webapp: "Webapp",
  Services: "Services",
} as const;

type FlagDefinition = {
  name: string;
  description: string;
  unfetchedValue: boolean;
};

type FlagDefinitionHash = {
  [product: string]: {
    [flag: string]: FlagDefinition;
  };
};

export const UserFlags: FlagDefinitionHash = {
  Onboarding: {
    HasInstalledChromeExtension: {
      name: "Has Installed the Chrome Extension",
      description: "A boolean that represents whether the user has installed the Chrome Extension",
      unfetchedValue: true,
    },
    InlineAutopilot: {
      name: "Seen Inline Autopilot Toggle in Google Calendar",
      description:
        "A boolean that determines if a user has seen the inline info tip about the Autopilot Toggle within Google Calendar",
      unfetchedValue: true,
    },
    TeamCalendar: {
      name: "Seen Team Calendar Toggle in Google Calendar",
      description:
        "A boolean that determines if a user has seen the tip about the team calendar in Google Calendar.",
      unfetchedValue: true,
    },
    TeamCalendarSharedEvent: {
      name: "Seen Team Calendar Tutorial in Event View",
      description:
        "A boolean that determines if a user has seen the tip about the team calendar in the Event View.",
      unfetchedValue: true,
    },
    InstallChromeExtension: {
      name: "Seen Install Chrome Extension Callout",
      description:
        "A boolean that determines if a user has seen the callout to install the chrome extension from the Smart Agenda Canvas View",
      unfetchedValue: true,
    },
    TeamHealth: {
      name: "Seen Onboarding TeamHealth",
      description:
        "A boolean that determines if a user has seen the inline info tip on the team health dashboard",
      unfetchedValue: true,
    },
    FeedScheduler: {
      name: "Seen Feed Scheduler",
      description:
        "A boolean that determines if a user has seen the info tip about the feed scheduler",
      unfetchedValue: true,
    },
    CustomColorsEverSetOnboardingModal: {
      name: "Custom Colors Ever Set Onboarding Modal",
      description:
        "The backend crawls events to see if a user has ever custom colored an event. if so we want to make sure they are aware that color coding will irreversibly override these",
      unfetchedValue: false,
    },
    HasSeenDayOfWeek: {
      name: "Has Seen Day of Week Time Range Flexibility Option",
      description:
        "A boolean that determines if flexibility settings within a scheduling modal should be expanded to display the Day of Week time range to a user",
      unfetchedValue: false,
    },
    ShouldSeeExtensionTutorialDialog: {
      name: "Should see Extension Tutorial Dialog",
      description:
        "A boolean that determines if a user has should see the Extension Tutorial Dialog",
      unfetchedValue: false,
    },
    HasSeenExtensionTutorialDialog: {
      name: "Has Seen Extension Tutorial Dialog",
      description:
        "A boolean that determines if a user has already been show the Extension Tutorial Dialog",
      unfetchedValue: true,
    },
  },
  NUX: {
    ShouldShowSuperpowersChecklist: {
      name: "Should Show Superpowers Checklist",
      description: "A boolean that determines if a user should see the Superpowers Checklist",
      unfetchedValue: false,
    },
    HasCompletedSchedulingChecklistItem: {
      name: "Has Completed Scheduling Checklist Item",
      description:
        "A boolean that determines if a user has completed the Scheduling Checklist Item",
      unfetchedValue: true,
    },
    HasCompletedReschedulingChecklistItem: {
      name: "Has Completed Rescheduling Checklist Item",
      description:
        "A boolean that determines if a user has completed the Rescheduling Checklist Item",
      unfetchedValue: true,
    },
    HasCompletedPreferencesChecklistItem: {
      name: "Has Completed Calendar Preferences Checklist Item",
      description:
        "A boolean that determines if a user has completed the Calendar Preferences Checklist Item",
      unfetchedValue: true,
    },
    ShouldShowUnvisitedBadges: {
      name: "Should Show Unvisited Badges",
      description:
        "A boolean that determines if a user should see the unvisited badges in the webapp",
      unfetchedValue: false,
    },
    HasVisitedLinksPage: {
      name: "Has Visited Links Page",
      description: "A boolean that determines if a user has visited the Links Page",
      unfetchedValue: true,
    },
    HasVisitedFlexibilityPage: {
      name: "Has Visited Flexibility Page",
      description: "A boolean that determines if a user has visited the Flexibility Page",
      unfetchedValue: true,
    },
    HasVisitedIdealDayPage: {
      name: "Has Visited Ideal Day Page",
      description: "A boolean that determines if a user has visited the Ideal Day Page",
      unfetchedValue: true,
    },
  },
} as const;

const ValidFlags = Object.values(UserFlags.Onboarding)[0].name; // Pluck a single type here for export
export type Flag = typeof ValidFlags;

export function validateFlagsErrorable(flagsErrorable: IFlagsErrorable): flagsErrorable is IFlags {
  return flagsErrorable && flagsErrorable.__typename !== "GraphEntityError";
}

/**
 * Relay util for checking if a flag is set
 *
 * @deprecated Use the new {@link isUserFlagSet} Apollo counterpart.
 */
export function isFlagSet(flagName: string, flagsErrorable: IFlagsErrorable) {
  if (!validateFlagsErrorable(flagsErrorable)) {
    console.error("flags is a GraphEntityError");
    return null;
  }

  return flagsErrorable.flags.indexOf(flagName) > -1;
}
/**
 * This is basically the same functionality of `isFlagSet` except the types are for Apollo
 * and the API is for the leaf type `flags` and not `IFlagsErrorable`
 * Please, let's deprecate usage of `isFlagSet` in favor of `isUserFlagSet`
 */
export function isUserFlagSet(flagName: string, flags: (string | null)[] | null | undefined) {
  if (!flags) {
    return null;
  }

  return flags.includes(flagName);
}

export const getFlagValue = (flagsErrorable: IFlagsErrorable) => (flagName: string) =>
  isFlagSet(flagName, flagsErrorable);

export function isUserAtLeastXDaysOld(user: IUser, days: number) {
  if (!user.createdTime) {
    console.error("user fragment is incomplete");
    return null;
  }

  const now = moment();
  const createdMoment = moment(user.createdTime);

  // + => future, - => past
  const diffInMillis = Math.abs(createdMoment.diff(now));
  const duration = moment.duration(diffInMillis, "ms");

  return duration.asHours() > days * 24;
}
