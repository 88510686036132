// schema
import { AutopilotEventStatusEnum } from "@clockwise/schema";
import { FlexStatus } from "@clockwise/schema/v2";
import { DateTime, Duration } from "luxon";
import { getRenderTimeZone } from "./time-zone.util";

export enum DefragRunFlowState {
  Unstarted = "Unstarted",
  Queued = "Queued",
  Running = "Running",
  Finished = "Finished",
}

export type EventAutopilotStatusCardStatus = keyof typeof AutopilotEventStatusEnum;

export const shouldRenderEventAutopilotStatus = (
  status: EventAutopilotStatusCardStatus | FlexStatus,
): boolean => {
  const EventAutopilotStatusToRenderMap = {
    CanMove: true,
    Paused: true,
    EventOutsideTimeRange: true,
    EventExceedsDefragRange: true,
    TrialExpired: true,
    Pinned: false,
    External: false,
    NoDuration: false,
    SmartHold: false,
    PrivateEvent: false,
    OverQuota: false,
  };

  return EventAutopilotStatusToRenderMap[status];
};

export const getDefragRunTag = (nextDefrag: string) => {
  const defragTime = DateTime.fromISO(nextDefrag, { zone: getRenderTimeZone() });
  const currentTime = DateTime.now().setZone(getRenderTimeZone());
  const defaultTag = {
    label: `${defragTime.toRelativeCalendar({ unit: "days" })} at ${defragTime.toLocaleString(
      DateTime.TIME_SIMPLE,
    )}`,
    flowState: DefragRunFlowState.Unstarted,
  };
  let tag: { label: string | null; flowState: DefragRunFlowState } = defaultTag;

  if (defragTime < currentTime) {
    tag = { label: null, flowState: DefragRunFlowState.Finished };
  }

  if (currentTime.diff(defragTime, "minutes") < Duration.fromObject({ minute: 5 })) {
    tag = { label: "in a few minutes", flowState: DefragRunFlowState.Queued };
  }

  if (currentTime.diff(defragTime, "minutes") < Duration.fromObject({ minute: 1 })) {
    tag = {
      label: "right now",
      flowState: DefragRunFlowState.Running,
    };
  }

  if (defragTime > currentTime) {
    tag = {
      label: `${defragTime.toRelativeCalendar({
        unit: "days",
        base: currentTime,
      })} at ${defragTime.toLocaleString(DateTime.TIME_SIMPLE)}`,
      flowState: DefragRunFlowState.Unstarted,
    };
  }

  if (defragTime > currentTime && defragTime.hasSame(currentTime, "day")) {
    tag = {
      label: `at ${defragTime.toLocaleString(DateTime.TIME_SIMPLE)}`,
      flowState: DefragRunFlowState.Unstarted,
    };
  }

  return tag;
};

export const getLocalDefragRunTime = (nextDefrag: string) => {
  return DateTime.fromISO(nextDefrag, { zone: getRenderTimeZone() }).toLocaleString(
    DateTime.TIME_SIMPLE,
  );
};
