import React from "react";

type Sentiment = "success" | "error" | "info";

const SENTIMENT_BG_COLOR = {
  success: "cw-bg-positive",
  error: "cw-bg-destructive",
  info: "cw-bg-info",
};

export const Notification = ({
  sentiment,
  children,
}: {
  sentiment: Sentiment;
  children: React.ReactNode;
}) => (
  <div
    className={`cw-font-body cw-text-14 cw-leading-snug cw-text-neutral cw-p-2 ${SENTIMENT_BG_COLOR[sentiment]} cw-rounded-lg`}
  >
    {children}
  </div>
);
