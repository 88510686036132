import { bg_brand_emphasis, fg_accent, fg_onEmphasis } from "@clockwise/design-system/tokens";
import { makeStyles } from "@material-ui/styles";
import { colors, greys } from "../../styles/color.styles";
import { fontFamilyBrand } from "../../styles/type.styles";

export const narrowCutoff = 640; // consistent with the design system "sm" breakpoint.

export const narrowMediaQuery = `@media (max-width: ${narrowCutoff}px)`;

export const useStyles = makeStyles({
  leftContent: {
    "& .MuiPickersBasePicker-pickerView": {
      maxWidth: "unset",
    },

    [narrowMediaQuery]: {
      "& .MuiPickersBasePicker-pickerView": {
        maxWidth: "calc(100vw - 50px)",
      },

      "& .MuiPickersCalendarHeader-transitionContainer": {
        maxWidth: 275,
      },
    },
  },
  hasTimes: {
    "& .MuiPickersDay-day": {
      backgroundColor: colors.green.lightest,
    },

    "& .MuiPickersDay-daySelected": {
      backgroundColor: bg_brand_emphasis,
      color: fg_onEmphasis,
    },
  },
  hasNoTimes: {
    "& .MuiPickersDay-daySelected": {
      backgroundColor: greys.blueGrey,
    },
    "& .MuiPickersDay-current": {
      color: "#263238", // Default color.
    },
  },
  calendarHeader: {
    "& .MuiPickersCalendar-transitionContainer": {
      marginBottom: 18, // Should be 3x .MuiPickersCalendar-week
    },

    "& .MuiPickersCalendarHeader-daysHeader": {
      justifyContent: "space-around",
    },

    "& .MuiPickersCalendar-week": {
      marginBottom: 3,
      justifyContent: "space-around",
    },

    "& .MuiPickersCalendarHeader-transitionContainer > p": {
      fontFamily: fontFamilyBrand,
      fontSize: "1.3rem",
      fontWeight: "bold",
      color: fg_accent,
      marginTop: -4,
    },

    "& .MuiSvgIcon-root": {
      // Left right chevrons.
      fontSize: "1.8rem",
    },

    [narrowMediaQuery]: {
      "& .MuiPickersCalendar-week": {
        justifyContent: "space-evenly",
        marginBottom: 4,
      },

      "& .MuiPickersCalendarHeader-daysHeader": {
        justifyContent: "space-evenly",
      },

      // Give extra height to account for increased .MuiPickersCalendar-week marginBottom
      // and .MuiPickersDay-day circle radius.
      "& .MuiPickersCalendar-transitionContainer": {
        marginBottom: 48,
      },

      // Increase radius of date circles.
      "& .MuiPickersDay-day": {
        height: 40,
        width: 40,
      },
    },
  },
});
