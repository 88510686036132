export const LENGTH_OF_PRO_TRIAL = 30;
export const OVER_EXPIRED_DAYS = -5;

// This object returns the days remaining on the trial based on how many weeks in you are.
// If LENGTH_OF_PRO_TRIAL changes above, all these values will as well (obviously)
export const TrialDuration = {
  zeroWeeksIn: LENGTH_OF_PRO_TRIAL - 0,
  oneWeekIn: LENGTH_OF_PRO_TRIAL - 7,
  twoWeeksIn: LENGTH_OF_PRO_TRIAL - 14,
  threeWeeksIn: LENGTH_OF_PRO_TRIAL - 21,
  fourWeeksIn: LENGTH_OF_PRO_TRIAL - 28,
  lastChanceNotification: LENGTH_OF_PRO_TRIAL - 27,
  expiredFor5DaysOrLess: LENGTH_OF_PRO_TRIAL - (LENGTH_OF_PRO_TRIAL + 5),
  end: LENGTH_OF_PRO_TRIAL - LENGTH_OF_PRO_TRIAL,
};
