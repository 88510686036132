import { IOrg } from "#webapp/src/__schema__";
import {
  getOrgBillingGroup,
  getSubscriptionFromBillingGroup,
} from "#webapp/src/util/billing-group.util";
import { isSubscriptionProductTypePro } from "#webapp/src/util/pricing.util";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";

export const orgAlreadyHasOrgPlan = (org: IOrg) => {
  const orgBg = org && getOrgBillingGroup(org);
  if (!orgBg) {
    return false;
  }
  const orgBgSubscription = orgBg && getSubscriptionFromBillingGroup(orgBg);
  return !!orgBgSubscription && isSubscriptionProductTypePro(orgBgSubscription.subscription);
};

export const getCurrentOrgPersons = (org: IOrg) => {
  const { currentOrgPersons } = org;
  const list = getValue(currentOrgPersons, "OrgPersonList");
  return list?.list || [];
};
