/**
 * @generated SignedSource<<fc6a66441eb5c742a81cd619d2f0fbac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TeamMemberRole = "Admin" | "Member" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TeamJoinCreate_viewer$data = {
  readonly user: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly teamQueryResultErrorable: {
    readonly query?: string;
    readonly teams?: ReadonlyArray<{
      readonly teamName: string;
      readonly teamId: string;
      readonly teamRoleAndPersonIds: ReadonlyArray<{
        readonly role: TeamMemberRole;
        readonly personId: string;
      }>;
    }>;
    readonly teamMembers?: ReadonlyArray<{
      readonly role: TeamMemberRole;
      readonly person: {
        readonly __typename: string;
        readonly id: string;
        readonly primaryCalendarId: string;
        readonly isYou: boolean;
        readonly userId: string | null;
        readonly personId: string;
        readonly profile: {
          readonly givenName: string | null;
          readonly familyName: string | null;
          readonly externalImageUrl: string | null;
        } | null;
      };
    }>;
  };
  readonly " $fragmentType": "TeamJoinCreate_viewer";
};
export type TeamJoinCreate_viewer$key = {
  readonly " $data"?: TeamJoinCreate_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamJoinCreate_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "personId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "excludeMyTeams"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orgRelayId"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamJoinCreate_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "excludeMyTeams",
          "variableName": "excludeMyTeams"
        },
        {
          "kind": "Variable",
          "name": "orgRelayId",
          "variableName": "orgRelayId"
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "teamQueryResultErrorable",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "query",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Team",
              "kind": "LinkedField",
              "name": "teams",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "teamName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "teamId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamRoleAndPersonId",
                  "kind": "LinkedField",
                  "name": "teamRoleAndPersonIds",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamMember",
              "kind": "LinkedField",
              "name": "teamMembers",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrgPerson",
                  "kind": "LinkedField",
                  "name": "person",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "primaryCalendarId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isYou",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "userId",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserProfile",
                      "kind": "LinkedField",
                      "name": "profile",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "givenName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "familyName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "externalImageUrl",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "TeamQueryResult",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "c3fac877395ba2e297d3cb923d67dc34";

export default node;
