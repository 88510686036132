import { AvailabilityRangeInput } from "@clockwise/schema/v2";
import { DateString } from "@clockwise/web-commons/src/ui/scheduling-link";
import { DateTime } from "luxon";

type Link = {
  schedulingLink: { active: boolean; singleUse?: boolean; linkMembers: unknown[] };
};

const isActiveLink = (link: Link) => link.schedulingLink.active;
const isGroupLink = (link: Link) => link.schedulingLink.linkMembers.length > 1;
const hasBookingLimit = (link: Link) => link.schedulingLink.singleUse;

export function getLinksByType<T extends Link>(
  schedulingLinks: T[],
): {
  normalLinks: T[];
  groupLinks: T[];
  singleUseLinks: T[];
  inactiveLinks: T[];
} {
  const activeLinks = schedulingLinks.filter(isActiveLink);
  return {
    normalLinks: activeLinks.filter((l) => !hasBookingLimit(l) && !isGroupLink(l)),
    groupLinks: activeLinks.filter(isGroupLink),
    singleUseLinks: activeLinks.filter((l) => hasBookingLimit(l) && !isGroupLink(l)),
    inactiveLinks: schedulingLinks.filter((l) => !isActiveLink(l)),
  };
}

export const getInitialDate = ({
  date,
  time,
  timezone,
}: {
  date?: DateString;
  time?: string;
  timezone?: string;
}): DateString => {
  if (date && time && timezone) {
    return DateTime.fromFormat(`${date}:${time}`, "yyyy-MM-dd:HHmm", {
      zone: timezone,
    })
      .toLocal()
      .toISODate();
  }

  return date || DateTime.now().toISODate();
};

export const getInitialDuration = ({
  preferredDuration,
  defaultDuration,
  durations,
}: {
  preferredDuration: number;
  defaultDuration: number;
  durations: number[];
}) => {
  return durations.includes(preferredDuration) ? preferredDuration : defaultDuration;
};

/**
 * Convert DateTime to Date string for compatibility with gateway schema
 */
export const buildAvailabilityRange = ({
  startDate,
  endDate,
  windowSize,
}: AvailabilityRangeInput) => {
  if (windowSize) {
    return { windowSize };
  } else if (startDate && endDate) {
    return {
      startDate: DateTime.fromISO(startDate).toFormat("yyyy-MM-dd"),
      endDate: DateTime.fromISO(endDate).toFormat("yyyy-MM-dd"),
    };
  } else {
    throw new Error("Invalid availability range");
  }
};

/**
 * Formats a time string from 24-hour format to 12-hour format with meridiem
 * @param time - Time string in 24-hour format (e.g. "1345")
 * @returns Formatted time string (e.g. "1:45pm") or empty string if invalid
 */
export const formatTimeFromParam = (time: string): string => {
  if (!time || typeof time !== "string" || time.length !== 4 || !/^\d+$/.test(time)) {
    return "";
  }

  const hourStr = time.slice(0, 2);
  const minuteStr = time.slice(2);

  const hour = Number(hourStr);
  const minute = Number(minuteStr);

  if (isNaN(hour) || isNaN(minute) || hour < 0 || hour > 23 || minute < 0 || minute > 59) {
    return "";
  }

  const meridiem = hour >= 12 ? "pm" : "am";
  const hour12 = hour % 12 || 12;
  return `${hour12}:${minuteStr}${meridiem}`;
};
