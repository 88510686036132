export function transform<A, R1>(data: A, f1: (a: A) => R1): R1;
export function transform<A, R1, R2>(data: A, f1: (a: A) => R1, f2: (a: R1) => R2): R2;
export function transform<A, R1, R2, R3>(
  data: A,
  f1: (a: A) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
): R3;
export function transform<A, R1, R2, R3, R4>(
  data: A,
  f1: (a: A) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
  f4: (a: R3) => R4,
): R4;
export function transform<A, R1, R2, R3, R4, R5>(
  data: A,
  f1: (a: A) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
  f4: (a: R3) => R4,
  f5: (a: R4) => R5,
): R5;
export function transform<A, R1, R2, R3, R4, R5, R6>(
  data: A,
  f1: (a: A) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
  f4: (a: R3) => R4,
  f5: (a: R4) => R5,
  f6: (a: R5) => R6,
): R6;
export function transform<A, R1, R2, R3, R4, R5, R6, R7>(
  data: A,
  f1: (a: A) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
  f4: (a: R3) => R4,
  f5: (a: R4) => R5,
  f6: (a: R5) => R6,
  f7: (a: R6) => R7,
): R7;
export function transform<A, R1, R2, R3, R4, R5, R6, R7, R8>(
  data: A,
  f1: (a: A) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
  f4: (a: R3) => R4,
  f5: (a: R4) => R5,
  f6: (a: R5) => R6,
  f7: (a: R6) => R7,
  f8: (a: R7) => R8,
): R8;

/**
 * Perform a series of arbitrary transformations on a data structure, with type safety at each step
 * of the way.
 *
 * @param data Initial data to perform transformations on.
 * @param transforms Functions to perform sequential transformations on the data.
 * @returns The result of the final transformation.
 */
export function transform<A>(data: A, ...transforms: Array<(...args: any[]) => any>) {
  return transforms.reduce((acc, transform) => transform(acc), data);
}

export function makeTransform<A, R1>(f1: (a: A) => R1): (data: A) => R1;
export function makeTransform<A, R1, R2>(f1: (a: A) => R1, f2: (a: R1) => R2): (data: A) => R2;
export function makeTransform<A, R1, R2, R3>(
  f1: (a: A) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
): (data: A) => R3;
export function makeTransform<A, R1, R2, R3, R4>(
  f1: (a: A) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
  f4: (a: R3) => R4,
): (data: A) => R4;
export function makeTransform<A, R1, R2, R3, R4, R5>(
  f1: (a: A) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
  f4: (a: R3) => R4,
  f5: (a: R4) => R5,
): (data: A) => R5;
export function makeTransform<A, R1, R2, R3, R4, R5, R6>(
  f1: (a: A) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
  f4: (a: R3) => R4,
  f5: (a: R4) => R5,
  f6: (a: R5) => R6,
): (data: A) => R6;
export function makeTransform<A, R1, R2, R3, R4, R5, R6, R7>(
  f1: (a: A) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
  f4: (a: R3) => R4,
  f5: (a: R4) => R5,
  f6: (a: R5) => R6,
  f7: (a: R6) => R7,
): (data: A) => R7;
export function makeTransform<A, R1, R2, R3, R4, R5, R6, R7, R8>(
  f1: (a: A) => R1,
  f2: (a: R1) => R2,
  f3: (a: R2) => R3,
  f4: (a: R3) => R4,
  f5: (a: R4) => R5,
  f6: (a: R5) => R6,
  f7: (a: R6) => R7,
  f8: (a: R7) => R8,
): (data: A) => R8;

/**
 * Create pipeline to performs a series of arbitrary transformations on a data structure, with type
 * safety at each step of the way.
 *
 * @param transforms Functions to perform sequential transformations on the data.
 * @returns A function that takes a single argument, the data to be transformed.
 */
export function makeTransform<A>(...transforms: Array<(...args: any[]) => any>) {
  return (data: A) => transforms.reduce((acc, transform) => transform(acc), data);
}
