import { graphql } from "react-relay";

export const saveTeamUserSettingsMutation = graphql`
  mutation SaveTeamUserSettingsMutation($input: saveTeamUserSettingsMutationInput!) {
    saveTeamUserSettings(input: $input) {
      clientMutationId
      teamUserSettings {
        useTeamCalendar
        useNoMeetingDay
        lastUpdated
      }
    }
  }
`;
