/**
 * @generated SignedSource<<ad72ccd5431b36bc5dea0823472b2b1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrgUserSelectForm_viewer$data = {
  readonly orgPersonQueryResultErrorable: {
    readonly __typename: "GraphEntityError";
    readonly statusCode: number | null;
    readonly message: string | null;
  } | {
    readonly __typename: "OrgPersonQueryResult";
    readonly query: string;
    readonly persons: ReadonlyArray<{
      readonly userId: string | null;
      readonly primaryCalendarId: string;
      readonly calendarAliases: ReadonlyArray<string>;
      readonly profile: {
        readonly givenName: string | null;
        readonly familyName: string | null;
        readonly externalImageUrl: string | null;
      } | null;
      readonly isYou: boolean;
    } | null>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly " $fragmentType": "OrgUserSelectForm_viewer";
};
export type OrgUserSelectForm_viewer$key = {
  readonly " $data"?: OrgUserSelectForm_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrgUserSelectForm_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "excludeCalendars"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "orgRelayId"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "queryInput"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrgUserSelectForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "excludeCalendars",
          "variableName": "excludeCalendars"
        },
        {
          "kind": "Variable",
          "name": "orgRelayId",
          "variableName": "orgRelayId"
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "queryInput"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "orgPersonQueryResultErrorable",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "statusCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            }
          ],
          "type": "GraphEntityError",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "query",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrgPerson",
              "kind": "LinkedField",
              "name": "persons",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "primaryCalendarId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "calendarAliases",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserProfile",
                  "kind": "LinkedField",
                  "name": "profile",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "givenName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "familyName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "externalImageUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isYou",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "OrgPersonQueryResult",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "d37025331fac72b72543548d8bd3c3de";

export default node;
