import React, { useMemo, useState } from "react";

export type AIChatCollapseContextValue = {
  chatIsCollapsed: boolean;
  setChatIsCollapsed: (collapsed: boolean) => void;
};

export const useAIChatCollapseContext = () => {
  const context = React.useContext(AIChatCollapseContext);
  if (!context) {
    throw new Error("useAIChatCollapseContext must be used within a AIChatCollapseProvider");
  }
  return context;
};

export const AIChatCollapseContext = React.createContext<AIChatCollapseContextValue | undefined>(
  undefined,
);

export const AIChatCollapseProvider = ({ children }: { children: React.ReactNode }) => {
  const [chatIsCollapsed, setChatIsCollapsed] = useState(false);

  const value = useMemo(() => ({ chatIsCollapsed, setChatIsCollapsed }), [chatIsCollapsed]);

  return <AIChatCollapseContext.Provider value={value}>{children}</AIChatCollapseContext.Provider>;
};
