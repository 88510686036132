import { Interval } from "luxon";
import React from "react";
import { ConfirmationType } from "../scheduling-confirmation";
import { EventSummary } from "../scheduling-confirmation/event-summary";
import { Booking } from "./types";

type Props = {
  booking: Booking;
  newDateTime?: string;
  timeZone: string;
  compact?: boolean;
};

/**
 * Given an existing booking for a Scheduling Link, display it in the same EventSummary form that
 * is used when creating the booking.
 */
export function CurrentBookingDetails({ booking, newDateTime, timeZone, compact }: Props) {
  const timeRange = Interval.fromISO(booking.timeSlot);
  return (
    <EventSummary
      compact={compact}
      title={booking.name}
      duration={timeRange.toDuration().as("minutes")}
      prevDateTime={newDateTime ? timeRange.start.toISO() : null}
      attendees={booking.attendees}
      dateTime={newDateTime || timeRange.start.toISO()}
      location={booking.location}
      description={booking.description}
      variant={ConfirmationType.Reschedule}
      timeZone={timeZone}
    />
  );
}
