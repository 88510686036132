import { IPlayerProps, Player } from "@lottiefiles/react-lottie-player";
import isChromatic from "chromatic/isChromatic";
import React from "react";

// Disable autoplay when taking snapshots for Chromatic visual diff testing
const allowAutoplay = !isChromatic();

export const LottiePlayer = ({ autoplay, ...props }: IPlayerProps) => (
  <Player {...props} autoplay={allowAutoplay && autoplay} />
);
