import moment from "moment-timezone";

export type MomentUnits =
  | "year"
  | "month"
  | "quarter"
  | "week"
  | "isoWeek"
  | "day"
  | "date"
  | "hour"
  | "minute"
  | "second";
export type MomentUnitsWithAbbr =
  | "years"
  | "y"
  | "quarters"
  | "Q"
  | "months"
  | "M"
  | "weeks"
  | "w"
  | "days"
  | "d"
  | "hours"
  | "h"
  | "minutes"
  | "m"
  | "seconds"
  | "s"
  | "milliseconds"
  | "ms";

export type Inclusivity = "()" | "[)" | "(]" | "[]" | undefined;

/**
 * Get best guess of what time zone user is in.
 */
export function getTimeZoneGuess(ignoreCache?: boolean): string {
  // workaround for https://github.com/moment/moment/issues/3852
  let timeZone = moment.tz.guess(ignoreCache);
  if (timeZone === "America/Buenos_Aires") {
    timeZone = "America/Argentina/Buenos_Aires";
  }
  if (timeZone === "Asia/Calcutta") {
    timeZone = "Asia/Kolkata";
  }

  return timeZone;
}
