import { getPreviousWeekday } from "@clockwise/web-commons/src/util/getPreviousWeekday";
import { difference, isEmpty, keys, uniq, uniqBy } from "lodash";
import { DateTime, DurationLike } from "luxon";
import { useCallback, useMemo } from "react";
import { PlannerEventCardsByDay } from "../types";
import { useCalendarEvents } from "./useCalendarEvents";
import { useCalendars } from "./useCalendars";

export const useCalendarEventCards = (
  date: string,
  calendarIds: string[],
  ownCalendarIds: string[],
) => {
  const weekStartDate = useMemo(
    () => getPreviousWeekday(DateTime.fromISO(date), "sunday").toISODate(),
    [date],
  );

  const otherCalendarIds = useMemo(() => {
    return difference(calendarIds, ownCalendarIds);
  }, [calendarIds, ownCalendarIds]);

  const {
    cardsByDay,
    loading,
    error,
    startPolling,
    stopPolling,
    refetch,
    fetchMore,
  } = useCalendarEvents(ownCalendarIds, weekStartDate);

  const {
    cardsByDay: multiCalCardsByDay,
    loading: loadingMultiCal,
    prefetch: prefetchMultiCal,
  } = useCalendars(otherCalendarIds, weekStartDate);

  const refetchAndstartPolling = useCallback(
    (time: number) => {
      void refetch();
      startPolling(time);
    },
    [refetch, startPolling],
  );

  const prefetch = useCallback(
    (relativeDuration: DurationLike) => {
      const newWeekStartDate = getPreviousWeekday(
        DateTime.fromISO(weekStartDate).plus(relativeDuration),
        "sunday",
      ).toISODate();

      void fetchMore({
        variables: {
          weekStartDate: newWeekStartDate,
        },
      });
      if (!isEmpty(otherCalendarIds)) {
        void prefetchMultiCal(relativeDuration);
      }
    },
    [fetchMore, otherCalendarIds, prefetchMultiCal, weekStartDate],
  );

  // Does not combine multi cal events for the same day
  // Used for Split View
  const allCardsByDay = useMemo(() => {
    if (isEmpty(multiCalCardsByDay)) {
      return cardsByDay;
    } else {
      const allCardsByDay: PlannerEventCardsByDay = {};

      const dates = uniq([...keys(cardsByDay), ...keys(multiCalCardsByDay)]);
      dates.forEach((date) => {
        const multiCalCardsForDay = multiCalCardsByDay[date] ?? [];
        const ownerCardsForDay = cardsByDay[date] ?? [];
        allCardsByDay[date] = ownerCardsForDay.concat(multiCalCardsForDay);
      });

      return allCardsByDay;
    }
  }, [cardsByDay, multiCalCardsByDay]);

  const combinedCardsByDay = useMemo(() => {
    if (isEmpty(multiCalCardsByDay)) {
      return cardsByDay;
    } else {
      const combinedCardsByDay: PlannerEventCardsByDay = {};

      const dates = uniq([...keys(cardsByDay), ...keys(multiCalCardsByDay)]);
      dates.forEach((date) => {
        const multiCalCardsForDay = multiCalCardsByDay[date] ?? [];
        const ownerCardsForDay = cardsByDay[date] ?? [];
        combinedCardsByDay[date] = uniqBy(
          ownerCardsForDay.concat(multiCalCardsForDay),
          "externalEventId",
        );
      });

      return combinedCardsByDay;
    }
  }, [cardsByDay, multiCalCardsByDay]);

  return useMemo(
    () => ({
      allCardsByDay,
      cardsByDay: combinedCardsByDay,
      loading,
      loadingMultiCal: !!loadingMultiCal,
      error,
      refetch: () => void refetch(),
      prefetch,
      startPolling: refetchAndstartPolling,
      stopPolling,
    }),
    [
      allCardsByDay,
      combinedCardsByDay,
      error,
      loading,
      loadingMultiCal,
      prefetch,
      refetch,
      refetchAndstartPolling,
      stopPolling,
    ],
  );
};
