// TODO: this should be a shared file between client and webserver
export const Connection = "connection";
export const Disconnect = "disconnect";
export const Reconnect = "reconnect";

export const AppStart = "AppStart";
export const JwtUpdate = "JwtUpdate";
export const JwtRemove = "JwtRemove";
export const XsrfUpdate = "XsrfUpdate";
export const XsrfRemove = "XsrfRemove";
export const SocketReceive = "SocketReceive";
export const SubscribeInit = "SubscribeInit";
export const SubscribeUpdate = "SubscribeUpdate";
export const Unsubscribe = "Unsubscribe";

export const StatsDSend = "StatsDSend";
export const LogSend = "LogSend";
