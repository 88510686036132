import { ZonedMoment } from "@clockwise/client-commons/src/util/ZonedMoment";
import { getTimeZoneGuess } from "@clockwise/client-commons/src/util/time-zone";
import { compact, fromPairs, keys } from "lodash";
import { filter, sortBy } from "lodash/fp";
import { DateTime, IANAZone, Interval, Zone } from "luxon";
import tzData from "tzdata";
import tzDataBackward from "tzdata-backward";
import tzDataBackwardUtc from "tzdata-backward-utc";
import { currentTimeZoneMap, orgPrimaryEmail, renderTimeZoneMap } from "./local-storage";
import { transform } from "./transform.util";

const zones = tzData.zones;
const backwardsZones = tzDataBackward.zones;
const backwardsUtcZones = tzDataBackwardUtc.zones;

const LEGACY_ZONES = [
  /**
   * These zones are all deprecated or legacy zones that shouldn't be shown for selection in the
   * list of zones. Any existing users with zones in these lists should instead call `normalizeZone`
   * to be mapped to the current equivalent zone.  This way, we don't have an ever growing list of
   * zones to pick from, including ones that are functionally identical, like `Asia/Calcutta` and
   * `Asia/Kolkata` or `US/Eastern` and `America/New_York`.
   */
  ...keys(backwardsZones),
  ...keys(backwardsUtcZones),
  /**
   * These zones are all handled improperly by Java, being returned on future requests as a fixed
   * offset. (e.g. `EST` becomes `-5`).
   */
  "HST",
  "MST",
  "EST",
  "WET",
  "CET",
  "MET",
  "EET",
  // This is the "undefined" timezone, and is not a zone in the Java zones list.
  "Factory",
];

export function normalizeZone(maybeDeprecatedZone: string) {
  return (
    (backwardsZones as { [deprecatedZone: string]: string })[maybeDeprecatedZone] ??
    (backwardsUtcZones as { [deprecatedZone: string]: string })[maybeDeprecatedZone] ??
    maybeDeprecatedZone
  );
}

const now = () => DateTime.now().toMillis();

export const INVALID_TIMEZONE = "Invalid Timezone";

export function getTimeZoneDisplayName(zoneName: string) {
  const zone = IANAZone.create(normalizeZone(zoneName));

  if (!zone.isValid) {
    console.error(`"${zoneName}" is not a valid IANA time zone!`);
    return INVALID_TIMEZONE;
  }

  const offset = zone.formatOffset(now(), "short");
  const abbr = zone.offsetName(now(), { format: "short" });
  const offsetAbbr = abbr.match(/GMT/i);
  const name = zone.name.replace(/\//g, " / ").replace(/_/g, " ");
  const fullNameLabel = compact([!offsetAbbr && abbr, name]).join(" - ");

  return `(GMT${offset}) ${fullNameLabel}`;
}

export const getSortedTimeZones = () =>
  transform(
    Object.keys(zones),
    filter((tz) => !LEGACY_ZONES.includes(tz) && IANAZone.isValidZone(tz)),
    sortBy([
      (z) => IANAZone.create(z).offset(DateTime.now().toMillis()),
      (z) => getTimeZoneDisplayName(z),
    ]),
  );

/**
 * A pairing of a formatted zone name with the canonical zone name.
 *
 * `[formattedZoneName, zoneName]`
 */
type TimeZoneTuple = [string, string];

export const getTimeZoneTuples = () =>
  getSortedTimeZones().map<TimeZoneTuple>((zone) => [getTimeZoneDisplayName(zone), zone]);

export const getTimeZones = () => fromPairs(getTimeZoneTuples());

//////////////////////////
// SMALL UTILs
//////////////////////////

export function getRenderTimeZone(): string {
  const email = orgPrimaryEmail.get();
  return (email && renderTimeZoneMap.get(email)) || getTimeZoneGuess();
}

export function setRenderTimeZone(tz: string): void {
  const email = orgPrimaryEmail.get();
  if (email) {
    return renderTimeZoneMap.set(email, tz);
  }
}

export function getCurrentTimeZone(): string {
  const email = orgPrimaryEmail.get();
  return (email && currentTimeZoneMap.get(email)) || getTimeZoneGuess();
}

export function setCurrentTimeZone(tz: string): void {
  const email = orgPrimaryEmail.get();
  if (email) {
    return currentTimeZoneMap.set(email, tz);
  }
}

export const intervalSetZone = (timeZone: Zone) => (interval: Interval) => {
  return Interval.fromDateTimes(interval.start.setZone(timeZone), interval.end.setZone(timeZone));
};

// here we override the default getRenderTimeZone defined by the common ZonedMoment
// this is to disassociate the ZonedMoment implementation from a localStorage dependency
ZonedMoment.getRenderTimeZone = () => {
  const email = orgPrimaryEmail.get();
  if (!email || !getRenderTimeZone()) {
    return getTimeZoneGuess();
  }
  return getRenderTimeZone();
};

export { getTimeZoneGuess, ZonedMoment };
