/**
 * @generated SignedSource<<0335ce0151d8a2e905192f85eec38ad6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DefragFeedback = "LooksGood" | "Maybe" | "Meh" | "%future added value";
export type RecordDefragResponseMutationInput = {
  clientMutationId?: string | null;
  feedback?: DefragFeedback | null;
  orgId: string;
  otherReason?: string | null;
  preDefinedReasons?: ReadonlyArray<string | null> | null;
  responseKeys: ReadonlyArray<any | null>;
};
export type RecordDefragResponseMutation$variables = {
  input: RecordDefragResponseMutationInput;
};
export type RecordDefragResponseMutation$data = {
  readonly recordDefragResponse: {
    readonly response: boolean | null;
  } | null;
};
export type RecordDefragResponseMutation = {
  variables: RecordDefragResponseMutation$variables;
  response: RecordDefragResponseMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RecordDefragResponseMutationPayload",
    "kind": "LinkedField",
    "name": "recordDefragResponse",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "response",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecordDefragResponseMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecordDefragResponseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dedf4ff42d527e3c30b2d28af4c261d5",
    "id": null,
    "metadata": {},
    "name": "RecordDefragResponseMutation",
    "operationKind": "mutation",
    "text": "mutation RecordDefragResponseMutation(\n  $input: RecordDefragResponseMutationInput!\n) {\n  recordDefragResponse(input: $input) {\n    response\n  }\n}\n"
  }
};
})();

(node as any).hash = "09a5b73eb9645531396c2edfb3cc20d3";

export default node;
