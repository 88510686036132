//////////////////
// IMPORTS
//////////////////
import { CWIDProps } from "@clockwise/web-commons/src/util/cw-id.util";
import classNames from "classnames";
import * as React from "react";

//////////////////
// TYPES
//////////////////
interface Props extends CWIDProps {
  href?: string;
  target?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  light?: boolean;
}

//////////////////
// COMPONENT
//////////////////

/**
 * @deprecated Prefer design-system's Link component
 */
export const A = ({ href, target, onClick, style, light, "cw-id": cwId, children }: Props) => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = React.useCallback(
    (e) => onClick?.(e),
    [onClick],
  );

  const colorClasses = light
    ? "cw-text-onEmphasis cw-border-onEmphasis"
    : "cw-text-brand cw-border-brand";

  return (
    <a
      className={classNames(
        "cw-pointer cw-transition-colors cw-duration-300",
        "cw-underline cw-underline-offset-2 cw-decoration-1 cw-decoration-dashed",
        colorClasses,
        "hover:cw-text-brand-hover hover:cw-decoration-solid",
      )}
      style={style}
      href={href}
      onClick={handleClick}
      target={target}
      cw-id={cwId}
    >
      {children}
    </a>
  );
};
