import { CSSProperties } from "react";
import { colors, greys } from "../../styles/color.styles";
import spacing from "../../styles/spacing.styles";
import { lineHeight, size } from "../../styles/type.styles";

const container: CSSProperties = {
  padding: spacing.sm,
  fontSize: size.md,
  lineHeight: lineHeight.std,
  borderTop: `1px solid ${greys.paleGrey}`,
  borderRight: `1px solid ${greys.paleGrey}`,
  borderBottom: `1px solid ${greys.paleGrey}`,
};

export const info: CSSProperties = {
  ...container,
  borderLeft: `3px solid ${colors.blueCool.standard}`,
};

export const warning: CSSProperties = {
  ...container,
  borderLeft: `3px solid ${colors.yellow.darker}`,
};

export const error: CSSProperties = {
  ...container,
  borderLeft: `3px solid ${colors.rose.standard}`,
};
