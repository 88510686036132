import { CWIDProps } from "@clockwise/web-commons/src/util/cw-id.util";
import classNames from "classnames";
import * as React from "react";

interface Props extends CWIDProps {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

/**
 * @deprecated Prefer tailwind classes (e.g., cw-heading-base)
 */
export const H2 = ({ className, style, children, "cw-id": cwId }: Props) => (
  <div className={classNames("cw-heading-base cw-mb-2", className)} style={style} cw-id={cwId}>
    {children}
  </div>
);
