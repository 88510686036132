/**
 * Based on FastCrawlFlowState in services.
 * See here: https://github.com/clockwisehq/services/blob/3a4db77f08635ae6232651d9e1aaad085c0998d3/java-services/scoping-service/scoping-shared/src/main/java/com/clockwise/scoping/flow/FastCrawlFlowState.java
 */
export enum FastCrawlFlowState {
  Unstarted = "Unstarted",
  CredentialsVerified = "CredentialsVerified",
  UserCrawled = "UserCrawled",
  CalendarAliasesResolved = "CalendarAliasesResolved",
  PersonsSaved = "PersonsSaved",
  TeammatesIdentified = "TeammatesIdentified",
  InvitesSuggested = "InvitesSuggested",
  TeammatesEventsCrawled = "TeammatesEventsCrawled",
  TeammatesEventsSaved = "TeammatesEventsSaved",
  Finished = "Finished",
}

const fatalStates = [
  FastCrawlFlowState.Unstarted,
  FastCrawlFlowState.CredentialsVerified,
  FastCrawlFlowState.UserCrawled,
  FastCrawlFlowState.CalendarAliasesResolved,
  FastCrawlFlowState.TeammatesIdentified,
  FastCrawlFlowState.InvitesSuggested,
];

export const isInFatalState = (
  flowState: {
    current: { errorMessage: string | null } | null;
    previousState: string | null;
  } | null,
) => {
  if (!flowState || !flowState.current?.errorMessage) {
    return false;
  }

  return (
    !flowState.previousState || fatalStates.includes(flowState.previousState as FastCrawlFlowState)
  );
};

export const fastCrawlSequence = [
  FastCrawlFlowState.Unstarted,
  FastCrawlFlowState.CredentialsVerified,
  FastCrawlFlowState.UserCrawled,
  FastCrawlFlowState.CalendarAliasesResolved,
  FastCrawlFlowState.PersonsSaved,
  FastCrawlFlowState.TeammatesIdentified,
  FastCrawlFlowState.InvitesSuggested,
  FastCrawlFlowState.TeammatesEventsCrawled,
  FastCrawlFlowState.TeammatesEventsSaved,
  FastCrawlFlowState.Finished,
];

const atOrPast = (state: FastCrawlFlowState) => (currentState: string | null) => {
  const currentStateIndex = fastCrawlSequence.findIndex((v) => v === currentState);
  const stateIndex = fastCrawlSequence.findIndex((v) => v === state);
  return currentState && currentStateIndex >= stateIndex;
};

export const areTeammatesIdentified = atOrPast(FastCrawlFlowState.TeammatesIdentified);
export const isUserCrawled = atOrPast(FastCrawlFlowState.UserCrawled);
