import { colors, greys } from "@clockwise/web-commons/src/styles/color.styles";
import * as typeStyles from "@clockwise/web-commons/src/styles/type.styles";
import { CSSProperties as MuiCSSProperties } from "@material-ui/core/styles/withStyles";
import { CSSProperties } from "react";

export const CHROME_MAX_WIDTH = 300;
export const CHROME_NAV_HEIGHT = 0;
export const CLOCKWISE_NAV_HEIGHT = 65;

export const rootContainer: CSSProperties = {
  fontFamily: typeStyles.fontFamilySans,
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  backgroundColor: "transparent",
};

const chromeContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  top: 0,
  bottom: 0,
  right: 0,
  width: CHROME_MAX_WIDTH,
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
};

export const offlineContainer: CSSProperties = {
  ...chromeContainer,
  borderLeft: `1px solid ${greys.borderGrey}`,
};

export const networkOffline: Record<string, CSSProperties> = {
  container: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    right: 0,
    top: 0,
    bottom: 0,
    width: CHROME_MAX_WIDTH,
    borderLeft: `1px solid ${greys.borderGrey}`,
  },
  prompt: {
    flex: 1,
    background: greys.paleGrey,
  },
};

export const closedIndicator: CSSProperties = {
  position: "absolute",
  top: 60,
  right: 0,
  height: 3,
  width: 3,
  backgroundColor: colors.rose.lighter,
};

export const nop: CSSProperties = {
  display: "none",
};

//////////////
// MODERN STUFF
/////////////
export const modernContainer: MuiCSSProperties = {
  position: "fixed",
  top: CHROME_NAV_HEIGHT,
  bottom: 0,
  right: 0,
  zIndex: 10,
  width: CHROME_MAX_WIDTH,
  maxWidth: CHROME_MAX_WIDTH,
  display: "flex",
  flexDirection: "column",
  borderLeft: `1px solid ${greys.borderGrey}`,
};

export const modernContainerWebapp: MuiCSSProperties = {
  ...modernContainer,
  overflow: "visible",
  "@media (max-width: 768px)": {
    top: 0,
    width: "100%",
    maxWidth: "100%",
    borderLeft: "none",
    zIndex: 10,
  },
};

export const modernContent: CSSProperties = {
  zIndex: 1,
  width: "100%",
  height: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  wordWrap: "break-word",
  position: "relative",
  paddingLeft: 8,
  paddingRight: 8,
};

export const styles = {
  modernContainer,
  modernContainerWebapp,
};
