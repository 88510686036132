//////////////////
// IMPORTS
//////////////////
// libraries
import * as React from "react";
// assets
import { clockwiseNotFoundHero } from "#webapp/src/assets/img/illustrations/index";
// downtime imports
import { styles } from "./Downtime.styles";
import { IProps, IState } from "./DowntimeTypes";
// components
import { A } from "#webapp/src/components/ui";
import { Nav } from "@clockwise/web-commons/src/components/nav";
// util
import { statusUrl } from "#webapp/src/constants/site.constant";
// material-ui
import { ThemeProvider, Typography } from "@clockwise/design-system";
import { withStyles } from "@material-ui/core/styles";
import { PostMessageManager } from "../../util/post-message.util";

//////////////////
// COMPONENT
//////////////////
class DowntimeCmpt extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  private onClickCollapse = () => {
    PostMessageManager.closeSidebar();
  };

  public render() {
    const headerImage = this.props.healthPayload.statusImage
      ? this.props.healthPayload.statusImage
      : clockwiseNotFoundHero;

    const statusText = this.props.healthPayload.statusText
      ? `${this.props.healthPayload.statusText} `
      : "";

    const padding = this.props.isChromeExtension ? ` ${this.props.classes.paddingChrome}` : "";

    return (
      <ThemeProvider>
        <div
          className={
            this.props.isChromeExtension
              ? this.props.classes.containerChrome
              : this.props.classes.container
          }
        >
          {this.props.isChromeExtension ? <Nav onClickCollapse={this.onClickCollapse} /> : null}
          <div
            className={
              this.props.isChromeExtension
                ? this.props.classes.headerImgChrome
                : this.props.classes.headerImg
            }
            style={{ backgroundImage: `url(${headerImage})` }}
          />
          <Typography className={`${this.props.classes.marginTop}${padding}`} variant="h6">
            Clockwise is unavailable at the moment
          </Typography>
          <Typography className={`${this.props.classes.marginTop}${padding}`} variant="body1">
            {statusText}Thanks for your patience as we work to get things ticking again. Check the{" "}
            <A href={statusUrl} target="_blank">
              status page
            </A>{" "}
            for updates.
          </Typography>
        </div>
      </ThemeProvider>
    );
  }
}

//////////////////
// WITH STYLES
//////////////////
export const Downtime = withStyles(styles)(DowntimeCmpt);
