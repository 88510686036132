import {
  ApolloLink,
  DocumentNode,
  fromPromise,
  HttpLink,
  Operation,
  selectURI,
  toPromise,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { genTxnId } from "@clockwise/client-commons/src/util/txn";
import { clientId } from "@clockwise/web-commons/src/util/fetch.util";
import { useIDB } from "@clockwise/web-commons/src/util/idb";
// TODO @types/apollo-upload-client are incorrect and currently don't reflect the MJS exports.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { jwt, xsrf } from "../../state/local-storage";

type NetworkLinkHeaders = {
  Authorization?: string;
  Xsrf?: string;
  clientId: string;
  txnId: string;
};

declare module "@apollo/client" {
  interface DefaultContext {
    /** Whether to use batched requests for this query/mutation. Defaults to `true`. */
    useBatching?: boolean;
    headers?: NetworkLinkHeaders;
  }
}

export const getTokens = async () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { jwt, xsrf } = await useIDB();

  const jwtToken = (await jwt.get()) as string | null;
  const xsrfToken = (await xsrf.get()) as string | null;

  return { jwtToken, xsrfToken };
};

export const setAuthLinkOperationContext = (
  operation: Operation,
  token: string | null,
  xsrfToken: string | null,
) => {
  const context = operation.getContext();
  const newHeaders: NetworkLinkHeaders = {
    clientId,
    txnId: genTxnId(),
  };

  if (xsrfToken) {
    newHeaders.Xsrf = xsrfToken;
  }
  if (token) {
    newHeaders.Authorization = `Bearer ${token}`;
  }

  operation.setContext({
    ...context,
    headers: {
      ...newHeaders,
      ...context.headers,
    },
  });
};

const buildConnectionParams = (token: string | null, Xsrf: string | null) => {
  return {
    Authorization: token ? `Bearer ${token}` : null,
    Xsrf,
    ConnectCount: null,
    ConnectReason: "Apollo initiating connection",
  };
};

const getConnectionParams = () => {
  const token = jwt.get();
  return buildConnectionParams(token, xsrf.get());
};

const getConnectionParamsAsync = async () => {
  const { jwtToken, xsrfToken } = await getTokens();
  return buildConnectionParams(jwtToken, xsrfToken);
};

export const getSocketLink = (url: string, async = false) => {
  return new WebSocketLink(
    new SubscriptionClient(url, {
      connectionParams: async ? getConnectionParamsAsync : getConnectionParams,
    }),
  );
};

export const getAuthLink = (useIDB = false) => {
  if (useIDB) {
    return new ApolloLink((operation, forward) => {
      return fromPromise(
        getTokens().then(({ jwtToken, xsrfToken }) => {
          setAuthLinkOperationContext(operation, jwtToken, xsrfToken);
          return toPromise(forward(operation));
        }),
      );
    });
  } else {
    return new ApolloLink((operation, forward) => {
      setAuthLinkOperationContext(operation, jwt.get(), xsrf.get());
      return forward(operation);
    });
  }
};

export const getGraphQLLink = (url: string) =>
  new HttpLink({
    uri: url,
    credentials: "include",
    fetch, // Provide a cross environment version of fetch, so that this can run in both the browser and tests.
  });

export const getBatchLink = (url: string) => {
  return new BatchHttpLink({
    uri: url,
    credentials: "include",
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    /**
     * Replace the standard `batchKey` method with one that ignores the `txnId` when creating batches.
     */
    batchKey: (operation) => {
      const context = operation.getContext();
      const uri: string = selectURI(operation, url);

      // Remove the `txnId` from the headers for the purposes of batching.
      const { txnId, ...headers } = context.headers ?? {};

      //may throw error if config not serializable
      return (
        uri +
        JSON.stringify({
          http: context.http,
          options: context.fetchOptions,
          credentials: context.credentials,
          headers,
        })
      );
    },
    batchMax: 10,
    fetch, // Provide a cross environment version of fetch, so that this can run in both the browser and tests.
  });
};

export const getUploadLink = (url: string) => {
  // TODO @types/apollo-upload-client are incorrect and currently don't reflect the MJS exports.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return createUploadLink({
    uri: url,
    credentials: "include",
    fetch, // Provide a cross environment version of fetch, so that this can run in both the browser and tests.
  });
};

export const isUsingBatching = (operation: Operation): boolean => {
  const context = operation.getContext();
  return context.useBatching ?? true;
};

export const isSubscriptionOperation = ({ query }: { query: DocumentNode }) => {
  const definition = getMainDefinition(query);
  return definition.kind === "OperationDefinition" && definition.operation === "subscription";
};

const storedTokenHash = {
  jwt,
  xsrf,
};

function updateTokenWithHeaders(headers: Headers) {
  return function updateToken(token: keyof typeof storedTokenHash) {
    const tokenStore = storedTokenHash[token];
    const t = headers.get(token);
    if (t && t !== tokenStore.get()) {
      tokenStore.set(t);
    }
  };
}

/**
 * Our custom "afterware" that checks each response and sets jwt/xsrf from header
 */
export const updateTokensLink = new ApolloLink((operation, forward) => {
  // Need to bail out when it's a subscription operation, due to bugs here:
  // https://github.com/apollographql/apollo-client/issues/9061#issuecomment-971773397
  if (isSubscriptionOperation(operation)) {
    return forward(operation);
  }

  return forward(operation).map((response) => {
    const context = operation.getContext();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const updateToken = updateTokenWithHeaders(context.response.headers as Headers);

    // maybe set the jwt
    updateToken("jwt");

    // maybe set the xsrf
    updateToken("xsrf");

    return response;
  });
});
