// schema
import * as ISchema from "#webapp/src/__schema__";
import { UrnType } from "@clockwise/schema";

import { orgId } from "#webapp/src/state/local-storage";
import { sortDataSources } from "#webapp/src/util/data-source.util";
import { logger } from "#webapp/src/util/logger.util";
import { compact } from "lodash";
interface MemberWithUrn {
  scope?: {
    value: string | null;
    type: UrnType | null;
  } | null;
  value: string | null;
}

export interface UserWithEmail {
  emails: string[];
  members: Array<MemberWithUrn | null> | null;
}

function getCurrentOrgPrimaryEmail(members: MemberWithUrn[]): string | null {
  if (!orgId.get() || !(orgId.get() as string).split(":").length) {
    return null;
  }
  const oid = atob(orgId.get() as string).split(":")[1];

  const primary = members.find((member) => member.scope && member.scope.value === oid);
  if (!primary) {
    return null;
  }

  return primary.value;
}

function getAnyOrgPrimaryEmail(members: MemberWithUrn[]): string | null {
  const primary = members.find((member) => member.scope?.type === "Org");
  if (!primary) {
    return null;
  }

  return primary.value;
}

export function getCurrentOrgPrimaryEmailOrBestEffort(user: UserWithEmail) {
  const members = compact(user.members);
  if (!members || !members.length) {
    logger.error(
      "getCurrentOrgPrimaryEmailOrBestEffort called with no user members.  Is members missing from fragment?",
    );
    return null;
  }
  let primaryEmail = getCurrentOrgPrimaryEmail(members);
  if (primaryEmail) {
    return primaryEmail;
  }

  primaryEmail = getAnyOrgPrimaryEmail(members);
  if (primaryEmail) {
    return primaryEmail;
  }

  return (user.emails && user.emails[0]) || null;
}

export type Node<T> = { node?: T | null };
export type Edges<T> = { edges?: (Node<T> | null | undefined)[] | null | undefined };

type Org = {
  id: string;
};

export interface UserWithOrgs {
  orgs: Edges<Org>;
}

/**
 * These are types defined to allow for a generic Viewer object in `getCurrentOrg`, they define only
 * the minimal set of fields necessary to satisfy `getCurrentOrg` usage.
 */
export interface Viewer {
  user: UserWithOrgs | null;
}

/**
 * Given a `Viewer` object, this type extracts the `Org` object type from it, assuming all the
 * various fields are not null. This allows for a variety of different Viewer objects to be used in
 * type-safe manner, e.g. an exhaustive Viewer object from Relay vs. a sparse Viewer object from an
 * Apollo query.
 */
type DefiniteOrg<V extends Viewer> = NonNullable<
  NonNullable<NonNullable<V["user"]>["orgs"]["edges"]>[0]
>["node"];

export function getCurrentOrg<V extends Viewer>(
  viewer: V | null | undefined,
): DefiniteOrg<V> | null {
  if (!viewer?.user?.orgs?.edges?.length) {
    return null;
  }

  // find the right org depending on orgId state
  const edges = compact(viewer.user.orgs.edges ?? []);
  const org = edges.find((edge) => edge.node?.id === orgId.get())?.node || edges[0]?.node;

  // if we haven't found anything, return null
  if (!org) {
    return null;
  }

  // if our org does not match the set orgId, update the orgId
  if (org.id && org.id !== orgId.get()) {
    orgId.set(org.id);
  }

  return org;
}

export function getOrgCrawlFailure(viewer: ISchema.IViewer, windowUserEmail?: string) {
  const org = getCurrentOrg(viewer);
  let personalCalendar = false;

  let orgCrawlFailure: ISchema.OrgCrawlTest | null = null;
  if (org && viewer.user.dataSources && viewer.user.dataSources.edges) {
    const sortedDataSources = sortDataSources(viewer.user.dataSources.edges, org.domains);
    personalCalendar =
      sortedDataSources.other.findIndex((ds) => ds.name === windowUserEmail) !== -1;
    if (org.crawlTest && org.crawlTest !== "Skipped" && org.crawlTest !== "StartedCrawl") {
      const userAge = new Date().getTime() - viewer.user.createdTime;
      const brandNewUser = userAge < 60 * 1000; // 1 minute

      // ShouldCrawl for brand new users is not an error
      if (org.crawlTest !== "ShouldCrawl" || !brandNewUser) {
        orgCrawlFailure = org.crawlTest;
      }
    }
  }

  return { orgCrawlFailure, personalCalendar };
}
