import { AccessTime, Notes, People, Public, Today, Videocam } from "@clockwise/design-system/icons";
import { LocationTypeEnum } from "@clockwise/schema/v2";
import classNames from "classnames";
import { DateTime } from "luxon";
import React, { PropsWithChildren, useState } from "react";
import { ConfirmationType } from "../";
import { MeetLogo } from "../../../components/svgs/MeetLogo";
import { TeamsLogo } from "../../../components/svgs/TeamsLogo";
import { ZoomLogo } from "../../../components/svgs/ZoomLogo";
import { getFormattedDateTimeTz } from "../../../util/date.util";
import { formatDuration } from "../../../util/duration.util";
import { getTimeZoneDisplayName } from "../../../util/time-zone.util";

type Props = {
  title: string;
  duration: number;
  prevDateTime?: string | null;
  dateTime: string;
  timeZone: string;
  attendees?: string[];
  location?: LocationTypeEnum | null;
  description?: string | null;
  className?: string;
  variant: ConfirmationType;
  compact?: boolean;
  cancelled?: boolean;
};

type MeetingLocationDetailTypes = {
  [Property in LocationTypeEnum | "ConnectZoom"]: {
    locationIcon: JSX.Element;
    locationName: string;
  };
};

export const MEETING_LOCATION_DETAILS: MeetingLocationDetailTypes = {
  Zoom: {
    locationIcon: <ZoomLogo />,
    locationName: "Zoom",
  },
  ConnectZoom: {
    locationIcon: <ZoomLogo />,
    locationName: "Connect your Zoom account",
  },
  Meet: {
    locationIcon: <MeetLogo />,
    locationName: "Google Meet",
  },
  Teams: {
    locationIcon: <TeamsLogo />,
    locationName: "Microsoft Teams",
  },
  InDescription: {
    locationIcon: <Videocam className="cw-h-5 cw-w-5 cw-mr-[-2px] cw-fill-muted" />, // This icon has more space to the right of it than the other two.
    locationName: "Specified in description",
  },
};

export const EventSummary = ({
  title,
  duration,
  prevDateTime,
  dateTime,
  timeZone,
  attendees,
  location,
  description,
  className,
  cancelled,
  compact = false,
  children,
}: PropsWithChildren<Props>) => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const locationDetails = location && MEETING_LOCATION_DETAILS[location];
  const date = DateTime.fromISO(dateTime, { zone: timeZone });
  const prevDate = prevDateTime ? DateTime.fromISO(prevDateTime, { zone: timeZone }) : null;
  const formattedEventTime = getFormattedDateTimeTz(date);
  const formattedPrevEventTime = prevDate ? getFormattedDateTimeTz(prevDate) : null;

  const formattedDuration = formatDuration(duration);

  const DetailRow = ({
    "cw-id": cwId,
    icon,
    children,
  }: PropsWithChildren<{ "cw-id"?: string; icon: JSX.Element }>) => (
    <div className="cw-flex cw-items-center cw-justify-start cw-gap-5 cw-body-lg" cw-id={cwId}>
      {icon}
      {children}
    </div>
  );

  return (
    <div
      className={classNames(
        "cw-flex cw-flex-col cw-bg-default cw-p-6 cw-border cw-border-solid cw-border-brand cw-shadow-[0px_4px_24px_rgba(64,164,124,0.08),0px_1px_4px_rgba(0,0,0,0.05)] cw-rounded-xl",
        className,
        compact ? "cw-gap-3" : "cw-gap-4",
      )}
    >
      <div
        className={compact ? "cw-heading-base" : "cw-heading-2xl"}
        cw-id="links-booking-title"
        aria-label="meeting name"
      >
        {title}
      </div>

      <div
        className={classNames("cw-flex cw-flex-col", compact ? "cw-gap-2" : "cw-gap-4", {
          "cw-line-through": cancelled,
        })}
      >
        <DetailRow
          cw-id="links-booking-duration"
          icon={<AccessTime className="cw-text-[22px] cw-fill-muted" />}
        >
          <div aria-label="meeting duration">{formattedDuration}</div>
        </DetailRow>
        <DetailRow
          cw-id="links-booking-datetime"
          icon={<Today className="cw-text-[22px] cw-fill-muted" />}
        >
          <div className="cw-flex cw-flex-col cw-gap-1">
            {formattedPrevEventTime && (
              <div
                className="cw-line-through cw-text-default-disabled"
                aria-label="previous meeting date and time"
              >
                {formattedPrevEventTime}
              </div>
            )}
            <div
              className={!cancelled ? "cw-text-positive cw-font-bold" : ""}
              aria-label="meeting date and time"
            >
              {formattedEventTime}
            </div>
          </div>
        </DetailRow>
        <DetailRow
          cw-id="links-booking-time-zone"
          icon={<Public className="cw-text-[22px] cw-fill-muted" />}
        >
          <div aria-label="time zone">{getTimeZoneDisplayName(timeZone)}</div>
        </DetailRow>
        {attendees && (
          <DetailRow
            cw-id="links-booking-attendees"
            icon={<People className="cw-text-[22px] cw-fill-muted" />}
          >
            <div aria-label="meeting attendees" className="cw-whitespace-pre-wrap cw-break-all">
              {attendees
                .filter((a) => !a.includes(" (optional)"))
                .join(", \n")
                .trim()}
              {/* We will always have at least one required attendee on an event */}
              {attendees.filter((a) => a.includes(" (optional)")).length > 0 && ",\n"}
              <div className="cw-text-muted">
                {attendees
                  .filter((a) => a.includes(" (optional)"))
                  .join(", \n")
                  .trim()}
              </div>
            </div>
          </DetailRow>
        )}
        {locationDetails && (
          <DetailRow icon={<Videocam className="cw-text-[22px cw-fill-muted" />}>
            <span
              className="cw-flex cw-items-center cw-gap-2"
              cw-id="links-booking-location-type"
              aria-label="meeting location"
            >
              {locationDetails?.locationIcon}
              {locationDetails?.locationName}
            </span>
          </DetailRow>
        )}
        {description && (
          <DetailRow icon={<Notes className="cw-text-[22px] cw-fill-muted cw-self-start" />}>
            <div className="cw-flex cw-gap-1 cw-justify-start">
              <div
                className={classNames("cw-body-base cw-max-w-md", {
                  "cw-line-clamp-1": !showFullDescription,
                })}
                cw-id="links-booking-description"
                aria-label="meeting description"
              >
                {description}
              </div>
              {!showFullDescription && !cancelled && (
                <button
                  className="cw-border-0 cw-bg-default cw-text-brand hover:cw-underline cw-cursor-pointer"
                  onClick={() => setShowFullDescription(true)}
                >
                  show
                </button>
              )}
            </div>
          </DetailRow>
        )}
      </div>
      {children}
    </div>
  );
};
