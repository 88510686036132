/**
 * @generated SignedSource<<ef060123db33617396dbd16db69b3c6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendM365TeamAvailabilityCalendarInviteMutationInput = {
  clientMutationId?: string | null;
  personIds: ReadonlyArray<string>;
  teamId: string;
};
export type SendM365TeamAvailabilityCalendarInviteMutation$variables = {
  input: SendM365TeamAvailabilityCalendarInviteMutationInput;
};
export type SendM365TeamAvailabilityCalendarInviteMutation$data = {
  readonly sendM365TeamAvailabilityCalendarInvite: {
    readonly clientMutationId: string | null;
  } | null;
};
export type SendM365TeamAvailabilityCalendarInviteMutation = {
  variables: SendM365TeamAvailabilityCalendarInviteMutation$variables;
  response: SendM365TeamAvailabilityCalendarInviteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendM365TeamAvailabilityCalendarInviteMutationPayload",
    "kind": "LinkedField",
    "name": "sendM365TeamAvailabilityCalendarInvite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendM365TeamAvailabilityCalendarInviteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendM365TeamAvailabilityCalendarInviteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a40ea4d6c41d44657aa2d61543ab8e08",
    "id": null,
    "metadata": {},
    "name": "SendM365TeamAvailabilityCalendarInviteMutation",
    "operationKind": "mutation",
    "text": "mutation SendM365TeamAvailabilityCalendarInviteMutation(\n  $input: SendM365TeamAvailabilityCalendarInviteMutationInput!\n) {\n  sendM365TeamAvailabilityCalendarInvite(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "a673083f8af980bd03bc37afe435cdcf";

export default node;
