// libraries
import { CSSProperties } from "@material-ui/core/styles/withStyles";
// styles
import { CHROME_MAX_WIDTH } from "#webapp/src/components/chrome-wrapper/ChromeWrapper.styles";
import { greys } from "@clockwise/web-commons/src/styles/color.styles";
import spacing from "@clockwise/web-commons/src/styles/spacing.styles";

export const container: CSSProperties = {
  textAlign: "center",
};

export const containerChrome: CSSProperties = {
  ...container,
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  right: 0,
  top: 0,
  bottom: 0,
  width: CHROME_MAX_WIDTH,
  borderLeft: `1px solid ${greys.borderGrey}`,
};

export const headerImg: CSSProperties = {
  marginTop: spacing.xlg,
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  minHeight: "200px",
};

export const headerImgChrome: CSSProperties = {
  ...headerImg,
  minHeight: "150px",
};

export const paddingChrome: CSSProperties = {
  paddingLeft: spacing.std,
  paddingRight: spacing.std,
};

export const marginTop: CSSProperties = {
  marginTop: spacing.xlg,
};

export const styles = {
  container,
  containerChrome,
  headerImg,
  headerImgChrome,
  marginTop,
  paddingChrome,
};
