// IMPORTS
// schema
import * as ISchema from "#webapp/src/__schema__";

import { modernCache } from "#webapp/src/network/modern-network-layer";
import { commitMutation } from "react-relay";
import { Environment } from "relay-runtime";
import { updateSmartHoldSyncSettingsMutation } from "./UpdateSmartHoldSyncSettings.gql";

// TYPES
interface IUpdateSmartHoldSyncSettingsMutationResponse {
  updateSmartHoldSyncSettings: ISchema.IUpdateSmartHoldSyncSettingsMutationPayload;
}

// MUTATION
export function updateSmartHoldSyncSettings(
  environment: Environment,
  input: ISchema.IUpdateSmartHoldSyncSettingsMutationInput,
  onSuccess: (response?: ISchema.IUpdateSmartHoldSyncSettingsMutationPayload) => void,
  onError: (error?: any) => void,
) {
  commitMutation(environment, {
    onError,
    mutation: updateSmartHoldSyncSettingsMutation,
    variables: { input },
    onCompleted: (response: IUpdateSmartHoldSyncSettingsMutationResponse, errors?: Error[]) => {
      if (errors) {
        onError(errors[0]);
      } else {
        // if we're not in a modern environment, modernCache will be undefined
        if (modernCache) {
          modernCache.clear();
        }
        onSuccess(response.updateSmartHoldSyncSettings);
      }
    },
  });
}
