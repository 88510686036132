import * as React from "react";
import { LegacyTooltip } from "../tooltip";

export interface DisabledWrapperProps {
  children: React.ReactNode;
  tooltipHeader?: string;
  tooltipBody?: string;
  width?: number;
  isDisabled: boolean;
  tooltipStyles?: Record<string, string>;
}

export const focusTimeExpiredTooltipCopy = {
  header: "Upgrade to schedule Focus Time",
  body: "Let Clockwise help you create and protect uninterrupted Focus Time on your calendar.",
};

export const flexibilityExpiredTooltipCopy = {
  header: "Upgrade to mark meetings as flexible",
  body:
    "Tell Clockwise which meetings are flexible, and we will optimize your schedule and free up blocks of uninterrupted time for you and your teammates.",
};

export const DisabledWrapper = ({
  children,
  tooltipBody,
  tooltipHeader,
  width,
  isDisabled,
  tooltipStyles,
}: DisabledWrapperProps) => {
  const renderDisabledDiv = () => {
    return (
      <div className="cw-opacity-50 cw-cursor-not-allowed" aria-disabled tabIndex={-1}>
        <fieldset
          className="cw-border-0 cw-pointer-events-none"
          disabled
          aria-disabled
          tabIndex={-1}
        >
          {children}
        </fieldset>
      </div>
    );
  };

  const maybeRenderTooltipWrapper = () => {
    const showTooltip = !!tooltipBody || !!tooltipHeader;
    return (
      <div
        tabIndex={0}
        aria-disabled="true"
        aria-label={tooltipHeader ? tooltipHeader : tooltipBody}
      >
        {showTooltip ? (
          <LegacyTooltip
            placement={"bottom"}
            width={width}
            body={tooltipBody || ""}
            header={tooltipHeader}
            showArrow={false}
            styles={tooltipStyles}
          >
            {renderDisabledDiv()}
          </LegacyTooltip>
        ) : (
          <>{renderDisabledDiv()}</>
        )}
      </div>
    );
  };
  return isDisabled ? maybeRenderTooltipWrapper() : <>{children}</>;
};
