import { noop } from "lodash";
import React from "react";

type UseIntersectionObserverProps = {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number;
};

export const useIntersectionObserver = ({
  root = null,
  rootMargin = "0px",
  threshold = 0,
}: UseIntersectionObserverProps): [
  React.Dispatch<React.SetStateAction<Element | null>>,
  Partial<IntersectionObserverEntry>,
] => {
  const [entry, setEntry] = React.useState<Partial<IntersectionObserverEntry>>({});
  const [node, setNode] = React.useState<Element | null>(null);

  const observer = React.useRef<IntersectionObserver | null>(null);

  React.useEffect(() => {
    if (!("IntersectionObserver" in window)) {
      return; // Only valid in a browser environment
    }

    if (observer.current) {
      observer.current.disconnect?.();
    }

    /*
     * An IntersectionObserver helps us detect when an element is visible.
     * Its options include a single threshold or a list of thresholds and
     * it executes a callback whenever those thresholds are crossed. To keep
     * things simple, this hook just accepts a single threshold.
     *
     * You could someday imagine wanting to respond to every additional
     * 25% visibility or something but that's not something we need to handle now.
     */
    observer.current = new IntersectionObserver(
      ([entry]) => {
        setEntry(entry);
      },
      { root, rootMargin, threshold },
    );

    const { current: currentObserver } = observer;

    if (node) {
      currentObserver.observe(node);
    }

    return () => currentObserver.disconnect?.();
  }, [node, root, rootMargin, threshold]);

  if (!("IntersectionObserver" in window)) {
    return [noop, {}];
  }

  return [setNode, entry];
};
