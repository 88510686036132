import { TranslationContext } from "@clockwise/client-commons/src/providers/TranslationContext";
import { ExpandLess, ExpandMore } from "@clockwise/design-system/icons";
import classNames from "classnames";
import { Sparkle } from "phosphor-react";
import React, { useContext } from "react";
import { ITierType } from "../../../constants/org-pricing.constants";
import TierAction from "../tier-action/TierAction";
import { IFeatureComparison, PlanTypes } from "../types";
import getFeatureComparisons from "../utils/getFeatureComparisons";
import { BaseFeature } from "./BaseFeature";
import FeatureComparison from "./FeatureComparison";
import { FeatureComparisonRow } from "./FeatureComparisonRow";
import { GridItem } from "./GridItem";

type ComparisonGridProps = {
  isUserOnTrial: boolean;
  onContactUsClick: (tierType: ITierType) => void;
  onDowngradeClick: (tierType: ITierType) => void;
  onUpgradeClick: (tierType: ITierType) => void;
  primarySubscriptionPlan: PlanTypes;
  isM365Beta: boolean;
};

const ComparisonGrid = ({
  isUserOnTrial,
  onContactUsClick,
  onDowngradeClick,
  onUpgradeClick,
  primarySubscriptionPlan,
  isM365Beta,
}: ComparisonGridProps) => {
  const translationContext = useContext(TranslationContext);
  const featureComparisonGetter = (translation: any) => {
    return getFeatureComparisons(translation);
  };

  const featureComprisons: IFeatureComparison[] = featureComparisonGetter({
    t: translationContext,
  });
  const [expandFeatureComparison, setExpandFeatureComparison] = React.useState(true);

  const handleToggleFeatureComparison = () => {
    setExpandFeatureComparison((state) => !state);
  };

  return (
    <React.Fragment>
      <div
        className="lg:cw-flex cw-w-full cw-hidden cw-justify-center cw-cursor-pointer cw-heading-lg cw-my-[50px] cw-items-center"
        onClick={handleToggleFeatureComparison}
      >
        <div>{expandFeatureComparison ? "Hide" : "Show"} feature comparison </div>
        {expandFeatureComparison ? <ExpandLess /> : <ExpandMore />}
      </div>
      <div
        className={classNames(
          "cw-hidden cw-overflow-hidden cw-w-full",
          "lg:cw-flex lg:cw-flex-col lg:cw-pl-2 lg:cw-mb-12",
          "cw-transition-all cw-duration-500 cw-ease-in-out",
          expandFeatureComparison ? "cw-max-h-[2600px]" : "cw-max-h-0 cw-mb-0 cw-pl-0",
        )}
      >
        <FeatureComparisonRow emphasis={true} className="cw-h-12">
          <div className="cw-w-2/5 cw-h-full cw-heading-sm cw-flex cw-justify-start cw-items-end cw-pb-2">
            <div className="cw-block">
              <div className="cw-flex cw-items-center cw-gap-1">
                <Sparkle size={20} />
                <span className="cw-text-brand cw-heading-lg">Smart calendar management</span>
              </div>
            </div>
          </div>
          <GridItem isHeader={true} variant="grey">
            Free
          </GridItem>
          <GridItem isHeader={true} variant="green">
            Teams (popular)
          </GridItem>
          <GridItem isHeader={true} variant="grey">
            Business
          </GridItem>
          <GridItem isHeader={true} variant="grey">
            Enterprise
          </GridItem>
        </FeatureComparisonRow>
        {featureComprisons.map((featureComparison, index) => (
          <FeatureComparison featureComparison={featureComparison} key={index} />
        ))}
        <div className="cw-pt-4 cw-flex cw-justify-evenly cw-leading-6">
          <div className="cw-w-2/5 cw-h-full" />
          <BaseFeature>
            <TierAction
              isUserOnTrial={isUserOnTrial}
              onContactUsClick={onContactUsClick}
              onDowngradeClick={onDowngradeClick}
              onUpgradeClick={onUpgradeClick}
              primarySubscriptionPlan={primarySubscriptionPlan}
              tierType="FreemiumOrg"
            />
          </BaseFeature>
          <BaseFeature>
            <TierAction
              isUserOnTrial={isUserOnTrial}
              onContactUsClick={onContactUsClick}
              onDowngradeClick={onDowngradeClick}
              onUpgradeClick={onUpgradeClick}
              primarySubscriptionPlan={primarySubscriptionPlan}
              tierType="ProOrg"
            />
          </BaseFeature>
          <BaseFeature>
            <TierAction
              isUserOnTrial={isUserOnTrial}
              onContactUsClick={onContactUsClick}
              onDowngradeClick={onDowngradeClick}
              onUpgradeClick={onUpgradeClick}
              primarySubscriptionPlan={primarySubscriptionPlan}
              tierType="BusinessOrg"
              isM365Beta={isM365Beta}
            />
          </BaseFeature>
          <BaseFeature>
            <TierAction
              isUserOnTrial={isUserOnTrial}
              onContactUsClick={onContactUsClick}
              onDowngradeClick={onDowngradeClick}
              onUpgradeClick={onUpgradeClick}
              primarySubscriptionPlan={primarySubscriptionPlan}
              tierType="Enterprise"
              isM365Beta={isM365Beta}
            />
          </BaseFeature>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ComparisonGrid;
