import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React, { useEffect } from "react";
import signUpAssetUrl from "./assets/signup-banner.png";
import tryClockwiseAssetUrl from "./assets/try-clockwise-banner.png";

interface IProps {
  isAuthenticatedUser: boolean;
}
export const BannerVariants = ({ isAuthenticatedUser }: IProps) => {
  if (isAuthenticatedUser) {
    return <TryClockwiseBanner />;
  } else {
    return <SignUpBanner />;
  }
};

const SignUpBanner = () => (
  <Banner
    assetUrl={signUpAssetUrl}
    url="/welcome?utm_medium=clockwise_links&utm_source=referral&utm_campaign=links_confirmation_page"
    viewEvent={TrackingEvents.LINKS.CONFIRMATION_PAGE.VARIANT_GOOGLE_SIGN_IN_VIEW}
    clickEvent={TrackingEvents.LINKS.CONFIRMATION_PAGE.VARIANT_GOOGLE_SIGN_IN_CLICKED}
  />
);

const TryClockwiseBanner = () => (
  <Banner
    assetUrl={tryClockwiseAssetUrl}
    url="/scheduling?utm_medium=clockwise_links&utm_source=referral&utm_campaign=links_confirmation_page"
    viewEvent={TrackingEvents.LINKS.CONFIRMATION_PAGE.VARIANT_CONTROL_VIEW}
    clickEvent={TrackingEvents.LINKS.CONFIRMATION_PAGE.VARIANT_CONTROL_CLICKED}
  />
);

interface BannerProps {
  assetUrl: string;
  url: string;
  viewEvent: string;
  clickEvent: string;
}

const Banner = ({ assetUrl, url, viewEvent, clickEvent }: BannerProps) => {
  const track = useTracking();
  useEffect(() => {
    track(viewEvent);
  }, []);
  return (
    <a href={url} onClick={() => track(clickEvent)}>
      <img className="cw-w-full cw-max-w-[600px]" src={assetUrl} />
    </a>
  );
};
