// schema
import { EcosystemEnum } from "@clockwise/schema";

import { cloneDeep } from "lodash";
import React from "react";

import {
  cwCalendarColors,
  defaultCWCalEventColor,
  defaultCWCalFocusTimeColor,
  defaultGoogleSet,
  defaultM365Set,
  EventColorCategory,
  googleCalendarColors,
  googleCalendarColorsForClockwiseCalendar,
  ICalendarColorsMap,
  m365CalendarColors,
} from "@clockwise/client-commons/src/util/event-category-coloring";
import { getUserTagValueOffEvent, Tags } from "@clockwise/client-commons/src/util/event-tag";
import { IGraphEntityError } from "@clockwise/client-commons/src/util/graphql";

import { useEcosystem } from "./ecosystem";

export { EventColorCategory, googleCalendarColors, m365CalendarColors };

//////////////////////
// Types
//////////////////////
type TagState = {
  active: boolean;
  value: string | null;
  subjectType: string;
  subjectValue: string;
  lastModified: number;
};

type OrgTagWithTagState = {
  tag: string;
  state: TagState;
};

type UserTagWithTagState = {
  tag: string;
  states: TagState[] | null;
};

type AnnotatedEventWithTags = {
  orgTags?: OrgTagWithTagState[] | null;
  userTags?: UserTagWithTagState[] | null;
};

export interface IEventWithAnnotatedEvents {
  annotatedEvent?: AnnotatedEventWithTags | null;
  annotatedRecurringEvent?: AnnotatedEventWithTags | null;
}

export interface IEventCategoryColoring {
  __typename: "EventCategoryColoring";
  active: boolean;
  isUserSet: boolean;
  eventCategory: string;
  googleColorKey: string | null;
}

export interface IEventColoringSettings<ECC extends IEventCategoryColoring> {
  __typename: "EventColoringSettings";
  eventCategoryColorings: ECC[] | null;
}

export type IEventColoringSettingsErrorable<
  ECC extends IEventCategoryColoring,
  ECS extends IEventColoringSettings<ECC>
> = IGraphEntityError | ECS;

export const EventColorCategorySort: { [key: string]: number } = {
  External: 1,
  OneOnOne: 2,
  FocusTime: 3,
  IndividualHold: 4,
  Holiday: 5,
  PaceSetting: 6,
  AdHoc: 7,
  OutOfOffice: 8,
  Other: 9,
};

//////////////////////
// Hook Methods
//////////////////////
export const useCalendarColors = () => {
  const ecosystem = useEcosystem();
  const calendarColors = getCalendarColors(ecosystem);
  const cwCalendarColors = getCWCalendarColors(ecosystem);
  const defaultSet = ecosystem === EcosystemEnum.Google ? defaultGoogleSet : defaultM365Set;

  return {
    // ***************
    // the set of calendar colors for a given ecosystem
    // ***************
    calendarColors,

    // ***************
    // the clockwise color-coding default settings
    // ***************
    defaultSet,

    // ***************
    // given an event category, return the color setting
    // ***************
    getColorsFromSetting: (ecc: IEventCategoryColoring) =>
      getColorsFromSetting(ecc, calendarColors),

    // ***************
    // get color from color settings
    // ***************
    getColors: <ECC extends IEventCategoryColoring>(
      selectedEventCategory: string,
      ecs: IEventColoringSettings<ECC>,
      isSyncedFocusTime?: boolean,
    ) => getColors(selectedEventCategory, ecs, calendarColors, isSyncedFocusTime),

    // ***************
    // get user's current ecc settings
    // ***************
    getCurrentSettings: <
      ECC extends IEventCategoryColoring,
      ECS extends IEventColoringSettings<ECC>
    >(
      ecs?: IEventColoringSettingsErrorable<ECC, ECS>,
    ) => {
      return filterAndSortCategories(ecs).map((ecc) => {
        const labels = getCategoryLabels(ecc.eventCategory);
        const renderColor = getColorsFromSetting(ecc, calendarColors)?.foreground;
        return { labels, renderColor, ...ecc };
      });
    },

    // ***************
    // given an event, return color
    // ***************
    getEventColors: <ECC extends IEventCategoryColoring, E extends IEventWithAnnotatedEvents>(
      event: E,
      colorSettings?: IEventColoringSettings<ECC>,
      userId?: string | null,
      isSyncedFocusTime?: boolean,
      newColors?: boolean,
    ) => {
      const defaultColors = defaultCWCalEventColor;
      if (!colorSettings || !userId) {
        return defaultColors;
      }

      const colorState = getUserTagValueOffEvent(event, Tags.EventColoringCategory, userId);
      let eventCategory;

      if (colorState) {
        eventCategory = colorState.value as EventColorCategory;
      } else {
        eventCategory = EventColorCategory.Other;
      }

      const colors = newColors ? cwCalendarColors : calendarColors;
      return (
        getColors(eventCategory, colorSettings, colors, isSyncedFocusTime) || defaultColors // default to light blue
      );
    },

    // ***************
    // merges remote settings, if any, with defaults
    // ***************
    getMergedDefaultSet: <
      ECC extends IEventCategoryColoring,
      ECS extends IEventColoringSettings<ECC>
    >(
      ecs?: IEventColoringSettingsErrorable<ECC, ECS>,
    ) => {
      return defaultSet.map((defaultSetting) => {
        let googleColorKey = defaultSetting.googleColorKey;
        let active = defaultSetting.active;
        if (!!ecs && ecs.__typename === "EventColoringSettings") {
          const remoteSetting = getSettingByCategory(ecs, defaultSetting.eventCategory);
          googleColorKey =
            (remoteSetting && remoteSetting.active && remoteSetting.googleColorKey) ||
            defaultSetting.googleColorKey;
          active =
            remoteSetting && remoteSetting.active !== undefined
              ? remoteSetting.active
              : defaultSetting.active;
        }
        const label = getCategoryLabels(defaultSetting.eventCategory).label;
        const color = calendarColors[googleColorKey].foreground;
        return { color, label, active };
      });
    },

    // ***************
    // Merges color settings with the defaults, returning an object that matches the schema settings, differing from above method.
    // ***************
    getMergedDefaultSettings: <
      ECC extends IEventCategoryColoring,
      ECS extends IEventColoringSettings<ECC>
    >(
      eventColorSettings?: IEventColoringSettingsErrorable<ECC, ECS>,
      active = true,
    ) => {
      if (!eventColorSettings || eventColorSettings.__typename === "GraphEntityError") {
        return;
      }

      const clonedEventColorSettings = cloneDeep(eventColorSettings);

      clonedEventColorSettings.eventCategoryColorings = filterAndSortCategories(
        clonedEventColorSettings,
      ).map((ecc) => {
        const maybeDefaultSetting = defaultSet.find((ds) => ds.eventCategory === ecc.eventCategory);

        return {
          ...ecc,
          googleColorKey:
            ecc.googleColorKey || (maybeDefaultSetting && maybeDefaultSetting.googleColorKey) || "",
          active,
        };
      });

      return clonedEventColorSettings;
    },

    // ***************
    // resets event color-coding settings to defaults
    // ***************
    resetEventColoringSettings: <
      ECC extends IEventCategoryColoring,
      ECS extends IEventColoringSettings<ECC>
    >(
      eventColoringSettings?: IEventColoringSettingsErrorable<ECC, ECS>,
    ) => {
      return filterAndSortCategories(eventColoringSettings).map((ecc) => {
        const maybeDefaultSetting = defaultSet.find((ds) => ds.eventCategory === ecc.eventCategory);
        return {
          eventCategory: ecc.eventCategory,
          active: ecc.active,
          googleColorKey: maybeDefaultSetting ? maybeDefaultSetting.googleColorKey : undefined,
          isUserSet: ecc.isUserSet,
        };
      });
    },
  };
};

export const getCalendarColors = (ecosystem: EcosystemEnum) => {
  switch (ecosystem) {
    case EcosystemEnum.Microsoft:
      return m365CalendarColors;
    case EcosystemEnum.Google:
    default:
      return googleCalendarColors;
  }
};

export const getCWCalendarColors = (ecosystem: EcosystemEnum) => {
  switch (ecosystem) {
    case EcosystemEnum.Microsoft:
      return cwCalendarColors;
    case EcosystemEnum.Google:
    default:
      return googleCalendarColorsForClockwiseCalendar;
  }
};

function getColorsFromSetting(
  eventCategoryColoring: IEventCategoryColoring,
  calendarColors: ICalendarColorsMap,
) {
  return (
    (eventCategoryColoring.active &&
      eventCategoryColoring.googleColorKey &&
      calendarColors[eventCategoryColoring.googleColorKey]) ||
    null
  );
}

function getColors<ECC extends IEventCategoryColoring>(
  selectedEventCategory: string,
  eventColoringSettings: IEventColoringSettings<ECC>,
  calendarColors: ICalendarColorsMap,
  isSyncedFocusTime?: boolean,
) {
  const { eventCategoryColorings } = eventColoringSettings;

  if (!eventCategoryColorings) {
    return null;
  }

  const selectedSetting = eventCategoryColorings.find(
    (ecc) => ecc.eventCategory === selectedEventCategory,
  );
  if (!selectedSetting) {
    return null;
  }
  // for focus time smart holds, if user has not ever set color, use the default color!
  if (isSyncedFocusTime && !selectedSetting.isUserSet) {
    return defaultCWCalFocusTimeColor;
  }
  return getColorsFromSetting(selectedSetting, calendarColors);
}

function getSettingByCategory<ECC extends IEventCategoryColoring>(
  eventColoringSettings: IEventColoringSettings<ECC>,
  category: EventColorCategory,
) {
  return eventColoringSettings.eventCategoryColorings?.find(
    (ecc) => ecc.eventCategory === category,
  );
}

//////////////////////
// Function
//////////////////////
export function isValidCategory(eventCategory: string) {
  return !!Object.values(EventColorCategory).includes(eventCategory as EventColorCategory);
}

export function filterAndSortCategories<ECC extends IEventCategoryColoring>(
  eventColoringSettings?: IGraphEntityError | IEventColoringSettings<ECC>,
) {
  if (
    !eventColoringSettings ||
    eventColoringSettings.__typename === "GraphEntityError" ||
    !eventColoringSettings.eventCategoryColorings
  ) {
    return [];
  }
  return eventColoringSettings.eventCategoryColorings
    .map((ecc) => {
      const sortValue = EventColorCategorySort[ecc.eventCategory];
      const valid = !!sortValue && isValidCategory(ecc.eventCategory);
      return {
        sortValue,
        valid,
        ecc,
      };
    })
    .filter((eccObj) => {
      return eccObj.valid;
    })
    .sort((a, b) => a.sortValue - b.sortValue)
    .map((eccObj) => eccObj.ecc);
}

export function getCategoryLabels(
  eventCategory: string,
): { label: string; description: React.ReactNode } {
  switch (eventCategory) {
    case EventColorCategory.AdHoc:
      return {
        label: "Ad-hoc",
        description: "Non-recurring meetings that pop-up in the course of a week",
      };
    case EventColorCategory.External:
      return {
        label: "External attendees",
        description: "Any meeting with one or more external attendees",
      };
    case EventColorCategory.FocusTime:
      return {
        label: "Focus Time",
        description: "Calendar blocks that are for Focus Time",
      };
    case EventColorCategory.IndividualHold:
      return {
        label: "Hold",
        description: "Calendar events that look like non-meeting holds",
      };
    case EventColorCategory.OneOnOne:
      return {
        label: "One-on-one",
        description: "Meetings with you and one other internal attendee",
      };
    case EventColorCategory.PaceSetting:
      return {
        label: "Team sync",
        description: "All-hands, project syncs, etc.",
      };
    case EventColorCategory.OutOfOffice:
      return {
        label: "Out of office",
        description: "Calendar blocks for time out of office",
      };
    case EventColorCategory.Holiday:
      return {
        label: "Holiday",
        description:
          "Company or team holidays automatically recognized by Clockwise, and sets attendees as OOO on those days.",
      };
    case EventColorCategory.Other:
    default:
      return {
        label: "Other",
        description: "Everything else",
      };
  }
}

export function getEventCategory<E extends IEventWithAnnotatedEvents>(event: E, userId: string) {
  const colorState = getUserTagValueOffEvent(event, Tags.EventColoringCategory, userId);

  if (colorState) {
    return colorState.value as EventColorCategory;
  } else {
    return EventColorCategory.Other;
  }
}
