import { errorActions } from "../actions/error.actions";

export interface IErrorState {
  message?: string;
}

interface IErrorAction {
  type: string;
  message: string;
}

export const initialErrorState: IErrorState = {};

export function errorReducer(state: IErrorState = initialErrorState, action: IErrorAction) {
  switch (action.type) {
    case errorActions.SET_ERROR:
      return Object.assign({}, state, { message: action.message });
    default:
      return state;
  }
}
