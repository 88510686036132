import { jwt } from "#webapp/src/state/local-storage";
import { getCurrentEnvironment } from "#webapp/src/state/relay-environment";
import { doUserLogout } from "#webapp/src/util/auth.util";
import { ApolloError, useMutation } from "@apollo/client";
import { isImpersonated } from "@clockwise/client-commons/src/util/jwt";
import { LogoutUserDocument } from "@clockwise/web-commons/src/mutations/__generated__/LogoutUser.generated";
import { TrackingEvents, track } from "@clockwise/web-commons/src/util/analytics.util";
import { useCallback } from "react";
import { commitLocalUpdate } from "react-relay";
import { modernCache } from "../../network/modern-network-layer";

type Props = {
  clientMutationId?: string;
  onError?: (error: ApolloError) => void;
};

type Result = [
  () => Promise<void>,
  {
    loading: boolean;
    error: ApolloError | undefined;
  },
];

// Update relay store to reflect the user being logged out
const notifyRelay = () => {
  const relayEnv = getCurrentEnvironment();
  commitLocalUpdate(relayEnv, (store) => {
    const viewer = store.getRoot()?.getLinkedRecord("viewer");
    viewer?.setValue(null, "user");
  });
  modernCache?.clear();
};

export function useLogout({ clientMutationId, onError }: Props = {}): Result {
  const [logout, { loading, error }] = useMutation(LogoutUserDocument, {
    variables: { clientMutationId },
    onError,
  });

  const onLogout = useCallback(async () => {
    track(TrackingEvents.AUTH.BEGIN_LOGOUT);
    if (isImpersonated(jwt.get())) {
      doUserLogout();
      notifyRelay();
    } else {
      await logout();
      doUserLogout();
      notifyRelay();
    }
  }, [logout]);

  return [onLogout, { loading, error }];
}
