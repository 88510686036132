import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const ArrowBack = (args: SvgIconProps) => (
  <SvgIcon {...args}>
    <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0723 3.86998L16.2923 2.09998L6.40234 12L16.3023 21.9L18.0723 20.13L9.94234 12L18.0723 3.86998Z"
        fill="#566D7F"
      />
    </svg>
  </SvgIcon>
);
