import * as ISchema from "#webapp/src/__schema__";
import { TeamSettingsSearch } from "#webapp/src/components/teams-settings/team-settings-search";
import React from "react";
import { useMonetization } from "../../../hooks/useMonetization";
import { TeamNameCreateInput } from "../../team-name-create-input";
import { JoinMode, TeamsFormMode } from "../TeamJoinCreateTypes";

type TeamCreateProps = {
  org: ISchema.IOrg;
  mode: TeamsFormMode;
  joinMode: JoinMode;
  initialTeamNeam?: string;
  onCheckTeammate: (person: ISchema.IOrgPerson, checked: boolean) => void;
  onChangeTeamName: (value: string, valid: boolean) => void;
  onInitializeSearch: ({
    nDisplayedInviteSuggestions,
  }: {
    nDisplayedInviteSuggestions: number;
  }) => void;
};

const EMPTY_ARRAY: [] = [];
const EMPTY_OBJECT = {};

export const TeamCreate = ({
  org,
  mode,
  joinMode,
  initialTeamNeam,
  onCheckTeammate,
  onChangeTeamName,
  onInitializeSearch,
}: TeamCreateProps) => {
  const { userIsOnActiveTrial } = useMonetization();

  const teamNameHeader =
    mode === "onboarding-manager" ? "Team name" : "What's the name of your team?";

  const renderCreateTeamHeader = () => {
    if (mode === "onboarding-manager") {
      return "Set up your team to make time together";
    } else if (joinMode === "off") {
      if (mode == "onboarding" && userIsOnActiveTrial) {
        return "Invite your teammates and trial together";
      }
      return "Create a new team";
    }
    return "What's the name of your team?";
  };

  const getDescriptionText = () => {
    if (mode === "onboarding-manager") {
      return "The best way to work together is for our schedules to do the same. Bring your team onboard so that Clockwise can make time for everyone.";
    } else if (mode === "onboarding" && userIsOnActiveTrial) {
      return "Clockwise creates most value when used as a team. Bring your team onboard so that Clockwise can make time for everyone!";
    }
    return "This could be the team you most closely identify with, the team you spend the most time with, or the team that is most important to your work.";
  };

  const descriptionText = getDescriptionText();

  return (
    <>
      <div className="cw-px-4">
        <div>
          <div className="cw-heading-2xl cw-mb-5">{renderCreateTeamHeader()}</div>
          <div className="cw-body-lg cw-mb-6">{descriptionText}</div>
        </div>
        <div className="cw-flex cw-flex-col">
          {joinMode === "off" ? (
            <div className="cw-heading-lg cw-mb-4">{teamNameHeader}</div>
          ) : null}
          <TeamNameCreateInput
            orgId={org.id}
            onChange={onChangeTeamName}
            initialTeamName={initialTeamNeam || ""}
          />
        </div>
        <div className="cw-heading-lg cw-mt-6">Invite your teammates</div>
      </div>
      <TeamSettingsSearch
        mode="create"
        containerClassName="cw-p-0"
        org={org}
        onInit={onInitializeSearch}
        team={null}
        onCheck={onCheckTeammate}
        defaultMembers={EMPTY_ARRAY}
        invitedMembers={EMPTY_OBJECT}
      />
    </>
  );
};
