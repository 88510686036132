import { CwIdProps } from "@clockwise/design-system/src/types/cw-id";
import { animated, config, useSpring } from "@react-spring/web";
import classNames from "classnames";
import React, { KeyboardEventHandler, MouseEventHandler, ReactNode } from "react";

interface Props extends CwIdProps {
  content?: ReactNode;
  subContent?: ReactNode;
  onClick?: MouseEventHandler;
  className?: string;
  scaleOnHover?: boolean;
  onSpaceOrEnter?: KeyboardEventHandler;
  tabIndex?: number;
  "aria-label"?: string;
}

export const CenteredCard = ({
  content,
  subContent,
  onClick,
  onSpaceOrEnter,
  className,
  scaleOnHover = true,
  tabIndex,
  "cw-id": cwId,
  "aria-label": ariaLabel,
}: Props) => {
  const [hovering, setHovering] = React.useState(false);

  const [{ scale }] = useSpring(
    {
      scale: hovering ? 1.065 : 1.0,
      config: { ...config.stiff, delay: 0 },
    },
    [hovering],
  );

  return (
    <animated.div
      style={{
        scale,
      }}
      aria-label={ariaLabel}
      cw-id={cwId ?? "centered-card"}
      tabIndex={tabIndex}
      onClick={onClick}
      onKeyDown={onSpaceOrEnter}
      onMouseEnter={scaleOnHover ? () => setHovering(true) : undefined}
      onMouseLeave={scaleOnHover ? () => setHovering(false) : undefined}
      className={classNames(
        className,
        "cw-flex cw-flex-col cw-items-center cw-justify-center cw-relative cw-overflow-hidden cw-select-none",
        "cw-mt-2 cw-px-4 cw-py-3 cw-bg-default cw-rounded-lg cw-shadow-none cw-border cw-border-solid",
        {
          "cw-border-default": !onClick,
          "cw-cursor-pointer hover:cw-bg-default-hover active:cw-bg-default-pressed": onClick,
          "cw-border-interactable hover:cw-border-interactable-hover active:cw-border-interactable-pressed": onClick,
        },
      )}
    >
      {content}
      {subContent}
    </animated.div>
  );
};
