import { useQuery } from "@apollo/client";
import { getPreviousWeekday } from "@clockwise/web-commons/src/util/getPreviousWeekday";
import { isEqual, noop } from "lodash";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { ModifiedPlannerEventCardDiff, PlannerEventCardsByDay } from "../../web-app-calendar/types";
import {
  CalendarProposalDocument,
  CalendarProposalQuery,
} from "../apollo/__generated__/CalendarProposal.generated";

import { useCallback } from "react";
import {
  isScheduleByMovingProposal,
  parseCalendarProposalGQL,
  parseTradeoffsFromCalendarProposalGQL,
} from "../util/parseCalendarProposalGQL";

export const useProposalEventCards = (date: string) => {
  // TODO: useProposal hook instead of fetching here
  const [cardsByDay, setCardsByDay] = React.useState<PlannerEventCardsByDay>({});
  const [modifiedCardDiffs, setModifiedCardDiffs] = React.useState<ModifiedPlannerEventCardDiff[]>(
    [],
  );

  const [weekStartDate, setWeekStartDate] = React.useState<string>("");

  const newWeekStartDate = getPreviousWeekday(DateTime.fromISO(date), "sunday").toISODate();
  if (!isEqual(newWeekStartDate, weekStartDate)) {
    setWeekStartDate(newWeekStartDate);
  }

  const parseProposedTimes = useCallback((data: CalendarProposalQuery) => {
    const newEventsByDay = parseCalendarProposalGQL(data).extract();
    if (newEventsByDay) {
      setCardsByDay({ ...newEventsByDay });
    }
    const newModifiedCardDiffs = parseTradeoffsFromCalendarProposalGQL(data).extract();
    if (newModifiedCardDiffs) {
      setModifiedCardDiffs([...newModifiedCardDiffs]);
    }
  }, []);

  const { loading, error, data } = useQuery(CalendarProposalDocument);

  useEffect(() => {
    if (!data) return;

    parseProposedTimes(data);
  }, [JSON.stringify(data)]);

  const refetchAndstartPolling = noop;
  const stopPolling = noop;
  const refetch = noop;
  const prefetch = noop;

  return {
    cardsByDay,
    modifiedCardDiffs,
    loading,
    error,
    refetch,
    prefetch,
    startPolling: refetchAndstartPolling,
    stopPolling,
    isScheduleByMoving: isScheduleByMovingProposal(data),
  };
};
