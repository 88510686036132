import { Button, Modal, TextArea, TextField } from "@clockwise/design-system";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { isValidEmailFormat } from "@clockwise/client-commons/src/util/email";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { EcosystemEnum } from "@clockwise/schema";
import { AttendeeQuestionTypeEnum } from "@clockwise/schema/v2";
import { compact, uniq } from "lodash";
import { Interval } from "luxon";
import pluralize from "pluralize";
import { asMinutes } from "../../util/date.util";
import { useEcosystem } from "../../util/ecosystem";
import {
  hasResetSchedulingLinkBookedEmail,
  orgPrimaryEmail,
  schedulingLinkBookedEmail,
  schedulingLinkBookedName,
} from "../../util/local-storage";
import { Notification } from "../Notification";
import { ConfirmationType } from "../scheduling-confirmation";
import { EventSummary } from "../scheduling-confirmation/event-summary";
import { AttendeeQuestion, AttendeeResponseToQuestion, Booking } from "../scheduling-link";
import {
  AdditionalAttendees,
  MAX_ADDITIONAL_ATTENDEES,
  useAdditionalAttendees,
} from "./additional-attendees";

type ReschedulePayload = {
  action: "reschedule";
  reason: string;
};

type BookPayload = {
  action: "book";
  attendeeName: string;
  attendeeEmailAddress: string;
  attendeeResponsesToQuestions: AttendeeResponseToQuestion[];
  additionalAttendeeEmails: string[];
};

type AttendeeResponseEvent =
  | React.ChangeEvent<HTMLInputElement>
  | React.FormEvent<HTMLTextAreaElement>;

const sanitizeText = (e: AttendeeResponseEvent, maxLength: number) => {
  const value = e.currentTarget.value;

  if (value?.length > maxLength) {
    return value.slice(0, maxLength);
  }

  return value;
};

const sanitzeAttendeeResponse = (e: AttendeeResponseEvent, type: AttendeeQuestionTypeEnum) => {
  if (type === AttendeeQuestionTypeEnum.PhoneNumber) {
    return sanitizeText(e, 50);
  }

  if (type === AttendeeQuestionTypeEnum.ShortText) {
    return sanitizeText(e, 200);
  }

  if (type === AttendeeQuestionTypeEnum.LongText) {
    return sanitizeText(e, 500);
  }

  return e.currentTarget.value;
};

export type ConfirmPayload = BookPayload | ReschedulePayload;

export type BookingModalProps = {
  meetingSettingsId: string;
  title: string;
  duration: number;
  existingBooking?: Booking | null;
  slotTimeRange: Interval;
  timeZone: string;
  onConfirm: (payload: ConfirmPayload, selectedTime: Interval) => void;
  onClose: () => void;
  loading: boolean;
  errorMsg?: string;
  timeTaken: boolean;
  fullscreen: boolean;
  attendeeCalendarIds: string[];
  attendeeQuestions: AttendeeQuestion[];
  allowAdditionalAttendees: boolean;
  initialName?: string;
  initialEmail?: string;
};

const getUserEmail = (paramEmail: string, ecosystem: EcosystemEnum): string => {
  // Deliberately use ?? and then || here. If a user sees their orgPrimaryEmail populated
  // but then they clear it, don't recommend it again. This works because by deleting it from the textfield,
  // it gets set to "" in local storage, and with this ?? it will still get rendered as "" instead of popping
  // over to orgPrimaryEmail.
  if (ecosystem === EcosystemEnum.Google) {
    return schedulingLinkBookedEmail.get() ?? (paramEmail || orgPrimaryEmail.get() || "");
  } else {
    return schedulingLinkBookedEmail.get() ?? (paramEmail || "");
  }
};

type AttendeedResponseElementProps = {
  label: string;
  required: boolean;
  error: boolean;
  value: string;
  onChange: (e: AttendeeResponseEvent) => void;
};

const ATTENDEE_QUESTION_RESPONSE_ELEMENT = {
  PhoneNumber: ({ label, required, error, value, onChange }: AttendeedResponseElementProps) => (
    <TextField
      type="tel"
      label={label}
      value={value}
      error={error}
      aria-required={required}
      required={required}
      onChange={onChange}
      fullWidth
    />
  ),
  ShortText: ({ label, required, error, value, onChange }: AttendeedResponseElementProps) => (
    <TextField
      label={label}
      value={value}
      error={error}
      onChange={onChange}
      required={required}
      aria-required={required}
      fullWidth
    />
  ),
  LongText: ({ label, required, error, value, onChange }: AttendeedResponseElementProps) => (
    <TextArea
      minRows={3}
      label={label}
      value={value}
      error={error}
      onChange={onChange}
      required={required}
      aria-required={required}
      fullWidth
    />
  ),
};

export const BookingModal = ({
  title,
  duration,
  existingBooking,
  slotTimeRange,
  timeZone,
  onConfirm,
  onClose,
  loading,
  errorMsg,
  fullscreen,
  attendeeCalendarIds,
  attendeeQuestions,
  timeTaken = false,
  allowAdditionalAttendees = false,
  initialName = "",
  initialEmail = "",
}: BookingModalProps) => {
  const ecosystem = useEcosystem();
  const [fullName, setFullName] = useState(schedulingLinkBookedName.get() || initialName || "");
  const [email, setEmail] = useState(getUserEmail(initialEmail, ecosystem));
  const [reason, setReason] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (ecosystem === EcosystemEnum.Microsoft && !hasResetSchedulingLinkBookedEmail.get()) {
      setShowErrors(false);
      setEmail("");

      schedulingLinkBookedEmail.remove();
      hasResetSchedulingLinkBookedEmail.set(true);
    }
  }, []);

  const [attendeeResponsesToQuestions, setAttendeeResponsesToQuestions] = React.useState<
    AttendeeResponseToQuestion[]
  >(attendeeQuestions.map(({ question }) => ({ question, response: "" })));

  const {
    numInvalidAttendeeEmail,
    numAdditionalAttendees,
    additionalAttendees,
    setAdditionalAttendees,
  } = useAdditionalAttendees();

  const onConfirmBooking = () => {
    const hasUnasweredQuestion = attendeeResponsesToQuestions.some(
      (q) =>
        attendeeQuestions.find((aq) => aq.question === q.question)?.required &&
        q.response.length === 0,
    );

    if (
      !fullName ||
      !isValidEmailFormat(email) ||
      hasUnasweredQuestion ||
      numInvalidAttendeeEmail > 0
    ) {
      setShowErrors(true);
    } else {
      onConfirm(
        {
          action: "book",
          attendeeName: fullName.trim(),
          attendeeEmailAddress: email,
          attendeeResponsesToQuestions: attendeeResponsesToQuestions,
          additionalAttendeeEmails: uniq(compact(additionalAttendees.map((a) => a.email))),
        },
        slotTimeRange,
      );
    }
  };

  const onConfirmReschedule = () => {
    onConfirm({ action: "reschedule", reason }, slotTimeRange);
  };

  const onChangeReason = (e: React.ChangeEvent<HTMLInputElement>) => setReason(e.target.value);
  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fullName = e.target.value;
    if (fullName) {
      schedulingLinkBookedName.set(fullName);
    } else {
      schedulingLinkBookedName.remove();
    }

    setShowErrors(false);
    setFullName(fullName);
  };
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value.trim();
    if (email) {
      schedulingLinkBookedEmail.set(email);
    } else {
      schedulingLinkBookedEmail.remove();
    }

    setShowErrors(false);
    setEmail(email);
  };

  const onChangeAttendeeResponse = useCallback(
    (e: AttendeeResponseEvent, { type, question }: AttendeeQuestion) => {
      setShowErrors(false);
      const newAttendeeResponsesToQuestions = [...attendeeResponsesToQuestions];
      const existingRecordIndex = newAttendeeResponsesToQuestions.findIndex(
        (rec) => rec.question === question,
      );

      if (existingRecordIndex === -1) {
        return;
      }

      newAttendeeResponsesToQuestions.splice(existingRecordIndex, 1, {
        question,
        response: sanitzeAttendeeResponse(e, type),
      });
      setAttendeeResponsesToQuestions(newAttendeeResponsesToQuestions);
    },
    [attendeeResponsesToQuestions],
  );

  const anyOptionalQuestions = attendeeQuestions.some((q) => !q.required);

  const attendeeQuestionResponseElements = useMemo(() => {
    return attendeeQuestions.map((attendeeQuestion, i) => {
      const { required, type, question } = attendeeQuestion;
      const label = required && anyOptionalQuestions ? `*${question}` : `${question}`;
      const response = attendeeResponsesToQuestions.find((res) => res.question === question)
        ?.response;

      if (response === undefined) {
        return null; // Shouldn't happen, but need because of the find.
      }

      return (
        <div key={`${type}-${i}`}>
          {ATTENDEE_QUESTION_RESPONSE_ELEMENT[type]({
            label,
            value: response,
            required,
            error: showErrors && required && !response,
            onChange: (e: AttendeeResponseEvent) => onChangeAttendeeResponse(e, attendeeQuestion),
          })}
        </div>
      );
    });
  }, [
    anyOptionalQuestions,
    attendeeQuestions,
    attendeeResponsesToQuestions,
    onChangeAttendeeResponse,
    showErrors,
  ]);

  const bookForm = (
    <>
      <div className="cw-flex cw-flex-col cw-gap-4 cw-mt-3">
        <TextField
          label={`${anyOptionalQuestions ? "*" : ""}Your full name`}
          error={showErrors && !fullName}
          placeholder="Enter your name"
          value={fullName}
          onChange={onChangeName}
          cw-id="booking-modal-name-field"
          fullWidth
        />
        <TextField
          label={`${anyOptionalQuestions ? "*" : ""}Your email`}
          type="email"
          error={showErrors && (!email || !isValidEmailFormat(email))}
          placeholder="Enter your email"
          value={email}
          onChange={onChangeEmail}
          cw-id="booking-modal-email-field"
          fullWidth
        />
        {allowAdditionalAttendees && (
          <AdditionalAttendees
            additionalAttendees={additionalAttendees}
            setAdditionalAttendees={setAdditionalAttendees}
            setShowErrors={setShowErrors}
            showErrors={showErrors}
          />
        )}
        {attendeeQuestionResponseElements}
      </div>
      <div className="cw-flex cw-justify-end cw-items-center cw-mt-8 cw-mb-2">
        {errorMsg && (
          <span className="cw-body-base cw-text-destructive cw-pr-4" cw-id="booking-error-message">
            {errorMsg}
          </span>
        )}
        {numAdditionalAttendees > MAX_ADDITIONAL_ATTENDEES && (
          <span className="cw-body-base cw-text-destructive cw-pr-4">
            Max of {MAX_ADDITIONAL_ATTENDEES} additional guests
          </span>
        )}
        {!errorMsg && showErrors && (!isValidEmailFormat(email) || !!numInvalidAttendeeEmail) && (
          <span className="cw-body-base cw-text-destructive cw-pr-4">
            {pluralize(
              "Invalid email address",
              numInvalidAttendeeEmail + (isValidEmailFormat(email) ? 0 : 1),
            )}
          </span>
        )}
        {loading && <Loader size={18} sentiment="positive" className="cw-mr-4" />}
        <Button
          onClick={onConfirmBooking}
          sentiment="positive"
          disabled={loading || numAdditionalAttendees > MAX_ADDITIONAL_ATTENDEES}
          size="large"
          aria-label={timeTaken ? "back to all times" : "book this time"}
          cw-id="booking-modal-book-button"
        >
          {timeTaken ? "Back" : "Book it"}
        </Button>
      </div>
    </>
  );

  const rescheduleForm = (
    <>
      <div className="cw-mt-3">
        <TextField
          label="Reason for change"
          value={reason}
          onChange={onChangeReason}
          cw-id="booking-modal-reason-field"
          fullWidth
        />
      </div>
      <div className="cw-flex cw-justify-end cw-items-center cw-mt-8 cw-mb-2">
        {errorMsg && <span className="cw-body-base cw-text-destructive cw-pr-4">{errorMsg}</span>}
        {loading && <Loader size={18} sentiment="positive" className="cw-mr-4" />}
        <Button
          onClick={onConfirmReschedule}
          sentiment="positive"
          disabled={loading}
          size="large"
          aria-label={timeTaken ? "back to all times" : "book this time"}
          cw-id="booking-modal-book-button"
        >
          {timeTaken ? "Back" : "Book it"}
        </Button>
      </div>
    </>
  );

  const meetingDetailsChanged =
    !!existingBooking &&
    (existingBooking.name !== title || asMinutes(existingBooking) !== duration);

  return (
    <Modal
      title="Book this time"
      opened
      onClose={() => {
        onClose();
        setShowErrors(false);
      }}
      fullScreen={fullscreen}
      returnFocus={false}
      lockScroll={false}
      cw-id="link-booking-modal"
    >
      <div className="cw-flex cw-flex-col cw-gap-4 cw-mb-3">
        <EventSummary
          title={title}
          duration={duration}
          prevDateTime={
            existingBooking ? Interval.fromISO(existingBooking.timeSlot).start.toISO() : null
          }
          dateTime={slotTimeRange.start.toISO()}
          attendees={existingBooking ? attendeeCalendarIds : undefined}
          compact
          timeZone={timeZone}
          variant={ConfirmationType.Book}
        />
        {meetingDetailsChanged && (
          <Notification sentiment="info">
            This booking link was edited. Some of the meeting details may have changed.
          </Notification>
        )}
      </div>
      {existingBooking ? rescheduleForm : bookForm}
    </Modal>
  );
};
