import { graphql } from "react-relay";

export const enableDataSourceSyncMutation = graphql`
  mutation EnableDataSourceSyncMutation($input: EnableDataSourceSyncInput!) {
    enableDataSourceSync(input: $input) {
      dataSource {
        id
        name
        email
        state
        type
        syncTargets {
          calendarId
          emailAddress
        }
      }
    }
  }
`;
