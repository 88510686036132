export type TeamNameResponse = {
  __typename: "TeamQueryResult";
  query: string;
  teams: {
    __typename: "Team";
    teamName: string;
  }[];
};

export type NameStatus = "valid" | "error" | "loading" | "unset";

export const TEAM_NAME_MAX_LENGTH = 240;

export const confirmIfNameIsValid = (
  response: TeamNameResponse | null,
  inputText: string,
  initialTeamName: string,
) => {
  if (!response) return null;
  const teams = response?.teams || [];
  const query = response?.query?.trim() || "";
  const queryMatchesSearch = query === inputText.trim();
  if (queryMatchesSearch && teams) {
    const isValid =
      query === initialTeamName ||
      (teams.reduce(
        (prev, curr) => prev && curr.teamName.toLowerCase() !== query.toLowerCase().trim(),
        true,
      ) &&
        query.length < TEAM_NAME_MAX_LENGTH);

    const newHelperText = isValid
      ? ""
      : query.length >= TEAM_NAME_MAX_LENGTH
      ? "This team name is too long. Please shorten the name."
      : "This team name has been taken. Please choose another.";

    const newStatus: NameStatus = !query ? "unset" : isValid ? "valid" : "error";

    return { newStatus, newHelperText, isValid, query };
  } else {
    return null;
  }
};
