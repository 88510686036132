export const bg_calOwn = "#E0EAFF";
export const bg_calOwn_Emphasis = "#444CE7";
export const bg_calOwn_Hover = "#C7D7FE";
export const border_calOwn = "#444CE7";
export const fg_calOwn = "#3538CD";

export const bg_calDeemphasis = "#F7F7F7";
export const bg_calDeemphasis_Emphasis = "#F7F7F7";
export const bg_calDeemphasis_Hover = "#F7F7F7";
export const border_calDeemphasis = "#C3C3C3";
export const border_calTransparent = "#F1F1F1";
export const fg_calDeemphasis = "#BCBCBC";

export const bg_cal1 = "#f9dbe6";
export const bg_cal1_Emphasis = "#d81b60";
export const bg_cal1_Hover = "#f2b6cc";
export const border_cal1 = "#d81b60";
export const fg_cal1 = "#8b113e";

export const bg_cal2 = "#fbddc4";
export const bg_cal2_Emphasis = "#ef6c00";
export const bg_cal2_Hover = "#f7ba87";
export const border_cal2 = "#ef6c00";
export const fg_cal2 = "#743500";

export const bg_cal3 = "#eadfee";
export const bg_cal3_Emphasis = "#9e69af";
export const bg_cal3_Hover = "#d6bfdd";
export const border_cal3 = "#9e69af";
export const fg_cal3 = "#5a3b63";

export const bg_cal4 = "#f2e3a4";
export const bg_cal4_Emphasis = "#e4c441";
export const bg_cal4_Hover = "#e4c543";
export const border_cal4 = "#e4c441";
export const fg_cal4 = "#524617";

export const bg_cal6 = "#e3e7a4";
export const bg_cal6_Emphasis = "#c0ca33";
export const bg_cal6_Hover = "#c5ce44";
export const border_cal6 = "#c0ca33";
export const fg_cal6 = "#464a13";

export const bg_cal7 = "#e6dff3";
export const bg_cal7_Emphasis = "#b39ddb";
export const bg_cal7_Hover = "#cec0e8";
export const border_cal7 = "#b39ddb";
export const fg_cal7 = "#4c425d";

export const bg_cal8 = "#fbdfb3";
export const bg_cal8_Emphasis = "#f09300";
export const bg_cal8_Hover = "#f6bd62";
export const border_cal8 = "#f09300";
export const fg_cal8 = "#653e00";

export const bg_cal9 = "#e7e1df";
export const bg_cal9_Emphasis = "#795548";
export const bg_cal9_Hover = "#d0c4bf";
export const border_cal9 = "#795548";
export const fg_cal9 = "#5c4037";

export const bg_calAnnotation = "#ffe800";
export const border_calAnnotation = "#d9c601";
