import {
  fg_busy,
  fg_destructive_muted,
  fg_neutral_muted,
  fg_positive_muted,
} from "#clockwise/design-system/tokens";
import { Loader as MantineLoader } from "@mantine/core";
import React from "react";
export type { TooltipProps } from "@mantine/core";

type Size = "xs" | "sm" | "md" | "lg" | "xl" | number;
type Sentiment = "positive" | "neutral" | "negative" | "busy";

type Props = {
  size?: Size;
  sentiment?: Sentiment;
  className?: string;
};

export function Loader({ size = "md", sentiment = "neutral", className }: Props) {
  const getColorForSentiment = () => {
    switch (sentiment) {
      case "neutral":
        return fg_neutral_muted;
      case "negative":
        return fg_destructive_muted;
      case "busy":
        return fg_busy;
      case "positive":
      default:
        return fg_positive_muted;
    }
  };

  return (
    <MantineLoader color={getColorForSentiment()} size={size} className={className} type="oval" />
  );
}
