import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";

interface IProps {
  children: JSX.Element;
}

export const StripeLoader = (props: IProps) => {
  const [stripePromise, setStripePromise] = useState<any>(null);

  // ~-~-~-~-~-~-~-
  // Lifecycle
  // ~-~-~-~-~-~-~-
  useEffect(() => {
    // does script exist by id, check load status or does window.stripe exist?
    if (document.querySelector("#stripe-script")) {
      setStripePromise(loadStripe());
      return;
    }

    // if not, inject script
    injectStripeScript();
  }, []);

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  const injectStripeScript = () => {
    const stripeScript = document.createElement("script");

    stripeScript.onload = () => setStripePromise(loadStripe());
    stripeScript.src = "https://js.stripe.com/v3/";
    stripeScript.id = "stripe-script";

    document.getElementsByTagName("head")[0].appendChild(stripeScript);
  };

  const loadStripe = () => {
    return getEnvironment() === "production"
      ? (window as any).Stripe("pk_live_hPj7AL82UxlydpXsg8qWLPc400xCzMDO2N")
      : (window as any).Stripe("pk_test_QzPgG8k7FYWAOR2q9JphRrjY00E3AND4O6");
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  if (!stripePromise) {
    return (
      <Loader
        size="xl"
        sentiment="positive"
        className="cw-w-full cw-h-screen cw-flex cw-justify-center cw-items-center"
      />
    );
  }

  return <Elements stripe={stripePromise}>{props.children}</Elements>;
};
