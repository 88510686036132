import { graphql } from "react-relay";

// gql
import "#webapp/src/components/team-join-create/TeamJoinCreate.gql";

/////////
// GQL //
/////////

export const fragments = {
  org: graphql`
    fragment TeamJoinCreateDialogRenderer_org on Org {
      ...TeamJoinCreate_org
      id
      primaryOrgCalendar
    }
  `,
};

export const query = graphql`
  query TeamJoinCreateDialogRendererQuery($orgRelayId: ID!, $excludeMyTeams: Boolean) {
    viewer {
      ...TeamJoinCreate_viewer @arguments(orgRelayId: $orgRelayId, excludeMyTeams: $excludeMyTeams)
    }
  }
`;
