import { DateTime, Interval } from "luxon";

export const formattedDateTime = (
  startTime: DateTime,
  timeZone?: string,
  longDayWord?: boolean,
  withShortTimeZone?: boolean,
) => {
  const now = timeZone ? DateTime.now().setZone(timeZone) : DateTime.now();
  const startTimeInZone = timeZone ? startTime.setZone(timeZone) : startTime;

  const isToday = startTimeInZone.hasSame(now, "day");
  const isTomorrow = startTimeInZone.hasSame(now.plus({ days: 1 }), "day");
  const isThisWeek = startTime.hasSame(now.plus({ week: 1 }), "week");
  let date = longDayWord ? startTimeInZone.toFormat("cccc,") : startTimeInZone.toFormat("ccc,");
  if (isToday) {
    date = "Today,";
  } else if (isTomorrow) {
    date = longDayWord ? "Tomorrow," : "Tmw,";
  } else if (!isThisWeek) {
    date = longDayWord
      ? startTimeInZone.toFormat("cccc, LLL d")
      : startTimeInZone.toFormat("ccc, LLL d");
  }
  const time = startTimeInZone.toFormat("h:mma").toLocaleLowerCase();
  const baseTime = `${date} ${time}`;
  return baseTime + (withShortTimeZone ? ` (${startTimeInZone.toFormat("ZZZZ")})` : "");
};

export const formattedInterval = (interval: Interval, timeZone?: string) => {
  const endTime = interval.end;
  const endTimeInZone = timeZone ? endTime.setZone(timeZone) : endTime;
  const endTimeFormatted = endTimeInZone.toFormat("h:mma").toLocaleLowerCase();

  const startTimeFormatted = formattedDateTime(interval.start, timeZone);
  return `${startTimeFormatted} - ${endTimeFormatted}`;
};
