import {
  EXPERIMENT_FLAGS,
  ExperimentMappingType,
} from "@clockwise/client-commons/src/util/experiment";
import { reduce } from "lodash";
import React, { PropsWithChildren, createContext, useContext } from "react";

const INITIAL_STATE = reduce(
  EXPERIMENT_FLAGS,
  (state, flag) => {
    state[flag] = false;
    return state;
  },
  {} as ExperimentMappingType,
);

const ExperimentsContext = createContext<ExperimentMappingType>(INITIAL_STATE);

type Props = {
  experiments: ExperimentMappingType;
};

export function ExperimentsProvider({ children, experiments }: PropsWithChildren<Props>) {
  return <ExperimentsContext.Provider value={experiments}>{children}</ExperimentsContext.Provider>;
}

/**
 * @deprecated Configure experiments in LaunchDarkly and use `useFeatureFlag` instead.
 */
export function useExperiment(name: keyof ExperimentMappingType) {
  const experiments = useContext(ExperimentsContext);
  return !!experiments[name];
}
