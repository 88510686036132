import classNames from "classnames";
import React from "react";

interface IProps {
  children: React.ReactNode;
  isHeader: boolean;
  variant: "grey" | "green";
}
export const GridItem = ({ children, isHeader, variant }: IProps) => {
  return (
    <div
      className={classNames(
        "cw-h-full cw-flex-1 cw-w-2/5 cw-flex cw-items-center cw-justify-center cw-heading-sm cw-mx-2.5",
        { "cw-bg-neutral": variant === "grey", "cw-bg-brand": variant === "green" },
        { "cw-rounded-t-lg": isHeader },
      )}
    >
      {children}
    </div>
  );
};
