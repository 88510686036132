/**
 * @generated SignedSource<<9f62aed5e2133864ab21bd56bd4ac4d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubjectUrnTypeEnum = "Email" | "Integration" | "Org" | "Person" | "Phone" | "Services" | "Team" | "User" | "%future added value";
export type RemoveOrgTagsMutationInput = {
  clientMutationId?: string | null;
  eventParentRelayId: string;
  orgRelayId: string;
  tagNames: ReadonlyArray<string>;
};
export type RemoveUserTagsMutation$variables = {
  input: RemoveOrgTagsMutationInput;
};
export type RemoveUserTagsMutation$data = {
  readonly removeOrgTags: {
    readonly eventParent: {
      readonly events: ReadonlyArray<{
        readonly id: string;
        readonly annotatedEvent: {
          readonly id: string;
          readonly externalEventId: string | null;
          readonly userTags: ReadonlyArray<{
            readonly tag: string;
            readonly states: ReadonlyArray<{
              readonly value: string | null;
              readonly subjectType: SubjectUrnTypeEnum;
              readonly subjectValue: string;
              readonly lastModified: number;
              readonly active: boolean;
            }> | null;
          }> | null;
        } | null;
        readonly annotatedRecurringEvent: {
          readonly id: string;
          readonly externalEventId: string | null;
          readonly userTags: ReadonlyArray<{
            readonly tag: string;
            readonly states: ReadonlyArray<{
              readonly value: string | null;
              readonly subjectType: SubjectUrnTypeEnum;
              readonly subjectValue: string;
              readonly lastModified: number;
              readonly active: boolean;
            }> | null;
          }> | null;
        } | null;
      }>;
    } | null;
  } | null;
};
export type RemoveUserTagsMutation = {
  variables: RemoveUserTagsMutation$variables;
  response: RemoveUserTagsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "externalEventId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserTag",
    "kind": "LinkedField",
    "name": "userTags",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tag",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TagState",
        "kind": "LinkedField",
        "name": "states",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subjectType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subjectValue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastModified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Event",
  "kind": "LinkedField",
  "name": "events",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AnnotatedEvent",
      "kind": "LinkedField",
      "name": "annotatedEvent",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnnotatedEvent",
      "kind": "LinkedField",
      "name": "annotatedRecurringEvent",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveUserTagsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveOrgTagsMutationPayload",
        "kind": "LinkedField",
        "name": "removeOrgTags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventParent",
            "kind": "LinkedField",
            "name": "eventParent",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveUserTagsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveOrgTagsMutationPayload",
        "kind": "LinkedField",
        "name": "removeOrgTags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventParent",
            "kind": "LinkedField",
            "name": "eventParent",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "26cd36564557f8c39bfda64886a00393",
    "id": null,
    "metadata": {},
    "name": "RemoveUserTagsMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveUserTagsMutation(\n  $input: RemoveOrgTagsMutationInput!\n) {\n  removeOrgTags(input: $input) {\n    eventParent {\n      events {\n        id\n        annotatedEvent {\n          id\n          externalEventId\n          userTags {\n            tag\n            states {\n              value\n              subjectType\n              subjectValue\n              lastModified\n              active\n            }\n          }\n        }\n        annotatedRecurringEvent {\n          id\n          externalEventId\n          userTags {\n            tag\n            states {\n              value\n              subjectType\n              subjectValue\n              lastModified\n              active\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eed485258bc0fd8c45b3f9c679200b2e";

export default node;
