import { Tooltip } from "@clockwise/design-system";
import React from "react";

export const FormSubHeaderTooltip = ({
  children,
  tooltipText,
}: {
  children: React.ReactNode;
  tooltipText: string;
}) => {
  return (
    <Tooltip title={tooltipText} arrow placement="bottom">
      <div className="cw-flex cw-items-center cw-body-sm cw-w-fit cw-cursor-pointer cw-text-subtle cw-border-dashed cw-border-b cw-leading-4 hover:cw-text-brand">
        {children}
      </div>
    </Tooltip>
  );
};
