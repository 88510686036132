import { logger } from "#webapp/src/util/logger.util";
import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import {
  ConferencingType,
  FlexDetailsInput,
  ProposalState,
  TradeoffType,
} from "@clockwise/schema/v2";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import isEqual from "lodash/isEqual";
import { DateTime, Interval } from "luxon";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import invariant from "tiny-invariant";
import { useDebounceValue } from "usehooks-ts";
import { formattedDateTime } from "../web-app-calendar/calendar-popover/utils/formattedDateTime";
import {
  CreatePersistedProposalDocument,
  CreatePersistedProposalFromEventDocument,
  CurrentPersistedProposalDocument,
  UpdatePersistedProposalDocument,
} from "./apollo/__generated__/CreateProposal.v2.generated";
import { Attendee, EventPermissions } from "./CurrentProposalContext";

export type Props = {
  eventId?: string | null;
  proposalId: string | null;
  title: string;
  startTime: DateTime;
  endTime: DateTime;
  timeZone: string;
  allDay: boolean;
  initialAttendeeIds: string[];
  attendees: Attendee[];
  removedAttendees: Record<string, Attendee>;
  upsertedAttendees: Record<string, Attendee>;
  description: string;
  recurrenceRule: RecurrenceRule | null;
  conferenceType: ConferencingType | null;
  location: string | null;
  initialized: boolean;
  flexDetails: FlexDetailsInput;
  permissions?: EventPermissions;
  meta: {
    isDragging: boolean;
  };
  selectedTimeOption?: SuggestedTimeOption;
  executedTimeRanges?: Interval[];
  searchTimeRanges?: Interval[];
};

type AffectedAttendee = {
  person: {
    isMe: boolean;
    displayName: string;
    externalImageUrl: string | null;
    email: string;
  };
};

type TradeoffAttendee = {
  person: {
    isMe: boolean;
    email: string;
    givenName: string | null;
    familyName: string | null;
    externalImageUrl: string | null;
  };
};

type GQLTime =
  | {
      __typename: "DateRange";
      dateRange: string;
    }
  | {
      __typename: "DateTimeRange";
      dateTimeRange: string;
    }
  | null;

type Tradeoff = {
  title: string;
  attendees: TradeoffAttendee[];
  event: {
    externalEventId: string;
    title: string;
    dateOrTimeRange: GQLTime;
  } | null;
  updatedTime?: GQLTime;
  diffId?: string | null;
};

export type TradeoffBlock = {
  tradeoffs: Tradeoff[];
  affectedAttendees: AffectedAttendee[];
  tradeoffType: TradeoffType;
};

export type SuggestedTimeOption = {
  label: string;
  interval: Interval;
  tradeoffBlocks: TradeoffBlock[];
};

type PersistedProposalState = {
  id: string | null;
  status: ProposalState;
  tradeoffBlocks?: TradeoffBlock[];
  suggestedTimeOptions?: SuggestedTimeOption[];
  executedTimeRanges?: Interval[];
};

type PersistedProposalWriteObj = {
  clearPersistedProposal: () => void;
};

const ReadContext = createContext<PersistedProposalState>({
  id: null,
  status: ProposalState.LoadingSuggestions,
  tradeoffBlocks: undefined,
  suggestedTimeOptions: undefined,
});

const WriteContext = createContext<PersistedProposalWriteObj | null>(null);

export const PersistedProposalProvider = ({
  eventId,
  proposalId,
  description: inputDescription,
  title: inputTitle,
  location: inputLocation,
  attendees: inputAttendees,
  startTime,
  endTime,
  recurrenceRule,
  conferenceType,
  flexDetails,
  permissions,
  meta,
  searchTimeRanges,
  children,
}: PropsWithChildren<Props>) => {
  const [pollInterval, setPollInterval] = useState(0);
  const [description, setDescription] = useDebounceValue(inputDescription, 200);
  const [title, setTitle] = useDebounceValue(inputTitle, 200);
  const [location, setLocation] = useDebounceValue(inputLocation, 200);

  const [localProposalId, setLocalProposalId] = useState(proposalId);
  const [proposalStatus, setProposalStatus] = useState(ProposalState.LoadingSuggestions);
  const [tradeoffBlocks, setTradeoffBlocks] = useState<TradeoffBlock[] | undefined>(undefined);
  const [suggestedTimeOptions, setSuggestedTimeOptions] = useState<
    SuggestedTimeOption[] | undefined
  >(undefined);
  const [executedTimeRanges, setExecutedTimeRanges] = useState<Interval[] | undefined>(undefined);

  // If we're creating a proposal for a new event, we can always modify it
  const canModify = eventId ? permissions?.canModify ?? false : true;

  const previousProposalValues = useRef({
    eventId,
    canModify,
    inputAttendees,
    startTime,
    endTime,
    description,
    location,
    title,
    recurrenceRule,
    conferenceType,
    flexDetails,
    localProposalId,
    searchTimeRanges,
  });

  useEffect(() => {
    // Update the description, but with a debounce
    setDescription(inputDescription);
  }, [inputDescription, setDescription]);

  useEffect(() => {
    // Update the title, but with a debounce
    setTitle(inputTitle);
  }, [inputTitle, setTitle]);

  useEffect(() => {
    // Update the location, but with a debounce
    setLocation(inputLocation);
  }, [inputLocation, setLocation]);

  const [
    createProposal,
    { loading: createProposalLoading, error: createProposalError },
  ] = useGatewayMutation(CreatePersistedProposalDocument, {
    onCompleted(data) {
      if (data.createProposal?.id) {
        setLocalProposalId(data.createProposal.id);
        setProposalStatus(data.createProposal.state);
      }
    },
  });

  const [
    createProposalFromEvent,
    { loading: createProposalFromEventLoading, error: createProposalFromEventError },
  ] = useGatewayMutation(CreatePersistedProposalFromEventDocument, {
    onCompleted(data) {
      if (data.createProposalFromEvent?.id) {
        setLocalProposalId(data.createProposalFromEvent.id);
        setProposalStatus(data.createProposalFromEvent.state);
      }
    },
  });

  const [updateProposal] = useGatewayMutation(UpdatePersistedProposalDocument, {
    onCompleted(data) {
      if (data.updateProposal?.id) {
        const diff = data?.updateProposal?.diffBlocks[0]?.diffs[0];
        const tradeoffBlocks = diff?.__typename !== "OthersDiff" ? diff.tradeoffBlocks : undefined;
        setProposalStatus(data.updateProposal.state);
        setTradeoffBlocks(tradeoffBlocks);
      }
    },
  });

  const { data } = useGatewayQuery(CurrentPersistedProposalDocument, {
    variables: { id: localProposalId! },
    skip: !localProposalId,
    pollInterval,
  });

  const proposalState = data?.proposal?.state;

  useEffect(() => {
    if (data?.proposal?.id && data.proposal?.state !== ProposalState.LoadingSuggestions) {
      const diff = data.proposal.diffBlocks[0].diffs[0];
      const diffTradeoffBlocks =
        diff?.__typename !== "OthersDiff" ? diff.tradeoffBlocks : undefined;
      const receivedSchedulingOptions = data.proposal?.schedulingOptions ?? [];
      const timeOptions = receivedSchedulingOptions.reduce(
        (prev, { interval: intervalString, tradeoffBlocks }, idx) => {
          let interval = Interval.fromISO(intervalString, { zone: getRenderTimeZone() });
          // Temporary fix for A-847 - ignore first suggestion if its duration doesn't match the second.
          if (idx === 0) {
            const nextIntervalMins = Interval.fromISO(receivedSchedulingOptions[1].interval, {
              zone: getRenderTimeZone(),
            }).count("minutes");
            if (nextIntervalMins !== interval.count("minutes")) {
              interval = Interval.fromDateTimes(
                interval.start,
                interval.start.plus({ minutes: nextIntervalMins }),
              );
            }
          }
          prev.push({
            interval,
            tradeoffBlocks,
            label: formattedDateTime(interval.start, getRenderTimeZone()),
          });
          return prev;
        },
        [] as { interval: Interval; tradeoffBlocks: TradeoffBlock[]; label: string }[],
      );

      setProposalStatus(data.proposal.state);
      setTradeoffBlocks(diffTradeoffBlocks);
      setSuggestedTimeOptions(timeOptions);
      setExecutedTimeRanges(
        data.proposal.executedTimeRanges?.map((range) =>
          Interval.fromISO(range, { zone: getRenderTimeZone() }),
        ),
      );
    }
  }, [data]);

  // Poll rapidly when we're still loading suggestions.
  useEffect(() => {
    const pollInterval = proposalState === ProposalState.LoadingSuggestions ? 500 : 0;
    setPollInterval(pollInterval);
  }, [proposalState]);

  useEffect(() => {
    const timeRange = Interval.fromDateTimes(startTime, endTime).toISO();
    const attendees = inputAttendees.map(({ person, isOptional }) => ({
      email: person.email,
      optional: isOptional,
      isOrganizer: person.isMe,
    }));

    const hasChanged = !isEqual(previousProposalValues.current, {
      eventId,
      canModify,
      inputAttendees,
      startTime,
      endTime,
      description,
      location,
      title,
      recurrenceRule,
      conferenceType,
      flexDetails,
      localProposalId,
      searchTimeRanges,
    });

    // We can only make a proposal if we have attendees
    if (!attendees.length) {
      return;
    }

    // If we don't have permission to modify, don't do anything
    if (!canModify) {
      return;
    }

    // If we're still creating the proposal, don't do anything
    if (createProposalLoading || createProposalFromEventLoading) {
      return;
    }

    // Something failed when creating the proposal, so don't try again
    if (createProposalError || createProposalFromEventError) {
      logger.error("Failed to create proposal", {
        createProposalError,
        createProposalFromEventError,
      });
      return;
    }

    // If the user is dragging to create a new event, don't do anything
    if (meta.isDragging) {
      return;
    }

    // Don't create or update proposal when a user is selecting a suggestedtime
    const isUserSelectingASuggestedTime =
      (suggestedTimeOptions || []).filter((option) => {
        return option.interval.start === startTime && option.interval.end === endTime;
      }).length > 0;
    if (isUserSelectingASuggestedTime) {
      return;
    }

    if (!localProposalId && eventId) {
      void createProposalFromEvent({
        variables: {
          eventId,
          timeRange,
          timeZone: startTime.zone.name,
        },
      });
    } else if (!localProposalId) {
      void createProposal({
        variables: {
          attendees: attendees.map(({ email, optional, isOrganizer }) => ({
            email,
            optional: optional ?? false,
            isOrganizer,
          })),
          timeRange,
          timeZone: getRenderTimeZone(),
          title: title,
        },
      });
    } else if (hasChanged) {
      void updateProposal({
        variables: {
          id: localProposalId,
          title,
          timeRange,
          timeZone: startTime.zone.name,
          attendees: attendees.map(({ email, optional, isOrganizer }) => ({
            email,
            optional: optional ?? false,
            isOrganizer,
          })),
          description,
          recurrenceRule: recurrenceRule?.toString(),
          conferenceType,
          location,
          // NB: Flexibility on existing events auto-saves
          // so the proposal does not need to be updated with this info
          flexDetails: eventId ? null : flexDetails,
          searchTimeRanges: searchTimeRanges?.map((interval) => interval.toISO()),
        },
      });

      previousProposalValues.current = {
        eventId,
        canModify,
        inputAttendees,
        startTime,
        endTime,
        description,
        location,
        title,
        recurrenceRule,
        conferenceType,
        flexDetails,
        localProposalId,
        searchTimeRanges,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eventId,
    canModify,
    inputAttendees,
    startTime,
    endTime,
    description,
    location,
    title,
    recurrenceRule,
    conferenceType,
    flexDetails,
    localProposalId,
    createProposal,
    createProposalFromEvent,
    updateProposal,
    createProposalLoading,
    createProposalFromEventLoading,
    meta.isDragging,
    createProposalError,
    createProposalFromEventError,
    searchTimeRanges,
    suggestedTimeOptions,
  ]);

  const providerValue = useMemo(() => {
    return {
      id: localProposalId,
      status: proposalStatus,
      tradeoffBlocks,
      suggestedTimeOptions,
      executedTimeRanges,
    };
  }, [localProposalId, proposalStatus, tradeoffBlocks, suggestedTimeOptions, executedTimeRanges]);

  const handleClearPersistedProposal = () => {
    setLocalProposalId(null);
    setProposalStatus(ProposalState.LoadingSuggestions);
    setTradeoffBlocks(undefined);
    setSuggestedTimeOptions(undefined);
    setExecutedTimeRanges(undefined);
  };

  return (
    <WriteContext.Provider value={{ clearPersistedProposal: handleClearPersistedProposal }}>
      <ReadContext.Provider value={providerValue}>{children}</ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const usePersistedProposal = () => {
  return useContext(ReadContext);
};

export const useUpdatePersistedProposal = () => {
  const context = useContext(WriteContext);
  invariant(context, "useUpdatePersistedProposal must be within PersistedProposalProvider");
  return context;
};
