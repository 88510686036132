import { INavTabsAction, NavTabsAction } from "#webapp/src/state/actions/nav-tabs.actions";
// util
import {
  ChromeAppWrapperView,
  ChromeSite,
  getChromeSite,
} from "#webapp/src/util/chrome-extension.util";

export interface INavTab {
  view: ChromeAppWrapperView;
  label: string;
}

export interface INavTabsState {
  selectedTabView: ChromeAppWrapperView;
  tabs: INavTab[];
}

export function getInitialNavTabsState(chromeSite = getChromeSite()): INavTabsState {
  // selected
  const selectedTabView = ChromeAppWrapperView.TeamHealth;

  // tabs
  const tabs = [
    {
      view: ChromeAppWrapperView.TeamHealth,
      label: "Teammates",
    },
  ];

  if (chromeSite !== ChromeSite.Gcal) {
    tabs.unshift({
      view: ChromeAppWrapperView.Day,
      label: "Calendar",
    });
  }

  return {
    selectedTabView,
    tabs,
  };
}

export function navTabsReducer(
  state: INavTabsState = getInitialNavTabsState(),
  action: INavTabsAction,
) {
  switch (action.type) {
    case NavTabsAction.SetSelectedTabView:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
