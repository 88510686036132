// IMPORTS
// schema
import * as ISchema from "#webapp/src/__schema__";

import { modernCache } from "#webapp/src/network/modern-network-layer";
import { commitMutation } from "react-relay";
import { Environment } from "relay-runtime";
import { invitePersonsToTeamMutation } from "./InvitePersonsToTeam.gql"; // change

// TYPES
interface IInvitePersonsToTeamMutationResponse {
  invitePersonsToTeam: ISchema.IInvitePersonsToTeamPayload;
}

// MUTATION
export function invitePersonsToTeam(
  environment: Environment,
  input: ISchema.IInvitePersonsToTeamInput,
  onSuccess: (response?: ISchema.IInvitePersonsToTeamPayload) => void,
  onError: (error?: any) => void,
) {
  commitMutation(environment, {
    onError,
    mutation: invitePersonsToTeamMutation,
    variables: { input },
    onCompleted: (response: IInvitePersonsToTeamMutationResponse, errors?: Error[]) => {
      if (errors) {
        onError(errors[0]);
      } else {
        // if we're not in a modern environment, modernCache will be undefined
        if (modernCache) {
          modernCache.clear();
        }
        onSuccess(response.invitePersonsToTeam);
      }
    },
  });
}
