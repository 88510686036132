import { getPreviousWeekday } from "@clockwise/web-commons/src/util/getPreviousWeekday";
import { compact, keys, uniq } from "lodash";
import { DateTime, DurationLike } from "luxon";
import { useCallback, useMemo } from "react";
import { PlannerEventCardsByDay } from "../types";
import { useCalendarEvents } from "./useCalendarEvents";

export function useCalendars(calendarIds: string[], weekStartDate: string) {
  // Due to the Rules of Hooks, we can't call hooks in a loop, so we have to
  // call them individually and then combine the results.
  // This is a hacky solution, but it allows us to properly cache events in 99% of use cases.
  // We try and fetch the first 8 individually to allow them to be cached independently, as Apollo
  // separates cache entries by their arguments.
  const [cal1, cal2, cal3, cal4, cal5, cal6, cal7, cal8, ...otherCals] = calendarIds;

  const result1 = useCalendarEvents(cal1, weekStartDate);
  const result2 = useCalendarEvents(cal2, weekStartDate);
  const result3 = useCalendarEvents(cal3, weekStartDate);
  const result4 = useCalendarEvents(cal4, weekStartDate);
  const result5 = useCalendarEvents(cal5, weekStartDate);
  const result6 = useCalendarEvents(cal6, weekStartDate);
  const result7 = useCalendarEvents(cal7, weekStartDate);
  const result8 = useCalendarEvents(cal8, weekStartDate);
  const otherResults = useCalendarEvents(otherCals, weekStartDate);

  const results = useMemo(
    () => [result1, result2, result3, result4, result5, result6, result7, result8, otherResults],
    [result1, result2, result3, result4, result5, result6, result7, result8, otherResults],
  );

  // Need to call out each of these individually as we only want to update when a fetchMore function changes.
  const fetchMoreFns = useMemo(
    () => [
      result1.fetchMore,
      result2.fetchMore,
      result3.fetchMore,
      result4.fetchMore,
      result5.fetchMore,
      result6.fetchMore,
      result7.fetchMore,
      result8.fetchMore,
      otherResults.fetchMore,
    ],
    [
      result1.fetchMore,
      result2.fetchMore,
      result3.fetchMore,
      result4.fetchMore,
      result5.fetchMore,
      result6.fetchMore,
      result7.fetchMore,
      result8.fetchMore,
      otherResults.fetchMore,
    ],
  );
  // Need to call out each of these individually as we only want to update when the cardsByDay actually change.
  const calendars = useMemo(
    () =>
      compact([
        result1.cardsByDay,
        result2.cardsByDay,
        result3.cardsByDay,
        result4.cardsByDay,
        result5.cardsByDay,
        result6.cardsByDay,
        result7.cardsByDay,
        result8.cardsByDay,
        otherResults.cardsByDay,
      ]),
    [
      result1.cardsByDay,
      result2.cardsByDay,
      result3.cardsByDay,
      result4.cardsByDay,
      result5.cardsByDay,
      result6.cardsByDay,
      result7.cardsByDay,
      result8.cardsByDay,
      otherResults.cardsByDay,
    ],
  );
  const loading = results.some((r) => r.loading);

  const prefetch = useCallback(
    (relativeDuration: DurationLike) => {
      const newWeekStartDate = getPreviousWeekday(
        DateTime.fromISO(weekStartDate).plus(relativeDuration),
        "sunday",
      ).toISODate();

      // Only call `fetchMore` on calendars have been passed in, rather than on all hooks.
      // Calling `fetchMore` on a query that is `skip`ped appears to perform the network request
      // with bad arguments.
      fetchMoreFns.slice(0, calendarIds.length).forEach((fetchMore) => {
        void fetchMore({
          variables: {
            weekStartDate: newWeekStartDate,
          },
        });
      });
    },
    [calendarIds.length, fetchMoreFns, weekStartDate],
  );

  // Merge all calendars into one object
  const cardsByDay = useMemo(() => {
    const combinedCardsByDay: PlannerEventCardsByDay = {};

    const dates = uniq(calendars.flatMap((cal) => keys(cal)));
    dates.forEach((date) => {
      const cardsForDay = calendars.map((cal) => cal[date] ?? []);
      combinedCardsByDay[date] = cardsForDay.flat();
    });

    return combinedCardsByDay;
  }, [calendars]);

  return {
    cardsByDay,
    prefetch,
    loading,
  };
}
