/**
 * Generate a stable unique number from a string
 *
 * @param string A string to hash into a number
 * @returns A stable unique number that represents the input string
 */
export function hashString(string: string) {
  let hash = 0;

  if (string.length == 0) return hash;

  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }

  return Math.abs(hash);
}
