import { ChevronLeft, ChevronRight } from "@clockwise/design-system/icons";
import classNames from "classnames";
import * as React from "react";

type Color = "positive" | "neutral";
export interface IProps {
  color?: Color;
  rounded?: "left" | "right" | "none";
  arrow?: "left" | "right" | "none";
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const colorMap: Record<Color, { default: string; disabled: string }> = {
  positive: {
    default:
      "cw-bg-positive-emphasis hover:cw-bg-positive-emphasis-hover active:cw-bg-positive-emphasis-pressed cw-text-onEmphasis",
    disabled: "cw-bg-positive-emphasis-disabled cw-text-onEmphasis-disabled",
  },
  neutral: {
    default: "cw-bg-default hover:cw-bg-default-hover active:cw-bg-default-pressed cw-text-default",
    disabled: "cw-bg-default-disabled cw-text-default-disabled",
  },
};

/**
 * @deprecated Use `Button` from `@clockwise/design-system` instead
 */
export const OnboardingNavButton = ({
  rounded = "none",
  color = "neutral",
  disabled = false,
  loading = false,
  children,
  onClick,
  arrow = "none",
}: IProps) => {
  const colorClass = [
    "cw-border-transparent cw-shadow-button",
    disabled ? colorMap[color].disabled : colorMap[color].default,
  ];
  const textClass = ["cw-font-body cw-font-bold md:cw-text-20 cw-text-base"];
  const borderClass = {
    "cw-rounded-tr": rounded === "right",
    "cw-rounded-tl": rounded === "left",
  };
  return (
    <button
      className={classNames(
        "cw-flex cw-items-center cw-justify-center cw-h-12 cw-p-2 md:cw-h-20 md:cw-p-7",
        colorClass,
        textClass,
        borderClass,
        {
          "cw-cursor-pointer": !disabled && !loading,
          "cw-cursor-not-allowed": disabled || loading,
        },
      )}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {arrow === "left" && <ChevronLeft />}
      <span className="cw-px-2 md:cw-px-5">{children}</span>
      {arrow === "right" && <ChevronRight />}
    </button>
  );
};
