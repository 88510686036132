import React from "react";
import { fg_positive } from "../../../tokens";

type Props = {
  fill?: string;
  className?: string;
};

export function FindTime({ fill = fg_positive, className }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_631_11194)">
        <path
          d="M13.3334 2.00001H12.6667V1.33334C12.6667 0.966672 12.3667 0.666672 12 0.666672C11.6334 0.666672 11.3334 0.966672 11.3334 1.33334V2.00001H4.66671V1.33334C4.66671 0.966672 4.36671 0.666672 4.00004 0.666672C3.63337 0.666672 3.33337 0.966672 3.33337 1.33334V2.00001H2.66671C1.93337 2.00001 1.33337 2.60001 1.33337 3.33334V14C1.33337 14.7333 1.93337 15.3333 2.66671 15.3333H13.3334C14.0667 15.3333 14.6667 14.7333 14.6667 14V3.33334C14.6667 2.60001 14.0667 2.00001 13.3334 2.00001ZM12.6667 14H3.33337C2.96671 14 2.66671 13.7 2.66671 13.3333V5.33334H13.3334V13.3333C13.3334 13.7 13.0334 14 12.6667 14Z"
          fill={fill}
        />
        <path
          d="M7.23077 11.0167L6.46154 12.6667L5.69231 11.0167L4 10.2667L5.69231 9.51666L6.46154 7.86666L7.23077 9.51666L8.92308 10.2667L7.23077 11.0167ZM10.7323 9.03066L10.1538 10.2667L9.57539 9.03066L8.30769 8.46666L9.57539 7.90266L10.1538 6.66666L10.7323 7.90266L12 8.46666L10.7323 9.03066Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_631_11194">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
