import * as ISchema from "#webapp/src/__schema__";
import { onboardingIntro } from "#webapp/src/assets/img/illustrations";
import { OnboardingNavButton } from "#webapp/src/components/onboarding-nux/onboarding-buttons/OnboardingNavButton";
import { paths } from "#webapp/src/constants/site.constant";
import { updateShouldRefetch } from "#webapp/src/state/actions/payments.actions";
import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { fromGlobalId } from "#webapp/src/util/graphql.util";
import { getCurrentOrg } from "#webapp/src/util/org.util";
import { useMutation, useQuery } from "@apollo/client";
import { getSiteUrl } from "@clockwise/client-commons/src/config/api";
import { copyTextToClipboard } from "@clockwise/web-commons/src/util/html.util";
import React, { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useMonetization } from "../../hooks/useMonetization";
import { InviteModalMode } from "./";
import { InviteForms } from "./InviteForms";
import { get30DaysFromNow } from "./InviteModal.utils";
import { InviteModalCopy } from "./InviteModalCopy";
import {
  ExtendTrialDocument,
  GetInviteDataDocument,
  InviteModalMutationDocument,
} from "./__generated__/InviteModal.generated";

const MIN_SELECTED_PERSONS = 3;
interface InviteModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  mode: InviteModalMode;
  setShowConfirmation: (showConfirmation: true) => void;
}
export const InviteModalContent = ({
  isOpen,
  onClose,
  mode,
  setShowConfirmation,
}: InviteModalContentProps) => {
  const [selectedPersons, setSelectedPersons] = useState<ISchema.IOrgPerson[]>([]);
  const [linkCopied, setLinkCopied] = useState(false);
  const dispatch = useDispatch();
  const { refetchFeatureGrid } = useMonetization();
  const { data } = useQuery(GetInviteDataDocument, {
    skip: !isOpen,
  });

  const [extendTrial] = useMutation(ExtendTrialDocument, {
    onCompleted: () => {
      track(TrackingEvents.TRIAL_EXTENSION.TRIAL_EXTENDED);
      dispatch(updateShouldRefetch(true));
      refetchFeatureGrid();
      setShowConfirmation(true);
    },
    onError: () => {
      toast.error("Failed to extend trial");
    },
  });
  const [updateOrgInvite] = useMutation(InviteModalMutationDocument, {
    onError: () => {
      toast.error("Failed to send invite");
    },
  });

  const inviteUrl = useMemo(() => {
    const userId = data?.viewer?.user?.id;
    if (!userId) return;
    const userToken = fromGlobalId(userId).id;
    return `${getSiteUrl()}${paths.personalInvite}/${userToken}`;
  }, [data?.viewer?.user?.id]);

  const onClickCopyLink = () => {
    if (inviteUrl) {
      const success = copyTextToClipboard(inviteUrl, true);
      if (success) {
        setLinkCopied(true);
        track(TrackingEvents.INVITE_DIALOG.COPY_INVITE_LINK);
        setTimeout(() => {
          setLinkCopied(false);
        }, 2100);
        toast.success("Copied invite link to your clipboard", { id: "copied-invite-link" });
      }
    }
  };

  const onClickButton = () => {
    if (mode === InviteModalMode.Invite) {
      // if invite
      handleInvite();
    } else {
      // If trial extension
      handleInviteAndTrialExtension();
    }
  };

  const handleInviteAndTrialExtension = () => {
    if (!data || !data.viewer) return;
    const currentOrg = getCurrentOrg(data?.viewer);
    const calendarIds: string[] = [];
    selectedPersons.forEach((person) => {
      calendarIds.push(person.primaryCalendarId);
    });

    if (selectedPersons.length >= MIN_SELECTED_PERSONS) {
      updateOrgInvite({
        variables: { input: { calendarIds: calendarIds, orgRelayId: currentOrg?.id || "" } },
        onCompleted: () => {
          track(TrackingEvents.TRIAL_EXTENSION.INVITED, { invitedCalendarIds: calendarIds });
          extendTrial({ variables: { input: { endDate: get30DaysFromNow() } } });
        },
      });
    } else {
      toast.error("Please invite at least 3 teammates to extend your trial ");
    }
  };

  const handleInvite = () => {
    if (!data || !data.viewer) return;
    const currentOrg = getCurrentOrg(data?.viewer);
    const calendarIds: string[] = [];
    selectedPersons.forEach((person) => {
      calendarIds.push(person.primaryCalendarId);
    });
    if (selectedPersons.length > 0) {
      updateOrgInvite({
        variables: { input: { calendarIds: calendarIds, orgRelayId: currentOrg?.id || "" } },
        onCompleted: () => {
          toast.success("Invitation sent!");
          track(TrackingEvents.INVITE_DIALOG.INVITED);
          onClose();
        },
      });
    } else {
      toast.error("Please select a teammate");
    }
  };

  const onTeammateChecked = (person: ISchema.IOrgPerson, checked: boolean) => {
    const newSelectedPersons = [...selectedPersons];
    if (checked) {
      if (!newSelectedPersons.includes(person)) {
        newSelectedPersons.push(person);
      }
    } else {
      const newPerson = newSelectedPersons.find(
        (newPerson) => newPerson.primaryCalendarId === person.primaryCalendarId,
      );
      if (newPerson) {
        const index = newSelectedPersons.indexOf(newPerson);
        newSelectedPersons.splice(index, 1);
      }
    }
    setSelectedPersons(newSelectedPersons);
  };

  const currentOrg = getCurrentOrg(data?.viewer);
  const isButtonDiabled = mode === InviteModalMode.TrialExtension && selectedPersons.length < 3;
  return (
    <div className="cw-flex cw-text-accent">
      <div className="cw-flex-1 cw-relative">
        <InviteForms
          currentOrg={currentOrg as ISchema.IOrg}
          onCheck={onTeammateChecked}
          linkCopied={linkCopied}
          onClickCopyLink={onClickCopyLink}
          inviteUrl={inviteUrl}
          mode={mode}
        />
        <div className="cw-absolute cw-bottom-0 cw-right-0">
          <OnboardingNavButton color="positive" onClick={onClickButton} disabled={isButtonDiabled}>
            {InviteModalCopy[mode].buttonText}
          </OnboardingNavButton>
        </div>
      </div>
      <div className="cw-flex-1 cw-flex cw-pb-[84px] cw-items-center cw-justify-center cw-bg-neutral cw-border-0 cw-border-l cw-border-solid cw-border-default">
        <img src={onboardingIntro} alt="invite-users" />
      </div>
    </div>
  );
};
