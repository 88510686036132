import { Check } from "@clockwise/icons";
import { Listbox } from "@headlessui/react";
import { SvgIconComponent } from "@material-ui/icons";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { getListBoxClasses } from "../constants/classes";

export const SelectOptionsGroup = ({
  label,
  children,
}: {
  label?: string | ReactNode;
  children: ReactNode;
}) => {
  return (
    <>
      <div>
        {label && (
          <span
            className={classNames(
              "cw-text-subtle cw-flex cw-items-center",
              "cw-text-12 cw-font-semibold cw-cursor-pointer cw-select-none cw-relative cw-px-2 cw-py-[5px]",
            )}
          >
            {label}
          </span>
        )}
        {children}
      </div>
      <hr className="last:cw-hidden cw-border-solid cw-border-muted cw-border-b cw-border-t-0 cw-block cw-mx-2 cw-my-1" />
    </>
  );
};

export interface SelectOptionProps<V> {
  value: V;
  disabled?: boolean;
  icon?: SvgIconComponent;
  iconProps?: { stroke?: string; fill?: string; className?: string; style?: { color?: string } };
  children?: ReactNode;
}

export function SelectOption<V extends string | number>({
  value,
  disabled = false,
  children,
  icon: SelectIcon,
  iconProps = {},
}: SelectOptionProps<V>) {
  // If the option is an empty value, only render it as the selected value, it shouldn't appear in
  // the list.
  if (value === "") return null;

  return (
    <Listbox.Option
      key={value}
      disabled={disabled}
      className={({ active }) => getListBoxClasses(active, disabled)}
      value={value}
      data-value={value}
    >
      {({ selected }) => (
        <div className="cw-flex cw-items-center cw-gap-2 cw-min-h-[14px]">
          {SelectIcon && (
            <SelectIcon
              {...iconProps}
              className={classNames("cw-w-4 cw-h-4", iconProps?.className)}
            />
          )}
          {children || <span className="cw-block cw-truncate">{value}</span>}
          {selected && (
            <Check
              className="cw-h-[14px] cw-w-[14px] cw-ml-auto cw-text-positive"
              aria-hidden="true"
            />
          )}
        </div>
      )}
    </Listbox.Option>
  );
}
