import {
  EventColorCategory,
  ICalendarColorsMap,
} from "@clockwise/client-commons/src/util/event-category-coloring";
import { ResponseStatusEnum } from "@clockwise/schema";
import { ConferencingType } from "@clockwise/schema/v2";
import { getEventStartEndTimeText } from "@clockwise/web-commons/src/components/calendar/calendar-event/utils/getEventStartEndTimeText";
import { EventType } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { isEmpty } from "lodash";
import { Interval } from "luxon";
import { CalendarEventsQuery } from "../apollo/__generated__/CalendarEvents.v2.generated";
import { WorkingLocationEventsQuery } from "../apollo/__generated__/WorkingLocationEvents.v2.generated";
import { hasIntervalAllDayLong } from "../ooo-events/utils/hasIntervalAllDayLong";
import { PlannerEventCardsByDay, TransparencyEnum } from "../types";
import { toWebserverResponseStatus } from "./event.util";

const getTransparency = (
  isMarkedBusy: boolean,
  microsoftTransparency: TransparencyEnum | null,
): TransparencyEnum => {
  if (microsoftTransparency && microsoftTransparency !== TransparencyEnum.None) {
    return microsoftTransparency;
  } else {
    return isMarkedBusy ? TransparencyEnum.Opaque : TransparencyEnum.Transparent;
  }
};

export function toPlannerEvents(
  data?: CalendarEventsQuery,
  colorMap?: ICalendarColorsMap,
): PlannerEventCardsByDay {
  return (
    data?.calendars.reduce<PlannerEventCardsByDay>((acc, member) => {
      member?.eventsByWeek.forEach((event) => {
        const range =
          event.dateOrTimeRange.__typename === "DateTimeRange"
            ? event.dateOrTimeRange.timeRange
            : event.dateOrTimeRange.dateRange;
        const timeInterval = Interval.fromISO(range, {
          zone: getRenderTimeZone(),
        });
        const date = timeInterval.start.toISODate();
        const endDate = timeInterval.end.toISODate();
        // Whether this event is displayed in the calendar header rather than the body of the calendar
        const isHeaderEvent =
          event.isAllDay ||
          timeInterval.toDuration().as("days") >= 1 ||
          hasIntervalAllDayLong({ interval: timeInterval });
        const recurrence = event.recurrenceRule;

        const attendees = event.attendees.map((a) => ({
          __typename: a.__typename,
          id: a.id,
          email: a.email,
          responseStatus: a.responseStatus ? toWebserverResponseStatus(a.responseStatus) : null,
        }));

        // All day event endTime is either the day it ends, or sometimes the next day at 00-00-00 hours/minutes/seconds. So,
        // subtract 1 second from the end day to check if the end date falls on 'day', which is the calendar day being rendered.
        const isAllDayOOOEvent =
          ((event.category.type as unknown) as EventColorCategory) ===
            EventColorCategory.OutOfOffice &&
          (timeInterval.toDuration().as("days") >= 1 ||
            hasIntervalAllDayLong({ interval: timeInterval }));
        const eventOrganizer = event.attendees.find((a) => a.isOrganizer)?.email;
        const calendarIds =
          isAllDayOOOEvent && eventOrganizer ? [eventOrganizer] : [event.calendar.id];

        const eventObject = {
          active: false,
          annotaion: undefined,
          attendeeCount: event.attendees.length,
          attendeesCalendarIds: event.attendees.map((a) => a.email),
          attendees,
          calendarIds,
          deemphasis: false,
          eventCategory: (event.category.type as unknown) as EventColorCategory,
          eventCategoryColor: event.category.color ? colorMap?.[event.category.color] : undefined,
          eventPermissions: event.eventPermissions,
          externalEventId: event.externalEventId,
          flexStatus: null,
          interval: timeInterval,
          isAllDay: event.isAllDay,
          isCancelled: event.isCanceled,
          key: event.id,
          locked: event.isLocked,
          responseState: toWebserverResponseStatus(event.responseStatus),
          subText: getEventStartEndTimeText(timeInterval.start, timeInterval.end),
          text: event.title,
          transparency: getTransparency(event.isMarkedBusy, event.microsoftTransparency),
          smartHold: event.smartHold
            ? {
                holdType: event.smartHold.holdType,
                state: event.smartHold.state,
              }
            : null,
          videoLink:
            event.conferencing && event.conferencing.type !== ConferencingType.None
              ? {
                  __typename: "VideoLink" as const,
                  uri: event.conferencing.url,
                  type: event.conferencing.type,
                }
              : null,
          type: event.isSyncedFromPersonalCalendar
            ? EventType.PersonalCalendarSync
            : EventType.Event,
          isRecurring: !isEmpty(recurrence),
          recurringEventId: event.recurringEventId,
          isHold: event.isHold,
        };
        // Events that cross day boundaries need to be added to both days.
        if (date !== endDate && !isHeaderEvent) {
          const startOfDay = timeInterval.end.startOf("day");
          const endOfDay = timeInterval.end.endOf("day");
          const fullDayInterval = Interval.fromDateTimes(startOfDay, endOfDay);
          const clampedTimeInterval = timeInterval.intersection(fullDayInterval);
          if (clampedTimeInterval) {
            // Add event to endDate.
            acc[endDate] = [
              ...(acc[endDate] ?? []),
              {
                ...eventObject,
                interval: event.isAllDay ? timeInterval : clampedTimeInterval,
              },
            ];
          }
        }
        const startOfDay = timeInterval.start.startOf("day");
        const endOfDay = timeInterval.start.endOf("day");
        const fullDayInterval = Interval.fromDateTimes(startOfDay, endOfDay);
        const clampedTimeInterval = timeInterval.intersection(fullDayInterval) || timeInterval;
        if (clampedTimeInterval) {
          acc[date] = [
            ...(acc[date] ?? []),
            {
              ...eventObject,
              interval: isHeaderEvent ? timeInterval : clampedTimeInterval,
            },
          ];
        }
      });
      return acc;
    }, {}) ?? {}
  );
}

export function toPlannerWorkingLocations(
  data?: WorkingLocationEventsQuery,
  _colorMap?: ICalendarColorsMap,
): PlannerEventCardsByDay {
  return (
    data?.calendars.reduce<PlannerEventCardsByDay>((acc, member) => {
      member?.workingLocationEventsByWeek.forEach((wl) => {
        const range =
          wl.range.__typename === "DateTimeRange" ? wl.range.timeRange : wl.range.dateRange;
        const timeInterval = Interval.fromISO(range, {
          zone: getRenderTimeZone(),
        });
        const date = timeInterval.start.toISODate();

        acc[date] = [
          ...(acc[date] ?? []),
          {
            active: false,
            annotaion: undefined,
            attendeeCount: 1,
            attendeesCalendarIds: [wl.calendarId],
            attendees: [],
            calendarIds: [wl.calendarId],
            deemphasis: false,
            eventCategory: undefined,
            eventCategoryColor: undefined,
            eventPermissions: {
              canRSVP: false,
              canSeeOtherGuests: false,
              canInviteOthers: false,
              canModify: false,
              canDelete: false,
              canRemove: false,
            },
            externalEventId: wl.eventId,
            flexStatus: null,
            interval: timeInterval,
            isAllDay: wl.isAllDay,
            isCancelled: false,
            key: wl.id,
            locked: false,
            responseState: ResponseStatusEnum.NotApplicable,
            subText: getEventStartEndTimeText(timeInterval.start, timeInterval.end),
            text: wl.title || wl.location.type,
            transparency: TransparencyEnum.None,
            smartHold: null,
            videoLink: null,
            type: EventType.WorkingLocation,
          },
        ];
      });
      return acc;
    }, {}) ?? {}
  );
}
