import { GroupAdd } from "@clockwise/design-system/icons";
import classNames from "classnames";
import React from "react";
import { JoinMode, TeamFormView, TeamsFormMode } from "../TeamJoinCreateTypes";

type PillsProps = {
  mode: TeamsFormMode;
  joinMode: JoinMode;
  currentView: TeamFormView;
  selectMode?: boolean;
  onSelectPill: (view: TeamFormView) => void;
};

export const TeamJoinCreatePills = ({
  mode,
  joinMode,
  currentView,
  selectMode,
  onSelectPill,
}: PillsProps) => {
  if (
    (mode !== "checkout" && (joinMode === "off" || joinMode === "select-primary")) ||
    mode == "onboarding"
  ) {
    return null;
  }

  const getPillClass = (tab: TeamFormView) => {
    return classNames(
      "cw-cursor-pointer cw-body-base cw-rounded-full cw-flex cw-flex-1 cw-flex-row cw-items-center cw-justify-center cw-text-info cw-font-bold cw-mr-1",
      {
        "cw-cursor-default cw-bg-default cw-shadow-sm hover:cw-shadow-sm": tab === currentView,
      },
      {
        "hover:cw-bg-neutral-hover": tab !== currentView,
      },
    );
  };

  return (
    <div className="cw-flex cw-flex-row cw-rounded-full cw-min-h-[43px] cw-p-1 cw-mb-8 cw-bg-neutral">
      {selectMode ? (
        <div
          className={getPillClass("select")}
          onClick={() => {
            onSelectPill("select");
          }}
        >
          Select a team
        </div>
      ) : (
        <div
          className={getPillClass("join")}
          onClick={() => {
            onSelectPill("join");
          }}
        >
          Join a team
        </div>
      )}
      <div
        className={getPillClass("create")}
        onClick={() => {
          onSelectPill("create");
        }}
      >
        <GroupAdd className="cw-mr-2" /> Create a new team
      </div>
    </div>
  );
};
