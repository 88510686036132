export type ITierType =
  | "FreemiumOrg"
  | "ProOrg"
  | "BusinessOrg"
  | "Starter"
  | "Team"
  | "Business"
  | "Enterprise";

export type IPlanPricing = {
  monthlyCost: number;
  yearlyCost: number;
  contactSalesForPricing: boolean;
};

export type ITierContent = {
  type: ITierType;
  title: string;
  planPricing: IPlanPricing;
  costUnit: string;
  featureHeader: string;
  features: string[];
};

export const freeTier: ITierContent = {
  type: "FreemiumOrg",
  title: "Free",
  planPricing: {
    monthlyCost: 0,
    yearlyCost: 0,
    contactSalesForPricing: false,
  },
  costUnit: "Per user per month",
  featureHeader: "Access to a smart calendar assistant:",
  features: [
    "Limited Smart Holds for Focus Time and lunch",
    "Limited Flexible Meetings usage",
    "Limited Scheduler usage",
    "Slack sync",
    "Personal-to-work calendar sync",
  ],
};

export const proTier: ITierContent = {
  type: "ProOrg",
  title: "Teams",
  planPricing: {
    monthlyCost: 7.75,
    yearlyCost: 6.75,
    contactSalesForPricing: false,
  },
  costUnit: "Per user per month, billed annually",
  featureHeader: "Everything in Free, plus:",
  features: [
    "Unlimited Smart Holds for Focus Time and lunch",
    "Unlimited Flexible Meetings usage",
    "Unlimited Scheduler usage",
    "Custom Smart Holds for any event",
    "Automatic team availability calendar",
    "Automatic team no-meeting days",
    "Team calendar analytics",
    "Priority Support",
  ],
};

export const businessTier: ITierContent = {
  type: "BusinessOrg",
  title: "Business",
  planPricing: {
    monthlyCost: 11.50, // prettier-ignore
    yearlyCost: 11.50, // prettier-ignore
    contactSalesForPricing: false,
  },
  costUnit: "Per user per month, billed annually",
  featureHeader: "Everything in Teams, plus:",
  features: [
    "Personalized onboarding and training",
    "Dedicated Customer Success Manager",
    "Custom contract & invoicing",
  ],
};

export const enterpriseTier: ITierContent = {
  type: "Enterprise",
  title: "Enterprise",
  planPricing: {
    monthlyCost: 0,
    yearlyCost: 0,
    contactSalesForPricing: false,
  },
  costUnit: "Per user per month, billed annually",
  featureHeader: "Everything in Business, plus:",
  features: [
    "Limited Smart Holds for Focus Time and lunch",
    "Limited Flexible Meetings usage",
    "Limited Scheduler usage",
    "Slack sync",
    "Personal-to-work calendar sync",
  ],
};

export const pricingFaqs = [
  {
    question: `How do I pay for Clockwise’s subscription?`,
    answer: `Clockwise offers both monthly and annual subscriptions. When you are ready to make a selection, we’ll walk you through step-by-step instructions on how to process your payment, in the product. Should you have any questions about larger plans or custom terms, email sales@getclockwise.com.`,
  },
  {
    question: `I have another question about Clockwise’s plans`,
    answer: `Please reach out to our team at sales@getclockwise.com, and we’ll do our best to address your question.`,
  },
];
