import { CheckCircle, NotInterested, RadioButtonUnchecked } from "@clockwise/design-system/icons";
import React from "react";

interface RadioControlProps {
  children: React.ReactNode;
  disabled?: boolean;
  on?: boolean;
}
export const RadioControl = ({ children, disabled, on }: RadioControlProps) => (
  <div className="cw-w-full cw-p-3 cw-flex cw-gap-3 cw-items-center" aria-disabled={disabled}>
    {disabled ? <NotInterested /> : on ? <CheckCircle /> : <RadioButtonUnchecked />}
    <div className="cw-flex cw-flex-col">{children}</div>
  </div>
);
