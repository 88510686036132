import { graphql } from "react-relay";

export const overrideSyncStatusForUserEventMutation = graphql`
  mutation OverrideSyncStatusForUserEventMutation(
    $input: OverrideSyncStatusForUserEventMutationInput!
  ) {
    overrideSyncStatusForUserEvent(input: $input) {
      clientMutationId
    }
  }
`;
