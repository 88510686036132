import { InviteModalMode } from "#webapp/src/components/invite-modal/InviteModal.types";
import { ChromeAppWrapperView } from "#webapp/src/util/chrome-extension.util";
import { logger } from "#webapp/src/util/logger.util";
import { incrementValue, recordDuration, recordValue } from "#webapp/src/util/stats.util";
import { DayOffsetTimeSlot, PreEventFlexibility, PreEventKey } from "@clockwise/schema";
import { DayOnOffMap } from "@clockwise/web-commons/src/ui/working-hours";
import {
  ActiveMutationCollection,
  ActiveQueryCollection,
} from "@clockwise/web-commons/src/util/apollo.util";
import { PayloadTypes } from "@clockwise/web-commons/src/util/post-message-common.util";
import {
  IChromeExtensionAction,
  chromeExtensionActions,
} from "../actions/chrome-extension.actions";

export interface ICalViewState {
  activeView: "agenda" | "week" | "day" | "month" | "year" | "custom";
  year: number;
  month: number;
  selectedDay: number;
}

export type IEditEventInfo = {
  attendees: string[];
  endDate: string;
  endTime: string;
  endTimeZone: string;
  rooms: string[];
  startDate: string;
  startTime: string;
  startTimeZone: string;
  timestamp: number;
  allDayEvent: boolean;
  isEditable: boolean;
  duration: number | null;
  needRoom: boolean;
  schedulingSessionId: string;
};

export type IExtensionTabFocusInfo = {
  visibility: "visible" | "hidden" | "";
  millis: number;
};

export type MessageType = "success" | "error";

export interface IWebExtensionState {
  activeView: ChromeAppWrapperView;
  activeViewCalendarProfile: {
    calendarId: string;
    viewWeek?: string;
  };
  activeViewEvent: {
    externalEventId: string;
    calendarId: string;
    recentlyRescheduled: boolean;
    openReschedule: boolean;
    showClockwiseHistory: boolean;
    currentViewOfEventWasSetByUrlParam: boolean;
    lastQuickRescheduleMillis?: number;
  };
  webExtensionEventInfo: {
    externalEventId?: string;
    calendarId?: string;
    activeEventMillis?: number;
  };
  sendInvites: {
    calendarIds: string[];
    millis: number;
    eventMeta?: { externalEventId: string; calendarId: string };
  };
  activeViewThreadInfo: {
    topicId: string;
  };
  mode: "webapp" | "extension";
  calendarUrl: string;
  calendarMismatch?: string;
  createEvent: boolean;
  editEvent: boolean;

  preEventFlexibility: PreEventFlexibility | null;
  preEventKey: PreEventKey | null;
  preEventCalendarId: string;

  menuDrawerOpen: boolean;

  webExtensionOpen: boolean;
  webExtensionInstalled: boolean;
  webExtensionVersion: string;
  isWebExtension: boolean;

  webExtensionDialogOpen: boolean;
  webExtensionShadowCalendarActive: number;
  webExtensionIdle: number;

  googleVersion: string;
  calendarSelectionStates: { [calId: string]: boolean };
  calendarView: null | ICalViewState;
  calendarDayViewDate: Date;
  editEventInfo: IEditEventInfo;
  windowUserEmail: string;
  eventDeleteButtonClicked: number;
  smartHoldToDeleteExternalEventId: string;
  setUserFlagMillis: number;
  userFlag: string;
  smartHoldUpdatedMillis: number;
  eventFlexUpdatedMillis: number;
  unsubscribedCalendarIdMillis: number;
  unsubscribedCalendarId: string;
  isOnline: boolean;
  lastOffline: number;
  setEventTags: {
    externalEventId: string;
    calendarId: string;
    flex: string;
    allowSimilarRooms: string;
    setEventTagsMillis: number;
    allowedStartV3: DayOffsetTimeSlot | null;
    allowedEndV3: DayOffsetTimeSlot | null;
    dayOnOffMap: DayOnOffMap;
  };
  snackBar: {
    message: string;
    messageMillis: number;
    messageType?: MessageType;
  };

  activeQueryCollection: ActiveQueryCollection;
  activeMutationCollection: ActiveMutationCollection;

  schedulerEmails: string[];
  contentScrollTop: number;
  shouldSyncActiveOrg: boolean;
  pricingDialogInfo: {
    openPricingDialogMillis: number;
    initialView: "Pricing" | "Checkout";
  };
  shouldFetchSuggestedTimes: boolean;
  openShareLinkModal: boolean;
  openShareWithCoworkersModal: { open: boolean; mode: InviteModalMode };
}

export const initialWebExtensionState: IWebExtensionState = {
  activeView: ChromeAppWrapperView.TeamHealth,
  activeViewCalendarProfile: {
    calendarId: "",
    viewWeek: undefined,
  },
  activeViewEvent: {
    externalEventId: "",
    calendarId: "",
    recentlyRescheduled: false,
    openReschedule: false,
    showClockwiseHistory: false,
    currentViewOfEventWasSetByUrlParam: false,
  },
  webExtensionEventInfo: {
    externalEventId: undefined,
    calendarId: undefined,
    activeEventMillis: undefined,
  },
  activeViewThreadInfo: {
    topicId: "",
  },
  mode: "extension",
  sendInvites: {
    millis: 0,
    calendarIds: [],
  },
  calendarUrl: "",
  createEvent: false,
  editEvent: false,
  preEventFlexibility: null,
  preEventKey: null,
  preEventCalendarId: "",
  menuDrawerOpen: false,

  // Really refers just to whether the sidebar is open
  webExtensionOpen: true,
  webExtensionInstalled: false,
  webExtensionVersion: "",
  isWebExtension: false,

  webExtensionDialogOpen: false,
  webExtensionShadowCalendarActive: 0,
  webExtensionIdle: 0,

  googleVersion: "unavailable",
  calendarSelectionStates: {},
  calendarView: null,
  calendarDayViewDate: new Date(),
  editEventInfo: {
    attendees: [],
    endDate: "",
    endTime: "",
    endTimeZone: "",
    rooms: [],
    startDate: "",
    startTime: "",
    startTimeZone: "",
    timestamp: 0,
    allDayEvent: false,
    isEditable: true,
    duration: null,
    needRoom: true,
    schedulingSessionId: "",
  },
  windowUserEmail: "",
  eventDeleteButtonClicked: 0,
  smartHoldToDeleteExternalEventId: "",
  setUserFlagMillis: 0,
  userFlag: "",
  smartHoldUpdatedMillis: 0,
  eventFlexUpdatedMillis: 0,
  unsubscribedCalendarIdMillis: 0,
  unsubscribedCalendarId: "",
  isOnline: true,
  lastOffline: 0,
  setEventTags: {
    externalEventId: "",
    calendarId: "",
    flex: "",
    allowSimilarRooms: "",
    setEventTagsMillis: 0,
    allowedStartV3: null,
    allowedEndV3: null,
    dayOnOffMap: {},
  },
  snackBar: {
    message: "",
    messageMillis: 0,
  },
  schedulerEmails: [],
  contentScrollTop: 0,
  shouldSyncActiveOrg: false,
  pricingDialogInfo: {
    openPricingDialogMillis: 0,
    initialView: "Pricing",
  },
  shouldFetchSuggestedTimes: false,
  openShareLinkModal: false,
  openShareWithCoworkersModal: { open: false, mode: InviteModalMode.Invite },
  activeQueryCollection: {},
  activeMutationCollection: {},
};

export function webExtensionReducer(
  state: IWebExtensionState = initialWebExtensionState,
  action: IChromeExtensionAction,
) {
  switch (action.type) {
    case chromeExtensionActions.NEW_MESSAGE_EVENT_DATA_FROM_CHROME_EXTENSION:
      return chromeExtensionInterAppPayloadReducer(state, action);
    case chromeExtensionActions.UNSET_RECENTLY_RESCHEDULED:
      return { ...state, recentlyRescheduled: false };
    case chromeExtensionActions.SET_MENU_DRAWER_OPEN:
      return {
        ...state,
        menuDrawerOpen: action.data ? action.data.menuDrawerOpen : state.menuDrawerOpen,
      };
    case chromeExtensionActions.SET_ACTIVE_VIEW:
      return { ...state, activeView: action.data ? action.data.activeView : state.activeView };
    case chromeExtensionActions.SET_IS_ONLINE:
      return { ...state, isOnline: action.data ? action.data.isOnline : state.isOnline };
    case chromeExtensionActions.SET_MODE:
      return { ...state, mode: action.data ? action.data.mode : state.mode };
    case chromeExtensionActions.SET_LAST_OFFLINE:
      return { ...state, lastOffline: action.data ? action.data.lastOffline : state.lastOffline };
    case chromeExtensionActions.SET_ACTIVE_VIEW_CALENDAR_PROFILE:
      return {
        ...state,
        activeView: ChromeAppWrapperView.CalendarProfile,
        activeViewCalendarProfile: {
          calendarId: action.data
            ? action.data.calendarId
            : state.activeViewCalendarProfile.calendarId,
          viewWeek: action.data ? action.data.viewWeek : state.activeViewCalendarProfile.viewWeek,
        },
      };
    case chromeExtensionActions.SET_ACTIVE_VIEW_EVENT:
      if (!action.data || !action.data.calendarId) {
        logger.error("SET_ACTIVE_VIEW_EVENT with no calendarId");
        return state;
      }
      return {
        ...state,
        menuDrawerOpen: false,
        activeView: ChromeAppWrapperView.Event,
        activeViewEvent: {
          externalEventId: action.data.externalEventId,
          calendarId: action.data.calendarId,
          recentlyRescheduled: action.data.recentlyRescheduled,
          openReschedule: !!action.data.openReschedule,
          showClockwiseHistory: !!action.data.showClockwiseHistory,
          currentViewOfEventWasSetByUrlParam: !!action.data.currentViewOfEventWasSetByUrlParam,
        },
      };
    case chromeExtensionActions.SET_CONTENT_SCROLL_TOP:
      return {
        ...state,
        contentScrollTop: action.data?.contentScrollTop,
      };
    case chromeExtensionActions.SET_SUGGESTIONS_FEED_NOTIFICATION_COUNT:
      return {
        ...state,
        suggestionFeedNotificationCount: action.data?.notificationCount || 0,
      };
    case chromeExtensionActions.SET_ASSISTANT_FEED_CARD_NOTIFICATION_COUNT:
      return {
        ...state,
        assistantFeedCardNotificationCount: action.data?.notificationCount || 0,
      };
    case chromeExtensionActions.SET_AUTOPILOT_FEED_STATUS_DEFRAG_CARD_NOTIFICATION_COUNT:
      return {
        ...state,
        autopilotFeedStatusDefragCardNotificationCount: action.data?.notificationCount || 0,
      };
    case chromeExtensionActions.SET_SHOULD_SYNC_ACTIVE_ORG:
      return {
        ...state,
        shouldSyncActiveOrg: action.data?.shouldSyncActiveOrg,
      };
    case chromeExtensionActions.SET_QUERY_COLLECTION:
      return { ...state, activeQueryCollection: action.data?.activeQueryCollection };
    case chromeExtensionActions.SET_MUTATION_COLLECTION:
      return { ...state, activeMutationCollection: action.data?.activeMutationCollection };
    case chromeExtensionActions.SET_CALENDAR_URL:
      return { ...state, calendarUrl: !!action.data?.calendarUrl };
    case chromeExtensionActions.SET_OPEN_SHARE_LINK_MODAL:
      return { ...state, openShareLinkModal: !!action.data?.open };
    case chromeExtensionActions.SET_OPEN_SHARE_WITH_COWORKERS_MODAL:
      return { ...state, openShareWithCoworkersModal: action.data?.openShareWithCoworkersModal };
    default:
      return state;
  }
}

export function chromeExtensionInterAppPayloadReducer(
  state: IWebExtensionState,
  action: IChromeExtensionAction,
) {
  let newState;
  if (!action.data) {
    return state;
  }

  // Old versions of the extension still send payloads that have properties like
  // isChromeExtension or blahBlahCHROMEMoreBlahBlah. However, the webapp wants to
  // move on with its life and use browser-agnostic state names, like isWebExtension.
  //
  // As a result, we introduce the following step to convert between those old values and
  // our new values. By converting earlier on, we're able to fearlessly spread payload into
  // our new state. This is a bit dangerous since we don't have type safety here, so we could
  // accidentally forget to convert from some property name fooCHROMEBar to fooWEBBar.
  const payload = action.data.payload;
  if (payload) {
    // double equality to capture both undefined and null
    if (payload.chromeExtensionOpen != null) {
      payload.webExtensionOpen = payload.chromeExtensionOpen;
      delete payload.chromeExtensionOpen;
    }

    if (payload.chromeExtensionInstalled != null) {
      payload.webExtensionInstalled = payload.chromeExtensionInstalled;
      delete payload.chromeExtensionInstalled;
    }

    if (payload.chromeExtensionVersion != null) {
      payload.webExtensionVersion = payload.chromeExtensionVersion;
      delete payload.chromeExtensionVersion;
    }

    if (payload.isChromeExtension != null) {
      payload.isWebExtension = payload.isChromeExtension;
      delete payload.isChromeExtension;
    }

    if (payload.chromeExtensionIdle != null) {
      payload.webExtensionIdle = payload.chromeExtensionIdle;
      delete payload.chromeExtensionIdle;
    }

    if (payload.chromeExtensionChromeDialogOpen != null) {
      payload.webExtensionDialogOpen = payload.chromeExtensionChromeDialogOpen;
      delete payload.chromeExtensionChromeDialogOpen;
    }
  }

  switch (action.data.type) {
    case PayloadTypes.SELECTION_STATES:
    case PayloadTypes.CALENDAR_MISMATCH:
    case PayloadTypes.CREATE_EVENT:
    case PayloadTypes.EDIT_EVENT:
    case PayloadTypes.PRE_EVENT_FLEXIBILITY:

    case PayloadTypes.IS_CHROME_EXTENSION:
    case PayloadTypes.IS_WEB_EXTENSION:
    case PayloadTypes.CHROME_EXTENSION_VERSION:
    case PayloadTypes.WEB_EXTENSION_VERSION:
    case PayloadTypes.CHROME_EXTENSION_HOVERED:
    case PayloadTypes.WEB_EXTENSION_HOVERED:
    case PayloadTypes.CHROME_EXTENSION_CHROME_DIALOG_OPEN:
    case PayloadTypes.WEB_EXTENSION_WEB_DIALOG_OPEN:
    case PayloadTypes.CHROME_EXTENSION_IDLE:
    case PayloadTypes.WEB_EXTENSION_IDLE:
    case PayloadTypes.CHROME_EXTENSION_SHADOW_CALENDAR_ACTIVE:
    case PayloadTypes.WEB_EXTENSION_SHADOW_CALENDAR_ACTIVE:
    case PayloadTypes.WEB_EXTENSION_EVENT_INFO:

    case PayloadTypes.GOOGLE_VERSION:
    case PayloadTypes.CALENDAR_VIEW:
    case PayloadTypes.EDIT_EVENT_INFO:
    case PayloadTypes.EVENT_DELETE_BUTTON_CLICKED:
    case PayloadTypes.USER_FLAGS:
    case PayloadTypes.SMART_HOLD_UPDATED:
    case PayloadTypes.EVENT_FLEX_UPDATED:
    case PayloadTypes.CALENDAR_URL:
    case PayloadTypes.WINDOW_USER_EMAIL:
    case PayloadTypes.UNSUBSCRIBE_CALENDAR_ID:
    case PayloadTypes.CHROME_EXTENSION_OPEN:
    case PayloadTypes.WEB_EXTENSION_OPEN:
      return { ...state, ...action.data.payload };
    case PayloadTypes.CALENDAR_USER_EMAIL: // deprecate once 0.6.24 proliferates
      return { ...state, windowUserEmail: action.data.payload.calendarUserEmail };
    case PayloadTypes.SNACK_BAR_OPEN:
      return { ...state, snackBar: action.data.payload };
    case PayloadTypes.SET_EVENT_TAGS:
      return { ...state, setEventTags: action.data.payload };
    case PayloadTypes.SCHEDULER_OPEN:
      return {
        ...state,
        schedulerEmails: action.data.payload.schedulerEmails,
        schedulerButtonClicked: new Date().valueOf(),
      };
    case PayloadTypes.PRICING_DIALOG_OPEN:
      return {
        ...state,
        pricingDialogInfo: {
          openPricingDialogMillis: action.data.payload.pricingDialogInfo.openPricingDialogMillis,
          initialView: action.data.payload.pricingDialogInfo.initialView,
        },
      };
    case PayloadTypes.SET_OPEN_SHARE_WITH_COWORKERS_MODAL:
      return {
        ...state,
        openShareWithCoworkersModal: action.data.payload.openShareWithCoworkersModal,
      };
    case PayloadTypes.QUICK_RESCHEDULE:
      return {
        ...state,
        activeView: ChromeAppWrapperView.Event,
        activeViewEvent: {
          externalEventId: action.data.payload.externalEventId,
          calendarId: action.data.payload.calendarId,
          recentlyRescheduled: false,
          openReschedule: false,
          showClockwiseHistory: true,
          currentViewOfEventWasSetByUrlParam: false,
          lastQuickRescheduleMillis: new Date().valueOf(),
        },
      };
    case PayloadTypes.EVENT_VIEW:
      const externalEventId = action.data.payload.externalEventId;
      const calendarId = action.data.payload.calendarId;
      const openReschedule = !!action.data.payload.openReschedule;
      const showClockwiseHistory = action.data.payload.showClockwiseHistory;
      const currentViewOfEventWasSetByUrlParam = !!action.data.payload
        .currentViewOfEventWasSetByUrlParam;
      newState = {
        ...state,
      };
      // EVENT_VIEW from the chrome extension is noisy, only update state
      // if we are sent a defined externalEventId
      if (externalEventId) {
        newState.menuDrawerOpen = false;
        newState.activeView = ChromeAppWrapperView.Event;
        newState.activeViewEvent = {
          externalEventId,
          calendarId,
          openReschedule,
          recentlyRescheduled: false,
          showClockwiseHistory,
          currentViewOfEventWasSetByUrlParam,
          lastQuickRescheduleMillis: undefined,
        };
      }
      return newState;
    case PayloadTypes.STATS_D_ACTION:
      const statsDAction = action.data.payload;
      switch (statsDAction.action) {
        case "RecordDuration":
          recordDuration(statsDAction.metric, statsDAction.value, statsDAction.tags);
          break;
        case "RecordValue":
          recordValue(statsDAction.metric, statsDAction.value, statsDAction.tags);
          break;
        case "IncrementValue":
          incrementValue(statsDAction.metric, statsDAction.tags);
          break;
        default:
          logger.error("failed to send sendStatsDAction over post-message", statsDAction);
          break;
      }
      return state;
    default:
      return state;
  }
}
