import classNames from "classnames";
import React from "react";

interface IProps {
  emphasis: boolean;
  className?: string;
  children: React.ReactNode;
}
export const FeatureComparisonRow = ({ emphasis, className, children }: IProps) => {
  return (
    <div
      className={classNames(
        "cw-flex cw-justify-evenly cw-leading-6 cw-border-0 cw-border-b cw-border-solid",
        emphasis ? "cw-border-emphasis" : "cw-border-default",
        className,
      )}
    >
      {children}
    </div>
  );
};
