import { DateTime } from "luxon";
import React, { useCallback, useMemo, useRef } from "react";

import { CopyText } from "@clockwise/web-commons/src/ui/copy-text/CopyText";
import { TrackingEvents, useTrackOnce } from "@clockwise/web-commons/src/util/analytics.util";
import { formatDuration } from "@clockwise/web-commons/src/util/duration.util";
import { getEditSchedulingLinkPath } from "@clockwise/web-commons/src/util/scheduling.util";
import { Skeleton } from "@material-ui/lab";
import { groupBy } from "lodash";
import { suggestSlotToFormattedSlot } from "./copy-best-times-utils";

export type FormattedSlot = {
  isoDate: string;
  startTime12h: string;
  startTime24h: string;
  relativeDateString: string;
  startMillis: number;
  timeZoneName: string;
};

type SlotsByDay = { isoDate: string; slots: FormattedSlot[] };

interface ICopyTimesPreviewProps {
  slots: { timeSlot: string }[];
  linkUrl: string;
  linkName: string;
  durationMinutes: number;
  targetTimeZone: string;
  loading: boolean;
  slug: string;
  onCopyRichText: (text: string) => void;
  errorMsg: string | null;
  numDaysToShow: number;
  multipleUser: boolean;
  renderedFrom: string;
}

export const formatDurationCopyTimes = (duration: number) =>
  formatDuration(duration, { listStyle: "long" })
    .replace(" and", "")
    .replace("mins", "minute")
    .replace("minutes", "minute")
    .replace("hours", "hour");

const CopyTimesRow = ({
  formatUrl,
  slotsByDay,
}: {
  slotsByDay: SlotsByDay;
  formatUrl: (slot: FormattedSlot) => string;
}) => {
  const { slots } = slotsByDay;

  return (
    <li>
      {slots[0].relativeDateString} &mdash;{" "}
      {slots.map((slot, i) => (
        <span key={slot.startMillis}>
          {i > 0 && ", "}
          <a href={formatUrl(slot)} target="_blank" rel="noreferrer">
            {slot.startTime12h}
          </a>
        </span>
      ))}
    </li>
  );
};

export const CopyTimesPreview = ({
  slots,
  linkUrl,
  linkName,
  durationMinutes,
  targetTimeZone,
  loading,
  onCopyRichText,
  slug,
  errorMsg,
  numDaysToShow,
  multipleUser,
  renderedFrom,
}: ICopyTimesPreviewProps) => {
  const trackCopyTimes = useTrackOnce(TrackingEvents.LINKS.COPY_TIMES.COPY_TIMES);
  const trackCopyTimesAiChat = useTrackOnce(
    TrackingEvents.CHAT.PROPOSAL.COPY_SCHEDULING_LINK.TIMES,
  );
  const trackCopyTimesAiChatLinkCard = useTrackOnce(
    TrackingEvents.CHAT.SCHEDULING_LINK.SHARE_TIMES_MODAL.COPY_TIMES,
  );
  const trackCopyTimesShareableProposal = useTrackOnce(
    TrackingEvents.SHAREABLE_PROPOSAL.MODAL.SCHEDULING_LINK.COPY_TIMES,
  );
  const copyRef = useRef<HTMLDivElement>(null);

  const formattedSlotsByDay = useMemo(() => {
    const formattedSlots = slots.map((s) => suggestSlotToFormattedSlot(s.timeSlot, targetTimeZone));
    const slotsByDay = Object.entries(groupBy(formattedSlots, "isoDate"))
      .map(([isoDate, slots]) => ({ isoDate, slots }))
      .sort((a, b) => (a.isoDate < b.isoDate ? -1 : 1));
    return slotsByDay;
  }, [slots, targetTimeZone]);

  const formatUrl = useCallback(
    (slot: FormattedSlot) => {
      const url = new URL(linkUrl);
      url.searchParams.set("date", slot.isoDate);
      // Legacy formatting behavior:
      // We used to not encode params correctly and the time separator `:` broke the url.
      // The component where this param is used expects `HHmm` format now so we need to keep this
      // for now until we update SchedulingLinkRendererGateway.
      url.searchParams.set("time", slot.startTime24h.replace(":", ""));
      url.searchParams.set("timezone", targetTimeZone);
      url.searchParams.set("duration", durationMinutes.toString(10));
      return url.toString();
    },
    [linkUrl, targetTimeZone, durationMinutes],
  );

  const shortTzString = DateTime.now().setZone(targetTimeZone).offsetNameShort;

  const handleCopyTimesClicked = () => {
    if (copyRef.current) {
      onCopyRichText(copyRef.current.innerHTML);
      if (renderedFrom === "scheduling-links") {
        trackCopyTimes({ numDays: formattedSlotsByDay.length });
      } else if (renderedFrom === "ai-chat") {
        trackCopyTimesAiChat();
      } else if (renderedFrom === "ai-chat-scheduling-link-card") {
        trackCopyTimesAiChatLinkCard();
      } else if (renderedFrom === "shareable-proposal") {
        trackCopyTimesShareableProposal();
      }
    }
  };

  const formattedDuration = formatDurationCopyTimes(durationMinutes);
  const onlySingleSlotAvailable =
    formattedSlotsByDay.length === 1 && formattedSlotsByDay[0].slots.length === 1;

  const noTimesOrErrorMsg = errorMsg ? (
    <div>{errorMsg}</div>
  ) : (
    !loading &&
    !formattedSlotsByDay.length && (
      <div>
        This scheduling link doesn't have any available times to share, try broadening the
        availability.{" "}
        <a
          href={getEditSchedulingLinkPath(linkName, slug)}
          target="_blank"
          rel="noopener noreferrer"
          className="cw-inline-flex cw-text-positive cw-font-bold cw-text-sm cw-no-underline"
        >
          View link settings
        </a>
      </div>
    )
  );

  const copyableRichText = useMemo(() => {
    const usOrMe = multipleUser ? "us" : "me";

    return (
      <div ref={copyRef}>
        <div>
          {onlySingleSlotAvailable
            ? `Here is a ${formattedDuration} time in ${shortTzString} that works for ${usOrMe}:`
            : `Here are some ${formattedDuration} times in ${shortTzString} that work for ${usOrMe}:`}
        </div>
        <ul
          className="cw-list-inside cw-list-disc cw-ml-3 cw-my-3 "
          aria-labelledby="best-days-and-times"
        >
          {loading
            ? [...Array(numDaysToShow).keys()].map((i) => (
                <Skeleton
                  key={i}
                  width={150 + Math.pow(-1, i) * (i * 20)}
                  height={26}
                  className={`-cw-mb-[6px] ${i === 0 ? "-cw-mt-[5px]" : ""}`}
                />
              ))
            : formattedSlotsByDay?.map?.((slotsByDay) => (
                <CopyTimesRow
                  key={slotsByDay.isoDate}
                  slotsByDay={slotsByDay}
                  formatUrl={formatUrl}
                />
              ))}
        </ul>
        <div>
          Let me know if
          {onlySingleSlotAvailable ? " this time works" : " any of these times work"} or view other
          times that work for {usOrMe}{" "}
          <a href={linkUrl} target="_blank" rel="noreferrer" className="cw-text-[blue]">
            here
          </a>
          .
        </div>
      </div>
    );
  }, [
    multipleUser,
    onlySingleSlotAvailable,
    formattedDuration,
    shortTzString,
    loading,
    numDaysToShow,
    formattedSlotsByDay,
    linkUrl,
    formatUrl,
  ]);

  return (
    <div className="cw-leading-5 cw-flex cw-flex-col cw-gap-3 cw-text-muted cw-cursor-default">
      {noTimesOrErrorMsg ? (
        <div className="cw-bg-default-pressed cw-p-5 cw-rounded-lg">{noTimesOrErrorMsg}</div>
      ) : (
        <CopyText
          onCopy={handleCopyTimesClicked}
          copyAriaLabel="copy available times"
          disabled={loading}
        >
          {copyableRichText}
        </CopyText>
      )}
    </div>
  );
};
