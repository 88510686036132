import { ThemeOptions } from "@material-ui/core/styles";
import {
  bg_brand_emphasis,
  bg_default,
  disabled_fg_default,
  fg_accent,
  fg_brand,
  fg_default,
  fg_muted,
  fg_onEmphasis,
  fg_subtle,
} from "../../tokens";
import { fontFamily, fontSize } from "./constants/typography";

import { colors } from "./constants/colors";
const brandFonts = fontFamily.brand.join(", ");
const bodyFonts = fontFamily.body.join(", ");

export const cwMuiBase: ThemeOptions = {
  shape: {
    borderRadius: 4,
  },
  palette: {
    primary: {
      main: bg_brand_emphasis,
      contrastText: fg_onEmphasis,
    },
    secondary: {
      main: colors.sky.standard,
    },
    text: {
      primary: fg_default,
      secondary: fg_muted,
      disabled: disabled_fg_default,
      hint: fg_subtle,
    },
    action: {
      hoverOpacity: 0.06,
    },
  },
  typography: {
    fontFamily: bodyFonts,
    fontWeightLight: "normal",
    fontWeightRegular: "normal",
    fontWeightMedium: "normal",
    h1: {
      fontFamily: brandFonts,
      fontWeight: "bold",
      fontSize: fontSize[36],
      lineHeight: 1.25,
      color: fg_accent,
    },
    h2: {
      fontFamily: brandFonts,
      fontWeight: "bold",
      fontSize: fontSize[23],
      lineHeight: 1.25,
      color: fg_accent,
    },
    h3: {
      fontFamily: brandFonts,
      fontWeight: "bold",
      fontSize: fontSize[20],
      lineHeight: 1.25,
      color: fg_accent,
    },
    h4: { fontFamily: brandFonts },
    h5: { fontFamily: brandFonts, fontWeight: "bold", color: fg_accent },
    h6: { fontFamily: brandFonts, fontWeight: "bold", color: fg_accent },
    subtitle1: {
      fontFamily: bodyFonts,
      fontSize: fontSize[16],
      lineHeight: 1.5,
      color: fg_muted,
    },
    subtitle2: { fontFamily: bodyFonts },
    caption: { fontFamily: bodyFonts },
    button: { fontFamily: brandFonts, fontWeight: "bold" },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: "6px 14px",
        display: "flex",
        alignItems: "center",
        "&$disabled": {
          pointerEvents: "all !important" as "all",
          cursor: "not-allowed !important",
        },
      },
      contained: {
        backgroundColor: bg_default,
        boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
        "&:active": {
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 2px 12px, rgba(0, 0, 0, 0.24) 0px 2px 8px",
        },
      },
      containedSecondary: {
        backgroundColor: bg_default,
        color: fg_default,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 2,
        paddingBottom: 2,
      },
    },
    MuiListItemIcon: {
      root: {
        marginRight: 0,
        minWidth: 0,
      },
    },
    MuiListItemText: {
      inset: {
        paddingLeft: 18,
        paddingRight: 0,
        "&:first-child": {
          paddingLeft: 38,
        },
      },
      primary: {
        fontSize: fontSize[14],
        color: fg_default,
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: 40,
        fontSize: fontSize[14],
      },
    },
    MuiDialog: {
      root: {
        fontFamily: bodyFonts,
        color: fg_default,
      },
    },
    MuiDialogContent: {
      root: {
        padding: 24,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: fontSize[14],
        color: fg_default,
      },
    },
    MuiInput: {
      underline: {
        "&:hover:not($disabled):before": {
          borderBottomWidth: 1,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "12px 14px",
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "unset",
        },
      },
    },
    MuiSelect: {
      select: {
        paddingLeft: 10,
        fontSize: fontSize[14],
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: bg_brand_emphasis,
        height: 4,
      },
    },
    MuiTab: {
      textColorInherit: {
        minWidth: "unset",
        fontWeight: "bold",
        fontSize: fontSize[12],
        opacity: 1,
        color: fg_default,
      },
      root: {
        "&$selected": {
          color: fg_brand,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: fontSize[12],
      },
      tooltipPlacementTop: {
        margin: "4px 0 !important",
      },
      tooltipPlacementBottom: {
        margin: "4px 0 !important",
      },
      tooltipPlacementLeft: {
        margin: "0 4px !important",
      },
      tooltipPlacementRight: {
        margin: "0 4px !important",
      },
    },
  },
};
