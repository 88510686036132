import { graphql } from "react-relay";

export const upsertNonUserDefragEmailResponseMutation = graphql`
  mutation UpsertNonUserDefragEmailResponseMutation(
    $input: UpsertNonUserDefragEmailResponseInput!
  ) {
    upsertNonUserDefragEmailResponse(input: $input) {
      nonUserDefragEmailResponse {
        id
        responseType
        additionalInformation
      }
    }
  }
`;
