export enum SelectedRescheduleOptionEntrypoint {
  QuickReschedule = "quick-reschedule",
  DirectManipulation = "direct-manipulation",
}

export type SelectedRescheduleOption = {
  externalEventId: string;
  duration: number; // in minutes
  title: string;
  proposedStartTimeISO: string;
  currentStartTimeISO: string;
  id: string;
  entryPoint: SelectedRescheduleOptionEntrypoint;
  showACopyOfNewProposedEvent?: boolean;
  hideOriginalEvent?: boolean;
  newDuration?: number;
  showFindMoreTimes?: boolean;
  isRecurring?: boolean;
  recurringEventId?: string | null;
  isSaving?: boolean;
  isHold?: boolean;
};

export enum RescheduleEventModalActions {
  AddSelectedRescheduleOption = "RescheduleEventModal_AddSelectedRescheduleOption",
  ClearSpecificSelectedRescheduleOption = "RescheduleEventModal_clearSpecificSelectedRescheduleOption",
}

export const addSelectedRescheduleOption = (
  selectedRescheduleOption: SelectedRescheduleOption,
) => ({
  type: RescheduleEventModalActions.AddSelectedRescheduleOption,
  data: {
    selectedRescheduleOption: selectedRescheduleOption,
  },
});

export const clearSpecificSelectedRescheduleOption = (externalEventId?: string) => ({
  type: RescheduleEventModalActions.ClearSpecificSelectedRescheduleOption,
  data: {
    externalEventId,
  },
});
