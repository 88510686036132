import { FieldMergeFunction, TypePolicies } from "@apollo/client";

type CacheRef = { __ref: string };

const deepMerge: FieldMergeFunction = (
  existing: CacheRef[] = [],
  incoming: CacheRef[],
  { readField, mergeObjects },
) => {
  const merged = existing ? existing.slice(0) : [];
  const timeslotIdToIndex: Record<string, number> = {};
  if (existing) {
    existing.forEach((timeslot, index) => {
      const id = readField<string>("id", timeslot);
      if (id) {
        timeslotIdToIndex[id] = index;
      }
    });
  }
  incoming.forEach((timeslot) => {
    const id = readField<string>("id", timeslot);
    if (id) {
      const index = timeslotIdToIndex[id];
      if (typeof index === "number") {
        // Merge the new timeslot data with the existing timeslot data.
        merged[index] = mergeObjects(merged[index], timeslot);
      } else {
        // First time we've seen this timeslot in this array.
        timeslotIdToIndex[id] = merged.length;
        merged.push(timeslot);
      }
    }
  });
  return merged;
};

export const gatewayTypePolicies: TypePolicies = {
  PublicSchedulingLink: {
    fields: {
      linkTimes: {
        keyArgs: ["timeZone", "duration"],
        merge: deepMerge,
      },
    },
  },
  Team: {
    fields: {
      noMeetingDay: {
        merge: true,
      },
    },
  },
  LinkBooking: {
    fields: {
      linkTimes: {
        keyArgs: ["timeZone", "duration"],
        merge: deepMerge,
      },
    },
  },
  Event: {
    fields: {
      flexibility: {
        merge: true,
      },
    },
  },
  EventPermissions: {
    merge: true,
  },
  PaidFeatureState: {
    merge: true,
  },
};
