// schema
// import * as ISchema from "#webapp/src/__schema__";

// state
import { FeatureGridActions } from "#webapp/src/state/actions/feature-grid.actions";
import {
  IFeatureGridReduxState,
  initialFeatureGridState,
} from "@clockwise/web-commons/src/util/feature-grid.util";

import { PostMessageManager } from "#webapp/src/util/post-message.util";

export interface IFeatureGridAction {
  type: FeatureGridActions;
  data: {
    featureStates: IFeatureGridReduxState;
  };
}

export function featureGridReducer(
  state: IFeatureGridReduxState = initialFeatureGridState,
  action: IFeatureGridAction,
) {
  switch (action.type) {
    case FeatureGridActions.SetFeatureGridStates:
      const newState = {
        ...state,
        ...action.data.featureStates,
      };

      PostMessageManager.sendFeatureGrid(newState);
      return newState;
    default:
      return {
        ...state,
      };
  }
}
