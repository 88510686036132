export const sidePanelQueryParam = "sidepanel";

export type Chrome = {
  chrome: {
    webstore: {
      install: (url?: string, successCallback?: () => void, failureCallback?: () => void) => void;
    };
    app: {
      isInstalled: boolean;
    };
  };
};

export enum ChromeAppWrapperView {
  CalendarMismatch = "CalendarMismatch",
  CalendarProfile = "CalendarProfile",
  CreateEvent = "CreateEvent",
  Day = "Day",
  Event = "Event",
  EventScheduler = "EventScheduler",
  GoogleApiAccessDisabled = "GoogleApiAccessDisabled",
  GoogleContactSharingDisabled = "GoogleContactSharingDisabled",
  NoExternalOrganization = "NoExternalOrganization",
  GenericCrawlFailure = "GenericCrawlFailure",
  ShouldCrawl = "ShouldCrawl",
  NoCrawl = "NoCrawl",
  NoAuth = "NoAuth",
  NoOrg = "NoOrg",
  Onboarding = "Onboarding",
  PersonalCalendar = "PersonalCalendar",
  TeamHealth = "TeamHealth",
}

// a higher index in this stack indicates a higher z-index visually
// so, an item at the bottom will slide over an item at the top
export const ChromeViewStack = [
  // the base
  ChromeAppWrapperView.Day,
  ChromeAppWrapperView.TeamHealth,
  // end of the base
  ChromeAppWrapperView.CalendarProfile,
  // feels like all events are on top of each other?
  ChromeAppWrapperView.Event,
  ChromeAppWrapperView.PersonalCalendar,
  ChromeAppWrapperView.CreateEvent,
  ChromeAppWrapperView.EventScheduler,
  // all of these error states are on top?
  ChromeAppWrapperView.Onboarding,
  ChromeAppWrapperView.NoAuth,
  ChromeAppWrapperView.NoOrg,
  ChromeAppWrapperView.CalendarMismatch,
  ChromeAppWrapperView.GoogleContactSharingDisabled,
  ChromeAppWrapperView.GoogleApiAccessDisabled,
  ChromeAppWrapperView.NoCrawl,
];

export enum ChromeSite {
  Gcal = "Gcal",
  Unknown = "Unknown",
}

export function getChromeSite() {
  const maybeWindow = typeof window !== "undefined" ? window : undefined;
  if (maybeWindow?.location.href.search(`[?&]site=${ChromeSite.Gcal.toLowerCase()}`) !== -1) {
    return ChromeSite.Gcal;
  }
  return ChromeSite.Unknown;
}
