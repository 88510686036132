import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SetFlagForUserMutationVariables = Types.Exact<{
  namespace: Types.Scalars['String'];
  flags: Array<Types.Scalars['String']> | Types.Scalars['String'];
  enable: Types.Scalars['Boolean'];
}>;


export type SetFlagForUserMutation = { __typename: 'Mutation', updateFlagsForUser: { __typename: 'UpdateFlagsForUserMutationPayload', flags: { __typename: 'Flags', id: string, flags: Array<string | null> | null } | null } | null };


export const SetFlagForUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetFlagForUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"namespace"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"flags"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"enable"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateFlagsForUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"flagNamespace"},"value":{"kind":"Variable","name":{"kind":"Name","value":"namespace"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"flagNames"},"value":{"kind":"Variable","name":{"kind":"Name","value":"flags"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"enable"},"value":{"kind":"Variable","name":{"kind":"Name","value":"enable"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"flags"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"flags"}}]}}]}}]}}]} as unknown as DocumentNode<SetFlagForUserMutation, SetFlagForUserMutationVariables>;