import { SchedulingProposalStateEnum } from "@clockwise/schema";
import { useMemo } from "react";
import {
  parseCalendarForSharedProposalGQL,
  parseTradeoffsFromCalendarForSharedProposalGQL,
} from "../chat-plus-calendar/util/parseCalendarProposalGQL";
import { GQLProposal } from "../chat/ai-chat/utils/types";

export const SharedProposalEventCards = (sharedProposal: GQLProposal | null) => {
  const cardsByDay = useMemo(
    () =>
      sharedProposal?.state === SchedulingProposalStateEnum.ACTIVE
        ? parseCalendarForSharedProposalGQL(sharedProposal).orDefault({})
        : {},
    [sharedProposal],
  );
  const modifiedCardDiffs = useMemo(
    () =>
      sharedProposal?.state === SchedulingProposalStateEnum.ACTIVE
        ? parseTradeoffsFromCalendarForSharedProposalGQL(sharedProposal).orDefault([])
        : [],
    [sharedProposal],
  );

  return {
    cardsByDay,
    modifiedCardDiffs,
    isScheduleByMoving: !!sharedProposal?.consequencesBlock,
    error: null,
    loading: false,
  };
};
