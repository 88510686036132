// schema
import { compact } from "lodash";
import { Edges } from "./org.util";

type Org = {
  workingGroups: Edges<{ id: string } | undefined>;
};

/**
 * Given an `Org` object, this type extracts the `WorkingGroup` object type from it, assuming all
 * the various fields are not null. This allows for a variety of different Org objects to be used in
 * a type-safe manner, e.g. an exhaustive Org object from Relay vs. a sparse Org object from an
 * Apollo query.
 */
type DefiniteWorkingGroup<O extends Org> = NonNullable<
  NonNullable<O["workingGroups"]["edges"]>[0]
>["node"];

export function getCurrentWorkingGroup<O extends Org>(
  org: O | null,
): DefiniteWorkingGroup<O> | null {
  if (!org || !org.workingGroups || !org.workingGroups.edges || !org.workingGroups.edges.length) {
    return null;
  }

  // find the right org depending on orgId state
  const edges = compact(org.workingGroups.edges ?? []);
  const workingGroup = edges[0]?.node;

  return workingGroup;
}
