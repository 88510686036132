import React from "react";

// material-ui
import { Button } from "@clockwise/design-system";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

// internals
import { styles } from "./snack-bar-content.styles";

export type SnackBarContentVariant = "success" | "error" | "info" | "warning" | "default";

interface IProps extends WithStyles<typeof styles> {
  isChromeExtension: boolean;
  actionLabel?: string;
  message: React.ReactNode;
  variant: SnackBarContentVariant;
  dismissable: boolean | undefined;
  onClose?: (event?: React.SyntheticEvent<any>, reason?: string) => void;
  onAction: () => void;
}

class SnackBarContentComponent extends React.PureComponent<IProps> {
  public static defaultProps = {
    variant: "default" as SnackBarContentVariant,
    onAction: () => null,
  };

  ///////////////
  // Rendering //
  ///////////////

  public render() {
    const { dismissable, message, onClose, variant, actionLabel, onAction } = this.props;

    const classes = [
      this.props.classes[`${variant}Content`],
      this.props.classes[this.props.isChromeExtension ? "chromeContent" : "content"],
    ].join(" ");

    const hasAction = actionLabel || dismissable;

    const messageClass = this.props.classes[
      hasAction ? "messageContainerWithActions" : "messageContainer"
    ];

    return (
      <SnackbarContent
        className={classes}
        classes={{
          action: this.props.classes[this.props.isChromeExtension ? "chromeAction" : "action"],
          message: messageClass,
        }}
        message={message}
        action={[
          actionLabel ? (
            <Button key="action" variant="outlined" onClick={onAction}>
              {actionLabel}
            </Button>
          ) : null,
          dismissable ? (
            <Button key="dismiss" variant="outlined" onClick={onClose}>
              Ok
            </Button>
          ) : null,
        ]}
      />
    );
  }
}

/////////////////
// With Styles //
/////////////////

export const SnackBarContent = withStyles(styles)(SnackBarContentComponent);
