export interface IUrn {
  id: string;
  type: string;
  value: string;
  scope: IUrn;
}

export class UrnUtil {
  public static DELIMITER = "::";

  public static createCalendarWeekUrn(
    calendarId: string,
    orgId: string,
    startDate: string,
    timeZone: string,
  ): IUrn {
    const urn = this.createUrn("Teammate", calendarId);
    this.setScope(urn, "Org", orgId);

    const dateUrn = this.createDateUrn(startDate, timeZone);
    urn.scope.scope = dateUrn;
    return urn;
  }

  public static createDateUrn(date: string, timeZone?: string): IUrn {
    const urn = this.createUrn("Date", date);
    this.setScope(urn, "TimeZone", timeZone);
    return urn;
  }

  public static createEventParentUrn(externalEventId: string, orgId: string) {
    const urn = this.createUrn("EventParent", externalEventId);
    this.setScope(urn, "Org", orgId);
    return urn;
  }

  public static createTeamUrn(teamId: string, orgId: string) {
    const urn = UrnUtil.createUrn("Team", teamId);
    UrnUtil.setScope(urn, "Org", orgId);
    return urn;
  }

  public static serialize(urn: IUrn): string {
    const urnParts: string[] = [];
    urnParts.push(urn.type.toString());
    urnParts.push(this.DELIMITER);
    urnParts.push(urn.value);
    if (urn.scope) {
      urnParts.push(this.DELIMITER);
      urnParts.push(this.serialize(urn.scope));
    }
    return urnParts.join("");
  }

  public static deserialize(urnString: string) {
    const parts = urnString.split(this.DELIMITER);
    if (parts.length < 2 || parts.length % 2 !== 0) {
      throw new TypeError(`Wrong number of parts to parse urn ${urnString}`);
    }
    const urnType = parts[0];
    const urn = this.createUrn(urnType, parts[1]);

    // recursively generate the scope
    if (parts.length > 2) {
      urn.scope = this.deserialize(parts.slice(2, parts.length).join(this.DELIMITER));
    }

    return urn;
  }

  private static createUrn(urnType: string, urnValue: string) {
    return {
      type: urnType,
      value: urnValue,
    } as IUrn;
  }

  private static setScope(urn: IUrn, scopeType: string, scopeValue?: string) {
    if (scopeValue) {
      const scope = this.createUrn(scopeType, scopeValue);
      urn.scope = scope;
    }
  }
}
