import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { LogoHorizontal } from "@clockwise/web-commons/src/components/logo";
import { useWindowSize } from "@clockwise/web-commons/src/util/react.util";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { InviteModalMode } from "./";
import { InviteModalContent } from "./InviteModalContent";
import { LockedDialog } from "./LockedDialog";
import { TrialExtensionConfirmation } from "./TrialExtensionConfirmation";

interface InviteModalProps {
  isOpen: boolean;
  onClose: () => void;
  mode?: InviteModalMode;
}
export const InviteModal = ({
  isOpen,
  onClose,
  mode = InviteModalMode.Invite,
}: InviteModalProps) => {
  const { width: windowWidth } = useWindowSize();

  const closeInviteModal = () => {
    if (mode === InviteModalMode.Invite) {
      track(TrackingEvents.INVITE_DIALOG.CANCEL);
    } else {
      track(TrackingEvents.TRIAL_EXTENSION.DISMISSED);
    }
    onClose();
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (mode === InviteModalMode.Invite) {
        track(TrackingEvents.INVITE_DIALOG.VIEWED);
      } else if (mode === InviteModalMode.TrialExtension) {
        track(TrackingEvents.TRIAL_EXTENSION.MODAL_VIEWED);
      }
    }
  }, [isOpen, mode]);

  return (
    <LockedDialog
      disableRestoreFocus
      disableScrollLock
      fullScreen={windowWidth < 500}
      maxWidth={false}
      onClose={closeInviteModal}
      open={isOpen}
    >
      <div className="cw-relative cw-rounded-md cw-w-[1200px]">
        {/* Top Bar */}
        <div className="cw-py-5 cw-px-7 cw-flex cw-border-0 cw-border-b cw-border-solid cw-border-default">
          <div className="cw-w-[150px]">
            <LogoHorizontal className="cw-h-5" />
          </div>
        </div>
        <div onClick={closeInviteModal} className="cw-absolute cw-top-3 cw-right-3">
          <Close className="cw-w-6 cw-h-6 cw-text-subtle cw-cursor-pointer" />
        </div>
        {/* Modal Content */}
        {showConfirmation ? (
          <TrialExtensionConfirmation onClose={closeInviteModal} />
        ) : (
          <InviteModalContent
            isOpen={isOpen}
            onClose={onClose}
            mode={mode}
            setShowConfirmation={setShowConfirmation}
          />
        )}
      </div>
    </LockedDialog>
  );
};
