/**
 * @generated SignedSource<<d532c92389f2248198c2f1a75330213e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MixedCheckoutForm_team$data = {
  readonly teamName: string;
  readonly teamId: string;
  readonly teamMembers: ReadonlyArray<{
    readonly person: {
      readonly personId: string;
      readonly profile: {
        readonly givenName: string | null;
        readonly familyName: string | null;
      } | null;
    };
  }>;
  readonly invitedMembers: ReadonlyArray<{
    readonly person: {
      readonly personId: string;
      readonly profile: {
        readonly givenName: string | null;
        readonly familyName: string | null;
      } | null;
    };
  }>;
  readonly " $fragmentType": "MixedCheckoutForm_team";
};
export type MixedCheckoutForm_team$key = {
  readonly " $data"?: MixedCheckoutForm_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"MixedCheckoutForm_team">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OrgPerson",
    "kind": "LinkedField",
    "name": "person",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "personId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfile",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "givenName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "familyName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MixedCheckoutForm_team",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "teamName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "teamId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamMember",
      "kind": "LinkedField",
      "name": "teamMembers",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamMember",
      "kind": "LinkedField",
      "name": "invitedMembers",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Team",
  "abstractKey": null
};
})();

(node as any).hash = "8e67d9e591670d3760473ef6164ce6de";

export default node;
