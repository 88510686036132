import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FlowStateFragmentFragmentDoc } from '../../components/login-btn/__generated__/LoginGoogleUser.generated';
export type CurrentFlowStateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentFlowStateQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', id: string, user: { __typename: 'User', createdTime: any | null, id: string, flowStates: { __typename: 'FlowStateConnection', edges: Array<{ __typename: 'FlowStateEdge', node: { __typename: 'FlowState', id: string, flowKey: string | null, previousState: string | null, current: { __typename: 'FlowStatus', id: string, state: string | null, percentComplete: number | null, errorMessage: string | null } | null, pending: { __typename: 'FlowStatus', id: string, state: string | null, percentComplete: number | null, errorMessage: string | null } | null } | null } | null> | null } | null, orgs: { __typename: 'OrgConnection', edges: Array<{ __typename: 'OrgEdge', node: { __typename: 'Org', id: string, flowStates: { __typename: 'FlowStateConnection', edges: Array<{ __typename: 'FlowStateEdge', node: { __typename: 'FlowState', id: string, flowKey: string | null, previousState: string | null, current: { __typename: 'FlowStatus', id: string, state: string | null, percentComplete: number | null, errorMessage: string | null } | null, pending: { __typename: 'FlowStatus', id: string, state: string | null, percentComplete: number | null, errorMessage: string | null } | null } | null } | null> | null } | null } | null } | null> | null } } | null } };


export const CurrentFlowStateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CurrentFlowState"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createdTime"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"flowStates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FlowStateFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"orgs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"flowStates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FlowStateFragment"}}]}}]}}]}}]}}]}}]}}]}},...FlowStateFragmentFragmentDoc.definitions]} as unknown as DocumentNode<CurrentFlowStateQuery, CurrentFlowStateQueryVariables>;