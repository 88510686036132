// IMPORTS
// schema
import { modernCache } from "#webapp/src/network/modern-network-layer";
import { PreEventFlexibility, PreEventKey, PreSetFlexibilityResponse } from "@clockwise/schema";
import { commitMutation } from "react-relay";
import { Environment } from "relay-runtime";
import { preSetEventFlexibilityMutation } from "./PreSetEventFlexibility.gql";

// MUTATION
export function preSetEventFlexibility(
  environment: Environment,
  orgRelayId: string,
  calendarId: string,
  preEventKey: PreEventKey,
  preEventFlexibility: PreEventFlexibility,
  onSuccess: (response?: PreSetFlexibilityResponse) => void,
  onError: (error?: any) => void,
) {
  const variables = {
    input: {
      orgRelayId,
      calendarId,
      preEventKey,
      preEventFlexibility,
    },
  };
  commitMutation(environment, {
    onError,
    variables,
    mutation: preSetEventFlexibilityMutation,
    onCompleted: (response: PreSetFlexibilityResponse, errors?: Error[]) => {
      if (errors) {
        onError(errors[0]);
      } else {
        // if we're not in a modern environment, modernCache will be undefined
        if (modernCache) {
          modernCache.clear();
        }
        onSuccess(response);
      }
    },
  });
}
