import { getDomainOffEmail } from "@clockwise/client-commons/src/util/email";
import { IgnoreState } from "@clockwise/client-commons/src/util/event-tag";
import { ExperimentMappingType } from "@clockwise/client-commons/src/util/experiment";
import { getTimeZoneGuess } from "@clockwise/client-commons/src/util/time-zone";
import { MovableUntil } from "@clockwise/schema";
import { ISharedState, SharedAction, SharedActions } from "@clockwise/web-commons/src/state";
import { unpauseEventMutation } from "../mutations/unpause-event/UnpauseEvent.gql";
import {
  IChromeExtensionActionData,
  newMessageEventDataFromChromeExtension,
  setMutationCollection,
  setQueryCollection,
  setShouldSyncActiveOrg,
} from "../state/actions/chrome-extension.actions";
// Experiments
import { DayOnOffMap } from "@clockwise/web-commons/src/ui/working-hours";
import { IFeatureGridReduxState } from "@clockwise/web-commons/src/util/feature-grid.util";
import {
  BasePostMessageManager,
  InterAppPayload,
  PayloadTypes,
} from "@clockwise/web-commons/src/util/post-message-common.util";
import * as Sentry from "@sentry/browser";
import * as ISchema from "../__schema__";
import { getClient } from "../network/apollo/client";
import { modernCache } from "../network/modern-network-layer";
import { orgPrimaryEmail, renderTimeZoneMap } from "../state/local-storage";
import { IReduxState } from "../state/reducers/root.reducer";
import { getReduxStore } from "../state/redux-store";
import { getCurrentEnvironment } from "../state/relay-environment";
import { track } from "../util/analytics.util";
import { ChromeSite, getChromeSite } from "../util/chrome-extension.util";
// Experiments
import { LaunchDarklyMappingType } from "@clockwise/client-commons/src/util/launch-darkly-flags";
import { setRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { commitMutation, fetchQuery, GraphQLTaggedNode } from "react-relay";
import { Store } from "redux";
import { IUpdatedEventInfo } from "../util/google-edit-page.util";
import { ddLog, inMemoryCacheTransport, logger } from "../util/logger.util";
import { recordDuration } from "../util/stats.util";
import { canAccessExperiments } from "./auth.util";

class PostMessageManagerClass extends BasePostMessageManager {
  private store: Store<IReduxState>;
  private registeredQueries: { [key: string]: GraphQLTaggedNode } = {};
  private registeredMutations: { [key: string]: GraphQLTaggedNode } = {
    unpauseEventMutation,
  };

  constructor(postMessageUrl: string) {
    super(postMessageUrl);
  }

  public init = (store: Store<IReduxState>) => {
    this.store = store;
    window.addEventListener("message", this.chromeDataListener, false);
    // note: in theory this handshake could fire before the parent is ready
    // but because the chrome extension renders the iFrame AFTER it mounts
    // we know that can't happen here
    if (window !== window.parent) {
      // if our parent is ourselves, we're not in an iframe and so there
      // cannot be anyone to send messages to
      this.initBase(window.parent);
      this.send(new InterAppPayload(this.payloadTypes.INITIAL_HANDSHAKE, undefined));

      // send a health check to the extension every 5 seconds
      // if a delay of >10s is reached, we will refresh the iframe and assume it got into a stuck state
      // only known "stuck state" as of now is ECONN RESET which does not seem preventable
      setInterval(
        () =>
          this.send(
            new InterAppPayload(this.payloadTypes.HEALTH_CHECK, {
              chromeDialogOpen: store.getState().webExtension.webExtensionDialogOpen,
            }),
          ),
        5000,
      );
    }
  };

  // ~~~~~~~~~~~~~~~
  // From Chrome
  // ~~~~~~~~~~~~~~~
  private chromeDataListener = async (e: MessageEvent) => {
    if (
      (e.origin === this.otherWindowUrl || e.origin === window.location.origin) &&
      e.data.source === "clockwise"
    ) {
      const environment = getCurrentEnvironment();

      // special handling for specific payload types
      switch (e.data.type) {
        case this.payloadTypes.TIME_ZONE:
          let timeZoneEmail = e.data.payload.email;
          // TEMP: this setter fallback is only needed until chrome 0.6.47
          if (!timeZoneEmail) {
            timeZoneEmail = orgPrimaryEmail.get();
          }
          // if we get a new time zone and its for our primary org email, set it
          const curRenderTimeZone = renderTimeZoneMap.get(timeZoneEmail);
          if (
            curRenderTimeZone !== e.data.payload.timeZone &&
            timeZoneEmail === orgPrimaryEmail.get()
          ) {
            setRenderTimeZone(e.data.payload.timeZone);
            // window.location.reload();
          }
          return; // return early
        case this.payloadTypes.CHROME_EXTENSION_ERROR: // Deprecated
        case this.payloadTypes.WEB_EXTENSION_ERROR:
          const errorObj = JSON.parse(e.data.payload.error);
          const error = new Error();
          error.name = errorObj.name;
          error.message = errorObj.message;
          error.stack = errorObj.stack;
          Sentry.captureException(error);
          return;
        case this.payloadTypes.CHROME_EXTENSION_LOG: // Deprecated
        case this.payloadTypes.WEB_EXTENSION_LOG:
          if (!canAccessExperiments()) {
            return;
          }

          const { level, message, meta } = e.data.payload;

          ddLog(level, message, "chrome-extension", meta);

          inMemoryCacheTransport.log(`chrome-extension: ${message}`, level, [meta]);

          return;
        case this.payloadTypes.COMMIT_MUTATION:
          const { mutationId, mutationName, mutationVariables } = e.data.payload;

          const mutation = this.registeredMutations[mutationName];

          if (!mutation) {
            this.sendCommitMutation(mutationId, {
              error: new Error(`Unknown mutation: ${mutationName}`),
            });
            break;
          }

          try {
            commitMutation(environment, {
              onError: (error) => this.sendCommitMutation(mutationId, { error }),
              mutation,
              variables: mutationVariables,
              onCompleted: (response: any, errors?: Error[]) => {
                if (errors) {
                  this.sendCommitMutation(mutationId, { error });
                } else {
                  // if we're not in a modern environment, modernCache will be undefined
                  if (modernCache) {
                    modernCache.clear();
                  }
                  this.sendCommitMutation(mutationId, { data: response });
                }
              },
            });
          } catch (error) {
            this.sendCommitMutation(mutationId, { error });
          }

          break;
        case this.payloadTypes.FETCH_QUERY:
          const { queryId, queryName, queryVariables } = e.data.payload;

          const query = this.registeredQueries[queryName];

          if (!query) {
            this.sendFetchQuery(queryId, {
              error: new Error(`Unknown query: ${queryName}`),
            });
            break;
          }

          try {
            const data = (await fetchQuery(environment, query, queryVariables)) as object;

            this.sendFetchQuery(queryId, { data });
          } catch (error) {
            this.sendFetchQuery(queryId, { error });
          }

          break;
        case this.payloadTypes.SEND_QUERY_COLLECTION: {
          getReduxStore().dispatch(setQueryCollection(e.data.payload.activeQueryCollection));

          break;
        }
        case this.payloadTypes.SEND_MUTATION_COLLECTION: {
          getReduxStore().dispatch(setMutationCollection(e.data.payload.activeMutationCollection));

          break;
        }
        case this.payloadTypes.FETCH_APOLLO_QUERY: {
          // IMPORTANT NOTE: This must stay until all old extension clients are off the
          // last version that sends `FETCH_APOLLO_QUERY`

          const { queryId } = e.data?.payload || {};

          if (e.data.payload?.options?.skip) {
            return;
          }

          try {
            // Hook up `errors, networkStatus, partial` when needed
            const { data, loading, error } = await getClient().query({
              query: e.data.payload?.query,
              variables: e.data.payload?.options?.variables,
              fetchPolicy: "network-only",
            });

            this.sendFetchApolloQuery(queryId, { data, loading, error });
          } catch (error) {
            this.sendFetchApolloQuery(queryId, { error, data: null, loading: false });
          }

          break;
        }
        case this.payloadTypes.COMMIT_APOLLO_MUTATION: {
          // IMPORTANT NOTE: This must stay until all old extension clients are off the
          // last version that sends `COMMIT_APOLLO_MUTATION`

          const { mutationId } = e.data?.payload || {};

          try {
            // Hook up `extensions, context` when needed
            const { data } = await getClient().mutate({
              mutation: e.data.payload?.mutation,
              variables: e.data.payload?.options?.variables,
            });

            this.sendCommitApolloMutation(mutationId, { data, error: undefined, loading: false });
          } catch (error) {
            this.sendCommitApolloMutation(mutationId, { data: undefined, error, loading: false });
          }

          break;
        }

        case this.payloadTypes.INIT_TIME:
          const now = Date.now();
          const failedHealthChecks = e.data.payload.failedHealthChecks;
          const tti = now - e.data.payload.initTime;
          const ttlChromeExtension = (window as any).initTime - e.data.payload.initTime;
          const ttlWebapp = now - (window as any).initTime;
          const networkEffectiveType =
            navigator &&
            (navigator as any).connection &&
            (navigator as any).connection.effectiveType;
          const failedHealthCheck = failedHealthChecks ? "true" : "false";
          const tz = getTimeZoneGuess();

          const tags: Record<string, string> = {};
          tags.project = "chrome";
          tags.failedHealthCheck = failedHealthCheck;
          tags.tz = tz;

          if (networkEffectiveType) {
            tags.networkEffectiveType = networkEffectiveType;
          }

          logger.debug("Recording TTI values", {
            failedHealthCheck,
            tz,
            networkEffectiveType,
            tti,
            ttlChromeExtension,
            ttlWebapp,
          });

          recordDuration("client.tti", tti, tags);
          recordDuration("client.ttl.chrome_extension", ttlChromeExtension, tags);
          recordDuration("client.ttl.webapp", ttlWebapp, tags);

          break;
        case this.payloadTypes.EVENT_VIEW:
          // bail if the sidebar is not open
          // only bail if uber.com
          const domain = e.data.payload.calendarId && getDomainOffEmail(e.data.payload.calendarId);
          const isUberDomain = domain && domain.endsWith("uber.com");
          if (!getReduxStore().getState().webExtension.webExtensionOpen && isUberDomain) {
            return;
          }

          // make sure to add last active millis when the event page is visible
          if (!!e.data.payload.calendarId && !!e.data.payload.externalEventId) {
            e.data.payload.chromeExtensionEventActive = new Date().valueOf();
          }
          break;

        case this.payloadTypes.DEBUG_MESSAGE:
          if (e.data.payload.message && e.data.payload.data) {
            logger.debug(`chrome-extension: ${e.data.payload.message}`, e.data.payload.data);
          }

          break;
        case this.payloadTypes.TRACK:
          if (getReduxStore().getState().auth.authed || e.data.payload.trackUnauthed) {
            track(e.data.payload.trackingString, e.data.payload.properties);
          }

          break;
        case this.payloadTypes.LOGS:
          this.sendLogs();

          break;
        case this.payloadTypes.SYNC_ACTIVE_ORG:
          getReduxStore().dispatch(setShouldSyncActiveOrg(true));
          break;
        case this.payloadTypes.SYNCED_ACTION:
          const action = e.data.payload as SharedAction;

          // we want to sync our initial state back with the chrome extension
          if (action.type === SharedActions.MergeInitialStates) {
            this.mergeInitialState(getReduxStore().getState().shared);
          }

          getReduxStore().dispatch(action);
          break;
        default:
        // do nothing
      }

      // the important part! dispatch the data to redux
      this.dispatchMessageEventDataToRedux(e.data);
    }
  };

  private dispatchMessageEventDataToRedux = (data: IChromeExtensionActionData) => {
    if (!this.store) {
      logger.warn("tried to dispatch before store set!");
      return;
    }

    if (!data) {
      return;
    }

    const onboardingState = getReduxStore().getState().onboarding;

    if (data.type === PayloadTypes.EVENT_VIEW && !onboardingState.onboardingFinished) {
      return;
    }

    this.store.dispatch(newMessageEventDataFromChromeExtension(data));
  };

  // ~~~~~~~~~~~~~~~
  // Public API
  // ~~~~~~~~~~~~~~~
  public sendCalendarIds = (calendarIds: string[]) => {
    this.send(new InterAppPayload(this.payloadTypes.USER_CALENDAR_IDS, { calendarIds }));
  };

  public toggleSidebar = (open: boolean, setUserPreference: boolean) => {
    this.send(
      new InterAppPayload(this.payloadTypes.CHROME_EXTENSION_OPEN, {
        setUserPreference,
        chromeExtensionOpen: open,
      }),
    );

    // update redux open state (async to avoid render/state warning)
    setTimeout(() => {
      this.dispatchMessageEventDataToRedux(
        new InterAppPayload(this.payloadTypes.CHROME_EXTENSION_OPEN, { chromeExtensionOpen: open }),
      );
    });
  };

  public setSignInNeeded = (signInNeeded: boolean) =>
    this.send(new InterAppPayload(this.payloadTypes.SIGN_IN_NEEDED, { signInNeeded }));

  public openSidebar = (setUserPreference?: boolean) => {
    this.toggleSidebar(true, !!setUserPreference);
  };

  public closeSidebar = (setUserPreference?: boolean) => {
    this.toggleSidebar(false, !!setUserPreference);
  };

  public sendAuthenticated = (authed: boolean) => {
    this.send(new InterAppPayload(this.payloadTypes.AUTHENTICATED, { authenticated: authed }));
  };

  public sendFastCrawlFlowStateCurrentStatus = (fastCrawlFlowStateCurrentStatus: string) => {
    this.send(
      new InterAppPayload(this.payloadTypes.FAST_CRAWL_FLOW_STATE_CURRENT_STATUS, {
        fastCrawlFlowStateCurrentStatus,
      }),
    );
  };

  public sendOnboardingFlowStateCurrentStatus = (onboardingFlowStateCurrentStatus: string) => {
    this.send(
      new InterAppPayload(this.payloadTypes.ONBOARDING_FLOW_STATE_CURRENT_STATUS, {
        onboardingFlowStateCurrentStatus,
      }),
    );
  };

  public sendOrgDomains = (domains: string[]) => {
    this.send(new InterAppPayload(this.payloadTypes.ORG_DOMAINS, { domains }));
  };

  public sendUserFlags = (flags: string[]) => {
    this.send(new InterAppPayload(this.payloadTypes.USER_FLAGS, { flags }));
  };

  public sendFetchQuery = (queryId: number, queryPayload: { error?: Error; data?: object }) => {
    this.send(new InterAppPayload(this.payloadTypes.FETCH_QUERY, { queryId, queryPayload }));
  };

  public sendFetchApolloQuery = (
    queryId: string,
    queryPayload: { error: any; data: any; loading: any },
  ) => {
    this.send(new InterAppPayload(this.payloadTypes.FETCH_APOLLO_QUERY, { queryId, queryPayload }));
  };

  public sendCommitApolloMutation = (
    mutationId: string,
    mutationPayload: { error: any; data: any; loading: boolean },
  ) => {
    this.send(
      new InterAppPayload(this.payloadTypes.COMMIT_APOLLO_MUTATION, {
        mutationId,
        mutationPayload,
      }),
    );
  };

  public sendCommitMutation = (
    mutationId: number,
    mutationPayload: { error?: Error; data?: object },
  ) => {
    this.send(
      new InterAppPayload(this.payloadTypes.COMMIT_MUTATION, { mutationId, mutationPayload }),
    );
  };

  public sendEnableExperiment = (experiment: string) => {
    this.send(new InterAppPayload(this.payloadTypes.ENABLE_EXPERIMENT, { experiment }));
  };

  public sendBulkExperiments = (experiments: ExperimentMappingType) => {
    this.send(new InterAppPayload(this.payloadTypes.BULK_EXPERIMENTS, experiments));
  };

  public sendAutopilotCardsNotificationCount = (count: number) => {
    this.send(
      new InterAppPayload(this.payloadTypes.AUTOPILOT_CARDS_NOTIFICATION_COUNT, {
        autopilotCardsNotificationCount: count,
      }),
    );
  };

  public sendLogs = () => {
    const logs = inMemoryCacheTransport.getLogsB64();

    this.send(new InterAppPayload(this.payloadTypes.LOGS, { logs }));
  };

  public setSuggestionCount = (count: number) => {
    this.send(new InterAppPayload(this.payloadTypes.SUGGESTION_COUNT, { suggestionCount: count }));
  };

  public goToEventEditPage = (externalEventId: string, calId: string) => {
    this.send(new InterAppPayload(this.payloadTypes.GO_TO_EVENT_EDIT, { externalEventId, calId }));
  };

  public sendEventEditHovered = (hovered: boolean) => {
    this.send(
      new InterAppPayload(this.payloadTypes.EVENT_EDIT_HOVERED, { eventEditHovered: hovered }),
    );
  };

  public sendWebExtensionHovered = (hovered: boolean) => {
    this.send(
      new InterAppPayload(this.payloadTypes.CHROME_EXTENSION_HOVERED, {
        chromeExtensionHovered: hovered,
      }),
    );
  };

  public sendWebExtensionClicked = (millis: number) => {
    this.send(
      new InterAppPayload(this.payloadTypes.CHROME_EXTENSION_CLICKED, {
        chromeExtensionClicked: millis,
      }),
    );
  };

  public sendWebDialogOpen = (chromeExtensionChromeDialogOpen: boolean) => {
    this.send(
      new InterAppPayload(this.payloadTypes.CHROME_EXTENSION_CHROME_DIALOG_OPEN, {
        chromeExtensionChromeDialogOpen,
      }),
    );
  };

  public sendIntercomOpen = (intercomOpen: boolean) => {
    this.send(
      new InterAppPayload(this.payloadTypes.CHROME_EXTENSION_INTERCOM_OPEN, {
        chromeExtensionIntercomOpen: intercomOpen,
      }),
    );
  };

  public sendShowEventOnCalendar = (
    startTime: number | null,
    durationMinutes: number | null,
    visible: boolean,
  ) => {
    this.send(
      new InterAppPayload(this.payloadTypes.SHOW_EVENT_ON_CALENDAR, {
        startTime,
        durationMinutes,
        visible,
      }),
    );
  };

  public highlightCalendarSelectionBoxes = (visible: boolean) => {
    this.send(
      new InterAppPayload(this.payloadTypes.HIGHLIGHT_CALENDAR_SELECTION_BOXES, { visible }),
    );
  };

  public toggleCalendar = (calendarId: string, checked: boolean) => {
    this.send(new InterAppPayload(this.payloadTypes.CALENDAR_TOGGLE, { calendarId, checked }));
  };

  public sendGoToDate = (date: string) => {
    this.send(new InterAppPayload(this.payloadTypes.GO_TO_DATE, { date }));
  };

  public sendInitialLoadComplete = (isHealthy: boolean) => {
    this.send(new InterAppPayload(this.payloadTypes.INITIAL_LOAD_COMPLETE, { isHealthy }));
  };

  public setCalendarSelections = (calendarsSelected: string[], forceAdd: boolean) => {
    this.send(
      new InterAppPayload(this.payloadTypes.SET_SELECTION_STATES, { calendarsSelected, forceAdd }),
    );
  };

  public sendWebappUpdate = (chromeAppUpdateTimes: any) => {
    this.send(new InterAppPayload(this.payloadTypes.WEBAPP_UPDATE, { chromeAppUpdateTimes }));
  };

  public sendUpdatedEditEventInfo = (updatedEditEventInfo: IUpdatedEventInfo) => {
    this.send(new InterAppPayload(this.payloadTypes.EDIT_EVENT_INFO, { updatedEditEventInfo }));
  };

  public sendEventAutopilotStatus = (
    externalEventId: string,
    calendarId: string,
    flex: string,
    allowSimilarRooms: boolean,
    showRoom: boolean,
    disabled: boolean,
    isPaused: boolean,
    status: string,
    attendeeNotInDomain: boolean,
    organizerNotClockwiseUser: boolean,
    isAllDayEvent: boolean,
    dayOnOffMap: DayOnOffMap,
    dayOfWeekFlexibilityEligible: boolean,
    movableUntil: MovableUntil | null,
    isHold: boolean,
  ) => {
    this.send(
      new InterAppPayload(this.payloadTypes.SET_EVENT_AUTOPILOT_STATUS, {
        externalEventId,
        calendarId,
        flex,
        allowSimilarRooms,
        showRoom,
        disabled,
        isPaused,
        status,
        attendeeNotInDomain,
        organizerNotClockwiseUser,
        isAllDayEvent,
        dayOnOffMap,
        dayOfWeekFlexibilityEligible,
        movableUntil,
        isHold,
      }),
    );
  };

  public sendOptimizationNotification = ({
    id,
    conflictsResolved,
    focusTimeAdded,
    optimizedAt,
    expiresAt,
  }: {
    id: string;
    conflictsResolved: number;
    focusTimeAdded: string;
    optimizedAt: string;
    expiresAt: string;
  }) => {
    this.send(
      new InterAppPayload(this.payloadTypes.SET_OPTIMIZATION_NOTIFICATION, {
        id,
        conflictsResolved,
        focusTimeAdded,
        optimizedAt,
        expiresAt,
      }),
    );
  };

  public sendResetOptimizationNotification = () => {
    this.send(new InterAppPayload(this.payloadTypes.RESET_OPTIMIZATION_NOTIFICATION, undefined));
  };

  public syncActiveOrg = (activeOrg: ISchema.IOrg) => {
    this.send(new InterAppPayload(this.payloadTypes.SYNC_ACTIVE_ORG, { activeOrg }));
  };

  public sendActiveEvent = (
    externalEventId: string,
    calendarId: string,
    canReschedule: boolean,
    event: ISchema.IEvent,
    organizerFeatureStates: ISchema.IFeatureStates | null,
    eventParentRelayId: string,
    ignoreState?: IgnoreState,
  ) => {
    this.send(
      new InterAppPayload(this.payloadTypes.SET_ACTIVE_EVENT, {
        externalEventId,
        calendarId,
        canReschedule,
        event,
        organizerFeatureStates,
        eventParentRelayId,
        ignoreState,
      }),
    );
  };

  public sendResetScheduleWithAiButtonClosed = () => {
    this.send(
      new InterAppPayload(this.payloadTypes.RESET_SCHEDULE_WITH_AI_BUTTON_CLOSED, undefined),
    );
  };

  public sendFeatureGrid = (featureGrid: IFeatureGridReduxState) => {
    this.send(new InterAppPayload(this.payloadTypes.SET_FEATURE_GRID, { featureGrid }));
  };

  public sendOrgBillingGroupIds = (orgBillingGroupIds: string[]) => {
    this.send(
      new InterAppPayload(this.payloadTypes.SET_ORG_BILLING_GROUP_IDS, { orgBillingGroupIds }),
    );
  };

  public clearQueryParams = (queryParam?: string) => {
    this.send(new InterAppPayload(this.payloadTypes.CLEAR_QUERY_PARAMS, { queryParam }));
  };

  public showTeamCalendarTip = (calendarId: string) => {
    this.send(new InterAppPayload(this.payloadTypes.SHOW_TEAM_CALENDAR_TIP, { calendarId }));
  };

  public registerQuery = (queryName: string, query: GraphQLTaggedNode) => {
    this.registeredQueries[queryName] = query;
  };

  public sendUserGoals = (userGoals: ISchema.IGoal[], focusTimeWeek: number) => {
    const goalsExtensionFormatted = userGoals.map((goal) => {
      const goalFormatted = {
        goalId: goal.goalId,
        isDeleted: goal.isDeleted,
        name: goal.name,
        options: goal.options?.specificOptions || {},
      } as any;

      if (goal.goalId === "FocusTime") {
        goalFormatted.options = {
          ...goalFormatted.options,
          focusTimeIdealMinutesPerWeek: focusTimeWeek,
        };
      }

      return goalFormatted;
    });

    this.send(new InterAppPayload(this.payloadTypes.SET_USER_GOALS, goalsExtensionFormatted));
  };

  public sendColorSettings = (colorSettings: ISchema.IEventColoringSettings) => {
    this.send(new InterAppPayload(this.payloadTypes.COLOR_SETTINGS, { colorSettings }));
  };

  public sendUser = (user: ISchema.IUser) => {
    this.send(new InterAppPayload(this.payloadTypes.USER, { user }));
  };

  public copyToClipboard = (toCopy: string) => {
    this.send(new InterAppPayload(this.payloadTypes.COPY_TO_CLIPBOARD, { toCopy }));
  };

  public copyRichTextToClipboard = (text: string) => {
    this.send(new InterAppPayload(this.payloadTypes.COPY_RICH_TEXT_TO_CLIPBOARD, { text }));
  };

  public sendShouldExcludeAi = (shouldExcludeAi: boolean) => {
    this.send(new InterAppPayload(this.payloadTypes.SHOULD_EXCLUDE_AI, { shouldExcludeAi }));
  };

  // TODO: synced state
  public sendSyncedAction = (_action: SharedAction) => {
    // this.send(
    //   new InterAppPayload(this.payloadTypes.SYNCED_ACTION, {
    //     isDispatched: true,
    //     ...action,
    //   }),
    // );
  };

  // TODO: synced state
  public mergeInitialState = (_initialState: ISharedState) => {
    // this.send(
    //   new InterAppPayload(this.payloadTypes.SYNCED_ACTION, {
    //     initialState,
    //     isDispatched: true,
    //     type: SharedActions.MergeInitialStates,
    //     data: {},
    //   } as SharedAction),
    // );
  };

  public sendExperiments = (
    experimentsAndFeatureFlags: ExperimentMappingType & LaunchDarklyMappingType,
  ) => {
    PostMessageManager.sendBulkExperiments(experimentsAndFeatureFlags);
  };
}

const chromeSite = getChromeSite();
const url =
  chromeSite === ChromeSite.Gcal ? "https://calendar.google.com" : "https://mail.google.com";
export const PostMessageManager = new PostMessageManagerClass(url);
