// schema
import * as ISchema from "#webapp/src/__schema__";

import { logger } from "#webapp/src/util/logger.util";
import { PostMessageManager } from "#webapp/src/util/post-message.util";
import { requestSubscription } from "react-relay";
import { Environment } from "relay-runtime";
import { subscription } from "./UpdateTeamCalendarState.gql";

export function updateTeamCalendarState(
  environment: Environment,
  input: ISchema.IUpdateTeamCalendarStateSubscriptionInput,
) {
  if (!(environment instanceof Environment)) {
    // don't proceed if it's not a modern environment
    return;
  }

  const variables = {
    input,
  };

  const subscriptionRequest = requestSubscription(environment, {
    subscription,
    variables,
    // optional but recommended:
    onCompleted: () => logger.debug("Subscription request for TeamCalendarState completed"),
    onError: (error) => logger.error("Subscription request for TeamCalendarState failed", error),
    updater: (store) => {
      const rootField = store.getRootField("updateTeamCalendarState");

      if (!rootField) {
        return;
      }

      const teamCalendarState = rootField.getLinkedRecord("teamCalendarState");

      if (!teamCalendarState) {
        return;
      }

      const teamCalendarId = teamCalendarState.getValue("calendarId");

      if (!teamCalendarId) {
        return;
      }

      PostMessageManager.toggleCalendar(teamCalendarId as string, true);

      subscriptionRequest.dispose();
    },
  });

  return subscriptionRequest;
}
