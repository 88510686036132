/**
 * @generated SignedSource<<db96235ceee7a862cbdcac1cd3176c4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DayOfWeek = "Friday" | "Monday" | "Saturday" | "Sunday" | "Thursday" | "Tuesday" | "Wednesday" | "%future added value";
export type EventScheduledBy = "Clockwise" | "Other" | "%future added value";
export type PreRangeFlexibility = "Day" | "Week" | "%future added value";
export type PreRoomFlexibility = "KeepCurrentRoom" | "SimilarRoomsAllowed" | "%future added value";
export type TimeSlot = "T_00_00" | "T_00_30" | "T_01_00" | "T_01_30" | "T_02_00" | "T_02_30" | "T_03_00" | "T_03_30" | "T_04_00" | "T_04_30" | "T_05_00" | "T_05_30" | "T_06_00" | "T_06_30" | "T_07_00" | "T_07_30" | "T_08_00" | "T_08_30" | "T_09_00" | "T_09_30" | "T_10_00" | "T_10_30" | "T_11_00" | "T_11_30" | "T_12_00" | "T_12_30" | "T_13_00" | "T_13_30" | "T_14_00" | "T_14_30" | "T_15_00" | "T_15_30" | "T_16_00" | "T_16_30" | "T_17_00" | "T_17_30" | "T_18_00" | "T_18_30" | "T_19_00" | "T_19_30" | "T_20_00" | "T_20_30" | "T_21_00" | "T_21_30" | "T_22_00" | "T_22_30" | "T_23_00" | "T_23_30" | "%future added value";
export type PreSetFlexibilityInput = {
  calendarId: any;
  orgRelayId: string;
  preEventFlexibility: PreEventFlexibility;
  preEventKey: PreEventKey;
};
export type PreEventFlexibility = {
  roomFlexibility: PreRoomFlexibility;
  scheduledBy: EventScheduledBy;
  timeOfDayFlexibility: MoveRangeInLocalTimeV3Input;
  timeRangeFlexibility: PreTimeRangeFlexibility;
};
export type MoveRangeInLocalTimeV3Input = {
  allowedEndTimeInclusive?: DayOffsetTimeSlotInput | null;
  allowedStartTimeInclusive?: DayOffsetTimeSlotInput | null;
};
export type DayOffsetTimeSlotInput = {
  dayOffset: number;
  timeSlot: TimeSlot;
};
export type PreTimeRangeFlexibility = {
  dayOfWeekFlexibility?: PreDayOfWeekFlexibility | null;
  rangeFlexibility?: PreRangeFlexibility | null;
};
export type PreDayOfWeekFlexibility = {
  allowedDays: ReadonlyArray<DayOfWeek>;
};
export type PreEventKey = {
  attendees: ReadonlyArray<any>;
  endTime: InferredEventTimeInput;
  startTime: InferredEventTimeInput;
  title?: string | null;
};
export type InferredEventTimeInput = {
  date: string;
  time: string;
  timeZone?: string | null;
};
export type PreSetEventFlexibilityMutation$variables = {
  input: PreSetFlexibilityInput;
};
export type PreSetEventFlexibilityMutation$data = {
  readonly preSetEventFlexibility: {
    readonly successful: boolean;
  } | null;
};
export type PreSetEventFlexibilityMutation = {
  variables: PreSetEventFlexibilityMutation$variables;
  response: PreSetEventFlexibilityMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PreSetFlexibilityResponse",
    "kind": "LinkedField",
    "name": "preSetEventFlexibility",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PreSetEventFlexibilityMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PreSetEventFlexibilityMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "16008811590827af7504eb985e3d832e",
    "id": null,
    "metadata": {},
    "name": "PreSetEventFlexibilityMutation",
    "operationKind": "mutation",
    "text": "mutation PreSetEventFlexibilityMutation(\n  $input: PreSetFlexibilityInput!\n) {\n  preSetEventFlexibility(input: $input) {\n    successful\n  }\n}\n"
  }
};
})();

(node as any).hash = "075aa938dd75653995a86d1347849771";

export default node;
