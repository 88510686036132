import pluralize from "pluralize";
import React from "react";

// Terminology
const FIXED = "won't move";
const MEETING_IS_FIXED = `This meeting ${FIXED}`; // When meeting's flexibility status is paused and the meeting will not move, we now say it 'won't move'

const TRANSLATIONS = {
  "onboarding:chrome-header": ({ meetingCount }: { meetingCount: number }) => {
    const theseMeetings =
      meetingCount === 0
        ? "these meetings"
        : `${pluralize("this", meetingCount)} ${pluralize("meeting", meetingCount, true)}`;
    return `Mark ${theseMeetings} as flexible to create Focus Time!`;
  },
  "onboarding:chrome-body": ({
    Link,
    href,
  }: {
    Link: React.ComponentType<{ href?: string; target?: string; children?: React.ReactNode }>;
    href: string;
  }) => (
    <span>
      Clockwise keeps attendee availability and time zones in mind when rescheduling flexible
      meetings for you. Learn more about{" "}
      <Link href={href} target="_blank">
        flexible meetings
      </Link>
      .
    </span>
  ),
  "onboarding:autopilot-reminder": `Clockwise will only reschedule flexible meetings within your working hours.`,
  "onboarding:autopilot-summary-header": ({ count }: { count: number }) =>
    count
      ? `🙌 You've marked ${pluralize("meeting", count, true)} as flexible to create Focus Time`
      : `❇️ You didn't mark any meetings as flexible to create Focus Time`,
  "onboarding:autopilot-summary-body":
    "Clockwise optimizes your team's schedule daily at 4:00pm by finding the best times for your flexible meetings.",
  "autopilot-form:toggle-tooltip": ({ on }: { on: boolean }): string =>
    on ? "Unmark as flexible" : "Mark as flexible",
  "autopilot-form:label": "Let Clockwise find the best time for this meeting",
  "feed:assistant-empty":
    "If you've marked meetings as flexible, check back later to see how both you and your coworkers benefited.",
  "feed:autopilot-link": "Learn about flexible meetings",
  "drawer:autopilot-nav-item": "Flexible meetings",
  "autopilot:history-title": "Focus Time created",
  "autopilot:nav-title": "Flexible meetings",
  "autopilot:meetings-title": "Your flexible meetings",
  "autopilot:suggestions-title": "Suggested for flexibility",
  "settings:slack-autopilot-updates": "Receive summary of your flexible meeting updates",
  "settings:slack-autopilot-notification":
    "Updating your Slack preferences for receiving flexible meeting update summaries.",
  "settings:email-title": "Flexible meeting emails",
  "settings:email-send-now-on-autopilot":
    "Notify non-Clockwise attendees when you mark a meeting as flexible",
  "settings:email-receive-now-on-autopilot":
    "Get notified when others mark a meeting you're attending as flexible",
  "settings:email-autopilot-updates": "Summary of your flexible meeting updates",
  "extension:autopilot-form-title": "Flexible",
  "extension:autopilot-learn-more-link": "how Clockwise reschedules flexible meetings",
  "extension:autopilot-learn-more-text": "to create Focus Time.",
  "extension:autopilot-view-history": "View meeting history",
  "event:autopilot-label-on": "FLEXIBLE MEETING",
  "event:autopilot-label-off": "MEETING NOT FLEXIBLE",
  "event:autopilot-label-paused": "MEETING WON'T MOVE",
  "autopilot-status:tooltip": ({ timeZone }: { timeZone: string }) =>
    `Clockwise looks for better times for flexible meetings every weekday at 4:00pm in the time zone set for your organization: ${timeZone}.`,
  "autopilot-status:empty": "No flexible meetings",
  "autopilot-status:error": "Clockwise could not move meetings today",
  "autopilot-status:no-update": "Clockwise did not move any of your flexible meetings",
  "autopilot-status:paused": ({ count }: { count: number }) =>
    `${pluralize("meeting", count, true)} ${FIXED}`,
  "autopilot-status:this-meeting-paused": MEETING_IS_FIXED,
  "autopilot-status:active": ({ count }: { count: number }) =>
    `${pluralize("meeting", count, true)} marked flexible`,
  "autopilot-status:queued": "Clockwise is preparing to optimize your calendar...",
  "autopilot-form:status-text": "Clockwise will search for the best time",
  "autopilot-form:past-message":
    "Clockwise will not change flexible meetings on your calendar that occur within the next 24 hours or in the past.",
  "autopilot-form:paused-message":
    "This meeting was manually rescheduled to a specific time. Clockwise will not move it.",
  "autopilot-form:disabled": "This event was unmarked as flexible",
  "autopilot-form:updated": "Flexible meeting settings were updated for this event",
  "autopilot-form:learn-more-link": "how Clockwise reschedules your flexible meetings",
  "autopilot-form:learn-more-text": " to create Focus Time.",
  "autopilot-form:tutorial-title": "Customize your flexible meeting settings",
  "autopilot-form:tutorial-body":
    "Update your meeting-specific flexibility settings, or turn flexibility on or off.",
  "ap-history:view-meetings-button": "View your flexible meetings",
  "1-on-1:mark-all": "Mark all one-on-ones as flexible",
  "1-on-1:mark-prompt": "Mark as flexible for more focus time",
  "1-on-1:autopilot-what-happens": "What happens when I mark a meeting as flexible?",
  "1-on-1:toggle-error": "Sorry, unable to mark One on One flexible.",
  "1-on-1:progress-title-good": "You have a great number of one-on-ones marked flexible. 👍",
  "1-on-1:progress-title-bad":
    "You could create more focus time by marking more one-on-ones as flexible.",
  "1-on-1:progress-subtitle-good":
    "You are helping create focus time by making your team's calendar more flexible.",
  "1-on-1:progress-subtitle-bad": ({
    count,
    desiredCount,
  }: {
    count: number;
    desiredCount: number;
  }) => {
    const meetingCount = count === 0 ? "no one-on-ones" : pluralize("one-on-one", count, true);
    return `You have ${meetingCount} marked flexible. For you, marking at least ${desiredCount} may create more focus time for your team.`;
  },
  "1-on-1:mark-all-error": "Sorry, unable to mark all of your One on Ones as flexible.",
  "event:autopilot-title": "Flexible meeting",
  "event:autopilot-history-title": "Flexible meeting history",
  "event-summary:next-autopilot-run-time": () => "", // Intentionally left blank
  "autopilot-list:autopilot-action-text": "Mark as flexible",
  "pricing:starter-tier-autopilot": "Flexible meetings",
  "pricing:pro-tier-autopilot": "Custom settings for flexible meetings",
  "pricing:feature-comparison-grid-autopilot": "Flexible Meetings",
  "pricing:feature-comparison-grid-autopilot-tooltip":
    "When you mark a meeting as flexible, Clockwise can reschedule it to create Focus Time and resolve conflicts for attendees.",
  "pricing:feature-comparison-grid-custom-autopilot-settings":
    "Custom settings for flexible meetings",
  "autopilot-tutorial:title": "Enable flexibility as you schedule",
  "autopilot-tutorial:body":
    "Mark new meetings as flexible to ensure they are always at the best time for you and your team.",
  "defrag-survey:title": "Survey | Clockwise",
  "defrag-survey:description": "Rate your experience with Clockwise flexible meetings.",
  "defrag-summary:survey-title": "Help improve flexible meetings",
  "autopilot-warning:all-day-event": "All day meetings cannot be marked as flexible.",
  "autopilot-warning:non-user-organizer": `Meetings can only be marked as flexible when the organizer is also using Clockwise.`,
  "autopilot-warning:non-user-organizer-with-email": ({
    organizerEmail,
  }: {
    organizerEmail?: string;
  }) =>
    `The meeting organizer ${
      organizerEmail ? "(" + organizerEmail + ")" : ""
    } is not using Clockwise. Meetings can only be marked flexible when the organizer is also using Clockwise.`,
  "autopilot-warning:external-attendee":
    "Meetings with external attendees can't be marked as flexible.",
  "autopilot-warning:no-permission": `This meeting can't be marked as flexible because you don't have permission to modify the event.`,
  "autopilot-warning:not-a-meeting": `Clockwise will not move meetings that have been marked as "free".`,
  "autopilot-warning:private": `This meeting can't be marked as flexible because it is set as private.`,
  "autopilot-warning:over-quota": `Clockwise will not move your flexible meetings because your group is over quota.`,
  "autopilot-suggestions:toggle-button": ({ on }: { on: boolean }) =>
    on ? `Marked flexible` : `Mark as flexible`,
  "event:smart-hold-updated": ({ updatedAgo }: { updatedAgo: string }) =>
    `Clockwise updated ${updatedAgo}`,
};

type TranslationHash = typeof TRANSLATIONS;
type TranslationTokens<T, K extends keyof T> = T[K] extends (tokens: infer A) => any ? A : never;

/**
 * Performs a lookup of message based on a key, which can be affected by the current feature flags
 * that are set for the user.
 *
 * @param featureFlags Hash of feature flags
 * @returns Translated message
 */
export const translate = () => {
  return function t<K extends keyof TranslationHash>(
    key: K,
    tokens?: TranslationTokens<TranslationHash, K>,
  ): string | JSX.Element {
    const translation = TRANSLATIONS[key];
    if (typeof translation === "function") {
      if (tokens) {
        return translation(tokens as any); // Token types cannot be inferred properly, sadly :-(
      } else {
        throw new Error("This translation requires tokens");
      }
    }

    return translation;
  };
};
