import { salesContactFormUrl } from "@clockwise/client-commons/src/constants/site";
import { Button } from "@clockwise/design-system";
import React from "react";

type ContactUsButtonProps = {
  isCurrentPlan: boolean;
  onClick: () => void;
  isM365Beta?: boolean;
};

const ContactUsButton = ({ isCurrentPlan, onClick, isM365Beta }: ContactUsButtonProps) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (isCurrentPlan) {
      event.preventDefault();
    }
  };

  return (
    <a
      className="cw-no-underline"
      href={salesContactFormUrl}
      onClick={handleClick}
      rel="noreferrer"
      target="_blank"
    >
      <Button
        disabled={isCurrentPlan || isM365Beta}
        fullWidth
        onClick={onClick}
        sentiment="positive"
        variant="outlined"
      >
        {isCurrentPlan ? "Current plan" : isM365Beta ? "Unavailable in beta" : "Contact us"}
      </Button>
    </a>
  );
};

export default ContactUsButton;
