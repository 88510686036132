import { googleDarkDisabled, googleDarkNormal, googleGSVG } from "#webapp/src/assets/img/google";
import { useGoogleOAuth } from "#webapp/src/auth/google-auth.util";
import { Button } from "@clockwise/design-system";
import { SvgIconComponent } from "@clockwise/design-system/icons";
import { Size, TextAlignment } from "@clockwise/design-system/src/components/Button";
import { Sentiment } from "@clockwise/design-system/src/constants/types";
import { fontFamilySans } from "@clockwise/web-commons/src/styles";
import {
  getInitialAuthTrackingFunnel,
  getStepBasedOnTrackingFunnel,
} from "@clockwise/web-commons/src/util/analytics.util";
import classNames from "classnames";
import React, { CSSProperties } from "react";

export type GoogleButtonVariant = "flat" | "googleStandard" | "googleSimple" | "default";

interface Props {
  variant?: GoogleButtonVariant;
  fullWidth?: boolean;
  icon?: SvgIconComponent;
  iconClassNames?: string;
  label: string | React.ReactNode;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  disabled?: boolean;
  textAlign?: TextAlignment;
  sentiment?: Sentiment;
  size?: Size;

  onSuccess: (response: { code: string }) => void;
  onFailure: (error: { error: string }) => void;
  onClick?: React.MouseEventHandler;

  excludeAdminPermissions?: boolean;
  excludeContactPermission?: boolean;
}

const GoogleBtn = ({
  disabled = false,
  label,
  fullWidth = false,
  icon,
  labelStyle,
  variant = "default",
  textAlign = "center",
  sentiment = "neutral",
  size = "medium",
  onSuccess,
  onFailure,
  excludeContactPermission,
  excludeAdminPermissions,
  iconClassNames,
}: Props) => {
  const { prompt } = useGoogleOAuth();
  const scopes: string[] = React.useMemo(() => {
    const scopes = [
      "https://www.googleapis.com/auth/calendar",
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ];

    if (!excludeContactPermission) {
      scopes.push("https://www.googleapis.com/auth/contacts.readonly");
    }
    if (!excludeAdminPermissions) {
      scopes.push(
        "https://www.googleapis.com/auth/admin.directory.user.readonly",
        "https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly",
      );
    }
    return scopes;
  }, [excludeContactPermission, excludeAdminPermissions]);

  const handleClick = () => {
    // google tag manager
    const dataLayer = window && (window as any).dataLayer;
    const tracking_funnel = getInitialAuthTrackingFunnel();
    const tracking_step = getStepBasedOnTrackingFunnel(tracking_funnel, "click_to_oauth");
    dataLayer.push({ event: "userClickedLoginBtn", ecosystem: "web" });
    // new GTM event for user login, dont delete one above until we confirm everything works correctly
    dataLayer.push({
      event: "funnel_step",
      universal_step: "OAUTH",
      funnel_name: tracking_funnel,
      step_name: "click_to_oauth",
      step_number: tracking_step,
      funnel_option: "google",
    });
    prompt(scopes, { onSuccess, onFailure });
  };

  switch (variant) {
    case "flat":
      return (
        <Button
          variant="text"
          startIcon={icon}
          onClick={handleClick}
          disabled={disabled}
          fullWidth={fullWidth}
          textAlign={textAlign}
          sentiment={sentiment}
          size={size}
        >
          {label}
        </Button>
      );
    case "googleStandard":
      return (
        <button
          className={classNames(
            {
              "cw-bg-[#4285F4] hover:cw-bg-[#3e7ee7] active:cw-bg-[#4285F4]": !disabled,
            },
            { "cw-bg-[#e0e0e0]": disabled },
            "cw-w-full cw-p-0",
            { "cw-shadow-md hover:cw-shadow-lg": !disabled },
            "cw-flex cw-justify-unset cw-box-border cw-items-center cw-rounded-[4px]",
            "cw-text-sm cw-font-bold",
            "cw-transition-[background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms]",
            "cw-border-0 cw-m-0 cw-outline-0 cw-relative cw-select-none cw-align-middle",
            disabled ? "cw-cursor-not-allowed" : "cw-cursor-pointer",
          )}
          style={{ fontFamily: fontFamilySans }}
          disabled={disabled}
          onClick={handleClick}
        >
          <img style={{ marginLeft: 0 }} src={disabled ? googleDarkDisabled : googleDarkNormal} />
          <span className={classNames("cw-text-white", "cw-pl-[15px]")}>{label}</span>
        </button>
      );
    case "googleSimple":
      return (
        <Button
          variant="outlined"
          disabled={disabled}
          onClick={handleClick}
          fullWidth={fullWidth}
          textAlign={textAlign}
          sentiment={sentiment}
          size={size}
        >
          <img width="20" height="20" className="cw-mr-[10px]" src={googleGSVG} />
          <span style={{ ...labelStyle }}>{label}</span>
        </Button>
      );
    default:
      return (
        <Button
          variant="outlined"
          startIcon={icon}
          disabled={disabled}
          onClick={handleClick}
          fullWidth={fullWidth}
          textAlign={textAlign}
          sentiment={sentiment}
          size={size}
          iconColorClassOverride={iconClassNames}
        >
          {label}
        </Button>
      );
  }
};

export default GoogleBtn;
