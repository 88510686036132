// schema
import * as ISchema from "#webapp/src/__schema__";

// libraries
import { PaymentMethod } from "@stripe/stripe-js";

// components
import { IPlanPricing } from "#webapp/src/util/pricing.util";

export enum PaymentsActions {
  ClearPaymentMethod = "Payments_ClearPaymentMethod",
  UpdatePaymentMethod = "Payments_UpdatePaymentMethod",
  PricingDialogOpen = "Payments_PricingDialogOpen",
  UpdateShouldRefetch = "Payments_UpdateShouldRefetch",
  SetOrgBillingGroupIds = "Payments_SetOrgBillingGroupIds",
}

export const clearPaymentInfo = () => ({
  type: PaymentsActions.ClearPaymentMethod,
  data: {},
});

export const updatePaymentInfo = ({
  paymentMethod,
  plan,
  subscriptionInterval,
}: {
  paymentMethod?: PaymentMethod;
  plan?: IPlanPricing;
  subscriptionInterval?: ISchema.SubscriptionInterval;
}) => ({
  type: PaymentsActions.UpdatePaymentMethod,
  data: {
    paymentMethod,
    plan,
    subscriptionInterval,
  },
});

export const setPricingDialogOpen = (
  pricingDialogOpen: boolean,
  teamId?: string,
  initialView?: "Checkout" | "Pricing",
) => ({
  type: PaymentsActions.PricingDialogOpen,
  data: {
    pricingDialogState: {
      pricingDialogOpen,
      teamId,
      initialView,
    },
  },
});

export const setOrgBillingGroupIds = (orgBillingGroupIds: string[]) => ({
  type: PaymentsActions.SetOrgBillingGroupIds,
  data: {
    orgBillingGroupIds,
  },
});

export const updateShouldRefetch = (shouldRefetchDueToPlanUpgrade: boolean) => ({
  type: PaymentsActions.UpdateShouldRefetch,
  data: {
    shouldRefetchDueToPlanUpgrade,
  },
});
