import {
  ArrowDropDown,
  ArrowDropUp,
  ArrowLeft,
  ArrowRight,
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
} from "@clockwise/design-system/icons";
import * as React from "react";

import { gCalColors } from "../../styles/color.styles";
import { CWIDProps } from "../../util/cw-id.util";
import { onSpaceOrEnterKey } from "../../util/html.util";

type Direction = "left" | "right" | "up" | "down";
export interface HoverableChevronProps {
  direction: Direction;
  onClick?: () => void;
  disabled?: boolean;
  useArrows?: boolean;
  "aria-label"?: string;
}

const getComponent = (direction: Direction, useArrows: boolean, style: React.CSSProperties) => {
  let Component;
  if (useArrows) {
    switch (direction) {
      case "left":
        Component = ArrowLeft;
        break;
      case "right":
        Component = ArrowRight;
        break;
      case "up":
        Component = ArrowDropUp;
        break;
      case "down":
        Component = ArrowDropDown;
        break;
    }
  } else {
    switch (direction) {
      case "left":
        Component = ChevronLeft;
        break;
      case "right":
        Component = ChevronRight;
        break;
      case "up":
        Component = ExpandLess;
        break;
      case "down":
        Component = ExpandMore;
        break;
    }
  }

  return <Component style={style} />;
};

export const HoverableChevron = ({
  direction,
  onClick,
  disabled,
  useArrows,
  "cw-id": cwId,
  "aria-label": ariaLabel,
}: HoverableChevronProps & CWIDProps) => {
  const containerClasses = [
    "cw-flex",
    "cw-justify-center",
    "cw-items-center",
    "cw-w-8",
    "cw-h-8",
    "cw-rounded-full",
    "cw-transition-colors",
    "cw-duration-250",
    !disabled && "cw-cursor-pointer",
    !disabled && "hover:cw-bg-neutral-hover",
  ]
    .filter(Boolean)
    .join(" ");

  const iconStyle = {
    fill: disabled ? "#B4B7C5" : gCalColors.iconStrokeAndFill,
  };

  const Component = getComponent(direction, !!useArrows, iconStyle);

  return (
    <div
      cw-id={cwId}
      aria-label={ariaLabel}
      tabIndex={0}
      className={containerClasses}
      onClick={disabled ? undefined : onClick}
      onKeyDown={disabled ? undefined : onSpaceOrEnterKey(onClick)}
    >
      {Component}
    </div>
  );
};
