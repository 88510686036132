import { Button } from "@clockwise/design-system";
import { Check, FileCopyOutlined } from "@clockwise/design-system/icons";
import classNames from "classnames";
import React from "react";
import { useResettingBoolean } from "../scheduling-links/hooks/useResettingBoolean";

type Props = {
  copyAriaLabel: string;
  disabled?: boolean;
  onCopy: () => unknown;
  startIcon?: JSX.Element;
  text?: string;
  urlFormatting?: boolean;
};

export function CopyText({
  children,
  copyAriaLabel,
  disabled,
  onCopy,
  startIcon,
  text,
  urlFormatting = false,
}: React.PropsWithChildren<Props>) {
  const [copied, setCopied] = useResettingBoolean(false);
  const toCopy = text || children;

  return (
    <div
      className={classNames(
        "cw-flex cw-items-stretch cw-justify-between",
        {
          "cw-bg-positive cw-duration-100": copied,
          "cw-bg-neutral-inset cw-duration-1000": !copied,
        },
        "cw-rounded-lg cw-min-w-min cw-transition-colors",
      )}
    >
      <div className="cw-flex cw-flex-col cw-items-center">
        <div className="cw-body-base cw-mx-2 cw-pl-1 cw-h-full cw-text-neutral-muted cw-inline-flex cw-items-center">
          {startIcon}
          <span
            className={classNames("cw-px-2 cw-py-[14px] cw-cursor-default cw-leading-[1.15rem]", {
              "cw-break-all": urlFormatting,
            })}
          >
            {toCopy}
          </span>
        </div>
      </div>

      <div className="cw-inline-flex cw-p-2">
        <Button
          onClick={() => {
            onCopy();
            setCopied(true);
          }}
          startIcon={copied ? Check : FileCopyOutlined}
          disabled={disabled}
          sentiment="positive"
          aria-label={copyAriaLabel}
        />
      </div>
    </div>
  );
}
