import { createUUID } from "#clockwise/web-commons/src/util/uuid";
import { analyticsPre } from "./local-storage";

// UtmProp enums are used to filter utm tracking values. We should retain legacy
// properties in this list to ensure proper tracking of older cookies.
export enum UtmProp {
  UtmMedium = "utm_medium",
  UtmSource = "utm_source",
  UtmCampaign = "utm_campaign",
  UtmTerm = "utm_term",
  UtmUtm = "utm_content",
}
type UtmCollection = Partial<Record<UtmProp, string>>;

export type PreCookie = {
  id: string;
  referrer: string;
  invite: string;
  utms: UtmCollection;
  latestUtms: UtmCollection;
};

const isWorker = typeof self !== "undefined";
const isWindow = typeof window !== "undefined";
const hostname = isWorker ? self.location.hostname : isWindow ? window.location.hostname : "";
const domain = hostname === "localhost" ? "localhost" : ".getclockwise.com";

export const getPreCookie = (): Readonly<PreCookie> => {
  let preCookie = fetchPreCookie();

  if (!preCookie) {
    // If we can't find the pre cookie, create a new one
    preCookie = {
      id: (analyticsPre.get() || createUUID()).replace(/-/g, ""),
      referrer: document.referrer,
      invite: "",
      utms: {},
      latestUtms: {},
    };
  }

  savePreCookie(preCookie);

  return preCookie;
};

/**
 * Type safety helper to ensure an unknown value is actually a string
 */
function asString(maybeString: any): string | null {
  return typeof maybeString === "string" ? maybeString : "";
}

/**
 * Type safety helper to coerce an unknown value into a UtmCollection
 */
function asUtms(maybeUtms: any): UtmCollection {
  if (!maybeUtms) {
    return {};
  }
  const utms: UtmCollection = {};
  Object.values(UtmProp).forEach((utmParam) => {
    const maybeValue = maybeUtms[utmParam];
    if (typeof maybeValue === "string") {
      utms[utmParam] = maybeValue;
    }
  });
  return utms;
}

/**
 * Normalize parsed JSON data to conform to the PreCookie object type
 */
function normalizePreCookie(data: any): PreCookie {
  return {
    id: asString(data.id) ?? "",
    referrer: asString(data.referrer) ?? "",
    invite: asString(data.invite) ?? "",
    utms: asUtms(data.utms) ?? {},
    latestUtms: asUtms(data.latestUtms) ?? {},
  };
}

/**
 * Extract `pre` data from the cookie if defined.
 */
export const fetchPreCookie = (): PreCookie | null => {
  const wholeCookie = document.cookie;
  // Extract `pre` data only
  const preCookieEncoded = wholeCookie.replace(/(?:(?:^|.*;\s*)pre\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  let preCookie = null;
  try {
    // Decode, convert to JSON, and normalize as PreCookie
    const parsedCookie = normalizePreCookie(JSON.parse(atob(preCookieEncoded)));
    // Throw away the data if it doesn't have an id associated with it
    if (parsedCookie?.id) {
      preCookie = parsedCookie;
    }
  } catch (e) {}
  return preCookie;
};

const savePreCookie = (preCookie: PreCookie) => {
  // Update the anonymous id in local storage to ensure it stays up to date with the cookie
  analyticsPre.set(preCookie.id);

  // Encode and remove unnecessary Base64 padding characters that could
  // interfere with the cookie data format.
  const preCookieEncoded = btoa(JSON.stringify(preCookie)).replace(/=/g, "");
  document.cookie = `pre=${preCookieEncoded}; SameSite=None; Secure; Domain=${domain}; Path=/; Expires=${new Date(
    253402300000000,
  )}`;
};
