import React from "react";
import { ITierType } from "../../../constants/org-pricing.constants";
import ContactUsButton from "../cta-buttons/ContactUsButton";
import DowngradeButton from "../cta-buttons/DowngradeButton";
import UpgradeButton from "../cta-buttons/UpgradeButton";
import { PlanTypes } from "../types";

type TierActionProps = {
  isUserOnTrial: boolean;
  onContactUsClick: (tierType: ITierType) => void;
  onDowngradeClick: (tierType: ITierType) => void;
  onUpgradeClick: (tierType: ITierType) => void;
  primarySubscriptionPlan: PlanTypes;
  tierType: ITierType;
  isM365Beta?: boolean;
};

const TierAction = ({
  isUserOnTrial,
  onDowngradeClick,
  onUpgradeClick,
  onContactUsClick,
  primarySubscriptionPlan,
  tierType,
  isM365Beta,
}: TierActionProps) => {
  switch (tierType) {
    // ~~~~~~~~~~~~~~~~~
    // Free plan actions
    // ~~~~~~~~~~~~~~~~~
    case "FreemiumOrg":
      return (
        <div className="cw-w-full cw-mt-1">
          <DowngradeButton
            isCurrentPlan={primarySubscriptionPlan === "Free"}
            onClick={() => onDowngradeClick(tierType)}
          />
        </div>
      );
    // ~~~~~~~~~~~~~~~~~
    // Pro plan actions
    // ~~~~~~~~~~~~~~~~~
    case "ProOrg":
      return (
        <div className="cw-w-full cw-mt-1">
          <UpgradeButton
            isUserOnTrial={isUserOnTrial}
            primarySubscriptionPlan={primarySubscriptionPlan}
            onClick={() => onUpgradeClick(tierType)}
          />
        </div>
      );
    // ~~~~~~~~~~~~~~~~~
    // Business plan action
    // ~~~~~~~~~~~~~~~~~
    case "BusinessOrg":
      return (
        <div className="cw-w-full cw-mt-1">
          <ContactUsButton
            isCurrentPlan={primarySubscriptionPlan === "Business"}
            onClick={() => onContactUsClick(tierType)}
            isM365Beta={isM365Beta}
          />
        </div>
      );
    // ~~~~~~~~~~~~~~~~~
    // Enterprise plan action
    // ~~~~~~~~~~~~~~~~~
    case "Enterprise":
      return (
        <div className="cw-w-full cw-mt-1">
          <ContactUsButton
            isCurrentPlan={primarySubscriptionPlan === "Enterprise"}
            onClick={() => onContactUsClick(tierType)}
            isM365Beta={isM365Beta}
          />
        </div>
      );
    default:
      return null;
  }
};

export default TierAction;
