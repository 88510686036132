import { InviteModalMode } from "#webapp/src/components/invite-modal/InviteModal.types";
import { ChromeAppWrapperView } from "#webapp/src/util/chrome-extension.util";
import {
  ActiveMutationCollection,
  ActiveQueryCollection,
} from "@clockwise/web-commons/src/util/apollo.util";

export const chromeExtensionActions = {
  NEW_MESSAGE_EVENT_DATA_FROM_CHROME_EXTENSION: "NEW_MESSAGE_EVENT_DATA_FROM_CHROME_EXTENSION",
  SET_ACTIVE_VIEW_CALENDAR_PROFILE: "SET_ACTIVE_VIEW_CALENDAR_PROFILE",
  SET_ACTIVE_VIEW_EVENT: "SET_ACTIVE_VIEW_EVENT",
  SET_ACTIVE_VIEW: "SET_ACTIVE_VIEW",
  SET_AUTOPILOT_FEED_STATUS_DEFRAG_CARD_NOTIFICATION_COUNT:
    "SET_AUTOPILOT_FEED_STATUS_DEFRAG_CARD_NOTIFICATION_COUNT",
  SET_CALENDAR_URL: "SET_CALENDAR_URL",
  SET_CONTENT_SCROLL_TOP: "SET_CONTENT_SCROLL_TOP",
  SET_IS_ONLINE: "SET_IS_ONLINE",
  SET_LAST_OFFLINE: "SET_LAST_OFFLINE",
  SET_MENU_DRAWER_OPEN: "SET_MENU_DRAWER_OPEN",
  SET_MODE: "SET_MODE",
  SET_SHOULD_SYNC_ACTIVE_ORG: "SET_SHOULD_SYNC_ACTIVE_ORG",
  SET_SUGGESTIONS_FEED_NOTIFICATION_COUNT: "SET_SUGGESTIONS_FEED_NOTIFICATION_COUNT",
  UNSET_RECENTLY_RESCHEDULED: "UNSET_RECENTLY_RESCHEDULED",
  SET_OPEN_SHARE_LINK_MODAL: "SET_OPEN_SHARE_LINK_MODAL",
  SET_OPEN_SHARE_WITH_COWORKERS_MODAL: "SET_OPEN_SHARE_WITH_COWORKERS_MODAL",
  SET_ASSISTANT_FEED_CARD_NOTIFICATION_COUNT: "SET_ASSISTANT_FEED_CARD_NOTIFICATION_COUNT",
  SET_QUERY_COLLECTION: "SET_QUERY_COLLECTION",
  SET_MUTATION_COLLECTION: "SET_MUTATION_COLLECTION",
};

export interface IChromeExtensionActionData {
  activeView?: ChromeAppWrapperView;
  calendarId?: string;
  calendarUrl?: string;
  contentScrollTop?: number;
  currentViewOfEventWasSetByUrlParam?: boolean;
  externalEventId?: string;
  isOnline?: boolean;
  lastOffline?: number;
  menuDrawerOpen?: boolean;
  mode?: "webapp" | "extension";
  notificationCount?: number;
  openReschedule?: boolean;
  payload?: any; // from post message
  recentlyRescheduled?: boolean;
  schedulerEmails?: string[];
  shouldFetchSuggestedTimes?: boolean;
  shouldSyncActiveOrg?: boolean;
  showClockwiseHistory?: boolean;
  topicId?: string;
  type?: string; // from post message
  viewWeek?: string;
  open?: boolean;
  visibility?: "visible" | "hidden";
  millis?: number;
  activeQueryCollection?: ActiveQueryCollection;
  activeMutationCollection?: ActiveMutationCollection;
  openShareWithCoworkersModal?: { open: boolean; mode: InviteModalMode };
}

export interface IChromeExtensionAction {
  type: string;
  data?: IChromeExtensionActionData;
}

export const newMessageEventDataFromChromeExtension = (
  data: IChromeExtensionActionData,
): IChromeExtensionAction => ({
  data,
  type: chromeExtensionActions.NEW_MESSAGE_EVENT_DATA_FROM_CHROME_EXTENSION,
});

export const unsetRecentlyRescheduled = () => ({
  type: chromeExtensionActions.UNSET_RECENTLY_RESCHEDULED,
});

export const setMode = (mode: "webapp" | "extension") => ({
  type: chromeExtensionActions.SET_MODE,
  data: {
    mode,
  },
});

export const setMenuDrawerOpen = (open: boolean) => ({
  type: chromeExtensionActions.SET_MENU_DRAWER_OPEN,
  data: {
    menuDrawerOpen: open,
  },
});

export const setActiveView = (view: ChromeAppWrapperView) => ({
  type: chromeExtensionActions.SET_ACTIVE_VIEW,
  data: {
    activeView: view,
  },
});

export const setActiveViewDay = () => ({
  type: chromeExtensionActions.SET_ACTIVE_VIEW,
  data: {
    activeView: ChromeAppWrapperView.Day,
  },
});

export const setActiveViewTeamHealth = () => ({
  type: chromeExtensionActions.SET_ACTIVE_VIEW,
  data: {
    activeView: ChromeAppWrapperView.TeamHealth,
  },
});

export const setActiveViewCalendarProfile = (calendarId: string, viewWeek?: string) => ({
  type: chromeExtensionActions.SET_ACTIVE_VIEW_CALENDAR_PROFILE,
  data: {
    calendarId,
    viewWeek,
  },
});

/**
 * @param calendarId
 * Important: Google makes copies of an event for every calendar that the event is on.
 * The event id will the same but the calendarId changes based on which calendar we're looking at.
 * Thus, be mindful when setting calendarId to the current user's calendarId vs the event organizer's calendarId (especially when they're different).
 * Setting the calendarId to event organizer's calendarId blocks the current user from rescheduling the event
 * because Google does not grant the user privileges to reschedule the other's copy of the event...
 * even though the user may be able to reschedule their own copy (which in result would reschedule the other's copy)
 */
export const setActiveViewEvent = (
  externalEventId: string,
  calendarId: string,
  recentlyRescheduled: boolean,
  openReschedule?: boolean,
  showClockwiseHistory?: boolean,
  currentViewOfEventWasSetByUrlParam = false,
) => ({
  type: chromeExtensionActions.SET_ACTIVE_VIEW_EVENT,
  data: {
    externalEventId,
    calendarId,
    recentlyRescheduled,
    openReschedule,
    showClockwiseHistory: !!showClockwiseHistory,
    currentViewOfEventWasSetByUrlParam: !!currentViewOfEventWasSetByUrlParam,
  },
});

export const setIsOnline = (isOnline: boolean) => ({
  type: chromeExtensionActions.SET_IS_ONLINE,
  data: {
    isOnline,
  },
});

export const setLastOffline = (lastOffline: number) => ({
  type: chromeExtensionActions.SET_LAST_OFFLINE,
  data: {
    lastOffline,
  },
});

export const setContentScrollTop = (contentScrollTop: number) => ({
  type: chromeExtensionActions.SET_CONTENT_SCROLL_TOP,
  data: {
    contentScrollTop,
  },
});

export const setShouldSyncActiveOrg = (shouldSyncActiveOrg: boolean) => ({
  type: chromeExtensionActions.SET_SHOULD_SYNC_ACTIVE_ORG,
  data: {
    shouldSyncActiveOrg,
  },
});

export const setQueryCollection = (activeQueryCollection: ActiveQueryCollection) => ({
  type: chromeExtensionActions.SET_QUERY_COLLECTION,
  data: {
    activeQueryCollection,
  },
});

export const setMutationCollection = (activeMutationCollection: ActiveMutationCollection) => {
  return {
    type: chromeExtensionActions.SET_MUTATION_COLLECTION,
    data: {
      activeMutationCollection,
    },
  };
};

export const setCalendarUrl = (calendarUrl: string) => ({
  type: chromeExtensionActions.SET_CALENDAR_URL,
  data: {
    calendarUrl,
  },
});

export const setOpenShareLinkModal = (open: boolean) => ({
  type: chromeExtensionActions.SET_OPEN_SHARE_LINK_MODAL,
  data: {
    open,
  },
});

export const setOpenShareWithCoworkersModal = (open: boolean, mode: InviteModalMode) => ({
  type: chromeExtensionActions.SET_OPEN_SHARE_WITH_COWORKERS_MODAL,
  data: {
    openShareWithCoworkersModal: {
      open: open,
      mode: mode,
    },
  },
});
