import { debounce } from "lodash";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { TextField } from "@clockwise/design-system";
import { TeamNameCreationInputDocument } from "./__generated__/TeamsNameCreateInput.generated";
import { confirmIfNameIsValid, NameStatus, TEAM_NAME_MAX_LENGTH } from "./TeamNameCreateInput.util";

type TeamNameCreateInputProps = {
  onChange: (value: string, valid: boolean) => void;
  initialTeamName: string;
  orgId: string | null;
};

export const TeamNameCreateInput = ({
  orgId,
  initialTeamName,
  onChange,
}: TeamNameCreateInputProps) => {
  const [status, setStatus] = useState<NameStatus>("unset");
  const [teamNameForQuery, setTeamNameForQuery] = useState("");
  const [helperText, setHelperText] = useState("");
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    setTeamNameForQuery(initialTeamName);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useQuery(TeamNameCreationInputDocument, {
    variables: {
      orgRelayId: orgId || "",
      query: teamNameForQuery,
    },
    skip: !orgId,
    onCompleted: (res) => {
      const teamsObject = getValue(res?.viewer?.teamQueryResultErrorable, "TeamQueryResult");
      const result = confirmIfNameIsValid(teamsObject, inputText, initialTeamName);
      if (result) {
        const { newStatus, newHelperText, isValid, query } = result;
        onChange(query, isValid);
        setStatus(newStatus);
        setHelperText(newHelperText);
      } else {
        setStatus("unset");
      }
    },
  });

  /////////////
  // Helpers //
  /////////////
  const debouncedChange = useCallback(
    debounce((newTeamName: string) => {
      onHandleInputChange(newTeamName);
    }, 500),
    [],
  );

  const onHandleInputChange = (newTeamName: string) => {
    if (newTeamName && orgId) {
      setStatus("loading");
      setHelperText("");
      setTeamNameForQuery(newTeamName.trim());
    } else {
      setStatus("unset");
      setHelperText("");
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputText(value);
    debouncedChange(value);
  };

  return (
    <TextField
      autoFocus
      loading={status === "loading"}
      error={status === "error"}
      errorMessage={helperText}
      maxLength={TEAM_NAME_MAX_LENGTH}
      placeholder="eg. Marketing, Design, Engineering..."
      value={inputText}
      onChange={handleChange}
    />
  );
};
