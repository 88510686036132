import { graphql } from "react-relay";

export const deleteZoomAccountMutation = graphql`
  mutation DeleteZoomAccountMutation($input: DeleteZoomAccountMutationInput!) {
    deleteZoomAccount(input: $input) {
      __typename
      deletedZoomAccountId
      clientMutationId
    }
  }
`;
