import {
  getDefaultEventName,
  isEventNameDefault,
} from "../../web-app-calendar/calendar-week/utils/defaultEventName";
import { Attendee } from "../CurrentProposalContext";

/**
 * Generates a new title if the old title was a default (wasn't changed by the user).
 */
export function getNewTitle(oldTitle: string, oldAttendees: Attendee[], newAttendees: Attendee[]) {
  return isEventNameDefault(oldTitle, { attendees: oldAttendees })
    ? getDefaultEventName({ attendees: newAttendees })
    : oldTitle;
}
