export const url = "https://www.getclockwise.com";
export const careersEmail = "careers@getclockwise.com";
export const contactEmail = "hello@getclockwise.com";
export const feedbackEmail = "feedback@getclockwise.com";
export const phoneNumber = "888-720-9788";
export const securityEmail = "security@getclockwise.com";
export const supportEmail = "support@getclockwise.com";
export const salesEmail = "sales@getclockwise.com";
export const address = ["Clockwise Inc.", "2261 Market Street #5100", "San Francisco, CA 94114"];

export const twitterUrl = "https://twitter.com/getclockwise";
export const helpUrl = "https://getclockwise.helpscoutdocs.com/";
export const gcalUrl = "https://calendar.google.com";
export const statusUrl = "https://status.getclockwise.com";
export const salesContactFormUrl = "https://www.getclockwise.com/contact-sales";
export const m365InterestFormUrl = "https://www.getclockwise.com/ms365";

//cancellation request
export const planCancellationRequestUrl =
  "https://form.asana.com/?k=TOnFDa9P3T5jDglkCUwdYg&d=322428955322623";

export const slackAppStoreUrl =
  "https://clockwisehq.slack.com/apps/A89HZ352N-clockwise?tab=more_info";

// paths
export const paths = {
  login: "/login",
  signup: "/signup",
  signout: "/signout",
  m365SignIn: "/m365",
  logout: "/logout",
  chromeSlackVerifier: "/chromeslack",
  terms: "/terms",
  privacy: "/privacy",
  careers: "/careers",
  about: "/about",
  blog: "https://www.getclockwise.com/blog",
  copyrightDispute: "/copyright-dispute",
  communityGuidelines: "/community-guidelines",
  uninstall: "/uninstall",
  nonUserDefragSurvey: "/meeting-move-survey",
  pricing: "/pricing",
  checkout: "/checkout",
  googleAuthRedirect: "/google/connect",
  microsoftAuthRedirect: "/microsoft/connect",
  slackLanding: "/slack",
  unsubscribe: "/unsubscribe",
  styleGuide: "/styleguide",
  webApp: "/app",
  chat: "/app/chat",
  calendar: "/app/calendar",
  planner: "/app/planner",
  security: "/security",
  sudo: "/sudo",
  invite: "/invite",
  personalInvite: "/invite/link",
  eventReschedule: "/eventreschedule",
  welcome: "/welcome",
  chromeZoomVerifier: "/zoom",
  admin: "/app/admin",
  /** @deprecated Moved to `flexibleMeetings` path. */
  oneOnOnes: "/app/one-on-ones",
  flexibleMeetings: "/app/flexibility",
  flexibleHolds: "/app/flexibility#holds",
  focusTime: "/app/focus-time",
  scheduling: "/scheduling",
  schedulingLink: "/c/:linkName/:slug",
  sharedProposal: "/p/:proposalId",
  editSchedulingLink: "/app/edit-scheduling-link/:linkName/:slug",
  createSchedulingLink: "/app/create-scheduling-link",
  schedulingLinks: "/app/scheduling-links",
  cancelBooking: "/booking/:bookingId/cancel",
  rescheduleBooking: "/booking/:bookingId/reschedule",
  zoomSettings: "/app/settings/zoom",
  flexMeetings: "/flexibility",
  /** @deprecated Moved to `/preferences` path. */
  webSettings: "/web-settings",
  signupExtension: "/signupextension",
  onboarding: "/onboarding",
  installExtension: "/install-extension",
} as const;

export const supportLinks = {
  flexMeetings: "/article/184-flexible-meetings",
};
// links in the footer of marketing pages
export const footerLinks = [
  { label: "About", path: paths.about },
  // { label: 'Blog', path: paths.blog },
  { label: "Careers", path: paths.careers },
  { label: "Security", path: paths.security },
  { label: "Terms", path: paths.terms },
  { label: "Privacy", path: paths.privacy },
  // { label: 'Help Center', path: helpUrl },
  // { label: 'Contact', path: `mailto:${contactEmail}` },
];

export const gmapsAddressLink =
  "https://www.google.com/maps/place/Clockwise/@37.7794399,-122.3981009,17z/data=!3m1!4b1!4m5!3m4!1s0x808f7fd63e23bda7:0x4f762d0d4df386ca!8m2!3d37.7794399!4d-122.3959122";

export const CLOCKWISE_ACADEMY = "https://learn.getclockwise.com/";
