import { SubscriptionInterval } from "#webapp/src/__schema__";
import { paths, salesContactFormUrl, supportEmail } from "#webapp/src/constants/site.constant";
import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { getArrayOfNumbersDivisbleByN } from "#webapp/src/util/math.util";
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import {
  Button,
  Divider,
  LinearProgress,
  Link,
  Select,
  SelectOption,
  TextField,
} from "@clockwise/design-system";
import { AddCircleOutline, Check } from "@clockwise/design-system/icons";
import { proTier as proOrgTier } from "@clockwise/web-commons/src/constants/org-pricing.constants";
import classNames from "classnames";
import pluralize from "pluralize";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { devPromoCodeMap, promoCodeMap } from "../util/constants";
import { validateDevPromoCode, validatePromoCode } from "../util/validatePromoCode";
import { CheckoutItem } from "./";

interface ReceiptItemsProps {
  selectedBillingRecurrence: SubscriptionInterval;
  isTeamPlanSelected: boolean;
  maxOrgSeatSize: number;
  maxTeamSeatSize: number;
  getSeatCount: () => number;
  createSubscription: (promoCode?: string) => void;
  submitDisabled: boolean;
  setPaidSeatCount: (seat: number) => void;
  paidSeatCount: number;
  minimumSeatCount: number;
  isLoading: boolean;
}

const ListItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <li className="cw-flex cw-items-center cw-py-1">
      <Check className="cw-mr-2 cw-body-base" /> {children}
    </li>
  );
};

export const ReceiptItems = ({
  selectedBillingRecurrence,
  isTeamPlanSelected,
  maxOrgSeatSize,
  maxTeamSeatSize,
  getSeatCount,
  createSubscription,
  submitDisabled,
  setPaidSeatCount,
  paidSeatCount,
  minimumSeatCount,
  isLoading,
}: ReceiptItemsProps) => {
  const [promoCode, setPromoCode] = useState<string | undefined>(undefined);
  const [promoCodeDiscount, setPromoCodeDiscount] = useState(0);
  const [showPromoCodeInput, setShowPromoCodeInput] = useState(false);
  const ORG_PRICE_YEARLY = proOrgTier.planPricing.yearlyCost;
  const ORG_PRICE_MONTHLY = proOrgTier.planPricing.monthlyCost;

  const isYearlyPricing = selectedBillingRecurrence === SubscriptionInterval.Yearly;
  const costPerIntervalAnnually = ORG_PRICE_YEARLY * paidSeatCount * 12 * (1 - promoCodeDiscount);
  const costPerIntervalMonthly = ORG_PRICE_MONTHLY * paidSeatCount * 1 * (1 - promoCodeDiscount);
  const costPerIntervalAnnualyWithoutAnnualDiscount = ORG_PRICE_MONTHLY * paidSeatCount * 12;

  const countUsers = pluralize("user", paidSeatCount, true);
  const monthlyCostIfAnnualLabel = `${countUsers} x $${ORG_PRICE_YEARLY} x 12 months`;
  const monthlyCostIfMonthlyLabel = `${countUsers} x $${ORG_PRICE_MONTHLY}`;

  const MAX_SEAT_SIZE_FOR_SELECTED_PLAN = isTeamPlanSelected ? maxTeamSeatSize : maxOrgSeatSize;

  const seatOptionsForSelectedPlan = isTeamPlanSelected
    ? [...new Set([getSeatCount(), 1, 2, 3, 4, ...getArrayOfNumbersDivisbleByN(5, 50, 5)])]
    : [...new Set([getSeatCount(), ...getArrayOfNumbersDivisbleByN(5, 150, 5)])];

  const onClickContactSales = () => {
    window.open(salesContactFormUrl);
    track(TrackingEvents.CHECKOUT.CONTACT_SALES_CLICKED);
  };

  const onChangePaidSeatCount = (newPaidSeatCount: number) => {
    setPaidSeatCount(newPaidSeatCount);
  };

  const onValidatePromoCode = (promoCode?: string) => {
    if (!promoCode) {
      return;
    }
    if (getEnvironment() === "production") {
      if (validatePromoCode(promoCode)) {
        setPromoCodeDiscount(promoCodeMap[promoCode].discount);
      } else {
        toast.error("We're sorry, that promo code is not valid.");
      }
    } else {
      if (validateDevPromoCode(promoCode)) {
        setPromoCodeDiscount(devPromoCodeMap[promoCode].discount);
      } else {
        toast.error("We're sorry, that promo code is not valid.");
      }
    }
  };
  const updatePromoCode = (newPromoCode: string) => {
    setPromoCode(newPromoCode);
  };

  const togglePromoCodeInput = () => {
    setShowPromoCodeInput(!showPromoCodeInput);
  };

  const hasDiscount = promoCodeDiscount > 0;

  if (getSeatCount() > MAX_SEAT_SIZE_FOR_SELECTED_PLAN) {
    return (
      <div>
        <div className="cw-heading-lg cw-mb-4">Review and purchase</div>
        <div className="cw-bg-neutral cw-w-[415px] cw-p-5 cw-rounded">
          <div className="cw-body-base">
            Your organization has more than {maxOrgSeatSize} users. Please{" "}
            <Link target="_blank" href={salesContactFormUrl}>
              {" "}
              contact sales{" "}
            </Link>{" "}
            to upgrade your plan to boost your organization’s productivity with:{" "}
          </div>
          <ul className="cw-body-base cw-my-4">
            <ListItem> Unlimited schedule assists to create more time in the day</ListItem>
            <ListItem> Productivity tools to work smarter, together </ListItem>
            <ListItem> Advanced calendar control for sustainable workdays </ListItem>
          </ul>
          <div className="cw-mt-4">
            <Button fullWidth sentiment="positive" onClick={onClickContactSales}>
              Contact Sales
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="cw-heading-lg cw-mb-4">Review and purchase</div>
      <div className="cw-bg-neutral cw-w-[415px] cw-p-5 cw-rounded">
        <div>
          <CheckoutItem>
            Upgrade your {isTeamPlanSelected ? "team" : "organization"} to the Teams plan
          </CheckoutItem>
          <CheckoutItem>
            <div className="cw-flex cw-items-center cw-gap-1">
              <div>Pay today for</div>
              <Select
                value={paidSeatCount}
                onChange={onChangePaidSeatCount}
                name="Seat count"
                size="medium"
              >
                {seatOptionsForSelectedPlan
                  .filter((c) => c >= getSeatCount() && c >= minimumSeatCount)
                  .map((c) => (
                    <SelectOption key={`seat-count-${c}`} value={c}>
                      {pluralize("seat", c, true)}
                    </SelectOption>
                  ))}
              </Select>
            </div>
          </CheckoutItem>
          <CheckoutItem>
            Pay for future users joining your {isTeamPlanSelected ? "team" : "organization"}
          </CheckoutItem>
        </div>
        <Divider />
        <div>
          <div className="cw-heading-sm cw-mb-2">Total billed today</div>
          <div className="cw-flex cw-items-end cw-w-full cw-gap-x-12">
            <div className="cw-flex cw-flex-col cw-w-full cw-gap-y-1">
              <div
                className="cw-caption cw-text-positive hover:cw-underline cw-cursor-pointer cw-flex cw-items-center"
                onClick={togglePromoCodeInput}
              >
                Add promo code{" "}
                <AddCircleOutline className="cw-ml-1 cw-w-3 cw-h-3 cw-text-positive" />
              </div>
              {showPromoCodeInput && (
                <TextField
                  value={promoCode}
                  onChange={(e) => updatePromoCode(e.target.value)}
                  disabled={hasDiscount}
                />
              )}
            </div>
            {showPromoCodeInput && (
              <Button
                size="small"
                sentiment="positive"
                onClick={() => onValidatePromoCode(promoCode)}
                disabled={hasDiscount}
              >
                Apply
              </Button>
            )}
          </div>
          {isYearlyPricing ? (
            <div>
              <div className="cw-flex cw-items-center cw-justify-between cw-my-1">
                <div className="cw-body-base">{monthlyCostIfAnnualLabel}</div>
                <div className="cw-body-lg cw-font-bold cw-line-through cw-text-subtle">
                  {/* Here we show the monthly cost to strike out and show how much they are saving */}
                  ${costPerIntervalAnnualyWithoutAnnualDiscount.toFixed(2)} + tax
                </div>
              </div>
              <div className="cw-flex cw-items-end cw-justify-between cw-my-2">
                <div className="cw-flex cw-flex-col cw-gap-1">
                  <div className="cw-body-base cw-text-brand">
                    13% Annual Discount {hasDiscount && <>+</>}
                  </div>
                  {hasDiscount && (
                    <div className="cw-body-base cw-text-brand">
                      {promoCodeDiscount * 100}% {promoCode} Discount{" "}
                    </div>
                  )}
                </div>

                <div className="cw-body-lg cw-font-bold cw-text-brand">
                  ${costPerIntervalAnnually.toFixed(2)} + tax
                </div>
              </div>
            </div>
          ) : (
            <div className="cw-flex cw-items-end cw-justify-between cw-my-1">
              <div className="cw-flex cw-flex-col cw-gap-1">
                <div className="cw-body-base">
                  {monthlyCostIfMonthlyLabel} {hasDiscount && <>+</>}
                </div>
                {hasDiscount && (
                  <div className="cw-body-base cw-text-brand">
                    {promoCodeDiscount * 100}% {promoCode} Discount{" "}
                  </div>
                )}
              </div>

              <div
                className={classNames("cw-body-lg cw-font-bold", {
                  "cw-text-positive": hasDiscount,
                })}
              >{`$${costPerIntervalMonthly.toFixed(2)} / month + tax`}</div>
            </div>
          )}
        </div>

        <Divider />
        <div className="cw-body-sm">
          By clicking “confirm upgrade,” you agree to our{" "}
          <Link target="_blank" href={paths.terms}>
            subscriber terms
          </Link>
          . Your subscription will automatically renew until you cancel. The subscription fee will
          be automatically updated if you modify the amount of users in your paid team or
          organization. You can make changes or{" "}
          <Link target="_blank" href={`mailto:${supportEmail}`}>
            cancel
          </Link>{" "}
          the plan at any time.
        </div>
        <div className="cw-mt-4">
          <Button
            fullWidth
            disabled={submitDisabled}
            onClick={() => createSubscription(promoCode)}
            sentiment="positive"
          >
            Confirm upgrade
          </Button>
        </div>
      </div>
      {isLoading && <LinearProgress className="cw-bg-transparent cw-bottom-0 cw-w-full" />}
    </div>
  );
};
