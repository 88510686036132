export type PromoCodes = Record<
  string,
  {
    discount: number;
  }
>;

export const promoCodeMap: PromoCodes = {
  Atlassian25: {
    discount: 0.25,
  },
};

export const devPromoCodeMap: PromoCodes = {
  MONTHFREE: {
    discount: 0.0833,
  },
  TEST10: {
    discount: 0.1,
  },
};
