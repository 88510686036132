import { Dispatch, SetStateAction, useEffect, useState } from "react";

/**
 *
 * A useState hook that resets the boolean state after a timeout.
 *
 * @param initialState Initial value that state will reset to after the timeout.
 * @param timeoutMs How long before the state resets, in milliseconds. Defaults to 2 seconds.
 */
export function useResettingBoolean(
  initialState: boolean,
  timeoutMs: number = 2000,
): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [happened, setHappened] = useState(initialState);

  /**
   * Reset the state of the `copied` flag after 2 seconds of display, with a cleanup function to
   * avoid flickering if the button is clicked multiple times.
   */
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (happened) {
      timeout = setTimeout(() => setHappened(initialState), timeoutMs);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [happened, setHappened]);

  return [happened, setHappened];
}
