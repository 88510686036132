import { compact, difference, keys, uniq } from "lodash";
import {
  CalendarIds,
  DiffIds,
  EventIdOrDiffIdToCalendarIds,
  MultiCalendarActions,
} from "../actions/multi-calendar.actions";

export type IMultiCalendarState = {
  userCalendarsIds: CalendarIds;
  userSelectedCalendarIds: CalendarIds;
  selectedDiffIds: DiffIds;
  eventIdOrDiffIdMappedToCalendarIds: EventIdOrDiffIdToCalendarIds; // These are event ids or diff ids
  calendarIdsToFullyShow: CalendarIds;
  calendarIdsForCalendarPeek: CalendarIds;
  eventIdOrDiffIdMappedToCalendarIdsBeforeEdit: EventIdOrDiffIdToCalendarIds;
};

export const initialMultiCalendarState: IMultiCalendarState = {
  userCalendarsIds: [],
  userSelectedCalendarIds: [],
  selectedDiffIds: [],
  eventIdOrDiffIdMappedToCalendarIds: {},
  calendarIdsToFullyShow: [],
  calendarIdsForCalendarPeek: [],
  eventIdOrDiffIdMappedToCalendarIdsBeforeEdit: {},
};

export interface IMultiCalendarActions {
  type: MultiCalendarActions;
  data: {
    calendarIds?: CalendarIds;
    diffIdsOrEventIds?: DiffIds;
    eventIdOrDiffIdMappedToCalendarIds?: EventIdOrDiffIdToCalendarIds;
    diffId?: string;
    diffIdOrEventId?: string;
    eventIdOrDiffIdMappedToCalendarIdsBeforeEdit?: EventIdOrDiffIdToCalendarIds;
    proposalIsProcessing?: boolean;
  };
}

export function multiCalendarReducer(
  state: IMultiCalendarState = initialMultiCalendarState,
  action: IMultiCalendarActions,
): IMultiCalendarState {
  const getCalendarIdsToFullyShow = (
    newUserSelectedCalendarIds: CalendarIds,
    newSelectedDiffIds: DiffIds,
    newEventIdOrDiffIdToCalendarIds?: EventIdOrDiffIdToCalendarIds,
  ) => {
    const { calendarIdsToFullyShow } = state;
    const calendarsFromSelectedDiffs = newSelectedDiffIds.flatMap(
      (diffId) =>
        (newEventIdOrDiffIdToCalendarIds || state.eventIdOrDiffIdMappedToCalendarIds)[diffId],
    );

    const newCalendarIdsToFullyShow: CalendarIds = compact(
      uniq([
        ...state.userCalendarsIds,
        ...newUserSelectedCalendarIds,
        ...calendarsFromSelectedDiffs,
      ]),
    );

    const newCalendarIdsToFullyShowOrderedByPreviousOrder = newCalendarIdsToFullyShow.sort(
      (a, b) => {
        // This is to ensure the order of the calendar ids from the previous state is maintained
        // New calendar ids are added to the end of the array
        // Removals of calendar ids are removed from the array preserving the rest of the order
        if (calendarIdsToFullyShow.indexOf(a) === -1) {
          return 1;
        }
        if (calendarIdsToFullyShow.indexOf(b) === -1) {
          return -1;
        }
        return calendarIdsToFullyShow.indexOf(a) - calendarIdsToFullyShow.indexOf(b);
      },
    );

    return newCalendarIdsToFullyShowOrderedByPreviousOrder;
  };

  const getUserSelectedCalendarIdsAfterRemove = (calendarIdsToRemove: CalendarIds) => {
    const calendarIdsToRemoveWithoutUsersCalendarIds = difference(
      calendarIdsToRemove,
      state.userCalendarsIds,
    );

    const newUserSelectedCalendarIds = compact(
      uniq(difference(state.userSelectedCalendarIds, calendarIdsToRemoveWithoutUsersCalendarIds)),
    );

    return newUserSelectedCalendarIds;
  };

  const getSelectedDiffIds = (
    newCalendarIdsToFullyShow: CalendarIds,
    newState?: IMultiCalendarState,
  ) => {
    const stateToUse = newState || state;

    const newSelectedDiffIds = keys(stateToUse.eventIdOrDiffIdMappedToCalendarIds).filter(
      (diffId) => {
        const calendarIds = stateToUse.eventIdOrDiffIdMappedToCalendarIds[diffId];
        const areCalendarIdsInNewCalendarIdsToFullyShow = calendarIds.every((calendarId) =>
          newCalendarIdsToFullyShow.includes(calendarId),
        );

        return areCalendarIdsInNewCalendarIdsToFullyShow;
      },
    );

    return newSelectedDiffIds;
  };

  switch (action.type) {
    case MultiCalendarActions.AddEventIdOrDiffIdWithCalendarIdsBeforeEdit: {
      const { diffIdOrEventId, calendarIds } = action.data;
      const { eventIdOrDiffIdMappedToCalendarIdsBeforeEdit } = state;

      if (!diffIdOrEventId || !calendarIds) {
        return {
          ...state,
        };
      }

      const newEventIdOrDiffIdToCalendarIdsBeforeEdit: EventIdOrDiffIdToCalendarIds = {
        ...eventIdOrDiffIdMappedToCalendarIdsBeforeEdit,
        [diffIdOrEventId]: calendarIds,
      };

      return {
        ...state,
        eventIdOrDiffIdMappedToCalendarIdsBeforeEdit: newEventIdOrDiffIdToCalendarIdsBeforeEdit,
      };
    }
    case MultiCalendarActions.AddUserCalendarIds: {
      const { calendarIds } = action.data;
      const newUserCalendarIdsToAdd = calendarIds || [];

      return {
        ...state,
        userCalendarsIds: [...state.userCalendarsIds, ...newUserCalendarIdsToAdd],
      };
    }
    case MultiCalendarActions.AddEventIdOrDiffIdWithCalendarIds: {
      const { calendarIds, diffId } = action.data;
      const {
        eventIdOrDiffIdMappedToCalendarIds,
        userSelectedCalendarIds,
        selectedDiffIds,
      } = state;

      if (!diffId || !calendarIds) {
        return {
          ...state,
        };
      }

      const newEventIdOrDiffIdToCalendarIds: EventIdOrDiffIdToCalendarIds = {
        ...eventIdOrDiffIdMappedToCalendarIds,
        [diffId]: calendarIds,
      };

      const newCalendarIdsToFullyShow = getCalendarIdsToFullyShow(
        userSelectedCalendarIds,
        selectedDiffIds,
        newEventIdOrDiffIdToCalendarIds,
      );

      const intermediateState = {
        ...state,
        eventIdOrDiffIdMappedToCalendarIds: newEventIdOrDiffIdToCalendarIds,
        calendarIdsToFullyShow: newCalendarIdsToFullyShow,
      };

      // Changes to `eventIdOrDiffIdMappedToCalendarIds` can turn on other diffs that were previously off
      const newSelectedDiffIds = getSelectedDiffIds(newCalendarIdsToFullyShow, intermediateState);

      return {
        ...intermediateState,
        selectedDiffIds: newSelectedDiffIds,
      };
    }
    case MultiCalendarActions.RemoveEventIdOrDiffIdWithCalendarIds: {
      // Note: If you want the calendars removed, you must first call `RemoveDiffIdsOrEventIds` first
      // then call this action
      const { diffIdOrEventId } = action.data;

      if (!diffIdOrEventId) {
        return {
          ...state,
        };
      }

      const { eventIdOrDiffIdMappedToCalendarIds } = state;
      const newEventIdOrDiffIdToCalendarIds = { ...eventIdOrDiffIdMappedToCalendarIds };

      delete newEventIdOrDiffIdToCalendarIds[diffIdOrEventId];

      return {
        ...state,
        eventIdOrDiffIdMappedToCalendarIds: newEventIdOrDiffIdToCalendarIds,
      };
    }

    case MultiCalendarActions.AddCalendarIds: {
      const { calendarIds } = action.data;

      const newCalendarIdsToAdd = calendarIds || [];
      const newUserSelectedCalendarIds = compact(
        uniq([...state.userSelectedCalendarIds, ...newCalendarIdsToAdd]),
      );

      const newCalendarIdsToFullyShow = getCalendarIdsToFullyShow(
        newUserSelectedCalendarIds,
        state.selectedDiffIds,
      );

      const newSelectedDiffIds = getSelectedDiffIds(newCalendarIdsToFullyShow);

      return {
        ...state,
        userSelectedCalendarIds: newUserSelectedCalendarIds,
        calendarIdsToFullyShow: newCalendarIdsToFullyShow,
        selectedDiffIds: newSelectedDiffIds,
      };
    }
    case MultiCalendarActions.RemoveCalendarIds: {
      const { calendarIds } = action.data;
      const { userCalendarsIds } = state;

      const calendarIdsToRemove = calendarIds || [];
      const calendarIdsToRemoveWithoutUsersCalendarIds = difference(
        calendarIdsToRemove,
        userCalendarsIds,
      );

      // This is a UX decision to remove the diffs that contain the calendar ids being removed
      // but we'll all add back all the other calendars of those diffs
      const newCalendarIdsToFullyShow = difference(
        state.calendarIdsToFullyShow,
        calendarIdsToRemoveWithoutUsersCalendarIds,
      );
      const newUserSelectedCalendarIds = difference(newCalendarIdsToFullyShow, userCalendarsIds);
      const newSelectedDiffIds = getSelectedDiffIds(newCalendarIdsToFullyShow);

      return {
        ...state,
        userSelectedCalendarIds: newUserSelectedCalendarIds,
        calendarIdsToFullyShow: newCalendarIdsToFullyShow,
        selectedDiffIds: newSelectedDiffIds,
      };
    }
    case MultiCalendarActions.ClearCalendarIds: {
      // Recalling the `multiCalendarReducer` with the `RemoveCalendarIds` action
      // ensures all the calendars are removed from the state with all UX the side effects
      return multiCalendarReducer(state, {
        type: MultiCalendarActions.RemoveCalendarIds,
        data: {
          calendarIds: state.userSelectedCalendarIds,
        },
      });
    }
    case MultiCalendarActions.AddDiffIdsOrEventIds: {
      const { diffIdsOrEventIds } = action.data;
      const { userSelectedCalendarIds } = state;

      const newDiffIdsToAdd = diffIdsOrEventIds || [];
      const newSelectedDiffIds = compact(uniq([...state.selectedDiffIds, ...newDiffIdsToAdd]));

      const newCalendarIdsToFullyShow = getCalendarIdsToFullyShow(
        userSelectedCalendarIds,
        newSelectedDiffIds,
      );

      // Adding multi diffs can turn on other diffs that were previously off
      // Thus we need to add those diffs to the selected diff ids
      const newNewSelectedDiffIds = getSelectedDiffIds(newCalendarIdsToFullyShow);

      return {
        ...state,
        selectedDiffIds: newNewSelectedDiffIds,
        calendarIdsToFullyShow: newCalendarIdsToFullyShow,
      };
    }
    case MultiCalendarActions.ReplaceDiffIdsOrEventIds: {
      const { diffIdsOrEventIds } = action.data;

      const newSelectedDiffIds = [...(diffIdsOrEventIds ?? [])];

      const newCalendarIdsToFullyShow = getCalendarIdsToFullyShow([], newSelectedDiffIds);

      return {
        ...state,
        selectedDiffIds: newSelectedDiffIds,
        calendarIdsToFullyShow: newCalendarIdsToFullyShow,
      };
    }
    case MultiCalendarActions.RemoveDiffIdsOrEventIds:
      const { diffIdsOrEventIds } = action.data;
      const { eventIdOrDiffIdMappedToCalendarIds } = state;
      const newDiffIdsToRemove = diffIdsOrEventIds || [];
      const newSelectedDiffIds = compact(
        uniq(difference(state.selectedDiffIds, newDiffIdsToRemove)),
      );

      const allCalendarIdsOfDiffIdsToRemove = newDiffIdsToRemove.flatMap(
        (diffId) => eventIdOrDiffIdMappedToCalendarIds[diffId],
      );

      // This is a UX decision to remove the calendars associated with the diff
      // even if they were manually selected by the user
      const newUserSelectedCalendarIds = getUserSelectedCalendarIdsAfterRemove(
        allCalendarIdsOfDiffIdsToRemove,
      );

      const newCalendarIdsToFullyShow = getCalendarIdsToFullyShow(
        newUserSelectedCalendarIds,
        newSelectedDiffIds,
      );

      return {
        ...state,
        userSelectedCalendarIds: newUserSelectedCalendarIds,
        selectedDiffIds: newSelectedDiffIds,
        calendarIdsToFullyShow: newCalendarIdsToFullyShow,
      };
    case MultiCalendarActions.ClearDiffIdsAndEventIds: {
      // Recalling the `multiCalendarReducer` with the `ClearDiffIdsAndEventIds` action
      // ensures all the calendars are removed from the state with all UX the side effects
      return multiCalendarReducer(state, {
        type: MultiCalendarActions.RemoveDiffIdsOrEventIds,
        data: {
          diffIdsOrEventIds: state.selectedDiffIds,
        },
      });
    }
    case MultiCalendarActions.ClearAllSelected: {
      const userSelectedCalendarIds: CalendarIds = [];
      const selectedDiffIds: DiffIds = [];
      const newCalendarIdsToFullyShow = getCalendarIdsToFullyShow(
        userSelectedCalendarIds,
        selectedDiffIds,
      );

      return {
        ...state,
        userSelectedCalendarIds,
        selectedDiffIds: [],
        calendarIdsToFullyShow: newCalendarIdsToFullyShow,
      };
    }
    case MultiCalendarActions.SetCalendarIdsForCalendarPeek: {
      const { calendarIds } = action.data;
      const newCalendarIdsForCalendarPeek = calendarIds || [];

      return {
        ...state,
        calendarIdsForCalendarPeek: newCalendarIdsForCalendarPeek,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
