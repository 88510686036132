import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import {
  inviteLink,
  signupExperimentExtensionRedirect,
  signupExperimentInitialAnonymousId,
} from "@clockwise/web-commons/src/util/local-storage";

export const setLoginTrackingEvents = () => {
  if (!!signupExperimentExtensionRedirect && signupExperimentExtensionRedirect.get() === true) {
    track(TrackingEvents.Q4_2022_SIGNUP_EXPERIMENT.SIGNUP_EXPERIMENT_EXTENSION_REDIRECT_LOGIN);
    // Set to false so that it's not counted multiple times
    signupExperimentExtensionRedirect.set(false);
  }

  const localInviteLink = inviteLink.getAll();

  if (!!inviteLink && !localInviteLink.hasSignedUp) {
    track(TrackingEvents.INVITE_ATTRIBUTION.USER_AUTHENTICATED_AFTER_APP_INVITE_LINK, {
      referrer: localInviteLink.referrer,
    });
    inviteLink.set("hasSignedUp", true);
  }

  if (!!signupExperimentExtensionRedirect && signupExperimentExtensionRedirect.get() === true) {
    const initialAnonymousId = signupExperimentInitialAnonymousId.get();
    track(TrackingEvents.Q4_2022_SIGNUP_EXPERIMENT.SIGNUP_EXPERIMENT_EXTENSION_REDIRECT_LOGIN, {
      initialAnonymousId: initialAnonymousId,
    });
    // Set to false so that it's not counted multiple times
    signupExperimentExtensionRedirect.set(false);
  }
};
