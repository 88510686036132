// schema
import * as ISchema from "#webapp/src/__schema__";

import { Slot, timeSlots } from "#webapp/src/constants/time-slots.constant";
import { warnOutsideTests } from "#webapp/src/util/helpers.util";
import { ZonedMoment } from "@clockwise/client-commons/src/util/ZonedMoment";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import moment from "moment-timezone";

type genericSlot = Slot;

////////////////////////
// SIMPLE HELPERS
///////////////////////
// take datestring of format 'YYYY-MM-DD' and add or subtract days
export function incrementDayInDateString(dateString: string, dayIncrement: number) {
  if (dateString.length !== 10 || dateString.split("-").length !== 3) {
    warnOutsideTests("check format of dateString passed to incrementDayInDateString");
  }
  const date = moment.utc(dateString, "YYYY-MM-DD");
  date.add(dayIncrement, "days");
  return date.format("YYYY-MM-DD");
}

////////////////////////
// TIME SLOTS (used with working hours)
///////////////////////

function convertTimeSlotToHourMinutes(
  slot: ISchema.MinSlot | ISchema.MaxSlot | ISchema.ExcludedSlots | ISchema.TimeSlot,
  workingHoursTimeZone?: string,
  isMinSlot = true,
  nowZonedMomentForTests?: ZonedMoment,
) {
  let hour = Number(slot.substring(2, 4));
  let minute = Number(slot.substring(5, 7));
  let minutesIntoDay = hour * 60 + minute;

  if (!isMinSlot) {
    hour = minute < 30 ? hour : hour + 1;
    minute = minute < 30 ? minute : 0;
    minutesIntoDay += 30;
  }

  if (workingHoursTimeZone) {
    // apply difference of timezones
    const now = nowZonedMomentForTests || new ZonedMoment().utc();
    const workingHoursTimeZoneOffset = new ZonedMoment(now, workingHoursTimeZone).utcOffset();
    const renderTimeZoneOffset = new ZonedMoment(now, getRenderTimeZone()).utcOffset();
    const tzOffset = renderTimeZoneOffset - workingHoursTimeZoneOffset;
    hour += tzOffset / 60;
    minute += tzOffset % 60;
    minutesIntoDay += tzOffset;
  }

  return { hour, minute, minutesIntoDay };
}

// take a MinSlotEnum and convert to { hour, minute }
export function convertTimeMinSlotToHourMinutes(
  slot: ISchema.MinSlot | ISchema.ExcludedSlots | ISchema.TimeSlot,
  workingHoursTimeZone?: string,
  nowZonedMomentForTests?: ZonedMoment,
) {
  return convertTimeSlotToHourMinutes(slot, workingHoursTimeZone, true, nowZonedMomentForTests);
}

// take a MaxSlotEnum and convert to { hour, minute }
export function convertTimeMaxSlotToHourMinutes(
  slot: ISchema.MaxSlot | ISchema.ExcludedSlots,
  workingHoursTimeZone?: string,
  nowZonedMomentForTests?: ZonedMoment,
) {
  return convertTimeSlotToHourMinutes(slot, workingHoursTimeZone, false, nowZonedMomentForTests);
}

// take { hour, minute }, round minutes to 0 || 30, and adjust for maxSlot
// NOTE: this will NOT circle around if hour exceeds 23
export function adjustHourMinutesForTimeSlot(
  hourMinutes: { hour: number; minute: number },
  isMaxSlot = false,
) {
  const { hour, minute } = hourMinutes;
  // first, need to round the minutes to zero or 30
  const remainder = minute % 60;
  const roundedMinutes = remainder < 15 || remainder > 45 ? 0 : 30;

  // if minutes were rounded UP, then hour needs adjustment
  const roundedHour = remainder > 45 ? hour + 1 : hour;

  // next, adjust for maxSlot, if need be
  if (isMaxSlot) {
    // if minutes are 0, then subtracting 30 needs to subtract from hour
    const adjustHour = roundedMinutes === 0 && roundedHour > 0;
    const maxHour = adjustHour ? roundedHour - 1 : roundedHour;
    // basically subtracting 30 minutes
    const maxMinutes = adjustHour ? 30 : 0;

    return {
      hour: Math.min(maxHour, 23), // sanity check, hour can't be > 23
      minute: maxMinutes,
    };
  }

  return {
    hour: Math.min(roundedHour, 23), // sanity check, hour can't be > 23
    minute: roundedMinutes,
  };
}

// takes { hour, minute } and converts to string '00' for use in constructing time slots
function createStringHourMinutesForSlots(
  hourMinutes: { hour: number; minute: number },
  isMaxSlot = false,
) {
  const adjustedHourMinutes = adjustHourMinutesForTimeSlot(hourMinutes, isMaxSlot);
  const stringHour = String(adjustedHourMinutes.hour);
  const minuteHour = String(adjustedHourMinutes.minute);

  const paddedHour = stringHour.length < 2 ? `0${stringHour}` : stringHour;
  const paddedMinutes = minuteHour.length < 2 ? `0${minuteHour}` : minuteHour;
  return {
    paddedHour,
    paddedMinutes,
  };
}

// take { hour, minute } to MinSlot or MaxSlot
function convertHourMinutesToTimeSlot(
  hourMinutes: { hour: number; minute: number },
  isMaxSlot = false,
): ISchema.MinSlot | ISchema.MaxSlot {
  const { paddedHour, paddedMinutes } = createStringHourMinutesForSlots(hourMinutes, isMaxSlot);

  const timeSlot = `T_${paddedHour}_${paddedMinutes}`;

  return isMaxSlot ? (timeSlot as ISchema.MaxSlot) : (timeSlot as ISchema.MinSlot);
}

// take { hour, minute } to MaxSlot
export function convertHourMinutesToTimeMaxSlot(hourMinutes: {
  hour: number;
  minute: number;
}): ISchema.MaxSlot {
  return convertHourMinutesToTimeSlot(hourMinutes, true) as ISchema.MaxSlot;
}

// take { hour, minute } to MinSlot
export function convertHourMinutesToTimeMinSlot(hourMinutes: {
  hour: number;
  minute: number;
}): ISchema.MinSlot {
  return convertHourMinutesToTimeSlot(hourMinutes, false) as ISchema.MinSlot;
}

// get next slot
export function nextTimeSlot(timeSlot: string): string {
  return addSlots(timeSlot, 1);
}

// get previous slot
export function previousTimeSlot(timeSlot: string): string {
  return addSlots(timeSlot, -1);
}

// go forward or back a slot
export function addSlots(originalSlot: string | ISchema.TimeSlot, offset: number): string {
  const allSlots = timeSlots.all;
  const slotIndex = allSlots.findIndex((slot) => slot === originalSlot);

  return allSlots[(slotIndex + offset) % allSlots.length];
}

// get sequential slots in a range
export function getRangeOfSlots(start: genericSlot, end: genericSlot) {
  const startIndex = timeSlots.all.indexOf(start);
  const endIndex = timeSlots.all.indexOf(end);
  if (startIndex === -1 || endIndex === -1 || startIndex > endIndex) {
    return [];
  }
  return timeSlots.all.slice(startIndex, endIndex + 1);
}

// for a range of slots, get the minutes between them
export function minutesInRange(start: ISchema.MinSlot, end: ISchema.MaxSlot): number {
  const step = (end as any) < start ? -1 : 1; // FIXME: TEMP FIX FOR GQL2TS ISSUES

  let currentStart: string = start;
  let currentDuration = timeSlots.minutesPer; // start at 30, to account for offset between a min and max slot conversion

  while (currentStart !== end) {
    currentStart = addSlots(currentStart, step);
    currentDuration = currentDuration + step * timeSlots.minutesPer;
  }

  return currentDuration;
}
