import { blue, blueGrey, green, grey, red } from "@material-ui/core/colors";

import { alpha, darken, lighten } from "@material-ui/core/styles";
import { opacity } from "../util/color.util";

const red900 = red[900];

const grey400 = grey[400];
const grey500 = grey[500];

const blue600 = blue[600];
const blue800 = blue[800];

const blueGrey700 = blueGrey[700];
const blueGrey900 = blueGrey[900];

const green900 = green[900];

export const background = { page: "white" };

export const text = {
  default: blueGrey900,
  secondary: blueGrey700,
  labelSecondary: grey500,
  error: red900,
};

export const theme = {
  text: text.default,
  textSecondary: text.secondary,
  primary: blue600,
  accentColor: blue800,
  maker: blue600,
  fragmented: red900,
  meeting: grey400,
  ok: green900,
};

export const chromeEvent = {
  bg: "#049be5", // from charles
};

export const helpers = {
  opacity,
  alpha,
  darken,
  lighten,
};

export const palette = {
  darkGreyBlue: "rgba(12, 31, 47, 1)", // dark-grey-blue
  dark: "rgba(40, 54, 70, 1)", // dark
  darkGreyBlue2: "rgba(48, 63, 80, 1)", // dark-grey-blue
  slate: "rgba(86, 109, 127, 1)", // slate
  medium: "rgba(113, 117, 122, 1)", // medium
  blueGrey: "rgba(150, 172, 192, 1)", // blue-grey
  cloudyBlue: "rgba(191, 204, 216, 1)", // cloudy-blue
  paleGrey: "rgba(238, 240, 241, 1)", // pale-grey
  white: "rgba(255, 255, 255, 1)", // white

  blueCool: "rgba(83, 147, 198, 1)", // cool-blue
  blueDusk: "rgba(34, 85, 136, 1)", // dusk-blue
  blueSky: "rgba(68, 195, 215, 1)", // dark-sky-blue
  green: "rgba(90, 191, 150, 1)", // greeny-blue
  yellow: "rgba(238, 206, 63, 1)", // mac-and-cheese
  peach: "rgba(233, 146, 111, 1)", // dark-peach
  rose: "rgba(217, 74, 123, 1)", // darkish-pink
  pink: "rgba(213, 130, 182, 1)", // dull-pink
  purple: "rgba(173, 111, 175, 1)", // soft-purple
};

export const greys = {
  white: palette.white,
  standard: palette.darkGreyBlue2,
  slate: palette.slate,
  medium: palette.medium,
  paleGrey: palette.paleGrey,
  cloudyBlue: palette.cloudyBlue,
  blueGrey: palette.blueGrey,
  dark: palette.dark,
  darkGreyBlue: palette.darkGreyBlue,
  borderGrey: "rgba(224, 224, 224, 1)", // charles addition
  palePaleGrey: "rgba(247, 247, 247, 1)", // charles addition
  lightishGrey: "rgba(155, 155, 155, 1)", // charles addition
  shadow: "rgba(148, 155, 172, 1)",
  darkestGrey: "rgba(48, 63, 80, 1)", // charles addition
  // 0: palette.white,
  1: palette.paleGrey,
  2: palette.cloudyBlue,
  3: palette.blueGrey,
  4: palette.slate,
  // 5: palette.darkGreyBlue2,
  6: palette.dark,
  // 7: palette.darkGreyBlue,
};

export const colors = {
  blueCool: {
    darker: `rgba(68, 124, 169, 1)`,
    standard: palette.blueCool,
    lighter: `rgba(127, 184, 229, 1)`,
    lightest: `rgba(200, 230, 255, 1)`,
  },
  blueDusk: {
    darker: `rgba(32, 76, 119, 1)`,
    standard: palette.blueDusk,
    lighter: `rgba(111, 164, 217, 1)`,
    lightest: `rgba(193, 223, 254, 1)`,
  },
  blueSky: {
    darker: `rgba(35, 167, 188, 1)`,
    standard: palette.blueSky,
    lighter: `rgba(95, 209, 227, 1)`,
    lightest: `rgba(184, 242, 251, 1)`,
  },
  green: {
    darkest: `rgba(29, 133, 91, 1)`,
    darker: `rgba(64, 164, 124, 1)`,
    standard: palette.green,
    lighter: `rgba(136, 215, 183, 1)`,
    lightest: `rgba(211, 247, 217, 1)`,
  },
  yellow: {
    darker: `rgba(208, 176, 32, 1)`,
    standard: palette.yellow,
    lighter: `rgba(248, 221, 100, 1)`,
    lightest: `rgba(255, 244, 193, 1)`,
  },
  peach: {
    darker: `rgba(207, 122, 88, 1)`,
    standard: palette.peach,
    lighter: `rgba(251, 170, 138, 1)`,
    lightest: `rgba(251, 218, 205, 1)`,
  },
  rose: {
    darker: `rgba(188, 58, 102, 1)`,
    standard: palette.rose,
    lighter: `rgba(227, 103, 145, 1)`,
    lightest: `rgba(252, 214, 228, 1)`,
  },
  purple: {
    darker: `rgba(153, 86, 155, 1)`,
    standard: palette.purple,
    lighter: `rgba(194, 136, 196, 1)`,
    lightest: `rgba(241, 206, 242, 1)`,
  },
  red: {
    standard: `rgba(237, 91, 91, 1)`,
    bright: `rgba(227, 3, 3, 1)`, // charles addition
  },
  birch: {
    lightest: `rgba(243, 238, 232, 1)`,
    standard: `rgba(195, 176, 156, 1)`,
    darkest: `rgba(127, 113, 99, 1)`,
  },
  googleBlue: "#4285F4",
  googleGrey: "#606367",
  googleLightGrey: "#f0f0f0",
  googleLavendar: "#7986cb",
};

export const gradient = `linear-gradient(135deg, ${palette.blueCool} 0%, #3EC4CF 50%, ${palette.green} 100%)`;

export const accordion = {
  titleBackground: greys.paleGrey,
  titleBackgroundHover: greys.darkGreyBlue,
  titleText: greys.standard,
  titleIcon: greys.slate,
  bodyBackground: "white",
  border: greys.slate,
};

// info boxes etc
export const callouts = {
  warning: {
    color: colors.yellow.darker,
  },
};

export const headerColor = colors.blueDusk.darker;

export const time = {
  focus: colors.blueSky.darker,
  fragmented: colors.yellow.standard,
  busy: colors.peach.lighter,
  other: greys.blueGrey,
  unassigned: greys.paleGrey,
};

// FIXME: Centralize colors for gCal colors in a better place.
export const gCalColors = {
  iconStrokeAndFill: "#5f6368",
  iconHoverColor: "rgba(32,33,36,0.039)",
};
