import {
  CalendarAction,
  calendarReducer,
  CalendarState,
} from "#clockwise/web-commons/src/util/calendarReducer";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useReducer,
} from "react";
import invariant from "tiny-invariant";

// pass on for consumers to use
export type { CalendarState, WeekStartDays } from "#clockwise/web-commons/src/util/calendarReducer";

const CalendarDispatchContext = createContext<Dispatch<CalendarAction> | undefined>(undefined);
const CalendarStateContext = createContext<CalendarState | undefined>(undefined);

type ProviderProps = {
  children: ReactNode;
  initValue: CalendarState;
  onChange?: (action: CalendarAction) => void;
};

export const CalendarProvider = ({ initValue, children, onChange }: ProviderProps) => {
  const [calendarState, calendarDispatch] = useReducer(calendarReducer, initValue);

  const dispatcher = useCallback(
    (action: CalendarAction) => {
      onChange?.(action);
      calendarDispatch(action);
    },
    [onChange],
  );

  return (
    <CalendarDispatchContext.Provider value={dispatcher}>
      <CalendarStateContext.Provider value={calendarState}>
        {children}
      </CalendarStateContext.Provider>
    </CalendarDispatchContext.Provider>
  );
};

export const useReadCalendar = () => {
  const context = useContext(CalendarStateContext);
  invariant(context, "useReadCalendar must be within CalendarProvider");
  return context;
};

export const useUpdateCalendar = () => {
  const context = useContext(CalendarDispatchContext);
  invariant(context, "useUpdateCalendar must be within CalendarProvider");
  return context;
};
