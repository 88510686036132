/**
 * @generated SignedSource<<b91076a9516ab8ee9bb16ff8c3b05a39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateFlagsForUserMutationInput = {
  clientMutationId?: string | null;
  enable: boolean;
  flagNames: ReadonlyArray<string | null>;
  flagNamespace: string;
};
export type UpdateFlagsForUserMutation$variables = {
  input: UpdateFlagsForUserMutationInput;
};
export type UpdateFlagsForUserMutation$data = {
  readonly updateFlagsForUser: {
    readonly flags: {
      readonly id: string;
      readonly flags: ReadonlyArray<string | null> | null;
    } | null;
  } | null;
};
export type UpdateFlagsForUserMutation = {
  variables: UpdateFlagsForUserMutation$variables;
  response: UpdateFlagsForUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateFlagsForUserMutationPayload",
    "kind": "LinkedField",
    "name": "updateFlagsForUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Flags",
        "kind": "LinkedField",
        "name": "flags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "flags",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateFlagsForUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateFlagsForUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2a8eb73104c85ef40e3d6442accc94b4",
    "id": null,
    "metadata": {},
    "name": "UpdateFlagsForUserMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateFlagsForUserMutation(\n  $input: UpdateFlagsForUserMutationInput!\n) {\n  updateFlagsForUser(input: $input) {\n    flags {\n      id\n      flags\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d2cf8d547c0d569beb0d797e7355b3c7";

export default node;
