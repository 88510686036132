import { getCurrentEnvironment } from "#webapp/src/state/relay-environment";
import * as React from "react";
import { GraphQLTaggedNode, QueryRenderer, ReadyState } from "react-relay";

import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import StaticContainer from "react-static-container";

//////////////////
// ROUTE CONFIG
//////////////////

export const noopComponent = StaticContainer;

////////////////////////////////////////////////////
// TEMP ROUTE BANDAID FOR DEPRECATING FOUND RELAY //
////////////////////////////////////////////////////

/**
 * variation of above `withRouteQuery` (described as "TEMP ROUTE BANDAID")
 * that eliminates prepareVariables and Found's RouterState
 * instead returns params and navigate from react-router-dom
 *
 * @deprecated use class components
 * */
export const withRouterQuery = <P extends React.PropsWithChildren>(
  ComponentClass: any,
  query: GraphQLTaggedNode | null | undefined,
  LoadingComponent?: React.FunctionComponent,
) =>
  function QueryRendererContainer(props: P) {
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const environment = getCurrentEnvironment();
    return (
      <QueryRenderer
        environment={environment}
        query={query}
        variables={{}}
        render={(readyState: ReadyState) => {
          // check for error or show a loader for first load
          if (!Object.keys(readyState.props ?? {}).length) {
            return !!LoadingComponent ? <LoadingComponent /> : null;
          }
          return (
            <ComponentClass
              {...props}
              {...readyState.props}
              navigate={navigate}
              params={params}
              searchParams={searchParams}
            />
          );
        }}
      />
    );
  };

export const withRouterForClass = <P extends React.PropsWithChildren>(ComponentClass: any) =>
  function ComponentWithRouterProp(props: P) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <ComponentClass {...props} location={location} navigate={navigate} params={params} />;
  };
