export const fontFamily = "'PP Mori', serif, ui-serif";
export const fontFamilySans = "Inter, sans-serif, ui-sans-serif, system-ui";
export const fontFamilyGooglePrimary = "'Google Sans',Roboto,Arial,sans-serif";

// These are the two fontFamilies use in Figma mocks. Aliasing for ease of use.
export const fontFamilyBrand = fontFamily;
export const fontFamilyBody = fontFamilySans;

export const size = {
  xs: 11,
  sm: 12,
  md: 14,
  std: 16,
  lg: 18,
  xl: 24,
  xxl: 32,
};

export const weight: { std: 400; bold: 700 } = {
  std: 400,
  bold: 700,
};

export const lineHeight: { std: 1.4; lg: 1.8 } = {
  std: 1.4,
  lg: 1.8,
};
