import { constructionBlockSvg } from "#webapp/src/assets/img/illustrations";
import { supportEmail } from "#webapp/src/constants/site.constant";
import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { ChromeAppWrapperView } from "#webapp/src/util/chrome-extension.util";
import { windowLocation } from "#webapp/src/util/location.util";
import { useMutation } from "@apollo/client";
import { Button, Link } from "@clockwise/design-system";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import {
  MaybeOnboardOrgDocument,
  MaybeOnboardOrgMutation,
} from "./__generated__/MaybeOnboardOrg.generated";
export interface IContainer {
  orgId: string;
  view: ChromeAppWrapperView;
}

const ButtonRow = ({ children }: { children: React.ReactNode }) => {
  return <div className="cw-flex cw-items-center cw-justify-center cw-py-8">{children}</div>;
};
export const FastCrawlErrorCard = ({
  orgId,
  view,
}: {
  orgId: string;
  view: ChromeAppWrapperView;
}) => {
  const [maybeOnboardOrg] = useMutation(MaybeOnboardOrgDocument, {
    variables: { orgId: orgId },
    onCompleted: (payload) => onMaybeOnboardOrgSuccess(payload),
    onError: () => onMaybeOnboardOrgFailure(),
  });

  useEffect(() => {
    switch (view) {
      case ChromeAppWrapperView.GoogleContactSharingDisabled:
        track(TrackingEvents.ONBOARDING.ONBOARDING_ERROR_GOOGLE_CONTACT_SHARING_DISABLED);
        break;
      case ChromeAppWrapperView.GoogleApiAccessDisabled:
        track(TrackingEvents.ONBOARDING.ONBOARDING_ERROR_GOOGLE_API_ACCESS_DISABLED);
        break;
      case ChromeAppWrapperView.ShouldCrawl:
        track(TrackingEvents.ONBOARDING.ONBOARDING_ERROR_SHOULD_CRAWL);
        break;
      case ChromeAppWrapperView.NoCrawl:
        track(TrackingEvents.ONBOARDING.ONBOARDING_ERROR_NO_CRAWL);
        break;
      default:
        track(TrackingEvents.ONBOARDING.ONBOARDING_ERROR_GENERIC);
        break;
    }
  }, [view]);

  const onClickTryAgain = () => {
    maybeOnboardOrg();
  };

  const onMaybeOnboardOrgSuccess = (payload: MaybeOnboardOrgMutation) => {
    const crawlTest = payload.maybeOnboardOrg?.crawlTest;
    if (!crawlTest) {
      return;
    }
    switch (crawlTest) {
      case "Skipped":
      case "StartedCrawl":
        toast.success("Success! Onboarding will now begin!");
        setTimeout(() => {
          windowLocation.reload("FatalCrawlErrorCardStartedCrawl");
        }, 1000);
        break;
      case "GoogleContactSharingDisabled":
        toast.error("Sorry, we still detected that contact sharing needs to be enabled.");
        setTimeout(() => {
          windowLocation.reload("FatalCrawlErrorCardSharingDisabled");
        }, 1000);
        break;
      case "GoogleApiAccessDisabled":
        toast.error("Sorry, we still detected that G Suite API access needs to be enabled.");
        setTimeout(() => {
          windowLocation.reload("FatalCrawlErrorCardAccessDisabled");
        }, 1000);
        break;
      case "ShouldCrawl":
      case "NoCrawl":
      default:
        toast.error("Sorry, something went wrong.");
        break;
    }
  };

  const onMaybeOnboardOrgFailure = () => {
    toast.error("There was a problem checking your settings. Please try again later.");
  };

  const renderIllustration = () => {
    switch (view) {
      case ChromeAppWrapperView.ShouldCrawl:
        return null;
      default:
        return <img className="cw-w-[125px]" src={constructionBlockSvg} />;
    }
  };

  const renderTitle = () => {
    let title = "Something went wrong";
    switch (view) {
      case ChromeAppWrapperView.GoogleContactSharingDisabled:
        title = "Clockwise needs contact sharing to be enabled";
        break;
      case ChromeAppWrapperView.GoogleApiAccessDisabled:
        title = "Clockwise needs G Suite API access to get started";
        break;
      case ChromeAppWrapperView.NoCrawl:
        title = "Something went wrong";
        break;
      case ChromeAppWrapperView.ShouldCrawl:
        title = "Clockwise Setup";
        break;
      case ChromeAppWrapperView.NoExternalOrganization:
        title = "Clockwise can't access your organization's data";
        break;
      default:
        break;
    }

    return <div className="cw-heading-3xl cw-text-center cw-py-4">{title}</div>;
  };

  const openGoogleAPIDocs = () => {
    const href = "https://support.google.com/a/answer/60218";
    window.open(href);
  };
  const renderReason = () => {
    switch (view) {
      case ChromeAppWrapperView.GoogleContactSharingDisabled:
        return (
          <div className="cw-text-center">
            <div className="cw-body-lg cw-text-center">
              Please ask your Google G Suite admin to enable contact sharing in the Admin console.
            </div>
            <ButtonRow>
              <div className="cw-px-2">
                <Button size="large" variant="text" onClick={onClickTryAgain}>
                  Try Again
                </Button>
              </div>
              <div className="cw-px-2">
                <Button size="large" sentiment="positive" onClick={openGoogleAPIDocs}>
                  Enable Setting
                </Button>
              </div>
            </ButtonRow>
          </div>
        );
      case ChromeAppWrapperView.GoogleApiAccessDisabled:
        return (
          <div className="cw-text-center">
            <div className="cw-body-lg cw-text-center">
              Please ask your Google G Suite admin to enable API access in the Admin console.
            </div>
            <ButtonRow>
              <div className="cw-px-2">
                <Button size="large" sentiment="neutral" variant="text" onClick={onClickTryAgain}>
                  Try Again
                </Button>
              </div>
              <div className="cw-px-2">
                <Button size="large" sentiment="positive" onClick={openGoogleAPIDocs}>
                  Enable Setting
                </Button>
              </div>
            </ButtonRow>
          </div>
        );
      case ChromeAppWrapperView.ShouldCrawl:
        return (
          <div className="cw-text-center">
            <div className="cw-body-lg cw-text-center">
              Your Clockwise account looks like it's ready to go!
            </div>
            <ButtonRow>
              <Button size="large" sentiment="positive" onClick={onClickTryAgain} color="primary">
                Begin Setup
              </Button>
            </ButtonRow>
          </div>
        );
      case ChromeAppWrapperView.NoCrawl:
        return (
          <div className="cw-text-center">
            <div className="cw-body-lg cw-text-center">
              Sorry, something went wrong onboarding your Clockwise account. Please check back later
              and always feel free to <Link href={`mailto:${supportEmail}`}>message us</Link>.
            </div>
            <ButtonRow>
              <Button size="large" sentiment="positive" onClick={onClickTryAgain}>
                Try Again
              </Button>
            </ButtonRow>
          </div>
        );
      case ChromeAppWrapperView.NoExternalOrganization:
        return (
          <div className="cw-body-lg cw-text-center">
            Google did not return the data we need to support your organization. Clockwise is unable
            to function without access to this data and unfortunately we don’t have recommended
            steps to resolve. You can try signing in with a different GSuite account. Always feel
            free to <Link href={`mailto:${supportEmail}`}>message us</Link> if you believe this
            information is incorrect.
          </div>
        );
      default:
        return (
          <div className="cw-body-lg cw-text-center">
            We're working on getting your Clockwise account back up and running. Please check back
            later and always feel free to <Link href={`mailto:${supportEmail}`}>message us</Link>.
          </div>
        );
    }
  };

  return (
    <div className="cw-flex cw-flex-col cw-items-center cw-justify-center cw-mt-4 cw-w-full cw-p-2 md:cw-w-[650px]">
      {renderIllustration()}
      {renderTitle()}
      {renderReason()}
    </div>
  );
};
