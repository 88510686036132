/**
 * @generated SignedSource<<06d5d4063fc0a6891da4caf53414b8b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventTimeType = "Date" | "DateTime" | "%future added value";
export type UnsyncFocusTimeEventMutationInput = {
  calendarId?: string | null;
  clientMutationId?: string | null;
  email?: string | null;
  externalEventId?: string | null;
  isPaused?: boolean | null;
  orgRelayId: string;
  timeRange: TimeRangeInput;
};
export type TimeRangeInput = {
  endTime?: EventTimeInput | null;
  id: string;
  startTime?: EventTimeInput | null;
};
export type EventTimeInput = {
  dateOrDateTime: string;
  millis: number;
  offsetMinutes?: number | null;
  timeZone?: string | null;
  type: EventTimeType;
};
export type UnsyncFocusTimeEventMutation$variables = {
  input: UnsyncFocusTimeEventMutationInput;
};
export type UnsyncFocusTimeEventMutation$data = {
  readonly unsyncFocusTimeEvent: {
    readonly clientMutationId: string | null;
  } | null;
};
export type UnsyncFocusTimeEventMutation = {
  variables: UnsyncFocusTimeEventMutation$variables;
  response: UnsyncFocusTimeEventMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UnsyncFocusTimeEventMutationPayload",
    "kind": "LinkedField",
    "name": "unsyncFocusTimeEvent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnsyncFocusTimeEventMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnsyncFocusTimeEventMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "67dbf70999c9a9dc8a1c0a0d9be6084c",
    "id": null,
    "metadata": {},
    "name": "UnsyncFocusTimeEventMutation",
    "operationKind": "mutation",
    "text": "mutation UnsyncFocusTimeEventMutation(\n  $input: UnsyncFocusTimeEventMutationInput!\n) {\n  unsyncFocusTimeEvent(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "76bd12001c646e188b96f1507e2ddc2d";

export default node;
