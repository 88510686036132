import { DividerProps, Divider as MDivider } from "@mantine/core";
import React from "react";

type Props = Pick<
  DividerProps,
  "label" | "labelPosition" | "size" | "orientation" | "className"
> & {
  spacing?: number | "xs" | "sm" | "md" | "lg" | "xl";
  inset?: number | true | "xs" | "sm" | "md" | "lg" | "xl";
};

export const Divider = ({
  size,
  orientation = "horizontal",
  spacing = "lg",
  label,
  labelPosition,
  inset = 0,
  className,
}: Props) => {
  return (
    <MDivider
      label={orientation === "horizontal" && label}
      labelPosition={labelPosition}
      orientation={orientation}
      size={size}
      my={orientation === "horizontal" ? spacing : inset === true ? "md" : inset}
      mx={orientation === "vertical" ? spacing : inset === true ? "md" : inset}
      className={className}
    />
  );
};
