import {
  IOrg,
  ISearchPerson,
  ISuggestedOrgInvite,
  ISuggestedOrgInviteConnection,
} from "#webapp/src/__schema__";
import { getUserRoleForBillingGroup } from "#webapp/src/util/billing-group.util";
import { BillingGroup } from "@clockwise/schema";
import { compact, keyBy } from "lodash";

export const MAX_MEMBERS_DISPLAYED = 20;

export type AugmentedPersonMap = {
  [k: string]: NewMemberPerson;
};

export type BillingGroupByCalendarId = {
  [key: string]: {
    allBillingGroupsOfCalendarId: BillingGroup[] | null;
    primaryBillingGroup: { id: string };
    calendarId: string;
  };
};

export enum SearchPersonStatusResponse {
  NonUser = "NonUser",
  Invited = "Invited",
  User = "User",
  Deactivated = "Deactivated",
}

export enum SortType {
  FullName = "FullName",
  Status = "Status",
  Subscription = "Subscription",
  SignUpDate = "SignUpDate",
  Role = "Role",
}

export enum SortDirection {
  Ascending = "Ascending",
  Descending = "Descending",
}

export enum BillingGroupRoleEnum {
  Admin = "Admin",
  Member = "Member",
  None = "None",
}

export type NewMemberPerson = {
  targetCalendarId: string;
  givenName: string;
  familyName: string;
  externalImageUrl: string;
  isSuggested: boolean;
  recentlySent: boolean;
  status: SearchPersonStatusResponse;
  billingGroups: BillingGroup[];
  primaryBillingGroup: BillingGroup | null;
  signUpMillis: number;
  userId?: string;
  personId: string;
  role: BillingGroupRoleEnum;
  isYou: boolean;
  recentlyRequested?: boolean;
};

export const getVisibleBillingGroupList = (billingGroupsByCalendarId: BillingGroupByCalendarId) => {
  const billingGroupsGroupedUp = Object.keys(billingGroupsByCalendarId).flatMap((calendarIDKey) => {
    return billingGroupsByCalendarId[calendarIDKey].allBillingGroupsOfCalendarId;
  });
  const hashOfFinalBillingGroups = keyBy(billingGroupsGroupedUp, "id");
  return compact(Object.values(hashOfFinalBillingGroups));
};

export const getSuggestedInvitesMap = (suggestedInvites: ISuggestedOrgInviteConnection) => {
  const suggestedInviteMap: { [calId: string]: ISuggestedOrgInvite } = {};
  if (!suggestedInvites || !suggestedInvites.edges) {
    return suggestedInviteMap;
  }

  suggestedInvites.edges.forEach((edge) => {
    if (edge?.node) {
      suggestedInviteMap[edge.node.targetCalendarId] = edge.node;
    }
  });

  return suggestedInviteMap;
};

export const getBillingGroupName = (billingGroups: BillingGroup[], billingGroupId: string) => {
  return billingGroups.find((bg) => bg.id === billingGroupId)?.name || "";
};

export const getCalendarIdsToQuery = (allUsersIds: string[], currentUsersId?: string) => {
  if (currentUsersId) {
    return [...allUsersIds, currentUsersId];
  } else {
    return allUsersIds;
  }
};

export const getCurrentPersonsList = (
  augmentedPersons: AugmentedPersonMap,
  isInitialUnsortedPersonList: boolean,
) => {
  const augmentedPersonsKeys = Object.keys(augmentedPersons);
  const alphabetizedPersonList = augmentedPersonsKeys.map((k) => augmentedPersons[k]);
  const currentUser = alphabetizedPersonList.filter((person) => person.isYou);
  if (isInitialUnsortedPersonList && currentUser) {
    const personsExcludingYou = alphabetizedPersonList.filter((person) => !person.isYou);
    return currentUser.concat(...personsExcludingYou);
  } else {
    if (!augmentedPersonsKeys) {
      return [];
    }
    return alphabetizedPersonList;
  }
};

export const getAugmentedPersons = (
  org: IOrg,
  billingGroupsByCalendarId: BillingGroupByCalendarId,
  recentlyInvitedPersons: AugmentedPersonMap,
  recentlyRequestedInvitePersons: AugmentedPersonMap,
  currentSearchParams: string,
) => {
  const suggestedInvitesMap = getSuggestedInvitesMap(org.suggestedInvites);

  // sanity check
  if (!org?.suggestedInvites?.edges) {
    return {} as AugmentedPersonMap;
  }

  let persons: ISearchPerson[] = [];
  const personlist = org.orgPersonListPaginatedErrorable;
  if (personlist.__typename !== "OrgPersonListPaginated") {
    return {} as AugmentedPersonMap;
  }
  persons = personlist.searchPersons ?? [];

  const result = persons.reduce((accumulator: AugmentedPersonMap, nextPerson) => {
    const { primaryCalendarId, profile } = nextPerson.person;

    // recentlySent and recentlyRequested are temp states that block the invite button once a user has invited them
    const recentlySent = !!recentlyInvitedPersons[primaryCalendarId];
    const recentlyRequested = !!recentlyRequestedInvitePersons[primaryCalendarId];

    const billingGroupsOfPerson =
      billingGroupsByCalendarId[primaryCalendarId]?.allBillingGroupsOfCalendarId;
    const primaryBillingGroupIdOfPerson =
      billingGroupsByCalendarId[primaryCalendarId]?.primaryBillingGroup?.id || null;
    const primaryBillingGroup =
      billingGroupsOfPerson &&
      billingGroupsOfPerson.find((bg) => bg.id === primaryBillingGroupIdOfPerson);

    accumulator[primaryCalendarId] = {
      status: nextPerson.status,
      targetCalendarId: primaryCalendarId,
      givenName: profile ? profile.givenName : primaryCalendarId, // if no user profile, show primaryCalendarId
      familyName: profile ? profile.familyName : "",
      externalImageUrl: profile ? profile.externalImageUrl : "",
      isSuggested: false,
      recentlySent,
      billingGroups: billingGroupsOfPerson || [],
      primaryBillingGroup: primaryBillingGroup || null,
      signUpMillis: nextPerson.person.signUpMillis,
      userId: nextPerson.person.userId,
      personId: nextPerson.person.personId,
      role: primaryBillingGroup
        ? getUserRoleForBillingGroup(primaryBillingGroup, nextPerson.person.userId)
        : BillingGroupRoleEnum.None,
      isYou: nextPerson.person.isYou,
      recentlyRequested,
    };

    // handle suggested invites
    const suggestedInvite = suggestedInvitesMap[primaryCalendarId];
    if (suggestedInvite) {
      accumulator[primaryCalendarId].isSuggested = true;
    }

    return accumulator;
  }, {});

  // include any recently sent persons
  if (!currentSearchParams) {
    Object.keys(recentlyInvitedPersons).forEach((cId: string) => {
      result[cId] = recentlyInvitedPersons[cId];
    });
  }

  return result;
};

export const formatListofPersonsAsEmailString = (persons: NewMemberPerson[]) => {
  const personEmailList = persons.map((person) => person.targetCalendarId);
  return personEmailList.join(", ");
};

export const getPersonFromCalId = (persons: NewMemberPerson[], calId: string) => {
  return persons.find((p) => p.targetCalendarId === calId);
};
