import { Button } from "@clockwise/design-system";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { normalizeDuration } from "../../util/duration.util";
import { useWindowFocus } from "../../util/react.util";
import { DeterioratingClock } from "../svgs/DeterioratingClock";

export const MAX_TIME_TO_RETRY = 6 * 60 * 60 * 1000; // 6 hours

export interface IProps {
  onRetry?: () => Promise<unknown>;
  additionalText?: JSX.Element | string | null;
  initialNetworkFailureScale?: number;
}

export const NetworkOffline = ({
  onRetry,
  additionalText,
  initialNetworkFailureScale = 0,
}: IProps) => {
  const interval = React.useRef<ReturnType<typeof setInterval> | undefined>(undefined);

  const [timeToRetry, setTimeToRetry] = useState<number>(
    Math.min(
      MAX_TIME_TO_RETRY,
      Math.round(15 + Math.random() * 45) * Math.pow(2, initialNetworkFailureScale),
    ),
  ); // initial TTR will be at least 15s, + 1-45s via RNG
  const [lastTimeToRetry, setLastTimeToRetry] = useState<number>(timeToRetry);
  const [isRetrying, setIsRetrying] = useState<boolean>(false);

  const retry = useCallback(() => {
    if (isRetrying) {
      return;
    }

    if (onRetry) {
      setIsRetrying(!!onRetry);

      const afterRetry = () => setTimeout(() => setIsRetrying(false), 2000); // 2s grace till we allow another retry
      onRetry().then(afterRetry).catch(afterRetry);
    }
  }, [isRetrying, onRetry]);

  const getNewTimeToRetry = useCallback(() => {
    return Math.min(lastTimeToRetry * 2, MAX_TIME_TO_RETRY / 1000);
  }, [lastTimeToRetry]);

  // Upon focus window, retry immediately. Increment timeToRetry if we're going to retry again in < 10s
  const isWindowFocused = useWindowFocus();
  useEffect(() => {
    if (isWindowFocused) {
      retry();
      if (timeToRetry < 10) {
        const newTimeToRetry = getNewTimeToRetry();
        setLastTimeToRetry(newTimeToRetry);
        setTimeToRetry(newTimeToRetry);
      }
    }
  }, [isWindowFocused]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onRetry) {
      interval.current = setInterval(() => {
        setTimeToRetry((time) => {
          // Must use 'time' instead of 'timeToRetry' here, otherwise we'll get stale values.
          if (time === 1) {
            retry();
            const newTimeToRetry = getNewTimeToRetry();
            setLastTimeToRetry(newTimeToRetry);
            return newTimeToRetry;
          }
          return time - 1;
        });
      }, 1000);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let retryText = "please check your network connection.";
  if (onRetry) {
    retryText = `We'll retry in ${normalizeDuration(0, timeToRetry).toHuman().replace(",", "")}.`;
  }

  let statusText: string;

  if (isRetrying) {
    statusText = "Retrying...";
  } else {
    statusText = `Oops, we're having trouble connecting to Clockwise. ${retryText}`;
  }

  return (
    <div className="cw-flex cw-items-center cw-flex-col cw-text-center">
      <DeterioratingClock style={{ marginTop: 43 }} />
      <h6 className="cw-heading-xl cw-mt-4">Connection Error</h6>
      <div className="cw-font-body cw-text-16 cw-p-5">{statusText}</div>
      {additionalText && <div className="cw-font-body cw-text-16 cw-p-5">{additionalText}</div>}
      {onRetry ? (
        <Button disabled={isRetrying} onClick={retry}>
          Retry now
        </Button>
      ) : null}
    </div>
  );
};
