import React from "react";

type TierCostProps = {
  cost: number;
  showDecimal?: boolean;
};

const TierCost = ({ cost, showDecimal = true }: TierCostProps) => {
  const whole = Math.floor(cost);
  const fractional = cost - whole;

  const wholeDisplay = whole.toString();
  const fractionalDisplay = fractional.toFixed(2).slice(2);

  return (
    <div className="cw-flex cw-items-center cw-gap-2 cw-my-2">
      <span className="cw-heading-lg">$</span>
      <span>
        <span className="cw-heading-3xl cw-text-center">{wholeDisplay}</span>
        {showDecimal && <span className="cw-heading-3xl cw-text-center">.{fractionalDisplay}</span>}
      </span>
      <span className="cw-heading-lg">USD</span>
    </div>
  );
};

export default TierCost;
