import { noop } from "lodash";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import invariant from "tiny-invariant";
import { useAIChatCollapseContext } from "./AIChatCollapseContext";

export enum EventType {
  Event = "Event",
  WorkingLocation = "WorkingLocation",
  PersonalCalendarSync = "PersonalCalendarSync",
  Proposal = "Proposal",
}

export type EventId = {
  externalEventId: string;
  calendarId: string;
  type: EventType;
};

const ReadContext = createContext<EventId | null>(null);
const WriteContext = createContext<(eventId: EventId | null, keepChatOpen?: boolean) => void>(noop);

export const ActiveEventProvider = ({
  children,
  initValue = null,
}: {
  children: ReactNode;
  initValue?: EventId | null;
}) => {
  const [activeEvent, setActiveEvent] = useState<EventId | null>(initValue);
  const { chatIsCollapsed, setChatIsCollapsed } = useAIChatCollapseContext();
  const [
    chatWasCollapsedBeforeEventOpened,
    setChatWasCollapsedBeforeEventOpened,
  ] = useState<boolean>(chatIsCollapsed);

  useEffect(() => {
    if (!activeEvent) {
      setChatWasCollapsedBeforeEventOpened(chatIsCollapsed);
    }
  }, [activeEvent, chatIsCollapsed]);

  const handleSetActiveEvent = (eventId: EventId | null, keepChatOpen?: boolean) => {
    setActiveEvent(eventId);
    if (eventId) {
      // Open chat sidebar when event is opened
      setChatIsCollapsed(false);
    } else if (!keepChatOpen) {
      // When event is closed, restore chat to previous state
      setChatIsCollapsed(chatWasCollapsedBeforeEventOpened);
    }
  };

  return (
    <WriteContext.Provider value={handleSetActiveEvent}>
      <ReadContext.Provider value={activeEvent}>{children}</ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const useReadActiveEvent = () => useContext(ReadContext);

export const useUpdateActiveEvent = () => {
  const context = useContext(WriteContext);
  invariant(context, "useUpdateActiveEvent must be within ActiveEventProvider");
  return context;
};
