import { graphql } from "react-relay";

export const sudoTriggerClientUpdateMutation = graphql`
  mutation SudoTriggerClientUpdateMutation($input: SudoTriggerClientUpdateMutationInput!) {
    sudoTriggerClientUpdate(input: $input) {
      clientMutationId
      sudo {
        id
        chromeAppUpdateTimes {
          idleTime
          forceTime
        }
      }
    }
  }
`;
