import * as ISchema from "#webapp/src/__schema__";
import { isTeamInPaidSubscription } from "#webapp/src/util/pricing.util";
import { Button, TextField } from "@clockwise/design-system";
import { Add, Search } from "@clockwise/design-system/icons";
import classNames from "classnames";
import React, { ChangeEvent } from "react";
import { JoinMode, TeamData, TeamsFormMode } from "../TeamJoinCreateTypes";
import { TeamContent } from "../team-content/TeamContent";
import { getCopyForBody, getCopyForHeader } from "./TeamJoinForm.util";

type TeamJoinProps = {
  joinMode: JoinMode;
  loading: boolean;
  query: string;
  teams: TeamData[];
  mode: TeamsFormMode;
  hasSuggestedTeams: boolean;
  personMap: { [keyof: string]: ISchema.IOrgPerson };
  org: ISchema.IOrg;
  onChangeTeam: (newTeamId: string) => void;
  onCreateTeamBtnClick: () => void;
  onChangeSearchQuery: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedTeamId: string;
};

export const TeamJoinForm = ({
  joinMode,
  loading,
  query,
  teams,
  mode,
  personMap,
  org,
  onChangeTeam,
  onCreateTeamBtnClick,
  onChangeSearchQuery,
  selectedTeamId,
}: TeamJoinProps) => {
  const showSearch = joinMode === "normal" && (query || teams.length >= 5 || (!query && loading));

  const showCreateTeamButton = joinMode === "normal" && mode === "onboarding";
  const isCheckout = mode === "checkout";
  const isJoinNormal = joinMode === "normal";

  const getControlWrapperClass = (teamForClass: TeamData) => {
    return classNames(
      "cw-border cw-p-0 cw-border-solid cw-border-default cw-rounded-md cw-mb-4 cw-cursor-pointer cw-select-none hover:cw-bg-default-hover",
      {
        "cw-text-positive cw-border-brand-pressed": teamForClass.id === selectedTeamId,
        "cw-opacity-75 cw-cursor-not-allowed": teamForClass.disabled,
      },
    );
  };

  return (
    <>
      <div className="cw-heading-2xl cw-mb-5">{getCopyForHeader(isCheckout, isJoinNormal)}</div>
      <div className="cw-body-lg cw-mb-6">
        {getCopyForBody(isCheckout, isJoinNormal, teams.length)}
      </div>

      {showSearch && (
        <div className="cw-mb-[14px]">
          <TextField
            autoFocus
            fullWidth
            startIcon={Search}
            loading={loading}
            placeholder="Search for a team..."
            value={query}
            onChange={onChangeSearchQuery}
          />
        </div>
      )}
      {showCreateTeamButton ? (
        <div className="cw-mb-3">
          <Button fullWidth onClick={onCreateTeamBtnClick} sentiment="neutral" variant="outlined">
            <Add className="cw-mr-2 cw-w-[36px]" />
            <div>Create a new team</div>
          </Button>
        </div>
      ) : null}
      {teams.length ? (
        teams.map((team, index) => {
          return (
            <div key={`control-${index}`} className={getControlWrapperClass(team)}>
              <div
                className="cw-w-full cw-flex cw-flex-row cw-items-center"
                onClick={() => {
                  onChangeTeam(team.id);
                }}
              >
                <TeamContent
                  team={team}
                  personMap={personMap}
                  isSelectPrimary={joinMode === "select-primary"}
                  isTeamPaid={isTeamInPaidSubscription(org, team.team)}
                  isInCheckout={isCheckout}
                  selectedTeamId={selectedTeamId}
                />
              </div>
            </div>
          );
        })
      ) : (
        <div className="cw-p-5 cw-text-center">No results found</div>
      )}
    </>
  );
};
