import { getFullName } from "#clockwise/web-commons/src/util/profile.util";
import { Tooltip } from "@clockwise/design-system";
import { find } from "lodash";
import pluralize from "pluralize";
import React from "react";
import { AttendeeAvatar } from "../AttendeeAvatar";
import { AttendeeAvatarStack } from "../AttendeeAvatarStack";
import { LinkMember } from "./types";

const findOwner = (members: LinkMember[]) => find(members, { owner: true });

type Props = {
  linkMembers: LinkMember[];
  attendees?: string[];
  isOwner?: boolean;
  isReschedule?: boolean;
  isRoundRobin: boolean;
};

const MeetingTitleSingleMember = ({
  linkMembers,
  isOwner = false,
  isReschedule = false,
}: Props) => {
  const firstLinkMember = findOwner(linkMembers) || linkMembers[0];
  const owner = findOwner(linkMembers);
  const isCurrentUserOwnerOfLink = isOwner && owner?.id;

  const getScheduleWithMessage = () => {
    if (isReschedule) {
      return "Reschedule with";
    }
    return isCurrentUserOwnerOfLink ? "Book with" : "You and";
  };

  const ownerFullName = getFullName(firstLinkMember?.person);

  return (
    <div className="cw-flex cw-items-center cw-py-1 cw-overflow-hidden cw-break-words cw-gap-4">
      {firstLinkMember && (
        <>
          <AttendeeAvatar
            profile={firstLinkMember.person}
            size="xlarge"
            cw-id="link-avatar"
            aria-label="link owner avatar"
          />
        </>
      )}
      <div className="cw-flex cw-flex-col cw-gap-[2px] cw-body-base cw-text-muted">
        <div>
          <span>{getScheduleWithMessage()} </span>
          <span className="cw-font-bold" cw-id="link-owner-name" aria-label="link owner name">
            {ownerFullName}
          </span>
        </div>
        {isCurrentUserOwnerOfLink && (
          <span className="cw-text-subtle cw-underline cw-decoration-dotted cw-leading-tight cw-cursor-default">
            <Tooltip
              title={
                <span>
                  You are logged in as {owner.id}. To view exactly what recipients will see, try
                  opening this link in an incognito window.
                </span>
              }
              arrow
              placement="bottom"
            >
              <span>That's you!</span>
            </Tooltip>
          </span>
        )}
      </div>
    </div>
  );
};

// TODO when rescheduling, use linkMemberCalendars as linkMembers may have changed after scheduling.
// linkMembersCalendars will have the calendarIds (emails) of the members that were invited to the
// original meeting. Probably a very small # users will ever see this, and they will probably never notice,
// but it is a bug.
// https://linear.app/getclockwise/issue/M365-653/use-correct-list-of-link-members-linkmembercalendars-on-link-page
const MeetingTitleMultiMember = ({ linkMembers, isRoundRobin, attendees }: Props) => {
  const ownerOrFirstLinkMember = findOwner(linkMembers)?.person || linkMembers[0].person;
  const isHostStillInMembersList = !!linkMembers.filter((member) =>
    attendees?.includes(member.person.id),
  ).length;

  if (!isHostStillInMembersList && isRoundRobin) {
    // Edge case, we dont have the person information for the host, because they have since been removed from the link.
    // In this case, we will just hide this component, previously it was grabbing a random user which is not accurate
    return null;
  }

  return (
    <div className="cw-flex cw-flex-wrap cw-gap-3 cw-items-center cw-mb-1">
      <AttendeeAvatarStack maxShown={8} overlap size="xlarge">
        {linkMembers.map((lm) => (
          <AttendeeAvatar profile={lm.person} isInOverlap size="xlarge" key={lm.id} />
        ))}
      </AttendeeAvatarStack>
      <span
        className="cw-body-lg cw-font-bold cw-text-muted"
        cw-id="link-owner-name"
        aria-label="link owner name"
      >
        {getFullName(ownerOrFirstLinkMember)} {isRoundRobin ? "or" : "and"}{" "}
        {pluralize("other", linkMembers.length - 1, true)}
      </span>
    </div>
  );
};
/**
 * Dynamic title for a meeting being booked with a Scheduling Link, including an avatar and a
 * call-out to notify the user if they are looking at their own Link.
 */
export function MeetingTitle(props: Props) {
  if (props.linkMembers.length === 1 || props.attendees?.length === 1) {
    return <MeetingTitleSingleMember {...props} />;
  } else {
    return <MeetingTitleMultiMember {...props} />;
  }
}
