// schema
// import * as ISchema from "#webapp/src/__schema__";

import { IFeatureGridReduxState } from "@clockwise/web-commons/src/util/feature-grid.util";

export enum FeatureGridActions {
  SetFeatureGridStates = "FeatureGrid_SetFeatureGridStates",
}

export const setFeatureGridStates = (featureStates: IFeatureGridReduxState) => ({
  type: FeatureGridActions.SetFeatureGridStates,
  data: {
    featureStates,
  },
});
