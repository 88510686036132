/**
 * @generated SignedSource<<3c0f4300e8a0f88fd19b45bb5b2a66db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NoMeetingDayOfWeek = "Friday" | "Monday" | "Saturday" | "Sunday" | "Thursday" | "Tuesday" | "Wednesday" | "%future added value";
export type TimeSlotEnum = "T_00_00" | "T_00_30" | "T_01_00" | "T_01_30" | "T_02_00" | "T_02_30" | "T_03_00" | "T_03_30" | "T_04_00" | "T_04_30" | "T_05_00" | "T_05_30" | "T_06_00" | "T_06_30" | "T_07_00" | "T_07_30" | "T_08_00" | "T_08_30" | "T_09_00" | "T_09_30" | "T_10_00" | "T_10_30" | "T_11_00" | "T_11_30" | "T_12_00" | "T_12_30" | "T_13_00" | "T_13_30" | "T_14_00" | "T_14_30" | "T_15_00" | "T_15_30" | "T_16_00" | "T_16_30" | "T_17_00" | "T_17_30" | "T_18_00" | "T_18_30" | "T_19_00" | "T_19_30" | "T_20_00" | "T_20_30" | "T_21_00" | "T_21_30" | "T_22_00" | "T_22_30" | "T_23_00" | "T_23_30" | "%future added value";
export type saveTeamSettingsMutationInput = {
  clientMutationId?: string | null;
  manageTeamCalendar?: boolean | null;
  minimumMinutesForSyncing?: number | null;
  noMeetingDayEmailOnScheduleOver?: boolean | null;
  noMeetingDayOfWeek?: NoMeetingDayOfWeek | null;
  orgRelayId: string;
  slackChannelId?: string | null;
  slackChannelName?: string | null;
  slackNotificationEnabled?: boolean | null;
  slackNotificationTimeSlot?: TimeSlotEnum | null;
  syncWFHEvents?: boolean | null;
  teamId: string;
  useNoMeetingDay?: boolean | null;
};
export type SaveTeamSettingsMutation$variables = {
  input: saveTeamSettingsMutationInput;
};
export type SaveTeamSettingsMutation$data = {
  readonly saveTeamSettings: {
    readonly clientMutationId: string | null;
    readonly teamSettings: {
      readonly manageTeamCalendar: boolean;
      readonly slackSettings: {
        readonly notificationEnabled: boolean;
        readonly channelId: string | null;
        readonly timeSlot: TimeSlotEnum | null;
      };
      readonly syncWFHEvents: boolean;
      readonly minimumMinutesForSyncing: number;
      readonly useNoMeetingDay: boolean;
      readonly noMeetingDayOfWeek: NoMeetingDayOfWeek;
      readonly noMeetingDayEmailOnScheduleOver: boolean;
      readonly lastUpdated: any | null;
    };
  } | null;
};
export type SaveTeamSettingsMutation = {
  variables: SaveTeamSettingsMutation$variables;
  response: SaveTeamSettingsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manageTeamCalendar",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamSlackSettings",
  "kind": "LinkedField",
  "name": "slackSettings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notificationEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "channelId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeSlot",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "syncWFHEvents",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimumMinutesForSyncing",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useNoMeetingDay",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "noMeetingDayOfWeek",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "noMeetingDayEmailOnScheduleOver",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdated",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SaveTeamSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "saveTeamSettingsMutationPayload",
        "kind": "LinkedField",
        "name": "saveTeamSettings",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamSettings",
            "kind": "LinkedField",
            "name": "teamSettings",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SaveTeamSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "saveTeamSettingsMutationPayload",
        "kind": "LinkedField",
        "name": "saveTeamSettings",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamSettings",
            "kind": "LinkedField",
            "name": "teamSettings",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a23e9ee7ccf37eb2f6acc0352be39810",
    "id": null,
    "metadata": {},
    "name": "SaveTeamSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation SaveTeamSettingsMutation(\n  $input: saveTeamSettingsMutationInput!\n) {\n  saveTeamSettings(input: $input) {\n    clientMutationId\n    teamSettings {\n      manageTeamCalendar\n      slackSettings {\n        notificationEnabled\n        channelId\n        timeSlot\n      }\n      syncWFHEvents\n      minimumMinutesForSyncing\n      useNoMeetingDay\n      noMeetingDayOfWeek\n      noMeetingDayEmailOnScheduleOver\n      lastUpdated\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8539554f2f75086ed9831faa44af8a6a";

export default node;
