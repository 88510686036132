interface EnhancedRequestInit extends RequestInit {
  timeout?: number;
  after?: (response?: Response) => void;
}

const MAX_BACKOFF_TIMEOUT = 30 * 60 * 1000; // 30 minutes

export function calculateBackoffTimeout(networkFailureScale: number) {
  return Math.min(MAX_BACKOFF_TIMEOUT, 5000 * Math.pow(2, networkFailureScale));
}

export function enhancedFetch(url: string, init: EnhancedRequestInit = {}): Promise<Response> {
  return new Promise((resolve, reject) => {
    const { timeout, after, ...vanillaInit } = init;
    let timer: ReturnType<typeof setTimeout>;
    let hasCancelled = false;

    if (timeout) {
      timer = setTimeout(() => {
        hasCancelled = true;
        reject();
      }, timeout);
    }

    try {
      return fetch(url, vanillaInit)
        .then((response) => {
          if (!timeout || (timeout && !hasCancelled)) {
            if (timer) {
              clearTimeout(timer);
            }

            resolve(response);
            if (after) {
              after(response);
            }
          }
        })
        .catch((error) => {
          if (!timeout || (timeout && !hasCancelled)) {
            if (timer) {
              clearTimeout(timer);
            }

            reject(error);
            if (after) {
              after();
            }
          }
        });
    } catch (e) {
      return Promise.reject(e);
    }
  });
}
