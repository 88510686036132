// IMPORTS
// schema
import * as ISchema from "#webapp/src/__schema__";

import { modernCache } from "#webapp/src/network/modern-network-layer";
import { commitMutation } from "react-relay";
import { Environment } from "relay-runtime";
import { updateTeamMutation } from "./UpdateTeam.gql"; // change

// TYPES
interface IUpdateTeamMutationResponse {
  updateTeam: ISchema.IUpdateTeamMutationPayload;
}

// MUTATION
export function updateTeam(
  environment: Environment,
  input: ISchema.IUpdateTeamMutationInput,
  onSuccess: (response?: ISchema.IUpdateTeamMutationPayload) => void,
  onError: (error?: any) => void,
) {
  commitMutation(environment, {
    onError,
    mutation: updateTeamMutation,
    variables: { input },
    // updater: (store, data) => {
    //   const { team } = data.updateTeam;

    //   // get the org from store
    //   const orgProxy = store.get(input.orgRelayId);
    //   if (!orgProxy) {
    //     return;
    //   }

    //   // get the userTeams from the store org
    //   const userTeamsProxy = orgProxy.getLinkedRecord('userTeams');
    //   if (!userTeamsProxy) {
    //     return;
    //   }

    //   // get the list from the store userTeams
    //   const userTeamsListProxy = userTeamsProxy.getLinkedRecords('list');

    //   if (userTeamsListProxy) {
    //     // find the team from store and add to userTeams
    //     const newTeam = store.get(team.id);

    //     // remove the team with corresponding team id and add in new team
    //     userTeamsProxy.setLinkedRecords(
    //       [...userTeamsListProxy.filter(ut => ut && ut.getDataID() !== team.id).map(aip => aip), newTeam],
    //       'list',
    //     );
    //   }
    // },
    onCompleted: (response: IUpdateTeamMutationResponse, errors?: Error[]) => {
      if (errors) {
        onError(errors[0]);
      } else {
        // if we're not in a modern environment, modernCache will be undefined
        if (modernCache) {
          modernCache.clear();
        }
        onSuccess(response.updateTeam);
      }
    },
  });
}
