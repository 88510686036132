import { graphql } from "react-relay";

export const setPreferredRoomsMutation = graphql`
  mutation SetPreferredRoomsMutation($input: SetPreferredRoomsMutationInput!) {
    setPreferredRooms(input: $input) {
      suggestedRoomPreferences {
        id
        suggestedRooms {
          isPreferred
          isSuggested
          room {
            roomId
            name
            orgId
            estimatedCapacity
            reportedCapacity
          }
        }
      }
    }
  }
`;
