import { graphql } from "react-relay";

export const addUserTagsMutation = graphql`
  mutation AddUserTagsMutation($input: AddOrgTagsMutationInput!) {
    addOrgTags(input: $input) {
      eventParent {
        events {
          id
          annotatedEvent {
            id
            externalEventId
            userTags {
              tag
              states {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
          annotatedRecurringEvent {
            id
            externalEventId
            userTags {
              tag
              states {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
        }
      }
    }
  }
`;
