import * as ISchema from "#webapp/src/__schema__";
import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { windowLocation } from "#webapp/src/util/location.util";
import { ITierType } from "#webapp/src/util/pricing.util";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { EcosystemEnum } from "@clockwise/schema";
import PricingPlans from "@clockwise/web-commons/src/ui/pricing-plans/PricingPlans";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import React, { useEffect, useState } from "react";
import { useMonetization } from "../../../hooks/useMonetization";

type OrgPricingGridProps = {
  billingRecurrence?: ISchema.SubscriptionInterval;
  isDialog: boolean;
  onChangeDialogView?: (view: "Pricing" | "Checkout") => void;
  isFullWidth?: boolean;
};

export const OrgPricingGrid = ({
  billingRecurrence,
  isDialog,
  onChangeDialogView,
  isFullWidth = true,
}: OrgPricingGridProps) => {
  const [loading, setLoading] = useState(false);
  const [downgradeDialogOpen, setDowngradeDialogOpen] = useState(false);

  const ecosystem = useEcosystem();

  const {
    shouldUserSeeJoinExistingPlan,
    daysLeftInTrial,
    userIsOnActiveTrial,
    usersProductType,
    isFeatureGridFetched,
  } = useMonetization();

  useEffect(() => {
    track(TrackingEvents.PRICING.VIEW_PRICING_GRID);
  }, []);

  const goToCheckout = () => {
    if (isDialog && onChangeDialogView) {
      // if dialog, open another dialog
      onChangeDialogView("Checkout");
    } else {
      // else set new window
      windowLocation.assign("OrgPricingGridGoToCheckout", "/app/checkout");
      onChangeLoading(true);
    }
  };

  const onChangeLoading = (newLoading: boolean) => {
    setLoading(newLoading);
  };

  const onDowngradeClick = (tierType: ITierType) => {
    setDowngradeDialogOpen(true);
    track(TrackingEvents.PRICING.DOWNGRADE_CLICKED, { tierType });
  };

  const onUpgradeClick = (tierType: ITierType) => {
    goToCheckout();
    track(TrackingEvents.PRICING.UPGRADE_CLICKED, { tierType });
  };

  const onContactUsClick = (tierType: ITierType) => {
    track(TrackingEvents.PRICING.CONTACT_US_CLICKED, { tierType });
  };

  const onDowngradeDialogClose = () => {
    setDowngradeDialogOpen(false);
  };

  const onDowngradeConfirmation = () => {
    track(TrackingEvents.PRICING.DOWNGRADE_CONFIRMATION_CLICKED);
  };

  if (!isFeatureGridFetched || loading) {
    return <Loader size="xl" sentiment="positive" />;
  } else {
    return (
      <PricingPlans
        billingRecurrence={billingRecurrence || ISchema.SubscriptionInterval.Yearly}
        daysLeftOnTrial={daysLeftInTrial || 0}
        downgradeDialogOpen={downgradeDialogOpen}
        isUserOnTrial={userIsOnActiveTrial}
        onContactUsClick={onContactUsClick}
        shouldShowJoinExistingPlanBanner={shouldUserSeeJoinExistingPlan}
        onDowngradeClick={onDowngradeClick}
        onDowngradeConfirmation={onDowngradeConfirmation}
        onDowngradeDialogClose={onDowngradeDialogClose}
        onUpgradeClick={onUpgradeClick}
        primarySubscriptionPlan={usersProductType}
        isFullWidth={isFullWidth}
        isM365Beta={ecosystem === EcosystemEnum.Microsoft}
      />
    );
  }
};
