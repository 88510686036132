//////////////////
// libraries
import { UserAvatar } from "@clockwise/web-commons/src/ui/UserAvatar";
import { Profile } from "@clockwise/web-commons/src/util/profile.util";
import React from "react";
import { IAvatarContainer } from "./AvatarTypes";

export const AvatarPerson = ({
  size,
  person,
  orgPerson,
  toolTipContent,
  backupCalendarId,
  showUserBadge = false,
  responseStatus,
  hasOverlap = false,
  onClick,
}: IAvatarContainer) => {
  const userBadge = showUserBadge && !!(person?.isUser || orgPerson?.userId);
  const profile: Profile = person?.profile ||
    orgPerson?.profile || {
      givenName: person?.primaryEmail ?? orgPerson?.primaryCalendarId ?? backupCalendarId,
      familyName: null,
      externalImageUrl: null,
    };
  return (
    <UserAvatar
      profile={profile}
      size={size}
      onClick={onClick}
      toolTipContent={toolTipContent}
      hasOverlap={hasOverlap}
      showUserBadge={userBadge}
      rsvp={responseStatus}
    />
  );
};
