import { sudoActions } from "../actions/sudo.actions";

export interface ISudoState {
  sudoTarget?: string;
}

interface ISudoAction {
  type: string;
  sudoTarget?: string;
}

export const initialSudoState: ISudoState = {
  sudoTarget: undefined,
};

export function sudoReducer(state: ISudoState = initialSudoState, action: ISudoAction) {
  switch (action.type) {
    case sudoActions.SET_SUDO_TARGET:
      return Object.assign({}, state, { sudoTarget: action.sudoTarget });
    case sudoActions.CLEAR_SUDO_TARGET:
      return Object.assign({}, state, { sudoTarget: undefined });
    default:
      return state;
  }
}
