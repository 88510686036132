import { useMutation } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { Flag, FlagNamespaces } from "@clockwise/client-commons/src/util/flags";
import { FlagsErrorable } from "@clockwise/schema";
import { compact } from "lodash";
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { SetFlagForUserDocument } from "./graphql/__generated__/SetFlagForUser.generated";

type FlagsContextObj = {
  flags: Flag[];
  /**
   * Whether the flags have been fully loaded from the network.
   */
  loaded: boolean;
  /**
   * Check if a feature flag is set for the currently defined namespace in this FlagsContext.
   */
  isFlagSet: (flag: Flag) => boolean;
  /**
   * Sets a feature flag for the currently defined namespace in this FlagsContext.
   */
  setFlag: (flag: Flag, enabled: boolean) => void;
};

/**
 * A context object containing user flags from a particular namespace, as well as functions to
 * check and set those flags.
 */
export const FlagsContext = createContext<FlagsContextObj>({
  flags: [],
  loaded: false,
  isFlagSet: (flag: Flag) => {
    console.warn(`
      The user flag "${flag}" could not be checked because flags are not injected into the current component tree!

      Please verify that your component root has been wrapped with <FlagsProvider>.
    `);
    return false;
  },
  setFlag: (flag: Flag, _enabled: boolean) => {
    console.warn(`
      The user flag "${flag}" could not be updated because flags are not injected into the current component tree!

      Please verify that your component root has been wrapped with <FlagsProvider>.
    `);
  },
});

type Props = {
  namespace: keyof typeof FlagNamespaces;
  flagsErrorable: FlagsErrorable | null;
};

/**
 * Injects a context for feature flag interaction with a given namespace and loaded set of feature flags.
 *
 * @param flagsErrorable A array of flags in the given namespace, returned from the backend (e.g. `webAppFlagsErrorable`, `servicesFlagsErrorable`)
 * @param namespace A namespace of flags to use/interact with.
 * @returns
 */
export function FlagsProvider({ children, flagsErrorable, namespace }: PropsWithChildren<Props>) {
  const [setFlagMutation] = useMutation(SetFlagForUserDocument);
  const flagsObj = getValue(flagsErrorable);
  const [flags, setFlags] = useState<Flag[]>([]);
  const [loaded, setLoaded] = useState(false);

  // Update flags from flagsErrorable if it changes.
  useEffect(() => {
    if (flagsObj?.flags) {
      setFlags(compact(flagsObj.flags));
      setLoaded(true);
    }
  }, [flagsObj]);

  const isFlagSet = useCallback((flag: Flag) => flags.indexOf(flag) > -1, [flags]);

  const setFlag = async (flag: Flag, enabled: boolean) => {
    const { data } = await setFlagMutation({
      variables: { namespace, flags: [flag], enable: enabled },
    });
    if (data?.updateFlagsForUser?.flags?.flags) {
      setFlags(compact(data.updateFlagsForUser.flags.flags));
    }
  };

  return (
    <FlagsContext.Provider value={{ flags, loaded, isFlagSet, setFlag }}>
      {children}
    </FlagsContext.Provider>
  );
}

export function useFlags() {
  return useContext(FlagsContext);
}
