import { DateTime } from "luxon";

export const get30DaysFromNow = () => {
  const now = DateTime.local();
  return now.plus({ days: 30 }).toFormat("yyyy-MM-dd");
};

export const get3DaysAgo = () => {
  const now = DateTime.local();
  return now.minus({ days: 3 }).toFormat("yyyy-MM-dd");
};
