import { useEffect, useRef } from "react";

/**
 * Creates an Interval and runs the given `callback` every `delay` ms.
 *
 * Based on code from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param callback Function to be called when the interval triggers
 * @param delay Milliseconds, how long to delay between firing, `null` to pause.
 */
export function useInterval(callback: () => void, delay?: number | null) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return;
  }, [delay]);
}
