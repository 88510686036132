import * as React from "react";
import { LoaderClock } from "../loader-clock";

interface IProps {
  message: string;
}

export const LoaderClockWithMessage = ({ message }: IProps) => {
  return (
    <div className="cw-inline-flex cw-flex-col cw-items-center cw-gap-2">
      <LoaderClock />
      <h6 className="cw-heading-xl cw-text-center">{message}</h6>
    </div>
  );
};
