import { graphql } from "react-relay";

export const sudoResetFlowStateMutation = graphql`
  mutation SudoResetFlowStateMutation($input: SudoResetFlowStateMutationInput!) {
    sudoResetFlowState(input: $input) {
      clientMutationId
      viewer {
        id
        sudo {
          resetFlowState {
            id
            flowKey
            current {
              id
              state
              percentComplete
              errorMessage
            }
            pending {
              id
              state
              percentComplete
              errorMessage
            }
          }
          modifyUser {
            user {
              id
              givenName
              familyName
              emails
              status
              externalImageUrl
              flowStates(first: 1000000) {
                edges {
                  node {
                    id
                    flowKey
                    current {
                      id
                      state
                      percentComplete
                      errorMessage
                    }
                    pending {
                      id
                      state
                      percentComplete
                      errorMessage
                    }
                  }
                }
              }
              orgs(first: 1000000) {
                edges {
                  node {
                    id
                    name
                    flowStates(first: 1000000) {
                      edges {
                        node {
                          id
                          flowKey
                          current {
                            id
                            state
                            percentComplete
                            errorMessage
                          }
                          pending {
                            id
                            state
                            percentComplete
                            errorMessage
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            userTeams {
              __typename
              ... on TeamList {
                list {
                  teamId
                  teamName
                }
              }
            }
            subscriptions {
              __typename
              ... on PaymentSubscriptionList {
                list {
                  id
                  type
                  subjectUrn {
                    value
                    type
                  }
                }
              }
            }
            featureStatesJson
          }
        }
      }
    }
  }
`;
