import { CSSProperties } from "@material-ui/core/styles/withStyles";

const backdrop: CSSProperties = {
  background: `rgba(255, 255, 255, 0.85)`,
};

const paper: CSSProperties = {
  "@media (max-width: 768px)": {
    margin: 0,
    maxWidth: "100%",
    width: "100%",
    maxHeight: "100%",
    height: "100%",
    borderRadius: 0,
  },
};

const rounded: CSSProperties = {
  borderRadius: 10,
};

export const styles = {
  backdrop,
  paper,
  rounded,
};
