import { SvgIconComponent } from "@clockwise/icons";
import { Menu as HeadlessMenu, Portal, Transition } from "@headlessui/react";
import classNames from "classnames";
import { isBoolean } from "lodash";
import React, { KeyboardEventHandler, PropsWithChildren, useRef, useState } from "react";
import { usePopper } from "react-popper";
import {
  EXPAND_SELECT_ICON_CLASS,
  INPUT_BACKGROUND_CLASS,
  INPUT_BORDER_CLASS,
  INPUT_BORDER_COLOR_CLASS,
  INPUT_BORDER_RADIUS_CLASS,
  INPUT_BORDER_SHADOW_CLASS,
  INPUT_CONTENT_SPACING_CLASS,
  INPUT_HEIGHT_CLASS,
  INPUT_ICON_SIZES,
  INPUT_MIN_WIDTH_CLASS,
  INPUT_PADDING_CLASS,
  INPUT_TEXT_CLASS,
  INPUT_TEXT_COLOR_CLASS,
  getInputDisabledClassNames,
  getInputFullWidthClassNames,
} from "../constants/classes";
import { Size, Variant, Sentiment as _Sentiment } from "../constants/types";
import { CwIdProps } from "../types/cw-id";
import { ExpandMore } from "./Icons";

const ABOVE_EVERYTHING = 10000;

type Sentiment = Extract<_Sentiment, "neutral" | "destructive" | "positive">;
// Same option as here: https://popper.js.org/docs/v2/constructors/#options
export type Placement =
  | "auto"
  | "auto-start"
  | "auto-end"
  | "top"
  | "top-start"
  | "top-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "right"
  | "right-start"
  | "right-end"
  | "left"
  | "left-start"
  | "left-end";

interface MenuProps extends CwIdProps {
  label: string | React.ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: Size;
  variant?: Variant;
  sentiment?: Sentiment;
  icon?: SvgIconComponent;
  showExpandIcon?: boolean | "default";
  onOpen?: () => void;
  maxHeight?: CSSStyleDeclaration["maxHeight"];
  keepOpen?: boolean;
  placement?: Placement;
  disablePortal?: boolean;
}

interface MenuContentProps extends CwIdProps {
  triggerRef: HTMLButtonElement | null;
  placement?: Placement;
  maxHeight?: CSSStyleDeclaration["maxHeight"];
  menuItemsRef: React.MutableRefObject<HTMLDivElement | null>;
  handleKeyDown: React.KeyboardEventHandler<HTMLDivElement>;
}

const MenuContents = ({
  "cw-id": cwId,
  triggerRef,
  maxHeight,
  placement,
  menuItemsRef,
  handleKeyDown,
  children,
}: PropsWithChildren<MenuContentProps>) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update: updatePopperInstance } = usePopper(triggerRef, container, {
    placement,
    strategy: "fixed",
    modifiers: [
      {
        name: "flip",
        enabled: true,
      },
      { name: "offset", options: { offset: [0, 4] } },
    ],
  });
  return (
    <div
      cw-id={`${cwId}-portal`}
      ref={setContainer}
      style={{
        ...styles.popper,
        minWidth: triggerRef?.scrollWidth,
        zIndex: ABOVE_EVERYTHING,
      }}
      {...attributes.popper}
    >
      <Transition
        leave="cw-transition cw-ease-in cw-duration-100"
        leaveFrom="cw-opacity-100"
        leaveTo="cw-opacity-0"
        enter="cw-transition cw-ease-in cw-duration-100"
        enterFrom="cw-opacity-0"
        enterTo="cw-opacity-100"
      >
        <HeadlessMenu.Items
          className={classNames(
            "cw-z-50 cw-min-w-full cw-p-1 cw-overflow-auto",
            "cw-font-body cw-font-normal cw-text-13 cw-bg-default cw-rounded-lg cw-shadow-selectPopup cw-min-w-[180px]",
          )}
          style={{ maxHeight }}
          ref={(element) => {
            // Update popper positioning after the content is rendered
            void updatePopperInstance?.();
            menuItemsRef.current = element;
          }}
          onKeyDown={handleKeyDown}
        >
          {children}
        </HeadlessMenu.Items>
      </Transition>
    </div>
  );
};

export function Menu({
  label,
  disabled = false,
  fullWidth = false,
  size = "medium",
  variant = "default",
  sentiment = "neutral",
  icon: StartIcon,
  showExpandIcon = "default",
  onOpen,
  maxHeight = "288px",
  keepOpen = false,
  placement = "bottom-start",
  children,
  "cw-id": cwId,
  disablePortal = false,
}: PropsWithChildren<MenuProps>) {
  // Chevron visibility states are varied depending on variant. Users preference is king, but if there are no specifications:
  // Inline variant will not show the chevron, but default and elevated will.
  const showChevronBasedOnVariant = () => {
    if (isBoolean(showExpandIcon)) return showExpandIcon;
    return variant === "inline" ? false : true;
  };
  const wasOpen = useRef(false);
  const menuItemsRef = useRef<HTMLDivElement | null>(null);
  const [trigger, setTrigger] = useState<HTMLButtonElement | null>(null);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    // DevNote: https://github.com/tailwindlabs/headlessui/discussions/1122
    if (!keepOpen) {
      return;
    }

    if (event.key === "Enter") {
      const activeItemId = menuItemsRef?.current?.getAttribute("aria-activedescendant");

      if (!activeItemId) {
        return;
      }

      const activeItem = document.getElementById(activeItemId);

      if (!activeItem) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      activeItem.click();
    }
  };

  return (
    <HeadlessMenu>
      {({ open }) => {
        if (open && !wasOpen.current) {
          wasOpen.current = true;
          setTimeout(() => {
            onOpen?.();
          }, 0);
        } else if (!open && wasOpen) {
          wasOpen.current = false;
        }
        return (
          <>
            <HeadlessMenu.Button
              cw-id={cwId}
              ref={setTrigger}
              disabled={disabled}
              className={classNames(
                INPUT_TEXT_COLOR_CLASS[variant][sentiment],
                INPUT_BORDER_COLOR_CLASS[variant][sentiment],
                INPUT_BACKGROUND_CLASS[variant][sentiment],
                INPUT_TEXT_CLASS[size],
                INPUT_HEIGHT_CLASS[size],
                INPUT_MIN_WIDTH_CLASS[size],
                INPUT_BORDER_RADIUS_CLASS[size],
                INPUT_BORDER_CLASS,
                INPUT_BORDER_SHADOW_CLASS[variant],
                INPUT_PADDING_CLASS[variant][size],
                getInputDisabledClassNames(!!disabled),
                getInputFullWidthClassNames(!!fullWidth),
                "cw-max-w-full cw-flex cw-justify-center cw-items-center",
              )}
            >
              <div className="cw-block cw-truncate cw-w-full">
                <div
                  className={classNames(
                    INPUT_CONTENT_SPACING_CLASS[size],
                    "cw-flex cw-items-center cw-justify-between cw-w-full",
                  )}
                >
                  <div className="cw-flex cw-items-center cw-gap-1 cw-w-full">
                    {StartIcon && <StartIcon className={classNames(INPUT_ICON_SIZES[size])} />}
                    {label}
                  </div>
                  {showChevronBasedOnVariant() && (
                    <ExpandMore
                      className={classNames(EXPAND_SELECT_ICON_CLASS[size])}
                      aria-hidden="true"
                    />
                  )}
                </div>
              </div>
            </HeadlessMenu.Button>
            {disablePortal ? (
              <MenuContents
                menuItemsRef={menuItemsRef}
                triggerRef={trigger}
                handleKeyDown={handleKeyDown}
                placement={placement}
                maxHeight={maxHeight}
              >
                {children}
              </MenuContents>
            ) : (
              <Portal>
                <MenuContents
                  menuItemsRef={menuItemsRef}
                  triggerRef={trigger}
                  handleKeyDown={handleKeyDown}
                  placement={placement}
                  maxHeight={maxHeight}
                >
                  {children}
                </MenuContents>
              </Portal>
            )}
          </>
        );
      }}
    </HeadlessMenu>
  );
}
