import { CSSProperties } from "@material-ui/core/styles/withStyles";

const container: CSSProperties = {};

const chromeContainer: CSSProperties = {
  right: 0,
  bottom: 0,
};

export const styles: Record<string, CSSProperties> = {
  container,
  chromeContainer,
};
