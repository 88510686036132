import { Tooltip } from "@clockwise/design-system";
import { AllInclusive, CheckCircle, InfoOutlined, Update } from "@clockwise/design-system/icons";
import classNames from "classnames";
import React from "react";
import { IFeatureComparison } from "../types";
import { FeatureComparisonRow } from "./FeatureComparisonRow";
import { GridItem } from "./GridItem";

type FeatureComparisonProps = {
  featureComparison: IFeatureComparison;
};

const FeatureComparison = ({ featureComparison }: FeatureComparisonProps) => {
  return (
    <FeatureComparisonRow
      emphasis={!!featureComparison.isSectionHeader}
      className={featureComparison.isSectionHeader ? "cw-h-[75px]" : "cw-h-16"}
    >
      <div
        className={classNames(
          "cw-w-2/5 cw-h-full cw-body-base cw-flex cw-justify-start cw-cursor-pointer",
          featureComparison.isSectionHeader ? "cw-items-end cw-pb-2" : "cw-items-center",
        )}
      >
        <Tooltip
          placement="top"
          title={String(featureComparison.tooltip)}
          open={!featureComparison.tooltip ? false : undefined}
        >
          <div className="cw-flex cw-items-center">
            <span>{featureComparison.feature}</span>
            {!featureComparison.isSectionHeader && (
              <InfoOutlined classes={{ root: "cw-fill-subtle" }} className="cw-ml-2 cw-text-base" />
            )}
          </div>
        </Tooltip>
      </div>
      <GridItem isHeader={false} variant="grey">
        {featureComparison.isSectionHeader ? (
          ""
        ) : featureComparison.tierFeatureMap.free.description ? (
          featureComparison.tierFeatureMap.free.description
        ) : featureComparison.tierFeatureMap.free.enabled === "Enabled" ? (
          <CheckCircle />
        ) : featureComparison.tierFeatureMap.free.enabled === "Disabled" ? (
          <div />
        ) : featureComparison.tierFeatureMap.free.enabled === "Unlimited" ? (
          <AllInclusive />
        ) : featureComparison.tierFeatureMap.free.enabled === "Limited" ? (
          <Update classes={{ root: "cw-fill-destructive" }} />
        ) : (
          ""
        )}
      </GridItem>
      <GridItem isHeader={false} variant="green">
        {featureComparison.isSectionHeader ? (
          ""
        ) : featureComparison.tierFeatureMap.pro.description ? (
          featureComparison.tierFeatureMap.pro.description
        ) : featureComparison.tierFeatureMap.pro.enabled === "Enabled" ? (
          <CheckCircle />
        ) : featureComparison.tierFeatureMap.pro.enabled === "Disabled" ? (
          <div />
        ) : featureComparison.tierFeatureMap.pro.enabled === "Unlimited" ? (
          <AllInclusive />
        ) : featureComparison.tierFeatureMap.pro.enabled === "Limited" ? (
          <Update classes={{ root: "cw-fill-destructive" }} />
        ) : (
          ""
        )}
      </GridItem>
      <GridItem isHeader={false} variant="grey">
        {featureComparison.isSectionHeader ? (
          ""
        ) : featureComparison.tierFeatureMap.business.description ? (
          featureComparison.tierFeatureMap.business.description
        ) : featureComparison.tierFeatureMap.business.enabled === "Enabled" ? (
          <CheckCircle />
        ) : featureComparison.tierFeatureMap.business.enabled === "Disabled" ? (
          <div />
        ) : featureComparison.tierFeatureMap.business.enabled === "Unlimited" ? (
          <AllInclusive />
        ) : featureComparison.tierFeatureMap.business.enabled === "Limited" ? (
          <Update classes={{ root: "cw-fill-destructive" }} />
        ) : (
          ""
        )}
      </GridItem>
      <GridItem isHeader={false} variant="grey">
        {featureComparison.isSectionHeader ? (
          ""
        ) : featureComparison.tierFeatureMap.enterprise.description ? (
          featureComparison.tierFeatureMap.enterprise.description
        ) : featureComparison.tierFeatureMap.enterprise.enabled === "Enabled" ? (
          <CheckCircle />
        ) : featureComparison.tierFeatureMap.enterprise.enabled === "Disabled" ? (
          <div />
        ) : featureComparison.tierFeatureMap.enterprise.enabled === "Unlimited" ? (
          <AllInclusive />
        ) : featureComparison.tierFeatureMap.enterprise.enabled === "Limited" ? (
          <Update classes={{ root: "cw-fill-destructive" }} />
        ) : (
          ""
        )}
      </GridItem>
    </FeatureComparisonRow>
  );
};

export default FeatureComparison;
