import { getRandomIntInRange } from "@clockwise/web-commons/src/util/browser.util";

export const scaleInterpolateRange = [0, 0.25, 0.35, 0.45, 0.5, 0.55, 0.65, 0.75, 1];
export const scaleInterpolateOutput = [1.04, 1.06, 1.07, 1.08, 1.1, 1.09, 1.08, 1.06, 1];
export const textScaleConfig = { mass: 1, tension: 170, friction: 18, precision: 0.02 };

export const getConfettis = () => {
  const randomColors = ["#FFFFFF", "#FFDE34"]; // #FFDE34 is emoji yellow
  randomColors.splice(getRandomIntInRange(0, 1), getRandomIntInRange(0, 1));
  return [
    {
      // Left to right, orginating from near the copy button
      particleCount: getRandomIntInRange(80, 120),
      spread: getRandomIntInRange(50, 150),
      startVelocity: getRandomIntInRange(18, 26),
      ticks: getRandomIntInRange(280, 320),
      drift: getRandomIntInRange(1, 6) / 10,
      origin: { x: 0.2, y: 1.2 }, // Sets it to pop from the copy button.
      angle: getRandomIntInRange(52, 70),
      colors: randomColors,
      scalar: 0.2,
      disableForReducedMotion: true,
    },
  ];
};
