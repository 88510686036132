export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. BigInt can represent values between -(2^53) + 1 and 2^53 - 1. */
  BigInt: any;
  /** An Id for a CalendarEvent */
  CalendarEventId: any;
  /** an id for a calendar */
  CalendarId: any;
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  Duration: any;
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: any;
  /** an id for an event, this is the id used to identify an event by the backend apis, as well as what google uses to identify calendar events */
  EventId: any;
  /** A string formatted as ISO8601 interval, such as 2023-06-01T13:00:00Z/2023-07-11T15:30:00Z */
  Interval: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A date string formatted as YYYY-MM-DD */
  LocalDate: any;
  /** A time string, in 24 hour time */
  LocalTime: any;
  /** A date string formatted as YYYY-MM-DD, indicating the beginning of a week */
  LocalWeek: any;
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: any;
  /** Recurrence rule as specified in [RFC 5545 Section 3.3.10](https://datatracker.ietf.org/doc/html/rfc5545#section-3.3.10) */
  RecurrenceRule: any;
  /** An Id for a RecurringEvent */
  RecurringEventId: any;
  /** Represents NULL values */
  Void: any;
};

/** DESCRIBE NEW FIELD HERE */
export type AccountStripe = {
  __typename: "AccountStripe";
  accountId: Scalars["String"];
  stripeCustomerId: Scalars["String"];
};

export enum ActionTypeEnum {
  AutopilotTurnedOff = "AutopilotTurnedOff",
  AutopilotTurnedOn = "AutopilotTurnedOn",
  ClockwiseCouldNotResolveConflict = "ClockwiseCouldNotResolveConflict",
  ClockwiseEventRescheduled = "ClockwiseEventRescheduled",
  ClockwiseMeetingMove = "ClockwiseMeetingMove",
  ClockwiseMovePaused = "ClockwiseMovePaused",
  ClockwiseMoveUnpaused = "ClockwiseMoveUnpaused",
  ClockwiseSecondaryReschedule = "ClockwiseSecondaryReschedule",
  EventCreated = "EventCreated",
  EventRescheduled = "EventRescheduled",
  NextAutopilotRun = "NextAutopilotRun",
}

export enum ActivityFilter {
  CurrentUser = "CurrentUser",
  CurrentUserOrInvited = "CurrentUserOrInvited",
  Invited = "Invited",
  NonUser = "NonUser",
}

export type AddAdminForBillingGroupMutationInput = {
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  userId: Scalars["String"];
};

export type AddAdminForBillingGroupMutationPayload = {
  __typename: "AddAdminForBillingGroupMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type AddAndRemoveOrgTagsMutationInput = {
  calendarId?: InputMaybe<Scalars["String"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  eventParentRelayId: Scalars["String"];
  moveRangeV3?: InputMaybe<MoveRangeInLocalTimeV3Input>;
  orgRelayId: Scalars["String"];
  tagNamesToRemove: Array<Scalars["String"]>;
  tagsToAdd?: InputMaybe<Array<TagInput>>;
};

export type AddAndRemoveOrgTagsMutationPayload = {
  __typename: "AddAndRemoveOrgTagsMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventParent?: Maybe<EventParent>;
};

export type AddDiffBlock = {
  __typename: "AddDiffBlock";
  diffs: Array<AddDiffSummary>;
  text: Scalars["String"];
};

/** The details of an event we are proposing to add to a user's calendar */
export type AddDiffDetails = DiffDetails & {
  __typename: "AddDiffDetails";
  /** The type of action being proposed for this diff */
  actionType: DiffActionTypeEnum;
  attendees: ProposalAttendees;
  /** Indicates that not all the attendees were returned */
  attendeesOmitted: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  /** Indicates the description has been omitted */
  descriptionOmitted: Scalars["Boolean"];
  flexibility?: Maybe<ProposalFlexibility>;
  id: Scalars["ID"];
  isFlexible: Scalars["Boolean"];
  location?: Maybe<Scalars["String"]>;
  recurrence: EventRecurrence;
  recurrenceRule?: Maybe<Scalars["RecurrenceRule"]>;
  /** The start and end time of the proposed event formatted as an ISO8601 interval. Null if there were no times found */
  time?: Maybe<Scalars["Interval"]>;
  title: Scalars["String"];
  tradeoffBlocks: Array<TradeoffBlock>;
  /** The type of video conferencing link found in the event */
  videoConferencingType?: Maybe<ConferencingType>;
};

/** A summary of an event we are proposing to add to a user's calendar */
export type AddDiffSummary = DiffSummary & {
  __typename: "AddDiffSummary";
  action: DiffAction;
  /** False if the diff has been rejected by the user */
  active: Scalars["Boolean"];
  attendees: ProposalAttendees;
  id: Scalars["ID"];
  isFlexible: Scalars["Boolean"];
  recurrence: EventRecurrence;
  /** The recurrence rule for the event */
  recurrenceRule?: Maybe<Scalars["RecurrenceRule"]>;
  /** Null if there were no times found */
  time?: Maybe<Scalars["Interval"]>;
  title: Scalars["String"];
  tradeoffBlocks: Array<TradeoffBlock>;
};

export type AddManualMembersForBillingGroupMutationInput = {
  addExclusions?: InputMaybe<Scalars["Boolean"]>;
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  personIds: Array<Scalars["String"]>;
};

export type AddManualMembersForBillingGroupMutationPayload = {
  __typename: "AddManualMembersForBillingGroupMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type AddOrgTagsMutationInput = {
  calendarId?: InputMaybe<Scalars["String"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  debuggingReason?: InputMaybe<Scalars["String"]>;
  eventParentRelayId: Scalars["String"];
  moveRangeV3?: InputMaybe<MoveRangeInLocalTimeV3Input>;
  orgRelayId: Scalars["String"];
  tags?: InputMaybe<Array<TagInput>>;
};

export type AddOrgTagsMutationPayload = {
  __typename: "AddOrgTagsMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventParent?: Maybe<EventParent>;
};

export type AddSlackAccountForTeamMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  redirect: Scalars["String"];
  teamId: Scalars["String"];
  verificationCode: Scalars["String"];
};

export type AddSlackAccountForTeamMutationPayload = {
  __typename: "AddSlackAccountForTeamMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type AddSlackAccountMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  redirect: Scalars["String"];
  verificationCode: Scalars["String"];
};

export type AddSlackAccountMutationPayload = {
  __typename: "AddSlackAccountMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type AddSlackAccountV2MutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  redirect: Scalars["String"];
  verificationCode: Scalars["String"];
};

export type AddSlackAccountV2MutationPayload = {
  __typename: "AddSlackAccountV2MutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type AddZoomAccountMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isUserLevelZoomAccount?: InputMaybe<Scalars["Boolean"]>;
  orgRelayId: Scalars["String"];
  redirect: Scalars["String"];
  verificationCode: Scalars["String"];
};

export type AddZoomAccountMutationPayload = {
  __typename: "AddZoomAccountMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type Address = {
  __typename: "Address";
  rawAddress: Scalars["String"];
};

export type AddressErrorable = Address | GraphEntityError;

export type AddressIfSet = Address | NoAddress;

/** A person affected by the changes of a scheduling proposal */
export type AffectedPerson = {
  __typename: "AffectedPerson";
  /** The affected calendar ID of the person */
  calendarId: Scalars["CalendarId"];
  id: Scalars["ID"];
  /** The affected person */
  person?: Maybe<OrgPerson>;
};

export type AllFocusTime = {
  __typename: "AllFocusTime";
  nop?: Maybe<Scalars["Void"]>;
};

/** An event annotated with user and org tags */
export type AnnotatedEvent = Node & {
  __typename: "AnnotatedEvent";
  externalEventId?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  /** The org tags for this event */
  orgTags?: Maybe<Array<OrgTag>>;
  /** The user tags for this event */
  userTags?: Maybe<Array<UserTag>>;
};

/** The event can move at any time */
export type AnyTime = {
  __typename: "AnyTime";
  void?: Maybe<Scalars["Void"]>;
};

/** Either a text response, view events response, or a (re)scheduling proposal */
export type AssistantMessage =
  | ProposalResponse
  | SchedulingLinksResponse
  | TextResponse
  | ViewEventsResponse;

/** A person or room attached to a meeting */
export type Attendee = {
  __typename: "Attendee";
  /** What to call the attendee */
  displayName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Whether the attendee has been marked as optional for the meeting */
  optional?: Maybe<Scalars["Boolean"]>;
  /** Whether the attendee is the organizer of the meeting */
  organizer?: Maybe<Scalars["Boolean"]>;
  /** The ID of the calendar user's profile */
  profileId?: Maybe<Scalars["String"]>;
  /** Indicates that the attendee is a resource */
  resource?: Maybe<Scalars["Boolean"]>;
  /** Invite response status */
  responseStatus?: Maybe<ResponseStatusEnum>;
  /** Type of attendee urn */
  urnType?: Maybe<UrnTypeEnum>;
  /** Attendee's urn (note this relates to urnType) */
  urnValue: Scalars["String"];
};

export type AttendeeAmbiguity = {
  __typename: "AttendeeAmbiguity";
  attendeeRequested: Scalars["String"];
  /** A list of attendee people that match the requested attendee string */
  disambiguations: PersonListErrorable;
  /** Whether or not the ambiguity is a group, false if for a person */
  isGroup: Scalars["Boolean"];
};

export enum AttendeeFilterModifierEnum {
  ALL = "ALL",
  ANY = "ANY",
  EXACT = "EXACT",
}

export enum AttendeeResponse {
  Accepted = "Accepted",
  Declined = "Declined",
  NotResponded = "NotResponded",
  TentativelyAccepted = "TentativelyAccepted",
}

export type AttendeesListSaveAddEventDiff = {
  calendarId: Scalars["CalendarId"];
  optional: Scalars["Boolean"];
};

export type AttendeesListSaveModifyEventDiff = {
  calendarId: Scalars["CalendarId"];
  optional: Scalars["Boolean"];
};

export type AttendeesListSharedProposalSaveAddEventDiff = {
  calendarId: Scalars["CalendarId"];
  optional: Scalars["Boolean"];
};

export type AutoDeclineSettings = {
  __typename: "AutoDeclineSettings";
  enabled: Scalars["Boolean"];
  threshold: Scalars["Duration"];
};

/** the current status of an autopilot event candidate */
export type AutopilotEventStatus = {
  __typename: "AutopilotEventStatus";
  /** the reason why autopilot cannot move this event */
  status?: Maybe<AutopilotEventStatusEnum>;
};

export enum AutopilotEventStatusEnum {
  CanMove = "CanMove",
  EventExceedsDefragRange = "EventExceedsDefragRange",
  EventOutsideTimeRange = "EventOutsideTimeRange",
  External = "External",
  NoDuration = "NoDuration",
  OverQuota = "OverQuota",
  Paused = "Paused",
  Pinned = "Pinned",
  PrivateEvent = "PrivateEvent",
  SmartHold = "SmartHold",
  TrialExpired = "TrialExpired",
}

export type AutopilotEventStatusErrorable = AutopilotEventStatus | GraphEntityError;

/** Events to modify */
export type AutopilotEventToModify = {
  externalEventId: Scalars["String"];
  movementType: ModifyAutopilotForEventMovementTypeEnum;
};

/** A list of autopilot events that are enabled or suggested */
export type AutopilotEventsResponse = {
  __typename: "AutopilotEventsResponse";
  enabledAttending: Array<Maybe<Event>>;
  enabledOrganized: Array<Maybe<Event>>;
  suggestedAttending: Array<Maybe<Event>>;
  suggestedOrganized: Array<Maybe<Event>>;
};

export type AutopilotEventsResponseErrorable = AutopilotEventsResponse | GraphEntityError;

/** A single action performed on an AP event */
export type AutopilotHistoryActionLog = {
  __typename: "AutopilotHistoryActionLog";
  actionInfo: Array<Scalars["String"]>;
  actionString: Scalars["String"];
  actionType: ActionTypeEnum;
  timestamp: Scalars["BigInt"];
};

/** Represents a set of suggestion events to put on autopilot */
export type AutopilotOnboardingSuggestionsResponse = {
  __typename: "AutopilotOnboardingSuggestionsResponse";
  flexible: Array<Maybe<Event>>;
  notFlexible: Array<Maybe<Event>>;
  veryFlexible: Array<Maybe<Event>>;
};

export type AutopilotOnboardingSuggestionsResponseErrorable =
  | AutopilotOnboardingSuggestionsResponse
  | GraphEntityError;

export type Availability = {
  __typename: "Availability";
  /** The eventDiffId to use for scheduling link creation for this proposal */
  baseEventDiffId: Scalars["String"];
  /** A summary of availabilities for this proposal */
  summaryText?: Maybe<Scalars["String"]>;
};

/** Start and end of hours that are available to meet, in the querying user's time zone */
export type AvailableMeetingHours = {
  __typename: "AvailableMeetingHours";
  end: Scalars["LocalTime"];
  start: Scalars["LocalTime"];
};

export type BatchAddAndRemoveOrgTagsMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  tagPairings?: InputMaybe<Array<BatchAddAndRemoveOrgTagsTagPairings>>;
};

export type BatchAddAndRemoveOrgTagsMutationPayload = {
  __typename: "BatchAddAndRemoveOrgTagsMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventParents?: Maybe<Array<EventParent>>;
};

export type BatchAddAndRemoveOrgTagsTagPairings = {
  calendarId?: InputMaybe<Scalars["String"]>;
  eventParentRelayId: Scalars["String"];
  moveRangeV3?: InputMaybe<MoveRangeInLocalTimeV3Input>;
  orgRelayId: Scalars["String"];
  tagNamesToRemove: Array<Scalars["String"]>;
  tagsToAdd?: InputMaybe<Array<TagInput>>;
};

export type BatchModifyAutopilotForEventsMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  events?: InputMaybe<Array<AutopilotEventToModify>>;
  orgRelayId: Scalars["String"];
};

export type BatchModifyAutopilotForEventsMutationPayload = {
  __typename: "BatchModifyAutopilotForEventsMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type BatchModifyFlexibilityInput = {
  /** Event ids that should be updated to day flexibility */
  dayFlexibleEvents?: Array<Scalars["String"]>;
  /** Events that should be updated to day of week flexibility, and the allowed days for the flexibility */
  dayOfWeekFlexibleEvents?: Array<DayOfWeekFlexibleEvent>;
  /** Event ids that should not be flexible */
  notFlexibleEvents?: Array<Scalars["String"]>;
  /** The relay org id */
  orgRelayId?: InputMaybe<Scalars["ID"]>;
  /** Event ids that should be updated to week flexibility */
  weekFlexibleEvents?: Array<Scalars["String"]>;
};

export type BatchUpdateRoomFlexibilityInput = {
  canChangeRooms?: Array<InputMaybe<Scalars["EventId"]>>;
  cannotChangeRooms?: Array<InputMaybe<Scalars["EventId"]>>;
  /** The relay org id */
  orgRelayId: Scalars["ID"];
};

/** Represents a billing group */
export type BillingGroup = {
  __typename: "BillingGroup";
  adminOrgPersons: OrgPersonListErrorable;
  createdAtMillis: Scalars["BigInt"];
  dsyncConfigured?: Maybe<DsyncConfiguredErrorable>;
  id: Scalars["String"];
  invitePolicy: InvitePolicyEnum;
  isAutoSyncEnabled?: Maybe<Scalars["Boolean"]>;
  manualMembers: BillingGroupManualMemberListErrorable;
  name: Scalars["String"];
  personListPaginatedErrorable?: Maybe<OrgPersonListPaginatedErrorable>;
  /** A link, which expires in 5 minutes, that can be used to configure sso for a billing group. */
  ssoAdminPortalLink?: Maybe<SsoAdminPortalLinkErrorable>;
  stripeCustomerPortalSession?: Maybe<StripeCustomerPortalSessionErrorable>;
  subscription: SubscriptionDetailsErrorable;
  syncSources: Array<Urn>;
  type?: Maybe<BillingGroupTypeEnum>;
  upcomingBillingDetails?: Maybe<UpcomingBillingDetailsErrorable>;
};

/** Represents a billing group */
export type BillingGroupPersonListPaginatedErrorableArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
};

/** Represents a billing group */
export type BillingGroupSsoAdminPortalLinkArgs = {
  intent?: InputMaybe<SsoIntentInput>;
};

export type BillingGroupErrorable = BillingGroup | GraphEntityError;

export type BillingGroupList = {
  __typename: "BillingGroupList";
  list: Array<BillingGroup>;
};

export type BillingGroupListErrorable = BillingGroupList | GraphEntityError;

/** Represents a manual member of a billing group */
export type BillingGroupManualMember = {
  __typename: "BillingGroupManualMember";
  calendarId: Scalars["String"];
  externalUserId: Scalars["String"];
  /** The associated person */
  orgPerson?: Maybe<OrgPersonErrorable>;
  personId?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type BillingGroupManualMemberList = {
  __typename: "BillingGroupManualMemberList";
  list: Array<BillingGroupManualMember>;
};

export type BillingGroupManualMemberListErrorable = BillingGroupManualMemberList | GraphEntityError;

export enum BillingGroupTypeEnum {
  Manual = "Manual",
  SSO = "SSO",
}

export type BillingGroupsOfCalendarIdList = {
  __typename: "BillingGroupsOfCalendarIdList";
  allBillingGroupsOfCalendarId: Array<BillingGroupErrorable>;
  calendarId: Scalars["ID"];
  primaryBillingGroup: BillingGroupErrorable;
};

/** A calendar belonging to someone or thing in the organization */
export type Calendar = Node & {
  __typename: "Calendar";
  /** The id of the calendar — usually an email address */
  calendarId?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  /** The id of the organization this calendar belongs to */
  orgId?: Maybe<Scalars["String"]>;
};

/** Entity to show relevant analytics for given user and week */
export type CalendarAnalyticsData = {
  __typename: "CalendarAnalyticsData";
  /** The week of this analytics */
  dateOfWeek: Scalars["String"];
  /** The number minutes of focus time the user had this week */
  focusMinutes: Scalars["Int"];
  /** The number minutes of fragmented time the user had this week */
  fragmentedMinutes: Scalars["Int"];
  /** The user's selected job from onboarding */
  job: Scalars["String"];
  /** The number minutes of meetings the user had this week */
  meetingMinutes: Scalars["Int"];
  /** The user's selected role from onboarding */
  role: Scalars["String"];
};

/** An entity to contain relevant user information for exported calendar analytics */
export type CalendarAnalyticsUser = {
  __typename: "CalendarAnalyticsUser";
  emailAddress: Scalars["String"];
  familyName: Scalars["String"];
  givenName: Scalars["String"];
  /** The associated org person for this user */
  orgPerson?: Maybe<OrgPersonErrorable>;
};

/** A connection to a list of items. */
export type CalendarConnection = {
  __typename: "CalendarConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CalendarEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CalendarEdge = {
  __typename: "CalendarEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<Calendar>;
};

/**
 *
 *             An event specific to a single calendar.
 *
 *             A couple assumptions made here:
 *             - the event will always be "public"; this object will never represent
 *                 a private event that the current user doesn't have access to
 *             - the event will never be an "all-day" event, start & end will always have a time component
 */
export type CalendarEvent = {
  __typename: "CalendarEvent";
  attendees: Array<EventAttendee>;
  createdMillis?: Maybe<Scalars["BigInt"]>;
  creator: EventCreator;
  endTime: Scalars["DateTime"];
  /** @deprecated Misspelled, use "flexibilityConstraints" */
  flexibililityConstraints?: Maybe<FlexibilityConstraints>;
  flexibility: FlexibleOrNot;
  flexibilityConstraints?: Maybe<FlexibilityConstraints>;
  id: Scalars["CalendarEventId"];
  organizer: EventOrganizer;
  startTime: Scalars["DateTime"];
  /** A human-readable string describing the time of the event (including recurrence & flexibility if exists) */
  timeDescription?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

/** Health Score for a given calendarId */
export type CalendarFocusTimeScore = {
  __typename: "CalendarFocusTimeScore";
  busyMinutes?: Maybe<Scalars["Int"]>;
  calendarId?: Maybe<Scalars["String"]>;
  categoryAdHoc?: Maybe<Scalars["Int"]>;
  categoryExternal?: Maybe<Scalars["Int"]>;
  categoryFocusTime?: Maybe<Scalars["Int"]>;
  categoryIndividualHold?: Maybe<Scalars["Int"]>;
  categoryOneOnOne?: Maybe<Scalars["Int"]>;
  categoryOther?: Maybe<Scalars["Int"]>;
  categoryOutOfOffice?: Maybe<Scalars["Int"]>;
  categoryPaceSetting?: Maybe<Scalars["Int"]>;
  crawledStatus: CrawledStatus;
  focusMinutes?: Maybe<Scalars["Int"]>;
  fragmentedMinutes?: Maybe<Scalars["Int"]>;
  secondaryKey?: Maybe<Scalars["String"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
};

/** Meeting Hours for a given calendar id */
export type CalendarMeetingHours = Node & {
  __typename: "CalendarMeetingHours";
  calendarId?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  workingHours?: Maybe<WorkingHours>;
};

/** A connection to a list of items. */
export type CalendarMeetingHoursConnection = {
  __typename: "CalendarMeetingHoursConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CalendarMeetingHoursEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CalendarMeetingHoursEdge = {
  __typename: "CalendarMeetingHoursEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<CalendarMeetingHours>;
};

export type CalendarNotification = CalendarNotificationResults | NoCalendarNotifications;

export type CalendarNotificationContent = StandardNotification;

/** User gcal notification. */
export type CalendarNotificationResults = {
  __typename: "CalendarNotificationResults";
  closeTracking: TrackingData;
  content: CalendarNotificationContent;
  /** The ID of an object */
  id: Scalars["ID"];
  showTracking: TrackingData;
  type: Scalars["String"];
};

/** Current Calendar Status */
export type CalendarStatus = Node & {
  __typename: "CalendarStatus";
  calendarId: Scalars["String"];
  /** The ID of an object */
  id: Scalars["ID"];
  moonPhase?: Maybe<MoonPhase>;
  status?: Maybe<CalendarStatusResponse>;
};

export type CalendarStatusErrorable = CalendarStatus | GraphEntityError;

export type CalendarStatusList = {
  __typename: "CalendarStatusList";
  list: Array<CalendarStatus>;
};

export type CalendarStatusListErrorable = CalendarStatusList | GraphEntityError;

export enum CalendarStatusResponse {
  AfterHours = "AfterHours",
  BeforeWorkingHours = "BeforeWorkingHours",
  DayOff = "DayOff",
  FocusTime = "FocusTime",
  Holiday = "Holiday",
  Lunch = "Lunch",
  Meeting = "Meeting",
  Other = "Other",
  OutOfOffice = "OutOfOffice",
  Weekend = "Weekend",
  WorkingHours = "WorkingHours",
}

/** Calendar stats for a teammate */
export type CalendarTimeStats = {
  __typename: "CalendarTimeStats";
  busyMinutes?: Maybe<Scalars["Int"]>;
  calendarId?: Maybe<Scalars["String"]>;
  focusMinutes?: Maybe<Scalars["Int"]>;
  fragmentedMinutes?: Maybe<Scalars["Int"]>;
  scoresByDate: Array<CalendarFocusTimeScore>;
  scoresByDay?: Maybe<Array<Maybe<CalendarFocusTimeScore>>>;
  totalMinutes?: Maybe<Scalars["Int"]>;
  unclassifiedMinutes?: Maybe<Scalars["Int"]>;
};

export type CalendarTimeStatsList = {
  __typename: "CalendarTimeStatsList";
  list: Array<CalendarTimeStats>;
};

export type CalendarTimeStatsListErrorable = CalendarTimeStatsList | GraphEntityError;

/** A week of events for a given calendar id */
export type CalendarWeek = Node & {
  __typename: "CalendarWeek";
  calendarId?: Maybe<Scalars["String"]>;
  calendarMeetingHours?: Maybe<CalendarMeetingHours>;
  eventParents?: Maybe<EventParentConnection>;
  /** The ID of an object */
  id: Scalars["ID"];
  orgId?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["Date"]>;
  timeZone?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  userMeetingHours?: Maybe<UserMeetingHours>;
};

/** A week of events for a given calendar id */
export type CalendarWeekEventParentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A connection to a list of items. */
export type CalendarWeekConnection = {
  __typename: "CalendarWeekConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CalendarWeekEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CalendarWeekEdge = {
  __typename: "CalendarWeekEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<CalendarWeek>;
};

/** An object representing whether a user can reschedule or enable autopilot */
export type CanScheduleResponse = {
  __typename: "CanScheduleResponse";
  attendeeNotInDomain: Scalars["Boolean"];
  canAutopilot: Scalars["Boolean"];
  canReschedule: Scalars["Boolean"];
  externalEventId: Scalars["String"];
  isAllDayEvent: Scalars["Boolean"];
  organizerNotClockwiseUser: Scalars["Boolean"];
};

export type CanScheduleResponseList = {
  __typename: "CanScheduleResponseList";
  list: Array<CanScheduleResponse>;
};

export type CanScheduleResponseListErrorable = CanScheduleResponseList | GraphEntityError;

export type CancelChatProposalPayload = {
  __typename: "CancelChatProposalPayload";
  /** @deprecated Use updatedHistory instead */
  message: AssistantMessage;
  success: Scalars["Boolean"];
  /** The updated chat history, including the user message and processed assistant response */
  updatedHistory: ChatHistory;
};

export type CancelEventDiffDetailsInput = {
  diffId: Scalars["ID"];
  /** Whether or not to delete future events, just this instance or all instances */
  repeatingEventSaveOption?: InputMaybe<RepeatingEventSaveOption>;
  /** Whether or not the event should be cancelled, false will set the action to unchanged and reverse the cancel */
  shouldCancel: Scalars["Boolean"];
};

export type CancelEventDiffDetailsPayload = {
  __typename: "CancelEventDiffDetailsPayload";
  message: AssistantMessage;
};

export type CancelProcessMessagePayload = {
  __typename: "CancelProcessMessagePayload";
  /**
   * A text, viewEvents, or proposal response from the assistant
   * @deprecated Use updatedHistory instead
   */
  assistantMessage: AssistantMessage;
  /** an id for the chat conversation */
  conversationId: Scalars["ID"];
  debugInfo?: Maybe<Scalars["String"]>;
  /** The updated chat history, including the user message and processed assistant response */
  updatedHistory: ChatHistory;
  /**
   * The user-submitted message
   * @deprecated Use updatedHistory instead
   */
  userMessage: UserMessage;
};

export type CancelSharedProposalPayload = {
  __typename: "CancelSharedProposalPayload";
  proposal: SchedulingProposal;
};

export type CancelTestSubscriptionsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["ID"];
};

export type CancelTestSubscriptionsPayload = {
  __typename: "CancelTestSubscriptionsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** A list of messages that make up a conversation between a user and Clockwise AI. */
export type ChatHistory = {
  __typename: "ChatHistory";
  /** The ID of an object */
  id: Scalars["ID"];
  /** Whether or not the user's message is still being processed */
  isProcessing: Scalars["Boolean"];
  messages: Array<ChatMessage>;
  /** Whether or not the user can use Clockwise AI's Chat functionality */
  shouldExcludeAi: Scalars["Boolean"];
};

/** Either a user message or an assistant response: text, view events, or a (re)scheduling proposal */
export type ChatMessage =
  | ProposalResponse
  | SchedulingLinksResponse
  | TextResponse
  | UserMessage
  | ViewEventsResponse;

export type ChromeAppUpdateTimes = {
  __typename: "ChromeAppUpdateTimes";
  /** Last update of the chrome app with immediate priority */
  forceTime?: Maybe<Scalars["BigInt"]>;
  /** Last update of the chrome app with idle priority */
  idleTime?: Maybe<Scalars["BigInt"]>;
};

export type ClearUserDataMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type ClearUserDataMutationPayload = {
  __typename: "ClearUserDataMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** The schema for a CLI command */
export type CliCommand = Node & {
  __typename: "CliCommand";
  description: Scalars["String"];
  /** The ID of an object */
  id: Scalars["ID"];
  name: Scalars["String"];
  options: Array<CliOption>;
};

export type CliCommandList = {
  __typename: "CliCommandList";
  list: Array<CliCommand>;
};

export type CliCommandListErrorable = CliCommandList | GraphEntityError;

/** The schema for an option of a CLI command */
export type CliOption = {
  __typename: "CliOption";
  description: Scalars["String"];
  name: Scalars["String"];
  type: CliOptionType;
};

export type CliOptionInput = {
  name: Scalars["String"];
  values: Array<Scalars["String"]>;
};

export enum CliOptionType {
  Boolean = "Boolean",
  String = "String",
}

/** A teammates shared minutes */
export type CollaboratorScore = {
  __typename: "CollaboratorScore";
  calendarId: Scalars["String"];
  orgPerson: OrgPerson;
  sharedMinutes: Scalars["Int"];
};

/** A list of teammates that you work with the most */
export type CollaboratorStats = {
  __typename: "CollaboratorStats";
  calendarId: Scalars["String"];
  topCollaborators?: Maybe<Array<CollaboratorScore>>;
};

export type CollaboratorStatsErrorable = CollaboratorStats | GraphEntityError;

export enum ConferenceTypeEnum {
  Meet = "Meet",
  Removed = "Removed",
  TeamsForBusiness = "TeamsForBusiness",
  Zoom = "Zoom",
}

export enum ConferencingType {
  AROUND = "AROUND",
  MEET = "MEET",
  OTHER = "OTHER",
  TEAMS_FOR_BUSINESS = "TEAMS_FOR_BUSINESS",
  ZOOM = "ZOOM",
}

export type ConfirmChatMessageInput = {
  /** Optionally clear the chat history as part of confirming the proposal */
  clearHistory: Scalars["Boolean"];
};

export type ConfirmChatProposalPayload = {
  __typename: "ConfirmChatProposalPayload";
  eventIds: Array<Scalars["String"]>;
  /** @deprecated Use updatedHistory instead */
  message: AssistantMessage;
  /** The list of events that were updated as part of the confirmation */
  updatedEvents: Array<UpdatedEvent>;
  /** The updated chat history, including the user message and processed assistant response */
  updatedHistory: ChatHistory;
};

export type ConfirmSharedProposalPayload = {
  __typename: "ConfirmSharedProposalPayload";
  proposal: SchedulingProposal;
};

export enum ConflictAction {
  CANCEL = "CANCEL",
  DECLINE = "DECLINE",
  RESCHEDULE = "RESCHEDULE",
  RSVP_MAYBE = "RSVP_MAYBE",
}

export type ConflictCluster = {
  __typename: "ConflictCluster";
  /** The events involved in the conflict */
  conflictEvents: Array<ConflictEvent>;
  id: Scalars["ID"];
  /** Whether the conflict cluster is active and visible in the UI */
  isActiveConflict: Scalars["Boolean"];
  /** The time range for the conflict cluster */
  timeBound: Scalars["Interval"];
};

export type ConflictEvent = {
  __typename: "ConflictEvent";
  /** The actions available to the current user to resolve the conflict */
  availableActions: Array<ConflictAction>;
  /** The external event id of the event */
  eventId: Scalars["String"];
  /** The ID of an object */
  id: Scalars["ID"];
  /** Whether the event is flexible */
  isFlexible: Scalars["Boolean"];
  /** The organizer's calendar id */
  organizerCalId: Scalars["String"];
  /** The title of the event */
  title: Scalars["String"];
};

export type ConflictEventNewTimes = {
  __typename: "ConflictEventNewTimes";
  time: Scalars["Interval"];
};

export type ConflictReceipt = {
  __typename: "ConflictReceipt";
  /** A receipt of the actions taken to resolve users conflicts */
  receipt?: Maybe<Scalars["String"]>;
};

export type ConsequencesBlock = {
  __typename: "ConsequencesBlock";
  changesText?: Maybe<Scalars["String"]>;
  otherCalDiffs?: Maybe<Array<OthersConsequenceDiffSummary>>;
  yourCalDiffs?: Maybe<Array<YourConsequenceDiffSummary>>;
};

export enum ContainerType {
  Multi = "Multi",
  Single = "Single",
}

/** a calendar that another calendar's events are synced to */
export type CrawlTarget = {
  __typename: "CrawlTarget";
  /** The target calendarId */
  calendarId?: Maybe<Scalars["String"]>;
  /** The target emailAddress */
  emailAddress?: Maybe<Scalars["String"]>;
};

/** For a given org, which crawl states calendar Ids fall into */
export type CrawledCalendarInfo = Node & {
  __typename: "CrawledCalendarInfo";
  /** The ID of an object */
  id: Scalars["ID"];
  /** Calendar Ids that have crawl status of 'None' */
  none?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Calendar Ids that have crawl status of 'Pending' */
  pending?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Calendar Ids that have crawl status of 'Stale' */
  stale?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Calendar Ids that have crawl status of 'Synced' */
  synced?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export enum CrawledStatus {
  NoAccess = "NoAccess",
  None = "None",
  Pending = "Pending",
  Stale = "Stale",
  Synced = "Synced",
  VeryStale = "VeryStale",
}

export type CreateDataSourceMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  googleOfflineCode: Scalars["String"];
  isPersonal?: InputMaybe<Scalars["Boolean"]>;
  /** The front-end ID of the organization the work calendar is part of */
  orgRelayId: Scalars["String"];
};

export type CreateDataSourceMutationPayload = {
  __typename: "CreateDataSourceMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The new data source that was just created */
  dataSource?: Maybe<DataSource>;
  dataSourceAuthError?: Maybe<DataSourceAuthError>;
  user?: Maybe<User>;
};

export type CreateOrgSubscriptionStripeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  initialUsers: Array<Scalars["String"]>;
  numSeats?: InputMaybe<Scalars["Int"]>;
  orgRelayId: Scalars["ID"];
  promoCode?: InputMaybe<Scalars["String"]>;
  recurrence: CreateOrgSubscriptionStripeRecurrenceEnum;
  stripePaymentMethodId: Scalars["String"];
};

export type CreateOrgSubscriptionStripePayload = {
  __typename: "CreateOrgSubscriptionStripePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  stripeError?: Maybe<StripeError>;
};

export enum CreateOrgSubscriptionStripeRecurrenceEnum {
  Every2Years = "Every2Years",
  Every3Years = "Every3Years",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  SemiAnnual = "SemiAnnual",
  Yearly = "Yearly",
}

export type CreateTeamAndTeamSubscriptionStripeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["ID"];
  personId: Scalars["ID"];
  promoCode?: InputMaybe<Scalars["String"]>;
  seatCount: Scalars["Int"];
  stripePaymentMethodId: Scalars["String"];
  subscriptionInterval: SubscriptionInterval;
  userEmail: Scalars["String"];
};

export type CreateTeamAndTeamSubscriptionStripePayload = {
  __typename: "CreateTeamAndTeamSubscriptionStripePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  stripeError?: Maybe<StripeError>;
  team: Team;
};

export type CreateTeamSubscriptionStripeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["ID"];
  promoCode?: InputMaybe<Scalars["String"]>;
  seatCount: Scalars["Int"];
  stripePaymentMethodId: Scalars["String"];
  subscriptionInterval: SubscriptionInterval;
  teamId: Scalars["String"];
};

export type CreateTeamSubscriptionStripePayload = {
  __typename: "CreateTeamSubscriptionStripePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  stripeError?: Maybe<StripeError>;
  team: Team;
};

export enum CreateTestSubscriptionPaidProductTypeEnum {
  Business_NonStandard = "Business_NonStandard",
  Business_Standard = "Business_Standard",
  Enterprise_NonStandard = "Enterprise_NonStandard",
  Enterprise_Standard = "Enterprise_Standard",
  Pro_NonStandard = "Pro_NonStandard",
  Pro_Standard = "Pro_Standard",
}

export type CreateTestSubscriptionStripeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  initialUsers: Array<Scalars["String"]>;
  orgRelayId: Scalars["ID"];
  paidProductType: CreateTestSubscriptionPaidProductTypeEnum;
};

export type CreateTestSubscriptionStripePayload = {
  __typename: "CreateTestSubscriptionStripePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  stripeError?: Maybe<StripeError>;
};

export type CreateWorkingLocationMutationInput = {
  address: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  locationType: LocationTypeEnum;
  name: Scalars["String"];
  timeZone: Scalars["String"];
};

export type CreateWorkingLocationMutationPayload = {
  __typename: "CreateWorkingLocationMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DailyMaxSettings = {
  __typename: "DailyMaxSettings";
  enabled: Scalars["Boolean"];
  maxAmount: Scalars["Duration"];
};

/** A data source */
export type DataSource = Node & {
  __typename: "DataSource";
  /** List of calendarIds associated with this datasource */
  calendarIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The email associated with a data source */
  email?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  /** The name of the data source */
  name?: Maybe<Scalars["String"]>;
  /**
   * The provider of the data source
   * @deprecated use type enum instead
   */
  provider?: Maybe<Scalars["Int"]>;
  state?: Maybe<DataSourceStatus>;
  /**
   * The status of the data source
   * @deprecated use state enum instead
   */
  status?: Maybe<Scalars["Int"]>;
  /** other calendars this data-source is syncing to, if any */
  syncTargets?: Maybe<Array<Maybe<CrawlTarget>>>;
  type?: Maybe<DataSourceProvider>;
  /** The username associated with a data source */
  username?: Maybe<Scalars["String"]>;
};

export enum DataSourceAuthError {
  GenericError = "GenericError",
  InsufficientScopes = "InsufficientScopes",
  SameDomainError = "SameDomainError",
}

/** A connection to a list of items. */
export type DataSourceConnection = {
  __typename: "DataSourceConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DataSourceEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DataSourceEdge = {
  __typename: "DataSourceEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<DataSource>;
};

export enum DataSourceProvider {
  Google = "Google",
  Office365 = "Office365",
}

export enum DataSourceStatus {
  Okay = "Okay",
  RequiresReAuth = "RequiresReAuth",
}

/** DESCRIBE NEW FIELD HERE */
export type DateRange = {
  __typename: "DateRange";
  maxDate: Scalars["String"];
  minDate: Scalars["String"];
};

export type DateTimeRange = {
  __typename: "DateTimeRange";
  duration: Scalars["Duration"];
  end: Scalars["DateTime"];
  start: Scalars["DateTime"];
};

export enum Day {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export enum DayOfWeek {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export type DayOfWeekFlexibility = {
  __typename: "DayOfWeekFlexibility";
  allowedDays: Array<DayOfWeek>;
};

export type DayOfWeekFlexibleEvent = {
  /** The days that this event is allowed to move among */
  allowedDays: Array<DayOfWeek>;
  /** The backend event id */
  eventId: Scalars["String"];
};

export type DayOfWeekFlexibleEventNew = {
  /** The days that this event is allowed to move among */
  allowedDays: Array<DayOfWeek>;
  /** The backend event id */
  eventId: Scalars["EventId"];
};

/** DESCRIBE NEW FIELD HERE */
export type DayOffsetTimeSlot = {
  __typename: "DayOffsetTimeSlot";
  dayOffset: Scalars["Int"];
  timeSlot: TimeSlot;
};

export type DayOffsetTimeSlotInput = {
  dayOffset: Scalars["Int"];
  timeSlot: TimeSlot;
};

/** A range between two day offset time slots */
export type DayOffsetTimeSlotRange = {
  __typename: "DayOffsetTimeSlotRange";
  maxSlot: DayOffsetTimeSlot;
  minSlot: DayOffsetTimeSlot;
};

/** An override for a day */
export type DayOverride = {
  __typename: "DayOverride";
  date?: Maybe<Scalars["String"]>;
  setting?: Maybe<WorkingSetting>;
};

export type DayOverrideInput = {
  date?: InputMaybe<Scalars["String"]>;
  setting?: InputMaybe<WorkingSettingInput>;
};

export enum DayRangeFlexibilityType {
  Day = "Day",
  /**
   *
   *                       Set the event flexibility to move within specific days of the week.
   *                       Requires setting the 'allowedDays' also in the mutation.
   *
   */
  DayOfWeek = "DayOfWeek",
  Week = "Week",
}

export type DayRangeFlexibilityUpdated = {
  __typename: "DayRangeFlexibilityUpdated";
  updatedEvents?: Maybe<Array<EventParent>>;
};

export type DayRangeFlexibilityUpdatedUpdatedEventsArgs = {
  calendarId?: InputMaybe<Scalars["CalendarId"]>;
};

/** A working setting for a day */
export type DaySetting = {
  __typename: "DaySetting";
  day?: Maybe<Day>;
  setting?: Maybe<WorkingSetting>;
};

export type DaySettingInput = {
  day?: InputMaybe<Day>;
  setting?: InputMaybe<WorkingSettingInput>;
};

export type DayTimeSlotInput = {
  date: Scalars["String"];
  timeSlot: TimeSlot;
};

export type DeactivateUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type DeactivateUserMutationPayload = {
  __typename: "DeactivateUserMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  viewer?: Maybe<Viewer>;
};

export enum DefragFeedback {
  LooksGood = "LooksGood",
  Maybe = "Maybe",
  Meh = "Meh",
}

/** Represents the time in which a defrag will run */
export type DefragTime = {
  __typename: "DefragTime";
  millis?: Maybe<Scalars["BigInt"]>;
};

export type DefragTimeErrorable = DefragTime | GraphEntityError;

export type DeleteDataSourceInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The relay ID of the organization the calendar is part of */
  orgRelayId: Scalars["String"];
  /** The ID of the calendar that is being deleted */
  sourceCalId: Scalars["String"];
};

export type DeleteDataSourcePayload = {
  __typename: "DeleteDataSourcePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteEventMutationInput = {
  calendarId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  emailAddress: Scalars["ID"];
  externalEventId: Scalars["String"];
  /** optional, when true will delete an entire recurrence */
  isRecurring?: InputMaybe<Scalars["Boolean"]>;
  /** Whether or not to delete future events, just this instance or all instances */
  repeatingDeleteOption?: InputMaybe<RepeatingEventSaveOption>;
};

export type DeleteEventMutationPayload = {
  __typename: "DeleteEventMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteUserLevelZoomAccountMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
};

export type DeleteUserLevelZoomAccountMutationPayload = {
  __typename: "DeleteUserLevelZoomAccountMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteWorkingLocationMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  workingLocationId: Scalars["String"];
};

export type DeleteWorkingLocationMutationPayload = {
  __typename: "DeleteWorkingLocationMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteZoomAccountMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  zoomAccountId: Scalars["String"];
};

export type DeleteZoomAccountMutationPayload = {
  __typename: "DeleteZoomAccountMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedZoomAccountId: Scalars["String"];
};

export type DeviceIdAndRegistrationToken = {
  /** A unique id that identifies the calling device */
  deviceId: Scalars["String"];
  /** The registration token of the device that allows sending push notifications */
  registrationToken: Scalars["String"];
};

/** The update being made to the diff, e.g. ADD, RESCHEDULE, CANCEL, RSVP, UNCHANGED, EDIT_DETAILS along with a label */
export type DiffAction = {
  __typename: "DiffAction";
  label: Scalars["String"];
  /** The updated RSVP status of the user when the action is of type RSVP, null otherwise */
  rsvp?: Maybe<ResponseStatusEnum>;
  type: DiffActionTypeEnum;
};

export enum DiffActionTypeEnum {
  ADD = "ADD",
  CANCEL = "CANCEL",
  EDIT_DETAILS = "EDIT_DETAILS",
  PROPOSE_NEW_TIME = "PROPOSE_NEW_TIME",
  RESCHEDULE = "RESCHEDULE",
  RSVP = "RSVP",
  UNCHANGED = "UNCHANGED",
}

/** Text describing the proposed changes to a user's calendar along with a list of diffs representing the changes */
export type DiffBlock = AddDiffBlock | ModifyDiffBlock;

/** Details about a diff we are proposing to a user's calendar */
export type DiffDetails = {
  actionType: DiffActionTypeEnum;
  attendees: ProposalAttendees;
  attendeesOmitted: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  descriptionOmitted: Scalars["Boolean"];
  id: Scalars["ID"];
  isFlexible: Scalars["Boolean"];
  location?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  videoConferencingType?: Maybe<ConferencingType>;
};

/** A diff summarizing the change we are proposing to a user's calendar */
export type DiffSummary = {
  action: DiffAction;
  active: Scalars["Boolean"];
  id: Scalars["ID"];
  title: Scalars["String"];
};

export enum DigestTimeSlot {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
}

export type DisableDataSourceSyncInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The relay ID of the organization the calendar is part of */
  orgRelayId: Scalars["String"];
  /** The ID of the calendar that is being disabled */
  sourceCalId: Scalars["String"];
};

export type DisableDataSourceSyncPayload = {
  __typename: "DisableDataSourceSyncPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  dataSource?: Maybe<DataSource>;
};

export type DisambiguateAttendeeInput = {
  attendeeString: Scalars["String"];
  /** The calendar id of the selected attendee */
  calendarId: Scalars["CalendarId"];
  diffId: Scalars["String"];
};

export type DisambiguateProposalAttendeePayload = {
  __typename: "DisambiguateProposalAttendeePayload";
  message: AssistantMessage;
};

/** Given a billing group, is SSO Dsync configured */
export type DsyncConfigured = {
  __typename: "DsyncConfigured";
  isDsyncConfigured: Scalars["Boolean"];
};

export type DsyncConfiguredErrorable = DsyncConfigured | GraphEntityError;

export enum EcosystemEnum {
  Google = "Google",
  Microsoft = "Microsoft",
  NotApplicable = "NotApplicable",
}

export type EditExistingEventInput = {
  attendees?: InputMaybe<Array<AttendeesForEditEvent>>;
  category?: InputMaybe<TagInput>;
  /** Type of conference for the event. Removed is selected if the users explicitly removed the conference */
  conferenceType?: InputMaybe<EventConferenceType>;
  /** Description of the event */
  description?: InputMaybe<Scalars["String"]>;
  /** The externaleventId of the event you are editing */
  externalEventId: Scalars["String"];
  flexUpdates?: InputMaybe<UpdateEventFlexibility>;
  /** Whether the event is an all day event */
  isAllDay?: InputMaybe<Scalars["Boolean"]>;
  /** Toggle whether the event is flexible or not */
  isFlexible?: InputMaybe<Scalars["Boolean"]>;
  /** Location of the event */
  location?: InputMaybe<Scalars["String"]>;
  /** The calendarId of the organizer */
  organizerCalendarId: Scalars["String"];
  recurrence?: InputMaybe<EventRecurrenceInput>;
  /** Whether or not to save future events, just this instance or all instances */
  repeatingEventSaveOption: RepeatingEventSaveOption;
  /** Invite response status of attendee */
  responseStatus?: InputMaybe<ResponseStatusEnum>;
  /** Mark as true if you want others to be notified of their event changing */
  sendNotifications: Scalars["Boolean"];
  /** Interval of Event (start/end time) */
  time?: InputMaybe<Scalars["Interval"]>;
  /** Title of the event */
  title?: InputMaybe<Scalars["String"]>;
};

export type EditExistingEventPayload = {
  __typename: "EditExistingEventPayload";
  updatedEventParent?: Maybe<EventParent>;
};

export type EnableDataSourceSyncInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The front-end ID of the organization the work calendar is part of */
  orgRelayId: Scalars["String"];
  /** The ID of the calendar that is being synced to the work calendar */
  sourceCalId: Scalars["String"];
};

export type EnableDataSourceSyncPayload = {
  __typename: "EnableDataSourceSyncPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  dataSource?: Maybe<DataSource>;
};

export type EnableOrDisableTeamSyncForEventInput = {
  calendarId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  disable: Scalars["Boolean"];
  externalEventId: Scalars["String"];
  orgRelayId: Scalars["ID"];
};

export type EnableOrDisableTeamSyncForEventPayload = {
  __typename: "EnableOrDisableTeamSyncForEventPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  disabled: Scalars["Boolean"];
};

export enum EnforcementStateEnum {
  ApproachingLimit = "ApproachingLimit",
  NoEnforcement = "NoEnforcement",
  ReachedLimit = "ReachedLimit",
}

/** An event on a calendar */
export type Event = {
  __typename: "Event";
  /** The annotations for this event */
  annotatedEvent?: Maybe<AnnotatedEvent>;
  /**
   * The annotations for the recurring version of this event
   * @deprecated Use annotatedEvent
   */
  annotatedRecurringEvent?: Maybe<AnnotatedEvent>;
  attendeePeople: PersonListErrorable;
  /** The attendees that are associated with this event */
  attendees: Array<Attendee>;
  /** In some cases when the event has many attendees, this flag will be set and the set of attendees will be filtered to just the queried calendar */
  attendeesOmitted?: Maybe<Scalars["Boolean"]>;
  /** For autopilot candidates, the status of the current autopilot */
  autopilotEventStatus?: Maybe<AutopilotEventStatusErrorable>;
  /** A list of calendar ids that the event is associated with */
  calendarIds: Array<Scalars["String"]>;
  /** When the event was created, in millis */
  createdMillis?: Maybe<Scalars["BigInt"]>;
  /** Attendee that created the event */
  creator?: Maybe<Attendee>;
  /** A description of the event */
  description?: Maybe<Scalars["String"]>;
  /** Indicates the description has been omitted */
  descriptionOmitted?: Maybe<Scalars["Boolean"]>;
  /** EventTime object representing the end time of the event */
  endTime: EventTime;
  /** Not sure what this is... */
  etag?: Maybe<Scalars["String"]>;
  /** A calendar event's autopilot history */
  eventAutopilotHistory?: Maybe<EventAutopilotHistoryErrorable>;
  /**
   * Contains all flex permutations of the event flexibility string
   * @deprecated Use EventFlexibility.description instead
   */
  eventFlexibilityErrorable: EventFlexibilityNewListErrorable;
  /** The unique event key for this event */
  eventKey: EventKey;
  eventParentBaseId?: Maybe<Scalars["String"]>;
  eventParentBaseTimestamp?: Maybe<Scalars["BigInt"]>;
  /** The id of the event parent that contains this event as a version */
  eventParentRelayId: Scalars["String"];
  /** Edit permissions for the event and current user */
  eventPermissions: EventPermissions;
  /** The feature grid feature states for the organizer of this event */
  featureStatesForOrganizer?: Maybe<FeatureStatesErrorable>;
  /** Indicates that attendees are allowed to invite others */
  guestsCanInviteOthers?: Maybe<Scalars["Boolean"]>;
  /** Indicates that attendees can modify the event */
  guestsCanModify?: Maybe<Scalars["Boolean"]>;
  /** Indicates that attendees can see other attendees */
  guestsCanSeeOtherGuests?: Maybe<Scalars["Boolean"]>;
  /** A Google Hangout link for the event */
  hangoutLink?: Maybe<Scalars["String"]>;
  /** A link associated with the event */
  htmlLink?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Indicates that this is an all-day event */
  isAllDay?: Maybe<Scalars["Boolean"]>;
  /** Represents when an event is in the canceled state (e.g. the organizer deleted it, so its canceled on your calendar) */
  isCancelled?: Maybe<Scalars["Boolean"]>;
  /** Is this a Clockwise generated event (eg. Smart Hold) */
  isClockwiseEvent: Scalars["Boolean"];
  /** Is this a Hold event with no attendees */
  isHold: Scalars["Boolean"];
  /** The location of the event */
  location?: Maybe<Scalars["String"]>;
  /** Indicates that this event is locked */
  locked?: Maybe<Scalars["Boolean"]>;
  moveRangeInLocalTimeV3: MoveRangeInLocalTimeV3Errorable;
  /** The attendee who organized the event */
  organizer?: Maybe<Attendee>;
  /** Additionally resolve the OrgPerson for the organizer */
  organizerOrgPerson?: Maybe<OrgPersonErrorable>;
  /**
   * Additionally resolve the OrgPerson for the organizer
   * @deprecated Replacing with Organizer Org Person
   */
  organizerPerson?: Maybe<PersonErrorable>;
  /** Whether or not this event is paused */
  paused: Scalars["Boolean"];
  /** Indicates that this is a private copy...? */
  privateCopy?: Maybe<Scalars["Boolean"]>;
  /**
   * The recurrence for the event, more formatted for editing and display
   * @deprecated Use recurrenceRule instead
   */
  recurrence: EventRecurrence;
  /** The recurrence rule for the event */
  recurrenceRule?: Maybe<Scalars["RecurrenceRule"]>;
  /**
   * The recurrence rules for the event
   * @deprecated Use recurrenceRule instead
   */
  recurrenceRules?: Maybe<RecurrenceRules>;
  /** The recurring id for the event, if it recurs. */
  recurringEventId?: Maybe<Scalars["String"]>;
  scrubbedEvent: Scalars["Boolean"];
  /** EventTime object representing the start time of the event */
  startTime: EventTime;
  suggestedAutopilotSettingsResponseV3: SuggestedAutopilotSettingsResponseV3Errorable;
  /** Description of the event time & recurrence, ignoring flexibility. Null if unable to compute */
  timeDescription?: Maybe<Scalars["String"]>;
  /** The name of the event */
  title?: Maybe<Scalars["String"]>;
  /** Can this time be scheduled over? (None = 0; Opaque = 1; Tentative = 2; Transparent = 3) */
  transparency?: Maybe<TransparencyEnum>;
  /** When the event was updated, in millis */
  updatedMillis?: Maybe<Scalars["BigInt"]>;
  /** The conferencing link for the event */
  videoLink?: Maybe<VideoLink>;
  visibility?: Maybe<VisibilityEnum>;
};

/** An event on a calendar */
export type EventAutopilotEventStatusArgs = {
  calendarId?: InputMaybe<Scalars["String"]>;
};

export type EventAttendee = {
  __typename: "EventAttendee";
  email: Scalars["EmailAddress"];
  person?: Maybe<Person>;
  response: AttendeeResponse;
};

/** An event's AP history */
export type EventAutopilotHistory = {
  __typename: "EventAutopilotHistory";
  /** AP history logs including next defrag run info */
  actions: Array<AutopilotHistoryActionLog>;
  /** AP history status: On/Off/Incomplete/Not Visible */
  status: StatusEnum;
};

export type EventAutopilotHistoryErrorable = EventAutopilotHistory | GraphEntityError;

/** Color settings for a given event category */
export type EventCategoryColoring = {
  __typename: "EventCategoryColoring";
  active: Scalars["Boolean"];
  eventCategory: Scalars["String"];
  googleColorKey?: Maybe<Scalars["String"]>;
  isUserSet: Scalars["Boolean"];
};

export type EventCategoryColoringInput = {
  active: Scalars["Boolean"];
  eventCategory: Scalars["String"];
  googleColorKey?: InputMaybe<Scalars["String"]>;
  isUserSet: Scalars["Boolean"];
};

/** A User's settings for how their events should be colored */
export type EventColoringSettings = Node & {
  __typename: "EventColoringSettings";
  /** A user's collection of event-category coloring preferences */
  eventCategoryColorings?: Maybe<Array<EventCategoryColoring>>;
  /** The ID of an object */
  id: Scalars["ID"];
};

export type EventColoringSettingsErrorable = EventColoringSettings | GraphEntityError;

export type EventColoringSettingsMutationsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  eventCategoryColorings: Array<InputMaybe<EventCategoryColoringInput>>;
  /** The front-end ID for the organization we're saving event coloring settings for */
  orgRelayId: Scalars["String"];
};

export type EventColoringSettingsMutationsPayload = {
  __typename: "EventColoringSettingsMutationsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventColoringSettings?: Maybe<EventColoringSettings>;
};

export enum EventConferenceType {
  Meet = "Meet",
  Removed = "Removed",
  TeamsForBusiness = "TeamsForBusiness",
  Zoom = "Zoom",
}

export type EventCreator = {
  __typename: "EventCreator";
  email: Scalars["EmailAddress"];
  person?: Maybe<Person>;
};

export type EventErrorable = Event | GraphEntityError;

/** Flexibility for a given event */
export type EventFlexibility = {
  __typename: "EventFlexibility";
  /** String describing the events flexibility. Null if unable to fetch */
  description?: Maybe<Scalars["String"]>;
  /** Whether the event can change rooms, null if the event has no room */
  isRoomFlexible?: Maybe<Scalars["Boolean"]>;
  /** When this event will next be considered for movement */
  movableAt?: Maybe<EventMovement>;
  movableUntil?: Maybe<MovableUntil>;
  /** How an event can move within a day, null if not configured */
  timeOfDayFlexibility?: Maybe<TimeOfDayFlexibility>;
  /** How an event can move between days */
  timeRangeFlexibility: TimeRangeFlexibility;
};

export type EventFlexibilityErrorable = EventFlexibility | GraphEntityError;

/** An event flex and corresponding flex string */
export type EventFlexibilityNew = {
  __typename: "EventFlexibilityNew";
  flex: Scalars["String"];
  flexString: Scalars["String"];
};

export type EventFlexibilityNewList = {
  __typename: "EventFlexibilityNewList";
  list: Array<EventFlexibilityNew>;
};

export type EventFlexibilityNewListErrorable = EventFlexibilityNewList | GraphEntityError;

/** A query object for fetching events */
export type EventKey = Node & {
  __typename: "EventKey";
  /** The unique event key for an event */
  calendarIds?: Maybe<Array<Scalars["String"]>>;
  /** The non unique event id */
  externalEventId: Scalars["String"];
  /** A hash of all the calendarIds deduped and sorted */
  hashedCalendars?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  orgId?: Maybe<Scalars["String"]>;
};

export type EventList = {
  __typename: "EventList";
  list: Array<Event>;
};

export type EventListErrorable = EventList | GraphEntityError;

export type EventMention = {
  externalEventId: Scalars["String"];
  /** ISO string from startTime.dateOrDateTime */
  startTime: Scalars["String"];
  title: Scalars["String"];
};

export type EventMovement = AnyTime | OrgDefragTime;

export type EventOrganizer = {
  __typename: "EventOrganizer";
  calendar: Scalars["CalendarId"];
};

/** An object that holds all event versions for a given externalEventId */
export type EventParent = Node & {
  __typename: "EventParent";
  /** All calendarIds found on any version of this event */
  calendarIds: Array<Scalars["String"]>;
  eventParentBaseId?: Maybe<Scalars["String"]>;
  eventParentBaseTimestamp?: Maybe<Scalars["BigInt"]>;
  /**
   * The services parentEventId shared by event parents referring to the same recurrence
   * @deprecated EventParentWrapper shouldn't be used
   */
  eventParentWrapperId: Scalars["String"];
  /** All active versions of an event for an externalEventId */
  events: Array<Event>;
  /** The externalEventId shared by all versions, used in the id as well */
  externalEventId: Scalars["String"];
  /** All calendarIds used to fetch this EventParent.  An empty array represents all calendarIds in a working group */
  fetchedWithCalendarIds?: Maybe<Array<Scalars["String"]>>;
  /** Event flexibility, if this event is flexible */
  flexibility?: Maybe<EventFlexibilityErrorable>;
  /** Constraints around setting flexibility, for the given event */
  flexibilityConstraints: FlexibilityConstraintsErrorable;
  /** The ID of an object */
  id: Scalars["ID"];
  /** Given a specific calendarId, smart hold information for the event; null if the event is not a smart hold */
  smartHold?: Maybe<SmartHold>;
  /** Smart hold information for the event matching any calendarId on eventParent, null if the event cannot be found or is not a smart hold */
  smartHoldMatch?: Maybe<SmartHold>;
  /** For personal calendar synced events, the actual event from the personal calendar */
  syncedOriginEventErrorable?: Maybe<EventErrorable>;
};

/** An object that holds all event versions for a given externalEventId */
export type EventParentFlexibilityArgs = {
  calendarId: Scalars["CalendarId"];
};

/** An object that holds all event versions for a given externalEventId */
export type EventParentFlexibilityConstraintsArgs = {
  calendarId: Scalars["CalendarId"];
};

/** An object that holds all event versions for a given externalEventId */
export type EventParentSmartHoldArgs = {
  calendarId: Scalars["CalendarId"];
};

/** An object that holds all event versions for a given externalEventId */
export type EventParentSyncedOriginEventErrorableArgs = {
  calendarId?: InputMaybe<Scalars["String"]>;
};

/** A record indicating which version of an event is current */
export type EventParentBase = Node & {
  __typename: "EventParentBase";
  /** The ID of an object */
  id: Scalars["ID"];
  /** Ignore all event parents with a eventParentBaseTimestamp less than this */
  timestamp: Scalars["BigInt"];
};

/** Event parents and event parent bases for those event parents */
export type EventParentBaseWrapper = {
  __typename: "EventParentBaseWrapper";
  eventParentBases: Array<EventParentBase>;
  eventParents: Array<EventParent>;
};

/** Event parent bases and parents grouped by day */
export type EventParentBaseWrapperByWeek = {
  __typename: "EventParentBaseWrapperByWeek";
  eventParentBases: Array<EventParentBase>;
  eventParentsByDay: Array<Array<EventParent>>;
};

export type EventParentBaseWrapperByWeekList = {
  __typename: "EventParentBaseWrapperByWeekList";
  list: Array<EventParentBaseWrapperByWeek>;
};

export type EventParentBaseWrapperByWeekListErrorable =
  | EventParentBaseWrapperByWeekList
  | GraphEntityError;

/** Event parent bases and parents grouped by day */
export type EventParentBaseWrapperByWeekObject = {
  __typename: "EventParentBaseWrapperByWeekObject";
  eventParentBases: Array<EventParentBase>;
  eventParentsByDay: Array<EventParentsForDay>;
};

export type EventParentBaseWrapperByWeekObjectList = {
  __typename: "EventParentBaseWrapperByWeekObjectList";
  list: Array<EventParentBaseWrapperByWeekObject>;
};

export type EventParentBaseWrapperByWeekObjectListErrorable =
  | EventParentBaseWrapperByWeekObjectList
  | GraphEntityError;

export type EventParentBaseWrapperErrorable = EventParentBaseWrapper | GraphEntityError;

/** A connection to a list of items. */
export type EventParentConnection = {
  __typename: "EventParentConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EventParentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type EventParentEdge = {
  __typename: "EventParentEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<EventParent>;
};

export type EventParentErrorable = EventParent | GraphEntityError;

/** A top level entity that encompasses a group of evereturn eventParents */
export type EventParentWrapper = Node & {
  __typename: "EventParentWrapper";
  deleted: Scalars["Boolean"];
  eventParents: Array<EventParent>;
  /** The ID of an object */
  id: Scalars["ID"];
};

/** A connection to a list of items. */
export type EventParentWrapperConnection = {
  __typename: "EventParentWrapperConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EventParentWrapperEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type EventParentWrapperConnectionErrorable = EventParentWrapperConnection | GraphEntityError;

/** An edge in a connection. */
export type EventParentWrapperEdge = {
  __typename: "EventParentWrapperEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<EventParentWrapper>;
};

export type EventParentsForDay = {
  __typename: "EventParentsForDay";
  eventParents?: Maybe<Array<EventParent>>;
};

export enum EventPermissions {
  ALL = "ALL",
  INVITE = "INVITE",
  ISATTENDEE = "ISATTENDEE",
  MODIFY = "MODIFY",
  NONE = "NONE",
}

/** Recurrence rules for an event */
export type EventRecurrence = {
  __typename: "EventRecurrence";
  /** Interval of the event recurrence */
  intervalCount: Scalars["Int"];
  /** Type of the event recurrence */
  intervalType: RecurrenceIntervalTypeEnum;
  /** String representation of the recurrence */
  recurrenceString: Scalars["String"];
};

/** Modifications to recurrence rules for an event */
export type EventRecurrenceInput = {
  /**
   * string representing the day(s) the recurrence occurs on.
   *       MO,TU,WE,TH,FR indicates all workdays,
   *       2TU represents the second Tuesday of the month.
   */
  byDay?: InputMaybe<Scalars["String"]>;
  /** Datetime of startTime */
  eventStartTime: Scalars["DateTime"];
  /** Interval of the event recurrence */
  intervalCount?: InputMaybe<Scalars["Int"]>;
  /** Type of the event recurrence */
  intervalType?: InputMaybe<RecurrenceIntervalTypeEnum>;
  /** Recurrence rule for the event */
  recurrenceRule?: InputMaybe<Scalars["RecurrenceRule"]>;
  /** Standard IANA timezone string */
  timeZone: Scalars["String"];
};

export enum EventScheduledBy {
  /** The event was scheduled using Clockwise, and Clockwise should record the event as scheduled by the app */
  Clockwise = "Clockwise",
  Other = "Other",
}

export type EventSearchAttendeeFilter = {
  __typename: "EventSearchAttendeeFilter";
  modifier: AttendeeFilterModifierEnum;
  resolvedAttendees: Array<Person>;
  unresolvedAttendees: Array<Scalars["String"]>;
};

/** Facets used to search for events */
export type EventSearchBlock = {
  __typename: "EventSearchBlock";
  attendeeFilter?: Maybe<EventSearchAttendeeFilter>;
  closeMatches: Array<EventSearchCloseMatch>;
  /** DiffIds of diffs that were sourced from the search */
  diffIds: Array<Scalars["String"]>;
  eventIdFilters: Array<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  ordinalModifier: EventSearchOrdinalModifier;
  rsvpFilters: Array<RsvpFilterEnum>;
  /** Event category filter */
  tagFilters: Array<TagFilterEnum>;
  timeRangeFilter?: Maybe<EventSearchTimeRangeFilter>;
  titleFilter?: Maybe<EventSearchTitleFilter>;
};

/** Events that are close matches to the search */
export type EventSearchCloseMatch = {
  __typename: "EventSearchCloseMatch";
  externalEventId: Scalars["String"];
  title: Scalars["String"];
};

/** Ordinal filter for search */
export type EventSearchOrdinalModifier = {
  __typename: "EventSearchOrdinalModifier";
  modifier: OrdinalModifierEnum;
  ordinal?: Maybe<Scalars["Int"]>;
};

export type EventSearchTimeRangeFilter = {
  __typename: "EventSearchTimeRangeFilter";
  excluded: Array<Scalars["Interval"]>;
  included: Array<Scalars["Interval"]>;
};

export type EventSearchTitleFilter = {
  __typename: "EventSearchTitleFilter";
  modifier: TitleFilterModifierEnum;
  title: Scalars["String"];
};

/** A rich description of a time */
export type EventTime = {
  __typename: "EventTime";
  /** String representation of the date or datetime */
  dateOrDateTime: Scalars["String"];
  /** Time represented in milliseconds (DateTime is ) */
  millis: Scalars["Float"];
  /** Timezone offset in minutes */
  offsetMinutes?: Maybe<Scalars["Float"]>;
  /** Standard IANA timezone string */
  timeZone?: Maybe<Scalars["String"]>;
  /** The type of date */
  type: EventTimeType;
};

export type EventTimeInput = {
  dateOrDateTime: Scalars["String"];
  millis: Scalars["Float"];
  offsetMinutes?: InputMaybe<Scalars["Float"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
  type: EventTimeType;
};

export enum EventTimeType {
  Date = "Date",
  DateTime = "DateTime",
}

export enum ExcludedMeetingType {
  /**
   * Events with only one attendee (regardless of what the color category is set to)
   * @deprecated This is implicitly applied
   */
  Hold = "Hold",
  OneOnOne = "OneOnOne",
}

export enum ExcludedSlots {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
}

export type ExecuteCliCommandMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  command: Scalars["String"];
  options?: InputMaybe<Array<CliOptionInput>>;
};

export type ExecuteCliCommandMutationPayload = {
  __typename: "ExecuteCliCommandMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  result: Scalars["String"];
};

/** Exportable list of records for the analytics per user for the org */
export type ExportedCalendarAnalytics = {
  __typename: "ExportedCalendarAnalytics";
  data: CalendarAnalyticsData;
  user: CalendarAnalyticsUser;
};

export type ExportedCalendarAnalyticsList = {
  __typename: "ExportedCalendarAnalyticsList";
  list: Array<ExportedCalendarAnalytics>;
};

export type ExportedCalendarAnalyticsListErrorable =
  | ExportedCalendarAnalyticsList
  | GraphEntityError;

export type ExtendTrialInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  endDate: Scalars["String"];
};

export type ExtendTrialPayload = {
  __typename: "ExtendTrialPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  trialMetadata: TrialMetadata;
};

/** A group of people defined in an external system */
export type ExternalGroup = Node & {
  __typename: "ExternalGroup";
  email: Scalars["String"];
  /** The ID of an object */
  id: Scalars["ID"];
  memberEmails: Array<Scalars["String"]>;
  members: PersonListErrorable;
};

export type ExternalGroupList = {
  __typename: "ExternalGroupList";
  list: Array<ExternalGroup>;
};

export type ExternalGroupListErrorable = ExternalGroupList | GraphEntityError;

/** Metadata about an external group */
export type ExternalGroupSearchMetadata = {
  __typename: "ExternalGroupSearchMetadata";
  emailAddress: Scalars["String"];
  groupSize: Scalars["Int"];
};

/** Result of searching for external groups */
export type ExternalGroupSearchResult = {
  __typename: "ExternalGroupSearchResult";
  groups: Array<ExternalGroupSearchMetadata>;
  inputQuery: Scalars["String"];
};

export type ExternalGroupSearchResultErrorable = ExternalGroupSearchResult | GraphEntityError;

/** Result of searching for a room by name */
export type ExternalRoomSearchResult = {
  __typename: "ExternalRoomSearchResult";
  /** Whether the result of the query is cacheable (e.g. was there an error) */
  cacheable: Scalars["Boolean"];
  rooms: Array<Room>;
  searchQuery: Scalars["String"];
};

export type ExternalRoomSearchResultErrorable = ExternalRoomSearchResult | GraphEntityError;

/** DESCRIBE NEW FIELD HERE */
export type FeatureGrid = {
  __typename: "FeatureGrid";
  featureStates: FeatureStates;
  stateMetadata: PaymentStateMetadata;
};

export type FeatureGridErrorable = FeatureGrid | GraphEntityError;

/** DESCRIBE NEW FIELD HERE */
export type FeatureStates = {
  __typename: "FeatureStates";
  freePaidFeatures: FreePaidFeatures;
  meetingReliefSettingVisibility: MeetingReliefSettingVisibility;
  mixedFreemiumOrgUIState: MixedFreemiumOrgUiState;
  planTierFeatures: PlanTierFeatures;
  /** @deprecated use the entity within mixedFreemiumOrgUIState */
  quotaAutopilotStatusCallout: QuotaAutopilotStatusCalloutEnumType;
  /** @deprecated use the entity within mixedFreemiumOrgUIState */
  quotaStatusBanner: QuotaStatusBannerEnumType;
};

export type FeatureStatesErrorable = FeatureStates | GraphEntityError;

/** bucket o' booleans */
export type Flags = Node & {
  __typename: "Flags";
  flags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The ID of an object */
  id: Scalars["ID"];
};

export type FlagsErrorable = Flags | GraphEntityError;

export type FlexMeetingEnabled = {
  __typename: "FlexMeetingEnabled";
  flexMeetingEnabled?: Maybe<Scalars["Boolean"]>;
};

export type FlexMeetingEnabledErrorable = FlexMeetingEnabled | GraphEntityError;

/** Constraints for modifying event flexibilty */
export type FlexibilityConstraints = {
  __typename: "FlexibilityConstraints";
  /** Whether the user is allowed to modify the flexibility settings for this event. Null if unable to compute */
  allowedToModify?: Maybe<Scalars["Boolean"]>;
  /**
   *
   *               Days of the week that are available for all attendees of an event; the intersection of working days for attendees.
   *               Null if unable to compute.
   *
   */
  availableMeetingDays?: Maybe<Array<DayOfWeek>>;
  /** The intersection of meeting hours, on the day the event is currently scheduled. Null if there are no available hours */
  availableMeetingHours?: Maybe<AvailableMeetingHours>;
  /** Whether day of week flexibility is allowed by the user's plan */
  dayOfWeekFlexibilityAllowedByPlan?: Maybe<Scalars["Boolean"]>;
  /** Whether this event is eligible for day of week flexibility. Null if unable to compute */
  dayOfWeekFlexibilityEligible?: Maybe<Scalars["Boolean"]>;
  /** Whether this event is external, and is ineligible for flexibility. Null if unable to compute */
  externalEvent?: Maybe<Scalars["Boolean"]>;
  /** Whether time of day flexibility is allowed by the user's plan. Null if unable to compute */
  timeOfDayAllowedByPlan?: Maybe<Scalars["Boolean"]>;
};

export type FlexibilityConstraintsErrorable = FlexibilityConstraints | GraphEntityError;

export enum FlexibilityRange {
  /** The event can only move within its currently scheduled day */
  Day = "Day",
  /** The event can move anywhere within its currently scheduled week */
  Week = "Week",
}

export type FlexibilityUpdated = {
  __typename: "FlexibilityUpdated";
  updatedEvents?: Maybe<Array<EventParent>>;
};

export type FlexibilityUpdatedUpdatedEventsArgs = {
  calendarId?: InputMaybe<Scalars["CalendarId"]>;
};

export type FlexibleEvent = {
  __typename: "FlexibleEvent";
  event: CalendarEvent;
  flexibility: EventFlexibility;
};

/** Lists of flexible meetings for the flexible meeting dashboard */
export type FlexibleMeetingsDashboard = {
  __typename: "FlexibleMeetingsDashboard";
  /** The ID of an object */
  id: Scalars["ID"];
  myFlexibleHolds: Array<FlexibleEvent>;
  myFlexibleMeetings: Array<FlexibleEvent>;
  myOneOffHolds: Array<CalendarEvent>;
  /** The amount of time I spend in 1/1s per week (on average) */
  myOneOnOneAverage: Scalars["Duration"];
  myOneOnOnes: Array<RecurringEvent>;
  myRecurringHolds: Array<RecurringEvent>;
  oneOnOnes: Array<Event>;
  recurringMeetings: Array<Event>;
  suggestedFlexibleMeetings: Array<CalendarEvent>;
};

/** Lists of flexible meetings for the flexible meeting dashboard */
export type FlexibleMeetingsDashboardMyFlexibleMeetingsArgs = {
  input?: FlexibleMeetingsInput;
};

export type FlexibleMeetingsInput = {
  exclude: Array<InputMaybe<ExcludedMeetingType>>;
};

export type FlexibleOrNot = EventFlexibility | NotFlexible;

/** A state for a flow defined by a flowKey for a given user/org */
export type FlowState = Node & {
  __typename: "FlowState";
  /** The current status of the flow */
  current?: Maybe<FlowStatus>;
  /** The key for looking up the state */
  flowKey?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  /** The pending/next status of the flow */
  pending?: Maybe<FlowStatus>;
  /** The previous state of the flow */
  previousState?: Maybe<Scalars["String"]>;
};

/** A connection to a list of items. */
export type FlowStateConnection = {
  __typename: "FlowStateConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FlowStateEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type FlowStateEdge = {
  __typename: "FlowStateEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<FlowState>;
};

export type FlowStateList = {
  __typename: "FlowStateList";
  list: Array<FlowState>;
};

export type FlowStateListErrorable = FlowStateList | GraphEntityError;

/** A state for a flow defined by a flowKey for a given user/org */
export type FlowStatus = Node & {
  __typename: "FlowStatus";
  /** Any associated error with the current state */
  errorMessage?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  /** A percentage of completion for the state */
  percentComplete?: Maybe<Scalars["Float"]>;
  /** The current state of flow */
  state?: Maybe<Scalars["String"]>;
};

export type FocusTimeEnabled = {
  __typename: "FocusTimeEnabled";
  focusTimeEnabled?: Maybe<Scalars["Boolean"]>;
};

export type FocusTimeEnabledErrorable = FocusTimeEnabled | GraphEntityError;

export enum FocusTimeExternalTransparency {
  None = "None",
  Opaque = "Opaque",
  Transparent = "Transparent",
}

export type FocusTimeGoals = {
  __typename: "FocusTimeGoals";
  autoDeclineSettings?: Maybe<AutoDeclineSettings>;
  dailyMaxSettings?: Maybe<DailyMaxSettings>;
  enabled?: Maybe<Scalars["Boolean"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  preferredFocusIn?: Maybe<PreferredFocusTime>;
  /** @deprecated use `preferredFocusIn` instead */
  preferredFocusTime?: Maybe<PreferredFocusTime>;
  remoteHoldSettings?: Maybe<RemoteSmartHoldSettings>;
  threshold?: Maybe<FocusTimeThreshold>;
};

export type FocusTimeHold = {
  __typename: "FocusTimeHold";
  /** null if the hold doesn't belong to the user */
  goals?: Maybe<FocusTimeGoals>;
  state?: Maybe<SmartHoldState>;
  times: DateTimeRange;
};

/** Average focus time stats (for use in Focus Time Sync) */
export type FocusTimeStats = {
  __typename: "FocusTimeStats";
  minutesPerWeek?: Maybe<Scalars["Int"]>;
};

export type FocusTimeStatsErrorable = FocusTimeStats | GraphEntityError;

export type FocusTimeThreshold = AllFocusTime | SomeFocusTime;

/** Availability of features based on user's monetization state */
export type FreePaidFeatures = {
  __typename: "FreePaidFeatures";
  autopilotWhenOrganizerCurrentlyAllowed: Scalars["Boolean"];
  eligibleForNoMeetingDay: Scalars["Boolean"];
  eligibleForTeamCalendarVisibility: Scalars["Boolean"];
  focusTimeAllowed: Scalars["Boolean"];
  moveRangesEnabled: Scalars["Boolean"];
};

/** A calendar's goal */
export type Goal = {
  __typename: "Goal";
  /** The id of the goal, used internally by the services */
  goalId: Scalars["String"];
  /** The ID of an object */
  id: Scalars["ID"];
  /** Whether this goal is deleted or not */
  isDeleted: Scalars["Boolean"];
  /** The name associated with this goal */
  name: Scalars["String"];
  /** Options associated with this goal */
  options?: Maybe<GoalOptions>;
  /** Additional ids that this goal is associated with */
  otherUrns: Array<Urn>;
  /** The id of this goal's target, generally a calendar id scoped to an org */
  targetUrn: Urn;
  /** The id associated with an object used to represent this goal, e.g. an event id if the goal is represented by an event */
  valueUrn?: Maybe<Urn>;
};

/** A connection to a list of items. */
export type GoalConnection = {
  __typename: "GoalConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GoalEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type GoalEdge = {
  __typename: "GoalEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<Goal>;
};

export type GoalInput = {
  goalId: Scalars["String"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  name: Scalars["String"];
  options?: InputMaybe<GoalOptionsInput>;
  otherUrns: Array<UrnInput>;
  targetUrn: UrnInput;
  valueUrn?: InputMaybe<UrnInput>;
};

/** Options for the preferred work option */
export type GoalMeetingReliefOptions = {
  __typename: "GoalMeetingReliefOptions";
  /** The number of consecutive minutes of meetings that are allowed before a gap is requested */
  fatigueThresholdMinutes: Scalars["Int"];
  /** The number of minutes placed between consecutive meetings when fatigue threshold met */
  meetingReliefBreakMinutes: Scalars["Int"];
};

/** Options describing a goal */
export type GoalOptions = {
  __typename: "GoalOptions";
  specificOptions?: Maybe<GoalSpecificOptions>;
  /** If this goal is saved on the user's google calendar */
  syncToGoogle: Scalars["Boolean"];
};

export type GoalOptionsInput = {
  syncToGoogle: Scalars["Boolean"];
};

export type GoalPatchFocusTimeOptionsInput = {
  maxMinutesPerBlock?: InputMaybe<Scalars["Int"]>;
  syncToGoogle?: InputMaybe<Scalars["Boolean"]>;
};

export type GoalPatchLunchOptionsInput = {
  idealMinutesDuration?: InputMaybe<Scalars["Int"]>;
  maxTimeSlot?: InputMaybe<TimeSlot>;
  minTimeSlot?: InputMaybe<TimeSlot>;
  minimumMinutesDuration?: InputMaybe<Scalars["Int"]>;
  syncToGoogle?: InputMaybe<Scalars["Boolean"]>;
};

export type GoalPatchMeetingReliefOptionsInput = {
  fatigueThresholdMinutes?: InputMaybe<Scalars["Int"]>;
  meetingReliefBreakMinutes?: InputMaybe<Scalars["Int"]>;
};

export type GoalPatchPreferredWorkOptionsInput = {
  keepEndOfDayOpen?: InputMaybe<Scalars["Boolean"]>;
  keepStartOfDayOpen?: InputMaybe<Scalars["Boolean"]>;
  preferredWorkType?: InputMaybe<PreferredWorkType>;
};

export type GoalPatchTravelTimeOptionsInput = {
  syncToGoogle?: InputMaybe<Scalars["Boolean"]>;
};

/** Options for the preferred work option */
export type GoalPreferredWorkOptions = {
  __typename: "GoalPreferredWorkOptions";
  /** Whether to keep the end of a user's day open, or to schedule meetings until the end */
  keepEndOfDayOpen?: Maybe<Scalars["Boolean"]>;
  /** Whether to keep the start of a user's day open, or to schedule meetings at the start */
  keepStartOfDayOpen?: Maybe<Scalars["Boolean"]>;
  /** When the user prefers to work */
  preferredWorkType: PreferredWorkType;
};

export type GoalSpecificOptions =
  | GoalMeetingReliefOptions
  | GoalPreferredWorkOptions
  | GoalWithinDayOptions
  | GoalWithinWeekOptions;

/** Options for goals that move within a day */
export type GoalWithinDayOptions = {
  __typename: "GoalWithinDayOptions";
  /** The ideal and usually maximum duration of the goal */
  idealDuration?: Maybe<Scalars["Int"]>;
  /** The maximum time slot in a day that the goal can be in */
  maxTimeSlot?: Maybe<TimeSlot>;
  /** The minimum time slot in a day that the goal can be in */
  minTimeSlot?: Maybe<TimeSlot>;
  /** The minimum duration of the goal */
  minimumDuration?: Maybe<Scalars["Int"]>;
};

/** Options for creating a goal that operates in the entire week context. e.g. focus time */
export type GoalWithinWeekOptions = {
  __typename: "GoalWithinWeekOptions";
  /** The maximum duration in minutes of each individual block, where a block is typically an event on the calendar */
  maxMinutesPerBlock: Scalars["Int"];
};

/** An error wrapper */
export type GraphEntityError = {
  __typename: "GraphEntityError";
  body?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  statusCode?: Maybe<Scalars["Int"]>;
};

export type GraphQlSelectSharedSchedulingOptionInput = {
  proposalId: Scalars["ID"];
  selectedIndex: Scalars["Int"];
};

export type HasInvitedTeammate = {
  __typename: "HasInvitedTeammate";
  hasInvitedTeammate?: Maybe<Scalars["Boolean"]>;
};

export type HasInvitedTeammateErrorable = GraphEntityError | HasInvitedTeammate;

export type HasSuggestedFlexMeetings = {
  __typename: "HasSuggestedFlexMeetings";
  hasSuggestedFlexMeetings?: Maybe<Scalars["Boolean"]>;
};

export type HasSuggestedFlexMeetingsErrorable = GraphEntityError | HasSuggestedFlexMeetings;

/** User analytics for home page */
export type HomeAnalytics = {
  __typename: "HomeAnalytics";
  currentWeek: HomeAnalyticsData;
  previousWeek: HomeAnalyticsData;
};

export type HomeAnalyticsData = {
  __typename: "HomeAnalyticsData";
  conflictsResolved: Scalars["Int"];
  focusTimeHeld: Scalars["Duration"];
  meetingTime: Scalars["Duration"];
};

export type HomeNotification = HomeNotificationResults | NoHomeNotification;

/** User home launch notification data. */
export type HomeNotificationData = {
  __typename: "HomeNotificationData";
  conflictsResolved: Scalars["Int"];
  focusHoursCreated: Scalars["Float"];
  potentialFlexMeetings: Scalars["Int"];
  potentialFocusHours: Scalars["Float"];
};

/** User home launch notification. */
export type HomeNotificationResults = {
  __typename: "HomeNotificationResults";
  data: HomeNotificationData;
  /** The ID of an object */
  id: Scalars["ID"];
  notificationType: Scalars["String"];
};

export type HubspotFormFieldInput = {
  key: Scalars["String"];
  value: Scalars["String"];
};

export enum Inconveniences {
  External = "External",
  MissedLunch = "MissedLunch",
  NoMeetingRelief = "NoMeetingRelief",
  OnNoMeetingDay = "OnNoMeetingDay",
  Other = "Other",
  OutsideMeetingHours = "OutsideMeetingHours",
  OutsideWorkingHours = "OutsideWorkingHours",
  Uncrawled = "Uncrawled",
}

export type InferredEventTimeInput = {
  date: Scalars["String"];
  time: Scalars["String"];
  timeZone?: InputMaybe<Scalars["String"]>;
};

export type InstallSlackAppMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  installSlackAppCode: Scalars["String"];
  redirect: Scalars["String"];
  useOAuthV2?: Scalars["Boolean"];
};

export type InstallSlackAppMutationPayload = {
  __typename: "InstallSlackAppMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  installSlackAppResult?: Maybe<Scalars["Boolean"]>;
  viewer?: Maybe<Viewer>;
};

/** Label and value pair */
export type IntegerDataPoint = {
  __typename: "IntegerDataPoint";
  label: Scalars["String"];
  value: Scalars["Int"];
};

/** The definition of the interval on which a recurrence recurs */
export type IntervalDefinition = {
  __typename: "IntervalDefinition";
  /** Which days the recurrence falls on — e.g., “MO,TU,FR” */
  byDay?: Maybe<Scalars["String"]>;
  /** Defines the poisition in the given frequence set that recurs; used for things like the first Monday of every month */
  bySetPos?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  /** How many times it recurs (null here AND for untilTime means it recurs forever) */
  count?: Maybe<Scalars["Int"]>;
  /** The frequency type of the interval */
  freqType: IntervalDefinitionFreqTypeEnum;
  /** The pacing of the interval — e.g., a bi-weekly interval is 2 */
  interval: Scalars["Int"];
  /** When the recurrence terminates (null here AND for count means it recurs forever) */
  untilTime?: Maybe<EventTime>;
};

export enum IntervalDefinitionFreqTypeEnum {
  Daily = "Daily",
  Monthly = "Monthly",
  Weekly = "Weekly",
  Yearly = "Yearly",
}

export type InvitePersonsToTeamInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  personIds: Array<Scalars["String"]>;
  teamId: Scalars["String"];
};

export type InvitePersonsToTeamPayload = {
  __typename: "InvitePersonsToTeamPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export enum InvitePolicyEnum {
  AdminOnly = "AdminOnly",
  AnyMember = "AnyMember",
}

export type InviteToBillingGroupMutationInput = {
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  personIds: Array<Scalars["String"]>;
};

export type InviteToBillingGroupMutationPayload = {
  __typename: "InviteToBillingGroupMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Attendees of a scheduling link */
export type LinkMember = {
  __typename: "LinkMember";
  calendarId: Scalars["String"];
  isRequired: Scalars["Boolean"];
  type: LinkMemberTypeEnum;
  userProfile: UserProfile;
};

export enum LinkMemberTypeEnum {
  Attendee = "Attendee",
  Owner = "Owner",
}

export enum LocationTypeEnum {
  HOME = "HOME",
  OFFICE = "OFFICE",
  OTHER = "OTHER",
}

export type LoginCredentialsUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  password: Scalars["String"];
};

export type LoginCredentialsUserMutationPayload = {
  __typename: "LoginCredentialsUserMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  loginStatus: LoginStatus;
  operation?: Maybe<RefreshTokenResultOperationEnum>;
  orgOperation?: Maybe<OrganizationOperationResultEnum>;
  viewer?: Maybe<Viewer>;
};

export type LoginMicrosoftUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  microsoftAuthCode: Scalars["String"];
  redirectUri: Scalars["String"];
};

export type LoginMicrosoftUserMutationPayload = {
  __typename: "LoginMicrosoftUserMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  loginStatus: LoginStatus;
  operation?: Maybe<RefreshTokenResultOperationEnum>;
  orgOperation?: Maybe<OrganizationOperationResultEnum>;
  verifyCodeMessage?: Maybe<Scalars["String"]>;
  verifyCodeResult?: Maybe<Scalars["Boolean"]>;
  viewer?: Maybe<Viewer>;
};

export enum LoginStatus {
  BlockedByAdmin = "BlockedByAdmin",
  Deactivating = "Deactivating",
  GoogleApiAccessDisabled = "GoogleApiAccessDisabled",
  GoogleContactSharingDisabled = "GoogleContactSharingDisabled",
  InsufficientScopes = "InsufficientScopes",
  NoCrawl = "NoCrawl",
  PersonalEmail = "PersonalEmail",
  SecondaryDomainUsed = "SecondaryDomainUsed",
  Success = "Success",
  WaitlistedEmail = "WaitlistedEmail",
}

export type LoginUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  deviceIdAndRegistrationToken?: InputMaybe<DeviceIdAndRegistrationToken>;
  googleOfflineCode: Scalars["String"];
  verificationType?: InputMaybe<VerificationType>;
};

export type LoginUserMutationPayload = {
  __typename: "LoginUserMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  loginStatus: LoginStatus;
  operation?: Maybe<RefreshTokenResultOperationEnum>;
  orgOperation?: Maybe<OrganizationOperationResultEnum>;
  verifyCodeMessage?: Maybe<Scalars["String"]>;
  verifyCodeResult?: Maybe<Scalars["Boolean"]>;
  viewer?: Maybe<Viewer>;
};

export type LogoutInsightsUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type LogoutInsightsUserPayload = {
  __typename: "LogoutInsightsUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type LogoutUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type LogoutUserMutationPayload = {
  __typename: "LogoutUserMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  viewer?: Maybe<Viewer>;
};

export type LunchEnabled = {
  __typename: "LunchEnabled";
  lunchEnabled?: Maybe<Scalars["Boolean"]>;
};

export type LunchEnabledErrorable = GraphEntityError | LunchEnabled;

export type LunchGoals = {
  __typename: "LunchGoals";
  enabled?: Maybe<Scalars["Boolean"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  preferredLunchTimes?: Maybe<PreferredLunchTimes>;
  remoteHoldSettings?: Maybe<RemoteSmartHoldSettings>;
};

export type LunchHold = {
  __typename: "LunchHold";
  /** null if the hold doesn't belong to the user */
  goals?: Maybe<LunchGoals>;
  state?: Maybe<SmartHoldState>;
  times: DateTimeRange;
};

export enum MaxSlot {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
}

export type MaybeOnboardOrgMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
};

export type MaybeOnboardOrgMutationPayload = {
  __typename: "MaybeOnboardOrgMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  crawlTest: OrgCrawlTest;
};

/** Meeting Hours for a given user id */
export type MeetingHoursQueryResult = {
  __typename: "MeetingHoursQueryResult";
  calendarMeetingHours?: Maybe<CalendarMeetingHoursConnection>;
  orgMeetingHours?: Maybe<OrgMeetingHours>;
  userMeetingHours?: Maybe<UserMeetingHoursConnection>;
};

/** Meeting Hours for a given user id */
export type MeetingHoursQueryResultCalendarMeetingHoursArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Meeting Hours for a given user id */
export type MeetingHoursQueryResultUserMeetingHoursArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MeetingReliefGoals = {
  __typename: "MeetingReliefGoals";
  enabled?: Maybe<Scalars["Boolean"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  meetingReliefThreshold?: Maybe<MeetingReliefThreshold>;
  remoteHoldSettings?: Maybe<RemoteSmartHoldSettings>;
};

export type MeetingReliefHold = {
  __typename: "MeetingReliefHold";
  /** null if the hold doesn't belong to the user */
  goals?: Maybe<MeetingReliefGoals>;
  state?: Maybe<SmartHoldState>;
  times: DateTimeRange;
};

/** No meeting relief suggestion exists */
export type MeetingReliefNoSuggestion = {
  __typename: "MeetingReliefNoSuggestion";
  void?: Maybe<Scalars["Void"]>;
};

export enum MeetingReliefSettingVisibility {
  DisabledByPaywall = "DisabledByPaywall",
  HideSetting = "HideSetting",
  ShowWithBadge = "ShowWithBadge",
  ShowWithoutBadge = "ShowWithoutBadge",
}

export type MeetingReliefSuggestion = MeetingReliefNoSuggestion | MeetingReliefSuggestionResults;

/** Notification of long meeting block to suggest meeting relief. Long meeting block will be within the current week. */
export type MeetingReliefSuggestionResults = {
  __typename: "MeetingReliefSuggestionResults";
  /** The ID of an object */
  id: Scalars["ID"];
  longMeetingBlock: DateTimeRange;
};

export type MeetingReliefThreshold = {
  __typename: "MeetingReliefThreshold";
  breakDuration: Scalars["Duration"];
  fatigueThreshold: Scalars["Duration"];
  /** @deprecated use `breakDuration` instead */
  meetingReliefBreak: Scalars["Duration"];
};

/** Meeting Settings */
export type MeetingSettings = {
  __typename: "MeetingSettings";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isActive: Scalars["Boolean"];
  linkMembers?: Maybe<Array<LinkMember>>;
  name: Scalars["String"];
  slug: Scalars["String"];
  timingSettings: TimingSettings;
};

export type MentionsV2 = {
  eventMentions: Array<InputMaybe<EventMention>>;
  personMentions: Array<PersonMention>;
};

export enum MinSlot {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
}

/** DESCRIBE NEW FIELD HERE */
export type MixedFreemiumOrgUiState = {
  __typename: "MixedFreemiumOrgUiState";
  autopilotPaused: Scalars["Boolean"];
  moveRangesEnabled: Scalars["Boolean"];
  paymentStatusBadge?: Maybe<PaymentStatusBadge>;
  quotaStatusBanner: QuotaStatusBannerEnum;
};

export enum ModifyAutopilotForEventMovementTypeEnum {
  Day = "Day",
  None = "None",
  Week = "Week",
}

export type ModifyDiffBlock = {
  __typename: "ModifyDiffBlock";
  diffs: Array<ModifyDiffSummary>;
  text: Scalars["String"];
};

/** The details of an event we are proposing to update on a user's calendar */
export type ModifyDiffDetails = DiffDetails & {
  __typename: "ModifyDiffDetails";
  /** The type of action being proposed for this diff */
  actionType: DiffActionTypeEnum;
  attendees: ProposalAttendees;
  /** Indicates that not all the attendees were returned */
  attendeesOmitted: Scalars["Boolean"];
  /** The existing start and end time of the event formatted as an ISO8601 interval */
  currentTime: Scalars["Interval"];
  description?: Maybe<Scalars["String"]>;
  /** Indicates the description has been omitted */
  descriptionOmitted: Scalars["Boolean"];
  /** The ID of the event being modified */
  eventId: Scalars["String"];
  /** Edit permissions for the event and current user */
  eventPermissions: EventPermissions;
  flexibility?: Maybe<ProposalFlexibility>;
  id: Scalars["ID"];
  isFlexible: Scalars["Boolean"];
  location?: Maybe<Scalars["String"]>;
  recurrence: EventRecurrence;
  recurrenceRule?: Maybe<Scalars["RecurrenceRule"]>;
  rsvp: ResponseStatusEnum;
  title: Scalars["String"];
  tradeoffBlocks: Array<TradeoffBlock>;
  /** The start and end time of the modified event formatted as an ISO8601 interval. Null if the event is being modified without a time change or there were no times found */
  updatedTime?: Maybe<Scalars["Interval"]>;
  /** The type of video conferencing link found in the event */
  videoConferencingType?: Maybe<ConferencingType>;
};

/** A summary of an event we are proposing to update on a user's calendar */
export type ModifyDiffSummary = DiffSummary & {
  __typename: "ModifyDiffSummary";
  action: DiffAction;
  /** False if the diff has been rejected by the user */
  active: Scalars["Boolean"];
  /** The list of attendees on the event after the proposed change as well as any ambiguities. Will include existing attendees plus any added ones, and minus any removed ones */
  attendees: ProposalAttendees;
  currentTime: Scalars["Interval"];
  /** The ID of the event being modified */
  eventId: Scalars["String"];
  id: Scalars["ID"];
  isFlexible: Scalars["Boolean"];
  rawCalendarScores: Array<RawCalendarScores>;
  recurrence: EventRecurrence;
  recurrenceRule?: Maybe<Scalars["RecurrenceRule"]>;
  resolvedAnyConflicts: Scalars["Boolean"];
  /** Either the existing title or the proposed new title */
  title: Scalars["String"];
  tradeoffBlocks: Array<TradeoffBlock>;
  /** Null if the diff's time is not being updated or there were no times found */
  updatedTime?: Maybe<Scalars["Interval"]>;
};

export enum MoonPhase {
  FirstQuarterMoon = "FirstQuarterMoon",
  FullMoon = "FullMoon",
  LastQuarterMoon = "LastQuarterMoon",
  NewMoon = "NewMoon",
  WaningCrescentMoon = "WaningCrescentMoon",
  WaningGibbousMoon = "WaningGibbousMoon",
  WaxingCrescentMoon = "WaxingCrescentMoon",
  WaxingGibbousMoon = "WaxingGibbousMoon",
}

/** When the event will no longer be eligible to move */
export type MovableUntil = {
  __typename: "MovableUntil";
  /** How soon before an event will it no longer be eligible to move */
  durationBefore?: Maybe<Scalars["Duration"]>;
};

/** A move range specified by two day offset time slots */
export type MoveRangeInLocalTimeV3 = {
  __typename: "MoveRangeInLocalTimeV3";
  allowedEndTimeInclusive?: Maybe<DayOffsetTimeSlot>;
  allowedStartTimeInclusive?: Maybe<DayOffsetTimeSlot>;
};

export type MoveRangeInLocalTimeV3Errorable = GraphEntityError | MoveRangeInLocalTimeV3;

export type MoveRangeInLocalTimeV3Input = {
  allowedEndTimeInclusive?: InputMaybe<DayOffsetTimeSlotInput>;
  allowedStartTimeInclusive?: InputMaybe<DayOffsetTimeSlotInput>;
};

export type Mutation = {
  __typename: "Mutation";
  addAdminForBillingGroup?: Maybe<AddAdminForBillingGroupMutationPayload>;
  addAndRemoveOrgTags?: Maybe<AddAndRemoveOrgTagsMutationPayload>;
  addManualMembersForBillingGroup?: Maybe<AddManualMembersForBillingGroupMutationPayload>;
  addOrgTags?: Maybe<AddOrgTagsMutationPayload>;
  addSlackAccount?: Maybe<AddSlackAccountMutationPayload>;
  addSlackAccountForTeam?: Maybe<AddSlackAccountForTeamMutationPayload>;
  addSlackAccountV2?: Maybe<AddSlackAccountV2MutationPayload>;
  addZoomAccount?: Maybe<AddZoomAccountMutationPayload>;
  batchAddAndRemoveOrgTags?: Maybe<BatchAddAndRemoveOrgTagsMutationPayload>;
  batchModifyAutopilotForEvents?: Maybe<BatchModifyAutopilotForEventsMutationPayload>;
  /**
   * Modifies events flexibilities, returns the event parents that have been updated
   * @deprecated Always and only returns event parents, use "setDayRangeFlexibility" instead
   */
  batchUpdateFlexibility?: Maybe<Array<EventParent>>;
  /** Confirms a chat proposal made by the assistant */
  cancelChatProposal?: Maybe<CancelChatProposalPayload>;
  /** Triggers cancelling of a modify event from the event diff */
  cancelEventDiffDetails?: Maybe<CancelEventDiffDetailsPayload>;
  /** Cancels an in flight processes message request from the user */
  cancelProcessMessage?: Maybe<CancelProcessMessagePayload>;
  /** Confirms a proposal by ID */
  cancelProposal?: Maybe<CancelSharedProposalPayload>;
  cancelTestSubscription?: Maybe<CancelTestSubscriptionsPayload>;
  clearUserData?: Maybe<ClearUserDataMutationPayload>;
  /** Confirms a chat proposal made by the assistant */
  confirmChatProposal?: Maybe<ConfirmChatProposalPayload>;
  /** Confirms a proposal by ID */
  confirmProposal?: Maybe<ConfirmSharedProposalPayload>;
  createDataSource?: Maybe<CreateDataSourceMutationPayload>;
  createOrgSubscriptionStripe?: Maybe<CreateOrgSubscriptionStripePayload>;
  createTeam?: Maybe<CreateTeamMutationPayload>;
  createTeamAndTeamSubscriptionStripe?: Maybe<CreateTeamAndTeamSubscriptionStripePayload>;
  createTeamCalendar?: Maybe<CreateTeamCalendarMutationPayload>;
  createTeamSubscriptionStripe?: Maybe<CreateTeamSubscriptionStripePayload>;
  createTestSubscriptionStripe?: Maybe<CreateTestSubscriptionStripePayload>;
  createWorkingLocation?: Maybe<CreateWorkingLocationMutationPayload>;
  deactivateUser?: Maybe<DeactivateUserMutationPayload>;
  deleteDataSource?: Maybe<DeleteDataSourcePayload>;
  deleteEvent?: Maybe<DeleteEventMutationPayload>;
  deleteTeam?: Maybe<DeleteTeamMutationPayload>;
  deleteUserLevelZoomAccount?: Maybe<DeleteUserLevelZoomAccountMutationPayload>;
  deleteWorkingLocation?: Maybe<DeleteWorkingLocationMutationPayload>;
  deleteZoomAccount?: Maybe<DeleteZoomAccountMutationPayload>;
  disableDataSourceSync?: Maybe<DisableDataSourceSyncPayload>;
  /** Disambiguates an attendee on an add or modify event diff */
  disambiguateProposalAttendee?: Maybe<DisambiguateProposalAttendeePayload>;
  editExistingEvent?: Maybe<EditExistingEventPayload>;
  enableDataSourceSync?: Maybe<EnableDataSourceSyncPayload>;
  enableOrDisableTeamSyncForEvent?: Maybe<EnableOrDisableTeamSyncForEventPayload>;
  executeCliCommand?: Maybe<ExecuteCliCommandMutationPayload>;
  extendTrial?: Maybe<ExtendTrialPayload>;
  installSlackApp?: Maybe<InstallSlackAppMutationPayload>;
  invitePersonsToTeam?: Maybe<InvitePersonsToTeamPayload>;
  inviteToBillingGroup?: Maybe<InviteToBillingGroupMutationPayload>;
  loginCredentialsUser?: Maybe<LoginCredentialsUserMutationPayload>;
  loginMicrosoftUser?: Maybe<LoginMicrosoftUserMutationPayload>;
  loginUser?: Maybe<LoginUserMutationPayload>;
  logoutInsightsUser?: Maybe<LogoutInsightsUserPayload>;
  logoutUser?: Maybe<LogoutUserMutationPayload>;
  maybeOnboardOrg?: Maybe<MaybeOnboardOrgMutationPayload>;
  overrideSyncStatusForUserEvent?: Maybe<OverrideSyncStatusForUserEventMutationPayload>;
  patchBillingGroup?: Maybe<PatchBillingGroupMutationPayload>;
  patchSlackSettings?: Maybe<PatchSlackSettingsMutationPayload>;
  pauseHold?: Maybe<PauseHoldPayload>;
  preAddOrgTags?: Maybe<PreAddOrgTagsMutationPayload>;
  /** Pre sets event flexibility, before the event has been created, based on a set of event properties */
  preSetEventFlexibility?: Maybe<PreSetFlexibilityResponse>;
  /** Processes a message from the user */
  processMessage?: Maybe<ProcessMessagePayload>;
  protectHold?: Maybe<ProtectedHoldPayload>;
  recordChromeInstall?: Maybe<RecordChromeInstallMutationPayload>;
  recordChromeUninstall?: Maybe<RecordChromeUninstallMutationPayload>;
  recordDefragResponse?: Maybe<RecordDefragResponseMutationPayload>;
  recordImpression?: Maybe<RecordImpressionMutationPayload>;
  registerEmailUpdates?: Maybe<RegisterEmailUpdatesPayload>;
  removeAdminForBillingGroup?: Maybe<RemoveAdminForBillingGroupMutationPayload>;
  /** Removes a diff from a scheduling proposal, marking it inactive */
  removeDiffFromProposal?: Maybe<RemoveDiffFromProposalPayload>;
  removeGoal?: Maybe<RemoveGoalMutationPayload>;
  removeLunchHold?: Maybe<RemoveLunchHoldMutationPayload>;
  removeManualMembersForBillingGroup?: Maybe<RemoveManualMembersForBillingGroupMutationPayload>;
  removeOrgTags?: Maybe<RemoveOrgTagsMutationPayload>;
  rescheduleEventParent?: Maybe<RescheduleEventParentMutationPayload>;
  /** Resets the activer conversation between the user and Clocky */
  resetConversation?: Maybe<ResetConversationPayload>;
  /** Modifies an event during the on demand conflict resolution */
  resolveConflict?: Maybe<ResolveConflictPayload>;
  /** Saves the details of an add event diff */
  saveAddEventDiffDetails?: Maybe<SaveAddEventDiffDetailsPayload>;
  /** Updates the flexibility  for a proposal */
  saveFlexStatus?: Maybe<SaveFlexStatusPayload>;
  /** Saves the details of a modify event diff */
  saveModifyEventDiffDetails?: Maybe<SaveModifyEventDiffDetailsPayload>;
  /** All the information that the application needs to send a push message: an endpoint and the encryption key needed for sending data. */
  savePushServiceSubscription?: Maybe<SavePushServiceSubscriptionMutationPayload>;
  /** Saves the details of an add event diff on a shared proposal */
  saveSharedProposalEventDiffDetails?: Maybe<SaveSharedProposalEventDiffDetailsPayload>;
  saveTeamSettings?: Maybe<SaveTeamSettingsMutationPayload>;
  saveTeamUserSettings?: Maybe<SaveTeamUserSettingsMutationPayload>;
  saveUninstallResponse?: Maybe<SaveUninstallResponseMutationPayload>;
  scheduleNewEventV2?: Maybe<ScheduleNewEventV2MutationPayload>;
  scheduleNewSmartHold?: Maybe<ScheduleNewSmartHoldMutationPayload>;
  /** Updates the scheduling option selection for an add or edit event diff */
  selectSchedulingOption?: Maybe<SelectSchedulingOptionPayload>;
  /** Selects a shared option for a proposal */
  selectSharedOption?: Maybe<SelectSharedOptionPayload>;
  sendBillingGroupRequestEmail?: Maybe<SendBillingGroupRequestEmailMutationPayload>;
  sendM365TeamAvailabilityCalendarInvite?: Maybe<SendM365TeamAvailabilityCalendarInviteMutationPayload>;
  sendM365TeamAvailabilityCalendarInvitesForTeam?: Maybe<SendM365TeamAvailabilityCalendarInvitesForTeamMutationPayload>;
  /** mutation that sends admin's request to change billing group seat quantity. */
  sendSeatQuantityChangeRequest?: Maybe<SendSeatQuantityChangeRequestMutationPayload>;
  /** Enables/Disables flexibility (infers specific flexibility setting) for these calendar events */
  setCalendarEventFlexibility?: Maybe<SetCalendarEventFlexibilityPayload>;
  /** Modifies events's day range flexibility */
  setDayRangeFlexibility?: Maybe<DayRangeFlexibilityUpdated>;
  /** Updates various event flexibility properties */
  setFlexibility?: Maybe<FlexibilityUpdated>;
  setPreferredRooms?: Maybe<SetPreferredRoomsMutationPayload>;
  setPrimaryTeam?: Maybe<SetPrimaryTeamMutationPayload>;
  /** Modifies the room flexibility for the supplied event(s) */
  setRoomFlexibility?: Maybe<RoomFlexibilityUpdated>;
  /** Modifies the time of day flexibility */
  setTimeOfDayFlexibility?: Maybe<TimeOfDayFlexibilityUpdated>;
  setWorkAddressForUser?: Maybe<SetWorkAddressForUserMutationPayload>;
  /** Mark a proposal as shared */
  shareProposal?: Maybe<ShareProposalPayload>;
  submitHubspotForm?: Maybe<SubmitHubspotFormMutationPayload>;
  sudoAddAdminToBillingGroup?: Maybe<SudoAddAdminToBillingGroupMutationPayload>;
  sudoAddMembersToBillingGroup?: Maybe<SudoAddMembersToBillingGroupMutationPayload>;
  sudoCreateBillingGroup?: Maybe<SudoCreateBillingGroupMutationPayload>;
  sudoDeactivateUser?: Maybe<SudoDeactivateUserMutationPayload>;
  sudoDeleteBillingGroup?: Maybe<SudoDeleteBillingGroupMutationPayload>;
  sudoDeleteEverything?: Maybe<SudoDeleteEverythingMutationPayload>;
  sudoEnableSSO?: Maybe<SudoEnableSsoMutationPayload>;
  sudoLinkSubscriptionToBillingGroup?: Maybe<SudoLinkSubscriptionToBillingGroupMutationPayload>;
  sudoModifyUser?: Maybe<SudoModifyUserMutationPayload>;
  sudoRemoveMembersFromBillingGroup?: Maybe<SudoRemoveMembersFromBillingGroupMutationPayload>;
  sudoResetFlowState?: Maybe<SudoResetFlowStateMutationPayload>;
  sudoSwitchUser?: Maybe<SudoSwitchUserMutationPayload>;
  sudoToggleAutoSyncForOrg?: Maybe<SudoToggleAutoSyncForOrgMutationPayload>;
  sudoTriggerClientUpdate?: Maybe<SudoTriggerClientUpdateMutationPayload>;
  sudoUnlinkSubscriptionFromBillingGroup?: Maybe<SudoUnlinkSubscriptionFromBillingGroupMutationPayload>;
  sudoUpdateBillingGroupName?: Maybe<SudoUpdateBillingGroupNameMutationPayload>;
  sudoUpdateInvitePolicy?: Maybe<SudoUpdateInvitePolicyMutationPayload>;
  /** Update setting for syncing meeting relief holds */
  syncMeetingRelief?: Maybe<SyncMeetingReliefPayload>;
  toggleAutoSyncForBillingGroup?: Maybe<ToggleAutoSyncForBillingGroupMutationPayload>;
  toggleFocusTime?: Maybe<ToggleFocusTimeGoalResponse>;
  toggleLunch?: Maybe<ToggleGoalResponse>;
  toggleMeetingRelief?: Maybe<ToggleMeetingReliefResponse>;
  toggleTravelTime?: Maybe<ToggleTravelTimeResponse>;
  triggerDefragRun?: Maybe<TriggerDefragRunMutationPayload>;
  /** triggers optimization notification for all users in the org */
  triggerOptimizationNotification?: Maybe<OptimizationNotificationTriggerResult>;
  unpauseEvent?: Maybe<UnpauseEventMutationPayload>;
  unpauseHold?: Maybe<UnpauseHoldPayload>;
  unprotectHold?: Maybe<UnprotectedHoldPayload>;
  unsubscribeEmail?: Maybe<UnsubscribeEmailPayload>;
  unsyncFocusTimeBlock?: Maybe<UnsyncFocusTimeBlockMutationPayload>;
  unsyncFocusTimeEvent?: Maybe<UnsyncFocusTimeEventMutationPayload>;
  /** Removes a smart hold event from a user's calendar */
  unsyncHold?: Maybe<UnsyncHoldPayload>;
  /** Removes a meeting relief event from a user's calendar */
  unsyncMeetingReliefEvent?: Maybe<UnsyncMeetingReliefEvent>;
  updateDeviceToken?: Maybe<UpdateDeviceTokenPayload>;
  updateEventColoringSettings?: Maybe<EventColoringSettingsMutationsPayload>;
  updateEventResponseStatus?: Maybe<UpdateEventResponseStatusMutationPayload>;
  updateFlagsForUser?: Maybe<UpdateFlagsForUserMutationPayload>;
  updateFlowStateUser?: Maybe<UpdateUserFlowMutationPayload>;
  updateFlowStateUserOrg?: Maybe<UpdateUserOrgFlowMutationPayload>;
  updateFocusTime?: Maybe<UpdateFocusTimeGoalResponse>;
  updateLunch?: Maybe<UpdateLunchGoalResponse>;
  updateMeetingRelief?: Maybe<UpdateMeetingReliefGoalResponse>;
  updateOrgInvite?: Maybe<UpdateOrgInvitePayload>;
  updateSetting?: Maybe<UpdateSettingMutationPayload>;
  updateSettings?: Maybe<UpdateSettingsMutationPayload>;
  updateShadowCalendarSettings?: Maybe<UpdateShadowCalendarSettingsMutationPayload>;
  updateSlackAccount?: Maybe<UpdateSlackAccountMutationPayload>;
  updateSlackAccountForTeam?: Maybe<UpdateSlackAccountForTeamMutationPayload>;
  updateSmartHoldSyncSettings?: Maybe<UpdateSmartHoldSyncSettingsMutationPayload>;
  updateSuggestedOrgInvite?: Maybe<UpdateSuggestedOrgInvitePayload>;
  updateTeam?: Maybe<UpdateTeamMutationPayload>;
  updateToggleEventColoringSettings?: Maybe<ToggleEventColoringSettingsMutationsPayload>;
  updateTravelTime?: Maybe<UpdateTravelTimeGoalResponse>;
  updateTravelTimeDuration?: Maybe<UpdateTravelTimeDurationPayload>;
  updateUserDefinedTeam?: Maybe<UpdateUserDefinedTeamMutationPayload>;
  updateWorkingHours?: Maybe<UpdateWorkingHoursMutationPayload>;
  updateWorkingLocation?: Maybe<UpdateWorkingLocationMutationPayload>;
  /** @deprecated use getUpdateWorkingLocationWeeklySchedule2 */
  updateWorkingLocationWeeklySchedule?: Maybe<UpdateWorkingLocationWeeklyScheduleMutationPayload>;
  updateWorkingLocationWeeklySchedule2?: Maybe<UpdateWorkingLocationWeeklyScheduleMutation2Payload>;
  upsertFocusTime?: Maybe<UpsertFocusTimeMutationPayload>;
  upsertLunch?: Maybe<UpsertLunchMutationPayload>;
  upsertMeetingRelief?: Maybe<UpsertMeetingReliefMutationPayload>;
  upsertNonUserDefragEmailResponse?: Maybe<UpsertNonUserDefragEmailResponsePayload>;
  upsertPreferredWork?: Maybe<UpsertPreferredWorkMutationPayload>;
  upsertTravelTime?: Maybe<UpsertTravelTimeMutationPayload>;
  /** Marks that the user has interacted with the the notification */
  userInteractedWithNotification?: Maybe<UserInteractedWithNotification>;
  userSurveyResponseMutations?: Maybe<SaveUserSurveyResponsesMutationPayload>;
  userSurveyResponseWrapperMutations?: Maybe<SaveUserSurveyResponseWrapperMutationPayload>;
  validateSlackChannelBySendingMessage?: Maybe<ValidateSlackChannelBySendingMessageMutationPayload>;
};

export type MutationAddAdminForBillingGroupArgs = {
  input: AddAdminForBillingGroupMutationInput;
};

export type MutationAddAndRemoveOrgTagsArgs = {
  input: AddAndRemoveOrgTagsMutationInput;
};

export type MutationAddManualMembersForBillingGroupArgs = {
  input: AddManualMembersForBillingGroupMutationInput;
};

export type MutationAddOrgTagsArgs = {
  input: AddOrgTagsMutationInput;
};

export type MutationAddSlackAccountArgs = {
  input: AddSlackAccountMutationInput;
};

export type MutationAddSlackAccountForTeamArgs = {
  input: AddSlackAccountForTeamMutationInput;
};

export type MutationAddSlackAccountV2Args = {
  input: AddSlackAccountV2MutationInput;
};

export type MutationAddZoomAccountArgs = {
  input: AddZoomAccountMutationInput;
};

export type MutationBatchAddAndRemoveOrgTagsArgs = {
  input: BatchAddAndRemoveOrgTagsMutationInput;
};

export type MutationBatchModifyAutopilotForEventsArgs = {
  input: BatchModifyAutopilotForEventsMutationInput;
};

export type MutationBatchUpdateFlexibilityArgs = {
  input: BatchModifyFlexibilityInput;
};

export type MutationCancelEventDiffDetailsArgs = {
  input: CancelEventDiffDetailsInput;
};

export type MutationCancelProposalArgs = {
  input: SharedProposalInput;
};

export type MutationCancelTestSubscriptionArgs = {
  input: CancelTestSubscriptionsInput;
};

export type MutationClearUserDataArgs = {
  input: ClearUserDataMutationInput;
};

export type MutationConfirmChatProposalArgs = {
  input?: InputMaybe<ConfirmChatMessageInput>;
};

export type MutationConfirmProposalArgs = {
  input: SharedProposalInput;
};

export type MutationCreateDataSourceArgs = {
  input: CreateDataSourceMutationInput;
};

export type MutationCreateOrgSubscriptionStripeArgs = {
  input: CreateOrgSubscriptionStripeInput;
};

export type MutationCreateTeamArgs = {
  input: CreateTeamMutationInput;
};

export type MutationCreateTeamAndTeamSubscriptionStripeArgs = {
  input: CreateTeamAndTeamSubscriptionStripeInput;
};

export type MutationCreateTeamCalendarArgs = {
  input: CreateTeamCalendarMutationInput;
};

export type MutationCreateTeamSubscriptionStripeArgs = {
  input: CreateTeamSubscriptionStripeInput;
};

export type MutationCreateTestSubscriptionStripeArgs = {
  input: CreateTestSubscriptionStripeInput;
};

export type MutationCreateWorkingLocationArgs = {
  input: CreateWorkingLocationMutationInput;
};

export type MutationDeactivateUserArgs = {
  input: DeactivateUserMutationInput;
};

export type MutationDeleteDataSourceArgs = {
  input: DeleteDataSourceInput;
};

export type MutationDeleteEventArgs = {
  input: DeleteEventMutationInput;
};

export type MutationDeleteTeamArgs = {
  input: DeleteTeamMutationInput;
};

export type MutationDeleteUserLevelZoomAccountArgs = {
  input: DeleteUserLevelZoomAccountMutationInput;
};

export type MutationDeleteWorkingLocationArgs = {
  input: DeleteWorkingLocationMutationInput;
};

export type MutationDeleteZoomAccountArgs = {
  input: DeleteZoomAccountMutationInput;
};

export type MutationDisableDataSourceSyncArgs = {
  input: DisableDataSourceSyncInput;
};

export type MutationDisambiguateProposalAttendeeArgs = {
  input: DisambiguateAttendeeInput;
};

export type MutationEditExistingEventArgs = {
  input: EditExistingEventInput;
};

export type MutationEnableDataSourceSyncArgs = {
  input: EnableDataSourceSyncInput;
};

export type MutationEnableOrDisableTeamSyncForEventArgs = {
  input: EnableOrDisableTeamSyncForEventInput;
};

export type MutationExecuteCliCommandArgs = {
  input: ExecuteCliCommandMutationInput;
};

export type MutationExtendTrialArgs = {
  input: ExtendTrialInput;
};

export type MutationInstallSlackAppArgs = {
  input: InstallSlackAppMutationInput;
};

export type MutationInvitePersonsToTeamArgs = {
  input: InvitePersonsToTeamInput;
};

export type MutationInviteToBillingGroupArgs = {
  input: InviteToBillingGroupMutationInput;
};

export type MutationLoginCredentialsUserArgs = {
  input: LoginCredentialsUserMutationInput;
};

export type MutationLoginMicrosoftUserArgs = {
  input: LoginMicrosoftUserMutationInput;
};

export type MutationLoginUserArgs = {
  input: LoginUserMutationInput;
};

export type MutationLogoutInsightsUserArgs = {
  input: LogoutInsightsUserInput;
};

export type MutationLogoutUserArgs = {
  input: LogoutUserMutationInput;
};

export type MutationMaybeOnboardOrgArgs = {
  input: MaybeOnboardOrgMutationInput;
};

export type MutationOverrideSyncStatusForUserEventArgs = {
  input: OverrideSyncStatusForUserEventMutationInput;
};

export type MutationPatchBillingGroupArgs = {
  input: PatchBillingGroupMutationInput;
};

export type MutationPatchSlackSettingsArgs = {
  input: PatchSlackSettingsMutationInput;
};

export type MutationPauseHoldArgs = {
  input: PauseHoldInput;
};

export type MutationPreAddOrgTagsArgs = {
  input: PreAddOrgTagsMutationInput;
};

export type MutationPreSetEventFlexibilityArgs = {
  input: PreSetFlexibilityInput;
};

export type MutationProcessMessageArgs = {
  input: ProcessChatMessageInput;
};

export type MutationProtectHoldArgs = {
  input: ProtectHoldInput;
};

export type MutationRecordChromeInstallArgs = {
  input: RecordChromeInstallMutationInput;
};

export type MutationRecordChromeUninstallArgs = {
  input: RecordChromeUninstallMutationInput;
};

export type MutationRecordDefragResponseArgs = {
  input: RecordDefragResponseMutationInput;
};

export type MutationRecordImpressionArgs = {
  input: RecordImpressionMutationInput;
};

export type MutationRegisterEmailUpdatesArgs = {
  input: RegisterEmailUpdatesInput;
};

export type MutationRemoveAdminForBillingGroupArgs = {
  input: RemoveAdminForBillingGroupMutationInput;
};

export type MutationRemoveDiffFromProposalArgs = {
  input?: InputMaybe<RemoveDiffFromProposalInput>;
};

export type MutationRemoveGoalArgs = {
  input: RemoveGoalMutationInput;
};

export type MutationRemoveLunchHoldArgs = {
  input: RemoveLunchHoldMutationInput;
};

export type MutationRemoveManualMembersForBillingGroupArgs = {
  input: RemoveManualMembersForBillingGroupMutationInput;
};

export type MutationRemoveOrgTagsArgs = {
  input: RemoveOrgTagsMutationInput;
};

export type MutationRescheduleEventParentArgs = {
  input: RescheduleEventParentMutationInput;
};

export type MutationResolveConflictArgs = {
  input: ResolveConflictInput;
};

export type MutationSaveAddEventDiffDetailsArgs = {
  input: SaveAddEventDiffDetailsInput;
};

export type MutationSaveFlexStatusArgs = {
  input: SaveFlexStatusInput;
};

export type MutationSaveModifyEventDiffDetailsArgs = {
  input: SaveModifyEventDiffDetailsInput;
};

export type MutationSavePushServiceSubscriptionArgs = {
  input: SavePushServiceSubscriptionMutationInput;
};

export type MutationSaveSharedProposalEventDiffDetailsArgs = {
  input: SaveSharedProposalEventDiffDetailsInput;
};

export type MutationSaveTeamSettingsArgs = {
  input: SaveTeamSettingsMutationInput;
};

export type MutationSaveTeamUserSettingsArgs = {
  input: SaveTeamUserSettingsMutationInput;
};

export type MutationSaveUninstallResponseArgs = {
  input: SaveUninstallResponseMutationInput;
};

export type MutationScheduleNewEventV2Args = {
  input: ScheduleNewEventV2MutationInput;
};

export type MutationScheduleNewSmartHoldArgs = {
  input: ScheduleNewSmartHoldMutationInput;
};

export type MutationSelectSchedulingOptionArgs = {
  input: SelectSchedulingOptionInput;
};

export type MutationSelectSharedOptionArgs = {
  input: GraphQlSelectSharedSchedulingOptionInput;
};

export type MutationSendBillingGroupRequestEmailArgs = {
  input: SendBillingGroupRequestEmailMutationInput;
};

export type MutationSendM365TeamAvailabilityCalendarInviteArgs = {
  input: SendM365TeamAvailabilityCalendarInviteMutationInput;
};

export type MutationSendM365TeamAvailabilityCalendarInvitesForTeamArgs = {
  input: SendM365TeamAvailabilityCalendarInvitesForTeamMutationInput;
};

export type MutationSendSeatQuantityChangeRequestArgs = {
  input: SendSeatQuantityChangeRequestMutationInput;
};

export type MutationSetCalendarEventFlexibilityArgs = {
  input: SetCalendarEventFlexibilityInput;
};

export type MutationSetDayRangeFlexibilityArgs = {
  input: UpdateDayRangeFlexibilityInput;
};

export type MutationSetFlexibilityArgs = {
  input: UpdateFlexibilityInput;
};

export type MutationSetPreferredRoomsArgs = {
  input: SetPreferredRoomsMutationInput;
};

export type MutationSetPrimaryTeamArgs = {
  input: SetPrimaryTeamMutationInput;
};

export type MutationSetRoomFlexibilityArgs = {
  input: BatchUpdateRoomFlexibilityInput;
};

export type MutationSetTimeOfDayFlexibilityArgs = {
  input: UpdateTimeOfDayFlexibilityInput;
};

export type MutationSetWorkAddressForUserArgs = {
  input: SetWorkAddressForUserMutationInput;
};

export type MutationShareProposalArgs = {
  input: SharedProposalInput;
};

export type MutationSubmitHubspotFormArgs = {
  input: SubmitHubspotFormMutationInput;
};

export type MutationSudoAddAdminToBillingGroupArgs = {
  input: SudoAddAdminToBillingGroupMutationInput;
};

export type MutationSudoAddMembersToBillingGroupArgs = {
  input: SudoAddMembersToBillingGroupMutationInput;
};

export type MutationSudoCreateBillingGroupArgs = {
  input: SudoCreateBillingGroupMutationInput;
};

export type MutationSudoDeactivateUserArgs = {
  input: SudoDeactivateUserMutationInput;
};

export type MutationSudoDeleteBillingGroupArgs = {
  input: SudoDeleteBillingGroupMutationInput;
};

export type MutationSudoDeleteEverythingArgs = {
  input: SudoDeleteEverythingMutationInput;
};

export type MutationSudoEnableSsoArgs = {
  input: SudoEnableSsoMutationInput;
};

export type MutationSudoLinkSubscriptionToBillingGroupArgs = {
  input: SudoLinkSubscriptionToBillingGroupMutationInput;
};

export type MutationSudoModifyUserArgs = {
  input: SudoModifyUserMutationInput;
};

export type MutationSudoRemoveMembersFromBillingGroupArgs = {
  input: SudoRemoveMembersFromBillingGroupMutationInput;
};

export type MutationSudoResetFlowStateArgs = {
  input: SudoResetFlowStateMutationInput;
};

export type MutationSudoSwitchUserArgs = {
  input: SudoSwitchUserMutationInput;
};

export type MutationSudoToggleAutoSyncForOrgArgs = {
  input: SudoToggleAutoSyncForOrgMutationInput;
};

export type MutationSudoTriggerClientUpdateArgs = {
  input: SudoTriggerClientUpdateMutationInput;
};

export type MutationSudoUnlinkSubscriptionFromBillingGroupArgs = {
  input: SudoUnlinkSubscriptionFromBillingGroupMutationInput;
};

export type MutationSudoUpdateBillingGroupNameArgs = {
  input: SudoUpdateBillingGroupNameMutationInput;
};

export type MutationSudoUpdateInvitePolicyArgs = {
  input: SudoUpdateInvitePolicyMutationInput;
};

export type MutationSyncMeetingReliefArgs = {
  input?: InputMaybe<SyncMeetingReliefInput>;
};

export type MutationToggleAutoSyncForBillingGroupArgs = {
  input: ToggleAutoSyncForBillingGroupMutationInput;
};

export type MutationToggleFocusTimeArgs = {
  enabled: Scalars["Boolean"];
  orgId: Scalars["ID"];
};

export type MutationToggleLunchArgs = {
  enabled: Scalars["Boolean"];
  orgId: Scalars["ID"];
};

export type MutationToggleMeetingReliefArgs = {
  enabled: Scalars["Boolean"];
  orgId: Scalars["ID"];
};

export type MutationToggleTravelTimeArgs = {
  enabled: Scalars["Boolean"];
  orgId: Scalars["ID"];
};

export type MutationTriggerDefragRunArgs = {
  input: TriggerDefragRunMutationInput;
};

export type MutationTriggerOptimizationNotificationArgs = {
  orgId: Scalars["ID"];
};

export type MutationUnpauseEventArgs = {
  input: UnpauseEventMutationInput;
};

export type MutationUnpauseHoldArgs = {
  input: UnpauseHoldInput;
};

export type MutationUnprotectHoldArgs = {
  input: UnprotectHoldInput;
};

export type MutationUnsubscribeEmailArgs = {
  input: UnsubscribeEmailInput;
};

export type MutationUnsyncFocusTimeBlockArgs = {
  input: UnsyncFocusTimeBlockMutationInput;
};

export type MutationUnsyncFocusTimeEventArgs = {
  input: UnsyncFocusTimeEventMutationInput;
};

export type MutationUnsyncHoldArgs = {
  input: UnsyncHoldInput;
};

export type MutationUnsyncMeetingReliefEventArgs = {
  input: UnsyncMeetingReliefEventInput;
};

export type MutationUpdateDeviceTokenArgs = {
  input: UpdateDeviceTokenInput;
};

export type MutationUpdateEventColoringSettingsArgs = {
  input: EventColoringSettingsMutationsInput;
};

export type MutationUpdateEventResponseStatusArgs = {
  input: UpdateEventResponseStatusMutationInput;
};

export type MutationUpdateFlagsForUserArgs = {
  input: UpdateFlagsForUserMutationInput;
};

export type MutationUpdateFlowStateUserArgs = {
  input: UpdateUserFlowMutationInput;
};

export type MutationUpdateFlowStateUserOrgArgs = {
  input: UpdateUserOrgFlowMutationInput;
};

export type MutationUpdateFocusTimeArgs = {
  autoDeclineEnabled?: InputMaybe<Scalars["Boolean"]>;
  autoDeclineThreshold?: InputMaybe<UpdateAutoDeclineThreshold>;
  dailyMaxAmount?: InputMaybe<UpdateDailyMax>;
  dailyMaxEnabled?: InputMaybe<Scalars["Boolean"]>;
  notificationStrategy?: InputMaybe<UpdateNotificationStrategyInput>;
  orgId: Scalars["ID"];
  preferredFocusIn?: InputMaybe<UpdatePreferredFocusTime>;
  scheduleAllFocusTime?: InputMaybe<Scalars["Boolean"]>;
  scheduleAs?: InputMaybe<UpdateScheduleAs>;
  scheduleAtMost?: InputMaybe<UpdateAtMostFocusHours>;
};

export type MutationUpdateLunchArgs = {
  earliestStart?: InputMaybe<TimeSlot>;
  idealDuration?: InputMaybe<Scalars["Duration"]>;
  latestEnd?: InputMaybe<TimeSlot>;
  minimumDuration?: InputMaybe<Scalars["Duration"]>;
  notificationStrategy?: InputMaybe<UpdateNotificationStrategyInput>;
  orgId: Scalars["ID"];
  scheduleAs?: InputMaybe<UpdateScheduleAs>;
};

export type MutationUpdateMeetingReliefArgs = {
  breakDuration?: InputMaybe<Scalars["Duration"]>;
  fatigueThreshold?: InputMaybe<Scalars["Duration"]>;
  notificationStrategy?: InputMaybe<UpdateNotificationStrategyInput>;
  orgId: Scalars["ID"];
  scheduleAs?: InputMaybe<UpdateScheduleAs>;
};

export type MutationUpdateOrgInviteArgs = {
  input: UpdateOrgInviteInput;
};

export type MutationUpdateSettingArgs = {
  input: UpdateSettingMutationInput;
};

export type MutationUpdateSettingsArgs = {
  input: UpdateSettingsMutationInput;
};

export type MutationUpdateShadowCalendarSettingsArgs = {
  input: UpdateShadowCalendarSettingsMutationInput;
};

export type MutationUpdateSlackAccountArgs = {
  input: UpdateSlackAccountMutationInput;
};

export type MutationUpdateSlackAccountForTeamArgs = {
  input: UpdateSlackAccountForTeamMutationInput;
};

export type MutationUpdateSmartHoldSyncSettingsArgs = {
  input: UpdateSmartHoldSyncSettingsMutationInput;
};

export type MutationUpdateSuggestedOrgInviteArgs = {
  input: UpdateSuggestedOrgInviteInput;
};

export type MutationUpdateTeamArgs = {
  input: UpdateTeamMutationInput;
};

export type MutationUpdateToggleEventColoringSettingsArgs = {
  input: ToggleEventColoringSettingsMutationsInput;
};

export type MutationUpdateTravelTimeArgs = {
  clearAddress?: Scalars["Boolean"];
  notificationStrategy?: InputMaybe<UpdateNotificationStrategyInput>;
  orgId: Scalars["ID"];
  scheduleAs?: InputMaybe<UpdateScheduleAs>;
  userAddress?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateTravelTimeDurationArgs = {
  input: UpdateTravelTimeDurationInput;
};

export type MutationUpdateUserDefinedTeamArgs = {
  input: UpdateUserDefinedTeamMutationInput;
};

export type MutationUpdateWorkingHoursArgs = {
  input: UpdateWorkingHoursMutationInput;
};

export type MutationUpdateWorkingLocationArgs = {
  input: UpdateWorkingLocationMutationInput;
};

export type MutationUpdateWorkingLocationWeeklyScheduleArgs = {
  input: UpdateWorkingLocationWeeklyScheduleMutationInput;
};

export type MutationUpdateWorkingLocationWeeklySchedule2Args = {
  input: UpdateWorkingLocationWeeklyScheduleMutation2Input;
};

export type MutationUpsertFocusTimeArgs = {
  input: UpsertFocusTimeMutationInput;
};

export type MutationUpsertLunchArgs = {
  input: UpsertLunchMutationInput;
};

export type MutationUpsertMeetingReliefArgs = {
  input: UpsertMeetingReliefMutationInput;
};

export type MutationUpsertNonUserDefragEmailResponseArgs = {
  input: UpsertNonUserDefragEmailResponseInput;
};

export type MutationUpsertPreferredWorkArgs = {
  input: UpsertPreferredWorkMutationInput;
};

export type MutationUpsertTravelTimeArgs = {
  input: UpsertTravelTimeMutationInput;
};

export type MutationUserInteractedWithNotificationArgs = {
  input: UserInteractedWithNotificationInput;
};

export type MutationUserSurveyResponseMutationsArgs = {
  input: SaveUserSurveyResponsesMutationInput;
};

export type MutationUserSurveyResponseWrapperMutationsArgs = {
  input: SaveUserSurveyResponseWrapperMutationInput;
};

export type MutationValidateSlackChannelBySendingMessageArgs = {
  input: ValidateSlackChannelBySendingMessageMutationInput;
};

export type MyBillingGroups = {
  __typename: "MyBillingGroups";
  allGroups: Array<BillingGroup>;
  primaryBillingGroup?: Maybe<BillingGroup>;
};

/** No address has been configured */
export type NoAddress = {
  __typename: "NoAddress";
  nop?: Maybe<Scalars["Void"]>;
};

/** No calendar notifications exist */
export type NoCalendarNotifications = {
  __typename: "NoCalendarNotifications";
  void?: Maybe<Scalars["Void"]>;
};

/** No home notification exist */
export type NoHomeNotification = {
  __typename: "NoHomeNotification";
  void?: Maybe<Scalars["Void"]>;
};

export enum NoMeetingDayOfWeek {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

/** No notification exists */
export type NoNotification = {
  __typename: "NoNotification";
  void?: Maybe<Scalars["Void"]>;
};

/** A placeholder for when there are no scheduling options provided */
export type NoSchedulingOptions = {
  __typename: "NoSchedulingOptions";
  nop?: Maybe<Scalars["Void"]>;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars["ID"];
};

/** Response to non-user defrag email survey */
export type NonUserDefragEmailResponse = Node & {
  __typename: "NonUserDefragEmailResponse";
  additionalInformation?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  responseType: NonUserDefragEmailResponseType;
};

export type NonUserDefragEmailResponseErrorable = GraphEntityError | NonUserDefragEmailResponse;

export enum NonUserDefragEmailResponseType {
  No = "No",
  NotSure = "NotSure",
  Yes = "Yes",
}

/** The event is not flexible */
export type NotFlexible = {
  __typename: "NotFlexible";
  void?: Maybe<Scalars["Void"]>;
};

export type NotificationButton = {
  text: Scalars["String"];
  tracking: TrackingData;
};

/** Button that links to any page */
export type NotificationLink = NotificationButton & {
  __typename: "NotificationLink";
  text: Scalars["String"];
  tracking: TrackingData;
  url: Scalars["String"];
};

export enum NotificationStrategy {
  DontNotify = "DontNotify",
  NotifyInGCal = "NotifyInGCal",
}

/** Onboarding Checklist */
export type OnboardingChecklist = {
  __typename: "OnboardingChecklist";
  flexMeetingEnabled?: Maybe<FlexMeetingEnabledErrorable>;
  focusTimeEnabled?: Maybe<FocusTimeEnabledErrorable>;
  hasInvitedTeammate?: Maybe<HasInvitedTeammateErrorable>;
  hasSuggestedFlexMeetings?: Maybe<HasSuggestedFlexMeetingsErrorable>;
  lunchEnabled?: Maybe<LunchEnabledErrorable>;
  slackEnabled?: Maybe<SlackEnabledErrorable>;
};

/** Onboarding Checklist */
export type OnboardingChecklistHasSuggestedFlexMeetingsArgs = {
  timeZone: Scalars["String"];
};

export enum OperationEnum {
  ADD = "ADD",
  CANCEL = "CANCEL",
  EDIT_DETAILS = "EDIT_DETAILS",
  PROPOSE_NEW_TIME = "PROPOSE_NEW_TIME",
  RESCHEDULE = "RESCHEDULE",
  RSVP = "RSVP",
  UNCHANGED = "UNCHANGED",
}

/** Optimistic savings for a user, used to determine how much time can be saved in a week */
export type OptimisticSavings = {
  __typename: "OptimisticSavings";
  couldCalculate: Scalars["Boolean"];
  savingMinutes?: Maybe<Scalars["Int"]>;
};

export type OptimisticSavingsErrorable = GraphEntityError | OptimisticSavings;

export type OptimizationNotification = NoNotification | OptimizationResults;

export type OptimizationNotificationTriggerResult = {
  __typename: "OptimizationNotificationTriggerResult";
  org?: Maybe<Org>;
};

/** Notification of what was optimized for your account */
export type OptimizationResults = {
  __typename: "OptimizationResults";
  conflictsResolved: Scalars["NonNegativeInt"];
  expiresAt: Scalars["DateTime"];
  focusTimeAdded: Scalars["Duration"];
  /** The ID of an object */
  id: Scalars["ID"];
  optimizedAt: Scalars["DateTime"];
};

export enum OrdinalModifierEnum {
  ALL = "ALL",
  FIRST = "FIRST",
  LAST = "LAST",
  NEXT = "NEXT",
  NTH = "NTH",
}

/** A collection of users with the same email domain. */
export type Org = Node & {
  __typename: "Org";
  /** Autopilot events for the user */
  autopilotEventsResponse: AutopilotEventsResponseErrorable;
  /** Autopilot onboarding suggestions for the user */
  autopilotOnboardingSuggestionsResponse: AutopilotOnboardingSuggestionsResponseErrorable;
  /** a billing group for given id */
  billingGroupForId: BillingGroupErrorable;
  /** a list of billing groups for current org */
  billingGroups: BillingGroupListErrorable;
  /** A list of billing group ids based from the selected calendar ids */
  billingGroupsByCalendarIds: Array<BillingGroupsOfCalendarIdList>;
  /** Calculates smart hold events without reserving them on a user calendar */
  calculateSmartHoldsErrorable?: Maybe<SmartHoldCalculationResultErrorable>;
  /** User gcal notification */
  calendarNotification?: Maybe<CalendarNotification>;
  /** The calendar status for a given calendarId */
  calendarStatusErrorable: CalendarStatusErrorable;
  /** Teammate time stats for a given time period */
  calendarTimeStats?: Maybe<CalendarTimeStats>;
  /** Teammate time stats for a given time period and set of calendars */
  calendarTimeStatsList?: Maybe<CalendarTimeStatsListErrorable>;
  /**
   * For a given user and event can an event be rescheduled
   * @deprecated use canScheduleResponse
   */
  canRescheduleEvent: Scalars["Boolean"];
  /** For a given user and event can an event be resheduled or put on autopilot */
  canScheduleResponse?: Maybe<CanScheduleResponseListErrorable>;
  /**
   * Teammate time stats with other teammates for a given time period
   * @deprecated In favor of new Errorable version
   */
  collaboratorStats?: Maybe<CollaboratorStats>;
  /** Errorable teammate time stats with other teammates for a given time period */
  collaboratorStatsErrorable?: Maybe<CollaboratorStatsErrorable>;
  crawlTest?: Maybe<OrgCrawlTest>;
  /** Crawl status for calendarIds in this org */
  crawledCalendarInfo?: Maybe<CrawledCalendarInfo>;
  /** Crawl status for given calendarIds */
  crawledCalendarInfoForCalendars?: Maybe<CrawledCalendarInfo>;
  createdTime?: Maybe<Scalars["BigInt"]>;
  /** A list of all `active` users in an org */
  currentOrgPersons: OrgPersonListErrorable;
  /** The domains associated with this org */
  domains: Array<Scalars["String"]>;
  /** An enum for the type of the current user's data source (eg. Google, Microsoft) */
  ecosystem: EcosystemEnum;
  /** The user's event coloring settings for the org */
  eventColoringSettingsErrorable: EventColoringSettingsErrorable;
  /** Event with a given id on the given calendar (unlike forceFetchEventParentErrorable does not do a pull from Google for the latest info) */
  eventForCalendar: EventErrorable;
  /** Event parents and event parent bases */
  eventParentBaseWrapperByDates: EventParentBaseWrapperErrorable;
  /**
   * Event parents and event parent bases by week
   * @deprecated In favor of object version which is supported by both Apollo and Relay
   */
  eventParentBaseWrapperByWeeks: EventParentBaseWrapperByWeekListErrorable;
  /** Event parents and event parent bases by week */
  eventParentBaseWrapperByWeeksObject: EventParentBaseWrapperByWeekObjectListErrorable;
  /** An org's collection of events from all queried calendars and dates */
  eventParentWrappersByDatesErrorable: EventParentWrapperConnectionErrorable;
  /** An org's collection of events from all queried calendars and dates */
  eventParentsByDates: EventParentConnection;
  /** A list of all experiment strings enabled for this org */
  experiments?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Exportable list of records for the analytics per user of the org */
  exportedCalendarAnalytics: ExportedCalendarAnalyticsListErrorable;
  /** External groups for emails */
  externalGroups: ExternalGroupListErrorable;
  featureGrid: FeatureGridErrorable;
  findSchedulingSuggestionsV2: Array<ConflictEventNewTimes>;
  /** Flexible meetings dashboard for a user */
  flexibleMeetingsDashboard?: Maybe<FlexibleMeetingsDashboard>;
  /** The collection of flow states for the user */
  flowStates?: Maybe<FlowStateConnection>;
  /** The total number of hours saved across an org */
  focusTimeSavedInHours: Scalars["Int"];
  /** Focus Time stats for the user */
  focusTimeStats: FocusTimeStatsErrorable;
  /** Force fetch an event from Google (does a pull from Google to ensure the event has latest info) */
  forceFetchEventParentErrorable: EventParentErrorable;
  /** User analytics for the home page */
  homeAnalytics?: Maybe<HomeAnalytics>;
  /** User notification for home page launch */
  homeNotification?: Maybe<HomeNotification>;
  /** The ID of an object */
  id: Scalars["ID"];
  /** Fetch a Microsoft event from our database using the eventId that we get from an Outlook event. Used to fetch events from Outlook Add-in */
  m365Event: EventParentErrorable;
  /** The meeting hours for the current org, a set of users (via userIds), or any set of non-users (via calendarIds) */
  meetingHoursQueryResult?: Maybe<MeetingHoursQueryResult>;
  /** Finds any long meeting blocks for meeting relief nudge notification */
  meetingReliefSuggestion?: Maybe<MeetingReliefSuggestion>;
  myBillingGroups?: Maybe<MyBillingGroups>;
  /** The name of the org */
  name?: Maybe<Scalars["String"]>;
  /** The next defrag time for the current org */
  nextDefragTime?: Maybe<DefragTimeErrorable>;
  /** Onboarding checklist for a user */
  onboardingChecklist: OnboardingChecklist;
  /** List of a user's one on ones */
  oneOnOnesForCalendar: EventListErrorable;
  /** Optimistic savings for a user, used to determine how much time can be saved in a week */
  optimisticSavingsErrorable?: Maybe<OptimisticSavingsErrorable>;
  /** Optimistic savings for current user for next week */
  optimisticSavingsForCurrentUserNextWeekErrorable?: Maybe<OptimisticSavingsErrorable>;
  optimizationNotification?: Maybe<OptimizationNotification>;
  orgAccountingPeriod: OrgAccountingPeriodErrorable;
  /** The collection of flow states for the org */
  orgFlowStates?: Maybe<FlowStateListErrorable>;
  /** The default meeting hours for the current org */
  orgMeetingHours?: Maybe<OrgMeetingHours>;
  /** Overview of organization-wide Clockwise usage */
  orgOverview?: Maybe<OrgOverviewErrorable>;
  orgPersonForUserErrorable: OrgPersonErrorable;
  /** Object defining a list of org persons and how to paginate the list */
  orgPersonListPaginatedErrorable: OrgPersonListPaginatedErrorable;
  /** Look up org persons by calendar id */
  orgPersonsByCalendarIdsErrorable: OrgPersonListErrorable;
  /** Pending org invites outstanding */
  pendingInvites?: Maybe<OrgInviteConnection>;
  /** A collection of teammates, suggestions, invitees and you */
  personCorpusErrorable: PersonListErrorable;
  personForUserErrorable: PersonErrorable;
  /**
   * Look up persons by calendar id
   * @deprecated use orgPersonsByCalendarIdsErrorable
   */
  personsByCalendarIdsErrorable: PersonListErrorable;
  /** Look up persons by user id */
  personsByUserIdsErrorable: PersonListErrorable;
  /** The users primary calendar in this org */
  primaryOrgCalendar: Scalars["String"];
  /** The users email thats part of this org */
  primaryOrgEmail: Scalars["String"];
  /** Primary team for user */
  primaryTeam?: Maybe<TeamErrorable>;
  /** A list of profile time zones */
  profileTimeZonesErrorable: ProfileTimeZoneListErrorable;
  /** List of public Slack channels for the slack workspace for a given Clockwise team */
  publicChannelsForSlackForTeam: SlackChannelListErrorable;
  /** External rooms for the given ids */
  rooms: RoomListErrorable;
  /** The collection of settings for this user in this org */
  settings?: Maybe<SettingConnection>;
  /** The user's shadow calendar settings for the org */
  shadowCalendarSettingsErrorable: ShadowCalendarSettingsErrorable;
  /** Slack Settings for a given team */
  slackAccountForTeamErrorable?: Maybe<SlackAccountErrorable>;
  /** The user's list of Slack Accounts */
  slackAccountsErrorable: SlackAccountListErrorable;
  /** The user's list of Slack Account Settings */
  slackSettingsErrorable: SlackSettingsListErrorable;
  smartHoldEventInfo: SmartHoldEventInfoErrorable;
  /** Goals/Preferences for smart hold */
  smartHoldGoals: SmartHoldGoals;
  /** A link, which expires in 5 minutes, that can be used to configure sso for a billing group. */
  ssoAdminPortalLink?: Maybe<SsoAdminPortalLinkErrorable>;
  suggestedFlexibleMeetings?: Maybe<SuggestedFlexibleMeetings>;
  /** Suggested invites to make for this org */
  suggestedInvites?: Maybe<SuggestedOrgInviteConnection>;
  /** The suggested and selected room preferences */
  suggestedRoomPreferences: SuggestedRoomPreferencesErrorable;
  /** A list of all suggested teams for user */
  suggestedTeams: SuggestedTeamListErrorable;
  /** Working preferences for a teammate */
  summarizedWorkingPreferences?: Maybe<SummarizedWorkingPreferences>;
  /** Event with a given id on the given calendar */
  syncedOriginEvent: EventErrorable;
  /** Fetches (most recent) tailored defrag summary */
  tailoredDefragCardSummary?: Maybe<TailoredDefragCardSummaryErrorable>;
  /** Calendar statuses for everyone on the team */
  teamCalendarStatusErrorable: CalendarStatusListErrorable;
  /** A team for org from given teamId */
  teamForTeamId?: Maybe<TeamErrorable>;
  /** Team health score for calendars for a given time period */
  teamHealthScoreForCalendarsErrorable?: Maybe<TeamHealthScoreErrorable>;
  /** Team health scorefor team for a given time period */
  teamHealthScoreForTeamErrorable?: Maybe<TeamHealthScoreErrorable>;
  /** Team health score for team for a given time period */
  teamHealthScoreForTeamWithSubTeamsErrorable?: Maybe<TeamHealthScoreWithSubTeamsErrorable>;
  /** Team health score just for user for a given time period */
  teamHealthScoreJustForUserErrorable?: Maybe<TeamHealthScoreErrorable>;
  /** Overall settings for a team */
  teamSettings: TeamSettingsErrorable;
  /** User's settings for a team */
  teamUserSettings: TeamUserSettingsErrorable;
  /** A list of all teams for org */
  teamsForOrg: TeamListErrorable;
  /** The user's team */
  userDefinedTeam?: Maybe<UserDefinedTeam>;
  /** The users's goals */
  userGoals?: Maybe<GoalConnection>;
  /** The meeting hours for the current user */
  userMeetingHours?: Maybe<UserMeetingHours>;
  /** The current user's profile */
  userPerson?: Maybe<PersonErrorable>;
  /** The user responses from the onboarding survey */
  userSurveyResponseErrorable: UserSurveyResponseListErrorable;
  /** The user responses wrapped by ID for the onboarding survey */
  userSurveyResponseWrapperErrorable: UserSurveyResponseWrapperErrorable;
  /** A list of all teams for user */
  userTeams: TeamListErrorable;
  /** The working hours for the current user */
  userWorkingHours?: Maybe<UserWorkingHours>;
  /** A user's working locations given an id */
  userWorkingLocationForId: WorkingLocation;
  /** A list of this users working locations */
  userWorkingLocations: WorkingLocationListErrorable;
  /** The meeting hours for the multiple users */
  usersMeetingHours: Array<UserMeetingHours>;
  /** The working hours for the multiple users */
  usersWorkingHours: Array<UserWorkingHours>;
  /** The user work address for this org */
  workAddressForUser: AddressErrorable;
  /** Working groups for this user for this org */
  workingGroups: WorkingGroupConnection;
  /** The working hours for any given set of users */
  workingHoursQueryResult?: Maybe<WorkingHoursQueryResult>;
  /** User's weekly schedule settings for working location */
  workingLocationWeeklySchedule: WorkingLocationWeeklyScheduleErrorable;
  /** Connected account-level zoom account for org */
  zoomAccount?: Maybe<ZoomAccountErrorable>;
  /** Connected user-level zoom account for org */
  zoomUserLevelAccount?: Maybe<ZoomAccountErrorable>;
};

/** A collection of users with the same email domain. */
export type OrgAutopilotEventsResponseArgs = {
  timeZone: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgBillingGroupForIdArgs = {
  billingGroupId: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgBillingGroupsByCalendarIdsArgs = {
  calendarIds: Array<Scalars["ID"]>;
};

/** A collection of users with the same email domain. */
export type OrgCalculateSmartHoldsErrorableArgs = {
  week: Scalars["LocalWeek"];
};

/** A collection of users with the same email domain. */
export type OrgCalendarStatusErrorableArgs = {
  calendarId: Scalars["String"];
  timeZone: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgCalendarTimeStatsArgs = {
  calendarId: Scalars["String"];
  rangeMax: DayTimeSlotInput;
  rangeMin: DayTimeSlotInput;
  timeZone: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgCalendarTimeStatsListArgs = {
  calendarIds: Array<Scalars["String"]>;
  dates: Array<Scalars["LocalDate"]>;
  timeZone: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgCanRescheduleEventArgs = {
  calendarIds?: InputMaybe<Array<Scalars["String"]>>;
  eventParentRelayId?: InputMaybe<Scalars["ID"]>;
};

/** A collection of users with the same email domain. */
export type OrgCanScheduleResponseArgs = {
  calendarIds?: InputMaybe<Array<Scalars["String"]>>;
  externalEventIds: Array<InputMaybe<Scalars["String"]>>;
};

/** A collection of users with the same email domain. */
export type OrgCollaboratorStatsArgs = {
  calendarId: Scalars["String"];
  rangeMax: DayTimeSlotInput;
  rangeMin: DayTimeSlotInput;
  timeZone: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgCollaboratorStatsErrorableArgs = {
  calendarId: Scalars["String"];
  rangeMax: DayTimeSlotInput;
  rangeMin: DayTimeSlotInput;
  timeZone: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgCrawledCalendarInfoForCalendarsArgs = {
  calendarIds: Array<Scalars["String"]>;
};

/** A collection of users with the same email domain. */
export type OrgEventForCalendarArgs = {
  calendarId: Scalars["String"];
  externalEventId: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgEventParentBaseWrapperByDatesArgs = {
  calendarIds: Array<Scalars["String"]>;
  dates: Array<Scalars["LocalDate"]>;
  includeAllDay?: InputMaybe<Scalars["Boolean"]>;
  timeZone: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgEventParentBaseWrapperByWeeksArgs = {
  calendarIds: Array<Scalars["String"]>;
  includeAllDay?: InputMaybe<Scalars["Boolean"]>;
  timeZone: Scalars["String"];
  weekStartDates: Array<Scalars["LocalDate"]>;
};

/** A collection of users with the same email domain. */
export type OrgEventParentBaseWrapperByWeeksObjectArgs = {
  calendarIds: Array<Scalars["String"]>;
  includeAllDay?: InputMaybe<Scalars["Boolean"]>;
  timeZone: Scalars["String"];
  weekStartDates: Array<Scalars["LocalDate"]>;
};

/** A collection of users with the same email domain. */
export type OrgEventParentWrappersByDatesErrorableArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  calendarIds: Array<Scalars["String"]>;
  dates: Array<Scalars["LocalDate"]>;
  first?: InputMaybe<Scalars["Int"]>;
  includeAllDay?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  timeZone: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgEventParentsByDatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  calendarIds: Array<Scalars["String"]>;
  dates: Array<Scalars["Date"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  timeZone: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgExternalGroupsArgs = {
  emails: Array<Scalars["String"]>;
};

/** A collection of users with the same email domain. */
export type OrgFindSchedulingSuggestionsV2Args = {
  clusterId: Scalars["String"];
  externalEventId: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgFlowStatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A collection of users with the same email domain. */
export type OrgForceFetchEventParentErrorableArgs = {
  calendarId: Scalars["String"];
  externalEventId: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgM365EventArgs = {
  calendarId: Scalars["String"];
  m365EventId: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgMeetingHoursQueryResultArgs = {
  nonUserCalendarIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  resolveOverrides?: InputMaybe<Scalars["Boolean"]>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** A collection of users with the same email domain. */
export type OrgOptimisticSavingsErrorableArgs = {
  calendarId: Scalars["String"];
  week: Scalars["LocalWeek"];
};

/** A collection of users with the same email domain. */
export type OrgOrgFlowStatesArgs = {
  flowKeys: Array<Scalars["String"]>;
};

/** A collection of users with the same email domain. */
export type OrgOrgOverviewArgs = {
  maxDate?: InputMaybe<Scalars["String"]>;
  minDate?: InputMaybe<Scalars["String"]>;
};

/** A collection of users with the same email domain. */
export type OrgOrgPersonListPaginatedErrorableArgs = {
  activityFilter?: InputMaybe<ActivityFilter>;
  billingGroupId?: InputMaybe<Scalars["String"]>;
  excludeNonUsers?: InputMaybe<Scalars["Boolean"]>;
  fetchNext?: InputMaybe<Scalars["Boolean"]>;
  freePersonsOnly?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  pageToken?: InputMaybe<Scalars["String"]>;
  primaryBillingGroupOnly?: InputMaybe<Scalars["Boolean"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortDirection: SortDirection;
  sortType: SortType;
  teamId?: InputMaybe<Scalars["String"]>;
};

/** A collection of users with the same email domain. */
export type OrgOrgPersonsByCalendarIdsErrorableArgs = {
  calendarIds: Array<InputMaybe<Scalars["String"]>>;
};

/** A collection of users with the same email domain. */
export type OrgPendingInvitesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A collection of users with the same email domain. */
export type OrgPersonsByCalendarIdsErrorableArgs = {
  calendarIds: Array<InputMaybe<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
};

/** A collection of users with the same email domain. */
export type OrgPersonsByUserIdsErrorableArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  userIds: Array<InputMaybe<Scalars["String"]>>;
};

/** A collection of users with the same email domain. */
export type OrgProfileTimeZonesErrorableArgs = {
  calendarIds: Array<InputMaybe<Scalars["String"]>>;
};

/** A collection of users with the same email domain. */
export type OrgPublicChannelsForSlackForTeamArgs = {
  teamId: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgRoomsArgs = {
  roomIds: Array<Scalars["String"]>;
};

/** A collection of users with the same email domain. */
export type OrgSettingsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A collection of users with the same email domain. */
export type OrgSlackAccountForTeamErrorableArgs = {
  teamId: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgSmartHoldEventInfoArgs = {
  externalEventId: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgSsoAdminPortalLinkArgs = {
  bgId: Scalars["String"];
  intent?: InputMaybe<SsoIntent>;
};

/** A collection of users with the same email domain. */
export type OrgSuggestedFlexibleMeetingsArgs = {
  filter?: SuggestedFlexibleMeetingFilter;
  limit?: Scalars["Int"];
};

/** A collection of users with the same email domain. */
export type OrgSuggestedInvitesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A collection of users with the same email domain. */
export type OrgSummarizedWorkingPreferencesArgs = {
  calendarId: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgSyncedOriginEventArgs = {
  externalEventId: Scalars["String"];
  startTime: EventTimeInput;
};

/** A collection of users with the same email domain. */
export type OrgTailoredDefragCardSummaryArgs = {
  newerThan?: InputMaybe<Scalars["BigInt"]>;
};

/** A collection of users with the same email domain. */
export type OrgTeamCalendarStatusErrorableArgs = {
  timeZone: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgTeamForTeamIdArgs = {
  teamId: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgTeamHealthScoreForCalendarsErrorableArgs = {
  calendarIds: Array<InputMaybe<Scalars["String"]>>;
  rangeMax: DayTimeSlotInput;
  rangeMin: DayTimeSlotInput;
  timeZone: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgTeamHealthScoreForTeamErrorableArgs = {
  rangeMax: DayTimeSlotInput;
  rangeMin: DayTimeSlotInput;
  teamId: Scalars["String"];
  timeZone: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgTeamHealthScoreForTeamWithSubTeamsErrorableArgs = {
  rangeMax: DayTimeSlotInput;
  rangeMin: DayTimeSlotInput;
  teamId: Scalars["String"];
  timeZone: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgTeamHealthScoreJustForUserErrorableArgs = {
  rangeMax: DayTimeSlotInput;
  rangeMin: DayTimeSlotInput;
  timeZone: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgTeamSettingsArgs = {
  teamId: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgTeamUserSettingsArgs = {
  teamId: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgUserGoalsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A collection of users with the same email domain. */
export type OrgUserMeetingHoursArgs = {
  resolveOverrides?: InputMaybe<Scalars["Boolean"]>;
};

/** A collection of users with the same email domain. */
export type OrgUserSurveyResponseErrorableArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A collection of users with the same email domain. */
export type OrgUserSurveyResponseWrapperErrorableArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A collection of users with the same email domain. */
export type OrgUserWorkingHoursArgs = {
  resolveOverrides?: InputMaybe<Scalars["Boolean"]>;
};

/** A collection of users with the same email domain. */
export type OrgUserWorkingLocationForIdArgs = {
  workingLocationId: Scalars["String"];
};

/** A collection of users with the same email domain. */
export type OrgUsersMeetingHoursArgs = {
  calendarIds?: InputMaybe<Array<Scalars["CalendarId"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  resolveOverrides?: InputMaybe<Scalars["Boolean"]>;
};

/** A collection of users with the same email domain. */
export type OrgUsersWorkingHoursArgs = {
  calendarIds?: InputMaybe<Array<Scalars["CalendarId"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  resolveOverrides?: InputMaybe<Scalars["Boolean"]>;
  weekOffset?: InputMaybe<Scalars["Int"]>;
};

/** A collection of users with the same email domain. */
export type OrgWorkingGroupsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A collection of users with the same email domain. */
export type OrgWorkingHoursQueryResultArgs = {
  nonUserCalendarIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  resolveOverrides?: InputMaybe<Scalars["Boolean"]>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Freemium Org accounting period info */
export type OrgAccountingPeriod = {
  __typename: "OrgAccountingPeriod";
  enforcementStarted: Scalars["Boolean"];
  enforcementState: EnforcementStateEnum;
  periodRange: DateRange;
  quotaMax: Scalars["Int"];
  quotaUsed: Scalars["Int"];
};

export type OrgAccountingPeriodErrorable = GraphEntityError | OrgAccountingPeriod;

/** A connection to a list of items. */
export type OrgConnection = {
  __typename: "OrgConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrgEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export enum OrgCrawlTest {
  GoogleApiAccessDisabled = "GoogleApiAccessDisabled",
  GoogleContactSharingDisabled = "GoogleContactSharingDisabled",
  NoCrawl = "NoCrawl",
  NoExternalOrganization = "NoExternalOrganization",
  ShouldCrawl = "ShouldCrawl",
  Skipped = "Skipped",
  StartedCrawl = "StartedCrawl",
}

/** The event can move at the next defrag */
export type OrgDefragTime = {
  __typename: "OrgDefragTime";
  nextDefrag?: Maybe<Scalars["DateTime"]>;
};

/** An edge in a connection. */
export type OrgEdge = {
  __typename: "OrgEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<Org>;
};

/** An invitation to join an org */
export type OrgInvite = Node & {
  __typename: "OrgInvite";
  createdTime?: Maybe<Scalars["Float"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  lastInvitingUserId?: Maybe<Scalars["String"]>;
  lastSentTime?: Maybe<Scalars["Float"]>;
  orgId?: Maybe<Scalars["String"]>;
  targetCalendarId?: Maybe<Scalars["String"]>;
  /** @deprecated Does not exist */
  targetProfile?: Maybe<UserProfile>;
  used?: Maybe<Scalars["Boolean"]>;
};

/** A connection to a list of items. */
export type OrgInviteConnection = {
  __typename: "OrgInviteConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrgInviteEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrgInviteEdge = {
  __typename: "OrgInviteEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<OrgInvite>;
};

/** Meeting hours for the org */
export type OrgMeetingHours = Node & {
  __typename: "OrgMeetingHours";
  hasBeenSet?: Maybe<Scalars["Boolean"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  orgId?: Maybe<Scalars["String"]>;
  workingHours?: Maybe<WorkingHours>;
};

/** Overview of organization-wide Clockwise usage */
export type OrgOverview = {
  __typename: "OrgOverview";
  conflictsResolvedCount: Array<IntegerDataPoint>;
  /** False if the org doesn't have enough data to display yet */
  doNotShowOverview: Scalars["Boolean"];
  focusTimeHoursCreatedCount: Array<IntegerDataPoint>;
  numUsersByFunction: Array<IntegerDataPoint>;
  numUsersByRole: Array<IntegerDataPoint>;
  scheduleAssistCount: Array<IntegerDataPoint>;
  scheduleAssistLimit: Scalars["Int"];
  userCount: Array<IntegerDataPoint>;
};

export type OrgOverviewErrorable = GraphEntityError | OrgOverview;

/** DESCRIBE NEW FIELD HERE */
export type OrgPerson = Node & {
  __typename: "OrgPerson";
  calendarAliases: Array<Scalars["String"]>;
  eligibleForTeams?: Maybe<Scalars["Boolean"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  isClockwiseUser: Scalars["Boolean"];
  isYou: Scalars["Boolean"];
  personId: Scalars["String"];
  primaryBillingGroupIdErrorable?: Maybe<PrimaryBillingGroupIdErrorable>;
  primaryCalendarId: Scalars["String"];
  profile?: Maybe<UserProfile>;
  redirectPersonId?: Maybe<Scalars["String"]>;
  signUpMillis?: Maybe<Scalars["BigInt"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type OrgPersonErrorable = GraphEntityError | OrgPerson;

export type OrgPersonList = {
  __typename: "OrgPersonList";
  list: Array<OrgPerson>;
};

export type OrgPersonListErrorable = GraphEntityError | OrgPersonList;

/** List of org persons along with a nexPersonId and previousPersonId for pagination. */
export type OrgPersonListPaginated = {
  __typename: "OrgPersonListPaginated";
  count?: Maybe<Scalars["Int"]>;
  inputQuery?: Maybe<Scalars["String"]>;
  nextPersonId?: Maybe<Scalars["String"]>;
  persons?: Maybe<Array<OrgPerson>>;
  previousPersonId?: Maybe<Scalars["String"]>;
  searchPersons?: Maybe<Array<SearchPerson>>;
};

export type OrgPersonListPaginatedErrorable = GraphEntityError | OrgPersonListPaginated;

/** Search results for org persons */
export type OrgPersonQueryResult = {
  __typename: "OrgPersonQueryResult";
  /** Persons that match the query */
  persons: Array<Maybe<OrgPerson>>;
  /** The input query string */
  query: Scalars["String"];
};

export type OrgPersonQueryResultErrorable = GraphEntityError | OrgPersonQueryResult;

/** A per org tag on an event */
export type OrgTag = {
  __typename: "OrgTag";
  /** The TagState for this tag */
  state: TagState;
  /** The tag name */
  tag: Scalars["String"];
};

export enum OrganizationOperationResultEnum {
  Add = "Add",
  Create = "Create",
  Delete = "Delete",
  Fetch = "Fetch",
}

/** A summary of a consequences for a diff proposed for other users' calendars */
export type OthersConsequenceDiffSummary = DiffSummary & {
  __typename: "OthersConsequenceDiffSummary";
  action: DiffAction;
  /** False if the diff has been rejected by the user */
  active: Scalars["Boolean"];
  affectedAttendees: Array<AffectedPerson>;
  currentTime: Scalars["Interval"];
  /** The ID of the event being modified */
  eventId: Scalars["String"];
  id: Scalars["ID"];
  /** Whether or not the event is flexible */
  isFlexible: Scalars["Boolean"];
  /** Either the existing title or the proposed new title */
  title: Scalars["String"];
  /** Null if the diff's time is not being updated or there were no times found */
  updatedTime?: Maybe<Scalars["Interval"]>;
};

export type OverrideSyncStatusForUserEventMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  externalEventId: Scalars["String"];
};

export type OverrideSyncStatusForUserEventMutationPayload = {
  __typename: "OverrideSyncStatusForUserEventMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type PatchBillingGroupMutationInput = {
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  invitePolicy: InvitePolicyEnum;
  name?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
};

export type PatchBillingGroupMutationPayload = {
  __typename: "PatchBillingGroupMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type PatchSlackSettingsMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["ID"];
  slackSettings: SlackSettingsInput;
};

export type PatchSlackSettingsMutationPayload = {
  __typename: "PatchSlackSettingsMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  slackSettings: SlackSettings;
};

export type PauseHoldInput = {
  /** The event id of the hold to protect */
  holdId: Scalars["EventId"];
  /** The graph id of the org */
  orgId: Scalars["ID"];
};

export type PauseHoldPayload = {
  __typename: "PauseHoldPayload";
  success: Scalars["Boolean"];
  updatedEvent: EventParent;
};

export type Paused = {
  __typename: "Paused";
  nop?: Maybe<Scalars["Void"]>;
};

/** DESCRIBE NEW FIELD HERE */
export type PaymentStateMetadata = {
  __typename: "PaymentStateMetadata";
  primaryBillingGroupMetadata?: Maybe<PrimaryBillingGroupMetadata>;
  userMetadata?: Maybe<UserMetadata>;
};

/** DESCRIBE NEW FIELD HERE */
export type PaymentStatusBadge = {
  __typename: "PaymentStatusBadge";
  text: PaymentStatusBadgeTextEnum;
  type: PaymentStatusBadgeTypeEnum;
};

export enum PaymentStatusBadgeTextEnum {
  Business = "Business",
  Enterprise = "Enterprise",
  Free = "Free",
  Pro = "Pro",
  Trial = "Trial",
}

export enum PaymentStatusBadgeTypeEnum {
  Blue = "Blue",
  Gradient = "Gradient",
}

/** A product subscription under an org for a particular subjectt */
export type PaymentSubscription = Node & {
  __typename: "PaymentSubscription";
  endMillis: Scalars["Int"];
  /** The ID of an object */
  id: Scalars["ID"];
  productType?: Maybe<ProductTypeEnum>;
  seatCount: Scalars["Int"];
  startMillis: Scalars["Int"];
  subjectUrn: Urn;
  type?: Maybe<SubscriptionTypeEnum>;
};

export type PaymentSubscriptionList = {
  __typename: "PaymentSubscriptionList";
  list: Array<PaymentSubscription>;
};

export type PaymentSubscriptionListErrorable = GraphEntityError | PaymentSubscriptionList;

/** A person */
export type Person = Node & {
  __typename: "Person";
  /** The emails of the person */
  emails: Array<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  isOutsideOrg: Scalars["Boolean"];
  isUser: Scalars["Boolean"];
  isYou: Scalars["Boolean"];
  /** The primary calendar of the person */
  primaryCalendar: Scalars["CalendarId"];
  /** The primary email of the person */
  primaryEmail: Scalars["String"];
  /** The profile of the person, may be null */
  profile?: Maybe<UserProfile>;
  userId?: Maybe<Scalars["String"]>;
};

export type PersonErrorable = GraphEntityError | Person;

export type PersonList = {
  __typename: "PersonList";
  list: Array<Person>;
};

export type PersonListErrorable = GraphEntityError | PersonList;

export type PersonMention = {
  /** The calendarId of the person mentioned. Acts as a unique identifier */
  calendarId: Scalars["CalendarId"];
  /** The name (given + family) of the person in the mention. String matches what was seen in chat */
  name: Scalars["String"];
};

/** Search results for persons */
export type PersonQueryResult = {
  __typename: "PersonQueryResult";
  /** Persons that match the query */
  persons: Array<Maybe<Person>>;
  /** The input query string */
  query: Scalars["String"];
};

export type PersonQueryResultErrorable = GraphEntityError | PersonQueryResult;

/** Plan tier information */
export type PlanTierFeatures = {
  __typename: "PlanTierFeatures";
  paymentStatusBadge?: Maybe<PaymentStatusBadge>;
};

export type PreAddOrgTagsMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  moveRangeV3?: InputMaybe<MoveRangeInLocalTimeV3Input>;
  orgRelayId: Scalars["ID"];
  preAnnotation: PreAnnotationInput;
};

export type PreAddOrgTagsMutationPayload = {
  __typename: "PreAddOrgTagsMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type PreAnnotationInput = {
  attendees: Array<Scalars["String"]>;
  calendarId: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  endTime: InferredEventTimeInput;
  startTime: InferredEventTimeInput;
  tags: Array<TagInput>;
  title?: InputMaybe<Scalars["String"]>;
};

export type PreDayOfWeekFlexibility = {
  allowedDays: Array<DayOfWeek>;
};

export type PreEventFlexibility = {
  roomFlexibility: PreRoomFlexibility;
  scheduledBy?: EventScheduledBy;
  timeOfDayFlexibility: MoveRangeInLocalTimeV3Input;
  timeRangeFlexibility: PreTimeRangeFlexibility;
};

export type PreEventKey = {
  attendees: Array<Scalars["CalendarId"]>;
  endTime: InferredEventTimeInput;
  startTime: InferredEventTimeInput;
  /** The event title, null if the event doesn't have a title */
  title?: InputMaybe<Scalars["String"]>;
};

export enum PreRangeFlexibility {
  Day = "Day",
  Week = "Week",
}

export enum PreRoomFlexibility {
  KeepCurrentRoom = "KeepCurrentRoom",
  SimilarRoomsAllowed = "SimilarRoomsAllowed",
}

export type PreSetFlexibilityInput = {
  calendarId: Scalars["CalendarId"];
  orgRelayId: Scalars["ID"];
  /** Event flexibility configuration */
  preEventFlexibility: PreEventFlexibility;
  /** A set of event properties that allows us to identify an event without its id */
  preEventKey: PreEventKey;
};

export type PreSetFlexibilityResponse = {
  __typename: "PreSetFlexibilityResponse";
  successful: Scalars["Boolean"];
};

/**
 *
 *                         OneOf RangeFlexibility or DayOfWeekFlexibility.
 *                         Because we don't have oneOf support yet, this is modelled as nullable fields, where exactly one field must be non-null
 *                         This is unfortunately validated at runtime, but it's the best we have for now.
 *
 */
export type PreTimeRangeFlexibility = {
  dayOfWeekFlexibility?: InputMaybe<PreDayOfWeekFlexibility>;
  rangeFlexibility?: InputMaybe<PreRangeFlexibility>;
};

export enum PreferredFocusTime {
  Afternoon = "Afternoon",
  Morning = "Morning",
  NoPreference = "NoPreference",
}

export type PreferredLunchTimes = {
  __typename: "PreferredLunchTimes";
  earliestStart: TimeSlot;
  idealDuration: Scalars["Duration"];
  latestEnd: TimeSlot;
  /** @deprecated use `latestEnd` instead */
  maxSlot: TimeSlot;
  /** @deprecated use `minimumDuration` instead */
  minDuration: Scalars["Duration"];
  /** @deprecated use `earliestStart` instead */
  minSlot: TimeSlot;
  minimumDuration: Scalars["Duration"];
};

export enum PreferredWorkType {
  Afternoon = "Afternoon",
  Any = "Any",
  Morning = "Morning",
}

export type PrimaryBillingGroupId = {
  __typename: "PrimaryBillingGroupId";
  primaryBillingGroupId?: Maybe<Scalars["String"]>;
};

export type PrimaryBillingGroupIdErrorable = GraphEntityError | PrimaryBillingGroupId;

/** Primary billing group metadata field */
export type PrimaryBillingGroupMetadata = {
  __typename: "PrimaryBillingGroupMetadata";
  billingGroup: BillingGroup;
  subscription: PaymentSubscription;
};

export type ProcessChatMessageInput = {
  /** User message direct CQL use in special cases to bypass gpt */
  cql?: InputMaybe<Scalars["String"]>;
  mentionsV2: MentionsV2;
  text: Scalars["String"];
};

export type ProcessMessagePayload = {
  __typename: "ProcessMessagePayload";
  /**
   * A text, viewEvents, or proposal response from the assistant
   * @deprecated Use updatedHistory instead
   */
  assistantMessage: AssistantMessage;
  /** an id for the chat conversation */
  conversationId: Scalars["ID"];
  debugInfo?: Maybe<Scalars["String"]>;
  /** The updated chat history, including the user message and processed assistant response */
  updatedHistory: ChatHistory;
  /**
   * The user-submitted message
   * @deprecated Use updatedHistory instead
   */
  userMessage: UserMessage;
};

export enum ProductType {
  Defender = "Defender",
  Defrag = "Defrag",
}

export enum ProductTypeEnum {
  Business_NonStandard = "Business_NonStandard",
  Business_Standard = "Business_Standard",
  Enterprise_NonStandard = "Enterprise_NonStandard",
  Enterprise_Standard = "Enterprise_Standard",
  Pro_NonStandard = "Pro_NonStandard",
  Pro_Standard = "Pro_Standard",
}

/** A time zone for a profile */
export type ProfileTimeZone = {
  __typename: "ProfileTimeZone";
  calendarId?: Maybe<Scalars["String"]>;
  timeZone: Scalars["String"];
  userId?: Maybe<Scalars["String"]>;
};

export type ProfileTimeZoneList = {
  __typename: "ProfileTimeZoneList";
  list: Array<ProfileTimeZone>;
};

export type ProfileTimeZoneListErrorable = GraphEntityError | ProfileTimeZoneList;

export type ProposalAttendee = {
  __typename: "ProposalAttendee";
  attendeePerson: Person;
  attendeeResponseStatus: ResponseStatusEnum;
  isOptional: Scalars["Boolean"];
  isOrganizer?: Maybe<Scalars["Boolean"]>;
};

/** A list of attendees on a diff along with any attendee ambiguities */
export type ProposalAttendees = {
  __typename: "ProposalAttendees";
  ambiguities: Array<AttendeeAmbiguity>;
  /** @deprecated use proposalAttendees instead, it contains responseStatus information */
  attendeePeopleErrorable: PersonListErrorable;
  proposalAttendees: Array<ProposalAttendee>;
};

/** Flexibility settings of a proposal event */
export type ProposalFlexibility = {
  __typename: "ProposalFlexibility";
  /** Whether the event is marked as flex */
  isFlexible: Scalars["Boolean"];
  roomIsFlexible: Scalars["Boolean"];
  /** How an event can move within a day, null if not configured */
  timeOfDayFlexibility?: Maybe<ProposalTimeOfDayFlex>;
  /** How an event can move between days */
  timeRangeFlexibility: TimeRangeFlexibility;
};

/** Flexibility settings of a proposal event */
export type ProposalFlexibilityInput = {
  /** Whether the event is marked as flex */
  isFlexible: Scalars["Boolean"];
  /** How an event can move within a day, null if not configured */
  timeOfDayFlexibility?: InputMaybe<ProposalTimeOfDayFlexInput>;
  /** How an event can move between days */
  timeRangeFlexibility?: InputMaybe<ProposalTimeRangeFlexibilityInput>;
};

export type ProposalOptions = NoSchedulingOptions | SchedulingOptions;

/** Modifications to recurrence rules for an event */
export type ProposalRecurrenceInput = {
  /** Interval of the event recurrence */
  intervalCount?: InputMaybe<Scalars["Int"]>;
  /** Type of the event recurrence */
  intervalType?: InputMaybe<RecurrenceIntervalTypeEnum>;
  /** Recurrence rule for the event */
  recurrenceRule?: InputMaybe<Scalars["RecurrenceRule"]>;
};

/** A proposal response from the assistant in response to a (re)schedule or update events request */
export type ProposalResponse = {
  __typename: "ProposalResponse";
  conversationId: Scalars["ID"];
  id: Scalars["ID"];
  proposal: SchedulingProposal;
};

export type ProposalTimeOfDayFlex = {
  __typename: "ProposalTimeOfDayFlex";
  /** End of allowed time range for moving an event, null if not bounded by an explicit end time */
  endTime?: Maybe<Scalars["LocalTime"]>;
  /** Beginning of allowed time range for moving an event, null if not bounded by an explicit start time */
  startTime?: Maybe<Scalars["LocalTime"]>;
};

export type ProposalTimeOfDayFlexInput = {
  /** End of allowed time range for moving an event, null if not bounded by an explicit end time */
  endTime?: InputMaybe<Scalars["LocalTime"]>;
  /** Beginning of allowed time range for moving an event, null if not bounded by an explicit start time */
  startTime?: InputMaybe<Scalars["LocalTime"]>;
};

export type ProposalTimeRangeFlexibilityInput = {
  allowedDays: Array<DayOfWeek>;
  range?: InputMaybe<FlexibilityRange>;
};

export type ProtectHoldInput = {
  /** The event id of the hold to protect */
  holdId: Scalars["EventId"];
  /** The graph id of the org */
  orgId: Scalars["ID"];
};

/**
 *
 *           Smart holds that are protected won't be moved automatically, and will modify
 *           any conflicting user events, to ensure there is dedicated time for the smart hold
 *
 */
export type Protected = {
  __typename: "Protected";
  nop?: Maybe<Scalars["Void"]>;
};

export type ProtectedHoldPayload = {
  __typename: "ProtectedHoldPayload";
  success: Scalars["Boolean"];
  updatedEvent: EventParent;
};

export type Query = {
  __typename: "Query";
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Undocumented interface to fetch nodes by ids as defined in graphql-relay node.js */
  nodes: Array<Maybe<Node>>;
  viewer: Viewer;
};

export type QueryNodeArgs = {
  id: Scalars["ID"];
};

export type QueryNodesArgs = {
  ids: Array<Scalars["ID"]>;
};

export enum QuestionType {
  DoNotKnowHowItWillAffectTeammates = "DoNotKnowHowItWillAffectTeammates",
  DoNotNeedClockwiseToMoveMeetings = "DoNotNeedClockwiseToMoveMeetings",
  DoNotTrustClockwiseToMoveMeetings = "DoNotTrustClockwiseToMoveMeetings",
  DoNotUnderstandFlexibleMeetings = "DoNotUnderstandFlexibleMeetings",
  HelpWithFindingUninterruptedTimeToFocus = "HelpWithFindingUninterruptedTimeToFocus",
  HelpWithSchedulingExternalMeetingsEffortlessly = "HelpWithSchedulingExternalMeetingsEffortlessly",
  HelpWithSettingHealthyWorkLifeBoundaries = "HelpWithSettingHealthyWorkLifeBoundaries",
  HelpWithSimplifyingSchedulingWithCoworkers = "HelpWithSimplifyingSchedulingWithCoworkers",
  HelpWithSomethingElse = "HelpWithSomethingElse",
  HowCanWeMakeClockwiseMoreUseful = "HowCanWeMakeClockwiseMoreUseful",
  HowDidYouHearAboutClockwise = "HowDidYouHearAboutClockwise",
  HowManyPeopleOnTeam = "HowManyPeopleOnTeam",
  HowWouldYouFeelIfNoClockwise = "HowWouldYouFeelIfNoClockwise",
  OtherFreeFormReason = "OtherFreeFormReason",
  SuggestedMeetingsAreNotMovable = "SuggestedMeetingsAreNotMovable",
  WhatIsMainBenefitOfClockwise = "WhatIsMainBenefitOfClockwise",
  WhatIsYourCurrentPosition = "WhatIsYourCurrentPosition",
  WhatToUseAsClockwiseAlternative = "WhatToUseAsClockwiseAlternative",
  WhatTypeOfWorkDoYouDo = "WhatTypeOfWorkDoYouDo",
  WhatsYourTimeManagementGoal = "WhatsYourTimeManagementGoal",
}

export enum QuotaAutopilotStatusCalloutEnumType {
  ApproachingLimit = "ApproachingLimit",
  NoEnforcement = "NoEnforcement",
  ReachedLimit = "ReachedLimit",
}

export enum QuotaStatusBannerEnum {
  ApproachingLimit = "ApproachingLimit",
  NoEnforcement = "NoEnforcement",
  ReachedLimit = "ReachedLimit",
}

export enum QuotaStatusBannerEnumType {
  ApproachingLimit = "ApproachingLimit",
  NoEnforcement = "NoEnforcement",
  ReachedLimit = "ReachedLimit",
}

export type RangeFlexibility = {
  __typename: "RangeFlexibility";
  range: FlexibilityRange;
};

export type RawCalendarScores = {
  __typename: "RawCalendarScores";
  calendarId: Scalars["CalendarId"];
  scores: Array<RawScore>;
};

export type RawScore = {
  __typename: "RawScore";
  delta: Scalars["Float"];
  feature: Scalars["String"];
};

export type RecordChromeInstallMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type RecordChromeInstallMutationPayload = {
  __typename: "RecordChromeInstallMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type RecordChromeUninstallMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type RecordChromeUninstallMutationPayload = {
  __typename: "RecordChromeUninstallMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type RecordDefragResponseMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  feedback?: InputMaybe<DefragFeedback>;
  orgId: Scalars["String"];
  otherReason?: InputMaybe<Scalars["String"]>;
  preDefinedReasons?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  responseKeys: Array<InputMaybe<Scalars["JSON"]>>;
};

export type RecordDefragResponseMutationPayload = {
  __typename: "RecordDefragResponseMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  response?: Maybe<Scalars["Boolean"]>;
};

export type RecordImpressionMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  treatmentType: Scalars["String"];
};

export type RecordImpressionMutationPayload = {
  __typename: "RecordImpressionMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export enum RecurrenceIntervalTypeEnum {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  NONE = "NONE",
  WEEKLY = "WEEKLY",
  YEARLY = "YEARLY",
}

/** Full set of rules for how an event recurs */
export type RecurrenceRules = {
  __typename: "RecurrenceRules";
  /** Instances that have been removed from this set of recurrence rules */
  cancelledInstances?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The start time of the recurrence rules */
  firstInstanceStartTime?: Maybe<EventTime>;
  /** The interval definition for the recurrence */
  interval?: Maybe<IntervalDefinition>;
  /** The timezone of the recurrence rules */
  timeZone: Scalars["String"];
};

/**
 *
 *               A simplification of recurrence; we classify a recurrence by the frequency which is _greater or equal_ its actual frequency.
 *
 *               examples
 *               | Event             | RecurrenceType  |
 *               | ----------------- | --------------- |
 *               | every day         | Daily           |
 *               | twice a week      | Weekly          |
 *               | every other week  | Fortnightly     |
 *               | every third week  | Monthly         |
 *               | once a year       | LessThanMonthly |
 */
export enum RecurrenceType {
  Daily = "Daily",
  Fortnightly = "Fortnightly",
  LessThanMonthly = "LessThanMonthly",
  Monthly = "Monthly",
  Weekly = "Weekly",
}

export type RecurringEvent = {
  __typename: "RecurringEvent";
  /**
   *
   *             The id of a random instance in the recurrence, useful for mutations that operate on an instance but can
   *             actually mutate the recurrence (e.g. flexibility).
   */
  anInstanceId: Scalars["CalendarEventId"];
  attendees: Array<EventAttendee>;
  creator: EventCreator;
  duration: Scalars["Duration"];
  firstInstanceStart: Scalars["DateTime"];
  flexibility: FlexibleOrNot;
  flexibilityConstraints?: Maybe<FlexibilityConstraints>;
  id: Scalars["RecurringEventId"];
  organizer: EventOrganizer;
  recurrenceType: RecurrenceType;
  /** Human-readable description of the events time (and flexibility if enabled) */
  timeDescription?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export enum RefreshTokenResultOperationEnum {
  Create = "Create",
  Fetch = "Fetch",
}

export type RegisterEmailUpdatesInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
};

export type RegisterEmailUpdatesPayload = {
  __typename: "RegisterEmailUpdatesPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Settings about how to represent the smart hold in a remote system */
export type RemoteSmartHoldSettings = {
  __typename: "RemoteSmartHoldSettings";
  notificationStrategy: NotificationStrategy;
  scheduleAs: ScheduleAs;
};

export type RemoveAdminForBillingGroupMutationInput = {
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  orgRelayId: Scalars["String"];
  userId: Scalars["String"];
};

export type RemoveAdminForBillingGroupMutationPayload = {
  __typename: "RemoveAdminForBillingGroupMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type RemoveDiffFromProposalInput = {
  diffId: Scalars["ID"];
};

export type RemoveDiffFromProposalPayload = {
  __typename: "RemoveDiffFromProposalPayload";
  message: AssistantMessage;
};

export type RemoveGoalMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  goal: GoalInput;
  orgId: Scalars["String"];
};

export type RemoveGoalMutationPayload = {
  __typename: "RemoveGoalMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  goal?: Maybe<Goal>;
};

export type RemoveLunchHoldMutationInput = {
  calendarId?: InputMaybe<Scalars["String"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isPaused?: InputMaybe<Scalars["Boolean"]>;
  lunchEventId: Scalars["String"];
  orgRelayId: Scalars["String"];
};

export type RemoveLunchHoldMutationPayload = {
  __typename: "RemoveLunchHoldMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type RemoveManualMembersForBillingGroupMutationInput = {
  addExclusions?: InputMaybe<Scalars["Boolean"]>;
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  personIds: Array<Scalars["String"]>;
};

export type RemoveManualMembersForBillingGroupMutationPayload = {
  __typename: "RemoveManualMembersForBillingGroupMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type RemoveOrgTagsMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  eventParentRelayId: Scalars["String"];
  orgRelayId: Scalars["String"];
  tagNames: Array<Scalars["String"]>;
};

export type RemoveOrgTagsMutationPayload = {
  __typename: "RemoveOrgTagsMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventParent?: Maybe<EventParent>;
};

export enum RepeatingEventSaveOption {
  AllInstances = "AllInstances",
  ThisAndFutureInstances = "ThisAndFutureInstances",
  ThisInstanceOnly = "ThisInstanceOnly",
}

export type RescheduleEventParentMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  eventTime: EventTimeInput;
  externalEventId: Scalars["String"];
  orgRelayId: Scalars["String"];
};

export type RescheduleEventParentMutationPayload = {
  __typename: "RescheduleEventParentMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventParent: EventParent;
};

export type ResetConversationPayload = {
  __typename: "ResetConversationPayload";
  success: Scalars["Boolean"];
  text: Scalars["String"];
};

export type ResolveConflictInput = {
  /** The action to resolve the conflict */
  actionToResolve: ConflictAction;
  clusterId: Scalars["String"];
  eventId: Scalars["String"];
  /** The new start time for the event */
  newStartTime?: InputMaybe<Scalars["DateTime"]>;
};

export type ResolveConflictPayload = {
  __typename: "ResolveConflictPayload";
  eventId: Scalars["String"];
  message: AssistantMessage;
};

export enum ResponseStatusEnum {
  Accepted = "Accepted",
  Declined = "Declined",
  NeedsAction = "NeedsAction",
  NotApplicable = "NotApplicable",
  Tentative = "Tentative",
}

export enum ResponseType {
  AccountingOrFinance = "AccountingOrFinance",
  Administrative = "Administrative",
  Between5And9 = "Between5And9",
  Between10And15 = "Between10And15",
  Between15And20 = "Between15And20",
  BusinessOwner = "BusinessOwner",
  Checked = "Checked",
  CreateMoreTimeForFocusedWork = "CreateMoreTimeForFocusedWork",
  CustomerSupport = "CustomerSupport",
  DesignOrUxResearch = "DesignOrUxResearch",
  DirectorOrDepartmentLeader = "DirectorOrDepartmentLeader",
  ExecutiveOrBusinessLeader = "ExecutiveOrBusinessLeader",
  FoundOnline = "FoundOnline",
  Freeform = "Freeform",
  FriendOrFamily = "FriendOrFamily",
  IDoNotKnow = "IDoNotKnow",
  IDoNotWorkOnAnyTeams = "IDoNotWorkOnAnyTeams",
  IndividualContributor = "IndividualContributor",
  InternetSearch = "InternetSearch",
  It = "It",
  JustLookingAround = "JustLookingAround",
  Legal = "Legal",
  LessThan5 = "LessThan5",
  Manager = "Manager",
  Marketing = "Marketing",
  MoreThan20 = "MoreThan20",
  MyMeetingsOnAutopilot = "MyMeetingsOnAutopilot",
  NotDisappointed = "NotDisappointed",
  OnlineAdvertisement = "OnlineAdvertisement",
  Operations = "Operations",
  Other = "Other",
  PeopleOpsOrHr = "PeopleOpsOrHr",
  ProductManagement = "ProductManagement",
  ProgramOrProjectManagement = "ProgramOrProjectManagement",
  Recruiting = "Recruiting",
  ReserveTimeForPersonalObligations = "ReserveTimeForPersonalObligations",
  ResolveConflictsAutomatically = "ResolveConflictsAutomatically",
  SalesOrBusinessDevelopment = "SalesOrBusinessDevelopment",
  ScheduleEasilyWithMyTeam = "ScheduleEasilyWithMyTeam",
  ScheduleExternalMeetingsSeamlessly = "ScheduleExternalMeetingsSeamlessly",
  SocialMedia = "SocialMedia",
  SoftwareEngineering = "SoftwareEngineering",
  SomewhatDisappointed = "SomewhatDisappointed",
  TeammateAutopilot = "TeammateAutopilot",
  TeammateCalendar = "TeammateCalendar",
  TeammateFocusTime = "TeammateFocusTime",
  TeammateInvited = "TeammateInvited",
  TeammatePersonalCalendar = "TeammatePersonalCalendar",
  TeammatePrepTime = "TeammatePrepTime",
  TeammateSlackStatus = "TeammateSlackStatus",
  TeammateTravelTime = "TeammateTravelTime",
  Unchecked = "Unchecked",
  VeryDisappointed = "VeryDisappointed",
  VpOrDivisionLeader = "VpOrDivisionLeader",
}

/** A room */
export type Room = Node & {
  __typename: "Room";
  estimatedCapacity?: Maybe<Scalars["Int"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  /** @deprecated Not populated, always null */
  locationId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  orgId: Scalars["String"];
  reportedCapacity?: Maybe<Scalars["Int"]>;
  roomId: Scalars["String"];
};

export enum RoomChangePolicy {
  CanChangeRooms = "CanChangeRooms",
  CannotChangeRooms = "CannotChangeRooms",
  /** Don't change the room flexibility */
  Unchanged = "Unchanged",
}

export type RoomFlexibilityUpdated = {
  __typename: "RoomFlexibilityUpdated";
  updatedEvents?: Maybe<Array<EventParent>>;
};

export type RoomFlexibilityUpdatedUpdatedEventsArgs = {
  calendarId?: InputMaybe<Scalars["CalendarId"]>;
};

export type RoomList = {
  __typename: "RoomList";
  list: Array<Room>;
};

export type RoomListErrorable = GraphEntityError | RoomList;

export enum RsvpFilterEnum {
  Accepted = "Accepted",
  Declined = "Declined",
  NeedsAction = "NeedsAction",
  Tentative = "Tentative",
}

export type SaveAddEventDiffDetailsInput = {
  attendees: Array<Scalars["CalendarId"]>;
  attendeesList?: InputMaybe<Array<AttendeesListSaveAddEventDiff>>;
  /** Type of video conference used for the event */
  conferenceType?: InputMaybe<ConferencingType>;
  description: Scalars["String"];
  diffId: Scalars["ID"];
  flexDetails?: InputMaybe<ProposalFlexibilityInput>;
  /** Whether the event is an all day event */
  isAllDay?: InputMaybe<Scalars["Boolean"]>;
  isFlexible?: InputMaybe<Scalars["Boolean"]>;
  location: Scalars["String"];
  recurrence?: InputMaybe<ProposalRecurrenceInput>;
  time: Scalars["Interval"];
  title: Scalars["String"];
};

export type SaveAddEventDiffDetailsPayload = {
  __typename: "SaveAddEventDiffDetailsPayload";
  message: AssistantMessage;
};

export type SaveFlexStatusInput = {
  diffId: Scalars["ID"];
  isFlexible?: InputMaybe<Scalars["Boolean"]>;
};

export type SaveFlexStatusPayload = {
  __typename: "SaveFlexStatusPayload";
  message: AssistantMessage;
};

export type SaveModifyEventDiffDetailsInput = {
  attendees: Array<Scalars["CalendarId"]>;
  attendeesList?: InputMaybe<Array<AttendeesListSaveModifyEventDiff>>;
  /** Type of video conference used for the event */
  conferenceType?: InputMaybe<ConferencingType>;
  description: Scalars["String"];
  diffId: Scalars["ID"];
  flexDetails?: InputMaybe<ProposalFlexibilityInput>;
  /** Whether the event is an all day event */
  isAllDay?: InputMaybe<Scalars["Boolean"]>;
  isFlexible?: InputMaybe<Scalars["Boolean"]>;
  location: Scalars["String"];
  recurrence?: InputMaybe<ProposalRecurrenceInput>;
  /** Whether or not to save future events or just this instance */
  repeatingEventSaveOption: RepeatingEventSaveOption;
  rsvp: ResponseStatusEnum;
  time: Scalars["Interval"];
  title: Scalars["String"];
};

export type SaveModifyEventDiffDetailsPayload = {
  __typename: "SaveModifyEventDiffDetailsPayload";
  message: AssistantMessage;
};

export type SavePushServiceSubscriptionMutationInput = {
  /** An authentication secret */
  auth: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A string containing the endpoint associated with the push subscription. */
  endpoint: Scalars["String"];
  /** The client public key, which will be sent to the server and used in encrypting push message data */
  publicKey: Scalars["String"];
};

export type SavePushServiceSubscriptionMutationPayload = {
  __typename: "SavePushServiceSubscriptionMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SaveSharedProposalEventDiffDetailsInput = {
  attendees: Array<Scalars["CalendarId"]>;
  attendeesList?: InputMaybe<Array<AttendeesListSharedProposalSaveAddEventDiff>>;
  /** Type of video conference used for the event */
  conferenceType?: InputMaybe<ConferencingType>;
  description: Scalars["String"];
  diffId: Scalars["ID"];
  flexDetails?: InputMaybe<ProposalFlexibilityInput>;
  /** Whether the event is an all-day event */
  isAllDay?: InputMaybe<Scalars["Boolean"]>;
  isFlexible?: InputMaybe<Scalars["Boolean"]>;
  location: Scalars["String"];
  proposalId: Scalars["ID"];
  recurrence?: InputMaybe<ProposalRecurrenceInput>;
  time: Scalars["Interval"];
  title: Scalars["String"];
};

export type SaveSharedProposalEventDiffDetailsPayload = {
  __typename: "SaveSharedProposalEventDiffDetailsPayload";
  proposal: SchedulingProposal;
};

export type SaveUninstallResponseMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  considerClockwiseFuture?: InputMaybe<Scalars["String"]>;
  more?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<Scalars["String"]>;
};

export type SaveUninstallResponseMutationPayload = {
  __typename: "SaveUninstallResponseMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SaveUserSurveyResponseWrapperMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  userSurveyResponseWrapper: UserSurveyResponseWrapperInput;
};

export type SaveUserSurveyResponseWrapperMutationPayload = {
  __typename: "SaveUserSurveyResponseWrapperMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  userSurveyResponseWrapper: UserSurveyResponseWrapper;
};

export type SaveUserSurveyResponsesMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  userSurveyResponses?: InputMaybe<Array<InputMaybe<UserSurveyResponseInput>>>;
};

export type SaveUserSurveyResponsesMutationPayload = {
  __typename: "SaveUserSurveyResponsesMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  userSurveyResponse?: Maybe<UserSurveyResponse>;
};

export enum ScheduleAs {
  Busy = "Busy",
  Free = "Free",
}

export type ScheduleNewEventV2MutationInput = {
  attendees?: InputMaybe<Array<Scalars["String"]>>;
  calendarId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  conferenceType?: InputMaybe<ConferenceTypeEnum>;
  conflictResolutionDefragId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  includeZoomLink?: InputMaybe<Scalars["Boolean"]>;
  location?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  timeRange: TimeRangeInput;
  timeZone: Scalars["String"];
  title?: InputMaybe<Scalars["String"]>;
};

export type ScheduleNewEventV2MutationPayload = {
  __typename: "ScheduleNewEventV2MutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventParent: EventParent;
};

export type ScheduleNewSmartHoldMutationInput = {
  calendarId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  smartHoldType: SmartHoldTypeEnum;
  timeRange: TimeRangeInput;
};

export type ScheduleNewSmartHoldMutationPayload = {
  __typename: "ScheduleNewSmartHoldMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventParent: EventParent;
};

export enum SchedulingAttendanceSummaryTag {
  Best = "Best",
  Conflict = "Conflict",
  Current = "Current",
  Good = "Good",
  InFocusTime = "InFocusTime",
  Inconvenient = "Inconvenient",
  MostFocus = "MostFocus",
  OverLunch = "OverLunch",
  Soonest = "Soonest",
}

/** Scheduling Link */
export type SchedulingLink = {
  __typename: "SchedulingLink";
  linkName: Scalars["String"];
  linkUrl: Scalars["String"];
  meetingSettings: MeetingSettings;
};

/** Metadata for a scheduling link */
export type SchedulingLinkMetadata = {
  __typename: "SchedulingLinkMetadata";
  link: SchedulingLink;
  operation: SchedulingLinkOperationEnum;
};

export enum SchedulingLinkOperationEnum {
  CREATE = "CREATE",
  FIND = "FIND",
  SET_ACTIVE = "SET_ACTIVE",
  SET_INACTIVE = "SET_INACTIVE",
  UPDATE = "UPDATE",
}

/** A response from the assistant for creating or updating scheduling link(s) request */
export type SchedulingLinksResponse = {
  __typename: "SchedulingLinksResponse";
  conversationId: Scalars["ID"];
  id: Scalars["ID"];
  linksMetadata: Array<SchedulingLinkMetadata>;
  responseText: Scalars["String"];
};

export type SchedulingMove = {
  __typename: "SchedulingMove";
  event?: Maybe<EventParent>;
  from: Scalars["DateTime"];
  organizer: Scalars["CalendarId"];
  to: Scalars["DateTime"];
};

export type SchedulingOption = {
  __typename: "SchedulingOption";
  /** An optional category for this scheduling option: availability issue, fixable conflict,  etc. */
  category?: Maybe<SchedulingOptionCategoryTypeEnum>;
  consequencesBlock?: Maybe<ConsequencesBlock>;
  /** The ID of an object */
  id: Scalars["ID"];
  interval: Scalars["Interval"];
  /** The label for this option */
  label: Scalars["String"];
  tradeoffBlocks: Array<TradeoffBlock>;
  /** Whether this option is an INITIAL, CUSTOM, or ADDITIONAL suggestion */
  type: SchedulingOptionTypeEnum;
};

export enum SchedulingOptionCategoryTypeEnum {
  AVAILABILITY_ISSUE = "AVAILABILITY_ISSUE",
  FIXABLE_CONFLICT = "FIXABLE_CONFLICT",
  INCONVENIENCE = "INCONVENIENCE",
  OUT_OF_TIME_RANGE = "OUT_OF_TIME_RANGE",
}

export enum SchedulingOptionTypeEnum {
  ADDITIONAL = "ADDITIONAL",
  CUSTOM = "CUSTOM",
  INITIAL = "INITIAL",
}

/** A list of scheduling options for a proposal */
export type SchedulingOptions = {
  __typename: "SchedulingOptions";
  /** The details for each option (in the correct order for `selectedIndex`) */
  optionDetails: Array<SchedulingOption>;
  /** The index of the selected option */
  selectedIndex: Scalars["Int"];
};

/** A proposal for (re)scheduling or updating events */
export type SchedulingProposal = {
  __typename: "SchedulingProposal";
  availability?: Maybe<Availability>;
  /** List of conflicts */
  conflictClusters: Array<ConflictCluster>;
  /** Message of changes the user has done to resolve conflicts */
  conflictReceipt?: Maybe<ConflictReceipt>;
  /** A block describing the consequences of the proposed changes to the user's calendar corresponding to the currently selected scheduling option, if any */
  consequencesBlock?: Maybe<ConsequencesBlock>;
  /** A list of diffs describing the proposed changes to the user's calendar corresponding to the currently selected scheduling option, if any */
  diffBlocks: Array<DiffBlock>;
  eventSearchBlocks: Array<EventSearchBlock>;
  id: Scalars["ID"];
  /** Whether this proposal is shared with others */
  isShared: Scalars["Boolean"];
  options: ProposalOptions;
  /** The ID of the proposal used for shared proposal, i.e. isShared = true */
  proposalId: Scalars["ID"];
  state: SchedulingProposalStateEnum;
};

export enum SchedulingProposalStateEnum {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  INCOMPLETE = "INCOMPLETE",
  LOADING_SUGGESTIONS = "LOADING_SUGGESTIONS",
  SOFT_CONFIRM = "SOFT_CONFIRM",
}

export type SchedulingSlot = {
  __typename: "SchedulingSlot";
  additionalChanges: Array<SchedulingMove>;
};

/** A specific scheduling tradeoff for a diff */
export type SchedulingTradeoff = {
  __typename: "SchedulingTradeoff";
  attendees: Array<OrgPerson>;
  /** The title of the event */
  eventTitle: Scalars["String"];
  externalEventId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Why this meeting could not be rescheduled, thus making it a tradeoff. */
  reasonForInaction: TradeoffInactionReason;
  /** The title of the tradeoff, includes the pill structuring. */
  title: Scalars["String"];
};

export type ScoredTime = {
  __typename: "ScoredTime";
  tradeoffs: Array<TradeoffBlock>;
};

/** Scoring overrides for the calling user. If not provided, defaults to 1.0 for all fields. */
export type ScoringWeightOverrides = {
  /** Penalize events scheduled in the last hour of the work week */
  commonlyDislikedTimes: Scalars["Float"];
  /** A small penalty for all moves; moving events is inherently painful */
  eventChangeDisruption: Scalars["Float"];
  focusTime: Scalars["Float"];
  lunchConflict: Scalars["Float"];
  meetingConflict: Scalars["Float"];
  meetingRelief: Scalars["Float"];
  noMeetingDay: Scalars["Float"];
  /** Penalize moves that take an event off the "grid" of the calendar */
  offGridPenalty: Scalars["Float"];
  outsideMeetingHours: Scalars["Float"];
  outsideWorkingHours: Scalars["Float"];
  preferredWorkingHours: Scalars["Float"];
  travelTime: Scalars["Float"];
};

/** A wrapper around org person for the admin panel members tab */
export type SearchPerson = {
  __typename: "SearchPerson";
  person: OrgPerson;
  status: SearchPersonStatusResponse;
};

export enum SearchPersonStatusResponse {
  Deactivated = "Deactivated",
  Invited = "Invited",
  NonUser = "NonUser",
  User = "User",
}

export type SelectSchedulingOptionInput = {
  selectedIndex: Scalars["Int"];
};

export type SelectSchedulingOptionPayload = {
  __typename: "SelectSchedulingOptionPayload";
  message: AssistantMessage;
  updatedProposal: SchedulingProposal;
};

export type SelectSharedOptionPayload = {
  __typename: "SelectSharedOptionPayload";
  proposal: SchedulingProposal;
};

export type SendBillingGroupRequestEmailMutationInput = {
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  personId: Scalars["String"];
};

export type SendBillingGroupRequestEmailMutationPayload = {
  __typename: "SendBillingGroupRequestEmailMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SendM365TeamAvailabilityCalendarInviteMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  personIds: Array<Scalars["String"]>;
  teamId: Scalars["String"];
};

export type SendM365TeamAvailabilityCalendarInviteMutationPayload = {
  __typename: "SendM365TeamAvailabilityCalendarInviteMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SendM365TeamAvailabilityCalendarInvitesForTeamMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  teamId: Scalars["String"];
};

export type SendM365TeamAvailabilityCalendarInvitesForTeamMutationPayload = {
  __typename: "SendM365TeamAvailabilityCalendarInvitesForTeamMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SendSeatQuantityChangeRequestMutationInput = {
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgId: Scalars["String"];
  requestedQuantity: Scalars["Int"];
};

export type SendSeatQuantityChangeRequestMutationPayload = {
  __typename: "SendSeatQuantityChangeRequestMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SetCalendarEventFlexibilityInput = {
  eventsToDisable?: Array<Scalars["CalendarEventId"]>;
  eventsToEnable?: Array<Scalars["CalendarEventId"]>;
  /** The id field of the org */
  orgId: Scalars["ID"];
};

export type SetCalendarEventFlexibilityPayload = {
  __typename: "SetCalendarEventFlexibilityPayload";
  updatedEvents?: Maybe<Array<CalendarEvent>>;
};

export type SetPreferredRoomsMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId?: InputMaybe<Scalars["ID"]>;
  preferredRoomIds: Array<Scalars["String"]>;
};

export type SetPreferredRoomsMutationPayload = {
  __typename: "SetPreferredRoomsMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestedRoomPreferences: SuggestedRoomPreferences;
};

export type SetWorkAddressForUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  rawAddress?: InputMaybe<Scalars["String"]>;
};

export type SetWorkAddressForUserMutationPayload = {
  __typename: "SetWorkAddressForUserMutationPayload";
  address?: Maybe<Address>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** A setting */
export type Setting = Node & {
  __typename: "Setting";
  /** The ID of an object */
  id: Scalars["ID"];
  key?: Maybe<SettingKey>;
  type?: Maybe<SettingType>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/** A connection to a list of items. */
export type SettingConnection = {
  __typename: "SettingConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SettingEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SettingEdge = {
  __typename: "SettingEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<Setting>;
};

export type SettingInput = {
  id: Scalars["ID"];
  key?: InputMaybe<SettingKeyInput>;
  type?: InputMaybe<SettingTypeInput>;
  value?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** A setting key */
export type SettingKey = Node & {
  __typename: "SettingKey";
  /** The ID of an object */
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  scope?: Maybe<Urn>;
  target?: Maybe<Urn>;
};

export type SettingKeyInput = {
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  scope?: InputMaybe<UrnInput>;
  target?: InputMaybe<UrnInput>;
};

/** A setting type */
export type SettingType = Node & {
  __typename: "SettingType";
  containerType?: Maybe<ContainerType>;
  /** The ID of an object */
  id: Scalars["ID"];
  required?: Maybe<Scalars["Boolean"]>;
  valueType?: Maybe<ValueType>;
};

export type SettingTypeInput = {
  containerType?: InputMaybe<ContainerType>;
  id: Scalars["ID"];
  required?: InputMaybe<Scalars["Boolean"]>;
  valueType?: InputMaybe<ValueType>;
};

/** A container of settings for the users shadow calendar */
export type ShadowCalendarSettings = Node & {
  __typename: "ShadowCalendarSettings";
  enabled: Scalars["Boolean"];
  focusTimeExternalTransparency?: Maybe<FocusTimeExternalTransparency>;
  focusTimeIdealMinutesPerWeek: Scalars["Int"];
  focusTimeRemindersEnabled: Scalars["Boolean"];
  focusTimeSyncAll: Scalars["Boolean"];
  focusTimeSyncEnabled: Scalars["Boolean"];
  /** The ID of an object */
  id: Scalars["ID"];
  lunchRemindersEnabled: Scalars["Boolean"];
  lunchSyncEnabled: Scalars["Boolean"];
  shadowCalendarId?: Maybe<Scalars["String"]>;
  travelTimeRemindersEnabled: Scalars["Boolean"];
  travelTimeSyncEnabled: Scalars["Boolean"];
};

export type ShadowCalendarSettingsErrorable = GraphEntityError | ShadowCalendarSettings;

export type ShareProposalPayload = {
  __typename: "ShareProposalPayload";
  sharedProposalLink: Scalars["String"];
};

/** A shared proposal for the user to view/edit or confirm/decline */
export type SharedProposal = {
  __typename: "SharedProposal";
  hasEditPermission: Scalars["Boolean"];
  ownerCalendarId: Scalars["String"];
  proposal: SchedulingProposal;
};

export type SharedProposalInput = {
  proposalId: Scalars["ID"];
};

/** Slack Account */
export type SlackAccount = Node & {
  __typename: "SlackAccount";
  /** The ID of an object */
  id: Scalars["ID"];
  needsUpdate: Scalars["Boolean"];
  slackTeamId?: Maybe<Scalars["String"]>;
  slackUserId?: Maybe<Scalars["String"]>;
  teamDisplayName?: Maybe<Scalars["String"]>;
};

export type SlackAccountErrorable = GraphEntityError | SlackAccount;

export type SlackAccountList = {
  __typename: "SlackAccountList";
  list: Array<SlackAccount>;
};

export type SlackAccountListErrorable = GraphEntityError | SlackAccountList;

export type SlackCalendarStatusSync = {
  __typename: "SlackCalendarStatusSync";
  calendarStatusEnabled: Scalars["Boolean"];
  includeMeetingCategory: Scalars["Boolean"];
  includeMeetingTitle: Scalars["Boolean"];
};

export type SlackCalendarStatusSyncInput = {
  calendarStatusEnabled: Scalars["Boolean"];
  includeMeetingCategory: Scalars["Boolean"];
  includeMeetingTitle: Scalars["Boolean"];
};

/** Slack channel */
export type SlackChannel = {
  __typename: "SlackChannel";
  channelId: Scalars["String"];
  name: Scalars["String"];
};

export type SlackChannelList = {
  __typename: "SlackChannelList";
  list: Array<SlackChannel>;
};

export type SlackChannelListErrorable = GraphEntityError | SlackChannelList;

export type SlackDailyDigestSettings = {
  __typename: "SlackDailyDigestSettings";
  enabled: Scalars["Boolean"];
  timeSlot: DigestTimeSlot;
};

export type SlackDailyDigestSettingsInput = {
  enabled: Scalars["Boolean"];
  timeSlot: DigestTimeSlot;
};

export type SlackDndSettings = {
  __typename: "SlackDndSettings";
  focusTime: Scalars["Boolean"];
  meeting: Scalars["Boolean"];
};

export type SlackDndSettingsInput = {
  focusTime: Scalars["Boolean"];
  meeting: Scalars["Boolean"];
};

export type SlackEnabled = {
  __typename: "SlackEnabled";
  slackEnabled?: Maybe<Scalars["Boolean"]>;
};

export type SlackEnabledErrorable = GraphEntityError | SlackEnabled;

export enum SlackForTeamUpdateAction {
  Delete = "Delete",
}

export type SlackNotificationSettings = {
  __typename: "SlackNotificationSettings";
  defragSummary: Scalars["Boolean"];
};

export type SlackNotificationSettingsInput = {
  defragSummary: Scalars["Boolean"];
};

/** Slack calendar sync settings */
export type SlackSettings = Node & {
  __typename: "SlackSettings";
  calendarStatus?: Maybe<SlackCalendarStatusSync>;
  /** @deprecated use calendarStatus */
  calendarStatusEnabled: Scalars["Boolean"];
  dailyDigestSettings?: Maybe<SlackDailyDigestSettings>;
  dndSettings?: Maybe<SlackDndSettings>;
  /** The ID of an object */
  id: Scalars["ID"];
  /** @deprecated use calendarStatus */
  includeMeetingCategory: Scalars["Boolean"];
  /** @deprecated use calendarStatus */
  includeMeetingTitle: Scalars["Boolean"];
  notificationSettings?: Maybe<SlackNotificationSettings>;
  slackTeamId: Scalars["String"];
  slackUserId: Scalars["String"];
};

export type SlackSettingsInput = {
  calendarStatus?: InputMaybe<SlackCalendarStatusSyncInput>;
  calendarStatusEnabled: Scalars["Boolean"];
  dailyDigestSettings?: InputMaybe<SlackDailyDigestSettingsInput>;
  dndSettings?: InputMaybe<SlackDndSettingsInput>;
  id: Scalars["ID"];
  includeMeetingCategory: Scalars["Boolean"];
  includeMeetingTitle: Scalars["Boolean"];
  notificationSettings?: InputMaybe<SlackNotificationSettingsInput>;
  slackTeamId: Scalars["String"];
  slackUserId: Scalars["String"];
};

export type SlackSettingsList = {
  __typename: "SlackSettingsList";
  list: Array<SlackSettings>;
};

export type SlackSettingsListErrorable = GraphEntityError | SlackSettingsList;

export enum SlackUpdateAction {
  Delete = "Delete",
}

export type SmartHold = FocusTimeHold | LunchHold | MeetingReliefHold | TravelTimeHold;

/** Calculates smart hold slots without reserving them on the calendar */
export type SmartHoldCalculationResult = {
  __typename: "SmartHoldCalculationResult";
  events: Array<Event>;
  hadGoals?: Maybe<Scalars["Boolean"]>;
  smartHolds: Array<Event>;
};

export type SmartHoldCalculationResultErrorable = GraphEntityError | SmartHoldCalculationResult;

/** Metadata about a Smart Hold event */
export type SmartHoldEventInfo = Node & {
  __typename: "SmartHoldEventInfo";
  /** The ID of an object */
  id: Scalars["ID"];
  isPostTravelTime: Scalars["Boolean"];
  isPreTravelTime: Scalars["Boolean"];
  isTeamCalendarSyncEvent: Scalars["Boolean"];
  originEvent: Event;
};

export type SmartHoldEventInfoErrorable = GraphEntityError | SmartHoldEventInfo;

export type SmartHoldGoals = {
  __typename: "SmartHoldGoals";
  focusTime: FocusTimeGoals;
  /** The ID of an object */
  id: Scalars["ID"];
  lunch: LunchGoals;
  meetingRelief: MeetingReliefGoals;
  travelTime: TravelTimeGoals;
};

export type SmartHoldState = Paused | Protected | Unpaused;

export enum SmartHoldTypeEnum {
  FocusTime = "FocusTime",
  Lunch = "Lunch",
  MeetingRelief = "MeetingRelief",
  MissingPriority = "MissingPriority",
  NoMeetingDay = "NoMeetingDay",
  OnboardingTestEvent = "OnboardingTestEvent",
  Pain = "Pain",
  ShadowCalendar = "ShadowCalendar",
  SyncedEvent = "SyncedEvent",
  TeamEvent = "TeamEvent",
  TravelTime = "TravelTime",
  TwoWaySync = "TwoWaySync",
}

export type SomeFocusTime = {
  __typename: "SomeFocusTime";
  idealFocus: Scalars["Duration"];
};

export enum SortDirection {
  Ascending = "Ascending",
  Descending = "Descending",
}

export enum SortType {
  Collaborators = "Collaborators",
  FullName = "FullName",
  Status = "Status",
}

/** Generated link to WorkOS admin portal */
export type SsoAdminPortalLink = {
  __typename: "SsoAdminPortalLink";
  link: Scalars["String"];
};

export type SsoAdminPortalLinkErrorable = GraphEntityError | SsoAdminPortalLink;

export enum SsoIntent {
  Dsync = "Dsync",
  Sso = "Sso",
}

export enum SsoIntentInput {
  Dsync = "Dsync",
  Sso = "Sso",
}

/** A simple markdown text notification. */
export type StandardNotification = {
  __typename: "StandardNotification";
  body: Scalars["String"];
  buttons?: Maybe<Array<NotificationButton>>;
  showConfetti: Scalars["Boolean"];
  title: Scalars["String"];
};

export enum StatusEnum {
  AutopilotHistoryIncomplete = "AutopilotHistoryIncomplete",
  AutopilotHistoryNotVisible = "AutopilotHistoryNotVisible",
  AutopilotHistoryOff = "AutopilotHistoryOff",
  AutopilotHistoryOn = "AutopilotHistoryOn",
}

/** Generated stripe customer portal session for a given subscription */
export type StripeCustomerPortalSession = {
  __typename: "StripeCustomerPortalSession";
  account?: Maybe<AccountStripe>;
  sessionUrl?: Maybe<Scalars["String"]>;
};

export type StripeCustomerPortalSessionErrorable = GraphEntityError | StripeCustomerPortalSession;

/** Stripe error */
export type StripeError = {
  __typename: "StripeError";
  code?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum SubjectUrnTypeEnum {
  Email = "Email",
  Integration = "Integration",
  Org = "Org",
  Person = "Person",
  Phone = "Phone",
  Services = "Services",
  Team = "Team",
  User = "User",
}

export type SubmitHubspotFormMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  formFields: Array<HubspotFormFieldInput>;
  formId: Scalars["String"];
};

export type SubmitHubspotFormMutationPayload = {
  __typename: "SubmitHubspotFormMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type Subscription = {
  __typename: "Subscription";
  updateEventParentBaseWrapper?: Maybe<EventParentBaseWrapper>;
  updateEventParentWrappers: UpdateEventParentWrappersSubscriptionPayload;
  updateFlowState: UpdateFlowStateSubscriptionPayload;
  updateOrgFlowState: UpdateOrgFlowStateSubscriptionPayload;
  updateTeamCalendarState: UpdateTeamCalendarStateSubscriptionPayload;
};

export type SubscriptionUpdateEventParentBaseWrapperArgs = {
  input: UpdateEventParentBaseWrappersSubscriptionInput;
};

export type SubscriptionUpdateEventParentWrappersArgs = {
  input: UpdateEventParentWrappersSubscriptionInput;
};

export type SubscriptionUpdateFlowStateArgs = {
  input: UpdateFlowStateSubscriptionInput;
};

export type SubscriptionUpdateOrgFlowStateArgs = {
  input: UpdateOrgFlowStateSubscriptionInput;
};

export type SubscriptionUpdateTeamCalendarStateArgs = {
  input: UpdateTeamCalendarStateSubscriptionInput;
};

/** DESCRIBE NEW FIELD HERE */
export type SubscriptionDetails = {
  __typename: "SubscriptionDetails";
  subscription: PaymentSubscription;
};

export type SubscriptionDetailsErrorable = GraphEntityError | SubscriptionDetails;

export enum SubscriptionInterval {
  Monthly = "Monthly",
  Yearly = "Yearly",
}

export enum SubscriptionTypeEnum {
  Expired = "Expired",
  FreemiumOrg = "FreemiumOrg",
  LegacyPrePaywall = "LegacyPrePaywall",
  PaidBillingGroup = "PaidBillingGroup",
  PaidOrgPro = "PaidOrgPro",
  PaidTeamPro = "PaidTeamPro",
  PaidUserPro = "PaidUserPro",
  SelfServePremiumOrgQ42020 = "SelfServePremiumOrgQ42020",
  SelfServePremiumTeamQ12021 = "SelfServePremiumTeamQ12021",
}

export type Sudo = Node & {
  __typename: "Sudo";
  billingGroupsForOrg: Array<Maybe<BillingGroup>>;
  /** Last update times for the chrome app */
  chromeAppUpdateTimes?: Maybe<ChromeAppUpdateTimes>;
  /** All emails for all users */
  claimedEmails: Array<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  listCliCommands?: Maybe<CliCommandListErrorable>;
  modifyUser?: Maybe<UserAndExtendedInfo>;
  orgIdFromDomain?: Maybe<Scalars["String"]>;
  resetFlowState?: Maybe<FlowState>;
};

export type SudoBillingGroupsForOrgArgs = {
  adminEmail: Scalars["String"];
  orgId: Scalars["String"];
};

export type SudoOrgIdFromDomainArgs = {
  domain: Scalars["String"];
};

export type SudoResetFlowStateArgs = {
  email?: InputMaybe<Scalars["String"]>;
};

export type SudoAddAdminToBillingGroupMutationInput = {
  adminEmailToAdd: Scalars["String"];
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  existingAdminEmail: Scalars["String"];
  orgId: Scalars["String"];
};

export type SudoAddAdminToBillingGroupMutationPayload = {
  __typename: "SudoAddAdminToBillingGroupMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SudoAddMembersToBillingGroupMutationInput = {
  addExclusions?: InputMaybe<Scalars["Boolean"]>;
  adminEmail: Scalars["String"];
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  emails: Array<InputMaybe<Scalars["String"]>>;
  orgId: Scalars["String"];
};

export type SudoAddMembersToBillingGroupMutationPayload = {
  __typename: "SudoAddMembersToBillingGroupMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SudoCreateBillingGroupMutationInput = {
  adminEmail: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  invitePolicy: Scalars["String"];
  name: Scalars["String"];
  orgId: Scalars["String"];
  type: Scalars["String"];
};

export type SudoCreateBillingGroupMutationPayload = {
  __typename: "SudoCreateBillingGroupMutationPayload";
  billingGroupId: Scalars["String"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SudoDeactivateUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
};

export type SudoDeactivateUserMutationPayload = {
  __typename: "SudoDeactivateUserMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SudoDeleteBillingGroupMutationInput = {
  adminEmail: Scalars["String"];
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgId: Scalars["String"];
};

export type SudoDeleteBillingGroupMutationPayload = {
  __typename: "SudoDeleteBillingGroupMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SudoDeleteEverythingMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type SudoDeleteEverythingMutationPayload = {
  __typename: "SudoDeleteEverythingMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SudoEnableSsoMutationInput = {
  adminEmail: Scalars["String"];
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgId: Scalars["String"];
};

export type SudoEnableSsoMutationPayload = {
  __typename: "SudoEnableSSOMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SudoLinkSubscriptionToBillingGroupMutationInput = {
  adminEmail: Scalars["String"];
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgId: Scalars["String"];
  seatCount: Scalars["Int"];
  subscriptionId: Scalars["String"];
};

export type SudoLinkSubscriptionToBillingGroupMutationPayload = {
  __typename: "SudoLinkSubscriptionToBillingGroupMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SudoModifyUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  status: Scalars["String"];
};

export type SudoModifyUserMutationPayload = {
  __typename: "SudoModifyUserMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  viewer?: Maybe<Viewer>;
};

export type SudoRemoveMembersFromBillingGroupMutationInput = {
  addExclusions?: InputMaybe<Scalars["Boolean"]>;
  adminEmail: Scalars["String"];
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  emails: Array<InputMaybe<Scalars["String"]>>;
  orgId: Scalars["String"];
};

export type SudoRemoveMembersFromBillingGroupMutationPayload = {
  __typename: "SudoRemoveMembersFromBillingGroupMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SudoResetFlowStateMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  flowKey: Scalars["String"];
  orgRelayId?: InputMaybe<Scalars["String"]>;
};

export type SudoResetFlowStateMutationPayload = {
  __typename: "SudoResetFlowStateMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  flowState?: Maybe<FlowState>;
  viewer?: Maybe<Viewer>;
};

export type SudoSwitchUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  jwtType: Scalars["String"];
};

export type SudoSwitchUserMutationPayload = {
  __typename: "SudoSwitchUserMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  viewer?: Maybe<Viewer>;
};

export type SudoToggleAutoSyncForOrgMutationInput = {
  adminEmail: Scalars["String"];
  autoSyncEnabled: Scalars["Boolean"];
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgId: Scalars["String"];
};

export type SudoToggleAutoSyncForOrgMutationPayload = {
  __typename: "SudoToggleAutoSyncForOrgMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SudoTriggerClientUpdateMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  force: Scalars["Boolean"];
};

export type SudoTriggerClientUpdateMutationPayload = {
  __typename: "SudoTriggerClientUpdateMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  sudo?: Maybe<Sudo>;
};

export type SudoUnlinkSubscriptionFromBillingGroupMutationInput = {
  adminEmail: Scalars["String"];
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgId: Scalars["String"];
};

export type SudoUnlinkSubscriptionFromBillingGroupMutationPayload = {
  __typename: "SudoUnlinkSubscriptionFromBillingGroupMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SudoUpdateBillingGroupNameMutationInput = {
  adminEmail: Scalars["String"];
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  orgId: Scalars["String"];
};

export type SudoUpdateBillingGroupNameMutationPayload = {
  __typename: "SudoUpdateBillingGroupNameMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SudoUpdateInvitePolicyMutationInput = {
  adminEmail: Scalars["String"];
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  invitePolicy: Scalars["String"];
  orgId: Scalars["String"];
};

export type SudoUpdateInvitePolicyMutationPayload = {
  __typename: "SudoUpdateInvitePolicyMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** DESCRIBE NEW FIELD HERE */
export type SuggestedAutopilotSettingsResponseV3 = {
  __typename: "SuggestedAutopilotSettingsResponseV3";
  calendarIdToMeetingHours: Array<SuggestedAutotpilotSettingsResponseV3CalendarIdMeetingHours>;
  id: Scalars["ID"];
  intersectionOfAttendeesMeetingHours?: Maybe<DayOffsetTimeSlotRange>;
  suggestedAutopilotSettings: SuggestedAutopilotSettingsV3;
};

export type SuggestedAutopilotSettingsResponseV3Errorable =
  | GraphEntityError
  | SuggestedAutopilotSettingsResponseV3;

/** DESCRIBE NEW FIELD HERE */
export type SuggestedAutopilotSettingsV3 = {
  __typename: "SuggestedAutopilotSettingsV3";
  allowedEndTime?: Maybe<DayOffsetTimeSlot>;
  allowedStartTime?: Maybe<DayOffsetTimeSlot>;
  isRoomFlexible: Scalars["Boolean"];
  movementType: SuggestedAutopilotSettingsV3MovementType;
};

export enum SuggestedAutopilotSettingsV3MovementType {
  Day = "Day",
  Week = "Week",
}

export type SuggestedAutotpilotSettingsResponseV3CalendarIdMeetingHours = {
  __typename: "SuggestedAutotpilotSettingsResponseV3CalendarIdMeetingHours";
  calendarId: Scalars["String"];
  dayOffsetTimeSlotRanges: Array<DayOffsetTimeSlotRange>;
};

export type SuggestedFlexibleMeetingFilter = {
  /** a datetime range that an event must be within to be suggested. Null if we don't want to filter down to a date time range */
  inRange?: InputMaybe<SuggestedMeetingDateTimeRangeFilter>;
  types: Array<SuggestedMeetingType>;
};

/** Meetings that are suggested to be flexible */
export type SuggestedFlexibleMeetings = {
  __typename: "SuggestedFlexibleMeetings";
  meetings: Array<EventParent>;
  totalNumberOfSuggestions: Scalars["NonNegativeInt"];
};

export type SuggestedMeetingDateTimeRangeFilter = {
  end: Scalars["DateTime"];
  start: Scalars["DateTime"];
};

export enum SuggestedMeetingType {
  OneOnOne = "OneOnOne",
}

/** A suggestion to invite someone to join an org */
export type SuggestedOrgInvite = Node & {
  __typename: "SuggestedOrgInvite";
  /** The ID of an object */
  id: Scalars["ID"];
  inviteReason?: Maybe<SuggestedOrgInviteReason>;
  numberOfOrganizedMeetings?: Maybe<Scalars["Int"]>;
  numberOfSharedMeetings?: Maybe<Scalars["Int"]>;
  orgId?: Maybe<Scalars["String"]>;
  resolution?: Maybe<SuggestedOrgInviteResolution>;
  suggestedTime?: Maybe<Scalars["Float"]>;
  targetCalendarId?: Maybe<Scalars["String"]>;
  targetProfile?: Maybe<UserProfile>;
  totalScore?: Maybe<Scalars["Float"]>;
};

/** A connection to a list of items. */
export type SuggestedOrgInviteConnection = {
  __typename: "SuggestedOrgInviteConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SuggestedOrgInviteEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SuggestedOrgInviteEdge = {
  __typename: "SuggestedOrgInviteEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<SuggestedOrgInvite>;
};

export enum SuggestedOrgInviteReason {
  AttendSameMeetings = "AttendSameMeetings",
  MeetsWithYourTeammates = "MeetsWithYourTeammates",
  OnYourTeam = "OnYourTeam",
  OrganizedYourMeetings = "OrganizedYourMeetings",
}

export enum SuggestedOrgInviteResolution {
  Accepted = "Accepted",
  Dismissed = "Dismissed",
}

/** A selected or suggested preferred room */
export type SuggestedRoomPreference = {
  __typename: "SuggestedRoomPreference";
  /** Has the room been selected as a preferred room by the user */
  isPreferred: Scalars["Boolean"];
  /** Is the room suggested as a preferred room for the user */
  isSuggested: Scalars["Boolean"];
  room: Room;
};

/** The suggested room preferences for the user */
export type SuggestedRoomPreferences = Node & {
  __typename: "SuggestedRoomPreferences";
  /** The ID of an object */
  id: Scalars["ID"];
  suggestedRooms: Array<SuggestedRoomPreference>;
};

export type SuggestedRoomPreferencesErrorable = GraphEntityError | SuggestedRoomPreferences;

/** A suggested team and its reasons */
export type SuggestedTeam = {
  __typename: "SuggestedTeam";
  reasons: Array<SuggestedTeamReason>;
  score: Scalars["Float"];
  team: Team;
};

export type SuggestedTeamList = {
  __typename: "SuggestedTeamList";
  list: Array<SuggestedTeam>;
};

export type SuggestedTeamListErrorable = GraphEntityError | SuggestedTeamList;

/** DESCRIBE NEW FIELD HERE */
export type SuggestedTeamReason = {
  __typename: "SuggestedTeamReason";
  type: SuggestedTeamReasonType;
  value: Scalars["Float"];
};

export enum SuggestedTeamReasonType {
  NumMeetingsWithOverlap = "NumMeetingsWithOverlap",
  NumOneOnOnesWithOverlap = "NumOneOnOnesWithOverlap",
}

/** A summary of working preferences for a teammate */
export type SummarizedWorkingPreferences = {
  __typename: "SummarizedWorkingPreferences";
  busiestDay?: Maybe<Day>;
  calendarId?: Maybe<Scalars["String"]>;
  mostBookedRoom?: Maybe<Scalars["String"]>;
  mostFocusTime?: Maybe<Day>;
};

export type SyncMeetingReliefInput = {
  orgId: Scalars["ID"];
  /** Should we sync meeting relief breaks to the user's calendar */
  shouldSync: Scalars["Boolean"];
};

export type SyncMeetingReliefPayload = {
  __typename: "SyncMeetingReliefPayload";
  goal?: Maybe<Goal>;
};

export enum TagFilterEnum {
  Conflict = "Conflict",
  External = "External",
  Flexible = "Flexible",
  Hold = "Hold",
  Internal = "Internal",
  OOO = "OOO",
}

export type TagInput = {
  name: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
};

/** The state of a tag on an event */
export type TagState = {
  __typename: "TagState";
  /** Whether a tag is active or deleted */
  active: Scalars["Boolean"];
  /** Timestamp in millis of last change */
  lastModified: Scalars["Float"];
  /** Type of attendee urn */
  subjectType: SubjectUrnTypeEnum;
  /** Attendee's urn (note this relates to urnType) */
  subjectValue: Scalars["String"];
  /** If a tag has an associated value, it goes here */
  value?: Maybe<Scalars["String"]>;
};

/** A tailored set of defrag moves summarized for the user */
export type TailoredDefragCardSummary = {
  __typename: "TailoredDefragCardSummary";
  numberOfMyMoves: Scalars["Int"];
};

export type TailoredDefragCardSummaryErrorable = GraphEntityError | TailoredDefragCardSummary;

/** Your team and its members */
export type Team = Node & {
  __typename: "Team";
  createdBy: Urn;
  /** The ID of an object */
  id: Scalars["ID"];
  invitedMembers: Array<TeamMember>;
  settings: TeamSettingsErrorable;
  teamCalendarId?: Maybe<Scalars["String"]>;
  teamId: Scalars["String"];
  teamMembers: Array<TeamMember>;
  teamName: Scalars["String"];
  teamRoleAndPersonIds: Array<TeamRoleAndPersonId>;
  userSettings: TeamUserSettingsErrorable;
};

/** Entity that represents m365 team calendar */
export type TeamCalendarDetails = {
  __typename: "TeamCalendarDetails";
  calendarName: Scalars["String"];
  members: Array<TeamCalendarMember>;
  owner: OrgPerson;
  teamId: Scalars["String"];
};

export type TeamCalendarDetailsErrorable = GraphEntityError | TeamCalendarDetails;

/** Entity that represents m365 team calendar member */
export type TeamCalendarMember = {
  __typename: "TeamCalendarMember";
  personId: Scalars["String"];
  status: TeamCalendarMemberInviteStatus;
};

export enum TeamCalendarMemberInviteStatus {
  Accepted = "Accepted",
  NotInvited = "NotInvited",
  Pending = "Pending",
  Unknown = "Unknown",
}

/** The state of a team calendar */
export type TeamCalendarState = {
  __typename: "TeamCalendarState";
  calendarId: Scalars["String"];
};

export type TeamErrorable = GraphEntityError | Team;

/** Team Health Score */
export type TeamHealthScore = {
  __typename: "TeamHealthScore";
  calendarFocusTimeScores?: Maybe<Array<Maybe<CalendarFocusTimeScore>>>;
  myFocusTimeScore?: Maybe<CalendarFocusTimeScore>;
  orgId: Scalars["String"];
  orgPersons: OrgPersonListErrorable;
  persons: PersonListErrorable;
  profileTimeZones?: Maybe<Array<Maybe<ProfileTimeZone>>>;
};

export type TeamHealthScoreErrorable = GraphEntityError | TeamHealthScore;

/** Team Health Score With Sub Teams */
export type TeamHealthScoreWithSubTeams = {
  __typename: "TeamHealthScoreWithSubTeams";
  teamCalendars: Array<TeamCalendarMap>;
  teamHealthScore: TeamHealthScore;
};

export type TeamHealthScoreWithSubTeamsErrorable = GraphEntityError | TeamHealthScoreWithSubTeams;

export type TeamList = {
  __typename: "TeamList";
  list: Array<Team>;
};

export type TeamListErrorable = GraphEntityError | TeamList;

/** DESCRIBE NEW FIELD HERE */
export type TeamMember = {
  __typename: "TeamMember";
  addedBy?: Maybe<Scalars["String"]>;
  person: OrgPerson;
  role: TeamMemberRole;
};

export enum TeamMemberRole {
  Admin = "Admin",
  Member = "Member",
}

/** The result of a team query */
export type TeamQueryResult = {
  __typename: "TeamQueryResult";
  query: Scalars["String"];
  searchId?: Maybe<Scalars["Int"]>;
  teamMembers: Array<TeamMember>;
  teams: Array<Team>;
};

export type TeamQueryResultErrorable = GraphEntityError | TeamQueryResult;

/** DESCRIBE NEW FIELD HERE */
export type TeamRoleAndPersonId = {
  __typename: "TeamRoleAndPersonId";
  personId: Scalars["String"];
  role: TeamMemberRole;
};

export type TeamRoleAndPersonIdInput = {
  personId: Scalars["String"];
  role: TeamMemberRole;
};

/** overall settings about a team */
export type TeamSettings = Node & {
  __typename: "TeamSettings";
  /** The ID of an object */
  id: Scalars["ID"];
  lastUpdated?: Maybe<Scalars["BigInt"]>;
  m365TeamCalendarDetails?: Maybe<TeamCalendarDetailsErrorable>;
  manageTeamCalendar: Scalars["Boolean"];
  minimumMinutesForSyncing: Scalars["Int"];
  noMeetingDayEmailOnScheduleOver: Scalars["Boolean"];
  noMeetingDayOfWeek: NoMeetingDayOfWeek;
  slackSettings: TeamSlackSettings;
  syncWFHEvents: Scalars["Boolean"];
  useNoMeetingDay: Scalars["Boolean"];
};

export type TeamSettingsErrorable = GraphEntityError | TeamSettings;

/** DESCRIBE NEW FIELD HERE */
export type TeamSlackSettings = {
  __typename: "TeamSlackSettings";
  channelId?: Maybe<Scalars["String"]>;
  channelName?: Maybe<Scalars["String"]>;
  notificationEnabled: Scalars["Boolean"];
  timeSlot?: Maybe<TimeSlotEnum>;
};

export enum TeamType {
  Managerial = "Managerial",
  Other = "Other",
}

/** individual user settings for a team */
export type TeamUserSettings = Node & {
  __typename: "TeamUserSettings";
  /** The ID of an object */
  id: Scalars["ID"];
  lastUpdated: Scalars["BigInt"];
  useNoMeetingDay: Scalars["Boolean"];
  useTeamCalendar: Scalars["Boolean"];
};

export type TeamUserSettingsErrorable = GraphEntityError | TeamUserSettings;

/** A text response from the assistant */
export type TextResponse = {
  __typename: "TextResponse";
  conversationId: Scalars["ID"];
  id: Scalars["ID"];
  text: Scalars["String"];
  type: TextResponseTypeEnum;
};

export enum TextResponseTypeEnum {
  BASIC_TEXT = "BASIC_TEXT",
  MARKDOWN_TEXT = "MARKDOWN_TEXT",
}

export type TimeOfDayFlexibility = {
  __typename: "TimeOfDayFlexibility";
  /** End of allowed time range for moving an event, null if not bounded by an explicit end time */
  end?: Maybe<Scalars["LocalTime"]>;
  /** Time slot indicating the ending bound of the time of day flexibility */
  endSlot: TimeOfDayTimeSlot;
  /** Beginning of allowed time range for moving an event, null if not bounded by an explicit start time */
  start?: Maybe<Scalars["LocalTime"]>;
  /** Time slot indicating the starting bound of the time of day flexibility */
  startSlot: TimeOfDayTimeSlot;
};

export type TimeOfDayFlexibilityUpdate = {
  allowedEnd: UpdateTimeOfDayTimeSlot;
  allowedStart: UpdateTimeOfDayTimeSlot;
  eventId: Scalars["EventId"];
};

export type TimeOfDayFlexibilityUpdated = {
  __typename: "TimeOfDayFlexibilityUpdated";
  updatedEvents?: Maybe<Array<EventParent>>;
};

export type TimeOfDayFlexibilityUpdatedUpdatedEventsArgs = {
  calendarId?: InputMaybe<Scalars["CalendarId"]>;
};

export enum TimeOfDayTimeSlot {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
  Unbounded = "Unbounded",
}

export type TimeRangeFlexibility = DayOfWeekFlexibility | RangeFlexibility;

export type TimeRangeInput = {
  endTime?: InputMaybe<EventTimeInput>;
  id: Scalars["ID"];
  startTime?: InputMaybe<EventTimeInput>;
};

/** Time range setting for a Timing Settings in a Meeting Settings */
export type TimeRangeSettings = {
  __typename: "TimeRangeSettings";
  rangeEndTime?: Maybe<Scalars["String"]>;
  rangeStartTime?: Maybe<Scalars["String"]>;
  windowSizeDays?: Maybe<Scalars["Int"]>;
};

/** A time slot */
export enum TimeSlot {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
}

export enum TimeSlotEnum {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
}

/** Timing setttings for a Meeting Settings */
export type TimingSettings = {
  __typename: "TimingSettings";
  defaultDuration: Scalars["Int"];
  durations?: Maybe<Array<Scalars["Int"]>>;
  periodType: TimingSettingsPeriodTypeEnum;
  timeRange: TimeRangeSettings;
};

export enum TimingSettingsPeriodTypeEnum {
  Range = "Range",
  RollingWindow = "RollingWindow",
}

export enum TitleFilterModifierEnum {
  CONTAINS = "CONTAINS",
  IS = "IS",
  LIKE = "LIKE",
}

export type ToggleAutoSyncForBillingGroupMutationInput = {
  billingGroupId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isAutoSyncEnabled: Scalars["Boolean"];
  orgRelayId: Scalars["String"];
  teamId?: InputMaybe<Scalars["String"]>;
};

export type ToggleAutoSyncForBillingGroupMutationPayload = {
  __typename: "ToggleAutoSyncForBillingGroupMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type ToggleEventColoringSettingsMutationsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  eventCategoryColorings: Array<InputMaybe<EventCategoryColoringInput>>;
  /** The front-end ID for the organization we're saving event coloring settings for */
  orgRelayId: Scalars["String"];
};

export type ToggleEventColoringSettingsMutationsPayload = {
  __typename: "ToggleEventColoringSettingsMutationsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventColoringSettings?: Maybe<EventColoringSettings>;
};

export type ToggleFocusTimeGoalResponse = {
  __typename: "ToggleFocusTimeGoalResponse";
  focusTimeGoal: FocusTimeGoals;
};

export type ToggleGoalResponse = {
  __typename: "ToggleGoalResponse";
  lunchGoal: LunchGoals;
};

export type ToggleMeetingReliefResponse = {
  __typename: "ToggleMeetingReliefResponse";
  meetingReliefGoal: MeetingReliefGoals;
};

export type ToggleTravelTimeResponse = {
  __typename: "ToggleTravelTimeResponse";
  travelTimeGoal: TravelTimeGoals;
};

/** Tracking data for notifications. */
export type TrackingData = {
  __typename: "TrackingData";
  data?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
};

/** A summary of scheduling tradeoffs for a diff */
export type TradeoffBlock = {
  __typename: "TradeoffBlock";
  /** Deduplicated list of all attendees impacted */
  aggregateAttendees: Array<OrgPerson>;
  id: Scalars["ID"];
  /** List of granular scheduling tradeoffs */
  schedulingTradeoffs: Array<SchedulingTradeoff>;
  title: Scalars["String"];
  tradeoffType: TradeoffTypeEnum;
};

export enum TradeoffInactionReason {
  ExternalMeeting = "ExternalMeeting",
  IsActionable = "IsActionable",
  LowVisibility = "LowVisibility",
  Other = "Other",
}

export enum TradeoffTypeEnum {
  AVAILABILITY_ISSUE = "AVAILABILITY_ISSUE",
  FIXABLE_CONFLICT = "FIXABLE_CONFLICT",
  INCONVENIENCE = "INCONVENIENCE",
  LOW_VIZ_INCONVENIENCE = "LOW_VIZ_INCONVENIENCE",
  OUT_OF_TIME_RANGE = "OUT_OF_TIME_RANGE",
}

export enum TransparencyEnum {
  None = "None",
  Opaque = "Opaque",
  Tentative = "Tentative",
  Transparent = "Transparent",
}

export type TravelTimeGoals = {
  __typename: "TravelTimeGoals";
  enabled?: Maybe<Scalars["Boolean"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  remoteHoldSettings?: Maybe<RemoteSmartHoldSettings>;
  userAddress?: Maybe<AddressIfSet>;
};

export type TravelTimeHold = {
  __typename: "TravelTimeHold";
  state?: Maybe<SmartHoldState>;
  times: DateTimeRange;
};

/** Trial metadata */
export type TrialMetadata = {
  __typename: "TrialMetadata";
  isTrialExpired: Scalars["Boolean"];
  timeZone: Scalars["String"];
  trialEndDate: Scalars["String"];
};

export type TriggerDefragRunMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
};

export type TriggerDefragRunMutationPayload = {
  __typename: "TriggerDefragRunMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export enum Unavailabilities {
  FarOutsideWorkingHours = "FarOutsideWorkingHours",
}

export type UnpauseEventMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  externalEventId: Scalars["String"];
  orgRelayId: Scalars["String"];
};

export type UnpauseEventMutationPayload = {
  __typename: "UnpauseEventMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventParent?: Maybe<EventParent>;
};

export type UnpauseHoldInput = {
  /** The event id of the hold to protect */
  holdId: Scalars["EventId"];
  /** The graph id of the org */
  orgId: Scalars["ID"];
};

export type UnpauseHoldPayload = {
  __typename: "UnpauseHoldPayload";
  success: Scalars["Boolean"];
  updatedEvent: EventParent;
};

export type Unpaused = {
  __typename: "Unpaused";
  nop?: Maybe<Scalars["Void"]>;
};

export type UnprotectHoldInput = {
  /** The event id of the hold to protect */
  holdId: Scalars["EventId"];
  /** The graph id of the org */
  orgId: Scalars["ID"];
};

export type UnprotectedHoldPayload = {
  __typename: "UnprotectedHoldPayload";
  success: Scalars["Boolean"];
  updatedEvent: EventParent;
};

export type UnsubscribeEmailInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  token: Scalars["String"];
};

export type UnsubscribeEmailPayload = {
  __typename: "UnsubscribeEmailPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UnsyncFocusTimeBlockMutationInput = {
  calendarId?: InputMaybe<Scalars["String"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  externalEventId?: InputMaybe<Scalars["String"]>;
  isPaused?: InputMaybe<Scalars["Boolean"]>;
  orgRelayId: Scalars["String"];
  timeRange: TimeRangeInput;
};

export type UnsyncFocusTimeBlockMutationPayload = {
  __typename: "UnsyncFocusTimeBlockMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UnsyncFocusTimeEventMutationInput = {
  calendarId?: InputMaybe<Scalars["String"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  externalEventId?: InputMaybe<Scalars["String"]>;
  isPaused?: InputMaybe<Scalars["Boolean"]>;
  orgRelayId: Scalars["String"];
  timeRange: TimeRangeInput;
};

export type UnsyncFocusTimeEventMutationPayload = {
  __typename: "UnsyncFocusTimeEventMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UnsyncHoldInput = {
  /** The event id of the hold to unsync */
  holdId: Scalars["EventId"];
  /** The graph id of the org */
  orgId: Scalars["ID"];
};

export type UnsyncHoldPayload = {
  __typename: "UnsyncHoldPayload";
  success: Scalars["Boolean"];
};

export type UnsyncMeetingReliefEvent = {
  __typename: "UnsyncMeetingReliefEvent";
  successful: Scalars["Boolean"];
};

export type UnsyncMeetingReliefEventInput = {
  externalEventId: Scalars["EventId"];
  orgId: Scalars["ID"];
};

/** Details concerning the upcoming billing cycle for a billing group. */
export type UpcomingBillingDetails = {
  __typename: "UpcomingBillingDetails";
  /** The number of seats that will be billed for the next billing cycle */
  billedQuantity: Scalars["Int"];
  /** Id of the billing group in question */
  billingGroupId: Scalars["String"];
  /** The number of occupied seats currently in the billing group */
  currentUsageQuantity: Scalars["Int"];
  /** When the billing group will be billed next (formatted YYYY-MM-DD) */
  nextBillingDate: Scalars["String"];
  /** When the billing group will be trued-up (formatted YYYY-MM-DD) */
  nextTrueUpDate?: Maybe<Scalars["String"]>;
  /** What the user has requested the new seat count to be */
  requestedUpdatedQuantity?: Maybe<Scalars["Int"]>;
};

export type UpcomingBillingDetailsErrorable = GraphEntityError | UpcomingBillingDetails;

export type UpdateAtMostFocusHours = {
  hours: Scalars["Int"];
};

export type UpdateAutoDeclineThreshold = {
  hours: Scalars["Int"];
};

export type UpdateDailyMax = {
  hours: Scalars["Int"];
};

export type UpdateDayRangeFlexibility = {
  /**
   *
   *                 Allowed days for the DayOfWeek flexibility.
   *                 Should be provided if and only if the flexibility type is 'DayOfWeek'
   *
   */
  allowedDays?: InputMaybe<Array<DayOfWeek>>;
  type: DayRangeFlexibilityType;
};

export type UpdateDayRangeFlexibilityInput = {
  /** Event ids that should be updated to day flexibility */
  dayFlexibleEvents?: Array<Scalars["EventId"]>;
  /** Events that should be updated to day of week flexibility, and the allowed days for the flexibility */
  dayOfWeekFlexibleEvents?: Array<DayOfWeekFlexibleEventNew>;
  /** Event ids that should not be flexible */
  notFlexibleEvents?: Array<Scalars["EventId"]>;
  /** The relay org id */
  orgRelayId?: InputMaybe<Scalars["ID"]>;
  /** Event ids that should be updated to week flexibility */
  weekFlexibleEvents?: Array<Scalars["EventId"]>;
};

export type UpdateDeviceTokenInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A unique identifier for the calling device */
  deviceId: Scalars["String"];
  /** The registration token that allows us to send push notifications to the device */
  registrationToken: Scalars["String"];
};

export type UpdateDeviceTokenPayload = {
  __typename: "UpdateDeviceTokenPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateEventFlexibility = {
  dayRangeFlexibility: UpdateDayRangeFlexibility;
  eventId: Scalars["EventId"];
  roomFlexibility?: UpdateRoomFlexibility;
  /**
   *
   *           Update the time of day flexibility, if provided. 'null' indicates that the time of day flexibility should remain unchanged
   *
   *           The start & end values each take an 'Unbounded' option, this indicates that the time of day flexibility won't be explicitly
   *           bounded by any set time, and will be implicitly bounded by the attendees meeting hours.
   *
   */
  timeOfDayFlexibility?: InputMaybe<UpdateTimeOfDayFlexibility>;
};

export type UpdateEventParentBaseWrappersSubscriptionInput = {
  calendarIds: Array<Scalars["String"]>;
  dates: Array<Scalars["LocalDate"]>;
  includeAllDay?: InputMaybe<Scalars["Boolean"]>;
  orgRelayId: Scalars["ID"];
  timeZone: Scalars["String"];
};

export type UpdateEventParentWrappersSubscriptionInput = {
  calendarIds: Array<Scalars["String"]>;
  dates: Array<Scalars["LocalDate"]>;
  includeAllDay?: InputMaybe<Scalars["Boolean"]>;
  orgRelayId: Scalars["ID"];
  timeZone: Scalars["String"];
};

export type UpdateEventParentWrappersSubscriptionPayload = {
  __typename: "UpdateEventParentWrappersSubscriptionPayload";
  eventParentBases: Array<EventParentBase>;
  eventParentWrappers?: Maybe<Array<EventParentWrapper>>;
};

export type UpdateEventResponseStatusMutationInput = {
  calendarId: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  externalEventId: Scalars["String"];
  orgRelayId: Scalars["String"];
  responseStatus: ResponseStatusEnum;
};

export type UpdateEventResponseStatusMutationPayload = {
  __typename: "UpdateEventResponseStatusMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventParent: EventParent;
};

export type UpdateFlagsForUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  enable: Scalars["Boolean"];
  flagNames: Array<InputMaybe<Scalars["String"]>>;
  flagNamespace: Scalars["String"];
};

export type UpdateFlagsForUserMutationPayload = {
  __typename: "UpdateFlagsForUserMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  flags?: Maybe<Flags>;
};

export type UpdateFlexibilityInput = {
  eventUpdates: Array<UpdateEventFlexibility>;
  orgRelayId: Scalars["ID"];
};

export type UpdateFlowStateSubscriptionInput = {
  flowStateRelayId: Scalars["ID"];
};

export type UpdateFlowStateSubscriptionPayload = {
  __typename: "UpdateFlowStateSubscriptionPayload";
  flowState?: Maybe<FlowState>;
};

export type UpdateFocusTimeGoalResponse = {
  __typename: "UpdateFocusTimeGoalResponse";
  focusTimeGoal: FocusTimeGoals;
};

export type UpdateLunchGoalResponse = {
  __typename: "UpdateLunchGoalResponse";
  lunchGoal: LunchGoals;
};

export type UpdateMeetingReliefGoalResponse = {
  __typename: "UpdateMeetingReliefGoalResponse";
  meetingReliefGoal: MeetingReliefGoals;
};

export enum UpdateNotificationStrategyInput {
  DontNotify = "DontNotify",
  NotifyInGCal = "NotifyInGCal",
}

export type UpdateOrgFlowStateSubscriptionInput = {
  flowStateRelayId: Scalars["ID"];
};

export type UpdateOrgFlowStateSubscriptionPayload = {
  __typename: "UpdateOrgFlowStateSubscriptionPayload";
  flowState?: Maybe<FlowState>;
};

export type UpdateOrgInviteInput = {
  calendarIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  productType?: InputMaybe<ProductType>;
};

export type UpdateOrgInvitePayload = {
  __typename: "UpdateOrgInvitePayload";
  calendarIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  clientMutationId?: Maybe<Scalars["String"]>;
  org?: Maybe<Org>;
};

export enum UpdatePreferredFocusTime {
  Afternoon = "Afternoon",
  Morning = "Morning",
  NoPreference = "NoPreference",
}

export type UpdateRoomFlexibility = {
  roomChangePolicy: RoomChangePolicy;
};

export enum UpdateScheduleAs {
  Busy = "Busy",
  Free = "Free",
}

export type UpdateSettingMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  setting: SettingInput;
  value: Array<InputMaybe<Scalars["String"]>>;
};

export type UpdateSettingMutationPayload = {
  __typename: "UpdateSettingMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  setting?: Maybe<Setting>;
};

export type UpdateSettingsMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  settings: Array<SettingInput>;
};

export type UpdateSettingsMutationPayload = {
  __typename: "UpdateSettingsMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  setting?: Maybe<Array<Maybe<Setting>>>;
};

export type UpdateShadowCalendarSettingsMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  /** The front-end ID for the organization we're saving shadow cal settings for */
  orgRelayId: Scalars["String"];
};

export type UpdateShadowCalendarSettingsMutationPayload = {
  __typename: "UpdateShadowCalendarSettingsMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  shadowCalendarSettings?: Maybe<ShadowCalendarSettings>;
};

export type UpdateSlackAccountForTeamMutationInput = {
  action: SlackForTeamUpdateAction;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  teamId: Scalars["String"];
};

export type UpdateSlackAccountForTeamMutationPayload = {
  __typename: "UpdateSlackAccountForTeamMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateSlackAccountMutationInput = {
  action: SlackUpdateAction;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  slackAccountRelayId: Scalars["String"];
};

export type UpdateSlackAccountMutationPayload = {
  __typename: "UpdateSlackAccountMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateSmartHoldSyncSettingsMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  focusTimeExternalTransparency?: InputMaybe<FocusTimeExternalTransparency>;
  focusTimeIdealMinutesPerWeek?: InputMaybe<Scalars["Int"]>;
  focusTimeRemindersEnabled?: InputMaybe<Scalars["Boolean"]>;
  focusTimeSyncAll?: InputMaybe<Scalars["Boolean"]>;
  focusTimeSyncEnabled?: InputMaybe<Scalars["Boolean"]>;
  lunchRemindersEnabled?: InputMaybe<Scalars["Boolean"]>;
  lunchSyncEnabled?: InputMaybe<Scalars["Boolean"]>;
  orgRelayId: Scalars["String"];
  travelTimeRemindersEnabled?: InputMaybe<Scalars["Boolean"]>;
  travelTimeSyncEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateSmartHoldSyncSettingsMutationPayload = {
  __typename: "UpdateSmartHoldSyncSettingsMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  shadowCalendarSettings: ShadowCalendarSettings;
};

export type UpdateSuggestedOrgInviteInput = {
  calendarIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  resolution?: InputMaybe<SuggestedOrgInviteResolution>;
};

export type UpdateSuggestedOrgInvitePayload = {
  __typename: "UpdateSuggestedOrgInvitePayload";
  calendarIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  clientMutationId?: Maybe<Scalars["String"]>;
  org?: Maybe<Org>;
};

export type UpdateTeamCalendarStateSubscriptionInput = {
  orgRelayId: Scalars["ID"];
  teamId: Scalars["String"];
};

export type UpdateTeamCalendarStateSubscriptionPayload = {
  __typename: "UpdateTeamCalendarStateSubscriptionPayload";
  teamCalendarState?: Maybe<TeamCalendarState>;
};

export type UpdateTimeOfDayFlexibility = {
  allowedEnd: UpdateTimeOfDayTimeSlot;
  allowedStart: UpdateTimeOfDayTimeSlot;
};

export type UpdateTimeOfDayFlexibilityInput = {
  orgRelayId: Scalars["ID"];
  updates: Array<TimeOfDayFlexibilityUpdate>;
};

export enum UpdateTimeOfDayTimeSlot {
  T_00_00 = "T_00_00",
  T_00_30 = "T_00_30",
  T_01_00 = "T_01_00",
  T_01_30 = "T_01_30",
  T_02_00 = "T_02_00",
  T_02_30 = "T_02_30",
  T_03_00 = "T_03_00",
  T_03_30 = "T_03_30",
  T_04_00 = "T_04_00",
  T_04_30 = "T_04_30",
  T_05_00 = "T_05_00",
  T_05_30 = "T_05_30",
  T_06_00 = "T_06_00",
  T_06_30 = "T_06_30",
  T_07_00 = "T_07_00",
  T_07_30 = "T_07_30",
  T_08_00 = "T_08_00",
  T_08_30 = "T_08_30",
  T_09_00 = "T_09_00",
  T_09_30 = "T_09_30",
  T_10_00 = "T_10_00",
  T_10_30 = "T_10_30",
  T_11_00 = "T_11_00",
  T_11_30 = "T_11_30",
  T_12_00 = "T_12_00",
  T_12_30 = "T_12_30",
  T_13_00 = "T_13_00",
  T_13_30 = "T_13_30",
  T_14_00 = "T_14_00",
  T_14_30 = "T_14_30",
  T_15_00 = "T_15_00",
  T_15_30 = "T_15_30",
  T_16_00 = "T_16_00",
  T_16_30 = "T_16_30",
  T_17_00 = "T_17_00",
  T_17_30 = "T_17_30",
  T_18_00 = "T_18_00",
  T_18_30 = "T_18_30",
  T_19_00 = "T_19_00",
  T_19_30 = "T_19_30",
  T_20_00 = "T_20_00",
  T_20_30 = "T_20_30",
  T_21_00 = "T_21_00",
  T_21_30 = "T_21_30",
  T_22_00 = "T_22_00",
  T_22_30 = "T_22_30",
  T_23_00 = "T_23_00",
  T_23_30 = "T_23_30",
  Unbounded = "Unbounded",
}

export type UpdateTravelTimeDurationInput = {
  /** The new duration in minutes */
  durationMinutes: Scalars["Int"];
  /** The event id of the hold to protect */
  holdId: Scalars["EventId"];
  /** The graph id of the org */
  orgId: Scalars["ID"];
};

export type UpdateTravelTimeDurationPayload = {
  __typename: "UpdateTravelTimeDurationPayload";
  success: Scalars["Boolean"];
  updatedEvent: EventParent;
};

export type UpdateTravelTimeGoalResponse = {
  __typename: "UpdateTravelTimeGoalResponse";
  travelTimeGoals: TravelTimeGoals;
};

export type UpdateUserDefinedTeamMutationInput = {
  calendarIds: Array<InputMaybe<Scalars["String"]>>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  userDefinedTeamRelayId: Scalars["String"];
};

export type UpdateUserDefinedTeamMutationPayload = {
  __typename: "UpdateUserDefinedTeamMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  userDefinedTeam?: Maybe<UserDefinedTeam>;
};

export type UpdateUserFlowMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  flowKey: Scalars["String"];
  newState: Scalars["String"];
};

export type UpdateUserFlowMutationPayload = {
  __typename: "UpdateUserFlowMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  flowState?: Maybe<FlowState>;
};

export type UpdateUserOrgFlowMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  flowKey: Scalars["String"];
  newState: Scalars["String"];
  orgRelayId: Scalars["String"];
};

export type UpdateUserOrgFlowMutationPayload = {
  __typename: "UpdateUserOrgFlowMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  flowState?: Maybe<FlowState>;
  org?: Maybe<Org>;
  user?: Maybe<User>;
};

export type UpdateWorkingHoursMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** if true, will be saved as both Base and Meeting, regardless of the type on the working hours argument */
  saveMeetingAndWorkingHours?: InputMaybe<Scalars["Boolean"]>;
  workingHours: WorkingHoursInput;
};

export type UpdateWorkingHoursMutationPayload = {
  __typename: "UpdateWorkingHoursMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  updatedOrgMeetingHours?: Maybe<WorkingHours>;
  updatedUserMeetingHours?: Maybe<WorkingHours>;
  updatedUserWorkingHours?: Maybe<WorkingHours>;
  userWorkingHours?: Maybe<UserWorkingHours>;
};

export type UpdateWorkingLocationMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  workingLocation: WorkingLocationInput;
  workingLocationId: Scalars["String"];
};

export type UpdateWorkingLocationMutationPayload = {
  __typename: "UpdateWorkingLocationMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateWorkingLocationWeeklyScheduleMutation2Input = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  deletions: Array<WorkingLocationWeeklyScheduleUpdateDeletionEnumInput>;
  updates: Array<WorkingLocationWeeklyScheduleUpdateWorkingLocationByDayInput>;
};

export type UpdateWorkingLocationWeeklyScheduleMutation2Payload = {
  __typename: "UpdateWorkingLocationWeeklyScheduleMutation2Payload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateWorkingLocationWeeklyScheduleMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  workingLocationByDay: Array<WorkingLocationByDayInput>;
};

export type UpdateWorkingLocationWeeklyScheduleMutationPayload = {
  __typename: "UpdateWorkingLocationWeeklyScheduleMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdatedEvent = {
  __typename: "UpdatedEvent";
  eventDiffId: Scalars["String"];
  externalEventId: Scalars["String"];
  operation: OperationEnum;
  success: Scalars["Boolean"];
  title: Scalars["String"];
};

export type UpsertFocusTimeMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  options: GoalPatchFocusTimeOptionsInput;
  orgId: Scalars["String"];
};

export type UpsertFocusTimeMutationPayload = {
  __typename: "UpsertFocusTimeMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  goal?: Maybe<Goal>;
  goalEdge?: Maybe<GoalEdge>;
  org?: Maybe<Org>;
};

export type UpsertLunchMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  options: GoalPatchLunchOptionsInput;
  orgId: Scalars["String"];
};

export type UpsertLunchMutationPayload = {
  __typename: "UpsertLunchMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  goal?: Maybe<Goal>;
  goalEdge?: Maybe<GoalEdge>;
  org?: Maybe<Org>;
};

export type UpsertMeetingReliefMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  options: GoalPatchMeetingReliefOptionsInput;
  orgId: Scalars["String"];
};

export type UpsertMeetingReliefMutationPayload = {
  __typename: "UpsertMeetingReliefMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  goal?: Maybe<Goal>;
  goalEdge?: Maybe<GoalEdge>;
  org?: Maybe<Org>;
};

export type UpsertNonUserDefragEmailResponseInput = {
  additionalInformation?: InputMaybe<Scalars["String"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  emailInstanceId: Scalars["String"];
  responseType: NonUserDefragEmailResponseType;
};

export type UpsertNonUserDefragEmailResponsePayload = {
  __typename: "UpsertNonUserDefragEmailResponsePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  nonUserDefragEmailResponse?: Maybe<NonUserDefragEmailResponse>;
};

export type UpsertPreferredWorkMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  options: GoalPatchPreferredWorkOptionsInput;
  orgId: Scalars["String"];
};

export type UpsertPreferredWorkMutationPayload = {
  __typename: "UpsertPreferredWorkMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  goal?: Maybe<Goal>;
  goalEdge?: Maybe<GoalEdge>;
  org?: Maybe<Org>;
};

export type UpsertTravelTimeMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  options: GoalPatchTravelTimeOptionsInput;
  orgId: Scalars["String"];
};

export type UpsertTravelTimeMutationPayload = {
  __typename: "UpsertTravelTimeMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  goal?: Maybe<Goal>;
  goalEdge?: Maybe<GoalEdge>;
  org?: Maybe<Org>;
};

/** A Uniform Resource Name */
export type Urn = Node & {
  __typename: "Urn";
  /** The ID of an object */
  id: Scalars["ID"];
  scope?: Maybe<Urn>;
  type?: Maybe<UrnType>;
  value?: Maybe<Scalars["String"]>;
};

export type UrnInput = {
  id?: InputMaybe<Scalars["ID"]>;
  scope?: InputMaybe<UrnInput>;
  type?: InputMaybe<UrnType>;
  value?: InputMaybe<Scalars["String"]>;
};

export enum UrnType {
  Date = "Date",
  Email = "Email",
  Event = "Event",
  FlowState = "FlowState",
  Guest = "Guest",
  Org = "Org",
  Phone = "Phone",
  Services = "Services",
  Setting = "Setting",
  Team = "Team",
  Teammate = "Teammate",
  TimeZone = "TimeZone",
  User = "User",
  WorkOsOrg = "WorkOsOrg",
  WorkingGroup = "WorkingGroup",
  WorkingHours = "WorkingHours",
}

export enum UrnTypeEnum {
  Email = "Email",
  Integration = "Integration",
  Org = "Org",
  Phone = "Phone",
  User = "User",
}

/** A person who uses our app */
export type User = Node & {
  __typename: "User";
  chatHistory?: Maybe<ChatHistory>;
  /** The user's bucket o' booleans for the chrome extension */
  chromeExtensionFlagsErrorable?: Maybe<FlagsErrorable>;
  createdTime?: Maybe<Scalars["BigInt"]>;
  /** A user's collection of data sources */
  dataSources?: Maybe<DataSourceConnection>;
  diffDetailsById?: Maybe<DiffDetails>;
  diffDetailsByIdAndProposalId?: Maybe<DiffDetails>;
  emails: Array<Scalars["String"]>;
  /** The profile image of the user */
  externalImageUrl?: Maybe<Scalars["String"]>;
  /** The last name of the user */
  familyName?: Maybe<Scalars["String"]>;
  fetchSharedProposal?: Maybe<SharedProposal>;
  /** The collection of flow states for the user */
  flowStates?: Maybe<FlowStateConnection>;
  /** The first name of the user */
  givenName?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  jwtIssuedTime?: Maybe<Scalars["BigInt"]>;
  /** A list of primary email/org urns for this user */
  members?: Maybe<Array<Maybe<Urn>>>;
  onDemandDefrag: SchedulingProposal;
  /** The collection of orgs the user belongs to */
  orgs: OrgConnection;
  rolloutProperties?: Maybe<Scalars["JSON"]>;
  scoreTime?: Maybe<ScoredTime>;
  /** The user's bucket o' booleans for the services */
  servicesFlagsErrorable?: Maybe<FlagsErrorable>;
  /** Status of the user */
  status?: Maybe<UserStatusEnum>;
  /** The user's bucket o' booleans for the web app */
  webAppFlagsErrorable?: Maybe<FlagsErrorable>;
};

/** A person who uses our app */
export type UserDataSourcesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** A person who uses our app */
export type UserDiffDetailsByIdArgs = {
  diffId: Scalars["ID"];
};

/** A person who uses our app */
export type UserDiffDetailsByIdAndProposalIdArgs = {
  diffId: Scalars["ID"];
  proposalId: Scalars["ID"];
};

/** A person who uses our app */
export type UserFetchSharedProposalArgs = {
  proposalId: Scalars["ID"];
};

/** A person who uses our app */
export type UserFlowStatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A person who uses our app */
export type UserOnDemandDefragArgs = {
  startTime: Scalars["DateTime"];
  weightOverrides?: InputMaybe<ScoringWeightOverrides>;
};

/** A person who uses our app */
export type UserOrgsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orgRelayId?: InputMaybe<Scalars["String"]>;
};

/** A person who uses our app */
export type UserScoreTimeArgs = {
  attendees: Array<InputMaybe<Scalars["CalendarId"]>>;
  endTime: Scalars["DateTime"];
  startTime: Scalars["DateTime"];
};

export type UserAndExtendedInfo = {
  __typename: "UserAndExtendedInfo";
  ecosystem: EcosystemEnum;
  /** User's feature grid */
  featureStatesJson?: Maybe<Scalars["String"]>;
  /** Org's current accounting period */
  orgAccountingPeriod?: Maybe<OrgAccountingPeriodErrorable>;
  /** User's subscriptions */
  subscriptions?: Maybe<PaymentSubscriptionListErrorable>;
  /** User */
  user?: Maybe<User>;
  /** User's teams */
  userTeams?: Maybe<TeamListErrorable>;
};

/** The users team as defined by the user */
export type UserDefinedTeam = Node & {
  __typename: "UserDefinedTeam";
  calendarIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The ID of an object */
  id: Scalars["ID"];
};

export type UserInteractedWithNotification = {
  __typename: "UserInteractedWithNotification";
  successful: Scalars["Boolean"];
};

export type UserInteractedWithNotificationInput = {
  orgId: Scalars["ID"];
  type: Scalars["String"];
};

/** Meeting Hours for a given user id */
export type UserMeetingHours = Node & {
  __typename: "UserMeetingHours";
  hasBeenSet?: Maybe<Scalars["Boolean"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  userId?: Maybe<Scalars["String"]>;
  workingHours?: Maybe<WorkingHours>;
};

/** A connection to a list of items. */
export type UserMeetingHoursConnection = {
  __typename: "UserMeetingHoursConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserMeetingHoursEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserMeetingHoursEdge = {
  __typename: "UserMeetingHoursEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<UserMeetingHours>;
};

export type UserMessage = {
  __typename: "UserMessage";
  id: Scalars["ID"];
  text: Scalars["String"];
};

/** User metadata */
export type UserMetadata = {
  __typename: "UserMetadata";
  person: OrgPerson;
  trialMetadata?: Maybe<TrialMetadata>;
};

/** A User Profile includes just the basic information about a Calendar User (not necessarily a Clockwise user) */
export type UserProfile = {
  __typename: "UserProfile";
  /** The URL for a User's profile image */
  externalImageUrl?: Maybe<Scalars["String"]>;
  /** A user's last name */
  familyName?: Maybe<Scalars["String"]>;
  /** A user's first name */
  givenName?: Maybe<Scalars["String"]>;
};

export enum UserStatusEnum {
  Active = "Active",
  Preview = "Preview",
  Restricted = "Restricted",
}

/** Onboarding survey reponses from user */
export type UserSurveyResponse = {
  __typename: "UserSurveyResponse";
  freeformResponse?: Maybe<Scalars["String"]>;
  questionText?: Maybe<Scalars["String"]>;
  questionType?: Maybe<QuestionType>;
  responseText?: Maybe<Scalars["String"]>;
  responseType?: Maybe<ResponseType>;
};

export type UserSurveyResponseInput = {
  freeformResponse?: InputMaybe<Scalars["String"]>;
  questionText?: InputMaybe<Scalars["String"]>;
  questionType?: InputMaybe<QuestionType>;
  responseText?: InputMaybe<Scalars["String"]>;
  responseType?: InputMaybe<ResponseType>;
};

export type UserSurveyResponseList = {
  __typename: "UserSurveyResponseList";
  list: Array<UserSurveyResponse>;
};

export type UserSurveyResponseListErrorable = GraphEntityError | UserSurveyResponseList;

/** A wrapper for UserSurveyResponses to give them an ID. */
export type UserSurveyResponseWrapper = Node & {
  __typename: "UserSurveyResponseWrapper";
  /** The ID of an object */
  id: Scalars["ID"];
  userSurveyResponses: Array<UserSurveyResponse>;
};

export type UserSurveyResponseWrapperErrorable = GraphEntityError | UserSurveyResponseWrapper;

export type UserSurveyResponseWrapperInput = {
  id: Scalars["ID"];
  userSurveyResponses: Array<UserSurveyResponseInput>;
};

/** A per user tag on an event */
export type UserTag = {
  __typename: "UserTag";
  /** The aggregate count for this tag */
  count: Scalars["Float"];
  /** The states of this user tag */
  states?: Maybe<Array<TagState>>;
  /** The tag name */
  tag: Scalars["String"];
};

/** Working Hours for a given user id */
export type UserWorkingHours = Node & {
  __typename: "UserWorkingHours";
  hasBeenSet?: Maybe<Scalars["Boolean"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  userId?: Maybe<Scalars["String"]>;
  workingHours?: Maybe<WorkingHours>;
};

/** A connection to a list of items. */
export type UserWorkingHoursConnection = {
  __typename: "UserWorkingHoursConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserWorkingHoursEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserWorkingHoursEdge = {
  __typename: "UserWorkingHoursEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<UserWorkingHours>;
};

export type ValidateSlackChannelBySendingMessageMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  slackChannelName: Scalars["String"];
  teamId: Scalars["String"];
};

export type ValidateSlackChannelBySendingMessageMutationPayload = {
  __typename: "ValidateSlackChannelBySendingMessageMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  validateSlackChannelResponse: ValidateSlackChannelResponse;
};

/** DESCRIBE NEW FIELD HERE */
export type ValidateSlackChannelResponse = {
  __typename: "ValidateSlackChannelResponse";
  channel?: Maybe<SlackChannel>;
  /** The ID of an object */
  id: Scalars["ID"];
  isValid: Scalars["Boolean"];
};

export enum ValueType {
  Boolean = "Boolean",
  Long = "Long",
  Numeric = "Numeric",
  String = "String",
}

/** A verification for a verification code including the external account that will be connected */
export type VerificationCodeResponse = {
  __typename: "VerificationCodeResponse";
  slackTeam?: Maybe<Scalars["String"]>;
  slackUsername?: Maybe<Scalars["String"]>;
  valid?: Maybe<Scalars["Boolean"]>;
  verificationAttempted?: Maybe<Scalars["Boolean"]>;
  verificationCode?: Maybe<Scalars["String"]>;
  verificationType?: Maybe<VerificationType>;
};

export enum VerificationType {
  Slack = "Slack",
}

/** A video conference link for the event, found in the description, the location, or the conferenceData. */
export type VideoLink = {
  __typename: "VideoLink";
  type: ConferencingType;
  uri: Scalars["String"];
};

/** A view events response from the assistant in response to a query for events */
export type ViewEventsResponse = {
  __typename: "ViewEventsResponse";
  conversationId: Scalars["ID"];
  /** The date range the events are rendered within, expressed as a ISO8601 Interval */
  dateRange: Scalars["Interval"];
  /** Event Ids for the returned events */
  eventIds: Array<Scalars["String"]>;
  eventSearchBlocks: Array<EventSearchBlock>;
  id: Scalars["ID"];
  text: Scalars["String"];
};

/** The base node for all queries */
export type Viewer = Node & {
  __typename: "Viewer";
  /** Search external groups against a query string */
  groupQueryResult: ExternalGroupSearchResultErrorable;
  /** The ID of an object */
  id: Scalars["ID"];
  /** Non-User Defrag Email Survey Response */
  nonUserDefragEmailResponse: NonUserDefragEmailResponseErrorable;
  /** Search org persons against a query string */
  orgPersonQueryResultErrorable: OrgPersonQueryResultErrorable;
  /** Search persons against a query string */
  personQueryResultErrorable: PersonQueryResultErrorable;
  /** Search external rooms against a query string */
  roomQueryResult: ExternalRoomSearchResultErrorable;
  sudo?: Maybe<Sudo>;
  /** Search teams within an org */
  teamQueryResultErrorable: TeamQueryResultErrorable;
  /** A Clockwise user */
  user?: Maybe<User>;
  /** Checks that a verification code is valid and which external client it will connect */
  verificationCodeResponse?: Maybe<VerificationCodeResponse>;
};

/** The base node for all queries */
export type ViewerGroupQueryResultArgs = {
  excludeCalendars?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  orgRelayId: Scalars["ID"];
  query: Scalars["String"];
};

/** The base node for all queries */
export type ViewerNonUserDefragEmailResponseArgs = {
  emailInstanceId: Scalars["String"];
};

/** The base node for all queries */
export type ViewerOrgPersonQueryResultErrorableArgs = {
  excludeCalendars?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  orgRelayId: Scalars["ID"];
  query: Scalars["String"];
};

/** The base node for all queries */
export type ViewerPersonQueryResultErrorableArgs = {
  excludeCalendars?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  orgRelayId: Scalars["ID"];
  query: Scalars["String"];
};

/** The base node for all queries */
export type ViewerRoomQueryResultArgs = {
  excludeCalendars: Array<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  orgRelayId?: InputMaybe<Scalars["ID"]>;
  query: Scalars["String"];
};

/** The base node for all queries */
export type ViewerTeamQueryResultErrorableArgs = {
  excludeMyTeams?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  orgRelayId: Scalars["ID"];
  query: Scalars["String"];
};

export enum VisibilityEnum {
  Private = "Private",
  Public = "Public",
}

/** Button that links to a webapp page */
export type WebappLink = NotificationButton & {
  __typename: "WebappLink";
  surface: WebappSurface;
  text: Scalars["String"];
  tracking: TrackingData;
};

export enum WebappSurface {
  Breaks = "Breaks",
  FlexibleMeetings = "FlexibleMeetings",
  FocusTime = "FocusTime",
  Home = "Home",
}

/** a collection of calendarIds in a shared group */
export type WorkingGroup = Node & {
  __typename: "WorkingGroup";
  /** The people that make up the working group */
  calendarIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Calendars associated with this org */
  calendarWeeks?: Maybe<CalendarWeekConnection>;
  /** Calendars associated with this org */
  calendars?: Maybe<CalendarConnection>;
  /** An org's collection of events from all queried calendars and dates */
  eventParents: EventParentConnection;
  /** The ID of an object */
  id: Scalars["ID"];
  /** The name of the working group */
  name?: Maybe<Scalars["String"]>;
  /** The org id this working group belongs to */
  orgId?: Maybe<Scalars["String"]>;
  /** The list of rooms associated with this working group */
  rooms?: Maybe<Array<Maybe<Room>>>;
};

/** a collection of calendarIds in a shared group */
export type WorkingGroupCalendarWeeksArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  calendarIds: Array<InputMaybe<Scalars["String"]>>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  startDate: Scalars["Date"];
  timeZone: Scalars["String"];
};

/** a collection of calendarIds in a shared group */
export type WorkingGroupCalendarsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  calendarIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orgId?: InputMaybe<Scalars["String"]>;
};

/** a collection of calendarIds in a shared group */
export type WorkingGroupEventParentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  calendarIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  dates?: InputMaybe<Array<InputMaybe<Scalars["Date"]>>>;
  eventParentRelayId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
};

/** A connection to a list of items. */
export type WorkingGroupConnection = {
  __typename: "WorkingGroupConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WorkingGroupEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type WorkingGroupEdge = {
  __typename: "WorkingGroupEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<WorkingGroup>;
};

/** Working Hours */
export type WorkingHours = Node & {
  __typename: "WorkingHours";
  dayOverrides?: Maybe<Array<Maybe<DayOverride>>>;
  daySettings?: Maybe<Array<Maybe<DaySetting>>>;
  /** The ID of an object */
  id: Scalars["ID"];
  timeZone?: Maybe<Scalars["String"]>;
  /** Can be working hours or meeting hours (if you have PreferredFocusTime type, something's wrong... */
  type?: Maybe<WorkingHoursTypeEnum>;
};

export type WorkingHoursInput = {
  dayOverrides?: InputMaybe<Array<InputMaybe<DayOverrideInput>>>;
  daySettings?: InputMaybe<Array<InputMaybe<DaySettingInput>>>;
  id: Scalars["ID"];
  timeZone?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<WorkingHoursTypeEnum>;
};

/** Working Hours for a given user id */
export type WorkingHoursQueryResult = {
  __typename: "WorkingHoursQueryResult";
  userWorkingHours?: Maybe<UserWorkingHoursConnection>;
};

/** Working Hours for a given user id */
export type WorkingHoursQueryResultUserWorkingHoursArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export enum WorkingHoursTypeEnum {
  Base = "Base",
  Meeting = "Meeting",
  PreferredFocusTime = "PreferredFocusTime",
}

/** DESCRIBE NEW FIELD HERE */
export type WorkingLocation = Node & {
  __typename: "WorkingLocation";
  address?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  locationType: LocationTypeEnum;
  /** Name of the working location. */
  name?: Maybe<Scalars["String"]>;
  timeZone?: Maybe<Scalars["String"]>;
};

export type WorkingLocationByDay = {
  __typename: "WorkingLocationByDay";
  day: Scalars["String"];
  workingLocation: WorkingLocation;
};

export type WorkingLocationByDayInput = {
  day: Scalars["String"];
  workingLocationId: Scalars["String"];
};

export type WorkingLocationInput = {
  address?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  locationType: LocationTypeEnum;
  name?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
};

export type WorkingLocationList = {
  __typename: "WorkingLocationList";
  list: Array<WorkingLocation>;
};

export type WorkingLocationListErrorable = GraphEntityError | WorkingLocationList;

/** DESCRIBE NEW FIELD HERE */
export type WorkingLocationWeeklySchedule = {
  __typename: "WorkingLocationWeeklySchedule";
  /** User's working location preference by day of the week. */
  workingLocationByDay: Array<WorkingLocationByDay>;
};

export type WorkingLocationWeeklyScheduleErrorable =
  | GraphEntityError
  | WorkingLocationWeeklySchedule;

export enum WorkingLocationWeeklyScheduleUpdateDeletionEnumInput {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export type WorkingLocationWeeklyScheduleUpdateWorkingLocationByDayInput = {
  day: Scalars["String"];
  workingLocation: WorkingLocationInput;
};

/** Working time constraints for a time period */
export type WorkingSetting = {
  __typename: "WorkingSetting";
  excludedSlots?: Maybe<Array<Maybe<ExcludedSlots>>>;
  maxSlot?: Maybe<MaxSlot>;
  minSlot?: Maybe<MinSlot>;
};

export type WorkingSettingInput = {
  excludedSlots?: InputMaybe<Array<InputMaybe<ExcludedSlots>>>;
  maxSlot?: InputMaybe<MaxSlot>;
  minSlot?: InputMaybe<MinSlot>;
};

/** A summary of a consequences for a diff proposed for the current user's calendar */
export type YourConsequenceDiffSummary = DiffSummary & {
  __typename: "YourConsequenceDiffSummary";
  action: DiffAction;
  /** False if the diff has been rejected by the user */
  active: Scalars["Boolean"];
  attendees: ProposalAttendees;
  currentTime: Scalars["Interval"];
  /** The ID of the event being modified */
  eventId: Scalars["String"];
  id: Scalars["ID"];
  /** Whether or not the event is flexible */
  isFlexible: Scalars["Boolean"];
  recurrence: EventRecurrence;
  /** The recurrence rule for the event */
  recurrenceRule?: Maybe<Scalars["RecurrenceRule"]>;
  /** Either the existing title or the proposed new title */
  title: Scalars["String"];
  /** Null if the diff's time is not being updated or there were no times found */
  updatedTime?: Maybe<Scalars["Interval"]>;
};

/** Zoom Account */
export type ZoomAccount = Node & {
  __typename: "ZoomAccount";
  accountId: Scalars["String"];
  connected: Scalars["String"];
  /** Domain of the zoom account that’s been connected */
  displayName: Scalars["String"];
  /** boolean whether the user has a valid zoom account for the connected zoom integration */
  hasValidZoomAccount: Scalars["Boolean"];
  /** The ID of an object */
  id: Scalars["ID"];
  ownerPersons: PersonListErrorable;
  /** userId of the person who connected the zoom account */
  ownerUserId: Scalars["String"];
};

export type ZoomAccountErrorable = GraphEntityError | ZoomAccount;

/** Necessary attendee information to edit an event */
export type AttendeesForEditEvent = {
  /** CalendarId of attendee */
  calendarId: Scalars["String"];
  /** Whether the attendee is optional */
  isOptional?: InputMaybe<Scalars["Boolean"]>;
  /** Whether the attendee is the organizer of the meeting */
  isOrganizer: Scalars["Boolean"];
  /** Invite response status of attendee */
  responseStatus: ResponseStatusEnum;
};

export type CreateTeamCalendarMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  teamId: Scalars["String"];
};

export type CreateTeamCalendarMutationPayload = {
  __typename: "createTeamCalendarMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateTeamMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  setAsPrimary?: InputMaybe<Scalars["Boolean"]>;
  teamMembers: Array<TeamRoleAndPersonIdInput>;
  teamName: Scalars["String"];
  teamType: TeamType;
};

export type CreateTeamMutationPayload = {
  __typename: "createTeamMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  team: Team;
};

export type DeleteTeamMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  teamId: Scalars["String"];
};

export type DeleteTeamMutationPayload = {
  __typename: "deleteTeamMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedTeamId: Scalars["String"];
};

export type SaveTeamSettingsMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  manageTeamCalendar?: InputMaybe<Scalars["Boolean"]>;
  minimumMinutesForSyncing?: InputMaybe<Scalars["Int"]>;
  noMeetingDayEmailOnScheduleOver?: InputMaybe<Scalars["Boolean"]>;
  noMeetingDayOfWeek?: InputMaybe<NoMeetingDayOfWeek>;
  orgRelayId: Scalars["String"];
  slackChannelId?: InputMaybe<Scalars["String"]>;
  slackChannelName?: InputMaybe<Scalars["String"]>;
  slackNotificationEnabled?: InputMaybe<Scalars["Boolean"]>;
  slackNotificationTimeSlot?: InputMaybe<TimeSlotEnum>;
  syncWFHEvents?: InputMaybe<Scalars["Boolean"]>;
  teamId: Scalars["String"];
  useNoMeetingDay?: InputMaybe<Scalars["Boolean"]>;
};

export type SaveTeamSettingsMutationPayload = {
  __typename: "saveTeamSettingsMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  teamSettings: TeamSettings;
};

export type SaveTeamUserSettingsMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  teamId: Scalars["String"];
  useNoMeetingDay?: InputMaybe<Scalars["Boolean"]>;
  useTeamCalendar?: InputMaybe<Scalars["Boolean"]>;
};

export type SaveTeamUserSettingsMutationPayload = {
  __typename: "saveTeamUserSettingsMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  teamUserSettings: TeamUserSettings;
};

export type SetPrimaryTeamMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  teamId: Scalars["String"];
};

export type SetPrimaryTeamMutationPayload = {
  __typename: "setPrimaryTeamMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  team: Team;
};

export type TeamCalendarMap = {
  __typename: "teamCalendarMap";
  calendarIds?: Maybe<Array<Scalars["String"]>>;
  teamId: Scalars["String"];
  teamName?: Maybe<Scalars["String"]>;
};

export type UpdateTeamMutationInput = {
  addedTeamMembers?: InputMaybe<Array<TeamRoleAndPersonIdInput>>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  newTeamName?: InputMaybe<Scalars["String"]>;
  orgRelayId: Scalars["String"];
  removedTeamMembers?: InputMaybe<Array<Scalars["String"]>>;
  teamId: Scalars["String"];
};

export type UpdateTeamMutationPayload = {
  __typename: "updateTeamMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  team: Team;
};
