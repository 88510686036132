export const FLOW_STATE_KEYS = {
  INITIAL_ONBOARDING: "InitialOnboarding",
  FAST_CRAWL: "FastCrawl",
  CHROME_FEED_ONBOARDING: "ChromeFeedOnboarding",
  WEB_ONBOARDING: "WebOnboarding",
  CHECKLIST_FLEX_MEETINGS: "ChecklistFlexMeetings",
  CHECKLIST_HOLDS: "ChecklistHolds",
  CHECKLIST_PREFERENCES: "ChecklistPreferences",
  CHECKLIST_SCHEDULE_MEETING: "ChecklistScheduleMeeting",
  AI_CHECKLIST_CLOSED: "AIChecklistClosed",
  NAVIGATION_EDUCATION: "NavigationEducation",
};
