import { experiments } from "#webapp/src/state/local-storage";
import {
  EXPERIMENT_FLAGS,
  ExperimentMappingType,
  ExperimentNameType,
} from "@clockwise/client-commons/src/util/experiment";
export { EXPERIMENT_FLAGS };
export type { ExperimentNameType };

export function isOnDisableHealthChecks() {
  return isOn(EXPERIMENT_FLAGS.disableHealthChecks);
}

export function isOnTrackLogging() {
  return isOn(EXPERIMENT_FLAGS.trackLogging);
}

export function getBulkExperiments(): ExperimentMappingType {
  return Object.values(EXPERIMENT_FLAGS).reduce(
    (acc, expName: ExperimentNameType) => ({
      ...acc,
      [expName]: isOn(expName),
    }),
    {} as ExperimentMappingType,
  );
}

export function isOn(experiment: ExperimentNameType) {
  return experiments.getFlag(experiment);
}
