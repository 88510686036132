import { graphql } from "react-relay";

export const scheduleNewEventMutation = graphql`
  mutation ScheduleNewEventV2Mutation($input: ScheduleNewEventV2MutationInput!) {
    scheduleNewEventV2(input: $input) {
      eventParent {
        id
        externalEventId
        events {
          startTime {
            millis
          }
          annotatedEvent {
            id
            externalEventId
            orgTags {
              tag
              state {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
          annotatedRecurringEvent {
            id
            externalEventId
            orgTags {
              tag
              state {
                value
                subjectType
                subjectValue
                lastModified
                active
              }
            }
          }
        }
      }
    }
  }
`;
