import { paths } from "#webapp/src/constants/site.constant";
import {
  slackNonce,
  slackNonceForTeam,
  zoomNonce,
  zoomUserLevelNonce,
} from "#webapp/src/state/local-storage";
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { getServerUrl } from "@clockwise/web-commons/src/util/post-message-common.util";

type TRedirectPath = "chrome" | "slack";

export function generateNonce() {
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const length = 20;
  let nonce = "";
  for (let i = 0; i < length; i++) {
    nonce += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return nonce;
}

/////////////////
// SLACK
/////////////////
function getSlackClientId(getDogfoodApp: boolean) {
  switch (getEnvironment()) {
    case "preprod":
    case "production":
      // We have a separate internal-only app on Prod.
      return getDogfoodApp ? "88887328098.1007455445024" : "88887328098.281611107090";
    case "staging":
      return "279700242646.282445389862";
    default:
      return "279700242646.282445389862";
  }
}

function getBotScopes() {
  return "app_mentions:read,channels:read,chat:write,chat:write.public,commands,im:write,users:read,mpim:read,mpim:write,im:read,groups:read";
}

function getBotScopesForTeam() {
  return "channels:read,chat:write,chat:write.public";
}

function getUserScopes() {
  return "dnd:read,dnd:write,users.profile:read,users.profile:write";
}

function generateBaseSlackUrlWithNewBotScope(getDogfoodApp: boolean) {
  return (
    `https://slack.com/oauth/v2/authorize?client_id=${getSlackClientId(getDogfoodApp)}` +
    `&scope=${getBotScopes()}` +
    `&user_scope=${getUserScopes()}`
  );
}

function generateBaseSlackUrlWithNewBotScopeForTeam(getDogfoodApp: boolean) {
  return `https://slack.com/oauth/v2/authorize?client_id=${getSlackClientId(
    getDogfoodApp,
  )}&scope=${getBotScopesForTeam()}`;
}

function generateRedirectUri(redirectPath: TRedirectPath) {
  let redirect = "";
  if (redirectPath === "chrome") {
    redirect = paths.chromeSlackVerifier;
  } else if (redirectPath === "slack") {
    redirect = paths.slackLanding + "/connect";
  }
  return `${getServerUrl()}${redirect}`;
}

function generateRedirectUriForTeam(redirectPath: TRedirectPath, teamId: string) {
  let redirect = "";
  if (redirectPath === "chrome") {
    redirect = paths.chromeSlackVerifier;
  } else if (redirectPath === "slack") {
    redirect = paths.slackLanding + "/connect";
  }
  return `${getServerUrl()}${redirect}/team/${teamId}`;
}

function generateNonceAndStoreForSlack(forTeam: boolean) {
  const nonce = generateNonce();
  if (forTeam) {
    slackNonceForTeam.set(nonce);
  } else {
    slackNonce.set(nonce);
  }
  return nonce;
}

/**
 * After a user has successfully connected their Slack account, we no longer need the nonce.
 */
export function cleanUpSlackNonce() {
  slackNonce.remove();
  slackNonceForTeam.remove();
}

export function generateSlackAuthUrl(redirectPath: TRedirectPath, getDogfoodApp: boolean) {
  return `${generateBaseSlackUrlWithNewBotScope(getDogfoodApp)}&redirect_uri=${generateRedirectUri(
    redirectPath,
  )}&state=${generateNonceAndStoreForSlack(false)}`;
}

export function generateSlackAuthUrlForTeam(
  redirectPath: TRedirectPath,
  teamId: string,
  getDogfoodApp: boolean,
) {
  return `${generateBaseSlackUrlWithNewBotScopeForTeam(
    getDogfoodApp,
  )}&redirect_uri=${generateRedirectUriForTeam(
    redirectPath,
    btoa(teamId),
  )}&state=${generateNonceAndStoreForSlack(true)}`;
}

/////////////////
// ZOOM
/////////////////
function getZoomClientId(isUserLevelAuth: boolean) {
  if (isUserLevelAuth) {
    switch (getEnvironment()) {
      case "preprod":
      case "production":
        return "Vu2MdltXQTF6Cy1RLJolw";
      case "staging":
      default:
        return "6pqpdz65Swyi6DoIEQCRbw";
    }
  }

  switch (getEnvironment()) {
    case "preprod":
    case "production":
      return "DfDHyFg1Rb2dGxOuFyXnFA";
    case "staging":
    default:
      return "JEY4AQT4TcSeXrcZJLGDgw";
  }
}

function generateBaseZoomAuthUrl(isUserLevelAuth: boolean) {
  return `https://zoom.us/oauth/authorize?response_type=code&client_id=${getZoomClientId(
    isUserLevelAuth,
  )}`;
}

function generateRedirectUriForZoom(isUserLevelAuth: boolean) {
  return `${getServerUrl()}${paths.chromeZoomVerifier}${isUserLevelAuth ? "/user" : "/account"}`;
}

function generateNonceAndStoreForZoom(isUserLevelNonce: boolean) {
  const nonce = generateNonce();
  if (isUserLevelNonce) {
    zoomUserLevelNonce.set(nonce);
  } else {
    zoomNonce.set(nonce);
  }
  return nonce;
}

export function generateZoomAccountLevelAuthUrl() {
  return `${generateBaseZoomAuthUrl(false)}&redirect_uri=${generateRedirectUriForZoom(
    false,
  )}&state=${generateNonceAndStoreForZoom(false)}`;
}

export function generateZoomUserLevelAuthUrl() {
  return `${generateBaseZoomAuthUrl(true)}&redirect_uri=${generateRedirectUriForZoom(
    true,
  )}&state=${generateNonceAndStoreForZoom(true)}`;
}
