/**
 * @generated SignedSource<<0353d173359b677b8d9fa79b75fcc9d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrgUserSelectForm_org$data = {
  readonly id: string;
  readonly experiments: ReadonlyArray<string | null> | null;
  readonly currentOrgPersons: {
    readonly __typename: "GraphEntityError";
    readonly message: string | null;
  } | {
    readonly __typename: "OrgPersonList";
    readonly list: ReadonlyArray<{
      readonly userId: string | null;
      readonly primaryCalendarId: string;
      readonly calendarAliases: ReadonlyArray<string>;
      readonly profile: {
        readonly givenName: string | null;
        readonly familyName: string | null;
        readonly externalImageUrl: string | null;
      } | null;
      readonly isYou: boolean;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly " $fragmentType": "OrgUserSelectForm_org";
};
export type OrgUserSelectForm_org$key = {
  readonly " $data"?: OrgUserSelectForm_org$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrgUserSelectForm_org">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrgUserSelectForm_org",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "experiments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "currentOrgPersons",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            }
          ],
          "type": "GraphEntityError",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrgPerson",
              "kind": "LinkedField",
              "name": "list",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "primaryCalendarId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "calendarAliases",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserProfile",
                  "kind": "LinkedField",
                  "name": "profile",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "givenName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "familyName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "externalImageUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isYou",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "OrgPersonList",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Org",
  "abstractKey": null
};

(node as any).hash = "f5c5e74a774dab51b799a4844ebcd2a7";

export default node;
