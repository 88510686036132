// IMPORTS
// schema
import * as ISchema from "#webapp/src/__schema__";

import { unpauseEventMutation } from "#webapp/src/mutations/unpause-event";
import { modernCache } from "#webapp/src/network/modern-network-layer";
import { commitMutation } from "react-relay";
import { Environment } from "relay-runtime";

// TYPES
interface IUnpauseEventMutationResponse {
  unpauseEvent: ISchema.IUnpauseEventMutationPayload;
}

// MUTATION
export function unpauseEvent(
  environment: Environment,
  input: ISchema.IUnpauseEventMutationInput,
  onSuccess: (response?: ISchema.IUnpauseEventMutationPayload) => void,
  onError: (error?: any) => void,
  calendarId: string,
) {
  commitMutation(environment, {
    onError,
    mutation: unpauseEventMutation,
    variables: { input, calendarId },
    onCompleted: (response: IUnpauseEventMutationResponse, errors?: Error[]) => {
      if (errors) {
        onError(errors[0]);
      } else {
        // if we're not in a modern environment, modernCache will be undefined
        if (modernCache) {
          modernCache.clear();
        }
        onSuccess(response.unpauseEvent);
      }
    },
  });
}
