import classNames from "classnames";
import React, { useEffect } from "react";

// internal

export interface ControlData {
  on: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

type ControlsProps = {
  controls: ControlData[];
  onChange: (controlIndex: number, control: ControlData) => void;
  renderControlContent: (data: ControlData) => JSX.Element | null;
  allowPointerEvents: boolean;
};

type ControlDataMap = {
  data: ControlData;
  index: number;
};

const LargeControl = ({
  control,
  index,
  allowPointerEvents,
  onControlClick,
  renderControlContent,
}: {
  control: ControlData;
  index: number;
  allowPointerEvents: boolean;
  onControlClick: () => void;
  renderControlContent: () => JSX.Element | null;
}) => {
  const controlWrapperClass = classNames(
    "cw-border cw-p-0 cw-border-solid cw-border-default cw-rounded-md cw-mb-4 cw-cursor-pointer cw-select-none hover:cw-bg-default-hover",
    {
      "cw-text-positive cw-border-brand-pressed": control.on,
      "cw-opacity-75 cw-cursor-not-allowed": control.disabled,
    },
  );

  const controlContentClass = classNames("cw-w-full cw-flex cw-flex-row cw-items-center", {
    "cw-pointer-events-none": control.disabled && !allowPointerEvents,
  });

  if (control.hidden) {
    return null;
  } else {
    return (
      <div key={`control-${index}`} className={controlWrapperClass}>
        <div className={controlContentClass} onClick={onControlClick}>
          {renderControlContent()}
        </div>
      </div>
    );
  }
};

export const LargeControlGroup = ({
  controls,
  onChange,
  allowPointerEvents,
  renderControlContent,
}: ControlsProps) => {
  useEffect(() => {
    const onControls = controls.reduce<ControlDataMap[]>((acc, data, index) => {
      if (data.on) {
        acc.push({ data, index });
      }
      return acc;
    }, []);
    onControls.slice(1).forEach(({ data, index }) => onChange(index, { ...data, on: false }));
  });

  const onControlSelect = (indexSelected: number, control: ControlData) => {
    const newControl = {
      ...control,
      on: true,
    };
    onChange(indexSelected, newControl);
    const controlsReduced = controls.reduce<ControlDataMap[]>((acc, data, index) => {
      if (data.on && index !== indexSelected) {
        acc.push({ data, index });
      }
      return acc;
    }, []);
    controlsReduced.forEach(({ data, index }) => onChange(index, { ...data, on: false }));
  };

  return (
    <div>
      {controls.map((control, index) => (
        <LargeControl
          key={`control-${index}`}
          allowPointerEvents={allowPointerEvents}
          onControlClick={() => {
            onControlSelect(index, control);
          }}
          renderControlContent={() => {
            return renderControlContent(control);
          }}
          index={index}
          control={control}
        />
      ))}
    </div>
  );
};
