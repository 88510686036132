import { GraphEntityError } from "@clockwise/schema";
import { compact } from "lodash";

type GenericGraphQLObject = { __typename: string };

export type Errorable<T extends GenericGraphQLObject> = T | GraphEntityError;

export type Node<T> = { node?: T | null };
export type Edges<T> = { edges?: (Node<T> | null | undefined)[] | null | undefined };

export interface Viewer {
  user: UserWithOrgs | null;
}
type Org = {
  id: string;
};
export interface UserWithOrgs {
  orgs: Edges<Org>;
}

export function isError<T extends GenericGraphQLObject>(
  value: Errorable<T>,
): value is GraphEntityError {
  return value.__typename === "GraphEntityError";
}

export function isValue<T extends GenericGraphQLObject>(
  value: Errorable<T>,
): value is Exclude<T, GraphEntityError> {
  return !isError(value);
}

function getValueFromUnion<
  T extends Exclude<GenericGraphQLObject, GraphEntityError>,
  K extends T["__typename"]
>(value: T | null, typename: K): Extract<T, { __typename: K }> | null {
  if (!value) {
    return null;
  }

  const isOneTypeOfUnionType = (value: T): value is Extract<T, { __typename: K }> =>
    value.__typename === typename;

  return isOneTypeOfUnionType(value) ? value : null;
}

// Function signature overloading
export function getValue<
  T extends GenericGraphQLObject,
  K extends Exclude<T, GraphEntityError>["__typename"]
>(
  value: Errorable<T> | null | undefined,
  typename: K,
): Extract<Exclude<T, GraphEntityError>, { __typename: K }> | null;
export function getValue<T extends GenericGraphQLObject>(
  value: Errorable<T> | null | undefined,
): Exclude<T, GraphEntityError> | null;

export function getValue(value: any, typename?: any): any {
  if (!value) {
    return null;
  }

  const nonErrorableValue = isValue(value) ? value : null;

  if (!nonErrorableValue) {
    return null;
  }

  return typename ? getValueFromUnion(nonErrorableValue, typename) : nonErrorableValue;
}

export function getError<T extends GenericGraphQLObject>(
  value: Errorable<T> | null | undefined,
): GraphEntityError | null {
  if (!value) {
    return null;
  }

  return isError(value) ? value : null;
}

/**
 * Given a `Viewer` object, this type extracts the `Org` object type from it, assuming all the
 * various fields are not null. This allows for a variety of different Viewer objects to be used in
 * type-safe manner, e.g. an exhaustive Viewer object from Relay vs. a sparse Viewer object from an
 * Apollo query.
 */
type DefiniteOrg<V extends Viewer> = NonNullable<
  NonNullable<NonNullable<V["user"]>["orgs"]["edges"]>[0]
>["node"];
export function getCurrentOrg<V extends Viewer>(
  viewer: V | null | undefined,
  orgId: string,
): DefiniteOrg<V> | null {
  if (!viewer?.user?.orgs?.edges?.length) {
    return null;
  }

  // find the right org depending on orgId state
  const edges = compact(viewer.user.orgs.edges ?? []);
  const org = edges.find((edge) => edge.node?.id === orgId)?.node || edges[0]?.node;

  // if we haven't found anything, return null
  if (!org) {
    return null;
  }

  return org;
}
