import { graphql } from "react-relay";

export const sendM365TeamAvailabilityCalendarInvitesForTeamMutation = graphql`
  mutation SendM365TeamAvailabilityCalendarInvitesForTeamMutation(
    $input: SendM365TeamAvailabilityCalendarInvitesForTeamMutationInput!
  ) {
    sendM365TeamAvailabilityCalendarInvitesForTeam(input: $input) {
      clientMutationId
    }
  }
`;
