import { greys, theme } from "@clockwise/web-commons/src/styles/color.styles";
import { fontFamilySans, weight } from "@clockwise/web-commons/src/styles/type.styles";

const empty = {
  color: theme.textSecondary,
  padding: "12px 0",
} as const;

const mixedOrgCallout = {
  fontSize: 12,
  color: greys.slate,
  fontWeight: weight.std,
  fontFamily: fontFamilySans,
} as const;

export const styles = {
  empty,
  mixedOrgCallout,
} as const;
