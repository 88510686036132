import { articleUrls } from "@clockwise/client-commons/src/constants/help-center";
import { supportEmail } from "@clockwise/client-commons/src/constants/site";
import { Link } from "@clockwise/design-system";
import { SubscriptionInterval } from "@clockwise/schema";
import classNames from "classnames";
import { Info } from "phosphor-react";
import React from "react";
import {
  ITierType,
  businessTier,
  enterpriseTier,
  freeTier,
  pricingFaqs,
  proTier,
} from "../../constants/org-pricing.constants";
import ComparisonGrid from "./comparison-grid/ComparisonGrid";
import DowngradeDialog from "./downgrade-dialog/DowngradeDialog";
import Header from "./header/Header";
import { JoinExistingPlanBanner } from "./join-existing-plan-banner/JoinExistingPlanBanner";
import { PricingFaq } from "./pricing-faq";
import BusinessTierHeader from "./tier-headers/BusinessTierHeader";
import EnterpriseTierHeader from "./tier-headers/EnterpriseTierHeader";
import FreeTierHeader from "./tier-headers/FreeTierHeader";
import ProTierHeader from "./tier-headers/ProTierHeader";
import { PlanTypes } from "./types";

export type PricingPlansProps = {
  billingRecurrence: SubscriptionInterval;
  daysLeftOnTrial: number;
  downgradeDialogOpen: boolean;
  isUserOnTrial: boolean;
  onContactUsClick: (tierType: ITierType) => void;
  onDowngradeClick: (tierType: ITierType) => void;
  onDowngradeConfirmation: () => void;
  onDowngradeDialogClose: () => void;
  onUpgradeClick: (tierType: ITierType) => void;
  primarySubscriptionPlan: PlanTypes;
  shouldShowJoinExistingPlanBanner: boolean;
  isFullWidth: boolean;
  isM365Beta: boolean;
};

const PricingPlans = ({
  billingRecurrence,
  daysLeftOnTrial,
  downgradeDialogOpen,
  isUserOnTrial,
  onContactUsClick,
  onDowngradeClick,
  onDowngradeConfirmation,
  onDowngradeDialogClose,
  onUpgradeClick,
  primarySubscriptionPlan,
  shouldShowJoinExistingPlanBanner,
  isFullWidth,
  isM365Beta,
}: PricingPlansProps) => {
  const maybeRenderNonStandardPlansCallout = () => {
    if (primarySubscriptionPlan !== "NonStandard") {
      return null;
    }

    return (
      <div
        className={classNames(
          "cw-body-lg cw-w-full cw-rounded cw-py-5 cw-mt-7 cw-mb-4 cw-text-center cw-bg-info",
          "cw-border cw-border-solid cw-border-info",
        )}
      >
        <Info className="-cw-mb-[6px]" /> You’re on a paid plan with custom terms. Contact{" "}
        <Link href={`mailto:${supportEmail}`} target="_blank">
          {supportEmail}
        </Link>{" "}
        to learn more about the details.
      </div>
    );
  };

  const renderPricingTierHeaders = () => {
    return (
      <div
        className={classNames(
          "cw-grid cw-grid-cols-1 cw-w-full cw-gap-y-2 cw-items-end",
          "md:cw-grid-cols-2 md:cw-gap-x-8",
          { "lg:cw-grid-cols-4": isFullWidth, "xl:cw-grid-cols-4": !isFullWidth },
        )}
      >
        <FreeTierHeader
          billingRecurrence={billingRecurrence}
          tier={freeTier}
          onSelect={() => onDowngradeClick(freeTier.type)}
          isCurrentPlan={primarySubscriptionPlan === "Free"}
          isFullWidth={isFullWidth}
        />
        <ProTierHeader
          billingRecurrence={billingRecurrence}
          daysLeftOnTrial={daysLeftOnTrial}
          isUserOnTrial={isUserOnTrial}
          onSelect={() => onUpgradeClick(proTier.type)}
          primarySubscriptionPlan={primarySubscriptionPlan}
          tier={proTier}
          isFullWidth={isFullWidth}
        />
        <BusinessTierHeader
          billingRecurrence={billingRecurrence}
          tier={businessTier}
          onSelect={() => onContactUsClick(businessTier.type)}
          isCurrentPlan={primarySubscriptionPlan === "Business"}
          isFullWidth={isFullWidth}
          isM365Beta={isM365Beta}
        />
        <EnterpriseTierHeader
          tier={enterpriseTier}
          onSelect={() => onContactUsClick(enterpriseTier.type)}
          isCurrentPlan={primarySubscriptionPlan === "Enterprise"}
          isFullWidth={isFullWidth}
          isM365Beta={isM365Beta}
        />
      </div>
    );
  };

  const renderFAQ = () => {
    return (
      <div className="cw-text-center cw-w-full cw-bg-neutral cw-flex cw-p-6 cw-mb-12 cw-rounded cw-flex-col md:cw-flex-row cw-mt-4">
        <div className="cw-heading-3xl">FAQ</div>
        <div className="cw-flex cw-flex-col cw-pl-4">
          {pricingFaqs.map((faq) => (
            <PricingFaq key={faq.question} question={faq.question} answer={faq.answer} />
          ))}
          {isM365Beta ? (
            <PricingFaq
              key={`m365-beta-question`}
              question={"What should I expect as part of the Clockwise for Microsoft Beta?"}
              answer={
                "We're continuing to fine-tune the experience for our users as part of the Clockwise for Microsoft Beta. New Clockwise functionality launched in our Google integration may not be immediately available on Microsoft. You may also encounter user experience bugs. To report an issue or request help, please email us at support@getclockwise.com."
              }
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="cw-flex cw-flex-col cw-flex-1 cw-items-center cw-max-w-[1200px] cw-w-full">
      {maybeRenderNonStandardPlansCallout()}
      <Header />
      {isM365Beta ? (
        <div
          className={classNames(
            "cw-bg-busy-emphasis",
            "cw-rounded-3xl",
            "cw-text-14 cw-text-white",
            "cw-px-4 cw-py-2 cw-mb-6",
          )}
        >
          Clockwise is currently in beta for Microsoft 365.{" "}
          <a
            className="cw-underline cw-cursor-pointer"
            onClick={() => {
              window.open(articleUrls.m365, "_blank");
            }}
          >
            What does this mean?
          </a>
        </div>
      ) : (
        ""
      )}

      {shouldShowJoinExistingPlanBanner && <JoinExistingPlanBanner />}
      {renderPricingTierHeaders()}
      <ComparisonGrid
        isUserOnTrial={isUserOnTrial}
        onContactUsClick={onContactUsClick}
        onDowngradeClick={onDowngradeClick}
        onUpgradeClick={onUpgradeClick}
        primarySubscriptionPlan={primarySubscriptionPlan}
        isM365Beta={isM365Beta}
      />
      {renderFAQ()}
      <DowngradeDialog
        isOpen={downgradeDialogOpen}
        onClose={onDowngradeDialogClose}
        onConfirm={onDowngradeConfirmation}
      />
    </div>
  );
};

export default PricingPlans;
