import { io, Socket } from "socket.io-client";

import { getSocketUrl } from "@clockwise/client-commons/src/config/api";

const path = `/api/io`;

let client: Socket | undefined = undefined;

const socket = {
  // getter prevents the client from automatically being instantiated, prevents session race conditions
  get(): Socket {
    if (!client) {
      client = io(`${getSocketUrl()}`, { path, transports: ["websocket"] });
    }

    return client;
  },
};

export default socket;
