// schema

// libraries
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useMemo } from "react";
// components
import { AvatarPerson } from "#webapp/src/components/avatar/AvatarPerson";
import { AvatarStack } from "@clockwise/web-commons/src/ui/avatar-stack/AvatarStack";
// internal
import { niceNameFromPerson } from "@clockwise/client-commons/src/util/event";
import { OrgPerson } from "@clockwise/schema";
import { greys } from "@clockwise/web-commons/src/styles/color.styles";
import { AvatarOrgPerson, AvatarPerson as AvatarPersonType } from "../avatar/AvatarTypes";
export const useStyles = makeStyles({
  avatar: {
    display: "inline-block",
    verticalAlign: "middle",
    position: "relative",

    "& .overflow": {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "bold",
      borderRadius: "50%",
      border: `2px solid ${greys.white}`,
      background: "#c4c4c4",
      color: greys.white,
    },
  },
});

type Props = {
  orgPersons?: AvatarOrgPerson[] | OrgPerson[];
  persons?: AvatarPersonType[];
  extraOverflowCount?: number;
  maxAvatarCount: number;
  size: "small" | "medium" | "large";
  overlap?: boolean;
  customTooltips?: JSX.Element[];
  disableAnimation?: boolean;
};

export const AvatarRow = ({
  orgPersons,
  persons,
  extraOverflowCount = 0,
  maxAvatarCount,
  size,
  overlap = false,
  customTooltips = [],
  disableAnimation = false,
}: Props) => {
  const classes = useStyles();
  ///////////////
  // Rendering //
  ///////////////

  const renderAvatarPerson = useCallback(
    (person: AvatarPersonType | null, orgPerson: AvatarOrgPerson | OrgPerson | null, i: number) => {
      // This is all just to appease typescript
      const avatarOrgPerson = {
        profile: {
          givenName: orgPerson?.profile?.givenName || null,
          familyName: orgPerson?.profile?.familyName || null,
          externalImageUrl: orgPerson?.profile?.externalImageUrl || null,
        },
        userId: orgPerson?.userId || undefined,
        primaryCalendarId: orgPerson?.primaryCalendarId,
      };
      //

      return (
        <div
          className={classes.avatar}
          key={person?.primaryEmail || orgPerson?.primaryCalendarId || i}
        >
          <AvatarPerson
            toolTipContent={
              customTooltips[i] || (
                <div>
                  <div>{niceNameFromPerson(orgPerson || person, true, undefined, true)}</div>
                  <div>
                    {(orgPerson && orgPerson.primaryCalendarId) || (person && person.primaryEmail)}
                  </div>
                </div>
              )
            }
            orgPerson={avatarOrgPerson}
            person={person}
            backupCalendarId={
              (orgPerson && orgPerson.primaryCalendarId) || (person && person.primaryEmail) || ""
            }
            size={size}
            unClickable
            hasOverlap={overlap}
          />
        </div>
      );
    },
    [classes.avatar, customTooltips, overlap, size],
  );

  const avatars = useMemo(
    () =>
      orgPersons
        ? orgPersons.map((person, i) => renderAvatarPerson(null, person, i))
        : (persons || []).map((person, i) => renderAvatarPerson(person, null, i)),
    [orgPersons, persons, renderAvatarPerson],
  );

  if (avatars) {
    return (
      <AvatarStack
        maxShown={maxAvatarCount}
        overlap={overlap}
        extraOverflowCount={extraOverflowCount}
        size={size}
        disableAnimation={disableAnimation}
      >
        {avatars}
      </AvatarStack>
    );
  }

  return null;
};
