import * as ISchema from "#webapp/src/__schema__";

export enum PlannerViewActions {
  SetHighlightedSlot = "PlannerViewActions_SetHighlightedSlot",
  SetMeetingTitle = "PlannerViewActions_SetMeetingTitle",
}

export const setHighlightedSlot = (slot: ISchema.ISchedulingSlot | null) => {
  return {
    type: PlannerViewActions.SetHighlightedSlot,
    data: {
      slot,
    },
  };
};

export const setMeetingTitle = (meetingTitle: string | null) => {
  return {
    type: PlannerViewActions.SetMeetingTitle,
    data: {
      meetingTitle,
    },
  };
};
